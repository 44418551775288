import Promise from 'bluebird';
import _ from 'lodash';
import { action, computed, decorate, extendObservable, flow, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { benoitApi } from '../../apis';
import { BoardCard } from './board-card';
import { BoardPreferences } from './board-preferences';
import { Listpack } from './listpack';
import simpleCypher from '../../services/simple-cypher';
import analytics from '../../services/analytics';
import { SORT_OPTIONS } from '../../constants';
import { invalidateBoardCardCache, updateBoardCardCache } from 'hooks/data/board-card/useBoardCard';
import moment from 'moment';

const draftObservable = (...args)=> {
    return observable(...args);
};

const userPermissionToCollaboratorMapper = (userPermission)=> {
    return {
        id: userPermission.id,
        userId: userPermission.user.id,
        headshot: userPermission.user.headshot,
        first_name: userPermission.user.first_name,
        last_name: userPermission.user.last_name,
        name: userPermission.user.name,
        email: userPermission.user.email,
        phone_formatted: userPermission.user.phone_formatted,
        label: userPermission.label,
        archived: userPermission.archived,
        archivedAt: userPermission.archivedAt
    };
};

const userInviteToCollaboratorMapper = (userInvite)=> {
    return {
        id: userInvite.id,
        userId: userInvite.invitedUserId,
        first_name: userInvite.first_name,
        last_name: userInvite.last_name,
        name: `${userInvite.first_name} ${userInvite.last_name}`,
        email: userInvite.email,
        phone_formatted: userInvite.phone,
        label: userInvite.meta ? userInvite.meta.label : null,
        type: 'invite',
        archived: userInvite.archived,
        archivedAt: userInvite.archivedAt
    };
};

export class Board {
    boardCardsById = {};
    userPermissions = [];
    userInvites = [];
    boardCollaborations = [];
    loadingData = false;
    listpacks = [];
    listpacksLoaded = false;
    loadedBoardData = false;
    handSelectedLaneId = '1000';

    constructor(boardObj, boardsStore) {
        this.boardsStore = boardsStore;
        if(boardObj.userPermissions) {
            this.userPermissions = boardObj.userPermissions;
            boardObj = _.omit(boardObj, 'userPermissions');
        }
        extendObservable(this, boardObj);
    }

    get boardCards() {
        return Object.values(this.boardCardsById)
            .sort((card1, card2)=> card1.index - card2.index);
    }

    getBoardCardsForColumn = computedFn((laneId)=> {
        const cards = this.boardCards.filter((bc)=> bc.laneId === laneId && bc.archived === false)
            .sort((cardA, cardB)=> {
                return cardA.index - cardB.index;
            });

        return cards;
    });

    getBoardCard = computedFn((boardCardId)=> {
        return this.boardCards.find((bc)=> bc.id === boardCardId);
    });

    get newListings () {
        const count = this.listpacks.reduce((accum, value)=> {
            if(!value.archived) {
                return accum + value.newListingsCount;
            }
        }, 0);

        return count;
    }

    get allCollaborators() {
        return _.concat(
            this.userPermissions
                .filter((p)=> p.user && p.user.account_type !== 'invited_robin')
                .map(userPermissionToCollaboratorMapper),
            this.userInvites.map(userInviteToCollaboratorMapper)
        );
    }
    get myCollaborators(){
        return this.allCollaborators.filter((collaborator)=> !collaborator.archived && collaborator.userId !== this.ownerId)
            .sort((c1,c2)=> c1?.label?.localeCompare(c2?.label));
    }

    get collaborators() {
        return this.allCollaborators.filter((collaborator)=> !collaborator.archived)
            .sort((c1,c2)=> c1?.label?.localeCompare(c2?.label));
    }

    get archivedCollaborators() {
        return this.allCollaborators
            .filter((collaborator)=> collaborator.archived)
            .sort((a, b)=> !a.archivedAt ?
                -1 : !b.archivedAt ?
                    1 : moment(a.archivedAt).isAfter(b.archivedAt) ?
                        1 : -1 );
    }

    get owner() {
        const ownerPermission = this.userPermissions.find((co)=> {
            return co.user.id === this.ownerId;
        });

        if(!ownerPermission) {
            return null;
        }
        return ownerPermission.user;
    }

    get needsSetup() {
        return this.boardPreferences && !this.boardPreferences.boardPreferences.setup;
    }

    get hasCobuyer() {
        return this.collaborators.filter((collaborator)=> collaborator.label === 'co-buyer').length > 0;
    }

    get userRoleOnBoard() {
        const { user } = this.boardsStore.rootStore.UserStore;
        if(!user){
            return false;
        }
        return this.collaborators.find((co)=> (co.userId === user.id));
    }

    get allListpacksLoaded() {
        return this.listpacksLoaded && this.listpacks.filter((l)=> l.listpackDataLoaded === true).length === this.listpacks?.length ? true : false;
    }

    get sortedListpacks() {
        const sortedListpacks = _.orderBy(this.listpacks, ['opened', 'handSelected','lastSeenDate', ], ['desc', 'desc', 'desc']);

        const defaultValue = {all: [], opened: [], closed: [], handSelected: null, archived: null };
        let orderedListpacks = sortedListpacks.reduce((accum, value)=> {
            const isHandSelected = value.handSelected && !value.archived;

            return {
                all: [
                    ...accum.all,
                    value
                ],
                opened: [
                    ...accum.opened,
                    ...(value.opened && !value.archived ? [value] : []),
                ],
                closed: [
                    ...accum.closed,
                    ...(!value.opened && !value.archived ? [value] : []),
                ],
                handSelected: isHandSelected ? value : accum.handSelected,
                archived: value.archived
            };
        }, defaultValue);

        return orderedListpacks;
    }

    // DEPRECATED, USE archivedBoardCards for consistency
    get archivedListings() {
        return this.archivedBoardCards;
    }

    get archivedBoardCards() {
        return this.boardCards.filter((bc)=> bc.archived === true);
    }

    get notArchivedBoardCards() {
        return this.boardCards.filter((bc)=> !bc.archived);
    }

    get notArchivedLaneBoardCards() {
        return this.boardCards.filter((bc)=> !bc.archived && !bc.inHandselectedSearch);
    }

    get notArchivedListpacks() {
        return this.listpacks.filter((listpack)=> !listpack.archived);
    }

    get unarchivedSavedSearches() {
        return this.sortedListpacks.all
            .filter((listpack)=> !listpack.archived && listpack.listings_type !== 'static');
    }

    fetchBoardData = flow(function*() {
        this.loadedBoardData = false;
        this.loadingBoardData = true;
        yield Promise.all([this.fetchBoardCards(), this.fetchBoardCollaborators(), this.fetchBoardPreferences()]);
        yield this.fetchBoardListpacks();
        /* yield this.fetchBoardCardsDetails(); */
        this.loadingBoardData = false;
        this.loadedBoardData = true;
    });

    update = flow(function*(boardSettings) {
        const response = yield benoitApi.updateBoard(boardSettings);
        Object.assign(this, response);
    });

    moveCard = flow(function * moveCard(boardCardId, destinationLaneId, destinationIndex) {
        const boardCard = this.boardCardsById[boardCardId];
        if(!boardCard) {
            console.error('Trying to move a card not present in active board');
            return;
        }

        const sourceLaneId = boardCard.laneId;
        let sourceBoardCards = this.notArchivedBoardCards
            .filter((bc)=> bc !== boardCard && bc.laneId === sourceLaneId);
        let destinationBoardCards = this.notArchivedBoardCards
            .filter((bc)=> bc !== boardCard && bc.laneId === destinationLaneId);

        boardCard.laneId = destinationLaneId;
        boardCard.index = destinationIndex;
        boardCard.archived = false;
        boardCard.lastMovedById = this.boardsStore?.rootStore.UserStore?.user?.id;

        // if the lane sort selected isn't columnIndex the destinationBoardCards should be order by
        // the one that it is selected, to keep the same order that is in current the destination lane
        // then set the lane sort to columnIndex
        const laneSortSelected = this.getSelectedLaneSort(destinationLaneId);

        if(laneSortSelected.key !== SORT_OPTIONS[0].key){
            destinationBoardCards = _.orderBy(destinationBoardCards, laneSortSelected.id, laneSortSelected.order);
            this.setSortLanes(destinationLaneId, SORT_OPTIONS[0]);
        }

        destinationBoardCards.splice(destinationIndex, 0, boardCard);

        sourceBoardCards.forEach((sourceBoardCard, index)=> {
            sourceBoardCard.index = index;
        });

        destinationBoardCards.forEach((destinationBoardCard, index)=> {
            destinationBoardCard.index = index;
        });

        let dataToUpdate;
        if(destinationLaneId === sourceLaneId) {
            dataToUpdate = destinationBoardCards;
        } else {
            dataToUpdate = _.concat(sourceBoardCards, destinationBoardCards);
        }

        dataToUpdate = dataToUpdate.map((bc)=> bc.serializeToUpdate());
        const response = yield benoitApi.updateBoardCards(this.id, dataToUpdate);

        sourceBoardCards.forEach((sourceBoardCard, index)=> {
            sourceBoardCard.updatedAt = new Date()
                .toISOString();
        });
        destinationBoardCards.forEach((destinationBoardCard, index)=> {
            destinationBoardCard.updatedAt = new Date()
                .toISOString();
        });

        yield invalidateBoardCardCache(boardCardId);

        return response;
    })

    fetchBoardListpacks = flow(function*() {
        this.listpacksLoaded = false;
        const boardListpacks = yield benoitApi.getBoardListpacks(this.id);
        this.listpacks = boardListpacks.map((listpackData)=> {
            return new Listpack( this.boardsStore.rootStore, listpackData);
        });

        // Convert the hand selected cards to a virtual Listpack

        // const preExistingStaticListpack = this.listpacks.find((l)=> { return l.handSelected; });
        // if(!preExistingStaticListpack) {
        //     const { user } = this.boardsStore.rootStore.UserStore;
        //     const staticListpack = new Listpack(
        //         this.boardsStore.rootStore,
        //         {
        //             id: `listpack-${user.id}-${user.affiliateId}`,
        //             listings_type: 'static',createOrUpdateListpack
        //         }
        //     );
        //     this.listpacks.push(staticListpack);
        // }

        this.listpacksLoaded = true;
    });

    createOrUpdateListpack = flow(function*(listpackData) {
        const { UserStore } = this.boardsStore.rootStore;
        const { user } = UserStore;
        if(UserStore.isAgent) {
            listpackData.affiliateId = user.affiliateId;
        }

        let listpack;
        let listpackPromise;

        if(listpackData.id) {
            listpack = yield benoitApi.updateListpack(listpackData);
            listpackPromise = Promise.resolve(listpack);
        } else {
            //this Promise is used to delay the listpack creation until we have the listing available to set the hero_image for the BoardCollaboration
            listpackPromise = new Promise((resolve, reject)=> {
                new Listpack(this.boardsStore.rootStore, listpackData, async (localListPack)=> {

                    listpackData.hero_image = localListPack.heroImage;

                    listpack = await benoitApi.createListpack(listpackData);
                    if(UserStore.isFromNurtureUnlimited && UserStore.hubspotRecordId) {
                        try {
                            const hubspotContact = await benoitApi.getHubspotContact(UserStore.hubspotRecordId);
                            if(hubspotContact?.properties?.nurture_unlimited_status === 'Cold') {
                                await benoitApi.updateHubspotContact(UserStore.hubspotRecordId, { nurture_unlimited_status: 'Warm' });
                            }
                            analytics.eventTrack('robin_nurture_hubspot_email_campaign_cancelled');
                        } catch (err) {
                            analytics.eventTrack('robin_nurture_hubspot_email_campaign_cancellation_failure');
                        }
                    }
                    resolve(listpack);
                });
            });

        }
        if(listpackPromise){
            return listpackPromise.then((newListpack)=> {
                let listpack = new Listpack( this.boardsStore.rootStore, newListpack);

                this.listpacks = [...this.listpacks.filter((l)=> l.id !== listpack.id), listpack];

                return listpack;
            });

        }

        return listpack;
    });

    addListpack = flow(function*(listpack) {
        const newListpack = new Listpack( this.boardsStore.rootStore, listpack);
        yield this.listpacks = [...this.listpacks.filter((l)=> l.id !== listpack.id), newListpack];
    })

    fetchBoardCards = flow(function*() {
        const boardCardObjs = yield benoitApi.getBoardCards(this.id, { include_total_notes: true, include_last_moved_by_id: true });
        const boardCardsById = boardCardObjs.reduce((accum, boardCardObj)=> {
            updateBoardCardCache(boardCardObj);
            const boardCard = new BoardCard(boardCardObj, this);
            return {
                ...accum,
                [boardCardObj.id]: boardCard,
            };
        }, {});
        this.boardCardsById = boardCardsById;
    });

    getBoardCardById(listingId) {
        return this.boardCardsById[listingId];
    }

    fetchBoardCardsDetails = ()=> {
        const listingsIds = this.boardCards.map((bc)=> bc.content.encoded_id);
        if(!listingsIds.length) {
            return null;
        }
        return this.listingsStore.fetchListings(listingsIds);
    };

    moveListpackCard = flow(function*(listing, laneId = '1'){
        //Check if card already exists in board, if so, move the card to the laneId
        //Else, create it to the designed boardCard
        const card = this.boardCards.find((card)=> card.content.encoded_id === listing.encodedId);

        if(!card){
            return this.createBoardCard(listing, laneId);
        }

        if(card.columnId === laneId){
            return card;
        }

        yield this.moveCard(card.id, laneId, 0);

        return card;
    });

    getBoardCardByEncodedId(encodedId){
        return this.boardCards.find((card)=> card.content.encoded_id === encodedId);
    }

    createBoardCard = flow(function*(listing, laneId = '1') {
        const columnId = parseInt(laneId);
        const listingDeciphered = simpleCypher.decode(listing.encodedId);

        var newCard = {
            boardId: this.id,
            type: 'listing',
            columnId: columnId,
            columnIndex: 99999,
            userId: this.boardsStore?.rootStore?.UserStore?.user?.id,
            content: {
                listing_id: listingDeciphered.id,
                listing_source: listingDeciphered.source
            }
        };

        yield this.listingsStore.fetchListingById(listing.id);
        const card = yield benoitApi.addBoardCard(newCard);

        const newBoardCard = new BoardCard(card, this);
        this.boardCardsById[card.id] = newBoardCard;
        yield this.moveCard(card.id, laneId, 0);

        return this.boardCardsById[card.id];
    });

    createBoardCards = flow(function*(listings, destLaneId) {
        const columnId = parseInt(destLaneId);
        const userId = this.boardsStore?.rootStore?.UserStore?.user?.id;
        const boardId = this.id;
        const boardCardType = 'listing';
        const source = null;
        const columnIndex = 99999;

        yield this.listingsStore.fetchListings(listings.map((listing)=> listing.id));

        const newBoardCards = listings.map((listing)=> {
            const listingDeciphered = simpleCypher.decode(listing.encodedId);

            return {
                boardId,
                type: boardCardType,
                source: source,
                columnId: columnId,
                columnIndex: columnIndex,
                userId,
                content: {
                    listing_id: listingDeciphered.id,
                    listing_source: listingDeciphered.source,
                },
            };
        });


        const cards = yield benoitApi.addBoardCard(newBoardCards);

        cards.map((card)=> {
            this.boardCardsById[card.id] = new BoardCard(card, this);
            this.moveCard(card.id, columnId, 0);
        });

        return cards;
    });

    archiveCard = flow(function*(boardCardId) {
        const boardCard = this.boardCardsById[boardCardId];
        if(!boardCard) {
            console.error('Trying to move a card not present in active board');
            return;
        }

        yield benoitApi.archiveBoardCard(boardCardId);
    })

    createInvite = flow(function*(data) {
        const { user } = this.boardsStore.rootStore.UserStore;
        const payload = {
            ...data, boardId: this.id, createdById: user.id, userId: user.id, affiliateId: user.affiliateId
        };
        const {invite, userPermission} = yield benoitApi.createBoardInvite(payload);
        this.userInvites = this.userInvites.concat([invite]);
        this.userPermissions = this.userPermissions.concat([userPermission]);
        return invite;
    });

    resendInviteEmail = flow(function*(collaboratorId){
        yield benoitApi.resendBoardInviteEmail(collaboratorId);
        return;
    });

    updateCollaborator = flow(function*(collaborator) {
        const isInvite = collaborator.type === 'invite';
        if(isInvite) {
            const inviteData = { id: collaborator.id, meta: { label: collaborator.label } };
            const response = yield benoitApi.updateBoardUserInvite(inviteData);
            this.userInvites = this.userInvites.map((item)=> (item.id === response.id ? { ...item, response } : item));
        } else {
            const response = yield benoitApi.updateBoardUserPermission({
                id: collaborator.id,
                label: collaborator.label,
            });
            this.userPermissions = this.userPermissions.map((item)=>
                item.id === response.id ? { ...item, response } : item
            );
        }
    });

    removeCollaborator = flow(function*(collaborator) {
        const isInvite = collaborator.type === 'invite';

        if(isInvite) {
            yield benoitApi.removeBoardUserInvite(collaborator.id);
            this.userInvites = this.userInvites.map((item)=>
                item.id === collaborator.id ? { ...item, archived: true, archivedAt: moment().format() } : item
            );
        } else {
            yield benoitApi.removeBoardUserPermission(collaborator.id);
            this.userPermissions = this.userPermissions.map((item)=>
                item.id === collaborator.id ? { ...item, archived: true, archivedAt: moment().format() } : item
            );
        }
    });

    undoLastRemoveCollaborator = flow(function*() {
        const collaborator = this.archivedCollaborators.pop();
        const isInvite = collaborator.type === 'invite';
        const data = { id: collaborator.id, archived: false };

        if(isInvite) {
            const userPermission = this.userPermissions.find((perm)=> perm.userId === collaborator.userId);
            if(userPermission) {
                const permissionData = {id: userPermission.id, archived: false};
                yield benoitApi.updateBoardUserPermission(permissionData);
                this.userPermissions = this.userPermissions.map((item)=> {
                    return item.id === permissionData.id ? { ...item, archived: false } : item;
                }
                );
            }
            yield benoitApi.updateBoardUserInvite(data);
            this.userInvites = this.userInvites.map((item)=>
                item.id === collaborator.id ? { ...item, archived: false } : item
            );
        } else {
            yield benoitApi.updateBoardUserPermission(data);
            this.userPermissions = this.userPermissions.map((item)=>
                item.id === collaborator.id ? { ...item, archived: false } : item
            );
        }
    });

    fetchBoardCollaborators = flow(function*() {
        const [userPermissions, userInvites] = yield Promise.all([
            benoitApi.getBoardCollaborators(this.id),
            benoitApi.getBoardInvites(this.id),
        ]);
        this.userPermissions = userPermissions;

        this.userInvites = userInvites.filter((invite)=> invite.status !== 'accepted');
    });

    fetchBoardPreferences = flow(function*() {
        const boardPreferences = yield benoitApi.getBoardPreferences(this.id);
        const boardPreferencesObj = boardPreferences
            ? boardPreferences
            : { setup: false, preferences: [], filters: [] };
        this.boardPreferences = new BoardPreferences(boardPreferencesObj, this);
    });

    get listingsStore() {
        return this.boardsStore.rootStore.listingsStore;
    }

    refreshBoardCards = flow(function*() {
        yield this.fetchBoardCards();
        yield this.fetchBoardCardsDetails();
    });

    /**
     * @param {Object} data
     */
    updateBoardCardsById(data) {
        this.boardCardsById = {
            ...this.boardCardsById,
            ...data,
        };
    }

    updateLastSeenAt = flow(function*(){
        const payload = {
            id: this.id,
            lastSeenAt: new Date()
                .toISOString(),
        };
        yield benoitApi.updateBoard(payload);
    });

    incrementBoardMetric = flow(function*(metric){
        yield benoitApi.incrementBoardMetric(this.id, metric);
    });

    getSelectedLaneSort(laneId) {
        if(this.sort_lanes) {
            const index = this.sort_lanes.findIndex((lane)=> lane.laneId === laneId );

            if(index !== undefined && index !== -1) {
                const {sort_property, sort_direction} = this.sort_lanes[index];
                if(sort_property && sort_direction){
                    return  _.values(SORT_OPTIONS).find((option)=> option.key === sort_property && option.order === sort_direction );
                } else {
                    return  SORT_OPTIONS[0];
                }

            } else {
                return  SORT_OPTIONS[0];
            }
        }

        return  SORT_OPTIONS[0];
    }

    setSortLanes = flow(function*(laneId, option) {
        const index = this.sort_lanes.findIndex((lane)=> lane.laneId === laneId );
        const selected = {laneId: laneId, sort_property: option.key, sort_direction: option.order };
        if(index !== -1){
            this.sort_lanes[index] = selected;
        } else {
            this.sort_lanes.push(selected);
        }

        yield benoitApi.updateBoard({id: this.id, sort_lanes: this.sort_lanes});
    });
}

decorate(Board, {
    boardCardsById: draftObservable,
    userPermissions: observable,
    userInvites: observable,
    loadingBoardData: observable,
    listpacks: observable,
    listpacksLoaded: observable,
    loadedBoardData: observable,
    handSelectedLaneId: observable,

    newListings: computed,
    boardCards: computed,
    allCollaborators: computed,
    collaborators: computed,
    archivedCollaborators: computed,
    owner: computed,
    needsSetup: computed,
    hasCobuyer: computed,
    sortedListpacks: computed,
    allListpacksLoaded: computed,
    notArchivedListpacks: computed,
    notArchivedBoardCards: computed,
    archivedListings: computed,
    archivedBoardCards: computed,
    unarchivedSavedSearches: computed,

    createOrUpdateListpack: action,
    // createListpack: action,
    // updateListpack: action,
    fetchBoardData: action,
    fetchBoardCards: action,
    fetchBoardCollaborators: action,
    fetchBoardCardsDetails: action,
    createBoardCard: action,
    createInvite: action,
    updateCollaborator: action,
    removeCollaborator: action,
    undoLastRemoveCollaborator: action,
    refreshBoardCards: action,
    updateBoardCardsById: action,
    updateLastSeenAt: action,
    incrementBoardMetric: action,
    moveListpackCard: action,
    getBoardCardByEncodedId: action,
    setSelectedLanesSort: action,
});
