import React, { useMemo, useState } from 'react';
import { AppBar, Grid, Hidden, Slide, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import LRModal from '../../LRModal';
import Map from '../../../scenes/ListingsMap/Map';
import { useBoardCards } from '../../../hooks/useBoardCards';
import Logger from 'js-logger';
import { DesktopSidebar } from '../../../scenes/ListingsMap/Sidebar';
import { observer } from 'mobx-react-lite';
import LRButton from '../../LRButton';
import { ChevronLeftRounded } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withTheme } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import { useStore } from '../../../mobx-store';
import Card from '../../../scenes/Board/Card';
import analytics from '../../../services/analytics';
import { useEffect } from 'react';
import LRSelect from '../../LRSelect';
import { DrawerToggleButton } from '../../DrawerToggleButton';
import _ from 'lodash';

const logger = Logger.get('MapViewSelectionModal');

const FilterButton = withTheme(styled(ToggleButton)`
    min-width: 160px;
    height: 40px;
    text-transform: capitalize;

    ${({ theme })=> css`
        color: ${theme.palette.text.primary};
        &.Mui-selected {
            color: ${theme.palette.text.primary};
            background-color: ${theme.palette.grey[300]};
        }
    `}
`);


export const MapViewSelectionModal = observer(function MapViewSelectionModal({
    open = false,
    selectedListings = [],
    className = '',
    onListingSelected = (e, selectedListing)=> {},
    onClose = (e)=> {},
    TransitionComponent = Slide,
    TransitionProps = {},
    ...props
}) {
    const DEFAULT_LANE_ID = '1';
    const MAX_SELECTED_LISTINGS = 5;
    const theme = useTheme();
    const { LanesStore } = useStore();
    const { lanes } = LanesStore;
    const {
        loaded,
        boardCardsById,
        getUnarchivedBoardCardsByLaneId
    } = useBoardCards();
    const [selectedLaneId, setSelectedLaneId] = useState(DEFAULT_LANE_ID);
    const [listings, setListings] = useState([]);
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const isXs = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
    const [openListingsDrawer, setOpenListingsDrawer] = useState(false);
    const [hoverListing, setHoverListing] = useState(null);

    // UseEffects
    useEffect(()=> {
        if(!open) setSelectedLaneId(DEFAULT_LANE_ID);
    }, [open]);

    useEffect(()=> {
        if(!loaded) return;

        const listings = getUnarchivedBoardCardsByLaneId(selectedLaneId);
        setListings(listings);
    }, [loaded, boardCardsById, selectedLaneId]);

    // Functions

    function listingSelected(selectedListing) {
        if(selectedListings.length >= MAX_SELECTED_LISTINGS && !~selectedListings.indexOf(selectedListing)) return;

        onListingSelected(selectedListing);
    }

    function handleLaneChange(value) {
        if(!!value){
            analytics.eventTrack('robin_schedule_a_tour_view_column_toggled_button_click', {
                filter: _.snakeCase(lanes[value - 1 ].name),
                source: 'home_tour_map',
            });
        }
    }

    return (
        <LRModal
            className={`MapViewSelectionModal-root ${className}`}
            TransitionComponent={TransitionComponent}
            TransitionProps={{
                direction: 'left',
                ...TransitionProps,
            }}
            open={open}
            {...props}
        >
            <div
                style={{
                    height: 'calc(100vh - 64px)'
                }}
            >
                <AppBar
                    color="inherit"
                    elevation={0}
                    style={{
                        borderBottom: '1px solid #dddddd',
                    }}
                >
                    <Toolbar
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                width: '150px',
                            }}
                        >
                            <LRButton
                                startIcon={<ChevronLeftRounded />}
                                onClick={onClose}
                                style={{
                                    minWidth: '0',
                                }}
                            >{!isMdDown && 'Schedule a tour'}</LRButton>
                        </div>

                        {/* Desktop */}
                        <Hidden smDown>
                            <div>
                                <ToggleButtonGroup
                                    exclusive
                                    value={selectedLaneId}
                                    onChange={(e, value)=> {
                                        if(!value) return;
                                        setSelectedLaneId(value);
                                        handleLaneChange(value);
                                    }}
                                >
                                    {
                                        lanes.map((lane)=> {
                                            const laneCards = getUnarchivedBoardCardsByLaneId(lane.id);
                                            return (
                                                <FilterButton
                                                    disabled={laneCards.length === 0}
                                                    value={lane.id}
                                                >
                                                    {lane.name} ({laneCards.length})
                                                </FilterButton>);
                                        })
                                    }
                                </ToggleButtonGroup>
                            </div>
                        </Hidden>

                        {/* Mobile */}
                        <Hidden mdUp>
                            <div>
                                <LRSelect
                                    native
                                    variant="filled"
                                    value={selectedLaneId}
                                    onChange={(e)=> {
                                        const value = e.currentTarget?.value;
                                        handleLaneChange(value || DEFAULT_LANE_ID);
                                        setSelectedLaneId(value || DEFAULT_LANE_ID);
                                    }}
                                >
                                    {
                                        lanes.map((lane)=> {
                                            const laneCards = getUnarchivedBoardCardsByLaneId(lane.id);
                                            return  (<option value={lane.id} disabled={laneCards.length === 0}>{lane.name} ({laneCards.length})</option>);
                                        })
                                    }
                                </LRSelect>
                            </div>
                        </Hidden>

                        <div
                            style={{
                                width: '150px',
                                textAlign: 'right',
                            }}
                        ></div>
                    </Toolbar>
                </AppBar>

                <Toolbar />

                <Grid container style={{ height: '100%' }}>
                    {!isMdDown && (
                        <Grid item xs>
                            <Map
                                listings={listings}
                                // selectedListing={selectedListing}
                                hoveredListing={hoverListing}
                                onHover={(listing)=> {
                                    setHoverListing(listing);
                                }}
                                onListingSelect={listingSelected}
                            />
                        </Grid>
                    )}

                    <Grid
                        item
                        style={{
                            width: isMdDown ? '100%' : (openListingsDrawer ? '765px' : '312px'),
                            maxHeight: '100%',
                            borderLeft: '1px solid #dddddd',
                            // transition: 'width 0.3s ease-out',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                maxHeight: '100%',
                                overflow: 'auto',
                            }}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item style={{ maxWidth: openListingsDrawer || isMdDown ? '750px' : '310px' }}>
                                    <DesktopSidebar
                                        listings={listings}
                                        showLoading={listings.length === 0}
                                        hoveredListing={hoverListing}
                                        handleOnHover={(listing)=> {
                                            setHoverListing(listing);
                                        }}
                                        renderCard={function (props) {
                                            const isSelected = _.some(selectedListings, ['id', props.listing.id]);
                                            return (
                                                <Card
                                                    {...props}
                                                    selected={isSelected}
                                                    hovering={hoverListing && props.listing?.id === hoverListing?.id}
                                                    variant="addHomeToTour"
                                                    onAddHomeToTourClick={listingSelected}
                                                    disableActionButton={!isSelected && selectedListings.length >= MAX_SELECTED_LISTINGS}
                                                />
                                            );
                                        }}
                                        onListingSelect={listingSelected}
                                        cardContainerWidth={isXs && '100%'}
                                        felxWrap="wrap"
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <Hidden mdDown>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '16px',
                                    left: '-10px',
                                }}
                            >
                                <DrawerToggleButton
                                    open={!openListingsDrawer} /* Its backwards because the button is on the opposite side it was coded for */
                                    handleToggle={()=> {
                                        setOpenListingsDrawer(!openListingsDrawer);
                                    }}
                                />
                            </div>
                        </Hidden>
                    </Grid>
                </Grid>
            </div>
        </LRModal>
    );
});
