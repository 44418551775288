import simpleCypher from '../../../services/simple-cypher';

export function transformMarketActivityListing(listing) {
    const transformedListing = {
        id: simpleCypher.encode(listing.LRSource, listing.id),
        active: listing.Active || false,
        address: listing.FormattedAddress,
        architecture_style: listing.ArchitectureStyle,
        baths: listing.Bathrooms,
        baths_full: listing.FullBathrooms,
        baths_half: listing.HalfBathrooms,
        baths_one_quarter: listing.OneQuarterBathrooms,
        baths_three_quarter: listing.ThreeQuarterBathrooms,
        beds: listing.Bedrooms,
        city: listing.City,
        country: listing.Country,
        county: listing.County,
        description: listing.Description,
        distance: listing.Distance,
        effective_listing_date: listing.EffectiveListingDate,
        encodedId: simpleCypher.encode(listing.LRSource, listing.id),
        floors: listing.NumFloors,
        hashed_address: listing.HashedAddress,
        hero_image: listing.HeroImage,
        home_size: listing.LivingArea,
        listing_category: listing.ListingCategory,
        listing_date: listing.ListingDate,
        listing_hash: listing.ListingHash,
        listing_source: listing.LRSource,
        listing_status: listing.ListingStatus,
        lat: listing.Latitude,
        latitude: listing.Latitude,
        longitude: listing.Longitude,
        lon: listing.Longitude,
        lot_size: listing.LotSize,
        mls_id: listing.MlsId,
        mls_number: listing.MlsNumber,
        neighborhoods: listing.Neighborhoods,
        normalized_listing_status: listing.NormalizedListingStatus,
        open_house_date: listing.OpenHouseDate,
        parking_spaces: listing.NumParkingSpaces,
        parcel_number: listing.ParcelNumber,
        postal_code: listing.PostalCode,
        price: listing.ListPrice || listing.SoldPrice,
        price_per_sqft: listing.PricePerSquareFoot || listing.PricePerSqft,
        photos: listing.HeroImage ? [listing.HeroImage] : [],
        realtor: {
            company: listing.RealtorCompany,
            email: listing.RealtorEmail,
            name: listing.RealtorName,
            first_name: listing.RealtorName?.split(' ')[0],
            last_name: listing.RealtorName?.split(' ')
                .slice(1)
                .join(' '),
            office_phone: null, // We dont get this info from the API
            phone: null, // We dont get this info from the API
            website: null, // We dont get this info from the API
            license_number: null, // We dont get this info from the API
        },
        sold_date: listing.SoldDate,
        sold_price: listing.SoldPrice,
        state: listing.StateOrProvince,
        street_address: listing.FullStreetAddress,
        sub_type: listing.PropertySubType,
        type: listing.PropertyType,
        year: parseFloat(listing.YearBuilt, 10),
    };

    if(transformedListing.listing_status === 'Hold') transformedListing.listing_status = 'Pending';

    return transformedListing;
}
