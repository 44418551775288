import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import videoUtils from '../../../services/video-utils';
import { COLOR_GREY_DARK } from '../../../constants';

const VideoPlayerContainer = styled(Box)`
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    background-color: ${COLOR_GREY_DARK};
`;

const EmbedContainer = styled(Box)`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
`;

const EmbeddedIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

function VideoPlayer({ videoUrl, autoplay=false, playerParams, containerProps={} }) {
    const video = videoUtils.getVideoProviderAndId(videoUrl);
    /*
    // Example Wistia Video
    const video = {
        provider: 'wistia',
        id: 'pmgxpou1lt',
    };*/

    const autoPlayParams = {
        vimeo: 'autoplay=1&muted=1&texttrack=en&loop=1',
        wistia: 'muted=true&autoPlay=true&playbar=false&controlsVisibleOnLoad=false&silentAutoPlay=allow&plugin[captions-v1][onByDefault]=true&settingsControl=false',
        youtube: 'autoplay=1&cc_load_policy=1&cc_lang_pref=en&modestbranding=1&mute=1&disablekb=1&iv_load_policy=3&loop=1',
    };
    const { id, provider } = video || {};
    let embedParams = autoplay ? autoPlayParams[provider] || '' : '';
    if(playerParams) {
        embedParams = playerParams;
    }

    if(!(video && id)) return null;

    return (
        <VideoPlayerContainer maxWidth={800}
            mx="auto"
            mb={2}
            {...containerProps}
        >
            <EmbedContainer>
                {provider === 'vimeo' && (
                    <EmbeddedIframe src={`https://player.vimeo.com/video/${id}?${embedParams}`}
                        frameborder='0'
                        name="vimeo_embed"
                        webkitAllowFullScreen
                        mozallowfullscreen
                        allowFullScreen
                        allow="autoplay; fullscreen"
                    />
                )}

                {provider === 'wistia' && (
                    <EmbeddedIframe src={`https://fast.wistia.net/embed/iframe/${id}?videoFoam=true&${embedParams}`}
                        allowtransparency="true"
                        frameborder="0"
                        scrolling="no"
                        className="wistia_embed"
                        name="wistia_embed"
                        allowFullScreen="true"
                        mozallowfullscreen
                        webkitallowfullscreen
                        oallowfullscreen
                        msallowfullscreen
                    />
                )}

                {provider === 'youtube' && (
                    <EmbeddedIframe
                        src={`https://www.youtube.com/embed/${id}?${embedParams}`}
                        frameborder='0'
                        name="youtube_embed"
                        allowfullScreen
                        title='The event will start shortly'
                    />
                )}
            </EmbedContainer>
        </VideoPlayerContainer>
    );

}
export default VideoPlayer;
