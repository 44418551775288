import { benoitApi } from 'apis';
import { useQuery } from 'react-query';
import _ from 'lodash';

export function useListingSchoolData(listing) {
    return useQuery(
        ['listing-school-data', listing?.id],
        async function() {
            const address = listing.address;

            let data;
            data = await benoitApi.getInfographicsData({
                type: 'schools',
                address: address,
            });

            data.schools = data.schools.map((school)=> {
                const sortOrder = school.grades
                    .toString()
                    .replace('-', '')
                    .replace(/[A-Za-z]+/g, '0');
                school.sort_order = sortOrder;

                return school;
            });
            data.schools = _.sortBy(data.schools, 'sort_order');
            return data;
        },
        {
            enabled: Boolean(listing),
        }
    );
}
