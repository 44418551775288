import { Dialog, useTheme, IconButton } from '@material-ui/core';
import { SIGNUP_LOGIN_VARIANT_WIDGET } from '../../constants';
import React from 'react';
import { Login } from '../../scenes/Login';
import { Close } from '@material-ui/icons';
import { SignUp } from 'scenes/SignUp';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import analytics from 'services/analytics';

export function LoginModal({
    onCloseClick = (e)=> {},
    onLoginSuccess = ()=> {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();

    return (
        <Dialog
            {...props}
        >
            <div
                style={{
                    width: !isMobile && 360,
                    paddingBottom: theme.spacing(3),
                    position: 'relative',
                }}
            >
                <Login
                    variant={SIGNUP_LOGIN_VARIANT_WIDGET}
                    onLoginSuccess={onLoginSuccess}
                />

                <div
                    style={{
                        position: 'absolute',
                        top: theme.spacing(1),
                        right: theme.spacing(1),
                    }}
                >
                    <IconButton onClick={onCloseClick}>
                        <Close />
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
}

export function SignUpModal({
    onCloseClick = (e)=> {},
    onSignUpSuccess = ()=> {},
    onSwitchToLoginClick,
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();

    return (
        <Dialog
            {...props}
        >
            <div
                style={{
                    width: !isMobile && 360,
                    paddingBottom: theme.spacing(3),
                    position: 'relative',
                }}
            >
                <SignUp
                    variant={SIGNUP_LOGIN_VARIANT_WIDGET}
                    onSignUpSuccess={onSignUpSuccess}
                    onSwitchToLoginClick={onSwitchToLoginClick}
                />

                <div
                    style={{
                        position: 'absolute',
                        top: theme.spacing(1),
                        right: theme.spacing(1),
                    }}
                >
                    <IconButton onClick={analytics.eventTrackOnEvent('robin_sign_up_modal_exit', {}, onCloseClick)}>
                        <Close />
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
}
