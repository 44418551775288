import React from 'react';
import { useTheme, Typography } from '@material-ui/core';
import LRButton from '../../../../LRButton';

export function AgentNotificationBase({
    children = null,
    caption = null,
    title = null,
    primaryButtonLabel = null,
    secondaryButtonLabel = null,
    showPrimaryButton = true,
    showSecondaryButton = true,
    onPrimaryButtonClick = ()=> {},
    onSecondaryButtonClick = ()=> {},
}) {
    const theme = useTheme();

    return (
        <div 
            className="AgentNotificationBase"
            style={{
                padding: theme.spacing(3),
                bckground: '#fff',
                border: '1px solid #DDDDDD',
                borderRadius: '4px',
                maxWidth: '100%',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
            }}
        >
            {caption && (
                <div
                    style={{
                        marginBottom: theme.spacing(1),
                    }}
                >
                    {caption}
                </div>
            )}

            <Typography 
                variant="h6"
                style={{
                    marginBottom: theme.spacing(2),
                    whiteSpace: 'normal',
                }}

            >
                {title}
            </Typography>

            <Typography
                variant="body2"
                component="div"
                style={{
                    marginBottom: theme.spacing(3),
                    whiteSpace: 'normal',
                }}
            >
                {children}
            </Typography>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {showSecondaryButton && (
                    <LRButton variant="text" inline onClick={onSecondaryButtonClick}>
                        {secondaryButtonLabel}
                    </LRButton>
                )}

                <span />

                {showPrimaryButton && (
                    <LRButton color="primary" variant="contained" onClick={onPrimaryButtonClick}>
                        {primaryButtonLabel}
                    </LRButton>
                )}
            </div>
        </div>
    );
};
