import React, { useMemo } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Box, Typography, withTheme } from '@material-ui/core';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import analytics from '../../../../services/analytics';
import { useStore } from '../../../../mobx-store';
import LRButton from '../../../../components/LRButton';

const Title = withTheme(styled(Typography)`
    margin-bottom: ${({ theme })=> theme.spacing(2)}px;
`);

const Title2 = styled(Typography)`
    font-weight: bold;
    fontsize: '1rem';
    line-height: 1.375rem;
`;

const StyledDesktopWindowsIcon = withTheme(styled(DesktopWindowsIcon)`
    margin-right: ${({ theme })=> theme.spacing(1.5)}px;
    font-size: 1.5rem;
`);

const Description = withTheme(styled(Typography)`
    fontsize: '1rem';
    line-height: 1.375rem;
    margin-bottom: ${({ theme })=> theme.spacing(3)}px;
`);

const Resource = observer(function Resource({ title, description, ctaUrl }) {
    function onClick() {
        analytics.eventTrack('mortgage_center_education_view_btn_click');
    }

    return (
        <Box>
            <Box mb={1} display="flex" alignItems="center">
                <StyledDesktopWindowsIcon />
                <Title2>{title}</Title2>
            </Box>
            <Description>{description}</Description>
            <LRButton variant="outlined" href={ctaUrl} target="_blank" onClick={onClick}>
                View
            </LRButton>
        </Box>
    );
});

function EducationalResources() {

    const resources = [
        {
            id: 1,
            title: 'Understanding loan options',
            description: 'Not all home loans are the same. Use our guide to understand how these choices affect your monthly payment, your overall costs both upfront and over time, and your level of risk.',
            ctaUrl: 'https://listreports.slides.com/listreports/deck-472d37/fullscreen?token=g_6XNLPH',
        },
        {
            id: 2,
            title: 'Understanding credit scores',
            description: 'Your credit score determines the loans you can get and the interest rates you pay and whether they help or hurt depends on how good they are.',
            ctaUrl: 'https://listreports.slides.com/listreports/deck-83832c/fullscreen?token=e1QpSwX4',
        }
    ];

    return (
        <Box p={3} border={2} mb={[4, 0]} borderColor="lr_colors.border_grey_lighter" height="max-content" >
            <Title variant="h6">Educational resources</Title>
            <Description>
                Choosing the right home loan is just as important as choosing the right home. Knowing what to expect—and
                what questions to ask—every step of the way is important.
            </Description>
            <Box height={8} />
            {resources && resources.length && resources.map((resource, index)=> (
                <>
                    <Resource key={resource.id} {...resource} />
                    {index < resources.length -1 &&
                        <Box height={32} />
                    }
                </>
            ))}
        </Box>
    );
}

export default EducationalResources;
