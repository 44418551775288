// eslint-disable-next-line
import axios from 'axios';
import Logger from 'js-logger';

const RATE_FARM_API_KEY = process.env.REACT_APP_RATE_FARM_API_KEY;
const portalAxios = axios.create({
    baseURL: `${process.env.REACT_APP_PORTAL_URL}`,
});

// PUBLIC FUNCTIONS
export async function incrementListpackClickedMetric(listpackId) {
    try {
        const response = await portalAxios.post(`/v1/listpacks/${listpackId}/metrics/clicks/increment`);

        return response.data;
    } catch (err) {}
}

export async function incrementListpackListingViewedMetric(listpackId) {
    try {
        const response = await portalAxios.post(`/v1/listpacks/${listpackId}/metrics/listings_viewed/increment`);

        return response.data;
    } catch (err) {}
}

export async function getSubscriptionStatus(userId, subscriptionType) {
    const response = await portalAxios.get(`/v1/users/${userId}/addon-subscriptions/${subscriptionType}/is-active`);
    return response.data;
}

export async function getLORobinAccessStatus(userId) {
    const subscriptionType = 'Robin Access';
    const response = await portalAxios.get(`/v1/users/${userId}/addon-subscriptions/${subscriptionType}/user-or-paired-agents/is-active`);
    return response.data;
}

/**
 * Get a listpack data
 *
 * @function getAddressRates
 * @param {String} address
 */
export async function getAddressRates(address) {
    // let encodedAddress = encodeURI(address);
    const response = await portalAxios.get(`/v2/properties/${address}/mortgagecompare?api_key=${RATE_FARM_API_KEY}`);

    return response.data.data;
}

/**
 * Get a listpack data
 *
 * @function getListpackData
 * @param {String} agentId
 * @param {String} listpackId
 */
export async function getListpackData(listpackId) {
    const response = await portalAxios.get(`/v1/listpacks/${listpackId}`);

    return response.data.data;
}

/**
 * Get a listpack listings
 *
 * @function getListpackListings
 * @param {Array} areaIds
 * @param {String} listpackId
 */
export async function getListpackListings(listpack, encodedId, order, limit = 30, offset = 0) {
    try {
    } catch (error) {
        throw new Error('Invalid areas given.');
    }
    const response = await portalAxios.get(
        `/v1/listpacks/${encodedId}/listings?limit=${limit}&skip=${offset}${order ? `&order=${order}` : ''}&v=${
            listpack.updatedAt
        }`
    );

    return response.data.data;
}

/**
 * Get a listpack listings count
 *
 * @function getListpackListingsCount
 * @param {Array} areas
 * @param {String} listpackId
 */
export async function getListpackListingsCount(listpack, encodedId) {
    try {
    } catch (error) {
        throw new Error('Invalid areas given.');
    }

    const response = await portalAxios.get(`/v1/listpacks/${encodedId}/listings-count?v=${listpack.updatedAt}`);

    return response.data.data;
}

/**
 * Get report data
 *
 * @function getReport
 * @param {String} reportId
 */
export async function getReport(reportId) {
    const response = await portalAxios.get(`/v1/reports/${reportId}`);

    return response.data;
}

/**
 * Get LrLive record
 *
 * @function getLrLive
 * @param {String} id
 * @param {Object} options
 * - {Boolean} includeUser: will include user join in response if true
 * - {Boolean} includeReport: will include basic report data join in response if true
 */
export async function getLrLive(id, options = {}) {
    const response = await portalAxios.get(`/v1/lr-live/${id}`, { params: options });

    return response.data.data;
}

/**
 * Get LrLive sponsor with formatted compliance and branch info
 *
 * @function getLrLiveSponsor
 * @param {String} id: the LrLive record id
 */
export async function getLrLiveSponsor(id) {
    const response = await portalAxios.get(`/v1/lr-live/${id}/sponsor`);

    return response.data.data;
}

/**
 * Create a generic VendorLead
 * This api expects all the specific lead keys to
 * be set in the payload data. Additional actions
 * may be performed based on the data.source passed
 *
 * @function getLrLiveLink
 * @param {String} meetingId
 * @param {Object} user - the name, email, id (could be the VendorLead id for a lrlive participant)
 */
export async function getLrLiveLink(meetingId, user) {
    const response = await portalAxios.get(`/v1/lr-live/${meetingId}/link`, {
        params: {
            user: JSON.stringify(user || {}),
        },
    });

    return response.data.data;
}

/**
 * Get a VendorLead by id
 *
 * @function getLead
 * @param {String} leadId
 */
export async function getLead(leadId) {
    const response = await portalAxios.get(`/v1/lr-live/leads/${leadId}`);

    return response.data.data;
}

/**
 * Creates a lead from the listpack form
 *
 * @function createLeadFromListpack
 * @param {Object} agent
 * @param {Object} leadData
 * @param {Object} listpack
 */
export async function createLeadFromListpack(agent, leadData, listpack) {
    const response = await portalAxios.post(`/v1/users/${agent.id}/listpacks/${listpack.id}/leads`, leadData);

    return response.data.data;
}

/**
 * Shares an email with the url to a listing with an optional message
 *
 * @function shareListing
 * @param {string} agentId
 * @param {string} listpackId
 * @param {string} listingId
 * @param {Object} shareData
 */
export async function shareListing(agentId, listpackId, listingId, shareData) {
    const response = await portalAxios.post(
        `/v1/users/${agentId}/listpacks/${listpackId}/listings/${listingId}/share`,
        shareData
    );

    return response.data.data;
}

/**
 * Creates a lead for a user
 *
 * @function createLead
 * @param {Object} user
 * @param {Object} leadData
 */
export async function createLead(user, leadData) {
    const response = await portalAxios.post(`/v1/users/${user.id}/leads`, { leadData });

    return response.data;
}

export async function sendUserLeadSubmit(user, lead, variation, message, address, snapshotImg, snapshotHtml) {
    const response = await portalAxios.post('/v1/send-email/rate-farm-lead-submit', {
        userId: user.id,
        leadId: lead.id,
        variation,
        message,
        address,
        snapshotImg,
        snapshotHtml,
    });

    return response.data.data;
}

/**
 * Updates a VendorLead
 * Since this is an unauthenticated API
 * `agentId` is a required property in the PUT body
 * and it will be rejected if the agentId doesn't match
 * the existing one on the lead
 *
 * @function updateLead
 * @param {String} leadId
 * @param {VendorLead} data
 */
export async function updateLead(leadId, data) {
    const response = await portalAxios.put(`/v1/lr-live/leads/${leadId}`, data);

    return response.data.data;
}

export async function getAffiliateUser(userId) {
    const response = await portalAxios.get(`/v1/affiliatecodes/${userId}`);

    return response.data;
}

export async function createActivity(activityData) {
    const response = await portalAxios.post('/v1/activities', activityData);

    return response.data.data;
}

export async function sendPremiumShareablesEmail(user, lead, message) {
    const response = await portalAxios.post('/v1/send-email/premium-shareables-new-lead', { user, lead, message });

    return response.data.data;
}

/**
 * Returns a shortened url
 *
 * @function shortenURL
 * @param {string} url
 */
export async function shortenURL(url) {
    const response = await portalAxios.post('/v1/shorten-url', { url: url });

    return response.data;
}

/**
 * Returns the socialShareUrl url from portal
 *
 * @function getSocialShareUrl
 * @param {string} agentId
 * @param {string} listpackId
 * @param {string} listingId
 */
export function getSocialShareUrl(agentId, listpackId, listingId) {
    return `${process.env.REACT_APP_PORTAL_URL}/v1/users/${agentId}/listpacks/${listpackId}/listings/${listingId}/social-share`;
}

/**
 * Returns if user has premium shareables subscription
 *
 * @function checkPremiumShareablesSubscription
 * @param {string} userId
 */
export async function checkPremiumShareablesSubscription(userId) {
    const response = await portalAxios(
        `${process.env.REACT_APP_PORTAL_URL}/v1/users/${userId}/addon-subscriptions/Premium Shareables/is-active`
    );

    return response.data;
}

/**
 * Returns an array of areas matching the search term
 *
 * @function checkPremiumShareablesSubscription
 * @param {string} userId
 */
export async function queryAreas(searchTerm, ids, type, options = {}) {
    try {
        const response = await portalAxios.get('/v1/areas', {
            isPublicRequest: true,
            params: {
                q: searchTerm,
                ids: ids,
                type: type,
            },
            cancelToken: options.cancelToken,
        });
        return response.data.data;
    } catch (err) {
        throw err;
    }
}

export async function getPEADStates() {
    const response = await portalAxios(
        `${process.env.REACT_APP_PORTAL_URL}/v1/pead-states`
    );

    return response.data?.data?.states;
}

export async function myHomeFeaturedPropertyPacks() {
    const response = await portalAxios.get('/v1/thirdparty/my-home/featured-property-packs');
    return response.data?.data;
}

export async function getMLSBySourceCode(sourceCodeName) {
    try {
        const response = await portalAxios(
            `${process.env.REACT_APP_PORTAL_URL}/v1/mls?source_code_name=${sourceCodeName}`
        );

        return response.data.data;
    } catch (err){
        throw err;
    }
}

export async function getMLSByZipcode(zipcode) {
    try {
        const response = await portalAxios(
            `${process.env.REACT_APP_PORTAL_URL}/v1/mls/from-zipcode/${zipcode}`
        );

        return response.data.data;
    } catch (err){
        throw err;
    }
}

export async function sendHomeReportOptinAffiliateNotify(leadEmail, agentId, loId) {
    const response = await portalAxios.post('/v1/send-email/home-report-optin-affiliate-notify', { leadEmail, agentId, loId });

    return response.data.data;
}

/**
 *
 * @param {String} value - Can either be a lead id or a user id if they are logged in
 */
export async function optinToMyHomeTextMessage(value, source) {
    let data;

    try {
        const response = await portalAxios.post(`/v1/leads/${value}/home-report-invite-opt-in`, { trackSource: source, });
        data = response.data.data;
    } catch (err) {
        Logger.error('optinToMyHomeTextMessage', err);
        throw err;
    }

    return data;
}

export async function getArea(areaId) {
    const response = await portalAxios.get(`/v1/areas/${areaId}`);
    return response.data.data;
}

export async function getListingsSourceDetails(source) {
    const response = await portalAxios.get(`/v1/listings-source-details/${source}`);
    return response.data.data;
}

export async function getTalkingPoints(userType, options) {
    const response = await portalAxios.get(`/v1/shareables/${userType}/talking-points`, { params: options });
    return response.data.data;
}




