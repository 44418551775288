import moment from 'moment';
import _ from 'lodash';

export function isListingNew(listpack, listing, listpackLastSeen){
    // if it's a card (not a listpack) then we don't want to display the new banner
    if(!listpack) return false;
    // if the listpack have not been seen yet  
    if(!listpackLastSeen) { 
        const { EffectiveListingDate } = listing.listing_details;
        const listingDate = moment(EffectiveListingDate);
        const fiveDaysAgo = moment()
            .subtract(5,'d');
        // if its a handselected listpack and i have see in before then all it's new
        // if it's a regular handselected listpack and i haven't seen it yet then apply the babou logic, listings with less than 5 days are new
        return listpack.handSelected ? true : fiveDaysAgo.isBefore(listingDate);
    }

    const lastSeenDate = moment(listpackLastSeen);
    if(listpack.handSelected) {
        const listingDate = moment(listing.createdAt);
        return lastSeenDate.isBefore(listingDate);
    } else {
        const { EffectiveListingDate } = listing.listing_details;
        let listingDate = EffectiveListingDate;
        if(_.isNil(listingDate)) return false;
        listingDate = moment(listingDate);
        return lastSeenDate.isBefore(listingDate);
    }
}