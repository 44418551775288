import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

const StyledTooltip = withTheme(styled((props)=> (
    <Tooltip classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }} {...props} />
))`
    & .arrow {
        color: #fff;
        font-size: 14px;
    }

    & .tooltip {
        position: relative;
        background-color: #fff;
        color: ${({ theme })=> theme.palette.text.primary};
        padding: 24px;
        font-size: 1rem;
        text-align: center;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
`);

const Arrow = styled.div`
    position: absolute;
    height: 16px;
    width: 30px;
    overflow-y: hidden;
    transform: scaleX(1.6);

    &.lr-tooltip-arrow-placement-bottom {
        top: -16px;
        left: 50%;
        margin-left: -15px;
    }

    &.lr-tooltip-arrow-placement-bottom-end {
        top: -16px;
        right: -3px;
        margin-left: -15px;
    }

    &.lr-tooltip-arrow-placement-bottom-start {
        top: -16px;
        left: 12px;
        margin-left: -15px;
    }

    &.lr-tooltip-arrow-placement-top {
        bottom: -16px;
        left: 50%;
        margin-left: -15px;
        transform: rotate(180deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-top-end {
        bottom: -16px;
        right: -3px;
        margin-left: -15px;
        transform: rotate(180deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-top-start {
        bottom: -16px;
        left: 12px;
        margin-left: -15px;
        transform: rotate(180deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-right {
        left: -23px;
        top: 50%;
        margin-top: -15px;
        transform: rotate(270deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-right-end {
        left: -23px;
        bottom: 4px;
        margin-top: -15px;
        transform: rotate(270deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-right-start {
        left: -23px;
        top: 20px;
        margin-top: -15px;
        transform: rotate(270deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-left {
        right: -23px;
        top: 50%;
        margin-top: -15px;
        transform: rotate(90deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-left-end {
        right: -23px;
        bottom: 4px;
        margin-top: -15px;
        transform: rotate(90deg) scaleX(1.6);
    }

    &.lr-tooltip-arrow-placement-left-start {
        right: -23px;
        top: 20px;
        margin-top: -15px;
        transform: rotate(90deg) scaleX(1.6);
    }

    &:after {
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
        content: '▲';
    }
`;

function LRTooltip({ title = '', placement = 'bottom', hideArrow = true, disableTouchListener = true, children, ...props }) {
    return title ? (
        <StyledTooltip
            arrow={!hideArrow}
            disableTouchListener={disableTouchListener}
            title={title}
            placement={placement}
            {...props}
            children={children}
        />
    ) : (
        children
    );
}

export default LRTooltip;
