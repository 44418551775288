export function waitForElement(selector, { tryInterval, timeout = 10000 } = {}) {
    return new Promise((resolve, reject)=> {
        const intervalId = setInterval(()=> {
            const element = document.querySelector(selector);
            if(element) {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
                resolve(element);
            }
        }, tryInterval);
        const timeoutId = setTimeout(()=> {
            clearInterval(intervalId);
            reject();
        }, timeout);
    });
}

export function scrollToElement(selector) {
    return new Promise((resolve, reject)=> {
        const element = document.querySelector(selector);
        if(element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            resolve(element);
        }
        reject();
    });
}

export function delay(timeout) {
    return new Promise((resolve)=> {
        setTimeout(()=> {
            resolve();
        }, timeout);
    });
}

export function simulateClick(selector) {
    return new Promise((resolve)=> {
        const elem = document.querySelector(selector);
        if(elem) {
            const evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            elem.dispatchEvent(evt);
        }
        resolve();
    });
}


export function addEventListener(selector, eventName, cb, { container = document } = {}) {
    function eventHandler(e) {
        if(e.target.matches(selector)) {
            cb(e);
        }
    }

    container.addEventListener(eventName, eventHandler);

    return function() {
        container.removeEventListener(eventName, eventHandler);
    };
}

export function clearShepherdOverlay() {
    const overlay = document
        .querySelector('.shepherd-modal-overlay-container');
    if(overlay) {
        overlay.style.opacity = 0;
    }
}

export function restoreShepherdOverlay() {
    const overlay = document
        .querySelector('.shepherd-modal-overlay-container');
    if(overlay) {
        overlay.style.opacity = 0.5;
    }
}

function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPhone',
        'iPod'
    ].includes(navigator.platform);
}

export function fixIosKeyboardIssue(tooltipSelector, inputSelector) {
    return {
        show: function() {
            if(!iOS()) {
                return;
            }
            function handleFocus() {
                const shepherdElement = document.querySelector(tooltipSelector);
                const overlayElement = document.querySelector('.shepherd-modal-overlay-container');
                if(shepherdElement) {
                    shepherdElement.style.display = 'none';
                }
                if(overlayElement) {
                    overlayElement.style.display = 'none';
                }
            }

            function handleBlur() {
                const shepherdElement = document.querySelector(tooltipSelector);
                const overlayElement = document.querySelector('.shepherd-modal-overlay-container');
                if(shepherdElement) {
                    shepherdElement.style.removeProperty('display');
                }
                if(overlayElement) {
                    overlayElement.style.removeProperty('display');
                }
            }

            const input = document.querySelector(inputSelector);
            if(iOS()) {
                input.addEventListener('focus', handleFocus);
                input.addEventListener('blur', handleBlur);
            }

            function removeHandlers() {
                if(iOS()) {
                    input.removeEventListener('focus', handleFocus);
                    input.removeEventListener('blur', handleBlur);
                }
            }
            this.removeHandlers = removeHandlers;
        },
        hide: function() {
            if(this.removeHandlers) {
                this.removeHandlers();
            }
        },
    };
}
