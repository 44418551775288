import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useOnboarding } from '../../store';
import { useStore } from '../../../../mobx-store';
import { delay, addEventListener, simulateClick, waitForElement } from '../../utils';
import { useHistory } from 'react-router-dom';
import Confetti from '../../../Confetti';

function createSteps(tour, { user, boardHaveHomes, isXs, onboarding }) {
    return !boardHaveHomes ? [
        {
            id: 'no-homes-dismissal',
            title: 'Notes are for homes on your board',
            text: 'It looks like you don’t have any homes on your board (in Interested, Want to see, and Want to buy). Add homes from a saved search or through the add home button.',
            beforeShowPromise() {
                return delay(200); // Wait for left drawer to close
            },
            buttons: [
                {
                    text: 'I’ll do this later',
                    action: function() {
                        onboarding.stop();
                    },
                }
            ],
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_add_note_onboarding_no_home_dismiss',
            },
            classes: 'shepherd-wide-tooltip',
        }
    ]
        : [
            {
                id: 'compare',
                title: 'Try adding a note on this home',
                text: 'Once it’s on your board, you can keep and share notes on any home. These can be seen by co-buyers and, if you like, your agent. <br><br><b>Select this home to see for yourself.</b>',
                attachTo: {
                    element: '.tour-target-board-card',
                    on: 'right',
                },
                beforeShowPromise() {
                    return delay(500); // Wait for left drawer to close
                },
                advanceOn: {
                    selector: '.tour-target-board-card',
                    event: 'click'
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_add_note_onboarding_card_click',
                },
            },
            {
                id: 'await-note-click',
                classes: 'hidden',
                attachTo: {
                    element: '.add-note-btn',
                    on: 'top',
                },
                highlightClass: 'animate-pulse-salmon',
                beforeShowPromise() {
                    tour.options.useModalOverlay = false;
                    return waitForElement('.add-note-btn');
                },
                analytics: {
                    nextEvent: 'robin_compare_onboarding_complete',
                },
            },
            {
                id: 'await-note-submit',
                classes: 'hidden',
                beforeShowPromise() {
                    tour.options.useModalOverlay = false;
                    return Promise.resolve();
                },
                analytics: {
                    nextEvent: 'robin_compare_onboarding_complete',
                },
            },
            {
                id: 'await-listing-details-close',
                classes: 'hidden',
                beforeShowPromise() {
                    tour.options.useModalOverlay = false;
                    return Promise.resolve();
                },
                analytics: {
                    nextEvent: 'robin_compare_onboarding_complete',
                },
            },
            {   
                id: 'final',
                title: 'Look - there’s your note! 💬',
                text: 'You can see how many notes have been added to any home on your board, at a glance.',
                attachTo: {
                    element: '.tour-target-board-card-draggable',
                    on: 'left',
                },
                beforeShowPromise() {
                    onboarding.preventAction = true;
                    tour.options.useModalOverlay = true;
                    return delay(200); // Wait for left drawer to close
                },
                canClickTarget: false,
                buttons: [
                    {
                        text: 'Finish tour',
                        action: ()=> {
                            onboarding.preventAction = false;
                            tour.complete();
                        }
                    }
                ],            
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [40, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_add_note_onboarding_card_click',
                },
            },
        ];
}

export const AddNoteFlowDesktop = observer(function AddNoteFlowDesktop() {
    const { UserStore, boardsStore } = useStore();
    const onboarding = useOnboarding();
    const [showConfetti, setShowConfetti] = useState(false);
    const history = useHistory();
    // const isXs = useResponsiveBreakpoint('xs');
    const { activeBoard } = boardsStore;
    const boardHaveHomes = activeBoard?.notArchivedLaneBoardCards?.length > 0;

    useEffect(()=> {
        history.replace('/boards');
    }, [history]);

    useEffect(()=> {
        const steps = createSteps(onboarding.tour, { user: UserStore.user, boardHaveHomes, onboarding });
        onboarding.addSteps(steps);
        onboarding.start();
        return function() {
            onboarding.reset();
        };
    }, [onboarding, UserStore]);

    useEffect(()=> {
        let timeout = null;
        function handleComplete() {
            setShowConfetti(true);
            UserStore.completeAddNoteOnboarding();
            simulateClick('.tour-target-leftsidebar-toggle');
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        }

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding, UserStore]);

    if(!showConfetti) {
        return null;
    }

    return <Confetti iterationCount={2}/>;
});
