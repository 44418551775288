import styled from 'styled-components';
import { Dialog, DialogContent, withTheme } from '@material-ui/core';
export const LinkButton = styled.a`
font-size: 14px;
text-decoration: underline;
`;

export const ModalContent = withTheme(styled(DialogContent)`
    padding: 30px 16px;
    ${({theme})=> theme.breakpoints.up('sm')}{
        padding: 0 !important;
    }
`);


export const Modal = withTheme(styled(Dialog)`
    & :nth-child(3){
        ${({theme})=> theme.breakpoints.up('sm')}{
            height: auto;
        }
    }
`);