import React from 'react';
import PropTypes from 'prop-types';
import { Box, GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import lodashChunk from 'lodash/chunk';
import PinnedByHeadshots from '../PinnedByHeadshots';
import GridTileActionMenu from '../GridTileActionMenu';
import { Can } from '../../Ability';

const useStyles = makeStyles((theme)=> ({
    tile: {
        borderRadius: '4px',
        cursor: 'pointer',
    },
    titleBar: {
        textAlign: 'left',
        background: 'linear-gradient(180deg, rgba(56, 56, 56, 0.0001) 0%, #1A1A1A 100%)',
    },
}));

const StyledHeadshotsContainer = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
`;

function getPinspirationGridRow({ chunk, chunkIndex }) {
    const rowChunks =
        chunkIndex % 2
            ? lodashChunk(chunk, 4) // Odd row: splits 5 urls into [[p0, p1, p2, p3], [p4]]
            : [[chunk.shift()], chunk]; // Even row: splits 5 urls into [[p0], [p1, p2, p3, p4]]

    return rowChunks;
}

function PinspirationGrid({ pinCollection = [], showPinnedByHeadshots, onUnpinClick, onTileClick, className = '' }) {
    const chunked = lodashChunk(pinCollection, 5);
    const classes = useStyles();

    const handleGridTileClick = (e, pinspiration)=> {
        if(e) e.stopPropagation();
        onTileClick(e, pinspiration);
    };

    return (
        <Box align="center" mt={[1, 3]} px={[2, 3]}>
            <GridList cellHeight={460} spacing={16} cols={2}>
                {chunked.map((chunk, chunkIndex)=>
                    getPinspirationGridRow({ chunk, chunkIndex })
                        .map((rowChunk, rowChunkIndex)=> (
                            <GridListTile
                                key={`pinspiration_grid_tile_${rowChunkIndex}`}
                                classes={{ tile: classes.tile }}
                                onClick={(e)=> {
                                    if(rowChunk.length === 1 && rowChunk[0]) {
                                        handleGridTileClick(e, rowChunk[0]);
                                    }
                                }}
                            >
                                {/* Large Image Column */}
                                {rowChunk.length === 1 && rowChunk[0] && (
                                    <img src={rowChunk[0].photo} alt={rowChunk[0].title} />
                                )}

                                {/* Large Image Column Title Bar */}
                                {rowChunk.length === 1 && rowChunk[0] && (
                                    <GridListTileBar
                                        title={rowChunk[0].title}
                                        subtitle={rowChunk[0].subtitle}
                                        classes={{ root: classes.titleBar }}
                                        actionIcon={
                                            <Can I="edit" a="Pinspiration">
                                                <GridTileActionMenu
                                                    pinspirationItem={rowChunk[0]}
                                                    onUnpinClick={onUnpinClick}
                                                />
                                            </Can>
                                        }
                                    />
                                )}

                                {rowChunk.length === 1 && rowChunk[0] && showPinnedByHeadshots && (
                                    <StyledHeadshotsContainer>
                                        <PinnedByHeadshots users={rowChunk[0].users} />
                                    </StyledHeadshotsContainer>
                                )}

                                {/* Small Image Grid Column */}
                                {rowChunk.length > 1 && (
                                    <GridList cellHeight={222} spacing={16} cols={2}>
                                        {rowChunk.map((pinspiration, i)=> (
                                            <GridListTile
                                                key={`pinspiration_grid_child_tile_${rowChunkIndex}_${i}`}
                                                classes={{ tile: classes.tile }}
                                                onClick={(e)=> handleGridTileClick(e, pinspiration)}
                                            >
                                                <img src={pinspiration.photo} alt={pinspiration.title} />

                                                {/* Small Image Column Title Bar */}
                                                <GridListTileBar
                                                    title={pinspiration.title}
                                                    subtitle={pinspiration.subtitle}
                                                    classes={{ root: classes.titleBar }}
                                                    actionIcon={
                                                        <Can I="edit" a="Pinspiration">
                                                            <GridTileActionMenu
                                                                pinspirationItem={pinspiration}
                                                                onUnpinClick={onUnpinClick}
                                                            />
                                                        </Can>
                                                    }
                                                />
                                                {showPinnedByHeadshots && (
                                                    <StyledHeadshotsContainer>
                                                        <PinnedByHeadshots users={pinspiration.users} />
                                                    </StyledHeadshotsContainer>
                                                )}
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                )}
                            </GridListTile>
                        ))
                )}
            </GridList>
        </Box>
    );
}

PinspirationGrid.propTypes = {
    pinCollection: PropTypes.array.isRequired,
    className: PropTypes.string,
};

export default React.memo(PinspirationGrid);
