import { portalApi } from 'apis';
import { useQuery } from 'react-query';

export function useArea(areaId) {
    return useQuery(
        ['area', areaId],
        function() {
            return portalApi.getArea(areaId);
        }, {
            enabled: Boolean(areaId),
        }
    );
}
