import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../mobx-store';
import { Box, Typography } from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../../hooks';
import { COLOR_STEAK_SAUCE } from '../../../constants';
import ClampLines from 'react-clamp-lines';


function LanesDescription({isSavedSearchLane, lane}) {
    const { LanesStore } = useStore();
    const isXs = useResponsiveBreakpoint('xs');
    const lines = 1;
    const buttons = false;
    const ellipsis = '...';

    let laneDescription;

    if(isSavedSearchLane) {
        laneDescription = 'Search, save filters, review agent picks, & see homes hit the market.';
    } else {
        laneDescription = LanesStore.lanes.find((storedLane)=> storedLane.id === lane?.id)?.description;
    }

    return (
        <Box display="flex" width={(isXs && !isSavedSearchLane && '350px') || 1}>
            {isXs ? (
                <>
                    {laneDescription && (
                        <Box
                            display="flex"
                            pt={isSavedSearchLane ? '24px' : '20px'}
                            pb={!isSavedSearchLane && '20px'}
                            pl={isSavedSearchLane && '24px'}
                        >
                            <Typography
                                variant="caption"
                                style={{
                                    fontSize: '12px',
                                    color: COLOR_STEAK_SAUCE
                                }}
                            >
                                {
                                    laneDescription && (
                                        <ClampLines
                                            text={laneDescription}
                                            lines={lines}
                                            ellipsis={ellipsis}
                                            buttons={buttons}
                                        />
                                    )
                                }
                            </Typography>
                        </Box>
                    )}
                </>
            ) : (
                <Box
                    mt={0.5}
                    pl={3}
                >
                    <Box>
                        <Typography
                            variant="caption"
                            style={{
                                fontSize: '10px',
                                color: COLOR_STEAK_SAUCE
                            }}
                        >
                            <ClampLines
                                text={laneDescription}
                                lines={lines}
                                ellipsis={ellipsis}
                                buttons={buttons}
                            />
                        </Typography>
                    </Box>    
                </Box>
            )}
        </Box>
    );
}

export default observer(LanesDescription);
