// eslint-disable-next-line
import Logger from 'js-logger';
import _ from 'lodash';
import { runInAction, decorate, observable, action } from 'mobx';
import { benoitApi } from '../../apis';

const logger = Logger.get('SchoolsStore');

class SchoolsStore {
    cardSchoolsData = {};

    async getSchoolsData(address) {
        if(this.cardSchoolsData[address]) {
            return this.cardSchoolsData[address];
        }

        let data;

        try {
            data = await benoitApi.getInfographicsData({
                type: 'schools',
                address: address
            });
        } catch (err) {
            logger.error('getSchoolsData', err);

            throw err;
        }

        data.schools = data.schools.map((school)=> {
            const sortOrder = school.grades
                .toString()
                .replace('-', '')
                .replace(/[A-Za-z]+/g, '0');
            school.sort_order = sortOrder;

            return school;
        });
        data.schools = _.sortBy(data.schools, 'sort_order');

        runInAction(()=> {
            this.cardSchoolsData[address] = data;
        });

        return data;
    }
}
decorate(SchoolsStore, {
    // Variables
    cardSchoolsData: observable,

    // Actions
    getSchoolsData: action
});

export default SchoolsStore;
