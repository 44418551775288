import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Box, withTheme } from '@material-ui/core';
import { ChatBubble, Close } from '@material-ui/icons';
import { ShareIcon } from '../../../../components/LRIcons';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../mobx-store';
import styled from 'styled-components';
import { useBoardCollaborations } from '../../../../hooks/useBoardCollaborations';
import { RobinLogo } from '../../../RobinLogo';

const MessageDot = withTheme(styled.i`
    background-color: ${({ theme })=> theme.palette.primary.main};
    width: 12px;
    height: 12px;
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 50%;
    border: 3px solid white;
`);

const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    position: relative;
`;
const ChatButton = styled(IconButton)`
    &:hover {
        i {
            border-color: #f6f6f6;
        }
    }
`;

export const MobileHeader = observer(function MobileHeader({
    totalListings,
    currentIndex,
    handleShowMessengerClick,
    onClose,
    cardId,
    onShareClick,
    showLogo,
}) {
    const [hasMessages, setHasMessages] = useState(false);
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const { isLoading:boardCollaborationsLoading, boardCollaborations } = useBoardCollaborations(cardId);

    useEffect(()=> {
        if(boardCollaborationsLoading) {
            return;
        }
        setHasMessages(boardCollaborations.length > 0);
    }, [boardCollaborationsLoading, boardCollaborations]);

    if(showLogo) {
        return <RobinLogo />;
    }

    return (
        <Grid container alignItems="center" justify="space-between" flexwrap="nowrap">
            <Grid item>
                <Box width="48px" height="48px">
                    {cardId && (
                        <ChatButton className="onboarding-tour-chat-bubble" onClick={handleShowMessengerClick}>
                            <IconContainer>
                                <ChatBubble />
                                {hasMessages && <MessageDot />}
                            </IconContainer>
                        </ChatButton>
                    )}
                </Box>
                {/*                 <IconButton onClick={onShareClick}>
                    <ShareIcon />
                </IconButton> */}
            </Grid>
            <Grid item>
                {currentIndex + 1} of {totalListings}
            </Grid>
            <Grid item>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Grid>
        </Grid>
    );
});
