import React from 'react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../../../mobx-store';
import Confetti from '../../../Confetti';
import { useOnboarding } from '../../store';
import {  delay, simulateClick, waitForElement, fixIosKeyboardIssue } from '../../utils';
import { useResponsiveBreakpoint } from '../../../../hooks';
import analytics from '../../../../services/analytics';

function createSteps(tour, { history, isXs }) {
    return [
        {
            id: 'search',
            title: 'Your Search starts here',
            text: isXs ?
                "At the top of your home screen, the very first section is Your Searches. Let's take a look at how those work" :
                'Search for homes from the top of this column, and save as many searches as you like based on your preferences. Saved searches are smart, so you’ll see them update when new listings hit the market! <strong>Go ahead and try searching in your city</strong>.',
            attachTo: {
                element: '.tour-target-listpack-search',
                on: isXs ? 'bottom' : 'right',
            },
            beforeShowPromise() {
                if(isXs) {
                    history.push('/boards');
                }
                return delay(400); // Wait for left drawer to close
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_search_submit',
            },
            classes: 'search-step-tooltip',
            when: {
                ...fixIosKeyboardIssue('.search-step-tooltip', '.tour-target-listpack-search input')
            }
        },
        {
            id: 'name-listpack',
            title: 'Name your search',
            text: 'Use the default name or use something more memorable - whatever you like!',
            attachTo: {
                element: '.tour-target-listpack-name',
                on: isXs ? 'bottom' : 'right',
            },
            beforeShowPromise() {
                return waitForElement('.tour-target-listpack-name');
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                }
            ],
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_search_filters_next1_click',
            },
            scrollTo: isXs ? { block: 'start', inline: 'nearest', behavior: 'smooth' } : false,
            classes: 'name-step-tooltip',
            when: {
                ...fixIosKeyboardIssue('.name-step-tooltip', '.tour-target-listpack-name input')
            }
        },
        {
            id: 'expand-search',
            title: 'You can expand the search area',
            text: 'Add any cities, zip codes, or school districts if you like. You can also create separate searches for multiple locations, later.',
            attachTo: {
                element: '.tour-target-listpack-area',
                on: isXs ? 'bottom' : 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                }
            ],
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_search_filters_next2_click',
            },
            classes: 'expand-search-step-tooltip',
            scrollTo: isXs ? { block: 'start', inline: 'nearest', behavior: 'smooth' } : false,
            when: {
                ...fixIosKeyboardIssue('.expand-search-step-tooltip', '.tour-target-listpack-area input'),
            }
        },
        {
            id: 'save-listpack',
            title: '☝️ Go ahead and save this one',
            text: 'You can edit preferences later. For now, let’s just put this search on your board.',
            attachTo: {
                element: '.tour-target-listpack-save',
                on: 'bottom',
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_saved_search_saved',
            },
        },
        {
            id: 'wait-by-listpack',
            cancelIcon: {
                enabled: false,
            },
            classes: 'hidden',
            when: {
                show: ()=> {
                    waitForElement('.tour-target-listpack-listing-card', { timeout: 20000 })
                        .then(()=> tour.next());
                }
            }
        },
        {
            id: 'highlight-listpack-listing-card',
            title: 'Add this one to your board',
            text: 'Hover your mouse over the home and add this to your Interested (you can remove it later).',
            attachTo: {
                element: '.tour-target-listpack-listing-card',
                on: isXs ? 'bottom' : 'left',
            },
            beforeShowPromise() {
                return waitForElement('.tour-target-listpack-listing-card');
            },
            highlightClass: 'pulse-move-card-button',
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
            },
        },
        {
            id: 'close-listpack',
            title: '☝️ Go ahead and close',
            attachTo: {
                element: isXs ? '.tour-target-modal-close-button-mobile' : '.tour-target-modal-close-button',
                on: 'bottom',
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            cancelIcon: {
                enabled: false,
            },
        },
        {
            id: 'highlight-listpack-card',
            title: 'Here’s your new </br>search! 🎉',
            text: 'Search for homes from the top of this column, and save as many searches as you like based on your preferences. Saved searches are smart, so you’ll see them update when new listings hit the market!',
            attachTo: {
                element: '.tour-target-my-opened-listpack-card',
                on: isXs ? 'bottom' : 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                }
            ],
            beforeShowPromise() {
                return Promise.resolve()
                    .then(()=> delay(100))
                    .then(()=> {
                        if(isXs) {
                            history.push('/boards/lane/0/your-searches');
                        }
                    })
                    .then(()=> waitForElement('.tour-target-my-opened-listpack-card'));
            },
            canClickTarget: false,
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_saved_search_next_click',
            },
            scrollTo: true,
        },
    ];
}

export const AsyncFirstRunFlow = observer(function() {
    const { LanesStore, UserStore, boardsStore } = useStore();
    const activeBoard = boardsStore.activeBoard;
    const history = useHistory();
    const [showConfetti, setShowConfetti] = useState(false);
    const isXs = useResponsiveBreakpoint('xs');

    const onboarding = useOnboarding();

    useEffect(()=> {
        const steps = createSteps(onboarding.tour, { history, isXs });
        onboarding.addSteps(steps);
        onboarding.start();
        return function() {
            onboarding.reset();
        };
    }, [onboarding, UserStore]);

    useEffect(()=> {
        let timeout = null;
        function handleComplete() {
            setShowConfetti(true);
            analytics.eventTrack('robin_async_onboarding_step_completed', { source: onboarding.source });
            simulateClick(isXs ? '.tour-target-mobile-footer-agent-toggle' : '.tour-target-leftsidebar-toggle');
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        }

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding, UserStore]);

    if(showConfetti) {
        return <Confetti iterationCount={2} />;
    }

    return null;
});
