import _ from 'lodash';
import Logger from 'js-logger';
import simpleCypher from '../simple-cypher';
import babou from 'babou-js';
import { portalApi, benoitApi } from '../../apis';
import { daysOnMarketToFilterParam } from '../../constants';
import moment from 'moment';

const logger = Logger.get('babou');

const _getCustomFilters = (listpack)=> {
    let customFilter = {};
    if(listpack?.fields?.mls_numbers?.length > 0) {
        customFilter.terms = { MlsNumber: listpack?.fields?.mls_numbers };
    }
    // only add something to the custom filters if there is a terms match
    return Object.keys(customFilter).length
        ? JSON.stringify(customFilter)
        : undefined;
};

export const getListpackListings = async (listpack, skip = 0, limit = 30, order = 'ListingDate:desc')=> {
    const result = await searchListpackListings(listpack, {
        skip,
        limit,
        order,
        includeListings: true,
    });
    const listings = _.get(result, 'page.listings') || [];

    const babouListingsById = listings.reduce((accum, listing)=> {
        const id = simpleCypher.encode(listing.LRSource, listing.id);
        accum[id] = listing;
        return accum;
    }, {});

    const listingIds = Object.keys(babouListingsById);

    let listingDetails = await benoitApi.getListingsDetailsByIds(listingIds);
    listingDetails = listingDetails.map((listing)=> {
        const babouListing = babouListingsById[listing.id];
        listing.createdAt = babouListing.createdAt;
        listing.updatedAt = babouListing.updatedAt;
        listing.EffectiveListingDate = babouListing.EffectiveListingDate;
        return listing;
    });

    return listingDetails;
};

export const mergeBabouListingInfo = function(babouListings, benoitListings) {
    const babouListingsById = babouListings.reduce((accum, listing)=> {
        const id = simpleCypher.encode(listing.LRSource, listing.id);
        accum[id] = listing;
        return accum;
    }, {});

    const listingDetails = benoitListings.map((listing)=> {
        const babouListing = babouListingsById[listing.id];
        listing.createdAt = babouListing.createdAt;
        listing.updatedAt = babouListing.updatedAt;
        listing.EffectiveListingDate = babouListing.EffectiveListingDate;
        return listing;
    });

    return listingDetails;
};

export const getListpackBabouListingsById = async (listpack, skip = 0, limit = 30, order = 'ListingDate:desc')=> {
    const result = await searchListpackListings(listpack, {
        skip,
        limit,
        order,
        includeListings: true,
    });
    const listings = _.get(result, 'page.listings') || [];

    return listings;
};

export const getListpackListingsCount = async (listpack, listingsSinceDate = false)=> {
    const result = await searchListpackListings(listpack, { includeListings: false, listingsSinceDate });
    if(!result) {
        return null;
    }
    return result.listing_stats.counts;
};

async function searchListpackListings(
    listpack,
    { skip = 0, limit = 30, order = 'ListingDate:desc', cacheTTL = 7200, includeListings = true, listingsSinceDate = false } = {}
) {
    const { areaIds } = listpack;

    try {
        // get the areas
        const areas = await portalApi.queryAreas(null, areaIds);
        const searchAreas = areas.map((marketingArea)=> {
            let search_term = marketingArea.id;
            let formatted_term = marketingArea.id;
            let search_type = marketingArea.type;

            if(marketingArea.type === 'zip') {
                search_type = 'zipcode';
            }

            if(['neighborhood', 'school'].includes(marketingArea.type)) {
                search_type = 'polygon';
                const { polygon: { coordinates: [actualPolygon] } } = marketingArea;
                formatted_term = JSON.stringify(actualPolygon);
            }


            return {
                formatted_term,
                search_term,
                search_type,
            };
        });

        const search_filter_params = _getCustomListpackBabouFilter(listpack);

        // If listpack.fields.blockIdxSources is true then
        // listhub is the only source we can search. In this case,
        // we want to ignore the source blocklist and include all zips/MLSs
        // to allow for more listings results *even if inaccurate*
        // https://listreports.atlassian.net/browse/AG-952
        const useSourceZipBlacklist = !listpack?.fields?.blockIdxSources;

        const options = {
            ...(skip && { skip: skip }),
            ...(limit && { limit: limit }),
            ...(order && { orderBy: order }),
            cache: true,
            include_listings: includeListings,
            include_listing_stats: !includeListings,
            useSourceZipBlacklist,
            search_filter_params,
            ...(listingsSinceDate && listpack.lastSeenDate && { new_listings_since_date: moment(listpack.lastSeenDate)
                .format('YYYY-MM-DD')
            }),
            custom_filter: _getCustomFilters(listpack),
        };

        logger.debug('searchListpackListings -> babou.searchListings');
        logger.debug(
            `searchListpackListings -> babou.searchListings -> request ${
                includeListings ? 'listings' : 'listings-count'
            }`,
            { areas: searchAreas, options }
        );

        try {
            const responseData = await babou.searchListings(searchAreas, options);
            
            if(includeListings) {
                logger.debug('searchListpackListings -> babou.searchListings -> listings result', {
                    numberOfListings: responseData?.data?.page?.listings?.length,
                    areas: searchAreas,
                    options,
                });
            } else {
                logger.debug('searchListpackListings -> babou.searchListings -> listings-count result', {
                    numberOfListings: responseData?.data?.listing_stats?.counts?.active_listings,
                    areas: searchAreas,
                    options,
                });
            }
            return responseData.data;
        } catch (err) {
            logger.error('searchListpackListings -> Failed to fetch listpack listings', {
                listpackId: listpack.id,
                options: options,
                areas: searchAreas,
            });
            throw err;
        }
    } catch (err) {
        logger.error(err);
    }
}

function _getCustomListpackBabouFilter(listpack) {
    const search_filter_params = [];

    if(listpack.fields.price_min) {
        search_filter_params.push({
            name: 'price_min',
            value: listpack.fields.price_min,
        });
    }

    if(listpack.fields.price_max) {
        search_filter_params.push({
            name: 'price_max',
            value: listpack.fields.price_max,
        });
    }

    if(listpack.fields.school_score_min) {
        search_filter_params.push({
            name: 'school_score_min',
            value: listpack.fields.school_score_min,
        });
    }

    if(listpack.fields.bedrooms) {
        search_filter_params.push({
            name: 'bedrooms_min',
            value: parseInt(listpack.fields.bedrooms),
        });
    }

    if(listpack.fields.bathrooms) {
        search_filter_params.push({
            name: 'bathrooms_min',
            value: parseInt(listpack.fields.bathrooms),
        });
    }

    if(listpack.fields.homesize_min) {
        search_filter_params.push({
            name: 'homesize_min',
            value: parseInt(listpack.fields.homesize_min),
        });
    }

    if(listpack.fields.homesize_max) {
        search_filter_params.push({
            name: 'homesize_max',
            value: parseInt(listpack.fields.homesize_max),
        });
    }

    search_filter_params.push({
        name: 'stories',
        value: parseInt(listpack.fields.stories),
    });

    search_filter_params.push({
        name: 'include_no_floors',
        value: listpack.fields.include_listings_with_unspecified_data || !listpack.fields.stories || false,
    });

    if(listpack.fields.lotsize_min) {
        search_filter_params.push({
            name: 'lotsize_min',
            value: parseInt(listpack.fields.lotsize_min),
        });
    }

    if(listpack.fields.lotsize_max) {
        search_filter_params.push({
            name: 'lotsize_max',
            value: parseInt(listpack.fields.lotsize_max),
        });
    }

    if(listpack.fields.year) {
        search_filter_params.push({
            name: 'lotsize_max',
            value: parseInt(listpack.fields.lotsize_max),
        });
    }

    if(listpack.fields.year_built_min) {
        search_filter_params.push({
            name: 'year_built_min',
            value: listpack.fields.year_built_min,
        });
    }

    if(listpack.fields.year_built_max) {
        search_filter_params.push({
            name: 'year_built_max',
            value: listpack.fields.year_built_max,
        });
    }

    if(listpack.fields.days_on_market) {
        search_filter_params.push(daysOnMarketToFilterParam(listpack.fields.days_on_market));
    }

    // hometype
    if(listpack.fields.hometype) {
        const values = _.pickBy(listpack.fields.hometype, function(value, key) {
            return value === true;
        });
        const keys = Object.keys(values);

        if(keys.length) {
            const homeTypeValues = keys.reduce((accum = [], current)=> {
                switch(current) {
                    case 'single_family':
                        accum.push('Single house');
                        break;
                    case 'condo_townhome':
                        accum.push('Condo');
                        accum.push('Townhome');
                        break;
                    case 'multi_family':
                        accum.push('Multi-family');
                        break;
                    case 'mobile_manufactured':
                        accum.push('Manufactured');
                        break;
                    case 'land':
                        accum.push('Land');
                        break;
                    default:
                        accum.push('Other');
                }
                return accum;
            }, []);

            search_filter_params.push({
                name: 'hometype',
                value: homeTypeValues,
            });
        }
    }

    // tags
    if(listpack.fields.tags) {
        const values = _.pickBy(listpack.fields.tags, function(value, key) {
            return value === true;
        });

        const keys = Object.keys(values);

        if(keys.length) {
            search_filter_params.push({
                name: 'tags',
                value: keys,
            });
        }
    }

    if(listpack.fields.keywords && listpack.fields.keywords.length > 0) {
        search_filter_params.push({
            name: 'keywords',
            value: listpack.fields.keywords,
        });
    }

    if(listpack.fields.blockIdxSources) {
        // if the agent has not signed the IDX license agreement
        // we need to restrict the data sources to listhub only
        // https://listreports.atlassian.net/browse/AG-859
        search_filter_params.push({
            name: 'lr_sources',
            value: ['listhub'],
        });
    }

    return search_filter_params;
}

export const getPreferredAgent = async function(agent) {
    try {
        const response = await babou.getPreferredAgent(agent);
        return response;
    } catch (err) {
        Logger.error(err);
        return null;
    }

};
