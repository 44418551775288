import React, { useContext } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { COLOR_STEAK_SAUCE, COLOR_BORDER_GREY } from '../../../constants';
import { useResponsiveBreakpoint } from '../../../hooks';
import { TalkingPointsContext } from '..';
const COPYRIGHT_YEAR = new Date().getFullYear();

export const Footer = function ({ user, style }) {
    const theme = useTheme();
    const isMobile = useResponsiveBreakpoint('xs');
    const { media } = useContext(TalkingPointsContext);
    const isPrint = media === 'print';
    const isAgent = user.type === 'agent';
    const complianceText = user?.compliance_text || user?.vendor?.compliance_text;

    return (
        <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column': 'row',
            alignItems: 'flex-start',
            padding: theme.spacing(2),
            paddingLeft: isMobile ? theme.spacing(2) : 0,
            gap: theme.spacing(2),
            marginTop: theme.spacing(6),
            borderTop: `1px solid ${COLOR_BORDER_GREY}`,
            ...style,
        }}>
            {isAgent && (
                <div style={{minWidth: '64px'}}>
                    <img
                        src="/images/lr-logo-disclaimer-grey.svg"
                        alt="ListReports"
                        style={{maxWidth: '100%'}}
                    />
                </div>
            )}
            <div style={{flex: 1}}>
                <div style={{
                    minWidth: '24px',
                    float: 'right',
                    marginLeft: theme.spacing(1),
                }}>
                    <img src="/images/equal-housing-logo.png"
                        alt="Equal Housing Opportunity"
                        style={{maxWidth: '100%'}}
                    />
                </div>

                <Typography variant="caption"
                    component="div"
                    style={{
                        color: COLOR_STEAK_SAUCE,
                        textAlign: 'justify',
                        lineHeight: '10px',
                        fontSize: '10px',
                        letterSpacing: 0,
                    }}
                >
                    {isAgent
                        ? `©ListReports ${COPYRIGHT_YEAR}, all rights reserved. This information is from third-party sources and its accuracy cannot be guaranteed.`
                        : complianceText
                    }
                </Typography>
            </div>
        </div>
    );
};
