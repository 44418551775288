import { useQuery, useMutation } from 'react-query';
import { benoitApi } from 'apis';
import { queryClient } from 'query-client';
// import queryCache from 'query-cache';
import _ from 'lodash';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';

// import { benoitApi } from 'apis';
// import { useQuery } from 'react-query';
// import { useActiveBoard } from 'mobx-store';
// import { queryClient } from 'query-client';

// const makeCacheKey = (cardId)=> ['board-card', cardId];

// export function useBoardCard(cardId) {
//     const activeBoard = useActiveBoard();
//     return useQuery(
//         makeCacheKey(cardId),
//         async function() {
//             return benoitApi.getBoardCard(cardId);
//         },
//         {
//             enabled: Boolean(cardId),
//             onSuccess: function(data) {
//                 if(!activeBoard) {
//                     return;
//                 }
//                 const boardCard = activeBoard.boardCardsById[cardId];
//                 if(boardCard) {
//                     Object.assign(boardCard, data);
//                 }
//             },
//         }
//     );
// }

// export function updateBoardCardCache(card) {
//     queryClient.setQueryData(makeCacheKey(card.id), card);
// }
// export function invalidateBoardCardCache(cardId) {
//     return queryClient.invalidateQueries(makeCacheKey(cardId));
// }

export function useGetBoardOpenHouses(boardId, filter, laneId) {
    return useQuery(
        ['board-openhouses', boardId, filter, laneId],
        async (payload)=> {
            const { queryKey } = payload;
            const [key, boardId, filter, laneId] = queryKey;
            if(!boardId) return [];
            let data;
            if(['scheduled', 'past'].includes(filter)) {
                data = await benoitApi.getBoardScheduledOpenHouses(boardId, filter, laneId);
            } else {
                data = await benoitApi.getBoardOpenHouses(boardId, filter, laneId);
            }
            return data;
        }, {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
        });
}

export function useScheduleOpenHouse() {
    const { addBoardCollaboration } = useBoardCollaborations();
    return useMutation(({ collaboration, filter, laneId })=> {
        return addBoardCollaboration(collaboration);
    }, {
        onSuccess: (data, {collaboration,  filter, laneId})=> {
            refreshQueries();
        },
        onMutate({ collaboration, filter, laneId }) {
            const cacheKey = ['board-openhouses', collaboration.boardId, filter, laneId];
            const openHouses = queryClient.getQueryData(cacheKey);
            const newOpenHouses = openHouses.map((oh)=> {
                if(oh?.openHouseInfo?.id === collaboration?.content.id
                    && oh?.openHouseInfo?.start_time_iso === collaboration?.content?.start_time_iso
                    && oh.cardId === collaboration.itemId) {
                    return {
                        ...oh,
                        isScheduled: true
                    };
                }   
                return oh;
            });
            queryClient.setQueryData(cacheKey, newOpenHouses);
        }
    });
}

export function useRemoveScheduledOpenHouse() {
    const { deleteBoardCollaboration } = useBoardCollaborations();
    
    return useMutation(({ collaboration, filter, laneId })=> {
        return deleteBoardCollaboration({ boardCollaboration: collaboration });
    },
    {
        onMutate: ({ collaboration, filter, laneId })=> {
            const cacheKey = ['board-openhouses', collaboration.boardId, filter, laneId];

            const openHouses = queryClient.getQueryData(cacheKey);
            const newOpenHouses = openHouses.map((oh)=> {
                if(oh?.boardCollaboration?.id === collaboration?.id
                    && oh?.openHouseInfo?.start_time_iso === collaboration?.content?.start_time_iso
                    && oh.cardId === collaboration.itemId) {
                    return {
                        ...oh,
                        isScheduled: false
                    };
                }
                return oh;
            });

            queryClient.setQueryData(cacheKey, newOpenHouses);
        },
        onSuccess: (data, {collaboration, filter, laneId})=> {
            refreshQueries();
        }
    });
}

export function refreshQueries() {
    queryClient.invalidateQueries('board-openhouses');
}
