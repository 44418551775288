// eslint-disable-next-line
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Box } from '@material-ui/core';
import LRButton from '../LRButton';
import { withTheme, useTheme } from '@material-ui/core/styles';

const StyledDialog = withTheme(styled((props)=> <Dialog {...props} />)`
    & .MuiDialog-container > .MuiPaper-root {
        padding: ${({ theme })=> theme.spacing(1)}px 0 ${({ theme })=> theme.spacing(2)}px;
    }
`);
const StyledDialogActions = withTheme(styled(DialogActions)`
    justify-content: center;
    margin-top: ${({ theme })=> theme.spacing(1)}px;
    margin-bottom: ${({ theme })=> theme.spacing(1)}px;
`);

function LRConfirmationDialog({ Icon, title, okButton, cancelButton, onClose, children, promoBanner, ...props }) {
    const theme = useTheme();
    okButton = _.merge({ label: 'Okay', value: true, visible: true }, okButton);
    cancelButton = _.merge({ label: 'Cancel', value: false, visible: false }, cancelButton);
    const [dialogOpen, setDialogOpen] = useState(true);
    const [closeValue, setCloseValue] = useState();

    function handleButtonClick(e, value) {
        setCloseValue(value);
        setDialogOpen(false);
    }

    function handleOnExited(e) {
        if(onClose) onClose(e, closeValue);
    }

    return (
        <StyledDialog fullWidth maxWidth="xs" open={dialogOpen} onExited={handleOnExited}>
            {(title || Icon) && (
                <DialogTitle disableTypography>
                    {Icon && (
                        <Box align="center" marginBottom="4px">
                            <Icon style={{ width: '48px', height: '48px' }} />
                        </Box>
                    )}

                    <Typography component="h1" variant="h5" align="center">
                        {title}
                    </Typography>
                </DialogTitle>
            )}

            {!promoBanner && (title || Icon) && children && (<Box width="24px" height="3px" bgcolor="#E3E3E3" margin={`0 auto ${theme.spacing(1)}px`} />)}

            <DialogContent>
                <Box align="center" fontSize="1rem">
                    {children}
                </Box>
            </DialogContent>

            <StyledDialogActions>
                {cancelButton.visible && (
                    <LRButton
                        color="inherit"
                        variant="text"
                        underline
                        onClick={_.partial(handleButtonClick, _, cancelButton.value)}
                    >
                        {cancelButton.label}
                    </LRButton>
                )}
                <LRButton color="primary" variant="contained" onClick={_.partial(handleButtonClick, _, okButton.value)}>
                    {okButton.label}
                </LRButton>
            </StyledDialogActions>
            { promoBanner && (<Box mt={3}>
                {promoBanner}
            </Box>)}
        </StyledDialog>
    );
}

LRConfirmationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    Icon: PropTypes.elementType,
    okButton: PropTypes.object,
    cancelButton: PropTypes.object,
    onClose: PropTypes.func,
    children: PropTypes.node,
    promoBanner: PropTypes.node
};

export default React.memo(LRConfirmationDialog);
