import { useState, useMemo, useCallback } from 'react';
import validate from 'validate.js';

function validateFields(formData, formStatus, constraints) {
    let newFormStatus = { ...formStatus };

    let newFormData = Object.keys(formData)
        .reduce((accum, value)=> {
            accum[value] = { ...formData[value], isValid: true };

            return accum;
        }, {});

    const fields = Object.keys(formData)
        .reduce((accum, fieldName)=> {
            accum[fieldName] = formData[fieldName].inputProps.value;

            return accum;
        }, {});

    const result = validate(fields, constraints);

    if(result) {
        newFormStatus.isValid = false;
        Object.keys(result)
            .forEach((fieldName)=> {
                newFormData = {
                    ...newFormData,
                    [fieldName]: {
                        ...newFormData[fieldName],
                        isValid: false,
                    },
                };
            });
    } else {
        newFormStatus.isValid = true;
    }

    newFormStatus.isTouched = true;
    return [newFormData, newFormStatus];
}

function buildConstraints(validationRules) {
    let constraints = {};
    for(const [key, value] of Object.entries(validationRules)) {
        constraints[key] = { presence: true };
        value.forEach((validation)=> {
            if(typeof validation === 'string' || validation instanceof String) {
                if(validation === 'required') {
                    constraints[key] = {
                        ...constraints[key],
                        length: { minimum: 1 },
                    };
                } else if(validation === 'email') {
                    constraints[key] = { ...constraints[key], email: true };
                } else if(validation === 'phone') {
                    constraints[key] = {
                        ...constraints[key],
                        length: { is: 10 },
                    };
                } else if(validation.includes('min') || validation.includes('max')) {
                    const [order, value] = validation.split(':');
                    if(order === 'max')
                        constraints[key] = {
                            ...constraints[key],
                            length: { maximum: parseInt(value) },
                        };
                    else
                        constraints[key] = {
                            ...constraints[key],
                            length: { minimum: parseInt(value) },
                        };
                } else if(validation.includes('eq')) {
                    const [, field] = validation.split(':');
                    constraints[key] = { ...constraints[key], equality: field };
                }
            } else if(typeof validation === 'object') {
                if(validation instanceof RegExp) {
                    constraints[key] = {
                        ...constraints[key],
                        format: {
                            pattern: validation,
                        },
                    };
                }
            }
        });
    }

    return constraints;
}

/**
 *
 * @function useValidationForm - Hook that given form data and validation rules, validates the fields and updates the state of the form accordingly
 * @param  { [fieldName]: string } initialData
 * @param  { [fieldName]: Array[Rule] } validationRules // Rule = 'required' | 'eq:{fieldName}' | 'phone' | 'email' | /regex/
 * @return [
 *  { isTouched: boolean, isValid: boolean },
 *  { [fieldName]: {
 *        inputProps: { name: string, value: string, onChange: Function },
 *        isTouched: boolean,
 *        isValid: boolean
 *    }
 *  }
 * ]
 *
 * @example
 * const [formStatus, formData] = useValidationForm({firstName: 'Andre', lastName: ''}, {firstName: ['required'], lastName: []});
 */
export const useValidationForm = (initialData = {}, validationRules = {})=> {
    const onChangeData = ({ target: { name, value } })=> {
        formData[name] = {
            ...formData[name],
            inputProps: { ...formData[name].inputProps, value },
            isTouched: true,
        };

        const [newFormData, newFormStatus] = validateFields(formData, formStatus, constraints);
        setFormData(newFormData);
        setFormStatus(newFormStatus);
    };

    const initialFormData = Object.keys(initialData)
        .reduce((accum, fieldName)=> {
            accum[fieldName] = {
                inputProps: {
                    name: fieldName,
                    value: initialData[fieldName],
                    onChange: onChangeData,
                },
                isTouched: false,
                isValid: true,
            };
            return accum;
        }, {});

    const constraints = useMemo(()=> buildConstraints(validationRules), [validationRules]);

    const [validatedFormData, initialFormStatus] = useMemo(
        ()=> validateFields(initialFormData, { isTouched: false, isValid: true }, constraints),
        [constraints, initialFormData]
    );

    const [formData, setFormData] = useState(validatedFormData);

    const [formStatus, setFormStatus] = useState(initialFormStatus);

    const clearForm = ()=> {
        setFormData(initialFormData);
        setFormStatus(initialFormStatus);
    };

    return [formStatus, formData, clearForm];
};

export const useValidationForm2 = (initialData = {}, validationRules = {})=> {
    const [isFormTouched, setIsFormTouched] = useState(false);
    const initialRawData = Object.keys(initialData)
        .reduce((accum, key)=> {
            return {
                ...accum,
                [key]: {
                    value: initialData[key],
                    isTouched: false,
                },
            };
        }, {});

    const [rawFormData, setRawFormData] = useState(initialRawData);
    const constraints = buildConstraints(validationRules);

    const onChange = ({ target: { name, value } })=> {
        setRawFormData({
            ...rawFormData,
            [name]: {
                value,
                isTouched: true,
            },
        });
        setIsFormTouched(true);
    };

    const clearForm = ()=> {
        setRawFormData(initialRawData);
        setIsFormTouched(false);
    };

    const keyValueFormData = Object.keys(rawFormData)
        .reduce((accum, fieldName)=> {
            return {
                ...accum,
                [fieldName]: rawFormData[fieldName].value,
            };
        }, {});

    const validationData = validate(keyValueFormData, constraints);
    const formData = Object.keys(rawFormData)
        .reduce((accum, fieldName)=> {
            const fieldData = rawFormData[fieldName];
            const isValid = validationData ? !validationData[fieldName] : true;
            return {
                ...accum,
                [fieldName]: {
                    inputProps: {
                        name: fieldName,
                        value: fieldData.value,
                        onChange: onChange,
                    },
                    isTouched: fieldData.isTouched,
                    isValid,
                },
            };
        }, {});

    const formStatus = { isTouched: isFormTouched, isValid: !validationData };

    return [formStatus, formData, clearForm];
};
