import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useResponsive } from 'hooks';

export default function SkeletonTask() {
    const theme = useTheme();
    const r = useResponsive();

    return (
        <div
            style={{
                position: 'relative',
                overflow: 'hidden',
                padding: theme.spacing(2),
                paddingRight: theme.spacing(1),
                borderRadius: '4px',
                border: `1px solid ${theme.palette.lr_colors.border_grey}`,
            }}
        >
            <div>
                <Typography style={{marginBottom: theme.spacing(1)}}>
                    <Skeleton animation="wave" variant="text" width={r({ xs: '100%', md: '80%' })} height="100%" />
                    <Skeleton animation="wave" variant="text" width={r({ xs: '100%', md: '80%' })} height="100%" />
                </Typography>
                <Typography variant="caption">
                    <Skeleton animation="wave" variant="text" width={80} height="100%" />
                </Typography>
            </div>
        </div>
    );
}