import React from 'react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import UserInfo from './UserInfo';
import SectionTitle from './../SectionTitle';
import { COLOR_GREY_LIGHT } from '../../../constants';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';

const YourAgent = ({
    buyerAgent,
    showContactAgentButton = false,
    onContactAgentClick = ()=> {},
})=> {
    const agent = buyerAgent;
    const lo = _.get(agent, 'preferred_vendor', null);
    const { UserStore } = useStore();

    return (
        <>
            <Box>
                <SectionTitle>Your agent</SectionTitle>
            </Box>
            <Box>
                <UserInfo user={agent} showContactAgentButton={showContactAgentButton} onContactAgentClick={onContactAgentClick} />
            </Box>

            {lo && lo.vendor?.company_type === 'Mortgage' && UserStore.loRobinAccess && (
                <>
                    <Box mt={4} pl={4} py={3} pr={6} bgcolor={COLOR_GREY_LIGHT} borderRadius="4px" fontSize={16}>
                        {agent.first_name} works closely with {lo.name} who can help you get pre-qualified, lock in an
                        interest rate, and answer any financing questions you might have.
                    </Box>
                    <Box mt={40 / 8}>
                        <UserInfo user={lo} />
                    </Box>{' '}
                </>
            )}
        </>
    );
};

export default observer(YourAgent);
