import { Box, Grid, IconButton, CircularProgress } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { TrashIcon } from '../../../../components/LRIcons';
import LRButton from '../../../LRButton';
import MoveToButton from '../../MoveToButton';
import { Can } from '../../../Ability';

const TrashButton = styled(IconButton)`
    padding: 6px;
`;
const FooterGrid = styled(Grid)`
    height: 100%;
`;
const ButtonLabel = styled.div`
    font-size: 12px;
`;

export function LoggedIn({
    cardId,
    laneId,
    onMoveToChange,
    onArchiveClick,
    isNextBackDisabled,
    isNextDisabled,
    onNextClick,
    onPreviousClick,
    currentIndex,
    totalListings,
    cardArchived,
}){
    const buttonsStyle = {
        height: '100%',
        padding:0
    };
    return (
        <Box height="100%">
            <FooterGrid container alignItems="center" wrap="nowrap" justify="space-between">
                <FooterGrid item>
                    <LRButton onClick={onPreviousClick} disabled={isNextBackDisabled || currentIndex === 0} style={buttonsStyle} >
                        <div>
                            <ArrowBack />
                            <ButtonLabel>Prev</ButtonLabel>
                        </div>
                    </LRButton>
                </FooterGrid>
                <FooterGrid item>
                    <Box height={1} display="flex" alignItems="center">
                        <Can I="edit" a="BoardCard">
                            <MoveToButton laneId={laneId} onMoveToChange={onMoveToChange} archived={cardArchived} style={{height: '100%'}} />
                            {cardId && !cardArchived && (
                                <Box ml="19px" >
                                    <TrashButton onClick={onArchiveClick}>
                                        <TrashIcon />
                                    </TrashButton>
                                </Box>
                            )}
                        </Can>
                    </Box>
                </FooterGrid>
                <FooterGrid item>
                    <LRButton 
                        onClick={onNextClick}
                        disabled={isNextDisabled || isNextBackDisabled || currentIndex === totalListings - 1}
                        style={buttonsStyle}>
                        {
                            isNextDisabled && currentIndex < totalListings - 1 ? 
                                (  
                                    <CircularProgress color="secondary" size={24} />   
                                )
                                : (
                                    <div>
                                        <ArrowForward />
                                        <ButtonLabel>Next</ButtonLabel>
                                    </div>
                                )
                        }
                    </LRButton>
                </FooterGrid>
            </FooterGrid>
        </Box>
    );
}