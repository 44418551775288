import { AppBar, Button, Grid, Toolbar, Typography, useTheme } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { Can } from 'components/Ability';
import React from 'react';
import { ArchiveCardButton } from './archive-card-button';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { MoveCardOrListingButton } from 'components/MoveCardOrListingButton';

export function Footer({ card, listing, index, total, onPrevious, onNext, showNavigation, trackEventData }) {
    const theme = useTheme();
    const location = useLocation();    
    const isFromHomeTour = matchPath(location?.pathname, {
        path: '/boards/scheduled-tours/',
        exact: false,
        strict: true
    });
    
    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={0}
            style={{
                borderTop: `1px solid ${theme.palette.lr_colors.border_grey}`,
                height: 64,
            }}
        >
            <Toolbar disableGutters>
                <Grid
                    container
                    alignItems="center"
                    style={{
                        height: 64,
                    }}
                >
                    {/* Previous Button */}
                    <Grid
                        item
                        xs={2}
                    >
                        <Button
                            onClick={onPrevious}
                            style={{ textTransform: 'none' }}
                            disabled={index < 0 || !total || index === 0}
                        >
                            <span style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ArrowBack />
                                <Typography variant="caption">Prev</Typography>
                            </span>
                        </Button>
                    </Grid>

                    <Grid
                        item
                        container
                        justify="center"
                        alignItems="center"
                        xs={8}
                        spacing={{xs: 0, sm: 8}} 
                    >
                        <Can I="edit" a="BoardCard">
                            <Grid item>
                                <MoveCardOrListingButton boardCard={card} listing={listing} />
                            </Grid>
                        </Can>

                        <Can I="edit" a="BoardCard">
                            <Grid item>
                                {!card?.archived && !isFromHomeTour && <ArchiveCardButton card={card} trackEventData={trackEventData}/>}
                            </Grid>
                        </Can>
                    </Grid>


                    {/* Next Button */}
                    <Grid
                        item
                        xs={2}
                    >
                        {showNavigation && (
                            <Button
                                onClick={onNext}
                                style={{ textTransform: 'none' }}
                                disabled={index < 0 || !total || index === total - 1}
                            >
                                <span style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <ArrowForward />
                                    <Typography variant="caption">Next</Typography>
                                </span>
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
