// eslint-disable-next-line
import Logger from 'js-logger';
import { flow, decorate, observable, action } from 'mobx';
import { portalApi } from '../../apis';

export class ShareablesStore {
    user = null;

    loadingUser = false;
    loadedUser = false;
    checkingPremiumShareablesSubscription = false;
    checkedPremiumShareablesSubscription = false;
    hasPremiumShareablesSubscription = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    getUser = flow(function*(userId) {
        if(!this.user || this.user.id !== userId) {
            this.loadingUser = true;

            this.user = yield portalApi.getAffiliateUser(userId);
            //TODO fetch the subscription status
            /*             const subscriptionType = 'Rate Farm';
            const subscriptionStatus = yield portalApi.getSubscriptionStatus(userId, subscriptionType);
            this.activeSubscription = subscriptionStatus.active; */
        } else {
            return;
        }

        this.loadedUser = true;
        this.loadingUser = false;
    });

    checkPremiumShareablesSubscription = flow(function*(userId) {
        this.checkingPremiumShareablesSubscription = true;
        const result = yield portalApi.checkPremiumShareablesSubscription(userId);
        this.hasPremiumShareablesSubscription = result.active;
        this.checkedPremiumShareablesSubscription = true;
        this.checkingPremiumShareablesSubscription = false;
    });

    onLeadCreated = flow(function*(lead, message, shareableSlug, shareableType) {
        let activityData = {
            name: 'myleads_from_robin_shareables',
            type: 'lead',
            userId: this.user.id,
            meta: {
                lead_name: lead.applicant.name,
                lead_email: lead.applicant.email,
                lead_phone: lead.applicant.phone,
                leadId: lead.id,
                message,
                shareable_slug: shareableSlug,
                shareable_type: shareableType,
            },
        };

        yield portalApi.createActivity(activityData);
        yield portalApi.sendPremiumShareablesEmail(this.user, lead, message);
    });
}

decorate(ShareablesStore, {
    user: observable,
    loadingUser: observable,
    loadedUser: observable,
    checkingPremiumShareablesSubscription: observable,
    checkedPremiumShareablesSubscription: observable,
    hasPremiumShareablesSubscription: observable,
    getUser: action,
    onLeadSubmit: action,
});

export default new ShareablesStore();
