import React from 'react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { Box, useTheme, Hidden, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import analytics from '../../services/analytics';
import LRButton from '../../components/LRButton';
import { RobinLogo } from '../../components/RobinLogo';
import {buttonStyles,  buttonContentStyles,  containerStyles, logoStyles,  taglineStyles, ImgInfoStyles  } from './styles';
import {AUTH_ONBOARDING_VIDEO} from '../../constants/routes';


export function AutoInfo({
    trackSource
}) {
    const theme = useTheme();
    const classes = containerStyles();
    const logoClasses = logoStyles();
    const taglineClasses = taglineStyles();
    const buttonClasses = buttonStyles();
    const buttonContentClasses= buttonContentStyles();
    const ImgInfoClasses= ImgInfoStyles();
    const location = useLocation();
    const history = useHistory();
    const pathname = location.pathname.replace(/\/$/, '');

    function handleBtn() {
        analytics.eventTrack('robin_entry_watch_video_button_click', {
            source: trackSource
        });
        analytics.eventTrack('robin_login_signup_promo_video_watch_click', {
            Source: trackSource === 'signup' ? 'signup_page' : 'login_page'
        });
        history.push(`${pathname}/${AUTH_ONBOARDING_VIDEO}`);
    };

    function handleImg() {

        analytics.eventTrack('robin_entry_image_click', {
            source: trackSource
        });
        history.push(`${pathname}/${AUTH_ONBOARDING_VIDEO}`);
    };

    return (
        <Box
            className={classes.root}
            height={{xs: '211px', md:'100%'}}
            width={['100%', '100%', 'auto']}
            flex={1}
            bgcolor={theme.palette.lr_colors.grey_light}
            display="flex"
            position="relative"
            overflow="hidden"
            alignItems="center"
        >
            <Box display="flex" alignItems="start" flexDirection="row" color="white" width="100%"  justifyContent="space-between">
                <Box paddingTop={{xs: 0, md:"10%"}} flexDirection="column" display="flex" alignItems={['center', 'center', 'start']} width={['100%', '100%', '50%']}>
                    <Box className={logoClasses.root}>
                        <RobinLogo width={{xs: 132, md:272}} color={theme.palette.lr_colors.salmon} />
                    </Box>

                    <Typography
                        className={taglineClasses.root}
                        variant="h4"
                        style={{
                            color: theme.palette.lr_colors.grade,
                        }}
                    >
                        Search, organize, and own your home buying process together.
                    </Typography>

                    <Box  className={buttonContentClasses.root}>
                        <LRButton
                            onClick={handleBtn}
                            className={buttonClasses.root}
                            style={{
                                backgroundColor: theme.palette.lr_colors.salmon,
                                color: theme.palette.lr_colors.crest_white,
                            }}
                        >
                            <PlayCircleFilledIcon style={{marginRight: theme.spacing(2)}}/>
                            Watch video
                        </LRButton>
                    </Box>
                </Box>
                <Hidden smDown>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        width="50%"
                        style={{
                            height: '100vh',
                            maxHeight: 744,
                            maxWidth: 552,
                            paddingBottom: theme.spacing(2),
                            paddingTop: theme.spacing(2),
                            paddingLeft: theme.spacing(1),
                        }}
                    >
                        <Box onClick={handleImg} className={ImgInfoClasses.root} />
                    </Box>
                </Hidden>
            </Box>
        </Box>
    );
}

AutoInfo.propTypes = {
    trackSource: PropTypes.string.isRequired,
};
