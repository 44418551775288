/**
 * This returns if the user is using an I-App Browser like FB or IG when you click a link instead
 * of a native browser on the device.
 *
 * @param {Object} userAgent 
 * @returns {Boolean}
 */
export function useIsInAppBrowser(userAgent) {
    const RULES = [
        'WebView',
        '(iPhone|iPod|iPad)(?!.*Safari\/)',
        'Android.*(wv|\.0\.0\.0)',
    ];
    const ua = userAgent || navigator.userAgent || navigator.vendor || window.opera;
    const regex = new RegExp(`(${RULES.join('|')})`, 'ig');

    return Boolean(ua.match(regex));
}
