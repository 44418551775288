import React from 'react';
import { SvgIcon } from '@material-ui/core';

function ActionShareIcon(props) {
    return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.43542 2.16182C8.33258 2.07294 8.20903 2.02067 8.08184 2.00503C8.04085 1.99996 7.99967 1.99875 7.95884 2.00128C7.80219 2.01091 7.64829 2.07558 7.52859 2.19528L7.52704 2.19684L4.19526 5.52861C3.93491 5.78896 3.93491 6.21107 4.19526 6.47142C4.45561 6.73177 4.87772 6.73177 5.13807 6.47142L7.33333 4.27616V10C7.33333 10.3682 7.63181 10.6667 8 10.6667C8.36819 10.6667 8.66667 10.3682 8.66667 10V4.27616L10.8619 6.47142C11.1223 6.73177 11.5444 6.73177 11.8047 6.47142C12.0651 6.21107 12.0651 5.78896 11.8047 5.52861L8.47491 2.19879C8.46228 2.18597 8.44911 2.17363 8.43542 2.16182ZM3.33333 8.66668L3.33333 10L3.33333 12C3.33333 12.3667 3.63333 12.6667 4 12.6667L8 12.6667L12 12.6667C12.3667 12.6667 12.6667 12.3667 12.6667 12V10L12.6667 8.66668C12.6667 8.30002 12.9667 8.00002 13.3333 8.00002C13.7 8.00002 14 8.30002 14 8.66668L14 10V12.6533C14 13.3867 13.4 13.9867 12.6667 13.9867H8H3.33333C2.6 13.9867 2 13.3867 2 12.6533V10V8.66668C2 8.30002 2.3 8.00002 2.66667 8.00002C3.03333 8.00002 3.33333 8.30002 3.33333 8.66668Z" />
        </SvgIcon>
    );
}

export default ActionShareIcon;

