import moment from 'moment';

function dom(str) {
    if(!str) return str;

    var a = moment();
    var b = moment(str);

    return a.diff(b, 'days');
}

export default dom;
