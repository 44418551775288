import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Dialog, Box, Grid, CircularProgress, useTheme, Slide } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import { Redirect } from 'react-router-dom';
import { useResponsiveBreakpoint } from '../../hooks';
import LRModal from '../../components/LRModal';
import LRButton from '../../components/LRButton';
import CompareNow from '../../components/CompareNow';
import { useOnboarding } from '../../components/Onboarding';
import _ from 'lodash';

const StyledDialog = styled(({ color, ...other })=> (
    <Dialog classes={{ paperWidthSm: 'paperWidthSm-compare-now' }} {...other} />
))`
    & .paperWidthSm-compare-now {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        overflow: visible;
    }
`;

const StyledLRModal = withTheme(styled(LRModal)`
    max-width: 450px;
    max-height: 245px;
    margin: auto auto;
`);

const CompareErrorModal = ({ onClose })=> {
    return (
        <StyledLRModal disableBackdropClick={true} onClose={onClose}>
            <Box px={4} pt={4}>
                <Box align="center">
                    <Box fontSize="h5.fontSize">Compare homes</Box>
                </Box>

                <Box my={2} mx="auto" borderTop={3} borderColor="grey.300" maxWidth={24} />

                <Box align="center">
                    <Box>
                        You'll need to have at least 2 homes on your board to use the comparison tool. Move some homes
                        from your saved searches to get started.
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center" mt={2}>
                    <Box width="50%" pl={1}>
                        <LRButton fullWidth color="primary" component="span" variant="contained" onClick={onClose}>
                            Ok
                        </LRButton>
                    </Box>
                </Box>
            </Box>
        </StyledLRModal>
    );
};

const CompareNowModal = observer(({ open: isOpen = false, onClose })=> {
    const onboarding = useOnboarding();
    const { UserStore, boardsStore, listingsStore, LanesStore } = useStore();
    const { activeBoard: board } = boardsStore;
    const isXs = useResponsiveBreakpoint('xs');
    const theme = useTheme();
    const [listings, setListings] = useState(null);
    const [cards, setCards] = useState(null);
    const [cardsByCategories, setCardsByCategories] = useState(null);

    const categories = useMemo(()=> {
        const formatLane = (lane)=> ({ index: lane.id, label: lane.name });
        return _.sortBy(_.map(LanesStore.lanes, formatLane), 'index');
    }, [LanesStore.lanes]);

    useEffect(()=> {
        async function fetchListings() {
            if(boardsStore.loaded) {
                const toFetch = board.notArchivedBoardCards
                    .filter((boardCard)=> !boardCard.inHandselectedSearch)
                    .map((card)=> card.content.encoded_id);
                const listings = await listingsStore.fetchListings(toFetch);
                const cards = _.reject(board.notArchivedBoardCards, 'inHandselectedSearch');
                const filteredCards = _.groupBy(cards, 'laneId');
                _.each(categories, (category)=> {
                    if(!(category.index in filteredCards)) {
                        filteredCards[category.index] = [];
                    }
                });
                setCards(cards);
                setCardsByCategories(filteredCards);
                setListings(listings);
            }
        }
        fetchListings();
    }, [board, boardsStore.loaded, listingsStore]);

    const canCompare = useMemo(()=> {
        return listings ? cards.length < 2 : false;
    }, [cards, listings]);

    const handleOnClose = ()=> {
        if(onboarding.isCompareOnboarding) {
            onboarding.next();
        }
        if(!onboarding.hasTour && !UserStore.compareOnboardingCompleted) {
            UserStore.completeCompareOnboarding();
        }
        onClose();
    };

    if(canCompare) {
        if(onboarding.isCompareOnboarding) {
            onboarding.next();
        }
        if(isOpen && !onboarding.isCompareOnboarding) {
            return <CompareErrorModal onClose={handleOnClose} />;
        } else {
            return <Redirect to="/boards" />;
        }
    }

    return (
        <StyledDialog
            open={isOpen}
            hideBackdrop
            PaperProps={{ elevation: 0 }}
            fullScreen
            maxWidth="100%"
            onClose={handleOnClose}
            style={{ marginTop: !isXs && 64, zIndex: isXs ? 1300 : 1299 }}
            TransitionComponent={Slide}
            TransitionProps={{ direction: 'up' }}
        >
            {listings && <CompareNow categories={categories} cardsByCategories={cardsByCategories} onClose={onClose} />}
            {!listings && (
                <Grid container justify="center" style={{ padding: theme.spacing(3) }}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
        </StyledDialog>
    );
});

export default CompareNowModal;
