import React from 'react';
import { useOnboarding } from '../../store';
import { useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { waitForElement, delay, simulateClick, addEventListener, scrollToElement } from '../../utils';
import Confetti from '../../../Confetti';
import { useStore, useActiveBoard } from '../../../../mobx-store';
import { reaction } from 'mobx';
import analytics from '../../../../services/analytics';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useMixpanelEvents } from './utils';
import { LoggedInWrapperContext } from 'components/LoggedInWrapper';
import { useBoardListpacks } from 'hooks/useBoardListpacks';
import Promise from 'bluebird';

function createSteps(tour, { history, user, userRole, listpacks, setShowOverlay }) {

    function trackSkipEvent() {
        analytics.eventTrack('robin_onboarding_add_home_from_saved_search_skipped', {
            source: 'first_run_onboarding'
        });
    }

    function dismissEvent() {
        analytics.eventTrack('robin_onboarding_edit_saved_search_guide_proceed', {
            source: 'first_run_onboarding'
        });
        tour.show('highlight-listpack-listing-card', true);
    }
    
    const filteredListpacks = listpacks.filter((listpack)=> listpack.affiliateId && !listpack.handSelected);

    const commonSteps = [
        {
            id: 'edit-option-highlight',
            title: 'You can edit your search',
            text: 'With Robin, you can create multiple saved searches with different preferences, and edit those preferences anytime.',
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.tour-fre-edit-highlight', { timeout: 40000 });
            },
            attachTo: {
                element:'.tour-fre-edit-highlight',
                on: 'bottom'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                    const overlay = document.querySelector('.shepherd-modal-overlay-container');
                    overlay.addEventListener('click', dismissEvent);
                },
                hide: ()=> {
                    const overlay = document.querySelector('.shepherd-modal-overlay-container');
                    overlay.removeEventListener('click', dismissEvent);
                }
            }
        },
        {
            id: 'edit-optional-step',
            attachTo: {
                element:'.lane-modal-dialog-content',
                on: 'right'
            },
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.lane-modal-dialog-content', { timeout: 1000 })
                    .then(()=> tour.options.useModalOverlay = false)
                    .catch((err)=> {
                        tour.options.useModalOverlay = true;
                        tour.next();
                    });
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                },
                hide: ()=> {
                    setShowOverlay(false);
                }
            },
            classes: 'hidden',
        },
        {
            id: 'highlight-listpack-listing-card',
            title: 'Try adding this home to your board',
            text: 'Hover your mouse over the home and add it to any section of your board (you can remove it later). ',
            attachTo: {
                element: '.tour-target-listpack-listing-card',
                on: 'left',
            },
            beforeShowPromise() {
                setShowOverlay(true);
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-target-listpack-listing-card', { timeout: 40000 });
            },
            highlightClass: 'pulse-move-card-button',
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: function() {
                    setShowOverlay(false);
                    const overlay = document.getElementsByClassName('shepherd-modal-overlay-container')[0];
                    overlay.addEventListener('click', ()=> {
                        trackSkipEvent();
                        tour.show('free-step', true);
                    });
                },
            }
        },
        {
            id: 'optional-rpp-view',
            attachTo: {
                element:'.tour-listpack-listing-detail',
                on: 'right'
            },
            beforeShowPromise() {
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-listpack-listing-detail', { timeout: 2000 })
                    .then(()=> tour.options.useModalOverlay = false);
            },  
            classes: 'hidden'
        },
        {
            id: 'free-step',
            beforeShowPromise() {
                tour.options.useModalOverlay = false;
                return delay(400);
            },
            advanceOn: {
                selector: '.tour-target-modal-close-button',
                event: 'click',
            },
            classes: 'hidden'
        },
    ];

    const flowMapper = {
        preExistingListpackFlow: [
            {
                id: 'open-listpack',
                classes: 'hidden',
                beforeShowPromise: function() {
                    const listpack = filteredListpacks[0];
                    history.push(`/boards/listpack/${listpack.id}/listings`);
                    tour.next();
                }
            },
            {
                id: 'introduce-listpacks',
                title: `${user?.affiliate?.first_name} created this saved search for you ❤️`,
                text: 'Saved searches in <b>Robin</b> are smart, showing you the latest homes matching your preferences as soon as they hit the market. This one was created especially for you!',
                attachTo: {
                    element: '.tour-target-listpack-name',
                    on: 'bottom-start'
                },
                beforeShowPromise() {
                    return waitForElement('.tour-target-listpack-name');
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [12, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_onboarding_agent_made_saved_search_dialog_any_click',
                },
                
                advanceOn: {
                    event: 'click',
                    selector: '.shepherd-modal-overlay-container',
                },
                classes: 'shepherd-left-pinned'
            },
            ...commonSteps
        ],
        newListpackFlow: [
            {
                id: 'search',
                title: 'Your Search starts here',
                text: 'Search for homes from the top of this column, and save as many searches as you like based on your preferences. Saved searches are smart, so you’ll see them update when new listings hit the market! <strong>Go ahead and try searching in your city</strong>.',
                attachTo: {
                    element: '.tour-target-listpack-search',
                    on: 'right',
                },
                beforeShowPromise() {
                    // tour.options.useModalOverlay = true;
                    return delay(400); // Wait for left drawer to close
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_onboarding_start_first_search_from_board_required',
                },
            },
            {
                id: 'listpack-area-filters',
                title: 'You can expand your search area and filters',
                text: 'Add any cities, zip codes, or school districts if you like. You can also create separate searches for multiple locations, later.',
                attachTo: {
                    element: '.tour-listpack-search-navbar',
                    on: 'bottom-start',
                },
                beforeShowPromise() {
                    return Promise.resolve(waitForElement('.tour-listpack-search-navbar'))
                        /**
                         Need to make sure animations of modal have finished or
                         else it will not show up.
                         */
                        .delay(500);
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [12, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_onboarding_new_general_search_dialog_any_click',
                },
                // advanceOn: {
                //     event: 'click',
                //     selector: '.shepherd-modal-overlay-container',
                // },
                when: {
                    show: ()=> {
                        document.querySelector('.shepherd-modal-overlay-container').addEventListener('click', ()=> {
                            simulateClick('.tour-listpack-search-navbar-disabled-actions');
                        });
                    }
                },
                classes: 'shepherd-left-pinned'
            },
            {
                id: 'optional-expand-search',
                title: 'Expand the search area',
                text: 'No homes matching your criteria are on the market in this area. Try adding nearby zipcodes or cities.',
                attachTo: {
                    element: '.tour-expand-search-step',
                    on: 'right',
                },
                beforeShowPromise() {
                    return delay(500);
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [30, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                showOn: function() {
                    return false;
                }
            },
            {
                id: 'search-cta',
                title: 'You can save a search ☝️',
                text: 'Saved searches in Robin are smart, showing you the latest homes matching your preferences as soon as they hit the market. <strong>Go ahead and save this one to see.</strong>',
                attachTo: {
                    element: '.tour-save-listpack-search',
                    on: 'bottom-end',
                },
                beforeShowPromise() {
                    return waitForElement('.tour-save-listpack-search');
                },
                highlightClass: 'pulsing-button',
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_onboarding_search_area_guide_save_search_CTA',
                },
                // advanceOn: {
                //     event: 'click',
                //     selector: '.tour-save-listpack-search',
                // },
            },
            {
                id: 'save-search-modal',
                attachTo: {
                    element: '.tour-save-search-modal',
                    on: 'bottom',
                },
                beforeShowPromise() {   
                    return waitForElement('.tour-save-search-modal');
                }, 
                modalOverlayOpeningRadius: 4,
                analytics: {
                    nextEvent: 'robin_onboarding_new_search_saved',
                },
                classes: 'hidden',
                // advanceOn: {
                //     event: 'click',
                //     selector: '.tour-saved-search',
                // },
            },
            ...commonSteps
        ]
    };

    return filteredListpacks.length ? flowMapper.preExistingListpackFlow : flowMapper.newListpackFlow;
}

export const FirstRunFlowDesktop = observer(function FirstRunFlowDesktop() {
    const { UserStore } = useStore();
    const onboarding = useOnboarding();
    const [showConfetti, setShowConfetti] = useState(false);
    const history = useHistory();
    const { onOpenLeftSidebar } = useContext(LoggedInWrapperContext);
    const [showOverlay, setShowOverlay] = useState();
    const { listpacks } = useBoardListpacks();

    useMixpanelEvents();

    useEffect(()=> {
        history.replace('/boards');
    }, [history]);

    useEffect(()=> {
        const steps = createSteps(onboarding.tour, { history, user: UserStore.user, userRole: UserStore.usersBoardRole, listpacks, setShowOverlay });
        onboarding.addSteps(steps);
        onboarding.start();
        return function() {
            onboarding.reset();
        };
    }, [onboarding, UserStore]);

    useEffect(()=> {
        let timeout = null;
        function handleComplete() {
            setShowConfetti(true);
            const onboardingCompletedInfo = { movedCard: onboarding?.userMovedCard };
            UserStore.completeAsyncFirstRunOnboarding(onboardingCompletedInfo);
            onOpenLeftSidebar(true);
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        }

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding, UserStore]);

    // if(!showConfetti) {
    //     return null;
    // }

    return (
        <>
            {
                showOverlay && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 9999, // HACK: guarantee that this is above anything else, material ui have some weird super high zindexes like > 1200 and sheperd element is 9999 so i had to use an even higher number 
                            background: 'rgba(0,0,0, 0.5)'
                        }}
                    ></div>
                )
            }
            { showConfetti && (
                <Confetti iterationCount={2}/>
            )}
            
        </>
    );
});
