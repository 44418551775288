import { Box, ButtonGroup, Menu, MenuItem, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import LRButton from '../../../components/LRButton';
import { useMenu, useResponsiveBreakpoint } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import { LANES_NAMES } from '../../../constants';

const StyledButtonGroup = styled(ButtonGroup)`
    box-shadow: none;

    & .MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
        border-right-color: #fff;
    }
`;

function MoveToButton({ laneId, onMoveToChange, archived, ...props }) {
    const { LanesStore } = useStore();
    const [loading, setLoading] = useState(false);
    const { lanes } = LanesStore;
    const [moveToMenuProps, handleMoveToOpenMenuClick, handleMoveToCloseMenu] = useMenu();

    // If currentLaneIndex is -1, means the nextLane will be the first lane. This is used for listpack listing
    const currentLaneIndex = laneId ? _.findIndex(lanes, { id: laneId }) : -1;
    const currentLane = lanes[currentLaneIndex];
    const isMobile = useResponsiveBreakpoint('xs');
    let moveToNextLane = lanes[Math.min(lanes.length - 1, currentLaneIndex + 1)] || 1;

    if(moveToNextLane.id === laneId) {
        moveToNextLane = lanes[1];
    }
    const isMakeAnOffer = currentLane && currentLane.name === LANES_NAMES['buy'];

    async function handleClick(nextLane, makeAnOffer){
        setLoading(true);
        try {
            await onMoveToChange(nextLane, makeAnOffer);
            setLoading(false);
        } catch (err){
            setLoading(false);
        }
    }
    const py = isMobile ? '0': '8px';
    const px = '12px';
    const buttonStyle = {
        padding: `${py} ${px}`,
    };

    const optionsButtonStyle = {
        maxWidth: isMobile ? '48px': '36px',
    };

    return (
        <>
            <StyledButtonGroup variant="contained" color="primary" {...props} >
                <LRButton color="primary" disabled={loading} onClick={()=> {
                    handleClick(moveToNextLane.id, isMakeAnOffer);
                }} style={buttonStyle}>
                    <Box display="flex" flexDirection={['column', 'row']} alignItems="flex-start" mt={0}>
                        {(!isMakeAnOffer || archived) && (
                            <>
                                <Box component="span" lineHeight={1} whiteSpace="nowrap">
                                    Move to:
                                </Box>
                                <Box component="span" fontSize={[16, 14]} ml={[0, 1]} lineHeight={1} whiteSpace="nowrap">
                                    {moveToNextLane.name}
                                </Box>
                            </>
                        )}
                        {isMakeAnOffer && !archived && (<Box component="span">{LANES_NAMES['buy']}</Box>)}
                    </Box>
                </LRButton>
                {(!isMakeAnOffer || archived) && (
                    <LRButton color="primary" style={optionsButtonStyle} onClick={handleMoveToOpenMenuClick} disabled={loading} >
                        <KeyboardArrowDown />
                    </LRButton>
                )}
            </StyledButtonGroup>

            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                placement="bottom-end"
                {...moveToMenuProps}
            >
                {!archived ? (
                    lanes
                        .filter((lane)=> !~[moveToNextLane.id, laneId].indexOf(lane.id))
                        .map((lane)=> (
                            <MenuItem
                                key={lane.id}
                                onClick={async (e)=> {
                                    handleMoveToCloseMenu(e);
                                    handleClick(lane.id, isMakeAnOffer && !archived);
                                }}
                            >
                                <Typography variant="body2">Move to {lane.name}</Typography>
                            </MenuItem>
                        ))
                ) : (
                    lanes
                        .filter((lane)=> lane.id !== moveToNextLane.id)
                        .map((lane)=> (
                            <MenuItem
                                key={lane.id}
                                onClick={async (e)=> {
                                    handleMoveToCloseMenu(e);
                                    handleClick(lane.id, false);
                                }}
                            >
                                <Typography variant="body2">Move to {lane.name}</Typography>
                            </MenuItem>
                        ))
                )}
            </Menu>
        </>
    );
}

MoveToButton.propTypes = {
    laneId: PropTypes.string,
    onMoveToChange: PropTypes.func.isRequired
};

export default React.memo(MoveToButton);
