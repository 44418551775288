// eslint-disable-next-line
import Logger from 'js-logger';
import Promise from 'bluebird';
import _ from 'lodash';
import { observable, action, computed, decorate } from 'mobx';
import { benoitApi } from '../../apis';
import { LANES, SORT_OPTIONS } from '../../constants';
import shortid from 'shortid';
class LanesStore {
    lanes = LANES;;
    // listings = [];
    loadingListings = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    get activeBoard() {
        return this.rootStore.boardsStore.activeBoard;
    }

    get listings() {
        if(this.activeBoard) {
            return this.rootStore.boardsStore.activeBoard.boardCards;
        } else {
            return [];
        }
    }

    // Computed Properties

    get lanesById() {
        return this.lanes.reduce((accum, lane)=> {
            return {
                ...accum,
                [lane.id]: lane,
            };
        }, {});
    }
    get listingsByLaneId() {
        const listingsByLaneId = _.groupBy(this.listings, 'laneId');
        return this.lanes.reduce((accum, lane)=> {
            return {
                ...accum,
                [lane.id]: listingsByLaneId[lane.id] || []
            };
        }, {});
    }
    get listingsById() {
        return this.listings.reduce((accum, listing)=> {
            return {
                ...accum,
                [listing.id]: listing
            };
        }, {});
    }

    get wantToSeeListings() {
        return this.listings.filter((listing)=> listing.laneId === '3');
    }

    get reviewListings() {
        return this.listings.filter((listing)=> listing.laneId === '1');
    }

    get maybeListings() {
        return this.listings.filter((listing)=> listing.laneId === '1');
    }

    get favoriteListings() {
        return this.listings.filter((listing)=> listing.laneId === '2');
    }

    get listingsForOffer() {
        return this.listings.filter((listing)=> listing.laneId === '3');
    }

    get sortByLaneId() {
        return this.lanes.reduce((accum, lane)=> {
            return {
                ...accum,
                [lane.id]: this.getSelectedLaneSort(lane.id)
            };
        }, {});
    }

    /**
     * This will return faux listing cards with the
     * listing attached to it.
     *
     * @param {Array} listingIds - The listings ids you want to fetch listings for
     * @param {Object} [options]
     * @param {Object} [options.cancelToken] - An axios cancel token https://github.com/axios/axios#cancellation
     */
    async fetchListingsByListingId(listingIds, options = {}) {
        let listingDetails;

        try {
            listingDetails = await Promise.map(listingIds, _.partial(benoitApi.getListingDetailsById, _, options));
        } catch (err) {
            return [];
        }

        const listings = listingDetails.map((listingInfo, i)=> ({
            id: shortid.generate(),
            listingId: listingInfo.id,
            listing_details: listingInfo,
            index: i,
            archived: false,
            match_score: Math.ceil(Math.random() * 20) + 80
        }));

        return listings;
    }

    /**
     * Returns a lanes listings sorted by its sorting function
     *
     * @function getLanesListings
     * @param {String} laneId
     */
    getLanesListings(laneId) {
        const lane = _.find(this.lanes, { id: laneId });
        const sortFunction = lane.selectedSortFunction || _.partial(_.sortBy, _, 'index');
        const listings = sortFunction(_.filter(this.listings, { laneId }));
        return listings;
    }

    /**
     * Returns a unarchived lanes listings sorted
     *
     * @function getUnarchivedLanesListings
     * @param {String} laneId
     */
    getUnarchivedLanesListings(laneId) {
        const listings = this.getLanesListings(laneId);
        const unarchivedListings = _.filter(listings, {archived: false});

        return unarchivedListings;
    }

    /**
     * Moves a listing from one listing to another
     *
     * TODO: Need to save results to the DB once we have a backend
     *
     * @function moveListing
     * @param {String} listingId
     * @param {String} sourceLaneId
     * @param {String} sourceIndex
     * @param {String} toLaneId
     * @param {String} toIndex
     */
    moveListing(listingId, sourceLaneId, sourceIndex, toLaneId, toIndex) {
        this.activeBoard.moveCard(listingId, toLaneId, toIndex);
    }

    async sortListing(boardCardId, laneId) {
        const response =  await this.activeBoard.sortCards(boardCardId, this.getUnarchivedLanesListings(laneId));
        return response;
    }

    getLastLaneIndex(laneId) {
        var laneSize = this.getLanesListings(laneId, false).length;
        return --laneSize;
    }

    getSelectedLaneSort(laneId) {
        if(this.activeBoard){
            return  this.activeBoard.getSelectedLaneSort(laneId);
        }

        return  SORT_OPTIONS[0];
    }


    setSelectedLaneSort(laneId, option) {
        if(this.activeBoard){
            this.activeBoard.setSortLanes(laneId, option);
        }
    }

    getListingById(listingId) {
        return this.listings.find((listing)=> listing.id === listingId);
    }

    /**
     * Change the function a lane uses to sort by
     *
     * @function changeLaneSortingFunction
     * @param {String} laneId
     * @param {Function} sortFunc
     */
    changeLaneSortingFunction(laneId, sortFunc) {
        const lane = _.find(this.lanes, { id: laneId });
        lane.selectedSortFunction = sortFunc;
    }

    /**
     * Archives a listing by id
     *
     * @archiveListing
     * @param {String} listingId
     */
    archiveListing(listingId) {
        const listing = _.find(this.listings, { id: listingId });
        listing.archived = true;
    }
}

decorate(LanesStore, {
    // Variables
    lanes: observable,
    loadingListings: observable,

    // Computed Vars
    activeBoard: computed,
    listings: computed,
    lanesById: computed,
    listingsByLaneId: computed,
    listingsById: computed,
    listingsForOffer: computed,
    sortByLaneId: computed,
    wantToSeeListings: computed,

    // Actions
    moveListing: action,
    changeLaneSortingFunction: action,
    archiveListing: action
});

export default LanesStore;
