import React, { useRef } from 'react';
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, useTheme } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import LRButton from 'components/LRButton';
import { useMenu, useResponsive } from 'hooks';

export function LRSplitButton({
    primaryLabel,
    onPrimaryClick,
    secondaryOptions,
    onSecondaryClick,
    variant = 'contained',
    color = 'primary',
    disabled,
    primaryProps,
    secondaryProps,
}) {
    const theme = useTheme();
    const r = useResponsive();
    const ref = useRef();
    const [menuProps, openMenu, closeMenu] = useMenu();
    const { open } = menuProps;

    function handleSecondaryClick(option) {
        closeMenu();
        onSecondaryClick(option);
    }

    return (
        <>
            <ButtonGroup
                variant={variant}
                color={color}
                ref={ref}
                style={{
                    boxShadow: 'none',
                }}
                disabled={disabled}
            >
                <LRButton
                    onClick={onPrimaryClick}
                    style={{
                        height: '36px',
                        padding: `0 ${theme.spacing(1.5)}px`,
                    }}
                    {...primaryProps}
                >
                    <div style={{ lineHeight: 1 }}>{primaryLabel}</div>
                </LRButton>
                {secondaryOptions && secondaryOptions.length && (
                    <LRButton
                        onClick={openMenu}
                        color="primary"
                        size="small"
                        style={{
                            maxWidth: r({ xs: '48px', sm: '36px' }),
                        }}
                        {...secondaryProps}
                    >
                        <KeyboardArrowDown fontSize="inherit" />
                    </LRButton>
                )}
            </ButtonGroup>
            <Popper open={open} anchorEl={ref.current} transition disablePortal style={{ zIndex: 10 }}>
                {({ TransitionProps, placement })=> (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={closeMenu}>
                                <div>
                                    <MenuList>
                                        {secondaryOptions.map((secondaryOption, index)=> (
                                            <MenuItem onClick={()=> handleSecondaryClick(secondaryOption)} key={index}>
                                                {secondaryOption.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
