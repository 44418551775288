import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import LRButton from '../../../../components/LRButton';
import { Popover, withStyles } from '@material-ui/core';

export const StyledLRButton = withTheme(styled(LRButton)`
    ${({ theme })=> `
        display: block;
        width: 100%;
        background-color: transparent;
        font-weight: 400;
        color: ${theme.palette.lr_colors.grade};
        text-align: left;
        border-radius: 0;
        &:hover{
            background-color: transparent;
            box-shadow: none;
        }
    `}
`);

export const StyledPopover = withStyles(
    (theme)=> ({
        paper: {
            [theme.breakpoints.only('xs')]: {
                marginTop: '17px'
            },
        },
    })
)(Popover);
