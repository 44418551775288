import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import LRButton from '../../../LRButton';
import clsx from 'clsx';
import './styles.css';


export function AgentMessage({ title, message, ctaText, onCtaClick, ctaProps = {}, animateCta = false }) {
    const theme = useTheme();
    return (
        <div
            style={{
                border: `1px solid ${theme.palette.lr_colors.border_grey}`,
                padding: theme.spacing(3),
                whiteSpace: 'normal',
                textAlign: 'left',
                borderRadius: '4px',
                maxWidth: '264px',
            }}
        >
            <div style={{
                marginBottom: theme.spacing(2)
            }}>
                <Typography variant="h6">
                    {title}
                </Typography>
            </div>
            <div style={{
                marginBottom: theme.spacing(2)
            }}>
                <Typography variant="body2">
                    {message}
                </Typography>
            </div>
            <div style={{
                textAlign: 'right'
            }}>
                <LRButton
                    color="primary"
                    variant="contained"
                    onClick={onCtaClick}
                    {...ctaProps}
                    className={clsx({ "pulsing-button": animateCta })}
                >
                    {ctaText}
                </LRButton>
            </div>
        </div>
    );
}
