import React from 'react';
import VideoPlayer from '../DigitalOpenHouse/VideoPlayer';
import { Box, Dialog, withStyles, IconButton, withTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CloseRounded } from '@material-ui/icons';
import styled from 'styled-components';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

const CloseButton = withTheme(styled(IconButton)`
    position: absolute;
    top: 8px;
    right: -55px;
    color: #fff;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: -55px;
        right: 0;
    }
`);

const StyledDialog = withStyles({
    paper: {
        overflow: 'visible',
        margin: '16px'
    },
    paperFullWidth: {
        width: '100%'
    }
})(Dialog);

export function OnboardingVideoModal({ open = true, onClose, ...props }) {
    const history = useHistory();
    const {
        isIPadDown,
    } = useDeviceBreakpoints();

    function handleOnClose() {
        if(onClose) {
            onClose();
        } else {
            history.push('/boards');
        }
    }

    return (
        <Box>
            <StyledDialog {...props} fullWidth maxWidth='lg' open={open} onBackdropClick={handleOnClose}>
                <Box onClick={handleOnClose}>
                    <CloseButton title="Close" edge={isIPadDown && 'end'}>
                        <CloseRounded />
                    </CloseButton>
                </Box>
                <Box width={1}>
                    <Box>
                        <VideoPlayer
                            videoUrl={'https://listreports.wistia.com/medias/sb73kuk5em'}
                            containerProps={{
                                maxWidth: '100%',
                                marginBottom: 0
                            }}
                            playerParams='autoPlay=allow'
                        />
                    </Box>
                </Box>
            </StyledDialog>
        </Box>
    );
}
