import React, { useContext, useState, useEffect } from 'react';
import { observable, decorate, computed, action } from 'mobx';
import { useStore } from '../../mobx-store';
import _ from 'lodash';

const TABS = ['photos', '3dTour', 'explore', 'streetView'];

class ListingExplorerStore {

    constructor(rootStore, card, selectedTab = 'photos') {
        this.rootStore = rootStore;
        this.selectedTab = selectedTab;
        this.card = card;
    }

    get listing_details() {
        return this.card?.listing_details;
    }

    get photos() {
        return !_.isEmpty(this.listing_details.photos) ? this.listing_details.photos : this?.card?.hero_image_url ? [this.card.hero_image_url] : [];
    }

    get has3dTour() {
        return this.listing_details.virtual_tours?.length > 0;
    }

    get virtualTourUrl() {
        const virtualTours = this.listing_details.virtual_tours;
        if(!virtualTours || !virtualTours.length) {
            return null;
        }
        return virtualTours[0].url;
    }

    get isPhotosTabSelected() {
        return this.selectedTab === 'photos';
    }

    get isTourTabSelected() {
        return this.selectedTab === '3dTour';
    }

    get isExploreTabSelected() {
        return this.selectedTab === 'explore';
    }

    get isStreetViewTabSelected() {
        return this.selectedTab === 'streetView';
    }

    setTab(tab) {
        if(TABS.includes(tab)) {
            this.selectedTab = tab;
        }
    }

}

decorate(ListingExplorerStore, {
    selectedTab: observable,

    listing_details: computed,
    photos: computed,
    has3dTour: computed,
    isPhotosTabSelected: computed,
    isTourTabSelected: computed,
    isExploreTabSelected: computed,
    isStreetViewTabSelected: computed,
    virtualTourUrl: computed,

    setTab: action
});

const ListingExplorerContext = React.createContext();

export function useListingExplorerStore(card, selectedTab) {
    const rootStore = useStore();
    const [store, setStore] = useState(new ListingExplorerStore(rootStore, card, selectedTab));

    useEffect(()=> {
        if((!store && card) || card?.listing_details?.id !== store?.listing_details?.id) {
            setStore(new ListingExplorerStore(rootStore, card, selectedTab));
        }
    }, [card, rootStore, selectedTab, store]);

    return store;
}

export function ListingExplorerStoreProvider({ listingExplorerStore, children }) {
    return <ListingExplorerContext.Provider value={listingExplorerStore}>{children}</ListingExplorerContext.Provider>;
}

export function useListingExplorerStoreContext() {
    return useContext(ListingExplorerContext);
}
