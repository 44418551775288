import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import LRModal from '../../LRModal';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { useSignupStore, SignupStoreProvider, useSignupStoreContext } from '../../../scenes/SignUp/store';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useQuery } from '../../../hooks/use-query';
import { SignupMenu, SignupHeader, Verification } from '../../../scenes/SignUp';
import { SignupForm } from '../../../scenes/SignUp/SignupForm';
import AgentInfo from '../../../scenes/SignUp/AgentInfo';
import { RobinLogo } from '../../RobinLogo';

export const SignUpModal = observer(function({onClose}) {
    const signupStore = useSignupStore();
    const { UserStore, boardsStore } = useStore();
    const { user } = UserStore;
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const inviteIdRef = useRef(query.get('invite_id'));
    const { affiliate } = signupStore;
    const isXs = useResponsiveBreakpoint('xs');
    const [closing, setClosing] = useState(false);
    const { from } = location.state || { from: { pathname: '/boards' } };

    function handleLoginSuccess(user) {
        UserStore.user.account_type = 'verified_robin';
        if(onClose) {
            onClose();
        } else {
            history.replace(from);
        }
    }

    useEffect(()=> {
        if(inviteIdRef.current) {
            signupStore.fetchInvite(inviteIdRef.current);
        } else {
            signupStore.goToMenu();
        }
    }, [signupStore]);

    if(closing) {
        return <Redirect to="/boards" />;
    }

    if(signupStore.activateDeviceRequest.hasLoaded) {
        handleOnClose();
    }

    function handleOnClose() {
        if(onClose) {
            onClose();
        } else {
            setClosing(true);
        }
    }

    return (
        <LRModal onClose={handleOnClose}>
            <SignupStoreProvider signupStore={signupStore}>
                <Box height="100%">
                    {isXs &&
                        <Box height={56} pr={2} borderBottom="1px solid #E0E0E0" display="flex" justifyContent="flex-end" alignItems="center">
                            <CloseRounded onClick={handleOnClose} />
                        </Box>
                    }
                    <Box height={['calc(100% - 56px)', 'auto']} overflow="auto">
                        <Box maxWidth={['none', 360]} mx="auto" display="flex" flexDirection="column" alignItems="center">
                            <Box mb={signupStore.isInitialState ? 0 : -4} mt={6}>
                                <RobinLogo width={101} />
                            </Box>
                            {(signupStore.inSignupMenu || signupStore.inOrganicSignup) && (
                                <>
                                    <SignupHeader showLoginInSignup={false} />
                                    {signupStore.inSignupMenu && <SignupMenu hideAgentBtn={true} />}
                                    {signupStore.inOrganicSignup && <SignupForm />}
                                </>
                            )}
                            {signupStore.inVerification && <Verification onLoginSuccess={handleLoginSuccess} method={signupStore.signUpMethodForVerification}/>}
                            {signupStore.affiliateRequest.hasLoaded && affiliate && (
                                <Divider flexItem style={{ height: 1, margin: '32px 0' }} />
                            )}
                            {signupStore.affiliateRequest.isLoading && (
                                <Box mt={3}>
                                    <CircularProgress size={30} color="secondary" />
                                </Box>
                            )}
                            {affiliate && <AgentInfo agent={affiliate} />}
                        </Box>
                    </Box>
                </Box>
            </SignupStoreProvider>
        </LRModal>
    );
});
