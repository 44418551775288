import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Switch, FormControlLabel, withTheme } from '@material-ui/core';
import { COLOR_MINTY_FRESH } from '../../../../constants';
import styled, { css } from 'styled-components/macro';
import LRDropdown from '../../../../components/LRDropdown';
import { MobileAppAdTooltip } from 'components/MobileAppAd';

const StyledSwitch = styled(Switch)`
    & .Mui-checked + span[class*='MuiSwitch-track'] {
        background-color: ${COLOR_MINTY_FRESH};
    }
    & .Mui-checked {
        color: ${COLOR_MINTY_FRESH};
    }
`;

const MobileControlLabel = withTheme(styled(FormControlLabel)`
    *[class*='MuiFormControlLabel-label'] {
        font-weight: 500;
        ${({ theme })=> css`
            font-size: ${theme.typography.subtitle2.fontSize};
        `}
    }
`);

const MobileGroupControlLabel = withTheme(styled(MobileControlLabel)`
    align-items: flex-start;
    *[class*='MuiFormControlLabel-label'] {
        ${({ theme })=> css`
            margin-top: ${theme.spacing(1)}px;
            margin-bottom: ${theme.spacing(0.5)}px;
        `}
    }
`);


function SelectedOption({ label }) {
    return <div style={{ cursor: 'pointer' }}>{label}</div>;
};

function DropdownOption({ label }) {
    return <div style={{ cursor: 'pointer' }}>{label}</div>;
};

const StyledListpackName = withTheme(styled.div`
    text-align: left;
    font-weight: bold;

    ${({ theme })=> css`
        margin: ${theme.spacing(2)}px 0 0;
    `}
`);

const MobileUserNotificationSetting = observer(({
    title,
    description,
    value,
    disabled,
    channelOptions,
    onChangeNotification,
})=> {
    const onChange = ({ target: { name } }, checked)=> {
        const newSetting = value;
        newSetting[name] = checked;
        onChangeNotification(name, checked);
    };
    const { email, text, push } = value;
    const { email: emailOptions = true, text: textOptions = true, push: pushOptions = true } = channelOptions || {};
    const { email: emailDisabled = false, text: textDisabled = false, push: pushDisabled = false } = disabled || {};
    return (
        <Box>
            <Box mb={1} textAlign="left" fontSize="h6.fontSize">
                {title}
            </Box>
            <Box mb={1}>{description}</Box>
            <Grid container spacing={2}>
                {emailOptions && (<Grid item>
                    <MobileControlLabel
                        label="Email"
                        labelPlacement="top"
                        control={<StyledSwitch name="email" color="primary" checked={email} disabled={emailDisabled} onChange={onChange} />}
                        style={{ margin: 0 }}
                    />
                </Grid>)}
                {textOptions && (<Grid item>
                    <MobileControlLabel
                        label="Text"
                        labelPlacement="top"
                        control={<StyledSwitch name="text" color="primary" checked={text} disabled={textDisabled} onChange={onChange} />}
                        style={{ margin: 0 }}
                    />
                </Grid>)}
                {false && pushOptions && (<Grid item>
                    <MobileAppAdTooltip source="notifications" disabled={!pushDisabled} mobile={true}>
                        <MobileControlLabel
                            label="Push"
                            labelPlacement="top"
                            control={<StyledSwitch name="push" color="primary" checked={push} disabled={pushDisabled} onChange={onChange} />}
                            style={{ margin: 0 }}
                        />
                    </MobileAppAdTooltip>
                </Grid>)}
            </Grid>
        </Box>
    );
});


const MobileListpackNotificationSetting = observer(({
    title,
    description,
    settingsKey,
    savedSearches,
    disabled,
    channelOptions,
    onChangeSavedSearchSettings,
})=> {
    const onDropdownChange = (listpack)=> ({ target: { name: channel, value: newValue } })=> {
        const value = newValue?.id;
        if(onChangeSavedSearchSettings && value) {
            onChangeSavedSearchSettings(listpack, channel, value);
        }
    };

    const getSelectedOption = (listpack, channel)=> {
        const selectedId = listpack.getNotificationSetting(settingsKey, channel);
        return channelOptions[channel].find((o)=> o.id === selectedId);
    };

    const { email: emailOptions = true, text: textOptions = true, push: pushOptions = true } = channelOptions || {};
    const { email: emailDisabled = false, text: textDisabled = false, push: pushDisabled = false } = disabled || {};
    return (
        <Box>
            <Box mb={1} textAlign="left" fontSize="h6.fontSize">
                {title}
            </Box>
            <Box mb={1}>{description}</Box>
            {savedSearches && savedSearches.length > 0 && (
                savedSearches.map((listpack, i)=> {
                    return (<div key={listpack && listpack.id}>
                        <StyledListpackName>{listpack.name}</StyledListpackName>
                        <Grid container spacing={2}>
                            {emailOptions && (<Grid item>
                                <MobileGroupControlLabel
                                    label="Email"
                                    labelPlacement="top"
                                    control={<LRDropdown
                                        minWidth={95}
                                        name="email"
                                        selectedOption={getSelectedOption(listpack, 'email')}
                                        options={emailOptions}
                                        renderDisplayOption={SelectedOption}
                                        fullWidth
                                        onChange={onDropdownChange(listpack)}
                                        renderOption={DropdownOption}
                                        disabled={emailDisabled}
                                    />}
                                    style={{ margin: 0 }}
                                />
                            </Grid>)}
                            {textOptions && (<Grid item>
                                <MobileGroupControlLabel
                                    label="Text"
                                    labelPlacement="top"
                                    control={<LRDropdown
                                        minWidth={100}
                                        name="text"
                                        selectedOption={getSelectedOption(listpack, 'text')}
                                        options={textOptions}
                                        renderDisplayOption={SelectedOption}
                                        fullWidth
                                        onChange={onDropdownChange(listpack)}
                                        renderOption={DropdownOption}
                                        disabled={textDisabled}
                                    />}
                                    style={{ margin: 0 }}
                                />
                            </Grid>)}
                            {false && pushOptions && (<Grid item>
                                <MobileAppAdTooltip source="notifications" disabled={!pushDisabled} mobile={true}>
                                    <MobileGroupControlLabel
                                        label="Push"
                                        labelPlacement="top"
                                        control={<LRDropdown
                                            minWidth={100}
                                            name="push"
                                            selectedOption={getSelectedOption(listpack, 'push')}
                                            options={pushOptions}
                                            renderDisplayOption={SelectedOption}
                                            fullWidth
                                            onChange={onDropdownChange(listpack)}
                                            renderOption={DropdownOption}
                                            disabled={pushDisabled}
                                        />}
                                        style={{ margin: 0 }}
                                    />
                                </MobileAppAdTooltip>
                            </Grid>)}
                        </Grid>
                    </div>);

                })
            )}
        </Box>
    );
});

export default function MobileNotificationSetting(props) {
    const { isListpackNotification } = props;
    return (isListpackNotification)
        ? <MobileListpackNotificationSetting {...props} />
        : <MobileUserNotificationSetting {...props} />;
}
