import React from 'react';
import { observer } from 'mobx-react-lite';

import { LeadContactForm } from '../../LeadContactForm';

export default observer(({ user, loadingUser, loadedUser })=> {
    return (
        <>
            <LeadContactForm
                agentText={`Curious about whether you can score a lower mortage rate? Shoot me a message using the form
                below—I’m here to help!`}
                source={'map_page'}
                user={user}
                loadingUser={loadingUser}
                loadedUser={loadedUser}
            />
        </>
    );
});
