// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Dialog, DialogContent, IconButton, withTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const StyledDialog = withTheme(styled(({ color, ...other })=> (
    <Dialog classes={{ paperWidthSm: 'paperWidthSm-listing-details' }} {...other} />
))`
    & .TourDetailsModalContainer {
        height: 100%;
    }
    & .paperWidthSm-listing-details {
        max-width: 768px;
        max-height: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        overflow: visible;

        ${({ theme })=> theme.breakpoints.only('xs')} {
            /* padding: 24px 16px; */
            width: 100%;
        }
    }
`);

const StyledDialogContent = withTheme(styled(DialogContent)`
    padding: 0 !important;
    line-height: 1.5;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        padding: 0 !important;
        line-height: 1.5;
    }
`);

const CloseButton = withTheme(styled(IconButton)`
    position: absolute;
    top: 8px;
    right: -55px;
    color: #fff;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: 4px;
    }
`);

export default ({
    children,
    onClose,
    onScroll,
    closeButtonRef,
    hideCloseButton = false,
    fullWidth = true,
    maxWidth = 'sm',
    dialogContentClasses = {},
    dialogContentProps = {},
    paperProps,
    closeButtonStyles = {},
    ...props
})=> {
    const [isOpen, setIsOpen] = useState(true);

    const handleOnClose = ()=> {
        setIsOpen(false);
        if(onClose) {
            onClose();
        }
    };

    const handleOnExited = ()=> {
        if(onClose) {
            onClose();
        }
    };

    useEffect(()=> {
        if(closeButtonRef) {
            closeButtonRef.addEventListener('click', handleOnClose);

            return ()=> {
                closeButtonRef.removeEventListener('click', handleOnClose);
            };
        }
    }, [closeButtonRef]);

    return (
        <StyledDialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isOpen}
            onClose={handleOnClose}
            onExited={handleOnExited}
            PaperProps={paperProps}
            {...props}
        >
            {!Boolean(closeButtonRef) && !hideCloseButton && (
                <CloseButton onClick={handleOnClose} title="Close" className="tour-target-modal-close-button" style={closeButtonStyles}>
                    <Close />
                </CloseButton>
            )}
            <Box clone px={{ xs: 2, md: 0 }}>
                <StyledDialogContent classes={dialogContentClasses} onScroll={onScroll} {...dialogContentProps}>
                    {children}
                </StyledDialogContent>
            </Box>
        </StyledDialog>
    );
};
