import { useState } from 'react';

export function useFormField(defaultValue) {
    const [value, setValue] = useState(defaultValue);

    function handleOnChange(e) {
        setValue(e.currentTarget.value);
    }

    const props = {
        value: value,
        onChange: handleOnChange
    };

    return [props, value, setValue];
}
