import React from 'react';
import { CopyToClipboard as RCopyToClipboard } from 'react-copy-to-clipboard';
import { useStore } from '../../mobx-store';
import PropTypes from 'prop-types';

export default function CopyToClipboard({
    onCopy: orgOnCopy,
    snackbarText = 'Link copied to clipboard',
    ...props
}) {
    const { GlobalSnackbarStore } = useStore();

    function onCopy() {
        if(orgOnCopy) orgOnCopy.call(this, arguments);
        GlobalSnackbarStore.show(snackbarText, {autoHideDuration: 3000});
    }

    return (
        <>
            <RCopyToClipboard {...props} onCopy={onCopy} />
        </>
    );
}

CopyToClipboard.propTypes = {
    text: PropTypes.string.isRequired,
    snackbarText: PropTypes.string,
    onCopy: PropTypes.func,
};
