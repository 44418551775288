import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
    COLOR_TREND_DOWN_RED,
    COLOR_TREND_UP_GREEN,
    GIGGITY,
} from '../../../../constants';
import formatTrendPercentage from '../../../../services/format-trend-percentage';

const TREND_ARROWS = {
    trendUp: '/images/trending-up.svg',
    trendDown: '/images/trending-down.svg',
    trendFlat: '/images/trending-flat.svg',
};

const CURRENT_YEAR = new Date().getFullYear();
const useStyles = makeStyles((theme)=> ({
    trendContainer: {
        display: (props)=> props.display || 'inline-block',
        fontWeight: 500,
        margin: (props)=> props.margin || '0 0 0 12px',
    },
    trendValueContainer: {
        display: 'flex',
        gap: '4px',
    },
    trendUp: {
        color: COLOR_TREND_UP_GREEN,
    },
    trendDown: {
        color: COLOR_TREND_DOWN_RED,
    },
    trendFlat: {
        color: GIGGITY,
    },
    trendArrow: {
        width: '18px',
    },
    trendValue: {
        fontWeight: 600,
    },
}));

export function HistoricalTrendLegend(props) {
    const {
        fromMonth,
        toMonth,
    } = props;

    return (
        <div style={{
            textAlign: 'center',
            verticalAlign: 'middle',
        }}>
            Local Market Trends (
            <img src={TREND_ARROWS.trendDown} alt="Red downwards arrow" width={14} />/
            <img src={TREND_ARROWS.trendUp} alt="Green upwards arrow" width={14}/>
            ) from {fromMonth}. to {toMonth}. {CURRENT_YEAR}.
        </div>
    );
}

export function HistoricalTrend(props) {
    const {
        value,
    } = props;
    const classes = useStyles(props);
    const formattedTrendPercentage = formatTrendPercentage(value);
    const trendClass = (/^-/).test(formattedTrendPercentage)
        ? 'trendDown'
        : (formattedTrendPercentage === '0%')
            ? 'trendFlat'
            : 'trendUp';
    if(!formattedTrendPercentage) return null;
    return (
        <div className={`${classes.trendContainer} ${classes[trendClass]}`}>
            <div className={classes.trendValueContainer}>
                <img className={classes.trendArrow} src={TREND_ARROWS[trendClass]} alt="Trend Arrow" />
                <div className={classes.trendValue}>{formatTrendPercentage(value)}</div>
            </div>
        </div>
    );
}
