import React from 'react';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../../hooks';
import formatPhoneNumber from 'services/format-phone-number';
import { COLOR_GREY_DARK } from '../../../constants';
import LRAvatar from 'components/LRAvatar';

const UserProfileInfoRow = function ({ user }) {
    const isMobile = useResponsiveBreakpoint('xs');
    const theme = useTheme();
    const isAgent = user.type === 'agent';

    return (<div style={{...(isMobile && {display: 'flex', flexDirection: 'column'})}}>
        <div className="space-x-2" style={{...((user.phone || user.email) && { marginBottom: theme.spacing(0.5) })}}>
            <Typography
                variant="h6"
                style={{ fontWeight: 500 }}>
                {user.first_name} {user.last_name}
            </Typography>
        </div>

        {user.phone && (
            <div className="space-x-2">
                <Typography
                    variant="body2">
                    {formatPhoneNumber(user.phone)}
                </Typography>
            </div>
        )}

        {user.email && (
            <div className="space-x-2">
                <Typography
                    variant="body2">
                    {user.email}
                </Typography>
            </div>
        )}

        {isMobile && (user.company || user.realtor_license_number) && (
            <div className="space-x-1" style={{color: COLOR_GREY_DARK}}>
                {user.company && (<Typography
                    component="span"
                    variant="caption">
                    {user.company}
                </Typography>)}

                {(user.company && user.realtor_license_number) && (
                    <Typography component="span" variant="caption">|</Typography>
                )}

                {user.realtor_license_number && (<Typography
                    component="span"
                    variant="caption">
                    {isAgent ? 'License' : 'NMLS'} #{user.realtor_license_number}
                </Typography>)}
            </div>
        )}
    </div>);
};

const UserProfileInfoColumn = function ({ user, hideFields = [] }) {
    const isMobile = useResponsiveBreakpoint('xs');
    const isAgent = user.type === 'agent';
    const branding = user.branding || user.vendor?.logo;

    return (
        <>
            <Box
                display="flex"
                flexDirection={isMobile ? 'row-reverse' : 'column'}
                alignItems={isMobile ? '' : 'center'}
                justifyContent={isMobile ? 'space-between' : ''}
                textAlign={isMobile ? '' : 'center'}
            >
                <Box
                    display="flex"
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    justifyContent="center"
                    mb={isMobile ? 0 : '22px'}
                >
                    {user.headshot && (<LRAvatar user={user} size="size64"/>)}
                </Box>

                <Box>
                    <Box fontWeight="500" fontSize="18px">
                        {user.name}
                    </Box>
                    <Box fontSize="16px">{user.company}</Box>
                    <Box fontSize="12px" color={COLOR_GREY_DARK}>
                        {isAgent ? 'License' : 'NMLS'} #{user.realtor_license_number}
                    </Box>
                    <Box my="12px" maxWidth="24px" mx={isMobile ? '' : 'auto'}>
                        <Divider style={{ height: '2px' }} />
                    </Box>
                    {!hideFields?.includes('phone') && (<Box fontSize="16px">{formatPhoneNumber(user.phone)}</Box>)}
                    {!hideFields?.includes('email') && (<Box fontSize="16px">{user.email}</Box>)}
                </Box>
            </Box>
            {branding && (<Box textAlign="center">
                <Box maxHeight="80px" maxWidth="100%" component="img" src={branding} alt="user_branding" />
            </Box>)}
        </>
    );
};

export const UserProfileInfo = function ({ variant = 'row', user, ...restProps }) {
    if (!user) return null;
    const props = { user, ...restProps };
    switch(variant) {
        case 'column':
            return <UserProfileInfoColumn {...props} />;
        case 'row':
        default:
            return <UserProfileInfoRow {...props} />;
    }
};