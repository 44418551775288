import { Box, useTheme } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../../../mobx-store';
import LRDropdown from '../../../LRDropdown';
import { useOnboarding } from '../../../Onboarding';

const StyledExpandMoreIcon = ()=> {
    return <Box fontSize="16px" display="flex" alignItems="center">
        <ExpandMore fontSize="inherit" />
    </Box>;
};

const RenderOptionLabel = ({laneId, label, listings})=> {
    return <>
        <Box ml={0.5}>{label}</Box>
        <Box ml={0.5} color={laneId === '0' && listings > 0 ? '#f3b204' : 'lr_colors.grey_dark'}>
            {listings}
            {
                laneId === '0' && listings > 0 && (
                    <Box component="span" ml={0.5} role="img" aria-label="party">
                    🎉
                    </Box>
                )

            }
        </Box>
    </>;
};

const RenderOption = ({ laneId, label, listings, selected = false, ...props })=> {
    return (
        <Box display="flex" key={laneId}>
            {/* <Box><CheckIcon fontSize="small" style={{
                visibility: selected ? 'visible': 'hidden'
            }}/></Box> */}
            <RenderOptionLabel laneId={laneId} label={label} listings={listings} />

        </Box>
    );
};

const DisplayRenderOption = ({ laneId, label, listings, ...props })=> {
    return (
        <Box display="flex" key={laneId}>
            <RenderOptionLabel laneId={laneId} label={label} listings={listings} />
        </Box>
    );
};

function MobileLaneSwitcher(props) {
    const { LanesStore, boardsStore  } = useStore();
    const location = useLocation();
    const history = useHistory();
    const onboarding = useOnboarding();
    const theme = useTheme();

    // For deleted homes
    const laneId = '-1';

    if(boardsStore.activeBoard === null) {
        return null;
    }
    
    const { activeBoard } = boardsStore;
    const archivedListings = activeBoard.archivedListings; //archived board cards


    const matchLane = matchPath(location.pathname, {
        path: '/boards/lane/:laneId/:laneSlug',
        exact: true,
        strict: true
    });

    let laneItems = [
        {
            laneId: '0',
            label: 'Your Searches',
            listings: activeBoard.newListings,
            route: '/boards/lane/0/your-searches',
            selected: matchLane?.params?.laneId === 0,
            disabled: onboarding.isFirstRunOnboarding,
        },
        ...LanesStore.lanes.map((lane)=> ({
            laneId: lane.id,
            label: lane.name,
            listings: LanesStore.getUnarchivedLanesListings(lane.id).length,
            route: `/boards/lane/${lane.id}/${lane.name}`,
            selected: lane.id === matchLane?.params?.laneId,
            disabled: onboarding.isFirstRunOnboarding && lane.id !== '1',
        }))
    ];
    
    const menuItems = [
        {
            id: 'home',
            label: 'Home',
            route: '/boards',
            disabled: onboarding.isFirstRunOnboarding
        },
        ...laneItems,
    ];

    let selectedOption;

    selectedOption = _.find(menuItems, { laneId: matchLane.params.laneId });

    function handleLaneChange(option) {
        if(onboarding.isFirstRunOnboarding) {
            onboarding.next();
        }
        history.push(option.route); 
    }

    return (
        <Box
            width={192}
            className="tour-target-mobile-lane-switcher"
        >
            <LRDropdown
                name="lane-switcher"
                fullWidth
                selectedOption={selectedOption}
                options={menuItems}
                onOptionSelect={handleLaneChange}
                renderDisplayOption={DisplayRenderOption}
                renderOption={RenderOption}
                DropdownDisplayProps={{borderColor: theme.palette.lr_colors.grey_2,paddingRight: '20px' }}
                textFieldIcon={StyledExpandMoreIcon}
            />  
        </Box>
    );
}

export default observer(MobileLaneSwitcher);
