import { Fade, Grid, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _, { partial } from 'lodash';
import React, { useMemo, useState } from 'react';
import addCommas from '../../../services/add-commas';
import ListingImage from '../../ListingImage';
import LRButton from '../../LRButton';
import LRConfirmationDialog from '../../LRConfirmationDialog';

export function SelectedListings({
    listings = [],
    variant = 'horizontal', // horizontal or vertical
    onRemoveListing = (listing, e)=> {},
    ...props
}) {
    const theme = useTheme();
    const isCardHorizontal = useMemo(()=> {
        return variant === 'horizontal';
    }, [variant]);
    const [hoveringOn, setHoveringOn] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
    const [selectedListingForRemovalMobile, setSelectedListingForRemovalMobile] = useState(null);

    // Functions

    function onMouseEnter(id) {
        setHoveringOn(id);
    }

    function onMouseLeave() {
        setHoveringOn(null);
    }

    return (
        <>
            <div
                className="SelectedListings-root"
                {...props}
            >
                {listings.map((boardCard)=> (
                    <div
                        key={boardCard.id}
                        onMouseEnter={partial(onMouseEnter, boardCard.id, _)}
                        onMouseLeave={partial(onMouseLeave, boardCard.id, _)}
                    >
                        <Grid
                            container
                            alignItems="center"
                            style={{
                                borderRadius: '4px',
                                backgroundColor: '#fff',
                                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                                display: !isCardHorizontal && 'block',
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <Grid item>
                                <div
                                    style={{
                                        padding: isCardHorizontal && theme.spacing(1),
                                        height: isCardHorizontal && '100%',
                                    }}
                                >
                                    <ListingImage
                                        style={{
                                            width: isCardHorizontal ? '120px' : '100%',
                                            height: isCardHorizontal && '100%',
                                            minHeight: '80px',
                                            maxHeight: !isCardHorizontal && '170px',
                                            paddingBottom: '51.52%',
                                            position: 'relative',
                                        }}
                                        image={
                                            boardCard.hero_image_url ||
                                            (boardCard.listing_details?.photos.length ? boardCard.listing_details.photos[0] : undefined)
                                        }
                                    >
                                        {isCardHorizontal && (
                                            <Fade in={hoveringOn === boardCard.id}>
                                                <LRButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        borderRadius: 0,
                                                    }}
                                                    onClick={partial(onRemoveListing, boardCard, 'schedule_a_home_modal', _)}
                                                >
                                                    Click here to remove
                                                </LRButton>
                                            </Fade>
                                        )}
                                    </ListingImage>
                                </div>
                            </Grid>

                            <Grid item xs>
                                <div
                                    style={{
                                        padding: theme.spacing(1),
                                    }}
                                >
                                    <Typography variant="h6">
                                        {boardCard?.name || boardCard.listing_details?.address?.split(',')[0]}
                                    </Typography>
                                    <Typography variant="body2">
                                        {!!boardCard.listing_details?.price && (<span style={{ display: 'inline-block', marginRight: theme.spacing(2), fontWeight: 'bold' }}>${addCommas(boardCard.listing_details?.price)}</span>)}
                                        {!!boardCard.listing_details?.beds && (<span style={{ display: 'inline-block', marginRight: theme.spacing(2) }}><span style={{ fontWeight: 'bold' }}>{boardCard.listing_details?.beds}</span> bed</span>)}
                                        {!!boardCard.listing_details?.baths && (<span style={{ display: 'inline-block', marginRight: theme.spacing(2) }}><span style={{ fontWeight: 'bold' }}>{boardCard.listing_details?.baths}</span> bath</span>)}
                                        {!!boardCard.listing_details?.home_size && (<span style={{ display: 'inline-block', marginRight: theme.spacing(2) }}><span style={{ fontWeight: 'bold' }}>{addCommas(boardCard.listing_details?.home_size)}</span> sqft</span>)}
                                    </Typography>
                                    <Typography variant="body4" style={{ ...theme.typography.body4, color: theme.palette.lr_colors.steak_sauce }}>
                                        {boardCard.listing_details?.address}
                                    </Typography>

                                    {!isCardHorizontal && (
                                        <div style={{ marginTop: theme.spacing(1.5), marginBottom: theme.spacing(1) }}>
                                            <LRButton
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={isMobile ? partial(setSelectedListingForRemovalMobile, boardCard) : partial(onRemoveListing, boardCard, 'schedule_a_home_modal', _)}
                                            >
                                                Remove
                                            </LRButton>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </div>

            {selectedListingForRemovalMobile && isMobile && (
                <LRConfirmationDialog
                    title={false}
                    okButton={{ label: 'Remove' }}
                    cancelButton={{ visible: true }}
                    onClose={(e, value)=> {
                        if(value) {
                            onRemoveListing(selectedListingForRemovalMobile, 'schedule_a_home_modal', e);
                        }

                        setSelectedListingForRemovalMobile(null);
                    }}
                >
                    <Typography variant="h6">
                        Remove {`${selectedListingForRemovalMobile?.listing_details?.address?.split(',')[0]}`} from your tour?
                    </Typography>
                </LRConfirmationDialog>
            )}
        </>
    );
}
