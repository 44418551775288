import { Box, CircularProgress, FormControl, TextareaAutosize, Typography, withTheme } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Card from '../../../Board/Card';

const WhiteContainer = withTheme(styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${({ theme })=> theme.spacing(3)}px;
    background-color: #fff;
    border-radius: 4px;
    padding: ${({ theme })=> theme.spacing(3)}px;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        grid-template-columns: repeat(2, 1fr);
        padding: ${({ theme })=> theme.spacing(5)}px;
    }
`);

const StyledCard = withTheme(styled(Card)`
    order: -1;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        order: initial;
    }
`);

const CommentBox = withTheme(styled(TextareaAutosize)`
    padding: ${({ theme })=> theme.spacing(2)}px;
    border: 1px solid ${({ theme })=> theme.palette.lr_colors.border_grey};
    border-radius: 2px;
    resize: none;
    outline: none;
    font-family: inherit;
    flex-grow: 1;
`);

const Loading = withTheme(styled(CircularProgress)`
    align-self: center;
    justify-self: center;
`);

function FormCard({ card, agent, formRef, onSchedule, isSubmiting, isScheduled }) {
    const { handleSubmit, register } = useFormContext();
    return (
        <WhiteContainer>
            {isSubmiting && <Loading color="secondary" size={60} />}
            {!isSubmiting && !isScheduled && (
                <form ref={formRef} onSubmit={handleSubmit(onSchedule)} style={{ display: 'flex' }}>
                    <FormControl>
                        <Box marginBottom={2}>
                            <Typography variant="h6">Do you have any comments for {agent?.name}?</Typography>
                        </Box>
                        <CommentBox
                            rowsMin={5}
                            rowsMax={5}
                            placeholder="Enter your comments here"
                            name="message"
                            ref={register}
                        />
                    </FormControl>
                </form>
            )}
            {!isSubmiting && isScheduled && (
                <Box>
                    <Box marginBottom={2}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            You’re all set!
                        </Typography>
                    </Box>
                    <Typography>
                        We’ve notified {agent?.name} that you want to make an offer. They’ll reach out shortly.
                    </Typography>
                </Box>
            )}
            <Box>
                <StyledCard boardCard={card} variant={'expanded'} enableActions={false} />
            </Box>
        </WhiteContainer>
    );
}

export default FormCard;
