import { useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';

export function Navigation({ activeIndex, size }) {
    const theme = useTheme();

    const dot = {
        margin: '10px',
        height: '8px',
        width: '8px',
        backgroundColor: '#bbb',
        borderRadius: '50%',
        display: 'inline-block'
    };

    const dotInactive = {
        ...dot,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.lr_colors?.grey_dark}`,
        boxSizing: 'border-box'
    };

    return (
        <div style={{display: 'flex'}}>
            {_.times(_.max([size - 1, 0]), 
                (index, key)=> index === activeIndex
                    ? <div key={key} style={dot}/>
                    : <div key={key} style={dotInactive}/>
            )}
        </div>
    );
}
