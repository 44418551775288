import React from 'react';
import { Chip, SvgIcon } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DeleteIcon = (props)=> {
    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="12" fill="#FFF" />
            <path d="M7.30295 7.71347L16.8761 17.2866" stroke="#1A1A1A" strokeLinecap="round" />
            <path d="M16.7096 7.71347L7.13648 17.2866" stroke="#1A1A1A" strokeLinecap="round" />
        </SvgIcon>
    );
};

const StyledChip = styled(Chip)`
    min-height: 42px;
    height: auto;
    border-radius: 21px;
    background-color: #1A1A1A;
    color: #FFF;
    justify-content: space-between;

    &:focus{
        background-color: #1A1A1A;
    }

    & > span {
        white-space: pre-wrap;
    }
`;

const ChipDeleteIcon = styled(DeleteIcon)`
    margin-right: 6px;
`;

export function LRChip({ label, onDelete, style={}, iconStyle={}, ...props }) {
    const onChipDelete = (e)=> {
        onDelete(label);
    };
    return <StyledChip
        style={style}
        label={label}
        deleteIcon={<ChipDeleteIcon style={iconStyle}/>}
        onDelete={onDelete ? onChipDelete : undefined}
        {...props}
    />;
}

LRChip.propTypes = {
    iconStyle: PropTypes.object,
    label: PropTypes.string,
    onDelete: PropTypes.func,
    style: PropTypes.object,
};
