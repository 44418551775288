import React from 'react';
import { observer } from 'mobx-react-lite';
import { HomeTour } from './HomeTour';
import { OpenHouse } from './OpenHouse';

const cardVariants = {  
    'schedule_viewing_request': HomeTour,
    'schedule_open_house': OpenHouse
};

export const Card = observer(function Card(props) {
    const CardComponent = cardVariants[props.tour.type];

    return (
        <CardComponent { ...props } />
    );
});
