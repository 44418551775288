import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Hidden } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import LRButton from '../../../LRButton';
import { useStore } from '../../../../mobx-store';
import analytics from '../../../../services/analytics';
import { useTheme } from '@material-ui/core/styles';
import Lockr from 'lockr';
import { LeadFormModal } from '../Modals/LeadForm';

export const Navbar = observer(({ onModalClose, isMobile })=> {
    const { RateFarmStore } = useStore();
    const theme = useTheme();
    const [showLeadModal, setShowLeadModal] = useState(null);

    function onChangeClick() {
        const rateFarmSubmittedLead = Lockr.get('rate-farm-submitted-lead');

        analytics.eventTrack('rate_farm_consumer_change_address_button_click', {
            distinct_id: RateFarmStore.user.id,
        });

        if(!rateFarmSubmittedLead) {
            return setShowLeadModal(true);
        }

        window.location.href = RateFarmStore.user? `/rate-farm/${RateFarmStore.user.id}` : '';
    }

    function toggleRateModal(result, submittedLead = false) {
        setShowLeadModal(false);
        if(submittedLead) {
            window.location.href = RateFarmStore.user? `/rate-farm/${RateFarmStore.user.id}` : '';
        }
    }

    return (
        <Box bgcolor="white">
            <LeadFormModal
                agentText="Enter your name and email below to access rates for your neighborhood"
                open={showLeadModal}
                toggleRateModal={toggleRateModal}
                window={isMobile? false : true}
                headerTitle='Contact me to learn more'
                source="address_change"
            />
            <Box position="relative" px={2} py={2} display="flex" justifyContent="space-between">
                <Hidden smDown>
                    <Box display="flex" alignItems="center" v ml={2}>
                        <img src="/images/logo.png" alt="lr_logo" height={20} style={{ objectFit: 'contain' }} />
                    </Box>
                    <Box display="flex" alignItems="center" fontSize={16}>
                        Rate shown are for 30 year fixed-rate mortgages.
                    </Box>
                </Hidden>
                <Box width={[1, 1, 328]} display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <Box
                            width={32}
                            height={32}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="#000"
                            borderRadius="50%"
                            color="#fff"
                        >
                            <Home />
                        </Box>
                        <Box ml={1}>
                            <Box fontSize="body3.fontSize" color={theme.palette.lr_colors.grey_dark}>
                                YOUR HOME
                            </Box>
                            <Box fontSize="body2.fontSize">
                                {RateFarmStore && RateFarmStore.address && <>{RateFarmStore.address.split(',')[0]}</>}
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <LRButton
                            variant="outlined"
                            onClick={onChangeClick}
                        >
                            <Box fontWeight="normal">Change</Box>
                        </LRButton>
                    </Box>
                </Box>
            </Box>
            <Hidden smUp>
                <Box py={1} bgcolor="#F9F9F9" textAlign="center">
                    Rates shown are for 30 year fixed-rate mortgages.
                </Box>
            </Hidden>
        </Box>
    );
});
