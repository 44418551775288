import React, { useContext, useRef } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { flows } from './flows';
import { observer } from 'mobx-react-lite';
import { OnboardingStore, OnboardingContext } from './store';

const defaultSteps = [];
const defaultTourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: false,
        },
    },
    useModalOverlay: true,
};

const OnboardingProvider = observer(function OnboardingProvider({ children }) {
    const shepherdTour = useContext(ShepherdTourContext);
    const onboardingStoreRef = useRef(new OnboardingStore(shepherdTour));
    const onboardingStore = onboardingStoreRef.current;
    
    const FlowComponent = flows[onboardingStore.selectedFlow];

    return (
        <OnboardingContext.Provider value={onboardingStore}>
            {children}
            {FlowComponent && <FlowComponent />}
        </OnboardingContext.Provider>
    );
});

export function AppOnboardingProvider({ children }) {
    return (
        <ShepherdTour steps={defaultSteps} tourOptions={defaultTourOptions}>
            <OnboardingProvider>
                {children}
            </OnboardingProvider>
        </ShepherdTour>
    );
}

export { useOnboarding } from './store';