import React from 'react';
import { SvgIcon } from '@material-ui/core';

function GalleryIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M0,0 L24,0 L24,24 L0,24 L0,0 Z M11,21 L11,13 L3,13 L3,21 L11,21 Z M12,11 L12,3 L3,3 L3,11 L12,11 Z M21,21 L21,13 L13,13 L13,21 L21,21 Z M21,11 L21,3 L12,3 L12,11 L21,11 Z"></path>
        </SvgIcon>
    );
}

export default GalleryIcon;
