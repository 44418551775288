// eslint-disable-next-line
import { Box, Grid, IconButton } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { COLOR_GREY } from '../../../constants';
import { useStore } from '../../../mobx-store';
import UtilitiesNavbar from '../../../scenes/Board/UtilitiesNavbar';
import LRAvatar from '../../LRAvatar';
import { RobinLogo } from '../../RobinLogo';
import MobileLaneSwitcher from './MobileLaneSwitcher';
import { NotificationsMenu } from './NotificationsMenu';
import FetchNotifications from '../../FetchNotifications';
import LRButton from '../../LRButton';
import analytics from '../../../services/analytics';
import { ListpackAreaSearchInput } from 'components/ListpackAreaSearchInput';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useCurrentPage } from 'hooks/useCurrentPage';
import { Can } from '../../Ability';

const Container = withTheme(styled.div`
    position: relative;
    padding: 8px 24px;
    border-bottom: 1px solid ${COLOR_GREY};
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1300;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        padding: 7px 16px 6px;
        height: 56px;
    }
`);

const StyledNotificationsContainer = styled.div`
    margin-right: 16px;
    & .notification-icon {
        margin-right: 8px;
    }
`;
const StyledNameContainer = styled.div`
    margin-right: 14px;
`;

const StyledAvatarContainer = styled.div`
    margin-right: 14px;
`;

function Header(props) {
    const { UserStore } = useStore();
    const { onMenuClick, onOpenLeftSidebar } = props;
    const { user } = UserStore;
    const location = useLocation();
    const matchLane = matchPath(location.pathname, {
        path: '/boards/lane/:laneId/:laneSlug',
        exact: true,
        strict: true,
    });
    const {
        isMobile,
        isIPad,
    } = useDeviceBreakpoints();
    const {
        current_page: currentPage,
        NAV_BOARD,
    } = useCurrentPage();

    function onLogoClick(e) {
        analytics.eventTrack('robin_board_header_logo_click');
    }

    return (
        <Container>
            <LRButton variant="text" component={Link} to="/" inline onClick={onLogoClick}>
                <RobinLogo variant={matchLane || isIPad || (isMobile && currentPage === NAV_BOARD) ? 'icon' : 'full'} />
            </LRButton>

            {matchLane && <MobileLaneSwitcher />}

            <UtilitiesNavbar onOpenLeftSidebar={onOpenLeftSidebar} />

            {(isMobile && currentPage === NAV_BOARD) && (
                <Can I="create" a="Listpack">
                    <div style={{flex: '1', padding: '0 10px'}}>
                        <ListpackAreaSearchInput 
                            inputHeight={32}
                            buttonColor="transparent"
                            buttonTextColor="#bbb"
                            location
                        />
                    </div>
                </Can>
            )}

            <div>
                <Grid container direction="row" justify="flex-end" alignItems="center" wrap="nowrap">
                    <StyledNotificationsContainer>
                        <FetchNotifications>
                            {!UserStore.isLO && <NotificationsMenu />}
                        </FetchNotifications>
                    </StyledNotificationsContainer>
                    {user && (
                        <>
                            <div onClick={onMenuClick} className="tour-target-user-avatar">
                                <LRAvatar user={user} />
                            </div>
                        </>
                    )}
                    {!user && (
                        <IconButton onClick={onMenuClick}>
                            <MenuIcon />
                        </IconButton>
                    )}
                </Grid>
            </div>
        </Container>
    );
}

Header.propTypes = {
    onMenuClick: PropTypes.func,
};

export default observer(Header);
