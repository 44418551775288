import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';

const StyledButton = withTheme(styled(Button)`
    text-transform: none;
    vertical-align: initial;
    box-shadow: none;
    ${({ inline })=> (inline ? 'padding: 0;' : '')}
    ${({ inline })=> (inline ? 'min-width: 0;' : '')}
    ${({ inline })=> (inline ? '&:hover { background: none; opacity: 0.9; }' : '')}
    ${({ underline })=> (underline ? 'text-decoration: underline;' : '')}
    ${({ underline })=> (underline ? '&:hover { text-decoration: none; }' : '')}

    &.ToggleButton {
        color: ${({ theme, color = 'default' })=> theme.palette[color]?.contrastText || theme.palette.text.primary};
    }

    &.Mui-selected,
    &.Mui-selected:hover {
        background-color: #000;
        color: #fff;
    }

    &:active {
        box-shadow: none;
    }
`);

const LRButton = React.forwardRef(function LRButton(props, ref) {
    const { children, inline, ...otherProps } = props;

    return (
        <StyledButton ref={ref} inline={inline ? 1 : 0} {...otherProps}>
            {children}
        </StyledButton>
    );
});

LRButton.displayName = 'LRButton';

LRButton.propTypes = {
    children: PropTypes.node,
    component: PropTypes.elementType,
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit']),
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    disableRipple: PropTypes.bool,
    endIcon: PropTypes.endIcon,
    fullWidth: PropTypes.bool,
    href: PropTypes.string,
    inline: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    startIcon: PropTypes.endIcon,
    underline: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

export default LRButton;
