import React from 'react';
import { InfographicsMap } from '../../../scenes/InfographicsMap';
import { useListingExplorerStoreContext } from '../store';

export const ExploreNearby = function({ requestSource='gallery', reportId }) {
    const store = useListingExplorerStoreContext();

    return (
        <InfographicsMap
            listingDetails={store.listing_details}
            requestSource={requestSource}
            reportId={reportId}
        />
    );
};