import React, { useState } from 'react';
import {  useHistory } from 'react-router-dom';
import { Box, Popover, Divider, Typography, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../mobx-store';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import LRButton from '../../../../components/LRButton';
import { Edit, ImportExport, Room, List, CheckRounded } from '@material-ui/icons';
import analytics from '../../../../services/analytics';
import { Can } from '../../../../components/Ability';
import { subject } from '@casl/ability';
import { useOnboarding } from 'components/Onboarding';

export const sortOptions = [
    {id: 'columnIndex', label: 'Column Order'},
    {id: 'listing_details.listing_date', label: 'Days on market', order: 'desc'},
    {id: 'listing_details.year', label: 'Year built', order: 'desc'},
    {id: 'listing_details.price', label: 'Price (high to low)', order: 'desc'},
    {id: 'listing_details.price', label: 'Price (low to high)', order: 'asc'},
    {id: 'listing_details.beds', label: 'Most bedrooms', order: 'desc'},
    {id: 'listing_details.baths', label: 'Most bathrooms', order: 'desc'},
    {id: 'listing_details.home_size', label: 'Most square feet', order: 'desc'},
    {id: (l)=> l.listing_details.open_houses[0]?.date_iso || '1970-01-01' , label: 'Open house', order: 'desc'},
];

const listpackSortOptions = [
    'Days on market (default)',
    'Year built',
    'Price (high to low)',
    'Price (low to high)',
    'Most bedrooms',
    'Most bathrooms',
    'Most square feet',
    'Open house',
];

const MobilePopover = styled(Popover)`
    .MuiPopover-paper {
        max-width: unset;
        width: 100%;
        left: 0 !important;
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.4);
        border-radius: unset;
    }
`;

function MobileSortPopover({ anchorEl, setAnchorEl, sort, setSort }) {
    const theme = useTheme();

    return (
        <MobilePopover
            id="desktop-sort"
            keepMounted
            open={Boolean(anchorEl)}
            onClose={()=> setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box px="16px" py="10px" color={theme.palette.lr_colors.grey_nero}>
                {sortOptions.map(function(option, index) {
                    return (
                        <Box
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={()=> {
                                setSort(option);
                                setAnchorEl(null);
                            }}
                        >
                            {index !== 0 && (
                                <Box margin="10px 0">
                                    <Divider />
                                </Box>
                            )}
                            <Box
                                display="flex"
                                height="24px"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box ml={1} fontSize="body1.fontSize">
                                    {option.label}
                                </Box>
                                {sort?.label === option.label && (
                                    <Box height="24px" color="#54D0AA">
                                        <CheckRounded />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </MobilePopover>
    );
}

function ListpackSortPopover({ anchorEl, setAnchorEl, sort, setSort }) {
    const theme = useTheme();

    return (
        <MobilePopover
            id="desktop-sort"
            keepMounted
            open={Boolean(anchorEl)}
            onClose={()=> setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box px="16px" py="10px" color={theme.palette.lr_colors.grey_nero}>
                {listpackSortOptions.map(function(option, index) {
                    return (
                        <Box
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={()=> {
                                setAnchorEl(null);
                                setSort(option);
                            }}
                        >
                            {index !== 0 && (
                                <Box margin="10px 0">
                                    <Divider />
                                </Box>
                            )}
                            <Box
                                display="flex"
                                height="24px"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box ml={1} fontSize="body1.fontSize">
                                    {option}
                                </Box>
                                {sort === option && (
                                    <Box height="24px" color="#54D0AA">
                                        <CheckRounded />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </MobilePopover>
    );
}

export const MobileFooter = observer(({
    listpack,
    view,
    onViewChange,
    onSortChange,
    sort,
    isSearch= false,
    listingsCount = 0,
    showLoading = false,
    ...props
})=> {
    const theme = useTheme();
    const history = useHistory();
    const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
    const onboarding = useOnboarding();

    const viewModesMapper = {
        map: { label: 'Map', icon: <Room  fontSize="inherit" /> },
        list: { label: 'List', icon: <List  fontSize="inherit"  /> }
    };

    const showViewKey = Object.keys(viewModesMapper)
        .find((key)=> key !== view);

    function handleViewChange() {
        analytics.eventTrack('robin_expanded_view_map_list_toggle_button_click', {
            listpack_id: listpack?.id,
            source: `${showViewKey}_view`
        });
        onViewChange(showViewKey);
    }

    function handleOnEditClick() {
        const { stepId } = onboarding;
        const shouldAdvanceOnboarding = (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && ['edit-option-highlight'].includes(stepId);
        if(shouldAdvanceOnboarding) {
            onboarding.next();
        }
        history.push(`/boards/listpack/${listpack.id}/edit`);
    }

    return (
        <Box
            position="relative"
            height={64}
            width="100%"
            borderTop={`1px solid ${theme.palette.lr_colors.border_grey}`}
            style={{ backgroundColor: '#FFFFFF' }}
            display="flex"
            justifyContent={(isSearch) ? 'flex-end' : 'center'}
            alignItems="center"
            zIndex={11}
            py={1.5}
            px={2}
        >
            {isSearch &&
                <Typography
                    variant="body1"
                    style={{
                        color: theme.palette.lr_colors.steak_sauce,
                        marginRight: theme.spacing(2),
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    {showLoading ? <CircularProgress size={24} color="primary" /> : (listingsCount === 1) ? `${listingsCount} home`: `${listingsCount} homes`}
                </Typography>
            }
            <Box mr={3 / 2} flex={1}  maxWidth={(isSearch) ? '101px' : '100%'}>
                <LRButton fullWidth variant="outlined" onClick={handleViewChange}>
                    { viewModesMapper[showViewKey].icon }
                    <Box component="span" ml={0.5}>
                        {viewModesMapper[showViewKey].label}
                    </Box>
                </LRButton>
            </Box>

            {listpack && !listpack.handSelected && !isSearch && (
                <Can I="edit" this={subject('Listpack', listpack)}>
                    <Box mr={3 / 2} flex={1}>
                        <LRButton fullWidth variant="outlined" onClick={handleOnEditClick} className="tour-fre-edit-highlight">
                            <Edit fontSize="inherit"  />
                            <Box component="span" ml={0.5}>
                                Edit
                            </Box>
                        </LRButton>
                    </Box>
                </Can>
            )}

            <Box flex={1}  maxWidth={(isSearch) ? '101px' : '100%'}>
                <LRButton fullWidth variant="outlined" onClick={(e)=> setMobileAnchorEl(e.currentTarget)} >
                    <ImportExport fontSize="inherit" />
                    <Box component="span" ml={0.5}>
                        Sort
                    </Box>
                </LRButton>
                {
                    (listpack && !listpack.handSelected) || isSearch ? (
                        <ListpackSortPopover
                            anchorEl={mobileAnchorEl}
                            setAnchorEl={setMobileAnchorEl}
                            sort={sort}
                            setSort={onSortChange}
                        />
                    ) : (
                        <MobileSortPopover
                            anchorEl={mobileAnchorEl}
                            setAnchorEl={setMobileAnchorEl}
                            sort={sort}
                            setSort={onSortChange}
                        />
                    )
                }
            </Box>

        </Box>
    );
});
