import Logger from 'js-logger';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

const logger = Logger.get('AnalyticsProviderMixpanel');

class AnalyticsProviderMixpanel {

    // 30 min in prod, 5 min in dev
    timeout = process.env.REACT_APP_MODE === 'production' ? 1800000 : 1800000;

    constructor() {
        mixpanel.init(process.env.REACT_APP_ROBIN_MIXPANEL_TOKEN);
    }

    reset(){
        mixpanel.reset();
    }

    // Session logic
    setSession() {
        function generateSessionId() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4()+s4()+'-'+s4()+'-'+s4()+'-'+s4()+'-'+s4()+s4()+s4();
        };

        mixpanel.register({
            session_id: generateSessionId(),
            last_event_time: moment().format()
        });

        mixpanel.track('robin_consumer_session_started');
    }

    haveValidSession() {
        const debugInfo = {
            session: mixpanel.get_property('session_id'),
            date: mixpanel.get_property('last_event_time')
        };
        logger.debug('session-info -> debugInfo', debugInfo);
        logger.debug('session-info -> haveValidSession -> lastEventTimestamp ', moment(mixpanel.get_property('last_event_time')).toDate().getTime());

        if(
            !mixpanel.get_property('last_event_time') ||
            !mixpanel.get_property('session_id') ||
            Date.now() - moment(mixpanel.get_property('last_event_time')).toDate().getTime() > this.timeout
        ) {
            return false;
        }

        return true;
    }

    // i have this here instead of the mixpanel init, because i want to delay the session start until i have all the necessary
    // user global props needed to trigger the session_started event
    initSession() {
        logger.debug('session-info -> initSession');

        if(!this.haveValidSession()) {
            this.setSession();
        }
        // we'll do our best effort to try to figure out if a user ended the session to track the required mixpanel event
        // if the user closed the tab/browser we can remove the previous session and dispatch a session_ended event
        // the problem with this approach is that a full page reload also triggers this event
        // window.onbeforeunload = (event)=> {
        //     logger.debug('session-info -> onbeforeunload');
        //     // since we started the time tracker for this event in the beginning of the session
        //     // once it is triggered it will automatically have a duration property
        //     mixpanel.track('robin_consumer_session_ended');
        //     mixpanel.unregister('session_id');
        // };
    }

    setUsername(userId) {
        mixpanel.identify(userId);
    }

    setAlias(alias) {
        mixpanel.alias(alias);
    }

    setSuperPropertiesOnce(properties) {
        mixpanel.register_once(properties);
    }

    setSuperProperties(properties) {
        mixpanel.register(properties);
    }

    setUserPropertiesOnce(properties) {
        mixpanel.people.set_once(properties);
    }

    setUserProperties(properties) {
        mixpanel.people.set(properties);
    }

    pageTrack(path) {
        // Commented out by Amish - We weren't ever tracking Robin page views in mixpanel
        // The analytics pageTrack method does get triggered so we want to skip mixpanel
        // but we do want to handle the braze analytics provider's pageTrack
        // mixpanel.track('Page Viewed', { page: path });
    }

    eventTrack(action, properties) {
        if(!this.haveValidSession()) this.setSession();
        // store a new last_event_time for each event tracked 
        mixpanel.register({'last_event_time': moment().format()});
        mixpanel.track(action, properties);
    }

    incrementProperty(property, value) {
        if(typeof value === 'undefined') {
            mixpanel.people.increment(property);
        } else {
            mixpanel.people.increment(property, value);
        }
    }

    userTimings(action) {
        mixpanel.time_event(action);
    }
}

export default new AnalyticsProviderMixpanel();
