import React, { useRef, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import LRButton from '../../../components/LRButton';
import { TextField } from '../../../components/TextField';
import { benoitApi } from '../../../apis';
import { COLOR_BLUE_LINK } from '../../../constants';
import analytics from '../../../services/analytics';
import { withTheme } from '@material-ui/core/styles';
import { CodeInput } from '../../CodeInput';
import { LRPhoneInput } from '../../../components/LRPhoneInput';
import { LRInputOutlined } from '../../../components/LRInput';

const StyledButton = styled(LRButton)`
    color: ${COLOR_BLUE_LINK};
`;

const StyledSubmitButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
        color: ${({ theme })=> theme.palette.lr_colors.grey_dark};
    }
`);

export const Verification = function({
    userInfo = {},
    token,
    onVerificationSuccessful,
    headerText,
    subTitleText,
    mode,
    method = 'phone',
}) {
    const theme = useTheme();
    const [useInfo, setUserInfo] = useState(userInfo);
    const [wrongCode, setWrongCode] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [tokenObj, setTokenObj] = useState(token);
    const [disabledResend, setDisabledResend] = useState(false);
    const [code, setCode] = useState('');

    // const [phoneNumber, setPhoneNumber] = useState(userInfo?.phone || '');
    // const [status, setStatus] = useState(phoneNumber ? 'verification' : 'needs-phone');

    // const submitUserData = async function() {
    //     const updatedUser = await benoitApi.updateUser({
    //         ...userInfo,
    //         phone: phoneNumber,
    //     });
    //     setUserInfo(updatedUser);
    //     if(updatedUser.phone) {
    //         setStatus('verification');
    //         resendCode(updatedUser.phone);
    //     }
    // };

    const submitCode = async function() {
        setSubmitting(true);
        const methodMap = {
            'email': 'email',
            'phone': 'mobile',
        };
        const eventMethod = methodMap[method] || method;
        try {
            const loggedinUser = await benoitApi.activateDevice(code, tokenObj.token);
            const eventContent = {
                consumer_name: loggedinUser.name,
                consumer_email: loggedinUser.email,
                consumer_phone: loggedinUser.phone,
            };
            if(mode === 'signup') {
                analytics.eventTrack(`robin_${eventMethod}_verified`, eventContent);
            } else if(mode === 'login') {
                const methodMap = {
                    'email': 'email',
                    'phone': 'mobile',
                };
                const eventMethod = methodMap[method] || method;
                analytics.eventTrack('robin_verification_passcode_submit', {
                    ...eventContent,
                    Source: eventMethod,
                });
                analytics.eventTrack(`robin_${eventMethod}_login_click`, {
                    ...eventContent,
                    Source: eventMethod,
                });
            } else if(mode === 'verify') {
                analytics.eventTrack(`robin_${eventMethod}_verified`, {
                    ...eventContent,
                    Source: 'notification_settings',
                });
            }
            onVerificationSuccessful(loggedinUser);
        } catch (err) {
            setWrongCode(true);
            setSubmitting(false);
            setCode('');
        }
    };

    const resendCode = async function(input, method = 'phone') {
        setDisabledResend(true);
        try {
            const newToken = await benoitApi.requestLogin(method, input, { forRobin: true });
            setTokenObj(newToken);
        } finally {
            setTimeout(()=> setDisabledResend(false), 1000 * 20);
        }
    };

    function onEnterClick(event) {
        event.preventDefault();
        if(code?.length >= 4 && !submitting) {
            submitCode();
        }
    }

    function setCodeValue(event){
        if(event.target.value.match(/^[0-9]*$/i)) {
            setCode(event.target.value);
        }
    }

    // if(status === 'needs-phone') {
    //     return (
    //         <Box>
    //             <TextField name="phone" value={phoneNumber} onChange={(e)=> setPhoneNumber(e.target.value)} />
    //             <LRButton onClick={()=> submitUserData()}>Submit</LRButton>
    //         </Box>
    //     );
    // }

    return (
        <>
            <Box mx={2}>
                <Box mt={5} fontSize={32} fontWeight={500} lineHeight="40px" textAlign="center">
                    {wrongCode ? 'Hrm, that’s not right.' : headerText}
                </Box>
                <Box mt={2} textAlign="center">
                    {wrongCode
                        ? 'The 4-digit code that you entered is incorrect. Please try entering it again below.'
                        : subTitleText}
                </Box>
            </Box>
            <form
                style={{
                    width: '100%',
                    display: 'block',
                    padding: `0 ${theme.spacing(2)}px`
                }}
                onSubmit={onEnterClick}
            >
                <Box mt={3}>
                    <LRInputOutlined
                        name='code'
                        value={code}
                        onChange= {setCodeValue}
                        required={true}
                        type="text"
                        inputProps={{
                            height: '48px',
                            fontSize: theme.typography.body1.fontSize,
                            borderRadius: '4px',
                            textAlign: 'center',
                            maxLength: 4,
                            width: '100%',
                            autoFocus: true,
                        }}
                    />
                </Box>
                <Box mt={7 / 2}>
                    <StyledSubmitButton
                        disabled={
                            code?.length < 4 || submitting
                        }
                        type="submit"
                        value="Submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            height: '48px',
                            fontWeight: 'normal',
                        }}
                    >
                        {!submitting && 'Verify'}
                        {submitting && <CircularProgress size={24} color="secondary" />}
                    </StyledSubmitButton>
                </Box>
            </form>
            <Box mt={2} width="calc(100% - 32px)">
                <StyledButton
                    fullWidth
                    disabled={disabledResend}
                    style={{
                        height: '48px',
                        fontWeight: 'normal',
                    }}
                    onClick={()=> resendCode(userInfo[method], method)}
                >
                    Re-send verification code
                </StyledButton>
            </Box>
        </>
    );
};
