import React from 'react';
import { AppBar, Grid, Hidden, Toolbar, useTheme } from '@material-ui/core';
import { RectShape } from 'react-placeholder/lib/placeholders';
import { BedIcon } from 'components/LRIcons';
import { Bathtub, PhotoSizeSelectSmall } from '@material-ui/icons';
import { CenteredLoading } from 'components/CenteredLoading';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

export function Placeholder({ fromRobin }) {
    const theme = useTheme();
    const { isMobile, isIPadUp } = useDeviceBreakpoints();

    return (
        <div className="show-loading-animation">
            {/* Header */}
            {!fromRobin && (
                <AppBar
                    position="static"
                    color="transparent"
                    elevation={0}
                    style={{
                        boxShadow: 'none',
                        borderBottom: '1px solid',
                        borderColor: theme.palette.lr_colors.border_grey,
                        height: 64,
                    }}
                >
                    <Toolbar>
                        <Grid
                            container
                            justify="flex-end"
                            alignItems="center"
                            style={{
                                position: 'relative',
                                right: -10,
                            }}
                        >
                            <Grid item>
                                <RectShape
                                    style={{
                                        width: 106,
                                        height: 36,
                                        backgroundColor: 'rgb(205, 205, 205)',
                                        borderRadius: 4,
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <RectShape
                                    style={{
                                        width: 38,
                                        height: 20,
                                        backgroundColor: 'rgb(205, 205, 205)',
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <RectShape
                                    style={{
                                        width: 82,
                                        height: 36,
                                        backgroundColor: 'rgb(205, 205, 205)',
                                        borderRadius: 4,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}

            {/* Image */}
            <div
                style={{
                    marginTop: !fromRobin && theme.spacing(7),
                }}
            >
                <RectShape
                    style={{
                        zIndex: 1,
                        width: '100%',
                        height: isIPadUp ? 384 : 240,
                        backgroundColor: 'rgb(205, 205, 205)',
                    }}
                />
            </div>

            {/* Info panel */}
            <div
                style={{
                    position: 'relative',
                    zIndex: 2,
                    padding: theme.spacing(2),
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    marginTop: isIPadUp && -24,
                    marginLeft: isIPadUp && theme.spacing(3),
                    marginRight: isIPadUp && theme.spacing(3),
                    boxShadow:
                        isIPadUp &&
                        'rgb(0 0 0 / 2%) 0px 5px 2px -1px, rgb(0 0 0 / 10%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                    backgroundColor: '#fff',
                    borderRadius: 4,
                }}
            >
                <Grid container alignItems="center">
                    <img src="/images/logo-simple.png" style={{ width: 13, marginRight: 5 }} />
                    <span>ListReport:</span>
                </Grid>

                <Grid container style={{ marginTop: 4 }}>
                    <Grid item xs={12} sm>
                        <div>
                            <RectShape
                                style={{
                                    zIndex: 1,
                                    width: '80%',
                                    height: 24,
                                    backgroundColor: 'rgb(205, 205, 205)',
                                }}
                            />
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <RectShape
                                style={{
                                    zIndex: 1,
                                    width: '65%',
                                    height: 14,
                                    backgroundColor: 'rgb(205, 205, 205)',
                                }}
                            />
                        </div>

                        <Grid container wrap="nowrap" alignItems="center" style={{ marginTop: 12 }}>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <BedIcon fontSize="small" />
                                    <div style={{ marginLeft: 8 }}>
                                        <RectShape
                                            style={{
                                                zIndex: 1,
                                                width: 41,
                                                height: 14,
                                                backgroundColor: 'rgb(205, 205, 205)',
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Bathtub fontSize="small" />
                                    <div style={{ marginLeft: 8 }}>
                                        <RectShape
                                            style={{
                                                zIndex: 1,
                                                width: 41,
                                                height: 14,
                                                backgroundColor: 'rgb(205, 205, 205)',
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <PhotoSizeSelectSmall fontSize="small" />
                                    <div style={{ marginLeft: 8 }}>
                                        <RectShape
                                            style={{
                                                zIndex: 1,
                                                width: 41,
                                                height: 14,
                                                backgroundColor: 'rgb(205, 205, 205)',
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        style={{
                            marginTop: isMobile && theme.spacing(2),
                        }}
                        xs={12}
                        sm="auto"
                    >
                        <Grid container justify="space-between">
                            <Hidden smUp>
                                <Grid item xs>
                                    <RectShape
                                        style={{
                                            width: 92,
                                            height: 24,
                                            backgroundColor: 'rgb(205, 205, 205)',
                                        }}
                                    />

                                    <RectShape
                                        style={{
                                            width: 92,
                                            height: 32,
                                            backgroundColor: 'rgb(205, 205, 205)',
                                            marginTop: 16,
                                        }}
                                    />
                                </Grid>
                            </Hidden>
                            <Grid item>
                                <RectShape
                                    style={{
                                        width: 128,
                                        height: 84,
                                        backgroundColor: 'rgb(205, 205, 205)',
                                        borderRadius: 4,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: theme.spacing(6) }}>
                <CenteredLoading />
            </div>
        </div>
    );
}
