import { useObserver } from 'mobx-react-lite';
import { useStore } from 'mobx-store';

export function useIsLoggedIn() {
    const { UserStore } = useStore();

    return useObserver(()=> {
        const {
            isLoggedIn,
            hasAttemptedToFetchUser,
            loadingUser,
        } = UserStore;

        return {
            isLoggedIn,
            hasAttemptedToFetchUser,
            loadingUser,
        };
    });
}
