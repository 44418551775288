import React, { useState, useEffect } from 'react';
import lodashGet from 'lodash/get';
import { Box, InputLabel, InputBase, Divider, CircularProgress, CardMedia, Link } from '@material-ui/core';
import styled from 'styled-components';
import LRButton from '../../LRButton';
import LRPoweredByLabel from '../../LRPoweredByLabel';
import { withTheme } from '@material-ui/core/styles';
import { COLOR_GREY_DARK, COLOR_GREY_LIGHT, COLOR_GREY_NERO, DEFAULT_HEADSHOT } from '../../../constants';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useValidationForm2 } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import LRConfirmationDialog from '../../../components/LRConfirmationDialog';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import { Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ListpackHeader from '../ListpackHeader';
import VendorCompliance from '../VendorCompliance';
import formatPhoneNumber from '../../../services/format-phone-number';
import analytics from '../../../services/analytics';
import simpleCypher from '../../../services/simple-cypher';
import InputMask from 'react-input-mask';
import { portalApi } from '../../../apis';
import { OvationBanner } from './OvationBanner';

const logger = Logger.get('ListpackEnd');

const AgentHeadshot = withTheme(styled(CardMedia)`
    margin-top: 24px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 40px;
    height: 40px;
    border-radius: 50%;

    ${({ theme })=> theme.breakpoints.up('xs')} {
        width: 64px;
        height: 64px;
    }
`);

const ListpackCoverImage = withTheme(styled(Box)`
    border-radius: 4px;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;

    ${({ theme })=> theme.breakpoints.up('sm')} {
        height: 256px;
    }

    ${({ src })=> (src ? `background: url("${src}");background-position:center;background-size: cover;` : '')}
`);

const Label = styled(InputLabel)`
    font-size: 20px;
    color: #1a1a1a;
    letter-spacing: 0.15px;
    line-height: 24px;
`;

const Input = styled(InputBase)`
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    padding: 7px 15px;
    width: 100%;
    input {
        position: relative;
        color: #000;
    }

    &.text-right {
        input {
            text-align: right;
        }
    }
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    resize: none;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    height: 150px;
    font-family: inherit !important;

    &::placeholder {
        color: ${COLOR_GREY_DARK} !important;
    }
    &:focus {
        outline: none;
    }
`;

const PageDivider = styled(Divider)`
    height: 2px;
    background-color: #eeecee;
    width: 24px;
    margin: 15px auto;
`;

const PhoneNumber = styled.a`
    color: inherit;
    text-decoration: unset;
`;

const PhoneInput = styled(Input)`
    input {
        color: ${(props)=> (props.placeholder === 'true' ? COLOR_GREY_DARK : 'black')};
    }
`;

const CustomLRButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme, color })=> theme.palette[color].main};
        color: ${({ theme, color })=> theme.palette[color].contrastText};
        opacity: 0.5;
    }
`);

const ListhubListingAgentCourtesy = ({ listing={} })=> {
    if(lodashGet(listing, 'listing_source') === 'listhub' &&
        lodashGet(listing, 'realtor.first_name')) {
        const { realtor } = listing;
        return (
            <Box pb={5 / 2} fontSize={12} color={COLOR_GREY_DARK} textAlign="center">
                Courtesy of {
                    `${lodashGet(realtor, 'first_name')} ${lodashGet(realtor, 'last_name')}, ${lodashGet(realtor, 'company')}`
                }
            </Box>
        );
    }
    return null;
};

function ListpackEnd({ onGoToList, onGoToListing}) {
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;
    const { ListpackStore } = useStore();
    const { listpack, agent, loadedListpack, loadedAgent, error, isCustomListpack } = ListpackStore;
    const { order, focus, source, listingId, address } = queryString.parse(useLocation().search);
    const [submitted, setSubmitted] = useState(false);
    const [redirectList, /* setRedirectList */] = useState(false);
    const [redirectToListing, setRedirectToListing] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [listing, setListing] = useState(null);

    const [formStatus, formData, clearForm] = useValidationForm2(
        {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        {
            name: ['required'],
            email: ['required', 'email'],
            phone: ['required', /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/],
            message: ['required'],
        }
    );
    const { name, email, phone, message } = formData;

    const [filledForm, setFilledForm] = useState(false);
    const [showPlaceholder, setShowPlaceholder] = useState(!phone.inputProps.value.replace(/[^0-9]+/g, ''));

    useEffect(
        function() {
            const filledInputs =
                name.inputProps.value && email.inputProps.value && phone.inputProps.value && message.inputProps.value;

            if(filledInputs !== filledForm) {
                setFilledForm(filledInputs);
            }

            setShowPlaceholder(!phone.inputProps.value.replace(/[^0-9]+/g, ''));
        },
        [name.inputProps.value, email.inputProps.value, phone.inputProps.value, message.inputProps.value, filledForm]
    );

    useEffect(
        function() {
            if(String(focus) !== 'true') {
                window.scrollTo({ top: 0 });
            }
        },
        [focus]
    );

    useEffect(
        function () {
            async function _getListingById() {
                if(!listpack) return;
                if(!listingId) return;
                try {
                    const response = await ListpackStore.getCachedListingById(listingId);
                    if(response) setListing(response);
                } catch (err) {
                    logger.error('Error fetching cached listing', err);
                }
            };
            _getListingById();
        },
        [ListpackStore, listingId, listpack]
    );

    async function createLead(e) {
        if(!loadedListpack || !loadedAgent) return;

        try {
            e.preventDefault();
            setSubmitted(true);
            if(formStatus.isValid) {
                const meta = getLeadMetadata();
                let data = {
                    applicant: {
                        name: name.inputProps.value,
                        email: email.inputProps.value,
                        // Remove InputMask added characters
                        phone: phone.inputProps.value.replace(/[^0-9]+/g, ''),
                    },
                    isCustomListpack,
                    listpackId: listpack.id,
                    message: message.inputProps.value,
                };
                if(meta) data.meta = meta;

                await portalApi.createLeadFromListpack(agent, data, listpack);
                setOpenConfirmationModal(true);

                analytics.eventTrack('listpacks_consumer_backcover_lead_form_submit', {
                    distinct_id: agent.id,
                    listpack_id: listpack.id,
                    'current url': window.location,
                });
            }
        } catch (err) {
            setSubmitted(false);
            logger.error(err.message);
            throw err.message;
        }
    }

    function getLeadMetadata() {
        if(!listingId) return null;
        const { id: listing_id, source: listing_source } = simpleCypher.decode(listingId) || {};
        if(!listing_id) return null;
        const baseUrl = `${window.location.protocol || 'https:'}//${window.location.hostname}`;
        const listing_url = `${baseUrl}/listpack/${ListpackStore.listpackEncodedPayload}/listings/${listingId}?order=${order}`;
        return {
            listing_id,
            listing_source,
            listing_url,
            address,
        };
    }

    function onViewAgainClick() {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_backcover_view_again_click', {
            distinct_id: agent.id,
            listpack_id: listpack.id,
            'current url': window.location,
        });

        if(listingId) {
            onGoToListing({ id: listingId });
        } else {
            onGoToList();
        }
    }

    if(redirectList) {
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/list?order=${order}${source ? `&source=${source}` : ''}`} />;
    }

    if(redirectToListing && listingId) {
        return <Redirect to={`/listpack/${ListpackStore.listpackEncodedPayload}/listings/${listingId}?order=${order}`} />;
    }

    return (
        <>
            <ListpackHeader />
            {(!loadedListpack || !loadedAgent || error) && (
                <Box display="flex" flex={1} alignItems="center" justifyContent="center" width="100%" height="100%">
                    {!error && <CircularProgress color="secondary" />}
                    {error && <>There was an error while loading the listpack. Please try again later.</>}
                </Box>
            )}
            {loadedListpack && loadedAgent && !error && (
                <Box px={[0, 5]} pb={[0, 5]}>
                    <Box width={1}>
                        <ListpackCoverImage  mx={[2, 0]}
                            src={
                                (listingId && listing)
                                    ? (listing.hero_image || listing.photos[0])
                                    : listpack.hero_image
                            }
                        >
                            <LRPoweredByLabel />
                            <Box
                                position="absolute"
                                width={1}
                                bottom={0}
                                top="50%"
                                style={{ background: 'linear-gradient(180deg, rgba(56,56,56,0.00) 0%, #1A1A1A 120%)' }}
                            />
                            <Box position="absolute"
                                width="100%"
                                bottom={0}
                                mb={3}
                                px={2}
                            >
                                <Box color="#FFF" fontSize={[24, 48]} textAlign="center">
                                    {(listingId && listing)
                                        ? listing.address.split(/,(.+)/)[0]
                                        : listpack.name
                                    }
                                </Box>
                                <Box
                                    style={{ cursor: 'pointer' }}
                                    onClick={onViewAgainClick}
                                    color="#FFF"
                                    fontSize={[14, 20]}
                                    textAlign="center"
                                >
                                    View{!listingId && ' Again'}
                                </Box>
                            </Box>
                        </ListpackCoverImage>
                    </Box>
                    {loadedAgent && (
                        <Box mt={3}>
                            <Box align="center"
                                color={COLOR_GREY_DARK}
                                letterSpacing={1.5}
                                fontSize={11}
                            >
                                PRESENTED BY
                            </Box>
                            <AgentHeadshot image={agent.headshot || DEFAULT_HEADSHOT} />
                            <Box mb={3} align="center">
                                <Box fontSize="20px">{agent.name}</Box>
                                <Box position="relative" fontSize="14px">
                                    <Box>{agent.company}</Box>
                                    <Box>
                                        <PhoneNumber href={isMobile ? `tel:${agent.phone}` : undefined}>
                                            {formatPhoneNumber(agent.phone)}
                                        </PhoneNumber>{' '}
                                        • License #{agent.normalized_license_number}
                                    </Box>
                                    <Box>{agent.email}</Box>
                                </Box>
                            </Box>
                            <Box textAlign="center" mb={3}>
                                <PageDivider></PageDivider>
                            </Box>
                            {agent.preferred_vendor && agent.preferred_vendor.vendor?.company_type === 'Mortgage' && (
                                <Box pb={5 / 2} fontSize={12} color={COLOR_GREY_DARK} textAlign="center">
                                    <Box>Financing available through {agent.preferred_vendor.name}</Box>
                                    <Box>{agent.preferred_vendor.company}</Box>
                                    <Box>
                                        <PhoneNumber
                                            href={isMobile ? `tel:${agent.preferred_vendor.phone}` : undefined}
                                        >
                                            {formatPhoneNumber(agent.preferred_vendor.phone)}
                                        </PhoneNumber>{' '}
                                        • NMLS #{agent.preferred_vendor.normalized_license_number}
                                    </Box>
                                    <Box>{agent.preferred_vendor.email}</Box>
                                </Box>
                            )}

                            <ListhubListingAgentCourtesy listing={listing} />
                        </Box>
                    )}
                    <Box
                        display="flex"
                        flexDirection="column"
                        bgcolor={COLOR_GREY_LIGHT}
                        justifyContent="space-between"
                        marginTop="16px"
                        borderRadius="4px"
                        pl={[2, 5]}
                        pr={[2, 5]}
                        pt={[3, 4]}
                        pb={3}
                    >
                        <form onSubmit={createLead}>
                            <Box mb={3}>
                                <Box fontSize={24} color={COLOR_GREY_NERO} letterSpacing={0.15}>
                                    Get in touch with {agent.name}
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="space-between" mb={3} flexDirection={['column', 'row']}>
                                <Box width={1}>
                                    <Box mb="16px">
                                        <Label>Full name*</Label>
                                    </Box>
                                    <Box>
                                        <Input
                                            autoFocus={String(focus) === 'true'}
                                            name="name"
                                            {...name.inputProps}
                                            error={submitted && !name.isValid}
                                        />
                                        {submitted && !name.isValid && (
                                            <Box fontSize={14} color="error.main" my="4px">
                                                Please enter a name
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                {/* Phone Number Desktop */}
                                {!isMobile && (
                                    <Box ml={4} width={210}>
                                        <Box mb={2}>
                                            <Label>Phone number*</Label>
                                        </Box>
                                        <Box>
                                            <InputMask
                                                mask="999-999-9999"
                                                maskChar={showPlaceholder ? 'X' : ''}
                                                alwaysShowMask={true}
                                                {...phone.inputProps}
                                            >
                                                {(inputProps)=> (
                                                    <PhoneInput
                                                        placeholder={`${showPlaceholder}`}
                                                        type="tel"
                                                        error={submitted && !phone.isValid}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputMask>
                                        </Box>
                                        {submitted && !phone.isValid && (
                                            <Box fontSize={14} color="error.main" my="4px">
                                                Please enter a valid phone number
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                            <Box width={1} mb={3}>
                                <Box mb={2}>
                                    <Label>Email address*</Label>
                                </Box>
                                <Box width={1}>
                                    <Input
                                        name="email"
                                        error={submitted && !email.isValid}
                                        {...email.inputProps}
                                        type="email"
                                    />
                                </Box>
                                {submitted && !email.isValid && (
                                    <Box fontSize={14} color="error.main" my="4px">
                                        Please enter a valid email number
                                    </Box>
                                )}
                            </Box>

                            {/* Phone Number Mobile */}
                            {isMobile && (
                                <Box mb={3}>
                                    <Box mb={2}>
                                        <Label>Phone number*</Label>
                                    </Box>
                                    <Box>
                                        <InputMask
                                            mask="999-999-9999"
                                            maskChar={showPlaceholder ? 'X' : ''}
                                            alwaysShowMask={true}
                                            {...phone.inputProps}
                                        >
                                            {(inputProps)=> (
                                                <PhoneInput
                                                    placeholder={`${showPlaceholder}`}
                                                    type="tel"
                                                    error={submitted && !phone.isValid}
                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </Box>
                                    {submitted && !phone.isValid && (
                                        <Box fontSize={14} color="error.main" my="4px">
                                            Please enter a valid phone number
                                        </Box>
                                    )}
                                </Box>
                            )}
                            <Box mb={3}>
                                <Box mb={2}>
                                    <Label>Your message*</Label>
                                </Box>
                                <Box>
                                    <StyledTextArea
                                        name="message"
                                        placeholder="Enter your message here!"
                                        error={submitted && !message.isValid}
                                        {...message.inputProps}
                                    />
                                </Box>
                                {submitted && !message.isValid && (
                                    <Box fontSize={14} color="error.main" my="4px">
                                        Please enter a message
                                    </Box>
                                )}
                            </Box>
                            <Box display="flex"
                                mb={3}
                                justifyContent={['center','flex-end']}
                                flexDirection={['column', 'row']}
                            >
                                <CustomLRButton
                                    disabled={!filledForm || openConfirmationModal}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        width: `${isMobile ? '100%' : '114px'}`,
                                        height: '48px',
                                        fontSize: '16px',
                                        minWidth: '120px',
                                    }}
                                >
                                    Contact me
                                </CustomLRButton>
                            </Box>
                            <Box fontWeight={500}>
                                By clicking Contact me, you consent to receive emails, calls, and texts,
                                 including marketing by autodialer and prerecorded and artificial voice,
                                 from the real estate professionals identified on this page and ListReports
                                 about your inquiry and other home buying/selling related matters.
                                 You also agree to ListReports&nbsp;
                                <Link color="secondary" href="https://welcome.listreports.com/terms-conditions" target="_blank">Terms of Service</Link>
                                &nbsp;and <Link color="secondary" href="https://welcome.listreports.com/privacy-policy" target="_blank">Privacy Policy</Link>.
                                 Msg/data rates may apply.
                            </Box>
                        </form>
                    </Box>
                    {openConfirmationModal && (
                        <LRConfirmationDialog
                            title="Thanks!"
                            okButton={{ label: 'OK' }}
                            open={openConfirmationModal}
                            onClose={()=> {
                                clearForm();
                                setSubmitted(false);
                                setOpenConfirmationModal(false);
                                setRedirectToListing(true);
                            }}
                        >
                            <Box>
                                {agent.name} Will contact you shortly
                            </Box>
                        </LRConfirmationDialog>
                    )}

                    {agent && agent.preferred_vendor && (
                        <VendorCompliance
                            vendorUser={agent.preferred_vendor}
                            isMobile={isMobile}
                        />
                    )}
                </Box>
            )}
        </>
    );
}

export default observer(ListpackEnd);
