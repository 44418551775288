import { useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../mobx-store';
import analytics from '../../../../services/analytics';
import { useOnboarding } from '../../store';
import { reaction } from 'mobx';
import { addEventListener } from '../../utils';

export function useMixpanelEvents() {
    const onboarding = useOnboarding();
    const { boardsStore } = useStore();

    useEffect(()=> {
        function showHandler({ step, previous }) {
            if(previous?.options?.analytics?.nextEvent) {
                analytics.eventTrack(previous?.options?.analytics?.nextEvent);
            }
        }

        function completeHandler() {
            const initialDate = moment(onboarding.startDate);
            const currentDate = moment(new Date());
            const duration = currentDate.diff(initialDate, 's');

            analytics.eventTrack('robin_fre_onboarding_completed', {
                board_id: boardsStore.activeBoard?.id,
                onboarding_duration: `${duration} seconds`,
            });
        }

        onboarding.registerTourEvent('show', showHandler);
        onboarding.registerTourEvent('complete', completeHandler);
        return ()=> {
            onboarding.unregisterTourEvent('show', showHandler);
            onboarding.unregisterTourEvent('complete', completeHandler);
        };
    }, [onboarding, boardsStore]);
}

export function useNeedsToExpandSearch() {
    const onboarding = useOnboarding();
    useEffect(()=> {
        const dispose = reaction(
            ()=> onboarding.needsToExpandSearch,
            ()=> {
                if(!onboarding.needsToExpandSearch) {
                    return false;
                }

                const tour = onboarding.tour;
                const step = tour.getById('expand-search');
                step.updateStepOptions({ text: 'No homes matching your criteria are on the market in this area. Try adding nearby zipcodes or cities.' });

                tour.show('expand-search', false);

                onboarding.needsToExpandSearch = false;
            }
        );
        return ()=> {
            dispose();
        };
    }, []);
}

export function useEnsureNameIsFilled() {
    const onboarding = useOnboarding();
    useEffect(()=> {
        function handler(e) {
            const tour = onboarding.tour;
            const step = tour.getById('name-listpack');
            const buttonOptions = step.options.buttons;
            const nextButton = buttonOptions[0];
            if(e.target.value && !nextButton.disabled) {
                return;
            }
            if(!e.target.value && nextButton.disabled) {
                return;
            }
            nextButton.disabled = !e.target.value;
            step.updateStepOptions({ buttons: buttonOptions });
        }

        const removeHandler = addEventListener('.tour-target-listpack-name input', 'input', handler);
        
        return ()=> {
            if(removeHandler) {
                removeHandler();
            }
        };
    }, [onboarding]);
}
