import { useState } from 'react';

export function useMenu() {
    const [menuAnchorEl, setMenuAnchorEl] = useState();

    function handleMenuOpenClick(e) {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        setMenuAnchorEl(e.currentTarget);
    }

    function handleOnMenuClose(e) {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        setMenuAnchorEl(undefined);
    }

    const menuProps = {
        anchorEl: menuAnchorEl,
        open: !!menuAnchorEl,
        onClose: handleOnMenuClose
    };

    return [menuProps, handleMenuOpenClick, handleOnMenuClose];
}
