import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useResponsive } from '../../../../hooks';
import { useActiveBoard } from '../../../../mobx-store';
import TaskLayout from './task-layout';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';

const MakeAnOfferTask = observer(function MakeAnOfferTask(props) {
    const match = useRouteMatch();
    const r = useResponsive();
    // const activeBoard = useActiveBoard();
    const { task } = props;
    const checked = !!task?.completedAt;
    const { actor, address, cta, message } = task.content;
    const strippedCta = cta.replace('/boards/', '');

    return (
        <TaskLayout {...props} viewTo={`${match.url}/${strippedCta}`}>
            <div>
                <Typography
                    // variant="body2"
                    variant={r({ xs: checked ? 'body2' : 'body1', md: checked ? 'body2' : 'h6' })}
                >
                    Help {actor?.first_name} prepare an offer for {' '}
                    <span
                        style={{
                            textDecoration: checked ? 'none' : 'underline',
                            fontWeight: 'bold'
                        }}
                    >
                        {address}.
                    </span>
                </Typography>
                {message && <Typography variant="body2">"{message}"</Typography>}
            </div>
        </TaskLayout>
    );
});

export default MakeAnOfferTask;
