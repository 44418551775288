import { benoitApi } from 'apis';
import { useListingsDetails } from 'hooks/useListingsDetails';
import Logger from 'js-logger';
import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { CACHE_KEY } from '../useMyHome/constants';
import { MyHomeContext } from '../useMyHome/MyHomeProvider';
import { transformMarketActivityListing } from '../useMyHome/transform-market-activity-listing';

const logger = Logger.get('useNeighborhoodMarketActivity');

export function useNeighborhoodMarketActivity(area = null) {
    const { myHome } = useContext(MyHomeContext);
    const marketActivityQuery = useQuery(
        [`${CACHE_KEY}-market-activity`, area],
        () =>
            myHome?.zipcode === area
                ? benoitApi.getMyHomeMarketActivityByAddress(encodeURIComponent(myHome?.address))
                : benoitApi.getMyHomeMarketActivityByAreaId(encodeURIComponent(area)),
        {
            enabled: Boolean(area),
            select: (data) => data.map((listing) => transformMarketActivityListing(listing)),
        }
    );
    /**
     * Enhances the data so we get more photos and other details
     */
    const listingDetailsQuery = useListingsDetails(marketActivityQuery.data);

    return listingDetailsQuery;
}
