import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Coffee(props) {
    return (
        <SvgIcon {...props} >
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M19 3H5C3.9 3 3 3.9 3 5V13C3 15.21 4.79 17 7 17H13C15.21 17 17 15.21 17 13V10H19C20.11 10 21 9.1 21 8V5C21 3.9 20.1 3 19 3ZM19 8H17V5H19V8ZM18 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21H18C18.55 21 19 20.55 19 20C19 19.45 18.55 19 18 19Z" fill='#1A1A1A' {...props}/>
        </SvgIcon>
    );
}
