import React from 'react';
import { Typography, useTheme, TextField, InputAdornment, makeStyles, CircularProgress } from '@material-ui/core';

const useTextFieldsStyles = makeStyles({
    root: {
        'background': '#FFF',
        'font-size': '16px',
        
        '& input': {
            'font-size': '16px',
            'color': '#1A1A1A',
        },

        '&:focus-within input': {
            'color': '#1A1A1A',
        },  

        '& fieldset': {
            'border': '1px solid #BBBBBB !important',
        },

        '&:hover fieldset': {
            'border': '1px solid #BBBBBB !important'
        },

        '&:focus-within fieldset': {
            'border': '1px solid #BBBBBB !important',
        },

        '& ::-webkit-input-placeholder': {
            'font-size': '16px'
        }, 
        '& ::-moz-placeholder': {
            'font-size': '16px'
        }
    }
});

export const EmailInput = function({ setInputValue, onSubmit, textFieldProps, adornmentProps, loading, defaultValue }) {
    const theme = useTheme();
    const textFieldStyles = useTextFieldsStyles();

    return (
        <TextField
            classes={textFieldStyles}
            variant="outlined"
            color="primary"
            placeholder="Enter your email"
            onChange={(e)=> setInputValue(e.currentTarget.value)}
            InputProps={{
                endAdornment:
                    <>
                        <InputAdornment position="end">
                            <div
                                style={{
                                    color: '#fff',
                                    border: `1px solid ${theme.palette.lr_colors.brick}`,
                                    backgroundColor: theme.palette.lr_colors.brick,
                                    position: 'relative',
                                    left: '14px',
                                    zIndex: 2,
                                    borderRadius: '0px 4px 4px 0px',
                                    display: 'flex',
                                    height: '48px',
                                    width: '127px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    ...adornmentProps
                                }}
                                onClick={onSubmit}
                            >
                                {!!loading && <CircularProgress color="white" size={20} />}
                                {!loading && <Typography variant="body2">Subscribe</Typography>}
                            </div>
                        </InputAdornment>
                    </>,
                style: {
                    height: '48px',
                    borderRadius: '2px 4px 4px 2px',
                    ...textFieldProps
                },
            }}
            size="small"
            fullWidth
            defaultValue={defaultValue}
        />
    );
};
