import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Fade, Grid, IconButton, Typography, CircularProgress, ThemeProvider, AppBar, Toolbar, Slide } from '@material-ui/core';
import LRButton from 'components/LRButton';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import { LRInputOutlined } from '../../../components/LRInput';
import Logger from 'js-logger';
import { useForm } from 'react-hook-form';
import addCommas from 'services/add-commas';
import { CloseRounded } from '@material-ui/icons';
import { uploadImageToThumbor } from 'services/thumborizer';
import { LRDialog } from 'components/LRDialog';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { benoitApi } from 'apis';
import { useActiveBoard } from 'mobx-store';
import Address from 'lana';
import { useMyHome } from '../../../hooks/useMyHome';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';
import { geocoder } from '../../../services/gmaps';
import { MuiPrimaryBlackTheme } from 'theme';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { LRAddressAutocompleteInput } from 'components/LRAddressAutocomplete';
import analytics from 'services/analytics';
import _ from 'lodash';

const logger = Logger.get('EditMyHomeModal');

function ActionCTAs({
    onClose,
    onDeleteHome,
    disableButtons = false,
    style = {},
    ...props
}) {
    const theme = useTheme();
    const { isLoggedIn } = useIsLoggedIn();
    const { isMobile } = useDeviceBreakpoints();
    const { myHome } = useMyHome();

    return (
        <DialogActions
            {...props}
            style={{
                padding: theme.spacing(isMobile ? 2 : 3),
                paddingTop: isMobile ? theme.spacing(2) - 2 : 0,
                paddingBottom: isMobile ? theme.spacing(2) - 2 : theme.spacing(3),
                ...style,
            }}
        >
            <Grid item xs>
                {!isMobile && (
                    <LRButton variant="text" inline underline color="inherit" onClick={analytics.eventTrackOnEvent('robin_home_report_edit_home_cancel_click', {}, onClose)} style={{ marginRight: theme.spacing(4) }}>
                        Cancel
                    </LRButton>
                )}
            </Grid>

            {isLoggedIn && Boolean(myHome) && (
                <Grid item>
                    <LRButton type="button" variant="outlined" color="default" disabled={disableButtons} onClick={analytics.eventTrackOnEvent('robin_home_report_edit_home_delete_click', {}, onDeleteHome)}>
                        Delete home
                    </LRButton>
                </Grid>
            )}

            <Grid item>
                <LRButton type="submit" variant="contained" color="primary" disabled={disableButtons} onClick={analytics.eventTrackOnEvent('robin_home_report_edit_home_save_click', {})}>
                    {myHome ? 'Save' : 'Add home'}
                </LRButton>
            </Grid>
        </DialogActions>
    );
}

function MobileHeader({
    onClose,
    style = {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();
    const { myHome } = useMyHome();

    return (
        <>
            <AppBar
                color="inherit"
                elevation={0}
                {...props}
                style={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.lr_colors.border_grey,
                    ...style,
                }}
            >
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <Grid item xs></Grid>

                    <Grid item xs={8}>
                        <Typography align="center">
                            {myHome ? 'Edit home' : 'Add your home'}
                        </Typography>
                    </Grid>

                    <Grid item xs style={{ textAlign: 'right' }}>
                        <IconButton edge="end" onClick={onClose}>
                            <CloseRounded />
                        </IconButton>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Toolbar style={{ height: isMobile ? 56 : 67 }} />
        </>
    );
}

export function EditMyHomeModal({
    open = false,
    PaperProps = {},
    onSave = async (formData)=> {},
    onHeroImageUploadError = (err)=> {},
    onClose = (e)=> {},
    ...props
}) {
    const HERO_IMG_WIDTH = '100%';
    const HERO_IMG_HEIGHT = 160;
    const theme = useTheme();
    const { myHome: home, updateMyHome, areaIds } = useMyHome();
    const [address, setAddress] = useState(home?.address || '');
    const [lotSize, setLotSize] = useState(addCommas(home?.lot_size || ''));
    const [livingArea, setLivingArea] = useState(addCommas(home?.living_area || ''));
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const [newHeroImage, setNewHeroImage] = useState();
    const [showHeroImagePreview, setShowHeroImagePreview] = useState(false);
    const [showRemoveHeroImageBtn, setShowRemoveHeroImageBtn] = useState(false);
    const [loadingListingDetails, setLoadingListingDetails] = useState(false);
    const [center, setCenter] = useState([33.78724, -117.85496]);
    const heroImagePreview = useMemo(()=> {
        if(!newHeroImage) return;

        return URL.createObjectURL(newHeroImage);
    }, [newHeroImage]);
    const { register, handleSubmit, setValue, setError, errors, reset, triggerValidation, watch } = useForm({
        defaultValues: {
            ...home,
        }
    });
    const [heroImageInputRef, setHeroImageInputRef] = useState();
    const { isMobile } = useDeviceBreakpoints();
    const activeBoard = useActiveBoard();
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const heroImage = watch('hero_image');

    // UseEffects
    useEffect(()=> {
        if(open) {
            onRemovePreviewHeroImageClick();
            reset({
                ...(home || {}),
            });
            setAddress(home?.address || '');
            setLotSize(addCommas(home?.lot_size || ''));
            setLivingArea(addCommas(home?.living_area || ''));
            setCenter([home?.latitude, home?.longitude]);
        }
    }, [open]);

    useEffect(()=> {
        register('address', { required: true });
        register('lot_size', { required: false });
        register('living_area', { required: false });
        register('hero_image', { required: false });
    }, [register]);

    useEffect(()=> {
        function getGeoLocationFromAddress() {
            geocoder.geocode(address)
                .then((results)=> {
                    const location = results[0].geometry.location;
                    const lat = location.lat();
                    const lng = location.lng();
                    setCenter([lat, lng]);
                });
        }

        if(address){
            getGeoLocationFromAddress();
        }
    },[address]);

    // Functions
    async function onAddressChange(value) {
        const addressChanged = value !== address;

        setValue('address', value);
        setAddress(value);

        if(Boolean(errors.type)) return triggerValidation('address');

        if(addressChanged && Address.isValid(value) && (address === '' || !Address.isSame(address, value))) {
            let listingData = {};
            try {
                setDisableSaveBtn(true);
                setLoadingListingDetails(true);
                listingData = await benoitApi.getMyHomeAddressListingDetails(activeBoardId, value);
            } catch (err) {
                logger.error(err);
            } finally {
                setDisableSaveBtn(false);
                setLoadingListingDetails(false);
                setValue('bedrooms', listingData?.bedrooms || '');
                setValue('bathrooms', listingData?.bathrooms || '');
                setValue('lot_size', listingData?.lot_size || '');
                setLotSize(addCommas(listingData?.lot_size || ''));
                setValue('living_area', listingData?.living_area || '');
                setLivingArea(addCommas(listingData?.living_area || ''));
                setValue('hero_image', listingData?.hero_image || null);
            }
        }
    }

    function onLotSizeChange(value) {
        value = value.replace(/([^0-9])/gi, '');

        setValue('lot_size', value);
        setLotSize(addCommas(value));

        if(Boolean(errors.type)) triggerValidation('lot_size');
    }

    function handleDecimalValue(value, name) {
        let newValue = Number(value);
        if(newValue < 0){
            setValue(name, null);
            return;
        }

        if(!isNaN(newValue) && !Number.isInteger(newValue) ){
            newValue = (Math.round(newValue * 4) / 4).toFixed(2);
            setValue(name, Number(newValue));
        }
    }

    function validateBedsAndBaths(value, validateDecimal = false){
        let newValue = Number(value);
        if(newValue < 0){
            return null;
        }

        if(!isNaN(newValue) && !Number.isInteger(newValue) && validateDecimal){
            newValue = (Math.round(newValue * 4) / 4).toFixed(2);
            return newValue;
        }

        return value;
    }

    function handleBedrooms(value) {
        const newValue = Number.parseInt(value);
        if(newValue < 0){
            setValue('bedrooms', null);
        } else {
            setValue('bedrooms', newValue);
        }
    }

    function onSqFtChange(value) {
        value = value.replace(/([^0-9])/gi, '');

        setValue('living_area', value);
        setLivingArea(addCommas(value));

        if(Boolean(errors.type)) triggerValidation('living_area');
    }

    async function onSubmit(formData) {
        if(home?.address !== formData.address && !Address.isValid(formData.address)){
            Logger.debug('INVALID ADDRESS');
            setError('address', { type: 'manual', message: 'Please provide a valid address' });
            return;
        }

        setDisableSaveBtn(true);

        if(newHeroImage) {
            try {
                const heroImageUrl = await uploadImageToThumbor(newHeroImage);
                formData.hero_image = heroImageUrl;
            } catch (err) {
                logger.error('onSubmit -> Error', err);
                setDisableSaveBtn(false);
                return onHeroImageUploadError(err);
            }
        }

        const data = {
            ...formData,
            bedrooms: validateBedsAndBaths(formData.bedrooms),
            bathrooms: validateBedsAndBaths(formData.bathrooms, true)
        };

        await onSave(data);
        setDisableSaveBtn(false);
    }

    function onHeroImageChange(e) {
        setNewHeroImage(e.target.files[0]);
        setShowHeroImagePreview(true);
    }

    function onRemovePreviewHeroImageClick() {
        setShowRemoveHeroImageBtn(false);
        setNewHeroImage(null);
        setShowHeroImagePreview(false);
        if(heroImageInputRef) heroImageInputRef.value = null;
    }

    function addressErrorMsg() {
        if(errors.address?.isManual){
            return errors.address?.types?.message;
        } else {
            return 'Please provide your homes address.';
        }
    }

    async function onDeleteHome(e) {
        setDisableSaveBtn(true);
        try {
            const homeCloned = _.cloneDeep(home);
            _.each(_.keys(homeCloned), (key)=> homeCloned[key] = null);
            await updateMyHome({
                ...homeCloned,
                createdAt: home.createdAt,
                boardId: home.boardId,
                id: home.id,
                archived: home.archived,
                third_party_ids: home.third_party_ids,
                updatedAt: home.updatedAt,
                areaIds
            });
        } finally {
            setDisableSaveBtn(false);
        }

        onClose(e);
    }

    return (
        <LRDialog
            open={open}
            fullScreen={isMobile}
            PaperProps={{
                style: {
                    borderRadius: isMobile && 0,
                },
                ...PaperProps,
            }}
            onClose={onClose}
            TransitionComponent={isMobile ? Slide : Fade}
            TransitionProps={isMobile && {
                direction: 'up',
            }}
            {...props}
        >
            <form name="editMyHomeForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                {isMobile && (
                    <MobileHeader
                        onClose={onClose}
                    />
                )}

                <DialogContent
                    style={{
                        width: !isMobile && 376,
                        paddingTop: isMobile && 0,
                        paddingLeft: isMobile && 0,
                        paddingRight: isMobile && 0,
                    }}
                >
                    {/*Priority hierarchy to show image
                        1. uploaded image
                        2. when address empty, hero-image-placeholder
                        3. hero_image from address changed getMyHomeAddressListingDetails
                        4. hero_image from props.home object
                        5. show Google Sattelite image
                    */}

                    {home && (
                        <div
                            style={{
                                position: 'relative',
                            }}
                        >
                            {(showHeroImagePreview || address === '' || ((address === home?.address || home?.hero_image !== heroImage) && heroImage)) ? (
                                <div
                                    style={{
                                        position: 'relative',
                                        width: HERO_IMG_WIDTH,
                                        height: HERO_IMG_HEIGHT,
                                        borderRadius: !isMobile && 4,
                                        backgroundImage: `url("${(address && heroImage) || '/images/hero-image-placeholder-grey.png'}")`,
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'cover',
                                    }}
                                    onMouseEnter={()=> setShowRemoveHeroImageBtn(true)}
                                    onMouseLeave={()=> setShowRemoveHeroImageBtn(false)}
                                >
                                    <Fade in={showHeroImagePreview} onExited={()=> setNewHeroImage(null)}>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                width: HERO_IMG_WIDTH,
                                                height: HERO_IMG_HEIGHT,
                                                borderRadius: 4,
                                                backgroundImage: `url("${heroImagePreview}")`,
                                                backgroundPosition: 'center center',
                                                backgroundSize: 'cover',
                                            }}
                                        ></div>
                                    </Fade>

                                    <Fade in={showRemoveHeroImageBtn && showHeroImagePreview}>
                                        <Grid
                                            container
                                            justify="center"
                                            alignItems="center"
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                color: '#fff',
                                            }}
                                        >
                                            <Grid item>
                                                <IconButton edge="end" color="inherit" title="Remove new hero image" onClick={onRemovePreviewHeroImageClick}>
                                                    <CloseRounded style={{ textShadow: '4px 4px', }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Fade>
                                </div>
                            ) : (
                                <div style={{padding: '12px'}}>
                                    <div style={{width: HERO_IMG_WIDTH, height: HERO_IMG_HEIGHT}}>
                                        <StaticGoogleMaps
                                            latitude={center[0] || home?.latitude}
                                            longitude={center[1] || home?.longitude}
                                        />
                                    </div>
                                </div>
                            )}

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    right: 0,
                                    marginTop: -18,
                                    textAlign: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'inline-block',
                                    }}
                                >
                                    <input
                                        ref={setHeroImageInputRef}
                                        id="hero-image"
                                        name="hero_image"
                                        accept="image/*"
                                        type="file"
                                        style={{
                                            display: 'none',
                                        }}
                                        onChange={onHeroImageChange}
                                    />

                                    <label htmlFor="hero-image">
                                        <ThemeProvider theme={MuiPrimaryBlackTheme}>
                                            <LRButton variant="contained" component="span" color="secondary">
                                                Change photo
                                            </LRButton>
                                        </ThemeProvider>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            marginTop: theme.spacing(home ? 2 : 3),
                            paddingLeft: isMobile && theme.spacing(2),
                            paddingRight: isMobile && theme.spacing(2),
                        }}
                    >
                        <div>
                            <LRAddressAutocompleteInput
                                autoFocus={!isMobile && !address}
                                fullWidth
                                label="Address"
                                name="address"
                                value={address}
                                error={Boolean(errors.address)}
                                helperText={Boolean(errors.address) && addressErrorMsg()}
                                onChange={(address)=> onAddressChange(address)}
                                autoSelect={false}
                                required
                                
                            />
                        </div>

                        <Grid container spacing={2}>
                            <Grid item xs>
                                <LRInputOutlined
                                    label="Beds"
                                    name="bedrooms"
                                    error={Boolean(errors.bedrooms)}
                                    autoComplete="off"
                                    endAdornment={loadingListingDetails && (
                                        <div style={{ paddingRight: theme.spacing(1.5), position: 'relative', top: 1, }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    )}
                                    ref={register({ required: false })}
                                    helperText={Boolean(errors.bedrooms) && 'Please provide your homes total bathrooms.'}
                                    inputProps={{type: 'number'}}
                                    onBlur={(e)=> handleBedrooms(e.target.value) }
                                />
                            </Grid>
                            <Grid item xs>
                                <LRInputOutlined
                                    label="Baths"
                                    name="bathrooms"
                                    error={Boolean(errors.bathrooms)}
                                    autoComplete="off"
                                    endAdornment={loadingListingDetails && (
                                        <div style={{ paddingRight: theme.spacing(1.5), position: 'relative', top: 1, }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    )}
                                    ref={register({ required: false })}
                                    helperText={Boolean(errors.bathrooms) && 'Please provide your homes total bathrooms.'}
                                    inputProps={{type: 'number'}}
                                    onBlur={(e)=> handleDecimalValue(e.target.value, 'bathrooms') }
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs>
                                <LRInputOutlined
                                    value={livingArea}
                                    label="SqFt"
                                    name="living_area"
                                    error={Boolean(errors.living_area)}
                                    autoComplete="off"
                                    endAdornment={loadingListingDetails && (
                                        <div style={{ paddingRight: theme.spacing(1.5), position: 'relative', top: 1, }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    )}
                                    inputProps={{ maxLength: 7 }}
                                    helperText={Boolean(errors.living_area) && 'Please provide your homes sqft.'}
                                    onChange={(e)=> onSqFtChange(e.currentTarget.value)}
                                />
                            </Grid>
                            <Grid item xs>
                                <LRInputOutlined
                                    value={lotSize}
                                    label="Lot Size"
                                    name="lot_size"
                                    error={Boolean(errors.lot_size)}
                                    autoComplete="off"
                                    endAdornment={loadingListingDetails ? (
                                        <div style={{ paddingRight: theme.spacing(1.5), position: 'relative', top: 1, }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    ) : (
                                        <Typography variant="body1" style={{ color: theme.palette.lr_colors.steak_sauce, paddingRight: theme.spacing(1.5) }}>
                                            SqFt
                                        </Typography>
                                    )}
                                    inputProps={{ maxLength: 7 }}
                                    helperText={Boolean(errors.lot_size) && 'Please provide your homes lot size.'}
                                    onChange={(e)=> onLotSizeChange(e.currentTarget.value)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>

                {isMobile ? (
                    <AppBar
                        color="inherit"
                        elevation={0}
                        style={{
                            top: 'auto',
                            bottom: 0,
                            borderTop: '1px solid',
                            borderTopColor: theme.palette.lr_colors.border_grey,
                            backgroundColor: theme.palette.lr_colors.costa_mesa,
                        }}
                    >
                        <Toolbar style={{ padding: 0 }}>
                            <Grid item xs>
                                <ActionCTAs
                                    disableButtons={disableSaveBtn}
                                    onClose={onClose}
                                    onDeleteHome={onDeleteHome}
                                />
                            </Grid>
                        </Toolbar>
                    </AppBar>
                ) : (
                    <ActionCTAs
                        disableButtons={disableSaveBtn || (!home?.address && !address)}
                        onClose={onClose}
                        onDeleteHome={onDeleteHome}
                    />
                )}
            </form>
        </LRDialog>
    );
}

EditMyHomeModal.propTypes = {
    ...Dialog.propTypes,
    home: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
};
