import React, { useMemo, useState } from 'react';
import { benoitApi, portalApi } from 'apis';
import { useActiveBoard, useStore } from 'mobx-store';
import { createContext } from 'react';
import { CACHE_KEY } from './constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Logger from 'js-logger';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useQuery as useUrlQuery} from 'hooks';

const logger = Logger.get('MyHomeProvider');

export const MyHomeContext = createContext({});

export const MyHomeProvider = observer(function MyHomeProvider({ leadId, ...props }) {
    const { isLoggedIn } = useIsLoggedIn();
    const myHomeData = useMyHomeData();
    const myHomeDataFromLead = useMyHomeDataFromLead(leadId);
    const mode = useUrlQuery().get('mode');
    const isAgentOrLOMode = useMemo(()=> mode === 'ro', [mode]);

    return (
        <MyHomeContext.Provider
            {...props}
            value={isLoggedIn && !isAgentOrLOMode ? myHomeData : myHomeDataFromLead}
        />
    );
});

// Helpers
function useMyHomeDataFromLead(leadId) {
    // This is only used when logged out and the user wants to change
    // address. It does not get saved unless the user signs up and
    // is only good till the user refreshes.
    const [loggedoutAddressData, setLoggedoutAddressData] = useState();
    const [loggedoutAreas, setLoggedoutAreas] = useState();
    const value = loggedoutAddressData?.address || leadId;
    const queryClient = useQueryClient();

    const { data, isLoading, ...query } = useQuery(
        [CACHE_KEY, value],
        ({ queryKey: [, value] })=> {
            return (
                loggedoutAddressData?.address ? (
                    benoitApi.getMyHomeDetailsByAddress(encodeURIComponent(value))
                ) : (
                    benoitApi.getMyHomeDetailsByLeadId(value)
                )
            );
        },
        {
            enabled: Boolean(value),
            select: ({ lead, myHome })=> {
                if(lead && myHome) {
                    myHome.bathrooms = get(lead, 'meta.robin.home_report.bathrooms') || myHome.bathrooms;
                    myHome.bedrooms = get(lead, 'meta.robin.home_report.bedrooms') || myHome.bedrooms;
                    myHome.living_area = get(lead, 'meta.robin.home_report.living_area') || myHome.living_area;
                    myHome.lot_size = get(lead, 'meta.robin.home_report.lot_size') || myHome.lot_size;
                }

                return {
                    lead,
                    myHome,
                };
            },
        },
    );
    let lead;
    let myHome;

    if(data) {
        lead = data.lead;
        myHome = buildMyHomeObj(data.myHome);

        if(myHome) delete myHome.id; // We never want this to have an ID

        if(lead && lead.meta.robin.home_report.default_preferences.areaIds && !loggedoutAreas?.length) {
            setLoggedoutAreas(lead.meta.robin.home_report.default_preferences.areaIds);
        }
    }

    async function createMyHome({address}) {
        const data = {
            ...(loggedoutAddressData || {}),
            address,
        };

        setLoggedoutAddressData(data);

        return data;
    }

    async function updateMyHome(data) {
        data = {
            ...(loggedoutAddressData || {}),
            ...data,
        };

        setLoggedoutAddressData({
            ...(loggedoutAddressData || {}),
            ...data,
        });
        refreshQueries(queryClient);

        return data;
    }

    function updateAreas(areas) {
        setLoggedoutAreas(areas);
    }

    /**
     * @param {String} [leadId=lead.id]
     */
    async function optinToMyHomeTextMessage(leadId, source) {
        await portalApi.optinToMyHomeTextMessage(leadId || lead.id, source);
        queryClient.invalidateQueries(CACHE_KEY);
    }

    return {
        ...query,
        isLoading: isLoading || !leadId,
        myHome,
        lead,
        createMyHome,
        updateMyHome,
        updateAreas,
        optinToMyHomeTextMessage,
        isDataLocal: true,
        areaIds: loggedoutAreas,
    };
}

function useMyHomeData() {
    const { UserStore } = useStore();
    const { user } = UserStore;
    const activeBoard = useActiveBoard();
    const boardId = activeBoard?.id;
    const queryClient = useQueryClient();
    const { data, isLoading, ...query } = useQuery(
        [CACHE_KEY, boardId],
        ({ queryKey: [, boardId] })=> (benoitApi.getMyHomes(boardId)),
        {
            enabled: Boolean(boardId),
        },
    );
    
    let myHome;
    if(data?.length) {
        myHome = buildMyHomeObj(data[0]);
    }

    const createMutation = useMutation(
        (myHome)=> benoitApi.createMyHome(boardId, myHome),
        {
            onSuccess: ()=> {
                queryClient.invalidateQueries(CACHE_KEY);
            }
        }
    );

    const updateMutation = useMutation(
        (data)=> {
            return benoitApi.updateMyHome(boardId, myHome?.id || data?.id, data);
        },
        {
            onSuccess: ()=> {
                refreshQueries(queryClient);
            }
        }
    );
    
    const deleteMutation = useMutation(
        (myHome)=> (benoitApi.deleteMyHome(boardId, myHome.id)),
        {
            onSuccess: ()=> {
                refreshQueries(queryClient);
            }
        }
    );

    async function inviteUserToRobinMyHome(contactInfo) {
        await benoitApi.inviteUserToUseMyHome(boardId, contactInfo);
    }

    async function contactAgentAboutMyHome(message) {
        await benoitApi.contactAgentAboutMyHome(boardId, myHome, message);
    }

    async function contactLOAboutMyHome(message) {
        await benoitApi.contactLOAboutMyHome(boardId, message);
    }

    /**
     * @param {String} [userId=user.id]
     */
    async function optinToMyHomeTextMessage(userId, source) {
        await portalApi.optinToMyHomeTextMessage(userId || user.id, source);
        queryClient.invalidateQueries(CACHE_KEY);
    }

    return {
        ...query,
        isLoading: isLoading || !boardId,
        myHome,
        createMyHome: createMutation.mutateAsync.bind(createMutation),
        deleteMyHome: deleteMutation.mutateAsync.bind(deleteMutation),
        updateMyHome: updateMutation.mutateAsync.bind(updateMutation),
        inviteUserToRobinMyHome,
        contactAgentAboutMyHome,
        contactLOAboutMyHome,
        optinToMyHomeTextMessage,
        isDataLocal: false,
        areaIds: myHome?.areaIds,
    };
}

function refreshQueries(queryClient) {
    queryClient.invalidateQueries(CACHE_KEY);
    queryClient.invalidateQueries(`${CACHE_KEY}-avm`);
    queryClient.invalidateQueries(`${CACHE_KEY}-market-activity`);
    queryClient.invalidateQueries(`${CACHE_KEY}-buyer-stats`);
    queryClient.invalidateQueries(`${CACHE_KEY}-listing-stats`);
}

function buildMyHomeObj(data, leadId) {
    if(!data) return data;

    return {
        ...data,
        meta: {
            ...(data.meta || {}),
            leadId,
        },
        get zipcode() {
            return this.address?.slice(-5);
        },
        get splitAddress() {
            if(!this.address) return {};

            const splitAdd = this.address.split(',');
            const add1 = splitAdd.shift();
            const add2 = splitAdd.join(',');

            return {
                addressLine1: add1,
                addressLine2: add2,
            };
        },
    };
}