import { MenuItem, Popover, useTheme } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import LRButton from 'components/LRButton';
import { isString, partial } from 'lodash';
import React, { useMemo, useState } from 'react';

export function LRInputSelect({
    value,
    options, // Array of strings or { label, value }
    disabled,
    fullWidth,
    error,
    placeholder = 'Select',
    onChange = (e, value)=> {},
    ...props
}) {
    const theme = useTheme();
    const [openMenu, setOpenMenu] = useState(false);
    const [btnRef, setBtnRef] = useState();
    const minWidth = useMemo(()=> {
        if(!btnRef) return;

        return btnRef.offsetWidth;
    }, [btnRef]);

    // Functions
    function onMenuItemClick(...args) {
        setOpenMenu(false);
        onChange.apply(this, args);
    }

    return (
        <div {...props}>
            <LRButton
                disabled={disabled}
                fullWidth={fullWidth}
                variant="outlined"
                color="default"
                ref={setBtnRef}
                endIcon={<ExpandMoreRounded />}
                onClick={()=> (setOpenMenu(true))}
                style={{
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    height: 48,
                    borderColor: error ? theme.palette.lr_colors.brick : theme.palette.lr_colors.border_grey,
                }}
            >
                <div
                    style={{
                        textAlign: 'left',
                        color: !value && theme.palette.lr_colors.steak_sauce,
                    }}
                >
                    {value || placeholder}
                </div>
            </LRButton>
            <Popover
                open={openMenu}
                anchorEl={btnRef}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={()=> {
                    setOpenMenu(false);
                }}
            >
                <ul
                    style={{
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                        minWidth,
                    }}
                >
                    {options?.map((opt)=> (
                        <MenuItem key={options?.label || opt} onClick={partial(onMenuItemClick, partial.placeholder, (isString(opt) ? opt : opt?.value))}>
                            {isString(opt) ? opt : opt?.label}
                        </MenuItem>
                    ))}
                </ul>
            </Popover>
        </div>
    );
}
