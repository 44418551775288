import { useMutation, useInfiniteQuery, useQuery } from 'react-query';
import { benoitApi } from '../../../apis';
import { queryClient } from 'query-client';
import _ from 'lodash';
import produce from 'immer';
import { useBoardCollaborations } from '../../../hooks/useBoardCollaborations';

const filterOptions = ['all', 'incomplete', 'completed'];

export function useTasks(boardId, { filter, limit = 5 }) {
    return useInfiniteQuery(
        ['board-tasks', boardId, filter],
        async ({ pageParam = 0 })=> {
            const data = await benoitApi.getBoardTasks(boardId, {
                filter: filter,
                limit: limit,
                skip: pageParam
            });
            return data;
        },
        {
            getNextPageParam: (lastPage, allPages)=> {
                return lastPage.length === limit ? allPages.flat().length : false;
            },
        }
    );
}

export function useCreateTask() {
    return useMutation(({ boardId, text, userId, targetBenoitUserId })=> {
        return benoitApi.createUserTodo(boardId, {
            boardId: boardId,
            type: 'custom',
            itemId: boardId,
            model: 'Board',
            urgency: 3,
            content: {
                text,
            },
            userId,
            targetBenoitUserId,
        });
    }, {
        onSettled: (data, error, { boardId  })=> {
            queryClient.invalidateQueries('board-tasks', boardId);
        }
    });
}

export function useMarkCompleted(filter) {
    return useMutation(
        ({boardId, taskId})=> {
            return benoitApi.markTaskComplete(boardId, taskId);
        },
        {
            onMutate: ({ boardId, taskId })=> {
                const cacheKey = ['board-tasks', boardId, filter];
                queryClient.cancelQueries(cacheKey);
                const tasksGroups = queryClient.getQueryData(cacheKey);
               
                const newTasksGroups = produce(tasksGroups, (draftTasksGroups)=> {
                    const taskToUpdate = draftTasksGroups
                        .pages
                        .flatMap((tasks)=> tasks)
                        .find((t)=> t.id === taskId);
                    _.merge(taskToUpdate, { completedAt: true });
                });

                queryClient.setQueryData(cacheKey, newTasksGroups);
            },
            onSettled: (data, error, { task })=> {
                queryClient.invalidateQueries('board-tasks');
            }
        }
    );
}

export function useUpdateTask(filter) {
    return useMutation(
        ({ task, payload })=> {
            return benoitApi.updateUserTodo(task.boardId, task.id, payload);
        },
        {
            onMutate: ({ task, payload })=> {
                const cacheKey = ['board-tasks', task.boardId, filter ];
                queryClient.cancelQueries(cacheKey);
                const tasksGroups = queryClient.getQueryData(cacheKey);
                const newTasksGroups = produce(tasksGroups, (draftTasksGroups)=> {
                    const taskToUpdate = draftTasksGroups
                        .pages
                        .flatMap(( tasks )=> tasks)
                        .find((t)=> t.id === task.id);
                    _.merge(taskToUpdate, payload);
                });
                queryClient.setQueryData(cacheKey, newTasksGroups);
            },
            onSettled: (data, error, { task })=> {
                queryClient.invalidateQueries('board-tasks');
            }
        }
    );
}

export function refreshQueries(boardId, { filterOption = null, exludedFilterOption = null } = {}) {
    filterOptions
        // uncomment to leave updated task as is in the current request data
        .filter((f)=> !exludedFilterOption || exludedFilterOption !== f) // If excludedFilterOption is defined, exclude it from the request
        .filter((f)=> !filterOption || filterOption === f) // If filter is defined, use it to filter
        .forEach((f)=> {
            const cacheKey = ['board-tasks', boardId, { filter: f }];
            queryClient.refetchQueries(cacheKey, { exact: true });
        });
}
