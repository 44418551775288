import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Box, Slide, Popover, RootRef, Divider, CircularProgress, CardMedia, Snackbar } from '@material-ui/core';
import { SortIcon } from '../../../components/LRIcons';
import { Skeleton } from '@material-ui/lab';
import { ChevronLeft, Email, Close, Check } from '@material-ui/icons';
import styled from 'styled-components';
import LRButton from '../../LRButton';
import {
    COLOR_GREY,
    COLOR_GREY_DARK,
    COLOR_GREY_NERO,
    COLOR_GREY_LIGHT,
    COLOR_SALMON,
    DEFAULT_HEADSHOT,
} from '../../../constants';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { Redirect, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import addCommas from '../../../services/add-commas';
import queryString from 'query-string';
import ListpackHeader from '../ListpackHeader';
import analytics from '../../../services/analytics';
import _ from 'lodash';
import { ListpackComplianceTerms } from '../../../components/ListpackComplianceTerms';
import { sortOptions, matchSortOptions, serializeSortOption } from '../utils';

const MobilePopover = styled(Popover)`
    .MuiPopover-paper {
        max-width: unset;
        width: 100%;
        left: 0 !important;
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.4);
        border-radius: unset;
    }
`;

const DesktopPopover = styled(Popover)`
    .MuiPopover-paper {
        max-width: 360px;
        width: 100%;
        box-shadow: -5px 5px 5px -5px rgba(0, 0, 0, 0.4);
        border-radius: unset;
    }
`;

const AgentHeadshot = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const ListingHeroImage = styled(CardMedia)`
    width: 100%;
    height: 216px;
    background-position: center;
    border-radius: 4px;
`;

const SortingSnackbar = styled(Snackbar)`
    .MuiSnackbarContent-root {
        display: flex;
        justify-content: center;
    }
`;

const BackIcon = styled(ChevronLeft)`
    vertical-align: middle;
`;


function ListpackList({ sortOption, onListingClick, firstPageLimit }) {
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;

    const [showSmallerHeader, setShowSmallerHeader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
    const [redirectCover, setRedirectCover] = useState(false);
    const [redirectEnd, setRedirectEnd] = useState(false);
    const [redirectEndContact, setRedirectEndContact] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const history = useHistory();
    const match = useRouteMatch();
    const { source } = queryString.parse(useLocation().search);
    const selectedSortOption = sortOption;

    const mobileSortButton = React.useRef();
    const smallerHeaderSortButton = React.useRef();
    const headerSortButton = React.useRef();

    const { ListpackStore } = useStore();
    let {
        listpack,
        agent,
        listings,
        listingsCount,
        loadedListpack,
        loadedAgent,
        loadedListingsCount,
        loadedListings,
        loadingMoreListings,
        listingsOrder,
        error,
        compliance,
        listingsSort,
    } = ListpackStore;
    const mlsSources = useMemo(()=> _.uniqBy(listings, 'listing_details.mls_id'), [listings]);

    useEffect(
        function() {
            // TODO: Show snackbar for order change
        },
        [loadedListings]
    );

    useEffect(()=> {
        if(!listpack || !agent) {
            return;
        }
        analytics.eventTrack('listpacks_consumer_listview_page_viewed', {
            listpack_id: listpack.id,
            distinct_id: agent.id,
        });
    }, [listpack, agent]);

    function handleSnackBarOnClose() {
        setOpenSnackBar(false);
    }

    function onMobileSortClick() {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_listview_sort_click', {
            distinct_id: agent.id,
            listpack_id: listpack.id,
            'current url': window.location.href,
        });

        setMobileAnchorEl(mobileSortButton.current);
    }

    function onDesktopButtonSortClick() {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_listview_sort_click', {
            distinct_id: agent.id,
            listpack_id: listpack.id,
            'current url': window.location.href,
        });

        setAnchorEl(onScroll() ? smallerHeaderSortButton.current : headerSortButton.current);
    }

    function closeSort() {
        setMobileAnchorEl(null);
        setAnchorEl(null);
    }

    function onOrderSelect(sortOption) {
        closeSort();
        history.push({
            pathname: match.url,
            search: `sort=${serializeSortOption(sortOption)}`
        });
    }

    function handleLoadMoreClick() {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_listview_view_more_click', {
            distinct_id: agent.id,
            listpack_id: listpack.id,
            'current url': window.location.href,
        });
        setCurrentPage((v)=> v + 1);
        ListpackStore.fetchMoreListings(30, listings.length, selectedSortOption);
    }

    function handleFinishClick() {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_listview_finish_button_click', {
            distinct_id: agent.id,
            listpack_id: listpack.id,
            'current url': window.location.href,
        });

        setRedirectEnd(true);
    }

    function HeaderButtons({ onDesktopButtonSortClick, setRedirectEnd, isMobile }) {
        function onContactClick() {
            if(!loadedListpack || !loadedAgent) return;

            analytics.eventTrack('listpacks_consumer_listview_contact_agent_click', {
                distinct_id: agent.id,
                listpack_id: listpack.id,
                'current url': window.location.href,
            });

            setRedirectEndContact(true);
        }

        return (
            <>
                {!isMobile && (
                    <LRButton
                        style={{
                            backgroundColor: 'unset',
                            fontWeight: 400,
                        }}
                        onClick={onDesktopButtonSortClick}
                    >
                        <Box fontSize="16px" marginRight="5px">
                            Sort
                        </Box>
                        <Box mt={2}>
                            <SortIcon />
                        </Box>
                    </LRButton>
                )}
                <LRButton
                    style={{
                        backgroundColor: 'unset',
                        width: '80px',
                        border: `1px solid ${COLOR_GREY}`,
                        fontWeight: 400,
                    }}
                    onClick={onContactClick}
                >
                    Contact
                </LRButton>
            </>
        );
    }

    function onScroll() {
        const headerSize = 65 + 40; // ListReports logo // Agent logo and name, listpack name and buttons

        const scroll = window.pageYOffset || document.documentElement.scrollTop;

        setShowSmallerHeader(scroll >= headerSize);

        return scroll >= headerSize;
    }

    if(redirectCover) {
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/cover?${source ? `&source=${source}` : ''}`} />;
    }

    if(redirectEnd) {
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/end?${source ? `&source=${source}` : ''}`} />;
    }

    if(redirectEndContact) {
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/end?${source ? `&source=${source}` : ''}&focus=true`} />;
    }

    function onBack() {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_listview_back_arrow_click', {
            distinct_id: agent.id,
            listpack_id: listpack.id,
            'current url': window.location.href,
        });

        setRedirectCover(true);
    }

    function onListingItemClick(listing) {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_listview_listing_click', {
            address: listing.address,
            'listing id': listing.id,
            distinct_id: agent.id,
            listpack_id: listpack.id,
            'current url': window.location.href,
        });
        onListingClick(listing);
    }

    if(listingsOrder) {
        listingsOrder = decodeURIComponent(listingsOrder);
    }

    return (
        <>
            {/* {
                <SortingSnackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    key="changed-order"
                    open={openSnackBar}
                    message={`Sorting by ${sortOptions[listingsOrder].snackbarLabel}`.trim()}
                    autoHideDuration={4000}
                    onClose={handleSnackBarOnClose}
                />
            } */}
            <ListpackHeader onBack={onBack} />
            {loadedListpack && loadedAgent && !error? (
                <>
                    <RootRef rootRef={smallerHeaderSortButton}>
                        <Slide in={showSmallerHeader} direction="down">
                            <Box
                                padding={`0 ${isMobile ? '20px' : '40px'}`}
                                maxWidth="inherit"
                                justifyContent="space-between"
                                alignItems="center"
                                bgcolor="white"
                                width="100%"
                                position="fixed"
                                top={0}
                                display="flex"
                                height="65px"
                                borderBottom="solid 1px #DDDDDD"
                            >
                                <Box display="flex" width="100%">
                                    <Box style={{ cursor: 'pointer' }}>
                                        <BackIcon onClick={()=> setRedirectCover(true)} />
                                    </Box>
                                    <Box
                                        justifyContent={`${isMobile ? 'center' : 'left'}`}
                                        fontSize="16px"
                                        width="100%"
                                        marginLeft="20px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        {listpack.name} {loadedListingsCount ? `(${listingsCount})` : ''}
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {!isMobile && (
                                        <HeaderButtons
                                            onDesktopButtonSortClick={onDesktopButtonSortClick}
                                            setRedirectEnd={setRedirectEnd}
                                            isMobile={isMobile}
                                        />
                                    )}
                                    {isMobile && (
                                        <Box style={{ cursor: 'pointer' }}>
                                            <Email onClick={()=> setRedirectEndContact(true)} />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Slide>
                    </RootRef>
                    <DesktopPopover
                        id="desktop-sort"
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeSort}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Box px="16px" py="10px" color={COLOR_GREY_NERO}>
                            {sortOptions
                                .map(function(sortOption, index) {
                                    return (
                                        <Box
                                            style={{ cursor: 'pointer' }}
                                            key={index}
                                            onClick={()=> onOrderSelect(sortOption)}
                                        >
                                            {index !== 0 && (
                                                <Box margin="10px 0">
                                                    <Divider />
                                                </Box>
                                            )}
                                            <Box
                                                display="flex"
                                                height="24px"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Box ml={1} fontSize="body1.fontSize">
                                                    {sortOption.text}
                                                </Box>
                                                {matchSortOptions(selectedSortOption, sortOption) && (
                                                    <Box height="24px" color="#54D0AA">
                                                        <Check />
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                })}
                        </Box>
                    </DesktopPopover>
                    <Box
                        display="flex"
                        flexDirection="column"
                        color={COLOR_GREY_NERO}
                        flex={1}
                        padding={`0 ${isMobile ? '20px' : '40px'}`}
                        paddingBottom={isMobile ? '20px' : '40px'}
                    >
                        <RootRef rootRef={headerSortButton}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                flexDirection={`${isMobile ? 'column' : 'row'}`}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    flexDirection={`${isMobile ? 'column' : 'row'}`}
                                >
                                    <AgentHeadshot src={agent.headshot || DEFAULT_HEADSHOT} />
                                    <Box
                                        marginTop={`${isMobile ? '10px' : '0'}`}
                                        margin="0 15px"
                                        height="fit-content"
                                        textAlign={`${isMobile ? 'center' : 'left'}`}
                                    >
                                        <Box fontSize="16px">
                                            {listpack.name} {loadedListingsCount ? `(${listingsCount})` : ''}
                                        </Box>
                                        <Box fontSize="12px">Presented by {agent.name}</Box>
                                    </Box>
                                </Box>
                                <Box
                                    marginTop={`${isMobile ? '10px' : '0'}`}
                                    textAlign={`${isMobile ? 'center' : 'left'}`}
                                >
                                    <HeaderButtons
                                        onDesktopButtonSortClick={onDesktopButtonSortClick}
                                        setRedirectEnd={setRedirectEnd}
                                        isMobile={isMobile}
                                    />
                                </Box>
                            </Box>
                        </RootRef>
                        {loadedListings? (
                            <Box color={COLOR_GREY_NERO}>
                                <Box display="flex" flexWrap="wrap">
                                    {(currentPage === 1 && !isNaN(parseInt(firstPageLimit, 10))
                                        ? listings.slice(0, parseInt(firstPageLimit, 10))
                                        : listings)
                                        .map(function(listing, index) {
                                            return (
                                                <Box
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={()=> onListingItemClick(listing, index)}
                                                    marginTop="20px"
                                                    width={`${isMobile ? '100%' : '50%'}`}
                                                    height="fit-content"
                                                    key={index}
                                                >
                                                    <Box
                                                        marginLeft={`${!isMobile && index % 2 !== 0 ? '10' : '0'}px`}
                                                        marginRight={`${!isMobile && index % 2 === 0 ? '10' : '0'}px`}
                                                    >
                                                        {listing.hero_image ? (
                                                            <ListingHeroImage image={listing.hero_image} />
                                                        ) : (
                                                            <Skeleton
                                                                animation="wave"
                                                                variant="rect"
                                                                width="100%"
                                                                height="216px"
                                                            />
                                                        )}
                                                        <Box
                                                            marginTop="10px"
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            fontSize="16px"
                                                        >
                                                            <Box>{listing.address.split(/,(.+)/)[0]}</Box>
                                                            <Box>${addCommas(listing.price)}</Box>
                                                        </Box>
                                                        <Box display="flex" justifyContent="space-between" fontSize="12px">
                                                            <Box color={COLOR_GREY_DARK}>
                                                                {listing.address.split(/,(.+)/)[1]}
                                                            </Box>
                                                            <Box>
                                                                {selectedSortOption &&
                                                                    selectedSortOption.valuefier && (
                                                                    <>
                                                                        {`${
                                                                            selectedSortOption.prefix
                                                                        } ${selectedSortOption.valuefier(
                                                                            listing
                                                                        ) || 'N/A'} ${
                                                                            selectedSortOption.label
                                                                        }`}
                                                                    </>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    height="100%"
                                    margin="20px 0"
                                    alignItems="center"
                                >
                                    {loadingMoreListings && (
                                        <Box
                                            display="flex"
                                            flex={1}
                                            alignItems="center"
                                            justifyContent="center"
                                            width="100%"
                                            height="100%"
                                        >
                                            <CircularProgress color="secondary" />
                                        </Box>
                                    )}
                                    {!loadingMoreListings && (
                                        <>
                                            {listings.length >= listingsCount && (
                                                <Box my="10px" fontSize="12px" color={COLOR_GREY_DARK}>
                                                    You've reached the end of this ListPack!
                                                </Box>
                                            )}
                                            <LRButton
                                                style={{
                                                    height: '50px',
                                                    ...(!isMobile && { maxWidth: '330px' }),
                                                    width: '100%',
                                                    color: 'white',
                                                    fontSize: '16px',
                                                    backgroundColor: COLOR_SALMON,
                                                }}
                                                onClick={
                                                    listings.length < listingsCount
                                                        ? handleLoadMoreClick
                                                        : handleFinishClick
                                                }
                                            >
                                                {listings.length < listingsCount ? 'Load more' : 'Finish 🎉'}
                                            </LRButton>
                                        </>
                                    )}
                                </Box>
                                {
                                    loadedListings && listings.length > 0 && (
                                        <Box px={2}>
                                            <ListpackComplianceTerms mlsSources={mlsSources} />
                                        </Box>
                                    )
                                }
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                flex={1}
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                height="100%"
                            >
                                <CircularProgress color="secondary" />
                            </Box>
                        )}
                    </Box>
                    {isMobile && (
                        <RootRef rootRef={mobileSortButton}>
                            <Box
                                bgcolor={COLOR_GREY_LIGHT}
                                width="100%"
                                position="fixed"
                                padding="20px"
                                bottom={0}
                                maxWidth="inherit"
                                borderTop="solid 1px #DDDDDD"
                            >
                                <LRButton
                                    style={{
                                        height: '50px',
                                        width: '100%',
                                        color: `${COLOR_GREY_NERO}`,
                                        backgroundColor: `${COLOR_GREY_LIGHT}`,
                                        border: `1px solid ${COLOR_GREY}`,
                                    }}
                                    onClick={onMobileSortClick}
                                >
                                    Sort
                                </LRButton>
                                <MobilePopover
                                    id="mobile-sort"
                                    keepMounted
                                    open={Boolean(mobileAnchorEl)}
                                    onClose={closeSort}
                                    anchorEl={mobileAnchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box color={COLOR_GREY_NERO} width="100%" padding="20px" paddingBottom="5px">
                                        <Box
                                            marginBottom="15px"
                                            fontSize="20px"
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box>Sort by:</Box>
                                            <Close onClick={closeSort} />
                                        </Box>
                                        {Object.keys(sortOptions)
                                            .map(function(option, index) {
                                                return (
                                                    <Box
                                                        style={{ cursor: 'pointer' }}
                                                        key={index}
                                                        onClick={()=> onOrderSelect(option)}
                                                    >
                                                        <Divider />
                                                        <Box
                                                            mr={0}
                                                            margin="10px"
                                                            display="flex"
                                                            height="24px"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                        >
                                                            <Box margin="15px 0">{option}</Box>
                                                            {matchSortOptions(selectedSortOption, option) && (
                                                                <Box height="24px" color="#54D0AA">
                                                                    <Check />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                </MobilePopover>
                            </Box>
                        </RootRef>
                    )}
                </>
            ) : (
                <Box
                    color={COLOR_GREY_NERO}
                    display="flex"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                >
                    {!error && <CircularProgress color="secondary" />}
                    {error && <>There was an error while loading the listpack. Please try again later.</>}
                </Box>
            )}
        </>
    );
}

export default observer(ListpackList);
