import React from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import { useMenu } from 'hooks';
import { useStore } from 'mobx-store';

export const LaneFilter = ()=> {
    const { OpenHouseCalendarStore, LanesStore } = useStore();
    const [menuProps, openMenu, closeMenu] = useMenu();
    const lanesById = LanesStore.lanesById;
    const listingsLaneFilter = OpenHouseCalendarStore.listingsLaneFilter;

    const handleLaneFilterClick = (laneId)=> {
        OpenHouseCalendarStore.listingsLaneFilter = laneId;
        closeMenu();
    };

    return (
        <Box>
            <Box onClick={openMenu} display="flex" alignItems="center" className="cursor-pointer">
                <Box mr={1}>Filter by:</Box>
                <Box fontWeight="bold">
                    {OpenHouseCalendarStore.selectedLane ? OpenHouseCalendarStore.selectedLane.name : 'All'}
                </Box>
                <Box display="flex" alignItems="center">
                    <KeyboardArrowDown></KeyboardArrowDown>
                </Box>
            </Box>

            <Menu {...menuProps}>
                <MenuItem onClick={()=> handleLaneFilterClick('all')}>All</MenuItem>
                {Object.keys(lanesById)
                    .map((laneId)=> {
                        const lane = lanesById[laneId];
                        const isSelected = listingsLaneFilter === laneId;
                        return (
                            <MenuItem selected={isSelected} onClick={()=> handleLaneFilterClick(laneId)}>
                                {lane.name}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </Box>
    );
};
