import React from 'react';
import analytics from '../../../services/analytics';
import { Box } from '@material-ui/core';
import { Container, StyledTour3DIcon, Styled3DTourLabel } from './style';
import { useResponsiveBreakpoint } from '../../../hooks';
import { COLOR_CREST_WHITE } from '../../../constants';

function ThumbnailListingImages({ listingDetails, listing, on3DTourClick, onImageClick}) {
    listingDetails = listingDetails || listing?.listing_details;
    const listingPhotos = listingDetails?.photos;
    const photos = listingPhotos.slice(1);
    const isXs = useResponsiveBreakpoint('xs');
    const minPhotosNumber = isXs ? 3 : 4;
    const displayImages = photos?.length >= minPhotosNumber + 1;

    if(!displayImages) return null;

    const background3DTour = 'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) )';
    
    const isPrivateTour = listingDetails?.virtual_tours?.length > 0;
    const privateTourImageIndex = 2;

    const imagesWidth = isXs ? '33.3%' : '33.65%';
    const imagesHeight = isXs ? '79px' : '128px';

    let imagesToDisplay = [];
    photos.slice(1, minPhotosNumber + 1).map((photo)=> {
        imagesToDisplay.push(photo);
    });

    const imagesToDisplayLength = imagesToDisplay?.length;

    function handleThumbnailClick(index) {
        const newIndex = index + 1;
        analytics.eventTrack('robin_gallery_thumbnail_click', {
            source: `image${newIndex}`,
            '3d_tour': isPrivateTour,
            board_id: listing.boardId,
            card_id: listing.id,
        });

        if(isPrivateTour && index === privateTourImageIndex) {
            on3DTourClick(newIndex);
        } else {
            onImageClick();
        }
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {displayImages && imagesToDisplay && (
                imagesToDisplay.map((imageToDisplay, index)=> {
                    if(isPrivateTour && index === privateTourImageIndex) {
                        return (
                            <Container 
                                background={`${background3DTour}, url('${imageToDisplay}')`} 
                                key={index}
                                onClick={()=> { handleThumbnailClick(index); }}
                                height={imagesHeight}
                                width={imagesWidth}
                                borderTop={`4px solid ${COLOR_CREST_WHITE}`}
                                borderRight={index === imagesToDisplayLength - 1 ? 0 : `2px solid ${COLOR_CREST_WHITE}`}
                                borderLeft={index === 0 ? 0 : `2px solid ${COLOR_CREST_WHITE}`}
                            >
                                <StyledTour3DIcon fillColor={COLOR_CREST_WHITE} />
                                <Styled3DTourLabel variant={isXs ? "body2" : "h6"}>
                                    3D Tour
                                </Styled3DTourLabel>
                            </Container>
                        );
                    } else {
                        return (
                            <Container 
                                background={`url('${imageToDisplay}')`} 
                                key={index}
                                onClick={()=> { handleThumbnailClick(index); }}
                                width={imagesWidth}
                                height={imagesHeight}
                                borderTop={`4px solid ${COLOR_CREST_WHITE}`}
                                borderRight={index === imagesToDisplayLength - 1 ? 0 : `2px solid ${COLOR_CREST_WHITE}`}
                                borderLeft={index === 0 ? 0 : `2px solid ${COLOR_CREST_WHITE}`}
                            />
                        );
                    }
                })
            )}
        </Box>
    );
}

export default ThumbnailListingImages;
