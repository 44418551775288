import React from 'react';
import { Box } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import styled from 'styled-components';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { useResponsiveBreakpoint } from '../../../hooks';

const ListreportLogo = styled.img`
    width: 130px;
    height: 22px;
`;

const AgentBranding = styled.img`
    max-width: 328px;
    height: 30px;
`;

const BackIcon = styled(ChevronLeft)`
    vertical-align: sub;
`;

function ListpackHeader({ onBack }) {
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;
    const { ListpackStore } = useStore();
    const { agent } = ListpackStore;

    return (
        <Box position="relative" height="65px" display="flex" alignItems="center" justifyContent="center">
            {onBack && (
                <Box style={{ cursor: 'pointer' }} position="absolute" height={22} left={isMobile ? '20px' : '40px'}>
                    <BackIcon onClick={onBack} />
                </Box>
            )}

            {(agent && agent.branding)
                ? <AgentBranding src={agent.branding} />
                : <ListreportLogo src="/images/logo.png" />
            }
        </Box>
    );
}

export default observer(ListpackHeader);
