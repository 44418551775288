import React, { useEffect } from 'react';
import lodashGet from 'lodash/get';
import { Box, CircularProgress, CardMedia, Divider, Grid, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import {
    COLOR_GREY_DARK,
    COLOR_BLUE_LINK,
    COLOR_BORDER_GREY,
    DEFAULT_HEADSHOT
} from '../../../constants';
import styled from 'styled-components';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';

import analytics from '../../../services/analytics';
import formatPhoneNumber from '../../../services/format-phone-number';
import addCommas from '../../../services/add-commas';
import Header from '../../../components/Header';
import LRButton from '../../LRButton';
import SponsorCompliance from '../SponsorCompliance';
import VideoPlayer from '../VideoPlayer';

const AgentHeadshot = withTheme(styled(CardMedia)`
    margin-top: 16px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 40px;
    height: 40px;
    border-radius: 50%;

    ${({ theme })=> theme.breakpoints.up('xs')} {
        width: 64px;
        height: 64px;
    }
`);

const PhoneNumber = styled.a`
    color: inherit;
    text-decoration: unset;
`;

const BlueLink = styled.a`
    cursor: pointer;
    color: ${COLOR_BLUE_LINK};
    text-decoration: unset;
`;

const PageDivider = withTheme(styled(Divider)`
    height: 2px;
    background-color: #eeecee;
    width: 24px;
    margin: 15px auto;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        margin: 12px 0;
    }
`);

const BoldTypography = styled(Typography)`
    font-weight: 500;
`;

const BodyTypography =  styled(Typography)`
    line-height: 1.3;
`;

const Address = withTheme(styled(BoldTypography)`
    whiteSpace: 'nowrap';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
    line-height: 32px;
    font-size: 24px;
    letter-spacing: 0.15;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 20px;
        line-height: 26px;
    }
`);

// const SectionTitle = withTheme(styled(Box)`
//     font-size: 24px;
//     font-weight: 200;
//     letter-spacing: 0.15;
//     vertical-align: middle;
//     margin-bottom: 6px;

//     ${({ theme })=> theme.breakpoints.down('sm')} {
//         font-size: 18px;
//     }
// `);

const ListingPrice = withTheme(styled(Box)`
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 0.15;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 18px;
        line-height: 32px;
    }
`);

const ListingStats = withTheme(styled(Box)`
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 0.15;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 16px;
    }
`);

const SemiBold = styled.b`
    font-weight: 500;
`;


function splitAddress(address) {
    return (address || '').replace(/^([\w\s\d#.:]*)(,)/i, '$1|||')
        .split('|||');
}


function Preloader({ error }) {
    return (
        <Box display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            mt={5}
        >
            {!error && <CircularProgress color="secondary" />}
            {error && (
                <span>There was an error while loading the open house details. Please try again later.</span>
            )}
        </Box>
    );
}

function ListingInfoCard({ report, isMobile, handleViewPropertyClick }) {
    const address = report.display_address || report.address;
    const [address1, address2] = splitAddress(address);
    return (
        <Grid container
            alignItems="flex-start"
            direction={isMobile ? 'column' : 'row'}
        >
            <Grid xs item>
                <Box textAlign="left">
                    <Address>
                        {address1}<br/>
                        {address2}
                    </Address>
                    {report.price_amount &&
                        <ListingPrice
                            py={[0, 1]}
                        >
                            ${addCommas(report.price_amount)}
                        </ListingPrice>
                    }

                    <ListingStats>
                        {report.bedrooms &&
                            <Box mr={2} display="inline">
                                <SemiBold>{report.bedrooms}</SemiBold> BD
                            </Box>
                        }
                        {report.bathrooms &&
                            <Box mr={2} display="inline">
                                <SemiBold>{report.bathrooms}</SemiBold> BA
                            </Box>
                        }
                        {report.home_size &&
                            <Box display="inline">
                                <SemiBold>{addCommas(report.home_size)}</SemiBold> SQ FT
                            </Box>
                        }
                    </ListingStats>

                    {report.links && report.links.property &&
                        <Box mt={2}>
                            <Box display="inline"
                                onClick={handleViewPropertyClick}
                            >
                                <BlueLink
                                    href={report.links.property}
                                    target="_blank"
                                    title="View property"
                                >
                                    View property
                                </BlueLink>
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>
    );
}

function AgentInfoCard({ agent, isMobile }) {
    return (
        <Box px={[3, 4]}>
            <Box mb={1}
                align={isMobile ? 'left' : 'center'}
            >
                <BoldTypography variant="body1">
                    Interested in more?
                </BoldTypography>
            </Box>
            <Box align={isMobile ? 'left' : 'center'}>
                <BodyTypography variant="body1">
                    If you need further information for this listing, please don&apos;t hesitate to reach out!
                </BodyTypography>
            </Box>
            <Box mt={3}
                display={isMobile ? 'inline-block' : 'block'}
            >
                <AgentHeadshot image={agent.headshot || DEFAULT_HEADSHOT} />
            </Box>

            <Grid container
                direction={isMobile ? 'row' : 'column'}
                justify="space-between"
                alignItems={isMobile ? 'flex-start' : 'center'}
            >
                <Grid item>
                    <Box align={isMobile ? 'left' : 'center'}
                    >
                        <Box fontSize="20px">{agent.name}</Box>
                        <Box position="relative" fontSize="14px">
                            <Box>{agent.company}</Box>
                            <Box color={COLOR_GREY_DARK}>
                                <Typography variant="caption">
                                    License #{agent.realtor_license_number}
                                </Typography>
                            </Box>
                            <Box textAlign={isMobile ? 'left' : 'center'}
                                display="inline-block"
                            >
                                <PageDivider></PageDivider>
                            </Box>
                            <Box>
                                <PhoneNumber href={isMobile ? `tel:${agent.phone}` : undefined}>
                                    {formatPhoneNumber(agent.phone)}
                                </PhoneNumber>{' '}
                            </Box>
                            <Box>{agent.email}</Box>
                            {agent.branding &&
                                <Box maxWidth={isMobile? '100%' : '75%'}
                                    pt={2}
                                >
                                    <img src={agent.branding}
                                        alt={agent.company}
                                        height={70}
                                    />
                                </Box>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

function SponsorInfoCard({ sponsor, isMobile }) {
    if(!sponsor) return null;
    return (
        <Box px={[3, 4]}
            mt={4}
            mb={3}
        >
            <Box height={1}
                borderBottom={`1px solid ${COLOR_BORDER_GREY}`}
                mb={4}
            />
            <Box mb={1}>
                <BoldTypography variant="body1">
                    Ready for the next step?
                </BoldTypography>
            </Box>
            <Box>
                <BodyTypography variant="body1">
                    {sponsor.name} can help you determine how much home you can afford.
                    Get pre-qualified with {sponsor.first_name} today!
                </BodyTypography>
            </Box>
            <Box mt={2} display="inline-block">
                <AgentHeadshot image={sponsor.headshot || DEFAULT_HEADSHOT} />
            </Box>

            <Grid container
                direction="column"
            >
                <Grid item>
                    <Box fontSize="20px">{sponsor.name}</Box>
                    <Box position="relative" fontSize="14px">
                        <Box color={COLOR_GREY_DARK}>
                            <Typography variant="caption">
                                {sponsor.title ? `${sponsor.title} at ` : ''}{sponsor.company}
                            </Typography><br/>
                            <Typography variant="caption">
                                NMLS# {sponsor.realtor_license_number}
                            </Typography>
                            {sponsor.mlo_license_number && (
                                <Typography variant="caption">
                                    &nbsp;• {sponsor.mlo_license_state} License: MLO.{sponsor.mlo_license_number}
                                </Typography>
                            )}
                        </Box>
                        <Box display="inline-block">
                            <PageDivider></PageDivider>
                        </Box>
                        <Box>
                            <PhoneNumber href={isMobile ? `tel:${sponsor.phone}` : undefined}>
                                {formatPhoneNumber(sponsor.phone)}
                            </PhoneNumber>{' '}
                        </Box>
                        <Box>{sponsor.email}</Box>
                        {sponsor.branding &&
                            <Box maxWidth={isMobile? '100%' : '75%'}
                                pt={2}
                                mb={1}
                            >
                                <img src={sponsor.branding}
                                    alt={sponsor.company}
                                    height={70}
                                />
                            </Box>
                        }
                        <Box color={COLOR_GREY_DARK}>
                            {sponsor.branch_address && (
                                <Typography variant="caption">
                                    {sponsor.branch_address}<br/>
                                </Typography>
                            )}
                            {sponsor.branch_license_number && (
                                <Typography variant="caption">
                                    Branch NMLS# {sponsor.branch_license_number}&nbsp;•&nbsp;
                                </Typography>
                            )}
                            {sponsor.phone_secondary && (
                                <Typography variant="caption">
                                    Phone: {formatPhoneNumber(sponsor.phone_secondary)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}


function TitleBlock({isMobile, ...restProps}) {
    const headingVariant = isMobile ? 'h5' : 'h4';
    return (
        <Box {...restProps}>
            <BoldTypography variant={headingVariant}
                align="left"
            >
                    Thank you for joining!
            </BoldTypography>
        </Box>
    );
}

function ContactBlock({ agent, sponsor, isMobile, isStacked, handleSponsorCTAClick }) {
    if(!agent) return null;
    return (
        <Box bgcolor="white"
            minHeight={(isMobile || isStacked) ? 0 : '100vh'}
            py={4}
        >
            <AgentInfoCard agent={agent}
                isMobile={isMobile || isStacked || sponsor}
            />

            {sponsor && <SponsorInfoCard sponsor={sponsor} isMobile={isMobile}/>}

            {sponsor && (
                <Box px={[3,4]}>
                    <VideoPlayer videoUrl={sponsor.pre_roll_video_url}/>

                    <Box py={1}>
                        <LRButton
                            type="button"
                            variant="contained"
                            color="primary"
                            style={{
                                width: '100%',
                                height: '48px',
                                fontSize: '16px',
                                'minWidth': '114px',
                            }}
                            href={sponsor.pre_qualification_website_url || sponsor.website}
                            target="_blank"
                            onClick={handleSponsorCTAClick}
                        >
                            Get pre-qualified
                        </LRButton>
                    </Box>
                </Box>
            )}

            {(isMobile || isStacked) && <SponsorCompliance sponsor={sponsor} isMobile={true}/>}
        </Box>
    );
}

function PostRoll() {
    const isMobile = useResponsiveBreakpoint('xs');
    const isStacked = useResponsiveBreakpoint('sm');
    const { LrLiveStore } = useStore();
    const { lrLive,
        sponsor,
        startTime,
        error,
    } = LrLiveStore;
    const { report, user } = lrLive || {};
    const agent = user
        ? user
        : report
            ? report.agent
            : null;
    // const meetingId = (lrLive && lrLive.id)
    //     ? lrLive.id
    //     : report
    //         ? report.id
    //         : null;

    const trackData = {
        distinct_id: lodashGet(agent, 'id') || lodashGet(agent, 'agent_user_id'),
        lrlive_id: lodashGet(lrLive, 'id'),
        event_type: lodashGet(lrLive, 'type'),
        sponsor_user_id: lodashGet(lrLive, 'sponsorUserId'),
        'Address': lodashGet(report, 'address'),
        'Start Time': startTime,
        report_id: lodashGet(report, 'id'),
    };


    useEffect(()=> {
        if(report && agent) {
            analytics.eventTrack('lrlive_event_postroll_page_viewed', {
                'current url': window.location.href,
                ...trackData,
            });
        }
    }, [report, agent, trackData]);

    const handleViewPropertyClick = ()=> {
        analytics.eventTrack('lrlive_event_postroll_page_view_property_click', {
            ...trackData
        });
    };

    const handleSponsorCTAClick = ()=> {
        analytics.eventTrack('lrlive_event_postroll_page_sponsor_cta_button_click', {
            cta_type: 'pre_qualification_website_url',
            ...trackData
        });
    };


    return (
        <Box display="flex"
            flexDirection="column"
            minHeight="100vh"
            height="100%"
        >
            {!error && (
                <Grid container
                    direction={isMobile ? 'column' : 'row'}
                    justify="space-between"
                    item
                >
                    <Grid item
                        sm={12}
                        md={8}
                        xl={9}
                        container
                        direction="column"
                    >
                        <Box maxWidth="100%"
                            flex={1}
                            style={{
                                backgroundImage: `${report ? "url('/images/lr-live/cityscape_grayscale.png')" : 'none'}`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'bottom',
                                backgroundSize: '100% auto',
                            }}
                        >
                            <Header justifyContent="left"
                                isMobile={isMobile}
                            />
                            <Box px={[3, 5]} pb={[3, 5, 12]}>
                                <TitleBlock
                                    isMobile={isMobile}
                                    mt={[3, 5, 12]}
                                />

                                {report && (
                                    <Box py={2} mb={8}>
                                        <ListingInfoCard
                                            report={report}
                                            isMobile={isMobile}
                                            handleViewPropertyClick={handleViewPropertyClick}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        {!(isMobile || isStacked) && (
                            <Box bgcolor="white">
                                <SponsorCompliance sponsor={sponsor} />
                            </Box>
                        )}
                    </Grid>

                    <Grid item
                        sm={12}
                        md={4}
                        xl={3}
                        container
                        direction="column"
                    >
                        <ContactBlock
                            agent={agent}
                            sponsor={sponsor}
                            isMobile={isMobile}
                            isStacked={isStacked}
                            handleSponsorCTAClick={handleSponsorCTAClick}
                        />
                    </Grid>
                </Grid>
            )}

            {(!report || error) && <Preloader error={error} />}
        </Box>
    );
}

export default observer(PostRoll);
