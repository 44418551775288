import React from 'react';
import { SvgIcon } from '@material-ui/core';

function HomeSparkleIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10.0749 20.0393V15.236H13.9176V20.0393C13.9176 20.5677 14.3499 21 14.8783 21H17.7603C18.2887 21 18.721 20.5677 18.721 20.0393V13.3146H20.3541C20.796 13.3146 21.0074 12.767 20.6712 12.4788L12.6399 5.24497C12.2749 4.91834 11.7177 4.91834 11.3526 5.24497L3.32141 12.4788C2.99478 12.767 3.19652 13.3146 3.63843 13.3146H5.27157V20.0393C5.27157 20.5677 5.70387 21 6.23224 21H9.11426C9.64263 21 10.0749 20.5677 10.0749 20.0393Z" />
            <path d="M19 8L19.94 5.93L22 5L19.94 4.07L19 2L18.08 4.07L16 5L18.08 5.93L19 8Z"/>
            <path d="M4.5 8L5 6L7 5.5L5 5L4.5 3L4 5L2 5.5L4 6L4.5 8Z"/>
        </SvgIcon>
    );
}

export default HomeSparkleIcon;
