import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { CircularProgress, Grid, useTheme } from '@material-ui/core';
import { useDebounce, useResponsiveConfig } from '../../hooks';
import { portalApi } from '../../apis';
import axios from 'axios';
import Logger from 'js-logger';
import { Room, School, Home, Business, Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { LRAutocomplete } from 'components/LRAutocomplete';

const CancelToken = axios.CancelToken;

export const LRAreaAutocompleteV2 = React.forwardRef(function LRAreaAutocompleteV2({
    value = '',
    name = '',
    type,
    label,
    debounceDelay = 500,
    minimumChars = 3,
    helperText,
    error,
    autoFocus = false,
    autoSelect = true,
    autoHighlight = true,
    clearOnBlur = false,
    onChange:_onChange = (newValue)=> {},
    filterSuggestions,
    groupBy=false,
    disabled=false,
    clearOnSelect = false,
    InputProps,
    ...props
}, ref) {
    const theme = useTheme();
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, debounceDelay);
    const inputRef = useRef();
    useImperativeHandle(ref, ()=> inputRef.current);
    const numberOfSuggestions = useResponsiveConfig({ xs: 5, md: 500 });

    // Functions
    function onInputChange(e, value) {
        setInputValue(value);
    }

    function onChange(e, value) {
        _onChange(value);
        clearOnSelect && setInputValue('');
    }

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
            case 'zip':
                iconElement = <Business fontSize="small" />;
                break;
            case 'neighborhood':
                iconElement = <Home fontSize="small" />;
                break;
            case 'school':
                iconElement = <School fontSize="small" />;
                break;
            default:
                iconElement = <Room fontSize="small" />;
                break;
        }
        return iconElement;
    }

    function onGetOptionLabel(option) {
        return option?.id || '';
    }

    // UseEffects
    useEffect(()=> {
        if(
            !debouncedInputValue ||
            debouncedInputValue.length < minimumChars ||
            (debouncedInputValue === value && debouncedInputValue.length)
        ) {
            setLoading(false);
            return;
        }

        let active = true;
        const canceler = CancelToken.source();

        setLoading(true);

        (async function fetchData() {
            let suggestions = await portalApi.queryAreas(
                debouncedInputValue,
                undefined,
                'city,zip,neighborhood,school',
                {
                    cancelToken: canceler.token,
                }
            );

            if(!active) return;

            if(type) {
                suggestions = suggestions.filter((suggestion)=> {
                    return suggestion.type === type;
                });
            }

            if(filterSuggestions){
                suggestions = suggestions.filter(filterSuggestions);
            }

            suggestions = suggestions.slice(0, numberOfSuggestions);

            setOptions(suggestions);
            setLoading(false);
        })();

        return ()=> {
            active = false;
            canceler.cancel();
        };
    }, [debouncedInputValue, filterSuggestions, minimumChars, numberOfSuggestions, type, value]);

    return (
        <LRAutocomplete
            {...props}
            loading={loading}
            value={value}
            inputValue={inputValue}
            open={suggestionsOpen && debouncedInputValue.length >= minimumChars}
            options={options}
            autoSelect={autoSelect}
            autoHighlight={autoHighlight}
            clearOnBlur={clearOnBlur}
            autoFocus={autoFocus}
            error={error}
            helperText={helperText}
            label={label}
            groupBy={groupBy}
            disabled={disabled}
            InputProps={{
                startAdornment:
                    <div style={{ paddingLeft: theme.spacing(1) + 2, paddingTop: 4}}>
                        <Search style={{
                            color: disabled
                                ? theme.palette?.lr_colors?.grey
                                : theme.palette?.lr_colors?.grey_dark
                        }}/>
                    </div>,
                endAdornment: loading &&
                    <div style={{ position: 'relative', top: 2, marginRight: 8, }}>
                        <CircularProgress color="primary" size={20} />
                    </div>,
                ...InputProps
            }}
            renderOption={(option)=> (
                <Grid container spacing={1} justify="center" alignItems="center">
                    {!groupBy && <Grid item>
                        <div style={{ position: 'relative', top: 4, }}>
                            {renderSuggestionIcon(option.type)}
                        </div>
                    </Grid>}
                    <Grid item xs>
                        {option.id}
                    </Grid>
                </Grid>
            )}
            getOptionLabel={onGetOptionLabel}
            onChange={onChange}
            onInputChange={onInputChange}
            onOpen={()=> setSuggestionsOpen(true)}
            onClose={()=> setSuggestionsOpen(false)}
        />
    );
});

LRAreaAutocompleteV2.propTypes = {
    /**
     * The area type you want to limit the suggestions to.
     */
    type: PropTypes.oneOf(['city', 'zip', 'neighborhood', 'school']),

    /**
     * The delay in milliseconds before the api is called.
     */
    debounceDelay: PropTypes.number,

    /**
     * The minimum number of characters the user must type before the api is called.
     */
    minimumChars: PropTypes.number,

    ...LRAutocomplete.propTypes,
};
