import React from 'react';
import { Divider, Typography, useTheme } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import LRAvatar from '../../../components/LRAvatar';
import LRButton from '../../../components/LRButton';
import LRLink from '../../../components/LRLink';
import { observer } from 'mobx-react-lite';
import { useResponsive } from '../../../hooks';
import { usePaginatedCollection } from '../utils';
import { useStore } from '../../../mobx-store';
import analytics from 'services/analytics';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import { useBoardListpacks } from 'hooks/useBoardListpacks';

const RecommendedCardRow = observer(function CardRow({ creator, card }) {
    const r = useResponsive();
    const theme = useTheme();
    const { UserStore } = useStore();
    const { user } = UserStore;
    const isCreator = user.isAgent;
    const { data: cardListing } = useBoardCardListing(card);

    if(!card || !cardListing) {
        return null;
    }

    function handleAddressClick() {
        analytics.eventTrack('robin_overview_properties_recommended_home_click', {
            address: cardListing.address,
        });
    }

    const image =
        card.hero_image_url || (cardListing.photos.length ? cardListing.photos[0] : undefined);
    return (
        <div
            className="space-x-4"
            style={{
                display: 'flex',
                alignItems: r({ xs: 'flex-start', md: 'center' }),
                justifyContent: 'space-between',
            }}
        >
            <div
                className="space-x-4"
                style={{
                    flex: 1,
                    display: 'flex',
                }}
            >
                <div>
                    <LRAvatar user={creator} darken="true" />
                </div>
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <Typography variant="body1">
                        { isCreator ? 'You' : creator?.first_name } recommended{' '}
                        <LRLink
                            color="black"
                            to={`/overview/cards/${card.id}`}
                            style={{
                                textDecoration: 'underline',
                            }}
                            onClick={handleAddressClick}
                        >
                            {cardListing.address}
                        </LRLink>
                        .
                    </Typography>
                    <Typography
                        variant="caption"
                        style={{
                            color: theme.palette.lr_colors.steak_sauce,
                        }}
                    >
                        {moment(card.createdAt)
                            .format('ll')}
                    </Typography>
                </div>
            </div>
            <div>
                <LRLink
                    color="black"
                    to={`/overview/cards/${card.id}`}
                    style={{
                        textDecoration: 'underline',
                    }}
                    onClick={handleAddressClick}
                >
                    <img
                        src={image}
                        style={{
                            objectFit: 'cover',
                            width: '100px',
                            display: 'block',
                            height: '66px'
                        }}
                        alt="listing"
                    />
                </LRLink>
            </div>
        </div>
    );
});

const emptyArray = [];

const RecommendedHomes = observer(function RecommendedHomes() {
    const theme = useTheme();
    const r = useResponsive();
    const { UserStore } = useStore();
    const affiliate = UserStore.user?.affiliate;
    const {
        sortedListpacks,
    } = useBoardListpacks();
    const handSelectedListpack = sortedListpacks.handSelected;
    const listpackCards = _.chain(handSelectedListpack?.allListpackCards || emptyArray)
        .sortBy('createdAt')
        .reverse()
        .value();

    const { paginatedCollection: paginatedListpackCards, loadMore, hasMore } = usePaginatedCollection(listpackCards, {
        initialPageSize: 5,
        newPageSize: 10,
    });

    function handleShowMoreClick() {
        analytics.eventTrack('robin_overview_properties_show_more_click', {
            source: 'recommended'
        });
        loadMore();
    }

    return (
        <div>
            <ul
                style={{
                    padding: theme.spacing(2),
                }}
                className="space-y-4"
            >
                {paginatedListpackCards.length ? (
                    paginatedListpackCards.map((card)=> {
                        return (
                            <li key={card.id}>
                                <RecommendedCardRow creator={affiliate} card={card} />
                            </li>
                        );
                    })
                ) : (
                    <Typography
                        variant="body1"
                        style={{
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                            paddingTop: r({ xs: 0, md: theme.spacing(2) }),
                            paddingBottom: r({ xs: 0, md: theme.spacing(2) }),
                        }}
                    >
                        <span role="img" aria-label="emoji">
                            💌
                        </span>{' '}
                        This is where you’ll be able to see all the homes you recommended to your buyers.
                    </Typography>
                )}
            </ul>
            {hasMore && (
                <>
                    <Divider></Divider>
                    <LRButton
                        onClick={handleShowMoreClick}
                        variant="text"
                        fullWidth
                        style={{
                            padding: theme.spacing(1.5),
                        }}
                    >
                        Show more
                    </LRButton>
                </>
            )}
        </div>
    );
});

export default RecommendedHomes;
