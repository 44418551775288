import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Divider, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import LRAvatar from '../../../components/LRAvatar';
import LRButton from '../../../components/LRButton';
import { useResponsive } from '../../../hooks';
import { useBoardCardListing } from '../../../hooks/data/board-card/useBoardCardListing';
import { useActiveBoard } from '../../../mobx-store';
import { usePaginatedCollection } from '../utils';
import { useQuery } from 'react-query';
import { benoitApi } from '../../../apis';
import LRLink from '../../../components/LRLink';
import { groupByKey } from '../../../services/group-by-key';
import analytics from 'services/analytics';

function useDeletedCardCollaborations(boardId) {
    const requestParams = {
        type: 'deleted_card',
        include_user: 'true',
    };

    return useQuery(
        ['board-collaborations', boardId, requestParams],
        async ()=> {
            if(!boardId) {
                return [];
            }
            return benoitApi.getBoardCollaborations(boardId, undefined, {
                params: requestParams,
            });
        },
        {
            staleTime: Infinity,
            refetchInterval: 60000,
            forceFetchOnMount: true,
        }
    );
}

const DeletedCardRow = observer(function CardRow({ boardCard, boardCollaboration }) {
    const r = useResponsive();
    const theme = useTheme();
    const card = boardCard;
    const message = boardCollaboration?.content?.message;
    const user = boardCollaboration?.user;
    const { data: listing_details } = useBoardCardListing(boardCard);

    if(!card) {
        return null;
    }

    function handleHomeLinkClick() {
        analytics.eventTrack('robin_overview_properties_deleted_home_click', {
            address: listing_details.address,
        });
    }

    const image =
        card.hero_image_url || (listing_details?.photos?.length ? listing_details?.photos[0] : undefined);

    return (
        <div
            className="space-x-4"
            style={{
                display: 'flex',
                alignItems: r({ xs: 'flex-start', md: 'center' }),
                justifyContent: 'space-between',
            }}
        >
            <div
                className="space-x-4"
                style={{
                    flex: 1,
                    display: 'flex',
                }}
            >
                <div>
                    <LRAvatar user={user} />
                </div>
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <Typography variant="body1">
                        {user ? (
                            <>
                                {user?.first_name} removed{' '}
                                <LRLink
                                    color="black"
                                    to={`/overview/archivedListings/${card.id}`}
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                    onClick={handleHomeLinkClick}
                                >
                                    {listing_details?.address}
                                </LRLink>
                                {' '}from their Robin board.
                                {message && ` "${message}"`}
                            </>
                        ) : (
                            <>
                                <LRLink
                                    color="black"
                                    to={`/overview/archivedListings/${card.id}`}
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                    onClick={handleHomeLinkClick}
                                >
                                    {listing_details?.address}
                                </LRLink>
                                {listing_details?.address ? ' was deleted.': ''}
                            </>
                        )}
                    </Typography>
                    {boardCollaboration?.createdAt && (
                        <Typography
                            variant="caption"
                            style={{
                                color: theme.palette.lr_colors.steak_sauce,
                            }}
                        >
                            {moment(boardCollaboration?.createdAt)
                                .format('ll')}
                        </Typography>
                    )}
                </div>
            </div>
            <div>
                <LRLink to={`/overview/archivedListings/${card.id}`} onClick={handleHomeLinkClick}>
                    <img
                        src={image}
                        style={{
                            objectFit: 'cover',
                            width: '100px',
                            display: 'block',
                            height: '66px'
                        }}
                        alt="listing"
                    />
                </LRLink>
            </div>
        </div>
    );
});

const emptyArray = [];
const DeletedHomes = observer(function DeletedHomes() {
    const theme = useTheme();
    const r = useResponsive();
    const activeBoard = useActiveBoard();
    const { data: deletedCardCollaborations } = useDeletedCardCollaborations(activeBoard?.id);
    const deletedCardCollaborationsByCardId = groupByKey(deletedCardCollaborations || emptyArray, 'itemId');
    const archivedBoardCards = _.chain(activeBoard.archivedBoardCards)
        .orderBy(
            [
                function(bc) {
                    const deletedBoardCollab = deletedCardCollaborationsByCardId[bc.id];
                    if(deletedBoardCollab) {
                        return deletedBoardCollab.createdAt;
                    } else {
                        return moment(0)
                            .toISOString();
                    }
                },
            ],
            ['desc']
        )
        .value();
    const {
        paginatedCollection: paginatedArchivedBoardCards,
        loadMore: loadMoreBoardCards,
        hasMore: hasMoreBoardCards,
    } = usePaginatedCollection(archivedBoardCards || emptyArray, {
        initialPageSize: 5,
        newPageSize: 10,
    });

    function handleShowMoreClick() {
        analytics.eventTrack('robin_overview_properties_show_more_click', {
            source: 'deleted'
        });
        loadMoreBoardCards();
    }

    return (
        <div>
            <ul
                className="space-y-4"
                style={{
                    padding: theme.spacing(2),
                }}
            >
                {paginatedArchivedBoardCards.length ? (
                    paginatedArchivedBoardCards.map((boardCard)=> {
                        const boardCollaboration = deletedCardCollaborationsByCardId[boardCard.id];
                        return (
                            <li key={boardCard.id}>
                                <DeletedCardRow boardCard={boardCard} boardCollaboration={boardCollaboration} />
                            </li>
                        );
                    })
                ) : (
                    <Typography
                        variant="body1"
                        style={{
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                            paddingTop: r({ xs: 0, md: theme.spacing(2) }),
                            paddingBottom: r({ xs: 0, md: theme.spacing(2) }),
                        }}
                    >
                        <span role="img" aria-label="emoji">
                            🗑️
                        </span>{' '}
                        This will be here in case you want to see the homes that your buyer rejected and why they did.
                    </Typography>
                )}
            </ul>
            {hasMoreBoardCards && (
                <>
                    <Divider />
                    <LRButton
                        onClick={handleShowMoreClick}
                        variant="text"
                        fullWidth
                        style={{
                            padding: theme.spacing(1.5),
                        }}
                    >
                        Show more
                    </LRButton>
                </>
            )}
        </div>
    );
});

export default DeletedHomes;
