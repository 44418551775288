import moment from 'moment';

export function timeago(date, separator) {
    var TODAY = moment()
        .startOf('day');
    var now = moment(new Date());
    var sep = (typeof separator === 'string') ? separator : '-';
    date = moment(date);

    if(date.isSame(TODAY, 'd')) {
        return moment.duration(now - date)
            .humanize() + ' ago';
    } else {
        return date.format('MMMM D, YYYY ' + sep + ' h:mm A');
    }
}
