import { useEffect } from 'react';
import { useOnboarding } from './store';
import { addEventListener } from './utils';

export function useDimissibleOverlay() {
    const onboarding = useOnboarding();

    useEffect(()=> {
        const removeHandler = addEventListener('.shepherd-modal-overlay-container path', 'click', function() {
            onboarding.stop();
        });

        return ()=> removeHandler();
    }, [onboarding]);
}
