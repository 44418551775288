import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Card from '../../Board/Card';
import { observer } from 'mobx-react-lite';
import { useResponsiveBreakpoint } from '../../../hooks';
import LRButton from '../../../components/LRButton';
import { ListpackComplianceTerms } from '../../../components/ListpackComplianceTerms';
import { Swiper } from '../../../components/Swiper';
import { isListingNew } from '../Common';
import { CardPlaceholderList } from '../CardPlaceholderList';
import { ListItem } from '../Common/ListItem';
import Logger from 'js-logger';
import { useBoardListpacks } from 'hooks/useBoardListpacks';
import _ from 'lodash';

const Sidebar = function(props) {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;

    return (
        isMobile ? <MobileSidebar {...props} /> : <DesktopSidebar {...props}/>
    );
};

export const DesktopSidebar = withRouter(
    observer(({ 
        listings, 
        selectedListing,
        onListingSelect = (listing)=> {}, 
        listpack, 
        lastSeenDate, 
        parentRef, 
        hoveredListing, 
        handleOnHover = (listing)=> {},
        currentLaneId, 
        hasMoreListings, 
        loadingMoreListings, 
        fetchMoreListings, 
        showLoading = false,
        renderCard = null,
        cardContainerWidth = null,
        ...props 
    })=> {
        const isXs = useResponsiveBreakpoint('xs');
        const scrollRef = useRef(null);
        const [selectedPlaceRef, setSelectedPlaceRef] = useState(null);
        const [showLoader, setShowLoader] = useState(true);
        const { isLoading:isLoadingListpacks } = useBoardListpacks();
        const mlsSources = useMemo(()=> _.uniqBy(listings, 'listing_details.mls_id'),[listings]);

        const calcSelectedPlaceRef = (id)=> {
            return (element)=> {
                if(selectedListing?.listing_details?.id === id) {
                    setSelectedPlaceRef(element);
                }
            };
        };

        function handleOnListingClick(listing) {
            onListingSelect(listing);
        }

        useEffect(()=> {
            if(selectedPlaceRef && parentRef) {

                parentRef.current.scrollTo({
                    top: selectedPlaceRef.offsetTop - 90
                });
            }
        }, [selectedPlaceRef, parentRef]);

        useEffect(()=> {
            const newValue = (!listpack && isLoadingListpacks) || showLoading;

            if(parentRef && newValue !== showLoader && !newValue) {
                parentRef.current.scrollTo({ top: 0 });
            }

            setShowLoader(newValue);
        }, [showLoader, listpack, showLoading, parentRef, isLoadingListpacks]);

        return (
            <Box
                px={1}
                py={1 / 2}
                flexDirection='row'
                flexWrap={props.felxWrap || `${isXs ? 'no-wrap' : 'wrap'}`}
                display="flex"
                justifyContent="center"
                className="DesktopSidebar-root"
                ref={scrollRef}
                {...props}
            >

                {showLoader && <CardPlaceholderList ready={!showLoader} />}
                {!showLoader && listings.map((listing, index)=> {
                    return (
                        <ListItem
                            key={listing.id ? listing.id : listing.listing_details.id}
                            cardOrListing={listing}
                            listpack={listpack}
                            selectedListing={selectedListing}
                            currentLaneId={currentLaneId}
                            lastSeenDate={lastSeenDate}
                            handleOnListingClick={handleOnListingClick}
                            renderCard={renderCard}
                        />
                    );
                })}

                {!showLoader && listpack && hasMoreListings && (
                    <Box mt={2} width="100%" display="flex" justifyContent="center" mb={3}>
                        <Box width={102}>
                            <LRButton
                                fullWidth
                                variant="outlined"
                                disabled={loadingMoreListings}
                                onClick={fetchMoreListings}
                            >
                                {!loadingMoreListings && 'Load more'}
                                {loadingMoreListings && (
                                    <CircularProgress size={24} color="secondary" />
                                )}
                            </LRButton>
                        </Box>
                    </Box>
                )}
                {
                    !showLoader && listpack && listings.length > 0 && (
                        <Box px={2}>
                            <ListpackComplianceTerms mlsSources={mlsSources} />
                        </Box>
                    )
                }
            </Box>
        );
    })
);

export const MobileSidebar = withRouter(
    observer(({ listings, selectedListing, onSelectListing,  onListingSelect, listpack, lastSeenDate, onSwiperInit, currentLaneId, ...props })=> {

        let activeIndex = listings.findIndex((listing)=> {
            return listing?.listing_details?.id === selectedListing?.listing_details?.id;
        });

        function handleOnListingClick(listing) {
            onListingSelect(listing);
        }

        if(!selectedListing || activeIndex < 0) {
            return <></>;
        }

        return (
            <Box className='map-sidebar-swiper' px={1}>
                <Box>
                    <Swiper
                        data={listings}
                        slidesPerView="auto"
                        centeredSlides={true}
                        pagination={false}
                        activeIndex={activeIndex}
                        initialSlide={activeIndex}
                        hasNavigation={false}
                        allowTouchMove={true}
                        onInit={onSwiperInit}
                        renderSlide={(listing)=>
                            <div
                                style={{
                                    marginLeft: '16px',
                                    marginRight: '16px',
                                    position: 'relative',
                                    borderRadius: '6px',
                                    border: `${selectedListing?.listing_details?.id === listing?.listing_details?.id ? '2px solid #FF6259' : 'none'}`
                                }}
                                className="tour-target-listpack-listing-card"
                                onClick={()=> { handleOnListingClick(listing); }}
                            >
                                <Card
                                    variant={'minimal'}
                                    listing={listing}
                                    boardCard={listing}
                                    isNew={isListingNew(listpack, listing, lastSeenDate)}
                                    laneIdBadge={currentLaneId ? null : listing.listing_details.linkedBoardCard?.laneId}
                                    listpack={listpack}
                                    showUnspecifiedListingsWarning
                                />
                            </div>
                        }
                        onSlideChange={(index, value)=> {
                            setTimeout(()=> {
                                onSelectListing(listings[index]);
                            }, 0);
                        }}
                    />
                </Box>
            </Box>
        );
    })
);

export default observer(Sidebar);
