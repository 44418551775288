import React, { useContext } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { TalkingPointsContext } from '..';
import { COLOR_BORDER_GREY } from '../../../constants';
import formatPhoneNumber from 'services/format-phone-number';
import { Footer } from '../Footer';
import { UserProfileInfo } from '../UserProfileInfo';
import { useResponsiveBreakpoint } from '../../../hooks';

export const PrintFooter = function () {
    const theme = useTheme();
    const { user = {} } = useContext(TalkingPointsContext);
    const isMobile = useResponsiveBreakpoint('xs');

    return (
        <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            padding: theme.spacing(2),
            gap: theme.spacing(2),
            marginTop: 'auto',
            borderTop: `1px solid ${COLOR_BORDER_GREY}`,
        }}>
            <Box minWidth={250}>
                <UserProfileInfo user={user} variant="column" hideFields={[ 'email', 'phone' ]} />
            </Box>
            <div>
                <Box
                    pr={2}
                    pt={2}
                    fontWeight="500"
                    letterSpacing={0}
                    fontStyle="normal"
                >
                    <Box fontSize="28px"
                        lineHeight="32px"
                    >
                        Questions about these articles or the state of your local market? Get in touch!
                    </Box>
                    <Box mt={2} fontSize="20px">
                        {user.phone && (<span>{formatPhoneNumber(user.phone)}</span>)}
                        {user.email && (<span style={{marginLeft: user.phone ? theme.spacing(8) : 0}}>{user.email}</span>)}
                    </Box>
                </Box>
                <Footer user={user} style={{marginTop: theme.spacing(3)}} />
            </div>
        </div>
    );
};
