
import { Typography, useTheme } from '@material-ui/core';
import LRButton from 'components/LRButton';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useMyHome } from 'hooks/useMyHome';
import _ from 'lodash';
import React from 'react';

export function Header({
    openManageModal,
    openSignUpModal,
}) {
    const theme = useTheme();
    const { isDataLocal, myHome } = useMyHome();
    const { isMobile } = useDeviceBreakpoints();
    const isDisabled = (isDataLocal && _.isEmpty(myHome?.address));

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...(isMobile && {paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2)}),
        }}>
            <Typography variant='h6' style={{...(isMobile && {paddingTop: theme.spacing(3) + 2, paddingBottom: theme.spacing(3) + 2})}}>
                Neighborhoods
            </Typography>
            <LRButton
                variant="outlined"
                disabled={isDisabled}
                onClick={()=> {
                    if(isDataLocal) {
                        openSignUpModal();
                    } else {
                        openManageModal();
                    }
                }}>
                Manage
            </LRButton>
        </div>
    );
}
