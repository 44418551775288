import { benoitApi } from 'apis';
import { useQuery } from 'react-query';
import { useActiveBoard } from 'mobx-store';
import { queryClient } from 'query-client';

const makeCacheKey = (cardId)=> ['board-card', cardId];

export function useBoardCard(cardId) {
    const activeBoard = useActiveBoard();
    return useQuery(
        makeCacheKey(cardId),
        async function() {
            return benoitApi.getBoardCard(cardId);
        },
        {
            enabled: Boolean(cardId),
            onSuccess: function(data) {
                if(!activeBoard) {
                    return;
                }
                const boardCard = activeBoard.boardCardsById[cardId];
                if(boardCard) {
                    Object.assign(boardCard, data);
                }
            },
        }
    );
}

export function updateBoardCardCache(card) {
    queryClient.setQueryData(makeCacheKey(card.id), card);
    // invalidateQueries
}
export function invalidateBoardCardCache(cardId) {
    return queryClient.invalidateQueries(makeCacheKey(cardId));
}
