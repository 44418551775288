import '@glidejs/glide/dist/css/glide.core.css';
import Glidejs from '@glidejs/glide';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

export const Slide = ({ children, ...props })=> {
    return (
        <Box
            className="glide__slide"
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            {...props}
        >
            {children}
        </Box>
    );
};

// Glidejs changes the padding on the element so need to force the padding.
// This is needed be able to apply scale transforms on the a given slide and not become hidden.
export const Slides = styled.div`
    padding: 30px 0px !important;
`;

export const StyledBoxArrow = styled(Box)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const useGlide = (glideProps = {}, glideEvents = {}, index)=> {
    const [gliderNode, setGlideNode] = useState(null);
    const [glideInstance, setGlideInstance] = useState(null);
    const [glideState, setGlideState] = useState({ index: glideProps.startsAt || 0 });

    useEffect(()=> {
        if(glideInstance && glideInstance.index !== index) {
            glideInstance.go(`=${index}`);
        }
        if(!gliderNode || glideInstance) {
            // skip initialized if already initialzied or node not available
            return;
        }

        let glider = new Glidejs(gliderNode, glideProps);
        glider.mount();
        setGlideInstance(glider);

        if(glideEvents.onInit) {
            glideEvents.onInit(glider);
        }
    }, [glideInstance, glideProps, gliderNode, glideEvents, index]);

    useEffect(()=> {
        if(glideInstance) {
            const { startAt, perView, focusAt, gap } = glideInstance.settings;
            const instanceProps = { startAt, perView, focusAt, gap };
            if(!_.isEqual(instanceProps, glideProps)) {
                glideInstance.update(glideProps);
            }
        }
    }, [glideInstance, glideProps]);

    useEffect(()=> {
        const handleOnAfterRun = ()=> {
            setGlideState({ index: glideInstance.index });

            if(glideEvents.onSlideFocus) {
                glideEvents.onSlideFocus(glideInstance.index);
            }
        };

        if(glideInstance) {
            glideInstance.on('run.after', handleOnAfterRun);
        }
    }, [glideInstance, glideEvents]);

    useEffect(()=> {
        return ()=> {
            if(glideInstance) {
                glideInstance.destroy();
            }
        };
    }, [glideInstance]);

    // To be used as an element ref
    return [glideState, setGlideNode];
};

export const Slider = React.memo(
    ({
        children,
        collection,
        renderSlide,
        onSlideFocus,
        onInit,
        nextButtonComponent: NextButtonComponent,
        previousButtonComponent: PreviousButtonComponent,
        nextButtonClick,
        previousButtonClick,
        className = '',
        index = 0,
        ...glideProps
    })=> {
        const glideEvents = useMemo(()=> ({ onSlideFocus, onInit }), [onSlideFocus, onInit]);
        const [glideState, glideRef] = useGlide(glideProps, glideEvents, index);

        const renderCollection = useCallback(()=> {
            return collection.map((item, index)=> (
                <Slide key={index}>{renderSlide(item, { isSelected: glideState.index === index, index })}</Slide>
            ));
        }, [collection, glideState, renderSlide]);

        return (
            <div className={`glide ${className}`} ref={glideRef}>
                <div className="glide__track" data-glide-el="track">
                    <Slides className="glide__slides">
                        {collection && renderSlide ? renderCollection() : children}
                    </Slides>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                    {(PreviousButtonComponent && (
                        <PreviousButtonComponent
                            className="glide__arrow glide__arrow--left"
                            data-glide-dir="<"
                            state={glideState}
                            onClick={previousButtonClick}
                            collectionLength={collection.length}
                        />
                    )) || (
                        <StyledBoxArrow left="-15px" className="glide__arrow glide__arrow--left" data-glide-dir="<">
                            <KeyboardArrowLeft />
                        </StyledBoxArrow>
                    )}

                    {(NextButtonComponent && (
                        <NextButtonComponent
                            className="glide__arrow glide__arrow--right"
                            data-glide-dir=">"
                            state={glideState}
                            onClick={nextButtonClick}
                            collectionLength={collection.length}
                        />
                    )) || (
                        <StyledBoxArrow right="-15px" className="glide__arrow glide__arrow--right" data-glide-dir=">">
                            <KeyboardArrowRight />
                        </StyledBoxArrow>
                    )}
                </div>
            </div>
        );
    }
);
