import Lockr from 'lockr';
import _ from 'lodash';
import { action, computed, decorate, flow, observable } from 'mobx';
import { benoitApi } from '../../apis';
import { Board } from './board';

export class BoardsStore {
    currentUserBoards = [];
    activeBoard = null;
    fetchingUserBoards = false;
    loading = false;
    loaded = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    get nonActiveBoards() {
        return this.currentUserBoards.filter((board)=> board !== this.activeBoard);
    }


    setActiveBoard = flow(function*(board) {
        this.activeBoard = board;
        this.saveLocalActiveBoard();
        return yield board.fetchBoardData();
    });

    fetchUserBoards = flow(function*() {
        this.fetchingUserBoards = true;
        this.loading = true;
        const user = this.rootStore.UserStore.user;
        if(!user) {
            return;
        }

        const boardObjs = yield benoitApi.getUserBoards(user.id);
        this.currentUserBoards = boardObjs.map((boardObj)=> {
            return new Board(boardObj, this);
        });

        yield this.loadActiveLocalBoard();

        if(this.rootStore.UserStore.isConsumer){
            yield this.activeBoard.updateLastSeenAt(user.id);
        }

        const preferencesResponse = yield benoitApi.getUserPreference(user.id, this.activeBoard.id);
        if(!!preferencesResponse.length){
            this.rootStore.UserStore.mergeUserPreferencesData(preferencesResponse[0]);
        }

        this.fetchingUserBoards = false;
        this.loading = false;
        this.loaded = true;
    });

    createUserBoard = flow(function*(data) {
        const { user } = this.rootStore.UserStore;
        var payload = {
            background_url: null,
            createdById: user.id, //change later to use authenticated request
            updatedById: user.id, //change later to use authenticated request
            name: 'my board',
            ownerId: user.id,
            archived: false,
        };

        payload = _.merge(payload, data);
        const userBoard = yield benoitApi.createBoard(payload);
        yield this.fetchUserBoards();

        return userBoard.data;
    });

    loadActiveLocalBoard() {
        if(!this.currentUserBoards.length) {
            return;
        }

        const localBoardObj = Lockr.get('board') || {};
        const localBoard = this.currentUserBoards.find((board)=> board.id === localBoardObj.id);

        if(localBoard) {
            return this.setActiveBoard(localBoard);
        }

        Lockr.rm('board');

        const sortedBoards = _.orderBy(this.currentUserBoards, ['createdAt'], ['desc']);
        const foundBoard = sortedBoards.find((board)=> {
            return board.ownerId === this.rootStore.UserStore.user.id;
        });
        return this.setActiveBoard(foundBoard || sortedBoards[0]);
    }

    saveLocalActiveBoard() {
        const SAVABLE_PROPS = ['id'];
        Lockr.set('board', _.pick(this.activeBoard, SAVABLE_PROPS));
    }
}

decorate(BoardsStore, {
    currentUserBoards: observable,
    activeBoard: observable,
    fetchingUserBoards: observable,
    loading: observable,
    loaded: observable,

    nonActiveBoards: computed,

    setActiveBoard: action,
    fetchUserBoards: action,
    loadActiveLocalBoard: action,
});
