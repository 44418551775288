import { Grid, Hidden, ThemeProvider, Typography, useTheme } from '@material-ui/core';
import LRButton from 'components/LRButton';
import LRTooltip from 'components/LRTooltip';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useMyHome } from 'hooks/useMyHome';
import { useSplitAddress } from 'hooks/useSplitAddress';
import Logger from 'js-logger';
import React from 'react';
import addCommas from 'services/add-commas';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';
import { MuiPrimaryWhiteTheme } from 'theme';
import { HomeRounded } from '@material-ui/icons';
import { useQuery } from 'hooks';

function HomeDetails({
    disableEditing = false,
    onEditClick = (e)=> {},
}) {
    const { myHome:home } = useMyHome();
    const theme = useTheme();
    const mode = useQuery().get('mode'); //ro means read-only

    return (
        <>
            {home && (
                <div>
                    {home?.bedrooms && <><b>{home?.bedrooms}</b> Beds </>}
                    {home?.bathrooms && <> • <b>{home?.bathrooms}</b> Baths </>}
                    {home?.living_area && <> • <b>{addCommas(home.living_area)}</b> Sq Ft</>}
                    {home?.lot_size && <> • <b>{addCommas(home?.lot_size)}</b> Sq Ft Lot</>}
                </div>
            )}

            {mode !== 'ro' && <div style={{ marginTop: theme.spacing(2), }}>
                <LRTooltip hideArrow={false} title={disableEditing && 'Only a buyer or co-buyer can edit their home information.'} placement="top" aria-label={disableEditing && 'Only a buyer or co-buyer can edit their home information.'}>
                    <ThemeProvider theme={MuiPrimaryWhiteTheme}>
                        <LRButton variant="outlined" color="primary" onClick={!disableEditing && onEditClick}>
                            {home ? 'Edit home' : 'Add your home'}
                        </LRButton>
                    </ThemeProvider>
                </LRTooltip>
            </div>}
        </>
    );
}

export function Header({
    disableEditing = false,
    style = {},
    onEditClick = (e)=> {},
    ...props
}) {
    const IMG_WIDTH = 192;
    const IMG_HEIGHT = 128;
    const theme = useTheme();
    const { myHome:home } = useMyHome();
    const {
        addressLine1,
        addressLine2
    } = useSplitAddress(home?.address);
    const {
        isMobile,
    } = useDeviceBreakpoints();

    return (
        <div
            {...props}
            style={{
                position: 'relative',
                backgroundColor: theme.palette.lr_colors.grade,
                padding: theme.spacing(isMobile ? 2 : 3),
                margin: -theme.spacing(2),
                marginTop: -theme.spacing(4),
                marginBottom: 0,
                ...style,
            }}
        >
            <div
                style={{
                    maxWidth: 1144, // Width of the content. Number is slightly different but aligns correctly
                    margin: '0 auto',
                }}
            >
                <Grid
                    container
                    spacing={isMobile ? 0 : 3}
                    alignItems="center"
                >
                    <Grid item>
                        <div
                            style={{
                                ...(isMobile ? {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 0,
                                    opacity: .25,
                                } : {})
                            }}
                        >
                            {home?.address ? (
                                home?.address && !home?.hero_image ? (
                                    <div style={{width: IMG_WIDTH, height: IMG_HEIGHT}}>
                                        <StaticGoogleMaps
                                            latitude={home?.latitude}
                                            longitude={home?.longitude}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            width: isMobile ? '100%' : IMG_WIDTH,
                                            height: isMobile ? '100%' : IMG_HEIGHT,
                                            borderRadius: !isMobile && 4,
                                            backgroundImage: `url("${home?.hero_image}")`,
                                            backgroundPosition: 'center center',
                                            backgroundSize: 'cover',
                                        }}
                                    />
                                )
                            ) : (
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    style={{
                                        width: isMobile ? '100%' : IMG_WIDTH,
                                        height: isMobile ? '100%' : IMG_HEIGHT,
                                        border: '1px dashed',
                                        borderColor: theme.palette.lr_colors.steak_sauce,
                                        borderRadius: !isMobile && 4,
                                        color: 'rgba(255, 255, 255, 0.1)',
                                    }}
                                >
                                    <Grid item>
                                        <HomeRounded style={{ fontSize: 80, }} />
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </Grid>
                    <Grid container item direction="column" justify="space-between" xs style={{ color: '#fff', zIndex: 1, }}>
                        <Grid item style={{ marginBottom: 4, marginTop: -4, }}>
                            <Typography variant="h6">
                                {addressLine1 || 'Your street address'}
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: '400' }}>
                                {addressLine2 || 'Your town, state, and ZIP'}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <HomeDetails disableEditing={disableEditing} onEditClick={onEditClick} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
