import React, { useState, useEffect, useRef } from 'react';
import { Page1 } from './Page1';
import { Page2 } from './Page2';
import Lockr from 'lockr';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from 'hooks';
import { useAgent } from 'hooks/useAgent';
import LaunchDarkly from 'launchdarkly-js-client-sdk';
import { useGetSubscriptionStatus } from 'hooks/data/user-addons';
import { HomeReportSignUp } from 'scenes/HomeReportSignUp';
import { SUBSCRIPTION_HOME_REPORT_LEAD_GEN } from '../../constants';

var ldClient = null;

const getUniqueId = function() {
    let uniqueId = null;
    uniqueId = Lockr.get('anonymous_unique_id');
    if(!uniqueId) {
        uniqueId = Math.random()
            .toString(16)
            .slice(2);
        Lockr.set('anonymous_unique_id', uniqueId);
    }

    return uniqueId;
};

export const OptInLandingPage = function() {
    const { affiliateCode } = useParams();
    const query = useQuery();
    const email = query.get('email') ? decodeURIComponent(query.get('email')) : null;
    const { data: agent } = useAgent(affiliateCode, { priority: 'affiliate', skipStore: true });
    const { data: leadGenSubscriptionResponse, isFetched: isLeadGenSubscriptionFetched } = useGetSubscriptionStatus(
        agent?.id,
        SUBSCRIPTION_HOME_REPORT_LEAD_GEN
    );
    const { active: isLeadGenSubscribed } = leadGenSubscriptionResponse || {};
    const uniqueId = getUniqueId();
    const [LDReady, setLDReady] = useState(false);
    const [pageVariant, setPageVariant] = useState(null);
    const [signupStep, setSignupStep] = useState(false);
    const ldInitialized = useRef(false);

    useEffect(()=> {
        if(!agent || !isLeadGenSubscriptionFetched || LDReady || ldInitialized.current) return;

        ldClient = LaunchDarkly.initialize(process.env.REACT_APP_ROBIN_LAUNCHDARKLY_CLIENT_ID, {
            anonymous: true,
            // key: uniqueId,
            custom: {
                anonymous_unique_id: uniqueId,
            },
        });

        ldClient.on('ready', ()=> {
            const flags = ldClient.allFlags();
            const optInPageFlag = flags['newsletter-opt-in-page-variants'];
            setPageVariant(optInPageFlag);
            setLDReady(true);
        });
        ldClient.on('error', handleLaunchdarklyError);

        ldInitialized.current = true;

        return ()=> {
            ldClient.on('ready', ()=> {});
            ldClient.on('error', ()=> {});
        };
    }, [LDReady, agent, isLeadGenSubscriptionFetched]);

    function handleLaunchdarklyError() {
        console.error('Launchdarkly error: Launchdarkly returned an error');
    }

    if(LDReady && pageVariant !== 'simplified with agent branding') { // https://listreports.atlassian.net/browse/AG-296
        return <Redirect to="/404" />;
    }

    return (
        <>
            {!signupStep && (
                <div>
                    {pageVariant === 'full' && (
                        <Page1 uniqueId={uniqueId} email={email} setSignupStep={setSignupStep} />
                    )}
                    {pageVariant === 'simplified' && (
                        <Page2 variation="simplified" uniqueId={uniqueId} email={email} setSignupStep={setSignupStep} />
                    )}
                    {pageVariant === 'simplified with agent branding' && (
                        <Page2
                            variation="simplified_with_agent_branding"
                            uniqueId={uniqueId}
                            email={email}
                            setSignupStep={setSignupStep}
                        />
                    )}
                </div>
            )}
            {signupStep && <HomeReportSignUp inputEmail={signupStep} />}
        </>
    );
};
