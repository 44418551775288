import React from 'react';
import LRButton from 'components/LRButton';
import theme from 'theme';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import analytics from 'services/analytics';
import Logger from 'js-logger';
import { useResponsiveBreakpoint } from 'hooks/use-responsive-breakpoint';
import { ChevronLeftRounded, CreateRounded, ExpandMoreRounded } from '@material-ui/icons';
import { AppBar, ButtonBase, ClickAwayListener, Grid, IconButton, MenuItem, Popover, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';

export function Navbar({onClose, closeLabel, ...props}) {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('xs');
    const isMobile = isXs || isSm;
    
    return (
        <>
            <AppBar
                color="inherit"
                elevation={0}
                style={{
                    borderBottom: '1px solid #ddd',
                    position: 'relative'
                }}
            >
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs={1} sm={3}>
                            {!isMobile ? (
                                <LRButton startIcon={<ChevronLeftRounded />} onClick={onClose}>
                                    {closeLabel}
                                </LRButton>
                            ) : (
                                <IconButton edge="start" onClick={onClose}>
                                    <ChevronLeftRounded />
                                </IconButton>
                            )}
                        </Grid>

                        <Grid item xs container justify="center" alignItems="center">
                            <Typography variant="h6">Open Houses</Typography>
                        </Grid>

                        <Grid item xs={1} sm={3} style={{ textAlign: 'right' }}>

                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
}
