import './patched-lockr';
import Logger from 'js-logger';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { StylesProvider } from '@material-ui/core/styles';
import reset from 'react-style-reset/string';
import { createGlobalStyle } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import babou from 'babou-js';
import App from './components/App';
import './styles/utilities.css';
import { CssBaseline } from '@material-ui/core';

// Setup CSS Injection
const GlobalStyles = createGlobalStyle`
    ${reset}

    html, body, #app {
        height: 100%;
    }

    b {
        font-weight: bold;
    }
    
    body {
        line-height: 1.15;
    }

    a {
        color: black;
    }

    :root {
        text-underline-position: under;
      }
`;

// Setup for any modules goes here
Logger.useDefaults();
Logger.setLevel(Logger[process.env.REACT_APP_LOG_LEVEL || 'DEBUG']);
Logger.info(`App Version: ${process.env.REACT_APP_VERSION}`);
babou.setConfig({
    url: process.env.REACT_APP_BABOU_URL,
    api_key: process.env.REACT_APP_BABOU_API_KEY
});

// Our apps main renderer
const WRAPPER = document.getElementById('app');
const MAIN = (
    <Fragment>
        <GlobalStyles />
        <CssBaseline />
        <StylesProvider injectFirst>
            <App />
        </StylesProvider>
    </Fragment>
);
ReactDOM.render(MAIN, WRAPPER);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
