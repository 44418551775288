import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AppBar, Toolbar, IconButton, Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme, withTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import LRTabs, { LRTab } from '../../LRTabs';

const StyledToggleButton = styled(ToggleButton)`
    text-transform: none;
    color: #000;
    padding-left: 14px !important;
    padding-right: 14px !important;
    width: 105px;

    &.Mui-selected {
        color: #000;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

const StyledToolbar = withTheme(styled(Toolbar)`
    ${({ theme })=> theme.breakpoints.only('xs')} {
        border-bottom: 1px solid ${({ theme })=> theme.palette.lr_colors.grey};
    }
`);
const StyledGridItem = styled(Grid)`
    display: flex;
    align-items: center;
`;

function Header({ defaultToggleValue, onClose, onViewToggleChange, ...otherProps }) {
    const [selectedButton, setSelectedButton] = useState(defaultToggleValue);
    const theme = useTheme();
    const gtXs = useMediaQuery(theme.breakpoints.up('sm'));

    /**
     * @function onToggleChange
     * @param {Event} e
     * @param {String} value
     */
    function onToggleChange(e, value) {
        // We dont ever want the value to be undefined so
        // default to the default value if no value
        value = value || defaultToggleValue;

        setSelectedButton(value);
        onViewToggleChange(e, value);
    }

    return (
        <Box bgcolor="#FFF">
            <StyledToolbar>
                <Grid container>
                    <StyledGridItem item xs={3}></StyledGridItem>

                    <StyledGridItem item xs={6}>
                        {/* Toggle Buttons */}
                        {gtXs && (
                            <Box margin="0 auto">
                                <ToggleButtonGroup
                                    size="small"
                                    value={selectedButton}
                                    exclusive
                                    onChange={onToggleChange}
                                >
                                    <StyledToggleButton value="map">Map</StyledToggleButton>

                                    <StyledToggleButton value="street">Street</StyledToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        )}
                    </StyledGridItem>

                    {/* Close Button */}
                    <StyledGridItem item xs={3}>
                        <Box marginLeft="auto">
                            <IconButton variant="contained" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </StyledGridItem>
                </Grid>
            </StyledToolbar>

            {/* Mobile Tabs */}
            {!gtXs && (
                <LRTabs variant="fullWidth" value={selectedButton} onChange={onToggleChange}>
                    <LRTab label="Map" value="map" />
                    <LRTab label="Street" value="street" />
                </LRTabs>
            )}
        </Box>
    );
}
Header.propTypes = {
    defaultToggleValue: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onViewToggleChange: PropTypes.func.isRequired,
};

export default React.memo(Header);
