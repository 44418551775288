import React from 'react';
import { useResponsiveBreakpoint } from '../../../../hooks';
import { FirstRunFlowDesktop } from './desktop';
import { observer } from 'mobx-react-lite';
import { FirstRunFlowMobile } from './mobile';

export const FirstRunFlow = observer(function FirstRunFlow() {
    const isMobile = useResponsiveBreakpoint('xs');

    if(isMobile) {
        return <FirstRunFlowMobile />;
    } else {
        return <FirstRunFlowDesktop />;
    }
});
