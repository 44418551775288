// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    GridList,
    GridListTile,
    InputAdornment,
    FormControl,
    InputLabel,
    InputBase,
    Grid,
    Box,
} from '@material-ui/core';
import LRSelect from '../../LRSelect';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { useResponsiveBreakpoint } from '../../../hooks';
import { PropTypes } from 'prop-types';
import LRButton from '../../LRButton';
import LRTooltip from '../../LRTooltip';
import analytics from '../../../services/analytics';

const StyledGridListTile = styled(GridListTile)`
    margin-bottom: 4px;
`;
const Label = styled(InputLabel)`
    color: #000;
    font-size: 20px;

    &.Mui-focused {
        color: #000;
    }
`;
const Input = styled(InputBase)`
    margin-top: 33px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    padding: 8px 15px;

    input {
        position: relative;
        color: #000;
        line-height: 1;
    }

    &::placeholder {
        color: #d8d8d8;
    }

    &.text-right {
        input {
            text-align: right;
        }
    }
`;

const StyledInputAdornment = styled(InputAdornment)`
    height: 16px;
    & p {
        color: #dddddd;
        line-height: 1;
    }
`;
const SplitInput = styled((props)=> <Grid container wrap="nowrap" {...props} />)`
    & .MuiGrid-item:first-child .MuiInputBase-root {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    & .MuiGrid-item:last-child .MuiInputBase-root {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
    }
`;
const StyledLRSelect = styled(LRSelect)`
    margin-top: 33px;

    select {
        line-height: 1;
        font-size: 16px;
        padding: 14px 8px 15px 8px;
        border-color: #ddd;
        width: 100%;

        &:focus {
            border-color: #ddd;
        }
    }
`;

function Form({ listing, requestSource, mobileViewOnly = false, reportId, ...props }) {
    const { MortgageCalculatorStore, ListpackStore, UserStore } = useStore();
    const isXs = useResponsiveBreakpoint('xs') || mobileViewOnly;
    const { agent, listpack } = ListpackStore;
    const [hasInteracted, setHasInteracted] = useState(false);
    const [showMortgageInsuranceLabel, setShowMortgageInsuranceLabel] = useState(false);
    const user = UserStore.user;

    function handleFormFieldChange(e) {
        if(!hasInteracted) {
            analytics.eventTrack('listpacks_consumer_listingpage_map_calculator_interaction', {
                address: listing.address,
                'listing id': listing.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
                source: requestSource,
                report_id: reportId,
            });
            setHasInteracted(true);
        }
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;

        MortgageCalculatorStore[name] = value;

        if(name === 'downPaymentPercent') {
            const val = parseInt(value || '0');

            if(val < 20) {
                MortgageCalculatorStore.other = MortgageCalculatorStore.mortgageInsurance + '';
            } else {
                MortgageCalculatorStore.other = '0';
            }
        }
        
        if(name === 'loanType') {
            MortgageCalculatorStore.interestRate = MortgageCalculatorStore.interestRates[value] || '5.81';
        }
    }

    useEffect(()=> {
        if(MortgageCalculatorStore.downPaymentPercent && MortgageCalculatorStore.downPaymentPercent < 20) {
            setShowMortgageInsuranceLabel(true);
        } else {
            setShowMortgageInsuranceLabel(false);
        }
    }, [MortgageCalculatorStore.downPaymentPercent]);

    function handleResetBtnClick() {
        analytics.eventTrack('listpacks_consumer_listingpage_reset_calculations', {
            address: listing.address,
            'listing id': listing.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            source: requestSource,
            report_id: reportId,
        });
        MortgageCalculatorStore.reset();
    }

    return (
        <form {...props}>
            <GridList cols={isXs ? 1 : 3} cellHeight="auto" spacing={28}>
                <StyledGridListTile>
                    <FormControl fullWidth>
                        <Label shrink>Home price</Label>

                        <Input
                            value={MortgageCalculatorStore.formattedHomePrice}
                            name="formattedHomePrice"
                            startAdornment={<StyledInputAdornment position="start">$</StyledInputAdornment>}
                            onChange={handleFormFieldChange}
                        />
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    <FormControl fullWidth>
                        <Label shrink>
                            {' '}
                            Down payment{' '}
                            <LRTooltip
                                arrow
                                disableFocusListener
                                disableTouchListener
                                title={
                                    'This is the amount of money you will put down up front towards the cost of your future home that will determine the amount of the mortgage loan you will need to take out.'
                                }
                            >
                                <Box
                                    className="cursor-pointer"
                                    component="span"
                                    ml={1}
                                    borderRadius="50%"
                                    border={3}
                                    borderColor="#D8D8D8"
                                    fontSize="1rem"
                                    px={1}
                                    color="#D8D8D8"
                                >
                                    <b>i</b>
                                </Box>
                            </LRTooltip>
                        </Label>

                        <SplitInput>
                            <Grid item style={{ width: '100%' }}>
                                <Input
                                    value={MortgageCalculatorStore.formattedDownPayment}
                                    name="formattedDownPayment"
                                    startAdornment={<StyledInputAdornment position="start">$</StyledInputAdornment>}
                                    onChange={handleFormFieldChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item style={{ minWidth: '80px', maxWidth: '90px', textAlign: 'right' }}>
                                <Input
                                    value={MortgageCalculatorStore.downPaymentPercent || ''}
                                    className="text-right"
                                    name="downPaymentPercent"
                                    endAdornment={<StyledInputAdornment position="end">%</StyledInputAdornment>}
                                    onChange={handleFormFieldChange}
                                />
                            </Grid>
                        </SplitInput>
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    <FormControl fullWidth>
                        <Label shrink>
                            Interest rate{' '}
                            <LRTooltip
                                arrow
                                disableFocusListener
                                disableTouchListener
                                title={
                                    'The cost of borrowing money expressed as an annual percentage rate of the total loan amount.'
                                }
                            >
                                <Box
                                    className="cursor-pointer"
                                    component="span"
                                    ml={1}
                                    borderRadius="50%"
                                    border={3}
                                    borderColor="#D8D8D8"
                                    fontSize="1rem"
                                    px={1}
                                    color="#D8D8D8"
                                >
                                    <b>i</b>
                                </Box>
                            </LRTooltip>
                        </Label>

                        <Input
                            value={MortgageCalculatorStore.formattedInterestRate}
                            align="center"
                            name="formattedInterestRate"
                            endAdornment={<StyledInputAdornment position="end">%</StyledInputAdornment>}
                            onChange={handleFormFieldChange}
                        />
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    <FormControl fullWidth>
                        <Label shrink>
                            Property taxes{' '}
                            <LRTooltip
                                arrow
                                disableFocusListener
                                disableTouchListener
                                title={
                                    "The property tax is a percentage of a property's assessed value and varies by area. A Homeowner pays this tax annually, semi-annually or as part of their monthly mortgage payment."
                                }
                            >
                                <Box
                                    className="cursor-pointer"
                                    component="span"
                                    ml={1}
                                    borderRadius="50%"
                                    border={3}
                                    borderColor="#D8D8D8"
                                    fontSize="1rem"
                                    px={1}
                                    color="#D8D8D8"
                                >
                                    <b>i</b>
                                </Box>
                            </LRTooltip>
                        </Label>

                        <Input
                            value={MortgageCalculatorStore.formattedPropertyTaxes}
                            name="formattedPropertyTaxes"
                            startAdornment={<StyledInputAdornment position="start">$</StyledInputAdornment>}
                            endAdornment={<StyledInputAdornment position="end">/month</StyledInputAdornment>}
                            onChange={handleFormFieldChange}
                        />
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    <FormControl fullWidth>
                        <Label shrink>
                            HOA dues{' '}
                            <LRTooltip
                                arrow
                                disableFocusListener
                                disableTouchListener
                                title={
                                    'These are fees paid by homeowners within a shared housing complex to pay for repairs, upkeep and operational costs.'
                                }
                            >
                                <Box
                                    className="cursor-pointer"
                                    component="span"
                                    ml={1}
                                    borderRadius="50%"
                                    border={3}
                                    borderColor="#D8D8D8"
                                    fontSize="1rem"
                                    px={1}
                                    color="#D8D8D8"
                                >
                                    <b>i</b>
                                </Box>
                            </LRTooltip>
                        </Label>

                        <Input
                            value={MortgageCalculatorStore.formattedHoaDues}
                            name="formattedHoaDues"
                            startAdornment={<StyledInputAdornment position="start">$</StyledInputAdornment>}
                            endAdornment={<StyledInputAdornment position="end">/month</StyledInputAdornment>}
                            onChange={handleFormFieldChange}
                        />
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    <FormControl fullWidth>
                        <Label shrink>
                            Home insurance{' '}
                            <LRTooltip
                                arrow
                                disableFocusListener
                                disableTouchListener
                                title={
                                    "Homeowners should purchase insurance when applying for a mortgage loan to protect against any potential damage to their future home. Rates for homeowners' insurance vary state to state."
                                }
                            >
                                <Box
                                    className="cursor-pointer"
                                    component="span"
                                    ml={1}
                                    borderRadius="50%"
                                    border={3}
                                    borderColor="#D8D8D8"
                                    fontSize="1rem"
                                    px={1}
                                    color="#D8D8D8"
                                >
                                    <b>i</b>
                                </Box>
                            </LRTooltip>
                        </Label>

                        <Input
                            value={MortgageCalculatorStore.formattedHomeInsurance}
                            name="formattedHomeInsurance"
                            startAdornment={<StyledInputAdornment position="start">$</StyledInputAdornment>}
                            endAdornment={<StyledInputAdornment position="end">/month</StyledInputAdornment>}
                            onChange={handleFormFieldChange}
                        />
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    <FormControl fullWidth>
                        <Label shrink style={{ width: 'max-content' }}>
                            {showMortgageInsuranceLabel && (
                                <>
                                    Mortgage Insurance
                                    <LRTooltip
                                        arrow
                                        disableFocusListener
                                        disableTouchListener
                                        title={
                                            'A Borrower providing less than 20% as a down payment is required to purchase mortgage insurance. This protects the lender against loss if the borrower defaults on their loan. In general, mortgage insurance rates range between 0.5%-1% of the actual loan amount. Your actual rate may vary.'
                                        }
                                    >
                                        <Box
                                            className="cursor-pointer"
                                            component="span"
                                            ml={1}
                                            borderRadius="50%"
                                            border={1}
                                            borderColor="#D8D8D8"
                                            fontSize="1rem"
                                            px={1}
                                            color="#D8D8D8"
                                        >
                                            <b>i</b>
                                        </Box>
                                    </LRTooltip>
                                </>
                            )}
                            {!showMortgageInsuranceLabel && 'Other'}
                        </Label>

                        <Input
                            value={MortgageCalculatorStore.formattedOther}
                            name="formattedOther"
                            startAdornment={<StyledInputAdornment position="start">$</StyledInputAdornment>}
                            endAdornment={<StyledInputAdornment position="end">/month</StyledInputAdornment>}
                            onChange={handleFormFieldChange}
                        />
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    {/*
                        Stupid but have to add width 100% here in order
                        for it to take the whole width.
                    */}
                    <FormControl style={{ width: '100%' }} fullWidth>
                        <Label shrink>
                            Loan type{' '}
                            <LRTooltip
                                arrow
                                disableFocusListener
                                disableTouchListener
                                title={
                                    'A fixed-rate loan such as 15 or 30-year has a set interest rate for the duration of the loan, whereas an adjustable-rate mortgage (ARM) generally has a lower set interest rate for the first 5 years and then changes to an adjustable rate for the following 25 years.'
                                }
                            >
                                <Box
                                    className="cursor-pointer"
                                    component="span"
                                    ml={1}
                                    borderRadius="50%"
                                    border={3}
                                    borderColor="#D8D8D8"
                                    fontSize="1rem"
                                    px={1}
                                    color="#D8D8D8"
                                >
                                    <b>i</b>
                                </Box>
                            </LRTooltip>
                        </Label>

                        <StyledLRSelect
                            name="loanType"
                            inputValue={MortgageCalculatorStore.loanType}
                            native={true}
                            onChange={handleFormFieldChange}
                        >
                            <option value="30">30 year fixed</option>
                            <option value="15">15 year fixed</option>
                            <option value="5/1">5/1 ARM</option>
                        </StyledLRSelect>
                    </FormControl>
                </StyledGridListTile>

                <StyledGridListTile>
                    <Box height="100%" display="flex" alignItems="flex-end" justifyContent="center">
                        <Box width={156}>
                            <LRButton fullWidth color="primary" variant="contained" onClick={handleResetBtnClick}>
                                Reset Calculations
                            </LRButton>
                        </Box>
                    </Box>
                </StyledGridListTile>
            </GridList>
        </form>
    );
}

Form.propTypes = {
    listing: PropTypes.object,
    mobileViewOnly: PropTypes.bool,
};

export default React.memo(observer(Form));
