import React from 'react';
import { Box } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import styled from 'styled-components';
import { useResponsiveBreakpoint } from '../../hooks';
import PropTypes from 'prop-types';

const ListreportsLogo = styled.img`
    width: 130px;
    height: 22px;
`;

const BackIcon = styled(ChevronLeft)`
    vertical-align: sub;
`;

function Header({ onBack, height=65, justifyContent='center' }) {
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;

    return (
        <Box position="relative"
            height={height}
            display="flex"
            alignItems="center"
            justifyContent={justifyContent}
            px={(justifyContent === 'center')
                ? 0
                : (isMobile)
                    ? 3
                    : 5
            }
        >
            {onBack && (
                <Box style={{ cursor: 'pointer' }} position="absolute" height={22} left={isMobile ? '20px' : '40px'}>
                    <BackIcon onClick={onBack} />
                </Box>
            )}
            <ListreportsLogo src="/images/logo.png" />
        </Box>
    );
}

Header.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    justifyContent: PropTypes.string,
    onBack: PropTypes.func,
};

export default Header;
