import { action, decorate, observable } from 'mobx';

export class GlobalConfettiStore {
    open = false;

    show(autoHideDuration= 10000) {
        this.open = true;
        setTimeout(()=> { 
            this.open = false;
        }, autoHideDuration);
    }

    hide() {
        this.open = false;
    }
}

decorate(GlobalConfettiStore, {
    open: observable,
    
    show: action,
    hide: action,
});

export default new GlobalConfettiStore();
