import React from 'react';
import { AgentNotificationBase } from '../AgentNotificationBase';
import { Typography, useTheme } from '@material-ui/core';
import splitAddress from '../../../../../services/split-address';
import { timeago } from '../../../../../services/timeago';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../mobx-store';
import notificationConfig from '../../../../../mobx-store/notifications/config';
import analytics from '../../../../../services/analytics';

export const AgentNotificationNewNote = observer(function AgentNotificationNewNote({
    notification = {},
    onMarkAsReadClick = ()=> {},
    onPrimaryCTAClick = ()=> {},
}) {
    const theme = useTheme();
    const  { UserStore } = useStore();
    const { user, isAgent, isLO } = UserStore;
    const history = useHistory();
    const {
        content: {
            address,
        }
    } = notification;
    const [addressLine1] = splitAddress(address);

    // Functions

    function onPrimaryButtonClick(e) {
        trackClick('cta_button');
        onPrimaryCTAClick(e);
        if(!(isAgent || isLO)) onMarkAsReadClick(e, notification);
        
        const conf = notificationConfig[notification.type] || {};
        const toUrl = (notification?.content?.path)
            ? notification.content.path
            : conf.cta
                ? conf.cta(notification)
                : null;
        if(toUrl) {
            history.push({
                pathname: toUrl,
                state: { trackSource: 'robin_notification_center' }
            });
        }
    }

    function onSecondaryButtonClick(e) {
        trackClick('dismiss');
        if(!(isAgent || isLO)) onMarkAsReadClick(e, notification);
    }

    function trackClick(source) {
        analytics.eventTrack('robin_board_drawer_notification_card_clicked', {
            type: 'note added',
            source,
        });
    }

    if(!notification.id) return null;

    return (
        <AgentNotificationBase 
            caption={(
                <Typography variant="caption" color="primary">
                    <b>NEW NOTE</b>
                </Typography>
            )}
            title={`${addressLine1} has a new note 📝`}
            secondaryButtonLabel="Dismiss"
            primaryButtonLabel="Review now"
            onPrimaryButtonClick={onPrimaryButtonClick}
            onSecondaryButtonClick={onSecondaryButtonClick}
            showSecondaryButton={!(isAgent || isLO)}
        >
            <div
                style={{
                    color: theme.palette.lr_colors.steak_sauce,
                }}
            >
                Created by {user.affiliate?.first_name} {timeago(notification.updatedAt)}. 
            </div>
        </AgentNotificationBase>
    );
});
