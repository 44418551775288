import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useResponsiveBreakpoint } from '../../../hooks';

export const Navbar = observer(({ onModalClose, selectedCategory })=> {
    const isXs = useResponsiveBreakpoint('xs');
    return (
        <Box>
            <Box position="relative" px={4} py={2}>
                <Box display={{ md: 'flex' }} alignItems="center">
                    <Box fontSize="h6.fontSize" fontWeight="bold" textAlign="center" marginRight={{ md: 4 }}>
                        {selectedCategory && isXs && selectedCategory}
                        {!selectedCategory && isXs && 'This Home'}
                        {!isXs && 'Explore nearby'}
                    </Box>
                </Box>
                <Box position="absolute" display="flex" alignItems="center" right={32} top={0} bottom={0}>
                    <Box onClick={onModalClose} color="black" style={{ cursor: 'pointer' }}>
                        <Close />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});
