import React from 'react';
import { SvgIcon } from '@material-ui/core';

function CircleArrowIcon({width = '36', height = '36', viewBox = '0 0 36 36', fill = 'action', right=false, ...props}) {
    const style = {
        transform: right ? 'scale(-1,1)' : '',
    };
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} color={fill} {...props} style={style}>
            <circle cx="18" cy="18" r="17.5" stroke="white" />
            <path
                d="M21 12.705C20.8132 12.5177 20.5595 12.4125 20.295 12.4125C20.0305 12.4125 19.7769 12.5177 19.59 12.705L15 17.295C14.61 17.685 14.61 18.315 15 18.705L19.59 23.295C19.98 23.685 20.61 23.685 21 23.295C21.39 22.905 21.39 22.275 21 21.885L17.12 17.995L21 14.115C21.39 13.725 21.38 13.085 21 12.705Z"
                fill="white"
            />
        </SvgIcon>
    );
}

export default CircleArrowIcon;
