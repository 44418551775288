import styled from 'styled-components';

export const ResponsiveImg = styled.img`
    display: block;
    max-width: 100%;
    height: auto;

    @media all and (-ms-high-contrast: none) {
        & {
            width: 100%;
        }
    }
`;
