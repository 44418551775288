import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Collapse, Grid, Typography, useTheme, withTheme } from '@material-ui/core';
import { useFeaturedPropertyPacks } from 'hooks/useFeaturedPropertyPacks';
import { useUserPreferencesAreaIds } from 'hooks/useUserPreferencesAreaIds';
import { useSplitAddress } from 'hooks/useSplitAddress';
import Logger from 'js-logger';
import { partial, sampleSize } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useCallback } from 'react';
import analytics from 'services/analytics';
import { Section, SectionContainer, SectionHeader, SectionTitle } from '../Section';
import _ from 'lodash';
import { useMyHome } from 'hooks/useMyHome';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useStore } from 'mobx-store';
import { useLoginAndContinue } from 'hooks/useLoginAndContinue';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';

const PropertyPackCointainer = styled.div`
    margin: 0 auto;
    width: 296px;
    min-height: 205px;
    display: flex;
    align-items: center;
    position: relative;
`;

const PropertyPackStyledCard = styled(Card)`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
    width: 296px;
    z-index: 1;
`;

const PropertyPackStyledCardContent = styled(CardContent)`
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
`;

const PropertyPackStyledCardMedia = withTheme(styled(CardMedia)`
    position: relative;
    margin: 16px 16px 0 16px;
    height: 136px;
    border-radius: 4px;
    background-color: ${({ theme })=> theme.palette.lr_colors.compliance_grey};
    width: ${(props)=> props?.width};
`);

const PropertyPackStyledCardMediaSecondary = withTheme(styled(CardMedia)`
    position: absolute;
    height: 66px;
    border-radius: 4px;
    background-color: ${({ theme })=> theme.palette.lr_colors.compliance_grey};
    width: 49.5%;
    bottom: ${(props)=> props?.bottom};
    left: 178px;
`);

function PropertyPackShadowPaper({
    style = {},
    ...props
}) {
    const rotation = useMemo(()=> {
        return generateRandomRotation();
    }, []);

    function generateRandomRotation() {
        const MAX_NUMBERS = 10;
        const randRotation = (Math.ceil(Math.random() * MAX_NUMBERS)) - Math.round(MAX_NUMBERS / 2);

        if(randRotation <= 2 && randRotation >= -2) return generateRandomRotation();

        return randRotation;
    }

    return (
        <div
            {...props}
            style={{
                borderRadius: 4,
                position: 'absolute',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                width: 296,
                height: '100%',
                minHeight: 205,
                backgroundColor: '#fff',
                zIndex: 0,
                transform: `rotate(${rotation}deg)`,
                ...style,
            }}
        ></div>
    );
}

function PropertyPack({
    title,
    images,
    index = 0,
    style = {},
    ...props
}) {
    const has3Images = useMemo(()=> (Boolean(images?.length >= 3)), [images]);
    const heroImage = useMemo(()=> (images?.[0]), [images]);
    const isOddIndex = useMemo(()=> Boolean(index % 2), [index]);

    return (
        <PropertyPackCointainer {...props} style={style}>
            <PropertyPackShadowPaper odd={isOddIndex} />
            <PropertyPackStyledCard classes={{ root: 'tour-target-my-listpack-card-cta' }} /* onClick={handleClick} */>
                <CardActionArea>
                    <div>
                        <PropertyPackStyledCardMedia
                            image={heroImage || null}
                            width={has3Images ? '58.8%' : null}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    borderRadius: '4px 4px 0px 0px',
                                    bottom: 0,
                                    minWidth: 190,
                                    width: '100%',
                                    height: 30,
                                    background: 'linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%)',
                                    transform: 'rotate(-180deg)'
                                }}
                            ></div>


                            {has3Images && images[1] && (
                                <PropertyPackStyledCardMediaSecondary
                                    image={images[1] || null}
                                    bottom={'70px'}
                                />
                            )}

                            {has3Images && images[2] && (
                                <PropertyPackStyledCardMediaSecondary
                                    image={images[2] || null}
                                    bottom={'0px'}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            borderRadius: '4px 4px 0px 0px',
                                            bottom: 0,
                                            width: '100%',
                                            height: 30,
                                            background: 'linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%)',
                                            transform: 'rotate(-180deg)'
                                        }}
                                    ></div>
                                </PropertyPackStyledCardMediaSecondary>
                            )}
                        </PropertyPackStyledCardMedia>
                    </div>
                    <PropertyPackStyledCardContent>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </PropertyPackStyledCardContent>
                </CardActionArea>
            </PropertyPackStyledCard>
        </PropertyPackCointainer>
    );
}

export const FeaturedPropertyPacks = observer(function FeaturedPropertyPacks({
    style = {},
    ...props
}) {
    const { isIPadUp } = useDeviceBreakpoints();
    const theme = useTheme();
    const history = useHistory();
    const isLoggedIn = useIsLoggedIn();
    const { LoginSignupModalStore, } = useStore();
    const { myHome:home } = useMyHome();
    const { data:userPrefsAreaIds, isLoading:isLoadingUserPrefAreaIds } = useUserPreferencesAreaIds();
    const { addressLine2 } = useSplitAddress(home?.address);
    const validZipCode = useCallback(
        (address)=> {
            return /^\d{5}(-\d{4})?$/.test(address.slice(-5));
        },
        [],
    );
    const areas = useMemo(()=> {
        if(isLoadingUserPrefAreaIds) return;

        if(userPrefsAreaIds?.length) {
            return userPrefsAreaIds;
        }

        if(!home) return;

        if(home?.zipcode && validZipCode(home.zipcode)){
            return [home.zipcode];
        } else {
            return [_.trim(addressLine2)];
        };
        // Commented out in case in the future we want to check if the property packs have listings
        // return [{
        //     formatted_term: home.zipcode,
        //     search_term: home.zipcode,
        //     search_type: 'zipcode',
        // },];
    }, [home, userPrefsAreaIds, isLoadingUserPrefAreaIds, addressLine2, validZipCode]);
    const linkAreas = useMemo(()=> {
        return areas?.length ? areas.join('|') : null;
        // Commented out in case in the future we want to check if the property packs have listings
        // return areas.map((area)=> area.search_term).join('|');
    }, [areas]);
    const featuredPropertyPacks = useFeaturedPropertyPacks();
    // Commented out in case in the future we want to check if the property packs have listings
    // const featuredPropertyPacks = useFeaturedPropertyPacks(areas);
    const propertyPacks = useMemo(()=> {
        return sampleSize(featuredPropertyPacks.data, 3);

        // Commented out in case in the future we want to check if the property packs have listings
        // return sampleSize(featuredPropertyPacks.data?.filter((propertyPack)=> Boolean(propertyPack.listings?.data?.length)), 3);
    }, [featuredPropertyPacks.isLoading]);
    const isOpen = useMemo(()=> {
        if(featuredPropertyPacks.isLoading || isLoadingUserPrefAreaIds) {
            return true;
        } else if(linkAreas && propertyPacks?.length) {
            return true;
        } else {
            return false;
        }
    }, [featuredPropertyPacks.isLoading, isLoadingUserPrefAreaIds, linkAreas, propertyPacks]);
    const loginAndContinue = useLoginAndContinue({
        singupFormOptions: {
            homeReportTextMessageOptInCheckboxEnabled: true,
        }
    });

    // Functions

    function onPropertyPackClick(e, propertyPack) {
        history.push({
            pathname: `/boards/listpack/create/${linkAreas}`,
            search: `?fields=${JSON.stringify(propertyPack.fields)}`,
            state: { from: '/boards/home-report' }
        });
    }

    function getImages(propertyPack) {
        const imgs = [];

        if(propertyPack?.hero_image) imgs.push(propertyPack.hero_image);
        if(propertyPack?.secondary_image) imgs.push(propertyPack.secondary_image);
        if(propertyPack?.tertiary_image) imgs.push(propertyPack.tertiary_image);

        return imgs;
    }

    return (
        <Collapse in={isOpen}>
            <Section
                {...props}
                style={{
                    background: theme.palette.lr_colors.grey_medium_second,
                    border: isIPadUp && '0',
                    boxShadow: 'none',
                    ...style,
                }}
            >
                <SectionHeader>
                    <SectionTitle>
                        Featured property packs
                    </SectionTitle>
                </SectionHeader>

                <SectionContainer style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingBottom: theme.spacing(4), paddingTop: theme.spacing(1), }}>
                    {featuredPropertyPacks.isLoading || isLoadingUserPrefAreaIds ? (
                        <Grid container justify="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        linkAreas && propertyPacks.slice(0, 3).map((propertyPack, i)=> (
                            <PropertyPack
                                key={propertyPack.id || i}
                                title={propertyPack.title}
                                images={getImages(propertyPack)}
                                style={{
                                    marginTop: theme.spacing(i ? 5 : 3),
                                }}
                                onClick={analytics.eventTrackOnEvent('robin_home_report_feature_property_pack_click', { Source: propertyPack.title, }, loginAndContinue(partial(onPropertyPackClick, partial.placeholder, propertyPack)))}
                            />
                        ))
                    )}
                </SectionContainer>
            </Section>
        </Collapse>
    );
});
