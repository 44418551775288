import React from 'react';
import { Box } from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../hooks';

function FourZeroFourPage() {
    const isXs = useResponsiveBreakpoint('xs');

    return (
        <Box height="100vh" width="100vw" display="flex" justifyContent="center" alignItems="center">
            <Box left={isXs ? '32px' : '85px'} top={isXs ? '24px' : '64px'} position="absolute">
                <img src="/images/logo.png" height="25" />
            </Box>

            <Box textAlign="center" py={2}>
                <Box>
                    <img src="/images/404.gif" width="273" />
                </Box>
                {/* 48 */}
                <Box fontSize={isXs ? 'h4.fontSize' : 'h3.fontSize'}>Hrm...</Box>
                {/* 32 */}
                <Box fontSize={isXs ? 'h5.fontSize' : 'h4.fontSize'}>We can’t find the page you were looking for.</Box>
                {/* 24 */}
                <Box fontSize={isXs ? 'h6.fontSize' : 'h5.fontSize'}>
                    It might have expired, or there could be a typo.
                </Box>
            </Box>
        </Box>
    );
}

export default FourZeroFourPage;
