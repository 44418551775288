import { useStore } from '../../mobx-store';
import { useEffect, useState } from 'react';
import Logger from 'js-logger';
import { autorun } from 'mobx';
import _, { values, partial, sortBy, orderBy } from 'lodash';

const logger = Logger.get('useBoardCards');

export function useBoardCards() {
    const { boardsStore, LanesStore } = useStore();
    const { activeBoard } = boardsStore || {};
    const [unarchivedBoardCardsByLaneId, setUnarchivedBoardCardsByLaneId] = useState({});
    const [boardCardsByLaneId, setBoardCardsByLaneId] = useState({});
    const [loaded, setLoaded] = useState(false);

    /**
     * Watches changes from the store so we can keep the local
     * store in sync. We are doing this for performance reasons.
     */
    useEffect(()=>
        autorun(
            ()=> {
                const boardCardsByLaneId = {};
                const unarchivedBoardCardsByLaneId = {};

                values(boardsStore?.activeBoard?.boardCardsById || {})
                    .map((item)=> {
                        boardCardsByLaneId[item.laneId] = boardCardsByLaneId[item.columnId] || [];
                        boardCardsByLaneId[item.laneId].push(item);

                        if(!item.archived) {
                            unarchivedBoardCardsByLaneId[item.laneId] = unarchivedBoardCardsByLaneId[item.columnId] || [];
                            unarchivedBoardCardsByLaneId[item.laneId].push(item);
                        }
                    }, {});

                LanesStore.lanes.forEach((lane)=> {

                    const sortFunction = lane.selectedSortFunction || partial(orderBy, _, LanesStore.sortByLaneId[lane.id]?.id, LanesStore.sortByLaneId[lane.id]?.order);
                    let cards = unarchivedBoardCardsByLaneId[lane.id] || [];
                    unarchivedBoardCardsByLaneId[lane.id] = sortFunction(_.filter(cards, { laneId: lane.id }));

                    cards = boardCardsByLaneId[lane.id] || [];
                    boardCardsByLaneId[lane.id] = sortFunction(_.filter(cards, { laneId: lane.id }));
                });

                setUnarchivedBoardCardsByLaneId(unarchivedBoardCardsByLaneId);
                setBoardCardsByLaneId(boardCardsByLaneId);
                setLoaded(true);
            }
        )
    , []);

    // Functions

    function getUnarchivedBoardCardsByLaneId(laneId) {
        return unarchivedBoardCardsByLaneId[laneId] || [];
    }

    function getBoardCardsByLaneId(laneId) {
        return boardCardsByLaneId[laneId] || [];
    }

    return {
        loaded,
        unarchivedBoardCardsByLaneId: unarchivedBoardCardsByLaneId,
        boardCards: activeBoard?.boardCards || [],
        boardCardsById: activeBoard?.boardCardsById || null,
        getBoardCardsByLaneId,
        getUnarchivedBoardCardsByLaneId,
    };
}
