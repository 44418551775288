// eslint-disable-next-line
import Logger from 'js-logger';
import { decorate, observable, computed, action, runInAction } from 'mobx';
import addCommas from '../../services/add-commas';
import hoiRates from '../../services/hoi-rates';
import axios from 'axios';
import Promise from 'bluebird';

const DEFAULT_INTEREST_RATE = '6.91';

class MortgageCalculatorStore {
    _resetValues;

    // Defaults as a fallback
    interestRates = {
        '30': '6.91',
        '15': '4.92',
        '5/1': '4.41'
    };

    // Constructor
    constructor() {
        this.setInitialValues();
        this._fetchInterestRates();
    }

    // Computed Vars
    get downPaymentPercent() {
        try {
            return Math.round(((this.downPayment || 0) / this.homePrice) * 100);
        } catch (err) {
            return 0;
        }
    }

    set downPaymentPercent(value) {
        value = parseInt(value);

        if(isNaN(value)) value = 0;

        this.downPayment = Math.round((this.homePrice * Math.min(value, 100)) / 100);
    }

    get formattedDownPayment() {
        return [this.wholeNumbers, addCommas].reduce((result, func)=> {
            return func(result);
        }, this.downPayment);
    }

    set formattedDownPayment(value) {
        this.downPayment = parseFloat(String(value)
            .replace(/[^0-9.]/g, ''));
    }

    get formattedHomePrice() {
        return [this.wholeNumbers, addCommas].reduce((result, func)=> {
            return func(result);
        }, this.homePrice);
    }

    set formattedHomePrice(value) {
        this.homePrice = parseFloat(String(value)
            .replace(/[^0-9.]/g, ''));
    }

    get formattedInterestRate() {
        return [this.formatInterestRate].reduce((result, func)=> {
            return func(result);
        }, this.interestRate);
    }

    set formattedInterestRate(value) {
        this.interestRate = String(value)
            .replace(/[^0-9.]/g, '');
    }

    get formattedPropertyTaxes() {
        return [this.wholeNumbers, addCommas].reduce((result, func)=> {
            return func(result);
        }, this.propertyTaxes);
    }

    set formattedPropertyTaxes(value) {
        if(~[undefined, null, ''].indexOf(value)) {
            value = 0;
        }

        this.propertyTaxes = parseFloat(String(value)
            .replace(/[^0-9.]/g, ''));
    }

    get formattedHoaDues() {
        return [this.wholeNumbers, addCommas].reduce((result, func)=> {
            return func(result);
        }, this.hoaDues);
    }

    set formattedHoaDues(value) {
        if(~[undefined, null, ''].indexOf(value)) {
            value = 0;
        }

        this.hoaDues = parseFloat(String(value)
            .replace(/[^0-9.]/g, ''));
    }

    get formattedHomeInsurance() {
        return [this.floatNumbers, addCommas].reduce((result, func)=> {
            return func(result);
        }, this.homeInsurance);
    }

    set formattedHomeInsurance(value) {
        if(~[undefined, null, ''].indexOf(value)) {
            value = 0;
        }

        this.homeInsurance = parseFloat(String(value)
            .replace(/[^0-9.]/g, ''));
    }

    get formattedOther() {
        return [this.wholeNumbers, addCommas].reduce((result, func)=> {
            return func(result);
        }, this.other);
    }

    set formattedOther(value) {
        if(~[undefined, null, ''].indexOf(value)) {
            value = 0;
        }

        this.other = parseFloat(String(value)
            .replace(/[^0-9.]/g, ''));
    }

    get formattedLoanType() {
        switch(this.loanType) {
            case '30':
                return '30 year fixed';

            case '15':
                return '15 year fixed';

            case '5/1':
                return '5/1 ARM';
            default:
                return '';
        }
    }

    get monthlyPayment() {
        let payment = this.principalAndInterest;
        if(this.homeInsurance) payment += this.homeInsurance;
        if(this.propertyTaxes) payment += this.propertyTaxes;
        if(this.hoaDues) payment += this.hoaDues;
        if(this.other) payment += this.other;
        // if(vm.model.down_payment_percent < .2 && vm.model.mortgage_insurance) payment += Math.round(vm.model.mortgage_insurance);
        return payment;
    }

    get formattedMonthlyPayment() {
        return addCommas(this.monthlyPayment);
    }

    get numberOfPayments() {
        switch(this.loanType) {
            case '30':
                return 30 * 12;

            case '15':
                return 15 * 12;

            case '5/1':
                return 30 * 12;
            default:
                return 0;
        }
    }

    get principalAndInterest() {
        const downPayment = isNaN(this.downPayment) ? 0 : this.downPayment;
        const interestRate = isNaN(this.interestRate) ? 0 : parseFloat(this.interestRate);
        const principal = this.homePrice - downPayment;
        const interest = interestRate / 100 / 12;
        const pNI =
            Math.round(
                ((principal * interest * Math.pow(1 + interest, this.numberOfPayments)) /
                    (Math.pow(1 + interest, this.numberOfPayments) - 1)) *
                    100
            ) / 100;

        return isNaN(pNI) ? principal / this.numberOfPayments : pNI;
    }

    get formattedPrincipalAndInterest() {
        return addCommas(this.principalAndInterest);
    }

    get mortgageInsurance() {
        const homePrice = isNaN(this.homePrice) ? 0 : this.homePrice;
        const downPayment = isNaN(this.downPayment) ? 0 : this.downPayment;
        const principal = homePrice - downPayment;

        return Math.round((principal * (this.mortgageInsurancePercent / 100)) / 12);
    }

    // Private Functions

    /**
     * Fetch the latest interest rates
     *
     * @function _fetchInterestRates
     */
    async _fetchInterestRates() {
        const types = {
            '30': '30-Yr FRM',
            '15': '15-Yr FRM',
            // '5/1': '5/1-Yr ARM'
        };

        async function fetchRateByType(type) {
            const response = await axios.get(
                `${process.env.REACT_APP_PORTAL_URL}/v1/mortgage-rates/${encodeURIComponent(types[type])}`
            );
            return response.data.data;
        }

        try {
            const response = await Promise.props({
                '30': fetchRateByType('30'),
                '15': fetchRateByType('15'),
                // '5/1': fetchRateByType('5/1')
            });

            runInAction(()=> {
                this.interestRates = response;
                this.interestRate = response['30'];
            });
        } catch (err) {
            Logger.warn('_fetchInterestRates error', err);
            this.interestRate = DEFAULT_INTEREST_RATE;
            return {};
        }
    }

    // Public Functions

    // Helpers
    /**
     * @function this.wholeNumbers
     * @param {Number|String} value
     */
    wholeNumbers(value) {
        value = String(value || '')
            .replace(/[^0-9]/g, '');

        if(value === '') return value;

        return parseInt(value);
    }

    /**
     * @function this.floatNumbers
     * @param {Number|String} value
     */
    floatNumbers(value) {
        let [front, back] = String(value || '')
            .split('.');
        front = parseInt(front || '0');
        back = parseInt((back || '').substr(0, 2));

        return parseFloat(`${front || 0}.${back}`);
    }

    /**
     * @function this.formatInterestRate
     * @param {Number|String} value
     */
    formatInterestRate(value) {
        const dot = ~String(value || '')
            .indexOf('.') ? '.' : '';
        let [front, back] = String(value || '')
            .split('.');
        front = (front || '0').replace(/^0+(\d)/, '$1');
        back = (back || '').substr(0, 2);

        return `${front}${dot}${back}`;
    }

    // End Helpers

    /**
     * Sets the initial values. Use this to pass the data
     * from the listing to reset the mortgage calculator.
     *
     * @function setInitialValues
     * @param {ArrAy} arr - ex. [{ name: 'homePrice', value: 800000 }, ...]
     */
    setInitialValues(arr = []) {
        // Reset any values that should have defaults
        this.downPayment = 0;
        this.hoaDues = 0;
        this.homeInsurance = 0;
        this.homePrice = 0;
        // this.interestRate = DEFAULT_INTEREST_RATE; // <--------------------------------------------------
        this.loanType = '30';
        this.mortgageInsurancePercent = 0.75;
        this.other = 0;
        this.propertyTaxes = 0;
        this.state = '';
        this.downPaymentPercent = 20;

        arr.forEach(({ name, value })=> {
            this[name] = value;
        });

        if(this.state) {
            const hoiRate = hoiRates[this.state.toLowerCase()];

            if(hoiRate) {
                this.homeInsurance = Math.round((this.homePrice * (hoiRate / 100)) / 12);
            }
        }

        this._resetValues = arr;
    }

    /**
     * Resets the values to the last time setInitialValues
     * was called.
     *
     * @function reset
     */
    reset() {
        this.setInitialValues(this._resetValues);
    }
}

decorate(MortgageCalculatorStore, {
    // Variables
    downPayment: observable,
    hoaDues: observable,
    homeInsurance: observable,
    homePrice: observable,
    interestRate: observable,
    interestRates: observable,
    loanType: observable,
    other: observable,
    propertyTaxes: observable,

    // Computed Vars
    downPaymentPercent: computed,
    formattedDownPayment: computed,
    formattedHoaDues: computed,
    formattedHomeInsurance: computed,
    formattedHomePrice: computed,
    formattedInterestRate: computed,
    formattedLoanType: computed,
    formattedMonthlyPayment: computed,
    formattedOther: computed,
    formattedPrincipalAndInterest: computed,
    formattedPropertyTaxes: computed,
    monthlyPayment: computed,
    numberOfPayments: computed,
    principalAndInterest: computed,
    mortgageInsurance: computed,

    // Actions
    _fetchInterestRates: action,
    setInitialValues: action,
    reset: action
});

export default MortgageCalculatorStore;
