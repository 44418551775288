import { CircularProgress, Divider, Hidden, Typography, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTaskManagementState, TaskManagementStateContext, useTaskManagementStateContext } from './context';
import LRButton from '../../../components/LRButton';
import LRDropdown from '../../../components/LRDropdown';
import { useActiveBoard, useStore } from '../../../mobx-store';
import TaskItem from './task-item';
import { refreshQueries, useTasks } from './hooks';
import AddTaskModal from './AddTaskModal';
import { observer } from 'mobx-react-lite';
import { useResponsive } from 'hooks';
import analytics from 'services/analytics';

const SKELETON_TASKS = [
    { id: 1, type: 'skeleton_task'},
    { id: 2, type: 'skeleton_task'},
    { id: 3, type: 'skeleton_task'},
];

const filterOptions = ['All', 'Incomplete', 'Completed'];

function Header({ isLoading }) {
    const r = useResponsive();
    const theme = useTheme();
    const activeBoard = useActiveBoard();
    const { filter, setFilter, setOpenTaskModal } = useTaskManagementStateContext();

    function handleFilterChange(event) {
        // Refresh current query in case there are updates
        refreshQueries(activeBoard.id, { filterOption: filter.toLowerCase() });
        setFilter(event.target.value);
    }

    function handleOnAddTask() {
        analytics.eventTrack('robin_overview_todo_add_task_button_click');
        setOpenTaskModal(true);
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: r({ xs: 0, md: `${theme.spacing(1.5)}px ${0}px` }),
            }}
        >
            <Hidden smDown>
                <Typography
                    variant="h6"
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            marginRight: theme.spacing(1),
                        }}
                    >
                        To-do
                    </span>{' '}
                </Typography>
            </Hidden>
            <div
                className="space-x-2"
                style={{
                    display: 'flex',
                }}
            >
                <LRDropdown
                    selectedOption={filter}
                    options={filterOptions}
                    onChange={handleFilterChange}
                    disabled={isLoading}
                />
                <LRButton onClick={handleOnAddTask} variant="contained" color="primary" disabled={isLoading}>
                    + Add task
                </LRButton>
            </div>
        </div>
    );
}

function TaskList({ tasks }) {
    return (
        <ul className="space-y-2">
            {tasks.map((task, index)=> (
                <li key={task.id}>
                    <TaskItem task={task} important={index < 3 && !task?.completedAt} />
                </li>
            ))}
        </ul>
    );
}

const TaskManagement = observer(function TaskManagement() {
    const theme = useTheme();
    const r = useResponsive();
    const { boardsStore } = useStore();
    const activeBoard = useActiveBoard();
    const state = useTaskManagementState('Incomplete');
    const { filter, openTaskModal, setOpenTaskModal } = state;

    const {
        isLoading: isLoadingAllTasks,
        data: allTasksData,
    } = useTasks(activeBoard?.id, { filter: 'all' });

    const {
        isLoading,
        data: tasksData,
        fetchNextPage: fetchMore,
        hasNextPage: canFetchMore,
        isFetchingNextPage: isFetchingMore,
    } = useTasks(activeBoard?.id, { filter: filter.toLowerCase() });

    const allTasks = useMemo(()=> (allTasksData ? allTasksData.pages.flatMap(( tasks )=> tasks) : []), [
        allTasksData,
    ]);
    const tasks = useMemo(()=> (tasksData ? tasksData.pages.flatMap(( tasks )=> tasks) : []), [tasksData]);
   
    const isLoadingData = boardsStore.loading || !boardsStore.loaded || isLoading;
   
    return (
        <TaskManagementStateContext.Provider value={state}>
            <div>
                <div
                    style={{
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                        paddingTop: r({ xs: theme.spacing(2), md: 0 }),
                        paddingBottom: r({ xs: theme.spacing(2), md: 0 }),
                    }}
                >
                    <Header isLoading={ isLoadingData || !activeBoard.loadedBoardData } />
                    <Hidden smDown>
                        <Divider style={{ marginLeft: theme.spacing(-2), marginRight: theme.spacing(-2) }}></Divider>
                    </Hidden>
                    {boardsStore.loaded && activeBoard.loadedBoardData && !isLoading && !isLoadingAllTasks && (
                        <div
                            style={{
                                padding: `${theme.spacing(2)}px ${0}px`,
                            }}
                        >
                            {tasks && tasks.length ? ( // There are tasks
                                <TaskList tasks={tasks} />
                            ) : allTasks.length === 0 ? ( // No tasks at all
                                <Typography
                                    variant="body1"
                                    style={{
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                        paddingTop: theme.spacing(2),
                                        paddingBottom: r({ xs: 0, md: theme.spacing(2) }),
                                    }}
                                >
                                    <span role="img" aria-label="emoji">
                                        ✔️
                                    </span>{' '}
                                    Make sure to check this often! You’ll see priority items to attend to here, like
                                    notes or requests for home tours.
                                </Typography>
                            ) : (
                                // No incompleted tasks
                                <Typography
                                    variant="body1"
                                    style={{
                                        textAlign: r({ xs: 'center', md: 'left' }),
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                        paddingTop: r({ xs: 0, md: theme.spacing(2) }),
                                        paddingBottom: r({ xs: 0, md: theme.spacing(2) }),
                                    }}
                                >
                                    <span role="img" aria-label="emoji">
                                        🎉
                                    </span>{' '}
                                    You completed your to-do list! We’ll add more items here if you need to address
                                    them.
                                </Typography>
                            )}
                        </div>
                    )}
                    {isLoadingData && (
                        <div
                            style={{
                                padding: `${theme.spacing(2)}px ${0}px`,
                            }}
                        >
                            <TaskList tasks={SKELETON_TASKS} />
                        </div>
                    )}
                </div>
                {(canFetchMore || isFetchingMore) && (
                    <>
                        <Divider></Divider>
                        <LRButton
                            onClick={fetchMore}
                            disabled={isFetchingMore || false}
                            variant="text"
                            fullWidth
                            style={{
                                padding: theme.spacing(1.5),
                            }}
                        >
                            {!isFetchingMore && 'Show more'}
                            {isFetchingMore && <CircularProgress color="secondary" size="20px" />}
                        </LRButton>
                    </>
                )}
            </div>
            <AddTaskModal open={openTaskModal} onClose={()=> setOpenTaskModal(false)} />
        </TaskManagementStateContext.Provider>
    );
});

export default TaskManagement;
