import React from 'react';
import styled from 'styled-components';
import { Avatar, Box, Divider, Typography, withTheme } from '@material-ui/core';
import { useStore } from '../../../../mobx-store';

const Title = withTheme(styled(Typography)`
    line-height: 2.25rem;
    letter-spacing: 0.0093rem;
    font-weight: 500;
    margin-bottom: ${({ theme })=> theme.spacing(2)}px;
`);

const Subtitle = withTheme(styled(Typography)`
    fontsize: '1rem';
    line-height: 1.25rem;
    margin-bottom: ${({ theme })=> theme.spacing(2)}px;
`);

const StyledAvatar = withTheme(styled(Avatar)`
    width: ${({ theme })=> theme.spacing(8)}px;
    height: ${({ theme })=> theme.spacing(8)}px;
    ${({theme})=> theme.breakpoints.up('sm')}{
        margin-right: ${({ theme })=> theme.spacing(2)}px;
    }
`);

const Lender = styled(Typography)`
    font-weight: bold;
`;

const Company = styled(Typography)``;

const NMLS = withTheme(styled(Typography)`
    font-size: 0.75rem;
    color: ${({ theme })=> theme.palette.lr_colors.border_grey_lighter};
`);

const StyledDivider = withTheme(styled(Divider)`
    width: ${({ theme })=> theme.spacing(3)}px;
    color: ${({ theme })=> theme.palette.lr_colors.border_grey_lighter};
    height: 2px;
    margin: 10px 0;
`);

const PhoneNumber = styled(Typography)``;

const Email = styled(Typography)`
    word-break: break-all;
`;

const Logo = withTheme(styled.img`
    margin-top: ${({ theme })=> theme.spacing(3)}px;
    max-width: min(320px, 100%);
`);

function Welcome() {
    const { UserStore } = useStore();
    const { user } = UserStore;
    const lo = user.affiliate.benoit_partner;
    const vendor = lo.vendor;

    return (
        <Box>
            <Title variant="h4">Welcome to your mortgage center!</Title>
            <Subtitle variant="body2">Find out more about the mortgage process.</Subtitle>
            <Box display="flex" justifyContent={['space-between', 'flex-start']}>
                <StyledAvatar src={lo.headshot}>{lo.name.substr(0,2)}</StyledAvatar>
                <Box order={[-1, 1]}>
                    <Lender>{lo.name}</Lender>
                    <Company>{lo.company}</Company>
                    <NMLS>NMLS#{lo.normalized_license_number}</NMLS>
                    <StyledDivider />
                    <PhoneNumber>{lo.phone_formatted}</PhoneNumber>
                    <Email>{lo.email}</Email>
                    <Logo src={vendor.logo} alt="company logo"/>
                </Box>
            </Box>
        </Box>
    );
}

export default Welcome;
