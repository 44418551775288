export const redfin = (path)=> {
    // example: IL/Chicago/2822-N-Sheffield-Ave-60657/unit-2N/home/26837977
    const matchedObject = path?.split('/');
    let address;

    if(matchedObject) {
        const [state, city, addressPart] = matchedObject;
        address = addressPart?.replace(/-/g, ' ');
        return `${address}, ${city}, ${state}`;
    }

    return address;
};
