import React from 'react';
import { Box, Grid, Typography, useTheme} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { LRToggleButtonGroup } from '../../../../components/LRToggleButtonGroup';
import { AreaSection } from './AreaSection';
import { PriceSection } from './PriceSection';

const LRToggleButtonGroupOptionStyle = {
    width: '100%',
    color: '#1a1a1a',
    textTransform: 'inherit',
    fontWeight: 'normal',
};

export function Preferences({setAreas, canEditUser, hasUserPreferences}) {
    const theme = useTheme();

    return (
        <>
            <Grid item xs={12}>
                <Box my={1}>
                    <Typography variant="h6"  style={{marginBottom: theme.spacing(1)}}>Default Preferences</Typography>
                    <Box fontSize={16} mb={3}>Use these to jump start your searches and improve your recommendations.</Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mb={1}>
                    <AreaSection setAreas={setAreas} canEditUser={canEditUser} hasUserPreferences={hasUserPreferences}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mb={2}>
                    <Box fontSize={16} mb={1}>💵 What’s your price range?</Box>
                    <PriceSection canEditUser={canEditUser}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mb={3}>
                    <Box fontSize={16} mb={1}>🛏 Beds</Box>
                    <Controller
                        name="preferences.bedrooms"
                        as={
                            <LRToggleButtonGroup
                                fullWidth
                                disabled={!canEditUser}
                                options={[
                                    { value: '0', label: 'Studio', style: LRToggleButtonGroupOptionStyle },
                                    { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
                                    { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
                                    { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
                                    { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
                                ]}
                            />
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mb={5}>
                    <Box fontSize={16} mb={1}>🛁 Baths</Box>
                    <Controller
                        name="preferences.bathrooms"
                        as={
                            <LRToggleButtonGroup
                                fullWidth
                                disabled={!canEditUser}
                                options={[
                                    { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
                                    { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
                                    { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
                                    { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
                                ]}
                            />
                        }
                    />
                </Box>
            </Grid>
        </>
    );
}
