import React, { useState } from 'react';
import { Box, Divider, Snackbar, Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import LRButton from '../../../../../components/LRButton';
import CollaboratorItem from './CollaboratorItem';
import PropTypes from 'prop-types';
import { COLOR_GREY } from '../../../../../constants';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../mobx-store';

const StyledDivider = styled(Divider)`
    background-color: ${COLOR_GREY};
`;

function BoardPermissions({ collaborators }) {
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const [snackbarStatus, setSnackbarStatus] = useState({ open: false, message: '', hasUndo: false });
    const [redirectToInvite, setRedirectToInvite] = useState(false);
    const { open, message, hasUndo } = snackbarStatus;

    function handleClose() {
        setSnackbarStatus({ ...snackbarStatus, open: false });
    }
    async function handleUndo() {
        await activeBoard.undoLastRemoveCollaborator();
        setSnackbarStatus({ ...snackbarStatus, open: false });
    }

    function onInviteCollaborator() {
        setRedirectToInvite(true);
    }

    if(redirectToInvite) {
        return <Redirect to="/boards/invite-cobuyer" />;
    }

    const snackbarActions = hasUndo
        ? [
            <Button key="undo" color="primary" size="small" onClick={handleUndo}>
                  UNDO
            </Button>
        ]
        : [];

    async function onCollaboratorRemove(collaborator) {
        await activeBoard.removeCollaborator(collaborator);
        setSnackbarStatus({ open: true, message: 'Collaborator removed', hasUndo: true });
    }
    return (
        <Box width="100%">
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={<span id="message-id">{message}</span>}
                autoHideDuration={2000}
                onClose={handleClose}
                action={snackbarActions}
            />
            <Box my={4}>
                <LRButton onClick={onInviteCollaborator} variant="contained" color="primary">
                    Invite collaborator
                </LRButton>
            </Box>
            {collaborators.map((collaborator, index)=> (
                <div key={collaborator.id}>
                    <CollaboratorItem
                        collaborator={collaborator}
                        role={collaborator.label}
                        hasPendingInvite={collaborator.type === 'invite'}
                        setSnackbarStatus={setSnackbarStatus}
                        onCollaboratorRemove={onCollaboratorRemove}
                    />
                    <Box display={index === collaborators.length - 1 ? 'none' : ''} py={2} mx={{ xs: '-56px', md: 0 }}>
                        <StyledDivider />
                    </Box>
                </div>
            ))}
        </Box>
    );
}

BoardPermissions.propTypes = {
    collaborators: PropTypes.array.isRequired
};

export default observer(BoardPermissions);
