import { benoitApi } from 'apis';
import { sortBy } from 'lodash';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import analytics from 'services/analytics';
import { CACHE_KEY } from './constants';
import { MyHomeContext } from './MyHomeProvider';
import moment from 'moment';
import Logger from 'js-logger';

export function useMyHomeAVM() {
    const { myHome, lead } = useContext(MyHomeContext);
    const avmQuery = useQuery(
        [`${CACHE_KEY}-avm`, lead ? lead.id : myHome?.address, myHome?.avm],
        ()=> (lead ? benoitApi.getMyHomeAVMDataByLeadId(lead.id) : benoitApi.getMyHomeAVMDataByAddress(encodeURIComponent(myHome.address))),
        {
            enabled: Boolean(myHome?.address),
            select: (data)=> {
                if(!data?.length) {
                    analytics.eventTrack('robin_home_report_address_avm_not_found', {
                        listing_address: myHome.address,
                    });
                }

                if(data?.length) {
                    if(myHome?.avm?.length) {
                        data = updateAVMDataWithAgentAdjustments(data, myHome.avm);
                    } else if(lead?.meta?.robin?.home_report?.avm?.length) {
                        data = updateAVMDataWithAgentAdjustments(data, lead.meta.robin.home_report.avm);
                    }
                }

                return data;
            },
        },
    );

    // Functions

    /**
     * Handles adding the adjusted data to the AVM data
     *
     * @param {Array} avmData
     * @param {Array} agentAdjustments
     * @returns Array
     */
    function updateAVMDataWithAgentAdjustments(avmData, agentAdjustments) {
        const adjustments = sortBy(agentAdjustments, 'createdAt');
        let lastFoundAdjustment = adjustments.shift();
        let newAVMData = avmData.map((avm)=> {
            avm = { ...avm };
            const avmDate = moment(avm.createdate);
            const isSameOrAfterNextAdjustment = Boolean(adjustments[0] && avmDate.isSameOrAfter(adjustments[0].createdAt));

            if(isSameOrAfterNextAdjustment) {
                lastFoundAdjustment = adjustments.shift();
            }

            const isAdjustable = avmDate.isSameOrAfter(lastFoundAdjustment.createdAt);

            if(isAdjustable) {
                avm.estimatedvalue = avm.estimatedvalue + parseFloat(lastFoundAdjustment.diff);
            }

            return avm;
        });
        newAVMData = [
            ...newAVMData,
            ...agentAdjustments.map((adjustment)=> ({
                ...adjustment,
                createdate: adjustment.createdAt,
                estimatedvalue: adjustment.value,
            })),
        ];

        return sortBy(newAVMData, 'createdate');
    }

    return {
        ...avmQuery,
        get estimatedValue() {
            return avmQuery.data?.slice()?.reverse()?.[0]?.estimatedvalue;
        },
        get lastUpdated() {
            return avmQuery.data?.slice()?.reverse()?.[0]?.valuationdate;
        },
        get daysSinceLastChangeInValue() {
            const reversedData = avmQuery.data?.slice()?.reverse();

            if(!reversedData) return null;

            const latest = reversedData[0];
            const lastMonth = reversedData[1] || latest; // Fallback to latest if there is no other month

            return moment(latest.valuationdate).diff(moment(lastMonth.valuationdate), 'days');
        },
        get lastNDayChangeInValue() {
            const reversedData = avmQuery.data?.slice()?.reverse();

            if(!reversedData) return null;

            const latest = reversedData[0];
            const lastMonth = reversedData[1] || latest; // Fallback to latest if there is no other month

            return latest.estimatedvalue - lastMonth.estimatedvalue;
        },
        get lastNDayChangeInValuePercent() {
            const reversedData = avmQuery.data?.slice()?.reverse();

            if(!reversedData) return null;

            const lastMonth = reversedData[1] || reversedData[0]; // Fallback to latest if there is no other month

            return Math.round(this.lastNDayChangeInValue / lastMonth.estimatedvalue * 1000) / 10;
        },
        includesAgentAdjustments: Boolean(myHome?.avm?.length || lead?.meta?.robin?.home_report?.avm?.length), // This will tell you if the agent has made adjustments to the price
    };
}
