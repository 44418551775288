import React from 'react';
import { useTheme } from '@material-ui/styles';
import { useStore } from 'mobx-store';
import { useOnboarding } from 'components/Onboarding';
import analytics from 'services/analytics';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { AddRounded, Close } from '@material-ui/icons';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';

const logger = Logger.get('OverlayMoveMenu');

export const OverlayMoveMenu = observer(function OverlayMoveMenu({
    menuOptions,
    handleMove,
    menuProps,
    handleCloseMenu,
}) {
    const theme = useTheme();
    const { UserStore } = useStore();
    const onboarding = useOnboarding();

    // Functions

    function onMenuItemClick(e, destinationLane) {
        e.preventDefault();
        handleMove(e, destinationLane, 'board_hover_menu');
        handleCloseMenu(e);

        const { stepId } = onboarding || {};
        const shouldAdvanceOnboarding = (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && stepId === 'highlight-listpack-listing-card';

        if(shouldAdvanceOnboarding) {
            UserStore.updateUser({ meta: { async_onboarding: { moved_board_cards: true } } });
            onboarding.tour.show('free-step', true);
            analytics.eventTrack('robin_onboarding_add_home_from_saved_search_done', {
                source: 'saved-search'
            });
        }
    }

    return (
        <Menu
            {...menuProps}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        >
            <MenuItem style={{ cursor: 'default', background: 'transparent' }} onClick={(e)=> e.preventDefault()}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            marginRight: theme.spacing(1),
                            width: '16px',
                            height: '20px',
                        }}
                    >
                        <AddRounded style={{ width: '16px', height: '16px' }} />
                    </div>
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                        Move to:
                    </Typography>
                    <Box ml={12} fontSize="16px" style={{ cursor: 'pointer' }}>
                        <Close
                            fontSize="inherit"
                            onClick={(e)=> {
                                e.preventDefault();
                                handleCloseMenu(e);
                            }}
                        />
                    </Box>
                </div>
            </MenuItem>

            {menuOptions.map((option)=> (
                <MenuItem
                    key={option.id}
                    onClick={(e)=> {
                        onMenuItemClick(e, option);
                    }}
                    className="tour-target-move-listing-card"
                    style={{ paddingLeft: theme.spacing(7) }}
                >
                    <Typography variant="body2">{option.name}</Typography>
                </MenuItem>
            ))}
        </Menu>
    );
});
