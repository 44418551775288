import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useActiveBoard } from '../../../../mobx-store';
import TaskLayout from './task-layout';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import { useResponsive } from '../../../../hooks';

const NoteTask = observer(function NoteTask(props) {
    const match = useRouteMatch();
    const activeBoard = useActiveBoard();
    const r = useResponsive();
    const { task } = props;
    const { actor, address, cta, message } = task.content;
    const checked = !!task?.completedAt;

    const strippedCta = cta.replace('/boards/', '');
    return (
        <TaskLayout {...props} viewTo={`${match.url}/${strippedCta}`}>
            <div>
                <Typography
                    variant="body2"
                    // variant={r({ xs: checked ? 'body2' : 'body1', md: checked ? 'body2' : 'h6' })}
                >
                    {/* {actor.first_name} added a note to{' '}
                    <span
                        style={{
                            textDecoration: checked ? 'none' : 'underline',
                        }}
                    >
                        {address}
                    </span>
                    . {task.content.message && `"${task.content.message}"`}
                    <br /><br /> */}
                    Review and respond to {actor?.first_name}'s note for <span
                        style={{
                            textDecoration: checked ? 'none' : 'underline',
                            fontWeight: 'bold'
                        }}
                    >
                        {address}.
                    </span>
                    {message && <Typography variant="body2">"{message}"</Typography>}
                </Typography>

            </div>
        </TaskLayout>
    );
});

export default NoteTask;
