import { CircularProgress, Fade, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import LRAvatar from 'components/LRAvatar';
import LRButton from 'components/LRButton';
import { useAgent } from 'hooks/useAgent';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useLoanOfficer } from 'hooks/useLoanOfficer';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Section, SectionContainer, SectionHeader, SectionTitle } from '../Section';
import { hyphenateSync } from 'hyphen/en';

export const InfoBox = observer(function InfoBox({
    variant, // agent or lo
    hasComplianceModal = false,
    hideIfNoProfile = false,
    onContactMeClick = ()=> {},
    onComplianceModal = ()=> {},
    ...props
}) {
    const theme = useTheme();
    const { data:agent } = useAgent({ priority: 'context' });
    const lo = useLoanOfficer(agent);
    const profile = useMemo(()=> {
        switch(variant) {
            case 'agent':
                return agent;
            case 'lo':
                return lo;
            default:
                return {};
        }
    }, [agent, lo, variant]);
    const title = useMemo(()=> {
        switch(variant) {
            case 'agent':
                return 'My agent';
            case 'lo':
                return 'My loan officer';
            default:
                return null;
        }
    }, [variant]);
    const { isLoggedIn } = useIsLoggedIn();

    return (
        <Fade in={!hideIfNoProfile || Boolean(profile)} unmountOnExit>
            <Section {...props}>
                <SectionHeader>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item style={{ marginRight: theme.spacing(2) }}>
                            <LRAvatar size="size36" user={profile} />
                        </Grid>
                        <Grid item xs>
                            <SectionTitle>
                                {title}
                            </SectionTitle>
                        </Grid>
                        <Grid item>
                            {isLoggedIn && (
                                <LRButton variant="outlined" color="default" onClick={onContactMeClick}>
                                    Email
                                </LRButton>
                            )}
                        </Grid>
                    </Grid>
                </SectionHeader>

                <SectionContainer style={{ paddingBottom: theme.spacing(4), }}>
                    <Fade in={Boolean(profile)} unmountOnExit>
                        <Grid container spacing={2} wrap="nowrap">
                            <Grid item xs zeroMinWidth>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                    {profile?.name && hyphenateSync(profile?.name)}
                                </Typography>

                                <Typography variant="body2" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                    {profile?.company && hyphenateSync(profile?.company)}
                                </Typography>
                                <Typography variant="body2" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                    {hasComplianceModal && <LRButton variant="text" color="secondary" inline onClick={onComplianceModal} >License #{profile?.realtor_license_number}</LRButton>}
                                    {!hasComplianceModal && `License #${profile?.realtor_license_number}`}

                                </Typography>

                                <Typography variant="body2">
                                    {profile?.phone_formatted}
                                </Typography>
                                <Typography variant="body2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                                    {profile?.email && hyphenateSync(profile?.email)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fade>

                    {!profile && (
                        <Grid container justify="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                </SectionContainer>
            </Section>
        </Fade>
    );
});
