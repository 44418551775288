import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useResponsive } from '../../../../hooks';
import TaskLayout from './task-layout';


const ScheduleViewingRequestTask = observer(function MakeAnOfferTask(props) {
    const match = useRouteMatch();
    const r = useResponsive();
    const { task } = props;
    const { actor, cta } = task.content;
    const strippedCta = cta.replace('/boards/', '');
    const checked = !!task?.completedAt;

    return (
        <TaskLayout {...props} viewTo={`${match.url}/${strippedCta}`}>
            <div>
                <Typography
                    // variant="body2"
                    variant={r({ xs: checked ? 'body2' : 'body1', md: checked ? 'body2' : 'h6' })}
                >
                    Review and schedule a home tour requested by {actor?.first_name}
                </Typography>
            </div>
        </TaskLayout>
    );
});

export default ScheduleViewingRequestTask;
