import { AppBar, Grid, Toolbar } from '@material-ui/core';
import { CreateRounded } from '@material-ui/icons';
import LRButton from 'components/LRButton';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import React from 'react';
import { Can } from 'components/Ability';
import { subject } from '@casl/ability';
import { useStore } from 'mobx-store';

export function AppBarFooter({
    tour,
    hideAddNoteButton = false,
    readyToSchedule = false,
    selectedTab,
    scheduleDateAndTime = (e)=> {},
    onEditClick= (e)=> {},
    onCancelTourClick= (e)=> {},
    onAddNoteClick= (e)=> {},
    ...props
}) {
    const {
        isMobile,
    } = useDeviceBreakpoints();
    const { UserStore } = useStore();
    const { isAgent } = UserStore;

    return (
        <AppBar
            component="footer"
            position="static"
            color="inherit"
            elevation={0}
            style={{
                borderTop: '1px solid #ddd',
                top: 'auto',
                bottom: 0,
            }}
        >
            <Toolbar>
                <Grid container spacing={1} justify="flex-end">
                    <Can I="delete" this={subject('HomeTour', tour)}>
                        <Grid item xs sm={false}>
                            <LRButton variant="outlined" fullWidth={isMobile} onClick={onCancelTourClick} style={{ padding: '5px 0'}}>
                                Cancel Tour
                            </LRButton>
                        </Grid>
                    </Can>

                    <Can I="edit" this={subject('HomeTour', tour)}>
                        <Grid item xs sm={false}>
                            <LRButton variant="outlined" startIcon={<CreateRounded />} fullWidth={isMobile} onClick={onEditClick}>
                                Edit
                            </LRButton>
                        </Grid>
                    </Can>

                    {isAgent && isMobile && !Boolean(tour?.content.scheduled_date_time) && selectedTab === 'Date & Time' && (
                        <Grid item xs={7} sm={false}>
                            <LRButton
                                variant="contained"
                                color="primary"
                                fullWidth={isMobile}
                                disabled={!readyToSchedule}
                                onClick={scheduleDateAndTime}
                            >
                                Schedule Tour
                            </LRButton>
                        </Grid>
                    )}

                    {!hideAddNoteButton && (
                        <Grid item xs sm={false}>
                            <LRButton variant="contained" color="primary" fullWidth onClick={onAddNoteClick} style={{ padding: '5px 0'}}>
                                Add a note
                            </LRButton>
                        </Grid>
                    )}
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
