import React from 'react';
import { Radio, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

const CustomFormControlLabel = styled(FormControlLabel)`
    align-items: start;
    & span {
        font-size: 14px;
    }

    & .MuiFormControlLabel-label {
        font-weight: bold;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

const CustomRadio = styled(Radio)`
    padding: 0;
    margin-right: 16px;
    margin-left: 9px;
    color: #1A1A1A !important;
`;

const CustomRadioSlim = styled(CustomRadio)`
    margin-right: 9px;
`;

export function LRRadio({ value, label, longLabel, disabled }) {
    return <CustomFormControlLabel
        control={longLabel ? <CustomRadioSlim /> : <CustomRadio />}
        value={value}
        label={label}
        disabled={disabled || false}
    />;
}
