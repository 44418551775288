import React from 'react';

import MakeAnOfferTask from './make-an-offer-task';
import NoteTask from './note-task';
import SimpleTask from './simple-task';
import ScheduleViewingRequestTask from './schedule_viewing_request';
import SkeletonTask from './skeleton-task';
import MovedCardTask from './moved-card-task';
import NurtureStatusHot from './nurture-status-hot';

const taskComponentMapping = {
    robin_offer_submitted: MakeAnOfferTask,
    robin_comment: NoteTask,
    custom: SimpleTask,
    robin_schedule_viewing_request: ScheduleViewingRequestTask,
    robin_moved_card_to_favorites: MovedCardTask,
    nurture_unlimited_status_hot: NurtureStatusHot,
    skeleton_task: SkeletonTask
};

const TaskItem = React.memo(function TaskItem(props) {
    
    const { task } = props;
    const TaskComponent = taskComponentMapping[task.type];

    return (TaskComponent) ? <TaskComponent {...props} /> : null;
});

export default TaskItem;
