import { useState, useCallback } from 'react';

export const useToggleState = function(initialState = false) {
    const [isOpen, setIsOpen] = useState(initialState);

    const toggle = useCallback(function() {
        setIsOpen((_isOpen)=> !_isOpen);
    }, []);

    const close = useCallback(function() {
        setIsOpen(false);
    }, []);

    const open = useCallback(function() {
        setIsOpen(true);
    }, []);

    return {
        toggle,
        isOpen,
        close,
        open
    };
};
