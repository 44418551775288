import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import { default as SwiperJs } from 'swiper';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@material-ui/icons';
import 'swiper/css/swiper.css';
import './custom-swiper.css';
import classNames from 'classnames';

function useSwiper({
    activeIndex,
    hasNavigation = false,
    slidesPerView = 1,
    centeredSlides = false,
    spaceBetween = 0,
    loop = false,
    autoplay = false,
    initialSlide = 0,
    pagination = true,
    autoHeight = false,
    allowTouchMove = true,
    navigationArrowStyle = {},
    dynamicBullets = true,
    clickable = false,
    onSlideChange = ()=> {},
    onPreviousButtonClick = ()=> {},
    onNextButtonClick = ()=> {},
    onInit = ()=> {},
} = {}) {
    const swiperRef = useRef();
    const paginationRef = useRef();
    const navigationLeftRef = useRef();
    const navigationRightRef = useRef();
    const swiperInstance = useRef(null);
    const onSlideChangeRef = useRef();
    const onPreviousButtonClickRef = useRef();
    const onNextButtonClickRef = useRef();
    const onInitRef = useRef();
    const [currentIndex, setCurrentIndex] = useState(initialSlide);
    const activeIndexRef = useRef(activeIndex);

    useEffect(()=> {
        activeIndexRef.current = activeIndex;
    }, [activeIndex]);

    useEffect(()=> {
        onSlideChangeRef.current = onSlideChange;
        onPreviousButtonClickRef.current = onPreviousButtonClick;
        onNextButtonClickRef.current = onNextButtonClick;
        onInitRef.current = onInit;
    });

    useEffect(()=> {
        const timeoutId = setTimeout(()=> {
            swiperInstance.current = new SwiperJs(swiperRef.current, {
                slidesPerView: slidesPerView,
                initialSlide: initialSlide,
                centeredSlides: centeredSlides,
                spaceBetween: spaceBetween,
                loop: loop,
                keyboard: {
                    enabled: true,
                },
                navigation: hasNavigation && {
                    nextEl: navigationRightRef.current,
                    prevEl: navigationLeftRef.current,
                },
                pagination: pagination && {
                    el: paginationRef.current,
                    dynamicBullets,
                    dynamicMainBullets: 8,
                    clickable,
                },
                // Can be changed to be configured in the future if needed
                autoplay: autoplay && {
                    delay: 2000,
                    disableOnInteraction: false,
                    ...(_.isPlainObject(autoplay) ? autoplay: {})
                },
                // observer: true,
                on: {
                    slideChange: function() {
                        if(swiperInstance?.current?.realIndex !== activeIndexRef.current) {
                            onSlideChangeRef.current(swiperInstance?.current?.realIndex || 0);
                            setCurrentIndex(swiperInstance?.current?.realIndex || 0);
                        }
                    },
                    slideNextTransitionStart: function() {
                        onNextButtonClickRef.current(swiperInstance.current);
                    },
                    slidePrevTransitionStart: function() {
                        onPreviousButtonClickRef.current(swiperInstance.current);
                    },
                    init: function() {
                        onInitRef.current();
                    }

                },
                autoHeight,
                allowTouchMove,
            });
        }, 0);
        return ()=> {
            clearTimeout(timeoutId);
            if(swiperInstance.current) {
                swiperInstance.current.destroy();
                swiperInstance.current = null;
            }
        };
    }, [hasNavigation, slidesPerView, pagination, autoHeight, allowTouchMove]);


    return {
        swiperRef,
        paginationRef,
        navigationLeftRef,
        navigationRightRef,
        swiperInstance: swiperInstance.current,
        currentIndex: currentIndex,
    };
}

const StyledGalleryButton = styled(IconButton)`
    border-radius: 0;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
    transform: translate3d(0, 0, 0);
    &:focus {
        outline:0;
    }
`;

export function Swiper({
    data,
    renderSlide,
    slidesPerView,
    slideFull = true, //set this to false if you want more than one slide perview
    centeredSlides,
    spaceBetween,
    loop,
    autoHeight,
    autoplay,
    initialSlide,
    allowTouchMove,
    onSlideChange,
    hasNavigation,
    onInit,
    onSlideClick,
    previousButtonClick,
    nextButtonClick,
    activeIndex,
    pagination,
    navigationLeft,
    navigationRight,
    navigationArrowStyle,
    dynamicBullets,
    clickable,
    variant,
}) {

    const {
        swiperInstance,
        swiperRef,
        paginationRef,
        navigationLeftRef,
        navigationRightRef,
        currentIndex
    } = useSwiper({
        activeIndex,
        hasNavigation: hasNavigation,
        slidesPerView: slidesPerView,
        centeredSlides: centeredSlides,
        spaceBetween: spaceBetween,
        loop: loop,
        autoplay,
        pagination,
        initialSlide,
        autoHeight,
        allowTouchMove,
        dynamicBullets,
        clickable,
        onSlideChange: onSlideChange,
        onPreviousButtonClick: previousButtonClick,
        onNextButtonClick: nextButtonClick,
        onInit: onInit
    });

    useEffect(()=> {
        if(swiperInstance && swiperInstance.initialized) {
            if(swiperInstance.realIndex !== activeIndex) {
                swiperInstance.activeIndex = activeIndex;
                swiperInstance.slideReset();
            }
        }
    }, [swiperInstance, activeIndex]);

    useEffect(()=> {
        if(swiperInstance) {
            loop && swiperInstance.loopDestroy();
            loop && swiperInstance.loopCreate();
            swiperInstance.update();
        }
    }, [data, loop, swiperInstance]);

    return (
        <Box height={1} width={1} display="flex" position="relative" className={variant}>

            {hasNavigation && (
                <Box
                    ref={navigationLeftRef}
                    width={56}
                    className={`swiper-button-prev ${data.length === 1 ? 'swiper-button-hidden' : ''}`}
                    style={
                        loop && data.length > 1  ?
                            {opacity: 1, pointerEvents: 'auto'} :
                            { visibility: currentIndex > 0 ? 'visible' : 'hidden' }
                    }
                >
                    {navigationLeft ? navigationLeft : (
                        <StyledGalleryButton>
                            <KeyboardArrowLeft style={{ fontSize: '3.5rem', ...navigationArrowStyle }} />
                        </StyledGalleryButton>
                    )}
                </Box>
            )}
            <Box ref={swiperRef} className="swiper-container" width={1}>
                <div className="swiper-wrapper">
                    {data.map((item, index)=> {
                        return (
                            <div key={index} className={classNames('swiper-slide', slideFull && 'swiper-slide-full')} onClick={onSlideClick}>
                                {renderSlide(item)}
                            </div>
                        );
                    })}
                </div>
                {
                    pagination && (
                        <div ref={paginationRef} className="swiper-pagination"></div>
                    )
                }
            </Box>
            {hasNavigation && (
                <Box
                    ref={navigationRightRef}
                    width={56}
                    className={`swiper-button-next ${data.length === 1 ? 'swiper-button-hidden' : ''}`}
                    style={
                        loop && data.length > 1 ?
                            {opacity: 1, pointerEvents: 'auto'} :
                            { visibility: currentIndex < data.length - 1 ? 'visible' : 'hidden' }
                    }
                >
                    {navigationRight ? navigationRight : (
                        <StyledGalleryButton>
                            <KeyboardArrowRight style={{ fontSize: '3.5rem', ...navigationArrowStyle }} />
                        </StyledGalleryButton>
                    )}
                </Box>
            )}
        </Box>
    );
}
