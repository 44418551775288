import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import PlusIcon from '../../../../components/LRIcons/plus';
import LRAvatar from '../../../../components/LRAvatar';
import { Can } from '../../../../components/Ability';
import { subject } from '@casl/ability';

const StyledPlusIcon = styled(PlusIcon)`
    width: 35px;
    height: 35px;
    border-radius: 100%;

    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.1);
    }
`;

const BoardCollaborators = function({ onAddClick, onRemoveCollaborator, board, collaborators, ...props }) {

    return (
        <Box display="flex" style={{ boxSizing: 'initial' }} flexWrap="wrap">
            {collaborators.map((collaborator)=> (
                <Box
                    position="relative"
                    ml="-10px"
                    border="2px solid #FFF"
                    borderRadius="100%"
                    key={collaborator.id}
                >
                    <LRAvatar user={collaborator} />
                </Box>
            ))}
            <Can I="write" this={subject('Board', board)}>
                <Box
                    width={35}
                    height={35}
                    bgcolor="#FFF"
                    position="relative"
                    border="2px solid white"
                    borderRadius="100%"
                    ml="-10px"
                    className="tour-target-add-cobuyer"
                    onClick={onAddClick}
                >
                    <StyledPlusIcon />
                </Box>
            </Can>
        </Box>
    );
};

BoardCollaborators.propTypes = {
    board: PropTypes.object.isRequired,
    collaborators: PropTypes.array.isRequired
};

export default observer(BoardCollaborators);
