import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import LRModal from '../../components/LRModal';
import { Box, Typography, Grid } from '@material-ui/core';
import LRButton from '../../components/LRButton';
import LRInput from '../../components/LRInput';
import PropTypes from 'prop-types';
import StyledLabel from '../../components/StyledLabel';
import { useValidationForm } from '../../hooks';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';

function InviteACoBuyerModal({ match }) {
    const { boardsStore, GlobalSnackbarStore } = useStore();
    const activeBoard = boardsStore.activeBoard;
    const [closing, setClosing] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formStatus, formData] = useValidationForm(
        {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
        },
        {
            firstName: ['required'],
            lastName: ['required'],
            phone: ['required', 'phone'],
            email: ['required', 'email'],
        }
    );
    const { firstName, lastName, phone, email } = formData;

    if(closing) {
        return <Redirect to="/boards" />;
    }

    function closeModal() {
        setClosing(true);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if(formStatus.isValid) {
            await activeBoard.createInvite({
                first_name: firstName.inputProps.value,
                last_name: lastName.inputProps.value,
                phone: phone.inputProps.value,
                email: email.inputProps.value,
                //v1 will be launched with only two roles, so all the invites will be as a co-buyer
                meta: {
                    label: 'co-buyer',
                },
            });
            GlobalSnackbarStore.show('Invite sent!');
            closeModal();
        }
    }

    return (
        <LRModal onClose={closeModal}>
            <Box px={4} pt={4}>
                <Box mb={2} textAlign="center" fontSize="h5.fontSize">
                    Invite a co-buyer
                </Box>
                <Box mx="auto" width={{ xs: '100%', md: '70%' }}>
                    <Box mb={2}>
                        <Typography align="center">Who do you want to collaborate with on your home search?</Typography>
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Box pr={{ xs: 0, md: 1 }}>
                                        <StyledLabel
                                            error={(submitted && !firstName.isValid).toString()}
                                            fontSize="h5.fontSize"
                                            align="left"
                                        >
                                            First name*
                                        </StyledLabel>
                                        <Box my={1}>
                                            <LRInput
                                                error={submitted && !firstName.isValid}
                                                {...firstName.inputProps}
                                                type="text"
                                                variant="outlined"
                                                fullWidth={true}
                                            />
                                        </Box>
                                        {submitted && !firstName.isValid && (
                                            <Box fontSize={14} color="error.main" my="4px">
                                                Please enter first name
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box pl={{ xs: 0, md: 1 }}>
                                        <StyledLabel error={(submitted && !lastName.isValid).toString()} align="left">
                                            Last name*
                                        </StyledLabel>
                                        <Box my={1}>
                                            <LRInput
                                                error={submitted && !lastName.isValid}
                                                {...lastName.inputProps}
                                                type="text"
                                                variant="outlined"
                                                fullWidth={true}
                                            />
                                        </Box>
                                    </Box>
                                    {submitted && !lastName.isValid && (
                                        <Box fontSize={14} color="error.main" my="4px">
                                            Please enter last name
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <StyledLabel error={(submitted && !email.isValid).toString()} align="left">
                                    Email address*
                                </StyledLabel>
                                <Box my={1}>
                                    <LRInput
                                        error={submitted && !email.isValid}
                                        {...email.inputProps}
                                        type="email"
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                    {submitted && !email.isValid && (
                                        <Box fontSize={14} color="error.main" my="4px">
                                            Please enter a valid email address
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        </LRModal>
    );
}

InviteACoBuyerModal.propTypes = {
    match: PropTypes.object.isRequired,
};

export default observer(InviteACoBuyerModal);
