import React from 'react';
import LRAvatar from '../../../components/LRAvatar';
import { Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

function SkeletonProperty() {
    const theme = useTheme();

    return (
        <div style={{display: 'flex', width: '100%', padding: theme.spacing(2)}}>
            <div style={{marginRight: theme.spacing(2)}}>
                <LRAvatar loading="true" />
            </div>
            <div>
                <Typography style={{marginBottom: theme.spacing(1)}}>
                    <Skeleton animation="wave" variant="text" width={390} height="100%" />
                    <Skeleton animation="wave" variant="text" width={290} height="100%" />
                </Typography>
                <Typography component="div" variant="caption">
                    <Skeleton animation="wave" variant="text" width={75} height="100%" />
                </Typography>
            </div>

        </div>
    );
}

export default function SkeletonProperties() {

    return (
        <div>
            <SkeletonProperty />
            <SkeletonProperty />
            <SkeletonProperty />
        </div>
    );
}
