import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTheme, Dialog, Typography, withStyles } from '@material-ui/core';
import analytics from '../../services/analytics';
import LRLink from 'components/LRLink';
import LRButton from 'components/LRButton';
import { useStore } from 'mobx-store';
import { useHistory } from 'react-router-dom';

const StyledDialog = withStyles({
    root: {
        position: 'fixed',
        zIndex: '10000 !important',
        right: '0px',
        bottom: '0px',
        top: '0px',
        left: '0px'
    }
})(Dialog);

export function TermsAndConditionsModal({ open = false, variant = 'new_user', ...props }) {
    const theme = useTheme();
    const history = useHistory();
    const { UserStore } = useStore();
    const { user } = UserStore;
    
    async function handleOnAcceptClick() {
        analytics.eventTrack('robin_terms_modal_clicked', {
            version: variant,
            source: 'accept'
        });
        const payload = {
            account_type: 'verified_robin',
            terms_and_conditions_accepted: process.env.REACT_APP_TERMS_AND_CONDITIONS_VERSION,
        };
        await UserStore.updateUser(payload);
    }

    async function handleOnDeclineClick() {
        analytics.eventTrack('robin_terms_modal_clicked', {
            version: variant,
            source: 'decline'
        });
        UserStore.logout();
    }

    useEffect(()=> {
        analytics.eventTrack('robin_terms_modal_displayed', {
            version: variant
        });
    }, [variant]);

    return (
        <StyledDialog
            open={open}   
        >
            <div
                style={{
                    padding: theme.spacing(3),
                    maxWidth: '280px',
                }}
            >
                <div>
                    <Typography variant="h6">
                        { variant === 'new_user' ? 'Terms' : 'Updated terms'}
                    </Typography>
                </div>
                <div
                    style={{
                        marginTop: theme.spacing(2),
                    }}
                > 
                    <Typography variant="body2">
                        { variant === 'new_user' ? 'By accepting, you\'re agreeing to ListReports ' : 'We\'ve updated our '} 
                        <LRLink
                            native target="_blank" href="https://welcome.listreports.com/terms-conditions"
                            style={{ textDecoration: 'underline',  color: theme.palette.lr_colors.grade}}
                        >terms of service</LRLink>
                        {' '}and{' '}
                        <LRLink
                            native target="_blank" href="https://welcome.listreports.com/privacy-policy"
                            style={{ textDecoration: 'underline',  color: theme.palette.lr_colors.grade}}
                        >privacy policy</LRLink>.
                    </Typography>
                    
                    {
                        variant === 'new_user' && (
                            <>
                                <br />
                                <Typography variant="body2">
                                    Robin is a collaborative home search tool. By clicking “Accept” your account information and home preferences will be shared with { user?.affiliate?.name } and their paired Loan Officer { user?.affiliate?.benoit_partner?.name }
                                </Typography>
                            </>
                        )
                    }
                    <br />
                    <Typography variant="body2">
                        Please take a few minutes to read the { variant === 'new_user' ? 'terms' : 'updated terms' }. You must accept to continue.
                    </Typography>
                </div>
                <div
                    style={{
                        marginTop: theme.spacing(3),
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <LRLink
                            mr={2}
                            height={36}
                            display= "inline-flex"
                            alignItems="center"
                            onClick={handleOnDeclineClick}

                            style={{ textDecoration: 'underline',  color: theme.palette.lr_colors.grade}}
                        >
                            Decline & log out
                        </LRLink>
                        
                    </div>
                    <div>
                        <LRButton
                            type="button"
                            variant="contained"
                            color="primary"
                            width={56}
                            height={36}        
                            onClick={handleOnAcceptClick}
                        >
                            Accept
                        </LRButton>
                    </div>
                </div>
            </div>
        </StyledDialog>
    );
}