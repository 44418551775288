import { useState, useEffect } from 'react';
import { useStore } from '../../mobx-store';
import { useRobinRealtimeCommunication } from '../../hooks/useRobinRealtimeCommunication';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import { BoardCard } from '../../mobx-store/domain/board-card';
import moment from 'moment';
import { updateBoardCardCache } from '../../hooks/data/board-card/useBoardCard';
const logger = Logger.get('RealtimeStoreManager');

/**
 * RealtimeStoreManager
 *
 * Handles all realtime messaes for the board. This
 * lives high in thr app chain so it should catch all
 * realtime events.
 */
export const RealtimeStoreManager = observer(function RealtimeStoreManager({ children = null }) {
    const {
        boardsStore,
        listingsStore
    } = useStore();
    const { activeBoard } = boardsStore || {};
    const {
        lastMessage,
    } = useRobinRealtimeCommunication(activeBoard?.id ? `robin-board-${activeBoard.id}` : null);
    const [_lastReceivedMessage, _setLastReceivedMessage] = useState();

    // useEffect

    /**
     * Handels incoming realtime messages
     */
    useEffect(function () {
        if(lastMessage === _lastReceivedMessage || !lastMessage?.data) return;
        _setLastReceivedMessage(lastMessage);

        let data;

        try {
            data = JSON.parse(lastMessage.data).p;
        } catch (err) {
            logger.error('useEffect -> JSON.parse(lastMessage.data) -> Error', err);
        }

        if(!data.content) return;

        // The main part of the message sent from the backend
        const content = data.content;

        // Add cases here to call different functions
        // based on the content.mtype

        switch(content.mtype) {
            /**
             * Board Card Changes
             */
            case 'board-card-created':
                handleNewBoardCardMessage(boardsStore, listingsStore, content);
                break;
            case 'board-card-updated':
            case 'board-card-deleted':
                handleBoardCardMessage(boardsStore, content);
                break;

            /**
             * ListPack Changes
             */
            case 'listpack-created':
            case 'listpack-updated':
                handleListPackMessage(boardsStore, content);
                break;

            /**
             * BoardCard Last Moved By
             */
            case 'board-collaboration-created':
                handleLastMovedByUserId(boardsStore, content);
                break;

            default:
                break;
        }
    }, [lastMessage]);

    return children;
});

// HANDLERS

/**
 * handleBoardCardMessage
 *
 * handler for BoardCard realtime messages
 *
 * @param {Class} boardsStore
 * @param {Object} message
 */
function handleBoardCardMessage(boardsStore, message) {
    const {
        boardCard,
    } = message;

    const currentBoardCard = boardsStore.activeBoard.boardCardsById[boardCard.id];

    // Only if we do not have a value for updatedAt or its newer then
    // the one we currently have stored. This helps prevent dom updates
    // from data we already have from realtime.
    if(
        currentBoardCard?.updatedAt &&
        !moment(boardCard.updatedAt)
            .isAfter(currentBoardCard.updatedAt)
    ) return;
    updateBoardCardInStore(boardsStore, boardCard.id, boardCard);
    updateBoardCardCache(boardCard);
}

/**
 * handleListPackMessage
 *
 * handler for ListPack realtime messages
 *
 * @param {Class} boardsStore
 * @param {Object} message
 */
function handleListPackMessage(boardsStore, message = {}) {
    const { listpacks } = boardsStore.activeBoard || [];
    const { listpack } = message;
    let currentListpack = listpacks.find((l)=> l.id === listpack.id);
    if(currentListpack) {
        const isUpdatedListpack = currentListpack?.updatedAt < listpack?.updatedAt;
        if(!isUpdatedListpack) return;
    }

    boardsStore.activeBoard.addListpack(listpack);
}

/**
 * handleLastMovedByUserId
 *
 * Updates a board cards lastMovedById property
 *
 * @param {Class} boardsStore
 * @param {Object} message
 */
function handleLastMovedByUserId(boardsStore, message = {}) {
    const {
        mtype,
        boardCollaboration,
    } = message;

    if(!(mtype === 'board-collaboration-created' && boardCollaboration.model === 'BoardCard' && boardCollaboration.type === 'moved_card')) return;

    updateBoardCardInStore(boardsStore, boardCollaboration.itemId, { lastMovedById: boardCollaboration.userId });
}

// HELPER FUNCTIONS

/**
 * updateBoardCardInStore
 *
 * Call this to update a boards board card
 *
 * @param {BoardsStore} boardsStore
 * @param {String} id
 * @param {Object} data
 */
function updateBoardCardInStore(boardsStore, id, data) {
    const bc = new BoardCard({
        ...(boardsStore.activeBoard.boardCardsById[id] || {}),
        ...data
    }, boardsStore.activeBoard);

    boardsStore.activeBoard.updateBoardCardsById({
        [id]: bc,
    });
}

/**
 * updateBoardCardInStore
 *
 * Call this to update a boards board card
 *
 * @param {BoardsStore} boardsStore
 * @param {ListingsStore} listingsStore
 * @param {Object} data
 */
async function handleNewBoardCardMessage(boardsStore, listingsStore,  data) { 
    const { boardCard } = data;
    const bc = new BoardCard({
        ...boardCard
    }, boardsStore.activeBoard);

    await listingsStore.fetchListings([bc.content.encoded_id]);

    boardsStore.activeBoard.updateBoardCardsById({
        [boardCard.id]: bc,
    });
}