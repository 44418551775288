import React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { css, keyframes } from 'styled-components';
import { LockOpenRounded, LockRounded } from '@material-ui/icons';
import Lockr from 'lockr';

const PulseBox = styled(Box)`
${({ showAnimation })=>
        showAnimation === true
            ? css`
                animation: ${pulseKeyFrames} 2s infinite;
            `
            : ''};
`;

const pulseKeyFrames = keyframes`
0% {
    -webkit-box-shadow: 0 0 0 0 #1EC8E1D9;
    box-shadow: 0 0 0 0 #1EC8E1D9;
}

70% {
    -webkit-box-shadow: 0 0 0 1em #1EC8E100;
    box-shadow: 0 0 0 1em #1EC8E100;
}

100% {
    -webkit-box-shadow: 0 0 0 0 #1EC8E100;
    box-shadow: 0 0 0 0 #1EC8E100;
`;

function UnlockIconButton() {
    return <Box
        bgcolor="#FFF"
        height={24}
        borderRadius="50%"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
        style={{ cursor: 'pointer', opacity: 0.90 }}
    >
        <Box pl='4px' pt='3px' color="rgba(0, 0, 0, 0.66)">
            <LockOpenRounded color="inherit" style={{ height: '16px', width: '16px' }}/>
        </Box>
    </Box>;
}

function LockIconButton() {
    return <Box
        bgcolor="#000"
        height={24}
        borderRadius="50%"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
        style={{ cursor: 'pointer' }}
    >
        <Box pl='4px' pt='3px' color="#FFF">
            <LockRounded style={{ height: '16px', width: '16px' }}/>
        </Box>
    </Box>;
}

const OverlayImageActions = observer(function OverlayImageActions(
    { listing, showLock,onLockClick, setShowLockTooltip, store, showLockTooltip }) {
    return <>
        {listing.icon && (
            <Box
                position="absolute"
                top={10}
                left={16}
                borderRadius="4px"
                py="2px"
                px={1}
                bgcolor="white"
                color="lr_colors.grade"
                fontWeight={500}
            >
                {listing.icon}
            </Box>
        )}
        {!listing.hideLock && showLock && <PulseBox
            mt={1}
            mr={1}
            width={24}
            height={24}
            borderRadius="50%"
            position="absolute"
            right={0}
            onClick={(e)=> {e.stopPropagation(); onLockClick(listing); setShowLockTooltip(false);}}
            showAnimation={!listing.locked && !Lockr.get('compare-now-lock-clicked')}
            onMouseOver={()=> { setShowLockTooltip(true); }}
            onMouseOut={()=> { setShowLockTooltip(false); }}
        >
            {!store.isCardLocked(listing) && <UnlockIconButton />}
            {store.isCardLocked(listing) && <LockIconButton />}
            {showLockTooltip && <Box
                mt={1}
                width={store.isCardLocked(listing) ? 58 : 43}
                borderRadius={2}
                position="relative"
                zIndex={1}
                ml={store.isCardLocked(listing) ? -2 : -1}
                textAlign="center"
                bgcolor="rgba(0, 0, 0, 0.5)" >
                <Box fontSize={14} color="#FFF">{store.isCardLocked(listing) ? 'Unlock' : 'Lock'}</Box>
            </Box>}
        </PulseBox>}
    </>;

});

export default OverlayImageActions;
