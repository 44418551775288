import React from 'react';
import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useRFHInput } from '../../../../hooks';
import { LRRangeDropdown } from '../../../../components/LRRangeDropdown';
import { PRICE_RANGE } from '../../../../constants';

export function PriceSection({canEditUser}) {
    const formMethods = useFormContext();

    const { value: priceMin, handleChange: setPriceMin } = useRFHInput('preferences.price_min', formMethods);
    const { value: priceMax, handleChange: setPriceMax } = useRFHInput('preferences.price_max', formMethods);

    return (
        <Box maxWidth={[1, 350]}>
            <LRRangeDropdown
                minValue={priceMin}
                maxValue={priceMax}
                options={PRICE_RANGE}
                disabled={!canEditUser}
                onMinValueChange={(option)=> {
                    setPriceMin(option);
                }}
                onMaxValueChange={(option)=> {
                    setPriceMax(option);
                }}
                compareAsNumbers={true}
            ></LRRangeDropdown>
        </Box>
    );
}
