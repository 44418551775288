import { MenuItem, Popover, ThemeProvider } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import LRButton from 'components/LRButton';
import Logger from 'js-logger';
import { partial } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { MuiCalendarBlackTheme } from 'theme';

export function LRTimeSelect({
    value,
    offsetAmount = 15, // In Minutes
    startTimeHour = 0, // 24 hrs
    startTimeMinute = 0,
    endTimeHour = 24, // 24 hrs
    endTimeMinute = 0,
    theme = MuiCalendarBlackTheme,
    fullWidth = false,
    minWidth = 184,
    disabled = false,
    onChange = (date)=> {},
    ...props
}) {
    const FORMAT = 'h:mma';
    const [buttonRef, setButtonRef] = useState();
    const [openPopover, setOpenPopover] = useState(false);
    const timeOptions = useMemo(()=> {
        const start = moment()
            .hour(startTimeHour)
            .minute(startTimeMinute)
        ;
        const end = moment()
            .hour(endTimeHour)
            .minute(endTimeMinute)
        ;
        const times = [start.format(FORMAT)];

        while(start.isBefore(end)) {
            start.add(offsetAmount, 'minutes');
            times.push(start.format(FORMAT));
        }

        return times;
    }, [offsetAmount, startTimeHour, startTimeMinute, endTimeHour, endTimeMinute]);

    // Functions
    
    function onTimeSelect(e, time) {
        onChange(time);
        setOpenPopover(false);
    }

    return (
        <div {...props}>
            <ThemeProvider theme={theme}>
                <LRButton
                    disabled={disabled}
                    fullWidth={fullWidth}
                    variant={value ? 'contained' : 'outlined'} 
                    color={value ? 'primary' : 'default'}
                    ref={setButtonRef} 
                    endIcon={<ExpandMoreRounded />}
                    onClick={()=> (setOpenPopover(true))}
                    style={{
                        justifyContent: 'space-between',
                        backgroundColor: !value && '#fff',
                        paddingTop: 7,
                        paddingBottom: 7,
                    }}
                >
                    <div 
                        style={{
                            minWidth: Math.max(minWidth - 56, 0),
                            textAlign: 'left',
                        }}
                    >
                        {value || 'Select time'}
                    </div>
                </LRButton>
            </ThemeProvider>

            <Popover
                open={openPopover}
                anchorEl={buttonRef}
                onClose={()=> {
                    setOpenPopover(false);
                }}
                style={{
                    maxHeight: 211,
                }}
            >
                <ul>
                    {timeOptions.map((time)=> (
                        <MenuItem key={time} onClick={partial(onTimeSelect, partial.placeholder, time)} style={{ minWidth: 184 }}>
                            {time}
                        </MenuItem>
                    ))}
                </ul>
            </Popover>
        </div>
    );
}
