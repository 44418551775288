import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Hidden, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { useResponsive } from '../../../hooks/use-responsive';
import { useStore } from '../../../mobx-store';
import LRAvatar from '../../../components/LRAvatar';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LRButton from '../../../components/LRButton';
import ResponsiveHOC from '../../../components/ResponsiveHoc';
import formatPhoneNumber from '../../../services/format-phone-number';
import { timeframeOptionsByValue } from '../../../services/user-timeframe';
import CobuyerInfoModal from './CobuyerInfoModal';
import LRLink from 'components/LRLink';

function ContactInfo({ email, phone, loading }) {
    const r = useResponsive();
    return (
        <div
            className={r({ xs: 'space-y-2', md: 'space-x-4' })}
            style={{
                display: r({ xs: 'block', md: 'flex' }),
                alignItems: 'center',
            }}
        >
            <div
                className="space-x-3"
                style={{
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        paddingTop: '4px',
                    }}
                >
                    <PhoneIcon />
                </div>
                {
                    loading ?
                        <Typography variant="h6">
                            <Skeleton animation="wave" variant="text" width={108} height="100%" />
                        </Typography> :
                        <a href={`tel:${phone}`}>
                            <Typography variant="h6">{formatPhoneNumber(phone, { withParentheses: true })}</Typography>
                        </a>
                }
            </div>
            <div
                className="space-x-3"
                style={{
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        paddingTop: '4px',
                    }}
                >
                    <EmailIcon />
                </div>
                {
                    loading ?
                        <Typography variant="h6">
                            <Skeleton animation="wave" variant="text" width={300} height="100%" />
                        </Typography> :
                        <a
                            href={`mailto:${email}`}
                            style={{
                                wordBreak: 'break-word',
                            }}
                        >
                            <Typography variant="h6">{email}</Typography>
                        </a>
                }
            </div>
        </div>
    );
}

const MobileBuyerDetails = observer(function MobileBuyerDetails({
    user,
    loanOfficer,
    board,
    collaborators,
    onCobuyerClick,
    loading
}) {
    const theme = useTheme();
    const [showDetails, setShowDetails] = useState(false);

    function toggleDetails() {
        setShowDetails((value)=> !value);
    }

    return (
        <div>
            {showDetails && (
                <ul className="space-y-1">
                    <li style={{ display: 'flex' }}>
                        <Typography variant="body1" style={{ width: '97px', flexShrink: 0, marginRight: theme.spacing(2) }}>
                            Timeline
                        </Typography>
                        <Typography variant="body1">
                            <b>{timeframeOptionsByValue[user.meta.how_soon]?.label || 'N/A'}</b>
                        </Typography>
                    </li>
                    <li style={{ display: 'flex' }}>
                        <Typography variant="body1" style={{ width: '97px', flexShrink: 0, marginRight: theme.spacing(2) }}>
                            Last active
                        </Typography>
                        <Typography variant="body1">
                            <b>{moment(board.lastSeenAt)
                                .fromNow()}</b>
                        </Typography>
                    </li>
                    <li style={{ display: 'flex' }}>
                        <Typography variant="body1" style={{ width: '97px', flexShrink: 0, marginRight: theme.spacing(2) }}>
                            Loan Officer
                        </Typography>
                        <Typography variant="body1">
                            <b>{loanOfficer?.name || 'N/A'}</b>
                        </Typography>
                    </li>
                    <li style={{ display: 'flex' }}>
                        <Typography variant="body1" style={{ width: '97px', flexShrink: 0, marginRight: theme.spacing(2) }}>
                            Co-buyers
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b>
                                {collaborators?.length
                                    ? collaborators
                                        .map((c)=> (
                                            <LRLink
                                                onClick={()=> onCobuyerClick(c)}
                                                style={{
                                                    color: '#1A1A1A',
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                {c.name}
                                            </LRLink>
                                        ))
                                        .reduce(
                                            (accum, elem)=> (accum === null ? [elem] : [...accum, ', ', elem]),
                                            null
                                        )
                                    : 'N/A'}
                            </b>
                        </Typography>
                    </li>
                </ul>
            )}
            {
                loading ?
                    <Typography>
                        <Skeleton animation="wave" variant="text" width={128} height="100%" />
                    </Typography> :
                    <LRButton
                        variant="text"
                        style={{
                            textDecoration: 'underline',
                            marginLeft: '-8px',
                        }}
                        onClick={toggleDetails}
                    >
                        {showDetails && (
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                Hide lead details <ExpandLessIcon style={{ fontSize: '1rem' }} />
                            </span>
                        )}
                        {!showDetails && (
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                Show lead details <ExpandMoreIcon style={{ fontSize: '1rem' }} />
                            </span>
                        )}
                    </LRButton>
            }
        </div>
    );
});

const DesktopBuyerDetails = observer(function DesktopBuyerDetails({
    user,
    loanOfficer,
    board,
    collaborators,
    onCobuyerClick,
    loading,
}) {
    const theme = useTheme();

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    marginRight: theme.spacing(5),
                    flexShrink: 0,
                }}
            >
                <div style={{ marginRight: theme.spacing(2), width: '83px' }}>
                    <Typography variant="body1">Timeline</Typography>
                    <Typography variant="body1">Last active</Typography>
                </div>
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <Typography variant="body1">
                        {
                            loading ?
                                <Skeleton animation="wave" variant="text" width={80} height="100%" /> :
                                <b>{timeframeOptionsByValue[user.meta.how_soon]?.label || 'N/A'}</b>
                        }
                        
                    </Typography>
                    <Typography variant="body1">
                        {
                            loading ?
                                <Skeleton animation="wave" variant="text" width={80} height="100%" /> :
                                <b>{moment(board.lastSeenAt)
                                    .fromNow()}</b>
                        }
                    </Typography>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{ marginRight: theme.spacing(2), width: '97px' }}>
                    <Typography variant="body1">Loan Officer</Typography>
                    <Typography variant="body1">Co-buyers</Typography>
                </div>
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <Typography variant="body1">
                        {
                            loading ?
                                <Skeleton animation="wave" variant="text" width={120} height="100%" /> :
                                <b>{loanOfficer?.name || 'N/A'}</b>
                        }
                    </Typography>
                    <Typography variant="body1">
                        {
                            loading ?
                                <>
                                    <Skeleton style={{display: 'inline-block', marginRight: '8px'}} animation="wave" variant="text" width={120} height="100%" />
                                    <Skeleton style={{display: 'inline-block', marginRight: '8px'}} animation="wave" variant="text" width={120} height="100%" />
                                    <Skeleton style={{display: 'inline-block', marginRight: '8px'}} animation="wave" variant="text" width={120} height="100%" />
                                </> :
                                <b>
                                    {collaborators?.length
                                        ? collaborators
                                            .map((c)=> (
                                                <LRLink
                                                    onClick={()=> onCobuyerClick(c)}
                                                    style={{
                                                        color: '#1A1A1A',
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    {c.name}
                                                </LRLink>
                                            ))
                                            .reduce((accum, elem)=> (accum === null ? [elem] : [...accum, ', ', elem]), null)
                                        : 'N/A'}
                                </b>
                        }
                    </Typography>
                </div>
            </div>
        </div>
    );
});

const ResponsiveBuyerDetails = ResponsiveHOC({
    xs: MobileBuyerDetails,
    md: DesktopBuyerDetails,
});

const BuyerDetails = observer(function BuyerDetails({ user, board, loading }) {
    const [selectedCobuyer, setSelectedCobuyer] = useState(null);
    const loanOfficer = user?.affiliate?.preferred_vendor;

    const collaborators = loading ? [] : board.collaborators.filter(({ userId })=> user.id !== userId);

    function onCobuyerClick(cobuyer) {
        setSelectedCobuyer(cobuyer);
    }

    return (
        <>
            <ResponsiveBuyerDetails
                user={user}
                loanOfficer={loanOfficer}
                board={board}
                collaborators={collaborators}
                onCobuyerClick={onCobuyerClick}
                loading={loading}
            />
            <CobuyerInfoModal
                open={!!selectedCobuyer}
                onClose={()=> setSelectedCobuyer(null)}
                cobuyer={selectedCobuyer}
            />
        </>
    );
});

export default observer(function BuyerInfo() {
    const r = useResponsive();
    const theme = useTheme();
    const { UserStore, boardsStore } = useStore();
    const activeBoard = boardsStore.activeBoard;
    const user = UserStore.user;

    return (
        <div
            style={{
                paddingLeft: r({ xs: theme.spacing(2), md: 0 }),
                paddingRight: r({ xs: theme.spacing(2), md: 0 }),
            }}
        >
            <div
                style={{
                    marginBottom: r({ xs: theme.spacing(2.5), md: theme.spacing(2) }),
                }}
            >
                <div
                    className="space-x-4"
                    style={{
                        display: 'flex',
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <div style={{ paddingTop: r({ xs: 0, md: '10px' }) }}>
                        <LRAvatar user={user} loading={UserStore.loadingUser} />
                    </div>
                    <div>
                        <Typography variant={r({ xs: 'h5', md: 'h4' })}>
                            {UserStore.loadingUser ?
                                <Skeleton animation="wave" variant="text" width={300} height="100%" /> :
                                user.name
                            }
                        </Typography>
                        <Hidden smDown>
                            <ContactInfo email={user.email} phone={user.phone} loading={UserStore.loadingUser} />
                        </Hidden>
                    </div>
                </div>
                <Hidden mdUp>
                    <ContactInfo email={user.email} phone={user.phone} loading={UserStore.loadingUser} />
                </Hidden>
            </div>
            <BuyerDetails user={user} board={activeBoard} loading={boardsStore.loading || !boardsStore.loaded} />
        </div>
    );
});
