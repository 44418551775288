import { Typography, useTheme } from '@material-ui/core';
import { Swiper } from 'components/Swiper';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useMyHome } from 'hooks/useMyHome';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { AverageHomeValue } from './Cards/AverageHomeValue';
import { SugestedAreas } from './Cards/SugestedAreas';
import { DeleteNeighborhoodModal } from './DeleteNeighborhoodModal';
import { Navigation } from './Navigation';
import moment from 'moment';

export const NeighborhoodSwiper = observer(function({
    swiperProps,
    openManageModal,
    openSignUpModal,
}) {
    const theme = useTheme();
    const { lead, myHome, areaIds, updateMyHome, createMyHome, isDataLocal } = useMyHome();
    const lastUpdatedAt = _.get(lead || myHome, 'updatedAt', null);
    const { isMobile } = useDeviceBreakpoints();
    const [activeIndex, setActiveIndex] = useState(0);
    const [areas, setAreas] = useState([]);
    const [modalData, setModalData] = useState({isOpen: false, dontAskToDelete: false});

    const saveAreas = (areas)=> {
        const saveAreas = myHome ? updateMyHome : createMyHome;
        return saveAreas({areaIds: areas});
    };

    const deleteArea = async (area)=> {
        const newLocalAreas = _.filter(areas, (localArea)=> localArea !== area);
        !lead && saveAreas(newLocalAreas);
        setAreas(newLocalAreas);
        setModalData({...modalData, isOpen: false});
    };
    const openDeleteAreaModal = (area)=> setModalData({...modalData, isOpen: true, area});

    const averageComponent = (area, key)=> ()=>
        <AverageHomeValue
            key={key}
            area={area}
            onDelete={()=> {
                if(isDataLocal) {
                    openSignUpModal();
                } else {
                    modalData.dontAskToDelete
                        ? deleteArea(area)
                        : openDeleteAreaModal(area);
                }
            }}
        />;

    const listAreaComponents = _.map(
        [...(myHome?.zipcode ? [myHome?.zipcode] : []), ...areas],
        (area, key)=> averageComponent(area, key)
    );
    const swipperComponents = [
        ...listAreaComponents,
        ...(_.size(listAreaComponents) < 10
            ? [()=> <SugestedAreas
                openManageModal={openManageModal}
                openSignUpModal={openSignUpModal}
            />]
            : [])
    ];
        
    useEffect(()=> {
        setAreas(areaIds || []);
    },[areaIds]);
    
    return (
        <div style={{position: 'relative'}}>
            <DeleteNeighborhoodModal 
                onClose={()=> setModalData({...modalData, isOpen: false})}
                onDontAskToDelete={(value)=> setModalData({...modalData, dontAskToDelete: value})}
                deleteArea={deleteArea}
                {...modalData}
            />
            <div style={{...(isMobile && { marginLeft: theme.spacing(1), marginRight: theme.spacing(1)})}}>
                <Swiper
                    key={'multiple-areas'}
                    data={swipperComponents}
                    activeIndex={activeIndex}
                    slideFull={false}
                    previousButtonClick={(e)=> setActiveIndex(e.activeIndex)}
                    nextButtonClick={(e)=> setActiveIndex(e.activeIndex)}
                    renderSlide={(show)=> show()}
                    navigationArrowStyle={{
                        color: theme.palette.lr_colors?.grey
                    }}
                    {...swiperProps}
                />
            </div>
            {isMobile && <div style={{display: 'flex', justifyContent: 'center'}}>
                <Navigation
                    activeIndex={activeIndex}
                    size={_.size(swipperComponents)}
                />
            </div>}
            {!!lastUpdatedAt && !_.isEmpty(areas) && <div 
                style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'flex-start',
                    ...(!isMobile && {marginTop: theme.spacing(2) - 2})
                }}>
                <Typography variant='body2' style={{color: theme.palette?.lr_colors?.steak_sauce}}>
                    Last updated: {moment(lastUpdatedAt).format('MM/DD/YY')}
                </Typography>
            </div>}
        </div>
    );
});
