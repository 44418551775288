import _ from 'lodash';
import { LANES_NAMES } from '../constants';

const LANES = {
    'maybes': LANES_NAMES['interested'],
    'favorites': LANES_NAMES['see'],
    'make-an-offer': LANES_NAMES['buy'],
};
const CONTENT_TYPES_TO_CHANGE = ['fromColumn', 'toColumn'];

export function useGetColumnName(column) {
    return (LANES[_.kebabCase(column)]) ? LANES[_.kebabCase(column)] : column;
}

export function useNotificationColumnName(notification) {
    for(const column of CONTENT_TYPES_TO_CHANGE) {
        if(notification?.content[column]){
            const newColumn = (LANES[_.kebabCase(notification.content[column])]) ? LANES[_.kebabCase(notification.content[column])] : column;
            notification.content[column] = newColumn;
        }
    }

    return notification;
}
