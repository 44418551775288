import Logger from 'js-logger';

const logger = Logger.get('AnalyticsProviderConsole');

class AnalyticsProviderConsole {

    reset(){
        logger.debug('reset');
    }

    setUsername(username) {
        logger.debug('setUserName -> Username:', username);
    }

    setAlias(alias) {
        logger.debug('setAlias -> Alias:', alias);
    }

    setSuperPropertiesOnce(properties) {
        logger.debug('setSuperPropertiesOnce -> Properties:', properties);
    }

    setSuperProperties(properties) {
        logger.debug('setSuperProperties -> Properties:', properties);
    }

    setUserPropertiesOnce(properties) {
        logger.debug('setUserPropertiesOnce -> Properties:', properties);
    }

    setUserProperties(properties) {
        logger.debug('setUserProperties -> Properties:', properties);
    }

    pageTrack(path) {
        logger.debug('pageTrack -> Path:', path);
    }

    eventTrack(action, properties) {
        logger.debug('eventTrack -> Action:', action);
        logger.debug('eventTrack -> Properties:', properties);
    }

    incrementProperty(property, value) {
        if(typeof value === 'undefined') {
            logger.debug('incrementProperty -> Property:', property);
        } else {
            logger.debug('incrementProperty -> Property:', property);
            logger.debug('incrementProperty -> Value:', value);
        }
    }

    userTimings(action, properties) {
        logger.debug('userTimings -> Action:', action);
        logger.debug('userTimings -> Properties:', properties);
    }
}

export default new AnalyticsProviderConsole();
