import { AppBar, Box, Grid, Hidden, IconButton, Slide } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTheme, withTheme } from '@material-ui/styles';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useRef } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import { Link, Route, withRouter } from 'react-router-dom';
import { ListingCardPlaceholder } from 'scenes/ListingsMap/ListingCardPlaceholder';
import styled from 'styled-components';
import LRAvatar from '../../../../components/LRAvatar';
import LRModal from '../../../../components/LRModal';
import { COLOR_OFF_WHITE } from '../../../../constants';
import { useStore } from '../../../../mobx-store';
import { BoardCardModal } from '../../../BoardCardModal';
import Card from '../../Card';

const StyledAppBar = withTheme(styled(AppBar)`
    background-color: #fff;
    color: ${({ theme })=> theme.palette.text.primary};
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    position: relative;
`);

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const HeaderContent = styled(Grid)`
    padding-left: 16px;
    height: 56px;
`;

const Content = withTheme(styled(Box)`
    overflow: auto;
    height: calc(100vh - 64px);
    max-height: calc(100vh - 64px);
    ${({ theme })=> theme.breakpoints.down('xs')} {
        max-height: 100%;
        height: 100%;
        overflow: hidden;
    }
`);

const InnerContent = withTheme(styled(Box)`
    ${({ theme })=> theme.breakpoints.down('xs')} {
        position: absolute;
        overflow: auto;
        max-height: calc(100% - 64px );
        margin: -16px;
        padding: 16px;
        width: 100%;
    }
`);

const StyledLRModal = styled(LRModal)`
    & .lane-modal-dialog-content {
        background-color: ${COLOR_OFF_WHITE};
        overflow: hidden;
    }
`;

const CloseButton = withTheme(styled(IconButton)`
    position: absolute;
    top: 8px;
    right: -55px;

    ${({ theme })=> theme.breakpoints.up('lg')} {
        color: #fff;
    }

    ${({ theme })=> theme.breakpoints.down('md')} {
        top: 4px;
        right: 4px;
        z-index: 3;
        color: #000;
    }
`);

const RenderOptionLabel = ({laneId, label, listings})=> {
    return <>
        <Box ml={1}>{label}</Box>
        <Box ml={1} color={laneId === '1' && listings > 0 ? '#f3b204' : 'lr_colors.grey_dark'}>
            {listings}
            {
                laneId === '1' && listings > 0 && (
                    <Box component="span" ml={0.5} role="img" aria-label="party">
                    🎉
                    </Box>
                )
            }
        </Box>
    </>;

};

// const RenderOption = ({ laneId, label, listings, selected = false, ...props })=> {
//     return (
//         <Box display="flex" key={laneId}>
//             <Box><CheckIcon fontSize="small" style={{
//                 visibility: selected ? 'visible': 'hidden'
//             }}/></Box>
//             <RenderOptionLabel laneId={laneId} label={label} listings={listings} />
//         </Box>
//     );
// };

// const DisplayRenderOption = ({ laneId, label, listings, ...props })=> {
//     return (
//         <Box display="flex" key={laneId}>
//             <RenderOptionLabel laneId={laneId} label={label} listings={listings} />
//         </Box>
//     );
// };

// const CardWrapper = withTheme(styled.div`
//     max-width: calc(50% - 8px);
//     flex: 1 0 100%;
//     margin-top: 16px;

//     ${({ theme })=> theme.breakpoints.down('sm')} {
//         max-width: 312px;
//         margin-left: auto;
//         margin-right: auto;
//     }
// `);

function ArchivedCard({ card }) {
    const { data: listing } = useBoardCardListing(card);

    return (
        <>
            {listing ? (
                <div>
                    <StyledLink
                        to={`/boards/archivedListings/${card.id}`}
                    >
                        <div>
                            <Card
                                boardCard={card}
                                listing_details={listing}
                                variant="expanded"
                                disableArchiveButton={true}
                            />
                        </div>
                    </StyledLink>
                </div>
            ) : (
                <Box width={[1,350]} m={1}>
                    <ReactPlaceholder
                        ready={false}
                        customPlaceholder={<ListingCardPlaceholder  />}
                    />
                </Box>
            )}
        </>
    );
}

function ArchivedHomesModal({ history, match, staticContext = undefined, ...props }) {
    const { boardsStore, LanesStore, UserStore } = useStore();
    const { activeBoard } = boardsStore;
    const archivedListings = activeBoard?.archivedListings; //archived board cards
    const mainContainerRef = useRef();
    const { user } = UserStore;
    const boardPermissions = activeBoard?.userRoleOnBoard;
    const theme = useTheme();
    const emptyArchivedHomes = useMemo(()=> [], []);

    let laneItems = [
        {
            laneId: '0',
            label: 'Your Searches',
            route: '/boards/lane/0/your-searches',
            selected: match?.params?.laneId === 0
        },
        ...LanesStore.lanes.map((lane)=> ({
            laneId: lane.id,
            label: lane.name,
            listings: LanesStore.getUnarchivedLanesListings(lane.id).length,
            route: `/boards/lane/${lane.id}/${lane.name}`,
            selected: lane.id === match?.params?.laneId
        }))
    ];

    const menuItems = [
        {
            id: 'home',
            label: 'Home',
            route: '/boards'
        },
        ...laneItems,
        {
            laneId: '-1',
            label: 'Deleted Homes',
            listings: archivedListings?.length || 0,
            route: '/boards/archivedListings',
            selected: true
        }
    ];

    // const selectedOption = _.find(menuItems, { laneId: '-1' });


    function handleOnClose() {
        history.push('/boards');
    }

    // function handleLaneChange(option) {
    //     const eventData = {
    //         role: boardPermissions?.label,
    //         selection: option.label,
    //         board_id: activeBoard?.id
    //     };

    //     analytics.eventTrack('robin_expanded_column_dropdown_select', eventData);

    //     mainContainerRef.current.scrollTo({top: 0});
    //     history.replace(option.route);
    // }

    return (
        <StyledLRModal
            fullScreen={true}
            fullWidth={true}
            maxWidth="sm"
            onClose={handleOnClose}
            dialogContentClasses={{ root: 'lane-modal-dialog-content' }}
            {...props}
        >
            <>
                <StyledAppBar>
                    <HeaderContent>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            flex="1"
                            height={1}
                        >
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                    <LRAvatar user={user} src={user.headshot} />
                                </Box>
                                <Box>
                                    <Box
                                        fontSize={theme.typography.body1.fontSize}
                                        color={theme.palette.lr_colors.grey_nero}
                                        lineHeight="20px"
                                    >
                                        Deleted Homes
                                    </Box>
                                </Box>
                            </Box>
                            <Hidden smUp>
                                <Box>
                                    <CloseButton onClick={handleOnClose} title="Close" className="tour-target-modal-close-button">
                                        <Close />
                                    </CloseButton>
                                </Box>
                            </Hidden>
                        </Box>
                    </HeaderContent>
                </StyledAppBar>

                <Content ref={mainContainerRef} p={2}>
                    <InnerContent>
                        <Grid container spacing={2}>
                            {(archivedListings || emptyArchivedHomes).map((listing, index)=>
                                <Grid item xs={12} sm={6} key={listing?.id || index}>
                                    <ArchivedCard card={listing} />
                                </Grid>
                            )}
                        </Grid>
                    </InnerContent>
                </Content>

                <Route
                    path={`${match.url}/:cardId`}
                    render={ ()=> (
                        <BoardCardModal
                            open={true}
                            onClose={()=> {
                                history.push('/boards/archivedListings');
                            }}
                        />
                    )}
                />
            </>
        </StyledLRModal>
    );
}

export default withRouter(observer(ArchivedHomesModal));
