import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { AppBar, Toolbar, IconButton, Box, Grid, useMediaQuery, Hidden, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { GalleryIcon, ListIcon } from '../../LRIcons';
import LRAvatar from '../../LRAvatar';
import LRSelect from '../../LRSelect';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../mobx-store';
import lodashSortBy from 'lodash/sortBy';
import { MuiPrimaryBlackTheme } from 'theme';
import LRButton from 'components/LRButton';
import { Close } from '@material-ui/icons';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

const StyledToggleButton = styled(ToggleButton)`
    text-transform: none;
    color: #fff;
    background-color: #000;
    padding-left: 14px !important;
    padding-right: 14px !important;
    border-left: 1px solid #eee !important;
    border-color: #eee;
    min-width: 80px;

    &:hover {
        background-color: #333;
    }

    @media screen and (max-width: 700px) {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    &.Mui-selected {
        color: #000;
        background-color: #fff;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

const StyledAvatarContainer = styled.div`
    display: flex;
    margin-right: 8px;
`;
const StyledGridItem = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const StyledHeaderControls = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 250px;
`;
const StyledLRSelect = styled(LRSelect)`
    margin-bottom: 8px;

    select {
        line-height: 1;
        font-size: 16px;
        border-color: #ddd;
        width: 100%;
        background-color: #000;
        color: #fff;
        margin: 0 8px;

        &:focus {
            border-color: #ddd;
            border: 1px solid #bbb;
            background-color: #000;
        }
    }
    & [class*="MuiSelect-icon"] {
        color: #fff;
        right: 14px;
    }
`;
const CloseButton = styled(IconButton)`
    color: #fff;
`;

function HeaderDetails({ isMobile }) {
    return (
        <Box
            fontSize={16}
            component="h3"
            variant="h6"
            color="#fff"
            py={2}
            px={2}
            ml={[10, 0]}
            maxWidth={150}
            textAlign={`${isMobile ? 'center' : 'left'}`}
            fontWeight={600}
        >
            Idea Board
        </Box>
    );
}

function Header({
    defaultToggleValue,
    initialUserFilterValue,
    onClose,
    onViewToggleChange,
    onUserFilterChange,
    style = {},
    elevation = 0,
    ...props
}) {
    const [selectedView, setSelectedView] = useState(defaultToggleValue);
    const [selectedUserFilter, setSelectedUserFilter] = useState(initialUserFilterValue || '');
    const theme = useTheme();
    const { boardsStore, UserStore, PinspirationStore } = useStore();
    const { filterUserId } = PinspirationStore;
    const { user } = UserStore;
    const { collaborators = [] } = boardsStore.activeBoard || {};
    const otherCollaborators = collaborators.filter((u)=> {
        return (
            u.userId !== user.id &&
            u.type !== 'invite'
        );
    });
    const userFilterList = [
        { id: '', first_name: 'View All'},
        user,
        ...lodashSortBy(otherCollaborators, 'first_name'),
    ];
    const {
        isMobile,
    } = useDeviceBreakpoints();

    useEffect(()=> {
        setSelectedUserFilter(filterUserId);
    },[filterUserId]);


    /**
     * @function onUserFilterToggle
     * @param {Event} e
     * @param {String} value
     */
    function onUserFilterToggle(e, value='') {
        if(!value) {
            value = (e && e.target) ? e.target.value || '' : '';
        }
        setSelectedUserFilter(value);
        onUserFilterChange(e, value);
    }

    /**
     * @function onToggleChange
     * @param {Event} e
     * @param {String} value
     */
    function onToggleChange(e, value) {
        // We dont ever want the value to be undefined so
        // default to the default value if no value
        value = value || defaultToggleValue;

        setSelectedView(value);
        onViewToggleChange(e, value);
    }

    return (
        <AppBar 
            position="static"
            color="inherit" 
            elevation={elevation}  
            style={{
                borderBottom: '1px solid #ddd',
                ...style,
            }}
            {...props}
        >
            <Hidden smUp>
                <Toolbar
                    style={{
                        borderBottom: '1px solid #ddd',
                    }}
                >
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={2} sm={1}></Grid>

                        <Grid item>
                            <Typography variant={isMobile ? 'body2' : 'h6'}>
                                Idea Board
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} style={{ textAlign: 'right' }}>
                            <IconButton edge="end" onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Hidden>

            <Toolbar>
                <Grid container justify="space-between">
                    <Grid item xs>
                        {/* Filter Toggle Buttons */}
                        <Hidden xsDown>
                            <ThemeProvider theme={MuiPrimaryBlackTheme}>
                                <ToggleButtonGroup value={selectedUserFilter} onChange={onUserFilterToggle} exclusive>
                                    {userFilterList.map((user, i)=> (
                                        <LRButton key={user.userId || user.id} component={ToggleButton} value={user.userId || user.id} style={{ height: 36 }} className="ToggleButton">
                                            {user.id &&
                                                <StyledAvatarContainer>
                                                    <LRAvatar user={user} size="xxsmall"></LRAvatar>
                                                </StyledAvatarContainer>
                                            }
                                            <div>{user.first_name}</div>
                                        </LRButton>
                                    ))}
                                </ToggleButtonGroup>
                            </ThemeProvider>
                        </Hidden>

                        <Hidden smUp>
                            <LRSelect
                                fullWidth
                                native
                                variant="filled"
                                value={selectedUserFilter}
                                onChange={(e)=> {
                                    const value = e.currentTarget?.value;
                                    onUserFilterToggle(e, value);
                                }}
                            >
                                {userFilterList.map((user, i)=> (
                                    <option key={user.userId || user.id} value={user.userId || user.id} >{user.first_name}</option>
                                ))}
                            </LRSelect>
                        </Hidden>
                    </Grid>

                    <Hidden xsDown>
                        <Grid item>
                            <ThemeProvider theme={MuiPrimaryBlackTheme}>
                                <ToggleButtonGroup value={selectedView} onChange={onToggleChange} exclusive>
                                    <LRButton component={ToggleButton} value="gallery" style={{ height: 36 }} className="ToggleButton">
                                        <Box display="flex">
                                            <GalleryIcon style={{ width: 12, height: 12 }} />
                                        </Box>
                                        <Box ml={0.5}>Gallery</Box>
                                    </LRButton>
                                    <LRButton component={ToggleButton} value="list" style={{ height: 36 }} className="ToggleButton">
                                        <Box display="flex">
                                            <ListIcon style={{ width: 12, height: 12 }} />
                                        </Box>
                                        <Box ml={0.5}>List</Box>
                                    </LRButton>
                                </ToggleButtonGroup>
                            </ThemeProvider>
                        </Grid>
                    </Hidden>
                </Grid>
            </Toolbar>
        </AppBar>

    // <AppBar style={{ backgroundColor: '#000' }} position="static" {...otherProps}>
    //     <Toolbar style={{ padding: '0px' }}>
    //         <Grid container>
    //             <Grid container justify="space-between">
    //                 {/* Mobile spacer GridItem for balancing close button */}
    //                 {!gtXs && <StyledGridItem item sm={2} />}

    //                 <Grid item sm={1}>
    //                     {/* Header Details */}
    //                     <HeaderDetails isMobile={!gtXs} />
    //                 </Grid>

    //                 <StyledGridItem item sm={7}>
    //                     {/* Filter Toggle Buttons */}
    //                     {gtXs && (
    //                         <ToggleButtonGroup
    //                             size="small"
    //                             value={selectedUserFilter}
    //                             exclusive
    //                             onChange={onUserFilterToggle}
    //                         >
    //                             {userFilterList.map((user, i)=> (
    //                                 <StyledToggleButton key={`user-filter-toggle-${i}`}
    //                                     value={user.userId || user.id}
    //                                 >
    //                                     {user.id &&
    //                                         <StyledAvatarContainer>
    //                                             <LRAvatar user={user} size="xsmall"></LRAvatar>
    //                                         </StyledAvatarContainer>
    //                                     }
    //                                     <div>{user.first_name}</div>
    //                                 </StyledToggleButton>
    //                             ))}
    //                         </ToggleButtonGroup>
    //                     )}
    //                 </StyledGridItem>
    //                 <StyledHeaderControls item
    //                     sm={2}
    //                 >
    //                     {/* View Toggle Buttons */}
    //                     {gtXs && (
    //                         <Box marginLeft="auto">
    //                             <ToggleButtonGroup
    //                                 size="small"
    //                                 value={selectedView}
    //                                 exclusive
    //                                 onChange={onToggleChange}
    //                             >
    //                                 <StyledToggleButton value="gallery">
    //                                     <Box display="flex">
    //                                         <GalleryIcon />
    //                                     </Box>
    //                                     <Box ml={0.5}>Gallery</Box>
    //                                 </StyledToggleButton>

    //                                 <StyledToggleButton value="list">
    //                                     <Box display="flex">
    //                                         <ListIcon />
    //                                     </Box>
    //                                     <Box ml={0.5}>List</Box>
    //                                 </StyledToggleButton>

    //                             </ToggleButtonGroup>
    //                         </Box>
    //                     )}
    //                     {/* Close Button */}
    //                     <Box ml={['auto', 0]}
    //                         mr={1}
    //                         maxWidth={50}
    //                     >
    //                         <CloseButton variant="contained" onClick={onClose}>
    //                             <CloseIcon />
    //                         </CloseButton>
    //                     </Box>
    //                 </StyledHeaderControls>
    //             </Grid>
    //             {/* Mobile Details */}
    //             {!gtXs && (
    //                 <Grid item xs={12}>
    //                     <StyledLRSelect
    //                         fullWidth={true}
    //                         native={true}
    //                         value={selectedUserFilter}
    //                         onChange={onUserFilterToggle}
    //                     >
    //                         {userFilterList.map((user, i)=> (
    //                             <option key={`mobile-user-filter-toggle-${i}`}
    //                                 value={user.userId || user.id}
    //                             >
    //                                 {user.first_name || user.name}
    //                             </option>
    //                         ))}
    //                     </StyledLRSelect>
    //                 </Grid>
    //             )}
    //         </Grid>
    //     </Toolbar>
    // </AppBar>
    );
}
Header.propTypes = {
    defaultToggleValue: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onViewToggleChange: PropTypes.func.isRequired,
    onUserFilterChange: PropTypes.func,
};

export default React.memo(observer(Header));
