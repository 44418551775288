import { Grid, Slide, Typography, useTheme, AppBar, Hidden } from '@material-ui/core';
import { ChevronLeftRounded } from '@material-ui/icons';
import { withTheme } from '@material-ui/styles';
import Logger from 'js-logger';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import LRButton from '../../LRButton';
import LRModal from '../../LRModal';
import { FormItemText } from '../FormFields';
import shortid from 'shortid';
import formatPhoneNumber from '../../../services/format-phone-number';
import LRLink from 'components/LRLink';
import { useResponsiveBreakpoint } from 'hooks/use-responsive-breakpoint';

const ForwardedCloseButton = withTheme(React.forwardRef(({ theme, ...props }, ref)=> (
    <LRButton variant="text" {...props} ref={ref} startIcon={<ChevronLeftRounded />}>
        Schedule a tour
    </LRButton>
)));
const CloseButton = styled(ForwardedCloseButton)`
    position: fixed;
    top: 13px;
    left: 13px;
`;

const TitleBar = styled.div`
    width: 56px;
    height: 4px;
    background: #1A1A1A;
    margin: 20px 0 40px;
`;

export function AddParticipantModal({
    open = false,
    onSubmit:_onSubmit = (data, e)=> {},
    onClose = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const [closeBtnRef, setCloseBtnRef] = useState();
    const { formState, register, handleSubmit, errors, reset, control } = useForm({
        mode: 'onChange',
    });
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;

    // UseEffects
    useEffect(()=> {
        if(!open) reset();
    }, [open]);

    // Functions

    function onCloseModal(e) {
        onClose(e);
    }

    function onSubmit(data, e) {
        _onSubmit({
            ...data,
            id: shortid.generate(),
            phone: data.phone.replace(/[^0-9]/gi, ''),
            phone_formatted: formatPhoneNumber(data.phone, 'domestic'),
            name: `${data.first_name || ''} ${data.last_name || ''}`.trim(),
        }, e);
    }
    
    return (
        <LRModal 
            className="AddParticipantModal-Root"
            fullScreen
            hideCloseButton
            maxWidth="100%"
            closeButtonRef={closeBtnRef}
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'left',
            }}
            open={open}
            {...props}
        >
            <div
                className="AddParticipantModal-Content"
                style={{
                    padding: isMobile ? 0 : `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
                    position: isMobile ? 'absolute' : 'relative',
                    top: 0,
                    bottom: 0,
                    maxWidth: '640px',
                    width: '100%',
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Hidden smDown>
                    <CloseButton ref={setCloseBtnRef} onClick={onCloseModal}/>
                    <div>
                        <Typography variant="h3">
                            Add a participant
                        </Typography>

                        <TitleBar />
                    </div>  
                </Hidden>
                            
                <Hidden smUp>
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '56px',
                            borderBottom: '1px solid #DDD'
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                left: '8px'
                            }}
                        >
                            <LRButton variant="text" onClick={onCloseModal} startIcon={<ChevronLeftRounded />}>  
                            </LRButton>
                        </div>
                        <div>
                            <Typography variant="body2">
                                Add a participant
                            </Typography>
                        </div>
                    </div>
                </Hidden>
               

                <div
                    style={{
                        flex: 1,
                        padding: isMobile ? '24px 16px' : 0,
                        overflow: isMobile ? 'auto' : 'hidden'
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm>
                            <FormItemText 
                                name="first_name" 
                                ref={register({ required: true })} 
                                autoFocus 
                                required 
                                hideRequiredLabel
                                inputProps={{
                                    ...(!errors.first_name ? {} : {
                                        error: true,
                                        helperText: 'Please provide a first name.'
                                    }),
                                }}
                            >
                                First Name
                            </FormItemText>
                        </Grid>
                        <Grid item xs={12} sm>
                            <FormItemText 
                                name="last_name"  
                                ref={register({ required: true })} 
                                required 
                                hideRequiredLabel
                                inputProps={{
                                    ...(!errors.last_name ? {} : {
                                        error: true,
                                        helperText: 'Please provide a last name.'
                                    }),
                                }}
                            >
                                Last Name
                            </FormItemText>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: isMobile ? 0 : theme.spacing(3)}}>
                        <Grid item xs={12} sm>
                            <Controller
                                as={
                                    <FormItemText
                                        type="tel"
                                        hideRequiredLabel
                                        required={false}
                                        inputProps={{
                                            ...(!errors.phone ? {} : {
                                                error: true,
                                                helperText: 'Please provide a phone number.'
                                            }),
                                        }}
                                    >
                                        Phone
                                    </FormItemText>
                                }
                                name="phone"
                                control={control}
                                defaultValue=""
                                rules={{required: false, pattern: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/}}
                            />
                        </Grid>
                        <Grid item xs={12} sm>
                            <FormItemText
                                name="email"
                                ref={register({
                                    required: true,
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                })}
                                required
                                hideRequiredLabel
                                inputProps={{
                                    ...(!errors.email ? {} : {
                                        error: true,
                                        helperText: 'Please provide a valid email address.'
                                    }),
                                }}
                            >
                                Email Address
                            </FormItemText>
                        </Grid>
                    </Grid>
                </div>

                <Hidden smUp>
                    <div
                        style={{
                            borderTop: '1px solid #DDD',
                            padding: '12px 16px'
                        }}
                    >
                        <Grid container spacing={1} justify="flex-end">
                            <Grid item xs sm={false}>
                                <LRButton
                                    variant="outlined"
                                    fullWidth={isMobile}
                                    onClick={onCloseModal}
                                    style={{
                                        height: '40px',
                                        padding: '5px 0'
                                    }}
                                >
                                    Cancel
                                </LRButton>
                            </Grid>

                            <Grid item xs sm={false}>
                                <LRButton
                                    variant="contained" 
                                    color="primary" 
                                    disabled={!formState.isValid}
                                    fullWidth={isMobile}
                                    onClick={handleSubmit(onSubmit)}
                                    style={{
                                        height: '40px'
                                    }}
                                >
                                Add participant
                                </LRButton>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
                 

                <Hidden smDown>
                    <Grid container spacing={3} justify="flex-end" alignItems="center" style={{ marginTop: theme.spacing(4.5) }}>
                        <Grid item>
                            <LRLink
                                onClick={onCloseModal}
                                style={{
                                    color: '#1A1A1A',
                                    textDecoration: 'underline',
                                }}
                            >
                            Cancel
                            </LRLink>
                        </Grid>

                        <Grid item>
                            <LRButton 
                                variant="contained" 
                                color="primary" 
                                disabled={!formState.isValid}
                                style={{ 
                                    height: '40px' 
                                }}
                                onClick={handleSubmit(onSubmit)}
                            >Add participant</LRButton>
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        </LRModal>
    );
}
AddParticipantModal.propTypes = {
    ...LRModal.propTypes || {},
};
