import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function Unchecked({pathStyle, svgStyle}) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...svgStyle}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM6 19H18C18.55 19 19 18.55 19 18V6C19 5.45 18.55 5 18 5H6C5.45 5 5 5.45 5 6V18C5 18.55 5.45 19 6 19Z" fill="#1A1A1A" {...pathStyle}/>
        </SvgIcon>
    );
}
