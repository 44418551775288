import React, { useRef } from 'react';
import { FormControlLabel, Checkbox, makeStyles, Box } from '@material-ui/core';
import styled from 'styled-components';

//Need to make disabled style
const useStyles = makeStyles({
    root: {
        display: 'none',
        height: '1px',
        width: '1px',
    }
});

export function LRRectCheckbox({ label, ...props }) {
    const classes = useStyles({ error: props.error });
    const checked = props.checked;
   
    return (
        <Box checked width={1} height={72} bgcolor={checked ? 'lr_colors.grey_nero' : '#FFF'} borderRadius={2} border="1px solid #DDD" color={checked ? '#FFF' : '#000'} style={{
            cursor: 'pointer'
        }}>
            <label style={{
                height: '100%',
                width: '100%'
            }}>
                <Box textAlign="center" p={2} height={1} width={1} display="flex" alignItems="center" justifyContent="center" style={{
                    cursor: 'pointer'
                }}>
                    {label}
                </Box>
                <Checkbox
                    className={classes.root}
                    disableRipple
                    color="default" 
                    {...props}
                />
            </label>
        </Box>
    );
}
