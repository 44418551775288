import { portalApi } from 'apis';
import Logger from 'js-logger';
import { useObserver } from 'mobx-react-lite';
import { useStore } from 'mobx-store';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

const USER_PREFERENCES_AREAIDS_CACHE_KEY = 'user-pref--areaIds';

export function useUserPreferencesAreaIds() {
    const { UserStore, boardsStore } = useStore();
    const areaIds = useMemo(()=> {
        if(!UserStore?.userPreferences?.areaIds?.length) return;

        return UserStore.userPreferences.areaIds;
    }, [UserStore?.userPreferences?.areaIds]);

    return useObserver(()=> ({
        data: areaIds,
        isLoading: boardsStore.loading,
    }));


    // const query = useQuery(
    //     [USER_PREFERENCES_AREAIDS_CACHE_KEY, areaIds],
    //     ()=> (portalApi.queryAreas(null, areaIds)),
    //     {
    //         enabled: Boolean(areaIds?.length),
    //         select: (data)=> data.map((area)=> ({
    //             formatted_term: area.id,
    //             search_term: area.id,
    //             search_type: area.type === 'zip' ? 'zipcode' : area.type,
    //         })),
    //     }
    // );

    // return useObserver(()=> ({
    //     ...query,
    //     isLoading: query.isLoading || boardsStore.loading, // Need to add this so we know we are waiting for additional data needed to make the query call
    // }));
}
