import React from 'react';
import { SvgIcon } from '@material-ui/core';

function TrashIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M6,19 C6,20.1000001 6.90000001,21 8,21 L16,21 C17.1000001,21 18,20.1000001 18,19 L18,7.00000002 L6,7.00000002 L6,19 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L19,4 Z"></path>
        </SvgIcon>
    );
}

export default TrashIcon;
