import React, { useState } from 'react';
import { Box, Icon, useTheme, Popper, ClickAwayListener } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


export function LRDropPopover({ title, children, onOpen, onClick= undefined, toggled = false }) {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        if(onClick){
            onClick(event);
        }

        if(onOpen){
            onOpen();
        }
        setAnchorEl(!anchorEl ? event.currentTarget : null);
    }

    function handleClickAway() {
        setAnchorEl(null);
    }

    const open = anchorEl ? true : false;

    const updateChildrenWithProps = React.Children.map(children, (child, i)=> {
        return React.cloneElement(child, {
            onClose: handleClickAway,
        });
    });

    return (
        <>
            <Box position="relative">
                <Box
                    style={{ cursor: 'pointer' }}
                    display="flex"
                    alignItems="center"
                    borderColor={theme.palette.lr_colors.border_grey}
                    bgcolor={toggled ? '#000' : theme.palette.lr_colors.crest_white}
                    color={toggled && '#fff'}
                    border={1}
                    borderRadius={4}
                    height={36}
                    padding="8px 29px 8px 12px"
                    position="relative"
                    data-testid="display"
                    onClick={handleClick}
                >
                    {title}
                    <Box position="absolute" display="flex" alignItems="center" right={8} top={0} bottom={0}>
                        <KeyboardArrowDownIcon style={{ fontSize: 16 }}/>
                    </Box>
                </Box>

                <Popper style={{ marginTop: '8px', zIndex: 1300 }} open={open} anchorEl={anchorEl} placement="bottom-start">
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box
                            borderRadius={4}
                            bgcolor={theme.palette.lr_colors.crest_white}
                            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
                        >
                            {updateChildrenWithProps}
                        </Box>
                    </ClickAwayListener>
                </Popper>
            </Box>
        </>
    );
}
