import { benoitApi } from 'apis';
import Logger from 'js-logger';
import { isObject } from 'lodash';
import { useStore } from 'mobx-store';
import { useQuery, useQueryClient } from 'react-query';
import { useAgentContext } from '.';

const CACHE_KEY = 'agent';

/**
 * @param {String} [agentId]
 * @param {Object} [options]
 * @param {String} [options.priority='agentId'] - (context, agentId or affiliate) used when the user is logged in and an agentId is passed in. by default it will prioritize the agentId passed in.
 * @param {Boolean} [options.skipUsersAffiliate=false] - Do not ever use the logged in users affiliate
 * @returns Object
 */

export function useAgent(agentId, options = { priority: 'agentId', skipUsersAffiliate: false }) {
    // This allows us to skip the agentId and pass the options object directly
    if(isObject(agentId)) {
        options = agentId;
        agentId = undefined;
    }

    const { UserStore } = useStore();
    const queryClient = useQueryClient();
    const contextValue = useAgentContext();

    if(options.priority === 'context' && contextValue) {
        agentId = contextValue?.id;
        const alreadyHasData = Boolean(queryClient.getQueryData([CACHE_KEY, agentId]));

        if(!alreadyHasData) queryClient.setQueryData([CACHE_KEY, agentId], contextValue);
    }

    if(!options.skipUsersAffiliate && (agentId === undefined || options.priority === 'affiliate') && UserStore.user?.affiliate ) {
        agentId = UserStore.user.affiliate.id;
        const alreadyHasData = Boolean(queryClient.getQueryData([CACHE_KEY, agentId]));

        if(!alreadyHasData) queryClient.setQueryData([CACHE_KEY, agentId], UserStore.user.affiliate);
    }

    const query = useQuery(
        [CACHE_KEY, agentId],
        ({ queryKey: [, agentId] })=> (benoitApi.getAffiliate(agentId)),
        {
            enabled: Boolean(agentId),
        },
    );

    return query;
}
