import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import LRAvatar from 'components/LRAvatar';

export const SkeletonNotification = function() {
    const theme = useTheme();
    
    return (
        <div style={{display: 'flex', width: '100%', padding: theme.spacing(2)}}>
            <div style={{marginRight: theme.spacing(2)}}>
                <LRAvatar loading="true" />
            </div>
            <div style={{flex: 1}}>
                <Typography variant="body2" style={{marginBottom: theme.spacing(1)}}>
                    <Skeleton animation="wave" variant="text" width="100%" height="100%" />
                    <Skeleton animation="wave" variant="text" width="100%" height="100%" />
                </Typography>
                <Typography component="div" variant="caption">
                    <Skeleton animation="wave" variant="text" width={80} height="100%" />
                </Typography>
            </div>

        </div>
    );
};
