import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import LRModal from '../../../../components/LRModal';

export const Modal = withTheme(styled((props)=> (
    <LRModal {...props} />
))`
    & .paperWidthSm-listing-details {
        height: auto;
        max-width: 560px;
        border-radius: 4px;
        padding: 0;

        ${({ theme })=> theme.breakpoints.only('xs')} {
            height: 332px;
            width: 280px;
        }
    }
`);

export const DeleteConfirmationFooter = withTheme(styled.div`
    padding-top: ${({ theme })=> theme.spacing(3)}px;
    display: flex;
    width:  100%;
    justify-content: flex-end;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        justify-content: space-between;
    }
`);
