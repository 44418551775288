import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Grid, Typography, useTheme, Dialog, CircularProgress, withTheme, Hidden } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { LRToggleButtonGroup } from 'components/LRToggleButtonGroup';
import { AreaSection } from './AreaSection';
import { PriceSection } from './PriceSection';
import styled from 'styled-components';
import { useActiveBoard, useStore } from 'mobx-store';
import { useForm, FormContext } from 'react-hook-form';
import LRDropdown from 'components/LRDropdown';
import { useAbility } from 'components/Ability';
import LRLink from 'components/LRLink';
import LRButton from 'components/LRButton';
import analytics from 'services/analytics';
import { useOnboarding } from 'components/Onboarding';
import { Close } from '@material-ui/icons';
import { useResponsiveBreakpoint } from 'hooks';
import { getListpackListingsCount } from 'services/babou';

const LRToggleButtonGroupOptionStyle = {
    width: '100%',
    color: '#1a1a1a',
    textTransform: 'inherit',
    fontWeight: 'normal',
};

export const SavedFooter = styled.div`
    display: flex;
    width:  100%;
    align-items: center;
    justify-content: space-between;
`;

const StyledDialog = styled(Dialog)``;

const howSoonOptions = [
    { value: 'ASAP', label: 'ASAP' },
    { value: 'within 3 months', label: '1-3 months' },
    { value: '3-6 months', label: '3-6 months' },
    { value: '6-12 months', label: '6-12 months' },
    { value: '12+ months', label: '12+ months' },
    { value:'i_already_bought_a_home_using_robin', label: 'I already bought a home using Robin'},
    { value: 'im_not_sure', label: "I'm not sure" },
];

const ModalActions = observer(({
    showMoreFilters,
    setShowMoreFilters,
    handleOnSkip,
    isValid,
    isLoading,
    ...props
})=> {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;

    return (
        <SavedFooter>
            <Box>
                <LRLink
                    mr={2}
                    height={36}
                    display= "inline-flex"
                    alignItems="center"
                    onClick={()=> setShowMoreFilters(!showMoreFilters)}
                    style={{ textDecoration: 'underline',  color: theme.palette.lr_colors.grade}}
                >
                    { showMoreFilters ? 'Fewer preferences' : 'More preferences' }
                </LRLink>
            </Box>
            <Box display="flex">
                {
                    handleOnSkip && (
                        <>
                            {
                                !isMobile ? (
                                    <LRLink
                                        mr={2}
                                        height={36}
                                        display= "inline-flex"
                                        alignItems="center"
                                        onClick={()=> { handleOnSkip(); }}
                                        style={{ textDecoration: 'underline',  color: theme.palette.lr_colors.grade}}
                                    >
                                    Skip
                                    </LRLink>
                                ) : (
                                    <LRButton
                                        variant="outlined"
                                        onClick={()=> { handleOnSkip(); }}
                                        style={{ marginRight: theme.spacing(1) }}
                                        disabled={isLoading}
                                    >
                                        Skip
                                    </LRButton>
                                )
                            }
                        </>
                    )
                }

                <LRButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    width={56}
                    height={36}
                    disabled={!isValid || isLoading}                                    
                >
                    {isLoading ? <CircularProgress size={24} color="white" /> : 'Done'}
                </LRButton>
            </Box>
        </SavedFooter>    
    );
});

export const PreferencesModal = observer(({
    open,
    handleOnClose,
    handleOnSkip,
    handleOnSubmit,
    ...props
})=> {
    const theme = useTheme();
    const activeBoard = useActiveBoard();
    const { UserStore } = useStore();
    const { user, userPreferences, hasUserPreferences } = UserStore;
    const ability = useAbility();
    const [isLoading, setIsLoading] = useState(false);
    const defaultTimeframeValue = useMemo(()=> {
        if(!user) return null;
        
        return howSoonOptions.find((option)=> {
            return option.value === (UserStore.howSoonTimeframe || 'im_not_sure');
        });
    }, [UserStore.howSoonTimeframe, user]);
    const [selectedTimeframe, setSelectedTimeframe] = useState(defaultTimeframeValue);
    const canEditUser = ability.can('edit', 'User');
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const formRef = useRef();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const [insuficientListingsError, setInsuficientListingsError] = useState(false);

    const rfhMethods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {    
            areaIds: userPreferences?.areaIds || [],
            timeframe: defaultTimeframeValue,
            preferences: {
                price_min: userPreferences?.price_min || null,
                price_max: userPreferences?.price_max || null,
                bathrooms: userPreferences?.bathrooms || null,
                bedrooms: userPreferences?.bedrooms || null,
            }
        },
    });
    const { handleSubmit, formState, triggerValidation } = rfhMethods;

    const { isValid } = formState;

    useEffect(()=> {
        triggerValidation();
    }, [triggerValidation]); 


    async function onSubmit(formdata, e) {
        const {preferences = {}, areaIds} = formdata;
        
        let preferencesData = null;

        if(preferences.price_min 
            || preferences.price_max
            || preferences.bathrooms
            || preferences.bedrooms
            || preferences.price_min
            || areaIds) {
            preferencesData = {
                ...preferences,
                areaIds: areaIds || null,
                boardId: activeBoard?.id,
                userId: user.id
            };
        }

        if(preferencesData){
            analytics.eventTrack((hasUserPreferences) ? 'robin_default_preferences_updated' : 'robin_default_preferences_added');
        }

        setIsLoading(true);
       
        const {  areaIds: areas, bathrooms, bedrooms, price_min, price_max } = preferencesData;

        const payload = {
            userId: user.id,
            boardId: activeBoard.id,
            name: `${user?.first_name}’s first saved search 🎉`,
            areaIds: areas,
            notification_settings: {
                alert_frequency: 'daily'
            },
            fields: {
                bathrooms: bathrooms,
                bedrooms: bedrooms,
                price_min: price_min,
                price_max: price_max,
                homesize_min: null,
                homesize_max: null,
                stories: null,
                lotsize_min: null,
                lotsize_max: null,
                year_built_min: null,
                year_built_max: null,
                school_score_min: null,
                hometype: {
                    single_family: false,
                    multi_family: false,
                    condo_townhome: false,
                    mobile_manufactured: false,
                    land: false,
                },
                tags: {
                    with_great_schools: false,
                    with_virtual_tours: false,
                    with_open_houses: false,
                    with_views: false,
                    with_upgraded_kitchens: false,
                    with_pool: false,
                },
            }
        };

        const babouCountResult = await getListpackListingsCount(payload);
        const { active_listings } = babouCountResult;
        
        if(active_listings <= 0) {
            setInsuficientListingsError(true);
            setIsLoading(false);
            return;
        }

        if(selectedTimeframe) {
            await UserStore.updateUserTimeframe(selectedTimeframe?.value);
        }
        await UserStore.updateUserPreferences(preferencesData);

        if(handleOnSubmit) {
            handleOnSubmit();
        }

        // setIsLoading(false);
    }
    
    return (
        <StyledDialog
            fullScreen={isMobile} 
            fullWidth 
            maxWidth='sm' 
            open={open}
            onClose={handleOnClose}
            classes={{ root: 'tour-set-preferences-modal' }}
        >
            <FormContext {...rfhMethods}>
                <form 
                    ref={formRef}
                    onSubmit={handleSubmit(onSubmit)} 
                    style={{
                        // height: '100%'
                    }}
                >
                    <div style={{
                        position: isMobile ? 'absolute' : 'relative',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0, 
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Hidden smUp>
                            <div
                                style={{
                                    position: 'relative',
                                    textAlign: 'center',
                                    padding: theme.spacing(2),
                                    borderBottom: `1px solid ${theme.palette.lr_colors.border_grey}`
                                }}
                            >
                                General Preferences
                                {/* <div
                                    style={{
                                        position: 'absolute',
                                        right: theme.spacing(2)
                                    }}
                                >
                                    <Close />
                                </div> */}
                            </div>
                        </Hidden>
                        <Box padding={[2, 3]} flex={1} overflow="auto">
                            <Grid item xs={12}>
                                <Box my={1}>
                                    <Typography variant="h6"  style={{marginBottom: theme.spacing(1)}}>Your home browsing preferences</Typography>
                                    <Box fontSize={16} mb={3}>
                                        Use these to jump start your searches and improve your recommendations.
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={1}>
                                    <Box fontSize={16} mb={1} fontWeight={500}>
                                        <span role="img" aria-label="search" style={{ marginRight: theme.spacing(1) }}>
                                        🔍️
                                        </span>
                                        Where do you want to see homes?
                                    </Box>
                                    <AreaSection onChange={setInsuficientListingsError} />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box my={2}>
                                    <Box fontSize={16} mb={1} fontWeight={500}>
                                        <span role="img" aria-label="calendar" style={{ marginRight: theme.spacing(1) }}>
                                            🗓️️
                                        </span>
                                        What’s your (best guess) timeframe to buy a home?
                                    </Box>
                                    <LRDropdown
                                        disabled={!canEditUser}
                                        selectedOption={selectedTimeframe}
                                        onOptionSelect={(option)=> {
                                            setSelectedTimeframe(option);
                                            setInsuficientListingsError(false);
                                        }}
                                        options={howSoonOptions}
                                        renderDisplayOption={({ label })=> <Box component="span" fontSize={16}>{label}</Box>}
                                        renderOption={({ label })=> label}
                                        DropdownDisplayProps={{ borderRadius: 0, pl: '12px' }}
                                    />
                                    
                                </Box>
                            </Grid>

                            { showMoreFilters && (
                                <>
                                    <Grid item xs={12}>
                                        <Box mb={2}>
                                            <Box fontSize={16} mb={1} fontWeight={500}>
                                                <span role="img" aria-label="price" style={{ marginRight: theme.spacing(1) }}>
                                                    💵
                                                </span>
                                                What’s your price range?
                                            </Box>
                                            <PriceSection onChange={setInsuficientListingsError} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mb={3}>
                                            <Box fontSize={16} mb={1} fontWeight={500}>
                                                <span role="img" aria-label="beds" style={{ marginRight: theme.spacing(1) }}>
                                                    🛏
                                                </span>
                                                Beds</Box>
                                            <Controller
                                                name="preferences.bedrooms"
                                                onChange={([selected])=> {
                                                    setInsuficientListingsError(false);
                                                    return selected;
                                                }}
                                                as={
                                                    <LRToggleButtonGroup
                                                        fullWidth
                                                        options={[
                                                            { value: '0', label: 'Studio', style: LRToggleButtonGroupOptionStyle },
                                                            { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
                                                            { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
                                                            { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
                                                            { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mb={5}>
                                            <Box fontSize={16} mb={1} fontWeight={500}>
                                                <span role="img" aria-label="baths" style={{ marginRight: theme.spacing(1) }}>
                                                    🛁
                                                </span>
                                                Baths</Box>
                                            <Controller
                                                name="preferences.bathrooms"
                                                onChange={([selected])=> {
                                                    setInsuficientListingsError(false);
                                                    return selected;
                                                }}
                                                as={
                                                    <LRToggleButtonGroup
                                                        fullWidth 
                                                        options={[
                                                            { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
                                                            { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
                                                            { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
                                                            { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            )
                            }

                            { insuficientListingsError && (
                                <Grid item xs={12}>
                                    <Box my={2} textAlign="center" color="lr_colors.error">
                                        We couldn’t find any active listings with your current filter settings. Please try expanding your search area or update your preferences to continue
                                    </Box>
                                </Grid>
                            )}

                            <Hidden smDown>
                                <div style={{
                                    width: '100%',
                                    paddingTop: theme.spacing(2)
                                }}>
                                    <ModalActions
                                        showMoreFilters={showMoreFilters}
                                        setShowMoreFilters={setShowMoreFilters}
                                        handleOnSkip={handleOnSkip}
                                        isValid={isValid}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </Hidden>

                        </Box>   
                        <Hidden smUp>
                            <div
                                style={{
                                    paddingRight: theme.spacing(2),
                                    paddingLeft: theme.spacing(2),
                                    paddingTop: theme.spacing(1.5),
                                    paddingBottom: theme.spacing(1.5),
                                    background: theme.palette.lr_colors.costa_mesa,
                                    borderTop: `1px solid ${theme.palette.lr_colors.border_grey}` 
                                }}
                            >
                                <ModalActions
                                    showMoreFilters={showMoreFilters}
                                    setShowMoreFilters={setShowMoreFilters}
                                    handleOnSkip={handleOnSkip}
                                    isValid={isValid}
                                    isLoading={isLoading}
                                />
                            </div>
                        </Hidden>
                    </div>
                </form>
            </FormContext>
        </StyledDialog>
    );
});
