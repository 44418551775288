import React, { useEffect, useRef } from 'react';
import { Switch, Route, useParams, useLocation, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { COLOR_GREY_LIGHT } from '../../constants';
import { useStore } from '../../mobx-store';
import queryString from 'query-string';
import Lockr from 'lockr';
import { portalApi } from '../../apis';
import { observer } from 'mobx-react-lite';

import ListpackCover from './ListpackCover';
import ListpackList from './ListpackList';
import ListpackEnd from './ListpackEnd';
import ListpackListing from './ListpackListing';
import { useQuery } from '../../hooks';

import { defaultSortQuery, deserializeSortOption } from './utils';

function Listpack() {
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const params = useParams();
    const { ListpackStore } = useStore();

    const agent = ListpackStore.agent;
    const listpack = ListpackStore.listpack;
    const listpackId = params.listpackId;
    const { areaIds, agentId } = queryString.parse(location.search);

    let isOldURLStructure = false;
    // let check if the url is in the new format
    var decodedPayload = {};

    // const orderRef = useRef();

    // useEffect(()=> {
    //     orderRef.current = location.pathname.endsWith('/cover') ? 'Price (high to low)' : order;
    // });

    try {
        decodedPayload = JSON.parse(window.atob(listpackId));
    } catch (err) {
        // try the tow old formats§
        isOldURLStructure = true;
        let decodedAreaParam = decodeURIComponent(areaIds);

        try {
            decodedPayload.areaIds = JSON.parse(decodedAreaParam);
        } catch (err) {
            decodedPayload.areaIds = JSON.parse(window.atob(decodedAreaParam));
        }

        decodedPayload.agentId = agentId;
        decodedPayload.id = listpackId;
    }
    const sort = useQuery()
        .get('sort') || decodedPayload.sort || defaultSortQuery;
    const sortOption = deserializeSortOption(sort);

    // Ranked listpacks like "Top 10 Most Expensive Homes"
    // are a primary use case for firstPageLimit
    // since we want to show only the top 10 results on
    // initial load but allow the user to load more
    const firstPageLimit = (decodedPayload.limit && !isNaN(parseInt(decodedPayload.limit, 10)))
        ? parseInt(decodedPayload.limit, 10)
        : null;

    ListpackStore.agentId = decodedPayload.agentId;
    ListpackStore.areasIds = decodedPayload.areaIds;
    ListpackStore.isCustomListpack = decodedPayload.custom;
    ListpackStore.listpackEncodedPayload = btoa(JSON.stringify(decodedPayload));

    useEffect(
        function() {
            if(!ListpackStore.listpack) {
                ListpackStore.fetchListpackData();
            } else {
                ListpackStore.incrementClickMetric();
            }
        },
        [ListpackStore, listpackId, ListpackStore.listpack]
    );

    useEffect(
        function() {
            if(!agent || !agent.preferredVendorId || !listpack || !listpack.id || !listpackId) return;
            if(Lockr.get('listpack-lead-saw-listpack-activity-sent')) return;

            const activityName = 'myagents_lead_opened_listpack';
            const activityData = {
                avatar: agent.headshot,
                type: 'realtor',
                userId: agent.preferredVendorId,
                meta: {
                    portal_user_id: agent.id,
                    name: agent.name,
                    first_name: agent.first_name,
                    last_name: agent.last_name,
                    agent_email: agent.email,
                    company: agent.company,
                    rusc: agent.rusc,
                    listpack_name: listpack.name,
                    listpack_id: listpack.id,
                    public_listpack_id: listpackId,
                },
                name: activityName,
            };

            portalApi.createActivity(activityData);

            Lockr.set('listpack-lead-saw-listpack-activity-sent', true);
        },
        [ListpackStore, agent, listpack, listpackId]
    );

    useEffect(
        function() {
            if(!ListpackStore.listpack || !ListpackStore.agent) return;
            ListpackStore.fetchListings(decodedPayload.id, sortOption);
        },
        [ListpackStore, ListpackStore.listpack, ListpackStore.agent, decodedPayload.id, sortOption]
    );

    function handleGoToListing(listing) {
        history.push({
            pathname: `${match.url}/listings/${listing.id}`,
            search: queryString.stringify({ sort }),
        });
    }

    function handleGoToEndFromListing(listing, { source }) {
        const qs = new URLSearchParams(location.search);
        qs.set('sort', sort);
        qs.set('source', source);
        qs.set('address', listing.address);
        qs.set('focus', true);
        if(listing?.listing_source !== 'listhub') {
            qs.set('listingId', listing.id);
        }

        history.push({
            pathname: `${match.url}/end`,
            search: qs.toString(),
        });
    }

    function handleGoBackToList() {
        history.push({
            pathname: `${match.url}/list`,
            search: queryString.stringify({ sort: sort }),
        });
    }

    return (
        <Box height="100%" width="100%" bgcolor={COLOR_GREY_LIGHT} mb={[12, 0]}>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
                height="100%"
                m="0 auto"
                maxWidth="770px"
                bgcolor="white"
            >
                <Switch>
                    {/* <Route exact path={'/listpack/:listpackId'} component={ListpackInit} /> */}
                    <Route path={'/listpack/:listpackId/cover'} component={ListpackCover} />
                    <Route path={'/listpack/:listpackId/list'} render={()=> (
                        <ListpackList sortOption={sortOption} firstPageLimit={firstPageLimit} onListingClick={handleGoToListing} />
                    )} />
                    <Route path={'/listpack/:listpackId/end'} render={()=> (
                        <ListpackEnd onGoToList={handleGoBackToList} onGoToListing={handleGoToListing}/>
                    )} />
                    <Route path={'/listpack/:listpackId/listings/:listingId'} render={()=> (
                        <ListpackListing onGoBack={handleGoBackToList} onGoToEnd={handleGoToEndFromListing}/>
                    )} />
                    <Route path={'/listpack/:listpackId/listing'} component={ListpackListing} />
                </Switch>
            </Box>
        </Box>
    );
}

export default observer(Listpack);
