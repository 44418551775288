import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { COLOR_GREY_MEDIUM } from '../../../../constants';
import { PushPinIcon } from '../../../LRIcons';
import { useCompareNowStoreContext } from '../../store';
import { useBoardCollaborations } from '../../../../hooks/useBoardCollaborations';
import { useStore } from '../../../../mobx-store';
import simpleCypher from '../../../../services/simple-cypher';
import analytics from '../../../../services/analytics';
import { ChatBubble } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const CommentWrapper = observer(({ card })=> {
    const store = useCompareNowStoreContext();
    const history = useHistory();
    const { boardsStore, UserStore, PinspirationStore } = useStore();
    const activeBoard = boardsStore.activeBoard;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const {
        cardMessageBoardCollaborations,
    } = useBoardCollaborations(card.id);
    let cardComments = cardMessageBoardCollaborations;
    if(!UserStore.isConsumer) {
        cardComments = cardComments.filter((comment)=> comment.visibleToAffiliate === true);
    }
    const { id: listingId, source: listingSource } = simpleCypher.decode(card.listing_details.id) || {};
    const itemId = `${listingSource}/${listingId}`;
    const pinnedImagesCount = [...PinspirationStore.collaborationsCache]
        .filter(([k, pin])=> pin.itemId === itemId).length;

    useEffect(()=> {
        async function fetchCollaborations() {
            await PinspirationStore.getCollaborationsByListingId(activeBoard.id, card.listing_details.id);
        }
        if(card.listing_details.id && activeBoard.id) {
            fetchCollaborations();
        }
    }, [card.listing_details.id, activeBoard.id, PinspirationStore]);

    function summaryClick(show_collab_modal) {
        history.push(`/boards/compare-now/cards/${card.id}/${_.kebabCase(card.listing_details.address)}?source=compare&show_collab_modal=${show_collab_modal}`);
    }

    return (
        <Box width="50%" position="relative" pl={2}>
            {cardComments.length > 0 &&
                <Box mt={2} display="flex" >
                    <Box fontSize={16} display="flex" alignItems="center">
                        <ChatBubble fontSize="inherit" />
                    </Box>
                    <Box
                        ml={1}
                        color="lr_colors.blue_link"
                        style={{cursor: 'pointer'}}
                        onClick={function() {
                            analytics.eventTrack('robin_compare_notes_comments_click', {
                                role: UserStore.usersBoardRole,
                                board_card_id: card.id
                            });
                            summaryClick(true);
                        }}
                    >
                        <Typography variant="body1" color="inherit">
                            {`${cardComments.length} comment${cardComments.length > 1 ? 's' : ''}`}
                        </Typography>
                    </Box>
                </Box>
            }
            { !!pinnedImagesCount &&
                <Box mt={1} display="flex" >
                    <Box fontSize={16} display="flex" alignItems="center">
                        <PushPinIcon fontSize="inherit" />
                    </Box>
                    <Box
                        ml={1}
                        color="lr_colors.blue_link"
                        style={{cursor: 'pointer'}}
                        onClick={function() {
                            analytics.eventTrack('robin_compare_notes_pinned_photos_click', {
                                role: UserStore.usersBoardRole,
                                board_card_id: card.id
                            });
                            summaryClick(false);
                        }}
                    >
                        <Typography variant="body1" color="inherit">
                            {`${pinnedImagesCount} pinned photo${pinnedImagesCount > 1 ? 's' : ''}`}
                        </Typography>
                    </Box>
                </Box>
            }
            <Box height={8} />
        </Box>
    );
});

function Comments({ cards }) {
    return (
        <Box display="flex" borderTop={1} borderColor={COLOR_GREY_MEDIUM} position="relative" minHeight={48}>
            <CommentWrapper card={cards[0]} />
            {cards[1] && <Box borderRight="1px solid #E0E0E0" />}
            {cards[1] && <CommentWrapper card={cards[1]} />}
        </Box>
    );
}

Comments.propTypes = {
    selectedComments: PropTypes.array,
    setFirstIndex: PropTypes.func,
    setSecondIndex: PropTypes.func
};

export default Comments;
