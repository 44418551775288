import React, { useEffect, useState } from 'react';
import Logger from 'js-logger';
import { AppBar, Dialog, DialogContent, Grid, Hidden, IconButton, Slide, Toolbar, Typography, useTheme } from '@material-ui/core';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import LRButton from 'components/LRButton';
import { ChevronLeftRounded } from '@material-ui/icons';
import { LRFormControl, LRInputOutlined, LRInputSelect } from 'components/LRInput';
import { useForm } from 'react-hook-form';
import { LRPhoneInput } from 'components/LRPhoneInput';
import { MY_HOME_SERVICE_PROVIDER_CONTRACTOR, MY_HOME_SERVICE_PROVIDER_ELECTRICIAN, MY_HOME_SERVICE_PROVIDER_HANDYPERSON, MY_HOME_SERVICE_PROVIDER_HOUSEKEEPER, MY_HOME_SERVICE_PROVIDER_HVAC, MY_HOME_SERVICE_PROVIDER_LANDSCAPER, MY_HOME_SERVICE_PROVIDER_OTHER, MY_HOME_SERVICE_PROVIDER_PLUMBER, MY_HOME_SERVICE_PROVIDER_POOL_SPA, MY_HOME_SERVICE_PROVIDER_ROOFING, MY_HOME_SERVICE_PROVIDER_WINDOWS_DOORS, VALIDATION_EMAIL_REGEX } from '../../../constants';
import { partial } from 'lodash';


const logger = Logger.get('AddServiceProviderModal');

export function AddServiceProviderModal({
    serviceProvider,
    open = false,
    closeCTAText,
    TransitionComponent = Slide,
    TransitionProps = {},
    style = {},
    PaperProps = {},
    onCloseClick = (e)=> {},
    onSave = async (data)=> {},
    onDeleteClick = async (e, serviceProvider)=> {},
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
    } = useDeviceBreakpoints();
    const form = useForm({
        defaultValues: {},
    });
    const { register, handleSubmit, errors, reset, setValue, triggerValidation, } = form;
    const [selectedService, setSelectedService] = useState();
    const [saving, setSaving] = useState(false);

    // UseEffects

    useEffect(()=> {
        if(open) {
            reset({
                ...serviceProvider,
            });
            setSelectedService(serviceProvider ? serviceProvider.type : '');
        }
    }, [open, reset, serviceProvider]);

    useEffect(()=> {
        register('type', { required: true });
    }, [register]);

    // Functions

    function onSelectedServiceChange(e, value) {
        setValue('type', value);
        setSelectedService(value);

        if(Boolean(errors.type)) triggerValidation('type');
    }

    async function onSubmit(data) {
        setSaving(true);
        await onSave(data);
        setSaving(false);
    }

    async function onDelete(e, serviceProvider) {
        setSaving(true);
        await onDeleteClick(e, serviceProvider);
        setSaving(false);
    }

    return (
        <Dialog 
            disableEnforceFocus
            fullScreen
            open={open} 
            style={{
                ...style,
            }}
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.lr_colors.grey_light,
                    ...(PaperProps?.style || {}),
                },
                ...PaperProps,
            }}
            TransitionComponent={TransitionComponent}
            TransitionProps={{
                direction: 'left',
                ...TransitionProps,
            }}
            {...props}
        >
            <AppBar color="inherit" elevation={0} style={{ border: '1px solid', borderColor: theme.palette.lr_colors.border_grey, backgroundColor: '#fff', }}>
                <Toolbar>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={2} sm={3}>
                            {!isMobile ? (
                                <LRButton startIcon={<ChevronLeftRounded />} onClick={onCloseClick}>
                                    {closeCTAText ? closeCTAText : 'Home Report'}
                                </LRButton>
                            ) : (
                                <IconButton edge="start" onClick={onCloseClick}>
                                    <ChevronLeftRounded />
                                </IconButton>
                            )}
                        </Grid>
                        
                        <Grid item>
                            <Typography>
                                {serviceProvider ? 'Edit' : 'Add'} service provider
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={3} style={{ textAlign: 'right', }}>
                            {Boolean(serviceProvider) && <LRButton variant="text" inline underline style={{ marginRight: !isMobile && theme.spacing(2), }} onClick={partial(onDelete, partial.placeholder, serviceProvider)} disabled={saving}>Delete</LRButton>}
                            
                            <Hidden xsDown>
                                <LRButton variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={saving}>Save</LRButton>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Toolbar />

            <DialogContent style={{ paddingLeft: isMobile && 0, paddingRight: isMobile && 0, paddingTop: isMobile && theme.spacing(1), }}>
                <div
                    style={{
                        width: '100%',
                        maxWidth: 688,
                        backgroundColor: '#fff',
                        margin: theme.spacing(isMobile ? 2 : 5),
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        padding: `${theme.spacing(isMobile ? 2 : 5)}px ${theme.spacing(isMobile ? 2 : 11)}px`,
                    }}
                >
                    <LRFormControl 
                        uniqueId="sp-service" 
                        label="Service" 
                        fullWidth 
                        required
                        error={Boolean(errors.type)}
                        helperText={Boolean(errors.type) && 'Please select a service.'}
                    >
                        <LRInputSelect
                            error={Boolean(errors.type)}
                            value={selectedService}
                            onChange={onSelectedServiceChange}
                            options={[
                                MY_HOME_SERVICE_PROVIDER_CONTRACTOR,
                                MY_HOME_SERVICE_PROVIDER_ELECTRICIAN,
                                MY_HOME_SERVICE_PROVIDER_HANDYPERSON,
                                MY_HOME_SERVICE_PROVIDER_HOUSEKEEPER,
                                MY_HOME_SERVICE_PROVIDER_HVAC,
                                MY_HOME_SERVICE_PROVIDER_LANDSCAPER,
                                MY_HOME_SERVICE_PROVIDER_OTHER,
                                MY_HOME_SERVICE_PROVIDER_PLUMBER,
                                MY_HOME_SERVICE_PROVIDER_POOL_SPA,
                                MY_HOME_SERVICE_PROVIDER_ROOFING,
                                MY_HOME_SERVICE_PROVIDER_WINDOWS_DOORS,
                            ]}
                            fullWidth
                            required
                        />
                    </LRFormControl>

                    <Grid container spacing={!isMobile && 2} style={{ display: isMobile ? 'block' : 'flex', }}>
                        <Grid item xs>
                            <LRInputOutlined 
                                label="First name"
                                name="first_name" 
                                error={Boolean(errors.first_name)}
                                autoComplete="off"
                                ref={register({ required: false })} 
                                helperText={Boolean(errors.first_name) && 'Please enter a First name.'}
                            />
                        </Grid>
                        <Grid item xs>
                            <LRInputOutlined 
                                label="Last name"
                                name="last_name" 
                                error={Boolean(errors.last_name)}
                                autoComplete="off"
                                ref={register({ required: false })} 
                                helperText={Boolean(errors.last_name) && 'Please enter a Last name.'}
                            />
                        </Grid>
                    </Grid>

                    <LRInputOutlined 
                        label="Company name"
                        name="company" 
                        error={Boolean(errors.company)}
                        autoComplete="off"
                        ref={register({ required: true })} 
                        helperText={Boolean(errors.company) && 'Please enter a Company name.'}
                        required
                    />

                    <LRFormControl uniqueId="sp-phone" label="Phone number">
                        <LRPhoneInput 
                            id="sp-phone"
                            rfhMethods={form}
                            rules={{ required: false }}
                            name="phone"
                            inputProps={{
                                height: '48px',
                                width: isMobile ? '100%' : 211,
                                fontSize: theme.typography.body1.fontSize,
                            }}
                        />
                    </LRFormControl>

                    <LRInputOutlined 
                        label="Email"
                        name="email" 
                        error={Boolean(errors.email)}
                        autoComplete="off"
                        ref={register({ required: false, validate: (value)=> (!value || Boolean((value || '').match(VALIDATION_EMAIL_REGEX))) })} 
                        helperText={Boolean(errors.email) && 'Please enter an Email address.'}
                        InputProps={{
                            type: 'email',
                        }}
                    />

                    <LRInputOutlined 
                        label="Notes"
                        name="notes" 
                        autoComplete="off"
                        ref={register({ required: false })} 
                        InputProps={{
                            multiline: true,
                        }}
                        inputProps={{
                            style: {
                                minHeight: 120,
                            },
                        }}
                    />
                </div>
            </DialogContent>

            <Hidden smUp>
                <Toolbar />
                <AppBar 
                    color="inherit" 
                    className="HeaderNavBar-root" 
                    elevation={0}  
                    style={{
                        borderTop: '1px solid #ddd',
                        top: 'auto',
                        bottom: 0,
                        paddingTop: 6,
                        paddingBottom: 6,
                    }}
                    {...props}
                >
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs>
                                <LRButton variant="contained" color="primary" fullWidth onClick={handleSubmit(onSubmit)} disabled={saving}>Save</LRButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Hidden>
        </Dialog>
    );
}
