import React, { useState, useCallback, useEffect } from 'react';
import { Dialog, Hidden } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import { useHistory } from 'react-router-dom';
import DialogHeader from './DialogHeader';
import MortgageCenterBoard from './MortgageCenterBoard';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

function MortgageCenterBoardModal({
    open = false,
    onClose = (e)=> {},
    style = {},
    ...props
}) {
    const history = useHistory();
    const { UserStore } = useStore();
    const {
        isMobile,
    } = useDeviceBreakpoints();

    useEffect(function() {
        if(!UserStore.loRobinAccess) {
            if(onClose) {
                onClose();
            } else {
                history.push('/boards');
            }
        }
    }, [UserStore.loRobinAccess]);

    return (
        <Dialog
            open={open}
            hideBackdrop
            PaperProps={{
                elevation: 0,
            }}
            fullScreen
            maxWidth="100%"
            style={{
                marginTop: !isMobile && 64,
                ...style,
            }}
            {...props}
        >
            <Hidden smUp>
                <DialogHeader onClose={onClose} />
            </Hidden>
            <MortgageCenterBoard />
        </Dialog>
    );
}

export default observer(MortgageCenterBoardModal);
