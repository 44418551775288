import React from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@material-ui/core';
import ListingImage from '../../../components/ListingImage';
import addCommas from '../../../services/add-commas';
import splitAddress from '../../../services/split-address';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';

export function AddAnyHomeListingCard({
    listingDetails,
    style = {},
    ...props
}) {
    const theme = useTheme();
    const [addressLine1, addressLine2] = splitAddress(listingDetails?.address);
    const [city] = addressLine2?.split(',') || [];
    const listingPrice = listingDetails?.price ? `$${addCommas(listingDetails?.price)}` : null;
    const image = listingDetails.hero_image_url || (listingDetails.photos.length >= 0 && listingDetails.photos[0]);
    return (
        <div
            style={{
                borderRadius: '4px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                overflow: 'hidden',
                background: '#fff',
                ...style,
            }}
            {...props}
        >
            <div
                style={{
                    position: 'relative',
                }}
            >
                {image && <ListingImage
                    style={{
                        paddingBottom: '51.52%',
                        position: 'relative',
                    }}
                    image={image}
                >
                    {!listingDetails.id && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                color: '#fff',
                                padding: theme.spacing(5),
                            }}
                        >
                            <div>
                                This listing is coming soon. We'll update it as soon as it's available.
                            </div>
                        </div>
                    )}
                </ListingImage>}
                {!image && <div style={{height: 124}}>
                    <StaticGoogleMaps
                        latitude={listingDetails?.lat || listingDetails?.latitude}
                        longitude={listingDetails?.lon || listingDetails?.longitude}
                        height={300}
                    />
                </div>}
            </div>

            <div
                style={{
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    paddingBottom: theme.spacing(2),
                    paddingTop: theme.spacing(1.5),
                    color: theme.palette?.lr_colors?.grade,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            maxWidth: '80%',
                        }}
                    >
                        {(listingDetails.id || listingDetails.address) && (
                            <>
                                <Typography
                                    title={addressLine1}
                                    style={{fontWeight: 'bold'}}
                                >
                                    {addressLine1}
                                </Typography>

                                <Typography>
                                    <span
                                        style={{
                                            color: theme.palette.lr_colors.steak_sauce,
                                        }}
                                    >{city}</span> {Boolean(listingPrice) && listingPrice}
                                </Typography>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

AddAnyHomeListingCard.propTypes = {
    listingDetails: PropTypes.object.isRequired,
};
