import React, { useMemo, useState, useEffect } from 'react';
import { useResponsive, useResponsiveBreakpoint } from 'hooks';
import { Swiper } from 'components/Swiper';
import ListingImage from 'components/ListingImage';
import { Slide, Typography, useTheme } from '@material-ui/core';
import { Tour3DIcon } from 'components/LRIcons';
import { Skeleton } from '@material-ui/lab';
import { ListingExplorerModal } from 'components/ListingExplorerModal';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import PinspirationOverlay from 'components/Pinspiration/PinspirationOverlay';
import analytics from 'services/analytics';
import Logger from 'js-logger';
import { ModalRoute } from 'components/ModalRoute';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';
import _ from 'lodash';

const background3DTour = 'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) )';

function ListingThumbnails({ listing, onThumbnailClick, onVirtualTourClick }) {
    const r = useResponsive();
    const hasVirtualTour = listing?.virtual_tours?.length > 0;
    const numberOfThumbnails = r({ xs: 3, sm: 4 });
    const photos = useMemo(()=> {
        if(!listing?.photos) {
            return Array(numberOfThumbnails)
                .fill({ url: null });
        }
        return listing.photos.slice(1, numberOfThumbnails + 1)
            .map((photo, index)=> {
                return { url: photo, isVirtualTourLink: index === 2 && hasVirtualTour };
            });
    }, [hasVirtualTour, listing, numberOfThumbnails]);

    function handlePhotoClick(index) {
        const photo = photos[index];
        if(photo.isVirtualTourLink) {
            onVirtualTourClick();
        } else {
            onThumbnailClick();
        }
    }

    return (
        <div
            className="space-x-1"
            style={{
                display: 'flex',
            }}
        >
            {photos.map(({ url, isVirtualTourLink }, index)=> (
                <div
                    key={url || index}
                    style={{
                        flex: 1,
                        height: '128px',
                        position: 'relative',
                    }}
                >
                    {url && (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                zIndex: 1,
                                cursor: 'pointer'
                            }}
                            onClick={()=> handlePhotoClick(index)}
                        >
                            <img
                                alt="listing"
                                src={url}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            {isVirtualTourLink && (
                                <div
                                    className="space-y-2"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        background: background3DTour,
                                        color: 'white',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Tour3DIcon />
                                    <Typography variant={r({ xs: 'body2', sm: 'h6' })}>3D Tour</Typography>
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 0,
                        }}
                    >
                        <Skeleton animation="wave" variant="rect" width="100%" height="100%" />
                    </div>
                </div>
            ))}
        </div>
    );
}

const emptyArray = [];

function GallerySwiper({ listing, card, listpack, onSlideClick, readOnly=false, PinspirationOverlayProps = {} }) {
    const theme = useTheme();
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

    const photos = !_.isEmpty(listing?.photos) ? listing?.photos : null || card?.hero_image_url ? [card?.hero_image_url] : null || emptyArray;
    const isXs = useResponsiveBreakpoint('xs');

    useEffect(()=> {
        setSelectedPhotoIndex(0);
    }, [listing]);

    // Functions

    function handleSlideClick() {
        onSlideClick(selectedPhotoIndex);
    }

    function handleLoopChange(swipeInstance) {
        if(swipeInstance) {
            swipeInstance.loopDestroy();
            swipeInstance.loopCreate();
            swipeInstance.update();
        }
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                zIndex: 1,
            }}
        >
            {listing ? (
                <>
                    {!_.isEmpty(photos) && 
                    <>
                        <Swiper
                            key={`${listing.id}-new-gallery-swiper`}
                            data={photos}
                            slidesPerView={1}
                            hasNavigation={true}
                            activeIndex={selectedPhotoIndex}
                            loop
                            nextButtonClick={handleLoopChange}
                            previousButtonClick={handleLoopChange}
                            allowTouchMove
                            onSlideClick={handleSlideClick}
                            onSlideChange={(index)=> {
                                setSelectedPhotoIndex(index);
                            }}
                            renderSlide={(photo)=> (
                                <>
                                    <ListingImage
                                        image={photo}
                                        width={1}
                                        height={1}
                                        hideAnimation={true}
                                        className="tour-target-listing-image"
                                        backgroundColor="transparent"
                                    />
                                    <PinspirationOverlay src={photo} listing={listing} listpackId={null} readOnly={readOnly} {...PinspirationOverlayProps}/>
                                </>
                            )}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '20px',
                                right: '40px',
                                zIndex: '10',
                            }}
                        >
                            <span
                                className="space-x-2"
                                style={{
                                    display: 'inline-flex',
                                    fontSize: '16px',
                                    backgroundColor: 'rgba(26, 26, 26, 0.8)',
                                    color: 'white',
                                    fontWeight: '500',
                                    borderRadius: '4px',
                                    padding: `${theme.spacing(0.75)}px ${theme.spacing(1)}px`,
                                }}
                            >
                                {/* <PhotoLibraryOutlined /> */}
                                <span>
                                    {selectedPhotoIndex + 1} of {photos.length}
                                </span>
                            </span>
                        </div>
                    </>}
                    {_.isEmpty(photos) && <div style={{height: isXs ? '240px' : '384px'}}>
                        <StaticGoogleMaps
                            latitude={listing?.lat || listing?.latitude}
                            longitude={listing?.lon || listing?.longitude}
                            width={1000}
                            height={900}
                        />
                    </div>}
                </>
            ) : (
                <Skeleton animation="wave" variant="rect" width="100%" height="100%" />
            )}
        </div>
    );
}

const GalleryPreview = React.memo(function GalleryPreview({ listing, card, listpack, readOnly=false, PinspirationOverlayProps = {} }) {
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    const r = useResponsive();

    // hack to handle ListingExplorerModal api
    const wrappedListing = useMemo(()=> {
        return { listing_details: listing };
    }, [listing]);

    function trackEvent(source) {
        analytics.eventTrack('listpacks_consumer_listingpage_header_image_click', {
            address: listing.address,
            'listing id': listing.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
        });
        if(card) {
            analytics.eventTrack('robin_gallery_viewed', {
                card_id: card?.id,
                source,
            });
        }
    }

    function handlePhotoClick(photoIndex, source) {
        trackEvent(source);
        const qs = new URLSearchParams(location.search);
        if(photoIndex) {
            qs.set('index', photoIndex);
        }
        history.push({ pathname: `${match.url}/gallery`, search: qs.toString() });
    }

    function handleVirtualTourClick(source) {
        trackEvent(source);
        const qs = new URLSearchParams(location.search);
        qs.set('tab', '3dTour');
        history.push({ pathname: `${match.url}/gallery`, search: qs.toString() });
    }

    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            <div className="space-y-1">
                <div
                    style={{
                        height: r({ xs: '240px', sm: '384px' }),
                        cursor: 'pointer',
                    }}
                >
                    <GallerySwiper
                        listing={listing}
                        card={card}
                        listpack={listpack}
                        onSlideClick={(index)=> handlePhotoClick(index, 'rpp_hero_image')}
                        readOnly={readOnly}
                        PinspirationOverlayProps={PinspirationOverlayProps}
                    />
                </div>
                <div>
                    <ListingThumbnails
                        listing={listing}
                        card={card}
                        listpack={listpack}
                        onThumbnailClick={(index)=> handlePhotoClick(index, 'rpp_photo_thumbnail')}
                        onVirtualTourClick={()=> handleVirtualTourClick('rpp_photo_thumbnail')}
                    />
                </div>
            </div>
            {listing && (
                <ModalRoute
                    path={`${match?.url}/gallery`}
                    Component={ListingExplorerModal}
                    ComponentProps={{
                        TransitionComponent: Slide,
                        TransitionProps: {
                            direction: 'left',
                        },
                        card: wrappedListing,
                        PhotoGalleryProps: {
                            PinspirationOverlayProps,
                        },
                        onClose: ()=> history.push(match.url),
                    }}
                />
            )}
        </div>
    );
});

export default GalleryPreview;
