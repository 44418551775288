import React, { useState, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { Hotel, Bathtub, Build, LocalOffer, Texture, Straighten } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import LRButton from 'components/LRButton';
import addCommas from 'services/add-commas';
import LRTabs, { LRTab } from 'components/LRTabs';
import DetailsTab from './DetailsTab';
import dom from 'services/dom';
import theme from 'theme';
import { useStore } from 'mobx-store';

function lotSizeLabel(lotSize) {
    return lotSize >= 10000 ? 'acre lot' : 'sqft lot';
}

function lotSizeValue(lotSize) {
    return lotSize >= 10000
        ? Number(lotSize / 43560)
            .toFixed(2)
            .toString()
        : addCommas(lotSize);
}

function transformDetails(details) {
    let _transformedDetails = [];
    let _detail = [];

    for(let i = 0; i < details.length; i++) {
        _detail.push(details[i]);
        if(_detail.length == 2 || i === details.length - 1) {
            _transformedDetails.push(_detail);
            _detail = [];
        }
    }

    return _transformedDetails;
}

function getBasicDetails(listingDetails) {
    let _basicDetails = [];

    if(listingDetails.baths_half && listingDetails.baths_half > 0) {
        _basicDetails.push({
            label: 'Baths - Half',
            value: listingDetails.baths_half,
        });
    }

    if(listingDetails.baths_one_quarter && listingDetails.baths_one_quarter > 0) {
        _basicDetails.push({
            label: 'Baths - Quarter',
            value: listingDetails.baths_one_quarter,
        });
    }

    if(listingDetails.baths_full && listingDetails.baths_full > 0) {
        _basicDetails.push({
            label: 'Baths - Full',
            value: listingDetails.baths_full,
        });
    }

    if(listingDetails.listing_date || listingDetails.last_updated) {
        let date = listingDetails.listing_date;
        if(!date) {
            date = moment(listingDetails.last_updated)
                .format('YYYY-MM-DD');
        }
        _basicDetails.push({
            label: 'Days on market',
            value: dom(date),
        });
    }
    if(listingDetails.expenses && listingDetails.expenses.length > 0) {
        _basicDetails.push({
            label: 'Expenses',
            value: _.map(listingDetails.expenses, 'ExpenseCategory')
                .join(', '),
        });
    }
    if(listingDetails.listing_date) {
        _basicDetails.push({
            label: 'List date',
            value: moment(listingDetails.listing_date)
                .format('MM/DD/YY'),
        });
    }
    if(listingDetails.mls_number) {
        _basicDetails.push({
            label: 'MLS #',
            value: listingDetails.mls_number,
        });
    }
    if(listingDetails.type) {
        _basicDetails.push({
            label: 'Property type',
            value: listingDetails.type,
        });
    }
    if(listingDetails.sub_type) {
        _basicDetails.push({
            label: 'Property sub type',
            value: listingDetails.sub_type,
        });
    }
    if(listingDetails.active) {
        _basicDetails.push({
            label: 'Status',
            value: 'Active',
        });
    }

    if(listingDetails.municipality) {
        _basicDetails.push({
            label: 'Municipality',
            value: listingDetails.municipality,
        });
    }

    return transformDetails(_basicDetails);
}

function getInteriorDetails(listingDetails) {
    let _interiorDetails = [];

    if(listingDetails.appliances && listingDetails.appliances.length > 0) {
        _interiorDetails.push({
            label: 'Appliances',
            value: listingDetails.appliances.join(', '),
        });
    }
    if(listingDetails.has_attic) {
        _interiorDetails.push({
            label: 'Attic',
            value: 'Yes',
        });
    }
    if(listingDetails.has_ceiling_fan) {
        _interiorDetails.push({
            label: 'Ceiling Fan(s)',
            value: 'Yes',
        });
    }
    if(listingDetails.cooling && listingDetails.cooling.length > 0) {
        _interiorDetails.push({
            label: 'Cooling',
            value: listingDetails.cooling.join(', '),
        });
    }
    if(listingDetails.has_double_pane_windows) {
        _interiorDetails.push({
            label: 'Double Pane Windows',
            value: 'Yes',
        });
    }
    if(listingDetails.heating && listingDetails.heating.length > 0) {
        _interiorDetails.push({
            label: 'Heating',
            value: listingDetails.heating.join(', '),
        });
    }
    if(listingDetails.has_fireplace) {
        _interiorDetails.push({
            label: 'Fireplace',
            value: 'Yes',
        });
    }
    if(listingDetails.floor_coverings) {
        _interiorDetails.push({
            label: 'Floor Coverings',
            value: listingDetails.floor_coverings,
        });
    }
    if(listingDetails.has_jetted_bathtub) {
        _interiorDetails.push({
            label: 'Jetted Bath Tub',
            value: 'Yes',
        });
    }
    if(listingDetails.rooms) {
        _interiorDetails.push({
            label: 'Rooms',
            value: listingDetails.rooms,
        });
    }
    if(listingDetails.has_sauna) {
        _interiorDetails.push({
            label: 'Sauna',
            value: 'Yes',
        });
    }
    if(listingDetails.has_security_system) {
        _interiorDetails.push({
            label: 'Security System',
            value: 'Yes',
        });
    }
    if(listingDetails.has_skylight) {
        _interiorDetails.push({
            label: 'Skylight',
            value: 'Yes',
        });
    }
    if(listingDetails.floors) {
        _interiorDetails.push({
            label: 'Stories',
            value: listingDetails.floors,
        });
    }
    if(listingDetails.has_vaulted_ceiling) {
        _interiorDetails.push({
            label: 'Vaulted Ceiling',
            value: 'Yes',
        });
    }
    if(listingDetails.has_wet_bar) {
        _interiorDetails.push({
            label: 'Wet Bar',
            value: 'Yes',
        });
    }

    return transformDetails(_interiorDetails);
}

function getExteriorDetails(listingDetails) {
    let _exteriorDetails = [];

    if(listingDetails.parking_spaces) {
        _exteriorDetails.push({
            label: '# Parking Spaces',
            value: parseInt(listingDetails.parking_spaces),
        });
    }

    if(listingDetails.has_bbq_area) {
        _exteriorDetails.push({
            label: 'Barbecue Area',
            value: 'Yes',
        });
    }
    if(listingDetails.has_deck) {
        _exteriorDetails.push({
            label: 'Deck',
            value: 'Yes',
        });
    }
    if(listingDetails.has_garden) {
        _exteriorDetails.push({
            label: 'Garden',
            value: 'Yes',
        });
    }
    if(listingDetails.has_gated_entry) {
        _exteriorDetails.push({
            label: 'Gated Entry',
            value: 'Yes',
        });
    }
    if(listingDetails.has_hot_tub) {
        _exteriorDetails.push({
            label: 'Hot Tub',
            value: 'Yes',
        });
    }
    if(listingDetails.has_lawn) {
        _exteriorDetails.push({
            label: 'Lawn',
            value: 'Yes',
        });
    }
    if(listingDetails.has_patio) {
        _exteriorDetails.push({
            label: 'Patio',
            value: 'Yes',
        });
    }
    if((listingDetails.tags && listingDetails.tags.indexOf('with_pool') > -1) || listingDetails.has_pool) {
        _exteriorDetails.push({
            label: 'Pool',
            value: 'Yes',
        });
    }
    if(listingDetails.has_porch) {
        _exteriorDetails.push({
            label: 'Porch',
            value: 'Yes',
        });
    }
    if(listingDetails.roof_types && listingDetails.roof_types.length > 0) {
        _exteriorDetails.push({
            label: 'Roof',
            value: listingDetails.roof_types,
        });
    }
    if(listingDetails.has_rv_parking) {
        _exteriorDetails.push({
            label: 'RV Parking',
            value: 'Yes',
        });
    }
    if(listingDetails.has_sprinkler_system) {
        _exteriorDetails.push({
            label: 'Sprinkler System',
            value: 'Yes',
        });
    }
    if(listingDetails.view_types && listingDetails.view_types.length > 0) {
        _exteriorDetails.push({
            label: 'View',
            value: listingDetails.view_types.join(', '),
        });
    }
    if(listingDetails.is_waterfront) {
        _exteriorDetails.push({
            label: 'Waterfront',
            value: 'Yes',
        });
    }

    return transformDetails(_exteriorDetails);
}

function getOtherDetails(listingDetails) {
    let _otherDetails = [];

    if(listingDetails.has_disabled_access) {
        _otherDetails.push({
            label: 'Disabled Access',
            value: 'Yes',
        });
    }
    if(listingDetails.has_dock) {
        _otherDetails.push({
            label: 'Dock',
            value: 'Yes',
        });
    }
    if(listingDetails.has_elevator) {
        _otherDetails.push({
            label: 'Elevator',
            value: 'Yes',
        });
    }
    if(listingDetails.has_greenhouse) {
        _otherDetails.push({
            label: 'Greenhouse',
            value: 'Yes',
        });
    }
    if(listingDetails.has_pond) {
        _otherDetails.push({
            label: 'Pond',
            value: 'Yes',
        });
    }

    return transformDetails(_otherDetails);
}

function BasicDetails({ listing }) {
    const theme = useTheme();
    const data = listing ? [
        {
            label: 'beds',
            value: listing.beds || '—',
            icon: <Hotel />,
        },
        {
            label: 'baths',
            value: listing.baths || '—',
            icon: <Bathtub />,
        },
        {
            label: 'sqft',
            value: listing.home_size ? addCommas(listing.home_size) : '—',
            icon: <Straighten />,
        },
        {
            label: lotSizeLabel(listing.lot_size),
            value: listing.lot_size ? lotSizeValue(listing.lot_size) : '—',
            icon: <Texture />,
        },
        {
            label: 'year built',
            value: listing.year || '—',
            icon: <Build />,
        },
        {
            label: 'price/sqft',
            value:
                listing.price > 0 && listing.home_size > 0
                    ? `$${addCommas(Math.round(listing.price / listing.home_size))}`
                    : 'N/A',
            icon: <LocalOffer />,
        },
    ] : null;

    return (
        <Grid container spacing={2}>
            {listing && data.map(({ label, value, icon })=> (
                <Grid key={label} item xs={4} md={2}>
                    <div
                        className="space-y-2"
                        style={{
                            padding: theme.spacing(1.5),
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'rgb(246, 246, 246)',
                        }}
                    >
                        <Typography variant="h6">{value}</Typography>
                        <div>{label}</div>
                        <div>{icon}</div>
                    </div>
                </Grid>
            ))}
            {!listing && [...Array(6)].map((value, index)=> (
                <Grid key={index} item xs={4} md={2}>
                    <Skeleton variant="rect" animation="wave" height="117px" width="100%" />
                </Grid>
            ))}
        </Grid>
    );
}

function FullDetails({ listing, onContactAgentClick }) {
    const { ListpackStore } = useStore();
    const { listpack } = ListpackStore;
    const [selectedTab, setSelectedTab] = useState('basic');
    const [expanded, setExpanded] = useState(false);
    const basicDetails = getBasicDetails(listing);
    const interiorDetails = getInteriorDetails(listing);
    const exteriorDetails = getExteriorDetails(listing);
    const otherDetails = getOtherDetails(listing);
    const tabs = [
        {
            label: 'Basic',
            value: 'basic',
            visible: true,
        },
        {
            label: 'Interior',
            value: 'interior',
            visible: interiorDetails.length > 0,
        },
        {
            label: 'Exterior',
            value: 'exterior',
            visible: exteriorDetails.length > 0,
        },
        {
            label: 'Other',
            value: 'other',
            visible: otherDetails.length > 0,
        },
    ];
    const showContactAgentCTA = listing?.listing_source !== 'listhub' && listpack;

    const hasMoreRows = useMemo(function() {
        return basicDetails.length > 4 ||
            interiorDetails.length > 4 ||
            exteriorDetails.length > 4 ||
            otherDetails.length > 4;
    }, [basicDetails.length, interiorDetails.length, exteriorDetails.length, otherDetails.length]);

    return (
        <div>
            <LRTabs
                variant="fullWidth"
                value={selectedTab}
                onChange={(e, value)=> setSelectedTab(value)}
                style={{ borderBottom: '1px solid #ddd' }}
                textColor="primary"
            >
                {tabs
                    .filter((tab)=> tab.visible)
                    .map((tab)=> {
                        const label = (
                            <Typography
                                component="h3"
                                style={{
                                    color: '#000000',
                                }}
                            >
                                {tab.label}
                            </Typography>
                        );

                        return <LRTab key={tab.value} label={label} value={tab.value} />;
                    })}
            </LRTabs>
            {selectedTab === 'basic' && <DetailsTab data={basicDetails} expanded={expanded}/>}
            {selectedTab === 'interior' && <DetailsTab data={interiorDetails} expanded={expanded}/>}
            {selectedTab === 'exterior' && <DetailsTab data={exteriorDetails} expanded={expanded}/>}
            {selectedTab === 'other' && <DetailsTab data={otherDetails} expanded={expanded}/>}
            {expanded && showContactAgentCTA && (
                <div style={{marginTop: theme.spacing(4)}}>
                    <Typography variant="h6" align="center">
                        Want to know more about this property?
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5)}}>
                        <LRButton variant="contained" color="primary" onClick={_.partial(onContactAgentClick, 'property_details')}>
                            Contact agent
                        </LRButton>
                    </div>
                </div>
            )}
            <div style={{ textAlign: 'center' }}>
                {expanded && (
                    <LRButton
                        onClick={()=> setExpanded(false)}
                        style={{
                            color: '#1EC8E1',
                            marginTop: theme.spacing(3),
                        }}
                    >
                        View less
                    </LRButton>
                )}
                {!expanded && (hasMoreRows || showContactAgentCTA) && (
                    <LRButton
                        onClick={()=> setExpanded(true)}
                        style={{
                            color: '#1EC8E1',
                            marginTop: theme.spacing(3),
                        }}
                    >
                        View more
                    </LRButton>
                )}
            </div>
        </div>
    );
}

const Details = React.memo(function Details({ listing, onContactAgentClick }) {

    return (
        <div className="space-y-6">
            <Typography variant="h5">Property Details</Typography>
            {listing && (
                <>
                    <BasicDetails listing={listing} />
                    {<FullDetails listing={listing} onContactAgentClick={onContactAgentClick}/>}
                    
                </>
            )}
            {!listing && (
                <BasicDetails />
            )}
        </div>
    );
});

export default Details;
