// eslint-disable-next-line
import { Box, Fab, IconButton, Menu, MenuItem, Typography, ClickAwayListener, makeStyles } from '@material-ui/core';
import { ChatBubble, Close, Create, Delete, AddRounded, MoreHoriz, Visibility } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import { useTheme, withTheme } from '@material-ui/styles';
import Logger from 'js-logger';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState, useMemo, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Can, useAbility } from '../../../components/Ability';
import ListingImage from '../../../components/ListingImage';
import LRAvatar from '../../../components/LRAvatar';
import LRButton from '../../../components/LRButton';
import { useMenu, useResponsiveBreakpoint } from '../../../hooks';
import { useBoardCollaborations } from '../../../hooks/useBoardCollaborations';
import { useActiveBoard, useStore } from '../../../mobx-store';
import addCommas from '../../../services/add-commas';
import analytics from '../../../services/analytics';
import splitAddress from '../../../services/split-address';
import { observer } from 'mobx-react-lite';
import { useOnboarding } from '../../../components/Onboarding';
import { subject } from '@casl/ability';
import { StatusBadge } from '../StatusBadge';
import SchedulePrivateTourModal from '../../../components/SchedulePrivateTourModal';
import { CardDeleteModal } from '../../../components/CardDeleteModal';
import { TourRounded } from '../../../components/LRIcons';
import { ScheduleATourModal } from '../../../components/ScheduleATourModal';
import LRTooltip from 'components/LRTooltip';
import { useArchiveBoardCard } from 'hooks/data/board-card/useArchiveBoardCard';
import _, { partial, truncate } from 'lodash';
import { COLOR_CHARLIE, LEGACY_LANES_NAMES, PENDING_STATUSES, SOLD_STATUSES } from '../../../constants';
import { OverlayMoveMenu } from './OverlayMoveMenu';
import { useMoveCardOrListing } from 'hooks/useMoveCardOrListing';
import { useListingMLS } from 'hooks/data/listing/useListingMLS';
import Warning from 'components/LRIcons/warning/warning';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';
import simpleCypher from '../../../services/simple-cypher';

const logger = Logger.get('Card');

const pulseAnimation = keyframes`
    0% {
        transform: scale(0.50);
        opacity: 0;
    }
    50% {
        opacity: 1
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
`;

const StyledFab = withTheme(styled(Fab)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #ffffff;
    color: ${({ theme })=> theme.palette.lr_colors.grey_nero};
    background-color: #ffffff;

    .pulse-move-card-button &.card-move-button:after, .pulse-move-card-button &.card-move-button:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        border: 1px solid rgba(30, 200, 225, 0.5);
        top: -20px;
        right: -20px;
        bottom: -20px;
        left: -20px;
        transform: scale(0.50);
        opacity: 0;
        animation: ${pulseAnimation} 1.5s linear infinite;
    }

    .pulse-move-card-button &.card-move-button:before {
        animation-delay: 0.5s;
        border-width: 2px;
    }
`);




const StyledIconButton = withTheme(styled(IconButton)`
    .pulse-move-card-button &.card-move-button:after, .pulse-move-card-button &.card-move-button:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        border: 1px solid rgba(30, 200, 225, 0.5);
        top: -20px;
        right: -20px;
        bottom: -20px;
        left: -20px;
        transform: scale(0.50);
        opacity: 0;
        animation: ${pulseAnimation} 1.5s linear infinite;
    }

    .pulse-move-card-button &.card-move-button:before {
        animation-delay: 0.5s;
        border-width: 2px;
    }
`);

function UnspecifiedInlineWarning({iconStyle}){
    return (
        <div style={{display: 'flex'}}>
            <div style={{alignSelf: 'center', margin: '0 6px', ...iconStyle}}>
                <Warning color={COLOR_CHARLIE}/>
            </div>
            <Typography variant='body2'
                style={{
                    color: 'white',
                    fontStyle: 'normal',
                    fontSize: '10px',
                    fontWeight: 500,
                    letterSpacing: '0.25px',
                }}>Unspecified story data</Typography>
        </div>);
}

function MinimalCardLayout({ boardCard, listingMls, isNew, laneIdBadge, enableActions, listpack, unspecifiedStoriesWarning = false }) {

    const { LanesStore, boardsStore, GlobalSnackbarStore, UserStore } = useStore();
    const { name, listing_details, total_notes } = boardCard;
    const listingHomeSize = listing_details.home_size ? `${addCommas(listing_details.home_size)}` : null;
    const listingPrice = listing_details.price ? `$${addCommas(listing_details.price)}` : null;

    const theme = useTheme();
    const history = useHistory();
    const [addressLine1, addressLine2] = splitAddress(listing_details.address);
    const [city] = addressLine2?.split(',') || [];
    const { activeBoard } = boardsStore;
    const collaborators = activeBoard ? activeBoard.collaborators : [];

    const image = boardCard.hero_image_url || (listing_details.photos.length ? listing_details.photos[0] : undefined);
    return (
        <div
            style={{
                borderRadius: '4px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                overflow: 'hidden',
                background: '#fff',
                minWidth: '264px',
                minHeight: '177px'
            }}
            className={'tour-target-minimal-board-card'}
        >
            <Box
                position="absolute"
                height={1}
                width={1}
            >
                <ListingImage
                    style={{
                        height:'100%',
                        width: '100%'
                    }}
                    image={image}
                >
                    <div style={{position: 'absolute', top: '8px', left: '8px'}}>
                        <StatusBadge
                            listingDetails={listing_details}
                            isNew={isNew}
                            laneIdBadge={laneIdBadge}
                            isListpackCard={!!listpack}
                        />
                    </div>

                    {boardCard.user && !!collaborators.length && (
                        <div
                            style={{
                                position: 'absolute',
                                top: theme.spacing(1),
                                right: theme.spacing(1),
                                border: '2px solid',
                                borderColor: theme.palette.lr_colors.crest_white,
                                borderRadius: '100%'
                            }}
                        >
                            <LRAvatar
                                user={boardCard.user}
                                size="xsmall"
                            />
                        </div>
                    )}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            width: '100%',
                            height: '110px',
                            background: 'linear-gradient(rgb(26, 26, 26) 0%, rgba(26, 26, 26, 0) 100%)',
                            transform: 'rotate(-180deg)',
                        }}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            paddingLeft: theme.spacing(1),
                            paddingRight: theme.spacing(1),
                            bottom: theme.spacing(1),
                            color: '#FFF'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {total_notes > 0 && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ChatBubble
                                        style={{
                                            marginRight: '5px',
                                            display: 'inline-block',
                                            color: '#fff',
                                            width: '16px',
                                            height: '16px',
                                        }}
                                    />

                                    <Typography
                                        variant="body2"
                                        style={{ color: '#fff'}}
                                    >
                                        {total_notes}
                                    </Typography>
                                </div>
                            )}
                            <div />
                            {enableActions && (
                                <div>
                                    <MoreActions boardCard={boardCard} disableArchiveButton={false} history={history} />
                                </div>
                            )}
                        </div>

                        {unspecifiedStoriesWarning && <UnspecifiedInlineWarning iconStyle={{marginLeft:0}}/>}

                        <div>
                            <div>
                                <Box display={listing_details.beds ? 'inline-block' : 'none'} >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {listingPrice}&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                </Box>
                                <Box display={listing_details.beds ? 'inline-block' : 'none'} >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {listing_details.beds}&nbsp;
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                        }}
                                    >
                                        bed&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                </Box>

                                <Box display={listing_details.baths ? 'inline-block' : 'none'}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {listing_details.baths}&nbsp;
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                        }}
                                    >
                                        bath&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                </Box>

                                <Box display={listingHomeSize ? 'inline-block' : 'none'}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {listingHomeSize}&nbsp;
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                        }}
                                    >
                                        sqft
                                    </Typography>
                                </Box>

                                <Typography
                                    variant="body2"
                                    style={{
                                        fontWeight: '700',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                    }}
                                >
                                    {name || addressLine1}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{
                                        fontWeight: '700',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                    }}
                                >
                                    {addressLine2}
                                </Typography>
                                
                                {listing_details?.realtor?.company && (
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '10px',
                                            lineHeight: '14px',
                                            color: theme.palette?.lr_colors?.steak_sauce,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        Courtesy of {_.join(_.compact([listing_details?.realtor?.company, listingMls]), ' and ')}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </div>

                </ListingImage>

            </Box>
        </div>
    );

};

function CardOverlayMenu({
    showArchiveButton,
    onArchiveClick,
    menuOptions,
    handleMove,
}) {
    const [menuProps, handleOpenMenuClick, handleCloseMenu] = useMenu();
    const onboarding = useOnboarding();

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: 'rgba(26, 26, 26, 0.45)' }}
        >
            {!menuProps.open && (
                <Box width="18%" mr={2}>
                    <Box width="100%" height="0" pt="100%" position="relative">
                        <StyledFab>
                            <Visibility />
                        </StyledFab>
                    </Box>
                </Box>
            )}
            <Box
                width="18%"
                mr={2}
                onClick={(e)=> {
                    e.preventDefault();
                    // if(onboarding.isFirstRunOnboarding) {
                    //     analytics.eventTrack('robin_fre_onboarding_move_card_icon_click');
                    // }
                    handleOpenMenuClick(e);
                }}
            >
                <Box width="100%" height="0" pt="100%" position="relative">
                    <StyledFab className="card-move-button">
                        <AddRounded />
                    </StyledFab>
                </Box>
            </Box>

            {!menuProps.open && showArchiveButton && (
                <Box width="18%" onClick={onArchiveClick}>
                    <Box width="100%" height="0" pt="100%" position="relative">
                        <StyledFab>
                            <Delete />
                        </StyledFab>
                    </Box>
                </Box>
            )}
            {menuProps.open && (
                <OverlayMoveMenu
                    menuOptions={menuOptions}
                    handleMove={handleMove}
                    menuProps={menuProps}
                    handleCloseMenu={handleCloseMenu}
                />
            )}
        </Box>
    );
}

function MoreActions({
    disableArchiveButton = true,
    boardCard,
    history,
    onMakeAnOfferClick = ()=> {},
    onMoveToChange = ()=> {},
    onPersonalizeClick = ()=> {},
    onDeleteClick = ()=> {},
    canMove = true,
}) {
    const match = useRouteMatch();
    const laneId = match.params.laneId;
    const { LanesStore, boardsStore, GlobalSnackbarStore, UserStore } = useStore();
    const { name, listing_details, total_notes } = boardCard;
    const theme = useTheme();
    const [addressLine1, addressLine2] = splitAddress(listing_details.address);
    const [city] = addressLine2?.split(',') || [];
    const [menuProps, handleOpenMenuClick, handleCloseMenu] = useMenu();
    const menuOptions = disableArchiveButton ? LanesStore?.lanes : LanesStore?.lanes.filter((lane)=> {
        if(!boardCard.laneId && !laneId){
            return lane;
        } else if((lane.id !== boardCard.laneId && boardCard.laneId) || (lane.id !== laneId && laneId)){
            return lane;
        }
    });
    const { activeBoard } = boardsStore;
    const collaborators = activeBoard ? activeBoard.collaborators : [];
    const isXs = useResponsiveBreakpoint('xs');
    const [showCardOverlay, setShowCardOverlay] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const onboarding = useOnboarding();
    const {
        addBoardCollaboration,
    } = useBoardCollaborations();
    const laneName = LanesStore.lanesById[boardCard.columnId] ?
        LanesStore.lanesById[boardCard.columnId].name : 'Deleted Homes';

    // Functions

    function handleMove(e, lane, source) {
        if(boardCard.id) {
            onMoveCardToSelected(e, lane, source);
        } else {
            onMoveListingToSelected(e, lane, source);
        }

        if(onboarding?.isActive && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding)) {
            onboarding.userMovedCard = true;
        }
    }

    async function onMoveListingToSelected(e, lane, source) {
        const newBoardCard = await activeBoard.createBoardCard(boardCard, lane.id);
        analytics.eventTrack('robin_card_moved', {
            Source: source,
            original_column: 'Your searches',
            moved_to_column: LanesStore.lanesById[lane.id].name,
            card_id: newBoardCard.id,
            board_id: newBoardCard.boardId,
            address: newBoardCard?.listing_details?.address
        });
        createMovedToBoardCollaboration(newBoardCard);
        handleCloseMenu(e);
    }

    function onMoveCardToSelected(e, lane, source) {
        handleCloseMenu(e);
        onMoveToChange(e, lane);

        const isArchived = boardCard.archived;

        analytics.eventTrack('robin_card_moved', {
            Source: source,
            original_column: laneName,
            moved_to_column: LanesStore.lanesById[lane.id].name,
            card_id: boardCard.id,
            board_id: boardCard.boardId,
            address: boardCard?.listing_details?.address
        });
        createMovedToBoardCollaboration(boardCard, laneName, LanesStore.lanesById[lane.id].name);

        setTimeout(()=> {
            LanesStore.moveListing(boardCard.id, boardCard.columnId, boardCard.columnIndex, lane.id, 0);
        });

        if(isArchived) {
            if(activeBoard?.notArchivedBoardCards?.length === 0) {
                history.push('/boards');
            }
            analytics.eventTrack('robin_card_unarchived', {
                source: 'card_ellipsis',
                card_id: boardCard.id,
                board_id: boardCard.boardId,
                listing_id: boardCard.content.listing_id,
                address: boardCard.listing_details.address,
            });
        }
    }

    function createMovedToBoardCollaboration(boardCard, sourceLaneName, toLaneName) {
        const fromColumn = LEGACY_LANES_NAMES[_.snakeCase(sourceLaneName)];
        const toColumn = LEGACY_LANES_NAMES[_.snakeCase(toLaneName)];

        const collaborationData = {
            boardId: boardCard.boardId,
            itemId: boardCard.id,
            content: {
                fromColumn: fromColumn ? fromColumn : sourceLaneName,
                toColumn: toColumn ? toColumn : toLaneName,
            },
            type: 'moved_card'
        };

        addBoardCollaboration(collaborationData);
    }

    function _onPersonalizeClick(e) {
        handleCloseMenu(e);
        onPersonalizeClick(e);

        history.push(`/boards/name-home/${boardCard.id}?source=minimal-ellipsis-menu`);
    }

    function handleOnDeleteConfirm(message) {
        setShowConfirmModal(false);
        boardCard.laneId = '-1';
        boardCard.archive();

        let cardInfo = null;
        if(boardCard?.name?.length > 0) {
            cardInfo = boardCard.name;
        } else {
            if(boardCard?.listing_details?.address) {
                const [address1, address2] = splitAddress(boardCard.listing_details.address);
                cardInfo = address1;
            }
        }

        // create the board collaboration
        const data = {
            model: 'BoardCard',
            itemId: boardCard?.id,
            boardId: activeBoard.id,
            content: {
                message: message,
                cta: `/boards/archivedListings/${boardCard?.id}`
            },
            type: 'deleted_card',
        };
        addBoardCollaboration(data);

        GlobalSnackbarStore.show(`${cardInfo} has been moved to your deleted homes`, {
            autoHideDuration: 2000,
        });

        analytics.eventTrack('robin_card_archived', {
            source: 'card_ellipsis',
            card_id: boardCard.id,
            board_id: boardCard.boardId,
            listing_id: boardCard.content.listing_id,
            address: boardCard.listing_details.address,
        });

        onDeleteClick(boardCard);
    };

    function _onDeleteClick(e) {
        e.preventDefault();
        e.stopPropagation();
        handleCloseMenu(e);
        if(![...SOLD_STATUSES, ...PENDING_STATUSES].includes(listing_details.listing_status.toLowerCase())) {
            setShowConfirmModal(true);
        } else {
            handleOnDeleteConfirm();
        }
    }

    function _onMakeAnOfferClick(e) {
        e.preventDefault();
        onMakeAnOfferClick(e);
        analytics.eventTrack('make_offer_button_click', {
            Source: 'make_an_offer_column',
            card_id: boardCard.id,
            board_id: boardCard.boardId,
            address: boardCard?.listing_details?.address
        });
        history.replace(`/boards/submit-offer/${boardCard.id}`);
    }

    return (
        <div>
            <IconButton onClick={handleOpenMenuClick} disabled={!canMove} style={{padding: 0, color: '#FFF'}}>
                <MoreHoriz />
            </IconButton>

            <Menu {...menuProps}>
                <MenuItem style={{ cursor: 'default', background: 'transparent' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                marginRight: theme.spacing(1),
                                width: '16px',
                                height: '20px',
                            }}
                        >
                            <AddRounded style={{ width: '16px', height: '16px' }} />
                        </div>
                        <Typography variant="body2" style={{ fontWeight: 500 }}>
                            Move to:
                        </Typography>
                    </div>
                </MenuItem>

                {menuOptions.map((option)=> (
                    <MenuItem
                        key={option.id}
                        onClick={(e)=> {
                            e.preventDefault();
                            handleMove(e, option, 'board_ellipsis');
                            handleCloseMenu(e);

                            if(isXs) {
                                const cardInfo = boardCard.archived ? (name || addressLine1) : addressLine1;

                                GlobalSnackbarStore.show(`${cardInfo} has been moved to ${option.name}`, {
                                    autoHideDuration: 2000,
                                });
                            }
                        }}
                        style={{ paddingLeft: theme.spacing(7) }}
                    >
                        <Typography variant="body2">{option.name}</Typography>
                    </MenuItem>
                ))}

                {boardCard.id && !disableArchiveButton && (
                    <MenuItem onClick={_onPersonalizeClick}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    marginRight: theme.spacing(1),
                                    width: '16px',
                                    height: '20px',
                                }}
                            >
                                <Create style={{ width: '16px', height: '16px' }} />
                            </div>
                            <Typography variant="body2">Personalize</Typography>
                        </div>
                    </MenuItem>
                )}

                {boardCard.id && !disableArchiveButton && (
                    <MenuItem onClick={_onDeleteClick}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    marginRight: theme.spacing(1),
                                    width: '16px',
                                    height: '20px',
                                    fontSize: '1rem',
                                }}
                            >
                                🗑
                            </div>
                            <Typography variant="body2">Delete</Typography>
                        </div>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}

// Ensures menu popover appears on top of the shepherd elemenets (onboarding library)
const useMenuPopoverStyles = makeStyles({
    root: {
        zIndex: '10000 !important',
    }, // a style rule
});

function ScheduleTourButton({homeTourData, disableActionButton,  handleScheduleTour, agent}){
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const [open, setOpen] = React.useState(false);


    const handle = (event)=> {
        event.stopPropagation();
        if(open){
            handleScheduleTour(event);
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const handleTooltipClose = ()=> {
        setOpen(false);
    };

    if(homeTourData && homeTourData.isScheduled){
        const time = moment(homeTourData.isScheduled).format('HH:mm');
        const date = moment(homeTourData.isScheduled).format('dddd MMMM DD');
        const title = `Tour scheduled for ${date} starting at ${time}.  Please contact ${agent.first_name} ${agent.last_name} if you have any questions.`;
        if(isMobile){
            return (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <LRTooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={title}
                            hideArrow={false}
                        >
                            <LRButton variant="outlined" fullWidth  onClick={handle} disabled={disableActionButton}>
                                <Box display="flex" alignItems="center">
                                    <CheckIcon color="secondary"/>
                                    <Typography component="div" variant="body2">
                                        <Box component="span" marginLeft="4px">Tour scheduled</Box>
                                    </Typography>
                                </Box>
                            </LRButton>
                        </LRTooltip>
                    </div>
                </ClickAwayListener>
            );
        } else {
            return (
                <LRTooltip
                    hideArrow={false}
                    title={title}
                >
                    <LRButton variant="outlined" fullWidth  onClick={handleScheduleTour} disabled={disableActionButton}>
                        <Box display="flex" alignItems="center">
                            <CheckIcon color="secondary"/>
                            <Typography component="div" variant="body2">
                                <Box component="span" marginLeft="4px">Tour scheduled</Box>
                            </Typography>
                        </Box>
                    </LRButton>
                </LRTooltip>
            );
        }

    } else if(homeTourData){
        const title = `${agent?.first_name} ${agent?.last_name} will let you know once the tour has been scheduled`;
        if(isMobile){
            return (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <LRTooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={title}
                            hideArrow={false}
                        >
                            <LRButton variant="outlined" color="default" fullWidth onClick={handle} disabled={disableActionButton}>Tour requested</LRButton>
                        </LRTooltip>
                    </div>
                </ClickAwayListener>
            );
        } else {
            return (
                <LRTooltip
                    hideArrow={false}
                    title={title}
                >
                    <LRButton variant="outlined" color="default" fullWidth onClick={handleScheduleTour} disabled={disableActionButton}>Tour requested</LRButton>
                </LRTooltip>
            );
        }

    } else {
        return (
            <LRButton variant="contained" color="primary" fullWidth onClick={handleScheduleTour} disabled={disableActionButton}>Schedule a tour</LRButton>
        );
    }
}
function Card({
    variant = 'board', // makeAnOffer || board || expanded || addHomeToTour
    boardCard,
    listing_details = null,
    cardOverlayMenuCanPassThrough = false,
    onMakeAnOfferClick = ()=> {},
    onAddHomeToTourClick = ()=> {},
    onMoveToChange = ()=> {},
    onPersonalizeClick = ()=> {},
    onDeleteClick = ()=> {},
    disableArchiveButton,
    canMove = true,
    enableActions = true,
    isNew,
    laneIdBadge,
    selected = false,
    hovering = false,
    listpack,
    disableActionButton = false,
    showUnspecifiedListingsWarning = false,
    ...props
}) {
    const show = !!~['board', 'makeAnOffer', 'expanded', 'addHomeToTour'].indexOf(variant);
    const history = useHistory();
    const match = useRouteMatch();
    const laneId = match?.params.laneId;
    const { LanesStore, GlobalSnackbarStore, UserStore } = useStore();
    const { name, total_notes } = boardCard;
    listing_details = listing_details || boardCard.listing_details;
    const { data: listingMLS, isFetched: isFetchedListingMLS } = useListingMLS(listing_details);
    const unspecifiedStoriesWarning = showUnspecifiedListingsWarning && (listing_details.floors === 0 || listing_details.floors === '' || listing_details.floors === null);
    const listingStatus = listing_details?.listing_status?.toLowerCase();
    const isListingOffMarket = SOLD_STATUSES.includes(listingStatus);
    const isListingPending = PENDING_STATUSES.includes(listingStatus);
    const theme = useTheme();
    const [addressLine1, addressLine2] = splitAddress(listing_details.address);
    const [city] = addressLine2?.split(',') || [];
    const [menuProps, handleOpenMenuClick, handleCloseMenu] = useMenu();
    const menuOptions = disableArchiveButton ? LanesStore?.lanes : LanesStore?.lanes.filter((lane)=> {
        if(!boardCard.laneId && !laneId){
            return lane;
        } else if((lane.id !== boardCard.laneId && boardCard.laneId) || (lane.id !== laneId && laneId)){
            return lane;
        }
    });
    const activeBoard = useActiveBoard();
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const collaborators = activeBoard ? activeBoard.collaborators : [];
    const isXs = useResponsiveBreakpoint('xs');
    const [showCardOverlay, setShowCardOverlay] = useState(false);
    const [menuIconEl, setMenuIconEl] = useState();
    const {
        boardCollaborations: hometours
    } = useBoardCollaborations(activeBoard?.id);
    const {
        boardCollaborations,
        addBoardCollaboration,
    } = useBoardCollaborations(boardCard.id);
    const laneName = LanesStore.lanesById[boardCard.columnId] ?
        LanesStore.lanesById[boardCard.columnId].name : 'Deleted Homes';
    const menuPopoverClasses = useMenuPopoverStyles();
    const onboarding = useOnboarding();
    const listingPrice = listing_details.price ? `$${addCommas(listing_details.price)}` : null;
    const listingHomeSize = listing_details.home_size ? `${addCommas(listing_details.home_size)}` : null;
    const isVariantExpanded = variant === 'expanded';
    const agent = UserStore.user?.affiliate;
    const [schedulePrivateTourModalOpen, setSchedulePrivateTourModalOpen] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { mutateAsync: archiveCard, isLoading: isDeleting } = useArchiveBoardCard();
    const ability = useAbility();
    const canEditBoardCards = ability.can('edit', 'BoardCard');
    const displayLogo = useMemo(()=> {
        return _.get(listingMLS, '[0].logo_display_required') && _.get(listingMLS, '[0].logo');
    }, [listingMLS]);
    const showMlsName = useMemo(()=> {
        return _.get(listingMLS, '[0].add_mls_name_to_listing_agent_info', false);
    }, [listingMLS]);
    const isScheduled = useMemo(()=> {
        return _.some(boardCollaborations, ['type', 'offer_submitted']);

    }, [boardCollaborations]);
    const homeTourData = useMemo(()=> {
        if(hometours?.length > 0 && (variant === 'scheduleTour' || laneId === '2' )){
            let data = null;
            for(const tour of hometours) {
                if(tour.content?.boardCardIds?.includes(boardCard.id) && tour?.content?.scheduled_date_time && moment().isSameOrBefore(tour?.content.scheduled_date_time) && !tour.content.cancelled) {
                    data = {id: tour.id, isScheduled: tour?.content?.scheduled_date_time};
                    break;
                } else if(!data && !tour?.content?.scheduled_date_time && tour.content?.boardCardIds?.includes(boardCard.id) && !tour?.content?.cancelled ){
                    data = {id: tour.id, isScheduled: false};
                }
            }
            return data;
        } else {
            return null;
        }
    }, [hometours, variant, boardCard.id, boardCard.columnId]);
    const heroImage = useMemo(()=> {
        return boardCard.hero_image_url || (listing_details.photos.length ? listing_details.photos[0] : undefined);
    }, [boardCard.hero_image_url, listing_details.photos]);
    const hasMenu = (!!~['board', 'makeAnOffer', 'scheduleTour'].indexOf(variant) || (isXs && isVariantExpanded && enableActions));
    const moveCardOrListing = useMoveCardOrListing('move_to_board_button');
    const isListingSold = SOLD_STATUSES.includes(listing_details?.listing_status?.toLowerCase());

    // Functions
    function trackElipsisCTA(source) {
        analytics.eventTrack('robin_card_ellipsis_menu_click', {
            Source: source
        });
    }

    function onHandleMove(e, lane, source) {
        if(boardCard.id) {
            onMoveCardToSelected(e, lane, source);
        } else {
            onMoveListingToSelected(e, lane, source);
            trackElipsisCTA(_.snakeCase(`move to ${lane?.name}`));
        }

        if(onboarding?.isActive && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding)) {
            onboarding.userMovedCard = true;
        }
    }

    function onMoveListingToSelected(e, lane, source) {
        moveCardOrListing(
            boardCard,
            lane,
            'Your searches',
            (boardCard)=> {
                analytics.eventTrack('robin_card_moved', {
                    Source: source,
                    original_column: 'Your searches',
                    moved_to_column: LanesStore.lanesById[lane.id].name,
                    card_id: boardCard.id,
                    board_id: boardCard.boardId,
                    address: boardCard?.listing_details?.address
                });
                trackElipsisCTA(_.snakeCase(`move to ${lane?.name}`));
                handleCloseMenu(e);
            }
        );
    }

    function onMoveCardToSelected(e, lane, source) {
        const isArchived = boardCard.archived;

        moveCardOrListing(
            boardCard,
            lane,
            'Your searches',
            (boardCard)=> {
                analytics.eventTrack('robin_card_moved', {
                    Source: source,
                    original_column: laneName,
                    moved_to_column: LanesStore.lanesById[lane.id].name,
                    card_id: boardCard.id,
                    board_id: boardCard.boardId,
                    address: boardCard?.listing_details?.address
                });
                handleCloseMenu(e);
                onMoveToChange(e, lane);

                if(isArchived) {
                    analytics.eventTrack('robin_card_unarchived', {
                        source: 'card_hover_menu',
                        card_id: boardCard.id,
                        board_id: boardCard.boardId,
                        listing_id: boardCard.content.listing_id,
                        address: boardCard.listing_details.address,
                    });
                }
            }
        );
    }

    function _onScheduleTourClick(e) {
        trackElipsisCTA('schedule_a_tour');
        handleCloseMenu(e);
        history.push({
            pathname: '/boards/schedule-a-tour',
            state: {
                boardCardId: boardCard.id,
                source: 'card_ellipsis_menu'
            },
        });
        // setSchedulePrivateTourModalOpen(true);
    }

    function _onPersonalizeClick(e) {
        handleCloseMenu(e);
        onPersonalizeClick(e);
        trackElipsisCTA('personalize');

        history.push(`/boards/name-home/${boardCard.id}?source=ellipsis-menu`);
    }

    async function handleOnDeleteConfirm(message) {
        boardCard.laneId = '-1';
        onDeleteClick(boardCard);
        archiveCard(boardCard);

        let cardInfo = null;
        if(boardCard?.name?.length > 0) {
            cardInfo = boardCard.name;
        } else {
            if(boardCard?.listing_details?.address) {
                const [address1, address2] = splitAddress(boardCard.listing_details.address);
                cardInfo = address1;
            }
        }

        if(message && message.trim().length > 0) {
            // add a board collaboration note
            const comment = {
                model: 'BoardCard',
                itemId: boardCard.id,
                boardId: activeBoardId,
                content: {
                    message,
                    suppress_notifications: true,
                },
                type: 'comment',
                visibleToAffiliate: true,
                visibleToLO: false,
            };
            addBoardCollaboration(comment);
        }
        // create the board collaboration
        const data = {
            model: 'BoardCard',
            itemId: boardCard?.id,
            boardId: activeBoard.id,
            content: {
                message: message,
                cta: `/boards/archivedListings/${boardCard?.id}`
            },
            type: 'deleted_card',
        };
        addBoardCollaboration(data);

        GlobalSnackbarStore.show(`${cardInfo} has been moved to your deleted homes`, {
            autoHideDuration: 2000,
        });

        analytics.eventTrack('robin_card_archived', {
            source: 'card_ellipsis',
            card_id: boardCard.id,
            board_id: boardCard.boardId,
            listing_id: boardCard.content.listing_id,
            address: boardCard.listing_details.address,
        });


    };

    function _onDeleteClick(e, source = 'card_ellipsis') {
        trackElipsisCTA('delete');
        handleCloseMenu(e);
        if(![...SOLD_STATUSES, ...PENDING_STATUSES].includes(listing_details.listing_status.toLowerCase())) {
            setShowConfirmModal(true);
        } else {
            handleOnDeleteConfirm();
        }
    }

    function _onMakeAnOfferClick(e) {
        e.preventDefault();
        e.stopPropagation();
        onMakeAnOfferClick(e);
        analytics.eventTrack('make_offer_button_click', {
            Source: 'want_to_buy_column',
            card_id: boardCard.id,
            board_id: boardCard.boardId,
            address: boardCard?.listing_details?.address
        });
        history.replace(`/boards/submit-offer/${boardCard.id}`);
    }

    function handleScheduleTour(e) {
        e.preventDefault();
        e.stopPropagation();

        if(homeTourData && homeTourData?.id){

            const eventTrack = (homeTourData.isScheduled) ? 'tour_scheduled_card_button_click' : 'tour_requested_card_button_click';
            analytics.eventTrack(eventTrack, {
                Source: 'want_to_see_column',
                card_id: boardCard.id,
                board_id: boardCard.boardId,
                address: boardCard?.listing_details?.address
            });
            history.replace(`/boards/scheduled-tours/${homeTourData.id}`);
        } else {
            analytics.eventTrack('schedule_a_tour_card_button_click', {
                Source: 'want_to_see_column',
                card_id: boardCard.id,
                board_id: boardCard.boardId,
                address: boardCard?.listing_details?.address
            });
            history.push({
                pathname: '/boards/schedule-a-tour',
                state: {
                    boardCardId: boardCard.id,
                    source: 'want_to_see_column'
                },
            });
        }
    }

    function _onAddHomeToTourClick(e) {
        e.preventDefault();
        e.stopPropagation();

        onAddHomeToTourClick(boardCard, e);
        // analytics.eventTrack('add_home_to_tour_button_click', {
        //     Source: 'make_an_offer_column',
        //     card_id: boardCard.id,
        //     board_id: boardCard.boardId,
        //     address: boardCard?.listing_details?.address
        // });
    }

    if(variant === 'minimal') {
        return (
            <MinimalCardLayout
                boardCard={boardCard}
                listing_details={listing_details}
                listingMls={showMlsName ? _.get(listingMLS, '[0].market_name') : null}
                total_notes={total_notes}
                isNew={isNew}
                laneIdBadge={laneIdBadge}
                enableActions={enableActions}
                listpack={listpack}
                unspecifiedStoriesWarning={unspecifiedStoriesWarning}
            />
        );
    }


    const overlayImageContent = ()=> {
        return <>
            <div style={{position: 'absolute', top: '10px', left: '16px'}}>
                <StatusBadge
                    listingDetails={listing_details}
                    isNew={isNew}
                    laneIdBadge={laneIdBadge}
                    isListpackCard={!!listpack}
                />
            </div>

            {boardCard.last_moved_by && collaborators.length > 1 && (
                <div
                    style={{
                        position: 'absolute',
                        top: theme.spacing(1),
                        right: theme.spacing(2),
                        border: '2px solid',
                        borderColor: theme.palette.lr_colors.crest_white,
                        borderRadius: '100%'
                    }}
                >
                    <LRAvatar
                        user={boardCard.last_moved_by}
                        size="xsmall"
                    />
                </div>
            )}

            <div
                style={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    height: '30px',
                    background: 'linear-gradient(rgb(26, 26, 26) 0%, rgba(26, 26, 26, 0) 100%)',
                    transform: 'rotate(-180deg)',
                }}
            />

            <Can I="read" this={subject('BoardCollaboration', {type: 'comment'})}>
                {!!~['board', 'makeAnOffer', 'expanded', 'addHomeToTour'].indexOf(variant) && total_notes > 0 && (
                    <div
                        style={{
                            position: 'absolute',
                            left: theme.spacing(2),
                            bottom: theme.spacing(1),
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <ChatBubble
                            style={{
                                marginRight: '5px',
                                display: 'inline-block',
                                color: '#fff',
                                width: '16px',
                                height: '16px',
                            }}
                        />

                        <Typography variant="body2" style={{ color: '#fff', fontWeight: '500' }}>
                            {total_notes}
                        </Typography>
                    </div>
                )}
            </Can>

            {displayLogo && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: theme.spacing(1),
                        right: theme.spacing(1),
                    }}
                >
                    <img
                        src={displayLogo}
                        style={{
                            maxWidth: 40,
                        }}
                        alt="mls logo"
                    />
                </div>
            )}

            {unspecifiedStoriesWarning && 
                <div style={{position: 'absolute', bottom: '8px', left: '8px'}}>
                    <UnspecifiedInlineWarning/>
                </div>}
        </>;
    };

    return (
        <>
            <div onClick={(e)=> { e.preventDefault(); e.stopPropagation(); }}>
                <CardDeleteModal open={showConfirmModal} onClose={()=> { setShowConfirmModal(false);}} onConfirm={handleOnDeleteConfirm} />
            </div>
            <div
                style={{
                    borderRadius: '4px',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                    overflow: 'hidden',
                    background: '#fff',
                    height: '100%'
                }}
                className={`tour-target-${variant}-board-card tour-target-board-card`}
                onClick={(e)=> {
                    const { id: listingId } = simpleCypher.decode(boardCard.listing_details.id) || {};
                    analytics.eventTrack('robin_searchpage_listing_card_clicked', {
                        listing_id: listingId,
                        address: boardCard.listing_details.address,
                    });

                    if(onboarding?.isAddNoteOnboarding && onboarding.preventAction) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                {...props}
            >
                {
                    (selected || (hovering && variant === 'addHomeToTour')) && (
                        <div
                            style={{
                                position: 'absolute',
                                pointerEvents: 'none',
                                zIndex: 1,
                                border: '2px solid #FF6259',
                                top:'-1px',
                                right:'-1px',
                                bottom:'-1px',
                                left:'-1px',
                                borderRadius: '6px'
                            }}
                        > </div>
                    )
                }
                {/* {schedulePrivateTourModalOpen && (
                    <Box onClick={(e)=> e.preventDefault()}>
                        <SchedulePrivateTourModal
                            open={schedulePrivateTourModalOpen}
                            onClose={()=> setSchedulePrivateTourModalOpen(false)}
                            card={boardCard}
                            agent={agent}
                        />
                    </Box>
                )} */}
                <Box
                    position="relative"
                    onMouseEnter={()=> setShowCardOverlay(true)}
                    onMouseLeave={()=> setShowCardOverlay(false)}
                >
                    {heroImage && <ListingImage
                        style={{
                            paddingBottom: variant === 'expanded' ? '57.39%' : '51.52%',
                            position: 'relative',
                        }}
                        image={heroImage}
                    >
                        {overlayImageContent()}
                    </ListingImage>}

                    {!heroImage &&
                    <div style={{width: '100%', height: isXs || variant === 'expanded' ? 200 : 136}}>
                        <StaticGoogleMaps
                            latitude={listing_details?.lat || listing_details?.latitude}
                            longitude={listing_details?.lon || listing_details?.longitude}
                            width={500}
                            height={400}
                        >
                        </StaticGoogleMaps>
                    </div>}

                    {enableActions && showCardOverlay && !onboarding?.isAddNoteOnboarding && !onboarding?.isIdeaBoardOnboarding && !!~['expanded'].indexOf(variant) && (
                        <Can I="edit" a="BoardCard" passThrough={cardOverlayMenuCanPassThrough}>
                            <Box position="absolute" top={0} left={0} height="100%" width="100%">
                                <CardOverlayMenu
                                    showArchiveButton={!disableArchiveButton && !!boardCard.id}
                                    onArchiveClick={(e)=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        _onDeleteClick(e, 'card_hover_menu');
                                    }}
                                    menuOptions={menuOptions.filter((lane)=> lane.columnId !== boardCard?.columnId || boardCard.archived)}
                                    handleMove={onHandleMove}
                                />
                            </Box>
                        </Can>
                    )}
                </Box>

                <div
                    style={{
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                        paddingBottom: (!!~['board', 'addHomeToTour'].indexOf(variant) && theme.spacing(0.8)) || theme.spacing(1.5),
                        paddingTop:
                            (!!~['board', 'makeAnOffer', 'addHomeToTour'].indexOf(variant) && theme.spacing(0.2)) || theme.spacing(1.5),
                        color: theme.palette?.lr_colors?.grade
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                maxWidth: '80%',
                            }}
                        >
                            <Typography variant="h6">
                                {listingPrice}&nbsp;&nbsp;&nbsp;
                            </Typography>
                        </div>

                        {hasMenu && (
                            <div
                                style={{
                                    marginRight: -theme.spacing(1),
                                }}
                            >

                                <StyledIconButton
                                    className="card-move-button"
                                    onClick={handleOpenMenuClick}
                                    disabled={
                                        !canEditBoardCards || !canMove || onboarding.isAddNoteOnboarding || onboarding.isIdeaBoardOnboarding
                                    }
                                    ref={setMenuIconEl}
                                >
                                    <MoreHoriz />
                                </StyledIconButton>

                                <Menu {...menuProps} PopoverClasses={menuPopoverClasses}>
                                    <Box width={'224px'}>
                                        <Box mb={[-1, 0]} mt={[-1, 0]}>
                                            <MenuItem
                                                style={{
                                                    cursor: 'default',
                                                    background: 'transparent',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: theme.spacing(1),
                                                            width: '16px',
                                                            height: '20px',
                                                        }}
                                                    >
                                                        <AddRounded style={{ width: '16px', height: '16px' }} />
                                                    </div>
                                                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                        Move to:
                                                    </Typography>
                                                </div>
                                            </MenuItem>
                                        </Box>

                                        {menuOptions.map((option)=> (
                                            <Box key={option.id} mb={[-1, 0]}>
                                                <MenuItem
                                                    onClick={(e)=> {
                                                        e.preventDefault();
                                                        onHandleMove(e, option, 'board_ellipsis');
                                                        handleCloseMenu(e);

                                                        if(isXs) {
                                                            const cardInfo = name || addressLine1;

                                                            GlobalSnackbarStore.show(`${cardInfo} has been moved to ${option.name}`, {
                                                                autoHideDuration: 2000,
                                                            });
                                                        }

                                                        const { stepId } = onboarding;
                                                        const shouldAdvanceOnboarding = ['highlight-listpack-listing-card'].includes(stepId);
                                                        if((onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && shouldAdvanceOnboarding) {
                                                            UserStore.updateUser({ meta: { async_onboarding: { moved_board_cards: true } } });
                                                            onboarding.tour.show('free-step', true);
                                                            analytics.eventTrack('robin_onboarding_add_home_from_saved_search_done', {
                                                                source: 'saved-search'
                                                            });
                                                        }
                                                    }}
                                                    style={{ paddingLeft: theme.spacing(7)}}
                                                >
                                                    <Typography variant="body2">{option.name}</Typography>
                                                </MenuItem>
                                            </Box>
                                        ))}

                                        {boardCard.id && !disableArchiveButton && (
                                            <Can I="create" a="HomeTour">
                                                <Box mb={[-1, 0]}>
                                                    <MenuItem
                                                        onClick={_onScheduleTourClick}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginRight: theme.spacing(1),
                                                                    width: '16px',
                                                                    height: '20px',
                                                                }}
                                                            >
                                                                <TourRounded style={{ width: '16px', height: '16px' }} />
                                                            </div>
                                                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                                Schedule a tour
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                </Box>
                                            </Can>
                                        )}

                                        {boardCard.id && !disableArchiveButton && (
                                            <Box mb={[-1, 0]}>
                                                <MenuItem onClick={_onPersonalizeClick} >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                marginRight: theme.spacing(1),
                                                                width: '16px',
                                                                height: '20px',
                                                            }}
                                                        >
                                                            <Create style={{ width: '16px', height: '16px' }} />
                                                        </div>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                            Personalize
                                                        </Typography>
                                                    </div>
                                                </MenuItem>
                                            </Box>
                                        )}

                                        {boardCard.id && !disableArchiveButton && (
                                            <Box mb={[-1, 0]}>
                                                <MenuItem onClick={_onDeleteClick} >
                                                    <Typography variant="caption">
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginRight: theme.spacing(1),
                                                                    width: '16px',
                                                                    height: '20px',
                                                                    fontSize: '1rem',
                                                                }}
                                                            >
                                                                🗑
                                                            </div>

                                                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                                Delete
                                                            </Typography>
                                                        </div>
                                                    </Typography>
                                                </MenuItem>
                                            </Box>
                                        )}
                                    </Box>
                                </Menu>
                            </div>
                        )}
                    </div>

                    <div
                        style={{
                            marginTop: -theme.spacing(hasMenu ? 1 : 0),
                        }}
                    >
                        {
                            <div>
                                <Box display={listing_details.beds ? 'inline-block' : 'none'} >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {listing_details.beds}&nbsp;
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                        }}
                                    >
                                        bed&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                </Box>

                                <Box display={listing_details.baths ? 'inline-block' : 'none'}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {listing_details.baths}&nbsp;
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                        }}
                                    >
                                        bath&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                </Box>

                                <Box display={listingHomeSize ? 'inline-block' : 'none'}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {listingHomeSize}&nbsp;
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            display: 'inline-block'
                                        }}
                                    >
                                        sqft
                                    </Typography>
                                </Box>

                                <Typography
                                    variant="body2"
                                    style={{
                                        fontWeight: name ? '500' : '400',
                                    }}
                                >
                                    {name || addressLine1}
                                </Typography>

                                <Typography
                                    variant="body2"
                                >
                                    {addressLine2}
                                </Typography>
                                {isFetchedListingMLS && (
                                    <Typography
                                        variant="body1"
                                        style={{
                                            minHeight: '24px',
                                            fontSize: '10px',
                                            color: theme.palette?.lr_colors?.steak_sauce,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {(listing_details?.realtor?.company || showMlsName)
                                            ? `Courtesy of ${_.join(_.compact([listing_details?.realtor?.company, showMlsName && _.get(listingMLS, '[0].market_name')]), ' and ')}`
                                            : ''
                                        }
                                    </Typography>
                                )}
                            </div>
                        }
                    </div>
                    <Can I="edit" a="BoardCard">
                        {!isListingSold && (variant === 'makeAnOffer' || laneId === '3' ) && (
                            <div
                                style={{
                                    marginTop: theme.spacing(1.5),
                                }}
                            >
                                <LRButton variant={(isScheduled) ? 'outlined' : 'contained'} color={(isScheduled) ? 'default' : 'primary'}  fullWidth onClick={_onMakeAnOfferClick} disabled={disableActionButton}>
                                    {(isScheduled) ? 'Agent notified' : 'Prepare an offer'}
                                </LRButton>
                            </div>
                        )}
                        {(!isListingSold && (variant === 'scheduleTour' || laneId === '2')) && (
                            <div
                                style={{
                                    marginTop: theme.spacing(1.5),
                                }}
                            >
                                <ScheduleTourButton
                                    homeTourData={homeTourData}
                                    disableActionButton={disableActionButton}
                                    handleScheduleTour={handleScheduleTour}
                                    agent={agent}
                                />
                            </div>
                        )}

                        {variant === 'addHomeToTour' && (
                            <div
                                style={{
                                    marginTop: theme.spacing(1.5),
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                {
                                    disableActionButton ? (
                                        <LRTooltip
                                            arrow
                                            // hideArrow={false}
                                            disableFocusListener
                                            disableTouchListener
                                            title={
                                                'You can only add up to 5 homes per tour.'
                                            }
                                        >
                                            <div>
                                                <LRButton variant="contained" color="primary" fullWidth onClick={_onAddHomeToTourClick} disabled={disableActionButton}>
                                                    {selected ? 'Remove' : 'Add home to tour'}
                                                </LRButton>
                                            </div>
                                        </LRTooltip>
                                    ) : (
                                        <LRButton variant="contained" color="primary" fullWidth onClick={_onAddHomeToTourClick} disabled={disableActionButton}>
                                            {selected ? 'Remove' : 'Add home to tour'}
                                        </LRButton>
                                    )
                                }
                            </div>
                        )}
                    </Can>
                </div>
            </div>
        </>
    );
}

Card.propTypes = {
    variant: PropTypes.string,
    boardCard: PropTypes.object.isRequired,
    canMove: PropTypes.bool,
    onMakeAnOfferClick: PropTypes.func,
    onMoveToChange: PropTypes.func,
};

export default observer(Card);

// Additional Exports
export { OverlayMoveMenu };
