import 'react-placeholder/lib/reactPlaceholder.css';
import React from 'react';
import { RectShape } from 'react-placeholder/lib/placeholders';
import styled from 'styled-components';
import { CardContent } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';

const StyledContainer = styled.div`
    width: 250px;
    margin: 0 auto;
    padding: 0 0 20px;
`;
const StyledInnerContainer = styled.div`
    border-radius: 4px;
    overflow: hidden;
`;
const StyledImage = styled.div`
    position: relative;
`;
const StyledHeadshot = withTheme(styled.div`
    position: absolute;
    top: ${({ theme })=> theme.spacing(1)}px;
    right: ${({ theme })=> theme.spacing(2)}px;
    border-radius: 50%;
    background: #f4f4f4;
    width: 24px;
    height: 24px;
`);
const StyledCardContent = styled(CardContent)`
    background: #fff;
`;

const styles = {
    image: {
        paddingBottom: '55%',
        maxHeight: '172px',
        backgroundColor: 'rgb(205, 205, 205)'
    },
    price: {
        height: '18px',
        backgroundColor: 'rgb(205, 205, 205)',
        maxWidth: '60%',
        marginBottom: '12px'
    },
    address: {
        height: '14px',
        backgroundColor: 'rgb(205, 205, 205)'
    },
    propertyDetails: {
        height: '14px',
        backgroundColor: 'rgb(205, 205, 205)',
        marginTop: '18px',
        display: 'inline-block',
        width: '18%',
        marginRight: '10px'
    }
};

function CardPlaceholder(props) {
    return (
        <StyledContainer className="show-loading-animation">
            <StyledInnerContainer>
                <StyledImage>
                    <RectShape style={styles.image} />
                    <StyledHeadshot />
                </StyledImage>

                <StyledCardContent>
                    <RectShape style={styles.price} />
                    <RectShape style={styles.address} />
                </StyledCardContent>
            </StyledInnerContainer>
        </StyledContainer>
    );
}

export default CardPlaceholder;
