import { Box, Typography, useTheme, CircularProgress, AppBar, Toolbar, Grid, Fade, Slide, Collapse,  } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import Logger from 'js-logger';
import { useHistory, useRouteMatch, useLocation, useParams } from 'react-router-dom';
import { Close, ImportExport } from '@material-ui/icons';
import { useStore } from '../../../mobx-store';
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { withTheme } from '@material-ui/core/styles';
import LRButton from '../../../components/LRButton';
import LRLink from '../../../components/LRLink';
import analytics from '../../../services/analytics';
import { useQuery, useResponsiveBreakpoint, useRFHInput } from '../../../hooks';
import styled from 'styled-components';
import { ListpackSortPopover } from '../../ListingsMap/Navbar';
import { useForm, FormContext, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import { useOnboarding } from 'components/Onboarding';
import { Filters } from './Filters/';
import { useListing } from 'hooks/data/listing/useListing';
import { ListingsMapAndListModal } from 'components/ListingsMapAndListModal';
import { LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL, LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS,
    MOBILE_VIEW_MODES, SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION } from '../../../constants';
import { ListpackListingView } from 'components/ListpackListingView';
import queryString from 'query-string';
import { MobileFooter } from 'scenes/ListingsMap/Mobile/Footer';
import { ContactAgentLeadCaptureButton } from 'components/LeadCaptureFormModal';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { MobileFilters } from './Filters/MobileFilters';
import shortid from 'shortid';
import { useBoardListpackListings, useNewBoardListpack } from 'hooks/useBoardListpacks';
import { useHasRobinAccess } from 'hooks/data/user-addons';
import { RobinLogo } from '../../../components/RobinLogo';
import { useAgent } from 'hooks/useAgent';
import { ModalRoute } from 'components/ModalRoute';
import Card from '../Card';
import simpleCypher from 'services/simple-cypher';
import Lockr from 'lockr';
import AgentInfo from 'components/ListingDetails/Header/SlidyAgentInfo/AgentInfo';
import AgentBrandingHeader from 'components/Listing/AgentBrandingHeader';
const logger = Logger.get('ListpackSearchModal');


const HeaderContent = withTheme(styled.div`
    border-bottom: 1px solid rgb(216, 216, 216);
    padding: 16px 24px;
    position: relative;

    ${({ theme })=> theme.breakpoints.up('md')} {
        padding: 16px;
    }
    ${({ theme })=> theme.breakpoints.up('lg')} {
        padding: 16px 24px;
    }
`);

const HomesTypography = withTheme(styled(Typography)`
    color: ${({ theme })=> theme.palette.lr_colors.steak_sauce};
    display: inline-block;
    line-height: 36px;

    ${({ theme })=> theme.breakpoints.up('md')} {
        max-width: 95px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${({ theme })=> theme.breakpoints.up('lg')} {
        max-width: 100%;
    }

`);

const getQueryParamFields = (queryParamFieldsRaw)=> {
    let queryParamFields = {};

    if(queryParamFieldsRaw && queryParamFieldsRaw.length) {
        try {
            queryParamFields = JSON.parse(queryParamFieldsRaw);
        } catch (err) {
            logger.error('Error parsing filters', err);
        }
    }

    if(queryParamFields?.stories) {
        let storiesFormValue = queryParamFields.stories;

        switch(queryParamFields.stories.toLowerCase()) {
            case 'single':
                storiesFormValue = '1';
                break;
            case 'multi':
                storiesFormValue = '2';
                break;
            default:
                break;
        }

        queryParamFields.stories = storiesFormValue;
    }

    if(queryParamFields?.bedrooms) {
        let bedroomsFormValue = queryParamFields.bedrooms;

        switch(queryParamFields.bedrooms.toLowerCase()) {
            case 'studio':
                bedroomsFormValue = '0';
                break;
            default:
                break;
        }

        queryParamFields.bedrooms = bedroomsFormValue;
    }

    if(queryParamFields?.school_score_min) {
        queryParamFields.school_score_min = parseInt(queryParamFields.school_score_min);
    }

    return queryParamFields;
};

function useListingFromListpack(listingId, listings) {
    const index = listings.findIndex((listpackListing)=> listingId && (listingId === listpackListing.id));
    const foundListing = Boolean(~index);
    const { data:hookListing } = useListing(!foundListing && listingId);
    const listing = listings[index];

    return listing || hookListing;
}

function ViewListpackListing({
    listings:boardCardStyleListings = [],
    totalListings = 0,
    hasNextPage = false,
    isFetchingNextPage = false,
    fetchNextPage = ()=> {},
    open = false,
    onExited = (e)=> {},
    onEntered = (e)=> {},
    onListingIdChange = (listingId)=> {},
    sourceRequest,
    ...props
}) {
    const history = useHistory();
    const location = useLocation();
    const { listingId:listingIdParam } = useParams();
    const [listingId, setListingId] = useState(listingIdParam);
    const listings = useMemo(()=> {
        return boardCardStyleListings.map((listing)=> listing.listing_details);
    }, [boardCardStyleListings]);
    const listingIndex = useMemo(()=> {
        return listings.findIndex((listpackListing)=> listingId && (listingId === listpackListing.id));
    }, [listingId, listings]);
    const listing = useListingFromListpack(listingId, listings);
    const qs = queryString.parse(location.search);

    // UseEffects

    /**
     set the initial listing id by the query param  on open
     */
    useEffect(()=> {
        if(open) {
            setListingId(listingIdParam);
        }
    }, [open]);

    /**
     Fetch the next page as needed when the user gets close to the end of the
     currently loaded listings and we have more listings.
     */
    useEffect(()=> {
        if(open && listings && hasNextPage && listingIndex > (listings.length - 10) && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, listingIndex, listings, open]);

    /**
     Update the URL with the latest listing id when the listing changes
     */
    useEffect(()=> {
        let to;

        if(listing && listing.id !== listingIdParam) {
            to = setTimeout(()=> {
                history.push({
                    pathname: location.pathname.replace(listingIdParam, listing.id),
                    search: location.search,
                });
            }, 100);
        }

        return ()=> {
            if(to) clearTimeout(to);
        };
    }, [history, listing, listingIdParam, location.pathname, location.search]);

    useEffect(()=> {
        if(listing?.id && open) onListingIdChange(listing);
    }, [listing?.id, open]);

    // Functions

    function onNextListingClick() {
        const nextListing = listings[listingIndex + 1];
        setListingId(nextListing.id);
    }

    function onPreviousListingClick() {
        const prevListing = listings[listingIndex - 1];
        setListingId(prevListing.id);
    }

    function onModalExited(e) {
        setListingId(null);
        onExited(e);
    }

    function onModalEntered(e) {
        onEntered(e, listingId);
    }

    return (
        <ListpackListingView
            {...props}
            open={open}
            listing={listing}
            listings={listings}
            totalOfListings={totalListings}
            isloadingMore={isFetchingNextPage}
            canLoadMore={hasNextPage}
            onNextListingClick={onNextListingClick}
            onPreviousListingClick={onPreviousListingClick}
            sourceRequest={qs.source_request || sourceRequest}
            onExited={onModalExited}
            onEntered={onModalEntered}
        />
    );
}

function ListpackSearchModal({
    open = true,
    header,
    includeDefaultHeader = true,
    bodyHeader,
    includeDefaultBodyHeader = true,
    hideCloseButton = false,
    ViewListpackListingProps = {},
    onSidebarColumnToggle = (isSingleColumn)=> {},
    hasRobinAccess = true,
    blockIdxSources = null,
    ...props
}) {
    const { isIPadDown } = useDeviceBreakpoints();
    const [anchorEl, setAnchorEl] = useState(null);
    const [nameStatus, setNameStatus] = useState(false);
    const { UserStore, LoginSignupModalStore, ListingsMapStore } = useStore();
    const { isLoggedIn } = UserStore;
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const [openSaveModal, setSaveOpenModal] = useState(false);
    const [saveTrackEvent, setSaveTrackEvent] = useState('header');
    const [openSignUpLoginModal, setOpenSignUpLoginModal] = useState(false);
    const match = useRouteMatch();
    const areaId = useMemo(()=> {
        return match?.params?.areaId.split('|') || [];
    }, [match?.params?.areaId]);
    const history = useHistory();
    const listpackId = match?.params?.listpackId;
    const onboarding = useOnboarding();
    // fields can be passed in from a query string, parse out the JSON stringified fields here
    const queryParamFields = getQueryParamFields(useQuery().get('fields'));
    const querySort = useQuery().get('sort');
    const queryLimit = parseInt(useQuery().get('limit'), 10);
    const [disableActions, setDisableActions] = useState(true);
    // const listingRequest = useListing(onboarding.listing); // Not sure if this is still needed for onboarding (LC)
    const allAreasRemoved = useRef(false);
    const DEFAULT_FIELDS = {
        price_min: null,
        price_max: null,
        bedrooms: null,
        bathrooms: null,
        homesize_min: null,
        homesize_max: null,
        stories: null,
        lotsize_min: null,
        lotsize_max: null,
        year_built_min: null,
        year_built_max: null,
        days_on_market: null,
        school_score_min: null,
        hometype: {
            single_family: false,
            multi_family: false,
            condo_townhome: false,
            mobile_manufactured: false,
            land: false,
        },
        tags: {
            with_great_schools: false,
            with_open_houses: false,
            with_views: false,
            with_virtual_tours: false,
            with_upgraded_kitchens: false,
            with_pool: false,
        },
        keywords: [],
    };
    const DEFAULT_STATE = {
        name: areaId ? `${areaId[0]} Homes` : '',
        areaIds: [],
        notification_settings: {
            new_listings: 'daily',
        },
        fields: _.merge(DEFAULT_FIELDS, queryParamFields),
    };
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: DEFAULT_STATE,
    });
    const { watch, reset, control } = formMethods;
    const { fields, append, remove} = useFieldArray(
        {
            control,
            name: 'areaIds'
        }
    );
    const [sort, setSort] = useState(querySort || 'ListingDate:desc');
    const location = useLocation();
    const locationSearch = location.search;
    const qs = new URLSearchParams(locationSearch);
    if(!qs.get('source')) qs.set('source', 'lane-expanded');
    const [view, setView] = useState(MOBILE_VIEW_MODES.LIST);
    const lpAreaIds = watch('areaIds');
    const lpFields = watch('fields');
    const tempistpackId = useMemo(()=> {
        return shortid.generate();
    }, [open]);
    const listpackData = useMemo(()=> {
        // sometimes areaIds are coming in as objects instead of strings, check for that so we solve for it
        let areaIds = _.clone(lpAreaIds);
        if(lpAreaIds && lpAreaIds.length && _.isPlainObject(lpAreaIds[0]) && lpAreaIds[0].value) {
            areaIds = lpAreaIds.map(({ value })=> value);
        }
        return {
            id: tempistpackId,
            areaIds,
            fields: {
                blockIdxSources,
                ...lpFields,
            },
        };
    }, [lpAreaIds, lpFields, tempistpackId, blockIdxSources]);
    const listpack = useNewBoardListpack(listpackData);
    const {
        pagesLoaded,
        listingCards:listings,
        isLoading:showLoading,
        isFetchingNextPage:loadingMoreListings,
        hasNextPage:hasMoreListings,
        fetchNextPage:fetchMoreListings,
    } = useBoardListpackListings(listpack, sort, queryLimit);
    const listingsTotal = useMemo(()=> {
        return (!isNaN(queryLimit) && pagesLoaded === 1)
            ? Math.min(queryLimit, listpack.activeListingsCount)
            : listpack.activeListingsCount;
    }, [listpack?.activeListingsCount, pagesLoaded, queryLimit]);
    const { handleChange:handleNameChange } = useRFHInput('name', formMethods);
    const { value: includeListingsWithUnspecifiedData } = useRFHInput('fields.include_listings_with_unspecified_data', formMethods);
    const [onLoginSuccessOpenSaveModal, setOnLoginSuccessOpenSaveModal] = useState();

    // Auto pop first listing if query param `autoopen` is truthy
    if(!isMobile && listings?.length > 0 && qs.get('autoopen')) {
        qs.delete('autoopen');
        const destination = `${match.url}/listing/${listings[0].listing_details.id}?${qs.toString()}`;
        history.push(destination);
    }

    // Functions
    function handleOnClose() {
        if((onboarding?.isNurtureFirstRunOnboarding || onboarding?.isFirstRunOnboarding) && ['free-step'].includes(onboarding?.stepId) ){
            onboarding.complete();
        }

        if( onboarding?.isNurtureFirstRunOnboarding && ['rpp-nurture-first'].includes(onboarding?.stepId)){
            analytics.eventTrack('robin_onboarding_search_area_close_search');
            onboarding.stop();
            UserStore.changedNurtureOnboardingToSidenav = true;
            history.push('/boards');
        } else {
            history.push((location.state && location.state?.from) ? location.state?.from : '/boards');
        }
    }

    async function onSortChange(sort) {
        if(allAreasRemoved.current){
            allAreasRemoved.current = false;
        }
        setSort(sort);
    };

    function trackFilterClick(filter) {
        analytics.eventTrack('robin_header_filter_click', {
            'source': filter,
        });
    }

    function trackFilterSet(filter) {
        analytics.eventTrack('robin_header_filter_set', {
            'source': filter,
        });
    }

    const removeField = useCallback(
        async function removeFields(){
            remove();
        },
        [],
    );

    function handleListingClick(listing) {
        const { stepId } = onboarding || {};
        const shouldAdvanceOnboarding = ['highlight-listpack-listing-card'].includes(stepId);

        if(onboarding?.isFirstRunOnboarding && shouldAdvanceOnboarding) {
            onboarding.next();
        }

        if(onboarding?.isFirstRunOnboarding) {
            analytics.eventTrack('robin_onboarding_view_RPP_from_search', {
                source: 'listings-map-rpp',
            });
        }
        const destination = `${match.url}/listing/${listing.listing_details.id}?${qs.toString()}`;
        history.push(destination);
    }

    function handleViewChange(view) {
        setView(view);
    }

    function onExited() {
        reset(DEFAULT_STATE);
        remove();
    }

    function onSetSaveOpenModal(value) {
        if(isLoggedIn || !value) {
            return setSaveOpenModal(value);
        }

        LoginSignupModalStore.openSignupModal('saved_search_button');
        LoginSignupModalStore.onSuccess(()=> setOnLoginSuccessOpenSaveModal(value));
    }

    // UseEffects
    
    useEffect(()=> {
        ListingsMapStore.setSelectedAreas(_.map(fields, 'value'));
    }, [fields]);

    useEffect(()=> {
        if(onLoginSuccessOpenSaveModal != null) {
            onSetSaveOpenModal(onLoginSuccessOpenSaveModal);
            setOnLoginSuccessOpenSaveModal(null);
        }
    }, [onLoginSuccessOpenSaveModal]);

    useEffect(()=> {
        if(!areaId) return;

        append(areaId);
        handleNameChange(`${areaId[0]} Homes`);
    }, [areaId]);

    /**
     onboarding check once we have listings
     */
    useEffect(()=> {
        if(onboarding?.isFirstRunOnboarding && !showLoading) {
            onboarding.needsToExpandSearch = !listings?.length;
        }
    }, [listings, onboarding, showLoading]);

    // TODO: Relook at what is actually needed in this use effect
    // useEffect( ()=> {
    //     if(listingRequest?.data && listingRequest?.isSuccess && !listingRequest?.isLoading && onboarding.listing && !UserStore.firstRunOnboardingCompleted){
    //         setValue('fields.bedrooms', listingRequest?.data?.beds.toString());
    //         setValue('fields.bathrooms', listingRequest?.data?.baths.toString());
    //         setValue('name', `${listingRequest?.data?.city}, ${listingRequest?.data?.state} Homes`);


    //         if(listingRequest?.data?.price){
    //             const index = PRICE_RANGE.findIndex((elem)=> elem.value > listingRequest?.data?.price);
    //             if(index !== -1){
    //                 setValue('fields.price_min', PRICE_RANGE[index - 1].value);
    //                 setValue('fields.price_max', (index !== PRICE_RANGE.length -1) ? PRICE_RANGE[index].value : null);
    //             }
    //         }

    //         removeField().then(()=> {
    //             append(`${listingRequest?.data?.city}, ${listingRequest?.data?.state}`);
    //             getListings();
    //         });

    //         onboarding.listing = null;
    //     }
    // },[listingRequest, onboarding.listing, UserStore.firstRunOnboardingCompleted]);

    return (
        <>
            <ListingsMapAndListModal
                open={open}
                view={view}
                listpack={listpack}
                listings={listings}
                hasMoreListings={hasMoreListings}
                loadingMoreListings={loadingMoreListings}
                fetchMoreListings={fetchMoreListings}
                isLoading={showLoading}
                header={(
                    <>
                        {header}

                        {includeDefaultHeader && (
                            <HeaderContent justifyContent="space-between" alignItems="center" className="tour-listpack-search-navbar tour-rpp-nurture">
                                {
                                    onboarding?.isFirstRunOnboarding && onboarding?.stepId === 'listpack-area-filters' && disableActions && (
                                        <div
                                            className="tour-listpack-search-navbar-disabled-actions"
                                            onClick={()=> {
                                                setDisableActions(false);
                                                onboarding.next();
                                            }}
                                            style={{
                                                position: 'absolute',
                                                zIndex: 1,
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                background: 'transparent',
                                                opacity: 0.5
                                            }}
                                        ></div>
                                    )
                                }

                                <Box width={1} display="flex" height="100%" alignItems="center" justifyContent="space-between">
                                    {!isMobile && (
                                        <>
                                            <FormContext {...formMethods}>
                                                <Filters
                                                    listingsCount={listingsTotal}
                                                    defaultFilters={DEFAULT_STATE}
                                                    openSaveModal={openSaveModal}
                                                    setSaveOpenModal={onSetSaveOpenModal}
                                                    showLoading={showLoading}
                                                    trackFilterClick={trackFilterClick}
                                                    trackFilterSet={trackFilterSet}
                                                    saveTrackEvent={saveTrackEvent}
                                                    setSaveTrackEvent={setSaveTrackEvent}
                                                    fieldArray={{ fields, append, remove}}
                                                    areas={fields}
                                                    nameState={{nameStatus, setNameStatus}}
                                                    hideSaveButton={!hasRobinAccess}
                                                />
                                            </FormContext>

                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {showLoading
                                                    ? <CircularProgress size={24} color="primary" />
                                                    : (
                                                        <HomesTypography variant="body1">
                                                            {(listingsTotal === 1) ? `${listingsTotal} home`: `${listingsTotal} homes`}
                                                        </HomesTypography>
                                                    )
                                                }
                                                <div style={{marginLeft: theme.spacing(1)}}>
                                                    <LRButton fullWidth variant="outlined" onClick={(e)=> {
                                                        if(onboarding && onboarding.isNurtureFirstRunOnboarding){
                                                            if(['rpp-nurture-first'].includes(onboarding.stepId)){
                                                                onboarding.next();
                                                            } else {
                                                                return;
                                                            }
                                                        } else {
                                                            setAnchorEl(e.currentTarget);
                                                        }
                                                    }}>
                                                        <ImportExport fontSize="inherit" />
                                                        <Box component="span" ml={0.5}>Sort</Box>
                                                    </LRButton>
                                                    <ListpackSortPopover
                                                        anchorEl={anchorEl}
                                                        setAnchorEl={setAnchorEl}
                                                        sort={sort}
                                                        setSort={onSortChange}
                                                    />
                                                </div>
                                                {!hideCloseButton && (
                                                    <Box ml={2} display="flex" alignItems="center" justifyContent="center">
                                                        <LRLink
                                                            color="black"
                                                            className="tour-target-modal-close-button tour-target-modal-close-button-mobile"
                                                            onClick={()=> {
                                                                handleOnClose();
                                                            }}
                                                        >
                                                            <Close />
                                                        </LRLink>
                                                    </Box>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {isMobile && (
                                        <>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                {(listpackId) ? 'Edit search' : 'New search'}
                                            </Typography>
                                            {!hideCloseButton && (
                                                <LRLink
                                                    style={{
                                                        position: 'absolute',
                                                        top: '16px',
                                                        right: '16px',
                                                    }}
                                                    color="black"
                                                    onClick={()=> {
                                                        handleOnClose();
                                                    }}
                                                >
                                                    <Close />
                                                </LRLink>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </HeaderContent>
                        )}
                    </>
                )}
                bodyHeader={(
                    <>
                        {bodyHeader}

                        {includeDefaultBodyHeader && isIPadDown && (
                            <FormContext {...formMethods}>
                                <MobileFilters
                                    listingsCount={listingsTotal}
                                    openSaveModal={openSaveModal}
                                    setSaveOpenModal={onSetSaveOpenModal}
                                    showLoading={showLoading}
                                    trackFilterClick={trackFilterClick}
                                    trackFilterSet={trackFilterSet}
                                    isloadingMore={loadingMoreListings}
                                    saveTrackEvent={saveTrackEvent}
                                    setSaveTrackEvent={setSaveTrackEvent}
                                    isLoadingListings={showLoading}
                                    fieldArray={{ fields, append, remove}}
                                    areas={fields}
                                    nameState={{nameStatus, setNameStatus}}
                                    hideSaveButton={!hasRobinAccess}
                                />
                            </FormContext>
                        )}
                    </>
                )}
                footer={isIPadDown && (
                    <Box>
                        <MobileFooter
                            view={view}
                            onViewChange={handleViewChange}
                            listpack={listpack}
                            onSortChange={onSortChange}
                            sort={sort}
                            isSearch={true}
                            listingsCount={listingsTotal}
                            showLoading={showLoading}
                        />
                    </Box>
                )}
                onListingSelect={handleListingClick}
                onExited={onExited}
                onSidebarColumnToggle={onSidebarColumnToggle}
                renderCard={({ listing, isNew, laneIdBadge, selected })=> (
                    <Card
                        variant={listing.laneId === '3' ? 'makeAnOffer' : 'expanded'}
                        boardCard={listing}
                        isNew={isNew}
                        laneIdBadge={laneIdBadge}
                        selected={selected}
                        cardOverlayMenuCanPassThrough={hasRobinAccess}
                        showUnspecifiedListingsWarning={includeListingsWithUnspecifiedData}
                    />
                )}
                {...props}
            />

            {match && (
                <ModalRoute
                    path={`${match.path}/listing/:listingId`}
                    Component={ViewListpackListing}
                    ComponentProps={{
                        TransitionComponent: Slide,
                        TransitionProps: {
                            direction: 'up',
                        },
                        ...ViewListpackListingProps,
                        listings: listings,
                        hasNextPage: hasMoreListings,
                        isFetchingNextPage: loadingMoreListings,
                        fetchNextPage: fetchMoreListings,
                        totalListings: listingsTotal,
                        onCloseClick: ()=> {
                            if(onboarding && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && onboarding.stepId !== 'free-step') {
                                onboarding.bypassRPPView = true;
                                onboarding.back();
                            }
                            history.push(`${match.url}${locationSearch}`);
                        },
                    }}
                />
            )}
        </>
    );
}

export default observer(ListpackSearchModal);

export const ListpackSearchModalLoggedOut = observer(function ListpackSearchModalLoggedOut({
    ...props
}) {
    const theme = useTheme();
    const { UserStore, LoginSignupModalStore,  } = useStore();
    const { isLoggedIn, hasAttemptedToFetchUser } = UserStore;
    const { isMobile, isIPadDown } = useDeviceBreakpoints();
    const [hideLicenseNumber, setHideLicenseNumber] = useState(false);
    const { affiliateCode } = useParams();
    const query = useQuery();
    const { data: agent } = useAgent(affiliateCode, { priority: 'affiliate' });
    const hasRobinAccess = useHasRobinAccess(agent?.id);
    const agentHasSignedIdxLicenseAgreement = useMemo(()=> agent?.has_signed_idx_license_agreement || false, [agent]);
    const agentHasActiveMLSAffiliationsLiveInRobin = useMemo(()=> (agent?.mls_affiliations || [])
        .filter((m)=> m?.status === 'active' && m?.mls?.live_in_robin).length > 0, [agent]);
    const queryParamSource = query.get('source');
    const queryParamListpackId = query.get('listpack_id');

    // UseEffects

    useEffect(()=> {
        analytics.eventTrack('robin_logged_out_unsaved_search_page_viewed', {
            agent_user_id: affiliateCode,
            listpack_id: queryParamListpackId,
            source: queryParamSource,
        });
    }, []);

    useEffect(()=> {
        if(LoginSignupModalStore.isOpen === LoginSignupModalStore.LOGIN_SIGNUP_MODAL_SIGNUP_OPEN) {
            analytics.eventTrack('robin_logged_out_signup_modal_displayed', {
                agent_user_id: affiliateCode,
                source: LoginSignupModalStore.openSignupSource,
            });
        }
    }, [LoginSignupModalStore.LOGIN_SIGNUP_MODAL_SIGNUP_OPEN, LoginSignupModalStore.isOpen, LoginSignupModalStore.openSignupSource, affiliateCode]);

    useEffect(()=> {
        const cancelEventListener = LoginSignupModalStore.onSignupSuccess(()=> {
            analytics.eventTrack('robin_logged_out_signup_success', {
                agent_user_id: affiliateCode,
                source: LoginSignupModalStore.openSignupSource,
            });
        });

        return cancelEventListener;
    }, []);

    useEffect(()=> {
        if(affiliateCode) LoginSignupModalStore.affiliateCode = affiliateCode;
    }, [affiliateCode]);

    // Functions

    function openLoginModal() {
        analytics.eventTrack('robin_logged_out_login_link_clicked', {
            agent_user_id: affiliateCode,
            source: 'unsaved_search_page',
        });
        LoginSignupModalStore.openLoginModal();
        Lockr.rm(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION);
    }

    function openSignUpModal() {
        analytics.eventTrack('robin_logged_out_signup_button_clicked', {
            agent_user_id: affiliateCode,
            source: 'unsaved_search_page',
        });
        LoginSignupModalStore.openSignupModal('signup_button');
        Lockr.rm(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION);
    }

    function onSidebarColumnToggle(isSingleColumn) {
        setHideLicenseNumber(isSingleColumn);
    }

    return (
        <>
            <ListpackSearchModal
                open
                hideCloseButton={!isLoggedIn}
                includeDefaultHeader={!isIPadDown || isLoggedIn}
                hasRobinAccess={hasRobinAccess}
                blockIdxSources={agentHasSignedIdxLicenseAgreement === false || !agentHasActiveMLSAffiliationsLiveInRobin}
                header={(
                    <Collapse in={Boolean(!isLoggedIn && hasAttemptedToFetchUser)}>
                        <AppBar position="static" color="inherit" elevation={0} style={{ borderBottom: `1px solid ${theme.palette.lr_colors.border_grey}`, height: isMobile ? 56 : 64, }}>
                            <Toolbar>
                                <Grid container alignItems="center">
                                    <Grid item xs style={{display: 'flex'}}>
                                        {!isMobile && agent && <AgentBrandingHeader
                                            agent={agent}
                                            hideLicenseNumber={isMobile}
                                            hideCompanyLogo={isMobile}
                                            style={{
                                                padding: 0
                                            }}
                                        />}
        
                                        {isMobile && (
                                            <Box display="flex" pr={3} alignItems="center">
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                        color: '#a1a1a1',
                                                        margin: '-4px 4px 0 0'
                                                    }}
                                                >
                                                    powered by
                                                </span>
                                                <RobinLogo/>
                                            </Box>
                                        )}
                                    </Grid>

                                    {hasRobinAccess && (<Fade in={!isLoggedIn && hasAttemptedToFetchUser}>
                                        <Grid container item xs justify="flex-end" spacing={1}>
                                            <Grid item>
                                                <LRButton variant="text" underline onClick={openLoginModal}>
                                                    Sign in
                                                </LRButton>
                                            </Grid>

                                            <Grid item>
                                                <LRButton variant="contained" color="primary" onClick={openSignUpModal}>
                                                    Sign up
                                                </LRButton>
                                            </Grid>
                                        </Grid>
                                    </Fade>)}

                                    {!hasRobinAccess && (<Fade in={!isLoggedIn && hasAttemptedToFetchUser}>
                                        <Grid container item xs justify="flex-end" spacing={1}>
                                            <Grid item>
                                                <ContactAgentLeadCaptureButton
                                                    agent={agent}
                                                    trackSource="map_view"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Fade>)}
                                </Grid>

                            </Toolbar>
                        </AppBar>
                    </Collapse>
                )}
                bodyHeader={!isLoggedIn && isMobile && hasAttemptedToFetchUser && agent && (
                    <div style={{boxShadow: '-1px 0px 15px 1px rgba(0,0,0,0.14)'}}>
                        <div style={{backgroundColor: 'white', padding: `${theme.spacing(1)}px 15px`}}>
                            <AgentBrandingHeader
                                agent={agent}
                                hideLicenseNumber={true}
                                style={{
                                    padding: 0
                                }}
                            />
                        </div>
                    </div>
                )}
                onSidebarColumnToggle={onSidebarColumnToggle}
                ViewListpackListingProps={{
                    sourceRequest: queryParamSource,
                    onListingIdChange: (listing)=> {
                        if(listing) {
                            const { id:listingId } = simpleCypher.decode(listing.id);

                            analytics.eventTrack('robin_logged_out_rpp_page_viewed', {
                                agent_user_id: affiliateCode,
                                listing_id: listingId,
                                address: listing.address,
                                listing_source: listing.listing_source,
                                source: queryParamSource,
                                type: queryParamListpackId,
                            });
                        }
                    },
                    HeaderProps: {
                        variant: hasRobinAccess
                            ? LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL
                            : LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS,
                        agent,
                    },
                    FooterProps: {
                        variant: hasRobinAccess
                            ? LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL
                            : LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS,
                        agent,
                    },
                    ListingProps: {
                        GalleryPreviewProps: {
                            PinspirationOverlayProps: {
                                canIPassThrough: hasRobinAccess,
                            }
                        },
                    },
                }}
                {...props}
            />
        </>
    );
});
