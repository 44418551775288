import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Box, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

const ClockDigits = withTheme(styled(Box)`
    font-weight: 400;
    font-size: 48px;
    line-height: 1;
    letter-spacing: 0.8;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 32px;
    }
`);

const ClockSeparator = withTheme(styled(Box)`
    font-weight: 400;
    font-size: 48px;
    line-height: 1;
    letter-spacing: 0.8;
    padding: 0 8px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 32px;
    }
`);

const ClockUnit = withTheme(styled(Box)`
    font-size: 16px;
    letter-spacing: 0.8;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`);

const ClockDigitCard = withTheme(styled(Box)`
    background: white;
    border-radius: 4px;
    width: 100px;
    height: 100px;
    padding: 12px 6px;
    text-align: center;
    box-shadow: 0 0 12px rgba(0,0,0,0.2);

    ${({ theme })=> theme.breakpoints.down('sm')} {
        width: 80px;
        height: 80px;
    }
`);

const CountdownTimer = function ({ startTime, expiredOpenHouse }) {
    const now = moment()
        .unix();
    const eventStartUnix = moment(startTime)
        .unix();
    const [secondsToGo, setSecondsToGo] = useState(eventStartUnix - now);
    const [formattedTime, setFormattedTime] = useState({});

    const zeroPad = (str)=> (str && str.length < 2) ? `0${str}` : str;

    const getFormattedTime = useCallback(()=> {
        var num_seconds = secondsToGo;
        var days = 0;
        var hours = 0;
        var minutes = 0;
        if(!isNaN(secondsToGo)) {
            days = Math.floor(num_seconds / (60 * 60 * 24));
            num_seconds -= days * (60 * 60 * 24);
            hours = Math.floor(num_seconds / (60 * 60));
            num_seconds -= hours * (60 * 60);
            minutes = Math.floor(num_seconds / 60);
        }

        return {
            days: zeroPad(days.toString()),
            hours: zeroPad(hours.toString()),
            minutes: zeroPad(minutes.toString()),
        };
    }, [secondsToGo]);

    useEffect(()=> {
        // we update the secondsToGo value every 60s
        (secondsToGo > 0) &&
            window.setTimeout(()=> setSecondsToGo(secondsToGo - 60), 60000);
    }, [secondsToGo]);

    useEffect(()=> {
        if(secondsToGo > 0) {
            const formattedTime = getFormattedTime();
            setFormattedTime(formattedTime);
        }
    }, [secondsToGo, getFormattedTime]);

    return (
        <Box>
            {!expiredOpenHouse && (
                <React.Fragment>
                    <Box pb={2}
                        fontWeight={500}
                        letterSpacing={1.8}
                    >STARTS IN</Box>

                    <Box>
                        <Grid container
                            direction="row"
                            alignItems="center"
                        >
                            <Grid item>
                                <ClockDigitCard>
                                    <ClockDigits>{formattedTime.days || '00'}</ClockDigits>
                                    <ClockUnit>Days</ClockUnit>
                                </ClockDigitCard>
                            </Grid>
                            <Grid item>
                                <ClockSeparator>:</ClockSeparator>
                            </Grid>
                            <Grid item>
                                <ClockDigitCard>
                                    <ClockDigits>{formattedTime.hours || '00'}</ClockDigits>
                                    <ClockUnit>Hours</ClockUnit>
                                </ClockDigitCard>
                            </Grid>
                            <Grid item>
                                <ClockSeparator>:</ClockSeparator>
                            </Grid>
                            <Grid item>
                                <ClockDigitCard>
                                    <ClockDigits>{formattedTime.minutes || '00'}</ClockDigits>
                                    <ClockUnit>Minutes</ClockUnit>
                                </ClockDigitCard>
                            </Grid>
                        </Grid>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};

export default CountdownTimer;
