// Taken from Portal
function formatPhoneNumber(phone, { withParentheses = false } = {}) {
    const format = (withParentheses) ? '($1) $2-$3' : '$1-$2-$3';

    return String(phone)
        .replace(/([^0-9])/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, format);
}

export default formatPhoneNumber;
