import { benoitApi } from 'apis';
import { useQuery } from 'react-query';

export function useListpackAgent(listpackId) {
    return useQuery(
        ['listpackAgent', listpackId],
        function() {
            return benoitApi.getListpackAgent(listpackId);
        }, {
            enabled: Boolean(listpackId),
        }
    );
}
