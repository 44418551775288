import { Box, Divider, Drawer, Hidden, ListItem, ListItemText } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { COLOR_GREY_DARK, COLOR_GREY_DARKEST, COLOR_OFF_WHITE } from '../../constants';
import { useResponsiveBreakpoint } from '../../hooks';
import { useStore } from '../../mobx-store';
import { Can, useAbility } from '../Ability';
import LRAvatar from '../LRAvatar';
import LRNavLink from '../LRNavLink/index';
import FooterMobile from './FooterMobile';
import Header from './Header';
import BoardCollaborators from '../../scenes/Board/BoardManage/BoardCollaborators';
import { useHistory } from 'react-router-dom';
import theme from '../../theme';
import { LeftSidebar } from './LeftSidebar';
import analytics from '../../services/analytics';
import { when } from 'mobx';
import { useOnboarding } from '../Onboarding';
import { VerificationBanner } from '../VerificationBanner';
import { useGoogleAuth } from 'components/GoogleLogin';

const StyledListItem = styled(ListItem)`
    &.selected {
        background-color: ${COLOR_OFF_WHITE};
    }
`;

const MenuItem = styled(Link)`
    text-decoration: none;
    & :hover {
        text-decoration: none;
    }
    color: ${(props)=> props.color || 'inherit'};
`;

const Username = styled(Box)`
    color: ${COLOR_GREY_DARKEST};
`;

export const LoggedInWrapperContext = React.createContext(null);

export const LoggedInWrapperProvider = function(props) {

    const [drawerLeftOpen, setDrawerLeftOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    return <LoggedInWrapperContext.Provider value={{
        drawerOpen,
        drawerLeftOpen,
        onOpenLeftSidebar: (open)=> {
            setDrawerLeftOpen(open);
        },
        setDrawerLeftOpen: (open)=> {
            setDrawerLeftOpen(open);
        },
        setDrawerOpen: (open)=> {
            setDrawerOpen(open);
        }
    }}>
        {props.children}
    </LoggedInWrapperContext.Provider>;
};


function LoggedInWrapper(props) {
    const history = useHistory();
    const { UserStore, boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const collaborators = activeBoard ? activeBoard.collaborators : [];
    const isNurtureRppOnboarding = document.location.pathname.match(/^\/boards\/listpack\/create\//);
    const { user } = UserStore;
    const { drawerOpen, drawerLeftOpen, setDrawerOpen, setDrawerLeftOpen } = useContext(LoggedInWrapperContext);
    const isXs = useResponsiveBreakpoint('xs');
    const onboarding = useOnboarding();
    const ability = useAbility();
    const canDoOnboarding = ability.can('read', 'Onboarding') && !isXs;
    const { signOut:googleSignOut, isSignedIn:googleIsSignedIn } = useGoogleAuth();

    // Start with the leftDrawer open if the onboarding is not completed
    useEffect(()=> {
        const dispose = when(
            ()=> canDoOnboarding && UserStore.hasAttemptedToFetchUser && UserStore.usersBoardRole && UserStore.usersBoardRole !== '' && !isNurtureRppOnboarding,
            ()=> setDrawerLeftOpen(!UserStore.onboardingCompleted)
        );
        return ()=> dispose && dispose();
    }, []);

    useEffect(()=> {
        if(UserStore.changedNurtureOnboardingToSidenav && canDoOnboarding){
            setDrawerLeftOpen(false);
            onboarding.startTour('nurtureFirstRun', {triggerOrigin: 'sidenav'});
            UserStore.changedNurtureOnboardingToSidenav = false;
        }
    }, [UserStore.changedNurtureOnboardingToSidenav]);

    function handleMenuClick() {
        setDrawerOpen(true);
    }

    function closeMenu() {
        setDrawerOpen(false);
    }

    function trackEvent(eventName){
        analytics.eventTrack(eventName, {
            board_id: activeBoard.id,
            role: UserStore.usersBoardRole,
        });
    }
    function onAddCollaboratorClick() {
        trackEvent('robin_board_menu_add_button_click');
        history.push('/boards/account/co-buyers?cobuyer_state=invite');
        setDrawerOpen(false);
    }
    function handleLogoutClick() {
        closeMenu();
        UserStore.logout();
        if(googleIsSignedIn) googleSignOut();
        window.location = '/';
    }

    return (
        <>
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* hack for ios glintches when opening the drawer */}
                <div
                    style={{
                        display: drawerLeftOpen && isXs ? 'none' : 'block'
                    }}
                >
                    <Header
                        onMenuClick={handleMenuClick}
                        onOpenLeftSidebar={()=> {
                            setDrawerLeftOpen(true);
                        }}
                    />
                    {!isXs && UserStore.isBannerVisible && <VerificationBanner source="board" />}
                </div>
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'stretch',
                        overflow: 'hidden',
                    }}
                >
                    <Hidden smUp>
                        {user.accessProfile !== 'guest' && (
                            <LeftSidebar
                                open={drawerLeftOpen}
                                onOpen={()=> {
                                    if(!isNurtureRppOnboarding){
                                        setDrawerLeftOpen(true);
                                    }

                                }}
                                onClose={()=> {
                                    setDrawerLeftOpen(false);
                                }}
                            />
                        )}
                    </Hidden>

                    {isXs ? (
                        <div
                            style={{
                                backgroundColor: '#F9F9F9',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 56,
                                bottom: 64,
                                overflow: 'auto'
                            }}
                        >
                            {props.children}
                        </div>
                    ) : (
                        <div
                            style={{
                                backgroundColor: '#F9F9F9',
                                maxWidth: '100%',
                                overflow: 'hidden',
                                flexGrow: '1',
                            }}
                        >
                            {props.children}
                        </div>
                    )}
                </div>

                <Drawer
                    open={drawerOpen}
                    anchor="right"
                    onClose={(e)=> {
                        if(onboarding.isCobuyerInviteOnboarding) {
                            onboarding.stop();
                        }
                        closeMenu(e);
                    }}
                >
                    <div
                        style={{
                            width: '300px'
                        }}
                    >
                        {(user && activeBoard) && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        borderBottom: '2px',
                                        borderColor: theme.palette.lr_colors.border_grey,
                                        padding: theme.spacing(2),
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: theme.spacing(2),
                                        }}
                                    >
                                        <LRAvatar user={user} />
                                    </div>
                                    <div>
                                        <Username fontSize="h6.fontSize" fontWeight="bold" lineHeight="1">
                                            {user.first_name}
                                        </Username>
                                        <Box fontSize="body2.fontSize" style={{wordBreak: 'break-all'}}>{user.email}</Box>
                                    </div>
                                </div>
                                {/* <Can I="edit" a="User"> */}
                                <Box
                                    p={2}
                                    borderTop="1px solid #A1A1A1"
                                    borderBottom="1px solid #A1A1A1"
                                    fontSize="body2.fontSize"
                                    fontWeight="bold"
                                    className="space-y-4"
                                >
                                    <Box ml="10px">
                                        <BoardCollaborators
                                            collaborators={collaborators}
                                            onAddClick={onAddCollaboratorClick}
                                            board={activeBoard}
                                        />
                                    </Box>
                                    <div>
                                        <MenuItem
                                            color={COLOR_GREY_DARKEST}
                                            onClick={(e)=> {
                                                if(onboarding.isCobuyerInviteOnboarding) {
                                                    e.preventDefault();
                                                } else {
                                                    closeMenu();
                                                }
                                            }}
                                            to="/boards/account/profile"
                                        >
                                            Profile
                                        </MenuItem>
                                    </div>
                                    <div>
                                        <MenuItem
                                            color={COLOR_GREY_DARKEST}
                                            onClick={(e)=> {
                                                trackEvent('robin_board_menu_cobuyers_click');
                                                closeMenu();
                                            }}
                                            to="/boards/account/co-buyers"
                                            className="tour-target-cobuyer-setting-item"
                                        >
                                            Co-buyers
                                        </MenuItem>
                                    </div>
                                    {UserStore.isConsumer && (<div>
                                        <MenuItem
                                            color={COLOR_GREY_DARKEST}
                                            onClick={(e)=> {
                                                if(onboarding.isCobuyerInviteOnboarding) {
                                                    e.preventDefault();
                                                } else {
                                                    closeMenu();
                                                    trackEvent('robin_board_menu_notifications_click');
                                                }
                                            }}
                                            to="/boards/account/notifications"
                                        >
                                            Notifications
                                        </MenuItem>
                                    </div>)}
                                </Box>

                                <Box p={2} fontSize="body2.fontSize" fontWeight="bold">
                                    <Can I="logout">
                                        <MenuItem
                                            color={COLOR_GREY_DARK}
                                            onClick={(e)=> {
                                                if(onboarding.isCobuyerInviteOnboarding) {
                                                    e.preventDefault();
                                                } else {
                                                    handleLogoutClick(e);
                                                }
                                            }}
                                        >
                                            Sign out
                                        </MenuItem>
                                    </Can>
                                </Box>

                                <Box
                                    display={{xs: 'block', lg:'none'}}
                                    position="absolute"
                                    bottom="0"
                                >
                                    <FooterMobile />
                                </Box>
                                {/* </Can> */}
                            </>
                        )}
                        {!user && (
                            <>
                                <Divider />

                                <StyledListItem button component={LRNavLink} to="/login" onClick={closeMenu}>
                                    <ListItemText>Login</ListItemText>
                                </StyledListItem>
                            </>
                        )}
                    </div>
                </Drawer>
            </div>
        </>
    );
}

LoggedInWrapper.propTypes = {
    children: PropTypes.node,
};

export default observer(LoggedInWrapper);
