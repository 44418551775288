import React, {useState, useMemo} from 'react';
import { HouseIcon } from '../LRIcons';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const StyledHouseIcon = styled(HouseIcon)`
    position: absolute;
    color: ${({ overlay })=> (overlay ? 'rgb(255,255,255,0.1)' : '#b6b6b6')};
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Container = styled(Box)`
    ${({ background, backgroundColor })=> css`
        background: ${background};
        background-color: ${backgroundColor || '#ccc'};
        background-size: cover;
        background-position: center;
    `}
`;

function ListingImage({ image, height, width, overlay, opacity, hideAnimation, ...props }) {
    const [isImageLoading, setIsImageLoading] = useState(true);

    const showImage = useMemo(()=> {
        return hideAnimation || !isImageLoading;
    }, [hideAnimation, isImageLoading]);

    let background = `url('${image}')`;
    if(!hideAnimation) {
        let img = new Image();
        img.onload = ()=> setIsImageLoading(false);
        img.onerror = ()=> setIsImageLoading(false);
        img.src = background;
    }

    if(overlay) {
        background = 'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), ' + background;
    }

    return (
        <>
            {!showImage &&
                <Box width={width} height={height}>
                    <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
                </Box>
            }
            {showImage &&
                <Container position="relative" background={background} width={width} height={height} {...props}>
                    {!image && <StyledHouseIcon overlay={overlay} />}
                    {props.children}
                </Container>
            }
        </>
    );
}

export default ListingImage;
