import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import React from 'react';

export function SectionTitle({
    children,
    ...props
}) {
    return (
        <Typography variant="h6" {...props}>
            {children}
        </Typography>
    );
}

export function SectionHeader({
    children,
    style = {},
    ...props
}) {
    const theme = useTheme();

    return (
        <div
            style={{
                padding: theme.spacing(2),
                paddingBottom: 0,
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
}

export function SectionContainer({
    children,
    style = {},
    ...props
}) {
    const theme = useTheme();

    return (
        <div
            style={{
                padding: theme.spacing(2),
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
}

export function Section({
    children,
    style = {},
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
        isIPadUp
    } = useDeviceBreakpoints();

    return (
        <div
            style={{
                background: '#fff',
                boxShadow: isIPadUp && '0px 1px 2px rgba(0, 0, 0, 0.35)',
                borderRadius: isIPadUp && 4,
                borderBottom: isMobile && '1px solid',
                borderBottomColor: isMobile && theme.palette.lr_colors.border_grey,
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
}
