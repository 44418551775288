import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Box } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { useStore } from '../../../../mobx-store';
import ListingImage from '../../../ListingImage';
import { listingImage as listingImageFor } from '../../../../services/listing-helpers';
import _ from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import analytics from '../../../../services/analytics';
import { observer } from 'mobx-react-lite';
import { useCompareNowStoreContext } from '../../store';
import { useMenu } from '../../../../hooks';
import { OverlayMoveMenu } from '../../../../scenes/Board/Card';
import splitAddress from '../../../../services/split-address';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';
import OverlayImageActions from './OverlayImageActions';

const ListingTile = observer(function ListingTile({ listing, removeListing, onLockClick, showLock, canMove =true }) {
    const [menuProps, openMenu, closeMenu] = useMenu();
    const store = useCompareNowStoreContext();
    const match = useRouteMatch();
    const listingImage = listingImageFor(listing);
    const [showLockTooltip, setShowLockTooltip] = useState(false);
    const { LanesStore, boardsStore, UserStore } = useStore();
    const history = useHistory();
    const { activeBoard } = boardsStore;
    const boardPermissions = activeBoard?.userRoleOnBoard;
    const [addressLine1, addressLine2] = splitAddress(listing?.listing_details?.address);
    const canOpen = useMemo(()=> _.defaultTo(listing.canOpen, true), [listing.canOpen]);
    const cardActions = useMemo(()=> _.defaultTo(listing.cardActions, true), [listing.cardActions]);

    const moveToLane = async (e, lane)=> {
        await activeBoard.moveCard(listing.id, lane.id, 0);
    };

    const encodedId = listing.listing_details?.encodedId;

    function onTileClick() {
        if(!canOpen) return;
        analytics.eventTrack('robin_compare_listing_image_click', {
            role: boardPermissions?.label,
            board_card_id: activeBoard?.id,
        });
        if(listing.id) {
            history.push(`${match.url}/cards/${listing.id}/${_.kebabCase(listing.listing_details.address)}?source=compare`);
        } else if(encodedId) {
            history.push(`${match.url}/listings/${encodedId}`);
        }
    };

    return (
        <Box position="relative" display="flex">
            <Box borderRight="solid 1px #E3E3E3" height={218} width={240} display="flex" flexDirection="column">
                <Box position="relative" height={170} style={{ cursor: canOpen && (listing.id || encodedId)  ? 'pointer' : 'auto' }}>
                    {/* if listingImage does not exists fallback to satellite image*/}
                    {listingImage && <ListingImage position="relative" width="100%" height="100%" image={listingImage} onClick={onTileClick}>
                        <OverlayImageActions
                            listing={listing}
                            showLock={showLock}
                            onLockClick={onLockClick}
                            setShowLockTooltip={setShowLockTooltip}
                            store={store}
                            showLockTooltip={showLockTooltip}
                        />
                    </ListingImage>}
                    {!listingImage &&
                    <div onClick={onTileClick} style={{width: 240, height:170}}>
                        <StaticGoogleMaps
                            latitude={listing.listing_details?.lat || listing.listing_details?.latitude}
                            longitude={listing.listing_details?.lon || listing.listing_details?.longitude}
                            width={240}
                            height={170}
                        >
                            <OverlayImageActions
                                listing={listing}
                                showLock={showLock}
                                onLockClick={onLockClick}
                                setShowLockTooltip={setShowLockTooltip}
                                store={store}
                                showLockTooltip={showLockTooltip}
                            />
                        </StaticGoogleMaps>
                    </div>}
                    {cardActions && (
                        <Box
                            position="absolute"
                            bottom={0}
                            right={0}
                            fontSize={30}
                            display="flex"
                            color="#FFFFFF"
                            onClick={(e)=> {
                                e.preventDefault();
                                if(canMove){
                                    openMenu(e);
                                }

                            }}
                        >
                            <IconButton color="inherit" disabled={!canMove}>
                                <MoreHoriz filter="drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5))" />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <Box px={2} flex="1" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Box
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        fontWeight="fontWeightMedium"
                    >
                        {listing.name || listing?.listing_details?.address}
                    </Box>

                    {menuProps.open && canMove && (
                        <OverlayMoveMenu
                            menuOptions={LanesStore?.lanes.filter((lane)=> lane.id !== listing.laneId)}
                            handleMove={moveToLane}
                            menuProps={menuProps}
                            handleCloseMenu={closeMenu}
                            cardAddress={addressLine1}
                            cardName={listing.name}
                            archived={listing.archived}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
});

ListingTile.propTypes = {
    listing: PropTypes.object,
    removeListing: PropTypes.func
};

export default ListingTile;
