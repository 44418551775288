export const fieldsByCategory = {
    Basics: {
        attributes: [
            //we are scoping match scores out of v1 - https://listreports.atlassian.net/browse/UP-2979
            // { name: 'Match score', keyName: 'match_score', type: 'string' },
            { name: 'Price', keyName: 'price', type: 'money' },
            { name: 'Bedrooms', keyName: 'beds', type: 'string' },
            { name: 'Bathrooms', keyName: 'baths', type: 'string' },
            { name: 'Price per sqft', keyName:'price_per_sqft', type: 'money' },
            { name: 'Square feet', keyName: 'home_size', type: 'number' },
            { name: 'Square feet lot', keyName: 'lot_size', type: 'number' },
            { name: 'Property type', keyName: 'type', type: 'string' },
            { name: 'Property sub type', keyName: 'sub_type', type: 'string' },
            { name: 'Days on market', keyName: 'listing_date', type: 'date' }
        ],
        type: 'sideBySide'
    },
    Interior: {
        attributes: [
            { name: 'Level', keyName: 'floors', type: 'string' },
            { name: 'Garage spaces', keyName: 'garages', type: 'string' },
            // { name: 'Attached garage', keyName: undefined, type: 'string' },
            { name: 'Laundry', keyName: undefined, type: 'string' },
            // { name: 'Cooling', keyName: undefined, type: 'string' },
            // { name: 'Heating', keyName: undefined, type: 'string' },
            { name: 'Fireplace', keyName: 'has_fireplace', type: 'boolean' },
            { name: 'Rooms', keyName: 'rooms', type: 'string' },
            // { name: 'Eating area', keyName: undefined, type: 'string' },
            { name: 'Appliances', keyName: 'appliances', type: 'array' },
            // { name: 'Amenities', keyName: undefined, type: 'string' }
        ],
        type: 'sideBySide'
    },
    Exterior: {
        attributes: [
            { name: 'Parking spaces', keyName: 'parking_spaces', type: 'number' },
            // { name: 'Lot Features', keyName: undefined, type: 'string' },
            { name: 'Security Features', keyName: undefined, type: 'string' },
            // { name: 'Sewer', keyName: undefined, type: 'string' },
            { name: 'Exterior Features', keyName: undefined, type: 'string' },
            { name: 'Patio and Porch', keyName: 'has_patio', type: 'boolean' },
            { name: 'Pool', keyName: 'has_pool', type: 'boolean' },
            { name: 'Spa', keyName: 'has_hot_tub', type: 'boolean' }
        ],
        type: 'sideBySide'
    },
    Other: {
        attributes: [
            // { name: 'Special Listing Conditions', keyName: undefined, type: 'string' },
            { name: 'Association Fee', keyName: 'hoa_fees', type: 'associationFee' },
            // { name: 'Association Fee Frequency', keyName: undefined, type: 'string' },
            // { name: 'High School District', keyName: undefined, type: 'string' },
            // { name: 'Senior Community', keyName: undefined, type: 'string' },
            // { name: 'Land Lease', keyName: undefined, type: 'string' },
            // { name: 'MLS Area Major', keyName: undefined, type: 'string' },
            // { name: 'County Or Parish', keyName: undefined, type: 'string' },
            // { name: 'Parcel Number', keyName: undefined, type: 'string' }
        ],
        type: 'sideBySide'
    },
    Notes: {
        type: 'comments'
    }
};

export const sortOptions = [
    //we are scoping match scores out of v1 - https://listreports.atlassian.net/browse/UP-2979
    // {id: 'matchScore', label: 'Match Score'},
    { id: 'listing_details.price', label: 'Price' },
    { id: 'listing_details.baths', label: 'Bathrooms' },
    { id: 'listing_details.beds', label: 'Bedrooms' },
    { id: 'listing_details.price_per_sqft', label: 'Price per sqft' },
    { id: 'listing_details.home_size', label: 'Square feet' },
    { id: 'listing_details.lot_size', label: 'Square feet lot' },
    { id: 'listing_details.type', label: 'Property Type' },
    { id: 'listing_details.listing_date', label: 'Days on market' },
];
