import { useIsInAppBrowser } from 'hooks/useIsInAppBrowser';
import React, { useMemo } from 'react';
import { useGoogleLogin } from 'react-use-googlelogin';

const GoogleAuthContext = React.createContext();
export const GoogleAuthProvider = ({ children })=> {
    const isIAB = useIsInAppBrowser();
    const baseURL = useMemo(()=> {
        return window.location.href.split('/').slice(0, 3).join('/');;
    }, []);
    const googleAuth = useGoogleLogin({
        clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        cookiePolicy: 'single_host_origin',
        uxMode: isIAB ? 'redirect' : 'popup',
        redirectUri: `${baseURL}/oauth/google`,
        fetchBasicProfile: true,
    });

    return (
        <GoogleAuthContext.Provider value={googleAuth}>
            {children}
        </GoogleAuthContext.Provider>
    );
};

export const useGoogleAuth = ()=> React.useContext(GoogleAuthContext);
