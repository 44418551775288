import React from 'react';
import { useTheme } from '@material-ui/styles';
import { LogoRIcon, LogoRMarkIcon } from '../LRIcons/logoR';
import { useResponsiveConfig } from '../../hooks';

export function RobinLogo({
    width = '89px',
    height,
    color,
    variant = 'full', // full | icon
}) {
    width = useResponsiveConfig(width);
    const theme = useTheme();
    color = color || theme.palette.lr_colors.brick;

    return (
        <span
            style={{
                display: 'inline-block',
                lineHeight: 0,
            }}
        >
            {variant === 'icon' ? (
                <LogoRMarkIcon
                    style={{
                        width: 35,
                        height: 37,
                        color,
                    }}
                />
            ) : (
                <LogoRIcon
                    style={{
                        width,
                        height: height || `${Math.round(parseInt(width) * 0.3146)}px`,
                        color,
                    }}
                />
            )}
        </span>
    );
}
