import React, { useEffect, useState, useRef, useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import { Place } from '@material-ui/icons';
import moment from 'moment';
import { useStore, useActiveBoard } from '../../../mobx-store';
import { useTheme } from '@material-ui/core/styles';
import ListingImage from '../../../components/ListingImage';
import LRLink from '../../../components/LRLink';
import { useGetBoardOpenHouses } from 'hooks/data/boards/open-house/useGetBoardOpenHouses';

function boundsForCoordinates(coords) {
    var bounds = new window.google.maps.LatLngBounds();
    for(const coord of coords) {
        bounds.extend(coord);
    }

    return {
        ne: bounds.getNorthEast()
            .toJSON(),
        sw: bounds.getSouthWest()
            .toJSON(),
    };
}

const MapListingPreview = observer(()=> {
    const { OpenHouseCalendarStore } = useStore();
    const listing = OpenHouseCalendarStore.selectedListingMapMarker;
    const cardId = OpenHouseCalendarStore.selectedListingCardId;
   
    if(!listing) {
        return null;
    }

    return (
        <Box>
            {listing.open_houses.filter((l)=> moment(l.date).isValid()).map((openHouseInfo, index)=> {
                const momentStartDate = moment(openHouseInfo.start_time_iso);
                const momentEndDate = moment(openHouseInfo.end_time_iso);
                const openHouseDate = moment(openHouseInfo.date);

                const now = moment();
                // if(!openHouseDate.isBefore(now)) {
                //     return null;
                // }

                const headshot =
                    listing && listing.photos && listing.photos.length
                        ? listing.photos[0]
                        : null;
                return (
                    <Box
                        component={LRLink}
                        unstyled
                        to={`calendar/cards/${cardId}`}
                        key={index}
                        display="flex"
                        height="88px"
                        alignItems="center"
                    >
                        <Box height="100%">
                            <ListingImage width="110px" height="100%" image={headshot}></ListingImage>
                        </Box>
                        <Box width="190px" px={2}>
                            <Box fontSize="h6.fontSize">
                                {momentStartDate.isValid() ? momentStartDate.format('ha') : 'N/A'} -{' '}
                                {momentEndDate.isValid() ? momentEndDate.format('ha') : 'N/A'}
                            </Box>
                            <Box fontSize="body2.fontSize" color={false ? 'white' : 'text.secondary'}>
                                {listing.address}
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
});

const PlaceWrapper = ({ children })=> {
    return children;
};

export const Map = observer(()=> {
    const theme = useTheme();
    const { OpenHouseCalendarStore } = useStore();
    const highlightedOpenHouse = OpenHouseCalendarStore.highlightedOpenHouse;
    const mapsContainerRef = useRef(null);      
    const selectedListingMapMarker = OpenHouseCalendarStore.selectedListingMapMarker;
    const activeBoard = useActiveBoard(); 
    const currentFilter = OpenHouseCalendarStore.openHouseFilter;
    const laneFilter = OpenHouseCalendarStore.listingsLaneFilter;
    const { data, isLoading } = useGetBoardOpenHouses(activeBoard?.id, currentFilter, laneFilter);

    const listingsWithCoordinates = useMemo(()=> {
        if(!data) return [];
        OpenHouseCalendarStore.setSelectedListingMapMarker(null);

        const filteredData = data.filter((v, i, a)=> a.findIndex((t)=> (t.listing.id === v.listing.id)) === i);
        
        return filteredData.map((openHouse)=> {
            if(openHouse.listing.lat && openHouse.listing.lon) {
                return openHouse;
            }
        });
    }, [data]);

    const coordinates = useMemo(()=> {
        return listingsWithCoordinates.map(({ listing })=> ({
            lat: listing.lat,
            lng: listing.lon,
        }));
    }, [listingsWithCoordinates]);

    window.coordinates = coordinates;

    const [center, setCenter] = useState([33.78724, -117.85496]);
    const [zoom, setZoom] = useState(20);

    const handleMarkerClick = (listing, cardId)=> {
        if(selectedListingMapMarker === listing) {
            OpenHouseCalendarStore.setSelectedListingMapMarker(null);
        } else {
            OpenHouseCalendarStore.setSelectedListingMapMarker(listing, cardId);
            setCenter([listing.lat, listing.lon]);
        }
    };

    useEffect(()=> {
        if(!mapsContainerRef.current) {
            return;
        }
        if(!coordinates.length) {
            return;
        }

        if(coordinates.length === 1) {
            setCenter(coordinates[0]);
            setZoom(16);
        } else {
            const bounds = boundsForCoordinates(coordinates);
            let { center: newCenter, zoom: newZoom } = fitBounds(bounds, {
                width: mapsContainerRef.current.offsetWidth,
                height: mapsContainerRef.current.offsetHeight,
            });
            setCenter(newCenter);
            setZoom(newZoom);
        }
    }, [coordinates]);

    return (
        <Box height="100%" width="100%" style={{ boxSizing: 'border-box' }}>
            <Box height="100%" width="100%" ref={mapsContainerRef} style={{ boxSizing: 'border-box' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: 'us' }}
                    zoom={zoom}
                    center={center}
                    options={{
                        draggable: true,
                        zoomControl: true,
                        fullscreenControl: false,
                        disableDefaultUI: true,
                    }}
                >
                    {listingsWithCoordinates.map(({ listing, cardId })=> (
                        <PlaceWrapper
                            key={listing.id}
                            lat={listing.lat}
                            lng={listing.lon}
                        >
                            <Box
                                className="cursor-pointer"
                                onClick={()=> handleMarkerClick(listing, cardId)}
                                color={
                                    (highlightedOpenHouse && listing.id === highlightedOpenHouse.listingId) ||
                                    (selectedListingMapMarker && listing.id === selectedListingMapMarker.id)
                                        ? theme.palette.primary.main
                                        : 'inherit'
                                }
                                width={0}
                                height={0}
                            >
                                <Place></Place>
                            </Box>
                        </PlaceWrapper>
                    ))}

                    {selectedListingMapMarker && (
                        <PlaceWrapper
                            lat={selectedListingMapMarker.lat}
                            lng={selectedListingMapMarker.lon}
                        >
                            <Box
                                position="absolute"
                                bottom="calc(100% + 4px)"
                                left="0"
                                bgcolor="white"
                                color="black"
                                style={{ transform: 'translateX(calc(-50% + 10px))' }}
                            >
                                <MapListingPreview></MapListingPreview>
                            </Box>
                        </PlaceWrapper>
                    )}
                </GoogleMapReact>
            </Box>
        </Box>
    );
});
