// eslint-disable-next-line
import Logger from 'js-logger';
import React from 'react';
import styled from 'styled-components';
import { Box, Typography, Avatar } from '@material-ui/core';
import { COLOR_GREY_LIGHT } from '../../../constants';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../mobx-store';
import { withTheme, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import LRTooltip from '../../LRTooltip';
import { useResponsiveBreakpoint } from '../../../hooks';

const BoldTypography = styled(Typography)`
    font-weight: bold;
`;
const WarningBadge = withTheme(styled(Avatar)`
    color: #fff;
    background: ${({ theme })=> theme.palette.secondary.main};
    font-size: 1rem;
    width: 24px;
    height: 24px;
`);
const IIcon = styled.div`
    position: relative;
    top: 1px;
    left: 0.5px;
`;

function OtherCautionTooltipTitle({ children, ...props }) {
    const theme = useTheme();

    return (
        <>
            <Typography component="h2" variant="h5" align="center">
                Caution
            </Typography>
            <Box width="24px" height="2px" bgcolor={theme.palette.secondary.main} margin="18px auto" />
            {children}
        </>
    );
}

function PaymentBreakdown({ ...props }) {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const { MortgageCalculatorStore } = useStore();
    const dataPoints = [
        {
            label: 'Principal & interest',
            value: `$${MortgageCalculatorStore.formattedPrincipalAndInterest || 0}`
        },
        {
            label: 'Property taxes',
            value: `$${MortgageCalculatorStore.formattedPropertyTaxes || 0}`
        },
        {
            label: 'HOA dues',
            value: `$${MortgageCalculatorStore.formattedHoaDues || 0}`
        },
        {
            label: 'Home insurance',
            value: `$${MortgageCalculatorStore.formattedHomeInsurance || 0}`
        },
        {
            label: 'Other',
            value: `$${MortgageCalculatorStore.formattedOther || 0}`,
            badge: {
                show:
                    MortgageCalculatorStore.downPaymentPercent < 20 &&
                    (MortgageCalculatorStore.other || 0) < MortgageCalculatorStore.mortgageInsurance,
                message: (
                    <Box>
                        A borrower providing less than 20% as a down payment is required to purchase mortgage insurance.
                        <br />
                        <br />
                        This protects the lender against loss if the borrower defaults on their loan. In general,
                        mortgage insurance rates range between 0.5%-1% of the actual loan amount. Your actual rate may
                        vary.
                    </Box>
                )
            }
        }
    ];

    return (
        <Box bgcolor={COLOR_GREY_LIGHT} {...props}>
            <Box pt={12/8} pb={14/8} px={3}>
                {dataPoints.map((dataPoint, i)=> (
                    <Box key={i} display="flex" justifyContent="space-between" margin="16px 0px" position="relative">
                        <Typography>{dataPoint.label}</Typography>

                        <BoldTypography>{dataPoint.value}</BoldTypography>

                        {_.get(dataPoint, 'badge.show', false) && (
                            <Box position="absolute" left="-37px">
                                <LRTooltip
                                    arrow
                                    disableFocusListener
                                    disableTouchListener
                                    title={
                                        <OtherCautionTooltipTitle>{dataPoint.badge.message}</OtherCautionTooltipTitle>
                                    }
                                >
                                    <WarningBadge>
                                        <IIcon>i</IIcon>
                                    </WarningBadge>
                                </LRTooltip>
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default React.memo(observer(PaymentBreakdown));
