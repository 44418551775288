import React, { useEffect, useState } from 'react';
import LRModal from 'components/LRModal';
import { LRPhoneInput } from 'components/LRPhoneInput';
import LRButton from 'components/LRButton';
import { useForm } from 'react-hook-form';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Box, CircularProgress, IconButton, useTheme } from '@material-ui/core';
import { useStore } from 'mobx-store';
import { RobinLogo } from 'components/RobinLogo';
import { Verification } from '../../../../scenes/Login/Verification';
import { benoitApi } from 'apis';
import analytics from 'services/analytics';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { useResponsiveBreakpoint } from 'hooks';

const StyledButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
        color: ${({ theme })=> theme.palette.lr_colors.grey_dark};
    }
`);

export function VerifyPhoneModal({ open, onClose, onVerificationSuccessful }) {
    const theme = useTheme();
    const rfhMethods = useForm({ mode: 'onChange' });
    const { handleSubmit, errors, formState, setError } = rfhMethods;
    const { UserStore, GlobalSnackbarStore } = useStore();
    const { user } = UserStore;
    const [state, setState] = useState(user.phone ? 'verifyPhone' : 'addPhone');
    const [submitting, setSubmitting] = useState(false);
    const [token, setToken] = useState(null);
    const isXs = useResponsiveBreakpoint('xs');

    useEffect(()=> {
        async function getToken() {
            try {
                const { token } = await benoitApi.requestLogin('phone', user.phone);
                setToken(token);
            } catch (err) {
                let message = 'Oops 😨, something went wrong!';

                const errorMessage = _.get(err, 'response.data.errors[0].detail');
                if(_.includes(errorMessage, 'is not a valid phone number')){
                    message = 'Oops 😨, Your phone number is not valid!';
                }

                GlobalSnackbarStore.show(message, {autoHideDuration: 3000});
                console.error('Request code error', user.phone, err);
                onClose();
            }
        }
        if(open && !token && state === 'verifyPhone' && user.phone) {
            getToken();
        }
    }, [open, user.phone]);

    async function submitForm(data) {
        const { phone } = data;
        setSubmitting(true);
        analytics.eventTrack('robin_mobile_phone_entered', {
            mobile_phone: phone,
            Source: 'notification_settings',
        });
        try {
            await benoitApi.updateUser({ id: user.id, phone });
            user.phone = phone;
            setState('verifyPhone');
        } catch (err) {
            setError('phone', 'manual', err?.response?.data?.errors[0]?.detail || 'Something went wrong!');
        }
        setSubmitting(false);
    }

    return (
        <LRModal open={open} onClose={onClose}>
            <>
                {isXs && <div style={{
                    width: '100%',
                    position: 'absolute'
                }}>
                    <IconButton variant="contained" onClick={onClose} style={{float: 'right', margin: theme.spacing(1)}}>
                        <CloseIcon />
                    </IconButton>
                </div>}
                <Box maxWidth={['none']} mx="auto" display="flex" flexDirection="column" alignItems="center">
                    <Box mb={-4} mt={6}>
                        <RobinLogo width={101} />
                    </Box>
                    {state === 'addPhone' && (
                        <>
                            <Box mx={2}>
                                <Box mt={5} fontSize={32} fontWeight={500} lineHeight="40px" textAlign="center">
                                Enter your mobile phone number
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Box width="330px">
                                    <Box mt={2} textAlign="center">
                                    In order to receive notifications through text, enter your mobile number below
                                    </Box>
                                    <form onSubmit={handleSubmit(submitForm)}>
                                        <Box mt={5 / 2}>
                                            <LRPhoneInput
                                                rfhMethods={rfhMethods}
                                                rules={{ required: true }}
                                                name="phone"
                                                inputProps={{
                                                    height: '48px',
                                                    width: '100%',
                                                    message: errors?.phone?.message,
                                                    placeholder: 'Enter your mobile phone number',
                                                    fontSize: theme.typography.body1.fontSize,
                                                    tone: errors.phone && 'error',
                                                }}
                                            // onChange={cleanErrors}
                                            />
                                        </Box>
                                        <Box mt={2}>
                                            <StyledButton
                                                type="submit"
                                                value="Submit"
                                                disabled={!formState.isValid || submitting}
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    height: '48px',
                                                    fontWeight: 'normal',
                                                }}
                                            >
                                                {!submitting && 'Continue'}
                                                {submitting && <CircularProgress size={24} color="secondary" />}
                                            </StyledButton>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        </>
                    )}
                    {state === 'verifyPhone' && (
                        <Box width="330px" display="flex" flexDirection="column" alignItems="center">
                            {!token && (
                                <Box display="flex" justifyContent="center" mt={8}>
                                    <CircularProgress size={24} color="secondary" />
                                </Box>
                            )}
                            {token && (
                                <Verification
                                    userInfo={user}
                                    token={{ token }}
                                    onVerificationSuccessful={onVerificationSuccessful}
                                    headerText="Verify your account"
                                    subTitleText="We just sent you a 4-digit verification code via text message. Please enter it below."
                                    mode="verify"
                                    method="phone"
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </>
        </LRModal>
    );
}
