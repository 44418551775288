import React from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import LRButton from '../../../components/LRButton';
import { useNameHomeStoreContext } from '../store';
import { observer } from 'mobx-react-lite';

function NameHomeFooter() {
    const nameHomeStore = useNameHomeStoreContext();
    return (
        <Hidden smUp>
            <Box
                position="fixed"
                bottom="0"
                height={64}
                width="100%"
                borderColor="lr_colors.border_grey"
                borderTop={1}
                borderBottom={0}
                borderLeft={0}
                borderRight={0}
                style={{ backgroundColor: '#FFFFFF' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={2}
                py={1.5}
            >
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Box width={1}>
                            <LRButton
                                type="button"
                                variant="outlined"
                                fullWidth
                                onClick={nameHomeStore.reset}
                                disabled={nameHomeStore.isResetDisabled}
                            >
                                Reset
                            </LRButton>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box width={1}>
                            <LRButton
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className="tour-target-listpack-save"
                                onClick={nameHomeStore.save}
                                disabled={nameHomeStore.isSaveDisabled}
                            >
                                Save
                            </LRButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Hidden>
    );
}

export default observer(NameHomeFooter);
