import React, { useState } from 'react';
import { useStore } from '../../../mobx-store';
import { AddAnyHomeHeaderStyle, AddAnyHomeHeaderLabel } from '../style';
import { COLOR_BORDER_GREY, COLOR_GREY_LIGHT } from '../../../constants';
import { useResponsiveBreakpoint } from '../../../hooks';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import LRButton from '../../../components/LRButton';
import { useOnboarding } from '../../../components/Onboarding';
import { useBoardCollaborations } from '../../../hooks/useBoardCollaborations';
import analytics from '../../../services/analytics';
import { observer } from 'mobx-react-lite';
import Logger from 'js-logger';


const DEST_COLUMN_ID = '1';

function AddAnyHomeHeader({handleClose, setAddingListings, addingListings}) {
    const { AddAnyHomeStore, boardsStore, LanesStore, UserStore } = useStore();
    const {
        matchedListings,
        notes,
    } = AddAnyHomeStore;
    const activeBoard = boardsStore.activeBoard;
    const matchedListingsLength = matchedListings?.length;
    const addToBoardLabel =
        matchedListingsLength ? `Add ${matchedListingsLength} to my board` : 'Add to my board';

    const onboarding = useOnboarding();
    const {
        addBoardCollaboration,
    } = useBoardCollaborations();

    const isXs = useResponsiveBreakpoint('xs');

    // Create Board Card
    async function createBoardCards() {
        if(!matchedListingsLength || addingListings) return null;

        setAddingListings(true);
        let boardCards;

        try {
            boardCards = await activeBoard.createBoardCards(matchedListings, DEST_COLUMN_ID);
        } catch (err) {
            setAddingListings(false);
            return Logger.error(err);
        }

        // Create Board Collaboration notes for any listings with a note
        try {
            await createBoardCollaborations(boardCards);
        } catch (err) {
            setAddingListings(false);
            return Logger.error(err);
        }

        if(onboarding.isFirstRunOnboarding) {
            analytics.eventTrack('robin_fre_onboarding_add_any_home_submit');
        } else {
            analytics.eventTrack('robin_add_any_home_submit');
        }

        const movedBoardCards = boardCards?.map((boardCard)=>
            setTimeout(()=> {
                LanesStore.moveListing(boardCard.id, boardCard.columnId, boardCard.columnIndex, "1", 0);
            })
        );

        setAddingListings(false);
        handleClose();
    }

    // Create Board Collaboration
    async function createBoardCollaborations(boardCards) {
        const listingsCount = boardCards?.length;

        if(!listingsCount) return;

        const column_name = LanesStore.lanesById[DEST_COLUMN_ID]?.name;
        const boardCollaborations = [];
        const userId = UserStore?.user?.id;
        const boardId = activeBoard?.id;
        const collabModel = 'BoardCard';
        const collabType = 'created_card';
        const noteModel = 'BoardCard';
        const noteType = 'comment';

        // Create collaboration

        const collabData = boardCards.map((card)=> {
            return {
                model: collabModel,
                boardId: boardId,
                itemId: card.id,
                userId: userId,
                createdById: userId,
                updatedById: userId,
                content: {
                    address: card?.address,
                    card_name: card?.name || card?.address,
                    column_name,
                    column_id: DEST_COLUMN_ID,
                    listing_count: listingsCount,
                },
                type: collabType,
            };
        });

        const response = await addBoardCollaboration(collabData);
        boardCollaborations.push(response);

        // Notes
        const whileCaseArray = [...boardCards];
        let whileCaseBoardCard = whileCaseArray.pop();

        while(whileCaseBoardCard) {
            const message = notes[`listing_note_${whileCaseBoardCard.content.encoded_id}`];

            if(message) {
                const trimmedMessage = message.trim();
                const msg = {
                    boardId: boardId,
                    itemId: whileCaseBoardCard.id,
                    content: {
                        message: trimmedMessage,
                        suppress_notifications: true,
                    },
                    type: noteType,
                    visibleToAffiliate: true,
                    userId: userId,
                    createdById: userId,
                    updatedById: userId,
                    model: noteModel,
                };

                const response = await addBoardCollaboration(msg);
                boardCollaborations.push(response);
            }

            whileCaseBoardCard = whileCaseArray.pop();
        }

        return boardCollaborations;
    }

    return (
        <AddAnyHomeHeaderStyle
            pb={['18px', '22px']}
            borderBottom={isXs ? `1px solid ${COLOR_BORDER_GREY}` : null}
        >
            {isXs && (
                <>
                    <Box display="flex" width="100%" justifyContent="space-between">
                        <Box display="flex" width="40px"></Box>
                        <Typography variant="body1">
                            <AddAnyHomeHeaderLabel>
                                Add any home
                            </AddAnyHomeHeaderLabel>
                        </Typography>
                        <Box display={"flex"} pr={"16px"}>
                            <IconButton onClick={handleClose} style={{padding: 0}}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        position="fixed"
                        bottom="0"
                        py={['12px', 0]}
                        px={['16px', 0]}
                        width="100%"
                        bgcolor={COLOR_GREY_LIGHT}
                        borderTop={isXs ? `1px solid ${COLOR_BORDER_GREY}` : null}
                        zIndex={1}
                    >
                        <LRButton
                            color="primary"
                            variant="contained"
                            onClick={createBoardCards}
                            disabled={addingListings || !matchedListingsLength}
                            fullWidth
                        >
                            {addToBoardLabel}
                        </LRButton>
                    </Box>
                </>
            )}

            {!isXs && (
                <>
                    <Box display="flex" pr={'167px'}>
                        <Box display="flex" width="40px"></Box>
                        <Typography variant="body1">
                            <AddAnyHomeHeaderLabel>
                                Add any home
                            </AddAnyHomeHeaderLabel>
                        </Typography>
                    </Box>
                    <Box display={"flex"} pr={"24px"}>
                        <LRButton
                            color="primary"
                            variant="contained"
                            onClick={createBoardCards}
                            disabled={addingListings || !matchedListingsLength}
                        >
                            {addToBoardLabel}
                        </LRButton>
                    </Box>
                </>
            )}
        </AddAnyHomeHeaderStyle>
    );
}

export default observer(AddAnyHomeHeader);
