// eslint-disable-next-line
import Logger from 'js-logger';
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle';
import { GridList, GridListTile, Typography, Grid, withWidth, Collapse, Box } from '@material-ui/core';
import addCommas from '../../../services/add-commas';
import dom from '../../../services/dom';
import { Hotel, Bathtub, Build, LocalOffer, Texture, Straighten } from '@material-ui/icons';
import Details from './Details';
import moment from 'moment';
import LRButton from '../../../components/LRButton';
import LRTabs, { LRTab } from '../../../components/LRTabs';
import analytics from '../../../services/analytics';
import _ from 'lodash';
import { COLOR_BLUE_LINK, COLOR_GREY_NERO } from '../../../constants';

const StyledButton = styled(LRButton)`
    color: ${COLOR_BLUE_LINK};
`;

const HighlightContainer = styled(({ ...props }) => <GridListTile classes={{ tile: 'highlight-tile' }} {...props} />)`
    & .highlight-tile {
        background: #f6f6f6;
    }
`;

const StyledGridList = styled(GridList)`
    flexwrap: nowrap;
    transform: translateZ(0);
    justify-content: center;
`;

const SyledHighlightGrid = styled(Grid)`
    height: 100%;
`;

const TabsWrapper = styled.div`
    margin-top: 36px;
`;

const ViewButtonWrapper = styled.div`
    text-align: center;
    margin-top: 36px;
`;

const StyledTabLabelTypography = styled(Typography)`
    font-size: 1.125rem;
    color: #000000;
    .Mui-selected & {
        font-weight: bold;
    }
`;

class PropertyDetails extends Component {
    constructor({ listingDetails, onContactAgentClick }) {
        super();

        this.state = {
            selectedTab: 'basic',
            basicDetails: [],
            interiorDetails: [],
            exteriorDetails: [],
            otherDetails: [],
        };

        // Bind Functions
        this.renderDetailHighlights = this.renderDetailHighlights.bind(this);
        this.renderTabs = this.renderTabs.bind(this);
        this.renderSelectedTab = this.renderSelectedTab.bind(this);
        this.renderViewButton = this.renderViewButton.bind(this);
        this.handleOnTabChange = this.handleOnTabChange.bind(this);
        this.handleOnViewClick = this.handleOnViewClick.bind(this);
        this.onContactAgentClick = onContactAgentClick;
    }

    transformDetails(details) {
        let _transformedDetails = [];
        let _detail = [];

        for (let i = 0; i < details.length; i++) {
            _detail.push(details[i]);
            if (_detail.length == 2 || i === details.length - 1) {
                _transformedDetails.push(_detail);
                _detail = [];
            }
        }

        return _transformedDetails;
    }

    getBasicDetails(listingDetails) {
        let _basicDetails = [];

        if (listingDetails.baths_half && listingDetails.baths_half > 0) {
            _basicDetails.push({
                label: 'Baths - Half',
                value: listingDetails.baths_half,
            });
        }

        if (listingDetails.baths_one_quarter && listingDetails.baths_one_quarter > 0) {
            _basicDetails.push({
                label: 'Baths - Quarter',
                value: listingDetails.baths_one_quarter,
            });
        }

        if (listingDetails.baths_full && listingDetails.baths_full > 0) {
            _basicDetails.push({
                label: 'Baths - Full',
                value: listingDetails.baths_full,
            });
        }

        if (listingDetails.listing_date || listingDetails.last_updated) {
            let date = listingDetails.listing_date || listingDetails.last_updated;
            // if(!date) {
            //     date =
            //     listingDetails.last_updated)
            //         .format('YYYY-MM-DD');
            // }
            _basicDetails.push({
                label: 'Days on market',
                value: dom(date),
            });
        }
        if (listingDetails.expenses && listingDetails.expenses.length > 0) {
            _basicDetails.push({
                label: 'Expenses',
                value: _.map(listingDetails.expenses, 'ExpenseCategory').join(', '),
            });
        }
        if (listingDetails.listing_date) {
            _basicDetails.push({
                label: 'List date',
                value: moment(listingDetails.listing_date).format('MM/DD/YY'),
            });
        }
        if (listingDetails.sub_type) {
            _basicDetails.push({
                label: 'Property sub type',
                value: listingDetails.sub_type,
            });
        }
        if (listingDetails.type) {
            _basicDetails.push({
                label: 'Property type',
                value: listingDetails.type,
            });
        }
        if (listingDetails.mls_number) {
            _basicDetails.push({
                label: 'MLS #',
                value: listingDetails.mls_number,
            });
        }
        if (listingDetails.active) {
            _basicDetails.push({
                label: 'Status',
                value: 'Active',
            });
        }

        if (listingDetails.municipality) {
            _basicDetails.push({
                label: 'Municipality',
                value: listingDetails.municipality,
            });
        }

        return this.transformDetails(_basicDetails);
    }

    getInteriorDetails(listingDetails) {
        let _interiorDetails = [];

        if (listingDetails.appliances && listingDetails.appliances.length > 0) {
            _interiorDetails.push({
                label: 'Appliances',
                value: listingDetails.appliances.join(', '),
            });
        }
        if (listingDetails.has_attic) {
            _interiorDetails.push({
                label: 'Attic',
                value: 'Yes',
            });
        }
        if (listingDetails.has_ceiling_fan) {
            _interiorDetails.push({
                label: 'Ceiling Fan(s)',
                value: 'Yes',
            });
        }
        if (listingDetails.cooling && listingDetails.cooling.length > 0) {
            _interiorDetails.push({
                label: 'Cooling',
                value: listingDetails.cooling.join(', '),
            });
        }
        if (listingDetails.has_double_pane_windows) {
            _interiorDetails.push({
                label: 'Double Pane Windows',
                value: 'Yes',
            });
        }
        if (listingDetails.heating && listingDetails.heating.length > 0) {
            _interiorDetails.push({
                label: 'Heating',
                value: listingDetails.heating.join(', '),
            });
        }
        if (listingDetails.has_fireplace) {
            _interiorDetails.push({
                label: 'Fireplace',
                value: 'Yes',
            });
        }
        if (listingDetails.floor_coverings) {
            _interiorDetails.push({
                label: 'Floor Coverings',
                value: listingDetails.floor_coverings,
            });
        }
        if (listingDetails.has_jetted_bathtub) {
            _interiorDetails.push({
                label: 'Jetted Bath Tub',
                value: 'Yes',
            });
        }
        if (listingDetails.rooms) {
            _interiorDetails.push({
                label: 'Rooms',
                value: listingDetails.rooms,
            });
        }
        if (listingDetails.has_sauna) {
            _interiorDetails.push({
                label: 'Sauna',
                value: 'Yes',
            });
        }
        if (listingDetails.has_security_system) {
            _interiorDetails.push({
                label: 'Security System',
                value: 'Yes',
            });
        }
        if (listingDetails.has_skylight) {
            _interiorDetails.push({
                label: 'Skylight',
                value: 'Yes',
            });
        }
        if (listingDetails.floors) {
            _interiorDetails.push({
                label: 'Stories',
                value: listingDetails.floors,
            });
        }
        if (listingDetails.has_vaulted_ceiling) {
            _interiorDetails.push({
                label: 'Vaulted Ceiling',
                value: 'Yes',
            });
        }
        if (listingDetails.has_wet_bar) {
            _interiorDetails.push({
                label: 'Wet Bar',
                value: 'Yes',
            });
        }

        return this.transformDetails(_interiorDetails);
    }

    getExteriorDetails(listingDetails) {
        let _exteriorDetails = [];

        if (listingDetails.parking_spaces) {
            _exteriorDetails.push({
                label: '# Parking Spaces',
                value: parseInt(listingDetails.parking_spaces),
            });
        }

        if (listingDetails.has_bbq_area) {
            _exteriorDetails.push({
                label: 'Barbecue Area',
                value: 'Yes',
            });
        }
        if (listingDetails.has_deck) {
            _exteriorDetails.push({
                label: 'Deck',
                value: 'Yes',
            });
        }
        if (listingDetails.has_garden) {
            _exteriorDetails.push({
                label: 'Garden',
                value: 'Yes',
            });
        }
        if (listingDetails.has_gated_entry) {
            _exteriorDetails.push({
                label: 'Gated Entry',
                value: 'Yes',
            });
        }
        if (listingDetails.has_hot_tub) {
            _exteriorDetails.push({
                label: 'Hot Tub',
                value: 'Yes',
            });
        }
        if (listingDetails.has_lawn) {
            _exteriorDetails.push({
                label: 'Lawn',
                value: 'Yes',
            });
        }
        if (listingDetails.has_patio) {
            _exteriorDetails.push({
                label: 'Patio',
                value: 'Yes',
            });
        }
        if ((listingDetails.tags && listingDetails.tags.indexOf('with_pool') > -1) || listingDetails.has_pool) {
            _exteriorDetails.push({
                label: 'Pool',
                value: 'Yes',
            });
        }
        if (listingDetails.has_porch) {
            _exteriorDetails.push({
                label: 'Porch',
                value: 'Yes',
            });
        }
        if (listingDetails.roof_types && listingDetails.roof_types.length > 0) {
            _exteriorDetails.push({
                label: 'Roof',
                value: listingDetails.roof_types,
            });
        }
        if (listingDetails.has_rv_parking) {
            _exteriorDetails.push({
                label: 'RV Parking',
                value: 'Yes',
            });
        }
        if (listingDetails.has_sprinkler_system) {
            _exteriorDetails.push({
                label: 'Sprinkler System',
                value: 'Yes',
            });
        }
        if (listingDetails.view_types && listingDetails.view_types.length > 0) {
            _exteriorDetails.push({
                label: 'View',
                value: listingDetails.view_types.join(', '),
            });
        }
        if (listingDetails.is_waterfront) {
            _exteriorDetails.push({
                label: 'Waterfront',
                value: 'Yes',
            });
        }

        return this.transformDetails(_exteriorDetails);
    }

    getOtherDetails(listingDetails) {
        let _otherDetails = [];

        if (listingDetails.has_disabled_access) {
            _otherDetails.push({
                label: 'Disabled Access',
                value: 'Yes',
            });
        }
        if (listingDetails.has_dock) {
            _otherDetails.push({
                label: 'Dock',
                value: 'Yes',
            });
        }
        if (listingDetails.has_elevator) {
            _otherDetails.push({
                label: 'Elevator',
                value: 'Yes',
            });
        }
        if (listingDetails.has_greenhouse) {
            _otherDetails.push({
                label: 'Greenhouse',
                value: 'Yes',
            });
        }
        if (listingDetails.has_pond) {
            _otherDetails.push({
                label: 'Pond',
                value: 'Yes',
            });
        }

        return this.transformDetails(_otherDetails);
    }

    setListingsDetails() {
        const listingDetails = this.props.listingDetails;

        // BasicDetails
        const _basicDetails = this.getBasicDetails(listingDetails);

        // InteriorDetails
        const _interiorDetails = this.getInteriorDetails(listingDetails);

        // ExteriorDetails
        const _exteriorDetails = this.getExteriorDetails(listingDetails);

        // OtherDetails
        const _otherDetails = this.getOtherDetails(listingDetails);

        this.setState({
            ...this.state,
            selectedTab: 'basic',
            basicDetails: _basicDetails,
            interiorDetails: _interiorDetails,
            exteriorDetails: _exteriorDetails,
            otherDetails: _otherDetails,
        });
    }

    componentDidMount() {
        this.setListingsDetails();
    }

    componentDidUpdate(prevProps) {
        if (this.props.listingDetails.id !== prevProps.listingDetails.id) {
            this.setListingsDetails();
        }
    }

    handleOnTabChange(e, value) {
        const { listpack, listing, user, agent, requestSource, reportId } = this.props;

        analytics.eventTrack('listpacks_consumer_listingpage_property_details_category_click', {
            address: listing.address,
            'listing id': listing.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            Category: value,
            source: requestSource,
            report_id: reportId,
        });
        this.setState({
            selectedTab: value,
        });
    }

    handleOnViewClick() {
        const { listpack, listing, user, agent, expand, onExpandClick, requestSource, reportId } = this.props;

        if (!expand) {
            analytics.eventTrack('listpacks_consumer_listingpage_property_details_view_all_click', {
                address: listing.address,
                'listing id': listing.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
                source: requestSource,
                report_id: reportId,
            });
        }
        onExpandClick();
    }

    lotSizeValue(lotSize) {
        return lotSize >= 10000
            ? Number(lotSize / 43560)
                .toFixed(2)
                .toString()
            : addCommas(lotSize);
    }

    lotSizeLabel(lotSize) {
        return lotSize >= 10000 ? 'acre lot' : 'sqft lot';
    }

    renderDetailHighlights(listingDetails, width) {
        const data = [
            {
                label: 'beds',
                value: listingDetails.beds,
                icon: <Hotel />,
            },
            {
                label: 'baths',
                value: listingDetails.baths,
                icon: <Bathtub />,
            },
            {
                label: 'sqft',
                value: addCommas(listingDetails.home_size),
                icon: <Straighten />,
            },
            {
                label: this.lotSizeLabel(listingDetails.lot_size),
                value: this.lotSizeValue(listingDetails.lot_size),
                icon: <Texture />,
            },
            {
                label: 'year built',
                value: listingDetails.year,
                icon: <Build />,
            },
            {
                label: 'price/sqft',
                value:
                    listingDetails.price > 0 && listingDetails.home_size > 0
                        ? `$${addCommas(Math.round(listingDetails.price / listingDetails.home_size))}`
                        : 'N/A',
                icon: <LocalOffer />,
            },
        ];

        return (
            <StyledGridList
                cols={width === 'xs' ? 3 : 6}
                cellHeight={108}
                spacing={16}
                style={{ color: COLOR_GREY_NERO }}
            >
                {data.map((detail) => {
                    if (detail.value == null) {
                        return undefined;
                    }
                    return (
                        <HighlightContainer key={detail.label}>
                            <SyledHighlightGrid container direction="column" justify="center" alignItems="center">
                                <Typography variant="h6" component="h3">
                                    {detail.value}
                                </Typography>
                                <Box fontSize={16}>{detail.label}</Box>
                                {detail.icon}
                            </SyledHighlightGrid>
                        </HighlightContainer>
                    );
                })}
            </StyledGridList>
        );
    }

    renderTabs() {
        const tabs = [
            {
                label: 'Basic',
                value: 'basic',
                visible: true,
            },
            {
                label: 'Interior',
                value: 'interior',
                visible: this.state.interiorDetails.length > 0,
            },
            {
                label: 'Exterior',
                value: 'exterior',
                visible: this.state.exteriorDetails.length > 0,
            },
            {
                label: 'Other',
                value: 'other',
                visible: this.state.otherDetails.length > 0,
            },
        ];

        return (
            <TabsWrapper>
                <LRTabs
                    variant="fullWidth"
                    value={this.state.selectedTab}
                    onChange={this.handleOnTabChange}
                    style={{ borderBottom: '1px solid #ddd' }}
                    textColor="primary"
                >
                    {tabs
                        .filter((tab) => tab.visible)
                        .map((tab) => {
                            const Label = (
                                <StyledTabLabelTypography component="h3">{tab.label}</StyledTabLabelTypography>
                            );

                            return <LRTab key={tab.value} label={Label} value={tab.value} />;
                        })}
                </LRTabs>
            </TabsWrapper>
        );
    }

    renderSelectedTab() {
        const { listingDetails } = this.props;

        switch (this.state.selectedTab) {
            case 'basic':
                return <Details data={this.state.basicDetails} />;
            case 'interior':
                return <Details data={this.state.interiorDetails} />;
            case 'exterior':
                return <Details data={this.state.exteriorDetails} />;
            case 'other':
                return <Details data={this.state.otherDetails} singleColumn />;
            default:
                return <div />;
        }
    }

    renderViewButton(shouldExpand) {
        return (
            <ViewButtonWrapper viewAll={shouldExpand}>
                <StyledButton variant="text" inline color="secondary" onClick={this.handleOnViewClick}>
                    View{shouldExpand ? ' less' : ' more'}
                </StyledButton>
            </ViewButtonWrapper>
        );
    }

    renderContactAgent(listingDetails) {
        const { listpack } = this.props;
        if (!listingDetails || listingDetails?.listing_source === 'listhub' || !listpack) return null;

        return (
            <Box mt={4}>
                <Typography variant="h6" align="center">
                    Want to know more about this property?
                </Typography>

                <Box display="flex" justifyContent="center" mt={2.5}>
                    <LRButton
                        variant="contained"
                        color="primary"
                        onClick={_.partial(this.onContactAgentClick, 'property_details')}
                    >
                        Contact agent
                    </LRButton>
                </Box>
            </Box>
        );
    }

    render() {
        const { listingDetails, width, expand, user } = this.props;

        return (
            <Box>
                <SectionTitle>Property Details</SectionTitle>
                <Box mt={42 / 8}>
                    {this.renderDetailHighlights(listingDetails, width)}

                    <Collapse in={expand}>
                        {this.renderTabs()}
                        {this.renderSelectedTab()}
                        {this.renderContactAgent(listingDetails)}
                    </Collapse>

                    {this.renderViewButton(expand)}
                </Box>
            </Box>
        );
    }
}
PropertyDetails.propTypes = {
    listingDetails: PropTypes.object.isRequired,
};

export default withWidth()(PropertyDetails);
