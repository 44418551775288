import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme, Box, CircularProgress } from '@material-ui/core';
import { useSignupStoreContext } from '../../SignUp/store';
import { useForm } from 'react-hook-form';
import { TextField } from '../../../components/TextField';
import LRLink from '../../../components/LRLink';
import { withTheme } from '@material-ui/styles';
import LRButton from '../../../components/LRButton';
import { LRCheckbox } from '../../../components/LRCheckbox';
import styled from 'styled-components';
import _ from 'lodash';
import analytics from '../../../services/analytics';
import { useRFHInput } from '../../../hooks';
import { useQuery } from 'hooks/use-query';

const TERMS_LINK = 'https://welcome.listreports.com/terms-conditions';
const PRIVACY_LINK = 'https://welcome.listreports.com/privacy-policy';

const StyledButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
        color: ${({ theme })=> theme.palette.lr_colors.grey_dark};
    }
`);

export const Form = observer(function SignupForm({ defaultEmail }) {
    const theme = useTheme();
    const query = useQuery();
    const queryEmail = query.get('email');
    const signupStore = useSignupStoreContext();
    const duplicateEmail = signupStore.saveUserRequest.hasDuplicateEmailError;
    const duplicateAuth = signupStore.saveUserRequest.hasDuplicateAuthError;
    const submitting = signupStore.saveUserRequest.isLoading || signupStore.sendCodeRequest.isLoading || signupStore.skipVerification;
    const affiliate = signupStore.affiliate;
    const defaultUserInfo = _.merge(
        {},
        {
            first_name: '',
            last_name: '',
            email: defaultEmail,
            phone: '',
        },
        signupStore.userInfo || {}
    );
    const rfhMethods = useForm({
        defaultValues: defaultUserInfo,
        mode: 'onChange',
        reValidateMode: 'onChange'
    });
    const { handleSubmit, errors, register, formState, getValues, setError } = rfhMethods;
    useRFHInput('meta.how_soon', {
        ...rfhMethods,
        register: register()
    });
    const [termsChecked, setTermsChecked] = useState(false);

    async function submitForm(data) {
        const { invite } = signupStore;
        analytics.eventTrack('robin_register_submit', {
            board_id: invite?.boardId,
            distinct_id: invite?.invitedUserId,
            invite_id: signupStore.inviteId,
            role: invite ? invite.meta.label.replace('-', '') : 'buyer',
            Source: signupStore.externalAuth?.method || (signupStore.isSignupByEmail && 'email') || (signupStore.isSignupByPhone && 'mobile'),
            consumer_name: `${data.first_name} ${data.last_name}`,
            consumer_email: data.email,
            consumer_phone: data.phone,
        });
        if(data.phone) {
            const { isValid: phoneIsValid, reason } = await signupStore.isPhoneValid(data.phone);
            if(data.phone && !phoneIsValid) {
                setError('phone', 'manual', reason);
            } else {
                signupStore.saveUser(data);
            }
        } else {

            const skipEmailValidation = queryEmail === data.email;
            if(!skipEmailValidation) {
                signupStore.signUpMethod = 'email';
            }
            await signupStore.saveUser(data, skipEmailValidation);
        }
    }

    return (
        <form onSubmit={handleSubmit((data)=> submitForm(data))} noValidate>
            <Box mt={9 / 2} display="flex">
                <Box flex="1">
                    <TextField
                        name="first_name"
                        placeholder="First name"
                        inputProps={{
                            height: '48px',
                            width: '100%',
                            fontSize: theme.typography.body1.fontSize,
                        }}
                        inputRef={register({
                            required: true,
                            validate: (value)=> value.trim().length,
                        })}
                        tone={errors.first_name && 'error'}
                        required
                    />
                </Box>
                <Box flex="1">
                    <TextField
                        name="last_name"
                        placeholder="Last name"
                        inputProps={{
                            height: '48px',
                            width: '100%',
                            fontSize: theme.typography.body1.fontSize,
                        }}
                        inputRef={register({
                            required: true,
                            validate: (value)=> value.trim().length,
                        })}
                        tone={errors.last_name && 'error'}
                        required
                    />
                </Box>
            </Box>
            <Box mt={2} position="relative">
                <TextField
                    name="email"
                    placeholder="Email"
                    type="email"
                    inputProps={{
                        height: '48px',
                        width: '100%',
                        fontSize: theme.typography.body1.fontSize,
                        paddingRight: '90px',
                    }}
                    inputRef={register({
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Invalid email address',
                        },
                    })}
                    message={
                        (duplicateAuth || duplicateEmail) && 'An account with this email already exists. Please Login'
                    }
                    tone={(errors.email || duplicateEmail || duplicateAuth) && 'error'}
                    onChange={()=> signupStore.saveUserRequest.reset()}
                    required
                />
                <Box
                    color={theme.palette.lr_colors.grey_dark}
                    position="absolute"
                    top="25px"
                    right="16px"
                    style={{
                        transform: 'translate(0, -50%)'
                    }}
                >
                    Required
                </Box>
            </Box>
            <Box display="flex" alignItems="center" mt={5 / 2}>
                <LRCheckbox checked={termsChecked} onChange={(e)=> setTermsChecked(e.target.checked)} />
                <Box fontSize={theme.typography.caption.fontSize} color={theme.palette.lr_colors.grey_dark}>
                    I agree to the ListReports{' '}
                    <LRLink native target="_blank" href={TERMS_LINK}>
                        terms of service
                    </LRLink>{' '}
                    and{' '}
                    <LRLink native target="_blank" href={PRIVACY_LINK}>
                        privacy policy.
                    </LRLink>
                </Box>
            </Box>
            <Box mt={3 / 2}>
                <StyledButton
                    type="submit"
                    value="Submit"
                    disabled={
                        !formState.isValid ||
                        duplicateAuth ||
                        duplicateEmail ||
                        submitting ||
                        !termsChecked
                    }
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                        height: '48px',
                        fontWeight: 'normal',
                    }}
                >
                    {!submitting && 'Sign up'}
                    {submitting && <CircularProgress size={24} color="secondary" />}
                </StyledButton>
            </Box>
            {affiliate && <Box
                mt={5 / 2}
                fontSize={theme.typography.caption.fontSize}
                color={theme.palette.lr_colors.grey_dark}
            >
                Robin is a collaborative home search tool. By clicking “Sign-up,” your account information and
                home preferences will be shared with {affiliate && ` ${affiliate.name}`} and their paired loan officer, {affiliate && `${affiliate?.preferred_vendor?.name}`}.
            </Box>}
        </form>
    );
});
