import babou from 'babou-js';
import Logger from 'js-logger';
import _ from 'lodash';
import { daysOnMarketToFilterParam } from '../../constants';
const logger = Logger.get('babouApi');

// Areas arg is assumed to be an array of Area model instances
export function transformAreasForSearch(areas) {
    return areas.map((marketingArea)=> {
        let formatted_term = marketingArea.id;
        let search_term = marketingArea.id;
        let search_type = marketingArea.type;

        if(search_type === 'zip') {
            search_type = 'zipcode';
        }

        if(['neighborhood', 'school'].includes(search_type)) {
            search_type = 'polygon';
            const {
                polygon: {
                    coordinates: [actualPolygon],
                },
            } = marketingArea;
            formatted_term = JSON.stringify(actualPolygon);
        }

        return {
            formatted_term,
            search_term,
            search_type,
        };
    });
}

// Fields arg is assumed to follow the struct of the 'fields' field of a Listpack
function transformFieldsForSearch(fields) {
    const search_filter_params = [];

    if(fields.price_min) {
        search_filter_params.push({
            name: 'price_min',
            value: fields.price_min,
        });
    }

    if(fields.price_max) {
        search_filter_params.push({
            name: 'price_max',
            value: fields.price_max,
        });
    }

    if(fields.bedrooms) {
        search_filter_params.push({
            name: 'bedrooms_min',
            value: parseInt(fields.bedrooms),
        });
    }

    if(fields.bathrooms) {
        search_filter_params.push({
            name: 'bathrooms_min',
            value: parseInt(fields.bathrooms),
        });
    }

    if(fields.homesize_min) {
        search_filter_params.push({
            name: 'homesize_min',
            value: parseInt(fields.homesize_min),
        });
    }

    if(fields.homesize_max) {
        search_filter_params.push({
            name: 'homesize_max',
            value: parseInt(fields.homesize_max),
        });
    }

    if(fields.stories) {
        // CAUSION: if we change this we might break existing listpacks
        // currently the listpacks stories are being created with 3 possible values:
        // {
        //      null -> doesn't matter,
        //      1 -> single storie home
        //      2 -> multiple stories home, so >= 2
        // }
        // we need to guarantee that we keep the same values and map to the appropriate babou prop
        const stories = parseInt(fields.stories);

        let propName;

        if(stories > 0) {
            propName = 'stories';
        }

        search_filter_params.push({
            name: propName,
            value: stories,
        });
    }

    if(fields.lotsize_min) {
        search_filter_params.push({
            name: 'lotsize_min',
            value: parseInt(fields.lotsize_min),
        });
    }

    if(fields.lotsize_max) {
        search_filter_params.push({
            name: 'lotsize_max',
            value: parseInt(fields.lotsize_max),
        });
    }

    if(fields.year) {
        search_filter_params.push({
            name: 'lotsize_max',
            value: parseInt(fields.lotsize_max),
        });
    }

    if(fields.year_built_min) {
        search_filter_params.push({
            name: 'year_built_min',
            value: fields.year_built_min,
        });
    }

    if(fields.year_built_max) {
        search_filter_params.push({
            name: 'year_built_max',
            value: fields.year_built_max,
        });
    }

    if(fields.days_on_market) { 
        search_filter_params.push(daysOnMarketToFilterParam(fields.days_on_market));
    }

    // hometype
    if(fields.hometype) {
        const values = _.pickBy(fields.hometype, function(value, key) {
            return value === true;
        });
        const keys = Object.keys(values);

        if(keys.length) {
            const homeTypeValues = keys.reduce((accum = [], current)=> {
                switch(current) {
                    case 'single_family':
                        accum.push('Single house');
                        break;
                    case 'condo_townhome':
                        accum.push('Condo');
                        accum.push('Townhome');
                        break;
                    case 'multi_family':
                        accum.push('Multi-family');
                        break;
                    case 'mobile_manufactured':
                        accum.push('Manufactured');
                        break;
                    default:
                        accum.push('Other');
                }
                return accum;
            }, []);

            search_filter_params.push({
                name: 'hometype',
                value: homeTypeValues,
            });
        }
    }

    // tags
    if(fields.tags) {
        const values = _.pickBy(fields.tags, function(value, key) {
            return value === true;
        });

        const keys = Object.keys(values);

        if(keys.length) {
            search_filter_params.push({
                name: 'tags',
                value: keys,
            });
        }
    }

    if(fields.keywords && fields.keywords.length > 0) {
        search_filter_params.push({
            name: 'keywords',
            value: fields.keywords,
        });
    }

    return search_filter_params;
}

export async function searchListings(
    areas,
    fields,
    { 
        skip = 0, 
        limit = 30, 
        cache = true, 
        useSourceZipBlacklist = true, 
        includeListings = false, 
        includeListingStats = false 
    } = {},
    {
        skipSearchAreaTransform = false,
    } = {},
) {
    const searchAreas = skipSearchAreaTransform ? areas : transformAreasForSearch(areas);
    const searchFilterParams = transformFieldsForSearch(fields);

    const options = {
        cache: cache,
        useSourceZipBlacklist: useSourceZipBlacklist,
        include_listings: includeListings,
        include_listing_stats: includeListingStats,
        search_filter_params: searchFilterParams,
        limit,
        skip
    };

    try {
        const response = await babou.searchListings(searchAreas, options);
        return response.data;
    } catch (err) {
        logger.error(err.message);
        throw err;
    }
}

export async function getSoldStats(areas) {
    try {
        const response = await babou.buyerStats(areas, {});
        return response.data;
    } catch (err) {
        logger.error(err.message);
        throw err;
    }
}

export async function getListingStats(areas) {
    const options = {
        cache: true,
        include_listings: false,
        include_listing_stats: true,
    };

    try {
        const response = await babou.searchListings(areas, options);
        return response.data.listing_stats;
    } catch (err) {
        logger.error(err.message);
        throw err;
    }
}
