import React, { useContext } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../../hooks';
import { TalkingPointsContext } from '..';
import { COLOR_GREY_LIGHT, COLOR_BLUE_LIGHT } from '../../../constants';
import LRAvatar from 'components/LRAvatar';
import { UserProfileInfo } from '../UserProfileInfo';
import { INTRO_PLACEHOLDER } from '../constants';
import { useQuery } from '../../../hooks';

export const AgentIntro = function () {
    const isMobile = useResponsiveBreakpoint('xs');
    const theme = useTheme();
    const {
        user,
        talkingPoints = {},
        media,
    } = useContext(TalkingPointsContext);
    const previewMode = media === 'preview';
    const printMode = media === 'print';
    const intro = previewMode
        ? talkingPoints?.intro || INTRO_PLACEHOLDER
        : talkingPoints?.intro;
    const highlightIntro = useQuery()
        .get('highlight') === 'personal_intro';

    if(!intro) return null;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: isMobile ? 'flex-start' : 'center',
                marginTop: isMobile ? theme.spacing(1.5) : theme.spacing(4),
                flexDirection: isMobile ? 'column-reverse' : 'row',
            }}
        >
            <div
                className="space-x-4"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '124px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    {user.headshot && <LRAvatar user={user} size="size64"></LRAvatar>}
                    {user.name && !isMobile && !printMode && (
                        <Typography variant="body2" style={{marginTop: theme.spacing(1), fontWeight: 500}}>{user.name}</Typography>
                    )}
                </div>

                {isMobile && (<UserProfileInfo user={user} />)}
            </div>

            <div style={{
                padding: theme.spacing(3),
                backgroundColor: media === 'print' ? COLOR_GREY_LIGHT : 'white',
                borderRadius: 8,
                marginBottom: isMobile ? theme.spacing(3) : 0,
                marginLeft: isMobile ? 0 : theme.spacing(3),
            }}>
                <Typography variant="h6" style={{
                    fontWeight: 'normal',
                    backgroundColor: highlightIntro ? `${COLOR_BLUE_LIGHT}50` : 'transparent',
                }}>{intro}</Typography>
            </div>
        </div>
    );
};
