import React from 'react';
import { LeadForm } from '../../../components/LeadForm';
import { Box } from '@material-ui/core';
import { COLOR_GREY_LIGHT } from '../../../constants';
import analytics from '../../../services/analytics';
import styled from 'styled-components';
import { useStore } from '../../../mobx-store';
import { useQuery } from '../../../hooks';
import { useResponsiveBreakpoint } from '../../../hooks';

const StyledImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const StyledIFrame = styled.iframe`
    width: 100%;
    height: 100%;

    img {
        width: 100%;
    }
`;

function FormHeader({ userType, ...props }) {
    return (
        <Box fontSize="20px" fontWeight="500">
            {userType === 'agent' ?
                'Interested in buying or selling a home? Get in touch!' :
                'Need help with mortgage financing?'
            }
        </Box>
    );
}

export function LandingPageBody({ user, loadingUser, loadedUser, ...props }) {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const { ShareablesStore } = useStore();
    const path = useQuery()
        .get('path');
    const imagePath = useQuery()
        .get('imagePath');
    const shareableSlug = useQuery()
        .get('slug');
    const shareableType = useQuery()
        .get('type');
    const landingPageType = useQuery()
        .get('landingPageType');
    const customImage = useQuery()
        .get('customImage');

    const onLeadCreated = (lead, message)=> {
        analytics.eventTrack('shareable_consumer_lead_form_submit', {
            distinct_id: user.id,
            ...(shareableSlug && { 'Shareable Slug': shareableSlug }),
            ...(shareableType && { 'Shareable Type': shareableType }),
            ...(customImage && { 'uploaded_image': customImage }),
        });

        ShareablesStore.onLeadCreated(lead, message, shareableSlug, shareableType);
    };

    return (
        <>
            <Box width={1} paddingTop={{ xs: '110%', md: 0 }} position="relative" bgcolor={COLOR_GREY_LIGHT}>
                <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                    {imagePath
                        ? <StyledImg src={imagePath} alt="shareable" />
                        : <StyledIFrame title="shareable image" src={path}></StyledIFrame>
                    }
                </Box>
            </Box>
            {landingPageType !== 'normal' && <Box
                width="100%"
                height={{ xs: '100%', md: 'auto' }}
                maxWidth={{ xs: 1, md: '360px' }}
                minWidth={{ xs: 0, md: '360px' }}
                overflow="auto"
            >
                <Box
                    px={4}
                    pb="28px"
                    pt="26px"
                >
                    <LeadForm
                        user={user}
                        loadingUser={loadingUser}
                        loadedUser={loadedUser}
                        formHeader={<FormHeader userType={user.type} />}
                        variant={isMobile ? 'mobile' : 'desktop'}
                        leadSource="Shareables"
                        onLeadCreated={onLeadCreated}
                        submitText="Get in touch"
                    />
                </Box>
            </Box>}
        </>
    );
}
