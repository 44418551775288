import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { LRRectCheckbox } from '../../../../components/LRRectCheckbox';
import { LRDropPopover } from '../../../../components/LRDropPopover';
import { useRFHInput } from '../../../../hooks';
import { COLOR_STEAK_SAUCE } from '../../../../constants';
import { useMemo } from 'react';

export function HomeTypeContent({fields, trackFilterSet, handleMixpanelEvent}) {
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6} sm={6}>
                <LRRectCheckbox
                    name="fields.hometype.single_family"
                    label="Single Family"
                    checked={!!fields.singleFamily}
                    onChange={(event)=> {
                        fields.handleSingleFamily(event.target.checked);
                        trackFilterSet('home_type');
                        handleMixpanelEvent();
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={6}>
                <LRRectCheckbox
                    name="fields.hometype.condo_townhome"
                    label="Condo or Townhome"
                    checked={!!fields.condoTownhome}
                    onChange={(event)=> {
                        fields.handleCondoTownhome(event.target.checked);
                        trackFilterSet('home_type');
                        handleMixpanelEvent();
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={6}>
                <LRRectCheckbox
                    name="fields.hometype.multi_family"
                    label="Multi Family"
                    checked={!!fields.multiFamily}
                    onChange={(event)=> {
                        fields.handleMultiFamily(event.target.checked);
                        trackFilterSet('home_type');
                        handleMixpanelEvent();
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={6}>
                <LRRectCheckbox
                    name="fields.hometype.mobile_manufactured"
                    label="Mobile or Manufactured"
                    checked={!!fields.mobileManufactured}
                    onChange={(event)=> {
                        fields.handleMobileManufactured(event.target.checked);
                        trackFilterSet('home_type');
                        handleMixpanelEvent();
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={6}>
                <LRRectCheckbox
                    name="fields.hometype.land"
                    label="Lot or Land"
                    checked={!!fields.land}
                    onChange={(event)=> {
                        fields.handleLand(event.target.checked);
                        trackFilterSet('home_type');
                        handleMixpanelEvent();
                    }}
                />
            </Grid>
        </Grid>
    );
}


export function HomeType({trackFilterSet, trackFilterClick, onClick, handleMixpanelEvent}) {
    const formMethods = useFormContext();
    const { value: singleFamily, handleChange: handleSingleFamily } = useRFHInput('fields.hometype.single_family', formMethods);
    const { value: condoTownhome, handleChange: handleCondoTownhome } = useRFHInput('fields.hometype.condo_townhome', formMethods);
    const { value: multiFamily, handleChange: handleMultiFamily } = useRFHInput('fields.hometype.multi_family', formMethods);
    const { value: mobileManufactured, handleChange: handleMobileManufactured } = useRFHInput('fields.hometype.mobile_manufactured', formMethods);
    const { value: land, handleChange: handleLand } = useRFHInput('fields.hometype.land', formMethods);
    const fields = { singleFamily, handleSingleFamily, condoTownhome, handleCondoTownhome, multiFamily, handleMultiFamily, mobileManufactured, handleMobileManufactured, land, handleLand };
    const hometypeTitle = useMemo(()=> {
        let totalSelections = 0;

        if(singleFamily) totalSelections++;
        if(condoTownhome) totalSelections++;
        if(multiFamily) totalSelections++;
        if(mobileManufactured) totalSelections++;
        if(land) totalSelections++;

        if(totalSelections) {
            return `Home type (${totalSelections})`;
        } else {
            return 'Home type';
        }
    }, [condoTownhome, land, mobileManufactured, multiFamily, singleFamily]);

    return (
        <LRDropPopover
            title={hometypeTitle}
            onOpen={()=> trackFilterClick('home_type')}
            onClick={onClick}
            toggled={Boolean(singleFamily || condoTownhome || multiFamily || mobileManufactured || land)}
        >
            <div style={{maxWidth: '360px', padding: '16px'}}>
                <Box fontSize="14px" color={COLOR_STEAK_SAUCE} marginBottom={2}>Select all that apply</Box>
                <HomeTypeContent fields={fields} trackFilterSet={trackFilterSet} handleMixpanelEvent={handleMixpanelEvent} />
            </div>
        </LRDropPopover>
    );
}
