import React, { useRef, useContext } from 'react';
import { observable, decorate, action, computed } from 'mobx';
import { useStore } from '../../mobx-store';
import analytics from '../../services/analytics';

class NameHomeStore {
    name = null;
    selectedPhoto = null;
    hidePersonalizedModal = false;
    close = false;
    index = 0;

    constructor(rootStore, listingId, source) {
        this.close = false;
        this.rootStore = rootStore;
        this.UserStore = rootStore.UserStore;
        this.listingId = listingId;
        this.globalSnackbar = rootStore.GlobalSnackbarStore;
        this.hidePersonalizedModal = this.user.meta?.hide_personalized_modal;
        this.name = this.listing.name;
        this.selectedPhoto = this.listing.hero_image_url || this.listing.listing_details.photos[this.selectedIndex];
        this.index = this.selectedIndex;
        this.source = source;
        this.trackEvent('robin_card_personalization_modal_displayed');
    }

    get listing() {
        return this.rootStore.boardsStore.activeBoard.getBoardCardById(this.listingId);
    }

    get user() {
        return this.UserStore.user;
    }

    trackEvent(eventName, props) {
        const defaultEventInfo = {
            source: this.source,
            listing_id: this.listingId,
        };

        const eventInfo = props ? { ...defaultEventInfo, ...props } : defaultEventInfo;

        analytics.eventTrack(eventName, eventInfo);
    }

    save = ()=> {
        this.trackEvent('robin_card_personalization_modal_save', {
            dont_show_again_checked: this.hidePersonalizedModal,
        });
        this.listing.name = this.name;
        this.listing.hero_image_url = this.selectedPhoto;
        if(this.hidePersonalizedModal) {
            const data = { meta: {hide_personalized_modal: this.hidePersonalizedModal }};
            this.UserStore.updateUser(data);
        }
        this.listing.update();
        this.globalSnackbar.show('Your personalizations have been saved.');
        this.close = true;
    };

    reset = ()=> {
        this.name = this.listing.name;
        this.selectedPhoto = this.listing.hero_image_url || this.listing.listing_details.photos[this.selectedIndex];
        this.index = this.defaultIndex;
        this.trackEvent('robin_card_personalization_modal_reset_click');
    };

    get hasChange() {
        return this.name !== this.listing.name || this.selectedPhoto !== this.listing.hero_image_url;
    }

    get isSaveDisabled() {
        return !this.hasChange && this.user.meta?.hide_personalized_modal === this.hidePersonalizedModal;
    }

    get isResetDisabled() {
        return !this.hasChange || (!this.listing.hero_image_url && this.selectedIndex === 0);
    }

    get defaultIndex() {
        const i = this.listing.listing_details.photos.findIndex((photo)=> this.listing.hero_image_url === photo);
        return i !== -1 ? i : 0;
    }

    get selectedIndex() {
        const i = this.listing.listing_details.photos.findIndex((photo)=> this.selectedPhoto === photo);
        return i !== -1 ? i : 0;
    }
}

decorate(NameHomeStore, {
    close: observable,
    name: observable,
    selectedPhoto: observable,
    hidePersonalizedModal: observable,
    index: observable,
    listing: computed,
    isResetDisabled: computed,
    isSaveDisabled: computed,
    selectedIndex: computed,
    reset: action,
});

const NameHomeContext = React.createContext();

export function useNameHomeStore(listingId, source) {
    const rootStore = useStore();
    const storeRef = useRef(new NameHomeStore(rootStore, listingId, source));
    return storeRef.current;
}

export function NameHomeStoreProvider({ nameHomeStore, children }) {
    return <NameHomeContext.Provider value={nameHomeStore}>{children}</NameHomeContext.Provider>;
}

export function useNameHomeStoreContext() {
    return useContext(NameHomeContext);
}
