import React from 'react';
import { NoResultsContainer, NoResultsMainText, NoResultsSecondaryText, EditSearchButtonContainer, StyledEditIcon, StyledEditText, StyledEditTextContainer, EditSearchContainer } from './style';
import LRButton from '../../../components/LRButton';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CircularProgress, Box } from '@material-ui/core';

export const NoResults = observer(function NoResults({loading, editLocation= {}}) {
    const isXs = useResponsiveBreakpoint('xs');
    const match = useRouteMatch();
    const history = useHistory();

    function handleEditClick() {
        history.push((editLocation) ? editLocation : `${match.url}/edit`);
    }

    return (
        <>
            {!loading && (
                <NoResultsContainer>
                    <NoResultsMainText>
                        There are no matching results
                    </NoResultsMainText>
                    <NoResultsSecondaryText>
                        Try changing your search criteria for better results.
                    </NoResultsSecondaryText>
                    <EditSearchContainer isXs={isXs}>
                        <LRButton
                            color="primary"
                            variant={'contained'}
                            onClick={handleEditClick}
                            type={'submit'}
                        >
                            <EditSearchButtonContainer>
                                <StyledEditTextContainer>
                                    <StyledEditIcon/>
                                </StyledEditTextContainer>
                                <StyledEditText>
                                    Edit
                                </StyledEditText>
                            </EditSearchButtonContainer>
                        </LRButton>
                    </EditSearchContainer>
                </NoResultsContainer>
            )}
        </>
    );
});
