import React from 'react';
import { useResponsiveConfig } from '../../hooks';

function ResponsiveHOC(config) {
    return function GeneratedComponent(props) {
        const ComponentToRender = useResponsiveConfig(config);
        return <ComponentToRender {...props} />;
    };
};


export default ResponsiveHOC;
