import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LRAvatar from '../../LRAvatar';

const StyledAvatarsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const StyledAvatarWrap = styled.div`
    border-radius: 50%;
    margin-left: -6px;
    border: 2px solid #fff;
`;

function PinnedByHeadshots({ users=[] }) {
    if(!(users && users.length)) return null;
    return (
        <StyledAvatarsRow>
            {users.filter((u)=> u)
                .map((user, i)=> (
                    <StyledAvatarWrap key={`pinned-by-headshots-${user.id}${i}`} style={{zIndex: (5 + (i + 1))}}>
                        <LRAvatar user={user} size="xsmall" />
                    </StyledAvatarWrap>
                ))}
        </StyledAvatarsRow>
    );
}

PinnedByHeadshots.propTypes = {
    users: PropTypes.array.isRequired,
};

export default PinnedByHeadshots;
