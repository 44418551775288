import { BOARD_LISTPACK_CACHE_KEY } from '../../../constants';
import { useQueries } from 'react-query';
import { pick } from 'lodash';
import { getListpackListingsCount } from 'services/babou';

export function useBoardListpacksInitialData(listpacks) {
    return useQueries(
        listpacks?.map((lp)=> ({
            queryKey: [`${BOARD_LISTPACK_CACHE_KEY}-listing-counts`, lp.id, pick(lp, ['areaIds', 'fields'])],
            queryFn: ()=> {
                return fetchInitialData(lp);
            },
            select: (data)=> ({
                activeListingsCount: data?.active_listings || 0,
                newListingsCount: data?.new || 0,
            }),
            enabled: Boolean(lp?.areaIds?.length),
        })) || []
    );
}

async function fetchInitialData(listpack) {
    return getListpackListingsCount(listpack, { includeListings: false, listingsSinceDate: true });
}
