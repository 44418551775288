var XRegExp = require('xregexp');
var Street_Type = require('lana/libs/street-types');
var Directional = require('lana/libs/directional');
var Direction_Code = invert(Directional);
var State_Code = require('lana/libs/states').STATE_CODES;
var pick = require('lodash/pick');

/**
 * This was pulled from https://github.com/hassansin/parse-address
 * We only need the code releated to getting the parts from the
 * address but all credit goes to this npm module.
 */

////////////////////////////////////////
// Helper Functions
////////////////////////////////////////

function capitalize(s){
    return s && s[0].toUpperCase() + s.slice(1);
}

function keys(o){
    return Object.keys(o);
}

function values(o){
    var v = [];

    keys(o).forEach(function(k){
        v.push(o[k]);
    });

    return v;
}

function each(o, fn){
    keys(o).forEach(function(k){
        fn(o[k],k);
    });
}

function invert(o) {
    var o1 = {};

    keys(o).forEach(function (k) {
        o1[o[k]] = k;
    });

    return o1;
}

function flatten(o){
    return keys(o).concat(values(o));
}

function normalizeParts(parts) {
    if(!parts) return {};

    var parsed = {};

    Object.keys(parts).forEach(function (k) {
        if(['input', 'index'].indexOf(k) !== -1 || isFinite(k))
            return;
        var key = isFinite(k.split('_').pop()) ? k.split('_').slice(0, -1).join('_') : k;
        if(parts[k])
            parsed[key] = parts[k].trim().replace(/[^\w\s\-\#\&]/, '');
    });

    if(parsed.city) {
        parsed.city = XRegExp.replace(parsed.city,
            XRegExp('^(?<dircode>' + Addr_Match.dircode + ')\\s+(?=\\S)', 'ix'),
            function (match) {
                return capitalize(Direction_Code[match.dircode.toUpperCase()]) + ' ';
            });
    }

    if(parsed.zip) {
        parsed.zip = XRegExp.replace(parsed.zip, /^(.{5}).*/, '$1');
    }

    return parsed;
}


////////////////////////////////////////
// Addr_Match
////////////////////////////////////////

var Addr_Match = {
    type: flatten(Street_Type).sort().filter(function(v,i,arr){return arr.indexOf(v)===i; }).join('|'),
    fraction : '\\d+\\/\\d+',
    state: '\\b(?:' + keys(State_Code).concat(values(State_Code)).map(XRegExp.escape).join('|') + ')\\b',
    direct: values(Directional).sort(function(a,b){return a.length < b.length;}).reduce(function(prev,curr){return prev.concat([XRegExp.escape(curr.replace(/\w/g,'$&.')),curr]);},keys(Directional)).join('|'),
    dircode : keys(Direction_Code).join("|"),
    zip: '\\d{5}(?:-?\\d{4})?',
};

Addr_Match.number = '(?<number>\\w*\\d+-?\\d*)(?=\\D)';

Addr_Match.street = '                                       \n\
    (?:                                                     \n\
    (?:(?<street_0>'+Addr_Match.direct+')\\W+               \n\
        (?<type_0>'+Addr_Match.type+')\\b                   \n\
    )                                                       \n\
    |                                                       \n\
    (?:(?<prefix_0>'+Addr_Match.direct+')\\W+)?             \n\
    (?:                                                     \n\
        (?<street_1>[^,]*\\d)                               \n\
        (?:[^\\w,]*(?<suffix_1>'+Addr_Match.direct+')\\b)   \n\
        |                                                   \n\
        (?<street_2>[^,]+)                                  \n\
        (?:[^\\w,]+(?<type_2>'+Addr_Match.type+')\\b)       \n\
        (?:[^\\w,]+(?<suffix_2>'+Addr_Match.direct+')\\b)?  \n\
        |                                                   \n\
        (?<street_3>[^,]+?)                                 \n\
        (?:[^\\w,]+(?<type_3>'+Addr_Match.type+')\\b)?      \n\
        (?:[^\\w,]+(?<suffix_3>'+Addr_Match.direct+')\\b)?  \n\
    )                                                       \n\
)';

Addr_Match.sec_unit_type_numbered = '             \n\
    (?<sec_unit_type_1>su?i?te                    \n\
    |p\\W*[om]\\W*b(?:ox)?                        \n\
    |(?:ap|dep)(?:ar)?t(?:me?nt)?                 \n\
    |ro*m                                         \n\
    |flo*r?                                       \n\
    |uni?t                                        \n\
    |bu?i?ldi?n?g                                 \n\
    |ha?nga?r                                     \n\
    |lo?t                                         \n\
    |ofc                                          \n\
    |office                                       \n\
    |pier                                         \n\
    |slip                                         \n\
    |spa?ce?                                      \n\
    |stop                                         \n\
    |tra?i?le?r                                   \n\
    |box)(?![a-z]                                 \n\
    )                                             \n\
';

Addr_Match.sec_unit_type_unnumbered = '           \n\
    (?<sec_unit_type_2>ba?se?me?n?t               \n\
    |fro?nt                                       \n\
    |lo?bby                                       \n\
    |lowe?r                                       \n\
    |off?i?ce?                                    \n\
    |pe?n?t?ho?u?s?e?                             \n\
    |rear                                         \n\
    |side                                         \n\
    |uppe?r                                       \n\
)\\b';

Addr_Match.sec_unit = '                               \n\
    (?:                             #fix3             \n\
    (?:                             #fix1             \n\
        (?:                                           \n\
        (?:'+Addr_Match.sec_unit_type_numbered+'\\W*) \n\
        |(?<sec_unit_type_3>\\#)\\W*                  \n\
        )                                             \n\
        (?<sec_unit_num_1>[\\w-]+)                    \n\
    )                                                 \n\
    |                                                 \n\
    '+Addr_Match.sec_unit_type_unnumbered+'           \n\
)';

Addr_Match.unitParse = XRegExp('                      \n\
    ^                                               \n\
    [^\\w\\#]*                                      \n\
    ('+Addr_Match.number+')\\W*                     \n\
    (?:'+Addr_Match.fraction+'\\W*)?                \n\
        '+Addr_Match.street+'\\W+                   \n\
    (?:'+Addr_Match.sec_unit+')','ix'
);


////////////////////////////////////////
// Main Function
////////////////////////////////////////

/**
 * @function parseAddress
 * @param {String} address
 */
module.exports = function parseUnit(address) {
    return pick(normalizeParts(XRegExp.exec(address, Addr_Match.unitParse)), ['sec_unit_num', 'sec_unit_type']);
};
