// eslint-disable-next-line
import Logger from 'js-logger';
import React from 'react';
import { useStore } from '../../mobx-store';
import { Snackbar } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const StyledSnackBar = styled(Snackbar)`
    z-index: 9999999;
    .MuiSnackbarContent-message {
        margin: 0 auto;
    }
`;

function GlobalSnackbar() {
    const { GlobalSnackbarStore } = useStore();

    function handleSnackbarOnClose(event, reason) {
        if(reason === 'clickaway') {
            return;
        }
        GlobalSnackbarStore.hide();
    }

    return (
        <StyledSnackBar
            anchorOrigin={GlobalSnackbarStore.anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
            key={GlobalSnackbar.key || 'action-successful'}
            open={GlobalSnackbarStore.open}
            message={GlobalSnackbarStore.message}
            onClose={handleSnackbarOnClose}
            ContentProps={{
                ...(GlobalSnackbarStore.ContentProps || {}),
                style: {
                    backgroundColor: '#000',
                    ...(GlobalSnackbarStore?.ContentProps?.style || {}),
                }
            }}
            {...GlobalSnackbarStore.options}
        />
    );
}

export default observer(GlobalSnackbar);
