import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { COLOR_BLUE_LINK } from '../../../constants';
import LRButton from 'components/LRButton';
import { Skeleton } from '@material-ui/lab';

const DEFAULT_DESCRIPTION_CHARACTER_LIMIT = 280;

const PropertyDescription = observer(function PropertyDescription({ listing }) {
    const [expanded, setExpanded] = useState(false);
    const description = listing?.description || '';

    const visibleDescription = expanded ? description : description.slice(0, DEFAULT_DESCRIPTION_CHARACTER_LIMIT);
    const canExpand = description.length > DEFAULT_DESCRIPTION_CHARACTER_LIMIT;

    function handleReadLess() {
        setExpanded(false);
    }

    function handleReadMore() {
        setExpanded(true);
    }

    return (
        <div
            className="space-y-2"
        >
            <div className="space-y-4">
                <Typography variant="h5">Description</Typography>
                <Typography variant="body1">
                    {visibleDescription ? (
                        visibleDescription + (canExpand && !expanded ? ' ...' : '')
                    ) : (
                        <Skeleton variant="rect" animation="wave" height="72px" width="100%" />
                    )}
                </Typography>
            </div>
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                {canExpand &&
                    (expanded ? (
                        <LRButton
                            onClick={handleReadLess}
                            variant="text"
                            style={{
                                color: COLOR_BLUE_LINK,
                            }}
                        >
                            Read less
                        </LRButton>
                    ) : (
                        <LRButton
                            onClick={handleReadMore}
                            variant="text"
                            style={{
                                color: COLOR_BLUE_LINK,
                            }}
                        >
                            Read more
                        </LRButton>
                    ))}
            </div>
        </div>
    );
});

export default PropertyDescription;
