import React, { useEffect, useState } from 'react';
import { LRInputOutlined } from 'components/LRInput';

const TEL_TYPE = 'tel';
const EMAIL_TYPE = 'email';

export function LREmailPhoneField({ rfhMethods, defaultValue, name, required, message, ...props }){
    const [currentType, setCurrentType] = useState(null);
    const [currentValue, setCurrentValue] = useState('');
    const { register, setValue, errors, formState } = rfhMethods;
    const showError = errors[name] && formState.isSubmitted;// && !formState.isValid;

    function cycleInput(inputValue){
        const sanitized = inputValue.replace(/\(|\)|\-|\s/gi, '');
        if(!isNaN(sanitized)){
            if(currentType !== TEL_TYPE){
                setCurrentType(TEL_TYPE);
                setValue(`${name}_type`, TEL_TYPE);
            }
            setValue(name, sanitized, true);
        } else {
            if(currentType !== EMAIL_TYPE){
                setCurrentType(EMAIL_TYPE);
                setValue(`${name}_type`, EMAIL_TYPE);
            }
            setValue(name, inputValue, true);
        }
    }

    function handleOnChange({ target: { value } }){
        setCurrentValue(value);
        cycleInput(value);
    }

    function validateSanitized(sanitized){
        if(currentType === TEL_TYPE){
            return sanitized.length === 10 || message;
        } else if(currentType === EMAIL_TYPE){
            return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(sanitized) || message;
        }
        return true;
    }

    useEffect(()=> {
        if(!currentValue && defaultValue) {
            handleOnChange({ target: { value: defaultValue, } });
        }
    }, [defaultValue]);

    return (
        <>
            <LRInputOutlined
                {...props}
                error={Boolean(showError && errors[name].message)}
                helperText={Boolean(showError && errors[name].message) && errors[name].message}
                onChange={handleOnChange}
                value={currentValue}
                type="text"
                style={{
                    marginBottom: 0,
                }}
            />
            <input type="hidden" ref={register({ required, validate: validateSanitized })} name={name} />
            <input type="hidden" ref={register} name={`${name}_type`} />
        </>
    );
}
