import React, { createContext } from 'react';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import { Header } from './Header';
import { AgentIntro } from './AgentIntro';
import { News } from './News';
import { LocalMarketTrends } from './LocalMarketTrends';
import { Footer } from './Footer';
import { PrintFooter } from './PrintFooter';
import { useResponsiveBreakpoint } from '../../hooks';
import { observer } from 'mobx-react-lite';
import { getLastFriday } from './utils';
import { PRINT_LAYOUT } from './constants';

export const TalkingPointsContext = createContext({});

const TalkingPointsProvider = observer(function ({ children, ...props }) {
    return <TalkingPointsContext.Provider value={props}>{children}</TalkingPointsContext.Provider>;
});

export const TalkingPoints = function ({
    user,
    talkingPoints,
    date,
    media,
}) {
    const isMobile = useResponsiveBreakpoint('xs');
    const isPrint = media === 'print';
    const isPreview = media === 'preview';
    const formattedDate = getLastFriday(date);
    const uncompressedTalkingPoints = React.useMemo(() => {
        let areas = [...(talkingPoints?.areas ?? [])];
        if(!Array.isArray(areas[0])) {
            // Not needed, areas are not compressed
            return talkingPoints;
        }
        const keyMap = areas.shift();
        areas = areas.map((item)=> _.zipObject(keyMap, item));
        return { ...talkingPoints, areas };
    }, [talkingPoints]);
    return (
        <TalkingPointsProvider
            user={user}
            talkingPoints={uncompressedTalkingPoints}
            date={date}
            formattedDate={formattedDate}
            media={media}
        >
            <Box id="talking-points-root"
                pt={{ xs: 3, md: 8 }}
                pb={{ xs: 3, md: isPrint ? 2 : 8 }}
                px={{ xs: 4, md: 9 }}
                width={isPrint ? PRINT_LAYOUT.width : 1}
                minHeight={isPrint ? PRINT_LAYOUT.height : '100vh' }
                maxWidth={isPrint ? PRINT_LAYOUT.width : 1200}
                margin="auto"
                display="flex"
                flexDirection="column"
                boxSizing="border-box"
            >
                <Header />
                <AgentIntro />
                <News />
                <LocalMarketTrends />
                {!isMobile && !(isPrint || isPreview) && (<Footer user={user} />)}
                {(isPrint || isPreview) && (<PrintFooter />)}
            </Box>
        </TalkingPointsProvider>
    );

};
