import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import LRModal from '../../../../components/LRModal';
import LRInput from '../../../../components/LRInput';
import { COLOR_BLACK, COLOR_CREST_WHITE } from '../../../../constants';

export const LRToggleButtonGroupOptionStyle = {
    width: '100%',
    color: COLOR_BLACK,
    textTransform: 'inherit',
    fontWeight: 'normal',
    padding: '0px 20px 0px 21px',
};

const blink = keyframes`
    0% {background: ${COLOR_BLACK}}
    50% {background: ${COLOR_CREST_WHITE}}
    100% {background: ${COLOR_BLACK}}
`;

export const StyledTitleSpan = withTheme(styled.span`
    font-size: 24px;
    ${({ theme })=> theme.breakpoints.down('xs')} {
        font-size: 20px;
    }
`);

export const FilterMoreActions = withTheme(styled.div`
    width: 100%;
    height: 66px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: ${(props)=> props.justifyContent};
    border-top: 1px solid ${({ theme })=> theme.palette.lr_colors.border_grey};
    background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
`);
;

export const BlinkCursor = withTheme(styled.span`
    background: lime;
    line-height: 17px;
    margin-left: 3px;
    -webkit-animation: ${blink} 0.8s infinite;
    width: 1px;
    height: 15px;
`);

export const Modal = withTheme(styled((props)=> (
    <LRModal {...props} />
))`
  & .paperWidthSm-listing-details {
      height: auto;
      max-width: 560px;
      border-radius: 4px;
      padding: 0;

      ${({ theme })=> theme.breakpoints.only('xs')} {
          height: 405px;
          Width: 90%;
      }
  }

  & .SaveSearchModal-DialogContent-root {
      padding: 32px 24px !important;
      display: flex;
      flex-direction: column;
  }
`);


export const StyledLRInput = styled(LRInput)`
    & .MuiOutlinedInput-input {
        padding: 14px;
    }
`;
