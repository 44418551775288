import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useListingExplorerStoreContext } from '../store';

const MATTERPORT_REGEX = /^(?:https?:\/\/)?my\.matterport\.com\/show(?:\?|\/\?)(?:\S+&m=|m=)(\w{11})(?:\S+)?$/;

function addQueryStringsToUrl(url, params) {
    const urlParts = url.split('?');
    let queryString = new URLSearchParams();
    if(urlParts.length > 1) {
        queryString = new URLSearchParams('?' + urlParts[1]);
    }
    Object
        .keys(params)
        .forEach((k)=> {
            queryString.set(k, params[k]);
        });
    return urlParts[0] + '?' + queryString.toString();
}

export const VirtualTour = observer(function() {
    const listingExplorerStore = useListingExplorerStoreContext();
    const history = useHistory();
    const location = useLocation();
    const locationRef = useRef(location);
    const [checkedVirtualTourUrl, setCheckedVirtualTourUrl] = useState(false);

    useEffect(()=> {
        const url = listingExplorerStore.virtualTourUrl;
        const searchParams = new URLSearchParams(locationRef.current.search);
        searchParams.set('tab', 'photos');

        if(MATTERPORT_REGEX.test(url)) {
            setCheckedVirtualTourUrl(true);
        } else {
            window.open(url, '_blank');
            if(history.length > 2) {
                history.goBack();
            } else {
                history.replace({
                    pathname: locationRef.current.pathname,
                    search: searchParams.toString(),
                });
            }
        }
    }, [listingExplorerStore, history]);

    if(!checkedVirtualTourUrl) {
        return null;
    }

    const url = addQueryStringsToUrl(listingExplorerStore.virtualTourUrl, { play: 1 });

    return (
        <Box height={1} width={1}>
            <iframe 
                style={{ width: '100%', height: '100%' }}
                title="3d tour"
                src={url}
            />
        </Box>
    );
});
