import React from 'react';
import { useResponsiveBreakpoint } from '../../../../hooks';
import { NurtureFirstRunFlowDesktop } from './desktop';
import { observer } from 'mobx-react-lite';
import { NurtureFirstRunFlowMobile } from './mobile';

export const NurtureFirstRunFlow = observer(function FirstRunFlow() {
    const isMobile = useResponsiveBreakpoint('xs');

    if(isMobile) {
        return <NurtureFirstRunFlowMobile />;
    } else {
        return <NurtureFirstRunFlowDesktop />;
    }
});
