import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import AnimatedScroll from '../../services/animated-scroll';
import { useStore } from '../../mobx-store';
import { useTheme, Grid, AppBar, MuiThemeProvider, Toolbar, Typography, Box } from '@material-ui/core';
import BoardUsersHeadshots from '../Messenger/BoardUsersHeadshots';
import { CardBoardCollaborationsTheme } from '../../theme';
import { ElevationScroll } from '../ElevationScroll';
import LRButton from '../LRButton';
import { Note } from './Note';
import Logger from 'js-logger';
import { LRAddNoteModal } from '../../components/LRAddNoteModal';
import { find } from 'lodash';
import analytics from '../../services/analytics';
import { ArrowForward } from '@material-ui/icons';
import { useBoardCollaborations } from '../../hooks/useBoardCollaborations';
import styled, { keyframes } from 'styled-components';
import { subject } from '@casl/ability';
import { Can, useAbility } from '../../components/Ability';
import { LANES_NAMES } from '../../constants';
import { useOnboarding } from '../Onboarding';
import { withTheme } from '@material-ui/core/styles';
import { EmptyNotesBackground } from './EmptyNotesBackground';
const logger = Logger.get('CardBoardCollaborations');

const BCWrapper = styled.div`
    position: relative;
    height: 100vh;
    max-height: -webkit-fill-available;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
`;


const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
        opacity: 0;
    }
    50% {
        opacity: 1.05
    }
    100% {
        transform: scale(1.1);
        opacity: 0;
    }
`;

const StyledLRButton = withTheme(styled(LRButton)`

    &.pulse:before {
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        transform: scale(0.5);
        opacity: 0;
        border-radius: 4px;
        border: 2px solid #ff6259
        animation: ${pulseAnimation} 1.2s linear infinite;
    }

`);


export const CardBoardCollaborations = observer(function CardBoardCollaborations({
    cardId,
    HeaderLeftContent,
    HeaderRightContent,
    ...props
}) {
    const { UserStore, boardsStore } = useStore();
    const { user, usersBoardRole, isAgent, isLO } = UserStore;
    const theme = useTheme();
    const scrollContainerRef = useRef();
    const [showMessages, setShowMessages] = useState(true);
    const activeBoard = boardsStore.activeBoard;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const {
        isLoading:boardCollaborationsLoading,
        boardCollaborations,
        addBoardCollaboration,
        updateBoardCollaboration,
        deleteBoardCollaboration,
    } = useBoardCollaborations(cardId);
    const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
    const [editBoardCardCollaboration, setEditBoardCardCollaboration] = useState(null);
    const onboarding = useOnboarding();
    const ability = useAbility();
    const hasCollaborationNotes = !!boardCollaborations?.find((cbc)=> {
        return cbc.type === 'comment' && ability.can('read', subject('BoardCollaboration', cbc));
    });
    const collaborationsCount = boardCollaborations?.reduce((accum, cbc)=> {
        if(!ability.can('read', subject('BoardCollaboration', cbc))){
            return accum;
        }
        return accum + 1;
    }, 0);
    // useEffects

    useEffect(function () {
        if(!boardCollaborationsLoading) {
            setShowMessages(true);
            scrollToBottom(0);
        }
    }, [boardCollaborationsLoading]);

    useEffect(()=> {
        if(editBoardCardCollaboration) setOpenAddNoteModal(true);
    }, [editBoardCardCollaboration]);


    // Functions

    function scrollToBottom(duration = 400) {
        if(scrollContainerRef.current) {
            if(duration > 0) {
                const scroll = new AnimatedScroll(scrollContainerRef.current);
                scroll.top(scrollContainerRef.current.scrollHeight, duration);
            } else {
                const orgOverflowValue = scrollContainerRef.current.style.overflow;
                scrollContainerRef.current.style.overflow = 'hidden';
                scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;

                /**
                 * A delay is needed in order for the scroll bar on a
                 * mac to be hidden and not stuck visible.
                 */
                setTimeout(()=> {
                    if(scrollContainerRef.current){
                        scrollContainerRef.current.style.overflow = orgOverflowValue;
                    }
                }, 10);
            }
        }
    }

    function toggleReaction(reaction = 'thumbsup', boardCollaboration) {
        const findByKey = isLO
            ? { userId: user?.affiliate?.preferredVendorId }
            : isAgent
                ? { affiliateId : user.affiliateId }
                : { userId : user.id };
        const usersReaction = find(boardCollaboration.reactions, findByKey);

        if(usersReaction) {
            deleteReaction(usersReaction);
        } else {
            createReaction(reaction, boardCollaboration);
        }
    }

    async function createReaction(reactionIcon, boardCollaboration) {
        const reactorId = isLO
            ? user?.affiliate?.preferredVendorId
            : isAgent
                ? user.affiliateId
                : user.id;
        const msg = {
            id: `reaction_${reactorId}_${boardCollaboration.id}_${activeBoardId}`,
            model: 'boardCollaboration',
            boardId: activeBoardId,
            itemId: boardCollaboration.id,
            content: {
                icon: reactionIcon,
            },
            type: 'reaction',
            visibleToAffiliate: boardCollaboration.visibleToAffiliate,
            visibleToLO: boardCollaboration.visibleToLO,
        };

        try {
            await addBoardCollaboration(msg);
            analytics.eventTrack('rpp_note_liked', {
                board_card_id: cardId,
                private: boardCollaboration.visibleToAffiliate,
                role: usersBoardRole,
            });
        } catch (err) {
            logger.error('createReaction -> Error', err);
        }
    }

    async function deleteReaction(usersReaction) {
        try {
            await deleteBoardCollaboration({ boardCollaboration: usersReaction, options: { soft_delete: false } });
        } catch (err) {
            logger.error('deleteReaction -> Error', err);
        }
    }

    const getLaneName = (column)=> {
        const lanes = {
            'maybes': LANES_NAMES['interested'],
            'favorites': LANES_NAMES['see'],
            'make-an-offer': LANES_NAMES['buy'],
        };

        return (lanes[_.kebabCase(column)]) ? lanes[_.kebabCase(column)] : column;
    };

    return (
        <MuiThemeProvider theme={CardBoardCollaborationsTheme}>
            <LRAddNoteModal
                boardCollaboration={editBoardCardCollaboration}
                open={openAddNoteModal}
                itemId={cardId}
                onClose={(e, createdNewCollaboration = false)=> {
                    setOpenAddNoteModal(false);
                    setEditBoardCardCollaboration(null);
                    if(createdNewCollaboration) scrollToBottom();
                }}
                addBoardCollaboration={addBoardCollaboration}
                updateBoardCollaboration={updateBoardCollaboration}
                deleteBoardCollaboration={deleteBoardCollaboration}
                eventSlug="rpp"
            />

            <BCWrapper
                {...props}
            >
                <ElevationScroll target={scrollContainerRef.current}>
                    <AppBar
                        position="sticky"
                        style={{
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <Toolbar>
                            <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                                style={{
                                    height: '100%',
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                <Grid item xs={true}>
                                    {HeaderLeftContent}
                                </Grid>
                                <Grid item>
                                    <BoardUsersHeadshots avatarBgcolor="#f9f9f9" style={{ width: '100%', height: '100%' }} />
                                </Grid>
                                <Grid item xs={true}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                        }}
                                    >
                                        {HeaderRightContent}
                                    </div>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>

                <div
                    ref={scrollContainerRef}
                    style={{
                        flexGrow: '1',
                        overflow: 'auto',
                    }}
                >
                    {boardCollaborations?.map((cbc, i)=> {
                        switch(cbc.type) {
                            case 'comment':
                                return (
                                    <Can key={cbc.id} I="read" this={subject('BoardCollaboration', cbc)}>
                                        <Note
                                            boardCardCollaboration={cbc}
                                            style={{
                                                marginTop: i > 0 ? theme.spacing(2) : '',
                                                marginLeft: theme.spacing(3),
                                                marginRight: theme.spacing(3),
                                                cursor: 'pointer',
                                            }}
                                            onEditClick={()=> {
                                                setEditBoardCardCollaboration(cbc);
                                            }}
                                            onReactionClick={(e, reaction)=> {
                                                toggleReaction(reaction, cbc);
                                            }}
                                        />
                                    </Can>
                                );

                            case 'moved_card':
                                return (
                                    <div
                                        key={cbc.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-center',
                                            alignItems: 'center',
                                            marginTop: theme.spacing(2),
                                            marginLeft: theme.spacing(3),
                                            marginRight: theme.spacing(3),
                                            color: theme.palette.lr_colors?.grey_dark,
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: theme.spacing(1),
                                                position: 'relative',
                                                top: '3px',
                                            }}
                                        >
                                            <ArrowForward />
                                        </div>

                                        <div>
                                            <Typography variant="body2">
                                                {cbc.user?.first_name} moved this listing to “{getLaneName(cbc.content.toColumn)}”
                                            </Typography>
                                        </div>
                                    </div>
                                );

                            case 'schedule_viewing_request':
                            case 'offer_submitted':
                                if(!cbc?.content?.message?.length) return null;
                                return (
                                    <Can key={cbc.id} I="read" this={subject('BoardCollaboration', cbc)}>
                                        <Note
                                            boardCardCollaboration={cbc}
                                            style={{
                                                marginTop: i > 0 ? theme.spacing(2) : '',
                                                marginLeft: theme.spacing(3),
                                                marginRight: theme.spacing(3),
                                                cursor: 'pointer',
                                            }}
                                            onEditClick={()=> {
                                                setEditBoardCardCollaboration(cbc);
                                            }}
                                            onReactionClick={(e, reaction)=> {
                                                toggleReaction(reaction, cbc);
                                            }}
                                        />
                                    </Can>
                                );

                            default:
                                return null;
                        }
                    })}

                    {!hasCollaborationNotes && collaborationsCount < 1 && (
                        <Box position="absolute" top="50%" left="50%" style={{transform: 'translate(-50%, -50%)'}}>
                            <EmptyNotesBackground />
                        </Box>
                    )}
                    {/* Small spacer to make sure the shadow of the boxes above show up */}
                    <div style={{ height: '4px' }} />
                </div>

                <AppBar
                    position="static"
                    elevation={0}
                    style={{
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    <Toolbar>
                        <StyledLRButton
                            className="add-note-btn"
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={()=> {
                                if(onboarding.isAddNoteOnboarding && onboarding.stepId === 'await-note-click') {
                                    onboarding.next();
                                }
                                setOpenAddNoteModal(true);
                            }}
                        >
                            + Add a note
                        </StyledLRButton>
                    </Toolbar>
                </AppBar>
            </BCWrapper>
        </MuiThemeProvider>
    );
});

CardBoardCollaborations.propTypes = {
    boardId: PropTypes.string.isRequired,
    cardId: PropTypes.string,
    HeaderLeftContent: PropTypes.element,
    HeaderRightContent: PropTypes.element,
    style: PropTypes.object,
};
