import React from 'react';
import { Box } from '@material-ui/core';

function LRPoweredByLabel(props) {
    const { children, ...restProps } = props;

    return (
        <Box position="absolute"
            width="100%"
            color="#fff"
            textAlign="center"
            top={0}
            py={1}
            zIndex={2}
            fontSize={11}
            style={{ opacity: 0.6 }}
            {...restProps}
        >
            {children ? children : 'Powered by ListReports'}
        </Box>
    );
}

export default LRPoweredByLabel;
