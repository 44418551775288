import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useStore } from '../../mobx-store';
import { Link, Route, Switch, useHistory, useParams  } from 'react-router-dom';
import { useSignupStore, SignupStoreProvider, useSignupStoreContext } from './store';
import { Box, CircularProgress, Divider, Grid, useTheme, Typography } from '@material-ui/core';
import { AutoInfo } from '../../components/AuthInfo';
import LRLink from '../../components/LRLink';
import { OnboardingVideoModal } from '../../components/OnboardingVideoModal';
import { FacebookBtn, GoogleBtn, AppleBtn } from '../../components/LRSocialLoginButtons';
import analytics from '../../services/analytics';
import { AUTH_ONBOARDING_VIDEO } from '../../constants/routes';
import { observer } from 'mobx-react-lite';
import { AgentLoginModal } from '../../components/AgentLoginModal';
import LRButton from '../../components/LRButton';
import { useQuery } from '../../hooks/use-query';
import { SignupForm } from './SignupForm';
import { CodeInput } from '../CodeInput';
import { withTheme } from '@material-ui/styles';
import styled from 'styled-components';
import AgentInfo from './AgentInfo';
import { LREmailPhoneField } from '../../components/LREmailPhoneField';
import { MobileAppAd } from '../../components/MobileAppAd';
import { ContactSupport } from '../../components/ContactSupport';
import { useForm } from 'react-hook-form';
import { SIGNUP_LOGIN_VARIANT_PAGE, SIGNUP_LOGIN_VARIANT_WIDGET } from '../../constants';
import { RobinLogo } from '../../components/RobinLogo';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Logger from 'js-logger';
import { LRInputOutlined } from '../../components/LRInput';
import formatPhoneNumber from 'services/format-phone-number';

export function SignupHeader({
    variant = SIGNUP_LOGIN_VARIANT_PAGE, // page or widget
    title,
    showLoginInSignup = true,
    onSwitchToLoginClick,
}) {

    return (
        <>
            <Box mt={5} fontSize={32} fontWeight={500} textAlign="center">
                {title}
            </Box>
            {showLoginInSignup && (
                <Box mt={5 / 4} textAlign="center">
                    {variant === SIGNUP_LOGIN_VARIANT_PAGE && (<Box>Robin is currently available by invite only.</Box>)}
                    <Box>
                        Already have an account? <LRLink to={!onSwitchToLoginClick && '/login'} onClick={onSwitchToLoginClick}>Log in.</LRLink>
                    </Box>
                </Box>
            )}
        </>
    );
}

export const SignupMenu = observer(function SignupMenu({
    variant = SIGNUP_LOGIN_VARIANT_PAGE, // page or widget
    hideAgentBtn= false
}) {
    const signupStore = useSignupStoreContext();
    const theme = useTheme();
    const [openedAgentLoginModal, setOpenedAgentLoginModal] = useState(false);
    const rfhMethods = useForm({
        mode: 'onChange',
        submitFocusError: false,
    });
    const { handleSubmit, setError } = rfhMethods;
    const [socialError, setSocialError] = useState(false);

    async function onSubmit(data){
        setSocialError(false);

        const { input, input_type } = data;
        const methodMap = {
            'email': 'email',
            'tel': 'mobile',
        };

        registerClickEvent(methodMap[input_type]);

        const inputChecks = [signupStore.userExists(input)];

        if(input_type === 'tel') {
            inputChecks.push(signupStore.isPhoneValid(input));
        }

        const [exists, { isValid: phoneIsValid, reason } = {} ] = await Promise.all(inputChecks);

        if(exists) {
            const messageMap = {
                'email': 'Email',
                'tel': 'Phone Number',
            };

            setError('input', 'manual', `${messageMap[input_type]} already exists.`);
        } else if(input_type === 'tel' && !phoneIsValid) {
            setError('input', 'manual', reason);
        } else {
            signupStore.setInputInfo(input, input_type);
        }
    }

    async function handleLoginSuccess(authResponse) {
        const exists = await signupStore.userExists(authResponse.userInfo.email);
        if(exists) {
            setSocialError(authResponse.auth.method);
        } else {
            signupStore.setExternalProviderInfo(authResponse);
        }
    }

    function registerClickEvent(method) {
        const eventName = `robin_${method}_register_option_click`;
        analytics.eventTrack(eventName);
    }

    return (
        <>
            <Box width="calc(100% - 32px)" mx={2} mt={5 / 2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <LREmailPhoneField
                        defaultValue={signupStore.userInfo?.phone ? formatPhoneNumber(signupStore.userInfo.phone, { withParentheses: true }) : signupStore.userInfo?.email || ''}
                        rfhMethods={rfhMethods}
                        required
                        name="input"
                        placeholder="Enter email or mobile phone number"
                        message="Please ensure you entered a 10 digit mobile number or a valid email address." />
                    <Box mt="19px">
                        <LRButton
                            fullWidth
                            variant="contained"
                            style={{
                                height: '48px'
                            }}
                            color="primary"
                            type="submit"
                        >
                            Continue
                        </LRButton>
                    </Box>
                </form>
            </Box>
            <Box mt={2} align="center">or</Box>
            <Box width={1} px={2}>
                {/* <Box mt={2}>
                    <FacebookBtn
                        signUp
                        onClick={()=> {
                            setSocialError(false);
                            registerClickEvent('google');
                        }}
                        onLoginSuccess={handleLoginSuccess}
                    />
                    {socialError === 'facebook' && (
                        <Box mt={1} fontSize="12px" color="#d51d4c"textAlign="left">
                            An account with this email already exists. Please Login
                        </Box>
                    )}
                </Box> */}

                <Box mt={2}>
                    <GoogleBtn
                        signUp
                        onClick={analytics.eventTrackOnEvent('robin_google_register_option_click', {}, ()=> {
                            setSocialError(false);
                            registerClickEvent('google');
                        })}
                        onLoginSuccess={handleLoginSuccess}
                    />
                    {socialError === 'google' && (
                        <Box mt={1} fontSize="12px" color="#d51d4c"textAlign="left">
                            An account with this email already exists. Please Login
                        </Box>
                    )}
                </Box>

                <Box mt={2}>
                    <AppleBtn
                        signUp
                        onClick={analytics.eventTrackOnEvent('robin_apple_register_option_click', {}, ()=> {
                            setSocialError(false);
                            registerClickEvent('apple');
                        })}
                        onLoginSuccess={handleLoginSuccess}
                    />
                    {socialError === 'apple' && (
                        <Box mt={1} fontSize="12px" color="#d51d4c"textAlign="left">
                            An account with this email already exists. Please Login
                        </Box>
                    )}
                </Box>
            </Box>
            {!hideAgentBtn && (
                <>
                    <Box
                        width="calc(100% - 32px)"
                        mt={3}
                        mx="auto"
                        borderBottom={`1px solid ${theme.palette.lr_colors.border_grey}`}
                    />

                    <Box width="calc(100% - 32px)" mx={2} mt={3}>
                        <LRButton
                            fullWidth
                            variant="outlined"
                            style={{
                                height: '48px',
                                fontWeight: 'normal',
                                backgroundColor: '#FFFFFF',
                            }}
                            onClick={()=> {
                                analytics.eventTrack('robin_login_im_an_agent_button_click');
                                setOpenedAgentLoginModal(true);
                            }}
                        >
                            I’m an agent
                        </LRButton>
                    </Box>
                </>
            )}
            <Box width={variant === SIGNUP_LOGIN_VARIANT_PAGE ? 'calc(100% - 32px)' : '100%'}>
                {/* <Box height={24} />
                <MobileAppAd source='signup' /> */}
                <Box mt={3}>
                    <ContactSupport source='signup' />
                </Box>
            </Box>

            <AgentLoginModal
                open={openedAgentLoginModal}
                onClose={()=> setOpenedAgentLoginModal(false)}
            />
        </>
    );
});

const StyledSubmitButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
        color: ${({ theme })=> theme.palette.lr_colors.grey_dark};
    }
`);

export const Verification = observer(function Verification({ onLoginSuccess, method='phone' }) {
    const theme = useTheme();
    const signupStore = useSignupStoreContext();
    const [code, setCode]  = useState('');

    const wrongCode = signupStore.activateDeviceRequest.hasError;
    const submitting = signupStore.activateDeviceRequest.isLoading;
    const disabledResend = signupStore.sendCodeRequest.isLoading;
    const userInfo = signupStore.userInfo;
    const titleMessageByMethod = {
        phone: 'Verify your mobile phone number',
        email: 'Verify your email address',
    };
    const subtitleMessageByMethod = {
        phone: `We just sent you a 4-digit verification code via text message to ${userInfo.phone}. Please enter it below.`,
        email: `We just sent you a 4-digit verification code via email to ${userInfo.email}. Please enter it below.`
    };
    const [verificationSuccess, setVerificationSuccess] = useState(false);

    async function submitCode() {
        analytics.eventTrack('robin_verify_click', {
            consumer_name: `${signupStore.userInfo.first_name} ${signupStore.userInfo.last_name}`,
            consumer_email: signupStore.userInfo.email,
            consumer_phone: signupStore.userInfo.phone,
            Source: signupStore.externalAuth?.method || (signupStore.isSignupByEmail && 'email') || (signupStore.isSignupByPhone && 'mobile'),
        });

        await signupStore.activateDevice(code);

        if(signupStore.activateDeviceRequest.hasLoaded) {
            const loggedinUser = signupStore.activateDeviceRequest.data;
            setVerificationSuccess(true);
            onLoginSuccess(loggedinUser);
        } else if(signupStore.activateDeviceRequest.hasError) {
            setCode('');
        }
    }

    function resendCode() {
        signupStore.sendCode(method);
    }

    function onEnterClick(event) {
        event.preventDefault();
        if(code?.length >= 4 && !submitting) {
            submitCode();
        }
    }

    function setCodeValue(event){
        if(event.target.value.match(/^[0-9]*$/i)) {
            setCode(event.target.value);
        }
    }

    return (
        <>
            <Box mx={2}>
                <Box mt={5} fontSize={32} fontWeight={500} lineHeight="40px" textAlign="center">
                    {wrongCode ? 'Hrm, that’s not right.' : titleMessageByMethod[method]}
                </Box>
                <Box mt={2} textAlign="center">
                    {wrongCode
                        ? 'The 4-digit code that you entered is incorrect. Please try entering it again below.'
                        : subtitleMessageByMethod[method]}
                </Box>
            </Box>
            <form
                style={{
                    width: '100%',
                    display: 'block',
                    padding: `0 ${theme.spacing(2)}px`
                }}
                onSubmit={onEnterClick}
            >
                <Box mt={3}>
                    <LRInputOutlined
                        name='code'
                        value={code}
                        onChange= {setCodeValue}
                        required={true}
                        type="text"
                        inputProps={{
                            height: '48px',
                            fontSize: theme.typography.body1.fontSize,
                            borderRadius: '4px',
                            textAlign: 'center',
                            maxLength: 4,
                            width: '100%',
                            autoFocus: true,
                        }}
                    />
                </Box>
                <Box mt={7 / 2}>
                    <StyledSubmitButton
                        disabled={
                            code?.length < 4 || submitting || verificationSuccess
                        }
                        type="submit"
                        value="Submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            height: '48px',
                            fontWeight: 'normal',
                        }}
                        onClick={analytics.eventTrackOnEvent('robin_mobile_verify')}
                    >
                        {!verificationSuccess && !submitting && 'Verify'}
                        {(verificationSuccess || submitting) && <CircularProgress size={24} color="secondary" />}
                    </StyledSubmitButton>
                </Box>
            </form>
            <Box mt={2} width="calc(100% - 32px)">
                <LRButton
                    color="secondary"
                    fullWidth
                    disabled={disabledResend}
                    style={{
                        height: '48px',
                        fontWeight: 'normal',
                    }}
                    onClick={()=> resendCode(userInfo.phone)}
                >
                    Re-send verification code
                </LRButton>
            </Box>
        </>
    );
});

export const SignUp = observer(function SignUpV2({
    variant = SIGNUP_LOGIN_VARIANT_PAGE, // page or widget
    onSignUpSuccess = ()=> {},
    onSwitchToLoginClick,
}) {
    const theme = useTheme();
    const history = useHistory();
    const { UserStore, LoginSignupModalStore } = useStore();
    const signupStore = useSignupStore();
    const { affiliateCode:affiliateCodeParam } = useParams();
    const affiliateCodeLSMStore = LoginSignupModalStore.affiliateCode;
    const affiliateCode = useMemo(()=> {
        return affiliateCodeLSMStore || affiliateCodeParam;
    }, [affiliateCodeLSMStore, affiliateCodeParam]);
    const query = useQuery();
    const inviteIdRef = useRef(query.get('inviteId'));
    const { affiliate } = signupStore;
    const variantProps = {
        [SIGNUP_LOGIN_VARIANT_PAGE]: {
            height: {xs: 'calc(100% - 211px)', md:'100%'},
            width: ['100%', '100%', '25%'],
            minWidth: ['initial', 'initial', 300],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: affiliate && 'auto',
        },
        [SIGNUP_LOGIN_VARIANT_WIDGET]: {
            width: '100%',
        }
    };
    const [openOnboardingVideo, setOpenOnboardingVideo] = useState(false);
    const currentRoute = (affiliateCode && affiliateCode !== AUTH_ONBOARDING_VIDEO) ? `/signup/${affiliateCode}` : '/signup';

    async function handleLoginSuccess(loggedinUser) {
        if(signupStore.isSignupByPhone) {
            analytics.eventTrack('robin_mobile_verified', {
                consumer_name: loggedinUser.name,
                consumer_email: loggedinUser.email,
                consumer_phone: loggedinUser.phone,
                Source: signupStore.externalAuth?.method || 'mobile'
            });
        }

        UserStore.storeToken(loggedinUser.token);
        await UserStore.fetchLocalUser();
        await UserStore.fetchUserData();

        if(variant === SIGNUP_LOGIN_VARIANT_PAGE) history.push('/boards');

        // If not email signup mark email as verified
        if(!signupStore.isSignupByEmail) {
            UserStore.updateUser({ verified: { email: true } });
        }

        onSignUpSuccess();
    }

    function onOpenOnboardingVideo() {
        setOpenOnboardingVideo(true);
    }

    function onCloseOnboardingVideo() {
        setOpenOnboardingVideo(false);
    }

    // UseEffects

    useEffect(()=> {
        if(signupStore.skipVerification) {
            handleLoginSuccess(signupStore.userInfo);
        }
    }, [signupStore.skipVerification]);

    useEffect(()=> {
        if(inviteIdRef.current) {
            signupStore.fetchInvite(inviteIdRef.current);
        } else {
            signupStore.goToMenu();
        }
    }, [signupStore]);

    useEffect(()=> {
        if((affiliateCode && affiliateCode !== AUTH_ONBOARDING_VIDEO)) {
            signupStore.affiliateRequest.fetch(affiliateCode);
        }
    }, [signupStore, affiliateCode]);

    useEffect(()=> {
        if(affiliateCode && affiliate && affiliateCode !== AUTH_ONBOARDING_VIDEO) {
            analytics.eventTrack('robin_vanity_url_registration_page_viewed', {
                distinct_id: affiliate.id,
            });
        }
    }, [affiliateCode, affiliate]);

    useEffect(()=> {
        if(!signupStore.userInfo && LoginSignupModalStore.userInfo != signupStore.userInfo) {
            signupStore.userInfo = LoginSignupModalStore.userInfo;
        }
    }, [LoginSignupModalStore.userInfo, signupStore.userInfo]);

    return (
        <>
            <SignupStoreProvider signupStore={signupStore}>
                <Box
                    height={variant === SIGNUP_LOGIN_VARIANT_PAGE ? '100vh' : variant === SIGNUP_LOGIN_VARIANT_WIDGET ? 'auto' : undefined}
                    display="flex"
                    flexDirection={['column', 'column', 'row']}
                >
                    {variant === SIGNUP_LOGIN_VARIANT_PAGE && <AutoInfo trackSource="signup" />}

                    <Box
                        {...variantProps[variant]}
                    >
                        {(signupStore.inSignupMenu || signupStore.inOrganicSignup || signupStore.skipVerification) && (
                            <>
                                <SignupHeader
                                    variant={variant}
                                    title={signupStore.inOrganicSignup ? 'Finish signing up' : 'Sign up'}
                                    onSwitchToLoginClick={onSwitchToLoginClick}
                                />
                                {signupStore.inSignupMenu && <SignupMenu variant={variant} />}
                                {(signupStore.inOrganicSignup || signupStore.skipVerification) && <SignupForm />}
                            </>
                        )}
                        {signupStore.inVerification && <Verification onLoginSuccess={handleLoginSuccess}  method={signupStore.signUpMethodForVerification}/>}
                        {signupStore.isInitialState && <CircularProgress size={30} color="secondary" />}

                        {variant === SIGNUP_LOGIN_VARIANT_PAGE && signupStore.affiliateRequest.hasLoaded && affiliate && (
                            <Divider flexItem style={{ height: 1, margin: '32px 0' }} />
                        )}
                        {variant === SIGNUP_LOGIN_VARIANT_PAGE && signupStore.affiliateRequest.isLoading && (
                            <Box mt={3}>
                                <CircularProgress size={30} color="secondary" />
                            </Box>
                        )}
                        {variant === SIGNUP_LOGIN_VARIANT_PAGE && affiliate && <AgentInfo agent={affiliate} />}
                    </Box>
                </Box>

                {variant === SIGNUP_LOGIN_VARIANT_WIDGET && (
                    <>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{
                                marginTop: theme.spacing(4),
                                marginBottom: theme.spacing(-3),
                                padding: theme.spacing(2),
                                backgroundColor: theme.palette.lr_colors.costa_mesa,
                            }}
                        >
                            <Grid item>
                                <RobinLogo width={89} color={theme.palette.lr_colors.salmon} />
                            </Grid>

                            <Grid item style={{ maxWidth: 328, }}>
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: theme.palette.lr_colors.grade,
                                        textAlign: 'center',
                                    }}
                                >
                                    Search, organize, and own your home buying process together.
                                </Typography>
                            </Grid>

                            <Grid item style={{ marginTop: theme.spacing(2.5), marginBottom: theme.spacing(2), }}>
                                <LRButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onOpenOnboardingVideo}
                                >
                                    <PlayCircleFilledIcon style={{marginRight: theme.spacing(2)}}/>
                                    Watch video
                                </LRButton>
                            </Grid>
                        </Grid>

                        <OnboardingVideoModal open={openOnboardingVideo} onClose={onCloseOnboardingVideo} />
                    </>
                )}
            </SignupStoreProvider>
            <Switch>
                <Route
                    path={`${currentRoute}/${AUTH_ONBOARDING_VIDEO}`}
                    render={()=> (
                        <OnboardingVideoModal onClose={()=> history.push(currentRoute)} />
                    )}
                />
            </Switch>
        </>
    );
});
