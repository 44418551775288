import { benoitApi } from 'apis';
import { getListpackBabouListingsById, mergeBabouListingInfo } from '../../services/babou';
import Logger from 'js-logger';
import { find, findIndex, keyBy, merge, orderBy, pick, reject, values, get } from 'lodash';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useStore } from 'mobx-store';
import { BOARD_LISTPACK_CACHE_KEY, DEFAULT_LISTING_HERO_IMAGE, LISTPACK_DEFAULT_SORT_ORDER, LISTPACK_SORT_MAPPING } from '../../constants';
import { Listing } from 'mobx-store/domain/listings-store';
import simpleCypher from 'services/simple-cypher';
import { updateListpack } from 'apis/benoit';
import { useMemo } from 'react';
import analytics from 'services/analytics';
import { useListing } from 'hooks/data/listing/useListing';
import { useBoardListpacksRealtime } from './useBoardListpacksRealtime';
import { useBoardListpacksInitialData } from './useBoardListpacksInitialData';
import { Listpack } from './Listpack';
import { useParams } from 'react-router';
import { useBoardCards } from 'hooks/useBoardCards';

const logger = Logger.get('useBoardListpacks');
const MAX_LISTINGS_PER_PAGE = 30;

export function useBoardListpacks() {
    const queryClient = useQueryClient();
    const { UserStore, boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const boardId = activeBoard?.id;
    const collaboratorsById = keyBy(boardsStore?.activeBoard?.collaborators, 'userId');
    const {
        getUnarchivedBoardCardsByLaneId,
    } = useBoardCards();

    useBoardListpacksRealtime(boardId, UserStore);

    /**
     * Main listpack query
     */
    const { data, ...query } = useQuery(
        [BOARD_LISTPACK_CACHE_KEY, boardId],
        async ({ queryKey: [, boardId] })=> {
            let listpacks = await benoitApi.getBoardListpacks(boardId);
            listpacks = listpacks.map((lp)=> new Listpack(lp, UserStore));

            return listpacks;
        },
        {
            enabled: Boolean(boardId),
        },
    );
    let listpacks = data;

    /**
     * Start fetching initial data for each listpack
     */
    const listpackListingsInitialDataQueries = useBoardListpacksInitialData(listpacks);

    /**
     * Add any additional data we have received from additional
     * queries here.
     */
    listpacks = useMemo(()=> {
        return listpacks?.map((lp, i)=> {
            const countData = listpackListingsInitialDataQueries[i]?.data;
            const countsLoaded = Boolean(countData);

            if(countsLoaded) merge(lp, countData);

            lp.loadingListingsCount = listpackListingsInitialDataQueries?.[i]?.isLoading ?? true;
            lp.listpackDataLoaded = true; // Always true because we want tp show the results immediately
            lp.user = collaboratorsById[lp.userId];

            return lp;
        });
    }, [listpacks, listpackListingsInitialDataQueries, collaboratorsById]);

    /**
     * Create and update mutation
     */
    const createOrUpdateListpackMutation = useMutation(
        async (listpackData)=> {
            const { user } = UserStore;
            let listpack;

            if(UserStore.isAgent) {
                listpackData.affiliateId = user.affiliateId;
            }

            if(listpackData.id) {
                listpack = new Listpack(undefined, UserStore);
                await listpack.update(listpackData);
            } else {
                listpack = await createListpack(listpackData);
                listpack = new Listpack(listpack, UserStore);

                if(UserStore.isFromNurtureUnlimited && UserStore.hubspotRecordId) {
                    try {
                        const hubspotContact = await benoitApi.getHubspotContact(UserStore.hubspotRecordId);

                        if(hubspotContact?.properties?.nurture_unlimited_status === 'Cold') {
                            await benoitApi.updateHubspotContact(UserStore.hubspotRecordId, { nurture_unlimited_status: 'Warm' });
                        }

                        analytics.eventTrack('robin_nurture_hubspot_email_campaign_cancelled');
                    } catch (err) {
                        analytics.eventTrack('robin_nurture_hubspot_email_campaign_cancellation_failure');
                    }
                }
            }

            return listpack;
        },
        {
            onSuccess: (listpack)=> {
                listpack = new Listpack(listpack, UserStore);
                const index = findIndex(listpacks, { id: listpack.id });
                const newListpacks = ~index ? [...listpacks.slice(0, index), listpack, ...listpacks.slice(index + 1)] : [...listpacks, listpack];

                queryClient.setQueryData([BOARD_LISTPACK_CACHE_KEY, boardId], newListpacks);
                queryClient.invalidateQueries(BOARD_LISTPACK_CACHE_KEY);
            }
        }
    );



    /**
     * Archive a listpack mutation
     */
    const archiveListpackMutation = useMutation(
        (listpack)=> (updateListpack({ id: listpack.id, archived: true, })),
        {
            onSuccess: (listpack)=> {
                queryClient.setQueryData([BOARD_LISTPACK_CACHE_KEY, boardId], reject(listpacks, { id: listpack.id }));
                queryClient.invalidateQueries(BOARD_LISTPACK_CACHE_KEY);
            }
        }
    );

    /**
     * Return data
     */
    return {
        ...query,
        listpacks,

        // Getters and Setters

        get isRefreshing() {
            return this.isStale && this.isFetching && !this.isLoading;
        },

        get sortedListpacks() {
            const sortedListpacks = orderBy(this.listpacks, ['opened', 'handSelected','lastSeenDate', ], ['desc', 'desc', 'desc']);
            const defaultValue = {all: [], opened: [], closed: [], handSelected: null, archived: null };
            let orderedListpacks = sortedListpacks.reduce((accum, value)=> {
                const isHandSelected = value.handSelected && !value.archived;

                if(value.handSelected) {

                    const newCount = getUnarchivedBoardCardsByLaneId('1000').filter((c)=> {
                        const match =  !value.lastSeenDate ||
                            new Date(c.createdAt).getTime() > new Date(value?.lastSeenDate).getTime();
                        return match;
                    }).length;

                    value.newListingCards = newCount;
                    value.listpackCards = getUnarchivedBoardCardsByLaneId('1000');
                }

                return {
                    all: [
                        ...accum.all,
                        value
                    ],
                    opened: [
                        ...accum.opened,
                        ...(value.opened && !value.archived ? [value] : []),
                    ],
                    closed: [
                        ...accum.closed,
                        ...(!value.opened && !value.archived ? [value] : []),
                    ],
                    handSelected: isHandSelected ? value : accum.handSelected,
                    archived: value.archived
                };
            }, defaultValue);

            return orderedListpacks;
        },

        // Functions

        createOrUpdateListpack: createOrUpdateListpackMutation.mutateAsync.bind(createOrUpdateListpackMutation),
        archiveListpack: archiveListpackMutation.mutateAsync.bind(archiveListpackMutation),
    };
}

export function useNewBoardListpack(data) {
    const { UserStore, boardsStore } = useStore();
    const collaboratorsById = keyBy(boardsStore?.activeBoard?.collaborators, 'userId');
    const listpack = new Listpack(data, UserStore);

    /**
     * Start fetching initial data for listpack
     */
    const listpackListingsInitialDataQueries = useBoardListpacksInitialData([listpack]);

    if(listpackListingsInitialDataQueries[0]?.data) {
        const countData = listpackListingsInitialDataQueries[0]?.data;
        const countsLoaded = Boolean(countData);

        if(countsLoaded) merge(listpack, countData);

        listpack.loadingListingsCount = listpackListingsInitialDataQueries?.[0]?.isLoading ?? true;
        listpack.listpackDataLoaded = true;

        if(UserStore.isLoggedIn) {
            listpack.userId = UserStore.user.id;
            listpack.user = collaboratorsById[listpack.userId];
        }
    }

    return listpack;
}

export function useBoardListpackListings(listpack, sortOrder = LISTPACK_DEFAULT_SORT_ORDER, firstPageLimit) {
    const query = useInfiniteQuery(
        [`${BOARD_LISTPACK_CACHE_KEY}-listings`, listpack?.id, pick(listpack, ['areaIds', 'fields']), sortOrder],
        ({ pageParam = 0 })=> {
            return listpack?.areaIds?.length ? fetchListpackListings(listpack, sortOrder, pageParam) : [];
        },
        {
            enabled: Boolean(listpack?.areaIds?.length),
            getNextPageParam: (lastPage, allPages)=> listpack?.listings_type !== 'static' && (allPages.length * MAX_LISTINGS_PER_PAGE) < listpack?.activeListingsCount ? allPages.length * MAX_LISTINGS_PER_PAGE : undefined,
            getPreviousPageParam: ()=> 0,
        },
    );
    const pagesLoaded = query.data?.pages?.length || 0;
    const allListings = [].concat.apply([], query.data?.pages);
    const listings = (firstPageLimit && !isNaN(firstPageLimit) && pagesLoaded === 1) ? allListings.slice(0, firstPageLimit) : allListings;
    let listingCards = listings.map((listing_details)=> ({ listing_details }));
    if(listpack?.handSelected) {

        listingCards = orderBy(listpack.listpackCards, (listing)=> {
            const value = get(listing, sortOrder.id);
            if(value) {
                return value;
            }

            return String.fromCharCode(sortOrder.order === 'desc'? 0 : 65535);
        }, sortOrder.order);
    }


    return {
        ...query,
        listings,
        listingCards,
        pagesLoaded,
    };
}

export function useBoardListpackListing(listpack, listingId, sortOrder) {
    const { listings, isLoading } = useBoardListpackListings(listpack, sortOrder);
    const includesListing = useMemo(()=> {
        if(isLoading) return false;

        return Boolean(find(listings, { id: listingId }));
    }, [isLoading, listingId, listings]);
    const { data:manualListingData, isLoading:isLoadingManualListing } = useListing((isLoading || !includesListing) && listingId);
    const listing = useMemo(()=> {
        if(isLoading && isLoadingManualListing) return;

        let listing;

        if(includesListing) {
            const l = find(listings, { id: listingId });

            if(l) listing = l;
        }

        if(!listing && !isLoadingManualListing) {
            listing = manualListingData;
        }

        return listing;
    }, [includesListing, isLoading, isLoadingManualListing, listingId, listings, manualListingData]);

    return {
        listing,
    };
}

export function useBoardListpackHeroImages(listpack, listings = [], maxImages = 3) {
    return getHeroImageFromListpackAndListings(listpack, listings, { maxImages });
}

export function useBoardListpack(listpackId) {
    const { listpacks, isLoading, createOrUpdateListpack, archiveListpack, } = useBoardListpacks();
    return {
        isLoading,
        listpack: (listpacks || []).find((listpack)=> listpack.id === listpackId),
        createOrUpdateListpack,
        archiveListpack,
    };
}

export function useMLSComplianceByListingId(listingId, affiliateCode) {
    const { UserStore } = useStore();
    const { affiliateCode: affiliateCodeParam } = useParams(); // Fallback for when a user is logged out
    affiliateCode = useMemo(()=> affiliateCode || UserStore.user?.affiliate || affiliateCodeParam, [affiliateCode, affiliateCodeParam, UserStore.user]);
    const { data: complianceText, ...query} = useQuery(
        ['mls-compliance-text-by-listing-id', listingId, affiliateCode],
        ({ queryKey: [, listingId] })=> (benoitApi.getMLSCompliance(listingId, affiliateCode)),
        {
            enabled: Boolean(listingId && affiliateCode),
        },
    );

    return {
        ...query,
        complianceText,
    };
}

export function useMLSComplianceByListingIdArray(arrayListingId, affiliateCode) {
    const { UserStore } = useStore();
    const affiliate = useMemo(()=> affiliateCode || UserStore.user?.affiliate , [affiliateCode, UserStore.user]);
    const promisesMLS = useMemo(()=> arrayListingId.map((listingId)=> fetchMLSCompliance(listingId, affiliateCode)), [affiliateCode, arrayListingId]);

    async function fetchMLSCompliance(listingId, affiliate){
        return benoitApi.getMLSCompliance(listingId, affiliate);
    }

    return useQuery(
        ['mls-compliance-text-by-listing-id-array', affiliate, arrayListingId],
        ()=> Promise.all(promisesMLS),
    );
}

// Helper Functions
function getHeroImageFromListpackAndListings(listpack, listings = [], options) {
    options = {
        allowDefaultImage: true,
        maxImages: 3,
        ...options,
    };
    const mainHeroImage = listpack.hero_image;
    const listingsSize = listings?.length;
    const heroImages = [];

    if(listpack.handSelected && !listpack.listpackCards?.length && options.allowDefaultImage) {
        heroImages.push(DEFAULT_LISTING_HERO_IMAGE);
    }

    // Put the main hero image if exists
    if(mainHeroImage) heroImages.push(mainHeroImage);

    const heroImagesNumber = options.maxImages + heroImages.length;

    if(listingsSize <= 0) return heroImages;

    // Search in listings for more photos to use for the hero images
    for(let i = 0; i < listingsSize; i++) {
        const listingPhotos = !listpack.handSelected ? listings[i]?.photos : listings[i]?.listing_details?.photos;

        if(listingPhotos && listingPhotos[0]) {
            heroImages.push(listingPhotos[0]);
        }

        if(heroImages.length === heroImagesNumber) {
            break;
        }
    }

    if(!heroImages.length && options.allowDefaultImage) heroImages.push(DEFAULT_LISTING_HERO_IMAGE);

    return heroImages.slice(-options.maxImages);
}

async function fetchListingsByIds(listingsIds) {
    const listingsDetails = await benoitApi.getListingsDetailsByIds(listingsIds);
    const listings = listingsDetails.map((listingDetails)=> {
        return new Listing(listingDetails);
    });

    return listings;
}

async function fetchListpackListings(listpack, sortOrder, skip = 0, limit = MAX_LISTINGS_PER_PAGE) {
    const { listings_type, listing_ids } = listpack;
    let listings;

    if(listings_type === 'static') {
        const listingIds = listing_ids.map((l)=> {
            return simpleCypher.encode(l.source, l.id);
        });
        const listingDetails = await fetchListingsByIds(listingIds);
        const listingIdsMapping = listing_ids.map((l)=> ({
            addedAt: l.addedAt,
            encodedId: simpleCypher.encode(l.source, l.id),
        }));
        listings = values(merge(
            keyBy(listingDetails, 'encodedId'),
            keyBy(listingIdsMapping, 'encodedId')
        ));
    } else {
        const babouListings = await getListpackBabouListingsById(listpack, skip, limit, LISTPACK_SORT_MAPPING[sortOrder] || sortOrder);
        const listingIds = babouListings.map((l)=> {
            return simpleCypher.encode(l.LRSource, l.id);
        });
        const listingDetails = await fetchListingsByIds(listingIds);
        listings = mergeBabouListingInfo(babouListings, listingDetails);
    }

    return listings;
}

async function createListpack(listpackData) {
    const listings = await fetchListpackListings(listpackData, LISTPACK_DEFAULT_SORT_ORDER, 0, 10);
    const [heroImage] = getHeroImageFromListpackAndListings(listpackData, listings, { maxImages: 1, allowDefaultImage: false });
    listpackData.hero_image = heroImage;
    const listpack = await benoitApi.createListpack(listpackData);

    return listpack;
}
