import { AppBar, Dialog, Grid, IconButton, Toolbar, Typography, useTheme, Hidden } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { portalApi } from 'apis';
import CopyToClipboard from 'components/CopyToClipboard';
import ListingDetails from 'components/ListingDetails';
import LRButton from 'components/LRButton';
import { ActionShareIcon } from 'components/LRIcons';
import { useAgent } from 'hooks/useAgent';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import Logger from 'js-logger';
import { findIndex } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Placeholder } from './Placeholder';

const logger = Logger.get('LRListingDetailsModal');

export { Placeholder };

export function LRListingDetailsModal({
    open = false,
    listings,
    fromRobin = true, // TODO: Need a petter property name that better describes what this is doing in the ListingDetails component
    onClose,
    ...props
}) {
    const theme = useTheme();
    const { listingId } = useParams();
    const { isIPadDown, isIPadProUp } = useDeviceBreakpoints();
    const { data: agent } = useAgent({ priority: 'context' });
    const wrappedListings = useMemo(() => listings?.map((listing) => ({ listing_details: listing })), [listings]);
    const startIndex = useMemo(() => findIndex(listings, { id: listingId }), [listingId, listings]);
    const [shortShareUrl, setShortShareUrl] = useState(null);

    // UseEffects

    useEffect(() => {
        async function shortenShareUrl(shareUrl) {
            const shortenedUrl = await portalApi.shortenURL(shareUrl);
            setShortShareUrl(shortenedUrl.data);
        }

        shortenShareUrl(window.location.href);
    }, []);

    // Render

    return (
        <Dialog
            {...props}
            fullWidth
            open={open}
            PaperProps={{
                style: {
                    maxWidth: 768,
                    maxHeight: '100%',
                    height: '100%',
                    width: '100%',
                    margin: 0,
                    overflowY: 'unset',
                    ...(props.PaperProps?.style || {}),
                },
            }}
            onClose={onClose}
        >
            <AppBar
                position="static"
                color="transparent"
                elevation={0}
                style={{
                    position: 'relative',
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.lr_colors.border_grey,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    zIndex: 30,
                    backgroundColor: '#fff',
                }}
            >
                <Toolbar
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                    }}
                >
                    <Grid item>
                        <Hidden mdUp>
                            <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                                <IconButton edge="start">
                                    <ActionShareIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </Hidden>
                    </Grid>

                    <Grid item>
                        <Hidden smDown>
                            <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                                <LRButton
                                    variant="text"
                                    color="inherit"
                                    underline
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                    disabled={!shortShareUrl}
                                >
                                    <ActionShareIcon
                                        style={{
                                            fontSize: theme.typography.body1.fontSize,
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                    <Typography variant="body2">Share</Typography>
                                </LRButton>
                            </CopyToClipboard>
                        </Hidden>

                        <IconButton
                            edge={isIPadDown ? 'end' : false}
                            style={{
                                position: isIPadProUp && 'absolute',
                                top: isIPadProUp && 8,
                                right: isIPadProUp && -55,
                                color: isIPadProUp && '#fff',
                                zIndex: 1101,
                            }}
                            onClick={onClose}
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Toolbar>
            </AppBar>

            {listings?.length ? (
                <div
                    style={{
                        position: 'relative',
                        height: 'calc(100vh - 64px)',
                    }}
                >
                    <ListingDetails
                        listingsAreNotCards
                        startIndex={startIndex}
                        listings={wrappedListings}
                        buyerAgent={agent}
                        requestSource="my_home"
                        fromRobin={fromRobin}
                        showAgentBanner
                        showAgentLoInfo
                        onClose={onClose}
                    />
                </div>
            ) : (
                <Placeholder fromRobin={fromRobin} />
            )}
        </Dialog>
    );
}
