import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../mobx-store';
import { ListpackCard, CardPlaceholder, MinimizedCardPlaceholder } from './../../ListpackCard';
import ReactPlaceholder from 'react-placeholder';
import { ArchivedHomes } from '../../ArchivedHomes';
import { ListpackAreaSearchInput } from '../../../../components/ListpackAreaSearchInput';
import clsx from 'clsx';
import { Can } from '../../../../components/Ability';

export const ListpackLane =  observer((props)=> {
    const { boardsStore, UserStore } = useStore();
    const { activeBoard } = boardsStore;
    const [showOverflowShadow, setShowOverflowShadow] = useState(false);
    const { user } = UserStore;


    function handleOnScroll(event) {
        const scrollTop = event.target.scrollTop;
        setShowOverflowShadow(scrollTop > 0);
    }

    return (
        <Box overflow="hidden" display="flex" alignItems="stretch" flexDirection="column">
            <Can I="create" a="Listpack">
                <Box p={3} pb={1}
                    boxShadow={showOverflowShadow ? 'rgba(0, 0, 0, 0.1) 0px 10px 20px 0px' : ''}
                    style={{
                        transition: 'box-shadow 0.2s ease-in-out'
                    }}>
                    <ListpackAreaSearchInput location />
                </Box>
            </Can>

            {/* <LanesDescription isSavedSearchLane={true} /> */}

            <Box height={1} p={3} pb={3} pt={1.5} overflow="auto" onScroll={handleOnScroll} >
                {activeBoard && activeBoard.sortedListpacks.all.filter((l)=> l.archived === false)
                    .map((listpack, index)=> (
                        <ReactPlaceholder key={listpack.id} ready={listpack.listpackDataLoaded} customPlaceholder={<CardPlaceholder />}>
                            <Box
                                mt={1.5}
                                key={listpack.id}
                                position="relative"
                                className={
                                    clsx(
                                        'tour-target-my-listpack-card',
                                        `tour-target-opened-listpack-card-created-by-${listpack.isCreatedByAffiliate ? listpack.affiliateId : listpack.userId}`,
                                        {
                                            'tour-target-my-opened-listpack-card': !listpack.isCreatedByAffiliate,
                                            'tour-target-affiliate-opened-listpack-card': listpack.isCreatedByAffiliate
                                        }
                                    )
                                }
                            >
                                <ListpackCard listpack={listpack} index={index} open={index === 0} />
                            </Box>
                        </ReactPlaceholder>
                    ))}

                {activeBoard && !activeBoard.sortedListpacks.handSelected && (
                    <ReactPlaceholder ready={true} customPlaceholder={<Box mt={2}><MinimizedCardPlaceholder /></Box>}>
                        <Box mt={2}>
                            <ListpackCard listpack={{
                                handSelected: true,
                                affiliate: user.affiliate,
                                listpackDataLoaded: true,
                                activeListingsCount: 0
                            }} />
                        </Box>
                    </ReactPlaceholder >
                )}
                <ArchivedHomes />
            </Box>
        </Box>
    );
});
