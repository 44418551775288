import React, { useState, useEffect } from 'react';
import { useResponsiveBreakpoint } from 'hooks';
import { useTheme, IconButton, Typography, Hidden, Grid, AppBar, Toolbar } from '@material-ui/core';
import CopyToClipboard from 'components/CopyToClipboard';
import analytics from 'services/analytics';
import { ActionShareIcon } from 'components/LRIcons';
import { portalApi } from 'apis';
import LRButton from 'components/LRButton';
import { RobinLogo } from 'components/RobinLogo';
import { Link, useLocation } from 'react-router-dom';

export const LoggedOutHeader = function({ listingId }) {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const theme = useTheme();
    const location = useLocation();

    const [shortShareUrl, setShortShareUrl] = useState(null);

    useEffect(()=> {
        async function shortenShareUrl(shareUrl) {
            const shortenedUrl = await portalApi.shortenURL(shareUrl);
            setShortShareUrl(shortenedUrl.data);
        }

        shortenShareUrl(window.location.href);
    }, []);

    return (
        <AppBar
            position="sticky"
            elevation={0}
            color="white"
            style={{
                borderBottom: `1px solid ${theme.palette.lr_colors.border_grey}`,
            }}
        >
            <Toolbar>
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    style={{
                        height: isMobile ? '54px' : '64px',
                    }}
                >
                    <Grid item>
                        <Hidden smDown>
                            {shortShareUrl && <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                        listingId: listingId,
                                        Source: 'logged_out',
                                    })}
                                >
                                    <IconButton
                                        disabled={false}
                                        edge="start"
                                        size="small"
                                    >
                                        <ActionShareIcon style={{fontSize: theme.typography.body1.fontSize}} />
                                    </IconButton>
                                    <span style={{textDecoration: 'underline'}}>Share</span>
                                </div>
                            </CopyToClipboard>}
                        </Hidden>

                        <Hidden mdUp>
                            {shortShareUrl && <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                                <div onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                    listingId: listingId,
                                    Source: 'logged_out',
                                })}>
                                    <IconButton
                                        disabled={false}
                                        edge="start"
                                    >
                                        <ActionShareIcon/>
                                    </IconButton>
                                </div>
                            </CopyToClipboard>}
                        </Hidden >
                    </Grid>

                    <Grid item>
                        <LRButton
                            variant="text"
                            component={Link}
                            inline
                            to={{
                                pathname: '/login',
                                state: { from: location },
                            }}
                            onClick={()=> analytics.eventTrack('rpp_logged_out_logo_click', {listingId: listingId})}
                            style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                        >
                            <RobinLogo variant="full" />
                        </LRButton>
                    </Grid>

                    <Grid item>
                        <LRButton
                            variant="contained"
                            component={Link}
                            color="primary"
                            to={{
                                pathname: '/login',
                                state: { from: location },
                            }}
                            onClick={()=> analytics.eventTrack('rpp_logged_out_log_in_button_click', {listingId: listingId})}
                            fullWidth
                            style={{
                                fontWeight: 'normal',
                                height: isMobile ? '32px' : '36px',
                                padding: '6px 0'
                            }}
                        >
                            <Typography variant="body2">Sign in</Typography>
                        </LRButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );

};
