import { decorate, observable, action, computed, flow } from 'mobx';
import _, { partial, get } from 'lodash';
import { getUserNotifications } from '../../apis/benoit';
import Stream from '../../services/stream';
import Logger from 'js-logger';

const logger = Logger.get('NotificationsStore');
const PAGE_LIMIT = 20;

export class NotificationsStore {
    _notifications = [];
    _agent_actions_notifications = [];
    unseen = 0;
    stream = null;
    subscribedNotifications = false;
    subscribedAgentActionsNotifications = false;
    lastId = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    get notifications() {
        const { isConsumer, isAgent, isLO } = this.rootStore.UserStore;
        return this._notifications
            .slice()
            .filter((notification)=> {
                return isConsumer
                    || (isAgent
                        && _.get(notification, 'activities[0].visible_to_affiliate') === true)
                    || (isLO
                        && _.get(notification, 'activities[0].visible_to_lo') === true);
            })
            .sort((a, b)=> {
                // sort items in descending order
                // by createdAt
                return (a.createdAt < b.createdAt)
                    ? 1
                    : (a.createdAt > b.createdAt)
                        ? -1
                        : 0;
            });
    }

    get agentActionsNotifications() {
        const { isConsumer, isAgent, isLO } = this.rootStore.UserStore;
        return this._agent_actions_notifications
            .slice()
            .filter((notification)=> {
                return isConsumer || isAgent ||
                    (isLO && _.get(notification, 'activities[0].visible_to_lo') === true);
            })
            .sort((a, b)=> {
                // sort items in descending order
                // by createdAt
                return (a.createdAt < b.createdAt)
                    ? 1
                    : (a.createdAt > b.createdAt)
                        ? -1
                        : 0;
            });
    }

    get unreadAgentActionsNotifications() {
        const { isConsumer, isAgent, isLO } = this.rootStore.UserStore;
        return this._agent_actions_notifications
            .slice()
            .filter((notification)=> {
                return !notification.is_read && (
                    isConsumer || isAgent ||
                    (isLO && _.get(notification, 'activities[0].visible_to_lo') === true)
                );
            })
            .sort((a, b)=> {
                // sort items in descending order
                // by createdAt
                return (a.createdAt < b.createdAt)
                    ? 1
                    : (a.createdAt > b.createdAt)
                        ? -1
                        : 0;
            });
    }

    get streamClient() {
        if(this.stream?.client) return this.stream.client;
        const token = _.get(this.rootStore, 'UserStore.user.streamToken', null);
        if(token) {
            this.stream = new Stream({ token });
        }
        return this.stream?.client;
    }

    get streamNotificationFeed() {
        const streamClient = this.streamClient;
        const userId = _.get(this.rootStore, 'UserStore.user.id');
        if(userId && streamClient) {
            return streamClient.feed('notification', userId);
        }
        return null;
    }

    get streamAgentActionsNotificationFeed() {
        const streamClient = this.streamClient;
        const userId = _.get(this.rootStore, 'UserStore.user.id');

        if(userId && streamClient) {
            return streamClient.feed('agent_actions_notification', userId);
        }

        return null;
    }

    // Private Methods

    validNotificationsFilter(notification) {
        const { user } = this.rootStore.UserStore;
        const self = this;
        return (
            !_.get(notification, 'content.suppress_notifications') &&
            !( notification.verb === 'schedule_viewing_request' && (
                _.get(notification, 'actor.id') === user?.id ||
                _.get(self.stream.formatNotification(notification), 'user.id') === user?.id
            ))
        );
    }

    onNewNotifications(response, type = 'notifications') {
        const self = this;
        const logPrefix = `onNewNotifications -> ${type} ->`;
        let resultSet = [];

        if(response?.results) {

            // Handles initial and paginated fetch response

            if(response.results.length) {
                resultSet = response.results;
                if(type === 'notifications') {
                    self.unseen += response.results.filter((n)=> self.validNotificationsFilter(n) && !n.is_seen).length;
                    self.lastId = (response.next?.length)
                        ? _.get(resultSet[resultSet.length - 1], 'id') || null
                        : null;
                }
            } else {
                if(type === 'notifications') self.lastId = null;
            }
        } else if(response?.new) {

            // Handles realtime subscription response

            const { new: newNotifications = [], deleted = [], /* deleted_foreign_ids = [] */ } = response;
            resultSet = newNotifications.filter((n)=> self.validNotificationsFilter(n));
            if(deleted?.length) self.onDeletedNotifications(deleted);
            if(type === 'notifications') self.unseen += resultSet.length;
        }

        const formattedResults = resultSet
            .map((notification)=> self.stream.formatNotification(notification))
            .filter((v)=> v);

        logger.debug(logPrefix, formattedResults);

        formattedResults.forEach((notification)=> {
            self[`_${type}`].push(notification);
        });
    }

    onDeletedNotifications(deleted=[]) {
        const self = this;
        if(deleted?.length) {
            const deletePredicate = (notif)=> (notif?.id && deleted.indexOf(notif.id) > -1);
            _.remove(self._notifications, deletePredicate);
            _.remove(self._agent_actions_notifications, deletePredicate);
        }
    }

    // Public Methods

    fetchStreamNotifications() {
        this._fetchStreamNotification();
        this._fetchStreamAgentActionsNotification();
    }

    fetchMoreStreamNotifications() {
        this._fetchStreamNotification();
    }

    _fetchStreamNotification = flow(function*() {
        const self = this;
        const notificationFeed = this.streamNotificationFeed;

        try {
            const options = {
                limit: PAGE_LIMIT,
                enrich: true,
                id_lt: self.lastId,
            };
            const response = yield notificationFeed.get(options);
            self.onNewNotifications(response, 'notifications');
        } catch (err) {
            logger.error('fetchStreamNotifications -> ', err ? err.stack : err);
        }
    });

    _fetchStreamAgentActionsNotification = flow(function*() {
        const self = this;
        const notificationFeed = this.streamAgentActionsNotificationFeed;

        try {
            const options = {
                limit: PAGE_LIMIT,
                enrich: true,
                id_lt: self.lastId,
            };
            const response = yield notificationFeed.get(options);

            if(!this.rootStore.UserStore.user?.meta?.first_run_onboarding_flow_completed) {
                const notifications = response.results;
                notifications.forEach((notification)=> {
                    this.markAsRead(notification, 'agent_actions_notifications');
                });
            } else {
                self.onNewNotifications(response, 'agent_actions_notifications');
            }
        } catch (err) {
            logger.error('fetchStreamNotifications -> ', err ? err.stack : err);
        }
    });

    markAllSeen = flow(function*() {
        if(!_.get(this.rootStore, 'UserStore.isConsumer')) return;
        this.unseen = 0;
        const notificationFeed = this.streamNotificationFeed;
        try {
            yield notificationFeed.get({mark_seen: true});

            // update front end list to mark notifications
            // added in realtime as seen
            this._notifications.forEach((notification)=> {
                if(notification && !notification.is_seen) {
                    notification.is_seen = true;
                }
            });
        } catch (err) {
            logger.warn('markAllSeen -> ', err ? err.stack : err);
        }
    });

    subscribe() {
        this._subscribeNotification();
        this._subscribeAgentActionsNotification();
    }

    _subscribeNotification() {
        const self = this;

        if(self.subscribedNotifications) return;

        const notificationFeed = this.streamNotificationFeed;
        const _successCallback = ()=> {
            self.subscribedNotifications = true;
            logger.debug('subscribeToNotificationFeed -> listening…');
        };
        const _failCallback = (err)=> {
            self.subscribedNotifications = false;
            logger.error('subscribeToNotificationFeed ->', err);
        };

        // notificationFeed.subscribe(self.onNewNotifications.bind(self))
        notificationFeed.subscribe(partial(self.onNewNotifications.bind(self), _, 'notifications'))
            .then(_successCallback, _failCallback);
    }

    _subscribeAgentActionsNotification() {
        const self = this;

        if(self.subscribedAgentActionsNotifications) return;

        const notificationFeed = this.streamAgentActionsNotificationFeed;
        const _successCallback = ()=> {
            self.subscribedAgentActionsNotifications = true;
            logger.debug('subscribeToAgentActionsNotificationFeed -> listening…');
        };
        const _failCallback = (err)=> {
            self.subscribedAgentActionsNotifications = false;
            logger.error('subscribeToAgentActionsNotificationFeed ->', err);
        };

        // notificationFeed.subscribe(self.onNewNotifications.bind(self))
        notificationFeed.subscribe(
            (response)=> {
                if(!this.rootStore.UserStore.user?.meta?.first_run_onboarding_flow_completed) {
                    const notifications = response.new;
                    notifications.forEach((notification)=> {
                        this.markAsRead(notification, 'agent_actions_notifications');
                    });
                } else {
                    self.onNewNotifications(response, 'agent_actions_notifications');
                }
            }
        )
            .then(_successCallback, _failCallback)
        ;
    }

    /**
     * @param {Object} notification
     * @param {String} [type = 'notifications'] // notifications or agent_actions_notifications
     */
    markAsRead = flow(function * markAsRead(notification, type = 'notifications') {
        if(!notification?.id) return;
        if(!_.get(this.rootStore, 'UserStore.isConsumer')) return;

        const notificationId = notification.id;

        /**
         * Handles both sets of notifications to try and find any that may overlap
         * and mark those as read.
         */
        if(~['notifications', 'agent_actions_notifications'].indexOf(type)) {
            let otherNotificationId;
            // Need fallback because foreign_id can live in 2 different places
            const foreignId = get(notification, 'activities[0].foreign_id', get(notification, 'foreign_id'));

            // Find the other notification id if there is one. We do this by
            // looking for the first match with the activity foreign_id.
            if(foreignId) {
                const otherType = type === 'notifications' ? 'agent_actions_notifications' : 'notifications';
                const otherNotifications = this[`_${otherType}`].reduce((result, noti)=> {
                    if(noti?.type === notification.type && ~[get(noti, 'activities[0].foreign_id'), get(noti, 'foreign_id')].indexOf(foreignId)) {
                        result.push(noti);
                    }

                    return result;
                }, []);
                // We only need the first notifications id that matched
                otherNotificationId = otherNotifications[0]?.id;
            }

            // Standard markAsRead call
            try {
                yield this[type === 'notifications' ? '_markNotificationAsRead' : '_markAgentActionsNotificationAsRead'](notificationId);
            } catch (err) {
                logger.error(`Error marking ${notificationId} as read`, err);
            }

            // If we found any additional notifications ids mark them as read here.
            if(otherNotificationId) {
                try {
                    yield this[type === 'notifications' ? '_markAgentActionsNotificationAsRead' : '_markNotificationAsRead'](otherNotificationId);
                } catch (err) {
                    logger.error(`Error marking ${notificationId} as read`, err);
                }
            }
        }
    });

    _markNotificationAsRead = flow(function * _markNotificationAsRead(notificationId) {
        const self = this;

        if(!notificationId) return;

        // Mark read on the front end
        const foundIndex = _.findIndex(self.notifications, (n)=> (n?.id === notificationId));

        if(foundIndex > -1) {
            const stale = _.get(self, `notifications[${foundIndex}]`);
            const is_read = true;
            const _notifications = self.notifications.slice(); // Make a local copy to splice
            _notifications.splice(foundIndex, 1, { ...stale, is_read });
            self._notifications = _notifications;
        }

        // Mark read on the back end
        const notificationFeed = this.streamNotificationFeed;

        try {
            yield notificationFeed.get({mark_read: [notificationId]});
        } catch (err) {
            logger.warn('markRead -> ', err ? err.stack : err);
        }
    })

    _markAgentActionsNotificationAsRead = flow(function * _markAgentActionsNotificationAsRead(notificationId) {
        const self = this;

        if(!notificationId) return;

        // Mark read on the front end
        const foundIndex = _.findIndex(self.agentActionsNotifications, (n)=> (n?.id === notificationId));

        if(foundIndex > -1) {
            const stale = _.get(self, `agentActionsNotifications[${foundIndex}]`);
            const is_read = true;
            const _notifications = self.agentActionsNotifications.slice(); // Make a local copy to splice
            _notifications.splice(foundIndex, 1, { ...stale, is_read });
            self._agent_actions_notifications = _notifications;
        }

        // Mark read on the back end
        const notificationFeed = this.streamAgentActionsNotificationFeed;

        try {
            yield notificationFeed.get({mark_read: [notificationId]});
        } catch (err) {
            logger.warn('markRead -> ', err ? err.stack : err);
        }
    })

    async fetchNotifications() {
        /* *** DEPRECATED METHOD ***
         * This fetches native BoardUserNotifications using the benoit API
         * which have been dropped in favor of GetStream notifications
         * This is here if stream doesn't work and we need a plan B
        */
        var user = this.rootStore.UserStore.user;
        if(user && user.id) {
            this._notifications = await getUserNotifications(user.id);
        }
    }
}

decorate(NotificationsStore, {
    _notifications: observable,
    _agent_actions_notifications: observable,
    lastId: observable,
    unseen: observable,

    fetchNotifications: action,
    onNewNotifications: action,
    onDeletedNotifications: action,
    readNotification: action,
    markAsRead: action,
    _markNotificationAsRead: action,
    _markAgentActionsNotificationAsRead: action,

    notifications: computed,
    agentActionsNotifications: computed,
    unreadAgentActionsNotifications: computed,
    streamNotificationFeed: computed,
    streamAgentActionsNotificationFeed: computed,
});

export default new NotificationsStore();
