import { Dialog } from '@material-ui/core';
import React from 'react';

export function LRDialog({
    children,
    open = false,
    style = {},
    PaperProps = {},
    onClose = (e)=> {},
    ...props
}) {
    return (
        <Dialog
            open={open}
            style={{
                ...style,
            }}
            PaperProps={{
                ...PaperProps,
                style: {
                    borderRadius: 12,
                    ...(PaperProps?.style || {}),
                }
            }}
            onClose={onClose}
            {...props}
        >
            {children}        
        </Dialog>
    );
}

LRDialog.propTypes = Dialog.propTypes;
