import React from 'react';
import { Grid, Typography, TextareaAutosize, useTheme, Box, Button } from '@material-ui/core';
import { AddAnyHomeListingCard } from '../AddAnyHomeListingCard';
import LRButton from '../../../components/LRButton';
import { observer } from 'mobx-react-lite';
import { useResponsiveBreakpoint } from '../../../hooks';
import _, { partial } from 'lodash';
import { useStore } from '../../../mobx-store';
import simpleCypher from '../../../services/simple-cypher';

function AddAnyHomeListingPreview ({listing_details, openListingPreview, onRemoveClick, ref, listingNotAdded}) {
    const mlsNumber = listing_details?.MlsNumber;
    const detailsId = listing_details?.id;
    const cypheredId = simpleCypher.encode(listing_details.listing_source, listing_details.id);

    const { AddAnyHomeStore } = useStore();
    const { 
        notes,
    } = AddAnyHomeStore;

    const DISABLED = !detailsId;
    const isXs = useResponsiveBreakpoint('xs');

    const theme = useTheme();

    function handleOnChange(event) {
        AddAnyHomeStore.setNotes({...notes, [`listing_note_${detailsId}`]: event.currentTarget.value });
    }

    return (
        <Box display="flex" mb={3} flexDirection={isXs ? 'column' : 'row'}>
            <Box display="flex" flexDirection="column">
                <Box display="flex">
                    <AddAnyHomeListingCard
                        listingDetails={listing_details}
                        style={{
                            width: isXs ? '100%' : '264px',
                        }}
                    />
                </Box>


                <Box display="flex" ml={'-15px'} pb={[2,0]}>
                    {!DISABLED && (
                        <Box display="flex">
                            <LRButton 
                                variant="text" 
                                color={theme.palette.lr_colors.grade}
                                onClick={partial(openListingPreview, listing_details)}
                                underline="always"
                                style={{ backgroundColor: 'transparent' }}
                            >
                                <b>View</b>
                            </LRButton>
                        </Box>
                    )}

                    <Box display="flex">
                        <LRButton 
                            variant="text" 
                            color={theme.palette.lr_colors.grade}
                            onClick={partial(onRemoveClick, _, detailsId)}
                            underline="always"
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <b>Remove</b>
                        </LRButton>
                    </Box>
                </Box>
            </Box>
            
            <Box 
                display={listingNotAdded ? "none" : "flex"} 
                flexDirection="column" 
                pl={[0, '27px']} 
                pb={['45px', 0]}
            >
                <Typography>
                    Add a note
                </Typography>

                <TextareaAutosize
                    name={`listing_note_${mlsNumber}`}
                    rowsMin={5}
                    placeholder=""
                    ref={ref}
                    style={{
                        width: isXs ? '100%' : '311px',
                        border: `1px solid ${theme.palette?.lr_colors?.border_grey}`,
                        borderRadius: '2px',
                        padding: theme.spacing(2),
                        marginTop: theme.spacing(2),
                        resize: 'none'
                    }}
                    onChange={handleOnChange}
                />
            </Box>
        </Box>
    );
}

export default observer(AddAnyHomeListingPreview);