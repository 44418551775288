import React, { useMemo } from 'react';
import lodashSome from 'lodash/some';
import lodashGet from 'lodash/get';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Paper, Typography, useTheme } from '@material-ui/core';
import { Place, PhotoSizeSelectSmall, Bathtub, Create } from '@material-ui/icons';
import { BedIcon } from '../../LRIcons';
import { observer } from 'mobx-react-lite';
import { Skeleton } from '@material-ui/lab';
import GoogleMapReact from 'google-map-react';
import { useResponsive } from 'hooks';
import splitAddress from 'services/split-address';
import formatPhoneNumber from 'services/format-phone-number';
import addCommas from 'services/add-commas';
import { useStore } from 'mobx-store';
import { StatusText } from 'scenes/Board/StatusBadge';
import { useListingRealtor } from 'hooks/data/listing/useListingRealtor';
import { useListingMLS, useListingMLSByZipcode } from 'hooks/data/listing/useListingMLS';
import { COLOR_GREY_DARK } from '../../../constants';

function MapMarker({ children }) {
    return <div>{children}</div>;
}

const BasicInfo = observer(function({ listingName, onEditListingName, listing }) {
    const theme = useTheme();
    const r = useResponsive();
    const history = useHistory();
    const match = useRouteMatch();
    const { MortgageCalculatorStore } = useStore();
    const [addressLine1, addressLine2] = splitAddress(listing?.address);
    const { UserStore } = useStore();
    const { isAgent, isLO } = UserStore;
    const name = listingName || addressLine1;
    const { data: agent } = useListingRealtor(listing);
    const { data: listingMLS, isFetched: isFetchedListingMLS } = useListingMLS(listing);
    const { data: mlses, isFetched: isFetchedMLSes } = useListingMLSByZipcode(listing);

    const showMlsName = useMemo(()=> {
        return lodashGet(listingMLS, '[0].add_mls_name_to_listing_agent_info', false);
    }, [listingMLS]);

    const hasPriceRange = useMemo(()=> listing?.price_min && listing?.price_max, [listing]);

    const listingPrice = useMemo(()=> {
        if(!listing?.price && !hasPriceRange) return null;

        if(hasPriceRange) {
            return `$${addCommas(listing.price_min)} - $${addCommas(listing.price_max)}`;
        }

        return '$' + addCommas(listing.price);
    }, [listing, hasPriceRange]);

    const hideEstPaymentComponent = useMemo(()=> {
        return lodashGet(listingMLS, '[0].hide_est_payment_component', false);
    }, [listingMLS]);

    const hideAgent = useMemo(()=> {
        return lodashSome(mlses, 'hide_listing_agent_from_detail_page');
    }, [mlses]);

    function handleEditListingName(params) {
        if(onEditListingName && !isAgent && !isLO){
            onEditListingName();
        }
    }
    return (
        <Paper elevation={r({ xs: 0, sm: 2 })}>
            <div style={{ padding: r({ xs: `${theme.spacing(2)}px 0`, sm: `${theme.spacing(2)}px ${theme.spacing(3)}px` }) }}>
                {showMlsName && (
                    <div style={{
                        display: 'flex',
                        fontSize: 12,
                        color: COLOR_GREY_DARK,
                        marginBottom: theme.spacing(2),
                    }}>
                        <span
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            {listing && agent && isFetchedMLSes && isFetchedListingMLS ? (
                                <div
                                    style={{
                                        fontSize: 12,
                                        textAlign: 'left',
                                    }}
                                >
                                    {'Listing by: '}
                                    {!hideAgent && (
                                        <>
                                            {agent?.first_name} {agent?.last_name}
                                            {(agent?.first_name || agent?.last_name) && (
                                                <span
                                                    style={{
                                                        paddingLeft: theme.spacing(0.5),
                                                        paddingRight: theme.spacing(0.5),
                                                        color: '#ababab',
                                                    }}
                                                >
                                                    |
                                                </span>
                                            )}
                                        </>
                                    )}
                                    {agent?.company && (
                                        <>
                                            {agent.company}{' and '}
                                        </>
                                    )}
                                    {lodashGet(listingMLS, '[0].market_name')}{' '}
                                    {(agent.phone || agent.email) && (
                                        <span>{`| ${agent.phone ? formatPhoneNumber(agent.phone) : agent.email} `}</span>
                                    )}
                                    {listing.mls_number && (
                                        <>
                                            <span>|</span>
                                            <span style={{whiteSpace: 'nowrap'}}> MLS# {listing.mls_number}</span>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <Skeleton width="250px" />
                            )}
                        </span>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: r({ xs: 'column', sm: 'row' }),
                    }}
                >
                    <div
                        style={{
                            width: r({ xs: '100%', sm: hasPriceRange ? '40%' : '50%' }),
                            marginBottom: r({ xs: theme.spacing(1.5), sm: 0 }),
                        }}
                    >
                        {name ? (
                            <div
                                className="space-x-1"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        borderBottom: onEditListingName ? '1px dashed #dddddd' : '',
                                    }}
                                >
                                    {name}
                                </Typography>
                                {onEditListingName && (
                                    <Create
                                        onClick={handleEditListingName}
                                        fontSize="small"
                                        style={{
                                            color: '#DADADA',
                                            cursor: (!isAgent && !isLO) ? 'pointer' : '',
                                        }}
                                    />
                                )}
                            </div>
                        ) : (
                            <Skeleton />
                        )}
                        {addressLine2 ? (
                            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: theme.spacing(0.5)}}>
                                {listingName && <Typography variant="caption">{addressLine1}</Typography>}
                                {listingName && <Typography variant="caption" style={{color: '#979797', whiteSpace: 'pre'}}>
                                    {' '}•{' '}
                                </Typography>}
                                <Typography variant="caption">{addressLine2}</Typography>
                            </div>
                        ) :
                            <Typography variant="caption"><Skeleton /></Typography>
                        }
                        <div
                            className="space-x-6"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                className="space-x-2"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <BedIcon
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                />
                                <Typography variant="body1">
                                    {listing?.beds ? listing.beds + ' beds' : <Skeleton width="40px" />}
                                </Typography>
                            </div>
                            <div
                                className="space-x-2"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Bathtub
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                />
                                <Typography variant="body1">
                                    {listing?.baths ? listing.baths + ' baths' : <Skeleton width="40px" />}
                                </Typography>
                            </div>
                            <div
                                className="space-x-2"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <PhotoSizeSelectSmall
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                />
                                <Typography variant="body1">
                                    {listing?.home_size ? listing.home_size + ' sqft' : <Skeleton width="40px" />}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: r({ xs: '100%', sm: hasPriceRange ? '60%' : '50%' }),
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="space-y-4">
                            <div>
                                <StatusText listing={listing} />
                                <Typography variant="h5">
                                    {listingPrice || <Skeleton width="50px" />}
                                </Typography>
                            </div>
                            {isFetchedListingMLS && !hideEstPaymentComponent && (
                                <div>
                                    <div>
                                        <Typography variant="caption">Est. Mortgage:</Typography>
                                    </div>

                                    <div>
                                        <a className="no-underline" href={`#listing-${listing?.id}-calculator`}>
                                            <Typography
                                                variant="caption"
                                                paragraph
                                                style={{
                                                    color: '#0EBDC8',
                                                }}
                                            >
                                                ${MortgageCalculatorStore.formattedMonthlyPayment}/mo
                                            </Typography>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                width: '128px',
                                height: '84px',
                            }}
                        >
                            {listing?.lat && listing?.lon ? (
                                <div
                                    style={{width: '100%', height: '100%'}}
                                    onClick={()=> history.push(`${match.url}/gallery?tab=explore`)}
                                >
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: process.env.REACT_APP_GOOGLE_API_KEY,
                                            region: 'us',
                                        }}
                                        defaultZoom={13}
                                        defaultCenter={[33.78724, -117.85496]}
                                        center={[listing.lat, listing.lon]}
                                        options={{
                                            draggable: false,
                                            zoomControl: false,
                                            fullscreenControl: false,
                                            disableDefaultUI: true,
                                            draggableCursor: 'pointer',
                                        }}
                                    >
                                        <MapMarker lat={listing.lat} lng={listing.lon}>
                                            <div
                                                style={{
                                                    width: 0,
                                                    height: 0,
                                                }}
                                            >
                                                <Place
                                                    style={{
                                                        width: '26px',
                                                        height: '56px',
                                                        marginLeft: '-13px',
                                                        marginTop: '-28px',
                                                    }}
                                                />
                                            </div>
                                        </MapMarker>
                                    </GoogleMapReact>
                                </div>
                            ) : (
                                <Skeleton variant="rect" height="100%" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Paper>
    );
});

export default BasicInfo;
