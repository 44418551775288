import React, { useContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import './shepherd-overrides.css';

export const OnboardingContext = React.createContext();

const defaultTourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: false,
        },
    },
    useModalOverlay: true,
};


export class OnboardingStore {
    selectedFlow = null;
    needsToExpandSearch = null;
    listing = null;
    userMovedCard = false;
    tour = null;
    tourEventsMap = observable(new Map());
    bypassRPPView = false;
    preventAction = false;
    options = {};

    get isFirstRunOnboarding() {
        return this.selectedFlow === 'firstRun';
    }

    get isNurtureFirstRunOnboarding() {
        return this.selectedFlow === 'nurtureFirstRun';
    }

    get isCobuyerInviteOnboarding() {
        return this.selectedFlow === 'inviteCobuyer';
    }

    get isCompareOnboarding() {
        return this.selectedFlow === 'compareFlow';
    }

    get isAddNoteOnboarding() {
        return this.selectedFlow === 'addNote';
    }

    get isIdeaBoardOnboarding() {
        return this.selectedFlow === 'pinPhoto';
    }

    get isAsyncFirstRunOnboarding() {
        return this.selectedFlow === 'asyncFirstRun';
    }

    get hasTour() {
        return !!this.selectedFlow;
    }

    get isActive() {
        return this.hasTour;
    }

    get stepId() {
        return this.tour && this.tour.getCurrentStep()?.id;
    }

    get source() {
        return this.options && this.options.source;
    }

    startTour(flowName, options = {}) {
        if(this.tour || this.selectedFlow) {
            this.stop();
        }
        this.tour = new Shepherd.Tour(defaultTourOptions);
        this.selectedFlow = flowName;
        this.options = options;
    }

    addSteps(steps) {
        steps.forEach((step)=> {
            this.tour.addStep(step);
        });
    }

    start() {
        if(!this.tour) {
            return;
        }
        this.tour.start();
    }

    stop() {
        if(this.tour) {
            this.deregisterTourEvents();
            this.tour.cancel();
            this.tour.hide();
        }
        this.tour = null;
        this.selectedFlow = null;
        this.options = {};
    }

    reset() {
        if(this.tour) {
            this.deregisterTourEvents();
            this.tour.hide();
        }
        this.tour = new Shepherd.Tour(defaultTourOptions);
    }

    next() {
        if(!this.tour) {
            return;
        }
        this.tour.next();
    }

    back() {
        if(!this.tour) {
            return;
        }
        this.tour.back();
    }

    show(key, forward= true) {
        if(!this.tour) {
            return;
        }
        this.tour.show(key, forward);
    }

    complete() {
        if(!this.tour) {
            return;
        }
        this.tour.complete();
    }

    matchesStepId(stepId) {
        return this.stepId === stepId;
    }

    registerTourEvent(eventName, eventHandler) {
        if(!this.tour) {
            return;
        }
        if(!this.tourEventsMap.has(eventName)) {
            this.tourEventsMap.set(eventName, observable(new Set()));
        }
        const handlers = this.tourEventsMap.get(eventName);
        handlers.add(eventHandler);
        this.tour.on(eventName, eventHandler);
    }

    unregisterTourEvent(eventName, eventHandler) {
        if(!this.tour) {
            return;
        }
        if(!this.tourEventsMap.has(eventName)) {
            return;
        }
        const handlers = this.tourEventsMap.get(eventName);
        if(handlers.has(eventHandler)) {
            handlers.delete(eventHandler);
            this.tour.off(eventName, eventHandler);
        }
    }

    deregisterTourEvents() {
        if(!this.tour) {
            return;
        }
        for(const [eventName, handlers] of this.tourEventsMap) {
            for(const handler of handlers) {
                this.tour.off(eventName, handler);
            }
        }
        this.tourEventsMap.clear();
    }
}

decorate(OnboardingStore, {
    selectedFlow: observable,
    needsToExpandSearch: observable,
    listing: observable,
    tour: observable,
    userMovedCard: observable,
    bypassRPPView: observable,
    preventAction: observable,

    isFirstRunOnboarding: computed,
    isNurtureFirstRunOnboarding: computed,
    isAsyncFirstRunOnboarding: computed,
    hasTour: computed,
    isActive: computed,
    source: computed,

    startTour: action,
    addSteps: action,
    stop: action,
    registerTourEvent: action,
    unregisterTourEvent: action,
    deregisterTourEvents: action,
});

export function useOnboarding() {
    return useContext(OnboardingContext);
}
