// Inspired by https://github.com/mui-org/material-ui/issues/6140#issuecomment-610313251
// It allows to collocate the responsive configuration with their usage, which is not the case with useResponsiveValue hook.

import { useTheme, useMediaQuery } from '@material-ui/core';
import _ from 'lodash';

/**
   DEPRECATED - Use `useDeviceBreakpoints` instead


 * Example usage:
 * 
 * function ExampleComponent()  {
 *     const r = useResponsive();
 * 
 *     return (
 *         <Grid justify={r({ xs: 'flex-start', md: 'flex-end' })}>
 *         ...
 *         </Grid>
 *     )
 * }
 */
export function useResponsive() {
    const theme = useTheme();

    const matches = {
        xs: useMediaQuery(theme.breakpoints.up('xs')),
        sm: useMediaQuery(theme.breakpoints.up('sm')),
        md: useMediaQuery(theme.breakpoints.up('md')),
        lg: useMediaQuery(theme.breakpoints.up('lg')),
        xl: useMediaQuery(theme.breakpoints.up('xl')),
    };

    return function(responsiveValues) {
        const match = _.findLast(
            theme.breakpoints.keys,
            (breakpoint)=> matches[breakpoint] && responsiveValues[breakpoint] != null
        );

        return match && responsiveValues[match];
    };
}
