import React from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import _ from 'lodash';
import { Divider, Typography, useTheme } from '@material-ui/core';
import LRAvatar from '../../../components/LRAvatar';
import LRButton from '../../../components/LRButton';
import { usePaginatedCollection } from '../utils';
import LRLink from '../../../components/LRLink';
import { useStore } from '../../../mobx-store';
import { useResponsive } from 'hooks';
import analytics from 'services/analytics';
import { useBoardListpacks } from 'hooks/useBoardListpacks';

const SavedSearch = observer(function SavedSearch({ listpack }) {
    const theme = useTheme();
    const { UserStore } = useStore();
    const user = UserStore.user;
    const creator = listpack.affiliateId ? user?.affiliate : listpack.creator;
    return (
        <div
            className="space-x-4"
            style={{
                display: 'flex',
            }}
        >
            <div>
                <LRAvatar user={creator} darken="true" />
            </div>
            <div
                style={{
                    flex: 1,
                }}
            >
                <Typography variant="body1">
                    {creator?.first_name} saved "
                    <LRLink
                        color="black"
                        to={`/overview/listpack/${listpack.id}/listings`}
                        style={{
                            textDecoration: 'underline',
                        }}
                        onClick={()=>
                            analytics.eventTrack('robin_overview_properties_saved_search_click', {
                                search_name: listpack.name,
                                listpack_id: listpack.id,
                            })
                        }
                    >
                        {listpack.name}
                    </LRLink>
                    "
                </Typography>
                <Typography variant="body1">{listpack?.preference_display.join(', ')}</Typography>
                <Typography
                    variant="caption"
                    style={{
                        color: theme.palette.lr_colors.steak_sauce,
                    }}
                >
                    {moment(listpack?.updatedAt)
                        .format('ll')}
                </Typography>
            </div>
        </div>
    );
});

const SavedSearches = observer(function SavedSearches() {
    const theme = useTheme();
    const r = useResponsive();
    const {
        sortedListpacks,
    } = useBoardListpacks();
    const allListpacks = _.chain(sortedListpacks.opened)
        .sortBy('updatedAt')
        .reverse()
        .value();

    const { paginatedCollection: listpacks, loadMore, hasMore } = usePaginatedCollection(allListpacks, {
        initialPageSize: 5,
        newPageSize: 10,
    });

    function handleShowMoreClick() {
        analytics.eventTrack('robin_overview_properties_show_more_click', {
            source: 'saved_searches'
        });
        loadMore();
    }

    return (
        <div>
            <ul
                style={{
                    padding: theme.spacing(2),
                }}
                className="space-y-4"
            >
                {listpacks.length ? (
                    listpacks.map((listpack)=> (
                        <li key={listpack.id}>
                            <SavedSearch listpack={listpack} />
                        </li>
                    ))
                ) : (
                    <Typography
                        variant="body1"
                        style={{
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                            paddingTop: r({ xs: 0, md: theme.spacing(2) }),
                            paddingBottom: r({ xs: 0, md: theme.spacing(2) }),
                        }}
                    >
                        <span role="img" aria-label="emoji">
                            📁
                        </span>{' '}
                        View all the preferences for your buyers’ saved searches in one place.
                    </Typography>
                )}
            </ul>
            {hasMore && (
                <>
                    <Divider />
                    <LRButton
                        onClick={handleShowMoreClick}
                        variant="text"
                        fullWidth
                        style={{
                            padding: theme.spacing(1.5),
                            textDecoration: 'underline',
                        }}
                    >
                        Show more
                    </LRButton>
                </>
            )}
        </div>
    );
});

export default SavedSearches;
