import { useState, useMemo, useCallback } from 'react';

export const useSelection = (initialSelection = [])=> {
    const [selection, setSelection] = useState(initialSelection);

    // Using a set here just to be easier to check if an elements exists and to toggle an element selection.
    const selectionSet = useMemo(()=> new Set(selection), [selection]);

    const addToSelection = useCallback(
        (item)=> {
            selectionSet.add(item);
            setSelection(Array.from(selectionSet.values()));
        },
        [selectionSet]
    );

    const removeFromSelection = useCallback(
        (item)=> {
            selectionSet.delete(item);
            setSelection(Array.from(selectionSet.values()));
        },
        [selectionSet]
    );

    const toggleItemSelection = useCallback(
        (item)=> {
            if(selectionSet.has(item)) {
                removeFromSelection(item);
            } else {
                addToSelection(item);
            }
            setSelection(Array.from(selectionSet.values()));
        },
        [selectionSet, addToSelection, removeFromSelection]
    );

    const isItemSelected = useCallback(
        (item)=> {
            return selectionSet.has(item);
        },
        [selectionSet]
    );

    return { selection, isItemSelected, toggleItemSelection, addToSelection, removeFromSelection };
};
