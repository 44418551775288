import { action, computed, decorate, observable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

export class OpenHouseCalendarStore {
    openHouseFilter = 'all';
    listingsLaneFilter = 'all';
    selectedMobileView = 'list';
    scheduledVisits = {};

    isFirstSelectedDate = false;
    selectedDate = null;

    highlightedOpenHouse = null;
    selectedListingMapMarker = null;
    selectedListingCardId = null;
    lastOperation = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    get isMapVisible() {
        return this.selectedMobileView === 'map';
    }

    get isListVisible() {
        return this.selectedMobileView === 'list';
    }

    toggleMobileView() {
        if(this.isMapVisible) {
            this.selectedMobileView = 'list';
        } else {
            this.selectedMobileView = 'map';
        }
    }

    get selectedLane() {
        let laneId = this.listingsLaneFilter;
        return this.rootStore.LanesStore.lanesById[laneId] || null;
    }
    
    setOpenHouseFilter(filter) {
        this.openHouseFilter = filter;
    }

    setSelectedListingMapMarker(listing,  cardId) {
        this.selectedListingMapMarker = listing;
        this.selectedListingCardId =  cardId;
    }
}

decorate(OpenHouseCalendarStore, {
    openHouseFilter: observable,
    listingsLaneFilter: observable,
    selectedMobileView: observable,
    scheduledVisits: observable,
    selectedDate: observable,
    isFirstSelectedDate: observable,
    highlightedOpenHouse: observable,
    selectedListingMapMarker: observable,
    selectedListingCardId: observable,

    setCurrentFilter: action,

    selectedLane: computed,
    isListVisible: computed,
    isMapVisible: computed,
});
