import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import PanToolIcon from '@material-ui/icons/PanTool';

const Container = styled.div`
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    text-align: center;
    opacity: 1;
    border: 1px dashed #BBBBBB;
    line-height: 1.5;
    color: #A1A1A1;
    width: 100%;
    margin: 0 auto;
    border-radius: 4px
    font-size: 16px;
`;

function DragHomeHereCard(props) {
    const { label } = props;

    return (
        <Container>
            <Grid
                style={{ height: '200px' }}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Box fontSize="body1.fontSize">
                    <PanToolIcon fontSize="large" />
                    <Box mt={2} width={130}>
                        {label}
                    </Box>
                </Box>
            </Grid>
        </Container>
    );
}
DragHomeHereCard.propTypes = {
    label: PropTypes.string.isRequired
};

export default DragHomeHereCard;
