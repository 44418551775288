import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import LRModal from '../../LRModal';
import { useStore } from '../../../mobx-store';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useQuery } from '../../../hooks/use-query';
import { Verification } from '../../../scenes/Login/Verification';
import { RobinLogo } from '../../RobinLogo';

export function VerifyModal({onClose}) {
    const { UserStore } = useStore();
    const query = useQuery();
    const login_token = query.get('login_token');
    const history = useHistory();
    const location = useLocation();
    const isXs = useResponsiveBreakpoint('xs');
    const [closing, setClosing] = useState(false);
    const { from } = location.state || { from: { pathname: '/boards' } };

    function onVerificationSuccessful(user) {
        UserStore.user.account_type = 'verified_robin';
        if(onClose) {
            onClose();
        } else {
            history.replace(from);
        }
    }

    if(closing) {
        return <Redirect to="/boards" />;
    }

    function handleOnClose() {
        if(onClose) {
            onClose();
        } else {
            setClosing(true);
        }
    }


    return (
        <LRModal onClose={handleOnClose}>
            {isXs &&
                <Box height={56} pr={2} borderBottom="1px solid #E0E0E0" display="flex" justifyContent="flex-end" alignItems="center">
                    <CloseRounded onClick={handleOnClose} />
                </Box>
            }
            <Box maxWidth={['none', 360]} mx="auto" display="flex" flexDirection="column" alignItems="center">
                <Box mb={-4} mt={6}>
                    <RobinLogo width={101} />
                </Box>
                <Verification
                    userInfo={UserStore.user}
                    token={{token: login_token}}
                    onVerificationSuccessful={onVerificationSuccessful}
                    headerText="Verify your account"
                    subTitleText="We just sent you a 4-digit verification code via text message. Please enter it below."
                    mode="login"
                />
            </Box>
        </LRModal>
    );
}
