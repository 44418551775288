import { action, decorate, observable } from 'mobx';

export class GlobalSnackbarStore {
    open = false;
    message;
    options;

    show(message, options = { autoHideDuration: 1000 }) {
        this.options = options;
        this.message = message;
        this.open = true;
    }

    hide() {
        this.message = undefined;
        this.open = false;
    }
}

decorate(GlobalSnackbarStore, {
    open: observable,
    message: observable,

    show: action,
    hide: action,
});

export default new GlobalSnackbarStore();
