import { Box, makeStyles, Divider, Grid, List, ListItem, ListItemText, withTheme, useTheme, LinearProgress, Typography } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import LRNavLink from '../../../components/LRNavLink/index';
import { COLOR_GREY_LIGHT } from '../../../constants';
import { useStore } from '../../../mobx-store';
import { VerificationBanner } from '../../../components/VerificationBanner';
import { useResponsiveBreakpoint } from '../../../hooks';
import { LoggedInWrapperContext } from '../../../components/LoggedInWrapper';
import { useOnboarding } from 'components/Onboarding';

const Container = withTheme(styled(Box)`
    position: relative;
    overflow: auto;
    padding: 16px;
    padding-top: 48px;
    height: 100%;
    background-color: ${({ theme })=> theme.palette.lr_colors.swim_lane};
`);
const BMList = styled(List)`
    background: #fff;
    box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 0;
`;
const StyledLRNavLink = styled(LRNavLink)`
    padding-top: 0;
    padding-bottom: 0;
    
    &:hover {
        background: ${COLOR_GREY_LIGHT};
    }
`;

const StyledDivider = withTheme(styled(Divider)`
    background-color: ${({ theme })=> theme.palette.lr_colors.swim_lane};
`);

const useLinearProgressStyles = makeStyles({
    root: {
        backgroundColor: '#EEEEEE',
    },
    barColorPrimary: {
        backgroundColor: '#54D0AA',
    }
});

function BoardMobile({ match }) {
    const { LanesStore, boardsStore, UserStore } = useStore();
    const { activeBoard } = boardsStore;
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const classes = useLinearProgressStyles();
    const { onboardingCompleted } = UserStore;
    const { onOpenLeftSidebar } = useContext(LoggedInWrapperContext);

    if(boardsStore.activeBoard === null) {
        return null;
    }

    return (
        <Box height={1}>
            {isXs && UserStore.isBannerVisible && <VerificationBanner source="board" />}
            <Container>
                {/* <Box mb={2}>
                    <ListpackAreaSearchInput/>
                </Box> */}

                <BMList>
                    <Fragment key='search-lane'>
                        <ListItem
                            button
                            component={StyledLRNavLink}
                            to={`${match.url}/lane/${0}/${_.kebabCase('Your Searches')}`}
                            className="tour-target-your-searches-item"
                        >
                            <Box height="64px" display="flex" alignItems="center" width="100%">
                                <Grid container justify="space-between" alignItems="center">
                                    <ListItemText>
                                        <Box display="flex" fontSize="h6.fontSize">
                                            <Box component="span" ml={1}>Your searches</Box>
                                            {activeBoard.newListings > 0 && (
                                                <Box ml={1.5} color="#f3b204">
                                                    <Box component="span" fontSize="body2.fontSize" fontWeight="bold" mr={1}>
                                                        {activeBoard.newListings} new
                                                    </Box>
                                                    <span role="img" aria-label="party" style={{fontSize: theme.typography.body4.fontSize}}>
                                                        🎉
                                                    </span>
                                                </Box>
                                            )}
                                            <Box component="span" color="lr_colors.grey_dark" ml={1.5}></Box>
                                        </Box>
                                    </ListItemText>

                                    <Box mr={2} display="flex" alignItems="center"><ChevronRight /></Box>
                                </Grid>
                            </Box>
                        </ListItem>
                    </Fragment>
                    {LanesStore.lanes.map((lane, i)=> {
                        const listingsCount = LanesStore.getUnarchivedLanesListings(lane.id).length;
                        return (
                            <Fragment key={lane.id}>
                                <StyledDivider />
                                <ListItem
                                    button
                                    component={StyledLRNavLink}
                                    to={`${match.url}/lane/${lane.id}/${_.kebabCase(lane.name)}`}
                                >
                                    <Box height="64px" display="flex" alignItems="center" width="100%">
                                        <Grid container justify="space-between" alignItems="center">
                                            <ListItemText>
                                                <Box display="flex" fontSize="h6.fontSize">
                                                    <Box component="span" ml={1}>{lane.name}</Box>
                                                    <Box component="span" color="lr_colors.grey_dark" ml={1.5}>{listingsCount}</Box>
                                                </Box>
                                            </ListItemText>

                                            <Box mr={2} display="flex" alignItems="center"><ChevronRight /></Box>
                                        </Grid>
                                    </Box>
                                </ListItem>
                            </Fragment>
                        );
                    })}
                </BMList>

                {
                    !onboardingCompleted && (
                        <Box mt={3}>
                            <BMList>
                                <Fragment key='onboarding'>
                                    <ListItem
                                        button
                                        component={StyledLRNavLink}
                                        onClick={(e)=> {
                                            e.preventDefault();
                                            onOpenLeftSidebar(true);
                                        }}
                                        to={'#'}
                                    >
                                        <Box height="64px" display="flex" alignItems="center" width="100%">
                                            <Grid container justify="space-between" alignItems="center">
                                                <ListItemText>
                                                    <Box display="flex" fontSize="h6.fontSize">
                                                        <Box component="span" ml={1}>Setup Checklist</Box>

                                                        <Box component="span" color="lr_colors.grey_dark" ml={1.5}></Box>
                                                    </Box>
                                                </ListItemText>
                                                <Box mr={2} display="flex" alignItems="center"><ChevronRight /></Box>
                                            </Grid>
                                        </Box>
                                    </ListItem>
                                    <Box px={2} pb={2}>
                                        <div>
                                            <div
                                                style={{
                                                    marginBottom: theme.spacing(1)
                                                }}
                                            >
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={UserStore.onboardingProgress}
                                                    color="primary"
                                                    classes={classes}
                                                />
                                            </div>
                                            <div>
                                                <Typography variant="caption" style={{ color: '#1A1A1A' }}>
                                                    {UserStore.onboardingProgress}% complete - nice work! Let’s check another item off the list.
                                                </Typography>
                                            </div>
                                        </div>
                                    </Box>
                                </Fragment>
                            </BMList>
                        </Box>
                    )
                }
            </Container>
        </Box>
    );
}
BoardMobile.propTypes = {
    match: PropTypes.object.isRequired
};

export default withRouter(observer(BoardMobile));
