import React, { useEffect, useRef } from 'react';
import LRButton from '../LRButton';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';
import Logger from 'js-logger';
import { useLocation } from 'react-router-dom';
import { useGoogleAuth } from 'components/GoogleLogin';
import Lockr from 'lockr';
import { useStore } from 'mobx-store';
import { useIsInAppBrowser } from 'hooks/useIsInAppBrowser';

const logger = Logger.get('GoogleBtn');

const StyledLRButton = styled(LRButton)`
width: 100%;
height: 48px;
font-weight: normal;
border: 1px solid #BBBBBB;
`;

export function GoogleBtn({
    scope = 'profile email',
    onLoginSuccess,
    onError = ()=> { },
    onClick = ()=> { },
    loading,
    signUp = false,
}) {
    const { LoginSignupModalStore } = useStore();
    const isIAB = useIsInAppBrowser();
    const googleBtnRef = useRef();
    const location = useLocation();
    const { state:locationState } = location;
    const authOnLoginSuccessSent = useRef(false);
    const { signIn, isSignedIn, googleUser } = useGoogleAuth();

    // UseEffects

    useEffect(()=> {
        if(!authOnLoginSuccessSent.current && locationState?.auth?.method === 'google' && onLoginSuccess) {
            authOnLoginSuccessSent.current = true; // Used to prevent it from being called more than once

            onLoginSuccess({
                userInfo: locationState.userInfo,
                auth: locationState.auth
            });
        }
    }, [locationState, onLoginSuccess]);

    useEffect(()=> {
        if(isSignedIn && googleUser) {
            const authResponse = googleUser.tokenObj;
            const basicProfile = googleUser.profileObj;
            const userInfo = {
                email: basicProfile.email || undefined,
                first_name: basicProfile.givenName || undefined,
                last_name: basicProfile.familyName || undefined,
                headshot: basicProfile.imageUrl || undefined,
                meta: {
                    auth_providers: {
                        google_user_id: basicProfile.googleId,
                    },
                },
            };
            const auth = { token: authResponse.id_token, method: 'google' };

            if(onLoginSuccess) onLoginSuccess({ userInfo, auth, });
        }
    }, [googleUser, isSignedIn]);

    // Functions

    function onBtnClick() {
        onClick(arguments);
        Lockr.set('thirdparty_auth_redirect_to', window.location.href);
        Lockr.set('thirdparty_auth_method', signUp ? 'signup' : 'login');

        /**
        * We only call this if we are in an In-App Browser (Webview in native app)
        * This allows other parts of the code to save an action for on success.
        */
        if(isIAB) LoginSignupModalStore.beforeOAuthRedirect();

        signIn({
            scope: scope,
            prompt: 'select_account',
        });
    }

    return (
        <StyledLRButton onClick={onBtnClick} ref={googleBtnRef}>
            <Box width={1} display="flex">
                <Box display="flex" alignItems="center" marginLeft={1}>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.68 8.43697C15.68 7.77367 15.6266 7.28957 15.5111 6.7876H8V9.78151H12.4089C12.32 10.5256 11.84 11.646 10.7733 12.399L10.7583 12.4992L13.1332 14.3545L13.2978 14.3711C14.8089 12.9637 15.68 10.893 15.68 8.43697Z" fill="#4285F4" />
                        <path d="M8.00025 16.3246C10.1602 16.3246 11.9735 15.6075 13.298 14.3704L10.7736 12.3983C10.098 12.8734 9.19135 13.205 8.00025 13.205C5.88469 13.205 4.08915 11.7977 3.44912 9.85254L3.3553 9.86057L0.885819 11.7879L0.853516 11.8784C2.16908 14.5138 4.87129 16.3246 8.00025 16.3246Z" fill="#34A853" />
                        <path d="M3.44884 9.8529C3.27994 9.35093 3.1822 8.81308 3.1822 8.25736C3.1822 7.70157 3.27994 7.16378 3.43992 6.66181L3.43542 6.55489L0.935117 4.59668L0.8533 4.63593C0.311085 5.7295 0 6.95758 0 8.25736C0 9.55713 0.311085 10.7851 0.8533 11.8787L3.44884 9.8529Z" fill="#FBBC05" />
                        <path d="M8.00025 3.30887C9.50243 3.30887 10.5158 3.96324 11.0936 4.51009L13.3513 2.28705C11.9647 0.987264 10.1602 0.189453 8.00025 0.189453C4.87129 0.189453 2.16908 2.00015 0.853516 4.63554L3.4402 6.66138C4.08915 4.71623 5.88469 3.30887 8.00025 3.30887Z" fill="#EB4335" />
                    </svg>
                </Box>
                {loading && (
                    <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                        <CircularProgress size={24} color="secondary" />
                    </Box>
                )}
                {!loading && <Box flex="1">{signUp ? 'Sign up' : 'Sign in'} with Google</Box>}
            </Box>
        </StyledLRButton>
    );
}
