import { updateListpack } from 'apis/benoit';
import { LISTPACK_DEFAULT_NOTIFY_FREQUENCY } from '../../../constants';
import Logger from 'js-logger';
import { get, merge, omit, set } from 'lodash';
import moment from 'moment';

const logger = Logger.get('class Listpack');

export class Listpack {
    // Most likely dont need
    // loadingAffiliate = false;
    // loadedAffiliate = false;
    // listings = [];
    // listingsOrder = 'Days on market (default)';
    // loadedListings = false;
    // loadedListingsCount = false;
    // loadingListpackData = true;
    // loadedListingById = false;
    // loadingListingById = false;

    // Loading
    loadingListings = false;
    loadingListingsCount = false;
    listpackDataLoaded = false;

    // Data props
    activeListingsCount = 0;
    newListingsCount = 0;

    constructor(listpackData, UserStore) {
        this.UserStore = UserStore;
        merge(this, listpackData);
    }

    toJSON() {
        return omit(this, ['UserStore']);
    }

    // GETTERS

    get affiliate() {
        return this.UserStore?.user?.affiliate;
    }

    get creator() {
        if(!this.affiliateId) {
            return this.listpack_creator;
        }

        return this.affiliate;
    }

    get handSelected() {
        return this.id === `listpack-${this.userId}-${this.affiliateId}`;
    }

    get isCreatedByAffiliate() {
        return !!this.affiliateId;
    }

    get lastSeenDate() {
        let date;
        const { user } = this.UserStore;

        if(!user) return null;

        let userId = user.id;

        if(this.UserStore.isAgent){
            userId = user.affiliateId;
        } else if(this.UserStore.isLO){
            userId = user.affiliate.preferredVendorId;
        }
        if(this.meta?.last_seen_by_collaborator_at && this.meta.last_seen_by_collaborator_at[userId]) {
            date = new Date(this.meta?.last_seen_by_collaborator_at[userId]);
        } else if(this.meta?.last_seen_at){
            date = new Date(this.meta.last_seen_at);
        }
        return date;
    }

    get newToCollaborator() {
        if(this.handSelected || !this.meta) return false;  

        const { user } = this.UserStore;
        return !this.meta.opened_by_collaborators[user.id];
    }

    get opened() {
        return !this.handSelected ? true : (this.newListingCards > 0 ? true : false);
    }

    // FUNCTIONS

    merge(data) {
        merge(this, data);
    }

    getNotificationSetting(key, channel) {
        return get(
            this,
            `notification_settings.${key}.${channel}.${this.currentUserId}`,
            LISTPACK_DEFAULT_NOTIFY_FREQUENCY
        );
    }

    // ASYNC FUNCTIONS

    async archive() {
        await this.update({
            id: this.id,
            archived: true,
        });
    }

    async setNotificationSetting(key, channel, value) {
        const settingPath = [
            'notification_settings',
            key,
            channel,
            this.currentUserId, // TODO: missing this.currentUserId
        ].join('.');
        set(this, settingPath, value);
        const { notification_settings } = this;

        await this.update({
            id: this.id,
            notification_settings,
        });
    }

    async update(data) {
        try {
            const listpack = await updateListpack(data);
            merge(this, listpack);
        } catch (error){
            logger.error(error);
        }
    }

    async updateLastSeenAt(userId) {
        const now = moment()
            .utc()
            .toISOString();

        await this.update({
            id: this.id,
            meta: {
                ...this.meta,
                last_seen_by_collaborator_at: {
                    ...(this.meta?.last_seen_by_collaborator_at || {}),
                    [userId]: now
                },
                new_listings_count: 0,
            },
        });
    }

    async updateOpenedByCollaborators(userId) {
        const now = moment()
            .utc()
            .toISOString();

        await this.update({
            id: this.id,
            meta: {
                ...this.meta,
                opened_by_collaborators: {
                    ...this.meta.opened_by_collaborators,
                    [userId]: now
                }
            },
        });
    }
}
