import React, { useEffect, useState } from 'react';
import { AgentNotificationBase } from '../AgentNotificationBase';
import { Typography, useTheme } from '@material-ui/core';
import { timeago } from '../../../../../services/timeago';
import { useStore } from '../../../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import notificationConfig from '../../../../../mobx-store/notifications/config';
import analytics from '../../../../../services/analytics';
import LRButton from '../../../../LRButton';

export const AgentNotificationNewSearchCreated = observer(function AgentNotificationNewSearchCreated({
    notification = {},
    onMarkAsReadClick = ()=> {},
    onPrimaryCTAClick = ()=> {},
}) {
    const MAX_MESSAGE_LENGTH = 62;
    const theme = useTheme();
    const { UserStore } = useStore();
    const { user, isAgent, isLO } = UserStore;
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);
    const [messageLength, setMessageLength] = useState(0);

    // UseEffect

    useEffect(()=> {
        setMessageLength(notification.content.message?.length || 0);
    }, [notification.content.message]);

    // Functions

    function onPrimaryButtonClick(e) {
        trackClick('cta_button');
        onPrimaryCTAClick(e);
        if(!(isAgent || isLO)) onMarkAsReadClick(e, notification);

        const conf = notificationConfig[notification.type] || {};
        const toUrl = notification?.content?.path
            ? notification.content.path
            : conf.cta
                ? conf.cta(notification)
                : null;
        if(toUrl) {
            history.push({
                pathname: toUrl,
                state: { trackSource: 'robin_notification_center' },
            });
        }
    }

    function onSecondaryButtonClick(e) {
        trackClick('dismiss');
        if(!(isAgent || isLO)) onMarkAsReadClick(e, notification);
    }

    function trackClick(source) {
        analytics.eventTrack('robin_board_drawer_notification_card_clicked', {
            type: 'saved search created',
            source,
        });
    }

    function onExpandCollapseClick() {
        analytics.eventTrack('robin_board_drawer_notification_card_clicked', {
            source: expanded ? 'expand' : 'collapse',
        });
        setExpanded(!expanded);
    }

    if(!notification.id) return null;

    return (
        <AgentNotificationBase
            caption={
                <Typography variant="caption" color="primary">
                    <b>NEW SEARCH</b>
                </Typography>
            }
            title={`${notification.content.listpack_name} 👉`}
            secondaryButtonLabel="Dismiss"
            primaryButtonLabel="Review now"
            onPrimaryButtonClick={onPrimaryButtonClick}
            onSecondaryButtonClick={onSecondaryButtonClick}
            showSecondaryButton={!(isAgent || isLO)}
        >
            {notification.content.message && (
                <div
                    style={{
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                        “
                        {messageLength > MAX_MESSAGE_LENGTH && !expanded
                            ? `${notification.content.message?.substr(0, MAX_MESSAGE_LENGTH) || ''}...`
                            : notification.content.message}
                        ”
                    </Typography>

                    {messageLength > MAX_MESSAGE_LENGTH && (
                        <div>
                            <LRButton variant="text" color="inherit" inline underline onClick={onExpandCollapseClick}>
                                {expanded ? 'Collapse' : 'Expand'}
                            </LRButton>
                        </div>
                    )}
                </div>
            )}
            <div
                style={{
                    color: theme.palette.lr_colors.steak_sauce,
                }}
            >
                Created by {user.affiliate?.first_name} {timeago(notification.updatedAt)}.
            </div>
        </AgentNotificationBase>
    );
});
