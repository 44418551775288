function addCommas(str, includeDecimal = false) {
    var value = String(str);
    var decimalVal = '';

    if(~value.split('')
        .indexOf('.')) {
        decimalVal = value.substr(value.lastIndexOf('.'));
        value = value.substr(0, value.lastIndexOf('.'));
    }

    value = value.replace(/[^0-9]+/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if(includeDecimal) value += decimalVal;

    return value === '' ? str : value;
}

export default addCommas;
