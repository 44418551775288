import React, { useState, useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { COLOR_GREY_DARK } from '../../../constants';
import { Room } from '@material-ui/icons';
import { withTheme } from '@material-ui/core/styles';
import { listingImage } from '../../../services/listing-helpers';
import { useResponsiveBreakpoint } from '../../../hooks';
import styled from 'styled-components';
import ListingImage from '../../../components/ListingImage';
// import infographicsMap from '../../../mobx-store/infographics-map';

const PlaceCard = withTheme(styled(Box)`
    cursor: pointer;

    ${({ theme, active })=> (active === 'true' ? 'box-shadow: 0 0 6px 1px rgba(0,0,0,0.10)' : '')};
    }

    &:hover {
        ${({ theme, hover })=> 'box-shadow: 0 0 6px 1px rgba(0,0,0,0.10);'}
    }

`);

const StyledScoreGrid = styled(Grid)`
    width: 56px;
    height: 56px;
    background: #1ec8e1;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
`;
const StyledScoreTypography = styled(Typography)`
    font-size: 1.75rem;
    color: #fff;
`;
const StyledOutOfTypography = styled(Typography)`
    font-size: 0.625rem;
    color: #fff;
`;

const MedalHeroImage = withTheme(styled.div`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;

    ${({ theme })=> theme.breakpoints.down('xs')} {
        width: 42px;
        height: 42px;
    }
`);

const getHomeAddress = (formatted_address, heroImage, isXs, homeCard = false)=> {
    const splitAddress = (address)=> {
        return (address || '').replace(/^([\w\s\d#.:]*)(,)/i, '$1|||')
            .split('|||');
    };

    if(!formatted_address) {
        return;
    } else {
        const [addressLine1, addressLine2] = splitAddress(formatted_address);

        return (
            <>
                <Box display="flex" flexDirection="row" alignItems="center">
                    {isXs && (
                        <Box mr={1}>
                            {heroImage && (
                                <MedalHeroImage>
                                    <ListingImage image={heroImage} height="100%" />
                                </MedalHeroImage>
                            )}
                        </Box>
                    )}
                    <Box>
                        <Box fontSize="h6.fontSize" fontWeight="900">
                            {addressLine1}
                        </Box>
                        <Box fontSize="subtitle2.fontSize">{addressLine2}</Box>
                    </Box>
                </Box>
            </>
        );
    }
};

const getAddress = (formatted_address, homeCard = false)=> {
    const splitAddress = (address)=> {
        return (address || '').replace(/^([\w\s\d#.:]*)(,)/i, '$1|||')
            .split('|||');
    };

    if(!formatted_address) {
        return;
    } else {
        const [addressLine1, addressLine2] = splitAddress(formatted_address);

        return (
            <>
                <Box fontSize="subtitle2.fontSize">{addressLine1}</Box>
                <Box fontSize="subtitle2.fontSize">{addressLine2}</Box>
            </>
        );
    }
};

const Sidebar = observer(function Sidebar({ item, listingDetails }) {
    const { InfographicsMapStore, SchoolsStore } = useStore();
    const [selectedPlaceRef, setSelectedPlaceRef] = useState(null);
    const schoolData = SchoolsStore.cardSchoolsData[listingDetails.address];
    const [schoolSummaryRating, setSchoolSummaryRating] = useState(null);
    const [heroImage, setHeroImage] = useState(null);
    const isXs = useResponsiveBreakpoint('xs');

    function handleHover(place) {
        InfographicsMapStore.hoveredPlaceId = place.place_id;
    }

    function cleanupHoverState() {
        InfographicsMapStore.hoveredPlaceId = null;
    }

    const categoriesLocalization = {
        coffee: 'Coffee shops',
        groceries: 'Grocery stores',
        atm: "ATM's",
        gas: 'Gas stations',
        pharmacy: 'Pharmacies',
        hospital: 'Medical',
        cleaners: 'Dry cleaners',
        gym: 'Gyms',
        movie: 'Theaters',
        schools: 'Schools',
    };

    const calcSelectedPlaceRef = (place)=> {
        return (element)=> {
            if(place.place_id === InfographicsMapStore.selectedPlaceId) {
                setSelectedPlaceRef(element);
            }
        };
    };

    useEffect(
        function() {
            if(!schoolData) {
                (async function() {
                    try {
                        await SchoolsStore.getSchoolsData(listingDetails.address);
                    } catch (err) {
                        return;
                    }
                })();
            }

            if(schoolData) {
                var avgRating = 0;
                schoolData.schools.forEach((school)=> {
                    avgRating += school.rating;
                });
                avgRating = avgRating / schoolData.schools.length;
                setSchoolSummaryRating(avgRating >= 7 ? 'above average' : 'average');
            }
        },
        [SchoolsStore, listingDetails.address, schoolData]
    );

    useEffect(()=> {
        const heroImg = listingImage({ listing_details: listingDetails });
        setHeroImage(heroImg);
    }, [listingDetails]);

    useEffect(()=> {
        if(selectedPlaceRef) {
            selectedPlaceRef.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }, [selectedPlaceRef]);

    return (
        <Box>
            {listingDetails && !InfographicsMapStore.selectedCategory && (
                <Box m={2} p={2}>
                    <Box color={COLOR_GREY_DARK}>This home</Box>
                    <Box fontSize="h6.fontSize" mt={1}>
                        {getHomeAddress(listingDetails.address, heroImage, isXs, true)}
                    </Box>
                    <Box mt={2} fontSize="body1.fontSize">
                        {InfographicsMapStore.summaryRating &&
                            `This home is in a ${InfographicsMapStore.summaryRating} area. `}
                        Some daily errands in this location require a car
                        {InfographicsMapStore.summaryRadius &&
                            `,
                            and most major services are within ${InfographicsMapStore.summaryRadius} mile${
                    InfographicsMapStore.summaryRadius !== 1 ? 's' : ''
                }`}
                        .
                        {schoolData &&
                            ` The assigned schools for this home are
                                ${schoolSummaryRating} for this area. There are additionally ${schoolData.total_private_schools ||
                                0} private school${schoolData.total_private_schools !== 1 ? 's' : ''}
                                and ${schoolData.total_charter_schools || 0} charter school${
                    schoolData.total_charter_schools !== 1 ? 's' : ''
                }
                            within 5 miles of this home.`}
                    </Box>
                </Box>
            )}

            {InfographicsMapStore.selectedCategory && (
                <>
                    <Box fontSize="body1.fontSize" mx={2} mt={2} pl={0} pr={2} py={2} color="#1E1E1E">
                        {categoriesLocalization[InfographicsMapStore.selectedCategory]} nearby
                    </Box>
                    <Box mx={2}>
                        <Divider light={true} />
                    </Box>
                </>
            )}

            {InfographicsMapStore.selectedCategory && !InfographicsMapStore.categoryPlaces.length && (
                <Box mt={1} mx={2} px={2}>
                    There are no nearby {categoriesLocalization[InfographicsMapStore.selectedCategory]}
                </Box>
            )}

            {InfographicsMapStore.categoryPlaces &&
                InfographicsMapStore.categoryPlaces.map((place)=> (
                    <PlaceCard
                        ref={calcSelectedPlaceRef(place)}
                        key={place.place_id}
                        active={
                            InfographicsMapStore.selectedPlaceId === place.place_id ||
                            InfographicsMapStore.hoveredPlaceId === place.place_id
                                ? 'true'
                                : 'false'
                        }
                        border={1}
                        m={2}
                        p={2}
                        borderColor="#F0F0F0"
                        borderRadius={6}
                        onMouseLeave={cleanupHoverState}
                        onMouseEnter={()=> {
                            handleHover(place);
                        }}
                        onClick={()=> {
                            handleHover(place);
                        }}
                    >
                        <Box display="flex">
                            <Box flex="1">
                                <Box fontSize="h6.fontSize" lineHeight={1.2} fontWeight="500">
                                    {place.name}
                                </Box>
                                <Box fontSize="subtitle2.fontSize" mt={1 / 2} color={COLOR_GREY_DARK}>
                                    {place.distance} miles
                                    {place.assigned && (
                                        <>
                                            <Box component="span" px={1 / 2}>
                                                &#183;
                                            </Box>
                                            <span>Assigned</span>
                                        </>
                                    )}
                                </Box>
                            </Box>
                            {InfographicsMapStore.selectedCategory === 'schools' && (
                                <Box textAlign="right" pl={1}>
                                    <StyledScoreGrid container direction="row" justify="center" alignItems="center">
                                        <div>
                                            <StyledScoreTypography display="inline">
                                                {place.rating}
                                            </StyledScoreTypography>{' '}
                                            <StyledOutOfTypography display="inline">/10</StyledOutOfTypography>
                                        </div>
                                    </StyledScoreGrid>
                                </Box>
                            )}
                        </Box>
                        {(place.formatted_address || place.vicinity) && (
                            <Box display="flex" alignItems="start" mt={1 / 2}>
                                <Box>
                                    <Room fontSize="small" />
                                </Box>
                                <Box ml={1} fontSize="body2.fontSize" lineHeight="18px">
                                    {getAddress(place.formatted_address || place.vicinity)}
                                </Box>
                            </Box>
                        )}
                    </PlaceCard>
                ))}
        </Box>
    );
});

export default Sidebar;
