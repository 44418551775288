import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../../hooks';
import { TalkingPointsContext } from '..';

export const Header = function () {
    const isXs = useResponsiveBreakpoint('xs');
    const { formattedDate } = useContext(TalkingPointsContext);
    return (
        <>
            <Box>
                <img src="/images/lr-logo-wordmark-light-bg.svg" width="155px" alt="ListReports" />
            </Box>

            <Box pt={2} pb={{ xs: 1, sm: 0 }}>
                <Typography variant={isXs ? 'h5' : 'h3'} style={{fontWeight: 500}}>
                    What&rsquo;s Up with Real Estate?
                </Typography>
            </Box>

            <Typography variant={isXs ? 'body1' : 'h6'} style={{fontWeight: 'normal'}}>
                National news and local views for the week ending <strong>{formattedDate}</strong>
            </Typography>
        </>
    );
};
