import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CircularProgress, Typography, Link as MuLink } from '@material-ui/core';
import _ from 'lodash';
import { useTheme } from '@material-ui/styles';
import LRButton from '../../../../components/LRButton';
import analytics from '../../../../services/analytics';
import { useStore } from '../../../../mobx-store';
import { useArchiveLaneCards } from 'hooks/data/board-card/useArchiveLaneCards';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { Modal, DeleteConfirmationFooter } from './style';

function DeleteConfirmation({isOpen, handleClose, lane, listings}) {
    const theme = useTheme();
    const { mutateAsync: archiveCard } = useArchiveLaneCards();
    const { addBoardCollaboration } = useBoardCollaborations({});
    const [isDeleting, setIsDeleting] = useState(false);
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;

    function handleCancel() {
        handleClose();
    };

    async function handleDelete() {
        setIsDeleting(true);
        await archiveCard({boardId: activeBoard.id, laneId: parseInt(lane.id)});
        for(const boardCard of listings) {
            boardCard.laneId = '-1';
            boardCard.archive();
        };

        const data = {
            model: 'Board',
            boardId: activeBoard.id,
            itemId: activeBoard.id,
            content: {
                laneName: lane.name,
                cta: `/boards/archivedListings`
            },
            type: 'deleted_all_homes_in_lane',
        };
        addBoardCollaboration(data);

        analytics.eventTrack('robin_deleted_all_homes_in_lane', {
            column: _.snakeCase(lane?.name),
        });

        handleClose();
        setIsDeleting(false);
    };

    return (
        <Modal
            className="SaveSearchModal"
            open={isOpen}
            hideCloseButton={true}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <div style={{padding: theme.spacing(3)}}>
                <Typography style={{marginBottom: theme.spacing(2)}} variant="h6">Do you want to delete all homes under "{lane?.name}"?</Typography>
                {(isDeleting)
                    ? (
                        <div style={{width: '100%', display:'flex', justifyContent: 'center', height: '100px', alignItems: 'center'}}>
                            <CircularProgress />
                        </div>
                    )
                    : (
                        <>
                            Any time you delete a home from your board, it’s archived for you in <Link style={{textDecoration: 'underline'}} to="/boards/archivedListings/">Deleted Homes</Link>, under your Saved Searches. You can always recover homes from there if you want them back.
                            <DeleteConfirmationFooter >
                                <MuLink
                                    onClick={handleCancel}
                                    style={{
                                        textDecoration: 'underline',
                                        color: theme.palette.lr_colors.grade,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        height: '36px',
                                        cursor: 'pointer',
                                        marginRight: theme.spacing(3),
                                    }}
                                >
                                    Cancel
                                </MuLink>
                                <LRButton
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    height={36}
                                    onClick={handleDelete}
                                >
                                    Delete
                                </LRButton>
                            </DeleteConfirmationFooter>
                        </>
                    )
                }
            </div>
        </Modal>
    );
}

DeleteConfirmation.propTypes = {
    lane: PropTypes.object.isRequired,
    listings: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default DeleteConfirmation;
