import _ from 'lodash';

//////////////////////////////////////////////////
// DO NOT REORDER LIST OR OR CHANGE THE OFFSET
// BECAUSE DECODING WILL NOT WORK
//////////////////////////////////////////////////
var KEYS = [
    'O','5','-','k','C','R','f','y','w','i',
    'x','I','F','L','6','t','b','3','E','m',
    'n','v','H','G','9','d','j','S','B','Q',
    'X','h','o','K','0','8','A','N','e','W',
    'z','V','g','M','l','p','D','U','2','P',
    'c','s','T','Y','r','q','Z','7','a','u',
    '4','1','J'
];
var KEY_OFFSET = 33;
var LETTER_MAP = KEYS.reduce(function (obj, key, i) {
    obj[key] = String(KEYS[(i + KEY_OFFSET) % KEYS.length]);

    return obj;
}, {});
var REVERSE_LETTER_MAP = _.invert(LETTER_MAP);
var SOURCES_MAP = {
    LR: 'listreports',
    LH: 'listhub',
    WN: 'wolfnet',
    TS: 'trestle',
    SP: 'spark',
    RP: 'rapattoni',
    RS: 'reso',
    BR: 'bridge',
    NV: 'navica',
    PA: 'paragon',
    MG: 'mlsgrid',
    RT: 'rets',
    TR: 'trawler',
    FA: 'firstam',
    TN: 'tns',
    AT: 'attom',
    DL: 'dls'
};
var REVERSE_SOURCES_MAP = _.invert(SOURCES_MAP);


export default {
    encode: function encode(source, str) {
        var prefix = REVERSE_SOURCES_MAP[source];
        var id = str
            .split('')
            .map(function encodeMap(char) {
                return LETTER_MAP[char] || char;
            })
            .join('');

        return prefix + id;
    },

    decode: function decode(str) {
        var source = SOURCES_MAP[str.substr(0, 2)];
        var id = str
            .substr(2)
            .split('')
            .map(function encodeMap(char) {
                return REVERSE_LETTER_MAP[char] || char;
            })
            .join('');

        return {
            id: id,
            source: source
        };
    },

    encodeString: function encodeString(str) {
        return str
            .split('')
            .map(function encodeMap(char) {
                return LETTER_MAP[char] || char;
            })
            .join('');
    },

    decodeString: function decodeString(str) {
        return str
            .split('')
            .map(function encodeMap(char) {
                return REVERSE_LETTER_MAP[char] || char;
            })
            .join('');
    }
};
