import React from 'react';
import { observer } from 'mobx-react-lite';
import { InviteCobuyerFlowDesktop } from './desktop';
import { InviteCobuyerFlowMobile } from './mobile';
import { useResponsiveBreakpoint } from '../../../../hooks';

export const InviteCobuyerFlow = observer(function InviteCobuyerFlow() {
    const isMobile = useResponsiveBreakpoint('xs');

    if(isMobile) {
        return <InviteCobuyerFlowMobile />;
    } else {
        return <InviteCobuyerFlowDesktop />;
    }
});
