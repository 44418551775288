import React from 'react';
import styled from 'styled-components';
import { COLOR_COSTA_MESA } from '../../constants';
import LRButton from '../LRButton';
import { AppStoreIcon, PlayStoreIcon } from 'components/LRIcons';
import analytics from '../../services/analytics';
import { Box, Typography } from '@material-ui/core';
import LRTooltip from 'components/LRTooltip';

const AdBackground = styled.div`
    background-color: ${COLOR_COSTA_MESA};
    padding: 16px 16px;
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    // white-space: pre;
`;

const Emoji = styled.img`
    margin-right: 2px;
    height: 16px;
`;

const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.listreports.robin';
const APP_STORE_URL = 'https://apps.apple.com/us/app/robin-homes/id1535045391';

function StoreButton({ store = 'google', source, icon = true }) {
    const isApple = store === 'apple';
    const handleClick = (event)=> {
        analytics.eventTrack(`robin_login_signup_app_redirect_click`, {
            Source: source === 'signup' ? 'signup_page' : 'login_page',
        });
    };

    return (
        <LRButton
            color="inherit"
            variant="text"
            underline
            target="_blank"
            onClick={handleClick}
            href={isApple ? APP_STORE_URL : PLAY_STORE_URL}
            style={{ padding: 0 }}
        >
            {isApple
                ? (icon ? <AppStoreIcon /> : 'App Store')
                : (icon ? <PlayStoreIcon /> : 'Google Play')
            }
        </LRButton>
    );
}

export function MobileAppAd({source, text, direction = 'column'}) {
    return (
        <AdBackground style={{ display: 'flex', flexDirection: direction, justifyContent: 'space-between' }}>
            <div>
                <Emoji src="/images/star-emoji.png"/>
                <span style={{ fontSize: '16px' }}>
                    {text || 'On the go? Check out our app.'}
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div style={{ width: '140px', height: '60px', marginRight: '-10px' }}>
                    <StoreButton store="apple" source={source} />
                </div>
                <div style={{ width: '155px', height: '60px', marginRight: '0' }}>
                    <StoreButton store="google" source={source} />
                </div>
            </div>
        </AdBackground>
    );
}

export function MobileAppAdTooltip({source, children, disabled, mobile=false}) {

    if(disabled) return children;

    return (
        <LRTooltip
            hideArrow={false}
            disableFocusListener
            disableTouchListener={!mobile}
            disableHoverListener={mobile}
            interactive
            enterTouchDelay={0}
            /*title={
                <>
                    <Box mb={1}>
                        <Typography variant="h6" align="left">
                            Download the Robin™ app
                        </Typography>
                    </Box>
                    <Typography variant="body2" align="left">
                        Want to receive push notifications on your phone? Check out the Robin app on the{' '}
                        <StoreButton store="apple" source={source} icon={false} /> or{' '}
                        <StoreButton store="google" source={source} icon={false} />.
                    </Typography>
                </>
            }*/
        >
            {children}
        </LRTooltip>
    );
};
