import React from 'react';
import { LRToggleButtonGroupOptionStyle } from './style';
import { LRToggleButtonGroup } from '../../../../components/LRToggleButtonGroup';
import { LRDropPopover } from '../../../../components/LRDropPopover';
import { useMemo } from 'react';
import { find } from 'lodash';

const BATHS_OPTIONS = [
    { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
    { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
    { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
    { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
];

export function formatBathTitle(bathrooms) {
    if(bathrooms) {
        const opt = find(BATHS_OPTIONS, { value: bathrooms });
        return `${opt.label} Baths`;
    } else {
        return 'Baths';
    }
}

export function BathsContent({handleBathrooms, bathrooms, onClose, style, trackFilterSet, agentId, handleMixpanelEvent }) {
    return (
        <div style={style}>
            <LRToggleButtonGroup
                fullWidth
                value={bathrooms}
                onChange={(option)=> {
                    handleBathrooms(option);
                    trackFilterSet('bath');
                    handleMixpanelEvent(agentId, 'bath','fields.bathrooms');
                    if(onClose){
                        onClose();
                    }
                }}
                options={BATHS_OPTIONS}
            />
        </div>
    );
}

export function Baths({handleBathrooms, bathrooms, trackFilterSet, trackFilterClick, onClick, agentId, handleMixpanelEvent }) {
    const bathsTitle = useMemo(()=> {
        return formatBathTitle(bathrooms);
    }, [bathrooms]);

    return (
        <LRDropPopover
            title={bathsTitle}
            onOpen={()=> trackFilterClick('bath')}
            onClick={onClick}
            toggled={Boolean(bathrooms)}
        >
            <BathsContent
                bathrooms={bathrooms}
                handleBathrooms={handleBathrooms}
                style={{padding: '16px'}}
                trackFilterSet={trackFilterSet}
                agentid={agentId}
                handleMixpanelEvent={handleMixpanelEvent}
            />
        </LRDropPopover>
    );
}
