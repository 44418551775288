import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { fitBounds } from 'google-map-react/utils';
import { useStore } from '../../../mobx-store';
import { COLOR_SALMON } from '../../../constants';
import { useResponsiveBreakpoint } from '../../../hooks';
import { Home } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withTheme } from '@material-ui/styles';
import analytics from '../../../services/analytics';
import { InfographicsCategoryIcon } from 'components/InfographicsCategoryIcon';

const ArrowDown = styled.div`
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 17px solid transparent;
    border-top: 20px solid ${COLOR_SALMON};
    margin-top: -15px;
    margin-left: -15px;
`;

const StyledInfographicsCategoryIcon = styled.div`
    display: flex;
    & .area-icon-svg {
        width: 20px;
        height: 20px;
        fill: #fff;
    }
`;

const ArrowDownBigger = styled.div`
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 17px solid transparent;
    border-top: 20px solid ${COLOR_SALMON};
    margin-top: -20px;
    margin-left: -8px;
`;

const HomeArrowDown = styled.div`
    width: 0;
    height: 0;
    border-left: 17px solid transparent;
    border-right: 20px solid transparent;
    border-top: 23px solid #000;
    margin-top: -22px;
    margin-left: -19px;
`;

const FilterButton = withTheme(styled(ToggleButton)`
    min-width: 80px;
    height: 40px;
    text-transform: capitalize;

    ${({ theme })=> css`
        color: ${theme.palette.text.primary};
        &.Mui-selected {
            color: ${theme.palette.text.primary};
            background-color: ${theme.palette.grey[300]};
        }
    `}
`);

const Map = ({ listingDetails, selectedCategory })=> {
    // map props
    const [center, setCenter] = useState([listingDetails.lat, listingDetails.lon]);
    const [zoom, setZoom] = useState(16);
    const [maps, setMaps] = useState(null);
    const mapsContainerRef = useRef(null);
    const { InfographicsMapStore } = useStore();
    const isSm = useResponsiveBreakpoint('sm');
    const isXs = useResponsiveBreakpoint('xs');
    const [mapType, setMapType] = useState('roadmap');

    useEffect(()=> {
        const map = ()=> {
            let bounds = new maps.LatLngBounds();

            window.places = InfographicsMapStore.categoryPlaces;

            bounds.extend(new maps.LatLng(listingDetails.lat, listingDetails.lon));

            InfographicsMapStore.categoryPlaces.forEach((p)=> {
                bounds.extend(new maps.LatLng(p.geometry.location.lat(), p.geometry.location.lng()));
            });

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            const nw = { lat: ne.lat(), lng: sw.lng() };
            const se = { lat: sw.lat(), lng: ne.lng() };
            const { center, zoom } = fitBounds(
                {
                    se: { lat: se.lat, lng: se.lng },
                    nw: { lat: nw.lat, lng: nw.lng },
                },
                {
                    width: mapsContainerRef.current.offsetWidth,
                    height: mapsContainerRef.current.offsetHeight,
                }
            );

            setZoom(zoom - 1);
            setCenter([listingDetails.lat, listingDetails.lon]);
        };

        if(InfographicsMapStore.categoryPlaces && InfographicsMapStore.categoryPlaces.length && maps) {
            map();
        }
    }, [InfographicsMapStore.categoryPlaces, listingDetails.lat, listingDetails.lon, maps]);

    function handleOnPlaceClick(place) {
        InfographicsMapStore.selectedPlaceId = place.place_id;
    }

    function handleApiLoaded(map, maps) {
        // setMap(map);
        setMaps(maps);

        InfographicsMapStore.map = map;
        InfographicsMapStore.maps = maps;
    }

    return (
        <Box ref={mapsContainerRef} width={1} height={1}>

            {listingDetails && (
                <>
                    <Box position="absolute" zIndex="1" bottom={16} left={16}>
                        <ToggleButtonGroup value={mapType} onChange={(e)=> {
                            analytics.eventTrack('listpacks_consumer_explore_nearby_map_type_button_click', {
                                source: e.currentTarget.value === 'roadmap' ? 'map' : 'satellite',
                            });
                            setMapType(e.currentTarget.value);}
                        }>
                            <FilterButton value={'roadmap'}>Map</FilterButton>
                            <FilterButton value={'satellite'}>Satellite</FilterButton>
                        </ToggleButtonGroup>
                    </Box>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: 'us' }}
                        zoom={zoom}
                        // defaultZoom={16}
                        defaultCenter={center}
                        center={center}
                        options={{
                            fullscreenControl: false,
                            zoomControl: isSm || isXs ? false : true,
                            mapTypeId: mapType,
                        }}
                        onGoogleApiLoaded={({ map, maps })=> handleApiLoaded(map, maps)}
                    >
                        {InfographicsMapStore.categoryPlaces.map((place)=> {
                            if(
                                InfographicsMapStore.hoveredPlaceId !== place.place_id &&
                                InfographicsMapStore.selectedPlaceId !== place.place_id
                            ) {
                                return (
                                    <Box
                                        onClick={()=> {
                                            handleOnPlaceClick(place);
                                        }}
                                        lat={place.geometry.location.lat()}
                                        lng={place.geometry.location.lng()}
                                        key={place.place_id}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Box marginTop="-37px" marginLeft="-16px">
                                            <Box
                                                boxShadow="0 0 6px 1px rgba(0,0,0,0.3)"
                                                color="#FFF"
                                                height={32}
                                                width={32}
                                                bgcolor={COLOR_SALMON}
                                                borderRadius="50%"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <StyledInfographicsCategoryIcon>
                                                    <InfographicsCategoryIcon
                                                        classes={{root: 'area-icon-svg'}}
                                                        category={InfographicsMapStore.selectedCategory}
                                                    />
                                                </StyledInfographicsCategoryIcon>
                                            </Box>
                                        </Box>
                                        <ArrowDown />
                                    </Box>
                                );
                            } else {
                                return (
                                    <Box
                                        onClick={()=> {
                                            handleOnPlaceClick(place);
                                        }}
                                        lat={place.geometry.location.lat()}
                                        lng={place.geometry.location.lng()}
                                        key={place.place_id}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Box marginTop="-50px" marginLeft="-25px" height={56} width={48}>
                                            <Box
                                                margin
                                                boxShadow="0 0 6px 1px rgba(0,0,0,0.3)"
                                                color="#FFF"
                                                height={48}
                                                width={48}
                                                bgcolor={COLOR_SALMON}
                                                borderRadius="50%"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <StyledInfographicsCategoryIcon>
                                                    <InfographicsCategoryIcon
                                                        classes={{root: 'area-icon-svg' }}
                                                        category={InfographicsMapStore.selectedCategory}
                                                    />
                                                </StyledInfographicsCategoryIcon>
                                            </Box>
                                        </Box>
                                        <ArrowDownBigger />
                                    </Box>
                                );
                            }
                        })}

                        <Box lat={listingDetails.lat} lng={listingDetails.lon}>
                            <Box marginTop="-50px" marginLeft="-25px" height={54} width={48}>
                                <Box
                                    color="#FFF"
                                    height={48}
                                    width={48}
                                    bgcolor="#000"
                                    borderRadius="50%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Home fontSize="large" />
                                </Box>
                            </Box>
                            <HomeArrowDown />
                        </Box>
                    </GoogleMapReact>
                </>
            )}
        </Box>
    );
};

export default observer(Map);
