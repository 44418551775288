import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography, useTheme, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import LRButton from '../LRButton';
import analytics from 'services/analytics';

const paperProps = {
    style: {
        maxWidth: '560px',
        borderRadius: '12px',
    },
};

const Content = withTheme(styled(DialogContent)`
    padding: 20px 24px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        padding-bottom: 16px;
    }
`);

const Actions = withTheme(styled(DialogActions)`
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    ${({ theme })=> theme.breakpoints.up('md')} {
        justify-content: flex-end;
    }
`);

export const AgentLoginModal = function({ open, onClose, ...props}) {
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={onClose} PaperProps={paperProps}>
            <Content>
                <Typography style={{marginTop: theme.spacing(1)}} variant="h6">
                    This login is currently only available for consumers
                </Typography>
                <Typography style={{marginTop: theme.spacing(2)}} variant="body2">
                    Want to manage your Robin leads? Head over to the
                    {' '}<span style={{textDecoration: 'underline'}}>leads page</span>{' '}
                    on your listreports.com dashboard.
                </Typography>
            </Content>
            <Actions>
                <LRButton
                    color="inherit"
                    variant="text"
                    underline
                    onClick={onClose}
                >
                    Cancel
                </LRButton>
                <LRButton
                    color="primary"
                    variant="contained"
                    onClick={()=> {
                        analytics.eventTrack('robin_agent_login_redirect_modal_manage_leads_button_click');
                    }}
                    target="_blank"
                    href={`${process.env.REACT_APP_PORTAL_URL}/my-leads`}
                    style={{marginLeft: theme.spacing(2)}}
                >
                    Manage leads
                </LRButton>
            </Actions>
        </Dialog>
    );
};
