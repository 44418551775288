import React, { useEffect, useMemo, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@material-ui/core';
import { LRDialog } from 'components/LRDialog';
import LRButton from 'components/LRButton';
import { useForm } from 'react-hook-form';
import { LRInputOutlined } from 'components/LRInput';
import { observer } from 'mobx-react-lite';
import { useStore } from 'mobx-store';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useAgent } from 'hooks/useAgent';
import LRTooltip from 'components/LRTooltip';

export const ContactModal = observer(function ContactModal({
    variant, // agent or lo
    open = false,
    disabled= false,
    defaultMessage = '',
    PaperProps = {},
    onSend = async (message)=> {},
    onClose = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const { UserStore } = useStore();
    const { isLO } = UserStore ?? {};
    const { data:agent } = useAgent({ priority: 'context' });
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const {
        register,
        handleSubmit,
        errors,
        reset,
    } = useForm({
        defaultValues: {
            message: defaultMessage,
        }
    });
    const {
        isMobile,
    } = useDeviceBreakpoints();
    const tooltipMessage = useMemo(()=> {
        switch(variant) {
            case 'agent':
                return isLO ?  'their agent' : 'you';
            case 'lo':
                return isLO ?  'you' : 'their lender';
            default:
                return null;
        }
    }, [isLO, variant]);
    const profile = useMemo(()=> {
        switch(variant) {
            case 'agent':
                return agent;
            case 'lo':
                return agent?.preferred_vendor;
            default:
                return {};
        }
    }, [agent, variant]);

    // UseEffects
    useEffect(()=> {
        if(open) {
            reset({
                message: defaultMessage,
            });
        }
    }, [open, reset]);

    // Functions

    async function onSubmit(formData) {
        if(!formData?.message) return;
        try {
            setDisableSaveBtn(true);
            await onSend(formData.message);
        } catch (error) {} finally {
            setDisableSaveBtn(false);
        }

    }

    return (
        <LRDialog
            open={open}
            fullWidth={isMobile}
            onClose={onClose}
            PaperProps={{
                style: {
                    margin: isMobile && theme.spacing(2),
                    width: isMobile && `calc(100% - ${theme.spacing(2)}px)`,
                },
                ...PaperProps,
            }}
            {...props}
        >
            <form name="contactAgentForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle disableTypography style={{ paddingTop: theme.spacing(3) }}>
                    <Typography variant="h6" style={{ fontWeight: 500 }}>
                        Email {profile?.first_name}
                    </Typography>
                </DialogTitle>

                <DialogContent
                    style={{
                        width: !isMobile && 560,
                    }}
                >
                    <LRInputOutlined
                        label="Message"
                        name="message"
                        ref={register({ required: true })}
                        error={Boolean(errors.message)}
                        helperText={Boolean(errors.message) && 'Please enter a message before sending.'}
                        InputProps={{
                            multiline: true,
                            autoFocus: true,
                        }}
                        inputProps={{
                            style: {
                                minHeight: 120,
                            },
                        }}
                    />
                </DialogContent>

                <DialogActions style={{ paddingBottom: theme.spacing(3), paddingRight: theme.spacing(3) }}>
                    <LRButton variant="text" inline underline color="inherit" onClick={onClose} style={{ marginRight: theme.spacing(4) }}>
                        Cancel
                    </LRButton>

                    <LRTooltip hideArrow={false} title={disabled && `If a buyer or co-buyer sends a message, ${tooltipMessage} will receive an email.`} placement="top" aria-label={disabled && `If a buyer or co-buyer sends a message, ${tooltipMessage} will receive an email.`}>
                        <span>
                            <LRButton type="submit" variant="contained" color="primary" disabled={disabled || disableSaveBtn}>
                                Send
                            </LRButton>
                        </span>
                    </LRTooltip>
                </DialogActions>
            </form>
        </LRDialog>
    );
});
