import _ from 'lodash';

function formatAreas(areaIds) {
    if(!areaIds || !areaIds.length) return [];

    return [].concat(areaIds)
        .sort(function(a, b) {
            return isNaN(Number(a))? -1 : 1;
        })
        .map((areaId)=> areaId.split(',')[0])
        .join(', ');
}

export default formatAreas;
