import React from 'react';
import { observer } from 'mobx-react-lite';
import { AddNoteFlowDesktop } from './desktop';
import { AddNoteFlowMobile } from './mobile';
import { useResponsiveBreakpoint } from '../../../../hooks';

export const AddNoteFlow = observer(function InviteCobuyerFlow() {
    const isMobile = useResponsiveBreakpoint('xs');

    if(isMobile) {
        return <AddNoteFlowMobile />;
    } else {
        return <AddNoteFlowDesktop />;
    }
});
