import { Dialog, Hidden } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import DialogHeader from './DialogHeader';
import DialogFooter from './DialogFooter';
import Content from './Content';
import { useStore } from '../../mobx-store';
import _ from 'lodash';
import analytics from '../../services/analytics';
import { useBoardCollaborations } from '../../hooks/useBoardCollaborations';
import addCommas from '../../services/add-commas';

export default ({ onClose })=> {
    const match = useRouteMatch();

    const formMethods = useForm({ mode: 'onChange' });
    const formRef = useRef();
    const [isSubmiting, setSubmiting] = useState(false);
    const [isScheduled, setScheduled] = useState(false);

    const { boardsStore, LanesStore, UserStore } = useStore();
    const agent = UserStore.user?.affiliate;
    const activeBoard = boardsStore.activeBoard;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const cardId = match.params.boardCardId;
    const card = LanesStore.listingsForOffer.find((card)=> card.id === cardId);
    const { boardCollaborations, addBoardCollaboration, isLoading:boardCollaborationsLoading } = useBoardCollaborations(cardId);

    useEffect(()=> {
        setScheduled(_.some(boardCollaborations, ['type', 'offer_submitted']));
    }, [boardCollaborations]);

    useEffect(()=> {
        setSubmiting(boardCollaborationsLoading);
    }, [boardCollaborationsLoading]);

    function submitForm() {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    }
    async function onSchedule(data) {
        setSubmiting(true);
        analytics.eventTrack('robin_offer_flow_notify_click', {
            card_id: card.id,
            address: card?.listing_details?.address,
            comment: data.message,
        });
        const listing_price = `$${addCommas(card?.listing_details?.price || '-')}`;
        await addBoardCollaboration({
            boardId: LanesStore.activeBoard.id,
            itemId: cardId,
            type: 'offer_submitted',
            content: {
                listing_price,
                message: data.message,
            },
        });
        setSubmiting(false);
    }

    return (
        <Dialog fullScreen fullWidth open>
            <FormContext {...formMethods}>
                <DialogHeader
                    onClose={onClose}
                    submitForm={submitForm}
                    isSubmiting={isSubmiting}
                    isScheduled={isScheduled}
                />
                <Content
                    card={card}
                    agent={agent}
                    formRef={formRef}
                    onSchedule={onSchedule}
                    isSubmiting={isSubmiting}
                    isScheduled={isScheduled}
                />
                <Hidden smUp>
                    <DialogFooter
                        onClose={onClose}
                        submitForm={submitForm}
                        isSubmiting={isSubmiting}
                        isScheduled={isScheduled}
                    />
                </Hidden>
            </FormContext>
        </Dialog>
    );
};
