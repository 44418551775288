import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AppBar, Box, CircularProgress, Hidden, IconButton, Toolbar, Typography, withTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LRButton from '../../../components/LRButton';
import { useFormContext } from 'react-hook-form';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import { useStore } from '../../../mobx-store';

const StyledAppBar = withTheme(styled(AppBar)`
    background-color: #fff;
    color: #000;
    position: relative;
    box-shadow: none;
    border-bottom: 1px solid ${({ theme })=> theme.palette.lr_colors.border_grey};
`);

const StyledToolbar = withTheme(styled(Toolbar)`
    display: grid;
    grid-template-columns: 60px 1fr 60px;
`);

const HeaderCenter = withTheme(styled(Box)`
    grid-column: 2 / span 1;
    display: flex;
    justify-content: center;
`);

const HeaderMiddle = withTheme(styled(Box)`
    max-width: 768px;
    display: grid;
    align-items: center;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        grid-template-columns: 220px 1fr 220px;
    }
    ${({ theme })=> theme.breakpoints.up('md')} {
        grid-template-columns: 1fr 1fr 220px;
    }
    flex-grow: 1;
`);

const Title = withTheme(styled(Typography)`
    ${({ theme })=> theme.breakpoints.up('sm')} {
        grid-column: 1 / span 1;
    }
    ${({ theme })=> theme.breakpoints.up('md')} {
        grid-column: 2 / span 1;
    }
`);

const ActionBox = withTheme(styled(Box)`
    grid-column: 3 / span 1;
    display: flex;
    justify-content: space-around;
`);

const HeaderRight = styled(Box)`
    grid-column: 3 / span 1;
`;

function DialogHeader({ onClose, submitForm, isSubmiting, isScheduled }) {
    const { formState } = useFormContext();
    const { UserStore } = useStore();
    const isAffiliate = UserStore.isAgent || UserStore.isLO;
    const isSubmitDisabled = !formState.isValid || isSubmiting || isScheduled || isAffiliate;
    return (
        <StyledAppBar>
            <StyledToolbar>
                <HeaderCenter>
                    <HeaderMiddle>
                        <Title variant="body2" align="center">
                            Prepare an offer
                        </Title>
                        <Hidden xsDown>
                            <ActionBox>
                                {!isScheduled && (
                                    <LRButton variant="outlined" onClick={onClose}>
                                        Cancel
                                    </LRButton>
                                )}
                                <LRButton
                                    color="primary"
                                    variant={isScheduled ? 'outlined' : 'contained'}
                                    onClick={submitForm}
                                    type={'submit'}
                                    disabled={isSubmitDisabled}
                                    style={{ width: 124 }}
                                >
                                    {!isSubmiting &&
                                        (isScheduled ? (
                                            <>
                                                <DoneRoundedIcon /> Notified
                                            </>
                                        ) : (
                                            'Notify'
                                        ))}
                                    {isSubmiting && <CircularProgress color="secondary" size={20} />}
                                </LRButton>
                            </ActionBox>
                        </Hidden>
                    </HeaderMiddle>
                </HeaderCenter>
                <HeaderRight display="flex" justifyContent="center">
                    <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </HeaderRight>
            </StyledToolbar>
        </StyledAppBar>
    );
}

DialogHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default DialogHeader;
