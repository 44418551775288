import React from 'react';
import { AppBar, Button, ButtonGroup, Grid, Hidden, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LRButton from '../../LRButton';
import theme, { MuiPrimaryBlackTheme } from '../../../theme';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import analytics from '../../../services/analytics';
import Logger from 'js-logger';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { LRToggleButtonGroup } from 'components/LRToggleButtonGroup';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ThemeProvider } from 'styled-components';
import LRSelect from 'components/LRSelect';

export function HeaderNavBar({
    boardId,
    position = 'static',
    elevation = 0,
    hideNewButton = false,
    hideBrowseOpenHousesButton = false,
    selectedTab = 'upcoming',
    onClose = (e)=> {},
    onNewClick = (e)=> {},
    onSelectedTabChange = (e, value)=> {},
    style = {},
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
    } = useDeviceBreakpoints();
    const toggleButtons = [
        {
            label: 'Upcoming',
            value: 'upcoming',
        },
        {
            label: 'Past',
            value: 'past',
        },
        {
            label: 'Cancelled',
            value: 'cancelled',
        },
    ];

    function handleOpenHouseClick() {
        analytics.eventTrack('robin_home_tours_browse_open_houses_button_click', {
            Source: 'home_tours_modal'
        });
    }

    return (
        <AppBar 
            color="inherit" 
            position={position}
            className="HeaderNavBar-root" 
            elevation={elevation}  
            style={{
                borderBottom: '1px solid #ddd',
                ...style,
            }}
            {...props}
        >
            <Hidden smUp>
                <Toolbar
                    style={{
                        borderBottom: '1px solid #ddd',
                    }}
                >
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={2} sm={1}></Grid>

                        <Grid item>
                            <Typography variant={isMobile ? 'body2' : 'h6'}>
                                Home Tours
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} style={{ textAlign: 'right' }}>
                            <IconButton edge="end" onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Hidden>
            <Toolbar>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Hidden xsDown>
                            <ThemeProvider theme={MuiPrimaryBlackTheme}>
                                <ToggleButtonGroup value={selectedTab} onChange={onSelectedTabChange} exclusive>
                                    {toggleButtons.map(({ label, value })=> (
                                        <LRButton key={value} component={ToggleButton} value={value} style={{ height: 36 }} className="ToggleButton">
                                            {label}
                                        </LRButton>
                                    ))}
                                </ToggleButtonGroup>
                            </ThemeProvider>
                        </Hidden>

                        <Hidden smUp>
                            <LRSelect
                                fullWidth
                                native
                                variant="filled"
                                value={selectedTab}
                                onChange={(e)=> {
                                    const value = e.currentTarget?.value;
                                    if(value) onSelectedTabChange(e, value);
                                }}
                            >
                                {toggleButtons.map(({ label, value })=> (
                                    <option key={value} value={value} >{label}</option>
                                ))}
                            </LRSelect>
                        </Hidden>
                    </Grid>

                    <Hidden xsDown>
                        <Grid item>
                            {!hideBrowseOpenHousesButton && (
                                <LRButton component={Link} to="/boards/scheduled-tours/calendar" variant="outlined" onClick={handleOpenHouseClick}>
                                    Browse Open Houses
                                </LRButton>
                            )}

                            {!hideNewButton && (
                                <LRButton 
                                    variant="contained" 
                                    color="primary" 
                                    style={{ 
                                        marginLeft: theme.spacing(2) 
                                    }} 
                                    onClick={(e)=> { 
                                        onNewClick(e);
                                        analytics.eventTrack('robin_home_tours_new_button_click', { 
                                            board_id: boardId,
                                            Source: 'home_tour_modal' 
                                        }); 
                                    }}
                                >
                                    New
                                </LRButton>
                            )}
                        </Grid>
                    </Hidden>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export function MobileFooterNavBar({
    boardId,
    elevation = 0,
    hideNewButton = false,
    hideBrowseOpenHousesButton = false,
    onNewClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();

    function handleOpenHouseClick() {
        analytics.eventTrack('robin_home_tours_browse_open_houses_button_click', {
            Source: 'home_tours_modal'
        });
    }

    if(hideNewButton && hideBrowseOpenHousesButton) return null;

    return (
        <>
            <Toolbar />
            <AppBar 
                color="inherit" 
                className="HeaderNavBar-root" 
                elevation={elevation}  
                style={{
                    borderTop: '1px solid #ddd',
                    top: 'auto',
                    bottom: 0,
                }}
                {...props}
            >
                <Toolbar>
                    <Grid container spacing={2} justify="space-between" style={{ margin: 0 }}>
                        {!hideBrowseOpenHousesButton && (
                            <Grid xs={8}>
                                <div>
                                    <LRButton fullWidth component={Link} to="/boards/scheduled-tours/calendar" variant="outlined" onClick={handleOpenHouseClick}>
                                        Browse Open Houses
                                    </LRButton>
                                </div>
                            </Grid>
                        )}

                        <Grid item style={{ width: theme.spacing(2) }} />

                        {!hideNewButton && (
                            <Grid xs>
                                <LRButton 
                                    fullWidth
                                    variant="contained" 
                                    color="primary" 
                                    onClick={(e)=> { 
                                        onNewClick(e);
                                        analytics.eventTrack('robin_home_tours_new_button_click', { 
                                            board_id: boardId,
                                            Source: 'home_tour_modal' 
                                        }); 
                                    }}
                                >
                                    New
                                </LRButton>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
}
