import React, { useRef, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { TextField } from '../TextField';

const StyledTextField = styled(TextField)`
    & input {
        -webkit-appearance: none;
        padding-left: 14px;
    }
`;

export function LRPhoneInput({ rfhMethods, name, label, rules = {}, labelProps, inputProps = {}, onChange }) {
    const phoneRef = useRef();
    const { control, errors } = rfhMethods;

    const tone = inputProps.tone ? inputProps.tone && 'error' : errors[name] && 'error';

    const [messageFn, setMessageFn] = useState(false);

    const message =
        inputProps.message == null
            ? 'Please provide a valid phone number.'
            : inputProps.message === ''
                ? false
                : inputProps.message;

    useEffect(
        function() {
            if(typeof inputProps.message === 'function') {
                setMessageFn(inputProps.message);
            }
        },
        [inputProps.message]
    );

    rules = {
        ...{ minLength: 10, maxLength: 10 },
        ...rules,
    };
    let formLabel = label;
    if(rules.required && formLabel) {
        formLabel = `${formLabel}*`;
    }

    return (
        <Controller
            as={<InputMask ref={phoneRef} />}
            name={name}
            type="tel"
            mask="999-999-9999"
            control={control}
            rules={rules}
            inputProps={inputProps}
            labelProps={labelProps}
            onChange={()=> {
                if(!phoneRef || !phoneRef.current) return;

                var newVal = phoneRef.current.value.replace(/([^0-9])/g, '');
                if(onChange) {
                    onChange(newVal);
                }
                return newVal;
            }}
        >
            {(inputProps)=> (
                <StyledTextField
                    label={formLabel}
                    {...inputProps}
                    message={messageFn ? messageFn : errors[name] && message}
                    tone={tone}
                />
            )}
        </Controller>
    );
}
