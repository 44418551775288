import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, OutlinedInput, Select, Snackbar, withTheme, Dialog } from '@material-ui/core';
import { Place, ViewStreamOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { Route, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { BoardCardModal }  from 'scenes/BoardCardModal';
import { useResponsiveBreakpoint, useQuery } from 'hooks';
import { useStore } from 'mobx-store';
import { Map } from './Map';
import { Navbar } from './Navbar';
import { SchedulePane } from './SchedulePane';
import LRButton from 'components/LRButton';
import { LaneFilter } from './LaneFilter';
import LRSelect from 'components/LRSelect';
import LRDropdown from 'components/LRDropdown';

const MainContentGrid = withTheme(styled(Box)`
    display: grid;
    grid-template-areas: 'filter-select' 'main-section' 'main-section' 'switch-view-footer';
    grid-template-columns: 'auto';
    grid-template-rows: 56px auto auto 65px;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        grid-template-areas:
            'schedule-pane listings-map';
        grid-template-columns: auto 570px;
        grid-template-rows: auto;
    }
`);

const GridItem = styled(Box)`
    ${({ areaName })=> css`
        grid-area: ${areaName};
    `}
`;

function SelectedOption({ label }) {
    return <div style={{ cursor: 'pointer' }}>{label}</div>;
};

function DropdownOption({ label }) {
    return <div style={{ cursor: 'pointer' }}>{label}</div>;
};

function FilterSelect() {
    const { OpenHouseCalendarStore } = useStore();
    
    const options = [
        { value: 'all', label: 'All' },
        { value: 'available', label: 'Available' },
        { value: 'scheduled', label: 'Scheduled' },
        { value: 'past', label: 'Past' }
    ];

    const [selectedOption, setSelectedOption] = useState(options.find((o)=> o.value === OpenHouseCalendarStore.openHouseFilter));

    return (
        <Box px={2} py={1}>
            <LRDropdown 
                fullWidth
                selectedOption={selectedOption}
                onOptionSelect={(option)=> {
                    OpenHouseCalendarStore.setOpenHouseFilter(option.value);
                    setSelectedOption(option);
                }}
                options={options}
                renderDisplayOption={SelectedOption}
                renderOption={DropdownOption}
                DropdownDisplayProps={{ borderRadius: 0, pl: '12px' }}
            />
        </Box>
    );
}

const SwitchViewFooter = observer(()=> {
    const { OpenHouseCalendarStore } = useStore();

    const isListVisible = OpenHouseCalendarStore.isListVisible;

    return (
        <Box
            borderTop={1}
            borderColor="#DDD"
            height={1}
            fontSize="h3.fontSize"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="cursor-pointer"
            px={2}
        >
            {isListVisible && (
                <LRButton variant="outlined" fullWidth={true} onClick={()=> OpenHouseCalendarStore.toggleMobileView()}>
                    <Place style={{ fontSize: '14px' }}/><span style={{ fontSize: '14px', marginLeft: '4px' }}>Map view</span>
                </LRButton> 
            )}
            {!isListVisible && (
                <LRButton variant="outlined" fullWidth={true} onClick={()=> OpenHouseCalendarStore.toggleMobileView()}>
                    <ViewStreamOutlined style={{ fontSize: '14px' }}/><span style={{ fontSize: '14px', marginLeft: '4px' }}>List view</span>
                </LRButton> 
            )}
        </Box>
    );
});

const OpenHouseCalendar = observer(({
    open = false,
    onClose, 
    closeLabel, 
    ...props 
})=> {
    const history = useHistory();
    const match = useRouteMatch();

    const defaultFilter = useQuery()
        .get('defaultFilter');
    const defaultMobileView = useQuery()
        .get('defaultMobileView');

    const { OpenHouseCalendarStore } = useStore();
    const isXs = useResponsiveBreakpoint('xs');
    const isMapVisible = OpenHouseCalendarStore.isMapVisible || !isXs;
    const isListVisible = OpenHouseCalendarStore.isListVisible || !isXs;

    useEffect(()=> {
        if(defaultFilter) {
            OpenHouseCalendarStore.openHouseFilter = defaultFilter;
        }
        if(defaultMobileView && isXs) {
            OpenHouseCalendarStore.selectedMobileView =  defaultMobileView;
        }

        return function () {
            OpenHouseCalendarStore.openHouseFilter = 'all';
            OpenHouseCalendarStore.selectedMobileView  = 'list';
        };
    }, []);

    return (
        <Dialog 
            fullScreen 
            fullWidth
            open={open}
            {...props}
        >
            <Route
                path={`${match?.path}/cards/:cardId`}
                render={()=> <BoardCardModal open={true} onClose={()=> history.push(match.url)}/>}
            />
            <Box height="100vh" display="flex" flexDirection="column">
                <Navbar onClose={onClose} closeLabel={closeLabel} />
                <Box flex="1">
                    <MainContentGrid isListView={isListVisible} isMapView={isMapVisible} height="100%">
                        <GridItem areaName="filter-select" display={{ sm: 'none' }}>
                            <Box display="flex" alignItems="center">
                                <Box flex={1}>
                                    <FilterSelect></FilterSelect>
                                </Box>
                                <Box flex={1}>
                                    <LaneFilter />
                                </Box>
                            </Box>
                        </GridItem>
                        {isListVisible && (
                            <GridItem areaName={isXs ? 'main-section' : 'schedule-pane'} bgcolor={{ xs: 'grey.100', sm: '#FFF' }}>
                                <SchedulePane></SchedulePane>
                            </GridItem>
                        )}
                        {isMapVisible && (
                            <GridItem areaName={isXs ? 'main-section' : 'listings-map'} bgcolor={{ sm: 'grey.100' }}>
                                <Map></Map>
                            </GridItem>
                        )}
                        <GridItem areaName="switch-view-footer" display={{ sm: 'none' }}>
                            <SwitchViewFooter></SwitchViewFooter>
                        </GridItem>
                    </MainContentGrid>
                </Box>
            </Box>
        </Dialog>
    );
});

export default OpenHouseCalendar;
