import React from 'react';
import { Box } from '@material-ui/core';
import ResponsiveHoc from '../../../components/ResponsiveHoc';

const DesktopImage = ()=> <img src="/images/desktop_2x.png" width="123"/>;


const MobileImage = ()=> <img src="/images/mobile_2x.png" width="126" />;

const ResponsiveImage = ResponsiveHoc({xs: MobileImage, md: DesktopImage});

export function EmptyNotesBackground(){
    return (
        <Box textAlign="center">
            <ResponsiveImage />
            <Box color="#A1A1A1" fontSize={16} width="200px" mx="auto" mt="13px" mb="18px">
                Got an idea, question, or comment? Add a note!
            </Box>
            <img src="/images/arrow_2x.png" width="37"/>
        </Box>
    );
}
