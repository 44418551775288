import React, { useState } from 'react';
import LRDropdown from '../LRDropdown';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { COLOR_BORDER_GREY } from '../../constants';
import _ from 'lodash';
import { useMemo } from 'react';

const renderDisplayOption = ({ label })=> {
    return <Box fontSize="16px">{label}</Box>;
};

const renderOption = ({ label })=> {
    return <Box  fontSize="16px" style={{ cursor: 'pointer' }}>{label}</Box>;
};

export function LRRangeDropdown({
    minValue,
    maxValue,
    onMinValueChange,
    onMaxValueChange,
    maxValueOptions,
    minValueOptions,
    options,
    compareAsNumbers,
    dropdownOptionProps={},
    dropdownMenuProps={},
    disabled= false,
    ...props
}) {
    minValueOptions = minValueOptions || options || [];
    maxValueOptions = maxValueOptions || options || [];

    const onMinChange = (option)=> {
        const { value } = option;
        onMinValueChange(value);
    };

    const onMaxChange = (option)=> {
        const { value } = option;
        onMaxValueChange(value);
    };

    const selectedMinOption = useMemo(()=> {
        return minValueOptions.find((option)=> {
            return option.value === minValue;
        });
    }, [minValue, minValueOptions]);

    const selectedMaxOption = useMemo(()=> {
        return maxValueOptions.find((option)=> {
            return option.value === maxValue;
        });
    }, [maxValue, maxValueOptions]);

    const filteredMinOptions = useMemo(()=> {
        return maxValueOptions.filter((option)=> {
            if(maxValue === null || maxValue === undefined) return true;
            if(compareAsNumbers) {
                return option.value === null || parseInt(option.value) < parseInt(maxValue);
            } else {
                return option.value === null || option.value < maxValue;
            }
        });
    }, [compareAsNumbers, maxValue, maxValueOptions]);

    const filteredMaxOptions = useMemo(()=> {
        return minValueOptions.filter((option)=> {
            if(minValue === null || minValue === undefined) return true;
            if(compareAsNumbers) {
                return option.value === null || parseInt(option.value) > parseInt(minValue);
            } else {
                return option.value === null || option.value > minValue;
            }
        });
    }, [compareAsNumbers, minValue, minValueOptions]);

    return (
        <Box display="flex" alignItems="center" width={1}>
            <LRDropdown
                name="minValue"
                maxOptionListHeight="172px"
                disabled={disabled}
                fullWidth
                selectedOption={selectedMinOption}
                options={filteredMinOptions}
                onOptionSelect={onMinChange}
                renderDisplayOption={renderDisplayOption}
                renderOption={renderOption}
                dropdownOptionProps={dropdownOptionProps}
                dropdownMenuProps={dropdownMenuProps}
            />
            {/* <Slash /> */}
            <Box mx={1.5} color="#A1A1A1">
                to
            </Box>
            <LRDropdown
                name="maxValue"
                disabled={disabled}
                maxOptionListHeight="172px"
                fullWidth
                selectedOption={selectedMaxOption}
                options={filteredMaxOptions}
                onOptionSelect={onMaxChange}
                renderDisplayOption={renderDisplayOption}
                renderOption={renderOption}
                dropdownOptionProps={dropdownOptionProps}
                dropdownMenuProps={dropdownMenuProps}
            />
        </Box>
    );
}
