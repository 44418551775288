import AnalyticsProviderConsole from './providers/console';
import AnalyticsProviderMixpanel from './providers/mixpanel';
import BrazeClient from './providers/braze';

class Analytics {
    constructor() {
        this.providers = [
            AnalyticsProviderConsole,
            AnalyticsProviderMixpanel,
            BrazeClient,
        ];
    }

    reset(){
        const FUNC_NAME = 'reset';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider);
            }
        });
    }

    initSession() {
        const FUNC_NAME = 'initSession';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider);
            }
        });
    }

    setUsername(username, isPublicUser=false) {
        const FUNC_NAME = 'setUsername';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, username, isPublicUser);
            }
        });
    }

    setAlias(alias) {
        const FUNC_NAME = 'setAlias';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, alias);
            }
        });
    }

    setSuperPropertiesOnce(properties) {
        const FUNC_NAME = 'setSuperPropertiesOnce';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, properties);
            }
        });
    }

    setSuperProperties(properties) {
        const FUNC_NAME = 'setSuperProperties';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, properties);
            }
        });
    }

    setUserPropertiesOnce(properties) {
        const FUNC_NAME = 'setUserPropertiesOnce';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, properties);
            }
        });
    }

    setUserProperties(properties) {
        const FUNC_NAME = 'setUserProperties';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, properties);
            }
        });
    }

    pageTrack(path) {
        const FUNC_NAME = 'pageTrack';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, path);
            }
        });
    }

    eventTrack(action, properties) {
        const FUNC_NAME = 'eventTrack';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, action, properties);
            }
        });
    }

    incrementProperty(property, value) {
        const FUNC_NAME = 'incrementProperty';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, property, value);
            }
        });
    }

    userTimings(action, properties) {
        const FUNC_NAME = 'userTimings';

        this.providers.forEach(function (provider) {
            if(typeof provider[FUNC_NAME] === 'function') {
                provider[FUNC_NAME].call(provider, action, properties);
            }
        });
    }

    eventTrackOnEvent(action, properties, func) {
        return function trackOnEvent() {
            this.eventTrack(action, properties);
            if(func) func.apply(func, arguments);
        }.bind(this);
    }
}

export default new Analytics();
