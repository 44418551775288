import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * This is needed in order to use it as a component
 * in the NavLink.
 * https://material-ui.com/components/buttons/#third-party-routing-library
 */
const LRNavLink = React.forwardRef((props, ref)=> <NavLink activeClassName="selected" innerRef={ref} {...props} />);

export default LRNavLink;
