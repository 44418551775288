import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import LRButton from '../../../components/LRButton';
import { COLOR_GREY } from '../../../constants';

export default ()=> {
    const [exit, setExit] = useState(false);

    if(exit) {
        return <Redirect to={'/boards'} />;
    }
    return (
        <Container>
            <Box pt={1.5} mx="auto" textAlign="center">
                <CheckCircleOutline style={{ fontSize: '60px' }} />
            </Box>
            <Box mb={2} textAlign="center" fontSize="h4.fontSize">
                Request sent!
            </Box>
            <Box mx="auto" mb={3} width={36} borderBottom={`solid 3px ${COLOR_GREY}`} />
            <Box mb={2} textAlign="center" fontSize="h6.fontSize">
                We’ve sent Alison a text message. They’ll get in touch with you to confirm a schedule.
            </Box>
            <Box textAlign="Center">
                <LRButton onClick={()=> setExit(true)} color="primary" variant="contained">
                    Back to my dashboard
                </LRButton>
            </Box>
        </Container>
    );
};
