import React, { useMemo, useContext, useState, useCallback } from 'react';
import { Typography, useTheme, makeStyles } from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../../hooks';
import { TalkingPointsContext } from '..';
import { COLOR_BORDER_GREY } from '../../../constants';
import { ImageLightbox } from '../ImageLightbox';
import analytics from 'services/analytics';

const useStyles = makeStyles((theme)=> ({
    imageWrap: {
        overflow: 'hidden',
        border: `1px solid ${COLOR_BORDER_GREY}`,
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'all 0.1s ease-in',

        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
}));

const Article = function ({
    headline,
    body,
    image_url,
    onImageClick = ()=> {},
}) {
    const { media, talkingPoints, user } = useContext(TalkingPointsContext);
    const { slug } = talkingPoints || {};
    const isPrint = media === 'print';
    const isMobile = useResponsiveBreakpoint('xs');
    const theme = useTheme();
    const classes = useStyles();
    const handleImageClick = (e)=> {
        onImageClick(e, image_url);
        analytics.eventTrack('shareables_talking_points_landing_page_image_click', {
            distinct_id: user?.id,
            slug,
            image_url,
        });
    };
    return (
        <div style={{
            marginTop: theme.spacing(isMobile ? 4 : 2),
            display: 'flex',
            flexDirection: isMobile || isPrint ? 'column' : 'row',
            justifyContent: isMobile || isPrint ? 'flex-start' : 'space-between',
            gap: theme.spacing(isMobile || isPrint ? 0 : 3),
            flex: 1,
        }}>
            <div>
                <Typography variant={isMobile ? 'h6' : 'h5'}
                    style={{
                        fontWeight: 500,
                        marginBottom: theme.spacing(1),
                    }}
                >{headline}</Typography>

                {isPrint && (<div
                    className={classes.imageWrap}
                    onClick={handleImageClick}
                    style={{
                        minWidth: 285,
                        maxWidth: 285,
                        height: 190,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    <img src={image_url} alt={headline} style={{maxWidth: '100%'}} />
                </div>)}

                <Typography variant="body1">{body}</Typography>
            </div>

            {!isPrint && (<div
                className={classes.imageWrap}
                onClick={handleImageClick}
                style={{
                    marginTop: theme.spacing(isMobile ? 2 : 0),
                    minWidth: 250,
                    maxWidth: 250,
                    height: 167,
                }}
            >
                <img src={image_url} alt={headline} style={{maxWidth: '100%'}} />
            </div>)}
        </div>
    );
};

export const News = function () {
    const [ isImageLightboxOpen, setIsImageLightboxOpen ] = useState(false);
    const [ imageLightboxUrl, setImageLightboxUrl ] = useState(null);
    const isMobile = useResponsiveBreakpoint('xs');
    const theme = useTheme();
    const { talkingPoints, media } = useContext(TalkingPointsContext);
    const articles = useMemo(()=> {
        let collection = [];
        if(talkingPoints) {
            for (const [key, val] of Object.entries(talkingPoints)) {
                if((/(body|headline|image_url)/).test(key)) {
                    const lastUnderscoreIndex = key.lastIndexOf('_');
                    const k = key.substring(0, lastUnderscoreIndex);
                    const i = parseInt(key.substring(lastUnderscoreIndex + 1), 10) - 1; // subtract 1 for 0 index
                    if(!collection[i]) collection[i] = {};
                    Object.assign(collection[i], { [k]: val });
                }
            }
        }
        return collection.filter(Boolean);
    }, [talkingPoints]);
    const onImageClick = useCallback((e, imageUrl)=> {
        if(imageUrl) {
            setImageLightboxUrl(imageUrl);
            setIsImageLightboxOpen(true);
        }
    }, []);
    const onImageLightboxClose = ()=> {
        setIsImageLightboxOpen(false);
        setImageLightboxUrl(null);
    };

    if(!articles.length) return null;

    return (
        <>
            <div style={{
                marginTop: theme.spacing(4)
            }}>
                <Typography variant={isMobile ? 'h5' : 'h4'}
                    style={{fontWeight: 500}}
                >
                    National Real Estate News
                </Typography>

                <div style={
                    media === 'print' ? {
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: theme.spacing(4),
                    } : {}
                }>
                    {articles.map((article)=> <Article key={article?.headline} onImageClick={onImageClick} {...article} />)}
                </div>
            </div>
            <ImageLightbox
                open={isImageLightboxOpen}
                imageUrl={imageLightboxUrl}
                onClose={onImageLightboxClose}
            />
        </>
    );
};
