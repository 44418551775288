import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, useTheme } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { TextField } from '../../../../components/TextField';
import { Room, Search } from '@material-ui/icons';
import { LRChip } from '../../../../components/LRChip';
import { LRAreaAutocomplete } from '../../../../components/LRAreaAutocomplete';


const StyledTextField = withTheme(styled(TextField)`
    background: #fff;
    border: none;
    input {
        font-size: 16px;
    }

    & .MuiOutlinedInput-notchedOutline {
        border-radius: 2px;
        border-color: #dddddd;
    }
    & ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 16px;
    }
    & ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 16px;
    }

    & .MuiOutlinedInput-input {
        padding: 14px;
    }

    & input {
        border: none;
    }
`);

export function AreaSection({setAreas, canEditUser, hasUserPreferences}) {
    const theme = useTheme();
    const areaAutocompleteRef = useRef();
    const formMethods = useFormContext();
    const { fields, append, remove } = useFieldArray({name: 'areaIds'});
    const [prefsSet , handlePrefsSet] = useState(hasUserPreferences);

    function handleAreaSelect(area) {
        formMethods.clearError('areaIds');
        append({ value: area.id });
        areaAutocompleteRef.current.clearSearch();
        if(!prefsSet){
            handlePrefsSet(true);
        }
    }

    function handleRemoveArea(index) {
        if(!canEditUser){
            return;
        }
        remove(index);
        formMethods.triggerValidation();
    }

    useEffect(()=> {
        setAreas(fields);
    }, [fields,]);

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
            case 'zip':
                iconElement = <BusinessIcon fontSize="small" />;
                break;
            case 'neighborhood':
                iconElement = <HomeIcon fontSize="small" />;
                break;
            case 'school':
                iconElement = <SchoolIcon fontSize="small" />;
                break;
            default:
                iconElement = <Room fontSize="small" />;
                break;
        }
        return iconElement;
    }

    function filterSuggestions(suggestion) {
        return !fields.find((area)=> {
            return area.value === suggestion.id;
        });
    }

    return (
        <>
            <Box fontSize={16} mb={1} color="inherit">🔍️  Where do you want to see homes?</Box>
            
            <Box mb={3}>
                <Grid container justify="space-between" spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Box border={`1px solid  ${theme.palette.lr_colors.border_grey}`}>
                            <LRAreaAutocomplete
                                ref={areaAutocompleteRef}
                                onSuggestionSelect={handleAreaSelect}
                                renderInput={(props)=> {
                                    return (
                                        <StyledTextField
                                            variant="outlined"
                                            color="primary"
                                            placeholder="Search By City, Zip, Neighborhood or School"
                                            inputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Box color="#DADADA" display="flex">
                                                            <Search fontSize="small" />
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                                disabled: !canEditUser
                                            }}
                                            size="small"
                                            fullWidth
                                            {...props}
                                        />
                                    );
                                }}
                                suggestionRenderOption={(suggestion)=> {
                                    return (
                                        <Box display="flex" style={{ cursor: 'pointer' }}>
                                            <div>{renderSuggestionIcon(suggestion.type)}</div>
                                            <Box ml={1}>{suggestion.id}</Box>
                                        </Box>
                                    );
                                }}
                                errorTooltipContent={'Sorry, it looks like we can’t find this area.'}
                                rootContainerProps={{ className: 'tour-target-listpack-area' }}
                                filterSuggestions={filterSuggestions}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} container spacing={1}>
                        {fields.map((area, index)=> (
                            <Grid item key={area.id}>
                                <LRChip label={area.value} onDelete={()=> handleRemoveArea(index)} />
                                <input
                                    type="hidden"
                                    defaultValue={area.value}
                                    name={`areaIds[${index}]`}
                                    ref={formMethods.register()}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
