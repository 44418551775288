import React from 'react';
import { useScrollTrigger } from '@material-ui/core';
import PropTypes from 'prop-types';

export function ElevationScroll(props) {
    const { children, target } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}
  
ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    target: PropTypes.object,
};
