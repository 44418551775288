import { useState, useEffect } from 'react';

export const useDebounce = function(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        function() {
            const handler = setTimeout(function() {
                setDebouncedValue(value);
            }, delay);

            return function() {
                clearTimeout(handler);
            };
        },
        [delay, value]
    );

    return debouncedValue;
};
