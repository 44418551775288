import React from 'react';
// import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import Listpack from '../../components/Listpack';

const ListpackModal = ()=> {
    // const params = useParams();
    // let listpackIdParam = params.listpackId;

    return (
        <Box display="flex" justifyContent="center">
            <Listpack></Listpack>
        </Box>
    );
};

export default observer(ListpackModal);