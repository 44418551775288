import { decorate, observable, action, flow, computed } from 'mobx';
import Promise from 'bluebird';
import { benoitApi } from '../../apis';
import _ from 'lodash';
import { DEFAULT_LISTING_HERO_IMAGE } from '../../constants';
import axios from 'axios';
import Logger from 'js-logger';
import { updateListingCache } from 'hooks/data/listing/useListing';

export class Listing {
    hero_image = null;
    photos = null;
    compliance = null;
    enriched = false;

    constructor(listingData, listingsStore) {
        Object.assign(this, listingData);
        this.listingsStore = listingsStore;
    }

    enrichOpenHouse = flow(function*() {
        try {
            const { virtual_tours, open_houses} = yield benoitApi.getListingOpenHouses(this.id);
            this.virtual_tours = virtual_tours;
            this.open_houses = open_houses;
            this.enriched = true;
        } catch (err) {
            Logger.error(`enrichOpenHouse -> ${this.id}`, err);
            this.enriched = true;
        }
    });

    checkImages = flow(function*() {
        if(!_.isArray(this.photos)) return;

        const results = yield Promise.map(this.photos, (photo)=> {
            return new Promise((resolve, reject)=> {
                const img = new Image();

                img.addEventListener('load', ()=> {
                    if(!this.hero_image) this.hero_image = photo;
                    resolve({ photo, success: true });
                });

                img.addEventListener('error', ()=> {
                    resolve({ photo, success: false });
                });

                img.src = photo;
            });
        });

        const goodImages = [];

        results.forEach(function(result) {
            if(result.success) goodImages.push(result.photo);
        });

        this.photos = goodImages;
        if(!this.hero_image) this.hero_image = DEFAULT_LISTING_HERO_IMAGE;

        return this;
    });

    get linkedBoardCards() {
        const boardCards = this.
            listingsStore?.
            rootStore?.
            boardsStore?.
            activeBoard?.
            boardCards || [];
        return boardCards.filter((bc)=> bc.listing_details?.id === this.id && !bc.archived);
    }

    get linkedBoardCard() {
        const linkedBoardCards = this.linkedBoardCards;

        if(linkedBoardCards.length > 0) {
            return linkedBoardCards[0];
        }
    }
}

export class ListingsStore {
    listingsById = {};
    fairHousing = null;
    complianceDataById = {}
    
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    getComplianceById = flow(function*(listingId, user) {
        if(this.complianceDataById[listingId]) return this.complianceDataById[listingId];
        const result = yield benoitApi.getMLSCompliance(listingId ,user);
        this.complianceDataById[listingId] = result;
        return result;
    })

    fetchListingById = flow(function*(listingId, refresh = false) {
        let listingDetails = this.listingsById[listingId];
        if(listingDetails && !refresh) {
            return Promise.resolve(listingDetails);
        }

        listingDetails = yield benoitApi.getListingDetailsById(listingId);
        updateListingCache(listingDetails);
        const listing = new Listing(listingDetails);

        this.listingsById[listingId] = listing;
        return listing;
    });

    fetchListings = flow(function*(listingsIds) {
        const listingsDetails = yield benoitApi.getListingsDetailsByIds(listingsIds);
        const listings = listingsDetails.map((listingDetails)=> {
            updateListingCache(listingDetails);
            return new Listing(listingDetails, this);
        });

        const realtorInfoPromises = listings.reduce(function(acc, listing) {
            var nextStep = acc;
            return nextStep;
        }, []);
        yield Promise.all(realtorInfoPromises);

        listings.forEach((listing)=> {
            this.listingsById[listing.encodedId] = listing;
        });

        const fairHousingResponse = yield axios.get(process.env.REACT_APP_FAIR_HOUSING_CONFIG_URL);
        this.fairHousing = fairHousingResponse.data;
        return listings;
    });

    persistListingData(listingData) {
        if(this.listingsById[listingData.id]) {
            return;
        }
        const newListing = new Listing(listingData, this);
        this.listingsById[newListing.id] = newListing;
    }

    get  listingsCount() {
        return Object.keys(this.listingsById || {}).length;
    }
}

decorate(ListingsStore, {
    listingsById: observable,
    fairHousing: observable,
    complianceDataById: observable,
    fetchListing: action,
    fetchListings: action,
    getComplianceById: action,
    listingsCount: computed,
});

decorate(Listing, {
    hero_image: observable,
    photos: observable,
    enriched: observable,

    linkedBoardCards: computed,
    linkedBoardCard: computed,

    checkImages: action,
    enrichOpenHouse: action,
});
