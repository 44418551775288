import {useState, useEffect} from 'react';

export const useIsScrollPositionWithinOffset = function(offset, container) {
    const [visible, setVisible] = useState(true);

    useEffect(
        function() {
            let scrollContainer = container || document.documentElement;
            let lastScrollTop = scrollContainer.scrollTop;

            const updateHeaderTop = function() {
                const isScrollDown = scrollContainer.scrollTop - lastScrollTop > 0;
                const isScrollUp = scrollContainer.scrollTop - lastScrollTop < 0;

                if(isScrollDown && scrollContainer.scrollTop > offset) {
                    setVisible(false);
                } else if(isScrollUp) {
                    setVisible(true);
                }
                lastScrollTop = scrollContainer.scrollTop;
            };

            const onScrollHandler = function() {
                requestAnimationFrame(updateHeaderTop);
            };
            if(container){
                scrollContainer.addEventListener('scroll', onScrollHandler);
            } else {
                document.addEventListener('scroll', onScrollHandler);
            }
            return function() {
                scrollContainer.removeEventListener('scroll', onScrollHandler);
            };
        },
        [offset, container]
    );

    return visible;
};