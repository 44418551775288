import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../mobx-store';
import { Typography, LinearProgress, makeStyles, useTheme, FormControlLabel, Checkbox } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useOnboarding } from '../../../Onboarding';
import analytics from '../../../../services/analytics';
import { OnboardingVideoModal } from 'components/OnboardingVideoModal';

const useLinearProgressStyles = makeStyles({
    root: {
        backgroundColor: '#EEEEEE',
    },
    barColorPrimary: {
        backgroundColor: '#54D0AA',
    }
});

const useFormControlLabelStyles = makeStyles({
    root: {
        alignItems: 'start',
    },
    label: {
        whiteSpace: 'normal',
        fontSize: '14px',
        color: ({ checked })=> checked ? '#A1A1A1' : '#1A1A1A' ,
        lineHeight: '1.14',
        textDecoration: ({ checked })=> checked ? 'line-through' : '',
    }
});

const useCheckboxStyles = makeStyles({
    root: {
        color: '#DADADA',
        paddingTop: 0,
        paddingBottom: 0,
        '&$checked': {
            color: '#54D0AA',
        },
        '& svg': {
            fontSize: '1rem',
        },
    },
    checked: {},
});

const OnboardingProgress = observer(function() {
    const { UserStore } = useStore();
    const classes = useLinearProgressStyles();
    const theme = useTheme();
    return (
        <div>
            <div
                style={{
                    marginBottom: theme.spacing(1)
                }}
            >
                <LinearProgress
                    variant="determinate"
                    value={UserStore.onboardingProgress}
                    color="primary"
                    classes={classes}
                />
            </div>
            <div>
                <Typography variant="caption" style={{ color: theme.palette.lr_colors.grey_dark }}>
                    {UserStore.onboardingProgress}% complete - nice work!
                </Typography>
            </div>
        </div>
    );
});

const ChecklistItem = observer(function ChecklistItem({ children, checked, onChange, label, disabled }) {
    const theme = useTheme();
    const checkboxClasses = useCheckboxStyles();
    const formControlLabelClasses = useFormControlLabelStyles({ checked });

    return (
        <div style={{
            textAlign: 'left',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.lr_colors.border_grey}`,
            padding: theme.spacing(1.5)
        }}>
            <FormControlLabel
                classes={formControlLabelClasses}
                checked={checked}
                onChange={onChange}
                control={
                    <Checkbox
                        disabled={disabled}
                        classes={checkboxClasses}
                    />
                }
                label={label}
            />
            <div>
                {children}
            </div>
        </div>
    );
});

const Checklist = observer(function Checklist({ onChange }) {
    const { UserStore, boardsStore } = useStore();
    const { user } = UserStore;
    const { activeBoard } = boardsStore;
    const onboarding = useOnboarding();
    const disabled = !activeBoard?.loadedBoardData;
    const [showVideo, setShowVideo] = useState(false);

    // Functions
    const onToggleVideo = useCallback((e, value)=> {
        setShowVideo(value == null ? !showVideo : value);
    }, [showVideo]);

    function onChangeVideo(e) {
        if(!UserStore.watchVideoOnboardingCompleted) {
            UserStore.completeWatchVideoOnboarding();
        }
        analytics.eventTrack('robin_async_onboarding_checklist_clicked', {
            source: 'sizzle_video'
        });

        onToggleVideo(e, true);

        if(onChange) {
            onChange('video');
        }
    };

    return (
        <>
            <div className="space-y-2">
                <ChecklistItem
                    label="Save a search"
                    checked={user?.meta?.async_onboarding?.created_saved_searches}
                    disabled={disabled}
                    onChange={(e)=> {
                        if(!user?.meta?.async_onboarding?.created_saved_searches) {
                            analytics.eventTrack('robin_async_onboarding_checklist_clicked', {
                                source: 'saved_search'
                            });
                            const onboardingFlow = 'firstRun';
                            onboarding.startTour(onboardingFlow, { source: 'save_search' });
                            if(onChange) {
                                onChange();
                            }
                        }
                    }}
                />

                <ChecklistItem
                    label="Add a home to your board from a saved search"
                    checked={user?.meta?.async_onboarding?.moved_board_cards}
                    disabled={disabled}
                    onChange={(e)=> {
                        if(!user?.meta?.async_onboarding?.moved_board_cards) {
                            analytics.eventTrack('robin_async_onboarding_checklist_clicked', {
                                source: 'move_card'
                            });
                            const onboardingFlow = 'firstRun';
                            onboarding.startTour(onboardingFlow, { source: 'move_card' });
                            if(onChange) {
                                onChange();
                            }
                        }
                    }}
                />

                <ChecklistItem
                    label="Watch this 2 minute video and learn about everything Robin has to offer! (optional)"
                    disabled={disabled}
                    checked={UserStore.watchVideoOnboardingCompleted}
                    onChange={onChangeVideo}
                    children={
                        <div style={{display: 'flex', marginTop: '12px', cursor: 'pointer'}} onClick={onChangeVideo}>
                            <div style={{width: '30px'}}></div>
                            <div style={{marginRight: '16px', marginBottom: '-16px'}}>
                                <img width="100%" src="/images/onboarding_video_thumbnail.png" alt="onboarding video" />
                            </div>
                        </div>
                    }
                />

                <ChecklistItem
                    label="Invite a co-buyer to your board (optional)"
                    disabled={disabled}
                    checked={UserStore.inviteCobuyerOnboardingCompleted}
                    onChange={(e)=> {
                        if(!UserStore.inviteCobuyerOnboardingCompleted) {
                            analytics.eventTrack('robin_async_onboarding_checklist_clicked', {
                                source: 'invite_cobuyer'
                            });
                            onboarding.startTour('inviteCobuyer');
                            if(onChange) {
                                onChange();
                            }
                        }
                    }}
                />

                <ChecklistItem
                    label="Add a note to a home on your board"
                    disabled={disabled}
                    checked={UserStore.addNoteOnboardingCompleted}
                    onChange={(e)=> {
                        if(!UserStore.addNoteOnboardingCompleted) {
                            analytics.eventTrack('robin_async_onboarding_checklist_clicked', {
                                source: 'add_note'
                            });
                            onboarding.startTour('addNote');
                            if(onChange) {
                                onChange();
                            }
                        }
                    }}
                />

                <ChecklistItem
                    label="Compare two or more homes on your board"
                    disabled={disabled}
                    checked={UserStore.compareOnboardingCompleted}
                    onChange={(e)=> {
                        if(!UserStore.compareOnboardingCompleted) {
                            analytics.eventTrack('robin_async_onboarding_checklist_clicked', {
                                source: 'compare'
                            });
                            onboarding.startTour('compareFlow');
                            if(onChange) {
                                onChange();
                            }
                        }
                    }}
                />

                <ChecklistItem
                    label="Pin a photo to your Idea board"
                    disabled={disabled}
                    checked={UserStore.pinPhotoOnboardingCompleted}
                    onChange={()=> {
                        if(!UserStore.pinPhotoOnboardingCompleted) {
                            analytics.eventTrack('robin_async_onboarding_checklist_clicked', {
                                source: 'pin_photo'
                            });
                            onboarding.startTour('pinPhoto');
                            if(onChange) {
                                onChange();
                            }
                        }
                    }}
                />
            </div>

            {showVideo && (<OnboardingVideoModal onClose={(e)=> onToggleVideo(e, false)} style={{ zIndex: 1301 }} />)}
        </>
    );
});

export const OnboardingChecklist = observer(function OnboardingChecklist({ onChange }) {
    const theme = useTheme();

    return (
        <div>
            <div
                style={{
                    marginBottom: theme.spacing(2)
                }}
            >
                <Typography variant="h6">
                    Setup Checklist <span role="img" aria-label="checklist">📝</span>
                </Typography>
            </div>
            <div
                style={{
                    marginBottom: theme.spacing(2)
                }}
            >
                <OnboardingProgress />
            </div>
            <div>
                <Checklist onChange={onChange} />
            </div>
        </div>
    );
});
