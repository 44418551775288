// eslint-disable-next-line
import Logger from 'js-logger';
import { connect } from 'getstream';
import lodashGet from 'lodash/get';

const API_KEY = process.env.REACT_APP_STREAM_API_KEY;
const ROBIN_APP_ID = process.env.REACT_APP_STREAM_ROBIN_APP_ID;

const streamTimeToISO = (timeStr='')=> {
    // Stream returns time strings in the following format
    // 2020-08-18T23:27:26.373066
    // the timezone offset is missing so
    // we convert it to an ISOString
    return timeStr.replace(/\d{3}$/, 'Z');
};

class Stream {
    constructor({ token }) {
        // A stream token is generated server-side and returned
        // in the GET User call for use on the front end
        this.client = connect(API_KEY, token, ROBIN_APP_ID);
    }

    // Private Functions

    // Public Functions
    formatNotification(notification={}) {
        const activity = lodashGet(notification, 'activities[0]') || notification;
        const actor = lodashGet(activity, 'actor') || {};
        const content = lodashGet(activity, 'content') || {};
        const [ objectModel, ...objectId ] = (lodashGet(activity, 'object') || '').split(':');
        const { id, data: userData } = actor;
        const user = { id, ...userData };
        const {
            created_at,
            updated_at,
            time,
            ...restProps
        } = notification;
        const formatted = {
            user,
            content,
            objectModel,
            objectId: (objectId || '').join(':'),
            type: notification.verb,
            createdAt: streamTimeToISO(created_at || time),
            updatedAt: streamTimeToISO(updated_at || time),
            ...restProps
        };
        return formatted;
    }
}

export default Stream;
