import { DialogActions, DialogContent, Typography, useTheme } from '@material-ui/core';
import LRButton from 'components/LRButton';
import { LRCheckbox } from 'components/LRCheckbox';
import { LRDialog } from 'components/LRDialog';
import Checked from 'components/LRIcons/checkbox-more-filters-icons/checked';
import Unchecked from 'components/LRIcons/checkbox-more-filters-icons/unchecked';
import React from 'react';

export function DeleteNeighborhoodModal({
    isOpen = true,
    onClose = (e)=> {},
    onChange = (e)=> {},
    onDontAskToDelete= (e)=> {},
    deleteArea = (e)=> {},
    dontAskToDelete,
    area,
    ...props
}) {
    const theme = useTheme();

    return (
        <LRDialog
            {...props}
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '370px',
                },
            }}
        >
            <Typography variant="h6" style={{ fontWeight: 500, padding: theme.spacing(3), paddingBottom: theme.spacing(2) }}>
                Are you sure you want to remove this area?
            </Typography>

            <DialogContent>
                <LRCheckbox
                    checked={dontAskToDelete || false}
                    onChange={(e)=> {
                        onDontAskToDelete(e.target.checked || false);
                    }}
                    label={<Typography variant="body1">Don’t ask me again</Typography>}
                    checkedIcon={<Checked/>}
                    icon={<Unchecked/>}
                    style={{display: 'flex'}}
                />
            </DialogContent>

            <DialogActions style={{ padding: theme.spacing(3), paddingTop: theme.spacing(2), justifyContent: 'space-between' }}>
                <LRButton variant="text" color="inherit" underline inline onClick={onClose} disabled={false}>
                    No thanks
                </LRButton>

                <LRButton variant="contained" color="primary" onClick={()=> deleteArea(area)} disabled={false}>
                    Yes, remove
                </LRButton>
            </DialogActions>
        </LRDialog>
    );
}



