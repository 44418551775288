import React from 'react';
import { Badge, StatusBadge } from 'scenes/Board/StatusBadge';

const ListingBadges = function ListingBadges({ listing, card, listpack }) {
    if(!listing) {
        return null;
    }
    
    if(listpack && listpack.listings_type === 'static') {
        return <Badge status={listing.listing_status} />;
    } else {
        return (
            <StatusBadge
                isRpp={true}
                laneIdBadge={card?.columnId}
                listingDetails={listing}
                isListpackCard={!!listpack}
            />
        );
    }
};

export default ListingBadges;
