import React, { useState, useEffect } from 'react';
import { Hidden, IconButton, useTheme, Typography } from '@material-ui/core';
import { ChatBubble, ChevronLeftRounded, ChevronRightRounded, Close } from '@material-ui/icons';
import { CardBoardCollaborationsModal } from 'components/CardBoardCollaborationsModal';
import LRButton from 'components/LRButton';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { useResponsiveBreakpoint } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useActiveBoard } from 'mobx-store';
import { ArchiveCardButton } from './archive-card-button';
import { Can } from 'components/Ability';
import { useOnboarding } from 'components/Onboarding';
import { ActionShareIcon } from 'components/LRIcons';
import CopyToClipboard from 'components/CopyToClipboard';
import analytics from 'services/analytics';
import { portalApi } from 'apis';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { MoveCardOrListingButton } from 'components/MoveCardOrListingButton';
import Logger from 'js-logger';

export const Header = observer(function Header({ card, listing, index, total, onPrevious, onNext, onClose, showNavigation, trackEventData }) {
    const activeBoard = useActiveBoard();
    const [showBoardCardNotes, setShowBoardCardNotes] = useState(false);
    const [shortShareUrl, setShortShareUrl] = useState(null);
    const { boardCollaborations } = useBoardCollaborations(card?.id);
    const hasBoardCollaborations = !!boardCollaborations && !!boardCollaborations.length;
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const onboarding = useOnboarding();
    const location = useLocation();    
    const isFromHomeTour = matchPath(location?.pathname, {
        path: '/boards/scheduled-tours/',
        exact: false,
        strict: true
    });

    useEffect(()=> {
        async function shortenShareUrl(shareUrl) {
            const shortenedUrl = await portalApi.shortenURL(shareUrl);
            setShortShareUrl(shortenedUrl.data);
        }

        shortenShareUrl(window.location.href);
    }, []);
    
    return (
        <div
            style={{
                padding: `0 ${theme.spacing(isMobile ? 1 : 3)}px`,
                height: isMobile ? '56px' : '64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                borderBottom: '1px solid #DDDDDD',
                zIndex: 100,
                position: 'relative',
            }}
            className="tour-listing-details-header"
        >
            <Hidden smDown>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Can I="edit" a="BoardCard">
                        <MoveCardOrListingButton boardCard={card} listing={listing} />
                    </Can>
                </div>
                {showNavigation && total > 1 && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '286px'
                        }}
                    >
                        <LRButton
                            variant="outlined"
                            color="default"
                            disabled={index < 0 || !total || index === 0}
                            onClick={onPrevious}
                            style={{
                                fontWeight: 'normal',
                                paddingLeft: '12px',
                                paddingRight: '16px',
                                minWidth: 'unset',
                                height: '36px'
                            }}
                        >
                            <ChevronLeftRounded style={{fontSize: theme.typography.body1.fontSize}} />
                            <Typography variant="body2" style={{marginLeft: theme.spacing(1)}}>Previous</Typography>
                        </LRButton>
                        <Typography variant="caption">
                            {index >= 0 ? index + 1 : '-'} of {total ? total : '-'}
                        </Typography>
                        <LRButton
                            variant="outlined"
                            color="default"
                            disabled={index < 0 || !total || index === total - 1}
                            onClick={onNext}
                            style={{
                                fontWeight: 'normal',
                                paddingLeft: '16px',
                                paddingRight: '12px',
                                minWidth: 'unset',
                                height: '36px',
                            }}
                        >
                            <Typography variant="body2" style={{marginRight: theme.spacing(1)}}>Next</Typography>
                            <ChevronRightRounded style={{fontSize: theme.typography.body1.fontSize}} />
                        </LRButton>
                    </div>
                )}
                <div style={{display: 'flex'}}>
                    <Can I="edit" a="BoardCard">
                        {card && !card?.archived && !isFromHomeTour && (
                            <ArchiveCardButton
                                card={card}
                                trackEventData={trackEventData}
                                style={{padding: theme.spacing(1), fontSize: theme.typography.body1.fontSize}}
                            />
                        )}
                    </Can>
                    {shortShareUrl && <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: theme.spacing(2),
                                cursor: 'pointer'
                            }}
                            onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                card_id: card.id,
                                listingId: card.content.encoded_id,
                                Source: 'logged_in',
                            })}
                        >
                            <IconButton
                                disabled={false}
                                style={{padding: theme.spacing(1)}}
                            >
                                <ActionShareIcon style={{fontSize: theme.typography.body1.fontSize}} />
                            </IconButton>
                            <span style={{textDecoration: 'underline'}}>Share</span>
                        </div>
                    </CopyToClipboard>}
                </div>
            </Hidden>
            <Hidden mdUp>
                <div style={{display: 'flex'}}>
                    <IconButton
                        onClick={()=> {
                            if(onboarding?.isAddNoteOnboarding) {
                                onboarding.next();
                            }
                            setShowBoardCardNotes(true);
                        }}
                        style={{padding: theme.spacing(1)}}
                    >
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <ChatBubble />
                            {hasBoardCollaborations && (
                                <div
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        width: '12px',
                                        height: '12px',
                                        position: 'absolute',
                                        top: '-2px',
                                        right: '-2px',
                                        borderRadius: '50%',
                                        border: '3px solid white',
                                    }}
                                ></div>
                            )}
                        </div>
                    </IconButton>
                    {shortShareUrl && <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconButton
                                disabled={false}
                                onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                    card_id: card.id,
                                    listingId: card.content.encoded_id,
                                    Source: 'logged_in',
                                })}
                                style={{padding: theme.spacing(1)}}
                            >
                                <ActionShareIcon style={{fontSize: theme.typography.body1.h5}} />
                            </IconButton>
                        </div>
                    </CopyToClipboard>}
                </div>
                <div style={{
                    fontSize: theme.typography.caption.fontSize,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    {(index >= 0 && total) ? `${index + 1} of ${total}` : ''}
                </div>
                <IconButton
                    style={{padding: theme.spacing(1)}}
                    onClick={()=> {
                        onClose();
                        if(onboarding?.isAddNoteOnboarding) {
                            onboarding.next();
                        }
                    }}
                >
                    <Close></Close>
                </IconButton>
            </Hidden>
            <CardBoardCollaborationsModal
                boardId={activeBoard?.id}
                cardId={card?.id}
                open={showBoardCardNotes}
                onClose={()=> setShowBoardCardNotes(false)}
            />
        </div>
    );
});
