import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import { COLOR_BLUE, COLOR_GREY } from '../../../constants';
import { useStore } from '../../../mobx-store';
import { useSelection } from '../../../hooks';
import LRButton from '../../../components/LRButton/index';
import ListingCardHorizontal from '../../../components/ListingCardHorizontal';
import moment from 'moment';
import styled from 'styled-components/macro';

const StyledTextArea = styled.textarea`
    width: 100%;
    border: none;
    background: #f4f4f4;
    resize: none;
    padding: 10px;
    font-size: 16px;
    display: block;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &::placeholder {
        color: #ababab;
    }
    &:focus {
        outline: none;
    }
`;

const StyledSelect = styled(Select)`
    display: block;
    margin-top: 5px;
`;

const ListingSelection = ({ match })=> {
    const { LanesStore } = useStore();

    const [state, setState] = useState({
        showWhenToSee: false,
        showTime: false,
        showNotes: false,
        wantToSeeTime: ['Morning', 'Afternoon', 'Evening'],
        selectedWantToSeeTimeFirst: '',
        selectedWantToSeeTimeSecond: '',
        selectedDateFirst: '',
        selectedDateSecond: '',
        scheduleFinished: false
    });

    const {
        selection: selectedListings,
        isItemSelected: isListingSelected,
        toggleItemSelection: toggleListingSelection,
        addToSelection: addListing,
        removeFromSelection: removeListing
    } = useSelection();

    const listings = LanesStore.wantToSeeListings;

    const areAllListingsSelected = useMemo(()=> {
        return listings.reduce((accum, listing)=> {
            return accum && isListingSelected(listing);
        }, true);
    }, [listings, isListingSelected]);

    const toggleAllSelected = useCallback(
        (e)=> {
            const isChecked = e.target.checked;
            if(isChecked) {
                listings.forEach(addListing);
            } else {
                listings.forEach(removeListing);
            }
        },
        [listings, addListing, removeListing]
    );

    useEffect(()=> {
        setState({
            ...state,
            showWhenToSee: selectedListings.length > 0
        });
    }, [selectedListings, state]);

    const handleOnASAPClick = ()=> {
        setState({
            ...state,
            showTime: false,
            showNotes: true
        });
    };

    const handleOnPickClick = ()=> {
        setState({
            ...state,
            showTime: true,
            showNotes: true
        });
    };

    const renderTimesOfDay = ()=> {
        return state.wantToSeeTime.map((time)=> (
            <MenuItem key={time} value={time}>
                {time}
            </MenuItem>
        ));
    };

    const renderDates = ()=> {
        const date = moment();
        const dates = [];
        for(let i = 0; i < 8; i++) {
            const value = date.format('ddd, MMMM DD');
            dates.push(
                <MenuItem key={value} value={value}>
                    {value}
                </MenuItem>
            );
            date.add(1, 'd');
        }
        return dates;
    };

    const onChangeSelect = (event)=> {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const onScheduleClick = ()=> {
        // Additional backend logic goes here
        setState({
            ...state,
            scheduleFinished: true
        });
    };

    if(state.scheduleFinished) {
        return <Redirect to={`${match.path}/success`} />;
    }

    return (
        <Box maxWidth={500} mx="auto">
            <Box textAlign="center" mb={1} fontSize="h4.fontSize">
                Let's get scheduling
            </Box>

            <Box mx="auto" mb={4} width={36} borderBottom={`solid 3px ${COLOR_GREY}`} />
            <Box mb={2} textAlign="center">
                Let (AGENT_NAME) know which homes you'd like to see.
            </Box>
            {listings.length > 1 && (
                <Box mb={2} textAlign="center">
                    <FormControlLabel
                        label={`Select all ${listings.length} homes`}
                        control={
                            <Checkbox color="primary" checked={areAllListingsSelected} onChange={toggleAllSelected} />
                        }
                    />
                </Box>
            )}

            <Box mb={4}>
                {listings.map((listing)=> (
                    <Box
                        position="relative"
                        style={{ cursor: 'pointer' }}
                        key={listing.id}
                        onClick={()=> toggleListingSelection(listing)}
                    >
                        <ListingCardHorizontal listing={listing} />
                        {isListingSelected(listing) && (
                            <Box
                                position="absolute"
                                top={0}
                                right={0}
                                bottom={0}
                                left={0}
                                borderRadius="borderRadius"
                                border={`2px solid ${COLOR_BLUE}`}
                            />
                        )}
                    </Box>
                ))}
            </Box>

            {state.showWhenToSee && (
                <Box px={{ xs: 0, md: 2 }}>
                    <Box>
                        <Box mb={2} textAlign="center">
                            When would you like to see them?
                        </Box>
                        <Box mb={4} display="flex" flexWrap="nowrap" justifyContent="space-around" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LRButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={()=> handleOnASAPClick()}
                                >
                                    ASAP
                                </LRButton>
                            </Box>
                            <Box width="100%" ml={1}>
                                <LRButton
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={()=> handleOnPickClick()}
                                >
                                    Let me pick
                                </LRButton>
                            </Box>
                        </Box>

                        {state.showTime && (
                            <Box mb={4}>
                                <Box mb={3} mx={2} textAlign="center">
                                    Select times that work best for you. Please note that these dates and times are not
                                    guaranteed.
                                </Box>
                                <Box display="flex" justifyContent="space-around" alignItems="center">
                                    <Box width="50%" mr={1}>
                                        <Box>Date</Box>
                                        <StyledSelect
                                            width="1/2"
                                            onChange={onChangeSelect}
                                            input={<OutlinedInput />}
                                            name="selectedDateFirst"
                                            value={state.selectedDateFirst}
                                        >
                                            {renderDates()}
                                        </StyledSelect>
                                        <StyledSelect
                                            width="1/2"
                                            onChange={onChangeSelect}
                                            input={<OutlinedInput />}
                                            name="selectedDateSecond"
                                            value={state.selectedDateSecond}
                                        >
                                            {renderDates()}
                                        </StyledSelect>
                                    </Box>
                                    <Box width="50%" ml={1}>
                                        <Box>Time</Box>
                                        <StyledSelect
                                            width="1/2"
                                            onChange={onChangeSelect}
                                            input={<OutlinedInput />}
                                            name="selectedWantToSeeTimeFirst"
                                            value={state.selectedWantToSeeTimeFirst}
                                        >
                                            {renderTimesOfDay()}
                                        </StyledSelect>
                                        <StyledSelect
                                            width="1/2"
                                            onChange={onChangeSelect}
                                            input={<OutlinedInput />}
                                            name="selectedWantToSeeTimeSecond"
                                            value={state.selectedWantToSeeTimeSecond}
                                        >
                                            {renderTimesOfDay()}
                                        </StyledSelect>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        {state.showNotes && (
                            <Box>
                                <Box textAlign="center" mb={2}>
                                    {' '}
                                    Any aditional comments for (AGENT_NAME)?
                                </Box>
                                <StyledTextArea
                                    rows="10"
                                    placeholder={'Tell (AGENT_NAME) how much you like these homes.'}
                                />
                                <Box mt={3} width={1 / 4} mx="auto">
                                    <LRButton
                                        onClick={onScheduleClick}
                                        fullWidth={true}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Schedule
                                    </LRButton>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ListingSelection;
