// eslint-disable-next-line
import Logger from 'js-logger';
import { decorate, observable, computed } from 'mobx';
import _ from 'lodash';
import { mapquestAPI } from '../../apis';

export class InfographicsMapStore {
    _places = {};
    _selectedCategory = null;
    localizatedCategory = null;
    currentAddress = null;
    hoveredPlaceId = null;
    selectedPlaceId = null;
    _debounce = null;
    summaryRadius = null;
    summaryRating = null;

    map = null;
    maps = null;

    isLoading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    fetchData = async function(listingDetails) {
        try {
            clearTimeout(this._debounce);
            
            this.currentAddress = listingDetails.address;

            if(this._places[this.currentAddress]) {
                return this._places[this.currentAddress];
            }

            this.isLoading = true;

            const debounce = new Promise((resolve, reject)=> {
                this._debounce = setTimeout(()=> {
                    resolve();
                }, 3000);
            });

            await debounce;

            // fetch places
            const places = await this.fetchNearbyPlaces(this.map, this.maps, listingDetails);
            // this.setPlaces(places);
            this._places[this.currentAddress] = places;

            // fetch schools
            let schoolsResult = await this.rootStore.SchoolsStore.getSchoolsData(this.currentAddress);

            schoolsResult = _.uniqBy(schoolsResult.all_schools, function(school) {
                return school._id;
            });

            schoolsResult = _.sortBy(schoolsResult, (school)=> {
                return parseFloat(school.distance);
            });

            schoolsResult = schoolsResult.slice(0, 5);
            schoolsResult = schoolsResult.map((school)=> {
                return {
                    place_id: school._id,
                    name: school.name,
                    assigned: school.assigned,
                    distance: school.distance,
                    rating: school.rating,
                    geometry: {
                        location: {
                            lat: ()=> {
                                return school.lat;
                            },
                            lng: ()=> {
                                return school.lng;
                            },
                        },
                    },
                };
            });

            this._places[this.currentAddress].schools = schoolsResult;

            await this.fetchMapQuestsMatrixDistances(listingDetails);
            //await this.fetchGMapsMatrixDistances(listingDetails);

            // after having the schools data merged and the distances calculated
            // we need to sort the places by distance
            Object.keys(this._places[this.currentAddress])
                .forEach((key)=> {
                    const sortedPlaces = _.sortBy(this._places[this.currentAddress][key], 'distance');
                    this._places[this.currentAddress][key] = sortedPlaces;
                });

            this.isLoading = false;

            return this._places[this.currentAddress];
        } catch (err) {
            this._places[this.currentAddress] = null;
            this.isLoading = false;
            return null;
        }
    };

    fetchMapQuestsMatrixDistances = async function(listingDetails) {
        const startLocation = `${listingDetails.lat},${listingDetails.lon}`;

        let flatPlaces = Object.values(this._places[listingDetails.address]);
        flatPlaces = _.flatten(flatPlaces);

        const searchCoordinates = flatPlaces.map((place)=> {
            return `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
        });

        try {
            const requestPayload = {
                locations: [].concat([startLocation], searchCoordinates),
            };

            const response = await mapquestAPI.fetchMatrixData(requestPayload);

            flatPlaces.forEach((place, index)=> {
                // the result object contains the target address on index 0
                // in order to fetch the correct place index we offset by 1
                const placeIndex = index + 1;
                const location = response.locations[placeIndex];
                place.distance = response.distance[placeIndex].toFixed(1);
                place.formatted_address = `${location.street}, ${location.adminArea5}, ${location.adminArea3} ${location.postalCode}, ${location.adminArea1}`;
            });
        } catch (err) {
            // if the request fails for some reason we could estimate the distance with a math function
            flatPlaces.forEach((place, index)=> {
                const placeLocation = place.geometry.location;

                const placeDistance = this.distance(
                    listingDetails.lat,
                    listingDetails.lon,
                    placeLocation.lat(),
                    placeLocation.lng()
                );
                place.distance = placeDistance.toFixed(1);
                place.formatted_address = '';
            });
        }
    };

    fetchGMapsMatrixDistances = async function(listingDetails) {
        // we will be using Goole Matrix Api to get the distance from our origin to all of the nearest places
        // Google Matrix Api also give us the formatted addresses for each LatLng object we submit to the query
        // since we also need the formatted address we will use this get request to also get it and avoiding the need to call the PlaceDetails method for each place
        let service = new this.maps.DistanceMatrixService();

        let flatPlaces = Object.values(this._places[listingDetails.address]);
        flatPlaces = _.flatten(flatPlaces);

        const searchCoordinates = flatPlaces.map((place)=> {
            return new this.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
        });

        const chunkedCoordinates = _.chunk(searchCoordinates, 25);

        let promises = [];

        chunkedCoordinates.forEach((items, index)=> {
            const chunkOffset = index === 0 ? 0 : index * 25;
            promises.push(
                new Promise((resolve, reject)=> {
                    service.getDistanceMatrix(
                        {
                            origins: [new this.maps.LatLng(listingDetails.lat, listingDetails.lon)],
                            destinations: items,
                            travelMode: 'DRIVING',
                            unitSystem: this.maps.UnitSystem.IMPERIAL,
                        },
                        (response, status)=> {
                            const responseData = response.rows[0].elements;
                            const destinationAddresses = response.destinationAddresses;
                            responseData.forEach((item, index)=> {
                                flatPlaces[chunkOffset + index].distance = parseFloat(item.distance.text.split(' ')[0]);
                                flatPlaces[chunkOffset + index].formatted_address = destinationAddresses[index];
                                resolve(flatPlaces);
                            });
                        }
                    );
                })
            );
        });

        const result = Promise.all(promises);

        return result;
    };

    // fetch methods
    fetchNearbyPlaces = async function(map, maps, listingDetails) {
        const service = new maps.places.PlacesService(map);

        let promises = [];

        const categories = [
            'cafe',
            'supermarket',
            'atm',
            'gas_station',
            'pharmacy',
            'hospital',
            'laundry',
            'gym',
            'movie_theater',
        ];

        categories.forEach((k)=> {
            promises.push(
                new Promise((resolve, reject)=> {
                    service.nearbySearch(
                        {
                            location: new maps.LatLng(listingDetails.lat, listingDetails.lon),
                            // radius: 50000,
                            rankBy: maps.places.RankBy.DISTANCE,
                            type: [k],
                        },
                        (results, status)=> {
                            if(status === maps.places.PlacesServiceStatus.OK) {
                                resolve(results.slice(0, 5));
                            } else {
                                console.error(status);
                                resolve([]);
                            }
                        }
                    );
                })
            );
        });

        let [coffee, groceries, atm, gas, pharmacy, hospital, cleaners, gym, movie] = await Promise.all(promises);

        const places = {
            coffee,
            groceries,
            atm,
            gas,
            pharmacy,
            hospital,
            cleaners,
            gym,
            movie,
        };

        return places;
    };

    distance = function(lat1, lon1, lat2, lon2, unit) {
        if(lat1 === lat2 && lon1 === lon2) {
            return 0;
        } else {
            var radlat1 = (Math.PI * lat1) / 180;
            var radlat2 = (Math.PI * lat2) / 180;
            var theta = lon1 - lon2;
            var radtheta = (Math.PI * theta) / 180;
            var dist =
                Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if(dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = (dist * 180) / Math.PI;
            dist = dist * 60 * 1.1515;
            if(unit === 'K') {
                dist = dist * 1.609344;
            }
            if(unit === 'N') {
                dist = dist * 0.8684;
            }
            return dist;
        }
    };

    // Setters
    setPlaces(address, place) {
        //a place always have the address as key
        this._places[address] = place;
    }

    setSelectedCategory(category) {
        this.hoveredPlaceId = null;
        this.selectedPlaceId = null;
        this._selectedCategory = category;
    }

    get places() {
        return this._places;
    }

    get selectedCategory() {
        return this._selectedCategory;
    }

    get categoryPlaces() {
        let selectedCategories = [];

        if(this.selectedCategory && this.places && this.places[this.currentAddress]) {
            selectedCategories = this.places[this.currentAddress][this.selectedCategory];
        }

        return selectedCategories;
    }
}

decorate(InfographicsMapStore, {
    // Variables
    _places: observable,
    _selectedCategory: observable,
    localizatedCategory: observable,
    hoveredPlaceId: observable,
    selectedPlaceId: observable,
    isLoading: observable,
    map: observable,
    maps: observable,
    summaryRadius: observable,
    summaryRating: observable,
    currentAddress: observable,

    // computed
    places: computed,
    selectedCategory: computed,
    categoryPlaces: computed,
});

export default new InfographicsMapStore();
