import { useStore } from '../../mobx-store';
import { useEffect, useRef, useCallback } from 'react';
import { orderBy, findIndex, reject } from 'lodash';
import { benoitApi } from '../../apis';
import Logger from 'js-logger';
import { useRobinRealtimeCommunication } from '../useRobinRealtimeCommunication';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const logger = Logger.get('useBoardCollaborations');
const BOARD_COLLABORATIONS_CACHE_KEY = 'board-collaborations';

export function useBoardCollaborations(itemId) {
    const queryClient = useQueryClient();
    const { UserStore, boardsStore } = useStore();
    const { user, isAgent, isLO } = UserStore;
    const activeBoard = boardsStore.activeBoard;
    const boardId = activeBoard?.id;

    useBoardCollaborationRealtime(boardId, itemId);

    const {data:boardCollaborations, isLoading, collaborationsQuery} = useQuery(
        [BOARD_COLLABORATIONS_CACHE_KEY, boardId, itemId],
        ()=> (
            benoitApi.getBoardCollaborations(boardId, itemId, {
                include_user: true,
                include_reactions: true,
                archived: false,
            })
        ),
        {
            enabled: Boolean(boardId && itemId),
            select: (data)=> {
                return orderBy(data, ['createdAt'], ['asc']);
            }
        }
    );
    const addBoardCollaborationMutation = useMutation(
        (data)=> (addCollaboration(data, user, { isLO, isAgent })),
        {
            onSuccess: (response)=> {
                let newBoardCollaborations;

                if(response.type === 'reaction') {
                    const itemIndex = findIndex(boardCollaborations, { id: response.itemId });
                    const item = boardCollaborations[itemIndex];
                    item.reactions = [...(item.reactions || []), response];
                    newBoardCollaborations = [
                        ...boardCollaborations.slice(0, itemIndex),
                        item,
                        ...boardCollaborations.slice(itemIndex + 1),
                    ];
                } else {
                    newBoardCollaborations = [
                        ...(boardCollaborations || []),
                        response,
                    ];
                }

                queryClient.setQueryData([BOARD_COLLABORATIONS_CACHE_KEY, boardId, itemId], newBoardCollaborations);
            }
        }
    );

    const updateBoardCollaborationMutation = useMutation(
        ({ id, data })=> (updateCollaboration(id, data, user, { isLO, isAgent })),
        {
            onSuccess: (response)=> {
                let newBoardCollaborations;
                const itemIndex = findIndex(boardCollaborations, { id: response.itemId });

                if(~itemIndex) {
                    newBoardCollaborations = [
                        ...boardCollaborations.slice(0, itemIndex),
                        response,
                        ...boardCollaborations.slice(itemIndex + 1),
                    ];

                    queryClient.setQueryData(
                        [BOARD_COLLABORATIONS_CACHE_KEY, boardId, itemId],
                        newBoardCollaborations
                    );
                }
            }
        }
    );

    const deleteBoardCollaborationMutation = useMutation(
        ({ boardCollaboration, options = {} })=> (deleteCollaboration(boardCollaboration, user, options)),
        {
            onSuccess: (boardCollaboration)=> {
                let newBoardCollaborations;

                if(boardCollaboration.type === 'reaction') {
                    const itemIndex = findIndex(boardCollaborations, { id: boardCollaboration.itemId });
                    const item = { ...boardCollaborations[itemIndex] };
                    const reactionIndex = findIndex(item.reactions, { id: boardCollaboration.id });
                    item.reactions = [
                        ...item.reactions.slice(0, reactionIndex),
                        ...item.reactions.slice(reactionIndex + 1)
                    ];
                    newBoardCollaborations = [
                        ...boardCollaborations.slice(0, itemIndex),
                        item,
                        ...boardCollaborations.slice(itemIndex + 1),
                    ];
                } else {
                    const itemIndex = findIndex(boardCollaborations, { id: boardCollaboration.id });

                    if(~itemIndex) {
                        newBoardCollaborations = [
                            ...boardCollaborations.slice(0, itemIndex),
                            ...boardCollaborations.slice(itemIndex + 1),
                        ];
                    }
                }

                if(newBoardCollaborations) {
                    queryClient.setQueryData(
                        [BOARD_COLLABORATIONS_CACHE_KEY, boardId, itemId],
                        newBoardCollaborations
                    );
                }
            }
        }
    );

    return {
        ...collaborationsQuery,
        isLoading: isLoading || !boardId || !itemId,
        boardCollaborations,

        // Getters and Setters
        get cardMessageBoardCollaborations() {
            return this.boardCollaborations?.filter(cardMessageTypeFilter) || [];
        },

        // Functions
        addBoardCollaboration: addBoardCollaborationMutation.mutateAsync.bind(addBoardCollaborationMutation),
        updateBoardCollaboration: updateBoardCollaborationMutation.mutateAsync.bind(updateBoardCollaborationMutation),
        deleteBoardCollaboration: deleteBoardCollaborationMutation.mutateAsync.bind(deleteBoardCollaborationMutation),
    };
}

// Hooks
function useBoardCollaborationRealtime(boardId, itemId) {
    const queryClient = useQueryClient();
    const _lastMessageReceived = useRef();
    const {
        lastMessage,
    } = useRobinRealtimeCommunication(boardId ? `robin-board-${boardId}` : null);
    const CACHE_KEY = [BOARD_COLLABORATIONS_CACHE_KEY, boardId, itemId];
    let boardCollaborations = queryClient.getQueryData(CACHE_KEY);

    // Functions

    const handleCommentChanges = useCallback(function handleCommentChanges(boardCollaboration, action) {
        const index = findIndex(boardCollaborations, { id: boardCollaboration.id });
        let newBoardCollaborations;

        if(action === 'board-collaboration-created') {
            // Created

            // Only add it if we dont already have it ( oh cool, that rhymes )
            if(!~index) {
                newBoardCollaborations = [
                    ...boardCollaborations,
                    boardCollaboration,
                ];
            }
        } else if(action === 'board-collaboration-deleted' || (action === 'board-collaboration-updated' && boardCollaboration.archived)) {
            // Deleted

            newBoardCollaborations = reject(boardCollaborations, { id: boardCollaboration.id });
        } else if(action === 'board-collaboration-updated') {
            // Updated

            newBoardCollaborations = [
                ...boardCollaborations.slice(0, index),
                boardCollaboration,
                ...boardCollaborations.slice(index + 1),
            ];
        }

        if(newBoardCollaborations) {
            queryClient.setQueryData(CACHE_KEY, newBoardCollaborations);
            queryClient.invalidateQueries(CACHE_KEY);
        }
    }, [CACHE_KEY, boardCollaborations, queryClient]);

    const handleReactionChanges = useCallback(function handleReactionChanges(boardCollaboration, action) {
        const itemIndex = findIndex(boardCollaborations, { id: boardCollaboration.itemId });
        const item = { ...boardCollaborations[itemIndex] };
        const reactions = (item.reactions || []);
        const reactionIndex = findIndex(reactions, { id: boardCollaboration.id });
        let newBoardCollaborations;

        if(action === 'board-collaboration-created') {
            // Created

            // Only add it if we dont already have it ( oh cool, that rhymes )
            if(!~reactionIndex) {
                item.reactions = [...reactions, boardCollaboration];
            }

            newBoardCollaborations = [
                ...boardCollaborations.slice(0, itemIndex),
                item,
                ...boardCollaborations.slice(itemIndex + 1),
            ];
        } else if(action === 'board-collaboration-deleted' || (action === 'board-collaboration-updated' && boardCollaboration.archived)) {
            // Deleted

            item.reactions = reject(reactions, { id: boardCollaboration.id });
            newBoardCollaborations = [
                ...boardCollaborations.slice(0, itemIndex),
                item,
                ...boardCollaborations.slice(itemIndex + 1),
            ];
        } else if(action === 'board-collaboration-updated') {
            // Updated

            item.reactions = [
                ...reactions.slice(0, reactionIndex),
                boardCollaboration,
                ...reactions.slice(reactionIndex + 1),
            ];
            newBoardCollaborations = [
                ...boardCollaborations.slice(0, itemIndex),
                item,
                ...boardCollaborations.slice(itemIndex + 1),
            ];
        }

        if(newBoardCollaborations) {
            queryClient.setQueryData(CACHE_KEY, newBoardCollaborations);
            queryClient.invalidateQueries(CACHE_KEY);
        }
    }, [CACHE_KEY, boardCollaborations, queryClient]);

    // UseEffects

    useEffect(()=> {
        if(!lastMessage || lastMessage === _lastMessageReceived.current) return;

        _lastMessageReceived.current = lastMessage;

        let data;

        try {
            data = JSON.parse(lastMessage.data).p;
        } catch (err) {
            logger.error('useEffect -> JSON.parse(lastMessage.data) -> Error', err);
        }

        if(!data?.content) return;

        // The main part of the message sent from the backend
        const content = data.content;
        const {
            boardCollaboration,
            mtype,
        } = content;
        const allowedMessageTypes = [
            'board-collaboration-created',
            'board-collaboration-updated',
            'board-collaboration-deleted',
        ];
        const commentMessageTypes = [
            'comment',
            'moved_card',
            'schedule_viewing_request',
            'offer_submitted',
            'schedule_viewing_request_comment',
        ];

        if(!~allowedMessageTypes.indexOf(mtype) || (boardCollaboration.itemId !== itemId && !~findIndex(boardCollaborations, { id: boardCollaboration.itemId }))) return;

        if(~commentMessageTypes.indexOf(boardCollaboration?.type)) {
            handleCommentChanges(boardCollaboration, mtype);
        } else if(boardCollaboration?.type === 'reaction') {
            handleReactionChanges(boardCollaboration, mtype);
        }
    }, [boardCollaborations, handleCommentChanges, handleReactionChanges, itemId, lastMessage]);
}

// Helper Functions
function cardMessageTypeFilter(boardCollaboration) {
    return [
        'comment',
        'offer_submitted',
        'schedule_viewing_request'
    ].indexOf(boardCollaboration?.type) > -1 &&
        boardCollaboration?.content?.message &&
        boardCollaboration.content.message.length > 0;
}

/**
* Add a new collaboration to a board card
*
* @param {Object} data
* @param {String} data.boardId
* @param {String} data.itemId - The cardId
* @param {Object} data.content - The data you need to save to show it in the message
* @param {String} data.type
*/
async function addCollaboration(data, user, { isLO, isAgent }) {
    if(!user) {
        throw new Error('User is not logged in');
    }

    data.createdById = user.id;
    data.updatedById = user.id;
    data.userId = isLO
        ? user.affiliate?.preferredVendorId
        : isAgent
            ? user.affiliateId
            : user.id;
    if(isAgent || isLO) data.affiliateId = user.affiliateId;
    data.model = data.model || 'BoardCard';

    let response = await benoitApi.addBoardCollaboration(data);
    response.user = (isAgent || isLO) ? null : user;

    return response;
}

/**
* Updates a board collaboration
*
* @param {String} id
* @param {Object} data
*/
async function updateCollaboration(id, data, user, { isLO, isAgent }) {
    if(!user) {
        throw new Error('User is not logged in');
    }

    let response = await benoitApi.updateBoardCollaboration(id, data);
    response.user = (isAgent || isLO) ? null : user;

    return response;
}

/**
* Delete a board collaboration
*
* @param {Object} boardCollaboration
* @param {Object} [options] - See benoitApi.deleteBoardCollaboration for options
*/
async function deleteCollaboration(boardCollaboration, user, options = {}) {
    if(!user) {
        throw new Error('User is not logged in');
    }

    await benoitApi.deleteBoardCollaboration(boardCollaboration.id, options);

    return boardCollaboration;
}
