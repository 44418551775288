import React from 'react';
import { Box } from '@material-ui/core';
import { ListingCardPlaceholder } from '../ListingCardPlaceholder';
import ReactPlaceholder from 'react-placeholder';
import _ from 'lodash';
export function CardPlaceholderList({placeholderCount = 30, ready, ...props}){
    return (
        <>
            {_.range(placeholderCount).map((num, index)=> {
                return (
                    <Box width={[1,350]} key={num} m={1}>
                        <ReactPlaceholder
                            ready={ready}
                            customPlaceholder={<ListingCardPlaceholder />}
                        />
                    </Box>
                );
            })}
        </>
    );
}
