import React, { useState } from 'react';
import {
    Box,
    MenuItem,
    Select,
    OutlinedInput,
    Popover,
    List,
    ListItem,
    ListItemText,
    withTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Done } from '@material-ui/icons';
import LRButton from '../../../../../../components/LRButton';
import LRAvatar from '../../../../../../components/LRAvatar';
import styled from 'styled-components';
import { COLOR_OFF_WHITE, COLOR_GREY_DARKEST } from '../../../../../../constants';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../../mobx-store';

const StyledListItem = styled(ListItem)`
    & .MuiListItemText-primary {
        font-weight: bold;
    }
    & .MuiListItemText-secondary {
        color: ${COLOR_GREY_DARKEST};
        word-wrap: break-word;
        margin-left: 30px;
    }
    &:hover {
        cursor: pointer;
        background: ${COLOR_OFF_WHITE};
    }
`;
const StyledCheck = styled(Done)`
    vertical-align: top;
    margin-right: 10px;
`;
const StyledList = withTheme(styled(List)`
    width: 266px;
    ${({ theme })=> theme.breakpoints.up('md')} {
        width: 315px;
    }
`);

function CollaboratorItem({
    collaborator,
    role = 'buyer',
    hasPendingInvite = true,
    setSnackbarStatus,
    onCollaboratorRemove
}) {
    const { name, phone_formatted, email } = collaborator;
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const [currentRole, setCurrentRole] = useState(role);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function handleClose() {
        setAnchorEl(null);
    }

    async function handleSelect(role) {
        setAnchorEl(null);
        setCurrentRole(role);
        const collaboratorData = { id: collaborator.id, label: role, type: collaborator.type };
        await activeBoard.updateCollaborator(collaboratorData, hasPendingInvite);
        setSnackbarStatus({ open: true, message: 'Role change saved', hasUndo: false });
    }

    function handleRemove() {
        onCollaboratorRemove(collaborator);
    }

    function handleResendInvite() {
        setSnackbarStatus({ open: true, message: 'Invite re-sent!', hasUndo: false });
    }

    return (
        <Box>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                <Box flexGrow="0" flexBasis="41.666667%" maxWidth={{ xs: 1, md: 5 / 12 }}>
                    <Box m="-4px" display="flex" flexWrap="nowrap">
                        <Box p="4px">
                            <LRAvatar user={collaborator} size="medium" />
                        </Box>
                        <Box p="4px" minWidth={0}>
                            <Box pr={2}>
                                <Box fontWeight="fontWeightBold">{name}</Box>
                                <Box>{phone_formatted}</Box>
                                <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {email}
                                </Box>
                                <Box display={hasPendingInvite ? '' : 'none'} color="text.hint">
                                    Pending
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-start', md: 'center' }}
                    flexBasis="33.3333%"
                    alignItems="center"
                    maxWidth={{ xs: 1, md: 4 / 12 }}
                >
                    <Box>
                        <Box
                            onClick={(e)=>
                                setAnchorEl(currentRole !== 'buyer' && currentRole !== 'agent' ? e.currentTarget : null)
                            }
                            width="160px"
                            ml={{ xs: '56px', md: '66px' }}
                            mt={{ xs: 1, md: 0 }}
                        >
                            <Select
                                style={{ pointerEvents: 'none' }}
                                fullWidth={true}
                                input={<OutlinedInput />}
                                value={currentRole}
                                disabled={currentRole === 'buyer' || currentRole === 'agent'}
                            >
                                <MenuItem value="agent">Agent</MenuItem>
                                <MenuItem value="buyer">Buyer</MenuItem>
                                <MenuItem value="co-buyer">Co-buyer</MenuItem>
                                <MenuItem value="collaborator">Collaborator</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <Popover
                        elevation={4}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <StyledList>
                            <StyledListItem onClick={(e)=> handleSelect('co-buyer')}>
                                <ListItemText
                                    fontSize="h7.fontSize"
                                    secondary="Can view, comment, edit and move cards."
                                >
                                    <StyledCheck
                                        style={{
                                            visibility: currentRole === 'co-buyer' ? 'visible' : 'hidden'
                                        }}
                                        fontSize="small"
                                    />
                                    <Box display="inline-block">Co-buyer</Box>
                                </ListItemText>
                            </StyledListItem>
                            <StyledListItem onClick={(e)=> handleSelect('collaborator')}>
                                <ListItemText
                                    fontSize="h7.fontSize"
                                    secondary="Can view and comment but cannot edit and move cards."
                                >
                                    <StyledCheck
                                        style={{
                                            visibility: currentRole === 'collaborator' ? 'visible' : 'hidden'
                                        }}
                                        fontSize="small"
                                    />
                                    <Box display="inline-block">Collaborator</Box>
                                </ListItemText>
                            </StyledListItem>
                        </StyledList>
                    </Popover>
                </Box> */}
                <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                    flexBasis="25%"
                    flexGrow="0"
                    maxWidth={{ xs: 1, md: 3 / 12 }}
                >
                    <Box display="flex" flexDirection={{ xs: 'row', md: 'column' }} ml={{ xs: '56px', md: '0' }}>
                        <Box display={hasPendingInvite ? '' : 'none'}>
                            <LRButton color="primary" onClick={handleResendInvite}>
                                Re-send invite
                            </LRButton>
                        </Box>
                        <Box display={role === 'buyer' ? 'none' : ''} textAlign="right">
                            <LRButton color="primary" onClick={handleRemove}>
                                Remove
                            </LRButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

CollaboratorItem.propTypes = {
    collaborator: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
    hasPendingInvite: PropTypes.bool.isRequired,
    setSnackbarStatus: PropTypes.func.isRequired,
    onCollaboratorRemove: PropTypes.func.isRequired
};

export default observer(CollaboratorItem);
