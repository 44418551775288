// eslint-disable-next-line
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { COLOR_GREY_DARK } from '../../../constants';

const DisclaimerLink = styled.a`
    color: ${COLOR_GREY_DARK};
    text-decoration: none;
`;

function FooterDesktop() {
    const currentYear = moment()
        .format('YYYY');

    return (         
        <Box color={COLOR_GREY_DARK}
            height="28px"
            bottom="11px"
            fontSize="10px"
            left="0"
            right="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="caption">
                &copy; Copyright {currentYear} ListReports, Inc. All rights reserved |&nbsp;Patent Pending |&nbsp;

                <DisclaimerLink 
                    href="https://welcome.listreports.com/terms-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms
                </DisclaimerLink> |&nbsp;

                <DisclaimerLink 
                    href="https://welcome.listreports.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy
                </DisclaimerLink> |&nbsp;
    
                <DisclaimerLink 
                    href="https://welcome.listreports.com/premium-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Premium Membership Terms
                </DisclaimerLink> |&nbsp;Available in USA only
            </Typography>
        </Box>
    );
}

export default FooterDesktop;
