// eslint-disable-next-line
import React, { useState, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme, withTheme } from '@material-ui/core/styles';
import { Grid, Box, Collapse, useMediaQuery } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import PaymentBreakdown from './PaymentBreakdown';
import LRButton from '../LRButton';
import Overview from './Overview';
import Form from './Form';
import { useStore } from '../../mobx-store';
import MortgageCalculatorModal from './Modal';
import analytics from '../../services/analytics';
import { COLOR_GREY_DARK } from '../../constants';

const InfoGrid = styled(Grid)`
    margin-top: 24px;
    margin-bottom: 24px;
`;
const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;
const InfoGridItem = withTheme(styled((props)=> <Grid item {...props} />)`
    width: ${({ mobileviewonly })=> (mobileviewonly === 'true' ? '100%' : '50%')};

    ${({ theme })=> theme.breakpoints.only('xs')} {
        width: 100%;
    }
`);

function MortgageCalculator({
    match,
    listing,
    history,
    buyerAgent,
    price,
    hoaDuesAmount,
    hoaDuesPeriod,
    taxes,
    state,
    expand,
    onExpandClick,
    requestSource,
    reportId,
    mobileViewOnly = false,
}) {
    const theme = useTheme();
    const gtXs = useMediaQuery(theme.breakpoints.up('sm'));
    const { MortgageCalculatorStore, ListpackStore, UserStore } = useStore();
    const { agent, listpack, loadedAgent, loadingAgent } = ListpackStore;
    const [showModal, setShowModal] = useState(false);
    const user = UserStore.user;

    if((hoaDuesPeriod || '').toLowerCase() === 'year' && hoaDuesAmount) {
        hoaDuesAmount = hoaDuesAmount / 12;
    }

    useEffect(
        function() {
            if(loadedAgent || loadingAgent || !buyerAgent) {
                return;
            }

            ListpackStore.fetchAgent(buyerAgent.id);
        }, [ListpackStore, buyerAgent, loadedAgent, loadingAgent]
    );

    useEffect(
        function() {
            MortgageCalculatorStore.setInitialValues([
                {
                    name: 'hoaDues',
                    value: Math.round(hoaDuesAmount || 0),
                },
                {
                    name: 'homePrice',
                    value: price,
                },
                {
                    name: 'propertyTaxes',
                    value: taxes || 0,
                },
                {
                    name: 'downPaymentPercent',
                    value: 20,
                },
                {
                    name: 'state',
                    value: state,
                },
            ]);
        },
        [MortgageCalculatorStore, hoaDuesAmount, price, state, taxes]
    );

    function handleOnViewClick() {
        if(!expand) {
            analytics.eventTrack('listpacks_consumer_listingpage_customize_calculations_click', {
                address: listing.address,
                'listing id': listing.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
                source: requestSource,
                report_id: reportId,
            });
        }
        if(gtXs || mobileViewOnly) {
            onExpandClick();
        } else {
            onExpandClick();
            setShowModal(true);
        }
    }

    if(showModal) {
        return (
            <MortgageCalculatorModal onClose={handleMobileMortgageCalculatorOnClose}>
                <MortgageCalculator
                    listing={listing}
                    price={price}
                    hoaDuesAmount={hoaDuesAmount}
                    hoaDuesPeriod={hoaDuesPeriod}
                    taxes={taxes}
                    state={state}
                    expand={expand}
                    onExpandClick={onExpandClick}
                    buyerAgent={buyerAgent}
                    mobileViewOnly
                    requestSource={requestSource}
                />
            </MortgageCalculatorModal>
        );
    }

    function handleMobileMortgageCalculatorOnClose() {
        onExpandClick();
        setShowModal(false);
    }

    return (
        <>
            <InfoGrid container wrap={gtXs && !mobileViewOnly ? 'nowrap' : 'wrap'} className="info">
                <InfoGridItem className="overview-wrapper" align="center" mobileviewonly={`${mobileViewOnly}`}>
                    <Box display="flex" flexDirection="column" justifyContent="center" width="100%" height="100%">
                        <Box>
                            {!mobileViewOnly && (
                                <>
                                    <Overview />
                                    <br />
                                </>
                            )}

                            <div>
                                <Image src="/images/tbd/mortgage-calculator-chicken.png" alt="Mortgage Calculator" />
                            </div>

                            {(!gtXs || mobileViewOnly) && <br />}
                        </Box>
                    </Box>
                </InfoGridItem>

                <InfoGridItem className="breakdown-wrapper" mobileviewonly={`${mobileViewOnly}`}>
                    <PaymentBreakdown
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        width="100%"
                        height="100%"
                        borderRadius="4px"
                    />
                </InfoGridItem>
            </InfoGrid>

            {!mobileViewOnly && (
                <Box fontSize={10} color={COLOR_GREY_DARK}>
                    Estimate provided by ListReports. This tool is intended for education purposes only. The info
                    provided relies on assumptions and info provided by you based on your individual goals and financial
                    situation. The output of this tool should not be considered financial or legal advice, nor is it a
                    loan offer or solicitation.
                </Box>
            )}

            <Collapse in={expand && (gtXs || mobileViewOnly)}>
                <Box mt={3}>
                    <Form listing={listing} mobileViewOnly={mobileViewOnly} requestSource={requestSource} reportId={reportId} />
                </Box>
            </Collapse>

            {!mobileViewOnly && (
                <Box align="center" mt={34 / 8}>
                    <LRButton variant="text" inline color="secondary" onClick={handleOnViewClick}>
                        {expand ? 'View less' : 'Customize calculations'}
                    </LRButton>
                </Box>
            )}
        </>
    );
}

MortgageCalculator.propTypes = {
    listing: PropTypes.object,
    history: PropTypes.object,
    hoaDuesAmount: PropTypes.number,
    hoaDuesPeriod: PropTypes.string,
    match: PropTypes.object,
    mobileViewOnly: PropTypes.bool,
    price: PropTypes.number.isRequired,
    state: PropTypes.string,
    taxes: PropTypes.number,
};

export default withRouter(observer(MortgageCalculator));
