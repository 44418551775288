import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

const Header = styled(Box)`
    z-index: 9;
`;

const PhantomBox = styled(Box)`
    display: block;
    width: '100%';
`;

const FixedBox = styled(Box)`
    position: fixed;
    text-align: center;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

function LRStickyHeader(props) {
    const { children, height=52, ...restProps } = props;

    return (
        <Header>
            <PhantomBox height={height}
                {...restProps}
            />
            <FixedBox
                bgcolor="white"
                height={height}
                {...restProps}
            >
                {children}
            </FixedBox>
        </Header>
    );
}

LRStickyHeader.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default LRStickyHeader;
