import { FirstRunFlow } from './FirstRunFlow';
import { InviteCobuyerFlow } from './InviteCobuyerFlow';
import { AddNoteFlow } from './AddNoteFlow';
import { CompareFlow } from './CompareFlow';
import { PinPhotoFlow } from './PinPhotoFlow';
import { AsyncFirstRunFlow } from './AsyncFirstRunFlow';
import { NurtureFirstRunFlow } from './NurtureFirstRunFlow';

export const flows = {
    firstRun: FirstRunFlow,
    asyncFirstRun: AsyncFirstRunFlow,
    inviteCobuyer: InviteCobuyerFlow,
    addNote: AddNoteFlow,
    compareFlow: CompareFlow,
    pinPhoto: PinPhotoFlow,
    nurtureFirstRun: NurtureFirstRunFlow
};