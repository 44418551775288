import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import LRModal from '../../../../components/LRModal';
import LRTabs from '../../../../components/LRTabs';
import LRTab from '../../../../components/LRTabs/LRTab';
import Permissions from './Permissions';
import BoardSettings from './BoardSettings';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../mobx-store';

function BoardManageModal({ match }) {
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const collaborators = activeBoard ? activeBoard.collaborators : [];
    const [closing, setClosing] = useState(false);

    if(closing) {
        return <Redirect to="/boards" />;
    }
    return (
        <LRModal onClose={()=> setClosing(true)}>
            <Box mx={4}>
                {activeBoard && (
                    <Box mt={2} mb={1} textAlign="center" fontSize="h5.fontSize">
                        {activeBoard.name}
                    </Box>
                )}

                <Permissions collaborators={collaborators} />
            </Box>
        </LRModal>
    );
}

export default observer(BoardManageModal);
