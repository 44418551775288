import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Hidden } from '@material-ui/core';
import LRAvatar from '../../../../../components/LRAvatar';
import formatPhoneNumber from '../../../../../services/format-phone-number';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

const Branding = withTheme(styled.img`
    max-height: 38px;
    display: block;
    ${({ theme })=> theme.breakpoints.down('xs')} {
        max-width: 92px;
    }
`);

const AgentInfo = ({ agent, variant = 'default', disableBrandingLogo=false })=> {
    return (
        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
            <Grid item container alignItems="center">
                {agent.headshot && (
                    <LRAvatar user={agent} size="small"></LRAvatar>
                )}
                <Box ml="12px" height={1}>
                    <Box>
                        <Box component="p" display="inline" fontSize="body1.fontSize" fontFamily="body1.fontFamily">
                            {agent.first_name} {agent.last_name}
                        </Box>
                        {variant === 'default' && <Hidden smDown>
                            <Box ml={1} fontFamily="body1.fontFamily" color="lr_colors.grey_dark" fontSize="body2.fontSize" component="span" display="inline">
                                Lic#: {agent.realtor_license_number}
                            </Box>
                        </Hidden>}
                    </Box>
                    <Box
                        display="flex"
                        fontSize="0.75rem"
                        flexDirection={['column', 'row']}
                        lineHeight="14px"
                    >
                        {variant === 'default' && <Box>{agent.company}</Box>}
                        <Box flexDirection="row">
                            {agent.company && variant === 'default' && (
                                <Hidden xsDown>
                                    <Box px={0.5} component="span" color={'#ababab'}>
                                        |
                                    </Box>
                                </Hidden>
                            )}
                            {formatPhoneNumber(agent.phone)}
                        </Box>
                    </Box>
                </Box>
            </Grid>
            {!disableBrandingLogo && <Grid item>
                <Branding alt={agent.company} src={agent.branding} />
            </Grid>}
        </Grid>
    );
};

AgentInfo.propTypes = {
    agent: PropTypes.object.isRequired,
};

export default React.memo(AgentInfo);
