import React, { useMemo } from 'react';
import Logger from 'js-logger';
import { createContext } from 'react';
import { useActiveBoard } from 'mobx-store';
import { useQuery } from 'react-query';
import { CACHE_KEY } from './constants';
import { benoitApi } from 'apis';
import { LANES } from '../../constants';
import { findIndex } from 'lodash';

const logger = Logger.get('useBoardCards');

export const BoardCardsContext = createContext({});

export function BoardCardsProvider(props) {
    const boardCardsData = useBoardCardsData();

    return (
        <BoardCardsContext.Provider {...props} value={boardCardsData} />
    );
}

// Helpoers

function useBoardCardsData() {
    const DEFAULT_BY_LANE_ID_VALUES = LANES.reduce((result, lane)=> {
        result[lane.id] = [];
        return result;
    }, {});
    const activeBoard = useActiveBoard();
    const boardId = useMemo(()=> activeBoard?.id, [activeBoard]);
    const {
        data:{
            boardCards = [],
            unarchivedBoardCards = [],
            archivedBoardCards = [],
            boardCardsByLaneId = { ...DEFAULT_BY_LANE_ID_VALUES },
            unarchivedBoardCardsByLaneId = { ...DEFAULT_BY_LANE_ID_VALUES },
            archivedBoardCardsByLaneId = { ...DEFAULT_BY_LANE_ID_VALUES },
            boardCardsById = {},
        } = {},
        isLoading,
        ...query
    } = useQuery(
        [CACHE_KEY, boardId],
        ({ queryKey: [, boardId] })=> (benoitApi.getBoardCards(boardId, { include_total_notes: true, include_last_moved_by_id: true })),
        {
            enabled: Boolean(boardId),
            select: (data)=> {
                const boardCards = data.sort((card1, card2)=> card1.index - card2.index);
                const unarchivedBoardCards = data.filter(({ archived })=> !archived);
                const archivedBoardCards = data.filter(({ archived })=> archived);
                const boardCardsByLaneId = data.reduce((result, boardCard)=> {
                    if(!~findIndex(result[boardCard.columnId], { id: boardCard.id })) result[boardCard.columnId].push(boardCard);
                    return result;
                }, { ...DEFAULT_BY_LANE_ID_VALUES });
                const unarchivedBoardCardsByLaneId = data.reduce((result, boardCard)=> {
                    if(!boardCard.archived && !~findIndex(result[boardCard.columnId], { id: boardCard.id })) {
                        result[boardCard.columnId].push(boardCard);
                    }
                    return result;
                }, { ...DEFAULT_BY_LANE_ID_VALUES });
                const archivedBoardCardsByLaneId = data.reduce((result, boardCard)=> {
                    if(boardCard.archived && !~findIndex(result[boardCard.columnId], { id: boardCard.id })) {
                        result[boardCard.columnId].push(boardCard);
                    }
                    return result;
                }, { ...DEFAULT_BY_LANE_ID_VALUES });
                const boardCardsById = data.reduce((result, boardCard)=> {
                    result[boardCard.id] = boardCard;
                    return result;
                }, {});

                return {
                    boardCards,
                    unarchivedBoardCards,
                    archivedBoardCards,
                    boardCardsById,
                    boardCardsByLaneId,
                    unarchivedBoardCardsByLaneId,
                    archivedBoardCardsByLaneId,
                };
            },
        },
    );

    return {
        ...query,
        isLoading: isLoading || !boardId,
        boardCards,
        unarchivedBoardCards,
        archivedBoardCards,
        boardCardsByLaneId,
        unarchivedBoardCardsByLaneId,
        archivedBoardCardsByLaneId,
        boardCardsById,
    };
}
