import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Typography, Grid, CircularProgress } from '@material-ui/core';
import { TextField } from '../../../../components/TextField';
import { useForm, FormContext, useFormContext } from 'react-hook-form';
import { useAccountSettingsStoreContext } from '../../store';
import analytics from '../../../../services/analytics';
import { useStore } from '../../../../mobx-store';
import { useOnboarding } from '../../../../components/Onboarding/store';
import { observer } from 'mobx-react-lite';

const EmailField = observer(function EmailField() {
    const { cobuyerStore } = useAccountSettingsStoreContext();
    const { emailAvailable } = cobuyerStore;
    const { register, errors } = useFormContext();
    return (
        <Box position="relative">
            <TextField name="email" label="Email address"
                required
                labelProps={{
                    fontSize: 16,
                    fontWeight: 'normal'
                }}
                inputProps={{
                    fontSize: 16
                }}
                endAdornment={emailAvailable.isLoading && <CircularProgress size="20px" color="secondary" />}
                inputRef={register({
                    required: true,
                    validate: {
                        duplicateEmail: emailAvailable.reFetch.bind(emailAvailable)
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address',
                    },
                })}
            />
            <span style={{ fontWeight: 'bold', color: 'red' }}>
                {errors.email && errors.email.message}
                {errors.email && errors.email.type === 'duplicateEmail' && 'already taken'}
            </span>
        </Box>
    );
});

export const InviteCollaborator = forwardRef(function InviteCollaborator(props, ref){

    const { UserStore, boardsStore } = useStore();
    const { activeBoard } = boardsStore;

    const formRef = useRef();
    const { cobuyerStore } = useAccountSettingsStoreContext();
    const defaultUserInfo = {
        first_name: '',
        last_name: '',
        email: ''
    };
    const rfhMethods = useForm({
        defaultValues: defaultUserInfo,
        mode: 'onChange'
    });

    const { handleSubmit, register, formState, triggerValidation } = rfhMethods;
    const onboarding = useOnboarding();

    async function handleInviteSubmit(data){
        const inviteSource = cobuyerStore.inviteSource;

        analytics.eventTrack('robin_cobuyer_invite_submit', {
            role: activeBoard.userRoleOnBoard.label,
            board_id: activeBoard.id,
            Source: inviteSource,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
        });

        await cobuyerStore.onInviteSubmit(data);
        onboarding.next();
    }

    // Complete cobuyer onboarding if user accesses invite form
    useEffect(()=> {
        UserStore.completeInviteBuyerOnboarding();
    }, [UserStore]);

    useEffect(()=> {
        cobuyerStore.setIsFormValid(formState.isValid);
    }, [formState.isValid, cobuyerStore]);

    useEffect(()=> {
        triggerValidation();
    }, [triggerValidation]);


    useImperativeHandle(ref, ()=> ({
        submit: ()=> {
            if(formRef.current){
                formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
            }
        }
    }));
    return (
        <Box mt={['24px','30px']} px={[2, 0]} pb={[14, 0]}>
            <Box textAlign="center" fontSize="h5.fontSize">
                Invite a co-buyer
            </Box>
            <Box maxWidth="432px" mx="auto">
                <Box mt={2}>
                    <Typography align="center">Who do you want to collaborate with on your home search?</Typography>
                </Box>
                <FormContext {...rfhMethods}>
                    <Box ref={formRef} component="form" mt={[3, 4]} onSubmit={handleSubmit(handleInviteSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField name="first_name" label="First name"
                                        required
                                        labelProps={{
                                            fontSize: 16,
                                            fontWeight: 'normal'
                                        }}
                                        inputProps={{
                                            fontSize: 16
                                        }}
                                        inputRef={register({
                                            required: true,
                                            validate: (value)=> value.trim().length,
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField name="last_name" label="Last name"
                                        required
                                        labelProps={{
                                            fontSize: 16,
                                            fontWeight: 'normal'
                                        }}
                                        inputProps={{
                                            fontSize: 16
                                        }}
                                        inputRef={register({
                                            required: true,
                                            validate: (value)=> value.trim().length,
                                        })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <EmailField />
                            </Grid>
                        </Grid>
                    </Box>
                </FormContext>
                {cobuyerStore.inviteError && (
                    <Box mt={2}>
                        <Typography align="center" style={{color: 'red'}}>{cobuyerStore.inviteError}</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
});
