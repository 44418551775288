import { Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import LRButton from '../../../components/LRButton';
import { AddHomeIcon } from '../../../components/LRIcons';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import Card from '../Card';
import { ListpackLane } from './ListpackLane';
import { useAbility } from '../../../components/Ability';
import { Redirect } from 'react-router-dom';
import { VerificationBanner } from '../../../components/VerificationBanner';
import { benoitApi } from 'apis';
import { useBoardCards } from 'hooks/useBoardCards';

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const Container = styled.div`
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    text-align: center;
    opacity: 1;
    border: 1px dashed #BBBBBB;
    line-height: 1.5;
    color: #A1A1A1;
    width: 100%;
    margin: 0 auto;
    border-radius: 4px
    font-size: 16px;
`;


function MobileLaneView(props) {
    const match = useRouteMatch();
    const history = useHistory();
    const { LanesStore, boardsStore, UserStore } = useStore();
    const { user } = UserStore;
    const paramId = match.params.laneId;
    const lane = _.find(LanesStore.lanes, { id: paramId });
    const { activeBoard } = boardsStore;
    const containerRef = useRef();
    const ability = useAbility();
    const canEditBoardCards = ability.can('edit', 'BoardCard');
    const isXs = useResponsiveBreakpoint('xs');
    const {
        getUnarchivedBoardCardsByLaneId,
    } = useBoardCards();
    const archivedListings = activeBoard?.archivedListings;

    async function handleOnVerifyClick() {
        const newToken = await benoitApi.requestLogin('phone', user?.phone);
        history.push(`/boards/verify?login_token=${newToken.token}`);
    }

    function handleOnSignupClick() {
        history.push(`/boards/signup?invite_id=${user?.meta?.boardUserInviteId}`);
    }

    useEffect(()=> {
        if(!containerRef || !containerRef.current) return;
        containerRef.current.scrollTo({top: 0});
    }, [match]);

    let listings;

    if(lane) {
        listings = getUnarchivedBoardCardsByLaneId(lane.id);
    }

    function onNewSearchClick(){
        history.push('/boards/lane/0/your-searches?focus=true');
    }

    if(paramId > 0) {
        return <Redirect
            to={`/boards/lane-expanded/${lane.id}/${lane.name}`}
        />;
    }

    return <Box ref={containerRef} >

        {isXs && UserStore.isBannerVisible && <VerificationBanner source="board" onVerifyClick={handleOnVerifyClick}  onSignupClick={handleOnSignupClick}/>}

        {
            Number(paramId) === 0 && (
                <ListpackLane />
            )
        }

        {
            Number(paramId) > 0 && activeBoard && !activeBoard.loadingBoardData && (
                <Box width={1} height={1} overflow="auto" p={3}>
                    <Grid container spacing={2}>
                        {listings.map((listing)=> (
                            <Grid item xs={12} md={6}>
                                <StyledLink 
                                    key={listing.id}
                                    className="tour-target-lane-card-link"
                                    to={`/boards/cards/${listing.id}/${_.kebabCase(listing.listing_details.address)}?source=mobile-lane-view`}
                                    style={{ display: 'block' }}
                                >
                                    <div key={listing.id}>
                                        <Card
                                            boardCard={listing}
                                            variant={lane.id === '3' ? 'makeAnOffer' : 'expanded'}
                                            canMove={canEditBoardCards}
                                        />
                                    </div>
                                </StyledLink>
                            </Grid>
                        ))} 
                    </Grid>
                </Box>
            ) 
        }

        {
            Number(paramId) > 0 && listings.length === 0 && (
                <Box width={1} height={1} p={6}>

                    <Container>

                        <Box fontSize="body1.fontSize" p={3}>
                            <AddHomeIcon fontSize="large" />
                            <Box mt={2}>
                                You don’t have any homes in this column yet. 
                                <br /><br />
                                    Move a few over from a saved search, or start a new search!
                            </Box>
                            <Box mt={3}>
                                <LRButton
                                    onClick={onNewSearchClick}
                                    color="primary"
                                    variant="contained"
                                >
                                    New search
                                </LRButton>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            )
        }

        {
            Number(paramId) === -1 && activeBoard && archivedListings?.length > 0 && (
                <Box width={1} height={1} overflow="auto" p={3}>
                    <Grid container spacing={2}>
                        {archivedListings.map((listing)=> (
                            <Grid item xs={12} md={6}>
                                <StyledLink
                                    key={listing.id}
                                    to={`/boards/cards/${listing.id}/${_.kebabCase(listing.listing_details.address)}?source=mobile-lane-view`}
                                >
                                    <div key={listing.id}>
                                        <Card
                                            boardCard={listing} 
                                            variant={'board'}
                                            disableArchiveButton={true}
                                            canMove={canEditBoardCards}
                                        />
                                    </div>
                                </StyledLink>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )
        }

        { Number(paramId) === -1 && !archivedListings?.length && (
            <Box width={1} height={1} p={6}>
                <Container>
                    <Box fontSize="body1.fontSize" p={3}>
                        <Box mt={2}>
                            You don’t have any homes in this column yet.
                        </Box>
                    </Box>
                </Container>
            </Box>
        )}
    </Box>;
}

export default observer(MobileLaneView);
