import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function WindowIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M20 2H4C2.9 2 2 2.9 2 4V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V4C22 2.9 21.1 2 20 2ZM20 11H13V4H20V11ZM11 4V11H4V4H11ZM4 13H11V20H4V13ZM13 20V13H20V20H13Z" />
        </SvgIcon>
    );
}
