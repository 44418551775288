import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useStore } from '../../mobx-store';
import { useHistory, useParams  } from 'react-router-dom';
import { useSignupStore, SignupStoreProvider, useSignupStoreContext } from '../SignUp/store';
import { Box, CircularProgress, useTheme, Typography } from '@material-ui/core';
import { AutoInfo } from '../../components/AuthInfo';
import LRLink from '../../components/LRLink';
import analytics from '../../services/analytics';
import { AUTH_ONBOARDING_VIDEO } from '../../constants/routes';
import { observer } from 'mobx-react-lite';
import LRButton from '../../components/LRButton';
import { useQuery } from '../../hooks/use-query';
import { Form } from './Form';
import { withTheme } from '@material-ui/styles';
import styled from 'styled-components';
import { SIGNUP_LOGIN_VARIANT_PAGE, SIGNUP_LOGIN_VARIANT_WIDGET } from '../../constants';
import { LogoRIcon } from 'components/LRIcons/logoR';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { LRInputOutlined } from '../../components/LRInput';
import { Swiper } from '../../components/Swiper';
import { useResponsiveBreakpoint } from '../../hooks';
import { useHomeReportSlides } from 'hooks/useHomeReportSlides';

export function SignupHeader({
    title,
    showLoginInSignup = true,
    onSwitchToLoginClick,
}) {
    const { isIPadDown } = useDeviceBreakpoints();

    return (
        <>
            <Box mt={(isIPadDown) ? 0 : 5} fontSize={32} fontWeight={500} textAlign="start">
                {title}
            </Box>
            {showLoginInSignup && (
                <Box mt={5 / 4} textAlign="start">
                    Already have an account? <LRLink to={!onSwitchToLoginClick && '/login'} onClick={onSwitchToLoginClick}>Sign in.</LRLink>
                </Box>
            )}
        </>
    );
}

const StyledSubmitButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
        color: ${({ theme })=> theme.palette.lr_colors.grey_dark};
    }
`);

export const Verification = observer(function Verification({ onLoginSuccess, method='phone' }) {
    const theme = useTheme();
    const signupStore = useSignupStoreContext();
    const [code, setCode]  = useState('');

    const wrongCode = signupStore.activateDeviceRequest.hasError;
    const submitting = signupStore.activateDeviceRequest.isLoading;
    const disabledResend = signupStore.sendCodeRequest.isLoading;
    const userInfo = signupStore.userInfo;
    const titleMessageByMethod = {
        phone: 'Verify your mobile phone number',
        email: 'Verify your email address',
    };
    const subtitleMessageByMethod = {
        phone: `We just sent you a 4-digit verification code via text message to ${userInfo.phone}. Please enter it below.`,
        email: `We just sent you a 4-digit verification code via email to ${userInfo.email}. Please enter it below.`
    };
    const [verificationSuccess, setVerificationSuccess] = useState(false);

    async function submitCode() {
        analytics.eventTrack('robin_verify_click', {
            consumer_name: `${signupStore.userInfo.first_name} ${signupStore.userInfo.last_name}`,
            consumer_email: signupStore.userInfo.email,
            consumer_phone: signupStore.userInfo.phone,
            Source: signupStore.externalAuth?.method || (signupStore.isSignupByEmail && 'email') || (signupStore.isSignupByPhone && 'mobile'),
        });

        await signupStore.activateDevice(code);

        if(signupStore.activateDeviceRequest.hasLoaded) {
            const loggedinUser = signupStore.activateDeviceRequest.data;
            setVerificationSuccess(true);
            onLoginSuccess(loggedinUser);
        } else if(signupStore.activateDeviceRequest.hasError) {
            setCode('');
        }
    }

    function resendCode() {
        signupStore.sendCode(method);
    }

    function onEnterClick(event) {
        event.preventDefault();
        if(code?.length >= 4 && !submitting) {
            submitCode();
        }
    }

    function setCodeValue(event){
        if(event.target.value.match(/^[0-9]*$/i)) {
            setCode(event.target.value);
        }
    }

    return (
        <>
            <Box mx={2}>
                <Box mt={5} fontSize={32} fontWeight={500} lineHeight="40px" textAlign="center">
                    {wrongCode ? 'Hrm, that’s not right.' : titleMessageByMethod[method]}
                </Box>
                <Box mt={2} textAlign="center">
                    {wrongCode
                        ? 'The 4-digit code that you entered is incorrect. Please try entering it again below.'
                        : subtitleMessageByMethod[method]}
                </Box>
            </Box>
            <form
                style={{
                    width: '100%',
                    display: 'block',
                    padding: `0 ${theme.spacing(2)}px`
                }}
                onSubmit={onEnterClick}
            >
                <Box mt={3}>
                    <LRInputOutlined
                        name='code'
                        value={code}
                        onChange= {setCodeValue}
                        required={true}
                        type="text"
                        inputProps={{
                            height: '48px',
                            fontSize: theme.typography.body1.fontSize,
                            borderRadius: '4px',
                            textAlign: 'center',
                            maxLength: 4,
                            width: '100%',
                            autoFocus: true,
                        }}
                    />
                </Box>
                <Box mt={7 / 2}>
                    <StyledSubmitButton
                        disabled={
                            code?.length < 4 || submitting || verificationSuccess
                        }
                        type="submit"
                        value="Submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            height: '48px',
                            fontWeight: 'normal',
                        }}
                    >
                        {!verificationSuccess && !submitting && 'Verify'}
                        {(verificationSuccess || submitting) && <CircularProgress size={24} color="secondary" />}
                    </StyledSubmitButton>
                </Box>
            </form>
            <Box mt={2}>
                <LRButton
                    color="secondary"
                    fullWidth
                    disabled={disabledResend}
                    style={{
                        height: '48px',
                        fontWeight: 'normal',
                    }}
                    onClick={()=> resendCode(userInfo.phone)}
                >
                    Re-send verification code
                </LRButton>
            </Box>
        </>
    );
});

export const HomeReportSignUp = observer(function SignUpV2({
    variant = SIGNUP_LOGIN_VARIANT_PAGE, // page or widget
    onSignUpSuccess = ()=> {},
    onSwitchToLoginClick,
    inputEmail = null,
}) {
    const theme = useTheme();
    const history = useHistory();
    const { isIPadDown } = useDeviceBreakpoints();
    const { UserStore, LoginSignupModalStore } = useStore();
    const signupStore = useSignupStore();
    const { affiliateCode:affiliateCodeParam } = useParams();
    const affiliateCodeLSMStore = LoginSignupModalStore.affiliateCode;
    const affiliateCode = useMemo(()=> {
        return affiliateCodeLSMStore || affiliateCodeParam;
    }, [affiliateCodeLSMStore, affiliateCodeParam]);
    const query = useQuery();
    const queryEmail = query.get('email');
    const inviteIdRef = useRef(query.get('inviteId'));
    const { affiliate } = signupStore;
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const variantProps = {
        [SIGNUP_LOGIN_VARIANT_PAGE]: {
            width: ['100%', '100%', '25%'],
            minWidth: ['initial', 'initial', 488],
            display: 'flex',
            flexDirection: 'column',
            paddingTop: isMobile ? '24px' : '64px',
            paddingBottom: isMobile ? '24px' : '64px',
            paddingLeft: isMobile ? '16px' : '64px',
            paddingRight: isMobile ? '16px' : '64px',
            overflow: affiliate && 'auto',
        },
        [SIGNUP_LOGIN_VARIANT_WIDGET]: {
            width: '100%',
        }
    };
    const {selectedPhotoIndex, setSelectedPhotoIndex, currentSlide, slideContents} = useHomeReportSlides();

    async function handleLoginSuccess(loggedinUser) {

        //generate event as signed up
        analytics.eventTrack('robin_home_report_signup_page_signed_up', {
            distinct_id: affiliate.id,
            lead_email: loggedinUser.email
        });

        if(signupStore.isSignupByPhone) {
            analytics.eventTrack('robin_mobile_verified', {
                consumer_name: loggedinUser.name,
                consumer_email: loggedinUser.email,
                consumer_phone: loggedinUser.phone,
                Source: signupStore.externalAuth?.method || 'mobile'
            });
        }

        UserStore.storeToken(loggedinUser.token);
        await UserStore.fetchLocalUser();
        await UserStore.fetchUserData();

        if(variant === SIGNUP_LOGIN_VARIANT_PAGE) history.push('/boards/home-report');

        // If not email signup mark email as verified
        if(!signupStore.isSignupByEmail) {
            UserStore.updateUser({ verified: { email: true } });
        }

        onSignUpSuccess();
    }

    useEffect(()=> {
        if(signupStore.skipVerification) {
            handleLoginSuccess(signupStore.userInfo);
        }
    }, [signupStore.skipVerification]);

    useEffect(()=> {
        if(inviteIdRef.current) {
            signupStore.fetchInvite(inviteIdRef.current);
        } else {
            signupStore.goToMenu();
        }
    }, [signupStore]);

    useEffect(()=> {
        if((affiliateCode && affiliateCode !== AUTH_ONBOARDING_VIDEO)) {
            signupStore.affiliateRequest.fetch(affiliateCode);
        }
    }, [signupStore, affiliateCode]);

    useEffect(()=> {
        if(affiliateCode && affiliate && affiliateCode !== AUTH_ONBOARDING_VIDEO) {
            analytics.eventTrack('robin_home_report_sign_up_page_viewed', {
                distinct_id: affiliate.id,
                lead_email: queryEmail
            });
        }
    }, [affiliateCode, affiliate, queryEmail]);

    return (
        <>
            <SignupStoreProvider signupStore={signupStore}>
                <Box

                    display='flex'
                    flexDirection={['column', 'column', 'row']}
                    minHeight={1}
                >
                    {false && <AutoInfo trackSource="signup" />}

                    <Box
                        display='flex'
                        {...variantProps[variant]}
                    >
                        {(signupStore.inSignupMenu || signupStore.inOrganicSignup || signupStore.skipVerification) && (
                            <>
                                <div style={{width: '107px', height: '32px', marginBottom: theme.spacing(isIPadDown ? 1 : 0 )}}>
                                    <LogoRIcon style={{width: '100%', height: '100%'}} />
                                </div>
                                <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                                    <SignupHeader
                                        title="Create an account to manage your home report"
                                        onSwitchToLoginClick={onSwitchToLoginClick}
                                    />
                                    <Form defaultEmail={inputEmail || queryEmail} />
                                </Box>
                            </>
                        )}
                        {signupStore.inVerification && <Verification onLoginSuccess={handleLoginSuccess}  method={signupStore.signUpMethodForVerification}/>}
                        {signupStore.isInitialState && <CircularProgress size={30} color="secondary" />}
                    </Box>
                    <Box
                        style={{background: currentSlide.background}}
                        width={1}
                        overflow="hidden"
                        paddingY={[4.5, 4.5, 0]}
                        flex={1}
                    >
                        <Swiper
                            data={slideContents}
                            slidesPerView={1}
                            pagination={true}
                            variant={currentSlide.variant}
                            initialSlide={0}
                            activeIndex={selectedPhotoIndex}
                            dynamicBullets={false}
                            loop
                            autoplay={{delay: 9000}}
                            clickable
                            onSlideChange={setSelectedPhotoIndex}
                            renderSlide={(slide)=> {
                                return (
                                    <Box height={1}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            height={1}
                                            maxWidth="100%"
                                            paddingX={[3, 0]}
                                        >
                                            <div>
                                                <img src={slide.photo} alt="" style={{ width: '100%' }}/>
                                            </div>
                                            {
                                                slide.title && slide.text && (
                                                    <div
                                                        style={{
                                                            maxWidth: isIPadDown ? '100%' : '400px',
                                                            ...(slide.textColor ? { color: slide.textColor } : {}),
                                                        }}
                                                    >
                                                        <Typography variant="h4">{ slide.title }</Typography>
                                                        <Typography variant="h6"
                                                            style={{
                                                                marginBottom: theme.spacing(2),
                                                            }}
                                                        >
                                                            { slide.text }
                                                        </Typography>
                                                    </div>
                                                )
                                            }

                                        </Box>
                                    </Box>
                                );
                            }}
                        />
                    </Box>
                </Box>
            </SignupStoreProvider>
        </>
    );
});
