import React, { useState } from 'react';
import { Box, Divider, CircularProgress, Snackbar, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { DEFAULT_HEADSHOT, COLOR_GREY_DARK } from '../../constants';
import { TextField } from '../TextField';
import LRButton from '../LRButton';
import { useForm } from 'react-hook-form';
import { portalApi } from '../../apis';
import { observer } from 'mobx-react-lite';
import formatPhoneNumber from '../../services/format-phone-number';
import { withTheme } from '@material-ui/core/styles';
import { LRPhoneInput } from '../LRPhoneInput';

const CustomLRButton = withTheme(styled(LRButton)`
    width: 100%;
    height: 48px;

    &.Mui-disabled {
        background-color: ${({ theme, color })=> theme.palette[color].main};
        color: ${({ theme, color })=> theme.palette[color].contrastText};
        opacity: 0.5;
    }
`);

const UserAvatar = styled(Box)`
    background-image: url("${(props)=> (props.headshot ? props.headshot : DEFAULT_HEADSHOT)}");
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    width: ${(props)=> (props.headshotSize === 'small' ? '64px' : '80px')};
    height: ${(props)=> (props.headshotSize === 'small' ? '64px' : '80px')};
`;

export const AgentInfo = function({ variant = 'desktop', user, source }) {
    const mobileVariant = variant.startsWith('mobile');
    const mapPageVariant = variant.endsWith('map_page');
    const isAgent = user.type === 'agent';

    return (
        <Box
            display="flex"
            flexDirection={mobileVariant && !mapPageVariant ? 'row-reverse' : 'column'}
            alignItems={mobileVariant ? '' : 'center'}
            justifyContent={mobileVariant ? 'space-between' : ''}
            textAlign={mobileVariant ? '' : 'center'}
        >
            <Box
                display="flex"
                alignItems={mobileVariant ? 'flex-start' : 'center'}
                justifyContent={mapPageVariant ? 'flex-start' : 'center'}
                mb={mobileVariant ? 0 : '22px'}
            >
                <UserAvatar
                    headshot={user.headshot}
                    headshotSize={mobileVariant || mapPageVariant ? 'small' : 'normal'}
                    source={source}
                />
                {mapPageVariant ? (
                    <Box ml="9px" textAlign="left">
                        <Box fontWeight="500" fontSize="18px">
                            {user.name}
                        </Box>
                        <Box fontSize="16px">{formatPhoneNumber(user.phone)}</Box>
                    </Box>
                ) : null}
            </Box>
            {mapPageVariant ? (
                <Box fontSize="18px" mt={mobileVariant ? '20px' : 0} textAlign="left">
                    Curious about whether you can score a lower mortage rate? Shoot me a message using the form
                    below—I’m here to help!
                </Box>
            ) : (
                <Box>
                    <Box fontWeight="500" fontSize="18px">
                        {user.name}
                    </Box>
                    <Box fontSize="16px">{user.company}</Box>
                    <Box fontSize="12px" color={COLOR_GREY_DARK}>
                        {isAgent ? 'License' : 'NMLS'} #{user.realtor_license_number}
                    </Box>
                    <Box my="12px" maxWidth="24px" mx={mobileVariant ? '' : 'auto'}>
                        <Divider style={{ height: '2px' }} />
                    </Box>
                    <Box fontSize="16px">{formatPhoneNumber(user.phone)}</Box>
                    <Box fontSize="16px">{user.email}</Box>
                </Box>
            )}
        </Box>
    );
};

export const LeadForm = observer(function({
    user,
    loadingUser,
    loadedUser,
    variant = 'desktop',
    source,
    formHeader,
    onLeadCreated,
    leadSource,
    submitText,
    address,
}) {
    const rfhMethods = useForm({
        defaultValues: {
            message: address,
        },
    });
    const { register, handleSubmit, errors, formState } = rfhMethods;
    const [submittingLead, setSubmittingLead] = useState(false);
    const [submittedLead, setSubmittedLead] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const branding = user.branding || user.vendor?.logo;

    const submitLead = async (data)=> {
        const leadData = {
            applicant: {
                name: data.name,
                email: data.email,
                ...(data.phone && { phone: data.phone }),
            },
            ownerId: user.id,
            source: leadSource,
        };

        const leadResponse = await portalApi.createLead(user, leadData);
        const lead = leadResponse.data;

        if(onLeadCreated) {
            onLeadCreated(lead, data.message);
        }

        return lead;
    };

    async function onLeadSubmit(data) {
        if(!loadedUser || loadingUser) return;
        setSubmittingLead(true);
        data.phone = (data.phone || '').replace(/[^0-9]/g, '');

        try {
            await submitLead(data);
            setShowSuccessSnackbar(true);
            setSubmittedLead(true);
        } catch (error) {
            setShowErrorSnackbar(true);
        } finally {
            setSubmittingLead(false);
        }
    }

    // Variant variables
    const mobileVariant = variant.startsWith('mobile');
    const mapPageVariant = variant.endsWith('map_page');

    if(loadingUser || !loadedUser) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                width={mobileVariant ? '100%' : '298px'}
            >
                <CircularProgress color="secondary" />
            </Box>
        );
    }

    return (
        <Box width="100%">
            <Snackbar
                open={showSuccessSnackbar || showErrorSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={
                    <Box width="330px" textAlign="center">
                        {showSuccessSnackbar && 'Message sent!'}
                        {showErrorSnackbar && 'An error occurred. Please try again later.'}
                    </Box>
                }
                autoHideDuration={2000}
                onClose={()=> {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
            />
            <form onSubmit={handleSubmit((data)=> onLeadSubmit(data))} noValidate>
                <Box display="flex" flexDirection={!mapPageVariant && mobileVariant ? 'column-reverse' : 'column'}>
                    {branding && mobileVariant && (
                        <Box mt="30px">
                            <Box maxHeight="80px" maxWidth="300px" component="img" src={branding} alt="user_branding" />
                        </Box>
                    )}
                    <Box
                        mb={mobileVariant || mapPageVariant ? 0 : '31px'}
                        mt={mobileVariant && !mapPageVariant ? '31px' : 0}
                        width="100%"
                    >
                        <AgentInfo user={user} variant={variant} source={source} />
                    </Box>
                    {branding && !mobileVariant && (
                        <Box textAlign="center" mb="30px">
                            <Box maxHeight="80px" maxWidth="300px" component="img" src={branding} alt="user_branding" />
                        </Box>
                    )}
                    {!submittedLead && (<Box>
                        {source !== 'map_page' && !formHeader && (
                            <Box fontSize="20px" fontWeight="500">
                                <Box>Got questions?</Box>
                                <Box>
                                    {user.first_name} can answer them.{' '}
                                    <span role="img" aria-label="point_down">
                                        &#128071;
                                    </span>
                                </Box>
                            </Box>
                        )}
                        {formHeader}
                        <Box mt={3}>
                            <TextField
                                name="name"
                                label="Name"
                                inputProps={{ height: '48px', width: '100%' }}
                                inputRef={register({
                                    required: true,
                                    validate: (value)=> value.split(' ').length >= 2,
                                })}
                                message={errors.name && 'Please provide a first and last name.'}
                                tone={errors.name && 'error'}
                                required
                            />
                            <Box mt="22px">
                                <TextField
                                    name="email"
                                    label="Email"
                                    type="email"
                                    inputProps={{ height: '48px', width: '100%' }}
                                    inputRef={register({
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address',
                                        },
                                    })}
                                    message={errors.email && 'Please provide a valid email.'}
                                    tone={errors.email && 'error'}
                                    required
                                />
                            </Box>
                            <Box mt="22px">
                                <LRPhoneInput rfhMethods={rfhMethods} label="Phone" name="phone" />
                            </Box>
                            <Box mt="22px">
                                <TextField
                                    name="message"
                                    label="Message"
                                    maxLength="1000"
                                    inputProps={{
                                        height: '96px',
                                        width: '100%',
                                        component: 'textarea',
                                        padding: '12px',
                                        maxLength: '1000',
                                        style: { resize: 'none', outline: 'none' },
                                    }}
                                    inputRef={register}
                                />
                            </Box>
                        </Box>
                        <Box mt="32px">
                            <CustomLRButton
                                type="submit"
                                disabled={
                                    submittingLead ||
                                    !formState.dirtyFields.has('name') ||
                                    !formState.dirtyFields.has('email')
                                }
                                style={{ width: '100%', height: '48px' }}
                                variant="contained"
                                color="primary"
                            >
                                {!submittingLead && submitText}
                                {!submitText && !submittingLead && <Box>Submit</Box>}
                                {submittingLead && <CircularProgress size={24} color="secondary" />}
                            </CustomLRButton>
                        </Box>
                    </Box>)}

                    {submittedLead && (
                        <Box mt="32px"
                            fontSize="20px"
                            fontWeight="500"
                            letterSpacing={0}
                            fontStyle="normal"
                            textAlign="center"
                        >
                            Thanks! Your information has been submitted.
                        </Box>
                    )}
                </Box>
            </form>
        </Box>
    );
});
