import { Box, DialogContent, Divider, Grid, Hidden, withTheme, useTheme } from '@material-ui/core';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import MortgageCalculator from '../../components/MortgageCalculator';
import PinspirationOverlay from '../../components/Pinspiration/PinspirationOverlay';
import { useResponsive, useResponsiveBreakpoint } from '../../hooks';
import { useStore } from '../../mobx-store';
import analytics from '../../services/analytics';
import { listingImage } from '../../services/listing-helpers';
import splitAddress from '../../services/split-address';
import { CardBoardCollaborations } from '../CardBoardCollaborations';
import ListingImage from '../ListingImage';
import { Swiper } from '../Swiper';
import AreaReport from './AreaReport';
import Description from './Description';
import Footer from './Footer';
import Header from './Header';
import ListingAgent from './ListingAgent';
import PropertyDetails from './PropertyDetails';
import PropertyInfo from './PropertyInfo';
import Schools from './Schools';
import SectionTitle from './SectionTitle';
import TermsAndConditions from './TermsAndConditions';
import ViewImagesButton from './ViewImagesButton';
import VirtualAndOpenHouses from './VirtualAndOpenHouses';
import YourAgent from './YourAgent';
import { useOnboarding } from '../Onboarding/store';
import ThumbnailListingImages from './ThumbnailListingImages';
import { StatusBadge, Badge } from '../../scenes/Board/StatusBadge';
import { ListingExplorerModal } from '../ListingExplorerModal';
import SchedulePrivateTourModal from '../SchedulePrivateTourModal';
import SubmitOfferModal from '../../scenes/SubmitOfferModal';
import { SignUpModal } from '../../components/VerificationBanner/SignUpModal';
import { VerifyModal } from '../../components/VerificationBanner/VerifyModal';
import { benoitApi } from '../../apis';
import { CardDeleteModal } from '../../components/CardDeleteModal';
import { useBoardCollaborations } from '../../hooks/useBoardCollaborations';
import Logger from 'js-logger';
import { ListpackScheduleTourConfirmationModal } from '../ListpackScheduleTourConfirmationModal';
import { SOLD_STATUSES, PENDING_STATUSES } from '../../constants';
import { useListingMLS } from '../../hooks/data/listing/useListingMLS';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';
import { SlidyAgentInfo } from './Header/SlidyAgentInfo';
import { useIsElementVisible } from 'hooks/useIsElementVisible';

const StyledDialogContent = withTheme(styled(DialogContent)`
    padding: 24px;
    line-height: 1.5;
    overflow-y: auto;
    ${({ hasHeader }) => hasHeader && 'padding-top: 78px;'}
    ${({ theme }) => theme.breakpoints.only('xs')} {
        padding: 24px 16px;
        ${({ hasHeader }) => hasHeader && 'padding-top: 88px;'}
    }
`);

const StyledSectionTitle = styled(SectionTitle)`
    margin: 0;
    line-height: 1;
`;

const StyledDivider = styled(Divider)`
    margin-top: 44px;
    margin-bottom: 24px;
`;

const RPPStatusBadge = ({ isRpp, laneIdBadge, listpack, listingsAreNotCards, listing_details, isListpackCard }) => {
    if (listpack && listpack.listings_type === 'static') {
        return <Badge status={listing_details.listing_status} />;
    } else {
        return (
            <StatusBadge
                isRpp={isRpp}
                laneIdBadge={laneIdBadge}
                listingDetails={listing_details}
                isListpackCard={isListpackCard}
            />
        );
    }
};

const StyledViewImagesButton = styled(ViewImagesButton)`
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: absolute;
    right: 40px;
    bottom: 34px;
    z-index: 3;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    .MuiButton-label {
        svg {
            width: 18px;
            height: 21px;
            margin-right: 8px;
        }
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }
`;
const InfoAndMatchScoreWrapper = withTheme(styled(Box)`
    position: relative;
    background-color: #fff;
    box-shadow: 0;
    ${({ theme }) => theme.breakpoints.up('sm')} {
        box-shadow: rgba(0, 0, 0, 0.02) 0px 5px 2px -1px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    }
`);

let ListingDetails = function ListingDetails({
    listingsAreNotCards = false,
    listings,
    buyerAgent,
    startIndex = 0,
    onArchive,
    onMoveToChange,
    embeded = false,
    hideHeader = false,
    onContactAgentClick: parentOnContactAgentClick,
    requestSource = 'consumer_view',
    onClose,
    onShare,
    showCollaborations = true,
    headerProps = {},
    overridingProps = {},
    fromRobin = false,
    isListpackCard,
    handleOnScheduleListpackCardTour,
    reportId,
    showAgentBanner = false,
    showAgentLoInfo = false,
}) {
    const r = useResponsive();
    const theme = useTheme();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [index, setIndex] = useState(startIndex);
    const [showGallery, setShowGallery] = useState(false);
    const defaultSelectedPhotoIndex = 0;
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(defaultSelectedPhotoIndex);
    const listing = useMemo(() => listings[index], [index, listings]);
    const isXs = useResponsiveBreakpoint('xs');
    const mortageCalculatorRef = useRef(null);
    const { RootStore, ListpackStore, PinspirationStore, UserStore, GlobalSnackbarStore, boardsStore, LanesStore } = useStore();
    const prodMode = RootStore.inProductionMode;
    const { lanesById } = LanesStore;
    const { activeBoard } = boardsStore;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const [expandDescription, setExpandDescription] = useState(false);
    const [expandDetails, setExpandDetails] = useState(false);
    const [expandCalculations, setExpandCalculations] = useState(false);
    const [schedulePrivateTourModalOpen, setSchedulePrivateTourModalOpen] = useState(false);
    const [pageViewTracked, setPageViewTracked] = useState(false);
    const [openScheduleConfirmationModal, setOpenScheduleConfirmationModal] = useState(false);
    const [enabledAreaReport, setEnabledAreaReport] = useState(false);
    const [areaReportElement, setAreaReportElement] = useState(null);
    const [scrollableElement, setScrollableElement] = useState(null);
    const isAreaElementVisible = useIsElementVisible(areaReportElement, scrollableElement);
    const [loadingCardCreation, setLoadingCardCreation] = useState(false);
    const { order, sortId, sortOrder } = queryString.parse(location.search);
    const { listing_details = {} } = listing || {};
    const { id: listingId } = listing_details || {};
    const boardId = PinspirationStore.currentBoardId;
    const usersBoardRole = UserStore.usersBoardRole;
    const { listpack, agent } = ListpackStore;
    const dialogContentRef = useRef(null);
    const laneIdBadge = listing?.listing_details?.linkedBoardCard?.laneId;
    const onboarding = useOnboarding();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const user = UserStore.user;
    const { affiliatedMLS } = UserStore;
    const trackUser = UserStore.isAgent ? user?.affiliate : UserStore.isLO ? user?.affiliate?.preferred_vendor : user;
    const { addBoardCollaboration } = useBoardCollaborations();
    const listingTaxes = useMemo(() => {
        if (!listing_details || !listing_details.id) {
            return null;
        }
        const { taxes } = listing_details;
        if (taxes && typeof taxes === 'object') {
            const ammount = parseInt(taxes.amount) || 0;
            if (_.includes(_.keys(taxes), 'year')) {
                return _.round(ammount / 12);
            } else {
                return ammount;
            }
        } else if (typeof taxes === 'string') {
            return parseInt(taxes) || 0;
        }
    }, [listing_details]);

    //We hide the footer since when embeded in property search, it goes to mobile design
    const hideFooter = !!~['property_search', 'my_home'].indexOf(requestSource);

    function trackRPPEvent(eventName, props) {
        const defaultEventInfo = {
            card_id: listing.id,
        };

        const eventInfo = props ? { ...defaultEventInfo, ...props } : defaultEventInfo;

        analytics.eventTrack(eventName, eventInfo);
    }

    useEffect(() => {
        if (embeded || hideHeader) {
            return;
        }

        if (UserStore.isConsumer && activeBoard) {
            activeBoard.incrementBoardMetric('listings_viewed');
        }
    }, [embeded, hideHeader, UserStore, activeBoard, index]);

    // Hack to allow the parent to control listing index;
    useEffect(() => {
        setIndex(startIndex);
    }, [startIndex]);

    useEffect(() => {
        if (!listing_details || !agent) return;

        if (!pageViewTracked) {
            analytics.eventTrack('Page Viewed', {
                address: listing_details.address,
                'listing id': listing_details.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
            });

            analytics.eventTrack('listpacks_consumer_listing_page_viewed', {
                address: listing_details.address,
                'listing id': listing_details.id,
                listpack_id: listpack?.id,
                source: requestSource,
                role: usersBoardRole,
                report_id: reportId,
            });
            setPageViewTracked(true);
        }
    }, [agent, listpack, listing_details, requestSource, usersBoardRole, pageViewTracked, reportId]);

    useEffect(() => {
        setExpandDescription(false);
        setExpandDetails(false);
        setExpandCalculations(false);
        setSelectedPhotoIndex(defaultSelectedPhotoIndex);
    }, [listings, index]);

    useEffect(() => {
        if (listings[index]?.listing_details && listings[index].listing_details.checkImages) {
            listings[index].listing_details.checkImages();
        }
    }, [listings, index]);

    useEffect(() => {
        async function fetchPinspirationCollaborations() {
            await PinspirationStore.getCollaborationsByListingId(boardId, listingId);
        }
        if (listingId && boardId) {
            fetchPinspirationCollaborations();
        }
    }, [listingId, boardId, PinspirationStore]);

    useEffect(() => {
        if(!listing) {
            setEnabledAreaReport(prodMode);
        }
    }, [listing, prodMode]);

    useEffect(() => {
        setEnabledAreaReport((currentEnabledAreaReport) => {
            if(!currentEnabledAreaReport) {
                return isAreaElementVisible;
            }
        });
    }, [isAreaElementVisible]);

    useEffect(() => {
        if(!scrollableElement) return;
        scrollableElement.scrollTo({ top: 0 });
    }, [listing?.id, scrollableElement]);

    const { data: listingMls } = useListingMLS(listing?.listing_details);

    const showAgentBranding = useMemo(() => {
        if (!listing?.listing_details || !listingMls) return false;

        const mls = _.get(listingMls, '[0]', null);
        if (listing.listing_details?.listing_source === 'listhub' || (mls && !mls.idx_rules_enforced)) return true;

        if (!mls) return false;

        return affiliatedMLS.includes(mls.source_code_name);
    }, [listing, listingMls, affiliatedMLS]);

    const brandingAgent = useMemo(() => {
        if ((showAgentBranding && buyerAgent) || !fromRobin) {
            return buyerAgent;
        }
        return null;
    }, [showAgentBranding, buyerAgent, fromRobin]);

    if (!listing?.listing_details) return null;

    function handleNextListingClick() {
        setIndex((index + 1) % listings.length);
        setSelectedPhotoIndex(defaultSelectedPhotoIndex);
        trackRPPEvent('rpp_next_button_click');
    }

    function handlePreviousListingClick() {
        let newIndex = index - 1;

        if (newIndex < 0) newIndex = listings.length + newIndex;

        setIndex(newIndex % listings.length);
        setSelectedPhotoIndex(defaultSelectedPhotoIndex);
        trackRPPEvent('rpp_previous_button_click');
    }

    function handleDeleteConfirmation(message) {
        setShowConfirmModal(false);
        const newListings = listings.filter((l) => l.id !== listing.id);
        const newListingsLength = newListings.length;
        const viewedFromMap = requestSource === 'lane-expanded';

        const laneId = match?.params.laneId;
        const listpackId = match?.params.listpackId;
        const lane = lanesById[laneId];
        var url;

        listing.laneId = '-1';
        listing.archive();

        const boardCard = listing?.listing_details?.linkedBoardCard;

        if (message && message.trim().length > 0) {
            // add a board collaboration note
            const comment = {
                model: 'BoardCard',
                itemId: boardCard?.id,
                boardId: activeBoardId,
                content: {
                    message,
                    suppress_notifications: true,
                },
                type: 'comment',
                visibleToAffiliate: true,
                visibleToLO: false,
            };
            addBoardCollaboration(comment);
        }

        // create the board collaboration
        const data = {
            model: 'BoardCard',
            itemId: boardCard?.id,
            boardId: activeBoard.id,
            content: {
                message: message,
                cta: `/boards/archivedListings/${boardCard?.id}`,
            },
            type: 'deleted_card',
        };
        addBoardCollaboration(data);

        let cardInfo = null;
        if (listing?.name?.length > 0) {
            cardInfo = listing.name;
        } else {
            if (listing?.listing_details?.address) {
                const [address1, address2] = splitAddress(listing.listing_details.address);
                cardInfo = address1;
            }
        }

        if (newListingsLength === 0) {
            history.push('/boards');
        } else {
            if (index >= newListingsLength) {
                const newIndex = newListingsLength - 1;
                setIndex(newIndex);
                const previousListing = newListings[newIndex];
                if (!viewedFromMap) {
                    url = `/boards/cards/${previousListing.id}/${_.kebabCase(previousListing.listing_details.address)}`;
                } else {
                    if (laneId) {
                        url = `/boards/lane-expanded/${laneId}/${lane.name}/cards/${previousListing.id}/${_.kebabCase(
                            previousListing.listing_details.address
                        )}?source=lane-expanded&sortId=${sortId}&sortOrder=${sortOrder}`;
                    } else {
                        url = `/boards/listpack/${listpackId}/listings/cards/${previousListing.id}/${_.kebabCase(
                            previousListing.listing_details.address
                        )}?source=lane-expanded&sortId=${sortId}&sortOrder=${sortOrder}`;
                    }
                }
            } else {
                const nextListing = newListings[index];
                if (!viewedFromMap) {
                    url = `/boards/cards/${nextListing.id}/${_.kebabCase(nextListing.listing_details.address)}`;
                } else {
                    if (laneId) {
                        url = `/boards/lane-expanded/${laneId}/${lane.name}/cards/${nextListing.id}/${_.kebabCase(
                            nextListing.listing_details.address
                        )}?source=lane-expanded`;
                    } else {
                        url = `/boards/listpack/${listpackId}/listings/cards/${nextListing.id}/${_.kebabCase(
                            nextListing.listing_details.address
                        )}?source=lane-expanded&sortId=${sortId}&sortOrder=${sortOrder}`;
                    }
                }
            }
        }
        history.push(url);

        GlobalSnackbarStore.show(`${cardInfo} has been moved to your deleted homes`, {
            autoHideDuration: 2000,
        });

        trackRPPEvent('robin_card_archived', {
            source: 'rpp_header',
            boardId: listing.boardId,
            listing_id: listing.content.listing_id,
            address: listing.listing_details.address,
        });

        onArchive();
    }

    function handleOnArchiveClick() {
        if (![...SOLD_STATUSES, ...PENDING_STATUSES].includes(listing.listing_status.toLowerCase())) {
            setShowConfirmModal(true);
        } else {
            handleDeleteConfirmation();
        }
    }

    async function handleOnMoveToChange(toLaneId, isMakeAnOffer = false) {
        const isArchived = listing.archived;

        await onMoveToChange(toLaneId, isMakeAnOffer);

        if (isMakeAnOffer && !isArchived) {
            trackRPPEvent('rpp_header_make_offer_button_click', {
                address: listing?.listing_details?.address,
            });
            history.push({
                pathname: `${match?.url}/submit-offer/${listing.id}`,
                search: location.search,
            });
        } else {
            if (isArchived) {
                trackRPPEvent('robin_card_unarchived', {
                    source: 'rpp_header',
                    boardId: listing.boardId,
                    listing_id: listing.content.listing_id,
                    address: listing.listing_details.address,
                });

                history.push(`/boards/cards/${listing.id}/${listing.listing_details.address}`);
            } else {
                trackRPPEvent('robin_card_created', {
                    source: 'rpp_header',
                    boardId: listing.boardId,
                    listing_id: listing.content.listing_id,
                    address: listing.listing_details.address,
                });
                setIndex(startIndex);
            }
        }
    }

    function onVirtualTourClick() {
        analytics.eventTrack('listpacks_consumer_listingpage_virtual_tour_button_click', {
            'listing id': listing_details.id,
            listpack_id: listpack?.id,
            source: requestSource,
            report_id: reportId,
        });
    }

    function onShowGallery(source, photoIndex) {
        if (!showGallery) {
            analytics.eventTrack('listpacks_consumer_listingpage_header_image_click', {
                address: listing_details.address,
                'listing id': listing_details.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
                source: requestSource,
                report_id: reportId,
            });
            analytics.eventTrack('robin_gallery_viewed', {
                card_id: listing.id,
                source: source,
            });
            if (listing_details.photos.length) {
                const qs = new URLSearchParams(location.search);
                if (photoIndex) {
                    qs.set('index', photoIndex);
                }
                history.push({
                    pathname: `${match?.url}/gallery`,
                    search: qs.toString(),
                });
                if (onboarding) {
                    onboarding.next();
                }
            }
        }
    }

    function onGalleryClose() {
        setShowGallery(false);
    }

    function handleEstMortgageClick() {
        analytics.eventTrack('listpacks_consumer_listingpage_est_mortgage_click', {
            address: listing_details.address,
            'listing id': listing_details.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            source: requestSource,
            report_id: reportId,
        });

        const { offsetTop } = mortageCalculatorRef.current;
        window.scroll(0, offsetTop - 131);
        mortageCalculatorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }

    function redirectToTheEnd(source) {
        if (parentOnContactAgentClick) parentOnContactAgentClick(source);
    }

    function onPrivateTourClick(source) {
        if (!isListpackCard) {
            history.push({
                pathname: '/boards/schedule-a-tour',
                state: {
                    previousPath: history.location.pathname,
                    boardCardId: listing.id,
                    source: 'rpp',
                },
            });

            analytics.eventTrack('listpacks_consumer_listingpage_private_tour_click', {
                card_id: listing.id,
                listingId: listing_details.id,
                Source: source,
                report_id: reportId,
            });
        } else {
            const card = activeBoard.getBoardCardByEncodedId(listing.listing_details.encodedId);
            if (!card) {
                setOpenScheduleConfirmationModal(true);
            } else {
                history.push({
                    pathname: '/boards/schedule-a-tour',
                    state: {
                        previousPath: history.location.pathname,
                        boardCardId: card.id,
                        source: 'rpp',
                    },
                });
            }
        }
    }

    function handle3DTourClick() {
        analytics.eventTrack('robin_gallery_viewed', {
            card_id: listing.id,
            source: 'rpp_virtual_tour_cta',
        });
        const qs = new URLSearchParams(location.search);
        qs.set('tab', '3dTour');
        history.push({
            pathname: `${match?.url}/gallery`,
            search: qs.toString(),
        });
    }

    function handleListingExplorerClose() {
        history.push({
            pathname: `${match?.url}`,
            search: location.search,
        });
    }

    async function handleOnVerifyClick() {
        const newToken = await benoitApi.requestLogin('phone', user.phone);
        history.push(`${match?.url}/verify?login_token=${newToken.token}`);
    }

    function onCancelScheduleClick() {
        setOpenScheduleConfirmationModal(false);
    }

    async function onConfirmScheduleClick() {
        setLoadingCardCreation(true);
        const card = await handleOnScheduleListpackCardTour();
        setOpenScheduleConfirmationModal(false);
        history.push({
            pathname: '/boards/schedule-a-tour',
            state: {
                previousPath: history.location.pathname,
                boardCardId: card.id,
                source: 'rpp',
            },
        });
    }

    return (
        <>
            {openScheduleConfirmationModal && (
                <ListpackScheduleTourConfirmationModal
                    open={openScheduleConfirmationModal}
                    title="Continuing will add the listing to your “Want to See” column."
                    subtitle="Would you like to continue?"
                    onCancelClick={onCancelScheduleClick}
                    onConfirmClick={onConfirmScheduleClick}
                    loading={loadingCardCreation}
                />
            )}
            <div
                ref={setScrollableElement}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingTop: buyerAgent ? theme.spacing(8) : 0,
                }}
            >
                {schedulePrivateTourModalOpen && (
                    <SchedulePrivateTourModal
                        open={schedulePrivateTourModalOpen}
                        onClose={() => setSchedulePrivateTourModalOpen(false)}
                        card={listing}
                        agent={agent}
                    />
                )}
                <Route
                    path={`${match?.url}/submit-offer/:boardCardId`}
                    render={() => <SubmitOfferModal onClose={() => history.push(match?.url)} />}
                />
                <Route
                    path={`${match?.path}/signup`}
                    render={() => <SignUpModal onClose={() => history.push(match?.url)} />}
                />
                <Route
                    path={`${match?.path}/verify`}
                    render={() => <VerifyModal onClose={() => history.push(match?.url)} />}
                />
                <Grid container wrap="nowrap" style={{ height: '100%' }}>
                    <Grid item xs={true} style={{ height: '100%' }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            height={1}
                            maxWidth={{ xs: '100vw', sm: '100vw', md: '768px', lg: '768px' }}
                            mx="auto"
                        >
                            {!embeded && !hideHeader && brandingAgent && (
                                <Header
                                    currentIndex={index}
                                    totalListings={listings.length}
                                    laneId={listing.laneId}
                                    cardId={listing.id}
                                    buyerAgent={buyerAgent}
                                    listingDetails={listing_details}
                                    isNextBackDisabled={listings.length < 2}
                                    onPreviousClick={handlePreviousListingClick}
                                    onNextClick={handleNextListingClick}
                                    onArchiveClick={handleOnArchiveClick}
                                    onMoveToChange={handleOnMoveToChange}
                                    requestSource={requestSource}
                                    reportId={reportId}
                                    contentRef={dialogContentRef}
                                    onClose={onClose}
                                    onShareClick={onShare}
                                    cardArchived={listing.archived}
                                    onSignupClick={() => {
                                        history.push(`${match?.url}/signup?invite_id=${user.meta.boardUserInviteId}`);
                                    }}
                                    onVerifyClick={handleOnVerifyClick}
                                    {...headerProps}
                                    {...overridingProps}
                                />
                            )}

                            {showAgentBanner && buyerAgent && (
                                <SlidyAgentInfo
                                    agent={buyerAgent}
                                    sliderRef={dialogContentRef}
                                    onSignupClick={() => {
                                        history.push(`${match?.url}/signup?invite_id=${user.meta.boardUserInviteId}`);
                                    }}
                                    onVerifyClick={handleOnVerifyClick}
                                    requestSource={requestSource}
                                />
                            )}

                            <StyledDialogContent ref={dialogContentRef} hasHeader={!embeded && !hideHeader}>
                                <Box
                                    position="relative"
                                    className="cursor-pointer"
                                    mt={
                                        isXs
                                            ? UserStore.isBannerVisible
                                                ? 11
                                                : -3
                                            : UserStore.isBannerVisible
                                                ? 6
                                                : -3
                                    }
                                    mx={{ xs: -2, sm: -3 }}
                                    mb={[3, 0]}
                                    style={{ overflowY: 'unset' }}
                                >
                                    {listing_details.photos.length > 0 && (
                                        <Box height={isXs ? '240px' : '384px'} position="relative">
                                            <Box
                                                position="absolute"
                                                zIndex="3"
                                                style={{ transform: 'translate3d(0, 0, 0)' }}
                                            >
                                                <RPPStatusBadge
                                                    status={listing_details.listing_status}
                                                    laneIdBadge={laneIdBadge}
                                                    listpack={listpack}
                                                    listingsAreNotCards={listingsAreNotCards}
                                                    listing_details={listing_details}
                                                    isRpp={true}
                                                    isListpackCard={isListpackCard}
                                                />
                                            </Box>
                                            <Swiper
                                                key={`${listing_details.id}-gallery-swiper`}
                                                data={listing_details.photos}
                                                slidesPerView={1}
                                                renderSlide={(photo) => (
                                                    <ListingImage
                                                        image={photo}
                                                        width={1}
                                                        height={1}
                                                        className="tour-target-listing-image"
                                                    >
                                                        {listing_details && boardId && (
                                                            <PinspirationOverlay
                                                                src={photo}
                                                                listing={listing_details}
                                                                listpackId={listpack?.id}
                                                            />
                                                        )}
                                                    </ListingImage>
                                                )}
                                                hasNavigation={true}
                                                onSlideClick={() => onShowGallery('rpp_hero_image', selectedPhotoIndex)}
                                                onSlideChange={(index) => {
                                                    setSelectedPhotoIndex(index);
                                                }}
                                                allowTouchMove={
                                                    requestSource !== 'consumer_view' ? (isXs ? true : false) : true
                                                }
                                                previousButtonClick={() => {
                                                    analytics.eventTrack(
                                                        'listpacks_consumer_listingpage_gallery_external_swipe',
                                                        {
                                                            address: listing_details.address,
                                                            'listing id': listing_details.id,
                                                            listpack_id: listpack?.id,
                                                            source: 'left',
                                                            report_id: reportId,
                                                        }
                                                    );
                                                }}
                                                nextButtonClick={() => {
                                                    analytics.eventTrack(
                                                        'listpacks_consumer_listingpage_gallery_external_swipe',
                                                        {
                                                            address: listing_details.address,
                                                            'listing id': listing_details.id,
                                                            listpack_id: listpack?.id,
                                                            source: 'right',
                                                            report_id: reportId,
                                                        }
                                                    );
                                                }}
                                                activeIndex={selectedPhotoIndex}
                                                loop
                                            />
                                        </Box>
                                    )}
                                    {listing_details.photos.length < 1 && (
                                        <>
                                            {listing?.hero_image_url && (
                                                <ListingImage
                                                    image={listing.hero_image_url}
                                                    height={isXs ? '240px' : '384px'}
                                                >
                                                    <RPPStatusBadge
                                                        status={listing_details.listing_status}
                                                        laneIdBadge={laneIdBadge}
                                                        listpack={listpack}
                                                        listingsAreNotCards={listingsAreNotCards}
                                                        listing_details={listing_details}
                                                        isRpp={true}
                                                        isListpackCard={isListpackCard}
                                                    />
                                                    <StyledViewImagesButton
                                                        listing={listing_details}
                                                        show={showGallery}
                                                        photos={listing_details.photos}
                                                        selectedPhotoIndex={selectedPhotoIndex}
                                                        onGalleryOpen={() => onShowGallery('rpp_photo_thumbnail')}
                                                        onGalleryClose={onGalleryClose}
                                                        requestSource={requestSource}
                                                        reportId={reportId}
                                                    />
                                                </ListingImage>
                                            )}
                                            {!listing?.hero_image_url && (
                                                <div style={{ height: isXs ? '240px' : '384px' }}>
                                                    <StaticGoogleMaps
                                                        latitude={listing_details?.lat || listing_details?.latitude}
                                                        longitude={listing_details?.lon || listing_details?.longitude}
                                                        width={1000}
                                                        height={900}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                            }}
                                                        >
                                                            <RPPStatusBadge
                                                                status={listing_details.listing_status}
                                                                laneIdBadge={laneIdBadge}
                                                                listpack={listpack}
                                                                listingsAreNotCards={listingsAreNotCards}
                                                                listing_details={listing_details}
                                                                isRpp={true}
                                                                isListpackCard={isListpackCard}
                                                            />
                                                        </div>
                                                    </StaticGoogleMaps>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {listing_details.photos.length > 0 && (
                                        <>
                                            <Box
                                                position="relative"
                                                top={listing_details.photos.length >= (isXs ? 4 : 5) ? 14 : 0}
                                            >
                                                <StyledViewImagesButton
                                                    listing={listing_details}
                                                    show={showGallery}
                                                    photos={listing_details.photos}
                                                    selectedPhotoIndex={selectedPhotoIndex}
                                                    onGalleryOpen={() => onShowGallery('rpp_photo_thumbnail')}
                                                    onGalleryClose={onGalleryClose}
                                                    requestSource={requestSource}
                                                    reportId={reportId}
                                                />
                                            </Box>
                                            <ThumbnailListingImages
                                                listing={listing}
                                                on3DTourClick={handle3DTourClick}
                                                onImageClick={() => onShowGallery('rpp_photo_thumbnail')}
                                                showGallery={showGallery}
                                            />
                                        </>
                                    )}
                                </Box>
                                {/* Listing Info */}
                                <InfoAndMatchScoreWrapper
                                    mt={-3}
                                    ml={[-2, 0]}
                                    width={['100vw', 1]}
                                    px={3}
                                    py={2}
                                    borderRadius={[0, 4]}
                                    zIndex={1}
                                >
                                    <PropertyInfo
                                        listingDetails={listing_details}
                                        listingsAreNotCards={listingsAreNotCards}
                                        onEstMortageClick={handleEstMortgageClick}
                                        requestSource={requestSource}
                                        listing={listing}
                                        reportId={reportId}
                                    />
                                </InfoAndMatchScoreWrapper>
                                {/** Listing Agent Details */}
                                <Box mt={[20 / 8, 4]} px={[1, 3]} textAlign="right">
                                    {
                                        // Open house dates removal
                                        /*<Box display="flex" flexDirection={['column-reverse', 'row']} alignItems={['', 'flex-end']} justifyContent="space-between">
                                        <Box mt={['20px', 0]} display="flex" flexWrap="wrap">
                                            <VirtualToursList onLinkClick={onVirtualTourClick} virtualTours={listing_details.virtual_tours} />
                                        </Box>
                                        <Box flexShrink={0}>
                                            <ListingAgent agent={listing_details.realtor} />
                                        </Box>
                                    </Box>*/
                                    }
                                    {listing_details.realtor?.first_name && (
                                        <ListingAgent listing={listing_details} />
                                    )}
                                </Box>
                                {/** Description */}
                                <Box px={2}>
                                    {listing_details.description && (
                                        <Description
                                            expand={expandDescription}
                                            onExpandClick={() => setExpandDescription(!expandDescription)}
                                            listing={listing_details}
                                            description={listing_details.description}
                                            requestSource={requestSource}
                                            reportId={reportId}
                                        />
                                    )}
                                </Box>
                                <StyledDivider />
                                {/** Open House */}
                                {listing_details.listing_status !== 'Sold' && (
                                    <>
                                        <Box px={[0, 2]}>
                                            <VirtualAndOpenHouses
                                                agent={agent}
                                                listpack={listpack}
                                                listing={listing_details}
                                                listingId={listing_details.id}
                                                virtualTours={listing_details.virtual_tours}
                                                onVirtualTourClick={onVirtualTourClick}
                                                embeded={embeded}
                                                onPrivateTourClick={onPrivateTourClick}
                                                requestSource={requestSource}
                                                reportId={reportId}
                                            />
                                        </Box>
                                        <StyledDivider />
                                    </>
                                )}
                                {/** Property Details */}
                                <Box px={2}>
                                    <PropertyDetails
                                        expand={expandDetails}
                                        onExpandClick={() => setExpandDetails(!expandDetails)}
                                        listpack={listpack}
                                        agent={agent}
                                        listing={listing_details}
                                        listingDetails={listing_details}
                                        onContactAgentClick={redirectToTheEnd}
                                        requestSource={requestSource}
                                        reportId={reportId}
                                        user={user}
                                    />
                                </Box>
                                <StyledDivider />
                                {/** Schools */}
                                <Box px={2}>
                                    <Schools address={listing_details.address} defaultExpanded={true} />
                                </Box>
                                <StyledDivider />
                                {/* Area Report */}
                                {listing && (
                                    <div
                                        ref={setAreaReportElement}
                                        style={{
                                            padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                                        }}
                                    >
                                        <AreaReport
                                            listingDetails={listing_details}
                                            heroImage={listingImage(listing)}
                                            buyerAgent={buyerAgent}
                                            embeded={embeded}
                                            onContactAgentClick={redirectToTheEnd}
                                            requestSource={requestSource}
                                            parentRef={dialogContentRef}
                                            fromRobin={fromRobin}
                                            reportId={reportId}
                                            enabled={enabledAreaReport}
                                        />
                                    </div>
                                )}
                                {/** Mortgage Calculator */}
                                <Box marginTop="-32px" ref={mortageCalculatorRef}></Box>
                                <StyledDivider />
                                <Box px={2}>
                                    <StyledSectionTitle>Monthly Payment Calculator</StyledSectionTitle>

                                    <MortgageCalculator
                                        expand={expandCalculations}
                                        onExpandClick={() => setExpandCalculations(!expandCalculations)}
                                        listing={listing_details}
                                        buyerAgent={buyerAgent}
                                        price={listing_details.price}
                                        taxes={listingTaxes}
                                        hoaDuesAmount={_.get(listing_details, 'hoa_fees.value')}
                                        hoaDuesPeriod={
                                            _.get(listing_details, 'hoa_fees.currencyPeriod', '').toLowerCase() ===
                                            'monthly'
                                                ? 'month'
                                                : 'year'
                                        }
                                        state={listing_details.state}
                                        requestSource={requestSource}
                                        reportId={reportId}
                                    />
                                </Box>
                                {(brandingAgent || showAgentLoInfo) && (
                                    <Box>
                                        <StyledDivider />
                                        <Box px={2}>
                                            {
                                                <YourAgent
                                                    buyerAgent={buyerAgent}
                                                    showContactAgentButton={
                                                        listing_details?.listing_source !== 'listhub' && listpack
                                                    }
                                                    onContactAgentClick={redirectToTheEnd}
                                                />
                                            }
                                        </Box>
                                    </Box>
                                )}
                                {/** Assessment And Tax */}
                                {/* {!embeded && !listingsAreNotCards && (
                                    <AssessmentAndTax assessmentAndTaxData={{}} defaultExpanded={true} />
                                )} */}
                                <StyledDivider />
                                <Box mt={4} px={2}>
                                    <TermsAndConditions listingDetails={listing_details} buyerAgent={buyerAgent} />
                                </Box>
                            </StyledDialogContent>

                            {!embeded && !hideFooter && (
                                <Hidden mdUp>
                                    <Footer
                                        currentIndex={index}
                                        totalListings={listings.length}
                                        isNextBackDisabled={listings.length < 2}
                                        onPreviousClick={handlePreviousListingClick}
                                        onNextClick={handleNextListingClick}
                                        laneId={listing.laneId}
                                        cardId={listing.id}
                                        onMoveToChange={handleOnMoveToChange}
                                        onArchiveClick={handleOnArchiveClick}
                                        {...overridingProps}
                                        cardArchived={listing.archived}
                                    />
                                </Hidden>
                            )}
                        </Box>
                    </Grid>
                    <Hidden mdDown>
                        {showCollaborations && !listingsAreNotCards && (
                            <Box
                                width="100%"
                                maxWidth="360px"
                                position="relative"
                                zIndex="2"
                                borderLeft="1px solid #ddd"
                            >
                                <CardBoardCollaborations boardId="1" cardId={listing.id} />
                            </Box>
                        )}
                    </Hidden>
                </Grid>
                <Route
                    path={`${match?.url}/gallery`}
                    render={() => (
                        <ListingExplorerModal
                            card={listing}
                            onClose={handleListingExplorerClose}
                            requestSource={requestSource}
                            reportId={reportId}
                        />
                    )}
                />
                <div
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <CardDeleteModal
                        open={showConfirmModal}
                        onClose={() => {
                            setShowConfirmModal(false);
                        }}
                        onConfirm={handleDeleteConfirmation}
                    />
                </div>
            </div>
        </>
    );
};
ListingDetails.propTypes = {
    listings: PropTypes.array.isRequired,
    onArchive: PropTypes.func,
    onMoveToChange: PropTypes.func,
    listingsAreNotCards: PropTypes.bool,
    startIndex: PropTypes.number,
};

export default observer(ListingDetails);
