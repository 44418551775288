import React, { useState, useEffect, useMemo, useRef } from 'react';
import lodashGet from 'lodash/get';
import lodashHead from 'lodash/head';
import GoogleMapReact from 'google-map-react';
import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { withTheme } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { fitBounds } from 'google-map-react/utils';
import analytics from '../../../services/analytics';
import { portalApi } from 'apis';
import simpleCypher from '../../../services/simple-cypher';
import useSupercluster from 'use-supercluster';
import { useParams } from 'react-router-dom';
import { useStore } from 'mobx-store';
import { useAgent } from 'hooks/useAgent';
import { useArea } from 'hooks/data/area';
import Logger from 'js-logger';

const NumAbbr = require('number-abbreviate');
const numAbbr = new NumAbbr();

const FilterButton = withTheme(styled(ToggleButton)`
    min-width: 80px;
    height: 40px;
    text-transform: capitalize;

    ${({ theme })=> css`
        color: ${theme.palette.text.primary};
        &.Mui-selected {
            color: ${theme.palette.text.primary};
            background-color: ${theme.palette.grey[300]};
        }
    `}
`);

const PriceMarker = withTheme(styled(Box)`
    padding: 3px;
    min-width: 60px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 2px;
    height: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -27px;
    left: -30px;

    ${({ theme, selected })=>
        selected === true
            ? css`
              background: #FF6259;
              border: 1px solid #FF6259;
              color: white;
            `
            : `background: white;
                border: 1px solid white;
                color: black;
            `};

    &::after {
        content: ' ';
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid white;
        position: absolute;
        top: 19px;
        left: 23px;

        &:hover {
            border-top:7px solid #FF6259;
        }

        ${({ theme, selected })=>
        selected === true
            ? css`
                border-top: 7px solid #FF6259;
            `
            : `border-top: 7px solid white;
            `};
    }

    &::before {
        content: ' ';
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        position: absolute;
        top: 19px;
        left: 23px;


        ${({ theme, selected })=>
        selected === true
            ? css`
                border-top: 7px solid #FF6259;
            `
            : `border-top: 7px solid white;
            `};
    }

    ${({ theme })=> theme.breakpoints.down('xs')} {
        &::after {
            top:19px;
        }

        &::before {
            top: 19px;
        }
    }

    &:hover {
       background: #FF6259;
       color: white;
       border: 1px solid #FF6259;

        &::after {
            border-top: 7px solid #FF6259;
        }

        &::before {
            border-top: 7px solid #FF6259;
        }
    }
`);

const PlaceWrapper = ({ children })=> {
    return children;
};

function boundsForCoordinates(coords) {
    var bounds = new window.google.maps.LatLngBounds();
    for(const coord of coords) {
        bounds.extend(coord);
    }
    return {
        ne: bounds.getNorthEast()
            .toJSON(),
        sw: bounds.getSouthWest()
            .toJSON(),
    };
}

const Map = ({
    listings,
    selectedListing,
    hoveredListing,
    listpackId,
    onListingSelect = (listing)=> {},
    onHover = (listing)=> {},
    ...props
})=> {
    const { UserStore, ListingsMapStore } = useStore();
    const { selectedAreas } = ListingsMapStore;
    const { user = {} } = UserStore;
    const { affiliateCode } = useParams();
    const { data: affiliate } = useAgent(affiliateCode, { priority: 'affiliate' });
    const { data: firstArea } = useArea(lodashHead(selectedAreas));
    const [center, setCenter] = useState([33.78724, -117.85496]);
    const [zoom, setZoom] = useState(10);
    const [bounds, setBounds] = useState({ nw: { lat: 85, lng: -180 }, se: { lat: -85, lng: 180 } });
    const [mapType, setMapType] = useState('roadmap');
    const mapsContainerRef = useRef(null);
    const [allListingDetailsLoaded, setAllListingDetailsLoaded] = useState(false);
    const agent = useMemo(()=> {
        return user?.affiliate || affiliate;
    }, [user, affiliate]);
    const points = useMemo(()=> {
        const filteredListings = listings.filter((l)=> { return l.listing_details && l.listing_details.lat && l.listing_details.lon; });

        return filteredListings.map((listing)=> {
            const abbreviate = numAbbr.abbreviate(listing.listing_details.price, listing.listing_details.price >= 1000000 ? 2 : 0);
            return {
                type: 'Feature',
                properties: {
                    cluster: false,
                    id: listing.listing_details.id,
                    lat: listing.listing_details.lat,
                    lng: listing.listing_details.lon,
                    price_formatted: `$${abbreviate}`.toUpperCase(),
                },
                geometry: {
                    type: 'Point',
                    coordinates: [
                        parseFloat(listing.listing_details.lon),
                        parseFloat(listing.listing_details.lat)
                    ]
                }
            };
        });
    }, [listings, allListingDetailsLoaded]);
    const { clusters, supercluster } = useSupercluster({
        points,
        bounds: [-180, -85, 180, 85],
        zoom,
        options: { radius: 20, maxZoom: 20 }
    });
    const hoveredPoint = useMemo(()=> {
        if(!clusters.length || !hoveredListing) return null;
        const selectedItem = clusters.find((c)=> {
            const {
                id,
                cluster: isCluster,
            } = c.properties;

            if(!isCluster) {
                return hoveredListing?.listing_details?.id === id;
            } else {
                const allClustersWithPoints = clusters.filter((c)=> c.properties.cluster);
                var selectedClusterId;
                allClustersWithPoints.forEach((v, i)=> {
                    const clusterLeaves = supercluster.getLeaves(v.id, 10000);             
                    const match = clusterLeaves.find((child)=> { return child.properties.id === hoveredListing?.listing_details?.id; }); 
                    if(match) {
                        selectedClusterId = v.id;
                    }
                });
                return selectedClusterId;
            }
        });
        return selectedItem;
    }, [clusters, hoveredListing]);

    function setDefaultMapLocation() {
        let lat, long;
        // if there's at least one area selected choose the first one, else default to affiliate's default marketing area
        if(firstArea) {
            [long, lat] = lodashGet(firstArea, 'longitude_latitude.coordinates', []);
        } else {
            [long, lat] = lodashGet(agent, 'marketing_areas[0].longitude_latitude.coordinates', []);
        }

        if(lat && long) {
            setCenter([lat, long]);
        }
    }

    // UseEffects

    useEffect(()=> {
        const isAllLoaded = Boolean(listings?.length) && listings.filter((l)=> !!l.listing_details).length === listings.length;
        if(isAllLoaded && isAllLoaded !== allListingDetailsLoaded) {
            setAllListingDetailsLoaded(true);
        }
    });

    useEffect(()=> {
        if(!points.length) {
            setDefaultMapLocation();
            return;
        }
        if(selectedListing) {
            const point = points.find((point)=> point.properties.id === selectedListing.listing_details.id);
            if(point) {
                const [longitude, latitude] = point.geometry.coordinates;
                setCenter([latitude, longitude]);
                // maybe use the same zoom calc funcftion???
                setZoom((zoom)=> (zoom < 16 ? 16 : zoom));
                return;
            }
        }
        if(points.length === 1) {
            const [longitude, latitude] = points[0].geometry.coordinates;
            setCenter([latitude, longitude]);
            setZoom(16);
        } else {
            const formattedPoints = points.map((p)=> {
                const [longitude, latitude] = p.geometry.coordinates;
                return {
                    lat: latitude,
                    lng: longitude,
                };
            });
            const bounds = boundsForCoordinates(formattedPoints);
            let { center: newCenter, zoom: newZoom, newBounds } = fitBounds(bounds, {
                width: mapsContainerRef.current.offsetWidth,
                height: mapsContainerRef.current.offsetHeight,
            });
            setCenter(newCenter);
            setBounds(newBounds);
            setZoom(newZoom);
        }

    }, [points, selectedListing, agent, firstArea]);

    function isSelected(item) {
        // clusters can't be selected
        if(item.properties.cluster) return false;
        return item?.properties?.id === selectedListing?.listing_details?.id;
    }

    function isHovered(item) {
        const {cluster: isCluster} = item.properties;
        
        if(isCluster) {
            return item?.id === hoveredPoint?.id;
        }

        return item?.properties?.id === hoveredPoint?.properties?.id;
    }

    function handleMarkerClick(item) {
        const [longitude, latitude] = item.geometry.coordinates;
        const {
            id,
            cluster: isCluster,
        } = item.properties;

        if(!isCluster) {
            setCenter([latitude, longitude]);
            const selectedListing = listings.find((listing)=> {
                return listing.listing_details.id === id;
            });

            const { id: listingId } = simpleCypher.decode(selectedListing.listing_details.id) || {};
            
            analytics.eventTrack('robin_expanded_view_map_pin_click', {
                listing_id: listingId,
                address: selectedListing.listing_details.address,
                listpack_id: listpackId
            });

            onListingSelect(selectedListing);
        } else {
            const newZoom = supercluster.getClusterExpansionZoom(item.id);
            setCenter([latitude, longitude]);
            setZoom(newZoom);
        }
    }

    function handleOnMouseEnter(item) {
        const {
            id,
            cluster: isCluster,
        } = item.properties;
        
        setTimeout(()=> {
            if(isCluster) return;

            const selectedItem = listings.find((listing)=> {
                return listing.listing_details.id === id;
            });
            if(selectedItem?.listing_details?.id === selectedListing?.listing_details?.id) {
                return;
            }
            onHover(selectedItem);
        }, 0);
    }

    function handleOnMouseLeave(item) {
        const {
            id,
            cluster: isCluster,
        } = item.properties;

        setTimeout(()=> {
            if(isCluster) return;

            const selectedItem = listings.find((listing)=> {
                return listing.listing_details.id === id;
            });
            if(selectedItem?.listing_details?.id === selectedListing?.listing_details?.id) {
                return;
            }
            onHover(null);
        }, 0);
    }

    function handleMapChange(zoom) {
        setZoom(zoom);
    }

    return (
        <Box ref={mapsContainerRef} width={1} height={1}>
            <Box position="absolute" zIndex="1" bottom={16} left={16}>
                <ToggleButtonGroup value={mapType} onChange={(e)=> {
                    analytics.eventTrack('robin_expanded_view_map_type_button_click', {
                        source: e.currentTarget.value === 'roadmap' ? 'map' : 'satellite',
                        listpack_id: listpackId
                    });
                    setMapType(e.currentTarget.value);}
                }>
                    <FilterButton value={'roadmap'}>Map</FilterButton>
                    <FilterButton value={'satellite'}>Satellite</FilterButton>
                </ToggleButtonGroup>
            </Box>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: 'us' }}
                zoom={zoom}
                center={center}
                yesIWantToUseGoogleMapApiInternals
                // onChange={handleMapChange}
                onZoomAnimationEnd={handleMapChange}
                options={
                    {
                        mapTypeId: mapType,
                        fullscreenControl: false,
                        streetViewControl: false
                    }
                }
            >
                {
                    clusters.map((point)=> {
                        const [longitude, latitude] = point.geometry.coordinates;
                        const {
                            id,
                            cluster: isCluster,
                            point_count: pointCount,
                            price_formatted: formattedPrice
                        } = point.properties;

                        return (
                            <PlaceWrapper key={point.id || id} lat={latitude} lng={longitude}>
                                <div style={{position: 'relative'}}>
                                    <PriceMarker
                                        selected={ isSelected(point) || isHovered(point) }
                                        onClick={()=> {
                                            handleMarkerClick(point);
                                        }}
                                        onMouseEnter={()=> { handleOnMouseEnter(point); }}
                                        onMouseLeave={()=> { handleOnMouseLeave(point); }}
                                    >
                                        <Box fontWeight="500" whiteSpace="nowrap">
                                            {isCluster ? `${pointCount} units` : formattedPrice}
                                        </Box>
                                    </PriceMarker>
                                </div>
                            </PlaceWrapper>
                        );
                    })
                }
            </GoogleMapReact>
        </Box>
    );
};

export default observer(Map);
