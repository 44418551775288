// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Box, Typography } from '@material-ui/core';
import Header from './Header';
import GoogleMapReact from 'google-map-react';
import { Place } from '@material-ui/icons';
import splitAddress from '../../services/split-address';
import analytics from '../../services/analytics';
import { useStore } from '../../mobx-store';
import { observer } from 'mobx-react-lite';
import { add } from 'lodash';
import { withTheme } from '@material-ui/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const Marker = styled.div``;

const FilterButton = withTheme(styled(ToggleButton)`
    min-width: 80px;
    height: 40px;
    text-transform: capitalize;

    ${({ theme })=> css`
        color: ${theme.palette.text.primary};
        &.Mui-selected {
            color: ${theme.palette.text.primary};
            background-color: ${theme.palette.grey[300]};
        }
    `}
`);


export function LRMap({ lat, lng, address, mode = 'map' }) {
    const gMapCenter = lat && lng ? [lat, lng] : undefined;
    const mapRef = useRef();
    const gMapRef = useRef();
    const [address1, address2] = splitAddress(address);
    const [mapType, setMapType] = useState('roadmap');
    const textColor = mapType === 'roadmap' ? 'black' : 'white';

    useEffect(()=> {
        if(!gMapRef.current) {
            return;
        }
        gMapRef.current.streetView.setVisible(mode === 'street');
    }, [mode]);

    /**
     * Handles setting up our street view for the map
     *
     * @function handleOnGoogleApiLoaded
     */
    function handleOnGoogleApiLoaded({ map, maps }) {
        if(map) {
            gMapRef.current= map;

            const target = new maps.LatLng(lat, lng);

            // https://developers.google.com/maps/documentation/javascript/reference/street-view-service
            const sv = new maps.StreetViewService();
            sv.getPanorama({ location: { lat, lng }, radius: 50 }, (data, status)=> {
                const panoramaParams = {
                    position: { lat, lng },
                    addressControl: false,
                    visible: mode === 'street' && status === 'OK',
                };

                if(status === maps.StreetViewStatus.OK) {
                    panoramaParams.pov = {
                        heading: maps.geometry.spherical.computeHeading(data.location.latLng, target),
                        pitch: 0,
                    };
                }

                const panorama = new maps.StreetViewPanorama(ReactDOM.findDOMNode(mapRef.current), panoramaParams);
                map.setStreetView(panorama);
            });
        }
    }

    return (
        <Box width={1} height={1} maxWidth={{ sm: '1038px' }} maxHeight={{ sm: '725px' }} margin="0 auto" position="relative">
            <Box position="absolute" zIndex="1" bottom={16} left={16}>
                <ToggleButtonGroup value={mapType} onChange={(e)=> {
                    analytics.eventTrack('listpacks_consumer_listingpage_map_type_button_click', {
                        source: e.currentTarget.value === 'roadmap' ? 'map' : 'satellite',
                    });
                    setMapType(e.currentTarget.value);}
                }>
                    <FilterButton value={'roadmap'}>Map</FilterButton>
                    <FilterButton value={'satellite'}>Satellite</FilterButton>
                </ToggleButtonGroup>
            </Box>
            <GoogleMapReact
                ref={mapRef}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: 'us' }}
                defaultZoom={16.5}
                defaultCenter={[33.78724, -117.85496]}
                center={gMapCenter}
                onGoogleApiLoaded={handleOnGoogleApiLoaded}
                options={{ mapTypeId: mapType }}
            >
                <Marker lat={lat} lng={lng}>
                    <Box width="0" height="0">
                        <Box color={textColor}>
                            <Place
                                style={{
                                    width: '42px',
                                    height: '72px',
                                    marginLeft: '-21px',
                                    marginTop: '-36px',
                                }}
                            />

                            <Box width="300px" marginLeft="-150px" marginTop="-16px">
                                <Typography variant="body2" align="center">
                                    {address1}
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {address2}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Marker>
            </GoogleMapReact>
        </Box>
    );
}

function LRMapModal({ listing, lat, lng, address, onClose, requestSource, reportId, ...props }) {
    const [isOpen, setIsOpen] = useState(true);
    const { ListpackStore, UserStore } = useStore();
    const { agent, listpack } = ListpackStore;
    const user = UserStore.user;
    const [mapMode, setMapMode] = useState('map');
    /**
     * Closes dialog
     *
     * @function handleOnCloseClick
     */
    function handleOnCloseClick() {
        setIsOpen(false);
    }

    /**
     * Set the toggle view
     *
     * @function handleViewToggleChange
     */
    function handleViewToggleChange(e, value) {
        if(value === 'street') {
            analytics.eventTrack('listpacks_consumer_listingpage_map_street_view_click', {
                address: listing.address,
                'listing id': listing.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
                source: requestSource,
                report_id: reportId,
            });
        }
        setMapMode(value);
    }


    return (
        <Dialog fullScreen open={isOpen} onExited={onClose}>
            <Box position="absolute" left={0} top={0} right={0} bottom={0} display="flex" flexDirection="column">
                <Box zIndex="1" boxShadow={'0px 3px 12px -2px rgba(0, 0, 0, 0.2)'}>
                    <Header
                        defaultToggleValue="map"
                        onClose={handleOnCloseClick}
                        onViewToggleChange={handleViewToggleChange}
                    />
                </Box>

                <Box flex="1">
                    <Box height={1} maxWidth={{ sm: '1038px' }} maxHeight={{ sm: '725px' }} margin="0 auto">
                        <LRMap
                            lat={lat}
                            lng={lng}
                            address={address}
                            mode={mapMode}
                        />
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

LRMapModal.propTypes = {
    listing: PropTypes.object,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    onClose: PropTypes.func,
};

export default React.memo(observer(LRMapModal));
