import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components/macro';
import { MinimizeRounded, AddRounded } from '@material-ui/icons';
import SideBySide from '../SideBySide';
import Comments from '../Comments';
import { COLOR_GREY_MEDIUM } from '../../../../constants';
import { observer } from 'mobx-react-lite';

const StyledMenu = styled.div`
    width: 100%;
    border-bottom: solid 1px ${COLOR_GREY_MEDIUM};
    &:last-child {
        border: 0;
    }
`;

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    height: 50px;
    position: relative;
`;

const StyledIconButton = styled(IconButton)`
    color: black;
    padding: 0;
`;

const StyledBold = styled.b`
    font-size: 20px;
`;

const getButtonIcon = (opened)=> {
    if(opened) {
        return <MinimizeRounded />;
    } else {
        return <AddRounded />;
    }
};

const Menu = observer(function Menu({
    name,
    tab,
    selectedListings,
    userPreference,
    initOpened = false
}) {
    let [opened, setOpened] = useState(initOpened);
    const processedListings = selectedListings.length > 1 ?
        [
            { ...selectedListings[0], ...selectedListings[0].listing_details },
            { ...selectedListings[1], ...selectedListings[1].listing_details }
        ]:
        [
            { ...selectedListings[0], ...selectedListings[0].listing_details }
        ];

    return (
        <StyledMenu>
            <StyledHeader>
                <StyledBold>{name}</StyledBold>
                <StyledIconButton onClick={()=> setOpened(!opened)}>{getButtonIcon(opened)}</StyledIconButton>
            </StyledHeader>
            {(()=> {
                if(opened) {
                    if(tab.type === 'sideBySide') {
                        return tab.attributes.map((attribute)=> {
                            return (
                                <SideBySide
                                    key={attribute.name}
                                    attribute={attribute}
                                    processedListings={processedListings}
                                    userPreference={userPreference}
                                />
                            );
                        });
                    } else if(tab.type === 'comments') {
                        return (
                            <Comments
                                cards={selectedListings}
                            />
                        );
                    }
                }
                return null;
            })()}
        </StyledMenu>
    );
});

Menu.propTypes = {
    name: PropTypes.string,
    tab: PropTypes.object,
    selectedListings: PropTypes.array,
    selectedComments: PropTypes.array,
    setFirstIndex: PropTypes.func,
    setSecondIndex: PropTypes.func,
    userPreference: PropTypes.object
};

export default Menu;
