// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Box } from '@material-ui/core';
import SectionTitle from '../SectionTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import School from './School';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';

const StyledExpansionPanel = styled(({ className, ...props })=> (
    <ExpansionPanel classes={{ root: className }} {...props} />
))`
    box-shadow: none;
    border-radius: 0 !important;
`;
const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
    padding: 0;
    min-height: 0 !important;

    & .MuiExpansionPanelSummary-content {
        margin: 0 !important;
    }
    & .MuiIconButton-root {
        padding-top: 0;
        padding-bottom: 0;
    }
`;
const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 24px;
`;

const StyleExpandMoreIcon = styled(ExpandMoreIcon)`
    color: #000;
`;
const StyledSubheadingTypography = styled(Typography)`
    margin-bottom: 42px;
`;
const StyledLegalTypography = styled(Typography)`
    font-size: 10px;
    color: #a1a1a1;
`;
const SchoolsContainer = styled.div`
    margin-bottom: 36px;
`;
const SchoolSpacer = styled.div`
    height: 32px;
`;

const StyledSectionTitle = styled(SectionTitle)`
    margin: 0;
    line-height: 1;
`;

function Schools({ defaultExpanded, address, ...props }) {
    const { SchoolsStore } = useStore();
    const [hideComponent, setHideComponent] = useState(false);
    const schoolData = SchoolsStore.cardSchoolsData[address];
    const [schoolSumaryRating, setSchoolSummaryRating] = useState('');

    useEffect(
        function() {
            if(!schoolData) {
                (async function() {
                    try {
                        await SchoolsStore.getSchoolsData(address);
                    } catch (err) {
                        setHideComponent(true);
                        return;
                    }
                })();
            }

            if(schoolData) {
                var avgRating = 0;
                schoolData.schools.forEach((school)=> {
                    avgRating += school.rating;
                });
                avgRating = avgRating / schoolData.schools.length;
                setSchoolSummaryRating(avgRating >= 7 ? 'above average' : 'average');
            }

            return ()=> {
                setHideComponent(false);
            };
        },
        [SchoolsStore, address, schoolData]
    );

    if(hideComponent || !schoolData) {
        return <></>;
    }

    return (
        <Box {...props}>
            <StyledExpansionPanel defaultExpanded={defaultExpanded}>
                <StyledExpansionPanelSummary expandIcon={<StyleExpandMoreIcon />}>
                    <StyledSectionTitle>Schools</StyledSectionTitle>
                </StyledExpansionPanelSummary>

                <StyledExpansionPanelDetails>
                    <StyledSubheadingTypography>
                        The assigned public schools for this home are {schoolSumaryRating} for this area. There are
                        additionally {schoolData.total_private_schools || 0} private school
                        {schoolData.total_private_schools !== 1 && 's'} and {schoolData.total_charter_schools || 0}{' '}
                        charter school{schoolData.total_charter_schools !== 1 && 's'} within 5 miles of this home.
                    </StyledSubheadingTypography>

                    <SchoolsContainer>
                        {schoolData.schools.map((school, i)=> (
                            <Box key={i}>
                                {i > 0 && <SchoolSpacer />}
                                <School schoolData={school} />
                            </Box>
                        ))}
                    </SchoolsContainer>

                    <StyledLegalTypography>
                        School data provided by ATTOM Data Solutions. School service boundaries are intended to be used as 
                        reference only. To verify enrollment eligibility for a property, contact the school directly.
                    </StyledLegalTypography>
                </StyledExpansionPanelDetails>
            </StyledExpansionPanel>
        </Box>
    );
}

Schools.propTypes = {
    address: PropTypes.string.isRequired,
    defaultExpanded: PropTypes.bool,
};

export default observer(Schools);
