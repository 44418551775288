import { decorate, observable, computed, action, flow } from 'mobx';

export const STATES = {
    INITIAL: 'initial',
    LOADING: 'loading',
    ERROR: 'error',
    LOADED: 'loaded',
};

export class AsyncRequest {
    state = STATES.INITIAL;
    data = null;
    error = null;

    constructor() {
        if(!this.asyncRequest) {
            alert('AsyncRequest requires an asyncRequest method to be implemented');
        }
    }

    get hasLoaded() {
        return this.state === STATES.LOADED;
    }

    get isLoading() {
        return this.state === STATES.LOADING;
    }

    get hasError() {
        return this.state === STATES.ERROR;
    }

    get errorData() {
        return this.error && this.error.response.data;
    }

    reset() {
        this.state = STATES.INITIAL;
        this.error = null;
        this.data = null;
    }

    fetch = flow(function*(...args) {
        if(this.hasLoaded) {
            return this.data;
        }

        this.state = STATES.LOADING;
        try {
            this.data = yield this.asyncRequest(...args);
            this.state = STATES.LOADED;
        } catch (err) {
            console.error(err);
            this.error = err;
            this.state = STATES.ERROR;
        }
        return this.data;
    });

    reFetch = flow(function* (...args) {
        this.reset();
        return yield this.fetch(...args);
    });
}

decorate(AsyncRequest, {
    state: observable,
    data: observable,
    error: observable,
    isLoading: computed,
    hasLoaded: computed,
    hasError: computed,
    errorData: computed,
    reset: action,
});

export function asyncRequest(asyncFunction) {
    class ExtendedAsyncRequestClass extends AsyncRequest {
        asyncRequest(...args) {
            return asyncFunction(...args);
        }
    }

    return new ExtendedAsyncRequestClass();
}
