import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Box, Grid, Snackbar, IconButton } from '@material-ui/core';
import LRButton from '../../../../components/LRButton';
import LRInput from '../../../../components/LRInput';
import { COLOR_GREY } from '../../../../constants';
import { Error, CheckCircleOutlined, Close } from '@material-ui/icons';
import StyledLabel from '../../../../components/StyledLabel';
import { useValidationForm } from '../../../../hooks';

const IndicatorIconContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    color: #8fb717;
`;

const CloseButton = styled(IconButton)`
    color: #fff;
`;
function PasswordForm() {
    const [cancel, setCancel] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const [form, inputs] = useValidationForm(
        {
            password: '',
            newPassword: '',
            newPasswordRetype: ''
        },
        {
            password: ['required'],
            newPassword: ['required', 'min:6'],
            newPasswordRetype: ['required', 'eq:newPassword']
        }
    );

    function handleSubmit() {
        setSubmitted(true);
        if(form.isValid) {
            setOpenSnackbar(true);
        }
    }

    const { password, newPassword, newPasswordRetype } = inputs;

    if(cancel) return <Redirect to={'/boards/account/profile'} />;

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={'Changes successfully saved!'}
                open={openSnackbar}
                action={[
                    <CloseButton onClick={()=> setOpenSnackbar(false)}>
                        <Close />
                    </CloseButton>
                ]}
            />
            <Box mb={2} textAlign="center" fontSize="h5.fontSize">
                Change password
            </Box>
            <Box mx="auto" mb={2} width={36} borderBottom={`solid 3px ${COLOR_GREY}`}></Box>
            <Box mx="auto" width={{ xs: '100%', md: '70%' }}>
                <Grid item xs={12}>
                    <StyledLabel error={(submitted && !password.isValid).toString()} align="left">
                        Current password
                    </StyledLabel>
                    <Box my="8px">
                        <LRInput
                            type="password"
                            variant="outlined"
                            {...password.inputProps}
                            error={submitted && !password.isValid}
                            fullWidth={true}
                        />
                    </Box>
                    {submitted && !password.isValid && (
                        <Box fontSize={14} color="error.main" my="4px">
                            Please enter your current password.
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <StyledLabel
                        error={((newPassword.isTouched || submitted) && !newPassword.isValid).toString()}
                        align="left"
                    >
                        New password
                    </StyledLabel>
                    <Box position="relative" my="8px">
                        <LRInput
                            name="newPassword"
                            type="password"
                            variant="outlined"
                            error={(newPassword.isTouched || submitted) && !newPassword.isValid}
                            {...newPassword.inputProps}
                            fullWidth={true}
                        />
                        {(newPassword.isTouched || submitted) && !newPassword.isValid && (
                            <IndicatorIconContainer>
                                <Error size={20} color="error" />
                            </IndicatorIconContainer>
                        )}
                        {(newPassword.isTouched || submitted) && newPassword.isValid && (
                            <IndicatorIconContainer>
                                <CheckCircleOutlined size={20} />
                            </IndicatorIconContainer>
                        )}
                    </Box>
                    {(newPassword.isTouched || submitted) && !newPassword.isValid && (
                        <Box fontSize={14} color="error.main" my="4px">
                            Password must be at least 6 characters.
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <StyledLabel
                        error={(
                            (submitted && !newPasswordRetype.isValid) ||
                            (newPasswordRetype.isTouched && !newPasswordRetype.isValid)
                        ).toString()}
                        align="left"
                    >
                        Re-type new password
                    </StyledLabel>
                    <Box position="relative" my="8px">
                        <LRInput
                            type="password"
                            variant="outlined"
                            {...newPasswordRetype.inputProps}
                            error={
                                (submitted && !newPasswordRetype.isValid) ||
                                (newPasswordRetype.isTouched && !newPasswordRetype.isValid)
                            }
                            fullWidth={true}
                        />
                        {((submitted && !newPasswordRetype.isValid) ||
                            (newPasswordRetype.isTouched && !newPasswordRetype.isValid)) && (
                            <IndicatorIconContainer>
                                <Error size={20} color="error" />
                            </IndicatorIconContainer>
                        )}
                        {newPasswordRetype.isValid && (
                            <IndicatorIconContainer>
                                <CheckCircleOutlined size={20} />
                            </IndicatorIconContainer>
                        )}
                    </Box>
                    {((submitted && !newPasswordRetype.isValid) ||
                        (newPasswordRetype.isTouched && !newPasswordRetype.isValid)) && (
                        <Box fontSize={14} color="error.main" my="4px">
                            New password does not match.
                        </Box>
                    )}
                </Grid>
                <Box mt={2}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                        <Grid item xs={6}>
                            <LRButton
                                fullWidth
                                color="primary"
                                disabled={submitted && !form.isValid}
                                onClick={handleSubmit}
                                variant="contained"
                            >
                                Save Changes
                            </LRButton>
                        </Grid>
                        <Grid item xs={6}>
                            <LRButton onClick={()=> setCancel(true)} fullWidth color="primary">
                                Cancel
                            </LRButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default observer(PasswordForm);
