import { useState, useEffect, useCallback } from 'react';

export function useRFHInput(name, rfhMethods) {
    const [inputValue, setInputValue] = useState();
    const { register, setValue, getValues, watch } = rfhMethods;
    const value = watch(name);

    useEffect(
        function() {
            if(inputValue !== value) setInputValue(value);
        },
        [getValues, inputValue, name, value]
    );

    useEffect(
        function() {
            register({ name });
        },
        [name, register]
    );

    const handleChange = useCallback(
        function(value) {
            setValue(name, value);
            setInputValue(value);
        },
        [name, setValue]
    );

    return { value: inputValue, handleChange };
}
