import React from 'react';

import { Link } from 'react-router-dom';
import { withTheme, Box, Link as htmlLink } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

const StyledLink = withTheme(styled(Box)`
    text-decoration: none;

    ${({ theme, color, unstyled = false })=>
        !unstyled &&
        css`
            color: ${color || theme.palette.secondary.main};
        `}
`);

export default function({ native = false,  ...props }) {
    return (
        <StyledLink component={native? htmlLink : Link} {...props} />
    );
}
