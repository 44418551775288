// eslint-disable-next-line
import Logger from 'js-logger';
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Dialog, DialogContent, LinearProgress, useMediaQuery, withStyles} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import PinspirationGrid from '../../components/Pinspiration/PinspirationGrid';
import PinspirationList from '../../components/Pinspiration/PinspirationList';
import {PushPinIcon} from '../../components/LRIcons';
import { openedFrom, BoardCardModal } from '../BoardCardModal';
import BoardListingModal from '../BoardListingModal';
import Header from '../../components/Pinspiration/Header';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../mobx-store';
import analytics from '../../services/analytics';
import {Route, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import lodashDebounce from 'lodash/debounce';
import {CancelToken} from 'axios';
import {useOnboarding} from '../../components/Onboarding/store';
import queryString from 'query-string';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { withTheme } from '@material-ui/styles';

const logger = Logger.get('PinspirationBoardModal');

const StyledDialog = styled(Dialog)`
    & [class*='MuiDialog-paperWidthSm'] {
        background-color: #000;
        color: #fff;
    }
`;
const StyledDialogContent = withTheme(styled(DialogContent)`
    padding: 0;
    width: 100%;
    background-color: #000;
    color: #fff;
    margin: 0 auto;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        padding: ${({ theme })=> theme.spacing(1)}px;
    }
`);

const StyledDialogNoContent = styled(DialogContent)`
    padding: 32px;
    width: 100%;
    min-height: 320px;
    margin: 0 auto;
    max-width: 428px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledIconBadge = styled.div`
    width: 24px;
    height: 24px;
    margin: 0 auto 16px;
    font-size: 16px;
    padding: 4px;
    background-color: #fff;
    border-radius: 50%;
`;

const StyledLinearProgress = withStyles({
    root: {
        backgroundColor: '#333',
        height: '2px',
    },
    bar: {
        backgroundColor: '#fff',
    },
})(LinearProgress);

const ZeroPinsBlock = ({user = null, userFilter = ''})=> {
    const message = (userFilter && userFilter !== user.id)
        ? 'It looks like they haven’t pinned any photos yet.'
        : `It looks like you haven’t pinned any photos yet.
            Browse galleries of homes on your board and look for
            this icon to add images that you love!`;
    return (
        <StyledDialogNoContent>
            <StyledIconBadge>
                <PushPinIcon fontSize="inherit" />
            </StyledIconBadge>
            {message}
        </StyledDialogNoContent>
    );
};


function PinspirationBoardModal({
    onClose,
    onModalClose,
    style = {},
    ...props
}) {
    const match = useRouteMatch();
    const {PinspirationStore, boardsStore, UserStore} = useStore();
    const [isOpen, setIsOpen] = useState(true);
    const [toggleView, setToggleView] = useState('gallery');
    const [userFilter, setUserFilter] = useState(PinspirationStore.filterUserId || '');
    const history = useHistory();
    const location = useLocation();
    const {trackSource = null} = location.state || {};
    const theme = useTheme();
    const gtXs = useMediaQuery(theme.breakpoints.up('sm'));
    const pinCollection = PinspirationStore.boardPinCollection;
    const loaded = PinspirationStore.boardLoaded;
    const loading = PinspirationStore.boardLoading;
    const hasMore = PinspirationStore.boardHasMore;
    const ref = React.createRef();
    const {id: boardId = null} = boardsStore.activeBoard || {};
    const {user = {}, usersBoardRole} = UserStore;
    // only show headshots in View All mode
    const showPinnedByHeadshots = PinspirationStore.filterUserId === '';
    const onboarding = useOnboarding();
    const queryStrings = queryString.parse(location.search);
    const requestSource = trackSource || queryStrings.source;
    const trackingData = useMemo(()=> {
        return {
            board_id: boardId,
            role: usersBoardRole,
        };
    }, [boardId, usersBoardRole]);
    const {
        isMobile,
    } = useDeviceBreakpoints();

    useEffect(()=> {
        let promiseCanceller;
        async function fetchCollaborations() {
            promiseCanceller = CancelToken.source();
            const cancelToken = promiseCanceller.token;
            await PinspirationStore.getCollaborations({boardId, cancelToken});
        }
        if(boardId) {
            try {
                fetchCollaborations();
            } catch (err) {
                logger.error('fetchCollaborations -> ', err);
            }
        }
        return ()=> {
            if(promiseCanceller) {
                promiseCanceller.cancel('Component unmounting');
            }
        };
    }, [boardId, PinspirationStore]);

    useEffect(()=> {
        if(!(user && boardId)) return;
        analytics.eventTrack('pinspiration_page_viewed', {
            source: requestSource,
            ...trackingData,
        });
    }, [user, boardId, trackingData, requestSource]);


    /**
     * Closes dialog and navigates back to board
     *
     * @function closeModal
     */
    const closeModal = useCallback(()=> {
        if(onboarding.isIdeaBoardOnboarding) {
            onboarding.next();
        }
        setIsOpen(false);
        setTimeout(()=> history.push('/boards'), 300);
    }, [history, onboarding]);

    /**
     * Invokes closeModal
     *
     * @function handleOnCloseClick
     */
    function handleOnCloseClick() {
        if(onModalClose) {
            onModalClose();
        } else {
            closeModal();
        }
    }

    /**
     * Set the toggle view
     *
     * @function handleViewToggleChange
     * @param {Event} e - the click event object
     * @param {String} value - can be ['list', 'gallery']
     */
    function handleViewToggleChange(e, value) {
        analytics.eventTrack(`pinspiration_${value}_view_toggle_click`, trackingData);
        setToggleView(value);
    }

    /**
     * Switches current user id filter
     *
     * @function handleUserFilterChange
     * @param {Event} e - the click/select event object
     * @param {String} value - the selected user's ID or '' for View All
     */
    async function handleUserFilterChange(e, value = '') {
        analytics.eventTrack('pinspiration_filter_click', trackingData);
        try {
            await PinspirationStore.setBoardUserFilter(value);
            setUserFilter(value);
        } catch (err) {
            logger.error('handleUserFilterChange', err);
        }
    }

    /**
     * Unpin click handler for Grid Tile Action Menu
     *
     * @function handleUnpinClick
     * @param {Event} e - the click event object
     * @param {Object} pinspiration - the formatted pinspiration object
        (see schema in the return value of the PinspirationStore.formatBoardPinItem() method)
            - photo: (required) The unpinned image URL
     */
    async function handleUnpinClick(e, pinspiration = {}) {
        analytics.eventTrack('pinspiration_image_unpinned', {
            ...trackingData,
            Source: 'pinspiration',
        });
        try {
            if(!(pinspiration && pinspiration.photo)) {
                throw new Error('pinspiration.photo missing');
            }
            await PinspirationStore.unpinItem({
                boardId,
                src: pinspiration.photo,
            });
        } catch (err) {
            logger.error('handleUnpinClick', err);
        }
    }

    /**
     * Grid tile click handler
     *
     * @function handleTileClick
     * @param {Event} e - the click event object
     * @param {Object} pinspiration - the formatted pinspiration object
        (see schema in the return value of the PinspirationStore.formatBoardPinItem() method)
        Required keys:
            - (cardId || encodedListingId)
            - addressSlug
     */
    function handleTileClick(e, pinspiration = null) {
        const {addressSlug, cardId, encodedListingId} = pinspiration || {};
        const destination = cardId
            ? `${match.url}/cards/${cardId}/${addressSlug}`
            : `${match.url}/listings/${encodedListingId}/agent/${boardsStore.activeBoard.owner.affiliateId}`;
        analytics.eventTrack('pinspiration_image_click', trackingData);
        history.push(destination, {trackSource: 'pinspiration'});
    }

    /**
     * Pagination - Load more - will only be invoked if hasMore
     *
     * @function fetchMoreCollaborations
     */
    async function fetchMoreCollaborations() {
        if(hasMore && !loading) {
            logger.debug('fetching more...');

            try {
                await PinspirationStore.getCollaborations({boardId});
            } catch (err) {
                logger.error('fetchMoreCollaborations ->', err);
            }
        }
    }

    /**
     * Scroll handler which triggers the load more fn
     * if we scrolled to the bottom of the target
     *
     * @function handleScroll
     */
    const handleScroll = (target)=> {
        if(target.scrollHeight - target.scrollTop === (target.clientHeight)) {
            // scrolled to the bottom
            fetchMoreCollaborations();
        }
    };

    /**
     * Debounced scroll handler
     *
     * @function handleScrollDebounced
     */
    const handleScrollDebounced = lodashDebounce(handleScroll, 500);

    /**
     * Scroll listener
     * Jumping through this hoop to account for sending the actual
     * event target reference to the handler (using e.persist())
     * instead of the synthetic event generated by React
     *
     * @function onScroll
     * @param {Event} e - the scroll event
     */
    const onScroll = (e)=> {
        if(e) {
            e.persist();
            handleScrollDebounced(e.target);
        }
    };

    return (
        <StyledDialog 
            fullScreen
            maxWidth="100%"
            hideBackdrop
            open={isOpen} 
            onExited={onClose}
            style={{
                marginTop: !isMobile && 64,
                ...style,
            }}
            {...props}
        >
            <Route
                path={`${match.path}/cards/:cardId/:address`}
                render={()=> (
                    <BoardCardModal open={true} onClose={()=> history.push(match.url)} from={openedFrom.pinspiration} />
                )}
            />
            <Route
                path={`${match.path}/listings/:listingIds`}
                render={()=> (
                    <BoardListingModal onClose={()=> history.push(match.url)} />
                )}
            />
            <Header
                defaultToggleValue={toggleView}
                initialUserFilterValue={userFilter}
                onClose={handleOnCloseClick}
                onViewToggleChange={handleViewToggleChange}
                onUserFilterChange={(e, v)=> handleUserFilterChange(e, v)}
            />

            {loading && <StyledLinearProgress />}

            {(((pinCollection || []).length === 0) && loaded) &&
                <ZeroPinsBlock
                    user={user}
                    userFilter={userFilter}
                />
            }

            {(((pinCollection || []).length > 0) && loaded) &&
                <StyledDialogContent ref={ref}
                    onScroll={onScroll}
                >
                    {/* PinspirationGrid View */}
                    {toggleView === 'gallery' && gtXs &&
                        <PinspirationGrid
                            pinCollection={pinCollection}
                            onUnpinClick={handleUnpinClick}
                            onTileClick={handleTileClick}
                            showPinnedByHeadshots={showPinnedByHeadshots}
                        />
                    }

                    {/* List View */}
                    {(toggleView === 'list' || !gtXs) &&
                        <PinspirationList
                            pinCollection={pinCollection}
                            parentRef={ref}
                            onUnpinClick={handleUnpinClick}
                            onTileClick={handleTileClick}
                            showPinnedByHeadshots={showPinnedByHeadshots}
                        />
                    }
                </StyledDialogContent>
            }
        </StyledDialog>
    );
}
PinspirationBoardModal.propTypes = {};

export default React.memo(observer(PinspirationBoardModal));
