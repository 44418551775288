
export function useSplitAddress(address) {
    const splitAddress = address?.split(',');
    const addressLine1 = splitAddress?.slice(0, 1) || '';
    const addressLine2 = splitAddress?.slice(1).join(',') || '';

    return {
        addressLine1,
        addressLine2,
    };
}
