import React, { useEffect, useMemo, useState } from 'react';
import { Grid, MenuItem, MenuList, Popover, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ThemeProvider, withTheme } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import { ToggleButton as _ToggleButton } from '@material-ui/lab';
import LRInput from '../../LRInput';
import _, { keyBy, partial } from 'lodash';
import { useStore, useActiveBoard } from '../../../mobx-store';
import LRAvatar from '../../LRAvatar';
import LRButton from '../../LRButton';
import { AddParticipantModal } from '../AddParticipantModal';
import { Add, ExpandMoreRounded } from '@material-ui/icons';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { MuiCalendarBlackTheme } from '../../../theme';
import { SCHEDULE_A_TOUR_WHEN_ASAP, SCHEDULE_A_TOUR_WHEN_LET_ME_PICK } from '../../../constants';
import Logger from 'js-logger';
import { LRDateSelect } from 'components/LRDateSelect';
import { LRTimeOfDaySelect } from 'components/LRTimeOfDaySelect';
import analytics from 'services/analytics';

const MAX_OTHER_PARTICIPANTS = 2;
const SelectionGroup = withTheme(styled.div`
    border-left: 4px solid #eee;
    padding-left: ${({ theme })=> theme.spacing(3)}px;
`);

const Button = withTheme(styled(LRButton)`
    height: 48px;
    color: ${({ theme })=> (theme.palette.lr_colors.grade)};
    text-transform: none;
    border-color: #bbb;
    padding-left: ${({ theme })=> theme.spacing(2)}px;
    padding-right: ${({ theme })=> theme.spacing(2)}px;

    ${({ variant })=> variant === 'contained' && (
        css`
            background-color: ${({ theme })=> (theme.palette.lr_colors.grade)};
            color: #fff;

            &:hover {
                background-color: ${({ theme })=> (theme.palette.lr_colors.grey_darkest)};
            }
        `
    )}

    &.Mui-disabled {
        color: rgba(0, 0, 0, 0.12);
        border-color: #bbb;
    }
`);

const DateAndTimePicker = function DateAndTimePicker({
    label = null,
    style = {},
    value = {},
    onChange = (data)=> {},
    ...props
}) {
    const theme = useTheme();
    const [date, setDate] = useState();
    const [openCalendar, setOpenCalendar] = useState(false);
    const [calendarRef, setCalendarRef] = useState();
    const [time, setTime] = useState('');
    const [openTime, setOpenTime] = useState(false);
    const [timeRef, setTimeRef] = useState();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
    const isiPadProDown = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const isiPad = useMediaQuery(theme.breakpoints.only('sm'), { noSsr: true });

    // UseEffects

    useEffect(()=> {
        onChange((date && time) ? { date: moment(date).toISOString(), time } : null);
    }, [date, time]);

    useEffect(()=> {
        if(!value) return;

        if(value.date) setDate(value.date);
        if(value.time) setTime(value.time);
    }, [value]);

    return (
        <Grid 
            container 
            className="FormItem-Selection"
            alignItems="center"
            spacing={2}
            style={{
                padding: theme.spacing(2),
                background: '#eee',
                borderRadius: '4px',
                ...(!isiPad && isiPadProDown && {
                    display: 'block',
                }) || {},
                ...style,
            }}
            {...props}
        >
            <Grid item xs>
                <Typography variant={isMobile ? 'body1' : 'h6'}>
                    {label}
                </Typography>
            </Grid>    
            
            <Grid item>
                <LRDateSelect 
                    value={date}
                    minWidth={129}
                    fullWidth={isMobile}
                    onChange={function (date) {
                        setDate(date);
                    }}
                />
            </Grid>
            
            <Grid item>
                <LRTimeOfDaySelect
                    value={time}
                    minWidth={129}
                    fullWidth={isMobile}
                    onChange={function (timeOfDay) {
                        setTime(timeOfDay);
                    }}
                />
            </Grid>    
        </Grid>
    );
};

export const FormLabel = withTheme(styled.label`
    margin-bottom: ${({ theme })=> (theme.spacing(2.5))}px;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        margin-bottom: ${({ theme })=> (theme.spacing(2))}px;
    }
`);

export const ToggleButton = withTheme(styled(_ToggleButton)`
    min-width: ${({ minWidth = '150px' })=> (minWidth)};
    color: ${({ theme })=> (theme.palette.lr_colors.grade)};
    text-transform: none;
    border-color: #bbb;
    padding-left: ${({ theme })=> theme.spacing(2)}px;
    padding-right: ${({ theme })=> theme.spacing(2)}px;

    &.Mui-selected,
    &.Mui-selected:hover {
        background-color: ${({ theme })=> (theme.palette.lr_colors.grade)};
        color: #fff;
    }
`);

export function FormItemBase({
    children,
    name,
    formField = null,
    required = false,
    autoFocus = false,
    hideRequiredLabel = false,
    ...props
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });

    return (
        <div className="FormItem-Root" {...props}>
            <Grid 
                container 
                className="FormItem-Label" 
                spacing={2} 
                component={FormLabel}
                alignItems="flex-end"
            >
                <Grid item xs>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                        {children}
                    </Typography>
                </Grid>

                {required && !hideRequiredLabel && (
                    <Grid item>
                        <Typography style={{ ...theme.typography.body4, color: theme.palette.lr_colors.steak_sauce }}>
                            Required
                        </Typography>
                    </Grid>
                )}
            </Grid>
            
            {formField}
        </div>
    );
};

export const FormItemText = React.forwardRef(function FormItemText(
    {
        children,
        type = 'text',
        name,
        required = false,
        autoFocus = false,
        inputProps = {},
        FormHelperTextProps = {},
        ...props
    }, 
    ref
) {
    const INPUT_PROPS = {
        className: 'FormItem-Input-Component',
        name: name,
        inputRef: ref,
        variant: 'outlined',
        fullWidth: true, 
        autoFocus: autoFocus,
        required: required,
        color: 'secondary',
        FormHelperTextProps: {
            ...FormHelperTextProps,
        },
        ...inputProps,
    };
    let formField = (
        <LRInput {...INPUT_PROPS} />
    );

    if(type === 'tel') {
        formField = (
            <InputMask
                mask="999-999-9999"
                maskChar=""
                alwaysShowMask={true}
            >
                {(imInputProps)=> (
                    <LRInput 
                        {...INPUT_PROPS}
                        {...imInputProps}
                    />
                )}
            </InputMask>
        );
    }

    return (
        <FormItemBase
            required={required} 
            formField={(
                <div className="FormItem-Input-Root">
                    {formField}
                </div>
            )}
            name={name}
            {...props}
        >
            {children}
        </FormItemBase>
    );
});

export function FormItemWhenSelection({
    children,
    name,
    ref,
    required = false,
    autoFocus = false,
    inputProps = {},
    defaultToggleValues = {},
    defaultDateTimeValues = {},
    onChange = (value)=> {},
    ...props
}) {
    const DEFAULT_TOGGLE_VALUES = {
        asap: false,
        letMePick: false,
    };
    const DEFAULT_VALUES = {
        first_choice: null,
        second_choice: null,
    };
    const theme = useTheme();
    const [toggleValues, setToggleValues] = useState(DEFAULT_TOGGLE_VALUES);
    const [value, setValue] = useState(DEFAULT_VALUES);

    // UseEffects

    useEffect(()=> {
        setToggleValues({
            ...DEFAULT_TOGGLE_VALUES,
            ...defaultToggleValues,
        });
    }, [defaultToggleValues]);

    useEffect(()=> {
        setValue({
            ...DEFAULT_VALUES,
            ...defaultDateTimeValues,
        });
    }, [defaultDateTimeValues]);

    // Functions

    function onToggleValueSelected(e, name) {
        setToggleValues({
            ...DEFAULT_TOGGLE_VALUES,
            [name]: true,
        });

        switch(name) {
            case SCHEDULE_A_TOUR_WHEN_ASAP:
                onChange({ first_choice: { asap: true } });
                setValue(DEFAULT_VALUES);
                break;

            case SCHEDULE_A_TOUR_WHEN_LET_ME_PICK:
                onChange(DEFAULT_TOGGLE_VALUES);
                break;

            default:
                break;
        }
    }

    function onDateTimeChange(name, data) {
        const newValue = {
            ...value,
            [name]: data,
        };

        if(value[name]?.date !== newValue[name]?.date || value[name]?.time !== newValue[name]?.time) {
            setValue(newValue);
            onChange(newValue);
        }
    }

    return (
        <FormItemBase
            required={required} 
            formField={(
                <div>
                    <Grid container className="FormItem-Selection-Root" spacing={2}>
                        <Grid item>
                            <ToggleButton selected={toggleValues.asap} onChange={partial(onToggleValueSelected, _, SCHEDULE_A_TOUR_WHEN_ASAP)}>ASAP</ToggleButton>
                        </Grid>
                        <Grid item>
                            <ToggleButton selected={toggleValues.letMePick} onChange={partial(onToggleValueSelected, _, SCHEDULE_A_TOUR_WHEN_LET_ME_PICK)}>Let me pick</ToggleButton>
                        </Grid>
                    </Grid>

                    {toggleValues.letMePick && (
                        <SelectionGroup style={{ marginTop: theme.spacing(3) }}>
                            <Typography variant="h6">
                                Select the dates and times that work best for you:
                            </Typography>
                            <Typography variant="caption" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                Dates and times are not guaranteed until confirmed by your agent.
                            </Typography>

                            <DateAndTimePicker 
                                label="First choice:" 
                                style={{ marginTop: theme.spacing(2), }} 
                                value={value.first_choice}
                                onChange={partial(onDateTimeChange, 'first_choice', _)} 
                            />
                            <DateAndTimePicker 
                                label="Second choice:" 
                                style={{ marginTop: theme.spacing(3), }} 
                                value={value.second_choice}
                                onChange={partial(onDateTimeChange, 'second_choice', _)} 
                            />
                        </SelectionGroup>
                    )}
                </div>
            )}
            name={name}
            {...props}
        >
            {children}
        </FormItemBase>
    );
};

export function FormItemWithSelection({
    children,
    name,
    ref,
    required = false,
    autoFocus = false,
    inputProps = {},
    value,
    onChange = ()=> {},
    ...props
}) {
    const DEFAULT_TOGGLE_VALUES = {
        yes: null,
        no: null,
    };
    const theme = useTheme();
    const [toggleValues, setToggleValues] = useState(DEFAULT_TOGGLE_VALUES);
    const { boardsStore, UserStore } = useStore();
    const activeBoard = useActiveBoard();
    const { user } = UserStore;
    const collaborators = boardsStore.activeBoard ? boardsStore.activeBoard.collaborators : [];
    const [additionalBoardUsers, setAdditionalBoardUsers] = useState([]);
    const [boardUsers, setBoardUsers] = useState([]);
    const toggledItems = useMemo(()=> {
        return keyBy(value || [], 'id');
    }, [value]);
    const totalSelectedBoardUsers = useMemo(()=> {
        if(!boardUsers?.length) return 0;

        return boardUsers.reduce((result, boardUser)=> {
            if(toggledItems[boardUser.id]) result++;

            return result;
        }, 0);
    }, [toggledItems, boardUsers]);
    const [openAddParticipantModal, setOpenAddParticipantModal] = useState(false);

    // UseEffects

    useEffect(()=> {
        setBoardUsers([
            ...collaborators,
            ...additionalBoardUsers,
        ]);
    }, [collaborators, additionalBoardUsers]);

    useEffect(()=> {
        if(value == null) return;

        if(value === false) {
            setToggleValues({
                ...DEFAULT_TOGGLE_VALUES,
                no: true,
            });
        } else if(value) {
            setToggleValues({
                ...DEFAULT_TOGGLE_VALUES,
                yes: true,
            });
        }
    }, [value]);

    // Functions

    function onOpenAddParticipantModal() {

        analytics.eventTrack('robin_schedule_a_tour_add_participant_button_click', {
            Source: 'schedule_a_home_modal',
            board_id: activeBoard?.id
        });

        setOpenAddParticipantModal(true);
    }

    function onCloseAddParticipantModal() {
        setOpenAddParticipantModal(false);
    }

    function onToggleValueSelected(e, name) {
        if(toggleValues[name]) return;

        setToggleValues({
            ...DEFAULT_TOGGLE_VALUES,
            [name]: true,
        });

        switch(name) {
            case 'yes':
                onChange([]);
                break;
            
            case 'no':
                onChange(false);
                break;

            default:
                break;
        }
    }

    function onToggleBoardUser(e, boardUser) {
        let participants;

        if(toggledItems[boardUser.id]) {
            participants = (value || []).filter((bu)=> (bu.id !== boardUser.id));
        } else {
            participants = [...(value || []), boardUser];
        }

        onChange(participants?.length ? participants : (toggleValues.no != null ? false : null));
    }

    function onAddParticipant(data, e) {

        analytics.eventTrack('robin_schedule_a_tour_custom_participant_added', {
            Source: 'schedule_a_home_modal',
            board_id: activeBoard?.id
        });

        setAdditionalBoardUsers([
            ...additionalBoardUsers,
            data,
        ]);
        setOpenAddParticipantModal(false);
        onToggleBoardUser(e, data);
    }

    return (
        <>
            <FormItemBase
                required={required} 
                formField={(
                    <div>
                        <Grid container className="FormItem-Selection-Root" spacing={2}>
                            <Grid item>
                                <ToggleButton selected={toggleValues.yes} onChange={partial(onToggleValueSelected, _, 'yes')}>Yes</ToggleButton>
                            </Grid>
                            <Grid item>
                                <ToggleButton selected={toggleValues.no} onChange={partial(onToggleValueSelected, _, 'no')}>No</ToggleButton>
                            </Grid>
                        </Grid>

                        {toggleValues.yes && (
                            <SelectionGroup style={{ marginTop: theme.spacing(3) }}>
                                <Typography variant="h6">
                                    Great! Please select everyone that will join you.
                                </Typography>
                                <Typography variant="caption" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                    Don't see them below? Add them by clicking on "Add Participant"
                                </Typography>

                                <Grid container style={{ marginTop: theme.spacing(2) }}>
                                    {boardUsers.map((boardUser)=> {
                                        if(user?.id === boardUser.userId) return null;

                                        return (
                                            <Grid
                                                item
                                                key={boardUser.id}
                                                style={{
                                                    paddingBottom: theme.spacing(1.5),
                                                    paddingRight: theme.spacing(1.5),
                                                }}
                                            >
                                                <ToggleButton 
                                                    selected={toggledItems[boardUser.id]}
                                                    minWidth="auto" 
                                                    onChange={partial(onToggleBoardUser, _, boardUser)}
                                                    disabled={totalSelectedBoardUsers >= MAX_OTHER_PARTICIPANTS && !toggledItems[boardUser.id]}
                                                >
                                                    <LRAvatar user={boardUser} size="xsmall" style={{ marginRight: theme.spacing(1), opacity: (totalSelectedBoardUsers >= MAX_OTHER_PARTICIPANTS && !toggledItems[boardUser.id] ? 0.3 : 1) }} /> {boardUser.first_name}
                                                </ToggleButton>
                                            </Grid>
                                        );
                                    })}

                                    <Grid
                                        item
                                        style={{
                                            paddingBottom: theme.spacing(1.5),
                                            paddingRight: theme.spacing(1.5),
                                        }}
                                    >
                                        <Button onClick={onOpenAddParticipantModal} variant="outlined" disabled={totalSelectedBoardUsers >= MAX_OTHER_PARTICIPANTS} startIcon={<Add />}>Add a participant</Button>
                                    </Grid>
                                </Grid>
                            </SelectionGroup>
                        )}
                    </div>
                )}
                name={name}
                {...props}
            >
                {children}
            </FormItemBase>


            <AddParticipantModal open={openAddParticipantModal} onClose={onCloseAddParticipantModal} onSubmit={onAddParticipant} />
        </>
    );
};
