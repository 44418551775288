import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import { Box, AppBar, Toolbar, Grid, Hidden } from '@material-ui/core';
import { ChevronRightRounded, ChevronLeftRounded, ExpandMoreRounded, CheckRounded } from '@material-ui/icons';
import ListingTile from './ListingTile';
import CategorySection from './CategorySection';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import LRDropdown from '../../LRDropdown';
import { useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
import _ from 'lodash';
import { ThemeProvider } from 'styled-components';
import { useCompareNowStoreContext } from '../store';
import { sortOptions, fieldsByCategory } from '../constants';
import { observer } from 'mobx-react-lite';
import Lockr from 'lockr';
import { useIsScrollPositionTop, useResponsiveBreakpoint }  from '../../../hooks';
import { MuiPrimaryBlackTheme } from 'theme';
import LRButton from 'components/LRButton';

function RenderDisplayOption({ label }) {
    return <Box style={{ cursor: 'pointer' }}>Sort by: {label}</Box>;
}

const DesktopCompare = observer(function DesktopCompare({handleOnClose, closeLabel, source, allLabel}) {
    const tiles3 = useResponsiveBreakpoint('md');
    const [container, setContainer] = useState(null);
    const tableBeginningRef = useCallback(setContainer, []);
    const visibleShadow = useIsScrollPositionTop(container);
    const store = useCompareNowStoreContext();
    const { UserStore, boardsStore, LanesStore } = useStore();
    const { isAgent, isLO  } = UserStore;
    const isAgentOrLO  = isAgent || isLO;
    const {activeBoard } = boardsStore;
    const boardPermissions = activeBoard?.userRoleOnBoard;

    // UseEffects

    useEffect(function() {
        store.changeWindowSize(tiles3 ? 3 : 4);
    }, [tiles3, store]);

    // Functions

    function handleOnLockClick(boardCard) {
        Lockr.set('compare-now-lock-clicked', true);
        store.toggleCardLock(boardCard);
        if(store.isCardLocked(boardCard)){
            analytics.eventTrack('robin_compare_listing_locked', {
                role: boardPermissions?.label
            });
        } else {
            analytics.eventTrack('robin_compare_listing_unlocked', {
                role: boardPermissions?.label
            });
        }
    }

    function handleRotateRightClick() {
        analytics.eventTrack('robin_compare_listing_next', {
            role: boardPermissions?.label
        });
        store.rotateRight();
    }

    function handleRotateLeftClick() {
        analytics.eventTrack('robin_compare_listing_previous', {
            role: boardPermissions?.label
        });
        store.rotateLeft();
    }

    function handleLaneChange(value) {
        if(!!value){
            const intValue = parseInt(value);

            const category = _.defaultTo(_.find(store.categories, ['index', value]), {label: 'all'});
            const category_label = _.snakeCase(category.label);
            analytics.eventTrack(`robin_${source ? (source + '_') : ''}compare_tool_${category_label}_view_toggle`, {});

            const filter = (intValue === 0) ? 'view_all': _.snakeCase(LanesStore.lanes[intValue - 1 ].name);
            analytics.eventTrack('robin_compare_tool_view_volumn_toggled_button_click', {
                filter,
                source: 'compare_tool'
            });
        }
    }

    return (
        <Box display="flex" flexDirection="column" height="100%" color="lr_colors.grade">
            <Box>
                <AppBar
                    position="static"
                    color="inherit"
                    className="HeaderNavBar-root"
                    elevation={0}
                    style={{
                        borderBottom: '1px solid #ddd',
                    }}
                >
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            {closeLabel && (
                                <Grid item>
                                    <LRButton startIcon={<ChevronLeftRounded />} onClick={handleOnClose}>
                                        {closeLabel}
                                    </LRButton>
                                </Grid>
                            )}
                            <Grid item>
                                <Hidden xsDown>
                                    <ThemeProvider theme={MuiPrimaryBlackTheme}>
                                        <ToggleButtonGroup
                                            value={store.filter}
                                            onChange={(e, value)=> {
                                                if(store.filter === value || !value) return;
                                                handleLaneChange(value);
                                                store.changeFilter(value);
                                            }}
                                            exclusive
                                        >
                                            <LRButton component={ToggleButton} value="0" style={{ height: 36 }} className="ToggleButton">
                                                {allLabel}
                                            </LRButton>

                                            {store.categories.map((category)=> (
                                                <LRButton key={category.index} component={ToggleButton} value={category.index} style={{ height: 36 }} className="ToggleButton">
                                                    {category.label} ({store.cardsByCategories[category.index].length})
                                                </LRButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </ThemeProvider>
                                </Hidden>
                            </Grid>

                            <Hidden xsDown>
                                <Grid item style={{ minWidth: 200 }}>
                                    <LRDropdown
                                        name="sortBy"
                                        selectedOption={store.sort}
                                        options={sortOptions}
                                        renderDisplayOption={RenderDisplayOption}
                                        onOptionSelect={(option)=> {
                                            analytics.eventTrack(`robin_${source ? (source + '_') : ''}compare_tool_sort_by_toggle`, {
                                                sort_by: _.snakeCase(option.label),
                                            });
                                            store.changeSort(option);
                                        }}
                                        textFieldIcon={ExpandMoreRounded}
                                        renderOption={({label})=> {
                                            return (
                                                <Box display="flex">
                                                    {store.sort.label === label &&
                                                        <Box mx={1} fontSize="body1.fontSize" display="flex" alignItems="center">
                                                            <CheckRounded fontSize="inherit" />
                                                        </Box>
                                                    }
                                                    {store.sort.label !== label &&
                                                        <Box width={30} />
                                                    }
                                                    <div>{label}</div>
                                                </Box>
                                            );
                                        }}
                                    />
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    style={
                        visibleShadow ?
                            {  boxShadow: '0 8px 4px -4px #E3E3E3', transition: 'box-shadow 0.3s' } :
                            {transition: 'box-shadow 0.3s'}
                    }
                    position="relative"
                    zIndex="1"
                    borderBottom="1px solid #E3E3E3"
                >
                    <Box pr="30px" flex="1" textAlign="right" alignSelf="center" minWidth="131px">
                        <ChevronLeftRounded onClick={handleRotateLeftClick} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box borderLeft="solid 1px #E3E3E3" />
                    {store.boardCardsWindow.map((listing, index)=> (
                        <ListingTile
                            key={listing.id}
                            listing={listing}
                            showLock={index < store.maxLocksAllowed}
                            onLockClick={handleOnLockClick}
                            display="flex"
                            canMove={!isAgentOrLO}
                        />
                    ))}
                    <Box pl="30px" flex="1" alignSelf="center">
                        <ChevronRightRounded onClick={handleRotateRightClick} style={{ cursor: 'pointer' }} />
                    </Box>
                </Box>
            </Box>
            <Box ref={tableBeginningRef} style={{ flex: 1, overflow: 'auto' }}>
                <Box width="100vw">
                    {Object.keys(fieldsByCategory)
                        .map((category)=> (
                            <CategorySection
                                key={category}
                                category={category}
                                fields={fieldsByCategory[category].attributes}
                                listings={store.boardCardsWindow}
                                initCollapsed={category === 'Basics' ? false : true}
                            />
                        ))}
                    <Box mb="50px" />
                </Box>
            </Box>
        </Box>
    );
});

DesktopCompare.propTypes = {
    listings: PropTypes.array,
    handleOnClose: PropTypes.func,
    fieldsByCategory: PropTypes.object
};

export default DesktopCompare;
