import React, { useContext, createContext, PureComponent } from 'react';
import PropTypes from 'prop-types';
import LanesStore from './lanes';
import { UserStore } from './user';
import { AccountStore } from './account';
import { ListingsMapStore } from './listings-map-store';
import { NotificationsStore } from './notifications';
import { OpenHouseCalendarStore } from './open-house-calendar';
import { Provider } from 'mobx-react';
import MortgageCalculatorStore from './mortgage-calculator';
import SchoolsStore from './schools';
import { GlobalSnackbarStore } from './global-snackbar';
import { GlobalConfettiStore } from './global-confetti';
import { BoardsStore } from './domain/boards-store';
import { ListingsStore as NewListingsStore, Listing } from './domain/listings-store';
import { ListpackStore } from './listpack';
import { InfographicsMapStore } from './infographics-map';
import { LrLiveStore } from './lr-live';
import { RateFarmStore } from './rate-farm';
import { ShareablesStore } from './shareables';
import PinspirationStore from './pinspiration';
import { PublicListing } from './public';
import { decorate, observable, reaction, when } from 'mobx';
import { AddAnyHomeStore } from './add-any-home';
import { LoginSignupModalStore } from './login-signup-modal';
import { useObserver } from 'mobx-react-lite';

const rootContext = createContext();

class RootStore {
    MortgageCalculatorStore = new MortgageCalculatorStore();
    SchoolsStore = new SchoolsStore();
    GlobalSnackbarStore = new GlobalSnackbarStore();
    GlobalConfettiStore = new GlobalConfettiStore();
    LrLiveStore = new LrLiveStore();

    constructor() {
        this.Listing = Listing;
        this.listingsStore = new NewListingsStore(this);
        this.boardsStore = new BoardsStore(this);
        this.LanesStore = new LanesStore(this);
        this.NotificationsStore = new NotificationsStore(this);
        this.UserStore = new UserStore(this);
        this.AccountStore = new AccountStore(this);
        this.OpenHouseCalendarStore = new OpenHouseCalendarStore(this);
        this.ListingsMapStore = new ListingsMapStore(this);
        this.ListpackStore = new ListpackStore(this);
        this.InfographicsMapStore = new InfographicsMapStore(this);
        this.RateFarmStore = new RateFarmStore(this);
        this.ShareablesStore = new ShareablesStore(this);
        this.PinspirationStore = new PinspirationStore(this);
        this.PublicListing = new PublicListing(this);
        this.AddAnyHomeStore = new AddAnyHomeStore(this);
        this.LoginSignupModalStore = new LoginSignupModalStore();
    }

    async fetchInitialData({ bearerToken, testOnboarding } = {}) {
        if(bearerToken) {
            await this.UserStore.fetchUserFromBearerToken(bearerToken);
        } else {
            await this.UserStore.fetchLocalUser({ testOnboarding });
        }

        const currentUser = this.UserStore.user;
        if(!currentUser) {
            return;
        }

        try {
            this.LanesStore.loadingListings = true;
            // TODO: Logic to fetch user boards should be moved to routeable board component.
            // await Promise.all([this.boardsStore.fetchUserBoards(), this.NotificationsStore.fetchNotifications()]);
            await Promise.all([this.boardsStore.fetchUserBoards()]);
        } finally {
            this.LanesStore.loadingListings = false;
        }
    }

    get inProductionMode() {
        return process.env.REACT_APP_MODE === 'production';
    }
}

decorate(RootStore, {

});

const rootStore = new RootStore();
/**
 * This is used so we can use mobx-react with functions. This
 * setup was based on the recomendation for migrating to hooks.
 * https://mobx-react.netlify.com/recipes-migration
 */
export class MobxBridgeProvider extends PureComponent {
    render() {
        return <rootContext.Provider value={this.context.mobxStores}>{this.props.children}</rootContext.Provider>;
    }
}
MobxBridgeProvider.contextTypes = {
    mobxStores: PropTypes.object,
};

export const StoreProvider = ({ children })=> {
    return (
        <Provider RootStore={rootStore} {...rootStore}>
            <MobxBridgeProvider>{children}</MobxBridgeProvider>
        </Provider>
    );
};

/**
 * This is our main store you will use for functional
 * components.
 */
export function useStore() {
    return useContext(rootContext);
}

export function useActiveBoard() {
    const { boardsStore } = useStore();
    return useObserver(()=> boardsStore?.activeBoard);
}

/**
 * Store Exports
 */
export default rootStore;
