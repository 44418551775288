// eslint-disable-next-line
import Logger from 'js-logger';
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import { defineAbilityFor } from '../../abilities/defineAbility';
import { AbilityContext } from '../Ability';
import { LoggedOutListingView } from 'scenes/LoggedOutListingView';

function PrivateRoute({ component: Component, render, ...props }) {
    const location = useLocation();
    const { UserStore } = useStore();
    const hasAttemptedToFetchUser = UserStore.hasAttemptedToFetchUser;

    function renderFunc(props) {
        if(!hasAttemptedToFetchUser) {
            return <></>;
        }

        // logged out
        if(!UserStore.user) {
            return (
                <Switch>
                    <Route
                        path={[
                            '/boards/cards/:cardId',
                            '/boards/lane-expanded/:laneId/:laneName/cards/:cardId',
                            '/boards/listpack/:listpackId/listings/listing/:listingId',
                            '/boards/compare-now/cards/:cardId',
                            '/boards/scheduled-tours/:tourId/cards/:cardId',
                            '/boards/archivedListings/:cardId',
                            '/boards/idea-board/listings/:listingId/agent/:agentId',
                            '/boards/idea-board/cards/:cardId',
                        ]}
                        render={()=> <LoggedOutListingView />}
                    />
                    <Route render={()=> <Redirect to={{ pathname: '/login', state: { from: location } }} />} />
                </Switch>
            );
        }
        const ability = defineAbilityFor(UserStore.user);

        return (
            <AbilityContext.Provider value={ability}>
                {render ? render.apply(render, arguments) : <Component {...props} />}
            </AbilityContext.Provider>
        );
    }

    return <Route {...props} render={renderFunc} />;
}
PrivateRoute.propTypes = {
    component: PropTypes.elementType,
    render: PropTypes.func,
};

export default observer(PrivateRoute);
