import Logger from 'js-logger';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

const logger = Logger.get('ModalRoute');

export const ModalRoute = function ModalRoute({
    Component,
    ComponentProps = {},
    renderOnlyRouteMatch = false, // DO NOT USE THIS PROPERTY // TODO: remove this
    useSwitch,
    ...props
}) {
    const location = useLocation();
    const [previousLocation, setPreviousLocation] = useState();
    const orgOnEntered = ComponentProps?.onEntered;
    const orgOnExited = ComponentProps?.onExited;
    const open = useMemo(() => {
        return Boolean(matchPath(`${location.pathname}${location.search}}`, props));
    }, [location.pathname, location.search, props]);

    // Functions

    function onEntered(...args) {
        setPreviousLocation(location);
        if (orgOnEntered) orgOnEntered(args);
    }

    function onExited(...args) {
        setPreviousLocation(null);
        if (orgOnEntered) orgOnExited(args);
    }

    // TODO: This is not a good fix for whatever issue this is supposed to fix.
    if (renderOnlyRouteMatch) {
        return (
            <Route
                {...props}
                children={({ match }) => {
                    if (match) {
                        return <Component {...ComponentProps} open={Boolean(match)} />;
                    } else {
                        return null;
                    }
                }}
            />
        );
    }

    return useSwitch ? (
        <Switch location={open ? location : previousLocation || location}>
            <Route
                {...props}
                children={() => <Component {...ComponentProps} open={open} onEntered={onEntered} onExited={onExited} />}
            />
        </Switch>
    ) : (
        <Route {...props} children={({ match }) => <Component {...ComponentProps} open={Boolean(match)} />} />
    );
};
