import React from 'react';
import { Box, Divider, withTheme } from '@material-ui/core';
import { useStore } from '../../../../mobx-store';
import { observer } from 'mobx-react-lite';
import LRAvatar from '../../../../components/LRAvatar';
import LRButton from '../../../../components/LRButton';
import styled from 'styled-components';
import formatPhoneNumber from '../../../../services/format-phone-number';
import { Can } from '../../../../components/Ability';
import { subject } from '@casl/ability';
import analytics from '../../../../services/analytics';
import { useBoardCollaborations } from '../../../../hooks/useBoardCollaborations';
import Logger from 'js-logger';

const logger = Logger.get('CollaboratorsList');

const Avatar = styled(LRAvatar)`
    font-size: 16px;
    width: 48px;
    height: 48px;
`;

const Item = withTheme(styled.div`
    padding-left: 16px;
    ${({theme})=> theme.breakpoints.up('sm')}{
        padding-left: 0;
    }
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;

    flex-direction: column;
    ${({theme})=> theme.breakpoints.up('sm')}{
        flex-direction: row;
        justify-content: space-between;
    }
`);

const Info = styled.div`
    display: flex;
    flex-direction: row;
    flex-basis: 60%;
`;

const Actions = withTheme(styled.div`
    margin-left: 54px;

    ${({theme})=> theme.breakpoints.up('sm')}{
        display: flex;
        margin-left: 0;
        flex-direction: column;
        align-items: flex-end;
        flex: 1;
    }

`);

const UserInfo = styled.div`
    margin-left: 14px;
    word-break: break-all;
`;

const InfoEntry = styled.div`
    ${(props)=> props.name && 'font-weight: 500;'}
    line-height: 1;
    margin-bottom: 2px;
    font-size: 16px;
    ${(props)=> props.pending && 'color: #A1A1A1;'}
    ${(props)=> props.pending && 'margin-top: 3px;'}
`;

const ActionLabel = styled.span`
    line-height: 1;
    font-size: 16px;
`;

const ReinviteButton = withTheme(styled(LRButton)`
    margin-right: 16px;
    ${({theme})=> theme.breakpoints.up('sm')}{
        margin-right: 0px;
        margin-bottom: 4px;
    }
`);

function Collaborator({collaborator, board, user, onRemoveClick, onResendInviteClick, onLeaveClick}){
    const { userId, name, email, phone, type } = collaborator;

    const invitePending = type === 'invite';

    return (
        <Item>
            <Info>
                <Avatar user={collaborator} />
                <UserInfo>
                    <InfoEntry name>{name}</InfoEntry>
                    <InfoEntry>{formatPhoneNumber(phone)}</InfoEntry>
                    <InfoEntry>{email}</InfoEntry>
                    {invitePending && <InfoEntry pending>Pending</InfoEntry>}
                </UserInfo>
            </Info>
            <Actions>
                <Can I="update" this={subject('Board',board)}>
                    {invitePending && (
                        <ReinviteButton color="secondary" variant="text" onClick={()=> onResendInviteClick(collaborator)}>
                            <ActionLabel>Re-send invite</ActionLabel>
                        </ReinviteButton>
                    )}
                </Can>
                <Can I="write" this={subject('Board', board)}>
                    {userId !== user.id  && (
                        <LRButton color="secondary" variant="text" onClick={()=> onRemoveClick(collaborator)}>
                            <ActionLabel>Remove</ActionLabel>
                        </LRButton>
                    )}
                </Can>
                {userId !== board.ownerId && userId === user.id && (
                    <LRButton color="secondary" variant="text" onClick={()=> onLeaveClick(collaborator)}>
                        <ActionLabel>Leave</ActionLabel>
                    </LRButton>
                )}
            </Actions>
        </Item>
    );
}

export const CollaboratorsList = observer(function CollaboratorsList(props){
    const { boardsStore, UserStore, GlobalSnackbarStore } = useStore();
    const { activeBoard } = boardsStore;
    const { user } = UserStore;
    const collaborators = activeBoard.collaborators;
    const {
        addBoardCollaboration,
    } = useBoardCollaborations(activeBoard?.id);

    async function createBoardCollaboration(collaborator, type) {
        const isInvite = collaborator?.type === 'invite';
        if(!isInvite && collaborator?.userId) {
            const data = {
                boardId: activeBoard?.id,
                itemId: collaborator.id,
                model: 'BoardUserPermissions',
                content: {
                    user_id: collaborator.userId,
                    first_name: collaborator.first_name,
                    last_name: collaborator.last_name,
                    name: collaborator.name,
                    email: collaborator.email,
                    headshot: collaborator.headshot,
                },
                type,
            };

            try {
                await addBoardCollaboration(data);
            } catch (err) {
                logger.debug('createBoardCollaboration err', data, err);
            }
        }
    }

    async function onRemoveCollaboratorClick(collaborator){
        try {
            await activeBoard.removeCollaborator(collaborator);
            analytics.eventTrack('robin_cobuyer_removed', {
                lead_id: collaborator.id,
                board_id: activeBoard.id
            });
            createBoardCollaboration(collaborator, 'user_removed');
            GlobalSnackbarStore.show('Co-buyer has been removed', {
                autoHideDuration: 3000,
                action: (
                    <>
                        <LRButton color="secondary" onClick={()=> {
                            GlobalSnackbarStore.hide();
                            activeBoard.undoLastRemoveCollaborator();
                        }}>Undo</LRButton>
                    </>)
            });
        } catch (err){
            GlobalSnackbarStore.show('There was an error while removing co-buyer');
        }
    }

    async function onResendInviteClick(collaborator){
        try {
            await activeBoard.resendInviteEmail(collaborator.id);
            analytics.eventTrack('robin_cobuyer_invite_resent', {
                lead_id: collaborator.id,
                board_id: activeBoard.id,
                invite_id: collaborator.id,
            });
            GlobalSnackbarStore.show('Invite has been resent');
        } catch (err){
            GlobalSnackbarStore.show('There was an error while reinviting co-buyer');
        }
    }

    async function onLeaveClick(collaborator){
        await createBoardCollaboration(collaborator, 'user_left');
        await activeBoard.removeCollaborator(collaborator);
        UserStore.logout();
        window.location = '/';
    }

    return (
        <Box mx={[0, 7]} pb={14} >
            {collaborators.map((colab, index)=> {
                return  (
                    <div key={colab.id} style={{width: '100%'}}>
                        <Collaborator collaborator={colab} board={activeBoard} user={user}
                            onRemoveClick={onRemoveCollaboratorClick} onResendInviteClick={onResendInviteClick} onLeaveClick={onLeaveClick} />
                        {index !== collaborators.length -1 && <Divider />}
                    </div>
                );
            })}
        </Box>
    );
});
