import { AppBar, ButtonBase, DialogActions, DialogContent, DialogTitle, Slide, Toolbar, Typography, useTheme } from '@material-ui/core';
import LRButton from 'components/LRButton';
import { LRDialog } from 'components/LRDialog';
import { SUBSCRIPTION_HOME_REPORT_LEAD_GEN } from '../../../constants';
import { useGetSubscriptionStatus } from 'hooks/data/user-addons';
import { useAgent } from 'hooks/useAgent';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useMyHome } from 'hooks/useMyHome';
import Logger from 'js-logger';
import { useStore } from 'mobx-store';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import analytics from 'services/analytics';

const logger = Logger.get('OptIntoHomeReportTextMessage');

export function useCanViewOptinTextMessage() {
    const { lead, isLoading } = useMyHome();
    const { UserStore } = useStore();
    const { isLoggedIn } = useIsLoggedIn();
    const { data:agent } = useAgent();
    const { data:leadGenSubscriptionResponse } = useGetSubscriptionStatus(
        agent?.id,
        SUBSCRIPTION_HOME_REPORT_LEAD_GEN
    );
    const leadGenSubscriptionActive = useMemo(()=> Boolean(leadGenSubscriptionResponse?.active), [leadGenSubscriptionResponse]);

    return useMemo(()=> {
        return Boolean(
            (
                // (isLoggedIn && leadGenSubscriptionActive && UserStore?.user?.phone && !UserStore?.user?.notification_settings?.robin_myhome?.text) ||
                !isLoggedIn &&
                (lead && lead?.meta?.robin?.home_report?.status !== 'opted-in')
            ) &&
            !isLoading
        );
    }, [UserStore, isLoading, isLoggedIn, lead, leadGenSubscriptionActive]);
}

function OptInDialog({
    open = false,
    onClose = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const { lead, optinToMyHomeTextMessage } = useMyHome();
    const [disableButtons, setDisableButtons] = useState(false);

    // Functions

    async function onEnableClick(e) {
        setDisableButtons(true);

        try {
            const trackSource = 'home_report_page';
            await optinToMyHomeTextMessage(lead?.id, trackSource);
        } catch (err) {
            logger.error('OptInDialog -> onEnableClick -> Error', err);
        } finally {
            setDisableButtons(false);
        }

        analytics.eventTrack('robin_invite_product_opt_in');
        onClose(e);
    }

    return (
        <LRDialog
            {...props}
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '370px',
                },
            }}
        >
            <DialogTitle disableTypography style={{ paddingBottom: theme.spacing(1), }}>
                <Typography variant="h6" style={{ fontWeight: 500, }}>
                    Get your monthly home report texted to you
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Typography variant="body2">
                    Enable text message notifications and you won’t miss important market and home activity.
                </Typography>
            </DialogContent>

            <DialogActions style={{ padding: theme.spacing(3), paddingTop: theme.spacing(2), justifyContent: 'space-between' }}>
                <LRButton variant="text" color="inherit" underline inline onClick={analytics.eventTrackOnEvent('robin_clippy_cancel_click', {}, onClose)} disabled={disableButtons}>
                    No thanks
                </LRButton>

                <LRButton variant="contained" color="primary" onClick={onEnableClick} disabled={disableButtons}>
                    Enable
                </LRButton>
            </DialogActions>
        </LRDialog>
    );
}

export const OptIntoHomeReportTextMessage = React.memo(function OptIntoHomeReportTextMessage({
    showDelay = 2000, // in MS
    style = {},
    ButtonStyle = {},
    onClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile, isIPadDown } = useDeviceBreakpoints();
    const preventReopen = useRef(false);
    const [show, setShow] = useState(false);
    const [openOptInDialog, setOpenOptInDialog] = useState(false);
    const canViewMessage = useCanViewOptinTextMessage();

    // Functions

    function onButtonClick(e) {
        onClick(e);
        setOpenOptInDialog(true);
        setShow(false);
        preventReopen.current = true;
    }

    function onCloseOptInDialog(e) {
        setOpenOptInDialog(false);
        preventReopen.current = false;
    }

    // UseEffects

    useEffect(()=> {
        if(show) return;

        const toId =  setTimeout(()=> {
            setShow(true);
        }, showDelay);

        return ()=> {
            clearTimeout(toId);
        };
    }, [show, showDelay]);

    return (
        <>
            <Slide in={show && !preventReopen.current && canViewMessage} direction="up">
                <AppBar
                    color="transparent"
                    elevation={0}
                    style={{
                        top: 'auto',
                        bottom: 0,
                        padding: theme.spacing(isMobile ? 2 : 4),
                        paddingTop: 0,
                        ...style,
                    }}
                >
                    <ButtonBase
                        {...props}
                        style={{
                            backgroundColor: theme.palette.lr_colors.grade,
                            borderRadius: 4,
                            maxWidth: 1112,
                            width: '100%',
                            margin: '0 auto',
                            padding: isIPadDown && theme.spacing(2),
                            ...ButtonStyle,
                        }}
                        onClick={analytics.eventTrackOnEvent('robin_invite_clippy_click', {}, onButtonClick)}
                    >
                        <Toolbar>
                            <div style={{ textAlign: isMobile ? 'left' : 'center', width: '100%', }}>
                                <Typography component="span" style={{ fontWeight: 500, color: theme.palette.lr_colors.charlie, }}>
                                    Don’t miss out on important market changes!
                                </Typography> <Typography component="span" style={{ color: '#fff', }}>
                                    <span style={{ textDecoration: 'underline' }}>Enable monthly texts</span> with a link to this home report.
                                </Typography>
                            </div>
                        </Toolbar>
                    </ButtonBase>
                </AppBar>
            </Slide>

            <OptInDialog open={openOptInDialog} onClose={onCloseOptInDialog} />
        </>
    );
});
