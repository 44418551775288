import { CircularProgress, Collapse, Grid, Typography, useTheme } from '@material-ui/core';
import LRButton from 'components/LRButton';
import { MY_HOME_SERVICE_PROVIDER_HANDYPERSON } from '../../../constants';
import Logger from 'js-logger';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { Section, SectionContainer, SectionHeader, SectionTitle } from '../Section';
import { ServiceProvidersIcon } from '../ServiceProvidersIcon';
import analytics from 'services/analytics';
import LRTooltip from 'components/LRTooltip';
import { useMyHome, useMyHomeServiceProviders } from 'hooks/useMyHome';
import { useQuery } from 'hooks';
import { hyphenateSync } from 'hyphen/en';

function ServiceProvider({
    data:serviceProvider = {},
    ...props
}) {
    const theme = useTheme();
    return (
        <SectionContainer
            key={serviceProvider.id}
            style={{
                borderBottom: `1px solid ${theme.palette.lr_colors.border_grey}`,
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
                paddingLeft: 0,
                paddingRight: 0,
            }}
        >
            <Grid 
                container 
                spacing={2} 
                wrap="nowrap"
            >
                <Grid item>
                    <div
                        style={{
                            width: 28,
                            height: 28,
                            backgroundColor: '#000',
                            color: '#fff',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ServiceProvidersIcon variant={serviceProvider.type} style={{ width: 16, height: 16, }} />
                    </div>
                </Grid>

                <Grid item xs zeroMinWidth>
                    <Typography variant="caption" style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                        {serviceProvider.type}
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {serviceProvider.name && hyphenateSync(serviceProvider.name)}
                    </Typography>

                    {/* Here we are using German language, because we want hyphens to occur when the space is small for the text, does not work well with english language.*/}
                    <Typography variant="body1">
                        {serviceProvider.company && hyphenateSync(serviceProvider.company)}
                    </Typography>
                    <Typography variant="body1">
                        {serviceProvider.phone_formatted}
                    </Typography>
                </Grid>
            </Grid>
        </SectionContainer>
    );
}

export function MyServiceProviders({
    isAgentOrLO = false,
    onAddClick = (e)=> {},
    onViewAllClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const { myHome:home } = useMyHome();
    const serviceProviders = useMyHomeServiceProviders();
    const providers = useMemo(()=> {
        const data = serviceProviders?.data || [];
        return orderBy(data, ['createdAt'], ['desc']).slice(0, 3);
    }, [serviceProviders]);
    const mode = useQuery().get('mode');

    return (
        <Section {...props}>
            <SectionHeader>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs>
                        <SectionTitle>
                            My service providers
                        </SectionTitle>
                    </Grid>
                    {mode !== 'ro' && <Grid item>
                        <LRTooltip title={isAgentOrLO && 'Only a buyer or co-buyer can add service providers to their Home Report.'} placement="top" hideArrow={false}>
                            <div>
                                <LRButton
                                    variant="outlined"
                                    color="default"
                                    onClick={!isAgentOrLO && analytics.eventTrackOnEvent('robin_home_report_service_provider_add_button_click', null, onAddClick)}
                                    disabled={!home || isAgentOrLO}
                                >
                                    Add
                                </LRButton>
                            </div>
                        </LRTooltip>
                    </Grid>}
                </Grid>
            </SectionHeader>

            {serviceProviders.isLoading && (
                <Grid container justify="center" style={{ padding: theme.spacing(2), }}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}

            <Collapse in={!serviceProviders.isLoading && serviceProviders?.data?.length}>
                {providers.map((serviceProvider)=> (<ServiceProvider data={serviceProvider} />))}
            </Collapse>

            {Boolean(serviceProviders?.data?.length) && (
                <SectionContainer
                    style={{
                        marginTop: -1,
                        textAlign: 'center',
                    }}
                >
                    <LRButton 
                        variant="text" 
                        inline 
                        underline 
                        color="inherit" 
                        onClick={analytics.eventTrackOnEvent('robin_home_report_service_provider_view_all_link_click', null, onViewAllClick)}
                    >
                        View all
                    </LRButton>
                </SectionContainer>
            )}

            {!Boolean(serviceProviders?.data?.length) && !serviceProviders.isLoading && (
                <Grid container justify="center" alignItems="center" style={{ minHeight: 221, }}>
                    <Grid item xs style={{ padding: theme.spacing(4), paddingBottom: theme.spacing(6) }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                backgroundColor: theme.palette.lr_colors.steak_sauce,
                                color: '#fff',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto 16px',
                            }}
                        >
                            <ServiceProvidersIcon variant={MY_HOME_SERVICE_PROVIDER_HANDYPERSON} style={{ width: 27, height: 27, }} />
                        </div>

                        <Typography variant="h6" style={{ fontWeight: 'normal', textAlign: 'center', color: theme.palette.lr_colors.steak_sauce, }}>
                            Add your service providers here to keep them all in one place.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Section>
    );
}
