import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import styled from 'styled-components';
import LRButton from '../../LRButton';
const paperProps = {
    style: {
        width: '280px',
        borderRadius: '12px',
    },
};

const Content = styled(DialogContent)`
    text-align: center;
    padding: 8px 16px;
`;

const Actions = styled(DialogActions)`
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
`;

export function CancelTourConfirmationModal({ open, title, onConfirm, onCancel, ...props}){

    return (
        <Dialog open={open} PaperProps={paperProps}>
            <Content>
                <Typography variant="h6">
                    {title}
                </Typography>
            </Content>
            <Actions>
                <LRButton
                    color="inherit"
                    variant="text"
                    underline
                    onClick={onCancel}
                >
                    Never mind
                </LRButton>
                <LRButton color="primary" variant="contained" onClick={onConfirm}>
                    Yes, cancel
                </LRButton>
            </Actions>
        </Dialog>
    );
}
