import React from 'react';
import { Hidden, Typography, useTheme } from '@material-ui/core';
import LRAvatar from 'components/LRAvatar';
import formatPhoneNumber from 'services/format-phone-number';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

const AgentBrandingHeader = React.memo(function AgentBrandingHeader({
    agent,
    hideCompanyLogo,
    hideLicenseNumber,
    hideCompanyName,
    style = {}
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();

    const isCaliforniaAgent = agent.licensed_address?.state === 'CA' || agent.licensed_address?.state === 'California';

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: theme.spacing(8),
                padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
                ...style,
            }}
        >
            <div
                className="space-x-3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {agent.headshot && <LRAvatar user={agent} size="small"></LRAvatar>}
                <div style={{...(isMobile && {display: 'flex', flexDirection: 'column'})}}>
                    <div className="space-x-2">
                        <Typography
                            variant="body2"
                            component="span"
                            style={{lineHeight: '19px', fontWeight: 600}}>
                            {agent.first_name} {agent.last_name}
                        </Typography>
                        {!hideLicenseNumber && (
                            <Hidden smDown>
                                <Typography
                                    variant="body2"
                                    style={{
                                        lineHeight: '14px',
                                        color: theme.palette.lr_colors.grey_dark
                                    }}
                                    component="span"
                                >
                                    {isCaliforniaAgent ? 'CA DRE #: ' : 'Lic #: '}
                                    {agent.realtor_license_number}
                                </Typography>
                            </Hidden>
                        )}
                    </div>
                    <div style={{...(isMobile && {display: 'flex', flexDirection: 'column'})}}>
                        {!hideCompanyName && agent.company && (
                            <Typography
                                variant="body2"
                                component="span"
                                style={{lineHeight: '14px'}}>
                                {agent.company}
                            </Typography>
                        )}
                        {!isMobile && !hideCompanyName && agent.company && agent.phone && (
                            <Typography variant="caption" style={{ color: '#ababab', marginLeft: isMobile ? 0 : theme.spacing(1) }}>
                                |
                            </Typography>
                        )}
                        {agent.phone && (
                            <Typography
                                component="span"
                                variant="body2"
                                style={{
                                    marginLeft: isMobile ? 0 : theme.spacing(1),
                                    lineHeight: '14px'
                                }}>
                                {formatPhoneNumber(agent.phone)}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
            {!hideCompanyLogo && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt={agent.company} src={agent.branding} style={{ height: '36px', paddingLeft: '42px' }} />
                </div>
            )}
        </div>
    );
});

export default AgentBrandingHeader;
