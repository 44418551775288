import { LEGACY_LANES_NAMES, SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION, SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_MOVE_CARD_OR_LISTING } from '../../constants';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { useActiveBoard, useStore } from 'mobx-store';
import { useEffect, useRef, useState } from 'react';
import { snakeCase } from 'lodash';
import splitAddress from '../../services/split-address';
import Logger from 'js-logger';
import Lockr from 'lockr';

const logger = Logger.get('useMoveCardOrListing');
const SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE = SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_MOVE_CARD_OR_LISTING;

export function useMoveCardOrListing(source) {
    const { LoginSignupModalStore, UserStore, GlobalSnackbarStore } = useStore();
    const activeBoard = useActiveBoard();
    const [moveListingDestinationWhenBoardIsActive, setMoveListingDestinationWhenBoardIsActive] = useState();
    const { addBoardCollaboration } = useBoardCollaborations();
    const cancelOnSuccessEventListenerRef = useRef();
    const cancelOnBeforeOAuthRedirectRef = useRef();

    // UseEffects

    /**
     Handles removing the event listener for onSuccess. We need to do this because
     if a user decides not to continue and do a different action we dont want this
     action to also run. Basically prevents a bug of using this in multiple places
     in the code.
     */
    useEffect(()=> {
        if(LoginSignupModalStore.isOpen) return;

        if(cancelOnSuccessEventListenerRef.current) {
            cancelOnSuccessEventListenerRef.current();
        }

        if(cancelOnBeforeOAuthRedirectRef.current) {
            cancelOnBeforeOAuthRedirectRef.current();
        }
    }, [LoginSignupModalStore.isOpen]);

    /**
     Handles moving the listing once the user has logged in
     and we have an active board. This prevents an error when
     useing a straight callback.
     We need to wait for activeBoard.loadedBoardData to be true
     or it can cause duplicate cards to be created.
     */
    useEffect(()=> {
        if(moveListingDestinationWhenBoardIsActive && activeBoard.loadedBoardData) {
            moveCardOrListing.apply(null, moveListingDestinationWhenBoardIsActive);
            setMoveListingDestinationWhenBoardIsActive(null);
        }
    }, [activeBoard?.loadedBoardData, moveListingDestinationWhenBoardIsActive]);

    // Functions

    /**
     * @param {Object} boardCard - Must include listing_details
     * @param {Object} destinationLane
     * @param {String} fromLaneName
     * @param {Function} [cb] - (boardCard)=> {}
     * @returns Promise
     */
    async function moveCardOrListing(boardCard, destinationLane, fromLaneName, cb) {
        if(!UserStore.isLoggedIn) {
            LoginSignupModalStore.openSignupModal(source);
            cancelOnBeforeOAuthRedirectRef.current = LoginSignupModalStore.onBeforeOAuthRedirect(()=> {
                Lockr.set(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION, {
                    type: SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE,
                    data: {
                        boardCard: {
                            id: boardCard.id,
                            listing_details: {
                                id: boardCard.listing_details.id,
                                encodedId: boardCard.listing_details.encodedId,
                                address: boardCard.listing_details.address,
                            },
                        },
                        destinationLane,
                        fromLaneName,
                    },
                });
            });
            cancelOnSuccessEventListenerRef.current = LoginSignupModalStore.onSuccess(()=> moveListingCallback(boardCard, destinationLane, fromLaneName, cb));
            return;
        }

        if(!activeBoard?.loadedBoardData) {
            moveListingCallback(boardCard, destinationLane, fromLaneName, cb);
            return;
        }

        const listing = boardCard.listing_details;

        if(!boardCard.id) {
            boardCard = await activeBoard.moveListpackCard(listing, destinationLane.id);
        } else {
            await activeBoard.moveCard(boardCard.id, destinationLane.id, 0);
        }

        const toColumn = LEGACY_LANES_NAMES[snakeCase(destinationLane.name)];

        // Create board collaboration
        addBoardCollaboration({
            boardId: activeBoard?.id,
            itemId: boardCard?.id,
            content: {
                fromColumn: fromLaneName,
                toColumn: toColumn ? toColumn : destinationLane.name,
            },
            type: 'moved_card',
        });

        // Show global snackbar message
        const [addressLine1] = splitAddress(listing.address);
        GlobalSnackbarStore.show(`${addressLine1} has been moved to ${destinationLane.name}`, {
            autoHideDuration: 2000,
        });

        // Call cb
        if(cb) cb(boardCard);
    }

    function moveListingCallback(...args) {
        setMoveListingDestinationWhenBoardIsActive(args);
    }

    return moveCardOrListing;
}
