import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { v4 } from 'uuid';
import { AutocompleteInput } from '../AutocompleteInput';

function useUniqueKey(defaultKey) {
    const generatedKey = useMemo(()=> v4(), []);
    const keyRef = useRef(defaultKey || generatedKey);

    return keyRef.current;
}

function makeSearchRequest(requestFunction) {
    return function({ queryKey }) {
        const [, searchTerm] = queryKey;
        return requestFunction(searchTerm);
    };
}

const defaultRenderSuggestion = (suggestion)=> suggestion;

export function LRSearchInput({
    searchTerm,
    onChangeSearchTerm,
    onSelectSuggestion,
    searchRequest,
    requestKey,
    onLoadingStatusChange,
    renderSuggestion = defaultRenderSuggestion,
    ...otherProps
}) {
    const memoSearchRequest = useMemo(()=> {
        return makeSearchRequest(searchRequest);
    }, [searchRequest]);
    const queryKey = useUniqueKey(requestKey);
    const [enabled, setEnabled] = useState(false);
    const { isLoading, data } = useQuery([queryKey, searchTerm], memoSearchRequest, { enabled, staleTime: Infinity });

    function handleOnChangeSearchTerm(searchTerm) {
        onChangeSearchTerm(searchTerm);
        setEnabled(true);
    }

    function handleOnSelectSuggestion(suggestion) {
        onSelectSuggestion(suggestion);
        setEnabled(false);
    }

    function handleOnClickOutside() {
        setEnabled(false);
    }

    useEffect(()=> {
        if(onLoadingStatusChange) {
            onLoadingStatusChange(isLoading);
        }
    }, [onLoadingStatusChange, isLoading]);

    return (
        <AutocompleteInput
            {...otherProps}
            value={searchTerm}
            onChange={handleOnChangeSearchTerm}
            onSuggestionSelect={handleOnSelectSuggestion}
            onClickOutside={handleOnClickOutside}
            renderSuggestion={renderSuggestion}
            isLoading={isLoading}
            suggestions={data}
        />
    );
}
