import { FitnessCenter, LocalAtm, LocalGasStation, LocalHospital, LocalLaundryService, LocalMovies, LocalPharmacy, Place, School, ShoppingCart } from '@material-ui/icons';
import Coffee from 'components/LRIcons/coffee';
import React from 'react';
import PropTypes from 'prop-types';
export function InfographicsCategoryIcon({category, classes, ...props}) {
    switch(category) {
        case 'coffee':
            return <Coffee className={classes.root} {...props}/>;
        case 'atm':
            return <LocalAtm classes={classes} {...props} />;
        case 'pharmacy':
            return <LocalPharmacy classes={classes} {...props} />;
        case 'hospital':
            return <LocalHospital classes={classes} {...props} />;
        case 'gym':
            return <FitnessCenter classes={classes} {...props} />;
        case 'gas':
            return <LocalGasStation classes={classes} {...props} />;
        case 'movie':
            return <LocalMovies classes={classes} {...props} />;
        case 'cleaners':
            return <LocalLaundryService classes={classes} {...props} />;
        case 'groceries':
            return <ShoppingCart classes={classes} {...props} />;
        case 'schools':
            return <School classes={classes} {...props} />;
        default:
            return <Place classes={classes} {...props} />;
    }
}

InfographicsCategoryIcon.propTypes = {
    category: PropTypes.oneOf([
        'atm',
        'cleaners',
        'coffee',
        'gas',
        'groceries',
        'gym',
        'hospital',
        'movie',
        'pharmacy',
        'place',
        'schools',
    ]),
    classes: PropTypes.object.isRequired,
};
