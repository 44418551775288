// eslint-disable-next-line
import Logger from 'js-logger';
import { observable, action, computed, decorate, reaction, when } from 'mobx';
import Lockr from 'lockr';
import _ from 'lodash';
import { benoitApi, portalApi } from '../../apis';
import launchdarkly from '../../services/launchdarkly';
import analytics from '../../services/analytics';
import jwt from 'jsonwebtoken';
import moment from 'moment';

const logger = Logger.get('UserStore');

export class UserStore {
    user = undefined;
    userPreferences = {};
    token = null;
    hasAttemptedToFetchUser = false;
    unverifiedOnboardingInProgress = false;
    showVerificationBanner = true;
    changedNurtureOnboardingToSidenav = false;
    loRobinAccess = false;
    loadingUser = false;
    timeframeMapper = {
        'i_already_own_a_home': 'i_already_bought_a_home_using_robin',
        'im_not_sure':'im_not_sure',
        'ASAP': 'asap',
        'within 3 months': '1-3 months',
        '3-6 months': '3-6months',
        '6-12 months': '6-12months',
        '12+ months': '12+months',
    };

    constructor(rootStore) {
        this.rootStore = rootStore;
        reaction(
            ()=> this.usersBoardRole,
            (role)=> this._identifyForAnalytics()
        );

        reaction(
            ()=> this.hasAttemptedToFetchUser && !this.user && this.publicUser,
            ()=> {
                const isPublicUser = true;
                analytics.setUsername(this.publicUser?.id, isPublicUser);
            }
        );
    }

    get isBannerVisible() {
        return this.isConsumer
            && this.showVerificationBanner
            && !this.isVerified
            && !this.isUnverified
            && (this.firstRunOnboardingInitiated || this.firstRunOnboardingCompleted)
            && !this.unverifiedOnboardingInProgress;
    }

    get hasUserPreferences() {
        return !!Object.keys(this.userPreferences).length;
    }

    get usersBoardRole() {
        if(!this.isConsumer) return this.user?.accessProfile;
        if(!(this.rootStore?.boardsStore?.activeBoard && this.user?.id)) return '';
        return this.rootStore.boardsStore.activeBoard.ownerId === this.user.id ? 'buyer' : 'cobuyer';
    }

    get publicUser() {
        return this.rootStore.ListpackStore.agent ||
            this.rootStore.RateFarmStore.user ||
            this.rootStore.ShareablesStore.user ||
            this.rootStore.LrLiveStore?.lrlive?.user ||
            this.rootStore.PublicListing.agent;
    }

    async _fetchLatestUserInfo({ testOnboarding } = {}) {
        const token = this.token;

        if(token) {
            try {
                this.loadingUser = true;
                const decodedToken = jwt.decode(token, { complete: true });
                const userId = decodedToken.payload.id;
                const response = await benoitApi.getUser(userId);

                this.user = {
                    ...response,
                    accessProfile: decodedToken.payload.accessProfile,
                };
                const loRobinAccessResponse = response.affiliate?.preferredVendorId
                    ? await portalApi.getLORobinAccessStatus(response.affiliate.preferredVendorId)
                    : {active: false};
                this.loRobinAccess = loRobinAccessResponse.active;
                if(testOnboarding) {
                    this.user.meta.first_run_onboarding_flow_completed = false;
                    this.user.meta.dismiss_first_run_onboarding_message = false;
                }

                // check if we need to update the nurture unlimited status
                try {
                    if(this.hubspotRecordId) {
                        const hubspotContact = await benoitApi.getHubspotContact(this.hubspotRecordId);
                        if(hubspotContact?.properties?.nurture_unlimited_status === 'Cold') {
                            await benoitApi.updateHubspotContact(this.hubspotRecordId, { nurture_unlimited_status: 'Warm' });
                            logger.debug(`Nurture learn ${this.hubspotRecordId} status updated to Warm`);
                        }
                    }
                } catch (err) {
                    logger.error('Error updating nurture_unlimited_status', err);
                }

                this.loadingUser = false;
                if(!this.isVerified) {
                    let promises = [];

                    if(!this.isFromNurtureUnlimited && !this.isUnverified) {
                        promises = [
                            this.completeFirstRunOnboarding(),
                        ];
                    }

                    if(!this.user.meta.loggedInAsUnverified) {
                        promises.push(
                            benoitApi.updateUser({ id: this.user.id, meta: { loggedInAsUnverified: true }})
                        );
                        this.user.meta.loggedInAsUnverified = true;
                    }
                    await Promise
                        .all(promises)
                        .catch((err)=> {
                            logger.error('Error updating user', err);
                        });
                }
            } catch (err) {
                logger.error('Error fetching user', err);
                this.user = undefined;
                this.loadingUser = false;
            }
        }

        this.hasAttemptedToFetchUser = true;

        if(this.user) {
            this.saveUserToLocalStorage();
            launchdarkly.identify(this.getLaunchDarklyUserObj());
            this._identifyForAnalytics();
            this._pendoIdentify();
        }

        logger.debug('Latest User Info', this.user);
    }

    /**
     * If pendo has not been initialized it will initialize
     * with the current users information. If it has it will
     * update their info in pendo by calling identify instead.
     *
     * @function _pendoIdentify
     */
    _pendoIdentify() {
        if(!(window.pendo && this.user)) return;

        if(this.pendo_initialized) {
            window.pendo.identify({
                visitor: {
                    id: this.user.id,
                    name: this.user.name,
                    email: this.user.email,
                    phone: this.user.phone,
                    robin_user: true,
                },
            });
        } else {
            window.pendo.initialize({
                visitor: {
                    id: this.user.id,
                    name: this.user.name,
                    email: this.user.email,
                    phone: this.user.phone,
                    robin_user: true,
                },
            });
            this.pendo_initialized = true;
        }
    }

    _identifyForAnalytics() {
        const user = this.isAgent
            ? this.user?.affiliate
            : this.isLO
                ? this.user?.affiliate?.preferred_vendor
                : this.user;

        if(!user) return;

        // Identify the user
        analytics.setUsername(user.id);

        // Set user props
        const userProps = {
            $email: user.email,
            $name: user.name,
            $created: user.createdAt,
            $phone: user.phone,
            $role: this.usersBoardRole,
            $board_id: this.user.defaultBoardId,

        };
        if(!this.isAgent) userProps.affiliate_id = user.affiliateId;

        analytics.setUserProperties(userProps);

        // Set super props that will show on every event
        const superProps = {
            name: user.name,
            email: user.email,
            phone: user.phone,
            role: this.usersBoardRole,
            board_id: this.user.defaultBoardId,
        };

        if(!this.isAgent) superProps.affiliate_id = user.affiliateId;

        analytics.setSuperProperties(superProps);

        // Used when you only want the value set if no value has been set
        const singleSuperProps = {
            lead_id: user?.meta?.portalVendorLeadId,
        };

        analytics.setSuperPropertiesOnce(singleSuperProps);

        if(this.usersBoardRole) {
            logger.debug('initSession -> usersBoardRole ->', this.usersBoardRole);
            analytics.initSession();
        }

    }

    /**
     * Creates the launch darkly user object with
     * all the props we want to passss over.
     */
    getLaunchDarklyUserObj() {
        return {
            name: _.get(this.user, 'name'),
            firstName: _.get(this.user, 'first_name'),
            lastName: _.get(this.user, 'last_name'),
            key: _.get(this.user, 'id'),
            custom: {
                createdAt: _.get(this.user, 'createdAt'),
                verifiedEmail: _.get(this.user, 'verified.email'),
                verifiedPhone: _.get(this.user, 'verified.phone'),
            },
        };
    }

    // PUBLIC FUNCTIONS

    /**
     * Fetches the locally stored user info if available.
     *
     * @function fetchLocalUser
     */
    async fetchLocalUser({ testOnboarding } = {}) {
        this.token = this.storedToken;
        await this._fetchLatestUserInfo({ testOnboarding });
    }

    async fetchUserFromBearerToken(bearerToken) {
        this.processBearerToken(bearerToken);
        await this._fetchLatestUserInfo();
    }

    processBearerToken(bearerToken) {
        this.token = bearerToken;

        const decodedToken = jwt.decode(bearerToken, { complete: true });
        if(decodedToken.payload.singleSession) {
            benoitApi.updateBenoitAuthToken(bearerToken);
        } else {
            this.storeToken(bearerToken);
        }
    }

    /**
     * Saves the user object to local storage that can
     * be fetched if the user reloads.
     *
     * @function saveUserToLocalStorage
     */
    saveUserToLocalStorage() {
        const SAVABLE_PROPS = ['id', 'bearer_token', 'name', 'first_name', 'last_name', 'email', 'phone', 'headshot'];

        Lockr.set('user', _.pick(this.user, SAVABLE_PROPS));
    }

    storeToken(token) {
        Lockr.set('token', token);
        benoitApi.updateBenoitAuthToken(token);
    }

    get storedToken() {
        return Lockr.get('token');
    }

    get isLoggedIn() {
        return this.user !== undefined;
    }

    get isConsumer(){
        return this.user?.accessProfile === 'consumer';
    }

    get isAgent() {
        return this.user?.accessProfile === 'agent';
    }

    get isLO() {
        return this.user?.accessProfile === 'lo';
    }

    get profileId() {
        if(this.isAgent) return this.user.affiliateId;
        else if(this.isLO) return this.user.affiliate.benoitPartnerId;
        return this.user.id;
    }

    get isInvited() {
        return this.user?.account_type === 'invited_robin';
    }

    get isUnverified() {
        return this.user?.account_type === 'unverified_robin';
    }

    get isVerified() {
        return this.user?.account_type === 'verified_robin';
    }

    get isPhoneVerified() {
        return this.user?.verified?.phone === true;
    }

    get hasMobileApp() {
        return _.has(this.user, 'meta.push_notification_tokens');
    }

    get firstRunOnboardingCompleted() {
        // ! not totally sure
        // ! need to confirm this, if we should or should not trigger the onboarding for unverified users
        // if(this.user && !this.isVerified) {
        //     // Skip firstRun onboarding for unverified users
        //     return true;
        // }
        return this.user?.meta?.first_run_onboarding_flow_completed;
    }

    get asyncFirstRunOnboardingCompleted() {
        if(this.firstRunOnboardingCompleted && !this.user.meta?.loggedInAsUnverified) {
            // Only consider the firstRunOnboardingCompleted flag to complete the first run onboarding if the user never logged in as an unverified user.
            return true;
        }
        return !!this.user?.meta?.async_onboarding?.first_run || false;
    }

    get inviteCobuyerOnboardingCompleted() {
        if(this.usersBoardRole === 'cobuyer' || this.usersBoardRole === '') return true;
        return !!this.user?.meta?.async_onboarding?.invite_cobuyer || false;
    }

    get pinPhotoOnboardingCompleted() {
        return this.user?.meta?.async_onboarding?.pin_photo || false;
    }

    get addNoteOnboardingCompleted() {
        return !!this.user?.meta?.async_onboarding?.add_note;
    }

    get compareOnboardingCompleted() {
        return this.user?.meta?.async_onboarding?.compare_homes || false;
    }

    get watchVideoOnboardingCompleted() {
        return this.user?.meta?.async_onboarding?.watch_video || false;
    }

    get createdSavedSearch() {
        return this.user?.meta?.async_onboarding?.created_saved_searches || false;
    }

    get movedBoardCards() {
        return this.user?.meta?.async_onboarding?.moved_board_cards || false;
    }

    get showTimeframeForm() {
        if(!this.user || !this.isConsumer || !this.firstRunOnboardingCompleted) {
            return false;
        }
        if(!this.user.meta.howSoonLastSeenAt){
            return true;
        }
        const howSoonLastSeenAt = moment(this.user.meta.howSoonLastSeenAt);
        const isProduction = this.rootStore.inProductionMode;
        const shouldShow = isProduction ? moment().diff(howSoonLastSeenAt, 'days') > 30 :
            moment().diff(howSoonLastSeenAt,'hours') > 1;

        return shouldShow;
    }

    get onboardingProgress() {
        const asyncOnboardings = [
            this.inviteCobuyerOnboardingCompleted,
            this.pinPhotoOnboardingCompleted,
            this.addNoteOnboardingCompleted,
            this.compareOnboardingCompleted,
            this.watchVideoOnboardingCompleted,
            this.createdSavedSearch,
            this.movedBoardCards
        ];
        if(this.isVerified) {
            const progressPerStep = 100 / asyncOnboardings.length;
            const completeAsyncOnboardings = asyncOnboardings.filter((completed)=> completed);
            return Math.round(
                // (progressPerStep * 2) + // counting 2 steps for the first run onboarding
                (completeAsyncOnboardings.length * progressPerStep)
            );
        } else {
            // asyncOnboardings.push(this.asyncFirstRunOnboardingCompleted);
            const progressPerStep = 100 / asyncOnboardings.length;
            const completeAsyncOnboardings = asyncOnboardings.filter((completed)=> completed);
            return Math.round(
                completeAsyncOnboardings.length * progressPerStep
            );
        }
    }

    get asyncOnboardingCompleted() {
        const asyncOnboardings = [
            this.inviteCobuyerOnboardingCompleted,
            this.pinPhotoOnboardingCompleted,
            this.addNoteOnboardingCompleted,
            this.compareOnboardingCompleted,
            this.watchVideoOnboardingCompleted,
        ];

        // if(!this.isVerified) {
        //     asyncOnboardings.push(this.asyncFirstRunOnboardingCompleted);
        // }
        return asyncOnboardings.every((onboarding)=> onboarding);
    }

    get onboardingCompleted() {
        return this.firstRunOnboardingCompleted && this.asyncOnboardingCompleted;
    }

    get firstRunOnboardingInitiated() {
        return !!this.user?.meta?.first_run_onboarding_flow_initiated;
    }

    get dismissedFirstRunOnboardingMessage() {
        // if(this.user && !this.isVerified) {
        //     // Skip firstRun onboarding for unverified users
        //     return true;
        // }
        return this.user?.meta?.dismiss_first_run_onboarding_message;
    }

    get howSoonTimeframe(){

        return this.user?.meta.how_soon || undefined;
    }

    get isFromNurtureUnlimited() {
        return this.user.source === 'nurture_unlimited';
    }

    get hubspotRecordId() {
        return this.user.third_party_ids?.hubspot_record_id;
    }

    get affiliatedMLS() {
        if(!this.user?.affiliate || !this.user?.affiliate.mls_affiliations) return [];

        const result = this.user?.affiliate.mls_affiliations.reduce((accum, value)=> {
            if(value.status === 'active') {
                accum.push(value.mls.source_code_name);
            }
            return accum;
        }, []);
        return result;
    } 

    updateUser(data) {
        _.merge(this.user, data);
        return benoitApi.updateUser({ id: this.user.id, ...data });
    }

    async updateUserPreferences(data) {
        let updatedData;
        if(this.userPreferences.id) {
            updatedData = await benoitApi.updateUserPreferences(data, this.userPreferences.id);
        } else if(data ){
            updatedData = await benoitApi.createUserPreferences(data);
        }
        _.merge(this.userPreferences, data);
        return updatedData;
    }

    dismissFirstRunOnboardingMessage() {
        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(this.user.meta.dismiss_first_run_onboarding_message) {
            return;
        }
        this.user.meta.dismiss_first_run_onboarding_message = true;
        return benoitApi.updateUser({ id: this.user.id, meta: { dismiss_first_run_onboarding_message: true }});
    }

    initiateFirstRunOnboarding() {
        if(!this.user.meta) {
            this.user.meta = {};
        }

        this.user.meta.first_run_onboarding_flow_initiated = true;
        return benoitApi.updateUser({ id: this.user.id, meta: { first_run_onboarding_flow_initiated: true }});
    }

    completeFirstRunOnboarding() {
        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(this.user.meta.first_run_onboarding_flow_completed) {
            return;
        }
        this.user.meta.first_run_onboarding_flow_completed = true;
        return benoitApi.updateUser({ id: this.user.id, meta: { first_run_onboarding_flow_completed: true }});
    }

    async completeAsyncFirstRunOnboarding({ movedCard = false, dismissed = false } = {}) {
        const haveSelfCreatedSearches = !dismissed && !!this.rootStore.boardsStore.activeBoard.notArchivedListpacks.length;
        const haveSelfCreatedBoardCards =  movedCard || !!this.rootStore.boardsStore.activeBoard.boardCards.find((bc)=> bc.userId === this.user?.id);

        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(!this.user.meta.async_onboarding) {
            this.user.meta.async_onboarding = {};
        }
        if(this.user.meta.async_onboarding.first_run &&
            this.user?.meta?.async_onboarding?.created_saved_searches &&
            this.user?.meta?.async_onboarding?.moved_board_cards) {
            return;
        }
        this.user.meta.async_onboarding.first_run = true;
        this.user.meta.first_run_onboarding_flow_completed = true;
        //this.user.meta.dismiss_first_run_onboarding_message = true;
        this.user.meta.async_onboarding.created_saved_searches = haveSelfCreatedSearches;
        this.user.meta.async_onboarding.moved_board_cards = haveSelfCreatedBoardCards;

        await benoitApi.updateUser({
            id: this.user.id,
            meta: {
                async_onboarding: { first_run: true, created_saved_searches: haveSelfCreatedSearches, moved_board_cards:haveSelfCreatedBoardCards },
                first_run_onboarding_flow_completed: true,
            }
        });

        if(!dismissed) {
            let eventName = 'robin_onboarding_end_no_saved_search_and_did_not_add_home';

            if(!haveSelfCreatedSearches && haveSelfCreatedBoardCards) {
                eventName = 'robin_onboarding_end_no_saved_search_but_added_home';
            } else if(haveSelfCreatedSearches && !haveSelfCreatedBoardCards) {
                eventName = 'robin_onboarding_end_saved_search_but_did_not_add_home';
            } else if(haveSelfCreatedSearches && haveSelfCreatedBoardCards) {
                eventName = 'robin_onboarding_end_close_saved_search_and_added_home';
            }

            analytics.eventTrack(eventName, {
                source: 'first_run_onboarding'
            });
        }
    }

    completeAddNoteOnboarding() {
        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(!this.user.meta.async_onboarding) {
            this.user.meta.async_onboarding = {};
        }
        this.user.meta.async_onboarding.add_note = true;
        benoitApi.updateUser({ id: this.user.id, meta: { async_onboarding: { add_note: true } }});

        analytics.eventTrack('robin_async_onboarding_step_completed', {
            source: 'add_note'
        });
    }

    completeCompareOnboarding() {
        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(!this.user.meta.async_onboarding) {
            this.user.meta.async_onboarding = {};
        }
        this.user.meta.async_onboarding.compare_homes = true;
        benoitApi.updateUser({ id: this.user.id, meta: { async_onboarding: { compare_homes: true } }});

        analytics.eventTrack('robin_async_onboarding_step_completed', {
            source: 'compare'
        });
    }

    completeInviteBuyerOnboarding() {
        if(this.inviteCobuyerOnboardingCompleted) {
            return;
        }

        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(!this.user.meta.async_onboarding) {
            this.user.meta.async_onboarding = {};
        }
        this.user.meta.async_onboarding.invite_cobuyer = true;
        benoitApi.updateUser({ id: this.user.id, meta: { async_onboarding: { invite_cobuyer: true } }});

        analytics.eventTrack('robin_async_onboarding_step_completed', {
            source: 'invite_cobuyer'
        });
    }

    completeWatchVideoOnboarding() {
        if(this.watchVideoOnboardingCompleted) {
            return;
        }

        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(!this.user.meta.async_onboarding) {
            this.user.meta.async_onboarding = {};
        }
        this.user.meta.async_onboarding.watch_video = true;
        benoitApi.updateUser({ id: this.user.id, meta: { async_onboarding: { watch_video: true } }});

        analytics.eventTrack('robin_async_onboarding_step_completed', {
            source: 'sizzle_video'
        });
    }

    completePinPhotoOnboarding() {
        if(this.pinPhotoOnboardingCompleted) {
            return;
        }

        if(!this.user.meta) {
            this.user.meta = {};
        }
        if(!this.user.meta.async_onboarding) {
            this.user.meta.async_onboarding = {};
        }
        this.user.meta.async_onboarding.pin_photo = true;
        benoitApi.updateUser({ id: this.user.id, meta: { async_onboarding: { pin_photo: true } }});

        analytics.eventTrack('robin_async_onboarding_step_completed', {
            source: 'pin_photo'
        });
    }

    async login(payload) {
        try {
            const response = await benoitApi.loginUser(payload);
            this.storeToken(response.token);
            this.token = response.token;
            await this.fetchUserData();
        } catch (err) {
            if(err?.response?.data?.errors[0].status === '401') {
                return null;
            } else throw err;
        }

        return this.user;
    }

    // To be removed when we get rid of the login by id ui.
    // get user endpoint needs to be changed to remove the jwt token from the response
    async deprecatedLogin(userId) {
        let user = await benoitApi.getUser(userId, { params: { 'dev-mode': true } });
        this.storeToken(user.token);
        this.token = user.token;
        await this._fetchLatestUserInfo();
    }

    async fetchUserData() {
        await this._fetchLatestUserInfo();
        this.rootStore.LanesStore.loadingListings = true;
        await this.rootStore.boardsStore.fetchUserBoards();
        this.rootStore.LanesStore.loadingListings = false;
    }

    /**
     * Log a user out
     */
    logout() {
        Lockr.rm('user');
        Lockr.rm('board');
        Lockr.rm('token');
        this.user = undefined;
        // reverted the change that sets this.user to an object with accessProfile 'guest', it was causing a flickr on the sidenav
        // keeping this here to guarantee that it didn't break anything, but it does seem that we should be ok as the profile guest don't have any permissions set on the defineAbility
        // this.user = { accessProfile: 'guest' };
        analytics.reset();
    }

    setSetting(settingKey, settingValue) {
        this.user.account.notification_settings[settingKey] = settingValue;
        this.saveUserToLocalStorage();
    }

    get notificationSettings() {
        return this.user.notification_settings;
    }

    async setNotification(key, channel, value) {
        this.user.notification_settings[key][channel] = value;
        await this.updateUser({ notification_settings: { [key]: { [channel]: value } } });
    }

    async setAllChannelNotifications(channel, value) {
        let settings = this.user.notification_settings;
        settings = _.mapValues(settings, (values)=> {
            values[channel] = value;
            return values;
        });
        await this.updateUser({ notification_settings: _.mapValues(settings, ()=> ({[channel]: value})) });
    }

    async updateHeadshot(file){
        if(!file) return;

        function encodeImageFileAsURL(file) {
            return new Promise((resolve, reject)=> {
                var reader = new FileReader();
                reader.onloadend = function() {
                    resolve(reader.result);
                };
                reader.onerror = function(event) {
                    reject(reader.error);
                };
                reader.readAsDataURL(file);
            });
        }

        const updateHeadshotOnPermissions = (headshot)=> {
            return this.rootStore.boardsStore.activeBoard.userPermissions.map((permission)=> {
                let permissionToReturn = permission;
                if(permission.user.id === this.user.id) {
                    permissionToReturn = {
                        ...permissionToReturn,
                        user: {
                            ...permissionToReturn.user,
                            headshot: headshot
                        }
                    };
                }
                return permissionToReturn;
            });
        };

        // optimistic update
        const oldHeadshot = this.user.headshot;
        const tmpHeadshot = await encodeImageFileAsURL(file);
        const oldPermissions = this.rootStore.boardsStore.activeBoard.userPermissions.concat([]);
        this.user.headshot = tmpHeadshot;
        this.rootStore.boardsStore.activeBoard.userPermissions = updateHeadshotOnPermissions(tmpHeadshot);

        try {
            const result = await benoitApi.updateUserHeadshot(file, this.user);
            this.user.headshot = result;
            updateHeadshotOnPermissions(result);

        } catch (err){
            this.user.headshot = oldHeadshot;
            this.rootStore.boardsStore.activeBoard.userPermissions = oldPermissions;
        }

    }

    async warmUpHubspotLeadToHot() {
        if(this.hubspotRecordId) {
            try {
                const hubspotContact = await benoitApi.getHubspotContact(this.hubspotRecordId);
                const currentStatus = hubspotContact?.properties?.nurture_unlimited_status;

                if(currentStatus !== 'Hot') {
                    return benoitApi.updateHubspotContact(this.hubspotRecordId, { nurture_unlimited_status: 'Hot' });
                }
            } catch (err) {
                logger.error('error warming up hubspot status');
            }
        }
        return Promise.resolve(null);
    }

    async updateUserTimeframe(newOption) {
        const updatePayload = {
            meta: {
                how_soon: newOption,
                howSoonLastSeenAt: moment()
                    .toISOString(),
            },
        };

        await this.warmUpHubspotLeadToHot();
        return this.updateUser(updatePayload);
    }

    mergeUserData(data){
        _.merge(this.user, data);
    }

    mergeUserPreferencesData(data){
        _.merge(this.userPreferences, data);
    }
}

decorate(UserStore, {
    // Variables
    user: observable,
    userPreferences: observable,
    hasAttemptedToFetchUser: observable,
    showVerificationBanner: observable,
    loRobinAccess: observable,
    loadingUser: observable,
    changedNurtureOnboardingToSidenav: observable,
    unverifiedOnboardingInProgress: observable,

    // Private Functions
    _fetchLatestUserInfo: action,

    // Public Functions
    fetchLocalUser: action,
    logout: action,
    setSetting: action,
    updateHeadshot: action,
    completeFirstRunOnboarding: action,
    dismissFirstRunOnboardingMessage: action,
    completeCompareOnboarding: action,
    completeAddNoteOnboarding: action,
    initiateFirstRunOnboarding: action,
    completeAsyncFirstRunOnboarding: action,
    updateUser: action,
    updateUserTimeframe: action,
    mergeUserData: action,
    updateUserPreferences: action,
    warmHubspotLeadToHot: action,

    // computed Properties
    isLoggedIn: computed,
    isAgent: computed,
    publicUser: computed,
    firstRunOnboardingCompleted: computed,
    inviteCobuyerOnboardingCompleted: computed,
    compareOnboardingCompleted: computed,
    pinPhotoOnboardingCompleted: computed,
    asyncOnboardingCompleted: computed,
    onboardingCompleted: computed,
    onboardingProgress: computed,
    dismissedFirstRunOnboardingMessage: computed,
    firstRunOnboardingInitiated: computed,
    isInvited: computed,
    isUnverified: computed,
    isVerified: computed,
    isPhoneVerified: computed,
    hasMobileApp: computed,
    isBannerVisible: computed,
    hasUserPreferences: computed,
    showTimeframeForm: computed,
    isFromNurtureUnlimited: computed,
    affiliatedMLS: computed,
});

export default new UserStore();
