import React from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, Box, Grid } from '@material-ui/core';
import moment from 'moment';
import LRAvatar from '../LRAvatar';
import notificationConfig from '../../mobx-store/notifications/config';
import { useStore } from '../../mobx-store';
import styled from 'styled-components';
import lodashGet from 'lodash/get';
import {
    COLOR_COSTA_MESA,
    DEFAULT_LISTING_HERO_IMAGE
} from '../../constants';

const StyledAvatarContainer = styled.div`
    position: relative;
`;

const StyledIconContainer = styled.div`
    position: absolute;
    right: -9px;
    bottom: -9px;
    border-radius: 50%;
    background-color: #1a1a1a;
    width: 28px;
    height: 28px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    > * {
        margin: 7px auto;
        font-size: 15px;
    }
`;

const StyledThumbnail = styled.img`
    border-radius: 2px;
    object-fit: cover;
    width: 70px;
    display: block;
    height: 48px;
`;

const MAX_THUMBNAILS = 3;

export const NotificationsList = observer(({ notifications, onNotificationClick, fullView = false })=> {
    const { UserStore } = useStore();
    const userId = UserStore.user?.id;
    return (
        <Box>
            {notifications.filter(
                (notification)=> {
                    const conf = notificationConfig[notification.type];
                    return conf?.text(notification, userId) && !notification.content?.suppress_notifications;
                })
                .map((notification, index)=> {
                    const bgColor = !(notification.is_seen || notification.is_read)
                        ? 'rgba(1, 170, 181, 10%)'
                        : !notification.is_read
                            ? COLOR_COSTA_MESA
                            : 'white';
                    const conf = notificationConfig[notification.type] || {};
                    const trimText = !fullView;
                    const text = conf?.text(notification, userId, trimText) || '';
                    const hero = conf?.hero ? conf.hero(notification) : null;
                    const icon = conf?.icon ? conf.icon(notification) : null;
                    const showAvatar = conf?.hasOwnProperty('avatar') ? conf.avatar : true;
                    const imageUrls = conf?.imageUrls ? conf.imageUrls(notification) || [] : [];
                    const plusCount = Math.max(imageUrls.length - MAX_THUMBNAILS, 0);
                    return (
                        <Box
                            key={notification?.id || `notification-list-item${index}`}
                            style={{ cursor: 'pointer' }}
                            onClick={()=> onNotificationClick && onNotificationClick(notification)}
                        >
                            <Box p={2} bgcolor={bgColor}>
                                <Grid container spacing={2}>
                                    {showAvatar && (<Grid item>
                                        <StyledAvatarContainer>
                                            <LRAvatar size="medium" user={notification.user} />

                                            {icon && (
                                                <StyledIconContainer>
                                                    {icon}
                                                </StyledIconContainer>
                                            )}
                                        </StyledAvatarContainer>
                                    </Grid>)}
                                    <Grid item xs>
                                        <Box fontSize="subtitle2.fontSize">
                                            {text}
                                        </Box>
                                        <Box fontSize="subtitle2.fontSize" color="text.secondary">
                                            {moment(notification.createdAt)
                                                .fromNow()}
                                        </Box>

                                        {(imageUrls.length > 1) && (<Box mt={1}>
                                            <Grid container
                                                spacing={1}
                                                alignItems="center"
                                            >
                                                {imageUrls
                                                    .slice(0, MAX_THUMBNAILS)
                                                    .map((imageUrl, i)=> (
                                                        <Grid item
                                                            key={`notification-list-thumbnails-${notification.id}-${i}`}
                                                        >
                                                            <StyledThumbnail
                                                                alt="thumbnail"
                                                                src={imageUrl}
                                                            />
                                                        </Grid>
                                                    ))}

                                                {(plusCount > 0) && (<Grid item>
                                                    <p>+{plusCount}</p>
                                                </Grid>)}
                                            </Grid>
                                        </Box>)}
                                    </Grid>

                                    {((imageUrls.length === 1) || (!imageUrls.length && hero)) && (
                                        <Grid item>
                                            <StyledThumbnail
                                                alt="thumbnail"
                                                src={lodashGet(imageUrls, '[0]') || hero}
                                                onError={(e)=> { 
                                                    e.target.src= DEFAULT_LISTING_HERO_IMAGE;
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
});
