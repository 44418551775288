import Logger from 'js-logger';
import { benoitAxios } from '../../benoit-axios';

const logger = Logger.get('Benoit API - My Home - Service Providers');

/**
 * Get the service providers for the users home
 * 
 * @param {String} boardId 
 * @param {String} myHomeId 
 * @returns Array
 */
export async function getMyHomeServiceProviders(boardId, myHomeId) {
    try {
        const response = await benoitAxios.get(`/v1/boards/${boardId}/my-homes/${myHomeId}/service-providers`);
        return response.data.data;
    } catch (err) {
        logger.error('getMyHomeServiceProviders -> Error', err);
        throw err;
    }
}

export async function createMyHomeServiceProvider(boardId, myHomeId, data) {
    if(!data) throw new Error('Missing data');

    try {
        const response = await benoitAxios.post(`/v1/boards/${boardId}/my-homes/${myHomeId}/service-providers`, data);
        return response.data.data;
    } catch (err) {
        logger.error('createMyHomeServiceProvider -> Error', err);
        throw err;
    }
}

export async function updateMyHomeServiceProvider(boardId, myHomeId, serviceProvider, data) {
    if(!data) throw new Error('Missing data');

    try {
        const response = await benoitAxios.patch(`/v1/boards/${boardId}/my-homes/${myHomeId}/service-providers/${serviceProvider.id}`, data);
        return response.data.data;
    } catch (err) {
        logger.error('updateMyHomeServiceProvider -> Error', err);
        throw err;
    }
}

export async function deleteMyHomeServiceProvider(boardId, myHomeId, serviceProvider) {
    try {
        const response = await benoitAxios.delete(`/v1/boards/${boardId}/my-homes/${myHomeId}/service-providers/${serviceProvider.id}`);
        return response.data.data;
    } catch (err) {
        logger.error('updateMyHomeServiceProvider -> Error', err);
        throw err;
    }
}
