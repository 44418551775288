import { createMuiTheme } from '@material-ui/core/styles';
import listreportsBlue from './listreports-blue';
import salmon from './salmon';
import error from './error';
import black from './black';
import lrColors from './listreports-colors';
import { COLOR_BLUE_LINK, COLOR_GRADE, COLOR_BRICK, COLOR_BORDER_GREY_LIGHTER } from '../constants';


const DEFAULT_THEME = {
    typography: {
        fontFamily: ['"LL Circular Pro"', 'Arial', 'Helvetica', 'sans-serif'],
        useNextVariants: true,
        body3: {
            fontSize: '10px',
        },
        h3: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
        },
    },
    palette: {
        black: black,
        primary: salmon,
        secondary: listreportsBlue,
        error: error,
        lr_colors: lrColors,
    },
    overrides: {
        MuiIconButton: {
            root: {
                color: '#000',
            },
        },
    },
    props: {
        MuiModal: {
            disableEnforceFocus: true,
        },
    },
};
const theme = createMuiTheme(DEFAULT_THEME);
export default theme;

const ROBIN_THEME = {
    ...DEFAULT_THEME,
    typography: {
        fontFamily: ['"LL Circular Pro"', 'Arial', 'Helvetica', 'sans-serif'],
        useNextVariants: true,
        body1: {
            fontWeight: 'normal',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.0313rem',
        },
        body2: {
            fontWeight: 'normal',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: '0.0156rem',
        },
        body3: {
            fontWeight: 'normal',
            fontSize: '0.625rem',
            lineHeight: '1.5rem',
            letterSpacing: '0',
        },
        body4: {
            fontWeight: 'normal',
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            letterSpacing: '0.025rem',
        },
        caption: {
            fontWeight: 'normal',
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            letterSpacing: '0.025rem',
        },
        h1: {
            fontWeight: 'normal',
            fontSize: '6rem',
            lineHeight: '8rem',
            letterSpacing: '-0.0938rem',
        },
        h2: {
            fontWeight: 'normal',
            fontSize: '3.75rem',
            lineHeight: '5.25rem',
            letterSpacing: '-0.0313rem',
        },
        h3: {
            fontWeight: 'normal',
            fontSize: '3rem',
            lineHeight: '4.25rem',
            letterSpacing: '0',
        },
        h4: {
            fontWeight: 'normal',
            fontSize: '2.125rem',
            lineHeight: '3rem',
            letterSpacing: '0.0156rem',
        },
        h5: {
            fontWeight: 'normal',
            fontSize: '1.5rem',
            lineHeight: '2rem',
            letterSpacing: '0',
        },
        h6: {
            fontWeight: '500',
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            letterSpacing: '0.0094rem',
        },
    },
    palette: {
        ...DEFAULT_THEME.palette,
        primary: {
            main: COLOR_BRICK,
            contrastText: '#fff',
        },
    },
    overrides: {
        ...DEFAULT_THEME.overrides,
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                    color: COLOR_BLUE_LINK,
                }
            }
        }
    }
};
export const RobinTheme = createMuiTheme(ROBIN_THEME);
export const CardBoardCollaborationsTheme = createMuiTheme({
    ...ROBIN_THEME,
    palette: {
        ...ROBIN_THEME.palette,
        primary: {
            main: '#bbb',
            contrastText: COLOR_GRADE,
        },
        secondary: {
            main: COLOR_BRICK,
            contrastText: '#fff',
        },
    },
});

export const tabsTheme = createMuiTheme({
    ...ROBIN_THEME,
    palette: {
        primary: {
            main: '#000000',
        },
    },
});
export const MuiCalendarBlackTheme = createMuiTheme({
    ...ROBIN_THEME,
    palette: {
        ...ROBIN_THEME.palette,
        primary: {
            main: COLOR_GRADE,
            contrastText: '#fff',
        },
    },
});
export const MuiPrimaryBlackTheme = createMuiTheme({
    ...ROBIN_THEME,
    palette: {
        ...ROBIN_THEME.palette,
        primary: {
            main: COLOR_GRADE,
            contrastText: '#fff',
        },
        secondary: {
            main: 'rgba(26, 26, 26, 0.75)',
            contrastText: '#fff',
        },
    },
});
export const MuiPrimaryWhiteTheme = createMuiTheme({
    ...ROBIN_THEME,
    palette: {
        ...ROBIN_THEME.palette,
        primary: {
            main: '#fff',
        },
    },
});
