import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../mobx-store';
import LRButton from '../LRButton';
import { useResponsiveBreakpoint } from '../../hooks';
import analytics from '../../services/analytics';
import { benoitApi } from '../../apis';
import { observer } from 'mobx-react-lite';

const StyledLRButton = styled(LRButton)`
    height: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const StyledTypography = styled(Typography)`
    letter-spacing: 1.25px;
`;

export const VerificationBanner = observer(function VerificationBanner({source, onSignupClick, onVerifyClick}) {
    const { UserStore } = useStore();
    const { user } = UserStore;
    const history = useHistory();
    const location = useLocation();

    async function onVerificationClick() {
        analytics.eventTrack('robin_unverified_banner_click', {
            click_type: 'verify',
            source: source,
        });

        if(onVerifyClick) {
            onVerifyClick();
        } else {
            const newToken = await benoitApi.requestLogin('phone', user.phone);
            history.push(`${location.pathname}/verify?login_token=${newToken.token}`);
        }
    };

    function onSignUpClick() {
        analytics.eventTrack('robin_unverified_banner_click', {
            click_type: 'signup',
            source: source,
        });

        if(onSignupClick) {
            onSignupClick();
        } else {
            history.push(`${location.pathname}/signup?invite_id=${user.meta.boardUserInviteId}`);
        }
    };

    function onDismissClick() {
        analytics.eventTrack('robin_unverified_banner_click', {
            click_type: 'dismiss',
            source: source,
        });

        UserStore.showVerificationBanner = false;
    }

    return (
        <>
            <Box width="100%" display="flex" justifyContent="center" bgcolor="lr_colors.grade">
                <Box
                    flex="1"
                    maxWidth={720}
                    height={[112, 72]}
                    color="#FFF"
                    display="flex"
                    flexDirection={['column', 'row']}
                    justifyContent="space-between"
                >
                    <Box mt={2} ml={3} mr={[3, 0]} maxWidth={430}>
                        <Typography variant="body2" color="inherit">
                            {UserStore.isInvited ? 'Sign up ' : 'Verify your account '}
                            and get instantly notified when new listings hit the market.
                        </Typography>
                    </Box>
                    <Box mt={[0, 7/2]} mb={[1, 0]} mr={1} display="flex" justifyContent={['flex-end', 'flex-start']} color="#FFF">
                        <StyledLRButton color="inherit" onClick={onDismissClick}>
                            <StyledTypography variant="body2" color="inherit">
                                DISMISS
                            </StyledTypography>
                        </StyledLRButton>
                        {UserStore.isInvited && <StyledLRButton color="inherit" onClick={onSignUpClick}>
                            <StyledTypography variant="body2" color="inherit">
                                SIGN UP
                            </StyledTypography>
                        </StyledLRButton>}
                        <Box mr={1} />
                        {!UserStore.isInvited && <StyledLRButton color="inherit" onClick={onVerificationClick}>
                            <StyledTypography variant="body2" color="inherit">
                                VERIFY
                            </StyledTypography>
                        </StyledLRButton>}
                    </Box>
                </Box>
            </Box>
        </>
    );
});
