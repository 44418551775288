import { BOARD_LISTPACK_CACHE_KEY } from '../../../constants';
import { useRobinRealtimeCommunication } from 'hooks/useRobinRealtimeCommunication';
import { useRef } from 'react';
import { useQueryClient } from 'react-query';
import Logger from 'js-logger';
import { useEffect } from 'react';
import { Listpack } from '../Listpack';
import { findIndex } from 'lodash';

const logger = Logger.get('useBoardListpacksRealtime');

export function useBoardListpacksRealtime(boardId, UserStore) {
    const queryClient = useQueryClient();
    const _lastMessageReceived = useRef();
    const {
        lastMessage,
    } = useRobinRealtimeCommunication(boardId ? `robin-board-${boardId}` : null);
    let listpacks = queryClient.getQueryData([BOARD_LISTPACK_CACHE_KEY, boardId]);

    useEffect(function onNewRealtimeMessage() {
        if(!listpacks?.length || !UserStore || !lastMessage || lastMessage === _lastMessageReceived.current) return;

        _lastMessageReceived.current = lastMessage;

        let data;

        try {
            data = JSON.parse(lastMessage.data).p;
        } catch (err) {
            logger.error('useEffect -> JSON.parse(lastMessage.data) -> Error', err);
        }

        if(!data?.content) return;

        // The main part of the message sent from the backend
        const content = data.content;

        if(~['listpack-created', 'listpack-updated'].indexOf(content.mtype)) {
            const { listpack:listpackData } = content;
            const listpack = new Listpack(listpackData, UserStore);
            const index = findIndex(listpacks, { id: listpack.id });
            let newListpacks;

            if(~index) {
                newListpacks = ~index ? [...listpacks.slice(0, index), listpack, ...listpacks.slice(index + 1)] : [...listpacks, listpack];
            } else {
                newListpacks = [...listpacks, listpack];
            }

            queryClient.setQueryData([BOARD_LISTPACK_CACHE_KEY, boardId], newListpacks);
            queryClient.invalidateQueries(BOARD_LISTPACK_CACHE_KEY);
        }
    }, [UserStore, boardId, lastMessage, listpacks, queryClient]);
}
