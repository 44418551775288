import { action, computed, decorate, extendObservable, flow, observable } from 'mobx';
import { benoitApi } from '../../apis';
import { find } from 'lodash';
import Logger from 'js-logger';

export class BoardCard {
    collaborations = [];
    offerSubmitted = false;
    submittingOffer = false;

    constructor(boardCardProps, board) {
        this.board = board;
        extendObservable(this, boardCardProps);
    }

    set index(newIndex) {
        this.columnIndex = newIndex;
    }

    set laneId(newLaneId) {
        const mapping = {
            1: '1',
            2: '2',
            3: '3',
            1000: '1000'
        };
        this.columnId = mapping[newLaneId] || 0;
    }

    get index() {
        return this.columnIndex;
    }

    get laneId() {
        const mapping = {
            1: '1',
            2: '2',
            3: '3',
            1000: '1000',
        };
        return mapping[this.columnId];
    }

    get hasListingDetails() {
        return !!this.listing_details;
    }

    get listing_details() {
        return this.listingsStore.listingsById[this.content.encoded_id];
    }
    
    get last_moved_by() {
        let lastMovedBy = find(this?.board?.collaborators, { userId: this.lastMovedById });
        const affiliate = this?.board?.boardsStore?.rootStore.UserStore?.user?.affiliate;

        if(!lastMovedBy && affiliate?.id === this.lastMovedById) {
            lastMovedBy = affiliate;
        } else if(!lastMovedBy && this.userId) {
            lastMovedBy = find(this?.board?.collaborators, { userId: this.userId });
        }

        return lastMovedBy;
    }

    get inHandselectedSearch() {
        return this.laneId === '1000';
    }

    update() {
        return benoitApi.updateBoardCard(this.id, this.serializeToUpdate());
    }

    fetchCollaborations = flow(function*() {
        this.collaborations = yield benoitApi.getBoardCollaborations(this.boardId, this.id);
    });

    async archive() {
        await benoitApi.archiveBoardCard(this.id);
        this.archived = true;
    }

    serializeToUpdate() {
        return {
            id: this.id,
            name: this.name,
            hero_image_url: this.hero_image_url,
            columnId: this.columnId,
            columnIndex: this.columnIndex,
            archived: this.archived,
        };
    }

    get listingsStore() {
        return this.board.boardsStore.rootStore.listingsStore;
    }
}

decorate(BoardCard, {
    collaborations: observable,
    offerSubmitted: observable,
    submittingOffer: observable,

    laneId: computed,
    index: computed,
    hasListingDetails: computed,
    listing_details: computed,
    last_moved_by: computed,

    fetchCollaborations: action,
    update: action,

});
