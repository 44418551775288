import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme)=> ({
    drawerIconButtonRoot: {
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: '#ccc',
        }
    }
}));

export const DrawerToggleButton = ({open, handleToggle, ...props})=> {
    const classes = useStyles();
    const theme = useTheme();
    
    return (
        <IconButton
            size="small"
            classes={{
                root: classes.drawerIconButtonRoot,
            }}
            className="tour-target-leftsidebar-toggle"
            onClick={handleToggle}
        >
            <ChevronLeft
                style={{
                    fontSize: '16px',
                    transform: `rotate(${open ? 0 : 180}deg)`,
                    transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.sharp,
                        duration: open ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
                    }),
                }}
            />
        </IconButton>
    );
};

DrawerToggleButton.propTypes = {
    open: PropTypes.bool,
    handleToggle: PropTypes.func,
};
