// eslint-disable-next-line
import Logger from 'js-logger';
import React from 'react';
import LRAvatar from '../../LRAvatar';
import { Box, Grid } from '@material-ui/core';
import { useStore } from '../../../mobx-store';

const MAX_AVATARS = 5;

function BoardUsersHeadshots({ ...props }) {
    const { boardsStore } = useStore();
    const boardUsers = boardsStore.activeBoard ? boardsStore.activeBoard.collaborators : [];
    const missingAvatars = boardUsers.length - MAX_AVATARS;

    return (
        <Grid container alignItems="center" justify="center" style={{ boxSizing: 'initial' }} {...props}>
            {boardUsers.slice(0, MAX_AVATARS).map((bUser, i)=> {
                return (
                    <Box
                        key={i}
                        marginLeft={i > 0 ? '-6px' : ''}
                        zIndex={i + 1}
                        border="3px solid #fff"
                        borderRadius="50%"
                        padding="1px solid"
                        bgcolor="#fff"
                    >
                        <LRAvatar user={bUser} size="xsmall" />
                    </Box>
                );
            })}
            {boardUsers.length > MAX_AVATARS && (
                <span>+{missingAvatars} other{missingAvatars > 1 && 's'}</span>
            )}
        </Grid>
    );
}

export default React.memo(BoardUsersHeadshots);
