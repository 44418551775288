import React, { useState } from 'react';
import Logger from 'js-logger';
import LRButton from 'components/LRButton';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import Address from 'lana';
import theme from 'theme';
import { LRAddressAutocompleteInput } from 'components/LRAddressAutocomplete';
import { Grid } from '@material-ui/core';

function SubmitButton({ style = {}, ...props }) {
    return (
        <LRButton
            variant="contained"
            color="primary"
            style={{
                height: 48,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                ...style,
            }}
            {...props}
        />
    );
}
export function AddAddressSearchInput({
    buttonLabel = 'Add',
    buttonPosition = 'right', // right or bottom
    address = '',
    disabled = false,
    onChange = (address)=> {},
    onSubmit  = (address)=> {},
    ...props
}) {
    const [disableButton, setDisableButton] = useState();
    // Functions

    async function onSubmitClick() {
        if(!Address.isValid(address)) {
            Logger.debug('INVALID ADDRESS');
            return;
        }

        setDisableButton(true);
        await onSubmit(
            new Address(address).toString()
                .split(' ')
                .map(capitalize)
                .join(' ')
        );
        setDisableButton(false);
    }

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <LRAddressAutocompleteInput
                        value={address}
                        onChange={onChange}
                        InputProps={{
                            style: {
                                borderRight: 0,
                            }
                        }}
                        disabled={disabled}
                        {...props}
                    />
                </Grid>

                {buttonPosition === 'right' && (
                    <Grid item>
                        <SubmitButton
                            disabled={disableButton || disabled}
                            onClick={onSubmitClick}
                        >
                            {buttonLabel}
                        </SubmitButton>
                    </Grid>
                )}
            </Grid>

            {buttonPosition === 'bottom' && (
                <div
                    style={{
                        marginTop: theme.spacing(2),
                    }}
                >
                    <SubmitButton
                        fullWidth
                        disabled={disableButton || disabled}
                        onClick={onSubmitClick}
                        style={{
                            borderRadius: 4,
                        }}
                    >
                        {buttonLabel}
                    </SubmitButton>
                </div>
            )}
        </>
    );
}

AddAddressSearchInput.propTypes = {
    address: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};
