import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Route, Redirect, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Box, Dialog, Hidden, SwipeableDrawer } from '@material-ui/core';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import ListpackModal from '../Board/ListpackModal';
import Map from './Map';
import Sidebar from './Sidebar';
import ListView from './List';
import { Navbar, OrganizerNavbar } from './Navbar';
import { useResponsiveBreakpoint } from '../../hooks';
import { DrawerToggleButton } from '../../components/DrawerToggleButton';
import { useBoardCards } from '../../hooks/useBoardCards';
import { SORT_MAP_MIXPANEL } from '../../constants';
import { ExpandMore } from '@material-ui/icons';
import { useOnboarding } from '../../components/Onboarding';
import { useAbility } from '../../components/Ability';
import analytics from '../../services/analytics';
import { MobileFooter } from './Mobile/Footer';
import addressSlugger from '../../services/address-slugger';
import { useSwipeable } from 'react-swipeable';
import { NoResults } from './NoResults';
import { BoardCardModal } from 'scenes/BoardCardModal';
import { ListpackListingView } from '../../components/ListpackListingView';

function ListingsMap({ open = true, onClose, ...props }) {
    const { boardsStore, UserStore, LanesStore, listingsStore } = useStore();
    const { user } = UserStore;
    const { activeBoard } = boardsStore;
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const [selectedListing, setSelectedListing] = useState(null);
    const [hoveredListing, setHoveredListing] = useState(null);
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const laneId = match?.params.laneId;
    const lane = LanesStore?.lanes?.find((lane)=> lane?.id === laneId);
    const listpackId = match?.params.listpackId;
    const listpack = activeBoard?.listpacks.find((listpack)=> listpack.id === listpackId);
    const defaultSort = !listpack ? {id: 'columnIndex', label: 'Column Order'} : {id: 'createdAt', label: 'New', order: 'desc'};
    const [sort, setSort] = useState(laneId ? LanesStore.sortByLaneId[laneId] : defaultSort);

    const  MOBILE_VIEW_MODES = {
        MAP: 'map',
        LiST: 'list'
    };

    const [view, setView] = useState(MOBILE_VIEW_MODES.LiST);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const listpackLastSeenAt = useRef();
    const ability = useAbility();
    const canUpdate = ability.can('update', 'Listpack');
    const canRead = ability.can('read', 'Listpack');
    const onboarding = useOnboarding();
    const [showDrawer, setShowDrawer] = useState(false);
    const [showTwoColumns, setShowTwoColumns] = useState(true);
    const outerSidebarRef = useRef();
    const outerSidebarMobileRef = useRef();
    const [swiperInitialized ,setSwiperInitialized] = useState(false);
    const [handSelectedLimit, setHandSelectedLimit] = useState(30);
    const {
        loaded,
        boardCards,
        unarchivedBoardCardsByLaneId,
        getUnarchivedBoardCardsByLaneId
    } = useBoardCards();

    const handlers = useSwipeable({ onSwipedDown: (eventData)=> {
        setShowDrawer(false);
    }});

    const listings = useMemo(()=> {
        if(listpackId && !listpack) return [];

        return listpackId
            ? listpack.handSelected ? listpack.listpackCards : listpack.listings?.map(function(listing) {
                return { listing_details: listing };
            })
            : getUnarchivedBoardCardsByLaneId(laneId);
    }, [laneId, listpackId, listpack, loaded, listpack?.listings, unarchivedBoardCardsByLaneId, listingsStore, listingsStore.listingsCount]);

    const hasMoreListings = listpack?.handSelected
        ? handSelectedLimit < listings.length
        : listpack?.hasMoreListings;
    const loadingMoreListings = listpack?.handSelected
        ? false
        : listpack?.loadingMoreListings;
    const fetchMoreListings = listpack?.handSelected
        ? ()=> setHandSelectedLimit(handSelectedLimit + 30)
        : ()=> listpack?.fetchMoreListings();

    const sortedListings = useMemo(function() {
        if(!listpack || (listpack && listpack?.handSelected)) {
            const sListings = _.orderBy(listings, sort.id, sort.order);

            return listpack?.handSelected
                ? sListings.slice(0, handSelectedLimit)
                : sListings;
        }

        return listings;
    }, [listings, sort, handSelectedLimit ]);

    function onSortChange(sort) {
        setSort(sort);

        if(lane){
            LanesStore.setSelectedLaneSort(lane.id, sort);
            const elem = document.getElementById(`lane-content-${lane?.id}`);
            if(elem){
                elem.scrollTo(0,0);
            }

            analytics.eventTrack('robin_column_sorted', {
                column: _.snakeCase(lane?.name),
                sorted_by: SORT_MAP_MIXPANEL[`${sort.key}_${sort.order}`],
            });
        }

        if(listpack && !listpack.handSelected) {
            listpack.changeSort(sort);
        }
    };

    function updateFilteringListings(e, laneId, laneName) {
        setSelectedListing(null);
        setHoveredListing(null);
        history.push(`/boards/lane-expanded/${laneId}/${laneName}`);
    }

    function handleListingClick(listing) {
        const { stepId } = onboarding;
        const shouldAdvanceOnboarding = ['highlight-listpack-listing-card'].includes(stepId);
        if((onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && shouldAdvanceOnboarding) {
            onboarding.next();
        }
        const addressSlug = addressSlugger(listing.listing_details.address);
        const destination = !listpack || (listpack && listpack.handSelected)
            ? `${match.url}/cards/${listing.id}/${addressSlug}?source=lane-expanded&sortId=${sort.id}&sortOrder=${sort.order}`
            : `${match.url}/listing/${listing.listing_details.id}?source=lane-expanded&source_request=saved_search`;

        if(onboarding?.isFirstRunOnboarding) {
            analytics.eventTrack('robin_onboarding_view_RPP_from_search', {
                source: 'listings-map-rpp',
            });
        }
        history.push(destination);
    }

    function handleViewChange(view) {
        setShowDrawer(false);
        setView(view);
    }
    useEffect(()=>  {
        let  newSort = (laneId) ? LanesStore.sortByLaneId[laneId] : {id: 'columnIndex', label: 'Column Order'};
        newSort = !listpack ? newSort : {id: 'createdAt', label: 'New', order: 'desc'};
        setSort(newSort);
    }, [laneId, listpackId, LanesStore.sortByLaneId[laneId]]);

    useEffect(()=> {
        setSwiperInitialized(false);
        setSelectedListing(null);
        setShowDrawer(false);
        setShowTwoColumns(listings?.length >= 6);

    }, [laneId, listpackId, listings]);

    useEffect(()=> {
        if(!listpack) return;

        if(!canRead) return;

        if(!listpack?.id || !isFirstRender) return;

        analytics.eventTrack('robin_board_listpack_viewed', {
            listpack_id: listpack?.id,
            status: listpack?.opened ? 'full_display ' : 'minimized',
        });

        listpackLastSeenAt.current = listpack.lastSeenDate;
        if(canUpdate && UserStore.isConsumer){
            listpack.updateLastSeenAt(user.id);
        }

        setIsFirstRender(false);
    }, [isFirstRender, listpack, canUpdate, canRead, user, UserStore.isConsumer]);

    function handleOnHover(listing) {
        setHoveredListing(listing);
    }

    return (
        <Dialog fullScreen open={open}>
            {match && (
                <>
                    <Route
                        path={`${match.path}/cards/:cardId/:address`}
                        render={()=> {
                            return <BoardCardModal
                                open={true}
                                onClose={()=> history.push(match.url)}
                                listpack={listpack}
                                requestSource={(listpack) ? 'saved_search' : 'map_view'}
                            />;
                        }}
                    />
                    <Route
                        path={`${match.path}/listing/:listingId`}
                        render={()=> (
                            <ListpackListingView
                                open={true}
                                onClose={()=> {
                                    if(onboarding && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && onboarding.stepId !== 'free-step') {
                                        onboarding.bypassRPPView = true;
                                        onboarding.back();
                                    }
                                    history.push(match.url);
                                }}
                                listpack={listpack}
                            />
                        )}
                    />

                    <Route path={`${match.path}/edit`} component={ListpackModal}></Route>

                    <Route
                        path={`${match.path}/:listingId`}
                        render={(props)=> {
                            if(['edit', 'listing', 'cards', 'verify', 'signup'].includes(props.match.params.listingId)) return;
                            return (
                                <Redirect
                                    to={`/boards/listpack/${props.match.params.listpackId}/listings/listing/${props.match.params.listingId}?source=lane-expanded`}
                                />
                            );
                        }}
                    />
                </>
            )}

            <Box
                display="flex"
                flexDirection="column"
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
            >
                <Box borderBottom="1px solid rgb(216, 216, 216)">
                    {
                        listpack ? (
                            <OrganizerNavbar listpack={listpack} onSortChange={onSortChange} sort={sort} onClose={onClose} />
                        ) : (
                            <Navbar currentFilter={laneId} onFilterChange={updateFilteringListings} onSortChange={onSortChange} sort={sort} onClose={onClose}></Navbar>
                        )
                    }
                </Box>

                {listpack && !listings?.length && !(listpack?.loadingListingsCount || listpack?.loadingListpackData || listpack?.loadingListings) && (
                    <NoResults  editLocation={{
                        pathname: `${match.url}/edit`,
                        state: { from: `/boards/listpack/${listpackId}/listings` }
                    }}/>
                )}

                <Box
                    flex="1"
                    display="flex"
                    width="100%"
                    maxHeight={isMobile ? 'calc(100vh - 60px})': 'calc(100vh - 72px)' }
                    flexDirection={{ xs: 'column', md: 'row' }}
                    overflow={isMobile && view === 'list' ? 'auto' : 'hidden'}
                >
                    <Box
                        flex="1"
                        position="relative"
                        maxHeight={isMobile && showDrawer ? 'calc(100% - 222px)' : '100%'}
                    >
                        {((isMobile && view === 'map') || !isMobile) ? (
                            <Map
                                listpackId={listpackId}
                                listings={listings}
                                selectedListing={selectedListing}
                                hoveredListing={hoveredListing}
                                onHover={handleOnHover}
                                onListingSelect={(listing)=> {
                                    setSelectedListing(listing);
                                    const elem = outerSidebarRef.current?.querySelector(`#${listing?.listing_details?.id}`);
                                    if(elem){
                                        elem.scrollIntoView();
                                    }
                                    setShowDrawer(true);
                                }}
                            />
                        ) : (
                            <Box
                                maxHeight="100%"
                                overflow="auto"
                                ref={outerSidebarMobileRef}
                                id={(lane?.id ? `lane-content-${lane?.id}` : '')}
                                {...((isMobile) ? {display: 'flex', width: '100%', height: '100%'} : {})}
                            >
                                <ListView
                                    lastSeenDate={listpackLastSeenAt.current}
                                    listings={sortedListings}
                                    listpack={listpack}
                                    selectedListing={selectedListing}
                                    onListingSelect={handleListingClick}
                                    currentLaneId={laneId}
                                    hasMoreListings={hasMoreListings}
                                    loadingMoreListings={loadingMoreListings}
                                    fetchMoreListings={fetchMoreListings}
                                    lane={lane}
                                    parentRef={outerSidebarMobileRef}
                                />
                            </Box>

                        )}
                        {
                            listings && listings.length > 5 && (
                                <Hidden smDown>
                                    <Box
                                        position='absolute'
                                        top={16}
                                        right={-10}
                                    >
                                        <DrawerToggleButton open={!showTwoColumns} handleToggle={()=> {
                                            analytics.eventTrack('robin_expanded_view_map_expand_icon_click',  {
                                                source: showTwoColumns ? 'collapse' : 'expand'
                                            });
                                            setShowTwoColumns(!showTwoColumns);
                                        }} />
                                    </Box>
                                </Hidden>
                            )
                        }
                    </Box>

                    {
                        !isMobile && (
                            <Box
                                style={{
                                    overflowX: isMobile ? 'auto': 'hidden',
                                    overflowY: isMobile ? 'hidden': 'auto'
                                }}
                                width={`${showTwoColumns ? '768px' : '400px'}`}
                                maxHeight={{ xs: '190px', md: '100%' }}
                                bgcolor="#F9F9F9"
                                display="flex"
                                alignItems={{xs: 'center', md: 'start'}}
                                justifyContent={{xs: 'start', md: 'center'}}
                                ref={outerSidebarRef}
                            >
                                <Sidebar
                                    handleOnHover={handleOnHover}
                                    parentRef={outerSidebarRef}
                                    hoveredListing={hoveredListing}
                                    onHover={handleOnHover}
                                    lastSeenDate={listpackLastSeenAt.current}
                                    listings={sortedListings}
                                    listpack={listpack}
                                    selectedListing={selectedListing}
                                    onListingSelect={handleListingClick}
                                    currentLaneId={laneId}
                                    hasMoreListings={hasMoreListings}
                                    loadingMoreListings={loadingMoreListings}
                                    fetchMoreListings={fetchMoreListings}
                                />
                            </Box>
                        )
                    }

                </Box>

                {
                    isMobile && view === 'map' && (
                        <SwipeableDrawer
                            anchor="bottom"
                            open={true}
                            variant="persistent"
                            style={{
                                zIndex: 1,
                                visibility: showDrawer ? 'visible' : 'hidden'
                            }}
                        >
                            <Box height={286} display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center" justifyContent="center"  {...handlers} onClick={()=> {
                                    setShowDrawer(false);
                                }}>
                                    <Box width={25} borderRadius="1">
                                        <ExpandMore />
                                    </Box>
                                </Box>
                                <Box visibility={showDrawer && swiperInitialized ? 'visible' : 'hidden'}>
                                    <Sidebar
                                        onSelectListing={(listing)=> {setSelectedListing(listing);} }
                                        hoveredListing={hoveredListing}
                                        onHover={handleOnHover}
                                        lastSeenDate={listpackLastSeenAt.current}
                                        listings={sortedListings}
                                        onSwiperInit={()=> { setSwiperInitialized(true); }}
                                        listpack={listpack}
                                        selectedListing={selectedListing}
                                        onListingSelect={handleListingClick}
                                        currentLaneId={laneId}
                                    />
                                </Box>
                            </Box>
                        </SwipeableDrawer>
                    )
                }
                {
                    isMobile && (
                        <Box>
                            <MobileFooter view={view} onViewChange={handleViewChange} listpack={listpack} onSortChange={onSortChange} sort={sort}/>
                        </Box>
                    )
                }
            </Box>
        </Dialog>
    );
}

export default observer(ListingsMap);
