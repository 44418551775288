import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AppBar, CircularProgress, Toolbar, withTheme } from '@material-ui/core';
import LRButton from '../../../components/LRButton';
import { useFormContext } from 'react-hook-form';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import { useStore } from '../../../mobx-store';

const StyledAppBar = withTheme(styled(AppBar)`
    background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
    color: #000;
    position: static;
    box-shadow: none;
    border-top: 1px solid ${({ theme })=> theme.palette.lr_colors.border_grey};
`);

const StyledToolbar = withTheme(styled(Toolbar)`
    display: grid;
    grid-gap: ${({ theme })=> theme.spacing(2)}px;
    grid-template-columns: repeat(3, 1fr);
    min-height: 64px;
`);

const Cancel = withTheme(styled(LRButton)`
    grid-column: 1 / span 1;
    text-align: center;
`);

const Schedule = styled(LRButton)`
    grid-column: ${({ isScheduled })=> (isScheduled ? 'span 3' : '2 / span 2')};
`;

function DialogFooter({ onClose, submitForm, isSubmiting, isScheduled }) {
    const { formState } = useFormContext();
    const { UserStore } = useStore();
    const isAffiliate = UserStore.isAgent || UserStore.isLO;
    const isSubmitDisabled = !formState.isValid || isSubmiting || isScheduled || isAffiliate;
    return (
        <StyledAppBar position="static" component="footer">
            <StyledToolbar>
                {!isScheduled && (
                    <Cancel fullWidth variant="outlined" onClick={onClose}>
                        Cancel
                    </Cancel>
                )}
                <Schedule
                    fullWidth
                    color="primary"
                    variant={isScheduled ? 'outlined' : 'contained'}
                    onClick={submitForm}
                    type={'submit'}
                    disabled={isSubmitDisabled}
                    isScheduled={isScheduled}
                >
                    {!isSubmiting &&
                        (isScheduled ? (
                            <>
                                <DoneRoundedIcon /> Notified
                            </>
                        ) : (
                            'Notify'
                        ))}
                    {isSubmiting && <CircularProgress color="secondary" size={20} />}
                </Schedule>
            </StyledToolbar>
        </StyledAppBar>
    );
}

DialogFooter.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default DialogFooter;
