import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import splitAddress from '../../../services/split-address';
import CreateIcon from '@material-ui/icons/Create';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledIconWrapper = styled(({...props})=> (
    <Box {...props} />
))`
    &:hover {
        cursor: pointer;
    }

    color: #DADADA;
    display: flex;
    margin-left: 10.5px;
`;

function DisplayAddress({ address, listing, listingsAreNotCards }) {
    const listingId = listing.id;
    const [addressLine1, addressLine2] = splitAddress(address);
    const listingName = listing.name;
    const history = useHistory();

    function openPersonalizeModal() {
        history.push(`/boards/name-home/${listingId}`);
    }

    return (
        <> 
            {!listingsAreNotCards ? (
                <>
                    <Box 
                        display="flex" 
                        alignItems={listingName && listingName.length > 24 ? "baseline" : "center"}
                    >
                        <Box 
                            fontSize={24} 
                            lineHeight={1.2} 
                            borderBottom={'1px dashed #DDDDDD'}
                            display='flex'
                        >
                            {listingName || addressLine1}
                        </Box>

                        <Box display="flex">
                            <Link to={`/boards/name-home/${listingId}?source=rpp`} >
                                <StyledIconWrapper onClick={openPersonalizeModal}>
                                    <CreateIcon fontSize={"small"}/>
                                </StyledIconWrapper>
                            </Link>   
                        </Box> 
                    </Box>
                </>    
            ) : (
                <>
                    <Box fontSize={24} lineHeight={1.2} >
                        {addressLine1}
                    </Box>
                </>
            )}

            <Box fontSize={12} mt={0.5} display="flex">
                {listingName?.length > 0 && !listingsAreNotCards ? address : addressLine2}
            </Box>
        </>
    );
}

DisplayAddress.propTypes = {
    address: PropTypes.string.isRequired,
};

export default React.memo(DisplayAddress);
