import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import styled from 'styled-components';
import { COLOR_BORDER_GREY, COLOR_GREY_NERO } from '../../constants';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    height: 42px;
    .MuiToggleButton-root {
        height: 42px;
        border-radius: 0;
        border-color: ${COLOR_BORDER_GREY};
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-transform: capitalize;
        ${(props)=> (props.equal ? 'flex: 1 1 0px;' : '')}
        color: ${COLOR_GREY_NERO};
    }

    & .Mui-selected {
        background: ${COLOR_GREY_NERO};
        color: #FFF !important;

        &:hover {
            background: #000;
            color: #FFF;
        }
    }
`;

export function LRToggleButtonGroup({ name, onChange, value, options, fullWidth, ...props }) {
    const onChangeHandler = function(event, newValue) {
        if(!!props?.disabled){
            return;
        }
        onChange(newValue);
    };
    let style = {};
    if(fullWidth) {
        style.width = '100%';
    }
    return (
        <StyledToggleButtonGroup
            value={value}
            exclusive
            onChange={onChangeHandler}
            aria-label="outlined primary button group"
            style={style}
        >
            {options.map((option)=> (
                <ToggleButton key={option.value} value={option.value} style={option.style} disabled={!!props?.disabled}>
                    {option.label || option.value}
                </ToggleButton>
            ))}
        </StyledToggleButtonGroup>
    );
}
