import React, { useState } from 'react';
import { Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useAgent } from 'hooks/useAgent';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useHasRobinAccess } from 'hooks/data/user-addons';
import LRAvatar from 'components/LRAvatar';
import formatPhoneNumber from 'services/format-phone-number';
import { Close } from '@material-ui/icons';
import { ContactAgentLeadCaptureButton } from 'components/LeadCaptureFormModal';
import { LISTPACK_CONTACT_AGENT_CARD_DISPLAY_FOR_SOURCES, LISTPACK_CONTACT_AGENT_CARD_DISMISS_LOCKR_KEY } from '../../constants';
import Lockr from 'lockr';
import { useQuery } from '../../hooks';

export function ContactAgentCard({
    onContactMeClick = (e)=> {},
    style = {},
    ...props
}) {
    const theme = useTheme();
    const { affiliateCode } = useParams();
    const { data: agent } = useAgent(affiliateCode, { priority: 'affiliate' });
    const { isMobile, isIPadDown } = useDeviceBreakpoints();
    const querySource = useQuery().get('source');
    const hasRobinAccess = useHasRobinAccess(agent?.id);
    const hasBeenDismissed = Lockr.get(LISTPACK_CONTACT_AGENT_CARD_DISMISS_LOCKR_KEY);
    const [ display, setDisplay ] = useState(
        (
            LISTPACK_CONTACT_AGENT_CARD_DISPLAY_FOR_SOURCES.includes(querySource) &&
            !hasBeenDismissed &&
            !hasRobinAccess &&
            agent
        )
    );
    const onDismissClick = (e)=> {
        setDisplay(false);
        Lockr.set(LISTPACK_CONTACT_AGENT_CARD_DISMISS_LOCKR_KEY, true);
    };

    if(!display) return null;
    return (
        <div
            style={{
                width: isMobile ? 'auto' : '100%',
                maxWidth: isIPadDown ? '350px' : '100%',
                backgroundColor: '#fff',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                marginTop: theme.spacing(isMobile ? 0 : 2),
                marginRight: theme.spacing(isMobile ? 0.5 : 2),
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(isMobile ? 0.5 : 2),
                padding: theme.spacing(2),
                paddingRight: theme.spacing(5),
                borderRadius: 4,
                position: 'relative',
                ...style,
            }}
            {...props}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Typography variant="body2">
                        Welcome! <span role="img" aria-label="icon hand wave">👋</span> Interested in buying or selling a home?{' '}
                        <ContactAgentLeadCaptureButton
                            agent={agent}
                            trackSource="agent_banner"
                            variant="text"
                            buttonProps={{
                                underline: true,
                                inline: true,
                                color: 'default',
                            }}
                        />{' '}
                        for more info!
                    </Typography>
                </Grid>
            </Grid>


            <div
                className="space-x-3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: theme.spacing(2),
                }}
            >
                {agent.headshot && <LRAvatar user={agent} size="small"></LRAvatar>}

                <div style={{...(isMobile && {display: 'flex', flexDirection: 'column'})}}>
                    <div className="space-x-2">
                        <Typography
                            variant="body2"
                            component="span"
                            style={{ fontWeight: 500 }}>
                            {agent.first_name} {agent.last_name}
                        </Typography>
                    </div>

                    {agent.phone && (
                        <Typography
                            component="span"
                            variant="caption">
                            {formatPhoneNumber(agent.phone)}
                        </Typography>
                    )}
                </div>
            </div>

            <IconButton onClick={onDismissClick} title="Close" style={{
                position: 'absolute',
                top: 0,
                right: 0,
            }}>
                <Close />
            </IconButton>
        </div>
    );
}
