export const realtor = (path)=> {
    const pathRegex = /.*\/(.*)_.*-.*/;
    const machedObject = path?.match(pathRegex);
    let address;

    if(machedObject) {
        const [, addressPart] = machedObject;
        address = addressPart?.replace(/(-|_)/g, ' ');
    }

    return address;
};
