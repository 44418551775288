import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTheme, Typography, IconButton } from '@material-ui/core';
import LRAvatar, { MultipleAvatars } from '../../LRAvatar';
import { ThumbUp as ThumbUpIcon, ThumbUpOutlined as ThumbUpOutlinedIcon, Visibility, Create } from '@material-ui/icons';
import Logger from 'js-logger';
import { useStore } from '../../../mobx-store';
import { timeago } from '../../../services/timeago';
import { findIndex, isNil } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Can } from '../../../components/Ability';
import { subject } from '@casl/ability';
const logger = Logger.get('Note');

export const Note = observer(function Note({
    boardCardCollaboration,
    style = {},
    onEditClick = ()=> {},
    onReactionClick = ()=> {},
}) {
    const theme = useTheme();
    const { UserStore } = useStore();
    const { user, isAgent, isLO } = UserStore;
    const [reactions, setReactions] = useState([]);
    const [usersReaction, setUsersReaction] = useState();
    const [usersThatHaveReacted, setUsersThatHaveReacted] = useState([]);
    const isBoardCollaborationFromAgent = useCallback((bc)=> (bc.userId === user?.affiliateId), [user]);
    const isBoardCollaborationFromLO = useCallback((bc)=> (bc.userId === user?.affiliate?.preferredVendorId), [user]);
    const boardCardUserIsAgent = isBoardCollaborationFromAgent(boardCardCollaboration);
    const boardCardUserIsLO = isBoardCollaborationFromLO(boardCardCollaboration);
    const commentAuthor = useMemo(()=> {
        return ( boardCardUserIsLO
            ? user?.affiliate?.preferred_vendor
            : boardCardUserIsAgent
                ? user?.affiliate
                : boardCardCollaboration.user );
    }, [boardCardCollaboration, boardCardUserIsAgent, boardCardUserIsLO, user]);
    const isPublic = boardCardCollaboration.visibleToAffiliate;
    let firstReactionName;
    if(reactions.length) {
        firstReactionName = isBoardCollaborationFromLO(reactions[0])
            ? user.affiliate?.preferred_vendor?.first_name
            : isBoardCollaborationFromAgent(reactions[0])
                ? user.affiliate?.first_name
                : reactions[0].user?.first_name;
    }

    useEffect(()=> {
        let reactions = Array.from(boardCardCollaboration.reactions || []);
        let userReaction = null;
        const findByKey = isLO
            ? { userId: user.affiliate?.preferredVendorId }
            : isAgent
                ? { affiliateId : user.affiliateId }
                : { userId : user.id };
        const usersReactionIndex = findIndex(reactions, findByKey);
        setUsersThatHaveReacted(reactions.map((reaction)=> {
            return isBoardCollaborationFromLO(reaction)
                ? user.affiliate?.preferred_vendor
                : isBoardCollaborationFromAgent(reaction)
                    ? user.affiliate
                    : reaction.user;
        }));

        if(usersReactionIndex >= 0) {
            userReaction = reactions[usersReactionIndex];
            reactions.splice(usersReactionIndex, 1);
        }

        setUsersReaction(userReaction);
        setReactions(reactions);
    }, [
        boardCardCollaboration.id,
        boardCardCollaboration.reactions,
        user,
        isAgent,
        isLO,
        isBoardCollaborationFromAgent,
        isBoardCollaborationFromLO,
    ]);

    return (
        <div
            className="CardBoardCollaborations-Note"
            mx={3}

            style={{
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                backgroundColor: '#fff',
                borderRadius: '4px',
                padding: theme.spacing(1),
                ...style,
            }}
        >
            <header
                className="CardBoardCollaborations-Note-header"
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ marginRight: theme.spacing(1) }}>
                        <LRAvatar
                            size="xsmall"
                            user={commentAuthor}
                        />
                    </div>

                    <div>
                        {commentAuthor?.first_name}
                        <Typography
                            component="span"
                            variant="body3"
                            style={{ color: theme.palette?.lr_colors?.grey_dark, marginLeft: theme.spacing(1) }}
                        >
                            {timeago(boardCardCollaboration.createdAt)}
                        </Typography>
                    </div>

                    <Can I="edit" this={subject('BoardCollaboration', boardCardCollaboration)}>
                        <div
                            style={{
                                flexGrow: '1',
                                textAlign: 'right',
                            }}
                        >
                            <IconButton
                                style={{
                                    padding: 0,
                                }}
                                onClick={onEditClick}
                            >
                                <Create style={{ width: '16px', height: '16px' }} />
                            </IconButton>
                        </div>
                    </Can>
                </div>
            </header>

            <div
                className="CardBoardCollaborations-Note-content"
                style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                    padding: theme.spacing(2),
                    paddingTop: theme.spacing(1.5),
                    borderRadius: '4px',
                    backgroundColor: isPublic ? theme.palette?.lr_colors?.blue_link : theme.palette?.lr_colors?.grey_light,
                    color: isPublic ? '#fff' : 'inherit',
                }}
            >
                {isPublic && !isAgent && !isLO && !boardCardUserIsAgent && (
                    <Typography
                        variant="body2"
                        style={{
                            textTransform: 'uppercase',
                            fontSize: theme.typography?.body3?.fontSize,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                            fontWeight: isPublic ? '500' : 'inherit',
                        }}
                    >
                        <Visibility style={{ width: '16px', height: '16px' }} /> <span
                            style={{ marginLeft: theme.spacing(1), display: 'inline-block' }}>
                                visible to {user?.affiliate?.first_name}{boardCardCollaboration.visibleToLO && !boardCardUserIsLO && (
                                <span> and {user?.affiliate?.preferred_vendor?.first_name}</span>
                            )}
                        </span>
                    </Typography>
                )}

                <Typography
                    variant="body2"
                    style={{
                        fontWeight: isPublic ? '500' : 'inherit',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        hyphens: 'auto',
                    }}
                >
                    {boardCardCollaboration.content?.message}
                </Typography>
            </div>

            <footer className="CardBoardCollaborations-Note-footer">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: '24px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {!!usersThatHaveReacted?.length && (
                            <>
                                <div>
                                    <MultipleAvatars
                                        users={usersThatHaveReacted}
                                        stepDown
                                        avatarSize="xxsmall"
                                    />
                                </div>

                                <div
                                    style={{
                                        marginLeft: theme.spacing(1),
                                    }}
                                >
                                    <Typography component="span" style={{ fontSize: '0.75rem' }}>
                                        Liked by {usersReaction && (<b>you</b>)} {usersReaction && !!reactions.length && 'and'} {
                                            !!reactions.length && (
                                                usersReaction ? (
                                                    <b>{reactions.length} other{reactions.length > 1 ? 's' : ''}</b>
                                                ) : (
                                                    <b>{firstReactionName}{reactions.length > 1 && ` and ${reactions.length - 1} other${reactions.length - 1 > 1 ? 's' : ''}`}</b>
                                                )
                                            )
                                        }
                                    </Typography>
                                </div>
                            </>
                        )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                        onClick={onReactionClick}
                    >
                        <div style={{ height: '16px' }}>
                            {usersReaction ? (<ThumbUpIcon style={{ width: '16px', height: '16px' }} />) : (<ThumbUpOutlinedIcon style={{ width: '16px', height: '16px' }} />)}
                        </div>

                        <div style={{ marginLeft: '5px', fontSize: '0.75rem' }}>
                            Like
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
});
