export default function splitAddress(address) {
    // suggestion for new regex /^(?<addressLine1>[^,]*),\s*(?<addressLine2>.*)/
    // to capture both addressLines in one go

    let [addressLine1, addressLine2] = (address || '')
        .replace(/^([^,;]+)(,)/i, '$1|||')
        .split('|||')
        .map((str)=> (str.trim()));

    if(addressLine2 && addressLine2.substr(0, 1) === '#') {
        const [unit, ...adr2] = addressLine2.split(',');
        addressLine1 = `${addressLine1} ${unit}`;
        addressLine2 = adr2.join(',');
    }

    return [
        addressLine1,
        addressLine2,
    ];
}
