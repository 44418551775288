import { useTheme } from '@material-ui/core';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import React from 'react';
import { NeighborhoodSwiper } from './NeighborhoodSwiper';
import { Section, SectionContainer, SectionHeader } from '../Section';
import { Header } from './Header';

export function NeighborhoodDataMultipleAreas({
    setOpenManageNeighborhoodModal,
    openSignUpModal,
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();
    return (
        <>
            {!isMobile && <Section>
                <SectionHeader style={{
                    width: '100%',
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.lr_colors.border_grey,
                    paddingBottom: theme.spacing(2)
                }}>
                    <Header
                        openManageModal={()=> setOpenManageNeighborhoodModal(true)}
                        openSignUpModal={openSignUpModal}
                    />
                </SectionHeader>

                <SectionContainer style={{backgroundColor: theme.palette.lr_colors.grey_medium_second}}>
                    <div style={{width: '100%'}}>
                        <NeighborhoodSwiper
                            openManageModal={()=> setOpenManageNeighborhoodModal(true)}
                            openSignUpModal={openSignUpModal}
                            swiperProps={{
                                slidesPerView: 2.4,
                                hasNavigation: true,
                                spaceBetween: 20
                            }}
                        />
                    </div>
                </SectionContainer>
            </Section>}
            
            {isMobile && <div 
                style={{
                    backgroundColor: theme.palette.lr_colors.grey_medium_second,
                    paddingBottom: theme.spacing(4) - 2
                }}>
                <Header
                    openManageModal={()=> setOpenManageNeighborhoodModal(true)}
                    openSignUpModal={openSignUpModal}
                />
                <NeighborhoodSwiper
                    openManageModal={()=> setOpenManageNeighborhoodModal(true)}
                    openSignUpModal={openSignUpModal}
                    swiperProps={{
                        slidesPerView: 1.3,
                        spaceBetween: 20
                    }}
                />
            </div>}
        </>
    );
};
