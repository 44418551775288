import React from 'react';
import { SvgIcon } from '@material-ui/core';

export function LogoRIcon(props) {
    return (
        <SvgIcon viewBox="0 0 89 27" {...props}>
            <path d="M21.8795 -0.00700378C20.5334 -0.00706755 19.2085 0.329417 18.0256 0.971846C16.8426 1.61428 15.839 2.54226 15.106 3.6714L14.0112 2.57489C13.9499 2.51008 13.8886 2.44177 13.8256 2.37871C12.6928 1.24985 11.2512 0.481594 9.68253 0.170762C8.11382 -0.140069 6.48821 0.0204402 5.01058 0.632058C3.53296 1.24368 2.26946 2.27903 1.37934 3.60762C0.489226 4.9362 0.0123311 6.49856 0.00876811 8.09776V8.11002C0.00541461 9.17237 0.212496 10.2249 0.61806 11.2068C1.02362 12.1886 1.61964 13.0805 2.37171 13.8308C2.44528 13.9026 2.51884 13.9727 2.59416 14.041L14.9957 26.4425L27.6073 13.8186C28.7397 12.6857 29.5108 11.2426 29.8231 9.67157C30.1353 8.10056 29.9748 6.47224 29.3617 4.99246C28.7487 3.51268 27.7106 2.2479 26.3788 1.35802C25.047 0.468134 23.4813 -0.00689176 21.8795 -0.00700378V-0.00700378ZM4.38783 11.8235C3.44752 10.8293 2.93208 9.50764 2.95111 8.1394C2.97014 6.77116 3.52213 5.4643 4.48972 4.49672C5.4573 3.52913 6.76416 2.97714 8.1324 2.95811C9.50064 2.93908 10.8223 3.45452 11.8165 4.39483L15.5194 8.09601L11.8165 11.8252C10.8309 12.8096 9.49476 13.3623 8.10178 13.362C6.70881 13.3617 5.37296 12.8083 4.38783 11.8235V11.8235ZM25.5982 11.8129L14.9992 22.4243L8.74762 16.1762C10.666 16.0277 12.4678 15.1976 13.8273 13.8361L19.5341 8.089L17.1869 5.74358C17.4388 5.24066 17.7693 4.7812 18.1661 4.38256C18.6508 3.88067 19.2307 3.48034 19.8718 3.20494C20.5129 2.92954 21.2025 2.78458 21.9002 2.77851C22.5979 2.77245 23.2899 2.90541 23.9357 3.16963C24.5815 3.43385 25.1682 3.82404 25.6616 4.31743C26.155 4.81082 26.5452 5.39754 26.8094 6.04334C27.0736 6.68915 27.2066 7.38111 27.2005 8.07884C27.1945 8.77658 27.0495 9.46613 26.7741 10.1072C26.4987 10.7484 26.0984 11.3282 25.5965 11.8129H25.5982Z" />
            <path d="M9.99303 6.29884C9.68123 6.29884 9.37644 6.39129 9.11719 6.56452C8.85794 6.73774 8.65588 6.98395 8.53657 7.27201C8.41725 7.56007 8.38603 7.87705 8.44686 8.18285C8.50769 8.48865 8.65783 8.76955 8.8783 8.99002C9.09877 9.21049 9.37967 9.36064 9.68547 9.42146C9.99128 9.48229 10.3083 9.45107 10.5963 9.33176C10.8844 9.21244 11.1306 9.01038 11.3038 8.75113C11.477 8.49188 11.5695 8.18709 11.5695 7.8753C11.5695 7.45719 11.4034 7.05621 11.1078 6.76057C10.8121 6.46493 10.4111 6.29884 9.99303 6.29884Z" />
            <path d="M70.2611 3.3456C69.9493 3.3456 69.6445 3.43806 69.3853 3.61129C69.126 3.78451 68.924 4.03072 68.8047 4.31878C68.6853 4.60684 68.6541 4.92381 68.715 5.22962C68.7758 5.53542 68.9259 5.81632 69.1464 6.03679C69.3669 6.25726 69.6478 6.4074 69.9536 6.46823C70.2594 6.52906 70.5764 6.49784 70.8644 6.37852C71.1525 6.25921 71.3987 6.05715 71.5719 5.7979C71.7451 5.53865 71.8376 5.23386 71.8376 4.92206C71.8376 4.50396 71.6715 4.10298 71.3759 3.80734C71.0802 3.51169 70.6792 3.3456 70.2611 3.3456Z" />
            <path d="M41.4154 8.02945C41.1672 8.03158 40.9195 8.05266 40.6745 8.0925C40.395 8.13708 40.1216 8.21409 39.8599 8.32197C39.5768 8.44111 39.3157 8.60689 39.0875 8.81242C38.8335 9.04237 38.6311 9.32356 38.4937 9.63744V8.11352H35.7997V18.385H38.576V13.6872C38.5624 13.2146 38.6366 12.7436 38.795 12.2982C38.9194 11.9643 39.1155 11.6617 39.3695 11.4118C39.6065 11.1885 39.8927 11.0241 40.205 10.9319C40.5367 10.832 40.8815 10.7824 41.228 10.7848C41.508 10.7849 41.7873 10.8131 42.0617 10.8688V8.06623C41.9514 8.05222 41.8428 8.04171 41.7394 8.0347C41.6361 8.02769 41.5222 8.02945 41.4154 8.02945Z" />
            <path d="M52.8395 9.3344C52.3499 8.84855 51.768 8.4656 51.1281 8.20811C49.7536 7.66388 48.2234 7.66388 46.8489 8.20811C46.2061 8.46349 45.6224 8.84801 45.134 9.33789C44.6457 9.82777 44.2629 10.4126 44.0095 11.0563C43.7337 11.7542 43.5957 12.4989 43.6032 13.2493C43.595 13.9958 43.7331 14.7366 44.0095 15.4301C44.2693 16.0723 44.6525 16.6573 45.1376 17.1519C45.6247 17.6438 46.207 18.0312 46.8489 18.2905C48.2239 18.8322 49.7532 18.8322 51.1281 18.2905C51.77 18.0312 52.3523 17.6438 52.8395 17.1519C53.3245 16.6573 53.7078 16.0723 53.9675 15.4301C54.2448 14.7368 54.3829 13.9958 54.3739 13.2493C54.3821 12.4988 54.2441 11.7539 53.9675 11.0563C53.7113 10.4122 53.3276 9.82656 52.8395 9.3344V9.3344ZM51.3786 14.521C51.2485 14.8587 51.0533 15.1676 50.8041 15.4301C50.569 15.6721 50.2847 15.8609 49.9703 15.9836C49.3403 16.2335 48.6386 16.2335 48.0085 15.9836C47.6934 15.8614 47.4084 15.6726 47.173 15.4301C46.9244 15.1671 46.7292 14.8584 46.5984 14.521C46.3065 13.6977 46.3065 12.7991 46.5984 11.9759C46.7292 11.6385 46.9244 11.3297 47.173 11.0668C47.4065 10.8249 47.6898 10.6366 48.0032 10.515C48.6329 10.2628 49.3354 10.2628 49.9651 10.515C50.2796 10.6364 50.5641 10.8247 50.7988 11.0668C51.048 11.3293 51.2432 11.6381 51.3734 11.9759C51.6653 12.7991 51.6653 13.6977 51.3734 14.521H51.3786Z" />
            <path d="M65.4512 9.37644C65.0416 8.89642 64.5323 8.51146 63.9588 8.24839C63.3284 7.96844 62.6445 7.82974 61.9549 7.84202C61.2793 7.82239 60.6092 7.96917 60.0036 8.26941C59.5418 8.49122 59.143 8.8255 58.844 9.24157V3.27028H56.1098V18.385H58.8038V17.1326C59.0999 17.595 59.5141 17.9699 60.0036 18.2186C60.5755 18.5086 61.2105 18.6519 61.8516 18.6355C62.534 18.6441 63.2103 18.5055 63.8344 18.2291C64.4217 17.965 64.9446 17.5766 65.3671 17.0906C65.8033 16.5859 66.137 16.001 66.3497 15.3687C66.587 14.6736 66.7054 13.9435 66.7001 13.209C66.7068 12.4872 66.6016 11.7686 66.3883 11.079C66.1929 10.4546 65.8742 9.87561 65.4512 9.37644V9.37644ZM63.174 15.3985C62.9415 15.6415 62.6611 15.8337 62.3506 15.9631C62.0401 16.0924 61.7062 16.156 61.3699 16.15C61.0289 16.1512 60.6912 16.0839 60.3767 15.952C60.0676 15.8213 59.7903 15.6253 59.564 15.3775C59.3226 15.1096 59.1348 14.7981 59.0104 14.4597C58.866 14.0673 58.7953 13.6516 58.802 13.2335C58.7934 12.8067 58.8641 12.3821 59.0104 11.9811C59.1327 11.6443 59.3209 11.3352 59.564 11.072C59.7913 10.8305 60.0687 10.6416 60.3767 10.5185C60.6931 10.3919 61.0308 10.3271 61.3716 10.3276C61.707 10.3211 62.0402 10.3835 62.3505 10.511C62.6609 10.6384 62.9417 10.8282 63.1758 11.0685C63.6698 11.5625 63.9167 12.283 63.9167 13.23C63.9167 14.1771 63.6698 14.9028 63.1758 15.4073L63.174 15.3985Z" />
            <path d="M71.6624 8.11353H68.8861V18.3833H71.6624V8.11353Z" />
            <path d="M82.8728 9.03137C82.5544 8.66139 82.1586 8.36598 81.7132 8.16607C81.1871 7.94341 80.6202 7.83308 80.0489 7.84213C79.4776 7.85118 78.9145 7.97941 78.3957 8.21862C77.8508 8.46448 77.3951 8.87249 77.0907 9.38695V8.11352H74.3985V18.385H77.1748V12.4768C77.1572 11.9289 77.3348 11.3926 77.6758 10.9634C77.8479 10.7572 78.0658 10.5941 78.3121 10.487C78.5584 10.38 78.8264 10.332 79.0946 10.3468C79.7619 10.3468 80.2454 10.543 80.5449 10.9319C80.8549 11.3543 81.0129 11.8691 80.9933 12.3927V18.385H83.7697V11.9128C83.7729 11.3766 83.6991 10.8428 83.5507 10.3276C83.4193 9.8523 83.1882 9.41045 82.8728 9.03137V9.03137Z" />
            <path d="M87.2536 11.608C86.9072 11.608 86.5685 11.5053 86.2805 11.3128C85.9924 11.1203 85.7679 10.8468 85.6353 10.5267C85.5028 10.2066 85.4681 9.85445 85.5357 9.51467C85.6033 9.17488 85.7701 8.86277 86.015 8.61781C86.26 8.37284 86.5721 8.20601 86.9119 8.13842C87.2517 8.07084 87.6039 8.10553 87.924 8.2381C88.244 8.37068 88.5176 8.59519 88.7101 8.88324C88.9025 9.17129 89.0053 9.50995 89.0053 9.85639C89.0053 10.3209 88.8207 10.7665 88.4922 11.095C88.1637 11.4235 87.7182 11.608 87.2536 11.608V11.608ZM87.2536 8.40254C86.9649 8.4022 86.6826 8.48752 86.4423 8.64772C86.2021 8.80791 86.0148 9.03577 85.9042 9.30246C85.7935 9.56915 85.7645 9.86267 85.8207 10.1459C85.877 10.4291 86.016 10.6892 86.2201 10.8934C86.4243 11.0975 86.6845 11.2365 86.9677 11.2928C87.2509 11.349 87.5444 11.32 87.8111 11.2093C88.0778 11.0987 88.3056 10.9114 88.4658 10.6712C88.626 10.431 88.7113 10.1486 88.711 9.85989C88.7121 9.6681 88.6753 9.47799 88.6025 9.30055C88.5296 9.12312 88.4224 8.96188 88.2868 8.82619C88.1513 8.69049 87.9902 8.58302 87.8128 8.51C87.6355 8.43698 87.4454 8.39986 87.2536 8.40079V8.40254ZM87.7231 10.8653L87.1976 10.0753H86.8893V10.8653H86.5845V8.86322H87.4008C87.5712 8.85463 87.7381 8.91398 87.8648 9.02825C87.9916 9.14251 88.0678 9.30238 88.0769 9.47278C88.0798 9.6174 88.0292 9.75799 87.9348 9.86759C87.8404 9.97718 87.7089 10.048 87.5654 10.0666L88.1014 10.8653H87.7231ZM87.3938 9.1137H86.8893V9.81435H87.4008C87.4937 9.81435 87.5828 9.77744 87.6485 9.71174C87.7142 9.64604 87.7511 9.55694 87.7511 9.46403C87.7511 9.37111 87.7142 9.28201 87.6485 9.21631C87.5828 9.15061 87.4937 9.1137 87.4008 9.1137H87.3938Z" />
        </SvgIcon>
    );
}

export function LogoRMarkIcon(props) {
    return (
        <SvgIcon viewBox="0 0 35 27" {...props}>
            <path d="M22.3349 -0.00714873C20.9607 -0.00721383 19.6083 0.336274 18.4007 0.992074C17.1931 1.64787 16.1686 2.59517 15.4204 3.74782L14.3028 2.62848C14.2403 2.56232 14.1777 2.49259 14.1133 2.42821C12.957 1.27587 11.4854 0.491618 9.88403 0.174317C8.28268 -0.142983 6.62323 0.0208664 5.11485 0.645214C3.60648 1.26956 2.31668 2.32646 1.40804 3.6827C0.499395 5.03894 0.012574 6.63381 0.0089368 8.26629V8.27881C0.0055135 9.36327 0.216905 10.4377 0.63091 11.44C1.04492 12.4423 1.65334 13.3527 2.42106 14.1187C2.49616 14.192 2.57125 14.2635 2.64814 14.3332L15.3077 26.9928L28.1819 14.1062C29.3379 12.9497 30.125 11.4766 30.4437 9.87286C30.7625 8.26916 30.5986 6.60694 29.9728 5.09637C29.347 3.58579 28.2873 2.29469 26.9278 1.38628C25.5683 0.477878 23.97 -0.00703439 22.3349 -0.00714873V-0.00714873ZM4.47914 12.0695C3.51926 11.0547 2.99309 9.70552 3.01252 8.3088C3.03194 6.91208 3.59542 5.57803 4.58315 4.5903C5.57087 3.60258 6.90493 3.0391 8.30164 3.01967C9.69836 3.00025 11.0476 3.52642 12.0624 4.48629L15.8424 8.26451L12.0624 12.0713C11.0563 13.0762 9.69235 13.6404 8.27039 13.6401C6.84842 13.6398 5.48477 13.0749 4.47914 12.0695V12.0695ZM26.131 12.0588L15.3113 22.891L8.92966 16.5129C10.8879 16.3613 12.7273 15.5139 14.1151 14.124L19.9407 8.25735L17.5446 5.86311C17.8017 5.34973 18.1391 4.88071 18.5442 4.47378C19.039 3.96144 19.6309 3.55278 20.2854 3.27164C20.9398 2.99051 21.6437 2.84253 22.356 2.83634C23.0682 2.83015 23.7746 2.96588 24.4339 3.23559C25.0931 3.50531 25.692 3.90362 26.1957 4.40729C26.6994 4.91095 27.0977 5.50988 27.3674 6.16912C27.6371 6.82837 27.7728 7.53473 27.7666 8.24699C27.7604 8.95925 27.6125 9.66314 27.3313 10.3176C27.0502 10.9721 26.6415 11.564 26.1292 12.0588H26.131Z" />
            <path d="M10.201 6.42993C9.88271 6.42993 9.57157 6.52431 9.30693 6.70114C9.04229 6.87797 8.83602 7.12931 8.71422 7.42336C8.59242 7.71742 8.56055 8.04099 8.62264 8.35316C8.68474 8.66532 8.83801 8.95207 9.06307 9.17713C9.28813 9.40219 9.57487 9.55546 9.88704 9.61755C10.1992 9.67965 10.5228 9.64778 10.8168 9.52598C11.1109 9.40417 11.3622 9.19791 11.5391 8.93327C11.7159 8.66862 11.8103 8.35749 11.8103 8.0392C11.8103 7.6124 11.6407 7.20307 11.3389 6.90128C11.0371 6.59948 10.6278 6.42993 10.201 6.42993Z" />
            <path d="M32.2695 3.569C31.9158 3.569 31.5701 3.46413 31.2761 3.26766C30.982 3.07118 30.7528 2.79192 30.6175 2.46519C30.4822 2.13846 30.4467 1.77894 30.5157 1.43209C30.5847 1.08523 30.755 0.766628 31.0051 0.516561C31.2552 0.266493 31.5738 0.0961953 31.9206 0.0272019C32.2675 -0.0417916 32.627 -0.00638163 32.9537 0.128954C33.2805 0.264289 33.5597 0.493472 33.7562 0.78752C33.9527 1.08157 34.0575 1.42728 34.0575 1.78092C34.0575 2.25515 33.8692 2.70996 33.5338 3.04529C33.1985 3.38062 32.7437 3.569 32.2695 3.569V3.569ZM32.2695 0.296818C31.9746 0.29682 31.6865 0.384313 31.4414 0.548216C31.1963 0.71212 31.0054 0.945061 30.8929 1.21754C30.7803 1.49002 30.7511 1.78977 30.809 2.07885C30.8669 2.36792 31.0093 2.63331 31.2182 2.8414C31.427 3.04949 31.6929 3.19092 31.9822 3.24779C32.2715 3.30465 32.5711 3.27439 32.8432 3.16083C33.1153 3.04728 33.3475 2.85553 33.5105 2.60988C33.6735 2.36424 33.76 2.07574 33.7589 1.78092C33.7599 1.58514 33.722 1.39112 33.6475 1.21008C33.5729 1.02904 33.4632 0.86458 33.3247 0.726223C33.1862 0.587866 33.0216 0.478358 32.8404 0.404036C32.6593 0.329715 32.4652 0.292057 32.2695 0.293242V0.296818ZM32.7505 2.81265L32.214 2.00443H31.8868V2.81265H31.5757V0.765295H32.4071C32.5811 0.756528 32.7515 0.817109 32.8809 0.933754C33.0103 1.0504 33.0881 1.21359 33.0973 1.38755C33.1003 1.53517 33.0487 1.67869 32.9523 1.79057C32.856 1.90244 32.7217 1.97478 32.5752 1.9937L33.1224 2.80907L32.7505 2.81265ZM32.4125 1.02457H31.8922V1.73086H32.4125C32.4636 1.73901 32.5158 1.73599 32.5656 1.72199C32.6154 1.70799 32.6616 1.68336 32.701 1.64978C32.7403 1.61621 32.7719 1.5745 32.7936 1.52753C32.8153 1.48056 32.8265 1.42944 32.8265 1.37771C32.8265 1.32598 32.8153 1.27487 32.7936 1.22789C32.7719 1.18092 32.7403 1.13921 32.701 1.10564C32.6616 1.07207 32.6154 1.04743 32.5656 1.03344C32.5158 1.01944 32.4636 1.01641 32.4125 1.02457Z" />
        </SvgIcon>
    );
}
