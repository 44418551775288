import { makeStyles} from '@material-ui/core';

export const containerStyles = makeStyles((theme)=> ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(\'/images/auth/bkg-auth.png\')',
            backgroundPosition: '250px 0',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            paddingLeft: theme.spacing(9),
        },
    },
}));

export const logoStyles = makeStyles((theme)=> ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(6),
        },
    },
}));

export const taglineStyles = makeStyles((theme)=> ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(4),
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(7),
            maxWidth: 371,
        },
    },
}));

export const buttonStyles = makeStyles((theme)=> ({
    root: {
        fontWeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            height: 40,
            width: 158,
        },
        [theme.breakpoints.up('md')]: {
            height: 64,
            width: 195,
        },
    },
}));

export const buttonContentStyles = makeStyles((theme)=> ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));


export const ImgInfoStyles = makeStyles(()=> ({
    root: {
        height: '100%',
        width: '100%',
        maxWidth: 552,
        maxHeight: 744,
        backgroundImage: 'url(\'/images/auth/auth_img_info@1x.png\')',
        backgroundSize: '100%',
        backgroundPosition: '0 0',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
    },
}));
