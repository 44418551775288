import { Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import 'react-placeholder/lib/reactPlaceholder.css';
import { withRouter } from 'react-router-dom';
import { useStore } from '../../../mobx-store';
import { MinimizedCardPlaceholder } from '../ListpackCard';

export const ArchivedHomes = withRouter(observer(({
    listpack, 
    index,
    history,
    ...props
})=> {

    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const archivedListingsCount = activeBoard?.archivedListings?.length || 0;

    function handleClick() {
        if(archivedListingsCount === 0) return;
        history.push('/boards/archivedListings');
    }

    return (
        <div onClick={handleClick} >
            {activeBoard && (
                <ReactPlaceholder
                    ready={true} 
                    customPlaceholder={<Box mt={2}><MinimizedCardPlaceholder /></Box>}
                >
                    <Box mt={2}>
                        <Box 
                            bgcolor="#FFF" 
                            display="flex" 
                            px={2} 
                            py={1} 
                            justifyContent="space-between" 
                            alignItems="center" 
                            boxShadow="0px 1px 2px rgba(0, 0, 0, 0.35)" 
                            borderRadius="4px"
                            style={{ 
                                cursor: 'pointer'
                            }}
                        >
                            <Box>
                                🗑️
                            </Box>
                            <Box display="flex" flex={1} alignItems="center">
                                <Box fontSize="body2.fontSize" fontWeight="normal" mx={1}>
                                    Deleted homes
                                </Box>
                            </Box>

                            <Box color="lr_colors.grey_dark" display="flex" width={55}>
                                <>
                                    <Box fontSize="16px" display="flex" alignItems="center">
                                        <HomeIcon fontSize="inherit"/>
                                    </Box>
                                    <Box component="span" ml={0.5}>{archivedListingsCount}</Box>
                                </>
                            </Box>
                        </Box>
                    </Box>
                </ReactPlaceholder >
            )}
        </div>
    );
}));