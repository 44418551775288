import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PinspirationOverlay from '../../Pinspiration/PinspirationOverlay';

const StyledImageWrap = styled.div`
    max-width: 100%;
    max-height: 100%;
    margin: 16px auto;
`;
const StyledImageContainer = styled.div`
    display: inline-block;
    position: relative;

    > img {
        width: 100%;
    }
`;

const scrollAdjust = 95;

const scrollInParent = (parentRef, childRef)=> {
    parentRef.current.scrollTo({top: childRef.current.offsetTop - scrollAdjust});
};

function List({ photos = [], selectedPhotoIndex, className = '', listing, listingId, listpackId, parentRef }) {
    const theme = useTheme();
    const gtXs = useMediaQuery(theme.breakpoints.up('sm'));
    const myRef = useRef(null);

    useEffect(()=> {
        if(selectedPhotoIndex) {
            scrollInParent(parentRef, myRef);
        }
    }, []);

    return (
        <Box align="center" marginTop={gtXs ? '40px' : '10px'}>
            {photos.map((photo, i)=> (
                <StyledImageWrap key={i}
                    ref={i === selectedPhotoIndex ? myRef : undefined}
                >
                    <StyledImageContainer>
                        <img src={photo}
                            alt={photo}
                        />
                        {listing && <PinspirationOverlay
                            src={photo}
                            listing={listing}
                            listpackId={listpackId}
                        />}
                    </StyledImageContainer>
                </StyledImageWrap>
            ))}
        </Box>
    );
}

List.propTypes = {
    photos: PropTypes.array.isRequired,
    selectedPhotoIndex: PropTypes.number.isRequired,
    className: PropTypes.string,
    ref: PropTypes.func,
};

export default React.memo(List);
