import { AppBar, DialogActions, DialogContent, Fade, Grid, IconButton, Slide, Toolbar, Typography, useTheme } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { LRAreaAutocompleteV2 } from 'components/LRAreaAutocomplete';
import LRButton from 'components/LRButton';
import { useMyHome } from 'hooks/useMyHome';
import Logger from 'js-logger';
import { uniq } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { LRDialog } from '../../../components/LRDialog';
import { useDeviceBreakpoints } from '../../../hooks/useDeviceBreakpoints';

function ActionCTAs({
    onClose,
    disableButtons = false,
    style = {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();

    return (
        <DialogActions
            {...props}
            style={{
                padding: theme.spacing(isMobile ? 2 : 3),
                paddingTop: isMobile ? theme.spacing(2) - 2 : 0,
                paddingBottom: isMobile ? theme.spacing(2) - 2 : theme.spacing(3),
                ...style,
            }}
        >
            <Grid item xs>
                {!isMobile && (
                    <LRButton variant="text" inline underline color="inherit" onClick={onClose} style={{ marginRight: theme.spacing(4) }}>
                        Cancel
                    </LRButton>
                )}
            </Grid>

            <Grid item>
                <LRButton type="submit" variant="contained" color="primary" disabled={disableButtons}>
                    Save
                </LRButton>
            </Grid>
        </DialogActions>
    );
}

function MobileHeader({
    onClose,
    style = {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();

    return (
        <>
            <AppBar
                color="inherit"
                elevation={0}
                {...props}
                style={{
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.lr_colors.border_grey,
                    ...style,
                }}
            >
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <Grid item xs></Grid>

                    <Grid item xs={8}>
                        <Typography align="center">
                            Edit ZIP
                        </Typography>
                    </Grid>

                    <Grid item xs style={{ textAlign: 'right' }}>
                        <IconButton edge="end" onClick={onClose}>
                            <CloseRounded />
                        </IconButton>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Toolbar style={{ height: isMobile ? 56 : 67 }} />
        </>
    );
}

export function EditAreaModal({
    open = false,
    PaperProps = {},
    onClose = ()=> {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();
    const {
        register,
        handleSubmit,
        setValue,
        errors,
        reset,
    } = useForm();
    const [area, setArea] = React.useState(null);
    const { lead, updateAreas } = useMyHome();

    // Functions

    async function onSubmit(formData) {
        // NOTE: Uncomment this code when we want to handle multiple areas
        // const areaIds = lead?.meta?.robin?.home_report?.default_preferences?.areaIds || [];
        // const newAreaIds = uniq([
        //     ...areaIds,
        //     formData.area.id,
        // ]);

        // updateAreas(newAreaIds);
        updateAreas([formData.area.id]);
        reset();
        setArea(null);
        onClose();
    }

    function onAreaChange(area) {
        setValue('area', area);
        setArea(area);
    }

    // UseEffects

    useEffect(()=> {
        register('area', { required: true });
    }, [register]);

    useEffect(()=> {
        if(open) {
            reset({ area: null });
            setArea(null);
        }
    }, [open, reset]);

    return (
        <LRDialog
            open={open}
            fullScreen={isMobile}
            PaperProps={{
                style: {
                    borderRadius: isMobile && 0,
                },
                ...PaperProps,
            }}
            TransitionComponent={isMobile ? Slide : Fade}
            TransitionProps={isMobile && {
                direction: 'up',
            }}
            {...props}
        >
            <form name="editArea" noValidate onSubmit={handleSubmit(onSubmit)}>
                {isMobile && (
                    <MobileHeader
                        onClose={onClose}
                    />
                )}

                <DialogContent
                    style={{
                        width: !isMobile && 376,
                        paddingTop: theme.spacing(isMobile ? 3 : 2),
                    }}
                >
                    <LRAreaAutocompleteV2
                        autoFocus
                        value={area}
                        name="area"
                        label="ZIP code"
                        type="zip"
                        noOptionsText="No ZIP code found"
                        onChange={onAreaChange}
                        error={Boolean(errors.area)}
                        helperText={Boolean(errors.area) && 'ZIP code is required.'}
                    />
                </DialogContent>

                {isMobile ? (
                    <AppBar
                        color="inherit"
                        elevation={0}
                        style={{
                            top: 'auto',
                            bottom: 0,
                            borderTop: '1px solid',
                            borderTopColor: theme.palette.lr_colors.border_grey,
                            backgroundColor: theme.palette.lr_colors.costa_mesa,
                        }}
                    >
                        <Toolbar style={{ padding: 0 }}>
                            <Grid item xs>
                                <ActionCTAs
                                    onClose={onClose}
                                />
                            </Grid>
                        </Toolbar>
                    </AppBar>
                ) : (
                    <ActionCTAs
                        onClose={onClose}
                    />
                )}
            </form>
        </LRDialog>
    );
}
