import { CircularProgress, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { LRSplitButton } from 'components/LRSplitButton';
import { LANES_NAMES, SOLD_STATUSES } from '../../constants';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useMoveCardOrListing } from 'hooks/useMoveCardOrListing';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import { useActiveBoard, useStore } from 'mobx-store';
import React, { useMemo, useState } from 'react';
import analytics from 'services/analytics';
import LRButton from 'components/LRButton';
import { some } from 'lodash';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { useHistory, useRouteMatch } from 'react-router';

export const MoveCardOrListingButton = observer(function MoveCardOrListingButton({
    listing,
    boardCard,
    ...props
}) {
    const theme = useTheme();
    const { LanesStore } = useStore();
    const history = useHistory();
    const match = useRouteMatch();
    const moveCardOrListing = useMoveCardOrListing('rpp_move_to_board_button');
    const activeBoard = useActiveBoard();
    const { isIPadDown, isMobile } = useDeviceBreakpoints();
    const {
        boardCollaborations,
    } = useBoardCollaborations(boardCard?.id);
    const lanes = LanesStore.lanes;
    const laneIndex = useMemo(()=> {
        if(!boardCard) return -1;
        return lanes.findIndex((lane)=> lane.columnId === parseInt(boardCard?.columnId));
    }, [boardCard, lanes]);
    const laneOptions = useMemo(()=> {
        const mapper = (lane)=> ({
            label: (
                <div style={{textAlign: isIPadDown ? 'left' : 'center'}}>
                    <span style={{
                        fontSize: isIPadDown ? theme.typography.caption.fontSize : theme.typography.body2.fontSize
                    }}>
                        Move to: {isIPadDown && <br/>}
                    </span>
                    <span>{lane.name}</span>
                </div>
            ),
            ...lane
        });

        if(laneIndex < 0 || !boardCard) {
            return lanes.map(mapper);
        } else {
            return lanes
                .slice(boardCard.archived ? laneIndex : laneIndex + 1)
                .concat(lanes.slice(0, laneIndex))
                .map(mapper);
        }
    }, [boardCard, isIPadDown, laneIndex, lanes, theme.typography.body2.fontSize, theme.typography.caption.fontSize]);
    const primaryOption = laneOptions[0];
    const secondaryOptions = laneOptions.slice(1);
    const [movingCard, setMovingCard] = useState(false);
    const currentCardLane = useMemo(()=> {
        return lanes[laneIndex];
    }, [laneIndex, lanes]);

    const inMakeAnOfferLane = useMemo(()=> {
        const status = listing?.listing_status?.toLowerCase();
        const isSold = SOLD_STATUSES.includes(status);
        return !isSold && (currentCardLane && currentCardLane.name === LANES_NAMES['buy']);
    }, [currentCardLane, listing]);

    const isScheduled = useMemo(()=> {
        return some(boardCollaborations, ['type', 'offer_submitted']);
    }, [boardCollaborations]);

    // Functions
    async function moveListing(destinationLane) {
        if(moveCardOrListing) {
            setMovingCard(true);
            await moveCardOrListing(
                { listing_details: listing },
                destinationLane,
                'Your searches',
                (boardCard)=> {
                    analytics.eventTrack('robin_card_created', {
                        source: 'saved_search_results_header',
                        boardId: activeBoard?.id,
                        card_id: boardCard?.id,
                        listing_id: listing?.id,
                        address: listing?.address,
                    });
                }
            );
            setMovingCard(false);
        }
    }

    function handlePrimaryOptionClick() {
        moveListing(primaryOption);
    }

    function handleSecondaryOptionClick(option) {
        moveListing(option);
    }

    function handleMakeAnOfferClick() {
        analytics.eventTrack('rpp_header_make_offer_button_click', {
            card_id: boardCard.id
        });
        history.push(`${match.url}/submit-offer/${boardCard.id}`);
    }

    if(inMakeAnOfferLane) {
        return  (
            <Grid container wrap="nowrap" alignItems="center" {...props}>
                <Grid item>
                    <LRButton
                        variant={(isScheduled) ? 'outlined' : 'contained'}
                        color={(isScheduled) ? 'default' : 'primary'}
                        onClick={handleMakeAnOfferClick}
                        style={{
                            width: isMobile ? 175 : 212,
                        }}
                    >
                        {(isScheduled) ? 'Agent notified' : 'Prepare an offer'}
                    </LRButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container wrap="nowrap" alignItems="center" {...props}>
            <Grid item>
                <LRSplitButton
                    disabled={movingCard}
                    primaryLabel={primaryOption.label}
                    onPrimaryClick={handlePrimaryOptionClick}
                    secondaryOptions={secondaryOptions}
                    onSecondaryClick={handleSecondaryOptionClick}
                    primaryProps={isIPadDown ?
                        {style: {height: theme.spacing(6), minWidth: theme.spacing(15), paddingLeft: theme.spacing(1.5), borderColor: 'white'}} :
                        {style: {height: theme.spacing(4.5), minWidth: theme.spacing(22), padding: '6px 12px 6px 12px', display: 'block', borderColor: 'white'}}
                    }
                    secondaryProps={isIPadDown ?
                        {style: {height: theme.spacing(6), width: theme.spacing(6)}} :
                        {style: {height: theme.spacing(4.5), width: theme.spacing(4.5), padding: '0', minWidth: 'unset'}}
                    }
                />
            </Grid>

            {movingCard && (
                <Grid item style={{ height: 24 }}>
                    <CircularProgress size={24} color="primary"  style={{marginLeft: theme.spacing(1) }}/>
                </Grid>
            )}
        </Grid>
    );
});
