import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { COLOR_BLUE, COLOR_BLUE_DARK } from '../../constants';
import { observer } from 'mobx-react-lite';

const AVATAR_SIZES = {
    xxsmall: 'width:16px;height:16px;line-height: 6px;font-size:6px',
    xsmall: 'width:24px;height:24px;font-size:0.625rem',
    small: 'width:32px;height:32px;font-size:1rem',
    medium: 'width: 48px; height: 48px; font-size: 1.25rem',
    large: 'width:80px;height:80px;font-size:1.5rem',
    size64: 'width: 64px; height: 64px; font-size: 1.35rem',
    size20: 'width:20px;height:20px;font-size:0.625rem',
    size36: 'width:36px;height:36px;font-size:1rem',
    default: 'width:50px;height:50px;font-size:1.25rem'
};

const StyledAvatar = styled(Avatar)`
    ${({ size })=>
        size ? AVATAR_SIZES[size] : AVATAR_SIZES['default']};

    ${({ src, darken })=>
        darken === 'true' && src
            ? 'background-color: #000;'
            : `background-color: ${src ? 'transparent' : COLOR_BLUE}; color: #fff;`}

    &:hover {
        ${({ src, darken })=>
        darken === 'true' && src
            ? `img {
                        opacity: 0.8;
                    }; cursor:pointer;`
            : darken === 'true' && !src
                ? `background-color: ${src ? 'transparent' : COLOR_BLUE}; color:#fff;cursor:pointer;`
                : ''}
    }
`;

const StyledAvatarSkeleton = styled(Skeleton)`
    ${({ size })=>
        size ? AVATAR_SIZES[size] : AVATAR_SIZES['default']}
`;

function LRAvatar({ user, darken = false, size = 'small', loading = false, ...props }) {
    const firstName = _.get(user, 'first_name', '');
    const lastName = _.get(user, 'last_name', '');
    const initials = `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`;
    let headshot = _.get(user, 'headshot');

    if(!headshot && !firstName && !lastName) {
        headshot = '/images/avatars/placeholder.png';
    }

    return loading ? (
        <StyledAvatarSkeleton variant="circle" animation="wave" size={size} />
    ) : (
        <StyledAvatar size={size} src={headshot} darken={darken} {...props}>
            {initials}
        </StyledAvatar>
    );
}

LRAvatar.propTypes = {
    darken: PropTypes.string,
    loading: PropTypes.bool,
    size: PropTypes.oneOf([
        'default',
        'xxsmall',
        'xsmall',
        'small',
        'medium',
        'large',
        'size64',
        'size20',
        'size36',
    ]),
    user: PropTypes.object,
};

export default observer(LRAvatar);

export function MultipleAvatars({
    users = [],
    avatarBgcolor = '#fff',
    avatarSize = 'xsmall',
    stepDown = false,
    ...props
}) {
    return (
        <Grid container alignItems="center" justify="center" style={{ boxSizing: 'initial' }} {...props}>
            {users.map((bUser, i)=> {
                return (
                    <div
                        key={i}
                        style={{
                            marginLeft: i > 0 ? '-6px' : '',
                            zIndex: stepDown ? users.length - i : i + 1,
                            border: `2px solid ${avatarBgcolor}`,
                            borderRadius: '50%',
                            padding: '1px solid',
                            bgcolor: avatarBgcolor,
                        }}
                    >
                        <LRAvatar user={bUser} size={avatarSize} />
                    </div>
                );
            })}
        </Grid>
    );
}

MultipleAvatars.propTypes = {
    avatarBgcolor: PropTypes.string,
    avatarSize: LRAvatar.propTypes.size,
    stepDown: PropTypes.bool,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
};
