
import axios from 'axios';

const mapQuestAxios = axios.create({
    baseURL: 'https://open.mapquestapi.com',
});

export async function fetchMatrixData(payload) {
    const response = await mapQuestAxios.post(
        `/directions/v2/routematrix?key=${process.env.REACT_APP_MAPQUEST_API_KEY}&doReverseGeocode=false`,
        payload
    );
    return response.data;
}