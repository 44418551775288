import { Card, CardContent, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useMyHome, useMyHomeStats } from 'hooks/useMyHome';
import _ from 'lodash';
import React, { useMemo } from 'react';
import numAbbr from 'number-abbreviate';

export function AverageHomeValue({ area, onDelete }) {
    const stats = useMyHomeStats(area);
    const averageValue = _.get(stats, 'listing.average_neighborhood_value', null);
    const { isDataLocal, myHome} = useMyHome();
    const statsToDisplay = useMemo(()=> {
        return [
            {
                label: 'Avg. DOM',
                value: stats.listing?.average_days_on_market,
            },
            {
                label: 'For sale',
                value: stats.listing?.active_listings,
            },
            {
                label: 'Sold',
                value: stats.buyside?.total_sales_last_30_days,
            },
        ];
    }, [stats]);

    const isDisabled = (isDataLocal && _.isEmpty(myHome?.address));

    return (
        <React.Fragment>
            <Card variant="outlined">
                <CardContent>
                    <div style={{
                        minHeight: '64px'
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start'
                        }}>
                            <Typography variant='h5' 
                                title={_.size(area) > 14 && area}
                                style={{
                                    display: '-webkit-box',
                                    '-webkit-box-orient': 'vertical',
                                    '-webkit-line-clamp': '2',
                                    overflow: 'hidden',
                                }}>
                                {area}
                            </Typography>
                            {myHome?.zipcode !== area && <IconButton
                                onClick={onDelete}
                                disabled={isDisabled}
                                style={{padding: 5}}
                            >
                                <Close />
                            </IconButton>}
                        </div>
                    </div>

                    {stats?.isLoading ? <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: 136
                        }}
                    >
                        <CircularProgress size={20} color="primary" />
                    </div> :
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                position: 'relative'
                            }}>
                                <Typography variant='h4' style={{zIndex: 1000000000}}>{(averageValue && `$${numAbbr(averageValue)}`) || 'N/A'}</Typography>
                                <div style={{
                                    position: 'absolute',
                                    height: '34px',
                                    backgroundColor: '#FFE86F',
                                    transform: 'rotate(-2deg)',
                                    top: '7px',
                                    width: '50%',
                                }}/>
                                <Typography variant='body2' style={{marginBottom: '20px'}}>Average home value</Typography> 
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                {_.map(statsToDisplay, (stat)=> {
                                    return <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        minWidth: '68px',
                                    }}>
                                        <Typography variant='h6'>{stat.value}</Typography>
                                        <Typography variant='body2'>{stat.label}</Typography>
                                    </div>;
                                })}
                            </div>
                        </div>}
                </CardContent>
            </Card>
        </React.Fragment>
    );
}
