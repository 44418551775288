import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import styled from 'styled-components';

const StyledIconButton = styled(IconButton)`
    color: #ffffff;
`;

function GridTileActionMenu({ pinspirationItem, onUnpinClick }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event)=> {
        if(event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setAnchorEl(event.currentTarget);
    };

    const handleUnpinClick = (event)=> {
        if(event) {
            event.stopPropagation();
            event.preventDefault();
        }
        onUnpinClick(event, pinspirationItem);
        handleMenuClose();
    };

    const handleMenuClose = (event)=> {
        if(event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setAnchorEl(null);
    };

    if(!(pinspirationItem && pinspirationItem.currentUserPin)) {
        return null;
    }

    return (
        <React.Fragment>
            <StyledIconButton onClick={handleMenuClick}>
                <MoreHorizIcon />
            </StyledIconButton>

            <Menu
                id="grid-tile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleUnpinClick}>Unpin this photo</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

GridTileActionMenu.propTypes = {
    pinspirationItem: PropTypes.object.isRequired,
    onUnpinClick: PropTypes.func,
};

export default React.memo(GridTileActionMenu);
