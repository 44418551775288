import React, { useState } from 'react';
import { Box, Hidden, Typography, Paper, useTheme, Dialog, Divider } from '@material-ui/core';
import { ArrowForwardRounded,  ArrowBackRounded } from '@material-ui/icons';

import { ModalTitle } from '../ModalTitle';
import { LinkButton, ModalContent } from './components';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useStore } from '../../../mobx-store';
import { COLOR_GREY_LIGHT } from '../../../constants';
import LRButton from '../../LRButton';
import LRModal from '../../LRModal';
import { MobileFooterActions } from '../../MobileFooterActions';
import analytics from 'services/analytics';
import { useHistory } from 'react-router-dom';

const PageDivider = withTheme(styled(Divider)`
    height: 4px;
    background-color: #1A1A1A;
    width: 56px;
    margin: 15px 0;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        margin: 12px 0;
    }
`);

const PEADImageDialog = ({open, onClose, image})=> {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <Box component="img" src="/images/sample@3x.png" position="absolute" top="10%" left="30%" width="40%" />
            <Box component="img" src="/images/covid_pead@3x.png" />
        </Dialog>
    );
};

export function CovidPEADModal({open, handleBack, onClose, tourCollaboration, setParticipantsJoining, ...props}){
    const theme = useTheme();
    const { UserStore } = useStore();
    const history = useHistory();
    const [openImage, setOpenImage] = useState(false);
    const onGotIt = ()=> {
        analytics.eventTrack('robin_schedule_a_tour_pead_form_click', {
            Source: 'schedule_a_home_modal',
        });
        setParticipantsJoining();
        history.push(`/boards/scheduled-tours/${tourCollaboration.id}`);
    };

    return (
        <LRModal
            open={open}
            fullScreen
            maxWidth="100%"
            onClose={onClose}
            hideCloseButton
        >
            <PEADImageDialog open={openImage} image="" onClose={()=> setOpenImage(false)} />
            <Box bgcolor={COLOR_GREY_LIGHT} >
                <Hidden smUp>
                    <Box bgcolor="#eee" p={2} mt={7} borderBottom="1px solid #DDDDDD">
                        <Typography variant="h6" >1 form needs signing</Typography>
                    </Box>
                </Hidden>
                <ModalContent>
                    <Box height={1}>
                        <ModalTitle title="Schedule a tour" onClose={onClose} />
                        <Box maxWidth={1275} mx="auto" p={[0, 10]}>
                            <Box display="flex" justifyContent="space-between" flexDirection={['column', 'row']}>
                                <Box flexBasis="40%">
                                    <Box fontSize={[24, 48]} fontWeight={['bold', 'normal']}>
                                        Covid is a thing.
                                    </Box>
                                    <Box mt={2} component="img" src="/images/covid-people.png" width={1} maxWidth={541}/>
                                    <Box mt={[2, 5]}>
                                        <p style={{marginTop: theme.spacing(2), fontSize: theme.typography.h6.fontSize}}>
                                            Our partner, HelloSign, has sent an email to your inbox at <b>{UserStore?.user?.email ?? ''}</b>.  Please follow the instructions in this email to sign this required COVID waiver form.
                                        </p>
                                        <PageDivider />
                                        <p style={{marginTop: theme.spacing(2), fontSize: theme.typography.body1.fontSize, fontWeight: 'bold'}}>
                                            Due to the pandemic, all in-person tours in your area may require extra precaution. Our biggest priority is making sure you are taking every measure to ensure the safety of yourself and your loved ones. Please be sure you’re taking appropriate steps to protect yourself and those around you.
                                        </p>
                                        <p style={{marginTop: theme.spacing(2), fontSize: theme.typography.body1.fontSize}}>
                                            Currently, Sellers (and their agents) may require tour participants to certify that they are symptom-free, are taking reasonable safety precautions, and sign a COVID-19 release and waiver.  This form, prepared by ListReports®, helps you (and your tour participants) meet these requirements. Please read it carefully.
                                        </p>
                                    </Box>
                                </Box>
                                <Box flexBasis="50%" mt={[3, 0]} position="relative">
                                    <Box component="img" src="/images/sample.png" position="absolute" top="10%" left="30%" width="40%" />
                                    <Paper elevation={3} onClick={()=> setOpenImage(true)} style={{cursor: 'pointer'}}>
                                        <Box component="img" src="/images/covid-pead.png" width={1} p="20px" />
                                    </Paper>
                                    <Hidden xsDown>
                                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '52px'}}>
                                            <LRButton variant="outlined" style={{width:'176px', height:'48px', fontSize: theme.typography.body1.fontSize }} onClick={handleBack}>
                                                <ArrowBackRounded style={{ width: '16px', marginRight: theme.spacing(1) }}/> Back
                                            </LRButton>
                                            <LRButton variant="contained" color="primary" onClick={onGotIt} style={{width:'176px', height:'48px', fontSize: theme.typography.body1.fontSize}}>
                                                Got it <ArrowForwardRounded style={{ width: '16px', marginLeft: theme.spacing(1) }} />
                                            </LRButton>
                                        </div>
                                    </Hidden>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ModalContent>
            </Box>
            <MobileFooterActions forceInplaceSpacing>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <LRButton variant="outlined" style={{width:'100px'}} onClick={handleBack}>
                        <ArrowBackRounded style={{ width: '16px', marginRight: theme.spacing(1) }}/> Back
                    </LRButton>
                    <LRButton variant="contained" color="primary" onClick={onGotIt} style={{width:'100px'}}>
                        Got it <ArrowForwardRounded style={{ width: '16px', marginLeft: theme.spacing(1) }} />
                    </LRButton>
                </div>
            </MobileFooterActions>
        </LRModal>
    );
}
