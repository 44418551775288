import { Grid, Typography, useTheme } from '@material-ui/core';
import { MeetingRoom } from '@material-ui/icons';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import LRButton from 'components/LRButton';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import analytics from 'services/analytics';

const logger = Logger.get('ScheduledToursModalCard');

export const OpenHouse = observer(function Card({
    tour,
    variant = 'upcoming', // upcoming or past or cancelled
    orientation = 'horizontal', // horizontal or vertical
    ...props
}) {
    const theme = useTheme();
    const isCardHorizontal = useMemo(()=> {
        return orientation === 'horizontal';
    }, [orientation]);
    const isScheduled = useMemo(()=> {
        return Boolean(tour.content.scheduled_date_time);
    }, [tour.content.scheduled_date_time]);
    const isPast = useMemo(()=> {
        return variant === 'past';
    }, [variant]);
    const isUpcoming = useMemo(()=> {
        return variant === 'upcoming';
    }, [variant]);
    
    const history = useHistory();
    const match = useRouteMatch();

    function handleViewClick() {
        analytics.eventTrack('robin_home_tours_open_house_card_click', {
            Source: 'home_tours_modal'
        });

        const isBefore = moment(tour.content.start_time_iso).isBefore(moment());
        const filter = isBefore ? 'past' : 'scheduled';
        history.push(`${match.url}/calendar?defaultFilter=${filter}`);
    }

    return (
        <div className="ScheduledToursModal-Card-root" {...props}>
            <div
                style={{
                    borderRadius: '4px',
                    borderLeft: isCardHorizontal && !isScheduled && !isPast && `4px solid ${theme.palette.lr_colors.brick}`,
                    borderTop: !isCardHorizontal && !isScheduled && !isPast && `4px solid ${theme.palette.lr_colors.brick}`,
                    backgroundColor: '#fff',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                    display: !isCardHorizontal && 'block',
                    padding: theme.spacing(2),
                }}
            >
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{
                        display: !isCardHorizontal && 'block',
                    }}
                >
                    <Grid 
                        item
                    >
                        <div
                            style={{
                                padding: theme.spacing(1),
                                backgroundColor: '#F9F9F9',
                                border: `1px solid ${theme.palette.lr_colors.border_grey}`,
                                textAlign: 'center',
                                borderRadius: '2px',
                                color: (!isScheduled || isPast) && theme.palette.lr_colors.border_grey,
                                alignSelf: 'center',
                                width: isCardHorizontal ? '72px' : '100%',
                                height: '74px',
                            }}
                        >
                            <Typography color="inherit">
                                {isScheduled || tour.content.suggested_date_time_1?.date ? moment(tour.content.scheduled_date_time || tour.content.suggested_date_time_1.date).format('MMM') : 'DATE'}
                            </Typography>
                            <Typography variant={isScheduled || tour.content.suggested_date_time_1?.date ? 'h4' : 'h5'} color="inherit" style={{ marginTop: (isScheduled || tour.content.suggested_date_time_1?.date) && '-10px' }}>
                                <b>{isScheduled || tour.content.suggested_date_time_1?.date ? moment(tour.content.scheduled_date_time || tour.content.suggested_date_time_1.date).format('DD') : 'TBD'}</b>
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item>
                        
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item style={{ height: '28px' }}> 
                                <MeetingRoom style={{ width: '20px', height: '20px' }} />
                            </Grid>
                            
                            <Grid item>
                                <Typography variant="body4" style={{ ...theme.typography.body4, textTransform: 'uppercase' }}>
                                    open house tour
                                </Typography>
                            </Grid>
                            
                            {isPast && (
                                <Grid
                                    item
                                    style={{
                                        color: isScheduled ? theme.palette.lr_colors.minty_fresh : theme.palette.lr_colors.brick,
                                        marginLeft: theme.spacing(.5)
                                    }}
                                >
                                    <Typography variant="body4" style={{ ...theme.typography.body4, textTransform: 'uppercase', color: theme.palette.lr_colors.steak_sauce }}>
                                        Past open house
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        
                        <Typography variant="h6">
                            { tour.content.name.length > 40 ? `${tour.content.name.substring(0, 40).concat('...')}` : tour.content.name}
                        </Typography>

                        <Typography>
                            <b>{isScheduled ? `${moment(tour.content.scheduled_date_time).format('h:mma ddd, MMM DD')}` : ''}</b> 
                        </Typography>
                    </Grid>

                    {/* Horizontal separator */}
                    {isCardHorizontal && <Grid item xs />}

                    <Grid item style={{ marginRight: isCardHorizontal && theme.spacing(2) }}>
                        <LRButton variant="outlined" onClick={handleViewClick} fullWidth={!isCardHorizontal}>
                            View
                        </LRButton>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
});
