import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Switch, FormControlLabel, withTheme } from '@material-ui/core';
import { COLOR_MINTY_FRESH } from '../../../../constants';
import LRDropdown from '../../../../components/LRDropdown';
import styled, { css } from 'styled-components/macro';
import { MobileAppAdTooltip } from 'components/MobileAppAd';

const StyledSwitch = styled(Switch)`
    & .Mui-checked + span[class^='MuiSwitch-track'] {
        background-color: ${COLOR_MINTY_FRESH};
    }
    & .Mui-checked {
        color: ${COLOR_MINTY_FRESH};
    }
`;

const DesktopControlLabel = withTheme(styled(FormControlLabel)`
    margin-left: 0;
    margin-right: 0;
    *[class*='MuiFormControlLabel-label'] {
        font-weight: 500;
        ${({ theme })=> css`
            margin-top: ${theme.spacing(1)}px;
            font-size: ${theme.typography.subtitle2.fontSize};
        `}
    }
`);

const DesktopGroupControlLabel = withTheme(styled(DesktopControlLabel)`
    align-items: flex-start;
    *[class*='MuiFormControlLabel-label'] {
        ${({ theme })=> css`
            margin-top: ${theme.spacing(1)}px;
            margin-bottom: ${theme.spacing(1)}px;
        `}
    }

    &.hide {
        ${({ theme })=> css`
            margin-top: ${theme.spacing(2)}px;
        `}
        *[class*='MuiFormControlLabel-label'] {
            display: none;
        }
    }
`);

const BlankLabel = styled.div`
    min-width: 58px;
`;

const StyledListpackName = styled.div`
    text-align: left;
    margin: 0 0 8px;
    font-weight: bold;
`;

function SelectedOption({ label }) {
    return <div style={{ cursor: 'pointer' }}>{label}</div>;
};

function DropdownOption({ label }) {
    return <div style={{ cursor: 'pointer' }}>{label}</div>;
};

const DesktopUserNotificationSetting = observer(({
    title,
    description,
    value,
    disabled,
    channelOptions,
    onChangeNotification,
})=> {
    const onChange = ({ target: { name } }, checked)=> {
        const newSetting = value;
        newSetting[name] = checked;
        if(onChangeNotification) {
            onChangeNotification(name, checked);
        }
    };
    const { email, text, push } = value;
    const { email: emailOptions = true, text: textOptions = true, push: pushOptions = true } = channelOptions || {};
    const { email: emailDisabled = false, text: textDisabled = false, push: pushDisabled = false } = disabled || {};

    return (
        <Grid container justify="space-between">
            <Grid item sm={8}>
                <Box mb={1} textAlign="left" fontSize="h6.fontSize">
                    {title}
                </Box>
                <Box mb={1}>{description}</Box>
            </Grid>
            <Grid item sm container spacing={2} justify="flex-end">
                <Grid item>
                    {emailOptions && (<DesktopControlLabel
                        label="Email"
                        labelPlacement="top"
                        control={<StyledSwitch name="email" color="primary" checked={email} disabled={emailDisabled} onChange={onChange} />}
                    />)}

                    {!emailOptions && <BlankLabel />}
                </Grid>
                <Grid item>
                    {textOptions && (<DesktopControlLabel
                        label="Text"
                        labelPlacement="top"
                        control={<StyledSwitch name="text" color="primary" checked={text} disabled={textDisabled} onChange={onChange} />}
                    />)}

                    {!textOptions && <BlankLabel />}
                </Grid>
                {false && <Grid item> 
                    {pushOptions && (
                        <MobileAppAdTooltip source="notifications" disabled={!pushDisabled}>
                            <DesktopControlLabel
                                label="Push"
                                labelPlacement="top"
                                control={<StyledSwitch name="push" color="primary" checked={push} disabled={pushDisabled} onChange={onChange} />}
                            />
                        </MobileAppAdTooltip>
                    )}
                    {!pushOptions && <BlankLabel />}
                </Grid>}
            </Grid>
        </Grid>
    );
});

const DesktopListpackNotificationSetting = observer(({
    title,
    description,
    settingsKey,
    savedSearches,
    onChangeSavedSearchSettings,
    channelOptions,
    disabled,
})=> {
    const { email: emailOptions = true, text: textOptions = true, push: pushOptions = true } = channelOptions || {};
    const { email: emailDisabled = false, text: textDisabled = false, push: pushDisabled = false } = disabled || {};

    const onDropdownChange = (listpack)=> ({ target: { name: channel, value: newValue } })=> {
        const value = newValue?.id;
        if(onChangeSavedSearchSettings && value) {
            onChangeSavedSearchSettings(listpack, channel, value);
        }
    };

    const getSelectedOption = (listpack, channel)=> {
        const selectedId = listpack.getNotificationSetting(settingsKey, channel);
        return channelOptions[channel].find((o)=> o.id === selectedId);
    };

    return (
        <Grid container flexDirection="column">
            <Grid item sm={8}>
                <Box mb={1} textAlign="left" fontSize="h6.fontSize">
                    {title}
                </Box>
                <Box>{description}</Box>
            </Grid>

            {savedSearches && savedSearches.length > 0 && (
                savedSearches.map((listpack, i)=> {
                    return (
                        <Grid container
                            item
                            justify="space-between"
                            alignItems="flex-end"
                            key={`notification_settings_new_listing_row_${listpack?.id}`}
                        >
                            <Grid item sm={4}>
                                <StyledListpackName>
                                    {listpack?.name}
                                </StyledListpackName>
                            </Grid>
                            <Grid item sm container spacing={2} justify="flex-end">
                                <Grid item>
                                    {emailOptions && (<DesktopGroupControlLabel
                                        label="Email"
                                        labelPlacement="top"
                                        className={i ? 'hide' : ''}
                                        control={<LRDropdown
                                            name="email"
                                            selectedOption={getSelectedOption(listpack, 'email')}
                                            options={emailOptions}
                                            renderDisplayOption={SelectedOption}
                                            fullWidth
                                            onChange={onDropdownChange(listpack)}
                                            renderOption={DropdownOption}
                                            disabled={emailDisabled}
                                        />}
                                    />)}
                                </Grid>
                                {textOptions && (<Grid item>
                                    <DesktopGroupControlLabel
                                        label="Text"
                                        labelPlacement="top"
                                        className={i ? 'hide' : ''}
                                        control={<LRDropdown
                                            name="text"
                                            selectedOption={getSelectedOption(listpack, 'text')}
                                            options={textOptions}
                                            renderDisplayOption={SelectedOption}
                                            fullWidth
                                            onChange={onDropdownChange(listpack)}
                                            renderOption={DropdownOption}
                                            disabled={textDisabled}
                                        />}
                                    />
                                </Grid>)}
                                {false && pushOptions && (<Grid item>
                                    <MobileAppAdTooltip source="notifications" disabled={!pushDisabled}>
                                        <DesktopGroupControlLabel
                                            label="Push"
                                            labelPlacement="top"
                                            className={i ? 'hide' : ''}
                                            control={<LRDropdown
                                                name="push"
                                                selectedOption={getSelectedOption(listpack, 'push')}
                                                options={pushOptions}
                                                renderDisplayOption={SelectedOption}
                                                fullWidth
                                                onChange={onDropdownChange(listpack)}
                                                renderOption={DropdownOption}
                                                disabled={pushDisabled}
                                            />}
                                        />
                                    </MobileAppAdTooltip>
                                </Grid>)}
                            </Grid>
                        </Grid>
                    );
                })
            )}
        </Grid>
    );
});


export default function DesktopNotificationSetting(props) {
    const { isListpackNotification } = props;
    return (isListpackNotification)
        ? <DesktopListpackNotificationSetting {...props} />
        : <DesktopUserNotificationSetting {...props} />;
}

