import { Box, Grid, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useStore } from '../../../mobx-store';
import Lane from '../Lane';
import { SearchLane } from './SearchLane';
import analytics from '../../../services/analytics';
import { useBoardCollaborations } from '../../../hooks/useBoardCollaborations';
import { useAbility } from '../../../components/Ability';
import FooterDesktop from '../FooterDesktop';
import { LeftSidebar } from '../../../components/LoggedInWrapper/LeftSidebar';
import { LoggedInWrapperContext } from '../../../components/LoggedInWrapper';
import _ from 'lodash';
import { LEGACY_LANES_NAMES } from '../../../constants';
import Logger from 'js-logger';
import { useBoardCards } from 'hooks/useBoardCards';

const Container = styled.div`
    position: relative;
    overflow-x: scroll;
`;

function BoardDesktop(props) {
    const theme = useTheme();
    const { LanesStore, UserStore } = useStore();
    const { user } = UserStore;
    const history = useHistory();
    const [sourceDroppableId, setSourceDroppableId] = useState();
    const ability = useAbility();
    const canEditBoardCards = ability.can('edit', 'BoardCard');
    const loggedInWrapperContext = useContext(LoggedInWrapperContext);
    const { drawerLeftOpen, onOpenLeftSidebar } = loggedInWrapperContext;
    const {
        addBoardCollaboration,
    } = useBoardCollaborations();
    const {
        getUnarchivedBoardCardsByLaneId,
    } = useBoardCards();

    // Functions

    function handleOnDragStart({ source }) {
        setSourceDroppableId(source.droppableId);
    }

    function handleOnDragEnd({ destination, source, draggableId }) {
        setSourceDroppableId(undefined);


        const isLaneDifferent = destination?.droppableId !== source.droppableId;
        const hasChangedPositions =
            destination && !!(isLaneDifferent || destination.index !== source.index);

        if(!(destination && hasChangedPositions)) return;

        const listing = LanesStore.getListingById(draggableId);

        analytics.eventTrack('robin_card_moved', {
            Source: 'board_drag_and_drop',
            original_column: LanesStore.lanesById[listing.columnId].name,
            moved_to_column: LanesStore.lanesById[destination.droppableId].name,
            card_id: listing.id,
            board_id: listing.boardId,
            address: listing?.listing_details?.address
        });

        if(parseInt(listing.columnId) !== parseInt(destination.droppableId)){
            createMovedToBoardCollaboration(listing, LanesStore.lanesById[listing.columnId].name, LanesStore.lanesById[destination.droppableId].name);
        }

        LanesStore.moveListing(
            draggableId,
            source.droppableId,
            source.index,
            destination.droppableId,
            destination.index
        );

        if(source.droppableId === '1' && hasChangedPositions && !listing.name && isLaneDifferent && !user.meta?.hide_personalized_modal) {
            history.push(`/boards/name-home/${listing.id}?source=autopop`);
        }
    }

    function createMovedToBoardCollaboration(boardCard, sourceLaneName, toLaneName) {

        const fromColumn = LEGACY_LANES_NAMES[_.snakeCase(sourceLaneName)];
        const toColumn = LEGACY_LANES_NAMES[_.snakeCase(toLaneName)];

        const collaborationData = {
            boardId: boardCard.boardId,
            itemId: boardCard.id,
            content: {
                fromColumn: fromColumn ? fromColumn : sourceLaneName,
                toColumn: toColumn ? toColumn : toLaneName,
            },
            type: 'moved_card'
        };

        addBoardCollaboration(collaborationData);
    }

    return (
        <div style={{
            display: 'flex',
            height: '100%'
        }}>
            {user.accessProfile !== 'guest' && (
                <LeftSidebar
                    open={drawerLeftOpen}
                    onOpen={()=> {
                        onOpenLeftSidebar(true);
                    }}
                    onClose={()=> {
                        onOpenLeftSidebar(false);
                    }}
                />
            )}
            <div style={{
                paddingTop: theme.spacing(3),
                height: '100%',
                overflow: 'auto',
            }}>
                <DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
                    <Container
                        style={{ height: '100%' }}
                        {...props}
                    >
                        <Grid container style={{height: 'calc(100% - 28px)'}} direction="row" justify="flex-start" alignItems="flex-start" wrap="nowrap">
                            <Box width="14px" height="1px" minWidth="14px"></Box>

                            <SearchLane />

                            {LanesStore.lanes.map((lane)=> {
                                return (
                                    <Lane
                                        key={lane.id}
                                        lane={lane}
                                        loading={LanesStore.loadingListings}
                                        listings={getUnarchivedBoardCardsByLaneId(lane.id)}
                                        isDropDisabled={!!(lane.sortFunction && sourceDroppableId === lane.id) || !canEditBoardCards}
                                    />
                                );
                            })}

                            <Box width="14px" height="1px" minWidth="14px"></Box>
                        </Grid>
                        <FooterDesktop />
                    </Container>
                </DragDropContext>
            </div>
        </div>
    );
}

export default observer(BoardDesktop);
