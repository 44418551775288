import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tab, Tabs, ThemeProvider, Typography, useTheme } from '@material-ui/core';
import { tabsTheme } from '../../../theme';
import ResponsiveHOC from '../../../components/ResponsiveHoc';
import LRDropdown from '../../../components/LRDropdown';
import SavedSearches from './saved-search';
import RecommendedHomes from './recommended-homes';
import BoardHomes from './board-homes';
import DeletedHomes from './deleted-homes';
import SkeletonProperties from './skeleton-properties';
import analytics from 'services/analytics';
import { useStore } from '../../../mobx-store';

const ContentOptions = {
    saved_searches: { component: SavedSearches, key: 'saved_searches', label: 'Saved searches' },
    recommended_homes: { component: RecommendedHomes, key: 'recommended_homes', label: 'Recommended homes' },
    board_homes: { component: BoardHomes, key: 'board_homes', label: 'Homes on board' },
    deleted_homes: { component: DeletedHomes, key: 'deleted_homes', label: 'Deleted homes' },
};

const options = Object.values(ContentOptions);

function TabsView({ selectedView, onChangeSelectedView }) {
    const theme = useTheme();
    const tabIndex = useMemo(()=> {
        return options.findIndex((tab)=> tab.key === selectedView);
    }, [selectedView]);

    function handleTabChange(event, index) {
        analytics.eventTrack('robin_overview_properties_tab_clicked', {
            source: options[index].key
        });
        onChangeSelectedView(options[index].key);
    }

    return (
        <div
            className="space-y-2"
            style={{
                paddingTop: theme.spacing(2),
            }}
        >
            <div
                style={{
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                }}
            >
                <Typography variant="h6">Properties</Typography>
            </div>
            <ThemeProvider theme={tabsTheme}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    style={{
                        borderBottom: '1px solid',
                        borderColor: theme.palette.lr_colors.border_grey,
                        padding: `0 ${theme.spacing(2)}px`,
                    }}
                    classes={{
                        flexContainer: 'space-x-6'
                    }}
                >
                    {options.map(({ key, label })=> (
                        <Tab
                            key={key}
                            label={label}
                            style={{
                                textTransform: 'none',
                                minWidth: 'auto',
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                        />
                    ))}
                </Tabs>
            </ThemeProvider>
        </div>
    );
}

function DropdownView({ selectedView, onChangeSelectedView }) {
    const theme = useTheme();

    const selectedOption = useMemo(()=> {
        return options.find(({ key })=> key === selectedView);
    }, [selectedView]);

    const visibleOptions = useMemo(()=> {
        return options.filter((option)=> option !== selectedOption);
    }, [selectedOption]);

    function handleOnViewChange(event) {
        const { key } = event.target.value;
        analytics.eventTrack('robin_overview_properties_dropdown_changed', {
            source: key
        });
        onChangeSelectedView(key);
    }

    return (
        <div
            style={{
                width: 'fit-content',
                padding: theme.spacing(2),
                paddingBottom: 0,
            }}
        >
            <LRDropdown
                selectedOption={selectedOption}
                renderDisplayOption={({ label })=> label}
                renderOption={({ label })=> label}
                onChange={handleOnViewChange}
                options={visibleOptions}
            />
        </div>
    );
}

const SelectionView = ResponsiveHOC({
    xs: DropdownView,
    md: TabsView,
});

const Properties = observer(function() {
    const { boardsStore, UserStore } = useStore();
    const [selectedView, setSelectedView] = useState('saved_searches');
    const Component = ContentOptions[selectedView].component;

    return (
        <div>
            <SelectionView selectedView={selectedView} onChangeSelectedView={setSelectedView} />
            {UserStore.loadingUser || boardsStore.loading || boardsStore.activeBoard?.loadingBoardData ? 
                <SkeletonProperties /> : 
                <Component />
            }
        </div>
    );
});

export default Properties;
