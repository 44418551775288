import { benoitApi } from 'apis';
import { queryClient } from 'query-client';
import { useQuery } from 'react-query';
import { useStore } from 'mobx-store';

const makeCacheKey = (listingId)=> ['listing', listingId];

export function useListing(listingId, {onError} = {}) {
    const { listingsStore } = useStore();
    return useQuery(
        makeCacheKey(listingId),
        async function() {
            return benoitApi.getListingDetailsById(listingId);
        },
        {
            enabled: Boolean(listingId),
            onSuccess: (data)=> {
                listingsStore.persistListingData(data);
            },
            onError: (err)=> {
                if(onError) {
                    onError(err);
                }
            }
        }
    );
}

export function updateListingCache(listing) {
    queryClient.setQueryData(makeCacheKey(listing.id), listing);
}
export function invalidateListingCache(listingId) {
    queryClient.invalidateQueries(makeCacheKey(listingId));
}
