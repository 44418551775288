// eslint-disable-next-line
import _, { keyBy } from 'lodash';
import { decorate, flow, observable, action, extendObservable, computed } from 'mobx';
import { computedFn } from 'mobx-utils';
import { getListpackListingsCount, getListpackListings, getListpackBabouListingsById, mergeBabouListingInfo } from '../../services/babou';
import { updateListpack } from '../../apis/benoit';
import moment from 'moment';
import { getAffiliateUser } from '../../apis/portal';
import {DEFAULT_LISTING_HERO_IMAGE} from '../../constants';
import simpleCypher from '../../services/simple-cypher';
import Logger from 'js-logger';

const logger = Logger.get('Listpack');

const sortMapping = {
    'Days on market (default)': 'ListingDate:desc',
    'Year built': 'YearBuilt:desc',
    'Price (high to low)': 'ListPrice:desc',
    'Price (low to high)': 'ListPrice:asc',
    'Most bedrooms': 'Bedrooms:desc',
    'Most bathrooms': 'Bathrooms:desc',
    'Most square feet': 'LivingArea:desc',
    'Open house': 'OpenHouseDate:asc',
};

const staticSortMapping = [
    {id: 'listing_date', label: 'Days on market (default)', order: 'desc'},
    {id: 'year', label: 'Year built', order: 'desc'},
    {id: 'price', label: 'Price (high to low)', order: 'desc'},
    {id: 'price', label: 'Price (low to high)', order: 'asc'},
    {id: 'beds', label: 'Most bedrooms', order: 'desc'},
    {id: 'baths', label: 'Most bathrooms', order: 'desc'},
    {id: 'home_size', label: 'Most square feet', order: 'desc'},
    {id: (l)=> l.open_houses[0]?.date_iso || '1970-01-01' , label: 'Open house', order: 'desc'},
];

const DEFAULT_NOTIFY_FREQUENCY = {
    email: 'daily',
    text: 'never',
    push: 'never',
};

export class Listpack {
    activeListingsCount = 0;
    newListingsCount = 0;
    listings = [];
    affiliate = null;
    listingsOrder = 'Days on market (default)';
    loadingListings = false;
    loadedListings = false;
    loadingMoreListings = false;
    loadingListingsCount = false;
    loadedListingsCount = false;
    loadingAffiliate = false;
    loadedAffiliate = false;
    loadingListpackData = true;
    listpackDataLoaded = false;
    loadedListingById = false;
    loadingListingById = false;

    constructor(rootStore, listpackObj, callback) {
        this.rootStore = rootStore;

        extendObservable(this, listpackObj);
        this.fetchInitialData()
            .then(()=> {
                //this callback is used to delay the listpack creation until we have the listing available to set the hero_image for the BoardCollaboration
                if(callback) {
                    callback(this);
                }
            });
        const { listings_type } = this;

        // if it is a live listpack set the pooling mechanism to fetch for new listings every  5 minutes
        if(listings_type !== 'static') {
            setInterval(()=> {
                this.poolNewListings();
            }, 5 * 60000);
        }
    }

    get creator() {
        if(!this.affiliateId) {
            return this.listpack_creator;
        }
        return this.affiliate;
    }

    get currentUserId() {
        return _.get(this, 'rootStore.UserStore.user.id');
    }

    get lastSeenDate() {
        let date;
        const { user } = this.rootStore.UserStore;
        let userId = user.id;
        if(this.rootStore.UserStore.isAgent){
            userId = user.affiliateId;
        } else if(this.rootStore.UserStore.isLO){
            userId = user.affiliate.preferredVendorId;
        }
        if(this.meta?.last_seen_by_collaborator_at && this.meta.last_seen_by_collaborator_at[userId]) {
            date = new Date(this.meta?.last_seen_by_collaborator_at[userId]);
        } else if(this.meta?.last_seen_at){
            date = new Date(this.meta.last_seen_at);
        }
        return date;
    }

    get hasMoreListings() {
        if(!this.loadedListingsCount || !this.loadedListings) {
            return false;
        }
        return this.listings.length < this.activeListingsCount;
    }

    get heroImage() {
        if(this.hero_image) return this.hero_image;

        if(!this.loadedListings) return null;

        const firstListingWithImages = this.listings.find((listing)=> {
            return listing.photos?.length;
        });

        return firstListingWithImages?.photos[0] || DEFAULT_LISTING_HERO_IMAGE;
    }

    get heroImages() {
        const mainHeroImage = this.hero_image;
        const listings = !this.handSelected ? this.listings : this.listpackCards;
        const listingsSize = listings?.length;
        const heroImagesNumber = 3;
        const heroImages = [];

        if(this.handSelected && !this.listpackCards?.length) {
            heroImages.push(DEFAULT_LISTING_HERO_IMAGE);
        }

        // Put the main hero image if exists
        if(mainHeroImage) heroImages.push(mainHeroImage);

        if(listingsSize < heroImagesNumber - 1) return heroImages;

        // Search in listings for more photos to use for the hero images
        for(let i = 0; i < listingsSize; i++) {
            const listingPhotos = !this.handSelected ? listings[i]?.photos : listings[i]?.listing_details?.photos;

            if(listingPhotos && listingPhotos[0]) {
                heroImages.push(listingPhotos[0]);
            }

            if(heroImages.length === heroImagesNumber) {
                break;
            }
        }

        return heroImages;
    }

    get opened() {
        return !this.handSelected ? true : (this.newListingCards > 0 ? true : false);
    }

    get handSelected() {
        return this.id === `listpack-${this.userId}-${this.affiliateId}`;
    }

    get allListpackCards() {
        if(!this.handSelected) return [];

        const cardsById = _.pickBy(this.rootStore?.boardsStore?.activeBoard?.boardCardsById,
            (value, key)=> {
                return value.columnId === 1000;
            }
        );

        let cards = Object.keys(cardsById)
            .map((value, index)=> {
                return cardsById[value];
            });

        cards = cards.filter((c)=> {
            return c?.listing_lifecycle?.status !== 'Missing' && c?.listing_lifecycle?.status !== 'Missing-Timed-Out';
        });

        return cards || [];
    }

    get listpackCards() {
        return this.allListpackCards.filter((card)=> !card.archived);
    }

    get newListingCards() {
        let newCards = [];

        newCards = this.listpackCards.filter((c)=> {
            const match =  !this.lastSeenDate || new Date(c.createdAt).getTime() > new Date(this?.lastSeenDate).getTime();
            return match;
        });

        return newCards.length;
    }

    get listingCards() {
        return this.listings ? this.listings.map((listing_details)=> ({ listing_details })) : [];
    }

    get isCreatedByAffiliate() {
        return !!this.affiliateId;
    }

    get loadingUser() {
        return !!this.user;
    }

    get loadedUser() {
        return !!this.user;
    }

    get user() {
        let user = keyBy(this.rootStore?.boardsStore?.activeBoard?.collaborators, 'userId');
        user = user[this.userId];
        return user;
    }

    get compliance() {
        if(!this.listings.length) return null;
        return this.rootStore.listingsStore.complianceDataById[this.listings[0].id];
    }

    get newToCollaborator() {
        if(this.handSelected || !this.meta) return false;

        const { user } = this.rootStore.UserStore;
        return !this.meta.opened_by_collaborators[user.id];
    }

    poolNewListings = flow(function*() {
        const counts = yield getListpackListingsCount(this, true);

        const differentActiveListings = this.activeListingsCount !== counts?.active_listings;
        const differentNewListings = this.newListingsCount !== counts?.new;

        if(differentActiveListings || differentNewListings) {
            this.activeListingsCount = counts?.active_listings || 0;
            this.newListingsCount = counts?.new || 0;

            const listings = yield getListpackListings(this, 0, 30, sortMapping[this.listingsOrder]);
            this.listings = listings;
        }
    });

    fetchInitialData = flow(function*() {
        this.loadingListpackData = true;
        this.listpackDataLoaded = false;
        try {
            const { listings_type } = this;

            if(listings_type === 'static') {
                // // this will be cards
                // // should we fetch them now or when the user open the listpack?!

                // // dummy implementation for now


                // yield Promise.all([this.fetchAffiliate()]);
                // this.fetchListings();
            } else {
                yield Promise.all([this.fetchAffiliate(), this.fetchListingsCount()]);
                if(this.activeListingsCount > 0) {
                    yield this.fetchListings();
                }
            }
        } catch (err) {
            logger.error(err);
        }

        this.loadingListpackData = false;
        this.listpackDataLoaded = true;
    });

    updateListpack = flow(function*(data) {
        try {
            const listpack = yield updateListpack(data);
            _.merge(this, listpack);
        } catch (error){
            logger.error(error);
        }
    });

    updateOpenedByCollaborators = flow(function*(userId) {
        const now = moment().utc().toISOString();

        yield this.updateListpack({
            id: this.id,
            meta: {
                ...this.meta,
                opened_by_collaborators: {
                    ...this.meta.opened_by_collaborators,
                    [userId]: now
                }
            }
        });
    });

    updateLastSeenAt = flow(function*(userId) {
        const now = moment()
            .utc()
            .toISOString();

        yield this.updateListpack({
            id: this.id,
            meta: {
                ...this.meta,
                last_seen_by_collaborator_at: {
                    ...this.meta.last_seen_by_collaborator_at,
                    [userId]: now
                },
                new_listings_count: 0,
            },
        });

        this.newListingsCount = 0;
    });

    updateListingsViewedMetric = flow(function*(){
        yield this.updateListpack({
            id: this.id,
            metrics: {
                ...this.metrics,
                listings_viewed: this.metrics.listings_viewed + 1,
            }
        });
    });

    archive = flow(function*() {
        yield this.updateListpack({
            id: this.id,
            archived: true,
        });
    });

    fetchListings = flow(function*() {
        this.loadingListings = true;
        this.loadedListings = false;

        try {
            const { listings_type, listing_ids } = this;

            if(listings_type === 'static') {
                const listingIds = listing_ids.map((l)=> {
                    return simpleCypher.encode(l.source, l.id);
                });
                const listingDetails = yield this.rootStore.listingsStore.fetchListings(listingIds);

                const listingIdsMapping = listing_ids.map((l)=> ({
                    addedAt: l.addedAt,
                    encodedId: simpleCypher.encode(l.source, l.id),
                }));
                const mergedListings = _.values(_.merge(
                    _.keyBy(listingDetails, 'encodedId'),
                    _.keyBy(listingIdsMapping, 'encodedId')
                ));
                this.listings = mergedListings;
                this.newListingsCount = this.meta?.new_listings_count || 0;
                this.activeListingsCount = listingDetails.length;
            } else {
                const babouListings = yield getListpackBabouListingsById(this, 0, 30, sortMapping[this.listingsOrder]);
                const listingIds = babouListings.map((l)=> {
                    return simpleCypher.encode(l.LRSource, l.id);
                });
                let listings = yield this.rootStore.listingsStore.fetchListings(listingIds);
                listings = mergeBabouListingInfo(babouListings, listings);
                this.listings = listings;
            }
        } catch (error) {
            this.loadingListingsCount = false;
            this.error = true;
            return null;
        }

        if(this.listings.length) {
            const { user } = this.rootStore.UserStore;
            yield this.rootStore.listingsStore.getComplianceById(this.listings[0].id, user.affiliate);
        }

        this.loadingListings = false;
        this.loadedListings = true;
    });

    fetchMoreListings = flow(function*() {
        this.loadingMoreListings = true;

        try {
            const babouListings =
                yield getListpackBabouListingsById(this, this.listings.length, 30, sortMapping[this.listingsOrder]);
            const listingIds = babouListings.map((l)=> {
                return simpleCypher.encode(l.LRSource, l.id);
            });
            let listings = yield this.rootStore.listingsStore.fetchListings(listingIds);
            listings = mergeBabouListingInfo(babouListings, listings);
            this.listings = [...this.listings, ...listings];
        } catch (error) {
            this.loadingListingsCount = false;
            this.error = true;
            return null;
        }

        this.loadingMoreListings = false;
    });

    getListingById = flow(function*(listingId) {
        if(!this.listings || this.loadingMoreListings) return null;
        const LIMIT = 30;

        this.loadingListingById = true;
        this.loadedListingById = false;
        do{
            let foundListingIndex = this.listings.findIndex((listing)=> listing.id === listingId);
            if(foundListingIndex >= 0) {
                this.loadedListingById = true;
                this.loadingListingById = false;
                let foundListing = this.listings[foundListingIndex];
                return [foundListing, foundListingIndex];
            }
            if(this.listings.length >= this.activeListingsCount) {
                this.loadingListingById = false;
                // This means we already have all listings loaded
                return null;
            }
            let skip = this.listings.length;
            yield this.fetchMoreListings(LIMIT, skip);
            if(skip === this.listings.length) {
                // This means it didn't fetch any more listings
                this.loadingListingById = false;
                return null;
            }
        } while(this.listings.length <= this.activeListingsCount);

        return null;
    });

    getListingByIndex = flow(function*(index) {
        if(index > this.activeListingsCount) {
            return;
        }

        if(index >= this.listings.length) {
            let listingsToFetch = Math.max(index - this.listings.length + 1, 30);
            yield this.fetchMoreListings(listingsToFetch, this.listings.length);
        }

        if(index >= this.listings.length) {
            return null;
        }

        return this.listings[index];
    });

    fetchListingsCount = flow(function*() {
        this.loadingListingsCount = true;
        this.loadedListingsCount = false;

        try {
            const counts = yield getListpackListingsCount(this, true);
            this.activeListingsCount = counts.active_listings;
            this.newListingsCount = counts.new;
        } catch (error) {
            this.loadingListingsCount = false;
            this.error = true;
            return null;
        }

        this.loadingListingsCount = false;
        this.loadedListingsCount = true;
    });

    fetchAffiliate = flow(function*() {
        if(!this.affiliateId) {
            this.affiliate = null;
            this.loadedAffiliate = true;
            return null;
        }
        if(this.loadedAffiliate) {
            return this.affiliate;
        }

        this.loadingAffiliate = true;
        this.loadedAffiliate = false;
        try {
            const user = yield getAffiliateUser(this.affiliateId);
            this.affiliate = user;
            this.loadedAffiliate = true;
        } finally {
            this.loadingAffiliate = false;
        }
    });

    changeSort = function(newOrder) {
        if(newOrder === this.listingsOrder) return;

        this.listingsOrder = newOrder;
        if(this.listings_type === 'static') {
            const sort = staticSortMapping.find((s)=> s.label === newOrder);
            this.listings = _.orderBy(this.listings, sort.id, sort.order);
        } else {
            this.fetchListings();
        }
    };

    getNotificationSetting = computedFn((key, channel)=> {
        const { isPhoneVerified, hasMobileApp } = this.rootStore.UserStore;
        if(channel === 'text' && !isPhoneVerified) return 'never';
        if(channel === 'push' && !hasMobileApp) return 'never';
        return _.get(
            this,
            `notification_settings.${key}.${channel}.${this.currentUserId}`,
            _.defaultTo(DEFAULT_NOTIFY_FREQUENCY[channel], 'daily')
        );
    });

    setNotificationSetting = flow(function*(key, channel, value) {
        const settingPath = [
            'notification_settings',
            key,
            channel,
            this.currentUserId
        ].join('.');
        _.set(this, settingPath, value);
        const { notification_settings } = this;
        yield this.updateListpack({
            id: this.id,
            notification_settings: {
                [key]: {
                    [channel]: {
                        [this.currentUserId]: value
                    }
                }
            },
        });
    });

}

decorate(Listpack, {
    creator: computed,
    lastSeenDate: computed,
    hasMoreListings: computed,
    heroImage: computed,
    opened: computed,
    handSelected: computed,
    listpackCards: computed,
    listingCards: computed,
    loadingUser: computed,
    loadedUser: computed,
    user: computed,
    compliance: computed,
    currentUserId: computed,
    heroImages: computed,
    newToCollaborator: computed,

    activeListingsCount: observable,
    listings: observable,
    newListingsCount: observable,
    loadingListings: observable,
    loadedListings: observable,
    loadingMoreListings: observable,
    loadingListingsCount: observable,
    loadedListingsCount: observable,
    loadingAffiliate: observable,
    loadedAffiliate: observable,
    loadingListpackData: observable,
    listpackDataLoaded: observable,
    affiliate: observable,

    poolNewListings: action,
    fetchListings: action,
    fetchMoreListings: action,
    getListingById: action,
    getListingByIndex: action,
    archive: action,
    fetchListingsCount: action,
    fetchAffiliate: action,
    updateLastSeenAt: action,
    changeSort: action,
    updateListingsViewedMetric: action,
    setNotificationSetting: action,
});
