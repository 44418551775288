import React, { useEffect } from 'react';
import LRButton from '../LRButton';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';
import jwt from 'jsonwebtoken';
import { v4 } from 'uuid';
import Logger from 'js-logger';

const state = v4();
const logger = Logger.get('AppleBtn');

const StyledLRButton = styled(LRButton)`
    width: 100%;
    height: 48px;
    font-weight: normal;
    border: 1px solid #BBBBBB;
`;

export function AppleBtn({
    onLoginSuccess = ()=> { },
    onError = ()=> { },
    onClick = ()=> { },
    loading,
    signUp = false,
}) {

    useEffect(()=> {
        if(!window.AppleID) {
            console.warn('AppleBtn -> window.AppleID is undefined.');
            return;
        }
        window.AppleID.auth.init({
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: document.location.origin, // Not actually needed. It's configured to support any of our base hosts
            state: state,
            usePopup: true
        });
    }, []);

    async function handleLogin() {
        onClick();
        try {
            const response = await window.AppleID.auth.signIn();
            logger.debug('appleBtn -> handleLogin', response);
            const token = response.authorization.id_token;
            const decodedToken = jwt.decode(token);
            logger.debug('appleBtn -> decodedToken', decodedToken);
            const email = decodedToken.email;
            const userInfo = {
                email: email,
                meta: {
                    auth_providers: {
                        apple_user_id: decodedToken.sub,
                    },
                },
            };
            if(response.user?.name) {
                userInfo.first_name = response.user?.name.firstName;
                userInfo.last_name = response.user?.name.lastName;
            }
            onLoginSuccess({ userInfo, auth: { id_token: response.authorization.id_token, code: response.authorization.code, method: 'apple' } });
        } catch (error) {
            console.error(error);
            onError(error);
        }
    };

    return (
        <StyledLRButton onClick={handleLogin}>
            <Box width={1} display="flex">
                <Box display="flex" alignItems="center" marginLeft={1}>
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3627 10.4394C13.3903 13.4131 15.9714 14.4027 16 14.4153C15.9782 14.4851 15.5876 15.8255 14.6402 17.2101C13.8211 18.4071 12.9711 19.5997 11.6321 19.6244C10.3164 19.6487 9.8933 18.8442 8.38904 18.8442C6.88524 18.8442 6.41518 19.5998 5.16969 19.6487C3.87719 19.6977 2.89293 18.3543 2.06715 17.1616C0.379706 14.722 -0.909851 10.2679 0.821698 7.26128C1.68189 5.76818 3.21913 4.82269 4.88766 4.79844C6.15687 4.77423 7.35481 5.65232 8.13073 5.65232C8.90616 5.65232 10.3619 4.59634 11.8924 4.75143C12.533 4.77809 14.3315 5.01023 15.4863 6.70059C15.3932 6.75827 13.3404 7.95334 13.3627 10.4394ZM10.8899 3.13742C11.5761 2.3068 12.038 1.15051 11.912 0C10.9229 0.0397537 9.72684 0.659112 9.01738 1.48927C8.38157 2.22442 7.82474 3.40106 7.97498 4.5288C9.07745 4.6141 10.2037 3.96856 10.8899 3.13742Z" fill="black" />
                    </svg>
                </Box>
                {loading && (
                    <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                        <CircularProgress size={24} color="secondary" />
                    </Box>
                )}
                {!loading && <Box flex="1">{signUp ? 'Sign up' : 'Sign in'} with Apple</Box>}
            </Box>
        </StyledLRButton>
    );
};
