import { useStore } from '../../mobx-store';
import { useRealtime } from '../useRealtime';

export function useRobinRealtimeCommunication(room) {
    const { UserStore } = useStore();
    const { user } = UserStore;
    const {
        readyState,
        sendMessage,
        lastMessage,
    } = useRealtime({
        userName: user?.id,
        userId: user?.id,
        room,
    });

    return {
        readyState,
        sendMessage,
        lastMessage,
    };
}
