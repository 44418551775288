// eslint-disable-next-line
import Logger from 'js-logger';
import { flow, decorate, observable, action, computed } from 'mobx';
import { portalApi } from '../../apis';

export class PublicListing {
    agent = null;
}

decorate(PublicListing, { 
    user: observable,
});

export default new PublicListing();
