import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { Can } from '../../../components/Ability';
import LRButton from '../../../components/LRButton';
import { useNameHomeStoreContext } from '../store';

const StyledAppBar = withTheme(styled(AppBar)`
    background-color: #fff;
    color: ${({ theme })=> theme.palette.text.primary};
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    position: relative;
`);

const StyledToolbar = withTheme(styled(Toolbar)`
    display: grid;
    min-height: auto;
    grid-template-columns: 60px 1fr 60px;
    grid-template-rows: 64px;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        grid-template-columns: 200px 1fr 200px;
    }
    ${({ theme })=> theme.breakpoints.down('sm')} {
        grid-template-rows: 54px;
    }
`);

const Title = withTheme(styled(Typography)`
    grid-column: 2 / span 1;
    text-align: center;
`);

const ActionButtons = styled(Box)`
    grid-column: 3 / span 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

function NameHomeHeader() {
    const nameHomeStore = useNameHomeStoreContext();

    return (
        <StyledAppBar>
            <StyledToolbar>
                <Title variant="body1">Personalize</Title>
                <ActionButtons>
                    <Hidden xsDown>
                        <Can I="edit" a="Listing">
                            <LRButton
                                variant="outlined"
                                onClick={nameHomeStore.reset}
                                disabled={nameHomeStore.isResetDisabled}
                            >
                                Reset
                            </LRButton>
                        </Can>
                        <Box mr="12px" />
                        <Can I="edit" a="Listing">
                            <LRButton
                                color="primary"
                                variant="contained"
                                onClick={nameHomeStore.save}
                                disabled={nameHomeStore.isSaveDisabled}
                            >
                                Save
                            </LRButton>
                        </Can>
                    </Hidden>
                    <Hidden smUp>
                        <IconButton onClick={()=> (nameHomeStore.close = true)}>
                            <Close />
                        </IconButton>
                    </Hidden>
                </ActionButtons>
            </StyledToolbar>
        </StyledAppBar>
    );
}

export default observer(NameHomeHeader);
