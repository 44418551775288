export async function getLocation() {
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };

    if(navigator.geolocation) {
        return new Promise((resolve, reject)=> {
            navigator.geolocation.getCurrentPosition(
                function sucess(pos) {
                    resolve(pos);
                },
                function error(err) {
                    reject(err);
                },
                options
            );
        });
    }
}

export function isLocationSupported() {
    return !!navigator.geolocation;
}