import React from 'react';

import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import RateFarm from '../../components/RateFarm';

function RateFarmModal() {
    return (
        <RateFarm />
    );
};

export default observer(RateFarmModal);