import React, { useCallback, useState } from 'react';
import { CircularProgress, InputAdornment } from '@material-ui/core';
import Address from 'lana';
import { extractListingUrlAddress } from '../../../services/extract-listing-url-address';
import { places } from '../../../services/gmaps';
import { getListingsForAddress } from '../../../services/listings';
import LRButton from '../../../components/LRButton';
import { LRSearchInput } from '../../../components/LRSearchInput';

async function searchAddress(searchTerm) {
    const lanaAddress = new Address(searchTerm) || {};
    lanaAddress.sec_unit_num = null;
    lanaAddress.sec_unit_type = null;
    return places.placesAutocomplete(lanaAddress.toString());
}

async function listingsSearch(address) {
    const babouListings = await getListingsForAddress(address);
    return babouListings && babouListings.length ? babouListings : [];
}

function assignAddressUnit(address, sourceAddress) {
    const lanaAddress = new Address(address.replace(', USA', ''));
    const sourceLanaAddress = new Address(sourceAddress.replace(', USA', ''));
    if(sourceLanaAddress._isValid && lanaAddress._isValid) {
        lanaAddress.sec_unit_num = sourceLanaAddress.sec_unit_num;
        lanaAddress.sec_unit_type = sourceLanaAddress.sec_unit_type;
    }
    return lanaAddress.toString();
}

export function SearchPlaceInput({ onFoundListings, onChange }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    async function handleAddListing() {
        setIsLoading(true);
        let searchAddress = searchTerm;
        let extractedAddress = await extractListingUrlAddress(searchTerm);
        if(extractedAddress) {
            searchAddress = extractedAddress.formattedAddress;
        }
        const listings = await listingsSearch(searchAddress);
        onFoundListings(listings, searchTerm);
        setSearchTerm('');

        setIsLoading(false);
    }

    async function handleOnSelectSuggestion(suggestion) {
        let place = await places.predictionDetails(suggestion);
        const newAddress = assignAddressUnit(place.formatted_address, searchTerm);
        setSearchTerm(newAddress);
    }

    const renderSuggestion = useCallback(function renderSuggestion(suggestion) {
        return assignAddressUnit(suggestion.description, searchTerm);
    }, [searchTerm]);

    function handleOnChangeSearchTerm(newSearchTerm) {
        setSearchTerm(newSearchTerm);
        if(onChange) {
            onChange();
        }
    }

    return (
        <LRSearchInput
            searchTerm={searchTerm}
            onChangeSearchTerm={handleOnChangeSearchTerm}
            onSelectSuggestion={handleOnSelectSuggestion}
            renderSuggestion={renderSuggestion}
            searchRequest={searchAddress}
            endAdornment={
                <InputAdornment position="end">
                    <LRButton
                        color="primary"
                        variant={'contained'}
                        onClick={handleAddListing}
                        style={{
                            borderRadius: 0,
                            height: '48px',
                            marginRight: '-20px',
                            zIndex: 1,
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '14px',
                            paddingBottom: '14px',
                            cursor: isLoading ? 'auto' : 'pointer',
                        }}
                    >
                        {isLoading ? <CircularProgress color="fff" size={20} /> : 'Add'}
                    </LRButton>
                </InputAdornment>
            }
            style={{ maxHeight: '48px', borderRadius: 2 }}
        />
    );
}
