import React from 'react';
import { AgentNotificationBase } from '../AgentNotificationBase';
import { useTheme, Typography } from '@material-ui/core';
import { timeago } from '../../../../../services/timeago';

export function AgentNotificationMessage({
    notification,
    onMarkAsReadClick,
    onPrimaryCTAClick,
}){
    const theme = useTheme();
    const { content: { message }, updatedAt } = notification;

    function onPrimaryClick(e){
        onMarkAsReadClick(e, notification);
    }
    return (
        <AgentNotificationBase
            title="New message from your agent ✉️"
            primaryButtonLabel="Ok"
            onMarkAsReadClick={onMarkAsReadClick}
            onPrimaryButtonClick={onPrimaryClick}
        >
            <Typography variant="body2">
                {message}
            </Typography>
            <div
                style={{
                    marginTop: '24px',
                    color: theme.palette.lr_colors.steak_sauce,
                }}
            >
                Sent {timeago(updatedAt)}.
            </div>
        </AgentNotificationBase>
    );
}