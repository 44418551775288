import React, { useContext, useState } from 'react';
import analytics from 'services/analytics';

export const TaskManagementStateContext = React.createContext({ filter: 'Incomplete' });

export function useTaskManagementState(initialState) {
    const [filter, setFilter] = useState(initialState);
    const [openTaskModal, setOpenTaskModal] = useState(false);

    function changeFilter(newFilter) {
        analytics.eventTrack('robin_overview_todo_add_filter_updated', {
            filter_selected: newFilter,
        });
        setFilter(newFilter);
    }

    return {
        filter,
        setFilter: changeFilter,
        openTaskModal,
        setOpenTaskModal
    };
}

export function useTaskManagementStateContext() {
    return useContext(TaskManagementStateContext);
}
