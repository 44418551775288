import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

const BgImage = styled(Box)`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    ${({ src })=>
        src &&
        css`
        background-image: url('${src}');
    `}
`;

BgImage.propTypes = {
    src: PropTypes.string.isRequired,
};

export default BgImage;
