import React from 'react';
import { SvgIcon } from '@material-ui/core';

function AddHomeIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.9999 2C19.5522 2 19.9999 2.44772 19.9999 3V4H20.9999C21.5522 4 21.9999 4.44772 21.9999 5C21.9999 5.55228 21.5522 6 20.9999 6H19.9999V7C19.9999 7.55228 19.5522 8 18.9999 8C18.4476 8 17.9999 7.55228 17.9999 7V6H16.9999C16.4476 6 15.9999 5.55228 15.9999 5C15.9999 4.44772 16.4476 4 16.9999 4H17.9999V3C17.9999 2.44772 18.4476 2 18.9999 2Z"/>
                <path d="M12.6677 3.92736L14.0015 5.12878C14.0698 7.83069 16.2815 10 18.9999 10C19.1321 10 19.2631 9.99487 19.3928 9.98479L21.0277 11.4574C21.3777 11.7574 21.1577 12.3274 20.6977 12.3274H18.9977V19.3274C18.9977 19.8774 18.5477 20.3274 17.9977 20.3274H14.9977C14.4477 20.3274 13.9977 19.8774 13.9977 19.3274V14.3274H9.99766V19.3274C9.99766 19.8774 9.54766 20.3274 8.99766 20.3274H5.99766C5.44766 20.3274 4.99766 19.8774 4.99766 19.3274V12.3274H3.29766C2.83766 12.3274 2.62766 11.7574 2.96766 11.4574L11.3277 3.92736C11.7077 3.58736 12.2877 3.58736 12.6677 3.92736Z"/>
            </svg>
        </SvgIcon>
    );
}

export default AddHomeIcon;






