import React from 'react';
import {Box, Slide, withTheme} from '@material-ui/core';
import AgentInfo from './AgentInfo';
import styled from 'styled-components';
import { useIsScrollPositionWithinOffset, useResponsiveBreakpoint }  from '../../../../hooks';
import { useStore } from '../../../../mobx-store';
import { VerificationBanner } from '../../../VerificationBanner';
import { useOnboarding } from 'components/Onboarding';

const AnimatedHeader = withTheme(styled(Box)`
    box-shadow: 0px 2px 4px -4px rgba(0,0,0,0.2), 0px 4px 5px -5px rgba(0,0,0,0.14), 0px 1px 10px -10px rgba(0,0,0,0.12);

    height: ${({ isBannerVisible })=> isBannerVisible ? '126px' : '54px'};
    ${({ theme })=> theme.breakpoints.down('xs')} {
        height: ${({ isBannerVisible })=> isBannerVisible ? '176px' : '64px'};
    }
`);



export function SlidyAgentInfo({agent, sliderRef, onVerifyClick, onSignupClick, requestSource}){
    const { UserStore } = useStore();
    const { isBannerVisible } = UserStore;
    const isXs = useResponsiveBreakpoint('xs');
    const visible = useIsScrollPositionWithinOffset(
        isBannerVisible ? 
            isXs ? 176 : 126 :
            isXs ? 64 : 54,
        sliderRef.current
    );
    const showBanner = isBannerVisible && !['property_search', 'consumer_view'].includes(requestSource);
    return (
        <Box position="relative">
            <Slide in={visible} direction="down" timeout={700}>
                <AnimatedHeader
                    bgcolor="white"
                    position="absolute"
                    isBannerVisible={showBanner}
                    width={1}
                    zIndex={20}
                >
                    <Box px={3} py={1}>
                        <AgentInfo agent={agent} />
                    </Box>
                    {showBanner && <VerificationBanner source="rpp" onVerifyClick={onVerifyClick}  onSignupClick={onSignupClick}/>}
                </AnimatedHeader>
            </Slide>
        </Box>
    );
};
