import { Card, CardActions, CardContent, Typography, useTheme } from '@material-ui/core';
import LRButton from 'components/LRButton';
import { useMyHome } from 'hooks/useMyHome';
import _ from 'lodash';
import React from 'react';

export function SugestedAreas({
    openManageModal,
    openSignUpModal,
}) {
    const theme = useTheme();
    const { isDataLocal, myHome } = useMyHome();
    const isDisabled = (isDataLocal && _.isEmpty(myHome?.address));
    return (
        <React.Fragment>
            <Card variant="outlined" style={{
                height: 241,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <CardContent>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: theme.spacing(2),
                    }}>
                        <Typography variant='h5' style={{textAlign: 'center'}}>
                            Curious about other areas?
                        </Typography>
                    </div>
                </CardContent>
                <CardActions style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 0, // cancel Card action padding
                    paddingBottom: theme.spacing(4),
                }}>
                    <LRButton
                        variant="contained"
                        color="primary"
                        disabled={isDisabled}
                        onClick={()=> {
                            isDataLocal
                                ? openSignUpModal()
                                : openManageModal();
                        }}
                    >
                        Add new
                    </LRButton>
                </CardActions>
            </Card>
        </React.Fragment>
    );
}
