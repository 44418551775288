import { useGoogleAuth } from 'components/GoogleLogin';
import { observer } from 'mobx-react-lite';
import { useStore } from 'mobx-store';
import { useEffect } from 'react';


export const Logout = observer(function Logout() {
    const { UserStore } = useStore();
    const { signOut:googleSignOut, isSignedIn:googleIsSignedIn, isInitialized:googleIsInitialized } = useGoogleAuth();

    // UseEffects
    useEffect(()=> {
        if(!googleIsInitialized) return;

        UserStore.logout();
        if(googleIsSignedIn) googleSignOut();
        window.location = '/';
    }, [googleIsInitialized]);

    return null;
});
