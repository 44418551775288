import _ from 'lodash';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { GroupRounded, WarningRounded } from '@material-ui/icons';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import LRButton from 'components/LRButton';
import moment from 'moment';
import { useStore } from 'mobx-store';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

const logger = Logger.get('ScheduledToursModalCard');

export const HomeTour = observer(function HomeTour({
    tour,
    variant = 'upcoming', // upcoming or past or cancelled
    orientation = 'horizontal', // horizontal or vertical
    onViewClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const { UserStore } = useStore();
    const { isAgent, user, profileId } = UserStore;
    const { isMobile } = useDeviceBreakpoints();
    const isCardHorizontal = useMemo(()=> {
        return orientation === 'horizontal';
    }, [orientation]);
    const isScheduled = useMemo(()=> {
        return Boolean(tour.content.scheduled_date_time);
    }, [tour.content.scheduled_date_time]);
    const isCancelled = useMemo(()=> {
        return variant === 'cancelled';
    }, [variant]);
    const isPast = useMemo(()=> {
        return variant === 'past';
    }, [variant]);
    const isUpcoming = useMemo(()=> {
        return variant === 'upcoming';
    }, [variant]);
    const isCreator = useMemo(()=> {
        if(!tour) return false;
        return profileId === tour.userId;
    }, [profileId, tour]);
    const isParticipant = useMemo(()=> {
        if(!tour?.content?.participants_joining.length) return isCreator || false;
        return isCreator || _.some(tour.content.participants_joining, {userId: profileId});
    }, [tour, profileId, isCreator]);
    const hasSigned = useMemo(()=> {
        if(!tour?.content?.pead_signatures) return false;
        return tour.content.pead_signatures[(isAgent ? user.affiliate.email : user.email)];
    }, [user, tour, isAgent]);
    const signingRequired = useMemo(()=> tour?.meta.pead_required, [tour]);
    const showActionRequired = useMemo(()=> {
        const mayRequireAction = isUpcoming && !isCancelled;
        const needsToSchedule = !isScheduled && isAgent;
        const needsToSign = signingRequired && (isAgent || isParticipant) && !hasSigned;
        return mayRequireAction && (needsToSchedule || needsToSign);
    }, [isAgent, isUpcoming, isScheduled, hasSigned, isParticipant, isCancelled, signingRequired]);

    return (
        <div
            className="ScheduledToursModal-Card-root"
            {...props}
            style={{
                borderRadius: '4px',
                borderLeft:
                    isCardHorizontal &&
                    !isScheduled &&
                    !isCancelled &&
                    !isPast &&
                    `4px solid ${theme.palette.lr_colors.brick}`,
                borderTop:
                    !isCardHorizontal &&
                    !isScheduled &&
                    !isCancelled &&
                    !isPast &&
                    `4px solid ${theme.palette.lr_colors.brick}`,
                backgroundColor: '#fff',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                display: !isCardHorizontal && 'block',
                padding: theme.spacing(2),
                ...(props.styles || {}),
            }}
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
                style={{
                    display: !isCardHorizontal && 'block',
                }}
            >
                <Grid item>
                    <div
                        style={{
                            padding: theme.spacing(1),
                            backgroundColor: '#F9F9F9',
                            border: `1px solid ${theme.palette.lr_colors.border_grey}`,
                            textAlign: 'center',
                            borderRadius: '2px',
                            color: (!isScheduled || isCancelled || isPast) && theme.palette.lr_colors.border_grey,
                            alignSelf: 'center',
                            width: isCardHorizontal ? '72px' : '100%',
                            height: '74px',
                        }}
                    >
                        <Typography color="inherit">
                            {isScheduled || tour.content.suggested_date_time_1?.date
                                ? moment(
                                    tour.content.scheduled_date_time || tour.content.suggested_date_time_1.date
                                ).format('MMM')
                                : 'DATE'}
                        </Typography>
                        <Typography
                            variant={isScheduled || tour.content.suggested_date_time_1?.date ? 'h4' : 'h5'}
                            color="inherit"
                            style={{ marginTop: (isScheduled || tour.content.suggested_date_time_1?.date) && '-10px' }}
                        >
                            <b>
                                {isScheduled || tour.content.suggested_date_time_1?.date
                                    ? moment(
                                        tour.content.scheduled_date_time || tour.content.suggested_date_time_1.date
                                    ).format('DD')
                                    : 'TBD'}
                            </b>
                        </Typography>
                    </div>
                </Grid>

                <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item style={{ height: '28px' }}>
                            <GroupRounded style={{ width: '20px', height: '20px' }} />
                        </Grid>

                        <Grid item>
                            <Typography
                                variant="body4"
                                style={{ ...theme.typography.body4, textTransform: 'uppercase' }}
                            >
                                in-person tour
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            style={{
                                color: isScheduled
                                    ? theme.palette.lr_colors.minty_fresh
                                    : theme.palette.lr_colors.brick,
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            {isUpcoming && (
                                <Typography
                                    variant="body4"
                                    color="inherit"
                                    style={{ ...theme.typography.body4, textTransform: 'uppercase' }}
                                >
                                    <b>{isScheduled ? 'SCHEDULED' : 'UNSCHEDULED'}</b>
                                </Typography>
                            )}
                            {isPast && (
                                <Typography
                                    variant="body4"
                                    style={{
                                        ...theme.typography.body4,
                                        textTransform: 'uppercase',
                                        color: theme.palette.lr_colors.steak_sauce,
                                    }}
                                >
                                    Past tour
                                </Typography>
                            )}

                            {isCancelled && (
                                <Typography
                                    variant="body4"
                                    style={{
                                        ...theme.typography.body4,
                                        textTransform: 'uppercase',
                                        color: theme.palette.lr_colors.steak_sauce,
                                    }}
                                >
                                    Cancelled
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Typography variant="h6">
                        {tour.content.tour_name.length > 30
                            ? `${tour.content.tour_name.substring(0, 30).concat('...')}`
                            : tour.content.tour_name}
                    </Typography>

                    <Typography>
                        <b>
                            {isScheduled
                                ? `${moment(tour.content.scheduled_date_time).calendar(null, {
                                    lastWeek: 'h:mma ddd, MMM DD',
                                    lastDay: 'h:mma [yesterday]',
                                    sameDay: 'h:mma [today]',
                                    nextDay: 'h:mma [tomorrow]',
                                    nextWeek: 'h:mma ddd, MMM DD',
                                    sameElse: 'h:mma ddd, MMM DD',
                                })} • `
                                : ''}
                        </b>{' '}
                        {tour.content.boardCardIds.length} home{tour.content.boardCardIds.length !== 1 ? 's' : ''} •{' '}
                        {(tour.content.participants_joining?.length || 0) + 1}{' '}
                        {(tour.content.participants_joining?.length || 0) + 1 === 1 ? 'person' : 'people'}
                    </Typography>
                </Grid>

                {/* Horizontal separator */}
                {isCardHorizontal && <Grid item xs />}

                {showActionRequired && (
                    <Grid item style={{ marginTop: isMobile && -theme.spacing(1) }}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item style={{ color: theme.palette.lr_colors.brick, height: 26 }}>
                                <WarningRounded style={{ fontSize: '16px' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">Action required</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item style={{ marginRight: isCardHorizontal && theme.spacing(2) }}>
                    <LRButton
                        variant={showActionRequired ? 'contained' : 'outlined'}
                        onClick={onViewClick}
                        fullWidth={!isCardHorizontal}
                        color={showActionRequired ? 'primary' : 'default'}
                    >
                        {showActionRequired ? 'Review' : 'View'}
                    </LRButton>
                </Grid>
            </Grid>
        </div>
    );
});
