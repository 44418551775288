import { isObject } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

/**
 * function useResponsiveConfiog
 * Given an object indexed by breakpoint key with a correspoding value,return the value with the matching mediaQuery.
 */
export const useResponsiveConfig = (config)=> {
    if(!isObject(config)) {
        config = { xs: config };
    }
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

    if(matchesXl && config.xl) {
        return config.xl;
    }
    if(matchesLg && config.lg) {
        return config.lg;
    }
    if(matchesMd && config.md) {
        return config.md;
    }
    if(matchesSm && config.sm) {
        return config.sm;
    }

    return config.xs;
};
