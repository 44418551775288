// eslint-disable-next-line
import Logger from 'js-logger';
import React from 'react';
import { useStore } from '../../mobx-store';
import { observer } from 'mobx-react-lite';
import Confetti from '../Confetti';


function GlobalConfetti() {
    const { GlobalConfettiStore } = useStore();

    return (
        <>
            {
                GlobalConfettiStore.open && (
                    <Confetti iterationCount={2} />
                )
            }
        </> 
    );
}

export default observer(GlobalConfetti);
