const getUrlParam = (url, key)=> {
    const re = new RegExp('(\\?|&)' + key + '\\=([^&]*)(&|$)');
    const matches = (url || '').match(re);
    if(matches) {
        return (matches[2]);
    } else {
        return ('');
    }
};

const getVideoProviderAndId = (url)=> {
    var retVal = {};
    const matches = {
        vimeo: url.match(/vimeo.com\/(\d+)/i),
        wistia: url.match(/wistia.com\/medias\/(.*)(\/|$)/i),
    };

    if(url.indexOf('youtube.com/watch') !== -1) {
        retVal.provider = 'youtube';
        retVal.id = getUrlParam(url, 'v');
    } else if(matches.vimeo) {
        retVal.provider = 'vimeo';
        retVal.id = matches.vimeo[1];
    } else if(matches.wistia) {
        retVal.provider = 'wistia';
        retVal.id = matches.wistia[1];
    } else if(url.indexOf('matterport.com/show') !== -1) {
        retVal.provider = 'matterport';
        retVal.id = getUrlParam(url, 'm');
    } else {
        retVal = null;
    }
    return (retVal);
};

const videoUtils = {
    getVideoProviderAndId,
};

export default videoUtils;
