import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, Box, Typography, useTheme, withTheme } from '@material-ui/core';
import { ChevronLeftRounded } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useListingExplorerStore, ListingExplorerStoreProvider, useListingExplorerStoreContext } from './store';
import { useResponsiveBreakpoint, useQuery } from '../../hooks';
import splitAddress from '../../services/split-address';
import analytics from '../../services/analytics';
import LRTabs, { LRTab } from '../LRTabs';
import { PhotoGallery } from './PhotoGallery';
import { VirtualTour } from './VirtualTour';
import { ExploreNearby } from './ExploreNearby';
import { StreetView } from './StreetView';
import querystring from 'query-string';
import Logger from 'js-logger';

const StyledLRTab = withTheme(styled(LRTab)`
    min-width: 0;
    opacity: 1;
    height: 64px;
    padding: 6px 0px;

    ${({ theme })=> theme.breakpoints.down('xs')} {
        height: 56px;
    }
`);

const StyledTypography = withTheme(styled(Typography)`
    color: #1A1A1A;
    .Mui-selected & {
        font-weight: bold;
    }
    ${({ theme })=> theme.breakpoints.down('xs')} {
        padding-left: 8px;
        padding-right: 8px;
    }
`);

export const ListingExplorerModal = observer(function({
    open = true,
    card,
    onClose,
    requestSource,
    reportId,
    PhotoGalleryProps = {},
    ...props
}) {
    const isXs = useResponsiveBreakpoint('xs');
    const listingExplorerStore = useListingExplorerStore(card, 'photos');
    const query = useQuery();
    const tab = query.get('tab');
    const listing_details = listingExplorerStore.listing_details;
    const theme = useTheme();
    const [addressLine1] = splitAddress(listing_details?.address);

    useEffect(function() {
        listingExplorerStore.setTab(tab || 'photos');
    }, [listingExplorerStore, tab]);

    return (
        <Dialog fullScreen open={open} {...props}>
            <ListingExplorerStoreProvider listingExplorerStore={listingExplorerStore}>
                <Box
                    display="flex"
                    flexDirection="column"
                    height={1}
                    overflow="hidden"
                >
                    <Box
                        height={[56, 64]}
                        display={'flex'}
                        alignItems={['center', 'flex-end']}
                        justifyContent={['flex-start', 'center']}
                        position="relative"
                        borderBottom={[`1px solid ${theme.palette.lr_colors.border_grey}`, 'none']}
                    >
                        <Box
                            position={{xs: 'absolute', sm: 'static'}}
                            left={[16, 0]}
                            top={0}
                            flex={1}
                            display="flex"
                            alignItems="center"
                            style={{cursor: 'pointer'}}
                            borderBottom={{xs: 'none', sm: `1px solid ${theme.palette.lr_colors.border_grey}`}}
                            height="100%"
                            onClick={()=> onClose()}
                            overflow="hidden"
                        >
                            <Box fontSize={24} display="flex" alignItems="center" mr={1} paddingLeft={{xs: 0, sm: 3}}>
                                <ChevronLeftRounded fontSize="inherit" />
                            </Box>
                            {!isXs &&
                                <Box color="lr_colors.grade" width="calc(100% - 48px)">
                                    <Typography
                                        color="inherit"
                                        variant="body2"
                                        style={{
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: '100%'
                                        }}
                                    >
                                        {addressLine1}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                        {!isXs &&
                            <>
                                <Box maxWidth={512} flex="2">
                                    <ListingExplorerTabs />
                                </Box>
                                <Box flex="1" height="100%" borderBottom={`1px solid ${theme.palette.lr_colors.border_grey}`} />
                            </>
                        }
                        {isXs &&
                            <Box mx="auto" height="100%" display="flex" alignItems="center">
                                <Box color="lr_colors.grade">
                                    <Typography color="inherit" variant="body2">{addressLine1}</Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                    {isXs && <Box height={56}><ListingExplorerTabs /></Box>}

                    <Box flex={1} overflow="hidden">
                        {listingExplorerStore.isPhotosTabSelected && <PhotoGallery selectedPhotoIndex={parseInt(query.get('index'))} {...PhotoGalleryProps} />}
                        {listingExplorerStore.isTourTabSelected && <VirtualTour />}
                        {listingExplorerStore.isExploreTabSelected && <ExploreNearby requestSource={requestSource} reportId={reportId} />}
                        {listingExplorerStore.isStreetViewTabSelected && <StreetView />}
                    </Box>
                </Box>
            </ListingExplorerStoreProvider>
        </Dialog>
    );
});

export const ListingExplorerTabs = observer(function() {
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const listingExplorerStore = useListingExplorerStoreContext();
    const tabs = [
        {
            label: 'Photos',
            value: 'photos',
            mixpanelSource: 'photos',
            visible: true,
        },
        {
            label: '3D Tour',
            value: '3dTour',
            mixpanelSource: '3d_tour',
            visible: listingExplorerStore.has3dTour,
        },
        // Hiding the explore nearby tab to reduce Places API usage
        // {
        //     label: 'Explore Nearby',
        //     value: 'explore',
        //     mixpanelSource: 'explore_nearby',
        //     visible: true,
        // },
        {
            label: 'Street View',
            value: 'streetView',
            mixpanelSource: 'street_view',
            visible: true,
        },
    ];

    return (
        <LRTabs
            variant="fullWidth"
            value={listingExplorerStore.selectedTab}
            onChange={(e, value)=> {
                analytics.eventTrack('robin_gallery_tab_click', {
                    card_id: listingExplorerStore.card.id,
                    source: tabs.find((tab)=> tab.value === value).mixpanelSource,
                });
                const search = querystring.parse(location.search);
                history.push({
                    search: querystring.stringify({
                        ...search,
                        tab: value
                    }),
                });
            }}
            TabIndicatorProps={{
                style: {
                    backgroundColor: theme.palette.lr_colors.grade
                }
            }}
            textColor="inherit"
            style={{ borderBottom: `1px solid ${theme.palette.lr_colors.border_grey}` }}
        >
            {tabs
                .filter((tab)=> tab.visible)
                .map((tab)=> {
                    const Label = (
                        <StyledTypography variant="body1">{tab.label}</StyledTypography>
                    );

                    return <StyledLRTab key={tab.value} label={Label} value={tab.value} />;
                })}
        </LRTabs>
    );
});
