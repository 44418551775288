import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, TextField, Button } from '@material-ui/core';
import listreportsBlue from '../../../theme/listreports-blue';
import Box from '@material-ui/core/Box';
import styled, { css } from 'styled-components/macro';
import { Slider, StyledBoxArrow } from '../../../components/Slider';
import ListingImage from '../../../components/ListingImage';
import addCommas from '../../../services/add-commas';
import { useResponsiveBreakpoint } from '../../../hooks';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useNameHomeStoreContext } from '../store';
import { withTheme } from '@material-ui/styles';

const StyledSlide = withTheme(styled.div`
    width: 100%;
    height: 100%;
    transition: transform 0.1s linear;
    transform: scale(0.7);
    ${({ theme, isSelected })=>
        isSelected &&
        css`
            transform: scale(1.1);
            position: relative;
            &::before {
                content: ' ';
                position: absolute;
                top: -8px;
                right: -8px;
                bottom: -8px;
                left: -8px;
                border: 1px solid ${theme.palette.lr_colors.border_grey_lighter};
            }
        `}
`);

/**
 * Previuos Button
 */
function PreviousButton({ collectionLength, state, ...props }) {
    return (
        <StyledBoxArrow {...props} left="-50px">
            <Button>
                <KeyboardArrowLeftIcon style={{ fontSize: '2.5rem' }} />
            </Button>
        </StyledBoxArrow>
    );
}

/**
 * Next Button
 */
function NextButton({ collectionLength, state, ...props }) {
    return (
        <StyledBoxArrow {...props} right="-50px">
            <Button>
                <KeyboardArrowRightIcon style={{ fontSize: '2.5rem' }} />
            </Button>
        </StyledBoxArrow>
    );
}

function NamedHomeForm() {
    const nameHomeStore = useNameHomeStoreContext();
    const { listing } = nameHomeStore;

    const { listing_details } = listing;

    const isXs = useResponsiveBreakpoint('xs');

    const handleOnSlideFocus = useCallback(
        (i)=> {
            nameHomeStore.index = i;
            nameHomeStore.selectedPhoto = listing_details.photos[i];
        },
        [listing_details, nameHomeStore.selectedPhoto, nameHomeStore.index]
    );

    const handleNameFieldOnChange = useCallback(
        (e)=> {
            const value = e.target.value;
            if((value?.length || 0) <= 25) nameHomeStore.name = value;
        },
        [nameHomeStore.name]
    );

    const splitAddress = useCallback((address)=> {
        return (address || '').replace(/^([\w\s\d#.:]*)(,)/i, '$1|||')
            .split('|||');
    }, []);
    const [addressLine1, addressLine2] = splitAddress(listing_details.address);
    return (
        <>
            <Box textAlign="center" mb={2}>
                <Typography variant="h5">Choose a cover photo:</Typography>
            </Box>
            <Box mb={2} maxWidth={isXs ? 300 : 600} margin="0 auto">
                {listing_details.photos && listing_details.photos.length && (
                    <Slider
                        type={'carousel'}
                        collection={listing_details.photos}
                        previousButtonComponent={PreviousButton}
                        nextButtonComponent={NextButton}
                        index={nameHomeStore.index}
                        renderSlide={(photo, { isSelected, index })=> (
                            <>
                                {!isXs && (
                                    <StyledSlide isSelected={isSelected} onClick={()=> (nameHomeStore.index = index)}>
                                        <ListingImage image={photo} width={1} height={120} />
                                    </StyledSlide>
                                )}
                                {isXs && <ListingImage image={photo} width={220} height={150} />}
                            </>
                        )}
                        onSlideFocus={handleOnSlideFocus}
                        perView={isXs ? 1 : 3}
                        gap={0}
                        glideProps={{ startAt: nameHomeStore.selectedIndex }}
                        focusAt={'center'}
                    />
                )}
            </Box>

            <Box mx="auto" mb={6} textAlign="center" position="relative" maxWidth={300}>
                <TextField
                    onChange={handleNameFieldOnChange}
                    fullWidth
                    placeholder="Give it a nickname"
                    value={nameHomeStore.name}
                    inputProps={{ style: { textAlign: 'center', borderBottom: '1px solid #DDDDDD' } }}
                />
                <Box color="#979797" fontSize={14} position="absolute" right={0} top="calc(100% + 4px)">
                    {nameHomeStore.name?.length || 0}/25
                </Box>
            </Box>

            <Box display="flex" justifyContent="center">
                <Box
                    px={5}
                    py={3}
                    width={[1, '60%']}
                    bgcolor="lr_colors.grey_light"
                    borderRadius="4px"
                    textAlign="center"
                >
                    <Box fontSize={16} letterSpacing={0.5}>
                        ${addCommas(listing_details.price)}
                    </Box>
                    <Box fontSize={16} letterSpacing={0.5} fontWeight={500}>
                        {addressLine1}
                    </Box>
                    <Box color="#979797" letterSpacing={0.25}>
                        {addressLine2}
                    </Box>
                </Box>
            </Box>
        </>
    );
}

NamedHomeForm.propTypes = {};

export default observer(NamedHomeForm);
