import React, { useEffect, useState } from 'react';
import { useTheme, CircularProgress, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Listing } from 'components/Listing';
import { useListing } from 'hooks/data/listing/useListing';
import { useBoardCard } from 'hooks/data/board-card/useBoardCard';
import { useListpackAgent } from 'hooks/data/listpack/useListpackAgent';
import { useResponsiveBreakpoint } from 'hooks';
import analytics from 'services/analytics';
import CopyToClipboard from 'components/CopyToClipboard';
import { ActionShareIcon } from 'components/LRIcons';
import { portalApi } from 'apis';
import { LoggedOutHeader } from './loggedOutHeader';

export const LoggedOutListingView = function() {
    const { listingId, listpackId, cardId, agentId } = useParams();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const theme = useTheme();

    const { data: card } = useBoardCard(cardId);
    const { data: agent } = useListpackAgent(listpackId || agentId);
    const buyerAgent = card?.buyerAgent || agent;
    const lo = buyerAgent?.preferred_vendor;
    const { data: listing } = useListing(listingId || card?.listingId || card?.content.encoded_id);

    const [shortShareUrl, setShortShareUrl] = useState(null);

    useEffect(function() {
        if(cardId && !card) {
            return;
        }

        analytics.eventTrack('robin_shared_listing_viewed', {
            card_id: cardId,
            listingId: listingId || card?.listingId || card.content.encoded_id,
            logged_out: 'yes'
        });
    }, [cardId, card, listingId]);

    useEffect(()=> {
        async function shortenShareUrl(shareUrl) {
            const shortenedUrl = await portalApi.shortenURL(shareUrl);
            setShortShareUrl(shortenedUrl.data);
        }

        shortenShareUrl(window.location.href);
    }, []);

    return (
        <div style={{backgroundColor: theme.palette.lr_colors.costa_mesa}}>
            {listing && (
                <div style={{backgroundColor: 'white', maxWidth: '768px', margin: '0 auto'}}>
                    <LoggedOutHeader listingId={listing.id} />
                    <Listing
                        listing={listing}
                        readOnly={true}
                        buyerAgent={buyerAgent}
                        loanOfficer={lo}
                        // onSchedulePrivateTour={onPrivateTourClick}
                        // onContactAgentClick={onContactAgentClick}
                    />
                </div>
            )}
            {!listing && (
                <div>
                    <div style={{width: '50px', margin: '0 auto'}}>
                        <CircularProgress size={50} color="secondary"/>
                    </div>
                </div>
            )}
        </div>
    );
};
