import { Ability, AbilityBuilder } from '@casl/ability';

const agentAllowedActivities = [
    'comment',
    'move_card',
    'schedule_viewing_request',
    'schedule_viewing_request_comment',
    'offer_submitted',
];
const loAllowedActivities = [
    'comment',
    'move_card',
    'schedule_viewing_request_comment',
];

const profilePermissions = {
    consumer(user, { can, cannot }) {
        const boardIds = user.board_permissions.map((permission)=> permission.boardId);
        can('manage', 'BoardCard', { boardId: { $in: boardIds } });
        can('manage', 'Listpack');
        can('manage', 'Listing');
        can('manage', 'BoardNotification');
        can('manage', 'Pinspiration');
        can('manage', 'User');
        can('manage', 'BoardCollaboration', { userId: user.id});
        can('read', 'BoardCollaboration');
        can('manage', 'Board', { ownerId: user.id });
        can('read', 'Onboarding');
        can('create', 'HomeTour');
        can('delete', 'HomeTour', {
            userId: { $eq: user.id },
        });
        cannot('edit', 'HomeTour');
        can('read', 'HomeTour', { userId: { $eq: user.id } });
        can('read', 'HomeTour', { 'content.participants_joining': { $elemMatch: { userId: user.id } } });
        // can('edit', 'HomeTour', {
        //     userId: { $eq: user.id },
        // });
        // can('edit', 'HomeTour', {
        //     'content.participants_joining': {
        //         $elemMatch: {
        //             userId: { $eq: user.id }
        //         }
        //     },
        // });
        const handSelectedListpackId = `listpack-${user.id}-${user.affiliateId}`;
        cannot('edit', 'Listpack', { id: handSelectedListpackId });
        //cannot('edit', 'Listpack', { userId : { $ne: user.id } });
        can('logout');

        // UI Checks. This may not the best way to configure this.
        // Authorization logic might not be the place for this.
        // If we need to expand we probably should seek a diferent solution.
        can('read', 'LRLogo');
    },
    agent(user, { can, cannot }) {
        const boardIds = user.board_permissions.map((permission)=> permission.boardId);
        can('read', 'BoardCard', { boardId: { $in: boardIds } });
        cannot('edit', 'BoardCard');
        can('create', 'Listpack', { boardId: { $in: boardIds } });
        can('edit', 'Listpack', { affiliateId : user.affiliateId});
        const handSelectedListpackId = `listpack-${user.id}-${user.affiliateId}`;
        cannot('edit', 'Listpack', { id: handSelectedListpackId });
        can('read', 'Listpack', { boardId: { $in: boardIds } });
        can('read', 'BoardNotification', { boardId: boardIds, type: { $in: agentAllowedActivities } });
        can('read', 'Pinspiration');
        can('create', 'BoardCollaboration');
        can('read', 'BoardCollaboration', { type: { $in: agentAllowedActivities } });
        cannot('read', 'BoardCollaboration', { visibleToAffiliate: false, type: { $in: ['comment', 'schedule_viewing_request_comment'] } });
        can('edit', 'BoardCollaboration', { affiliateId: user.affiliateId, type: 'comment' });
        can('edit', 'BoardCollaboration', { userId: user.affiliateId, type: 'comment' });
        can('read', 'HomeTour');
        cannot('edit', 'HomeTour');
        can('delete', 'HomeTour');
        cannot('create', 'HomeTour');
    },
    lo(user, { can, cannot }) {
        const boardIds = user.board_permissions.map((permission)=> permission.boardId);
        can('read', 'BoardCard', { boardId: { $in: boardIds } });
        cannot('edit', 'BoardCard');
        can('read', 'Listpack', { boardId: { $in: boardIds } });
        can('read', 'Pinspiration');
        can('read', 'BoardCollaboration', { type: { $in: loAllowedActivities } });
        cannot('read', 'BoardCollaboration', { visibleToLO: false, type: { $in: ['comment', 'schedule_viewing_request_comment'] } });
        can('create', 'BoardCollaboration');
        can('edit', 'BoardCollaboration', { userId: user.affiliate && user.affiliate.preferredVendorId, type: 'comment' });
        cannot('delete', 'HomeTour');
        can('read', 'HomeTour');
    },
    guest(user, {can }){

    }
};

export function defineAbilityFor(user) {
    const builder = new AbilityBuilder(Ability);

    if(!user) {
        profilePermissions['guest'](user, builder);
    } else {
        if(typeof profilePermissions[user.accessProfile] === 'function') {
            profilePermissions[user.accessProfile](user, builder);
        } else {
            throw new Error('Trying to use unknown access profile');
        }
    }

    return builder.build();
}
