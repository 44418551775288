import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import { COLOR_GREY_DARK } from '../../constants';
import { useTheme } from '@material-ui/styles';

const toneColorMapping = {
    info: 'inherit',
    error: '#d51d4c',
    positive: 'inherit',
    neutral: 'inherit',
};

// Allows label to exist even if it's an empty space
const invisibleChar = String.fromCharCode(8292);

const StyledBox = styled(Box)`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-input-placeholder {
        color: ${COLOR_GREY_DARK};
        font-size: ${(props)=> (props.placeholderFontSize ? props.placeholderFontSize : '')}px;
    }
    input::-moz-placeholder {
        color: ${COLOR_GREY_DARK};
        font-size: ${(props)=> (props.placeholderFontSize ? props.placeholderFontSize : '')}px;
    }
    input:-ms-input-placeholder {
        color: ${COLOR_GREY_DARK};
        font-size: ${(props)=> (props.placeholderFontSize ? props.placeholderFontSize : '')}px;
    }
    input:-moz-placeholder {
        color: ${COLOR_GREY_DARK};
        font-size: ${(props)=> (props.placeholderFontSize ? props.placeholderFontSize : '')}px;
    }
`;
const Input = styled(Box)`
    -webkit-appearance: none;
`;

/**
    DEVELOPER NOTE: We have multiple text fields in this app that do the same thing. Instead
    of using this component you should probably use LRInputOutlined or LRInput.
    Only use this component if you absolutly know you have to use this for a
    specific reason.
 */

export function TextField({
    name,
    value,
    onChange,
    label,
    message,
    tone,
    required,
    type,
    placeholder,
    inputRef,
    onBlur,
    startAdornment,
    endAdornment,
    inputProps,
    labelProps,
    placeholderFontSize,
    ...props
}) {
    const toneColor = tone ? toneColorMapping[tone] : null;
    const theme = useTheme();

    return (
        <StyledBox {...props} placeholderFontSize={placeholderFontSize}>
            {label && (
                <Box mb={1} fontWeight="bold" fontSize="14px" textAlign="left" color={toneColor} {...labelProps}>
                    {_.isString(label) ? label : invisibleChar}
                    {required ? '*' : null}
                </Box>
            )}
            <Box position="relative">
                {startAdornment && (
                    <Box position="absolute" top={0} bottom={0} pl={1.5} display="flex" alignItems="center">
                        {startAdornment}
                    </Box>
                )}
                <Input
                    component="input"
                    height={42}
                    border={`1px solid ${toneColor || '#D5DFE3'}`}
                    borderRadius="2px"
                    pl={startAdornment ? 3 : '14px'}
                    pr={endAdornment ? 3 : 1.5}
                    name={name}
                    value={value}
                    style={{ outline: 'none' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={required}
                    width={1}
                    type={type || 'text'}
                    placeholder={placeholder}
                    fontFamily={theme.typography.fontFamily.join(', ')}
                    ref={inputRef}
                    {...inputProps}
                />
                {endAdornment && (
                    <Box position="absolute" top={0} bottom={0} right={0} pr={1.5} display="flex" alignItems="center">
                        {endAdornment}
                    </Box>
                )}
            </Box>
            {message && (
                <Box mt={1} fontSize="12px" color={toneColor} textAlign="left">
                    {message}
                </Box>
            )}
        </StyledBox>
    );
}

TextField.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    tone: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['info', 'error', 'positive', 'neutral'])]),
    message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element]),
    required: PropTypes.bool,
};
