import React, { useRef, useContext } from 'react';
import { observable, decorate, action, computed, flow } from 'mobx';
import { useStore } from '../../mobx-store';
import { AsyncRequest, asyncRequest } from '../../mobx-store/utils/async-request';
import { benoitApi } from '../../apis';
import _ from 'lodash';
import Logger from 'js-logger';
import moment from 'moment';
import analytics from '../../services/analytics';

const logger = Logger.get('SignupStore');

class SaveUserRequest extends AsyncRequest {
    asyncRequest(userData, { inviteId, affiliateId, externalAuth, fromHomeReportSignup } = {}) {
        return benoitApi.signupUser({ userData, inviteId, affiliateId, externalAuth, fromHomeReportSignup });
    }

    get firstError() {
        return this.errorData && this.errorData.errors?.length && this.errorData.errors[0];
    }

    get hasDuplicateEmailError() {
        return (
            this.firstError &&
            this.firstError.detail.includes('already exists') &&
            this.firstError.detail.includes('email')
        );
    }

    get hasDuplicateAuthError() {
        return (
            this.firstError &&
            this.firstError.detail.includes('already exists') &&
            (this.firstError.detail.includes('apple') || this.firstError.detail.includes('google') || this.firstError.detail.includes('facebook'))
        );
    }

    get hasDuplicatePhoneError() {
        return (
            this.firstError &&
            this.firstError.detail.includes('already exists') &&
            this.firstError.detail.includes('phone')
        );
    }
}

decorate(SaveUserRequest, {
    firstError: computed,
    hasDuplicateEmailError: computed,
    hasDuplicateAuthError: computed,
    hasDuplicatePhoneError: computed,
});

class SignupStore {
    status = 'initial';
    userInfo = null;
    affiliate = null;
    externalAuth = null; // ex: { token: '', method: 'google' }, from google btns
    signUpMethod = null;
    saveUserRequest = new SaveUserRequest();
    inviteRequest = asyncRequest((inviteId)=> {
        return benoitApi.getBoardUserInvite(inviteId);
    });
    sendCodeRequest = asyncRequest((user, method = 'phone')=> {
        const value = method === 'phone' ? user.phone : user.email;
        return benoitApi.requestLogin(method, value, { forRobin: true });
    });
    activateDeviceRequest = asyncRequest((code)=> {
        const tokenObj = this.sendCodeRequest.data;
        return benoitApi.activateDevice(code, tokenObj.token);
    });
    affiliateRequest = asyncRequest(async (affiliateId)=> {
        this.affiliate = await benoitApi.getAffiliate(affiliateId);
        return this.affiliate;
    });

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.UserStore = rootStore.UserStore;

    }

    get inSignupMenu() {
        return this.status === 'signupMenu';
    }

    get inOrganicSignup() {
        return this.status === 'organicSignup';
    }

    get inVerification() {
        return this.status === 'verification';
    }

    get skipVerification() {
        return this.status === 'skip_verification';
    }

    get isInitialState() {
        return this.status === 'initial';
    }

    get invite() {
        return this.inviteRequest.hasLoaded && this.inviteRequest.data;
    }

    get invitedUser() {
        return this.invite && this.invite.invitedUser;
    }

    get isSignupByPhone(){
        return this.signUpMethod === 'phone';
    }

    get isSignupByEmail(){
        return this.signUpMethod === 'email';
    }

    get isSignupByExternal(){
        return this.signUpMethod === 'external';
    }

    get signUpMethodForVerification(){
        if(this.isSignupByPhone || (this.isSignupByExternal && this.userInfo?.phone)) {
            return 'phone';
        }
        return 'email';
    }

    goToMenu() {
        this.status = 'signupMenu';
    }

    goToSignupForm() {
        this.status = 'organicSignup';
    }

    setInputInfo(input, type){
        if(!this.userInfo) {
            this.userInfo = {};
        }

        const inputTypeToUserInfo = {
            email: 'email',
            tel: 'phone',
        };
        this.signUpMethod = inputTypeToUserInfo[type];
        const info = {
            [this.signUpMethod]: input,
        };
        _.merge(this.userInfo, info);
        this.goToSignupForm();
    }

    userExists(emailOrPhone) {
        return benoitApi.userExists(emailOrPhone);
    };

    isPhoneValid(phone) {
        return benoitApi.isPhoneValid(phone);
    };

    setExternalProviderInfo({ userInfo, auth }) {
        if(!this.userInfo) {
            this.userInfo = {};
        }
        _.merge(this.userInfo, { ...userInfo, account_type: 'verified_robin', });
        this.externalAuth = auth;
        this.status = 'organicSignup';
        this.signUpMethod = 'external';
    }

    saveUser = flow(function*(userData, fromHomeReportSignup) {
        const timeframeMapper = {
            'i_already_own_a_home': 'i_already_bought_a_home_using_robin',
            'im_not_sure':'im_not_sure',
            'ASAP': 'asap',
            'within 3 months': '1-3 months',
            '3-6 months': '3-6months',
            '6-12 months': '6-12months',
            '12+ months': '12+months',
        };

        const user = _.merge({}, this.userInfo || {}, userData);
        user.meta.howSoonLastSeenAt = moment().toISOString();
        user.terms_and_conditions_accepted = process.env.REACT_APP_TERMS_AND_CONDITIONS_VERSION;
        yield this.saveUserRequest.fetch(user, {
            inviteId: this.inviteId,
            affiliateId: this.affiliate?.id,
            externalAuth: this.externalAuth,
            fromHomeReportSignup
        });
        if(this.saveUserRequest.hasLoaded) {
            this.userInfo = this.saveUserRequest.data;
            const oldHowSoon = this.invitedUser?.meta?.how_soon;
            const newHowSoon = this.userInfo.meta.how_soon;
            if(oldHowSoon && oldHowSoon !== newHowSoon) {
                const invite = this.inviteRequest.data;
                let role = invite?.meta?.label;
                if(role){
                    role = role.replace('-', '');
                }
                analytics.eventTrack('robin_user_timeframe_updated', {
                    name: this.userInfo.name,
                    distinct_id: this.userInfo.id,
                    email: this.userInfo.email,
                    board_id: this.userInfo.defaultBoardId,
                    role,
                    source: 'registration',
                    new_timeframe: timeframeMapper[newHowSoon],
                    old_timeframe: timeframeMapper[oldHowSoon],
                });
            }
            if(this.isSignupByPhone){
                yield this.sendCode('phone');
            } else if(this.isSignupByEmail){
                yield this.sendCode('email');
            } else if(this.isSignupByExternal || fromHomeReportSignup) {
                this.status = 'skip_verification';
            }
            if(this.sendCodeRequest.hasLoaded) {
                this.status = 'verification';
                this.UserStore.fetchLocalUser();
            }
        }
    });

    sendCode = flow(function*(method = 'phone'){
        if(this.userInfo[method]){
            yield this.sendCodeRequest.reFetch(this.userInfo, method);
        }
    });

    sendEmailCode = flow(function*(){
        if(this.userInfo.email){
            yield this.sendCodeRequest.reFetch(this.userInfo, 'email');
        }
    });

    sendPhoneCode = flow(function*() {
        if(this.userInfo.phone) {
            yield this.sendCodeRequest.reFetch(this.userInfo, 'phone');
        }
    });

    activateDevice = flow(function*(code) {
        if(!this.sendCodeRequest.hasLoaded) {
            logger.warn('Token not available');
        }

        yield this.activateDeviceRequest.fetch(code);
    });

    fetchInvite = flow(function*(inviteId) {
        this.inviteId = inviteId;
        yield this.inviteRequest.fetch(inviteId);
        if(this.inviteRequest.hasLoaded) {
            const invite = this.inviteRequest.data;
            if(invite.invitedUser) {
                this.userInfo = _.pick(invite.invitedUser, ['first_name', 'last_name', 'email']);
                if(invite.invitedUser.meta?.how_soon) {
                    this.userInfo.meta = { how_soon: invite.invitedUser.meta.how_soon };
                }
            } else {
                this.userInfo = _.pick(invite, ['first_name', 'last_name', 'email']);
            }
            if(invite.affiliateId) {
                yield this.affiliateRequest.fetch(this.inviteRequest.data.affiliateId);
            }
        }
        this.status = 'signupMenu';
    });
}

decorate(SignupStore, {
    status: observable,
    userInfo: observable,
    affiliate: observable,
    externalAuth: observable,
    inviteId: observable,
    signUpMethod: observable,

    isInitialState: computed,
    inSignupMenu: computed,
    inOrganicSignup: computed,
    inVerification: computed,
    skipVerification: computed,
    invite: computed,
    invitedUser: computed,
    isSignupByPhone: computed,
    isSignupByEmail: computed,
    isSignupByExternal: computed,
    signUpMethodForVerification: computed,

    setExternalProviderInfo: action,
    setInputInfo: action,
});

const SignupContext = React.createContext();

export function useSignupStore() {
    const rootStore = useStore();
    const storeRef = useRef(new SignupStore(rootStore));
    return storeRef.current;
}

export function SignupStoreProvider({ signupStore, children }) {
    return <SignupContext.Provider value={signupStore}>{children}</SignupContext.Provider>;
}

export function useSignupStoreContext() {
    return useContext(SignupContext);
}
