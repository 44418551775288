import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LRButton from '../../components/LRButton';
import { Box } from '@material-ui/core';

class DraggableUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null
        };
    }

    onChangeHandler = (event)=> {
        const { onFileSelected } = this.props;
        this.setState({ selectedFile: event.target.files[0] });
        onFileSelected(event.target.files[0]);
    };

    render() {
        const { btnText, showInstructionalHeader, onFileSelected, ...otherProps } = this.props;

        return (
            <Box display="flex" {...otherProps}>
                <Box flex="1" display="flex" alignItems="center" justifyContent="center">
                    <Box textAlign="center">
                        {showInstructionalHeader && <Box mb={2}>Drag and drop an image or</Box>}
                        <Box
                            component={'input'}
                            display="none"
                            id="outlined-button-file"
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={this.onChangeHandler}
                        />
                        <label htmlFor="outlined-button-file">
                            <LRButton color="primary" component="span" variant="outlined">
                                {btnText}
                            </LRButton>
                        </label>
                    </Box>
                </Box>
            </Box>
        );
    }
}

DraggableUpload.propTypes = {
    onFileSelected: PropTypes.func.isRequired,
    btnText: PropTypes.string.isRequired,
    showInstructionalHeader: PropTypes.bool
};

export default DraggableUpload;
