import lodash from 'lodash';

/**
 *
 * BRAZE CUSTOM EVENT CONFIG
 * The object keys (e.g. 'button_click') will be the custom event names in Braze
 * Edit this object to modify and customize the events tracked in Braze
 *
 *
 * brazeEventProperties can be a static object literal or a function
 * that takes the mixpanel event name and properties as arguments e.g.
 * brazeEventProperties: { foo: 'bar', ... }
 * - OR -
 * brazeEventProperties: (mpEventName, mpEventProperties)=> ({ foo: 'bar', ... })
 *
 */

export const CUSTOM_EVENT_CONFIG = {
    button_click: [
        { 
            mixpanelEventNames: [ 'robin_add_any_home_submit' ],
            brazeEventProperties: { target: 'robin_add_any_home' },
        },

        { 
            mixpanelEventNames: [ 'robin_card_created' ],
            brazeEventProperties: { target: 'robin_add_to_board' },
        },

        { 
            mixpanelEventNames: [ 'robin_board_listpack_created' ],
            brazeEventProperties: (action, properties)=> ({
                target: 'robin_saved_search',
                name: lodash.get(properties, 'listpack_name'),
                location: lodash.get(properties, 'listpack_area_ids'),
            }),
        },

        { 
            mixpanelEventNames: [
                'robin_schedule_private_tour_scheduled',
                'robin_schedule_a_tour_request_submit',
            ],
            brazeEventProperties: (action, properties)=> ({
                target: 'robin_schedule_a_tour',
                address: lodash.get(properties, 'address', null),
                tour_name: lodash.get(properties, 'tour_name', null),
            }),
        },

        {
            mixpanelEventNames: [ 'make_offer_button_click' ],
            brazeEventProperties: { target: 'robin_prepare_an_offer' },
        },

        {
            mixpanelEventNames: [ 'robin_cobuyer_invite_submit' ],
            brazeEventProperties: (action, properties)=> ({
                target: 'robin_cobuyer_invite',
                cobuyer_name: `${lodash.get(properties, 'first_name')} ${lodash.get(properties, 'last_name')}`,
                cobuyer_email: lodash.get(properties, 'email'),
            }),
        },
    ],
};

/**
 *  Transforming CUSTOM_EVENT_CONFIG to MIXPANEL_BRAZE_EVENT_MAP
 *  with the mixpanel event names as the key for a more efficient lookup
 *
 *  e.g.
 *  {
 *      View Report Click: {
 *          eventName: "button_click",
 *          eventProperties: { target: "Marketing Kits View" }
 *      },
 *      ...
 *  }
 */

const MIXPANEL_BRAZE_EVENT_MAP = Object.keys(CUSTOM_EVENT_CONFIG)
    .reduce((result, brazeEventName)=> {
        const brazeEventConfig = lodash.get(CUSTOM_EVENT_CONFIG, brazeEventName);

        if(lodash.get(brazeEventConfig, 'length')) {
            brazeEventConfig.forEach((brazeEventConfigEntry)=> {
                const mappings = Object.fromEntries(
                    lodash.get(brazeEventConfigEntry, 'mixpanelEventNames', [])
                        .map((mpEventName)=> {
                            const brazeEvent = {
                                eventName: brazeEventName,
                                eventProperties: lodash.get(brazeEventConfigEntry, 'brazeEventProperties'),
                            };
                            return [ mpEventName, brazeEvent ];
                        })
                );
                Object.assign(result, mappings);
            });
        }

        return result;
    }, {});

// console.log('MIXPANEL_BRAZE_EVENT_MAP', MIXPANEL_BRAZE_EVENT_MAP);


/**
 * getBrazeEvent
 *
 * This is a helper function that returns a braze formatted event
 * by mixpanel event name lookup, if configured in CUSTOM_EVENT_CONFIG
 *
 * @param  {String} mixpanelEventName
 * @param  {Object} mixpanelEventProperties
 * @return {Object} { eventName, eventProperties } Braze Custom Event name and properties to track
 */

export const getBrazeEvent = (mixpanelEventName, mixpanelEventProperties)=> {
    const {
        eventName = null,
        eventProperties = null,
    } = MIXPANEL_BRAZE_EVENT_MAP[mixpanelEventName] || {};

    return {
        eventName,
        eventProperties,
    };
};

