import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import LRModal from '../../components/LRModal';

import ListingSelection from './ListingSelection';
import SubmitSuccess from './SubmitSuccess';

export default ({ match })=> {
    const [closing, setClosing] = useState(false);
    if(closing) {
        return <Redirect to="/boards"/>;
    }
    return (
        <LRModal onClose={ ()=> setClosing(true) }>
            <Route
                exact
                component={ListingSelection}
                path={`${match.path}`} />
            <Route
                component={SubmitSuccess}
                path={`${match.path}/success`} />
        </LRModal>
    );
};
