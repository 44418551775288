// eslint-disable-next-line
import Logger from 'js-logger';
import { flow, decorate, observable, action } from 'mobx';
import _ from 'lodash';
import Lockr from 'lockr';
import { portalApi } from '../../apis';

const logger = Logger.get('RateFarmStore');

export class RateFarmStore {
    address = null;
    loading = null;
    addressInfo = null;
    selectedPin = null;
    compareRate = null;
    loadingUser = null;
    loadedUser = null;
    user = null;
    activeSubscription = null;
    leadSubmitted = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.leadSubmitted = !!Lockr.get('rate-farm-submitted-lead');
    }

    async fetchRates(address) {
        if(address === this.address && this.addressInfo) {
            return;
        }

        // fetch rates
        this.loading = true;
        this.address = address;
        this.compareRate = null;

        try {
            const response =  await portalApi.getAddressRates(encodeURI(this.address));
            this.addressInfo = response;

            if(this.addressInfo.targetProperty && this.addressInfo.targetProperty.loanData.interestrate) {
                this.compareRate = this.addressInfo.targetProperty.loanData.interestrate;
            }
        } catch (err) {
            if(err.message === 'Request failed with status code 404') {
                this.addressInfo = { properties: [] };
            } else {
                logger.error(err);
            }
        }

        this.loading = false;
    }

    getUser = flow(function*(userId) {
        if(!this.user || this.user.id !== userId) {
            this.loadingUser = true;

            this.user = yield portalApi.getAffiliateUser(userId);
            const subscriptionType = 'Rate Farm';
            const subscriptionStatus = yield portalApi.getSubscriptionStatus(userId, subscriptionType);
            this.activeSubscription = subscriptionStatus.active;
        } else {
            return;
        }

        this.loadedUser = true;
        this.loadingUser = false;
    });
}

decorate(RateFarmStore, {
    // variables
    address: observable,
    loading: observable,
    addressInfo: observable,
    selectedPin: observable,
    compareRate: observable,
    user: observable,
    loadingUser: observable,
    loadedUser: observable,
    activeSubscription: observable,
    leadSubmitted: observable,
    // actions
    fetchRates: action,
});

export default new RateFarmStore();
