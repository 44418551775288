import { portalApi } from 'apis';
import { useQuery } from 'react-query';
import { SUBSCRIPTION_ROBIN_ACCESS, SUBSCRIPTION_HOME_REPORT_LEAD_GEN } from '../../../constants';

export function useGetSubscriptionStatus(userId, subscriptionType) {
    return useQuery(
        ['subscription-status', userId, subscriptionType],
        function() {
            return portalApi.getSubscriptionStatus(userId, subscriptionType);
        }, {
            enabled: Boolean(userId),
        }
    );
}

export function useHasRobinAccess(userId, options) {
    const { data: homeReportSubscriptionResponse } = useGetSubscriptionStatus(
        userId,
        SUBSCRIPTION_HOME_REPORT_LEAD_GEN
    );
    const { data: robinAccessSubscriptionResponse } = useGetSubscriptionStatus(
        userId,
        SUBSCRIPTION_ROBIN_ACCESS
    );

    const { active: isHomeReportSubscribed = false } = homeReportSubscriptionResponse || {};
    const { active: isRobinAccessActive = false } = robinAccessSubscriptionResponse || {};
    return isHomeReportSubscribed || isRobinAccessActive;
}
