import Logger from 'js-logger';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore, useActiveBoard } from '../../mobx-store';
import { Container, Paper, Grid, Hidden, Tab, Tabs, useTheme } from '@material-ui/core';
import { ThemeProvider, withTheme} from '@material-ui/styles';
import styled from 'styled-components';
import { useResponsive } from '../../hooks/use-responsive';
import { tabsTheme } from '../../theme';
import { TabPanel } from './utils';
import BuyerInfo from './BuyerInfo';
import TaskManagement from './TaskManagement';
import BoardSummary from './BoardSummary';
import Properties from './Properties';
import { Switch, Route, useHistory, useRouteMatch, Redirect } from 'react-router-dom';
import { BoardCardModal } from '../BoardCardModal';
import ListingsMap from '../ListingsMap';
import BoardActivity from './BoardActivity';
import PinspirationBoardModal from 'scenes/PinspirationBoardModal';
import analytics from 'services/analytics';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { TourDetailsModal } from 'components/ScheduledToursModal/TourDetailsModal';

const logger = Logger.get('OverviewPage');

const BorderedContainer = withTheme(styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    background-color: white;
`);

const todoTab = {
    label: 'To-do',
    Component: TaskManagement,
};

const tabs = [
    {
        label: 'Properties',
        Component: Properties,
    },
    {
        label: 'Summary',
        Component: BoardSummary,
    },
    {
        label: 'Activity',
        Component: BoardActivity,
    },
];

function TabsView() {
    const theme = useTheme();
    const { UserStore } = useStore();
    const [tabIndex, setTabIndex] = useState(0);

    let tabsToShow = tabs;
    if(!UserStore.isLO){
        tabsToShow = [todoTab, ...tabsToShow];
    }

    function handleTabChange(event, newValue) {
        setTabIndex(newValue);
    }

    return (
        <Paper
            style={{
                backgroundColor: 'white',
            }}
        >
            <ThemeProvider theme={tabsTheme}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    style={{
                        borderBottom: '1px solid',
                        borderColor: theme.palette.lr_colors.border_grey,
                    }}
                >
                    {tabsToShow.map(({ label })=> (
                        <Tab key={label} label={label} 
                            style={{
                                textTransform: 'none',
                            }}
                        />
                    ))}
                </Tabs>
            </ThemeProvider>
            {tabsToShow.map(({label, Component }, index)=> (
                <TabPanel key={label} value={tabIndex} index={index}>
                    <Component />
                </TabPanel>
            ))}
        </Paper>
    );
}

const OverviewRoutes = observer(function OverviewRoutes() {
    const {boardsStore, UserStore} = useStore();
    const { user } = UserStore;
    const activeBoard = useActiveBoard();
    const match = useRouteMatch();
    const history = useHistory();
    const {
        boardCollaborations,
        updateBoardCollaboration,
        addBoardCollaboration
    } = useBoardCollaborations(activeBoard?.id);
    const tours = (boardCollaborations || []).filter((tour)=> tour.type === 'schedule_viewing_request');

    if(!boardsStore.loaded) {
        return null;
    }

    async function cancelTour(e, tour) {
        const content = {
            cancelled: {
                cancelledAt: new Date().toISOString(),
                userId: user.id,
            }
        };

        try {
            await updateBoardCollaboration({
                id: tour.id,
                data: {
                    content: content
                }
            });
        } catch (err) {
            logger.error('cancelTour -> Error', err);
            return;
        }

        try {
            await addBoardCollaboration({
                boardId: activeBoard.id,
                itemId: tour.id,
                content: {
                    ...tour.content,
                    ...content,
                },
                model: 'BoardCollaboration',
                type: 'schedule_viewing_cancelled',
                visibleToAffiliate: true,
                visibleToLO: false,
            });
        } catch (err) {
            logger.warn('cancelTour -> Create cancelled board collaboration -> Error', err);
        }

        history.push(match.url);
    }

    function handleModalClose() {
        history.push(match.url);
    }

    return (
        <Switch>
            <Route path={`${match.url}/cards/:cardId`} render={()=> <BoardCardModal open={true} onClose={handleModalClose} />} />
            <Route path={`${match.url}/lane/:laneId/:laneSlug/cards/:cardId/:addressSlug`} render={()=> <BoardCardModal open={true} onClose={handleModalClose} />} />
            <Route path={`${match.url}/archivedListings/:cardId`} render={()=> <BoardCardModal open={true} onClose={handleModalClose} />} />
			
            <Route
                path={`${match.url}/lane-expanded/:laneId/:laneSlug`}
                render={()=> <ListingsMap onClose={handleModalClose} />}
            />
            <Route
                path={`${match.url}/listpack/:listpackId/listings`}
                render={()=> <ListingsMap onClose={handleModalClose} />}
            />

            <Route
                path={`${match.url}/idea-board`}
                render={()=> <PinspirationBoardModal onModalClose={handleModalClose} />}
            />

            <Route 
                path={`${match.url}/scheduled-tours/:tourId`}
                render={()=> <TourDetailsModal
                    open={true}
                    tours={tours}
                    appBarHeaderProps={{
                        closeCTAText: 'Overview'
                    }}
                    onClose={()=> {
                        if(match) history.push(match.url); 
                    }}
                    onTourChange={(e, tour)=> {
                        if(match) history.push(`${match.url}/scheduled-tours/${tour.id}`); 
                    }}
                    onCancelTour={cancelTour}
                />}
            />
        </Switch>
    );
});

const OverviewPage = observer(function OverviewPage() {
    const theme = useTheme();
    const r = useResponsive();
    const { boardsStore, UserStore } = useStore();

    useEffect(()=> {
        analytics.eventTrack('robin_overview_page_view');
    }, []);

    if(UserStore.isConsumer) {
        return <Redirect to="/boards" />;
    }

    return (
        <div
            style={{
                height: '100%',
                overflow: 'auto',
            }}
        >
            <Container
                disableGutters={true}
                maxWidth="lg"
                style={{
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(3),
                    overflowX: 'hidden',
                    paddingLeft: r({xs: 0, md: theme.spacing(2)}),
                    paddingRight: r({xs: 0, md: theme.spacing(2)}),
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs>
                        <div className="space-y-4">
                            <BuyerInfo />
                            <Hidden smDown>
                                {!UserStore.isLO && (
                                    <BorderedContainer>
                                        <TaskManagement />
                                    </BorderedContainer>
                                )}
                                <BorderedContainer>
                                    <Properties />
                                </BorderedContainer>
                            </Hidden>
                            <Hidden mdUp>
                                <TabsView />
                            </Hidden>
                        </div>
                    </Grid>
                    <Hidden smDown>
                        <Grid item>
                            <div
                                className="space-y-4"
                                style={{
                                    width: '360px',
                                }}
                            >
                                <BorderedContainer>
                                    <BoardSummary />
                                </BorderedContainer>
                                <BorderedContainer>
                                    <BoardActivity />
                                </BorderedContainer>
                            </div>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
            <OverviewRoutes />
        </div>
    );
});

export default OverviewPage;
