import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { MinimizeRounded, AddRounded } from '@material-ui/icons';
import FieldsTable from './FieldsTable';
import NotesTable from './NotesTable';
import { withStyles } from '@material-ui/core/styles';
import { useCompareNowStoreContext } from '../../store';

const StyledExpansionPanel = withStyles({
    root: {
        boxShadow: 'none',
        position: 'relative',
    }
})(ExpansionPanel);
const StyledExpansionPanelDetails = withStyles({
    root: {
        padding: '0px'
    }
})(ExpansionPanelDetails);

const StyledExpansionPanelSummary = withStyles({
    root: {
        padding: '0px',
        margin: '0px',
        minHeight: '54px !important',
        '&$expanded': {
        },
    },
    content: {
        margin: '0px !important',
        '&$expanded': {
            margin: '0px !important'
        }
    },
    expandIcon: {
        position: 'absolute',
        zIndex: 1,
        right: '0px',
    }
})(ExpansionPanelSummary);

function CategorySection({ category, fields, listings, initCollapsed = true }) {
    const [collapsed, setCollapsed] = useState(initCollapsed);
    const [expandIcon, setExpandIcon] = useState(initCollapsed ? <AddRounded /> : <MinimizeRounded />);
    const store = useCompareNowStoreContext();

    const toggleExpand = function() {
        if(collapsed) {
            setExpandIcon(<MinimizeRounded />);
            setCollapsed(false);
        } else {
            setExpandIcon(<AddRounded />);
            setCollapsed(true);
        }
    };

    return (
        <Box>
            <Box padding="0px 46px">
                <StyledExpansionPanel expanded={!collapsed} onChange={toggleExpand} square={true}>
                    <StyledExpansionPanelSummary
                        expandIcon={
                            !initCollapsed ?
                                <Box pt={collapsed ? '20px' : '0px'} pb={!collapsed ? '20px' : '0px'}>
                                    {expandIcon}
                                </Box> :
                                expandIcon
                        }
                    >
                        <Box width="100%">
                            <Box
                                key={category}
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="stretch"
                                height={!initCollapsed ? 64 : 54}
                                color="lr_colors.grade"
                            >
                                <Box display="flex" flex="1" alignItems={!initCollapsed ? 'flex-end' : 'center'}>
                                    <Box fontSize="20px" fontWeight="fontWeightMedium" pl="16px" minWidth="85px" mb={!initCollapsed ? '6px' : '0px'}>
                                        {category}
                                    </Box>
                                </Box>
                                <Box flex="1" />
                            </Box>
                        </Box>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                        <Box width="100%" padding="0px">
                            {category !== 'Notes' && <FieldsTable fields={fields} listings={listings} />}
                            {category === 'Notes' &&
                                <Box position="relative" top="-54px">
                                    <NotesTable listings={listings} />
                                </Box>
                            }
                        </Box>
                    </StyledExpansionPanelDetails>
                </StyledExpansionPanel>
            </Box>
            {collapsed && category !== 'Notes' && <Box ml="46px" mr="46px" borderBottom="1px solid #E3E3E3" />}
        </Box>
    );
}

CategorySection.propTypes = {
    category: PropTypes.string,
    fields: PropTypes.array,
    listings: PropTypes.array,
    initCollapsed: PropTypes.bool
};

export default CategorySection;
