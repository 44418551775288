import _ from 'lodash';
import { Hidden } from '@material-ui/core';
import { useActiveBoard } from 'mobx-store';
import React from 'react';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { Header } from './header';
import { Footer } from './footer';
import analytics from 'services/analytics';
import addressSlugger from 'services/address-slugger';
import { useStore } from 'mobx-store';

export default function PropertyDetailLayout({ card, listing, onClose, trackEventData, showNavigation=true, children, requestSource, listings }) {
    const history = useHistory();
    const activeBoard = useActiveBoard();
    const match = useRouteMatch();
    const { LanesStore } = useStore();
    const { UserStore } = useStore();

    const laneCards = (listings) ? listings : card?.archived
        ? activeBoard?.archivedBoardCards
        : activeBoard?.notArchivedBoardCards?.filter((bc)=> String(bc.columnId) === String(card?.columnId));

    const cardIndex = laneCards?.findIndex((bc)=> bc.id === card?.id);

    function handlePreviousClick() {
        analytics.eventTrack('rpp_previous_button_click', {
            card_id: card.id,
        });
        const newCard = laneCards[cardIndex - 1];
        const targetLane =  LanesStore.lanesById[newCard.columnId];
        const previousPath = generatePath(match.path, {
            cardId: newCard.id,
            laneId: targetLane.id,
            laneSlug: targetLane.name,
            // the plus space is a hack since we are lazy loading
            // the listing details thus we might not have the address
            address: addressSlugger(newCard.listing_details?.address) + ' '
        });

        analytics.eventTrack('listpacks_consumer_listing_page_viewed', {
            address: newCard.listing_details?.address,
            'listing id': newCard.id,
            listing_id: card?.content?.listing_id,
            source: requestSource,
            role: UserStore.usersBoardRole,
        });

        history.push(previousPath);
    }

    function handleNextClick() {
        analytics.eventTrack('rpp_next_button_click', {
            card_id: card.id,
        });
        const newCard = laneCards[cardIndex + 1];
        const targetLane = LanesStore.lanesById[newCard.columnId];
        const nextPath = generatePath(match.path, {
            cardId: newCard.id,
            laneId: targetLane.id,
            laneSlug: targetLane.name,
            // the plus space is a hack since we are lazy loading
            // the listing details thus we might not have the address
            address: addressSlugger(newCard.listing_details?.address) + ' '
        });

        analytics.eventTrack('listpacks_consumer_listing_page_viewed', {
            address: newCard.listing_details?.address,
            'listing id': newCard.id,
            listing_id: card?.content?.listing_id,
            source: requestSource,
            role: UserStore.usersBoardRole,
        });

        history.push(nextPath);
    }

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header
                card={card}
                listing={listing}
                index={cardIndex}
                total={laneCards && laneCards.length}
                onPrevious={handlePreviousClick}
                onNext={handleNextClick}
                onClose={onClose}
                trackEventData={trackEventData}
                showNavigation={showNavigation}
            />
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                {children}
            </div>
            <Hidden mdUp>
                <Footer
                    card={card}
                    listing={listing}
                    index={cardIndex}
                    total={laneCards && laneCards.length}
                    onPrevious={handlePreviousClick}
                    onNext={handleNextClick}
                    trackEventData={trackEventData}
                    showNavigation={showNavigation}
                />
            </Hidden>
        </div>
    );
}
