import React from 'react';
import lodashGet from 'lodash/get';
import _ from 'lodash';
import addressSlugger from '../../services/address-slugger';
import { LANES_NAMES } from '../../constants';
import {
    ChatBubble,
    CalendarToday,
    LocalAtm,
} from '@material-ui/icons';
import { TourRounded } from '../../components/LRIcons';

const getLaneName = (notification)=> {
    const lanes = {
        'maybes': LANES_NAMES['interested'],
        'favorites': LANES_NAMES['see'],
        'make-an-offer': LANES_NAMES['buy'],
    };

    const notificationLane = lodashGet(notification, 'content.toColumn');
    return (lanes[_.kebabCase(notificationLane)]) ? lanes[_.kebabCase(notificationLane)] : notificationLane;
};

const getCardName = (notification)=> {
    return lodashGet(notification, 'content.card_name') ||
        lodashGet(notification, 'content.address') ||
        'a listing';
};

const getCardUrl = (notification)=> {
    const cardId = lodashGet(notification, 'objectId');
    const address = lodashGet(notification, 'content.address');
    return `/boards/cards/${cardId}/${addressSlugger(address)}`;
};

const getHero = (notification)=> (lodashGet(notification, 'content.image_url'));
const getImageUrls = (notification)=> {
    const activities = lodashGet(notification, 'activities') || [];
    return activities
        .map((a)=> lodashGet(a, 'content.image_url'))
        .filter((v)=> v);
};

const notificationConfig = {
    user_joined: {
        text: (notification, currentUserId)=> (
            (lodashGet(notification, 'user.id') === currentUserId)
                ? (<p>Welcome to Robin, <b>{lodashGet(notification, 'user.name')}</b>!</p>)
                : (<p><b>{lodashGet(notification, 'user.name')}</b> has joined the board. Welcome!</p>)
        ),
        cta: ()=> '/boards/account/co-buyers',
        trackEventName: (notification)=> (lodashGet(notification, `content.user_types.${lodashGet(notification, 'user.id')}`) === 'buyer')
            ? 'agent_to_buyer_robin_invite_accepted'
            : 'buyer_to_cobuyer_robin_invite_accepted',
    },
    sent_invite: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> invited{' '}
                <b>{lodashGet(notification, 'content.first_name') || lodashGet(notification, 'content.invitee_first_name')}{' '}
                    {lodashGet(notification, 'content.last_name') || lodashGet(notification, 'content.invitee_last_name')}</b> to the board.
            </p>
        ),
        cta: ()=> '/boards/account/co-buyers',
        trackEventName: 'buyer_to_cobuyer_robin_invite',
    },
    comment: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> added a note on <b>
                    {getCardName(notification)}</b>.
            </p>
        ),
        icon: ()=> (<ChatBubble/>),
        cta: getCardUrl,
        trackEventName: 'rpp_note_added',
    },
    reaction: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> liked a note on <b>
                    {getCardName(notification)}</b>.
            </p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'rpp_note_liked',
    },
    preferences_change:{
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> updated preferences: <b>
                    {notification?.content?.updated_preferences}
                </b>
            </p>
        ),
    },
    moved_card: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> moved <b>
                    {getCardName(notification)}</b> to{' '}
                <b>{getLaneName(notification)}</b>.
            </p>
        ),
        cta: getCardUrl,
        trackEventName: 'robin_card_moved',
    },
    schedule_viewing_request: {
        text: (notification, currentUserId)=> (
            (lodashGet(notification, 'user.id') === currentUserId)
                ? null
                : (
                    <p>
                        <b>{lodashGet(notification, 'user.name')}</b> created a new home tour request:{' '}
                        <b>&ldquo;{lodashGet(notification, 'content.tour_name')}&rdquo;</b>. It has been sent{' '}
                        to {lodashGet(notification, 'content.agent.first_name')} for scheduling.
                    </p>
                )
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        icon: ()=> (<TourRounded/>),
    },
    schedule_viewing_confirmed: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'content.agent.first_name')}</b> scheduled the{' '}
                <b>&ldquo;{lodashGet(notification, 'content.tour_name')}&rdquo;</b> tour for{' '}
                {lodashGet(notification, 'content.scheduled_date_time_formatted')}.
            </p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        icon: ()=> (<TourRounded/>),
        trackEventName: 'robin_home_tour_scheduled',
    },
    schedule_viewing_cancelled: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> cancelled an in-person home tour:{' '}
                <b>&ldquo;{lodashGet(notification, 'content.tour_name')}&rdquo;</b>.
            </p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        icon: ()=> (<TourRounded/>),
        trackEventName: 'robin_home_tour_cancelled',
    },
    schedule_viewing_request_comment: {
        text: (notification, currentUserId)=> (
            <p>
                <b>{lodashGet(notification, `user.${
                    ['agent', 'lo'].indexOf(
                        lodashGet(notification, `content.user_types.${
                            lodashGet(notification, 'user.id')
                        }`)) > -1
                        ? 'first_name'
                        : 'name'
                }`)}</b>{' '}
                added a new note to {lodashGet(notification, 'content.tour_name')}:{' '}
                <b>&ldquo;{lodashGet(notification, 'content.message')}&rdquo;</b>.
            </p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        icon: ()=> (<TourRounded/>),
        trackEventName: 'robin_home_tour_note_added',
    },
    schedule_open_house: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> scheduled an open house viewing for{' '}
                <b>{getCardName(notification)}</b>.
            </p>
        ),
        hero: getHero,
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        icon: ()=> (<CalendarToday/>),
        trackEventName: 'robin_openhouse_scheduled',
    },
    offer_submitted: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> would like to prepare an offer for{' '}
                <b>{getCardName(notification)}</b>
            </p>
        ),
        icon: ()=> (<LocalAtm />),
        trackEventName: 'robin_submit_offer',
        cta: getCardUrl,
    },
    pinspiration_image: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> pinned
                {(lodashGet(notification, 'activity_count', 0) > 1) ? ` ${notification.activity_count} photos` : ' a photo' }.
            </p>
        ),
        hero: getHero,
        imageUrls: getImageUrls,
        cta: ()=> ('/boards/idea-board'),
        trackEventName: 'pinspiration_pinned_photo',
    },
    created_saved_search: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> added a saved search{' '}
                <b>{lodashGet(notification, 'content.listpack_name')}</b> to the board.
            </p>
        ),
        cta: (notification)=> (`/boards/listpack/${lodashGet(notification, 'objectId')}/listings`),
        trackEventName: 'robin_savedsearch_added',
    },
    updated_saved_search: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> updated filters on the{' '}
                <b>{lodashGet(notification, 'content.listpack_name')}</b> saved search.
            </p>
        ),
        cta: (notification)=> (`/boards/listpack/${lodashGet(notification, 'objectId')}/listings`),
        trackEventName: 'robin_savedsearch_prefs_updated',
    },
    archived_saved_search: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> removed the saved search{' '}
                <b>{lodashGet(notification, 'content.listpack_name')}</b> from the board.
            </p>
        ),
        trackEventName: 'robin_savedsearch_removed',
    },
    created_card: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> added
                {(notification?.activity_count > 1) ? ` ${notification.activity_count} new homes to ` : ' a new home to '}
                <b>{lodashGet(notification, 'content.column_name')}</b>.
            </p>
        ),
        cta: getCardUrl,
        trackEventName: 'added_new_home_manually',
    },
    deleted_card: {
        text: (notification, userId, trim)=> {
            return (
                <p>
                    <b>{lodashGet(notification, 'user.name')}</b> removed
                    <b>{` ${notification.content.card_name} ` }</b>
                    from the board.
                    {
                        notification.content.message && (
                            <b>{` "${notification.content.message.length > 20 && trim ? notification.content.message.slice(0, 20) + '...' : notification.content.message}"`}</b>
                        )
                    }
                </p>
            );
        },
        cta: (notification)=> {
            return `/boards/archivedListings/${lodashGet(notification, 'objectId')}`;
        },
        trackEventName: 'deleted_home',
        hero: getHero,
    },
    deleted_all_homes_in_lane: {
        text: (notification)=> {
            return (
                <p>
                    <b>{lodashGet(notification, 'user.name')}</b> deleted all the homes in the <b>{lodashGet(notification, 'content.laneName')}</b> column.  You can find them in your “Deleted homes” under your “Saved searches”.”
                </p>
            );
        },
        cta: ()=> {
            return `/boards/archivedListings`;
        },
        trackEventName: 'robin_deleted_all_homes_in_lane',
    },
    new_agent_recommended_homes: {
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'content.agent.name')}</b> recommended new homes. View homes.
            </p>
        ),
        cta: (notification)=> `/boards/listpack/${lodashGet(notification, 'objectId')}/listings`,
        imageUrls: getImageUrls,
        trackEventName: 'agent_recommended_homes',
    },
    user_removed: {
        text: (notification)=> (
            <p><b>{lodashGet(notification, 'content.name')}</b> was removed from the board.</p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'robin_cobuyer_removed',
    },
    user_left: {
        text: (notification)=> (
            <p><b>{lodashGet(notification, 'content.name')}</b> left the board.</p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'robin_cobuyer_left_board',
    },
    listpack_new_listings: {
        text: (notification)=> (
            <p>Check out the new listings in your <b>{lodashGet(notification, 'content.listpack_name')}</b> saved search.</p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'robin_new_listings_in_saved_search',
        hero: getHero,
        avatar: false,
    },
    updated_buy_timeframe:{
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> updated their buying timeframe {notification?.content?.old_timeframe && (
                    <span>from <b>
                        {notification?.content?.old_timeframe}
                    </b> </span>
                )}to <b>{notification?.content?.new_timeframe}</b>.
            </p>
        ),
    },
    updated_my_home_address:{
        text: (notification)=> (
            <p>
                <b>{lodashGet(notification, 'user.name')}</b> added their home address to Home Report to check their home valuation.
            </p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
    },
};

export default notificationConfig;
