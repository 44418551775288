import React, { useState, useRef } from 'react';

import { Box, Dialog, Snackbar, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Close } from '@material-ui/icons';
import { useTheme, withTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Lockr from 'lockr';

import { useStore } from '../../../../../mobx-store';
import LRButton from '../../../../LRButton';
import { DEFAULT_HEADSHOT } from '../../../../../constants';
import { TextField } from '../../../../TextField';
import { portalApi } from '../../../../../apis';
import { LRPhoneInput } from '../../../../LRPhoneInput';
import analytics from '../../../../../services/analytics';
import html2canvas from 'html2canvas';
import Logger from 'js-logger';
import LRLink from '../../../../LRLink';
import { useResponsiveBreakpoint } from '../../../../../hooks';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const logger = Logger.get('LeadForm');

// ${({ theme })=> theme.breakpoints.up('sm')} {
//     height: calc(100vh - 64px);
// }

const StyledDialog = withTheme(styled(Dialog)`
 
& .MuiDialog-paperScrollPaper {
    min-height: calc(100vh - 64px);
}


    
`);

const CustomLRButton = withTheme(styled(LRButton)`
    width: 100%;
    height: 48px;

    &.Mui-disabled {
        background-color: ${({ theme, color })=> theme.palette[color].main};
        color: ${({ theme, color })=> theme.palette[color].contrastText};
        opacity: 0.5;
    }
`);

const AgentAvatar = styled(Box)`
    background-image: url("${(props)=> (props.headshot ? props.headshot : DEFAULT_HEADSHOT)}");
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    width: 64px;
    height: 64px;
`;

const LeadFormModal = observer(({ open = false, window = false, toggleRateModal = ()=> void 0, headerTitle, agentText, source, onLeadSubmitted })=> {
    const { RateFarmStore } = useStore();
    const user = RateFarmStore.user || {};

    const loadingUser = RateFarmStore.loadingUser;
    const loadedUser = RateFarmStore.loadedUser;
   
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;

    const rfhMethods = useForm();
    const { register, handleSubmit, errors, formState } = rfhMethods;
    const [submittingLead, setSubmittingLead] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showLOCompliance, setShowLOCompliance] = useState(false);
    
    const formRef = useRef(null);

    async function takeSnapshot() {
        const el = document.body;
        const canvas = await html2canvas(el, { useCORS: true });
        const snapshotImg = canvas.toDataURL();
        const snapshotHtml = document.documentElement.outerHTML;
        return [snapshotImg, snapshotHtml];
    }

    const submitLead = async (data)=> {
        const leadData = {
            applicant: {
                name: data.name,
                email: data.email,
                ...(data.phone && { phone: data.phone }),
            },
            ownerId: user.id,
            source: 'Rate Farm',
        };

        const response = await portalApi.createLead(user, leadData);
        const addedLead = response.createdNewLead;
        const lead = response.data;
        const latestSearchedAddress = Lockr.get('rate-farm-latest-searched-address');
        const variation = addedLead? 'new_lead' : 'new_message';
        
        let snapshotImg, snapshotHtml;
        try {
            [snapshotImg, snapshotHtml] = await takeSnapshot();
        } catch (err) {
            logger.error(err);
        }

        await portalApi.sendUserLeadSubmit(user, lead, variation, data.message, latestSearchedAddress, snapshotImg, snapshotHtml);

        analytics.eventTrack('rate_farm_consumer_lead_form_submit', {
            distinct_id: user.id,
            Source: source,
            Lead_Name: lead.applicant.name,
            Lead_Email: lead.applicant.email,
            ...(lead.applicant.phone && { Lead_Phone: lead.applicant.phone }),
            Lead_Message: data.message,
        });

        Lockr.set('rate-farm-submitted-lead', true);
        RateFarmStore.leadSubmitted = true;

        return lead;
    };

    async function onLeadSubmit(data) {
        if(!loadedUser || loadingUser) {
            return;
        };

        setSubmittingLead(true);
        data.phone = (data.phone || '').replace(/[^0-9]/g, '');

        try {
            await submitLead(data);
            setShowSuccessSnackbar(true);
            if(onLeadSubmitted) {
                onLeadSubmitted();
            }
            Lockr.set('rate-farm-submitted-lead', true);
        } catch (error) {
            setShowErrorSnackbar(true);
        } finally {
            setSubmittingLead(false);
            setTimeout(()=> toggleRateModal(null, true), 2000);
        }
    }

    const maxWidth = isMobile ? false : 'xs';

    return (
        <StyledDialog open={open} fullScreen={isMobile} maxWidth={'xs'}>
            <Box zIndex="1" boxShadow={'0px 3px 12px -2px rgba(0, 0, 0, 0.2)'} bgcolor="white">
                <Box
                    display="flex"
                    position="relative"
                    px={4} py={2}
                >
                    <Box width={50} style={{
                        cursor: 'pointer',
                        visibility: showLOCompliance ? 'visible' : 'hidden'
                    }} onClick={()=> {setShowLOCompliance(false);}}>
                        <ArrowBackIosIcon />
                    </Box>
                    <Box flex="1" display="flex" justifyContent="center" fontSize="body1.fontSize">{headerTitle}</Box>
                    <Box width={50}>
                        {/* <Box
                        component={Close}
                        top="50%"
                        style={{
                            transform: 'translate(0, -50%)',
                            cursor: 'pointer',
                        }}
                        right={0}
                        mx={4}
                        position="absolute"
                        onClick={toggleRateModal}
                    /> */}
                    </Box>
                    
                </Box>
            </Box>
            <Box flex={1} overflow="auto">
                {
                    showLOCompliance ? (
                        <Box p={4} position="relative">
                            <span>
                                {`${user?.vendor?.compliance_text || ''} `}
                                This is an advertisement and not an offer to enter into an agreement. Not all customers will qualify. All products are subject to credit and property approval. Other restrictions and limitations apply. Equal 
                                Housing Lender. 
                            </span>
                            
                            <Box position="absolute" right={48} zIndex={1}>
                                <img src="/images/equal-housing-logo.png" alt="equal-housing-logo" />
                            </Box>

                        </Box>
                    ) :
                        (
                            <Box component="form" ref={formRef} onSubmit={handleSubmit((data)=> onLeadSubmit(data))} noValidate height="100%">
                                <Box px={4} pt={3} overflow="auto">
                                    <Snackbar
                                        open={showSuccessSnackbar || showErrorSnackbar}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        message={
                                            <Box width="330px" textAlign="center">
                                                {showSuccessSnackbar && 'Message sent!'}
                                                {showErrorSnackbar && 'An error occurred. Please try again later.'}
                                            </Box>
                                        }
                                        autoHideDuration={2000}
                                        onClose={()=> {
                                            setShowErrorSnackbar(false);
                                            setShowSuccessSnackbar(false);
                                        }}
                                    />

                                    <Box display="flex" flexDirection={'column'}>
                                        <Box mb={0} mt={0} width="fit-content">
                                            <Box display="flex" alignItems="center">
                                                <AgentAvatar headshot={user.headshot} />
                                                <Box ml="9px" textAlign="left">
                                                    <Box fontWeight="500" fontSize="18px">
                                                        {user.name || 'Lenny Lender'}
                                                    </Box>
                                                    <Box fontSize="16px">{user.phone || '714-555-1234'}</Box>
                                                </Box>
                                            </Box>

                                            <Box fontSize="18px" mt={'20px'} textAlign="left">
                                                {agentText}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box mt="27px">
                                                <TextField
                                                    name="name"
                                                    label="Name"
                                                    inputProps={{ height: '48px', width: '100%' }}
                                                    inputRef={register({
                                                        required: true,
                                                        validate: (value)=> value.split(' ').length >= 2,
                                                    })}
                                                    message={errors.name && 'Please provide a first and last name.'}
                                                    tone={errors.name && 'error'}
                                                    required
                                                />
                                                <Box mt="22px">
                                                    <TextField
                                                        name="email"
                                                        label="Email"
                                                        type="email"
                                                        inputProps={{ height: '48px', width: '100%' }}
                                                        inputRef={register({
                                                            required: true,
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                message: 'invalid email address',
                                                            },
                                                        })}
                                                        message={errors.email && 'Please provide a valid email.'}
                                                        tone={errors.email && 'error'}
                                                        required
                                                    />
                                                </Box>
                                                <Box mt="22px">
                                                    <LRPhoneInput
                                                        rfhMethods={rfhMethods}
                                                        label="Phone" name="phone"
                                                        inputProps={{ height: '48px', width: '100%' }}
                                                    />
                                                </Box>
                                                <Box mt="22px" mb={2}>
                                                    <TextField
                                                        name="message"
                                                        label="Message"
                                                        inputProps={{
                                                            height: '96px',
                                                            width: '100%',
                                                            component: 'textarea',
                                                            padding: '12px',
                                                            maxLength: '1000',
                                                            style: { resize: 'none', outline: 'none' },
                                                        }}
                                                        inputRef={register}
                                                    />
                                                </Box>
                                            </Box>{' '}
                                        </Box>
                                    </Box>

                                    <Box mt={3} color="#A1A1A1" fontSize="12px" pb={3}>
                                        {`By clicking Submit, you consent to receive emails, calls, and texts from ${user.name} and ListReports about your inquiry.`}
                                        <span>You agree to ListReports <LRLink native target="_blank" href="https://welcome.listreports.com/terms-conditions">Terms of Service</LRLink> and <LRLink native target="_blank" href="https://welcome.listreports.com/privacy-policy">Privacy Policy</LRLink>. Msg/data rates may apply.</span>
                                        <br /><br />
                                        {`${user.first_name}'s NMLS #${user.realtor_license_number} `}<LRLink onClick={()=> {
                                            setShowLOCompliance(true);
                                        }}>learn more</LRLink>.
                                    </Box>
                                </Box>
                            </Box>
                        )
                }
            </Box>
            
            {
                !showLOCompliance && (
                    <Box 
                        py={window? 0 : 1.5}
                        px={window? 0 : 2}
                        bgcolor={window? null : '#F9F9F9'}
                        borderTop={window? null : '1px solid #DDDDDD'}
                    >
                        <CustomLRButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                            disabled={
                                submittingLead ||
                                !formState.dirtyFields.has('name') ||
                                !formState.dirtyFields.has('email')
                            }
                            style={{ width: '100%', height: '48px' }}
                            onClick={()=> {
                                formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
                            }}
                        >
                            {!submittingLead && <Box>Submit</Box>}
                            {submittingLead && <CircularProgress size={24} color="secondary" />}
                        </CustomLRButton>
                    </Box>
                )
            }
            

        </StyledDialog>
    );
});

export { LeadFormModal };
