import {
    CircularProgress,
    Collapse,
    Grid,
    Hidden,
    IconButton,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import LRButton from 'components/LRButton';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme, { MuiPrimaryBlackTheme } from 'theme';
import { Section, SectionContainer, SectionHeader, SectionTitle } from '../Section';
import GoogleMapReact from 'google-map-react';
import { DoubleArrowRounded, HomeRounded } from '@material-ui/icons';
import numAbbr from 'number-abbreviate';
import _, { filter } from 'lodash';
import Logger from 'js-logger';
import addCommas from 'services/add-commas';
import analytics from 'services/analytics';
import titleCase from 'titlecase';
import { useMyHome, useMyHomeAVM } from 'hooks/useMyHome';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useQuery } from 'hooks';
import LRDropdown from 'components/LRDropdown';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { LRListingDetailsModal } from 'components/LRListingDetailsModal';
import { ModalRoute } from 'components/ModalRoute';
import { SOLD_STATUSES, PENDING_STATUSES } from '../../../constants';

const MapWrapper = styled.div`
    height: 336px;
    position: relative;

    & .gm-bundled-control-on-bottom div div {
        border-radius: 8px !important;
    }
`;
const INITIAL_MAX_LISTINGS = 7;

/**
 * This lives here because the colors will only be used for this component.
 * Use this to change the colors throughout the components.
 *
 * @returns {Object}
 */
function useListingStatusColors() {
    const theme = useTheme();

    return {
        colorForStatus: (status)=> {
            const stLower = status.toLowerCase();
            if(['active', 'new'].includes(stLower)) {
                return theme.palette.lr_colors.minty_fresh;
            }
            if(PENDING_STATUSES.includes(stLower)) {
                return theme.palette.lr_colors.charlie;
            }
            if(SOLD_STATUSES.includes(stLower)){
                return theme.palette.lr_colors.brick;
            }
        },
        colors: {
            sold: theme.palette.lr_colors.brick,
            active: theme.palette.lr_colors.minty_fresh,
            pending: theme.palette.lr_colors.charlie,
            new: theme.palette.lr_colors.minty_fresh,
        }
    };
}

function MapPin({ color = '#000', icon, price, style = {}, ...props }) {
    const abbrPrice = useMemo(() => {
        const p = parseInt(price);
        return numAbbr(price, p >= 1000000 ? 2 : 0);
    }, [price]);

    return (
        <div
            style={{
                position: 'relative',
                ...style,
            }}
            {...props}
        >
            <div
                style={{
                    position: 'absolute',
                    bottom: 7,
                }}
            >
                <div
                    style={{
                        padding: 4,
                        borderRadius: 4,
                        background: '#fff',
                        width: '100%',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        position: 'relative',
                        marginLeft: '-50%',
                    }}
                >
                    <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                        <Grid
                            container
                            item
                            justify="center"
                            alignItems="center"
                            style={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                backgroundColor: color,
                                color: '#fff',
                                textAlign: 'center',
                                marginRight: 4,
                            }}
                        >
                            <Grid item>{icon}</Grid>
                        </Grid>

                        <Grid item xs>
                            <Typography variant="body2" align={price ? 'right' : 'center'} style={{ fontWeight: 500 }}>
                                {price ? `$${abbrPrice}` : 'N/A'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <div
                        style={{
                            display: 'block',
                            height: 10,
                            width: 10,
                            backgroundColor: '#fff',
                            position: 'absolute',
                            left: 'calc(50% - 5px)',
                            bottom: -5,
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
                            transform: 'rotate(-45deg)',
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

function ListingTableRow({ listing, index, onCompareClick = (e, listing) => {}, ...props }) {
    const match = useRouteMatch();
    const { myHome } = useMyHome();
    const { colorForStatus } = useListingStatusColors();
    const mode = useQuery().get('mode');

    return (
        <TableRow {...props}>
            <TableCell style={{ paddingLeft: 0 }}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: colorForStatus(listing.listing_status),
                        color: '#fff',
                        textAlign: 'center',
                    }}
                >
                    <Grid item>
                        <Typography variant="caption" style={{ position: 'relative', top: -1 }}>
                            {index}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <LRButton
                    variant="text"
                    color="inherit"
                    underline
                    inline
                    component={Link}
                    to={`${match?.url}/listings/${listing.id}/${listing.address}`}
                >
                    {titleCase(listing?.street_address?.toLowerCase())}
                </LRButton>
            </TableCell>
            <TableCell>{(listing?.distance && `${Math.round(listing.distance * 100) / 100} mi`) || '--'}</TableCell>
            <TableCell>{listing?.beds || '--'}</TableCell>
            <TableCell>{listing?.baths || '--'}</TableCell>
            <TableCell>{(listing?.home_size && addCommas(listing?.home_size)) || '--'}</TableCell>
            <TableCell>{(listing.price && `$${addCommas(listing.price)}`) || '--'}</TableCell>
            <TableCell style={{ paddingRight: 0 }}>
                {mode !== 'ro' && myHome && (
                    <LRButton
                        onClick={(e) => onCompareClick(e, listing)}
                        variant="text"
                        inline
                        underline
                        color="inherit"
                    >
                        Compare
                    </LRButton>
                )}
            </TableCell>
        </TableRow>
    );
}

function ListingsTable({
    listings,
    showMoreToggle = false,
    selectedNeighborhood,
    onShowMoreToggle = (e) => {},
    onCompareClick = (e, listing) => {},
    ...props
}) {
    const { myHome } = useMyHome();
    const avm = useMyHomeAVM();
    const theme = useTheme();
    const mode = useQuery().get('mode');

    return (
        <TableContainer
            {...props}
            style={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                ...(props.styles || {}),
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Distance</TableCell>
                        <TableCell>Beds</TableCell>
                        <TableCell>Baths</TableCell>
                        <TableCell>Sq ft</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {/* My Home */}
                    {!!myHome?.address && (
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Grid item>
                                        {<HomeRounded style={{ fontSize: 14, position: 'relative', top: 1 }} />}
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell>{titleCase(myHome?.address?.split(',')?.[0]?.toLowerCase())}</TableCell>
                            <TableCell>--</TableCell>
                            <TableCell>{myHome?.bedrooms || '--'}</TableCell>
                            <TableCell>{myHome?.bathrooms || '--'}</TableCell>
                            <TableCell>{(myHome?.living_area && addCommas(myHome?.living_area)) || '--'}</TableCell>
                            <TableCell>{(avm?.estimatedValue && `$${addCommas(avm?.estimatedValue)}`) || '--'}</TableCell>
                            <TableCell style={{ paddingRight: 0 }}>
                                {mode !== 'ro' && (
                                    <LRButton onClick={onCompareClick} variant="text" inline underline color="inherit">
                                        Compare
                                    </LRButton>
                                )}
                            </TableCell>
                        </TableRow>
                    )}

                    {/* Listings */}
                    {Boolean(listings?.length) &&
                        listings
                            .slice(0, showMoreToggle ? undefined : INITIAL_MAX_LISTINGS)
                            .map((listing, i) => (
                                <ListingTableRow
                                    key={listing.id}
                                    listing={listing}
                                    index={i + 1}
                                    onCompareClick={onCompareClick}
                                />
                            ))}
                </TableBody>
            </Table>

            {listings?.length > INITIAL_MAX_LISTINGS && (
                <Grid container justify="center">
                    <Grid item style={{ padding: theme.spacing(1) }}>
                        <LRButton variant="text" color="inherit" underline onClick={onShowMoreToggle}>
                            View {showMoreToggle ? 'less' : 'more'}
                        </LRButton>
                    </Grid>
                </Grid>
            )}
        </TableContainer>
    );
}

function ListingsRow({
    listings,
    selectedNeighborhood,
    showMoreToggle = false,
    onShowMoreToggle = (e) => {},
    onCompareClick = (e, listing) => {},
}) {
    const match = useRouteMatch();
    const { myHome } = useMyHome();
    const theme = useTheme();
    const { colorForStatus } = useListingStatusColors();

    return (
        <div
            style={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            {!!myHome?.address && (
                <Grid
                    container
                    style={{
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                        borderBottom: '1px solid',
                        borderColor: theme.palette.lr_colors.border_grey,
                    }}
                >
                    <Grid item style={{ marginRight: theme.spacing(2) }}>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                backgroundColor: '#000',
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            <Grid item>{<HomeRounded style={{ fontSize: 14, position: 'relative', top: 1 }} />}</Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography variant="body2" style={{ fontWeight: 500 }}>
                            {titleCase(myHome.splitAddress.addressLine1?.toLowerCase())}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {Boolean(listings?.length) &&
                listings.slice(0, showMoreToggle ? undefined : INITIAL_MAX_LISTINGS).map((listing, i) => (
                    <Grid
                        key={listing.id}
                        container
                        style={{
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                            borderBottom: '1px solid',
                            borderColor: theme.palette.lr_colors.border_grey,
                        }}
                    >
                        <Grid item style={{ marginRight: theme.spacing(2) }}>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: '50%',
                                    backgroundColor: colorForStatus(listing.listing_status),
                                    color: '#fff',
                                    textAlign: 'center',
                                }}
                            >
                                <Grid item>
                                    <Typography variant="caption" style={{ position: 'relative', top: -1 }}>
                                        {i + 1}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs>
                            <LRButton
                                variant="text"
                                color="inherit"
                                underline
                                inline
                                component={Link}
                                to={`${match?.url}/listings/${listing.id}/${listing.address}`}
                            >
                                <Typography variant="body2">
                                    {titleCase(listing?.street_address?.toLowerCase())}
                                </Typography>
                            </LRButton>
                            <Typography variant="body2">
                                {listing?.beds || 'N/A'} Bd • {listing?.baths || 'N/A'} Ba •{' '}
                                {listing?.home_size ? addCommas(listing.home_size) : 'N/A'} Sq Ft • $
                                {addCommas(listing.price) || 'N/A'}
                            </Typography>

                            {myHome && (
                                <div>
                                    <LRButton
                                        onClick={(e) => onCompareClick(e, listing)}
                                        variant="text"
                                        inline
                                        underline
                                        color="inherit"
                                    >
                                        Compare
                                    </LRButton>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                ))}

            {listings?.length > INITIAL_MAX_LISTINGS && (
                <Grid container justify="center">
                    <Grid item style={{ padding: theme.spacing(1) }}>
                        <LRButton variant="text" color="inherit" underline onClick={onShowMoreToggle}>
                            View {showMoreToggle ? 'less' : 'more'}
                        </LRButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export function NearbyProperties({
    hideEnterAddressCta,
    onEnterYourAddressClick = (e) => {},
    onCompareClick = (e, source) => {},
    setPropertyLabel,
    marketActivity,
    selectedNeighborhood,
    setSelectedNeighborhood,
    openAddNeighborhoodModal,
    openSignUpModal,
    ...props
}) {
    const match = useRouteMatch();
    const history = useHistory();
    const { isIPadProDown, isIPadUp, isMobile } = useDeviceBreakpoints();
    const { myHome: home, areaIds, isDataLocal } = useMyHome();
    const avm = useMyHomeAVM();
    const { colorForStatus, colors } = useListingStatusColors();

    const [toggleButtons, legendOptions] = useMemo(
        () => {
            const legendOptions = [
                {label: 'Active', color: colors.active},
                {label: 'Pending', color: colors.pending},
            ];
            const toggleButtons = [
                {label: 'All', value: 'all'},
                {label: 'Active', value: 'Active'},
                {label: 'Pending', value: 'Pending'},
            ];
            const hasSoldListings = _.some(marketActivity?.data || [], { listing_status: 'Sold' });
            if(hasSoldListings){
                legendOptions.push({label: 'Sold', color: colors.sold});
                toggleButtons.push({label: 'Sold', value: 'Sold'});
            }
            return [toggleButtons, legendOptions];
        },
        [marketActivity]
    );

    const [selectedToggleButton, setSelectedToggleButton] = useState(toggleButtons[0].value);
    const [openLegend, setOpenLegend] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [gmaps, setGmaps] = useState();
    const [map, setMap] = useState();
    const ADD_NEW = '+ Add new';
    const filteredListings = useMemo(
        () =>
            selectedToggleButton === 'all'
                ? marketActivity?.data || []
                : filter(marketActivity?.data || [], { listing_status: selectedToggleButton }),
        [marketActivity, selectedToggleButton]
    );
    const streetAddress = home?.address ? titleCase(home?.address?.split(',')?.[0]?.toLowerCase()) : null;

    const neighborhoodAreasOptions = useMemo(()=> {
        let areasValueLabel = _.map(areaIds || [],(area)=> ({value: area, label: area}));
        return [
            ...(home?.address ? [{value: home?.zipcode, label: streetAddress}] : []),
            ...areasValueLabel
        ];
    },[areaIds, home, streetAddress]);
    
    const maxAreasReached = _.size(neighborhoodAreasOptions) >= 10;

    //
    // UseEffects

    useEffect(() => {
        if (!gmaps || !filteredListings?.length) return;

        const bounds = new gmaps.LatLngBounds();

        if (filteredListings?.length) {
            filteredListings.slice(0, showMore ? undefined : INITIAL_MAX_LISTINGS).forEach((listing) => {
                bounds.extend(new gmaps.LatLng(listing.latitude, listing.longitude));
            });
        }

        if(home?.address && selectedNeighborhood === home?.zipcode) {
            bounds.extend(new gmaps.LatLng(home.latitude, home.longitude));
        }

        map.fitBounds(bounds);
    }, [filteredListings, gmaps, map, showMore, selectedNeighborhood]);

    useEffect(() => {
        //if house is deleted update selected neighborhood
        const neighAreas = _.map(neighborhoodAreasOptions, (neigh)=> neigh.value);
        if (_.isEmpty(selectedNeighborhood) || !_.includes(neighAreas, selectedNeighborhood)) {
            setSelectedNeighborhood(neighborhoodAreasOptions[0]?.value || null);
        }
    }, [areaIds, home, neighborhoodAreasOptions, selectedNeighborhood, setSelectedNeighborhood]);

    //
    // Functions

    function onToggleButtonChange(e, value) {
        setPropertyLabel(value || toggleButtons[0].value);
        setSelectedToggleButton(value || toggleButtons[0].value);
    }

    function onToggleOpenLegend() {
        setOpenLegend(!openLegend);
    }

    function onShowMoreToggle() {
        setShowMore(!showMore);
    }

    const ToggleButtons = (
        <ThemeProvider theme={MuiPrimaryBlackTheme}>
            <ToggleButtonGroup
                value={selectedToggleButton}
                onChange={onToggleButtonChange}
                exclusive
                style={{ width: '100%' }}
            >
                {toggleButtons.map(({ label, value }) => (
                    <LRButton
                        key={value}
                        component={ToggleButton}
                        value={value}
                        style={{ height: isMobile ? 36 : 42, width: '100%', padding: 4 }}
                        className="ToggleButton"
                    >
                        {value !== 'all' && <div
                            style={{
                                marginRight: 3,
                                height: 12,
                                width: 12,
                                backgroundColor: colorForStatus(value),
                                borderRadius: '50%',
                                display: 'inline-block',
                            }}
                        />}
                        <div>{label}</div>
                    </LRButton>
                ))}
            </ToggleButtonGroup>
        </ThemeProvider>
    );

    //
    // Render

    return (
        <>
            {match && (
                <ModalRoute
                    path={`${match?.url}/listings/:listingId/:address`}
                    Component={LRListingDetailsModal}
                    ComponentProps={{
                        TransitionComponent: Slide,
                        TransitionProps: {
                            direction: 'up',
                        },
                        listings: filteredListings,
                        onClose: () => history.push(match.url),
                    }}
                    useSwitch
                />
            )}

            <Section {...props}>
                <SectionHeader style={{ marginBottom: isIPadUp && theme.spacing(2) }}>
                    <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12}>
                            <SectionTitle>Neighborhood listings</SectionTitle>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <LRDropdown
                                selectedOption={_.find(neighborhoodAreasOptions, (neigh)=> neigh.value === selectedNeighborhood)?.label}
                                options={[
                                    ...neighborhoodAreasOptions,
                                    ...(
                                        (isDataLocal && home?.address && [{label: ADD_NEW}]) //logged out logic
                                        || (!isDataLocal && !maxAreasReached && [{label: ADD_NEW}]) //logged in logic
                                        || []
                                    )
                                ]}
                                renderOption={(option)=> <div>{option.label}</div>}
                                onOptionSelect={(option) => {
                                    if(option.label === ADD_NEW) {
                                        isDataLocal
                                            ? openSignUpModal()
                                            : openAddNeighborhoodModal();
                                    } else {
                                        setSelectedNeighborhood(option.value);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6} style={{ paddingBottom: theme.spacing(2) }}>
                            {ToggleButtons}
                        </Grid>
                    </Grid>
                </SectionHeader>
                <SectionContainer style={{ padding: 0, overflow: 'hidden' }}>
                    <MapWrapper>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: 'us' }}
                            defaultZoom={16}
                            defaultCenter={[33.78724, -117.85496]}
                            center={map?.center?.lat() ? [map.center.lat(), map.center.lng()] : [33.78724, -117.85496]}
                            options={{
                                draggable: true,
                                zoomControl: true,
                                fullscreenControl: false,
                                disableDefaultUI: true,
                                gestureHandling: isIPadProDown && 'cooperative',
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => {
                                setMap(map);
                                setGmaps(maps);
                            }}
                        >
                            {filteredListings
                                ?.slice(0, showMore ? undefined : INITIAL_MAX_LISTINGS)
                                ?.map((listing, i) => (
                                    <MapPin
                                        key={listing.id}
                                        lat={listing.latitude}
                                        lng={listing.longitude}
                                        price={listing.price}
                                        color={colorForStatus(listing.listing_status)}
                                        icon={<Typography variant="caption">{i + 1}</Typography>}
                                        onClick={() =>
                                            history.push(`${match.url}/listings/${listing.id}/${listing.address}`)
                                        }
                                    />
                                ))}

                            {!!home?.zipcode && home?.zipcode === selectedNeighborhood && (
                                <MapPin
                                    lat={home.latitude}
                                    lng={home.longitude}
                                    price={avm?.estimatedValue}
                                    icon={<HomeRounded style={{ fontSize: 14, position: 'relative', top: 1 }} />}
                                />
                            )}
                        </GoogleMapReact>

                        {/* Enter address messaging */}
                        {_.isEmpty(neighborhoodAreasOptions) && (
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                            >
                                <Grid
                                    item
                                    style={{
                                        padding: theme.spacing(2),
                                        paddingTop: theme.spacing(1.5),
                                        paddingBottom: theme.spacing(1.5),
                                        background: '#000',
                                        color: '#fff',
                                    }}
                                    onClick={analytics.eventTrackOnEvent(
                                        'robin_home_report_nearby_properties_enter_address_link_click',
                                        null,
                                        onEnterYourAddressClick
                                    )}
                                >
                                    <Typography variant="body2">
                                        <span role="img" aria-label="house emoji">
                                            🏠
                                        </span>
                                        &nbsp; <span style={{ textDecoration: 'underline' }}>Enter your address</span>{' '}
                                        to view nearby homes.
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {/* Legend */}
                        <div
                            style={{
                                position: 'absolute',
                                bottom: theme.spacing(3),
                                right: 68,
                                backgroundColor: '#fff',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                borderRadius: 8,
                                padding: theme.spacing(1),
                                paddingTop: theme.spacing(0.5),
                                paddingBottom: theme.spacing(0.5),
                                width: '80px',
                                maxHeight: '80px',
                            }}
                        >
                            <Grid container alignItems="center" justify="space-between" style={{ maxHeight: '18px' }}>
                                <Grid item xs>
                                    <Typography variant="caption">Legend</Typography>
                                </Grid>

                                <Grid item>
                                    <IconButton
                                        size="small"
                                        style={{ position: 'relative', top: openLegend ? -2 : 0 }}
                                        edge="end"
                                        onClick={onToggleOpenLegend}
                                    >
                                        <DoubleArrowRounded
                                            style={{
                                                height: 8,
                                                width: 8,
                                                transform: `rotate(${openLegend ? 90 : -90}deg)`,
                                                color: theme.palette.lr_colors.steak_sauce,
                                            }}
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Collapse in={openLegend}>
                                {legendOptions.map((legend, i) => (
                                    <Grid
                                        container
                                        alignItems="center"
                                        key={`${legend.label}-${i}`}
                                        style={{ height: 17 }}
                                    >
                                        <Grid item style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <div
                                                style={{
                                                    width: 12,
                                                    height: 12,
                                                    backgroundColor: legend.color,
                                                    borderRadius: '50%',
                                                    marginRight: '4px',
                                                }}
                                            ></div>
                                        </Grid>

                                        <Grid item xs style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <Typography variant="caption" style={{ fontWeight: 500, fontSize: '10px' }}>
                                                {legend.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}

                                <div style={{ height: theme.spacing(0.5) }}></div>
                            </Collapse>
                        </div>
                    </MapWrapper>

                    {marketActivity.isLoading && (
                        <Grid container justify="center" style={{ padding: theme.spacing(3) }}>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}

                    <Hidden xsDown>
                        <Collapse in={!marketActivity.isLoading}>
                            <ListingsTable
                                home={home}
                                listings={filteredListings}
                                onShowMoreToggle={onShowMoreToggle}
                                showMoreToggle={showMore}
                                selectedNeighborhood={selectedNeighborhood}
                                onCompareClick={analytics.eventTrackOnEvent(
                                    'robin_home_report_nearby_properties_compare_link_click',
                                    null,
                                    onCompareClick
                                )}
                            />
                        </Collapse>
                    </Hidden>

                    <Hidden smUp>
                        <Collapse in={!marketActivity.isLoading}>
                            <ListingsRow
                                home={home}
                                listings={filteredListings}
                                onShowMoreToggle={onShowMoreToggle}
                                showMoreToggle={showMore}
                                selectedNeighborhood={selectedNeighborhood}
                                onCompareClick={analytics.eventTrackOnEvent(
                                    'robin_home_report_nearby_properties_compare_link_click',
                                    null,
                                    onCompareClick
                                )}
                            />
                        </Collapse>
                    </Hidden>
                </SectionContainer>
            </Section>
        </>
    );
}
