import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import styled from 'styled-components/macro';
import addCommas from '../../../../services/add-commas';
import { Info } from '@material-ui/icons';
import { COLOR_SALMON, COLOR_GREY_MEDIUM, COLOR_OFF_WHITE } from '../../../../constants';
import _ from 'lodash';
import moment from 'moment';
import { useCompareNowStoreContext } from '../../store';

const TableContainer = styled.div`
    display: flex;
    min-height: 48px;
    font-size: 16px;
    border-top: solid 1px ${COLOR_GREY_MEDIUM};
    justify-content: flex-start;
`;

const TableHeader = styled(TableContainer)`
    height: 40px;
    color: #3a3a3a;
    background-color: ${COLOR_OFF_WHITE};
    align-items: center;
    padding-left: 16px;
`;

const StyledB = styled.b`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 15px 16px;
`;

const renderFieldsByType = {
    money: (value)=> {
        return '$' + addCommas(value);
    },
    string: (value)=> {
        return value;
    },
    date: (value)=> {
        return moment()
            .diff(moment(value), 'days');
    },
    number: function(value) {
        return addCommas(value);
    },
    boolean: function(value) {
        return value ? 'Yes' : 'No';
    },
    array: function(value) {
        return value.join(', ');
    },
    associationFee: function(value) {
        var feeObj = _.cloneDeep(value);
        if(feeObj.value != null) {
            feeObj = feeObj.value;
            feeObj = addCommas(feeObj);
        }
        if(value.currencyPeriod) {
            feeObj = feeObj + ' ' + value.currencyPeriod;
        }
        return '$' + feeObj;
    }
};

const Value = ({ value })=> {
    return (
        <Box width="50%">
            <StyledB>{value}</StyledB>
        </Box>
    );
};

function SideBySide({ attribute, processedListings, userPreference }) {
    const renderValueFn = renderFieldsByType[attribute.type];
    const hasMoreThanOneListing = processedListings.length > 1;
    var value1 = processedListings[0][attribute.keyName];
    var value2 = _.get(processedListings[1], attribute.keyName);
    value1 = value1 == null ? '--' : renderValueFn(value1);
    value2 = value2 == null ? '--' : renderValueFn(value2);

    return (
        <Box position="relative">
            <TableHeader>
                <span>{attribute.name}</span>
            </TableHeader>
            <TableContainer>
                <Value value={value1}/>
                {hasMoreThanOneListing && <Box borderRight="1px solid #E0E0E0" min-height={6} />}
                {hasMoreThanOneListing && <Value value={value2}/>}
            </TableContainer>
        </Box>
    );
}

SideBySide.propTypes = {
    attribute: PropTypes.object,
    processedListings: PropTypes.array,
    userPreference: PropTypes.object
};

export default SideBySide;
