import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Box, useTheme, Typography, CircularProgress, FormHelperText } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';

import LRButton from '../../../../components/LRButton';
import analytics from '../../../../services/analytics';
import LRLink from '../../../../components/LRLink';
import LRDropdown from '../../../../components/LRDropdown';
import { useStore } from '../../../../mobx-store';
import { Modal, StyledLRInput } from './style';
import { useRFHInput, useResponsiveBreakpoint } from '../../../../hooks';
import { NOTIFY_OPTIONS } from './constants';
import { OnboardingVideoModal } from 'components/OnboardingVideoModal';
import { useOnboarding } from 'components/Onboarding';
import { simulateClick } from 'components/Onboarding/utils';
import { useAbility } from '../../../../components/Ability';
import { useBoardListpacks } from 'hooks/useBoardListpacks';

export const SytledDropdownOption = withTheme(styled.div`
    width: 100%;
    cursor: pointer;
    padding: 12px 0;
    border-bottom: 1px solid ${({ theme })=> theme.palette.lr_colors.grey_medium_second };
`);

export const SytledDropdownContentOption = withTheme(styled.div`
    width: 100%;
    padding: 16px 16px 12px;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme })=> theme.palette.lr_colors.costa_mesa};
    }
`);

export const SavedFooter = withTheme(styled.div`
    padding-top: ${({ theme })=> theme.spacing(3)};
    display: flex;
    width:  100%;
    justify-content: flex-end;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        justify-content: space-between;
    }
`);

function DropdownOption({ label, desc }) {
    return (
        <SytledDropdownOption>
            <SytledDropdownContentOption>
                <Typography variant="body1"> {label}</Typography>
                <Typography variant="body1" style={{color: '#a1a1a1'}}> {desc}</Typography>
            </SytledDropdownContentOption>
        </SytledDropdownOption>
    );
};

function SelectOption({ label}) {
    return <div style={{ cursor: 'pointer' }}>{label}</div>;

};


export function SaveFilter({
    openSaveModal,
    setSaveOpenModal,
    saveTrackEvent,
    setSaveTrackEvent,
    areas,
    isLoadingListings = false,
    nameState
}) {
    const { UserStore, boardsStore } = useStore();
    const [ showLoading, setShowLoading ] = useState(false);
    const { user } = UserStore;
    const { activeBoard } = boardsStore;
    const { createOrUpdateListpack } = useBoardListpacks();
    const theme = useTheme();
    const match = useRouteMatch();
    const formMethods = useFormContext();
    const history = useHistory();
    const listpackId = match?.params.listpackId;
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const onboarding = useOnboarding();
    const ability = useAbility();
    const canEditUser = ability.can('edit', 'User');
    const {nameStatus, setNameStatus} = nameState;
    const shouldOnboardingPreventAction =  isMobile
        && onboarding?.isFirstRunOnboarding
        && ['tour-listpack-area-filters'].includes(onboarding.stepId);

    async function onSubmit(data) {
        setShowLoading(true);
        setSaveTrackEvent('header');

        const {notification_settings, ...rest} = data;
        const opt = {[user.id]: notification_settings.new_listings};
        const notifications = {new_listings:{email: opt, text: opt, push: opt}};
        const payload = {
            userId: user.id,
            boardId: activeBoard.id,
            notification_settings: notifications,
            ...rest,
        };

        if(listpackId) { payload.id = listpackId; };

        const listpack = await createOrUpdateListpack(payload);

        analytics.eventTrack('robin_board_listpack_created', {
            'alert_frequency': data.notification_settings.new_listings ,
            source: saveTrackEvent,
            'listpack_id': listpack?.id,
            'listpack_name': listpack?.name,
            'listpack_area_ids': (listpack?.areaIds || []).join('|'),
            'keyword_filter_set': listpack.fields.keywords.length > 0,
            'keywords_used': listpack.fields.keywords,
        });

        await UserStore.updateUser({ meta: { async_onboarding: { created_saved_searches: true } } });

        history.replace(`/boards/listpack/${listpack.id}/listings`);

        if(onboarding && onboarding.isNurtureFirstRunOnboarding ){
            // onboarding.next();
            onboarding.tour.show('edit-option-highlight', true);
        }

        if(onboarding?.isFirstRunOnboarding) {
            if(!['free-step'].includes(onboarding.stepId)){
                onboarding.next();
            }
        }
    }

    const { value, handleChange } = useRFHInput('name', formMethods);
    const { value: notificationPeriod, handleChange: handleNotificationPeriod } = useRFHInput('notification_settings.new_listings', formMethods);

    function handleSearchName(event) {
        if(!nameStatus){
            setNameStatus(true);
        }

        handleChange(event.target.value);
    }

    const selectedOption = useMemo(()=> {
        const notification = (notificationPeriod) ? notificationPeriod : 'daily';

        return NOTIFY_OPTIONS.find((option)=> {
            return option.value === notification;
        });
    }, [notificationPeriod]);

    return (
        <>
            <Modal
                className="SaveSearchModal"
                open={openSaveModal}
                hideCloseButton={true}
                onClose={()=> {
                    setSaveOpenModal(false);
                    setSaveTrackEvent('header');
                }}
                disableBackdropClick={onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding}
                disableEscapeKeyDown={onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding}
                dialogContentClasses={{ root: 'SaveSearchModal-DialogContent-root tour-save-search-modal' }}
            >
                <>
                    <Typography variant="h6" mb={2} style={{ marginBottom: theme.spacing(2) }}> Save search</Typography>
                    <Typography mb={2} variant="body1" style={{ fontWeight: 500, marginBottom: theme.spacing(2) }}> ✏️ Let’s give your search a name </Typography>

                    <Box mb={3}>
                        <StyledLRInput
                            type="text"
                            name="name"
                            variant="outlined"
                            defaultValue={(nameStatus) ? value : (areas.length === 1) ? `${areas[0].value} Homes`  : ''}
                            onChange={handleSearchName}
                            inputRef={formMethods.register({ required: true })}
                            fullWidth={true}
                            {
                                ...(!formMethods.errors.name ? {} : {
                                    error: true,
                                    helperText: 'Please provide a name to your search.'
                                })
                            }
                        />
                    </Box>

                    <Typography mb={2} variant="body1" style={{ fontWeight: 500, marginBottom: theme.spacing(2) }}> ⏰️️  Notify me of new listings: </Typography>
                    <Box mb={3}>
                        {canEditUser ? (
                            <LRDropdown
                                selectedOption={selectedOption}
                                onOptionSelect={(option)=> {
                                    handleNotificationPeriod(option.value);
                                }}
                                options={NOTIFY_OPTIONS}
                                renderDisplayOption={SelectOption}
                                renderOption={DropdownOption}
                                DropdownDisplayProps={{ pl: '12px', width: '144px', height: '48px',  }}
                                dropdownOptionProps={{ py:0, px: 0}}
                                dropdownMenuProps={ (isMobile) ? {} : {width:'440px'}}
                                disabled={!canEditUser}
                            />
                        ) : (
                            <Box
                                width="144px"
                                border={`1px solid ${theme.palette.lr_colors.border_grey}`}
                                height="48px"
                                display="flex"
                                alignItems="center"
                                paddingLeft="12px"
                                borderRadius="4px"
                            >
                                Daily
                            </Box>
                        )}

                    </Box>

                    <SavedFooter>
                        {/* {!onboarding?.isFirstRunOnboarding && ( */}
                        <LRLink
                            mr={2}
                            height={36}
                            display= "inline-flex"
                            alignItems="center"
                            onClick={()=> {
                                setSaveOpenModal(false);
                                if(onboarding?.isFirstRunOnboarding && !['free-step'].includes(onboarding.stepId)) {
                                    const { tour } = onboarding;
                                    tour.show('highlight-listpack-listing-card', true);
                                }

                                if(onboarding && onboarding.isNurtureFirstRunOnboarding ){
                                    const { tour } = onboarding;
                                    tour.show('rpp-nurture-skip', true);
                                }
                            }}

                            style={{ textDecoration: 'underline',  color: theme.palette.lr_colors.grade}}
                        >
                            Skip
                        </LRLink>
                        {/* )} */}

                        <LRButton
                            className="tour-saved-search"
                            type="button"
                            variant="contained"
                            color="primary"
                            width={56}
                            height={36}
                            disabled={showLoading}
                            onClick={formMethods.handleSubmit(onSubmit)}
                        >
                            {showLoading ? <CircularProgress size={24} color="secondary" /> : 'Save'}
                        </LRButton>
                    </SavedFooter>
                    {formMethods.errors.areaIds && (
                        <FormHelperText
                            style={{paddingTop: theme.spacing(1), display:"flex", width: "100%", justifyContent: "flex-end"}}
                            error={true}
                        >
                            Please provide an Area.
                        </FormHelperText>
                    )}
                </>
            </Modal>
            <LRButton
                className="tour-save-listpack-search"
                type="button"
                variant="contained"
                color="primary"
                disabled={(onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && isLoadingListings}
                style={{height: '36px', padding: '6px 10px', width: '100px'}}
                onClick={(e)=> {
                    if(onboarding && onboarding.isNurtureFirstRunOnboarding ){
                        setSaveOpenModal(true);
                        analytics.eventTrack('robin_onboarding_search_area_guide_save_search_CTA');
                        onboarding.tour.show('save-search-modal', true);
                        return;

                    }
                    if(!shouldOnboardingPreventAction && !onboarding?.needsToExpandSearch) {
                        setSaveOpenModal(true);
                        if(onboarding && !['free-step'].includes(onboarding?.stepId)){
                            onboarding.next();
                        }
                    }

                    if(onboarding?.needsToExpandSearch) {
                        const tour = onboarding.tour;
                        const optionalStep = tour.getById('optional-expand-search');

                        optionalStep.updateStepOptions({
                            showOn: function() {
                                return onboarding.needsToExpandSearch;
                            }
                        });

                        simulateClick('.tour-listpack-areas-filter > div > div');
                        tour.show('optional-expand-search', false);
                    }
                }}
            >
                {(onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && isLoadingListings ? <CircularProgress size={24} color="white" /> : 'Save search'}
            </LRButton>
        </>
    );
}
