import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { COLOR_GRADE, COLOR_CREST_WHITE, COLOR_STEAK_SAUCE } from '../../constants';

export const AddAnyHomeHeaderStyle = styled(Box)`
    background-color: ${COLOR_CREST_WHITE};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    width: 100%;
`;

export const AddAnyHomeHeaderLabel = styled(Box)`
    color: ${COLOR_GRADE};
`;


export const AddAnyHomeContent = styled(Box)`
    display: flex;
`;

export const AddAnyHomeAddressLinkContainer = styled(Box)`
    display: flex;
    border-radius: 4px;
    background-color: ${COLOR_CREST_WHITE};
`;

export const AddAnyHomeAddressLinkMainText = styled(Box)`
    color: ${COLOR_GRADE};
    font-weight: 500;
    font-size: 24px;
    display: flex;
`;

export const AddAnyHomeAddressLinkSecondaryText = styled(Box)`
    color: ${COLOR_GRADE};
    font-size: 16px;
`;

export const AddAnyHomeAddressRequired = styled(Box)`
    color: ${COLOR_STEAK_SAUCE};
    font-size: 14px;
    display: flex;
`;



