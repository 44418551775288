// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState } from 'react';
import styled from 'styled-components';
import LRModal from '../../LRModal';
import { AppBar, Typography, Box, Divider, IconButton } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import Overview from '../Overview';
import { Close } from '@material-ui/icons';
import { useResponsiveBreakpoint } from '../../../hooks';

const Header = styled.div`
    margin: -24px -16px 0;
`;
const StyledAppBar = withTheme(styled(AppBar)`
    background-color: #fff;
    color: ${({ theme })=> theme.palette.text.primary};
    box-shadow: -2px -16px 25px 0px rgba(0, 0, 0, 0.75);
`);
const AppBarSection = styled(Box)`
    padding: 20px;
`;
const CloseButton = styled(IconButton)`
    position: absolute;
    top: 13px;
    right: 10px;
    color: #000;
`;
const StyledLRModal = withTheme(styled(LRModal)`
    width: 100%;

    .mortgage-calculator-modal-dialog-content {
        padding: 8px 24px;

        ${({ theme })=> theme.breakpoints.up('sm')} {
            padding: 8px 24px;
        }
    }
`);

function MortgageCalculatorModal({ children, ...props }) {
    const isXs = useResponsiveBreakpoint('xs');
    const [closeBtnRef, setCloseBtnRef] = useState();
    const [isScrolled, setIsScrolled] = useState(false);

    function handleOnScroll(e) {
        setIsScrolled(e.currentTarget.scrollTop > 0);
    }

    return (
        <StyledLRModal
            closeButtonRef={closeBtnRef}
            hideBackdrop={isXs}
            onScroll={handleOnScroll}
            dialogContentClasses={{ root: 'mortgage-calculator-modal-dialog-content' }}
            {...props}
        >
            <>
                <Header>
                    <StyledAppBar position="absolute" isscrolled={`${isScrolled}`}>
                        <AppBarSection>
                            <Typography component="h1" variant="h6" align="center">
                                Monthly Payment
                            </Typography>

                            <CloseButton ref={setCloseBtnRef}>
                                <Close />
                            </CloseButton>
                        </AppBarSection>

                        <Divider />

                        <AppBarSection>
                            <Overview />
                        </AppBarSection>
                    </StyledAppBar>
                </Header>
                <Box marginTop="220px">{children}</Box>
            </>
        </StyledLRModal>
    );
}

export default React.memo(MortgageCalculatorModal);
