import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

const confettiKeyframes = ({ animatedLeft })=> keyframes`
    100% {
        top: 110%;
        left: ${animatedLeft}%
    }
`;

const confettiAnimation = ({ duration1, duration2, iterationCount })=> css`
    ${confettiKeyframes} ${duration1}s ${duration2}s ${iterationCount};
`;

const AnimatedConfetti = styled.div.attrs(
    ({
        width,
        color,
        topOffset,
        left,
        rotation,
        skew,
        animationDelay,
        duration1,
        duration2,
        animationLeft
    })=> ({
        style: {
            width: width,
            height: width * 0.4,
            backgroundColor: color,
            top: `-${topOffset + 10}%`,
            left: `${left}%`,
            transform: `rotate(${rotation}deg) skew(${skew}deg)`,
            animationDelay: `${animationDelay}s`,
        },
    })
)`
    animation: ${confettiAnimation};
    animation-timing-function: linear;
    position: absolute;
`;

function random(to, from = 1) {
    return Math.floor(Math.random() * to) + from;
}

const colors = ['#d13447', '#ffbf00', '#263672'];

const SingleConfetti = React.memo(function SingleConfetti({ number, iterationCount }) {
    const width = random(12);
    const color = _.sample(colors);
    const left = random(100);
    const topOffset = random(100);
    const animatedLeft = left + random(15);
    const rotation = Math.random() * 360;
    const skew = random(10);
    const duration1 = 4 + Math.random();
    const duration2 = Math.random();
    const animationDelay = (number / 100) * Math.random();
    return (
        <AnimatedConfetti
            width={width}
            color={color}
            left={left}
            topOffset={topOffset}
            animatedLeft={animatedLeft}
            rotation={rotation}
            skew={skew}
            duration1={duration1}
            duration2={duration2}
            animationDelay={animationDelay}
            iterationCount={iterationCount}
        />
    );
});

const createArray = _.memoize(function createArray(numberOfElements) {
    return new Array(numberOfElements).fill(null);
});

function Confetti({ onClick, numberOfSingleConfettis = 300, iterationCount = 'infinite' }) {
    const array = createArray(numberOfSingleConfettis);
    return (
        <div
            onClick={onClick}
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 10000,
                pointerEvents: 'none',
            }}
        >
            {array.map((value, index)=> (
                <SingleConfetti key={index} number={index} iterationCount={iterationCount} />
            ))}
        </div>
    );
}

Confetti.propTypes = {
    numberOfSingleConfettis: PropTypes.number,
    iterationCount: PropTypes.string,
    onClick: PropTypes.func,
};

export default Confetti;
