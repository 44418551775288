// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useEffect, useState, Fragment, useCallback, useRef, useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import BRAND_ICONS from '../../../services/brand-icons';
import { useResponsiveBreakpoint } from '../../../hooks';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    Box,
    ExpansionPanelDetails,
    ButtonBase,
    CircularProgress,
} from '@material-ui/core';
import ListingImage from '../../ListingImage';
import SectionTitle from '../SectionTitle';
import {
    ExpandMore,
    Place,
    Home,
} from '@material-ui/icons';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import { useStore } from '../../../mobx-store';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import InfographicsMapModal from '../../../scenes/InfographicsMap';
import analytics from '../../../services/analytics';
import LRButton from '../../LRButton';
import { InfographicsCategoryIcon } from 'components/InfographicsCategoryIcon';
import { useListingPlaceCategoriesWithClosestPlace } from 'hooks/data/listing/useListingPlaceCategoriesWithClosestPlace';
import mockData from 'components/Listing/AreaReport/mock';
import { COLOR_GREY_NERO, COLOR_BORDER_GREY_LIGHTER, COLOR_GREY_DARK } from '../../../constants';
import { useListingSchoolData } from 'hooks/data/listing/useListingSchoolData';

const logger = Logger.get('AreaReport'); 
const Container = styled.div`
    box-sizing: initial;
`;

const StyledButton = styled(LRButton)`
    color: ${COLOR_GREY_NERO};
`;

const StyledExpansionPanel = styled(({ className, ...props })=> (
    <ExpansionPanel classes={{ root: className }} {...props} />
))`
    box-shadow: none;
    border-radius: 0 !important;
    padding-bottom: 0;
`;
const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
    padding: 0;
    min-height: 0 !important;

    & .MuiExpansionPanelSummary-content {
        margin: 0 !important;
    }
    & .MuiIconButton-root {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

const StyledSectionTitle = styled(SectionTitle)`
    margin: 0;
    line-height: 1;
`;

const StyleExpandMoreIcon = styled(ExpandMore)`
    color: #000;
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
    margin-top: 24px;
    display: block;
    padding: 0;
`;

const pulseKeyFrames = keyframes`
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 98, 89, .85);
    box-shadow: 0 0 0 0 rgba(255, 98, 89, .85);
  }
  
  70% {
    -webkit-box-shadow: 0 0 0 1em rgba(255, 98, 89, 0);
    box-shadow: 0 0 0 1em rgba(255, 98, 89, 0);
  }
  
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 98, 89, 0);
    box-shadow: 0 0 0 0 rgba(255, 98, 89, 0);
`;

const AreaIcon = withTheme(styled(Box)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid #f0f0f0;
    padding: 8px;
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;

    ${({ theme, showAnimation })=>
        showAnimation === true
            ? css`
                  animation: ${pulseKeyFrames} 2s 5;
              `
            : ''};

    & .area-icon-svg {
        font-size: 3.5rem;
    }

    &:hover {
        ${({ theme, hover })=>
        hover !== 'none'
            ? `border-color: ${theme.palette.primary.main};
                box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4)`
            : ''};
    }

    ${({ theme })=> theme.breakpoints.down('sm')} {
        width: 48px;
        height: 48px;
        margin-left: auto;
        margin-right: auto;
        border: 6px solid #f0f0f0;
        & .area-icon-svg {
            font-size: 2rem;
        }
    }
`);

const AreaPipe = withTheme(styled(Box)`
    width: 36px;
    height: 8px;
    background-color: #f0f0f0;
    position: relative;
    top: 52px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: 36px;
        width: 200%;
        margin-left: -20px;
        margin-right: -20px;
    }

    ${({ theme })=> theme.breakpoints.only('sm')} {
        margin-left: -40px;
        margin-right: -40px;
    }
`);

const AreaPipeV = styled(Box)`
    width: 8px;
    height: 40px;
    background-color: #f0f0f0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: -1;
`;

const FirstIconDropShadow = withTheme(styled(Box)`
    background-color: transparent;
    width: 112px;
    height: 112px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -62px;
    margin-top: -56px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: auto;
        right: auto;
        bottom: -40px;
        left: 19px;
        width: 80px;
        height: 80px;
    }
`);

const Distance = withTheme(styled(Box)`
    margin-top: 12px;
    ${({ theme })=> theme.breakpoints.down('sm')} {
        margin-top: 8px;
    }
    font-weight: 500;
    color: #a1a1a1;
`);

const PipeWrapper = withTheme(styled(Box)`
    position: relative;
    width: 48px;
    min-width: 48px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        width: 20px;
        min-width: 20px;
    }
`);
const RightDownPipe = withTheme(styled(Box)`
    border-top: 8px solid #f0f0f0;
    border-right: 8px solid #f0f0f0;
    border-top-right-radius: 35px;
    width: 40px;
    height: 150px;
    position: absolute;
    top: 52px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: 36px;
        width: 20px;
        border-top-right-radius: 20px;
    }
`);
const RightUpPipe = withTheme(styled(Box)`
    border-bottom: 8px solid #f0f0f0;
    border-right: 8px solid #f0f0f0;
    border-bottom-right-radius: 35px;
    width: 40px;
    height: 150px;
    position: absolute;
    top: -98px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: -114px;
        width: 20px;
        border-bottom-right-radius: 20px;
    }
`);
const LeftDownPipe = withTheme(styled(Box)`
    border-top: 8px solid #f0f0f0;
    border-left: 8px solid #f0f0f0;
    border-top-left-radius: 35px;
    width: 40px;
    height: 150px;
    position: absolute;
    left: 5px;
    top: 52px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: 36px;
        width: 20px;
        border-top-left-radius: 20px;
    }
`);
const LeftUpPipe = withTheme(styled(Box)`
    border-bottom: 8px solid #f0f0f0;
    border-left: 8px solid #f0f0f0;
    border-bottom-left-radius: 35px;
    width: 40px;
    height: 130px;
    position: absolute;
    left: 5px;
    top: -94px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        top: -93px;
        width: 20px;
        border-bottom-left-radius: 20px;
    }
`);
const Medal = withTheme(styled(Box)`
    max-width: 112px;
    min-height: 200px;
    z-index: 1;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        min-height: 180px;
    }
`);

const StyledLogoImage = withTheme(styled.img`
    height: 56px;
    filter: grayscale(100%);

    ${({ theme })=> theme.breakpoints.down('sm')} {
        height: 32px;
    }
`);

const MedalHeroImage = withTheme(styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        width: 50px;
        height: 50px;
    }
`);
const Marker = styled.div``;
const MarkerIconWrapper = styled(Box)`
    width: 0;
    height: 0;
`;


function FetchPlaces({ listing, enabled, data, setData }) {
    const result = useListingPlaceCategoriesWithClosestPlace(enabled ? listing : null);

    useEffect(() => {
        if(_.isEqual(data, result)) return;

        setData(result);
    }, [result]);

    return <div />;
}


function AreaReport({
    listingDetails,
    defaultExpanded = true,
    heroImage,
    embeded,
    buyerAgent,
    onContactAgentClick = ()=> {},
    requestSource,
    parentRef,
    fromRobin,
    reportId,
    enabled = true,
    ...props
}) {
    const { RootStore, InfographicsMapStore, ListpackStore, SchoolsStore, UserStore } = useStore();
    const [showMap, setShowMap] = useState(false);
    const [placesResult, setPlacesResult] = useState(mockData);

    const [expanded, setExpanded] = useState(false);

    const [showAnimation, setShowAnimation] = useState(false);
    const [loadData, setLoadData] = useState(false);

    const mainPanelRef = useRef(null);

    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const match = useRouteMatch();
    const history = useHistory();
    const isMobile = isXs || isSm;
    const { agent, listpack } = ListpackStore;
    const user = UserStore.user;
    const [listing, setListing] = useState(listingDetails);
    // const isLoading = !InfographicsMapStore || (InfographicsMapStore && InfographicsMapStore.isLoading);
    const { data: schoolData = null } = useListingSchoolData(enabled ? listingDetails : null);

    const schoolSummaryRating = useMemo(() => {
        if(!schoolData || !schoolData?.schools.length) {
            return null;
        }
        const totalRating = schoolData.schools.reduce((accum, { rating }) => accum + rating, 0);
        const avgRating = totalRating / schoolData.schools.length;
        return avgRating >= 7 ? 'above average' : 'average';
    }, [schoolData]);

    const { data: railsCategories = [], nearbyPlaces, isLoading } = placesResult;
    
    const areaData = useMemo(() => {
        if(!railsCategories || !railsCategories.length) {
            return [];
        }
        if(isMobile) {
            return _.chunk(railsCategories, 3);
        } else {
            const totalDatapoints = railsCategories.length + 3;
            let categories = [...railsCategories];
            const firstItem = categories[0];
            categories = categories.slice(1);
            categories = [].concat(
                [],
                [
                    { hidden: true, place: {} },
                    { hidden: true, place: {} },
                    { category: 'Image', place: {}, heroImage: heroImage },
                    firstItem,
                ],
                categories,
                [
                    { hidden: true, place: {} },
                    { hidden: true, place: {} },
                    { hidden: true, place: {} },
                    { hidden: true, place: {} },
                ]
            );

            return _.chunk(categories.slice(0, Math.ceil(totalDatapoints / 4) * 4), 4);
        }
    }, [railsCategories, heroImage, isMobile]);

    const [summaryRadius, summaryRating] = useMemo(() => {
        if(!railsCategories || !railsCategories.length) {
            return [null, null];
        }
        const validCategories = railsCategories.filter((item) => item.place);
        if(validCategories.length > 4) {
            const middleItem = validCategories[Math.ceil(validCategories.length / 2) - 1];
            const radius = Math.ceil(middleItem.place.distance);
            return [radius, radius < 2 ? 'very convenient' : radius < 5 ? 'convenient' : null];
        } else {
            return [null, null];
        }
    }, [railsCategories]);

    const categoriesLocalization = {
        coffee: 'Coffee',
        groceries: 'Grocery store',
        atm: 'ATM',
        gas: 'Gas',
        pharmacy: 'Pharmacy',
        hospital: 'Medical',
        cleaners: 'Dry cleaners',
        gym: 'Gym',
        movie: 'Theater',
        schools: 'Schools',
    };

    // const onScrollHandler = useCallback(()=> {
    //     if(mainPanelRef.current) {
    //         let bounding = mainPanelRef.current.getBoundingClientRect();
    //         if(
    //             bounding.top >= 0 &&
    //             bounding.left >= 0 &&
    //             bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    //             bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    //         ) {

    //             // in the bounds
    //             logger.debug('Inside the bounds');

    //             if(!loadData) {
    //                 setLoadData(true);
    //             }

    //             if(!showAnimation) {
    //                 setShowAnimation(true);
    //             }
    //         }
    //     }
    // }, [loadData, showAnimation]);

    // useEffect(()=> {
    //     window.addEventListener('scroll', onScrollHandler);
    //     parentRef.current.addEventListener('scroll', onScrollHandler);
    //     return function() {
    //         window.removeEventListener('scroll', onScrollHandler); 
    //     };
    // },[onScrollHandler, parentRef]);

    // useEffect(()=> {
    //     const exec = ()=> {
    //         let totalDatapoints;
    //         let categories = railsCategories;
    //         try {
    //             totalDatapoints = categories.length + 3;
    //         } catch (err) {
    //             setAreaData([]);
    //             return;
    //         }

    //         if(isMobile) {
    //             categories = _.chunk(categories, 3);
    //         } else {
    //             const firstItem = categories[0];
    //             categories = categories.slice(1);
    //             categories = [].concat(
    //                 [],
    //                 [
    //                     { hidden: true, place: {} },
    //                     { hidden: true, place: {} },
    //                     { category: 'Image', place: {}, heroImage: heroImage },
    //                     firstItem,
    //                 ],
    //                 categories,
    //                 [
    //                     { hidden: true, place: {} },
    //                     { hidden: true, place: {} },
    //                     { hidden: true, place: {} },
    //                     { hidden: true, place: {} },
    //                 ]
    //             );

    //             categories = _.chunk(categories.slice(0, Math.ceil(totalDatapoints / 4) * 4), 4);
    //         }

    //         setAreaData(categories);

    //         const validCategories = railsCategories.filter((item)=> item.place);
    //         if(validCategories.length > 4) {
    //             const middleItem = validCategories[Math.ceil(validCategories.length / 2) - 1];
    //             const radius = Math.ceil(middleItem.place.distance);

    //             InfographicsMapStore.summaryRadius = radius;
    //             InfographicsMapStore.summaryRating = radius < 2 ? 'very convenient' : radius < 5 ? 'convenient' : null;
    //         } else {
    //             InfographicsMapStore.summaryRadius = null;
    //             InfographicsMapStore.summaryRating = null;
    //         }
    //     };

    //     if(railsCategories.length) {
    //         exec();
    //     }
    // }, [railsCategories, heroImage, isMobile, InfographicsMapStore.summaryRadius, InfographicsMapStore.summaryRating]);

    // useEffect(
    //     function() {
    //         if(!schoolData) {
    //             (async function() {
    //                 try {
    //                     await SchoolsStore.getSchoolsData(listingDetails.address);
    //                 } catch (err) {
    //                     setHideSchoolDetails(true);
    //                     return;
    //                 }
    //             })();
    //         }

    //         if(schoolData) {
    //             var avgRating = 0;
    //             schoolData.schools.forEach((school)=> {
    //                 avgRating += school.rating;
    //             });
    //             avgRating = avgRating / schoolData.schools.length;
    //             setSchoolSummaryRating(avgRating >= 7 ? 'above average' : 'average');
    //         }

    //         return ()=> {
    //             setHideSchoolDetails(false);
    //         };
    //     },
    //     [SchoolsStore, listingDetails.address, schoolData]
    // );

    // const fetchAreaData = useCallback(
    //     async function() {
    //         const response = await InfographicsMapStore.fetchData(listing);
          
    //         let closestByCategory = [];

    //         _.forOwn(response, (value, key)=> {
    //             if(key !== 'schools') {
    //                 if(value.length) {
    //                     closestByCategory.push({
    //                         category: key,
    //                         place: value[0],
    //                     });
    //                 } else {
    //                     closestByCategory.push({
    //                         category: key,
    //                         place: null,
    //                     });
    //                 }
    //             }
    //         });

    //         closestByCategory = _.sortBy(closestByCategory, (c)=> {
    //             if(c.place && c.place.distance) {
    //                 return c.place.distance;
    //             }
    //         });
    //         setRailCategories(closestByCategory);
    //         onScrollHandler();
    //     },
    //     [InfographicsMapStore, listing, onScrollHandler]
    // );

    useEffect(()=> {
        // if the listingId changed
        setListing(listingDetails);
        setLoadData(fromRobin && RootStore.inProductionMode ? true : false);
    }, [listingDetails.id]);

    // useEffect(()=> {
    //     if(loadData) {
    //         fetchAreaData();
    //     }
    // }, [fetchAreaData, loadData]);

    useEffect(() => {
        if(nearbyPlaces && listing?.address) {
            //store nearbyPlaces of listing.address in InfographicsMapStore
            //to avoid doing a duplicated request when entering InfographicsModal
            InfographicsMapStore.setPlaces(listing.address, nearbyPlaces);
        }
    }, [InfographicsMapStore, listing?.address, nearbyPlaces]);

    function getArea(item) {
        if(item.category && item.place) {
            const category = item.category.toLowerCase();
            const name = item.place.name;
            const categoryLookupObj = BRAND_ICONS[category];

            if(categoryLookupObj) {
                const logo = categoryLookupObj[name];
                if(logo) {
                    return (
                        <AreaIcon>
                            <StyledLogoImage src={`https://s3-us-west-2.amazonaws.com/listreports-templates${logo}`} />
                        </AreaIcon>
                    );
                }
            }
        }

        return <AreaIcon>
            <InfographicsCategoryIcon
                category={item.category}
                classes={{ root: 'area-icon-svg' }}
            />
        </AreaIcon>;
    }

    function handleIconClick(category) {
        analytics.eventTrack('listpacks_consumer_listingpage_infographic_category_click', {
            address: listingDetails.address,
            'listing id': listingDetails.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            Category: category || 'home',
            source: requestSource,
            report_id: reportId,
        });
        InfographicsMapStore.setSelectedCategory(category);
        analytics.eventTrack('robin_gallery_viewed', {
            card_id: listing.id,
            source: 'rpp_infographics',
        });
        history.push(`${match.url}/gallery?tab=explore`);
    }

    function handleMapClick() {
        if(listpack) {
            analytics.eventTrack('listpacks_consumer_listingpage_infographic_map_click', {
                address: listingDetails.address,
                listpack_id: listpack.id,
            });
        }
        InfographicsMapStore.setSelectedCategory(null);
        analytics.eventTrack('robin_gallery_viewed', {
            card_id: listing.id,
            source: 'rpp_infographics',
        });
        history.push(`${match.url}/gallery?tab=explore`);
    }

    function handleOnMapClose() {
        setShowMap(false);
    }

    if(showMap) {
        return (
            <InfographicsMapModal
                listingDetails={listing}
                onModalClose={handleOnMapClose}
                requestSource={requestSource}
            />
        );
    }

    return (
        <Container {...props}>
            <StyledExpansionPanel defaultExpanded={defaultExpanded}>
                {/* Title */}
                <StyledExpansionPanelSummary expandIcon={<StyleExpandMoreIcon />}>
                    <StyledSectionTitle>Explore nearby</StyledSectionTitle>
                </StyledExpansionPanelSummary>

                <StyledExpansionPanelDetails>
                    {expanded && (
                        <FetchPlaces listing={listing} enabled={enabled} data={placesResult} setData={setPlacesResult} />
                    )}

                    {isLoading && (
                        <Box display="flex" width={1} alignItems="center" justifyContent="center">
                            <CircularProgress color="secondary" />
                        </Box>
                    )}
                    {/* Description */}
                    {enabled && !isLoading && (
                        <Box ref={mainPanelRef} marginBottom="32px;">
                            <Typography>
                                {summaryRating &&
                                    `This home is in a ${summaryRating} area. `}
                                Some daily errands in this location require a car
                                {summaryRadius &&
                                    `,
                                and most major services are within ${summaryRadius} mile${
                            summaryRadius !== 1 ? 's' : ''
                        }`}
                                .
                                {schoolData &&
                                    ` The assigned schools for this home are
                                 ${schoolSummaryRating} for this area. There are additionally ${schoolData.total_private_schools ||
                                        0} private school${schoolData.total_private_schools !== 1 ? 's' : ''}
                                 and ${schoolData.total_charter_schools || 0} charter school${
                            schoolData.total_charter_schools !== 1 ? 's' : ''
                        }
                                within 5 miles of this home.`}
                            </Typography>
                        </Box>
                    )}

                    {enabled && !isLoading && railsCategories?.length > 0 && (
                        <>
                            {/* Map */}
                            <Box onClick={handleMapClick} style={{ cursor: 'pointer', opacity: expanded ? 1 : 0.8 }}>
                                <Box
                                    border={'1px solid #F0F0F0'}
                                    bgcolor="#fff"
                                    width={isMobile ? '100%' : '60%'}
                                    height="238px"
                                    position="relative"
                                    overflow="hidden"
                                    borderRadius="4px"
                                    marginBottom={isMobile ? '0' : '-176px'}
                                    style={{ pointerEvents: 'none' }}
                                >
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: 'us' }}
                                        defaultZoom={16}
                                        defaultCenter={[33.78724, -117.85496]}
                                        center={[listingDetails.lat, listingDetails.lon]}
                                        options={{
                                            draggable: false,
                                            zoomControl: false,
                                            fullscreenControl: false,
                                            disableDefaultUI: true,
                                        }}
                                    >
                                        <Marker lat={listingDetails.lat} lng={listingDetails.lon}>
                                            <MarkerIconWrapper>
                                                <Place
                                                    style={{
                                                        width: '57px',
                                                        height: '87px',
                                                        marginLeft: '-28px',
                                                        marginTop: '-59px',
                                                    }}
                                                />
                                            </MarkerIconWrapper>
                                        </Marker>
                                    </GoogleMapReact>

                                    {/* Drop shadow for first Medal */}
                                    <FirstIconDropShadow />
                                </Box>
                            </Box>

                            {/* Image Icon for Mobile */}
                            {isMobile && (
                                <Box style={{ opacity: expanded ? 1 : 0.8 }} paddingLeft="4px" mt="-44px" mb="-65px" zIndex="1" position="relative">
                                    <Medal
                                        onClick={()=> {
                                            handleIconClick(null);
                                        }}
                                    >
                                        <AreaIcon showAnimation={showAnimation}>
                                            {heroImage && (
                                                <MedalHeroImage>
                                                    <ListingImage image={heroImage} height="100%" />
                                                </MedalHeroImage>
                                            )}

                                            {!heroImage && <Home classes={{ root: 'area-icon-svg' }} {...props} />}
                                        </AreaIcon>

                                        <AreaPipeV />
                                    </Medal>
                                </Box>
                            )}

                            {areaData.length &&
                                <Box maxHeight={expanded ? 'none' : (isMobile ? '125px' : '325px')} overflow="hidden" position="relative" mb={2}>
                                    {areaData.map((chunk, ci)=> {
                                        const opacity = (!expanded && ci >= 1)
                                            ? 0.6
                                            : expanded
                                                ? 1
                                                : isMobile ? 0.6 : 0.8;
                                        return (
                                            <Box key={ci} style={{ pointerEvents: 'none', opacity: opacity }}>
                                                <Box
                                                    position="relative"
                                                    display="flex"
                                                    alignItems="flex-start"
                                                    justifyContent="center"
                                                    paddingBottom={ci === 0 ? '20px' : '0'}
                                                >
                                                    {chunk.map((item, i)=> {
                                                        return (
                                                            <Fragment key={i}>
                                                                {/* Left Down Pipe */}
                                                                {i === 0 && ci % 2 === 1 && (
                                                                    <PipeWrapper>
                                                                        <LeftDownPipe />
                                                                    </PipeWrapper>
                                                                )}

                                                                {/* Left Up Pipe */}
                                                                {i === 0 && ci % 2 === 0 && (
                                                                    <PipeWrapper
                                                                        style={{
                                                                            opacity: ci !== 0 ? 1 : 0,
                                                                            pointerEvents: item.hidden ? 'none' : 'auto',
                                                                        }}
                                                                        onClick={() => expanded && handleIconClick(item.category)}
                                                                    >
                                                                        <LeftUpPipe />
                                                                    </PipeWrapper>
                                                                )}

                                                                {/* Main icon that shows up */}

                                                                <Medal
                                                                    className="medal"
                                                                    key={i}
                                                                    style={{
                                                                        opacity: item.hidden ? 0 : 1,
                                                                        pointerEvents: item.hidden ? 'none' : 'auto',
                                                                    }}
                                                                >
                                                                    {/* Image */}
                                                                    {item.category === 'Image' && (
                                                                        <Box
                                                                            margin="0"
                                                                            onClick={()=> {
                                                                                handleIconClick(null);
                                                                            }}
                                                                        >
                                                                            <AreaIcon showAnimation={showAnimation}>
                                                                                {heroImage && (
                                                                                    <MedalHeroImage>
                                                                                        <ListingImage
                                                                                            image={heroImage}
                                                                                            height="100%"
                                                                                        />
                                                                                    </MedalHeroImage>
                                                                                )}

                                                                                {!heroImage && (
                                                                                    <Home
                                                                                        classes={{ root: 'area-icon-svg' }}
                                                                                        {...props}
                                                                                    />
                                                                                )}
                                                                            </AreaIcon>
                                                                        </Box>
                                                                    )}

                                                                    {/* Icon */}
                                                                    {item.category !== 'Image' && (
                                                                        <>
                                                                            <ButtonBase
                                                                                style={{
                                                                                    borderRadius: '50%',
                                                                                    overflow: 'hidden',
                                                                                    margin: '0',
                                                                                }}
                                                                                disabled={!expanded}
                                                                                onClick={()=> {
                                                                                    handleIconClick(item.category);
                                                                                }}
                                                                            >
                                                                                {getArea(item)}
                                                                            </ButtonBase>

                                                                            <Box
                                                                                position="relative"
                                                                                left={{ sm: '-14%', md: 0 }}
                                                                            >
                                                                                <Distance
                                                                                    textAlign="center"
                                                                                    fontSize="fontSize.body2"
                                                                                >
                                                                                    {item.place && (
                                                                                        <Box>
                                                                                            {item.place.distance} miles
                                                                                        </Box>
                                                                                    )}
                                                                                    {!item.place && (
                                                                                        <Box>No nearby places</Box>
                                                                                    )}
                                                                                </Distance>
                                                                                <Box
                                                                                    textAlign="center"
                                                                                    fontSize={16}
                                                                                    lineHeight="16px"
                                                                                    fontWeight={700}
                                                                                >
                                                                                    {categoriesLocalization[item.category]}
                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                </Medal>

                                                                {/* Pipe separator */}
                                                                {chunk.length - 1 !== i && (
                                                                    <AreaPipe
                                                                        style={{
                                                                            opacity:
                                                                                item.hidden || (chunk[i + 1] || {}).hidden
                                                                                    ? 0
                                                                                    : 1,
                                                                            pointerEvents:
                                                                                item.hidden || (chunk[i + 1] || {}).hidden
                                                                                    ? 'none'
                                                                                    : 'auto',
                                                                        }}
                                                                    />
                                                                )}

                                                                {/* Right Up Pipe */}
                                                                {chunk.length - 1 === i && ci % 2 === 1 && (
                                                                    <PipeWrapper left="-10px">
                                                                        <RightUpPipe />
                                                                    </PipeWrapper>
                                                                )}

                                                                {/* Right Down pipe */}
                                                                {chunk.length - 1 === i && ci % 2 === 0 && (
                                                                    <PipeWrapper
                                                                        left="-10px"
                                                                        style={{
                                                                            opacity: ci !== areaData.length - 1 ? 1 : 0,
                                                                            pointerEvents: item.hidden ? 'none' : 'auto',
                                                                        }}
                                                                    >
                                                                        <RightDownPipe />
                                                                    </PipeWrapper>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    })}
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                    {!isLoading && (
                                        <Box
                                            width="100%"
                                            display="flex"
                                            justifyContent="center"
                                            backgroundColor="transparent"
                                            textAlign="center" mt={12 / 8}
                                            position={expanded ? 'relative' : 'absolute'}
                                            bottom={15}
                                        >
                                            {!expanded && (
                                                <StyledButton
                                                    style={{ backgroundColor: 'white' }}
                                                    variant="outlined"
                                                    backgroundColor="white"
                                                    padding="8px 10px"
                                                    border={`1px solid ${COLOR_BORDER_GREY_LIGHTER}`}
                                                    onClick={() => {
                                                        analytics.eventTrack('listpacks_consumer_listingpage_infographic_view_more_click');

                                                        setExpanded(true);
                                                    }}
                                                >
                                                    View more
                                                </StyledButton>
                                            )}

                                            {expanded && (
                                                <StyledButton
                                                    style={{ backgroundColor: 'white' }}
                                                    variant="outlined"
                                                    backgroundColor="white"
                                                    padding="8px 10px"
                                                    border={`1px solid ${COLOR_BORDER_GREY_LIGHTER}`}
                                                    onClick={() => setExpanded(false)}
                                                >
                                                    View less
                                                </StyledButton>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            }
                        </>
                    )}
                </StyledExpansionPanelDetails>

                {!isLoading && listingDetails && listingDetails?.listing_source !== 'listhub' && listpack && (
                    <Box mb={4}>
                        <Typography variant="h6" align="center">
                            Want to know more about this property?
                        </Typography>

                        <Box display="flex" justifyContent="center" mt={2.5}>
                            <LRButton variant="contained" color="primary" onClick={_.partial(onContactAgentClick, 'explore_neighborhood')}>
                                Contact agent
                            </LRButton>
                        </Box>
                    </Box>
                )}
            </StyledExpansionPanel>
        </Container>
    );
}
AreaReport.propTypes = {
    defaultExpanded: PropTypes.bool,
    heroImage: PropTypes.string,
    listingDetails: PropTypes.object.isRequired,
    onContactAgentClick: PropTypes.func,
};

export default observer(AreaReport);
// export default withRouter(observer(Board));
