import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme, Box, CircularProgress, Typography } from '@material-ui/core';
import { useSignupStoreContext } from '../store';
import { useForm } from 'react-hook-form';
import { TextField } from '../../../components/TextField';
import { LRPhoneInput } from '../../../components/LRPhoneInput';
import LRLink from '../../../components/LRLink';
import { withTheme } from '@material-ui/styles';
import LRButton from '../../../components/LRButton';
import { LRCheckbox } from '../../../components/LRCheckbox';
import styled from 'styled-components';
import _, { set } from 'lodash';
import analytics from '../../../services/analytics';
import LRDropdown from '../../../components/LRDropdown';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useRFHInput } from '../../../hooks';
import { useStore } from 'mobx-store';
import Logger from 'js-logger';
import formatPhoneNumber from 'services/format-phone-number';

const TERMS_LINK = 'https://welcome.listreports.com/terms-conditions';
const PRIVACY_LINK = 'https://welcome.listreports.com/privacy-policy';

const StyledButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
        color: ${({ theme })=> theme.palette.lr_colors.grey_dark};
    }
`);

const howSoonAnalyticsMapping = {
    'ASAP': 'asap',
    'within 3 months': '1-3 months',
    '3-6 months': '3-6months',
    '6-12 months': '6-12months',
    '12+ months': '12+months',
    'im_not_sure': 'im_not_sure',
};

const howSoonOptions = [
    { value: 'ASAP', label: 'ASAP' },
    { value: 'within 3 months', label: '1-3 months' },
    { value: '3-6 months', label: '3-6 months' },
    { value: '6-12 months', label: '6-12 months' },
    { value: '12+ months', label: '12+ months' },
    { value: 'im_not_sure', label: "I'm not sure" },
];
export const SignupForm = observer(function SignupForm() {
    const theme = useTheme();
    const { LoginSignupModalStore } = useStore();
    const signupStore = useSignupStoreContext();
    const duplicateEmail = signupStore.saveUserRequest.hasDuplicateEmailError;
    const duplicatePhone = signupStore.saveUserRequest.hasDuplicatePhoneError;
    const invalidPhone = signupStore.saveUserRequest.hasInvalidPhoneError;
    const phoneError = useMemo(()=> {
        return (duplicatePhone || invalidPhone) ? signupStore.saveUserRequest.firstError.detail : '';
    }, [duplicatePhone, invalidPhone]);
    const duplicateAuth = signupStore.saveUserRequest.hasDuplicateAuthError;
    const submitting = signupStore.saveUserRequest.isLoading || signupStore.sendCodeRequest.isLoading || signupStore.skipVerification;
    const affiliate = signupStore.affiliate;
    const defaultUserInfo = _.merge(
        {},
        {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
        },
        signupStore.userInfo || {}
    );
    const rfhMethods = useForm({
        defaultValues: defaultUserInfo,
        mode: 'onChange',
        reValidateMode: 'onChange'
    });
    const { handleSubmit, errors, register, formState, setError, watch } = rfhMethods;
    const { handleChange: handleHowSoonChange } = useRFHInput('meta.how_soon', {
        ...rfhMethods,
        register: register()
    });
    const [termsChecked, setTermsChecked] = useState(false);
    const [homeReportTextMessageOptInChecked, setHomeReportTextMessageOptInChecked] = useState(false);
    const defaultSelectedHowSoonOption = useMemo(()=> {
        if(signupStore.userInfo?.meta?.how_soon) {
            return howSoonOptions.find((option)=> option.value === signupStore.userInfo?.meta.how_soon);
        } else {
            return null;
        }
    }, [signupStore.userInfo]);
    const [selectedHowSoonOption, setSelectedHowSoonOption] = useState(defaultSelectedHowSoonOption);
    const phoneNumber = watch('phone');

    async function submitForm(data) {
        const { invite } = signupStore;

        if(homeReportTextMessageOptInChecked) {
            set(data, 'notification_settings.robin_myhome.text', true);
        }

        const canViewHomeReportTextMessageOptin = LoginSignupModalStore.signupFormOptions.homeReportTextMessageOptInCheckboxEnabled;

        if(homeReportTextMessageOptInChecked) analytics.eventTrack('robin_invite_signup_opt_in');
        analytics.eventTrack('robin_register_submit', {
            board_id: invite?.boardId,
            distinct_id: invite?.invitedUserId,
            invite_id: signupStore.inviteId,
            how_soon: howSoonAnalyticsMapping[data.meta.how_soon],
            role: invite ? invite.meta.label.replace('-', '') : 'buyer',
            Source: signupStore.externalAuth?.method || (signupStore.isSignupByEmail && 'email') || (signupStore.isSignupByPhone && 'mobile'),
            consumer_name: `${data.first_name} ${data.last_name}`,
            consumer_email: data.email,
            consumer_phone: data.phone,
            terms_box_check: Boolean(termsChecked),
            invite_signup_opt_in: !canViewHomeReportTextMessageOptin ? undefined : Boolean(homeReportTextMessageOptInChecked),
        });

        if(data.phone) {
            const { isValid: phoneIsValid, reason } = await signupStore.isPhoneValid(data.phone);

            if(data.phone && !phoneIsValid) {
                setError('phone', 'manual', reason);
            } else {
                signupStore.saveUser(data);
            }
        } else {
            signupStore.saveUser(data);
        }
    }

    return (
        <form onSubmit={handleSubmit((data)=> submitForm(data))} noValidate>
            <Box width="calc(100% - 32px)" mt={9 / 2} mx={2} display="flex">
                <Box flex="1">
                    <TextField
                        name="first_name"
                        placeholder="First name"
                        inputProps={{
                            height: '48px',
                            width: '100%',
                            fontSize: theme.typography.body1.fontSize,
                        }}
                        inputRef={register({
                            required: true,
                            validate: (value)=> value.trim().length,
                        })}
                        tone={errors.first_name && 'error'}
                        required
                    />
                </Box>

                <Box width={theme.spacing(1)} />

                <Box flex="1">
                    <TextField
                        name="last_name"
                        placeholder="Last name"
                        inputProps={{
                            height: '48px',
                            width: '100%',
                            fontSize: theme.typography.body1.fontSize,
                        }}
                        inputRef={register({
                            required: true,
                            validate: (value)=> value.trim().length,
                        })}
                        tone={errors.last_name && 'error'}
                        required
                    />
                </Box>
            </Box>
            <Box mt={2} mx={2} display={signupStore.isSignupByPhone ? 'none': 'block'}>
                <LRPhoneInput
                    rfhMethods={rfhMethods}
                    rules={{ required: !signupStore.isSignupByEmail && !signupStore.isSignupByExternal }}
                    name="phone"
                    inputProps={{
                        height: '48px',
                        width: '100%',
                        message: ()=> errors?.phone?.message || phoneError,
                        placeholder: 'Mobile phone number',
                        fontSize: theme.typography.body1.fontSize,
                        tone: (errors.phone || phoneError) && 'error',
                    }}
                    onChange={()=> signupStore.saveUserRequest.reset()}
                />
            </Box>
            <Box mt={2} mx={2} display={signupStore.isSignupByEmail ? 'none': 'block'} position="relative">
                <TextField
                    name="email"
                    placeholder="Email"
                    type="email"
                    inputProps={{
                        height: '48px',
                        width: '100%',
                        fontSize: theme.typography.body1.fontSize,
                        paddingRight: '90px',
                    }}
                    inputRef={register({
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Invalid email address',
                        },
                    })}
                    message={
                        (duplicateAuth || duplicateEmail) && 'An account with this email already exists. Please Login'
                    }
                    tone={(errors.email || duplicateEmail || duplicateAuth) && 'error'}
                    onChange={()=> signupStore.saveUserRequest.reset()}
                    required
                />
                <Box
                    color={theme.palette.lr_colors.grey_dark}
                    position="absolute"
                    top="50%"
                    right="16px"
                    style={{
                        transform: 'translate(0, -50%)'
                    }}
                >
                    Required
                </Box>
            </Box>
            <Box mt={2} mx={2}>
                <LRDropdown
                    fullWidth
                    selectedOption={selectedHowSoonOption}
                    options={howSoonOptions}
                    onOptionSelect={(option)=> {
                        handleHowSoonChange(option.value);
                        setSelectedHowSoonOption(option);
                    }}
                    renderDisplayOption={({ label })=> label}
                    renderOption={({ label })=> label}
                    textFieldIcon={KeyboardArrowDownRoundedIcon}
                    height={'48px'}
                    placeholder="How soon are you looking to buy?"
                />
                <Box
                    mt={2}
                    fontSize={theme.typography.caption.fontSize}
                    color={theme.palette.lr_colors.grey_dark}
                >
                    We’ll call or text to confirm your number and if you need help logging in. Standard message and data rates apply.
                </Box>
            </Box>
            <Box display="flex" alignItems="center" mt={5 / 2} mx={2}>
                <LRCheckbox checked={termsChecked} onChange={(e)=> setTermsChecked(e.target.checked)} />
                <Box fontSize={theme.typography.caption.fontSize} color={theme.palette.lr_colors.grey_dark}>
                    I agree to the ListReports{' '}
                    <LRLink native target="_blank" href={TERMS_LINK}>
                        terms of service
                    </LRLink>{' '}
                    and{' '}
                    <LRLink native target="_blank" href={PRIVACY_LINK}>
                        privacy policy.
                    </LRLink>
                </Box>
            </Box>

            {phoneNumber?.length === 10 && LoginSignupModalStore.signupFormOptions.homeReportTextMessageOptInCheckboxEnabled && (
                <Box display="flex" alignItems="center" mt={1} mx={2}>
                    <LRCheckbox checked={homeReportTextMessageOptInChecked} onChange={(e)=> setHomeReportTextMessageOptInChecked(e.target.checked)} />
                    <Typography variant="caption" style={{ color: theme.palette.lr_colors.grey_dark, }}>
                        Get your Robin home report automatically texted each month to {formatPhoneNumber(phoneNumber)}.
                    </Typography>
                </Box>
            )}
            <Box mt={3} mx={2}>
                <StyledButton
                    type="submit"
                    value="Submit"
                    disabled={
                        !formState.isValid ||
                        duplicateAuth ||
                        duplicateEmail ||
                        duplicatePhone ||
                        submitting ||
                        !termsChecked
                    }
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                        height: '48px',
                        fontWeight: 'normal',
                    }}
                >
                    {!submitting && 'Sign up'}
                    {submitting && <CircularProgress size={24} color="secondary" />}
                </StyledButton>
            </Box>
            {affiliate && <Box
                mt={5 / 2}
                mx={2}
                fontSize={theme.typography.caption.fontSize}
                color={theme.palette.lr_colors.grey_dark}
            >
                Robin is a collaborative home search tool. By clicking Sign Up, your account information and
                home preferences will be shared with{affiliate && ` ${affiliate.name}`} and their paired Loan Officer{affiliate && `, ${affiliate?.preferred_vendor?.name}`}.
            </Box>}
        </form>
    );
});
