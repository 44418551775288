export const listingImage = (listing)=> {
    const listing_details = listing.listing_details;
    return listingDetailsImage(listing_details);
};

export const listingDetailsImage = (listingDetails)=> {
    return (
        listingDetails?.hero_image_url ||
        (listingDetails?.photos && listingDetails?.photos.length ? listingDetails?.photos[0] : undefined)
    );
};
