import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IconButton, Divider, Box } from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../../../hooks';
import { MoreHoriz, CheckRounded } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTheme } from '@material-ui/styles';
import analytics from '../../../../services/analytics';
import { useStore } from '../../../../mobx-store';
import DeleteConfirmation from '../DeleteConfirmation';
import { SORT_OPTIONS, SORT_MAP_MIXPANEL } from '../../../../constants';
import { StyledPopover } from './style';
function SortMenu({lane}) {
    const theme = useTheme();
    const { LanesStore, UserStore } = useStore();
    const isMobile = useResponsiveBreakpoint('xs');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isModalOpen, setModal] = useState(false);
    const { isAgent, isLO } = UserStore;
    const selectedOption = useMemo(function() {
        if(!lane?.id) return;

        return LanesStore.sortByLaneId[lane.id];
    }, [lane?.id]);

    function handleDelete(event) {
        setAnchorEl(event.currentTarget);
    };

    function openModal() {
        setAnchorEl(null);
        setModal(true);
    };

    function handleClose() {
        setAnchorEl(null);
    };

    function handleCloseModal(){
        setModal(false);
    };

    function handleSort(option){
        LanesStore.setSelectedLaneSort(lane.id, option);
        setAnchorEl(null);
        const elem = document.getElementById(`lane-container-${lane.id}`);
        if(elem){
            elem.scrollTo(0, 0);
        }
        analytics.eventTrack('robin_column_sorted', {
            column: _.snakeCase(lane?.name),
            sorted_by:  SORT_MAP_MIXPANEL[`${option.key}_${option.order}`],
        });
    };

    if(isAgent || isLO || !lane){
        return null;
    }

    return (
        <>
            <IconButton onClick={handleDelete} disabled={LanesStore.getUnarchivedLanesListings(lane.id).length === 0} style={{marginLeft: theme.spacing(1), padding: 0, color: LanesStore.getUnarchivedLanesListings(lane.id).length > 0 ? 'black' : '#acacac', cursor: LanesStore.getUnarchivedLanesListings(lane.id).length > 0 ? 'pointer' : 'default'}}>
                <MoreHoriz  />
            </IconButton>
            <StyledPopover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <>
                    {!isMobile && (
                        <div style={{padding: '10px 16px', color: theme.palette.lr_colors.grey_nero}}>
                            {SORT_OPTIONS.map(function(option, index) {
                                return (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        key={index}
                                        onClick={()=> {
                                            handleSort(option);

                                        }}
                                    >
                                        {index !== 0 && (
                                            <Box margin="10px 0">
                                                <Divider />
                                            </Box>
                                        )}
                                        <Box
                                            display="flex"
                                            height="24px"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Box ml={1} fontSize="body1.fontSize">
                                                {option.label}
                                            </Box>
                                            {selectedOption?.label === option.label && (
                                                <Box height="24px" color="#54D0AA">
                                                    <CheckRounded />
                                                </Box>
                                            )}
                                        </Box>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div
                        onClick={openModal}
                        style={{
                            padding: theme.spacing(2),
                            backgroundColor: theme.palette.lr_colors.grey_light,
                            display: 'inline-flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            width: '100%',
                            borderTop:`1px solid ${theme.palette.lr_colors.border_grey}`
                        }}
                    >
                        <DeleteIcon style={{marginRight: theme.spacing(1)}}/> Delete all homes in lane
                    </div>
                </>
            </StyledPopover>
            <DeleteConfirmation isOpen={isModalOpen} handleClose={handleCloseModal} lane={lane} listings={LanesStore.getUnarchivedLanesListings(lane.id)}/>
        </>
    );
}

SortMenu.propTypes = {
    lane: PropTypes.object.isRequired,
    listings: PropTypes.array.isRequired,
    setListings: PropTypes.func.isRequired,
};

export default SortMenu;
