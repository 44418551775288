import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { ChevronLeftRounded, ChevronRightRounded, Email } from '@material-ui/icons';
import { useStore } from '../../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { ActionShareIcon } from '../../../../components/LRIcons';
import AgentInfo from '../../../ListingDetails/Header/SlidyAgentInfo/AgentInfo';
import LRButton from '../../../LRButton';
import { COLOR_GREY, COLOR_SALMON } from '../../../../constants';
import { useResponsiveBreakpoint } from '../../../../hooks';
import styled from 'styled-components';
import { Redirect, useLocation, useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import analytics from '../../../../services/analytics';
import { withTheme } from '@material-ui/core/styles';
import ShareModal from '../../../../scenes/ShareModal';
import LRConfirmationDialog from '../../../../components/LRConfirmationDialog';
import { portalApi } from '../../../../apis';
import Logger from 'js-logger';
import { useIsScrollPositionWithinOffset } from '../../../../hooks';

const logger = Logger.get('ListpackListingHeader');
const AgentBranding = withTheme(styled.img`
    max-height: 50px;
    ${({ theme })=> theme.breakpoints.down('xs')} {
        max-width: 92px;
    }
`);

const BackIcon = styled(ChevronLeftRounded)`
    vertical-align: middle;
`;

const AnimatedHeader = styled(Box)`
    box-shadow: 0px 16px 32px -13px rgba(0, 0, 0, 0.21);
`;

function ListpackListingHeader({ listing_details, index, toIndex, requestSource, onGoBack }) {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;
    const isInsideIframe = window.location !== window.parent.location;

    const [redirectEnd, setRedirectEnd] = useState(false);
    const [redirectEndContact, setRedirectEndContact] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [openSuccessShareDialog, setOpenSuccessShareDialog] = useState(false);
    const [shortenedUrl, setShortenedUrl] = useState();

    const { source } = queryString.parse(useLocation().search);
    const { ListpackStore } = useStore();
    const params = useParams();
    const { listpack, agent, listingsCount, loadedListpack, loadedListingsCount, loadedAgent, loadingMoreListings } = ListpackStore;
    const visible = useIsScrollPositionWithinOffset(80);

    index = Number(index);

    function trackEvent(eventName) {
        if(!loadedAgent || !loadedListpack || !listing_details) return;

        analytics.eventTrack(eventName, {
            address: listing_details.address,
            'listing id': listing_details.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            source: requestSource,
        });
    }

    function onBackClick() {
        if(listing_details && listpack) {
            trackEvent('listpacks_consumer_listingpage_back_arrow_click');
        }
        onGoBack();
    }

    function onPreviousClick() {
        window.scrollTo(0,0);
        if(listing_details && listpack) {
            trackEvent('listpacks_consumer_listingpage_previous_click');
        }
        toIndex(index - 1);
    }

    function onNextClick() {
        window.scrollTo(0,0);
        if(listing_details && listpack) {
            trackEvent('listpacks_consumer_listingpage_next_click');
        }
        toIndex(index + 1);
    }

    function onContactClick() {
        if(listing_details && listpack) {
            trackEvent('listpacks_consumer_listingpage_contact_agent_click');
        }
        setRedirectEndContact(true);
    }

    function handleFinishClick() {
        setRedirectEnd(true);
    }

    function openShareWindow() {
        if(!isInsideIframe) {
            if(listing_details && listpack && agent) {
                trackEvent('listpacks_consumer_listingpage_share_icon_click');
            }
            setOpenShare(true);
        }
    }

    function closeShareModal(success) {
        setOpenShareModal(false);
        setOpenSuccessShareDialog(success);
    }

    useEffect(
        function() {
            async function _getShortenedUrl() {
                if(agent && listing_details) {
                    // const shareUrl = portalApi.getSocialShareUrl(agent.id, params.listpackId, listing_details.id);
                    const host = process.env.REACT_APP_PUBLIC_URL_FOR_META_TAGS || window.host;
                    const url = `${host}/listpack/${params.listpackId}/listings/${listing_details.id}`;
                    const _shortenedUrl = await portalApi.shortenURL(url);
                    setShortenedUrl(_shortenedUrl.data);
                }
            }
            _getShortenedUrl();
        },
        [agent, listing_details, params.listpackId]
    );

    if(openShare) {
        if(isMobile && navigator.share) {
            navigator
                .share({
                    title: listpack.name,
                    url: shortenedUrl,
                })
                .then(()=> {})
                .catch(console.error);
        } else {
            setOpenShareModal(true);
        }
        setOpenShare(false);
    }

    if(redirectEnd) {
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/end?${source ? `source=${source}` : ''}`} />;
    }

    if(redirectEndContact) {
        const uData = {
            address: listing_details.address,
            source: source || 'agent_header',
        };

        if(listing_details?.listing_source !== 'listhub') {
            uData.listingId = listing_details.id;
        }

        const referrerListingParams = (listing_details?.id)
            ? '&' + queryString.stringify(uData)
            : '';
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/end?&focus=true${referrerListingParams}`} />;
    }

    return (
        <>
            {openSuccessShareDialog && (
                <LRConfirmationDialog
                    title="Thanks!"
                    okButton={{ label: 'OK' }}
                    open={true}
                    onClose={()=> {
                        setOpenSuccessShareDialog(false);
                    }}
                >
                    <Box>Your email has been sent.</Box>
                </LRConfirmationDialog>
            )}

            {openShareModal && (
                <ShareModal
                    open={true}
                    onClose={closeShareModal}
                    agentId={agent.id}
                    listing={listing_details}
                    listpackId={listpack.id}
                    shortenedUrl={shortenedUrl}
                ></ShareModal>
            )}
            <Box
                paddingLeft={isMobile ? `${theme.spacing(2)}px` : `${theme.spacing(3)}px`}
                paddingRight={isMobile ? `${theme.spacing(2)}px` : `${theme.spacing(3)}px`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height={theme.spacing(8)}
                width="100%"
                position="relative"
                zIndex={10}
                bgcolor="white"
            >
                <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }} onClick={onBackClick}>
                    <BackIcon />
                    <div style={{marginLeft: theme.spacing(1)}}>back</div>
                </Box>
                {isMobile && (
                    <div style={{display: 'flex'}}>
                        <ActionShareIcon onClick={openShareWindow} />
                        <Email onClick={()=> setRedirectEndContact(true)} style={{marginLeft: theme.spacing(2)}} />
                    </div>
                )}
                {!isMobile && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" width={286}>
                        <LRButton
                            disabled={index === 0}
                            onClick={onPreviousClick}
                            style={{
                                border: `1px solid ${COLOR_GREY}`,
                                fontWeight: 'normal',
                                padding: '5px 16px 5px 12px',
                                minWidth: 'unset',
                            }}
                        >
                            <ChevronLeftRounded style={{fontSize: theme.typography.body1.fontSize}} />
                            <Typography variant="body2" style={{marginLeft: theme.spacing(1)}}>Previous</Typography>
                        </LRButton>
                        <Typography variant="caption">
                            {loadedListingsCount && !isNaN(index) ? `${Number(index) + 1} of ${listingsCount}` : ''}
                        </Typography>
                        {Number(index) + 1 < listingsCount && (
                            <Box>
                                <LRButton
                                    disabled={loadingMoreListings}
                                    onClick={onNextClick}
                                    style={{
                                        color: 'white',
                                        fontSize: 'body1.fontSize',
                                        backgroundColor: COLOR_SALMON,
                                        fontWeight: 'normal',
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(1.5),
                                        minWidth: 'unset',
                                    }}
                                >
                                    <Typography variant="body2" style={{marginRight: theme.spacing(1)}}>Next</Typography>
                                    <ChevronRightRounded style={{fontSize: theme.typography.body1.fontSize}} />
                                </LRButton>
                            </Box>
                        )}
                        {Number(index) + 1 >= listingsCount && (
                            <Box width={102}>
                                <LRButton
                                    fullWidth
                                    style={{
                                        width: '100px',
                                        color: 'white',
                                        fontSize: 'body1.fontSize',
                                        backgroundColor: COLOR_SALMON,
                                    }}
                                    onClick={handleFinishClick}
                                >
                                    {'Finish 🎉'}
                                </LRButton>
                            </Box>
                        )}
                    </Box>
                )}
                {!isMobile && (
                    <Box
                        style={{ cursor: 'pointer' }}
                        onClick={openShareWindow}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flexDirection="row"
                        color={isInsideIframe ? COLOR_GREY : 'inherit'}
                    >
                        <Box pt={1}>
                            <ActionShareIcon style={{fontSize: theme.typography.body1.fontSize}} />
                        </Box>
                        <Box fontSize="body2.fontsize" ml={1} style={{textDecoration: 'underline'}}
                        >
                            Share
                        </Box>
                    </Box>
                )}
            </Box>
            
            {/* <Divider />
            {loadedAgent && agent && (
                <Box position="relative">
                    <Slide in={visible} direction="down" timeout={1000}>
                        <AnimatedHeader
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            padding={`8px ${isMobile ? '10px' : '24px'}`}
                            paddingRight={isMobile ? '20px' : '24px'}
                            bgcolor="white"
                            position="absolute"
                            width={1}
                            zIndex={0}
                        >
                            <AgentInfo agent={agent} onContactClick={onContactClick} />
                            {<AgentBranding src={agent?.branding} />}
                        </AnimatedHeader>
                    </Slide>
                </Box>
            )} */}
            
        </>
    );
}

export default observer(ListpackListingHeader);
