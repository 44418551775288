import { AppBar, Grid, Slide, Toolbar, Typography, useTheme, IconButton } from '@material-ui/core';
import moment from 'moment';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { useBoardCards } from '../../../hooks/useBoardCards';
import Map from '../../../scenes/ListingsMap/Map';
import LRModal from '../../LRModal';
import _ from 'lodash';
import { BoardCardModal } from '../../../scenes/BoardCardModal';
import { ScheduleATourModal } from '../../ScheduleATourModal';
import { AppBarHeader } from './AppBarHeader';
import { AppBarFooter } from './AppBarFooter';
import { TAB_DATE_AND_TIME, TAB_DOCUMENTS, TAB_HOMES, TAB_NOTES, TourDetailsAppBarHeader, TourDetailsDateAndTime, TourDetailsDocuments, TourDetailsHomes, TourDetailsNotes, TourDetailsTabs } from './TourDetails';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { useStore, useActiveBoard } from 'mobx-store';
import LRButton from 'components/LRButton';
import { LRAddNoteModal } from 'components/LRAddNoteModal';
import Promise from 'bluebird';
import analytics from 'services/analytics';
import { CancelTourConfirmationModal } from '../CancelTourConfirmationModal';
import queryString from 'query-string';
import { Close } from '@material-ui/icons';
import { MobileDrawer } from './MobileDrawer';

const logger = Logger.get('TourDetailsModal');
const tabs = {
    homes: TAB_HOMES,
    date_time: TAB_DATE_AND_TIME,
    documents: TAB_DOCUMENTS,
    notes: TAB_NOTES,
};

export const TourDetailsModal = observer(function TourDetailsModal({
    tours,
    open = false,
    appBarHeader:Header = AppBarHeader,
    appBarHeaderProps:headerProps = {},
    appBarFooter:Footer = AppBarFooter,
    appBarFooterProps:footerProps = {},
    onClose = (e)=> {},
    onTourChange = (e, tour)=> {},
    onCancelTour = (e, tour)=> {},
    ...props
}) {
    const theme = useTheme();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const { tourId } = useParams();
    const { boardsStore } = useStore();
    const activeBoard = useMemo(()=> {
        return boardsStore.activeBoard || {};
    }, [boardsStore.activeBoard]);
    const tour = useMemo(()=> {
        if(!tours?.length) return null;

        return _.find(tours, { id: tourId });
    }, [tourId, tours]);
    const queryStrings = queryString.parse(location.search);
    const initialSelectedTab = ( queryStrings.tab && tabs[queryStrings.tab] ) || TAB_HOMES;
    const {
        boardCardsById,
    } = useBoardCards();
    const listings = useMemo(()=> {
        if(!(tour && boardCardsById)) return [];

        return tour.content.boardCardIds.reduce((result, id)=> {
            if(boardCardsById[id]) result.push(boardCardsById[id]);

            return result;
        }, []);
    }, [tour, boardCardsById]);
    const {
        isMobile,
        isIPadDown,
    } = useDeviceBreakpoints();
    const [openCancelConfirmationModal, setOpenCancelConfirmationModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
    const [mobileView, setMobileView] = useState('list');
    const {
        boardCollaborations,
        addBoardCollaboration,
        updateBoardCollaboration,
    } = useBoardCollaborations(tour?.id);
    const notes = useMemo(()=> {
        return boardCollaborations?.filter((bc)=> (bc.type === 'schedule_viewing_request_comment')) || [];
    }, [boardCollaborations]);

    const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
    const [hoveredListing, setHoveredListing] = useState();
    const [readyToSchedule, setReadyToSchedule] = useState();
    const [schedulingDateAndTime, setSchedulingDateAndTime] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedListing, setSelectedListing] = useState(null);

    const cobuyersToExclude = (activeBoard.collaborators || [])
        .filter((collaborator)=> {
            //to be included: collaborator.userId === tour.userId || isParticipant
            const isParticipant = (tour?.content?.participants_joining || []).find((participant)=> {
                return collaborator?.userId === participant?.userId;
            });
            return collaborator?.userId !== tour?.userId && !isParticipant;
        });

    // Functions

    function onScheduleReadyToggle(ready) {
        setReadyToSchedule(ready);
    }

    function onEditTourClick() {
        analytics.eventTrack('robin_home_tour_detail_edit_button_click', {
            Source: 'home_tours_modal',
            board_id: activeBoard?.id
        });
        history.push(`${match.url}/edit`);
    }

    function onCancelTourClick() {
        analytics.eventTrack('robin_home_tour_detail_cancel_tour_button_click', {
            Source: 'home_tours_modal',
            board_id: activeBoard?.id
        });
        setOpenCancelConfirmationModal(true);
    }

    async function addNote(data) {
        const content = {
            ...data?.content,
            tour_name: _.get(tour, 'content.tour_name'),
            // include participants_joining to target for notifications
            participants_joining: _.get(tour, 'content.participants_joining'),
        };
        const msg = {
            ...data,
            content,
            type: 'schedule_viewing_request_comment',
            model: 'BoardCollaboration',
        };

        await addBoardCollaboration(msg);
    }

    async function scheduleTourDateAndTime(dateAndTime) {
        const content = {
            scheduled_date_time: dateAndTime,
            scheduled_date_formatted: moment(dateAndTime).format('ddd, MMM D'),
            scheduled_date_time_formatted: moment(dateAndTime).format('ddd, MMM D [at] h:mm A'),
        };

        try {
            await updateBoardCollaboration({ id: tour.id, data: { content } });
            await Promise.delay(200); // Delay so the data can propagate
        } catch (err) {
            return logger.error('scheduleTourDateAndTime -> Error', err);
        }

        analytics.eventTrack('home_tour_schedule_confirmed', {
            Source: 'home_tours_detail_date_time_tab',
            board_id: activeBoard?.id
        });

        try {
            await addBoardCollaboration({
                boardId: activeBoard.id,
                itemId: tour.id,
                content: {
                    ...tour.content,
                    ...content,
                },
                model: 'BoardCollaboration',
                type: 'schedule_viewing_confirmed',
                visibleToAffiliate: true,
                visibleToLO: true,
            });
        } catch (err) {
            logger.warn('scheduleTourDateAndTime -> Create schedule confirmed board collaboration -> Error', err);
        }
    }

    function reviewAndSignClick() {
        analytics.eventTrack('robin_home_tour_detail_review_and_sign_button_click', {
            Source: 'home_tours_detail_documents_tab',
        });
    }

    function handleTabChange(e, value) {
        setSelectedTab(value);
        const tab = Object.keys(tabs)
            .reduce((result, t)=> {
                if(tabs[t] === value) {
                    result = t;
                }
                return result;
            }, null);
        if(tab && tab !== queryStrings.tab) {
            history.push({
                search: '?' + new URLSearchParams({ ...queryStrings, tab }).toString(),
            });
        }
        switch(value) {
            case TAB_DATE_AND_TIME:
                analytics.eventTrack('robin_home_tour_detail_date_time_tab_click', {
                    Source: 'home_tours_modal',
                    board_id: activeBoard?.id,
                });
                break;

            case TAB_DOCUMENTS:
                analytics.eventTrack('robin_home_tour_detail_documents_tab_click', {
                    Source: 'home_tours_detail_documents_tab',
                    board_id: activeBoard?.id,
                });
                break;

            case TAB_HOMES:
                analytics.eventTrack('robin_home_tour_detail_home_tab_click', {
                    Source: 'home_tours_modal',
                    board_id: activeBoard?.id,
                });
                break;

            case TAB_NOTES:
                analytics.eventTrack('robin_home_tour_detail_notes_tab_click', {
                    Source: 'home_tours_modal',
                    board_id: activeBoard?.id,
                });
                break;

            default:
                break;
        }
    }

    useEffect(()=> {
        if(!_.isEmpty(tours) && !tour){
            onTourChange(null, tours[0]);
        }
    },[tours]);

    return (
        <>
            <LRModal
                className="TourDetailsModal-Root"
                fullScreen
                hideCloseButton
                dialogContentClasses={{ root: 'TourDetailsModalContainer' }}
                maxWidth="100%"
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'left',
                }}
                open={open}
                {...props}
            >
                <div
                    className="TourDetailsModal-Content"
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        maxHeight: '100%',
                        overflow: 'hidden',
                    }}
                    dialogContentProps={{
                        style: {
                            padding: '0 !important',
                        }
                    }}
                >
                    <Header
                        tour={tour}
                        tours={tours}
                        onEditClick={onEditTourClick}
                        onCloseClick={onClose}
                        onCancelTourClick={onCancelTourClick}
                        onTourChange={onTourChange}
                        {...headerProps}
                    />

                    <div
                        style={{
                            height: `calc(100% - ${isIPadDown && !tour?.content.cancelled ? '114' : '57'}px)`,
                            position: 'relative',
                        }}
                    >
                        <Grid
                            container
                            style={{
                                position: 'absolue',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                height: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            {/* Left Column */}
                            {!isMobile && (
                                <Grid
                                    item
                                    xs
                                    style={{
                                        height: '100%',
                                    }}
                                >
                                    <Map
                                        listings={listings}
                                        hoveredListing={hoveredListing}
                                        onListingSelect={(listing)=> {
                                            setHoveredListing(listing);
                                        }}
                                    />
                                </Grid>
                            )}

                            {/* Right Column */}
                            <Grid
                                container
                                item
                                xs
                                style={{
                                    maxWidth: !isMobile && '571px',
                                    backgroundColor: (~[TAB_DATE_AND_TIME, TAB_DOCUMENTS].indexOf(selectedTab) && isMobile) ? '#fff' : '#eee',
                                    borderLeft: '1px solid #ddd',
                                }}
                                direction="column"
                            >
                                <Grid item>
                                    <TourDetailsAppBarHeader tour={tour} tourId={tourId} onMapToggle={()=> {
                                        setMobileView('map');
                                    }}/>
                                    <TourDetailsTabs
                                        tour={tour}
                                        notes={notes}
                                        selectedTab={selectedTab}
                                        onChange={handleTabChange}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: theme.spacing(isIPadDown ? 2 : 6),
                                            paddingRight: theme.spacing(isIPadDown ? 2 : 6),
                                            paddingTop: theme.spacing(isIPadDown ? 2 : 5),
                                            paddingBottom: theme.spacing(isIPadDown ? 2 : 5),
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            overflow: 'auto',
                                            maxHeight: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        {selectedTab === TAB_HOMES && (
                                            <TourDetailsHomes
                                                boardCards={listings}
                                                highlightBoardCard={hoveredListing}
                                                onCardHover={(e, boardCard)=> (setHoveredListing(boardCard))}
                                                onCardClick={(e, boardCard)=> {
                                                    analytics.eventTrack('robin_home_tour_detail_card_click', {
                                                        Source: 'home_tours_modal',
                                                        board_id: activeBoard?.id
                                                    });
                                                    history.push(`${match.url}/cards/${boardCard.id}`);
                                                }}
                                            />
                                        )}
                                        {selectedTab === TAB_DATE_AND_TIME && (
                                            <TourDetailsDateAndTime
                                                tour={tour}
                                                onEditClick={onEditTourClick}
                                                onTourTimeAndDateSelected={scheduleTourDateAndTime}
                                                onScheduleReadyToggle={onScheduleReadyToggle}
                                                schedulingDateAndTime={schedulingDateAndTime}
                                                setSchedulingDateAndTime={setSchedulingDateAndTime}
                                            />
                                        )}
                                        {selectedTab === TAB_NOTES && (<TourDetailsNotes notes={notes} tour={tour} />)}
                                        {selectedTab === TAB_DOCUMENTS && tour?.meta.pead_required && (
                                            <TourDetailsDocuments
                                                tour={tour}
                                                onReviewAndSignClick={reviewAndSignClick}
                                            />
                                        )}
                                    </div>
                                </Grid>

                                {!isIPadDown && selectedTab === TAB_NOTES && (
                                    <Grid item style={{ marginBottom: theme.spacing(2) }}>
                                        <AppBar
                                            color="inherit"
                                            position="static"
                                            elevation={0}
                                            style={{
                                                background: 'transparent',
                                                paddingLeft: theme.spacing(isIPadDown ? 0 : 3),
                                                paddingRight: theme.spacing(isIPadDown ? 0 : 3),
                                            }}
                                        >
                                            <Toolbar>
                                                <LRButton variant="contained" color="primary" fullWidth onClick={()=> (setOpenAddNoteModal(true))}>
                                                    Add a note
                                                </LRButton>
                                            </Toolbar>
                                        </AppBar>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </div>

                    {isIPadDown && !tour?.content.cancelled && match && (
                        <Footer
                            tour={tour}
                            hideAddNoteButton={selectedTab !== TAB_NOTES}
                            onEditClick={onEditTourClick}
                            onCancelTourClick={onCancelTourClick}
                            onAddNoteClick={()=> (setOpenAddNoteModal(true))}
                            readyToSchedule={readyToSchedule}
                            selectedTab={selectedTab}
                            scheduleDateAndTime={()=> setSchedulingDateAndTime(true)}
                            {...footerProps}
                        />
                    )}
                </div>
            </LRModal>


            {
                isMobile && mobileView === 'map' && (
                    <LRModal
                        fullScreen
                        maxWidth="100%"
                        TransitionComponent={Slide}
                        TransitionProps={{
                            direction: 'left',
                        }}
                        open={mobileView === 'map'}
                        dialogContentProps={{
                            style: {
                                height: '100%'
                            }
                        }}
                    >

                        <AppBar
                            color={isMobile ? 'inherit' : 'transparent'}
                            elevation={0}
                            style={{
                                borderBottom: isMobile && '1px solid #ddd',
                            }}
                        >
                            <Toolbar>
                                <Grid container justify="space-evenly" alignItems="center">
                                    <Grid item xs></Grid>

                                    <Grid item xs style={{ textAlign: 'center' }}>
                                        <div
                                            style={{
                                                display: 'inline-block',
                                            }}
                                        >
                                            {isMobile && (
                                                <Typography variant="body2">
                                                    Map
                                                </Typography>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs style={{ textAlign: 'right' }}>
                                        <IconButton onClick={()=> {
                                            setMobileView('list');
                                        }} title="Close">
                                            <Close />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>

                        <Map
                            listings={listings}
                            hoveredListing={hoveredListing}
                            onHover={(listing)=> {
                                setHoveredListing(listing);
                            }}
                            onListingSelect={(listing)=> {
                                setSelectedListing(listing);
                                setShowDrawer(true);
                            }}
                            selectedListing={selectedListing}
                        />

                        <MobileDrawer
                            showDrawer={showDrawer}
                            closeDrawer={()=> setShowDrawer(false)}
                            listings={listings}
                            selectedListing={selectedListing}
                            onSelectListing={(listing)=> setSelectedListing(listing)}
                            hoveredListing={hoveredListing}
                        />

                    </LRModal>
                )
            }



            {match && (
                <>
                    <Route
                        path={`${match.url}/cards/:cardId`}
                        render={()=> <BoardCardModal open={true} onClose={()=> { history.push(match.url); }} listings={listings}/>}
                    />

                    <Route
                        path={match?.url && `${match.url}/edit`}
                        children={({ match: routeMatch })=> {

                            return (
                                Boolean(routeMatch) && <ScheduleATourModal
                                    tour={tour}
                                    open={Boolean(routeMatch)}
                                    onClose={()=> {
                                        history.push(match.url);
                                    }}
                                />
                            );
                        }}
                    />
                </>
            )}
            {openCancelConfirmationModal && (
                <CancelTourConfirmationModal
                    title="Are you sure you’d like to cancel this tour?"
                    open={openCancelConfirmationModal}
                    onConfirm={(e)=> {
                        onCancelTour(e, tour);
                        analytics.eventTrack('robin_home_tour_cancelled', {
                            Source: 'home_tours_modal',
                            board_id: activeBoard?.id
                        });
                        setOpenCancelConfirmationModal(false);
                    }}
                    onCancel={()=> {
                        setOpenCancelConfirmationModal(false);
                    }}
                />
            )}
            <LRAddNoteModal
                cobuyersToExclude={cobuyersToExclude}
                itemId={tour?.id}
                open={openAddNoteModal}
                onClose={()=> (setOpenAddNoteModal(false))}
                addBoardCollaboration={addNote}
                eventSlug="schedule_tours"
            />
        </>
    );
});
TourDetailsModal.propTypes = {
    ...LRModal.propTypes || {},
};
