import Promise from 'bluebird';
import _ from 'lodash';
import axios from 'axios';
import Logger from 'js-logger';
import simpleCypher from '../simple-cypher';
import babou from 'babou-js';

const logger = Logger.get('getListingsForAddress');

const benoitAxios = axios.create({
    baseURL: `${process.env.REACT_APP_BENOIT_URL}`,
});

export const getListingsForAddress = async (formatedAddress)=> {
    try {
        const response = await babou.searchListings(
            [
                {
                    formatted_term: formatedAddress,
                    search_term: formatedAddress,
                    search_type: 'full_address',
                },
            ],
            {
                limit: 25,
                include_listings: true,
                include_listing_stats: false,
                active_statuses: ['Active', 'Pending'],
            }
        );
        if(!response.data.page?.listings) {
            return [];
        }
        return Promise.map(response.data.page.listings, async (listing)=> {
            const id = simpleCypher.encode(listing.LRSource, listing.id);
            const response = await benoitAxios.get(`/v1/listings/${id}`, { params: { forRobin: true } });
            listing = response.data.data;
            listing.open_houses = _(listing.open_houses)
                .uniqBy('id')
                .orderBy(['date_iso'], ['asc'])
                .value();
            listing.photos = listing.photos.filter(
                (photo)=>
                    !~photo.toLowerCase()
                        .indexOf('.doc') &&
                    !~photo.toLowerCase()
                        .indexOf('.docx') &&
                    !~photo.toLowerCase()
                        .indexOf('.pdf')
            );

            return listing;
        });
    } catch (e) {
        logger.error(e);
    }
};
