import React from 'react';
import styled from 'styled-components';
import { Box, Divider, Typography, withTheme } from '@material-ui/core';
import LRAvatar from '../../../components/LRAvatar';
import { useResponsiveBreakpoint } from '../../../hooks';

const StyledAvatar = withTheme(styled(LRAvatar)`
    width: ${({ theme })=> theme.spacing(10)}px;
    height: ${({ theme })=> theme.spacing(10)}px;
    margin: ${({ theme })=> theme.spacing(3)}px 0;
`);

const Name = styled(Typography)``;

const Company = styled(Typography)``;

const License = withTheme(styled(Typography)`
    color: ${({ theme })=> theme.palette.lr_colors.border_grey_lighter};
`);

const StyledDivider = withTheme(styled(Divider)`
    width: ${({ theme })=> theme.spacing(3)}px;
    color: ${({ theme })=> theme.palette.lr_colors.border_grey_lighter};
    height: 2px;
    margin: 10px 0;
`);

const PhoneNumber = styled(Typography)``;

const Email = styled(Typography)`
    word-break: break-all;
    padding-left: 32px; 
    padding-right: 32px;
    text-align: center;
`;

function AgentInfo({ agent }) {
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;
    return (
        <Box display="flex" flexDirection="column" alignItems="center" pb={4}>
            <Typography variant="h6">Welcome to Robin!</Typography>
            <StyledAvatar user={agent} />
            <Name variant="h6">{agent.name}</Name>
            <Company>{agent.company}</Company>
            <License variant="caption">License #{agent.normalized_license_number}</License>
            <StyledDivider />
            <PhoneNumber component="a" href={isMobile ? `tel:${agent.phone_formatted}` : undefined}>
                {agent.phone_formatted}
            </PhoneNumber>
            <Email 
                component="a" 
                href={`mailto:${agent.email}`} 
            >
                {agent.email}
            </Email>
        </Box>
    );
}

export default AgentInfo;
