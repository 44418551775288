import React from 'react';
import { useOnboarding } from '../../store';
import { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { waitForElement, delay, simulateClick, addEventListener, scrollToElement } from '../../utils';
import Confetti from '../../../Confetti';
import { useStore, useActiveBoard } from '../../../../mobx-store';
import analytics from '../../../../services/analytics';
import { useHistory } from 'react-router-dom';
import { useMixpanelEvents } from './utils';
import { LoggedInWrapperContext } from 'components/LoggedInWrapper';
import { PreferencesModal } from 'components/PreferencesModal';

function commonSteps(tour, { setShowOverlay }) {
    function trackSkipEvent() {
        analytics.eventTrack('robin_onboarding_add_home_from_saved_search_skipped', {
            source: 'first_run_onboarding'
        });
    }
    function dismissEvent() {
        analytics.eventTrack('robin_onboarding_edit_saved_search_guide_proceed', {
            source: 'first_run_onboarding'
        });
        tour.show('highlight-listpack-listing-card', true);
    }
    return [
        {
            id: 'edit-option-highlight',
            title: 'You can edit your search',
            text: 'With Robin, you can create multiple saved searches with different preferences, and edit those preferences anytime.',
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.tour-fre-edit-highlight', { timeout: 40000 })
                    .then(()=> setShowOverlay(false));
            },
            attachTo: {
                element:'.tour-fre-edit-highlight',
                on: 'bottom'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    if(overlay){
                        overlay.forEach((elem)=> {
                            elem.addEventListener('click', dismissEvent);
                        });
                    }
                }
            }
        },
        {
            id: 'edit-optional-step',
            attachTo: {
                element:'.lane-modal-dialog-content',
                on: 'right'
            },
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.lane-modal-dialog-content', { timeout: 2000 })
                    .then(()=> tour.options.useModalOverlay = false)
                    .catch((err)=> {
                        tour.options.useModalOverlay = true;
                        tour.next();
                    });
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                },
                hide: ()=> {
                    setShowOverlay(false);
                }
            },
            classes: 'hidden',
        },
        {
            id: 'highlight-listpack-listing-card',
            title: 'Try adding this home to your board',
            text: 'Hover your mouse over the home and add it to any section of your board (you can remove it later). ',
            attachTo: {
                element: '.tour-target-listpack-listing-card',
                on: 'left',
            },
            beforeShowPromise() {
                setShowOverlay(true);
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-target-listpack-listing-card', { timeout: 40000 });
            },
            highlightClass: 'pulse-move-card-button',
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: function() {
                    setShowOverlay(false);
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    if(overlay){
                        overlay.forEach((elem)=> {
                            elem.removeEventListener('click', dismissEvent);
                            elem.addEventListener('click', ()=> {
                                tour.show('free-step', true);
                                trackSkipEvent();
                            });
                        });
                    }

                },
            }
        },
        {
            id: 'optional-rpp-view',
            attachTo: {
                element:'.tour-listpack-listing-detail',
                on: 'right'
            },
            beforeShowPromise() {
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-listpack-listing-detail', { timeout: 2000 })
                    .then(()=> tour.options.useModalOverlay = false);
            },
            classes: 'hidden'
        },
        {
            id: 'free-step',
            beforeShowPromise() {
                tour.options.useModalOverlay = false;
                return delay(400);
            },
            classes: 'hidden'
        },
    ];
}

function createLastWhinerStep(tour, { history, setShowOverlay }) {

    function handleDismiss() {
        analytics.eventTrack('robin_onboarding_search_area_dialog_dismiss');
    }

    return [
        {
            id: 'rpp-nurture-first',
            title: '🔍 Find homes you like',
            text: 'Based on that listing, here’s a quick search for similar homes. Broaden or narrow your filters to see all active listings that suit your needs.',
            beforeShowPromise() {
                return waitForElement('.tour-rpp-nurture', { timeout: 40000 });
            },
            attachTo: {
                element:'.tour-rpp-nurture',
                on: 'top-start'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [20, 0]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            classes: 'shepperd-arrow-bottom-start-override',

            advanceOn: {
                event: 'click',
                selector: '.shepherd-modal-overlay-container',
            },
            when: {
                show: ()=> {
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> elem.addEventListener('click', handleDismiss));
                    setShowOverlay(false);
                },
            },
        },
        {
            id: 'rpp-nurture-second',
            title: 'You can save a search ☝️',
            text: 'Saved searches in Robin are smart, showing you the latest homes matching your preferences as soon as they hit the market. Go ahead and save this one to see.',
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.tour-save-listpack-search', { timeout: 40000 });
            },
            attachTo: {
                element: '.tour-save-listpack-search',
                on: 'bottom-end',
            },
            highlightClass: 'pulsing-button',

            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },

            advanceOn: {
                event: 'click',
                selector: '.shepherd-modal-overlay-container',
            },
            when: {
                show: ()=> {
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> {
                        elem.removeEventListener('click', handleDismiss);
                    });
                    setShowOverlay(false);
                },
            },
        },
        {
            id: 'rpp-nurture-skip',
            title: 'Want to see what else you can do?',
            text: 'You can always search for homes with Robin and even create multiple saved searches. But there’s much more to this app than an awesome search tool - you can collaborate with co-buyers, take notes on homes, save ideas for later, make appointments to see homes you love... Want to take it for a spin?',
            beforeShowPromise() {
                setShowOverlay(true);
            },
            buttons: [
                {
                    text: 'Maybe later',
                    classes:'shepherd-text-button',
                    action: ()=> {
                        tour.show('free-step');
                    },
                },
                {
                    text: 'Show me!',
                    action: ()=> {
                        tour.complete();
                        history.replace('/boards');
                    },
                }
            ],
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_onboarding_search_not_saved_dialog',
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> {
                        elem.removeEventListener('click', handleDismiss);
                    });
                },
                hide: function() {
                    tour.options.useModalOverlay = true;
                },
            },
            classes: 'shepherd-wide-tooltip',
        },
        {
            id: 'save-search-modal',
            attachTo: {
                element:'.tour-rpp-nurture',
                on: 'bottom'
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            classes: 'hidden',
            beforeShowPromise() {
                return waitForElement('.tour-rpp-nurture', { timeout: 40000 })
                    .then(()=> {
                        tour.options.useModalOverlay = false;
                    })
                    .catch(()=> {
                        tour.options.useModalOverlay = true;
                        tour.next();
                    });
            },
            analytics: {
                nextEvent: 'robin_onboarding_new_search_saved',
            },
            when: {
                show: ()=> {
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> {
                        elem.removeEventListener('click', handleDismiss);
                    });
                },
                hide: function() {
                    tour.options.useModalOverlay = true;
                }
            }
        },

        ...commonSteps(tour, {setShowOverlay})
    ];
}

function createInitalWhinersSteps(tour, { history, user, userRole, listpacks, setShowPreferencesModal, setShowOverlay, goToListpack }) {

    return [
        {
            id: 'set-preferences',
            beforeShowPromise() {
                setShowPreferencesModal(true);
                return waitForElement('.tour-set-preferences-modal', { timeout: 20000 });
            },
            attachTo: {
                element:'.tour-set-preferences-modal',
                on: 'bottom'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            // analytics: {
            //     nextEvent: 'robin_onboarding_browsing_preferences_submit',
            // },
            classes: 'hidden',
            when: {
                hide: ()=> {
                    setShowPreferencesModal(true);
                }
            }
        },
        {
            id: 'highlight-saved-search',
            title: 'Check out your first saved search!',
            text: 'You can always search for homes from the top of this column, and save as many searches as you like based on your preferences. Saved searches are smart, so you’ll see them update when new listings hit the market, and can edit them anytime!',
            beforeShowPromise() {
                setShowPreferencesModal(true);
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-target-my-listpack-card', { timeout: 40000 })
                    .then(()=> setShowPreferencesModal(false))
                    .catch(()=> setShowPreferencesModal(true));
            },
            buttons: [
                {
                    text: 'View homes',
                    action: ()=> {
                        analytics.eventTrack('robin_onboarding_view_first_saved_search_submit', {
                            source: 'saved_search_card'
                        });
                        tour.next();
                        goToListpack();
                    }
                }
            ],
            attachTo: {
                element:'.tour-target-my-listpack-card',
                on: 'left'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 24]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: ()=> {
                    tour.options.useModalOverlay = true;
                    setShowPreferencesModal(false);
                },
            }
        },
        ...commonSteps(tour, { history, user, userRole, listpacks, setShowPreferencesModal, setShowOverlay })
    ];
}

function createSteps(tour, options) {
    const { triggerOrigin } = options;

    const steps = {
        sidenav: createInitalWhinersSteps,
        rpp: createLastWhinerStep
    };

    return steps[triggerOrigin](tour, options);
}

export const NurtureFirstRunFlowDesktop = observer(function FirstRunFlowDesktop() {
    const { UserStore } = useStore();
    const { user, userPreferences } = UserStore;
    const onboarding = useOnboarding();
    const [showConfetti, setShowConfetti] = useState(false);
    const history = useHistory();
    const activeBoard = useActiveBoard();
    const { onOpenLeftSidebar } = useContext(LoggedInWrapperContext);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState();
    const currentListpack = useRef(null);

    useMixpanelEvents();

    useEffect(()=> {
        if( onboarding?.options?.triggerOrigin === 'sidenav'){
            history.replace('/boards');
        }

    }, [history]);

    const goToListpack = useCallback(
        ()=> {
            if(currentListpack.current?.id){
                history.replace(`/boards/listpack/${currentListpack.current?.id}/listings`);
            }
        },
        [currentListpack]
    );
    
    useEffect(()=> {
        const steps = createSteps(onboarding.tour, {
            triggerOrigin: onboarding?.options?.triggerOrigin,
            history,
            user: UserStore.user,
            userRole: UserStore.usersBoardRole,
            setShowPreferencesModal,
            setShowOverlay,
            goToListpack,
        });
        onboarding.addSteps(steps);
        onboarding.start();
        UserStore.unverifiedOnboardingInProgress = true;
        return function() {
            onboarding.reset();
        };

    }, [onboarding, UserStore, history]);

    useEffect(()=> {
        let timeout = null;
        function handleComplete() {
            setShowConfetti(true);
            const onboardingCompletedInfo = { movedCard: onboarding?.userMovedCard };
            UserStore.completeAsyncFirstRunOnboarding(onboardingCompletedInfo);
            UserStore.unverifiedOnboardingInProgress = false;
            onOpenLeftSidebar(true);
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        }

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding, UserStore, onOpenLeftSidebar]);

    async function handleOnSubmit() {
        
        analytics.eventTrack('robin_onboarding_browsing_preferences_submit', {
            source: 'preferences_modal'
        });

        // create listpack with the
        const { areaIds, bathrooms, bedrooms, price_min, price_max } = userPreferences;

        const payload = {
            userId: user.id,
            boardId: activeBoard.id,
            name: `${user?.first_name}’s first saved search 🎉`,
            areaIds: areaIds,
            notification_settings: {
                alert_frequency: 'daily'
            },
            fields: {
                bathrooms: bathrooms,
                bedrooms: bedrooms,
                price_min: price_min,
                price_max: price_max,
                homesize_min: null,
                homesize_max: null,
                stories: null,
                lotsize_min: null,
                lotsize_max: null,
                year_built_min: null,
                year_built_max: null,
                school_score_min: null,
                hometype: {
                    single_family: false,
                    multi_family: false,
                    condo_townhome: false,
                    mobile_manufactured: false,
                    land: false,
                },
                tags: {
                    with_great_schools: false,
                    with_open_houses: false,
                    with_virtual_tours: false,
                    with_views: false,
                    with_upgraded_kitchens: false,
                    with_pool: false,
                },
            }
        };

        currentListpack.current = await activeBoard.createOrUpdateListpack(payload);
        setShowPreferencesModal(false);
        onboarding.next();
    }

    async function handleOnSkip() {
        setShowPreferencesModal(false);
        onboarding.complete();
    }

    return <>
        <PreferencesModal
            open={showPreferencesModal}
            handleOnSubmit={handleOnSubmit}
            handleOnSkip={handleOnSkip}
        />
        {
            showOverlay && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        // HACK: guarantee that this is above anything else,
                        //material ui have some weird super high zindexes like > 1200 and sheperd elements start at  9997
                        zIndex: 9999,
                        background: 'rgba(0,0,0, 0.5)'
                    }}
                ></div>
            )
        }
        { showConfetti && (
            <Confetti iterationCount={2}/>
        )}
    </>;
});
