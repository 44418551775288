import React from 'react';
import LRButton from '../LRButton';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';

const StyledLRButton = styled(LRButton)`
    width: 100%;
    height: 48px;
    font-weight: normal;
    border: 1px solid #BBBBBB;
`;

export function FacebookBtn({
    scope = { scope: 'public_profile,email' },
    apiParams = { fields: 'name, email' },
    onClick = ()=> { },
    onLoginSuccess = ()=> {},
    onLoginFailed = ()=> {},
    loading,
    signUp = false,
}) {
    async function handleFacebookLogin() {
        onClick();
        window.FB.login((authResponse)=> {
            window.FB.api('/me', apiParams, async function(response) {

                const {error} = response;

                if(error) {
                    onLoginFailed();
                    return;
                }

                const nameParts = response.name.split(' ');
                const userInfo = {
                    email: response.email,
                    first_name: nameParts[0],
                    last_name: nameParts[nameParts.length - 1],
                    meta: {
                        auth_providers: {
                            facebook_user_id: response.id,
                        },
                    },
                };
                const { accessToken } = authResponse.authResponse;
                const payload = { userInfo, auth: { token: accessToken, method: 'facebook' } };

                onLoginSuccess(payload);
            });
        }, scope);
    }

    return (
        <StyledLRButton onClick={handleFacebookLogin}>
            <Box width={1} display="flex">
                <Box display="flex" alignItems="center" marginLeft={1}>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 8.75842C16 4.27562 12.4183 0.641602 8 0.641602C3.58172 0.641602 0 4.27562 0 8.75842C0 12.8097 2.92547 16.1677 6.75 16.7766V11.1047H4.71875V8.75842H6.75V6.97018C6.75 4.9359 7.94438 3.81223 9.77172 3.81223C10.647 3.81223 11.5625 3.97076 11.5625 3.97076V5.96826H10.5538C9.56 5.96826 9.25 6.59398 9.25 7.23588V8.75842H11.4688L11.1141 11.1047H9.25V16.7766C13.0745 16.1677 16 12.8097 16 8.75842Z" fill="#1877F2"/>
                    </svg>
                </Box>
                {loading && (
                    <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                        <CircularProgress size={24} color="secondary" />
                    </Box>
                )}
                {!loading && <Box flex="1">{signUp ? 'Sign up' : 'Login'} with Facebook</Box>}
            </Box>
        </StyledLRButton>
    );
}
