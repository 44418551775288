import Logger from 'js-logger';
import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
    Box,
    Grid,
    FormGroup,
    TextField,
    InputAdornment,
    Hidden,
    Divider,
    TextareaAutosize,
    FormControl,
    Typography,
    useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import { DAYS_ON_MARKET } from '../../../../constants';
import LRInput from '../../../../components/LRInput';
import { useForm, useFormContext, FormContext, Controller, useFieldArray } from 'react-hook-form';
import { LRRangeDropdown } from '../../../../components/LRRangeDropdown';
import { useRFHInput, useResponsive, useResponsiveBreakpoint } from '../../../../hooks';
import { PRICE_RANGE, SQUARE_FT_VALUES, LOT_SIZE_VALUES, YEAR_RANGE } from './constants';
import { LRToggleButtonGroup } from '../../../../components/LRToggleButtonGroup';
import LRDropdown from '../../../../components/LRDropdown';
import { LRRectCheckbox } from '../../../../components/LRRectCheckbox';
import { LRAreaAutocomplete } from '../../../../components/LRAreaAutocomplete';
import { LRChip } from '../../../../components/LRChip';
import { TextField as LRTextField } from '../../../../components/TextField';
import { Room, Search } from '@material-ui/icons';
import { LRSwitch } from '../../../../components/LRSwitch';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import { useStore } from '../../../../mobx-store';
import analytics from 'services/analytics';
import UnspecifiedListingsStoriesCheckbox from 'components/UnspecifiedListingsStoriesCheckbox';
import theme from 'theme';

const WEB_SPACING_GRID = 4;


const StyledFormSectionContainer = withTheme(styled.div`
    background: #fff;
    padding: 32px 40px;
    width: 100%;

    ${({ theme })=> theme.breakpoints.down('xs')} {
        padding: 32px 16px;
    }
`);

const StyledTextField = withTheme(styled(TextField)`
    background: #fff;

    input {
        font-size: 16px;
    }

    & .MuiOutlinedInput-notchedOutline {
        border-radius: 2px;
        border-color: #dddddd;
    }
    & ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 16px;
    }
    & ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 16px;
    }

    & .MuiOutlinedInput-input {
        padding: 14px;
    }
`);

const StyledTitleSpan = withTheme(styled.span`
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 30px;
    ${({ theme })=> theme.breakpoints.down('xs')} {
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.15px;
    }
`);

const StyledLRInput = styled(LRInput)`
    & .MuiOutlinedInput-input {
        padding: 14px;
    }
`;

const LRToggleButtonGroupOptionStyle = {
    width: '100%',
    color: '#1a1a1a',
    textTransform: 'inherit',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
};

const CommentBox = withTheme(styled(TextareaAutosize)`
    padding: ${({ theme })=> theme.spacing(2)}px;
    border: 1px solid ${({ theme })=> theme.palette.lr_colors.border_grey};
    border-radius: 2px;
    resize: none;
    outline: none;
    font-family: inherit;
    flex-grow: 1;
`);

function AreaSection({ initialValues }) {
    const isInitalRender = React.useRef(true);
    const areaAutocompleteRef = useRef();
    const formMethods = useFormContext();
    const { fields, append, prepend, remove, swap, move, insert, errors } = useFieldArray({
        name: 'areaIds',
    });

    function handleAreaSelect(area) {
        formMethods.clearError('areaIds');
        append({ value: area.id });
        areaAutocompleteRef.current.clearSearch();
    }

    function handleRemoveArea(index) {
        remove(index);
        formMethods.triggerValidation();
    }

    useEffect(()=> {
        if(fields.length === 0) {
            formMethods.register('areaIds', { validate: (value)=> value && value.length > 0 });
            formMethods.setValue('areaIds', []);
            if(!isInitalRender.current) {
                formMethods.triggerValidation();
            }
        } else {
            formMethods.unregister('areaIds');
        }
    }, [
        fields,
        formMethods.register,
        formMethods.unregister,
        formMethods.setValue,
        formMethods.triggerValidation,
        formMethods,
    ]);

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
            case 'zip':
                iconElement = <BusinessIcon fontSize="small" />;
                break;
            case 'neighborhood':
                iconElement = <HomeIcon fontSize="small" />;
                break;
            case 'school':
                iconElement = <SchoolIcon fontSize="small" />;
                break;
            default:
                iconElement = <Room fontSize="small" />;
                break;
        }
        return iconElement;
    }

    function filterSuggestions(suggestion) {
        return !fields.find((area)=> {
            return area.value === suggestion.id;
        });
    }

    return (
        <Box>
            <Grid container justify="space-between" spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Box mb={2}>
                        <LRAreaAutocomplete
                            ref={areaAutocompleteRef}
                            onSuggestionSelect={handleAreaSelect}
                            renderInput={(props)=> {
                                return (
                                    <StyledTextField
                                        variant="outlined"
                                        color="primary"
                                        placeholder="Search By City, Zip, Neighborhood or School"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Box color="#DADADA" display="flex">
                                                        <Search fontSize="small" />
                                                    </Box>
                                                </InputAdornment>
                                            ),
                                        }}
                                        size="small"
                                        fullWidth
                                        error={formMethods.errors['areaIds']}
                                        {...props}
                                    />
                                );
                            }}
                            suggestionRenderOption={(suggestion)=> {
                                return (
                                    <Box display="flex" style={{ cursor: 'pointer' }}>
                                        <div>{renderSuggestionIcon(suggestion.type)}</div>
                                        <Box ml={1}>{suggestion.id}</Box>
                                    </Box>
                                );
                            }}
                            errorTooltipContent={'Sorry, it looks like we can’t find this area.'}
                            rootContainerProps={{ className: 'tour-target-listpack-area' }}
                            filterSuggestions={filterSuggestions}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} container spacing={1}>
                    {fields.map((area, index)=> (
                        <Grid item key={area.id}>
                            <LRChip label={area.value} onDelete={()=> handleRemoveArea(index)} />
                            <input
                                type="hidden"
                                defaultValue={area.value}
                                name={`areaIds[${index}]`}
                                ref={formMethods.register()}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}

function PriceSection() {
    const formMethods = useFormContext();

    const { value: priceMin, handleChange: setPriceMin } = useRFHInput('fields.price_min', formMethods);
    const { value: priceMax, handleChange: setPriceMax } = useRFHInput('fields.price_max', formMethods);

    return (
        <Box maxWidth={[1, 350]}>
            <LRRangeDropdown
                minValue={priceMin}
                maxValue={priceMax}
                options={PRICE_RANGE}
                onMinValueChange={(option)=> {
                    setPriceMin(option);
                }}
                onMaxValueChange={(option)=> {
                    setPriceMax(option);
                }}
                compareAsNumbers={true}
            ></LRRangeDropdown>
        </Box>
    );
}

function BasicsSection() {
    const formMethods = useFormContext();
    const r = useResponsive();

    const { value: homeSizeMin, handleChange: handleHomeSizeMinChange } = useRFHInput(
        'fields.homesize_min',
        formMethods
    );
    const { value: homeSizeMax, handleChange: handleHomeSizeMaxChange } = useRFHInput(
        'fields.homesize_max',
        formMethods
    );

    const { value: lotSizeMin, handleChange: handleLotSizeMinChange } = useRFHInput('fields.lotsize_min', formMethods);
    const { value: lotSizeMax, handleChange: handleLotSizeMaxChange } = useRFHInput('fields.lotsize_max', formMethods);
    const { value: stories, handleChange: handleStoriesChange } = useRFHInput('fields.stories', formMethods);
    const { value: includeListingsWithUnspecifiedData, handleChange: handleIncludeListingsWithUnspecifiedData } = useRFHInput('fields.include_listings_with_unspecified_data', formMethods);
    const isXs = useResponsiveBreakpoint('xs');
    const theme = useTheme();

    const checkBoxStyle = {
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: '0.5px'
    };
    return (
        <Box>
            <Grid container spacing={isXs ? 2 : 5} alignItems="flex-start">
                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                            Beds
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify={r({ xs: 'flex-start', sm: 'flex-end' })}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="fields.bedrooms"
                            as={
                                <LRToggleButtonGroup
                                    fullWidth
                                    options={[
                                        { value: '0', label: 'Studio', style: LRToggleButtonGroupOptionStyle },
                                        { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
                                        { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
                                        { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
                                        { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
                                    ]}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                            Baths
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify={r({ xs: 'flex-start', sm: 'flex-end' })}>
                    <Grid item xs={12}>
                        <Controller
                            name="fields.bathrooms"
                            as={
                                <LRToggleButtonGroup
                                    fullWidth
                                    options={[
                                        { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
                                        { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
                                        { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
                                        { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
                                    ]}
                                />
                            }
                        />
                    </Grid>
                </Grid>

                {/* hometype */}
                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <div style={{...(isXs && {display: 'inline-flex', alignItems: 'flex-end'})}}>
                        <Typography variant='h6' style={{fontWeight: 450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                            Home Type
                        </Typography>
                        <Typography variant='body1' style={{fontWeight: 450, color: '#A1A1A1', ...(isXs && {marginTop: theme.spacing(2), marginLeft: theme.spacing(2)})}}>
                            Select all that apply
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={7} container justify={r({ xs: 'flex-start', sm: 'flex-end' })}>
                    <FormGroup>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}
                                style={checkBoxStyle}>
                                <Controller
                                    as={<LRRectCheckbox />}
                                    name="fields.hometype.single_family"
                                    label="Single Family"
                                    valueName="checked"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}
                                style={checkBoxStyle}>
                                <Controller
                                    as={<LRRectCheckbox />}
                                    name="fields.hometype.condo_townhome"
                                    label="Condo or Townhome"
                                    valueName="checked"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}
                                style={checkBoxStyle}>
                                <Controller
                                    as={<LRRectCheckbox />}
                                    name="fields.hometype.multi_family"
                                    label="Multi Family"
                                    valueName="checked"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}
                                style={checkBoxStyle}>
                                <Controller
                                    as={<LRRectCheckbox />}
                                    name="fields.hometype.mobile_manufactured"
                                    label="Mobile or Manufactured"
                                    valueName="checked"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}
                                style={checkBoxStyle}>
                                <Controller
                                    as={<LRRectCheckbox />}
                                    name="fields.hometype.land"
                                    label="Lot or Land"
                                    valueName="checked"
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </Grid>
                {/* hometype end */}
                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Square feet
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify="flex-end">
                    <Grid item xs={12}>
                        <LRRangeDropdown
                            minValue={homeSizeMin}
                            maxValue={homeSizeMax}
                            options={SQUARE_FT_VALUES}
                            onMinValueChange={(option)=> {
                                handleHomeSizeMinChange(option);
                            }}
                            onMaxValueChange={(option)=> {
                                handleHomeSizeMaxChange(option);
                            }}
                            compareAsNumbers={true}
                        ></LRRangeDropdown>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Lot size
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify="flex-end">
                    <Grid item xs={12}>
                        <LRRangeDropdown
                            options={LOT_SIZE_VALUES}
                            minValue={lotSizeMin}
                            maxValue={lotSizeMax}
                            onMinValueChange={(option)=> {
                                handleLotSizeMinChange(option);
                            }}
                            onMaxValueChange={(option)=> {
                                handleLotSizeMaxChange(option);
                            }}
                            compareAsNumbers={true}
                        ></LRRangeDropdown>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Stories
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify="flex-end">
                    <Grid item xs={12}>
                        <LRToggleButtonGroup
                            value={stories}
                            onChange={(option)=> {
                                handleStoriesChange(option);
                                if(option === null) { //if label Any
                                    //reset fields.includeListingsWithUnspecifiedData
                                    handleIncludeListingsWithUnspecifiedData(false);
                                }
                            }}
                            options={[
                                { value: null, label: 'Any', style: LRToggleButtonGroupOptionStyle },
                                { value: '1', label: 'Single', style: LRToggleButtonGroupOptionStyle },
                                { value: '2', label: 'Multi', style: LRToggleButtonGroupOptionStyle },
                            ]}
                            fullWidth
                        ></LRToggleButtonGroup>
                    </Grid>
                </Grid>
                {stories && <Grid item xs={12}>
                    <UnspecifiedListingsStoriesCheckbox
                        value={includeListingsWithUnspecifiedData}
                        onChange={handleIncludeListingsWithUnspecifiedData}
                    />
                </Grid>}
            </Grid>
        </Box>
    );
}

function EverythingElseSection() {
    const formMethods = useFormContext();
    const isXs = useResponsiveBreakpoint('xs');
    const { value: yearBuiltMin, handleChange: handleYearBuiltMinChange } = useRFHInput(
        'fields.year_built_min',
        formMethods
    );
    const { value: yearBuiltMax, handleChange: handleYearBuiltMaxChange } = useRFHInput(
        'fields.year_built_max',
        formMethods
    );

    const { value: daysOnMarket, handleChange: handleDaysOnMarket } = useRFHInput(
        'fields.days_on_market',
        formMethods
    );

    const { value: keywords, handleChange: handleKeywordsChange } = useRFHInput(
        'fields.keywords',
        formMethods
    );

    const { value: mlsNumbers, handleChange: handleMlsNumbersChange } = useRFHInput(
        'fields.mls_numbers',
        formMethods
    );

    function onKeywordsChange(event) {
        const value = _.replace(_.trim(event.target.value), ',', '');
        const hasKeyword = keywords && keywords.filter((k)=> k.toLowerCase() === value.toLowerCase()).length > 0;
        if(!value || hasKeyword) {
            event.target.value = value;
            return;
        }
        handleKeywordsChange([...(keywords || []), value]);
        analytics.eventTrack('robin_keyword_filter_input', {keyword_input: value});
        event.target.value = '';
    }

    function handleDeleteKeyword(id) {
        const _keywords = keywords.filter((keyword)=> keyword !== id);
        handleKeywordsChange(_keywords);
    }

    function onMlsNumberChange(event) {
        const value = _.replace(_.trim(event.target.value), ',', '');
        const hasMls = mlsNumbers && mlsNumbers.filter((k)=> k.toLowerCase() === value.toLowerCase()).length > 0;
        if(!value || hasMls) {
            event.target.value = value;
            return;
        }
        handleMlsNumbersChange([...(mlsNumbers || []), value]);
        event.target.value = '';
        analytics.eventTrack('robin_mls_number_filter_added', {
            mls_number: value
        });
    }

    function handleDeleteMlsNumber(id) {
        const _mlsNumbers = mlsNumbers.filter((mlsNumber)=> mlsNumber !== id);
        handleMlsNumbersChange(_mlsNumbers);
    }

    const { value: schoolScoreMin, handleChange: setSchoolScoreMin } = useRFHInput(
        'fields.school_score_min',
        formMethods
    );

    const schoolOptions = [
        { value: null, label: 'No preference' },
        { value: 10, label: '1+' },
        { value: 20, label: '2+' },
        { value: 30, label: '3+' },
        { value: 40, label: '4+' },
        { value: 50, label: '5+' },
        { value: 60, label: '6+' },
        { value: 70, label: '7+' },
        { value: 80, label: '8+' },
        { value: 90, label: '9+' },
        { value: 100, label: '10+' },
    ];

    const r = useResponsive();
    return (
        <Box pb={4}>
            <Grid container alignItems="flex-start" spacing={isXs ? 2 : 3}>
                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Year built
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify="flex-end">
                    <Grid item xs={12}>
                        <LRRangeDropdown
                            options={YEAR_RANGE}
                            minValue={yearBuiltMin}
                            maxValue={yearBuiltMax}
                            onMinValueChange={(option)=> {
                                handleYearBuiltMinChange(option);
                            }}
                            onMaxValueChange={(option)=> {
                                handleYearBuiltMaxChange(option);
                            }}
                            compareAsNumbers={true}
                        ></LRRangeDropdown>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Days on market
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify="flex-end">
                    <Grid item xs={12}>
                        <LRDropdown
                            name="fields.days_on_market"
                            selectedOption={_.find(DAYS_ON_MARKET, ['value', daysOnMarket]) || DAYS_ON_MARKET[0]}
                            onOptionSelect={(option)=> handleDaysOnMarket(option.value)}
                            renderDisplayOption={({ label })=> label}
                            renderOption={({ label })=> label}
                            maxOptionListHeight="200px"
                            options={DAYS_ON_MARKET}
                            DropdownDisplayProps={{fontSize: '16px'}}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight:450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Must-haves
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={7} container justify="space-between">
                    <Grid item container xs={12} sm={12} alignItems="center">
                        <Box width={1}>
                            <Divider />
                        </Box>
                        <Grid item xs={10} sm={10}>
                            <Box py="18px" fontSize='body1.fontSize' style={{marginLeft: r({ xs: theme.spacing(1)})}}>Pool</Box>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box>
                                <Controller
                                    as={<LRSwitch />}
                                    name="fields.tags.with_pool"
                                    label="Pool"
                                    valueName="checked"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} alignItems="center">
                        <Box width={1}>
                            <Divider />
                        </Box>
                        <Grid item xs={10} sm={10}>
                            <Box py="18px" fontSize='body1.fontSize' style={{marginLeft: r({ xs: theme.spacing(1)})}}>View</Box>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box>
                                <Controller
                                    as={<LRSwitch />}
                                    name="fields.tags.with_views"
                                    label="View"
                                    valueName="checked"
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} sm={12} alignItems="center">
                        <Box width={1}>
                            <Divider />
                        </Box>
                        <Grid item xs={10} sm={10}>
                            <Box py="18px" fontSize='body1.fontSize' style={{marginLeft: r({ xs: theme.spacing(1)})}}>Virtual Tour</Box>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box>
                                <Controller
                                    as={<LRSwitch />}
                                    name="fields.tags.with_virtual_tours"
                                    label="Virtual Tour"
                                    valueName="checked"
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} sm={12} alignItems="center">
                        <Box width={1}>
                            <Divider />
                        </Box>
                        <Grid item xs={10} sm={10}>
                            <Box py="18px" fontSize='body1.fontSize' style={{marginLeft: r({ xs: theme.spacing(1)})}}>Open House</Box>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box>
                                <Controller
                                    as={<LRSwitch />}
                                    name="fields.tags.with_open_houses"
                                    label="Open House"
                                    valueName="checked"
                                />
                            </Box>
                        </Grid>
                        <Box width={1}>
                            <Divider />
                        </Box>
                    </Grid>

                </Grid>

                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight: 450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Great Schools rating
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container justify="flex-end">
                    <Grid item xs={12}>
                        <LRDropdown
                            name="fields.school_score_min"
                            selectedOption={_.find(schoolOptions, ['value', schoolScoreMin])}
                            onOptionSelect={(option)=> setSchoolScoreMin(option.value)}
                            renderDisplayOption={({ label })=> label}
                            renderOption={({ label })=> label}
                            maxOptionListHeight="200px"
                            placement="top"
                            options={schoolOptions}
                            DropdownDisplayProps={{fontSize: '16px'}}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={WEB_SPACING_GRID}>
                    <Typography variant='h6' style={{fontWeight: 450, ...(isXs && {marginTop: theme.spacing(2)})}}>
                        Keywords
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} container>
                    <Grid item xs={12}>
                        <LRTextField
                            name="fields.keywords"
                            type="text"
                            variant="outlined"
                            size="small"
                            placeholder="E.g. Office, fireplace, pool"
                            fullWidth={true}
                            inputProps={{
                                fontSize: 16
                            }}
                            labelProps={{
                                fontWeight: 'normal',
                                fontSize: 16,
                            }}
                            onKeyPress={(event)=> {
                                if(event.key === 'Enter' || event.charCode === 13) {
                                    onKeywordsChange(event);
                                }
                            }}
                            onChange={(event)=> {
                                if(_.includes([',', ' '], _.last(event.target.value))) {
                                    onKeywordsChange(event);
                                }
                            }}
                            onBlur={onKeywordsChange}
                        />
                    </Grid>
                    <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {keywords &&
                            keywords.map((keyword)=> (
                                <Box key={keyword} m="12px 12px 0 0">
                                    <LRChip
                                        label={keyword}
                                        onDelete={handleDeleteKeyword}
                                    />
                                </Box>
                            ))
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Box fontSize="20px">
                        MLS #
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} container>
                    <Grid item xs={12}>
                        <LRTextField
                            name="fields.mlsNumbers"
                            type="text"
                            variant="outlined"
                            placeholder=""
                            fullWidth={true}
                            inputProps={{
                                fontSize: 16
                            }}
                            labelProps={{
                                fontWeight: 'normal',
                                fontSize: 16,
                            }}
                            onKeyPress={(event)=> {
                                if(event.key === 'Enter' || event.charCode === 13) {
                                    onMlsNumberChange(event);
                                }
                            }}
                            onChange={(event)=> {
                                if(_.includes([',', ' '], _.last(event.target.value))) {
                                    onMlsNumberChange(event);
                                }
                            }}
                            onBlur={onMlsNumberChange}
                        />
                    </Grid>
                    <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {mlsNumbers && mlsNumbers.map((keyword)=> (
                            <Box key={keyword} m="12px 12px 0 0">
                                <LRChip
                                    label={keyword}
                                    onDelete={handleDeleteMlsNumber}
                                />
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

function AddANoteSection() {
    const formMethods = useFormContext();
    const { value: agentNote, handleChange: handleAgentNoteChange } = useRFHInput('meta.agent_note', formMethods);

    return (
        <Box pb={4}>
            <FormControl>
                <Box marginBottom={2}>
                    <Typography variant="body1">
                        Is there anything you want to say to your buyer about this search?
                    </Typography>
                </Box>
                <CommentBox
                    rowsMin={5}
                    rowsMax={5}
                    placeholder="Leave a note here"
                    name="note"
                    value={agentNote}
                    onChange={(event)=> handleAgentNoteChange(event.target?.value)}
                />
            </FormControl>
        </Box>
    );
}

export const ListpackForm = forwardRef(function ListpackForm(
    { listpack, initialValues, onSubmit = ()=> {}, onStateChanged, editMode },
    ref
) {
    const { UserStore } = useStore();
    const { isAgent } = UserStore;
    const isMobile = useResponsiveBreakpoint('xs');
    const formRef = useRef();
    const DEFAULT_STATE = {
        name: initialValues?.name,
        areaIds: initialValues.areaIds,
        fields: {
            price_min: null,
            price_max: null,
            school_score_min: null,
            hometype: {
                single_family: false,
                multi_family: false,
                condo_townhome: false,
                mobile_manufactured: false,
                land: false,
            },
            tags: {
                with_pool: false,
                with_great_schools: false,
                with_views: false,
                with_open_houses: false,
                with_virtual_tours: false,
            },
        },
        meta: {
            agent_note: null,
        },
    };

    listpack = listpack
        ? {
            ...listpack,
        }
        : listpack;

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: listpack || DEFAULT_STATE,
    });

    const { register, reset, formState, triggerValidation } = formMethods;

    const onStateChangeRef = useRef();
    const triggerValidationRef = useRef();

    useEffect(()=> {
        onStateChangeRef.current = onStateChanged;
        triggerValidationRef.current = triggerValidation;
    });

    useEffect(()=> {
        triggerValidationRef.current();
    }, []);

    useEffect(()=> {
        onStateChangeRef.current(formState);
    }, [formState]);

    useImperativeHandle(ref, ()=> ({
        submit: ()=> {
            if(formRef.current) {
                formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
            }
        },
        reset: ()=> {
            // Update accordingly with the fields used in the forms
            if(formRef.current) {
                reset({
                    fields: {
                        price_min: null,
                        price_max: null,
                        bedrooms: null,
                        bathrooms: null,
                        homesize_min: null,
                        homesize_max: null,
                        stories: null,
                        lotsize_min: null,
                        lotsize_max: null,
                        year_built_min: null,
                        year_built_max: null,
                        school_score_min: null,
                        include_listings_with_unspecified_data: false,
                        hometype: {
                            single_family: false,
                            multi_family: false,
                            condo_townhome: false,
                            mobile_manufactured: false,
                            land: false,
                        },
                        tags: {
                            with_great_schools: false,
                            with_open_houses: false,
                            with_virtual_tours: false,
                            with_views: false,
                            with_upgraded_kitchens: false,
                            with_pool: false,
                        },
                    },
                    meta: {
                        agent_note: null,
                    },
                });
            }
        },
    }));

    return (
        <FormContext {...formMethods}>
            <form ref={formRef} onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Box width={1} height={1}>
                    <Grid container spacing={isMobile ? 0 : 3}>
                        <Grid item xs={12}>
                            <StyledFormSectionContainer>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                                    <StyledTitleSpan>
                                        ✏️ Let’s give your search a name:
                                    </StyledTitleSpan>
                                    <Hidden xsDown>
                                        <Box color="lr_colors.grey_dark" fontSize="body2.fontSize">
                                            Required
                                        </Box>
                                    </Hidden>
                                </Box>
                                <Box>
                                    <StyledLRInput
                                        className="tour-target-listpack-name"
                                        type="text"
                                        name="name"
                                        inputRef={register({ required: true })}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </Box>
                            </StyledFormSectionContainer>
                        </Grid>

                        <Hidden smUp>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Hidden>

                        <Grid item xs={12}>
                            <StyledFormSectionContainer>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                                    <StyledTitleSpan>
                                        🔍  Where would you like to search?
                                    </StyledTitleSpan>
                                    <Hidden xsDown>
                                        <Box color="lr_colors.grey_dark" fontSize="body2.fontSize">
                                            Required
                                        </Box>
                                    </Hidden>
                                </Box>
                                <Box>
                                    <AreaSection initialValues={initialValues} />
                                </Box>
                            </StyledFormSectionContainer>
                        </Grid>

                        <Hidden smUp>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Hidden>

                        <Grid item xs={12}>
                            <StyledFormSectionContainer>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                                    <StyledTitleSpan>💵 Budget</StyledTitleSpan>
                                </Box>
                                <Box>
                                    <PriceSection />
                                </Box>
                            </StyledFormSectionContainer>
                        </Grid>

                        <Hidden smUp>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Hidden>

                        <Grid item xs={12}>
                            <StyledFormSectionContainer>
                                <Box display="flex" alignItems="center" mb={3}>
                                    <img src="/images/basic-emogi-icon.png" style={{marginRight: '8px'}}/>
                                    <StyledTitleSpan>The basics</StyledTitleSpan>
                                </Box>
                                <Box>
                                    <BasicsSection />
                                </Box>
                            </StyledFormSectionContainer>
                        </Grid>

                        <Hidden smUp>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Hidden>

                        <Grid item xs={12}>
                            <StyledFormSectionContainer>
                                <Box display="flex" alignItems="center" mb={3}>
                                    <img src="/images/everything-emogi-icon.png" style={{marginRight: '8px'}}/>
                                    <StyledTitleSpan>Everything else</StyledTitleSpan>
                                </Box>
                                <Box>
                                    <EverythingElseSection />
                                </Box>
                            </StyledFormSectionContainer>
                        </Grid>

                        {isAgent && (
                            <>
                                <Hidden smUp>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Hidden>

                                <Grid item xs={12}>
                                    <StyledFormSectionContainer>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                                            <StyledTitleSpan>
                                                <span role="img" aria-label="memo">
                                                    📝
                                                </span>{' '}
                                                Add a note
                                            </StyledTitleSpan>
                                        </Box>
                                        <Box>
                                            <AddANoteSection />
                                        </Box>
                                    </StyledFormSectionContainer>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </form>
        </FormContext>
    );
});
