import moment from 'moment';

export const getLastFriday = function getLastFriday(date) {
    let inputDate;
    try {
        if(!date) throw new Error('Invalid date');
        if(!moment(date).isValid()) throw new Error('Invalid date');
        inputDate = moment(date);
    } catch (e) {
        // reset to today if `date` is invalid
        inputDate = moment();
    }
    const lastFridayMoment = inputDate.day(inputDate.day() >= 5 ? 5 : -2);
    return lastFridayMoment.format('dddd, MMMM D, YYYY');
};
