import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useActiveBoard, useStore } from 'mobx-store';
import { useEffect, useMemo, useRef, useState } from 'react';

export function useLoginAndContinue({
    source,
    singupFormOptions = {},
}) {
    const { isLoggedIn } = useIsLoggedIn();
    const activeBoard = useActiveBoard();
    const { LoginSignupModalStore, } = useStore();
    const [continueActionWhenLoggedin, setContinueActionWhenLoggedin] = useState();
    const [handleAction, setHandleAction] = useState();
    const cancelOnSuccessEventListenerRef = useRef();
    const loadedBoardData = useMemo(()=> activeBoard?.loadedBoardData, [activeBoard?.loadedBoardData]);

    // UseEffects

    useEffect(()=> {
        if(LoginSignupModalStore.isOpen) return;

        if(cancelOnSuccessEventListenerRef.current) {
            cancelOnSuccessEventListenerRef.current();
        }
    }, [LoginSignupModalStore.isOpen]);

    useEffect(()=> {
        if(continueActionWhenLoggedin && loadedBoardData && handleAction) {
            handleAction.apply(null, continueActionWhenLoggedin);
            setContinueActionWhenLoggedin(null);
        }
    }, [loadedBoardData, continueActionWhenLoggedin, handleAction]);

    return (func)=> (
        (...args)=> {
            if(!isLoggedIn) {
                setHandleAction(()=> func);
                LoginSignupModalStore.openSignupModal(source, singupFormOptions);
                LoginSignupModalStore.onSuccess(()=> setContinueActionWhenLoggedin(args));
                return;
            }

            if(func) func.apply(func, args);
        }
    );
}
