import { decorate, flow, observable, action, computed } from 'mobx';
import { portalApi, benoitApi } from '../../apis';
import Promise from 'bluebird';
import _ from 'lodash';
import { DEFAULT_LISTING_HERO_IMAGE } from '../../constants';
import analytics from '../../services/analytics';
import axios from 'axios';

export class ListpackStore {
    constructor({ listingsStore, Listing }) {
        this.listingsStore = listingsStore;
        this.Listing = Listing;
    }

    previewMode = false;
    disableClickedMetric = false;

    listpack = null;
    loadingListpack = false;
    loadedListpack = false;

    listingsCount = null;
    loadingListingsCount = false;
    loadedListingsCount = false;
    fetchedListpackData = false;
    loadedListingById = false;

    agentId = null;
    agent = null;
    loadingAgent = false;
    loadedAgent = false;

    listings = null;
    listingsRegistry = observable.map();
    topPriceListings = null;
    loadingListings = false;
    loadedListings = false;
    loadingMoreListings = false;

    isCustomListpack = false;

    areaIds = null;

    error = false;

    listpackEncodedPayload = null;

    incrementClickMetric = flow(function*() {
        if(this.previewMode || this.disableClickedMetric) {
            return;
        }
        yield portalApi.incrementListpackClickedMetric(this.listpackEncodedPayload);
    });

    incrementListingViewedMetric = flow(function*() {
        if(this.previewMode) {
            return;
        }
        yield portalApi.incrementListpackListingViewedMetric(this.listpackEncodedPayload);
    });

    fetchListpack = flow(function*(listpackId) {
        this.loadingListpack = true;
        this.loadedListpack = false;

        try {
            this.listpack = yield portalApi.getListpackData(this.listpackEncodedPayload);
            this.areaIds = this.listpack.custom ? this.listpack.areaIds : this.areaIds;
            if(!this.listpack) throw new Error('listpack not found');
        } catch (error) {
            this.loadingListpack = false;
            this.error = true;
            console.error(error);

            return null;
        }

        this.loadingListpack = false;
        this.loadedListpack = true;
    });

    fetchListings = flow(function*(payload, sortOption) {
        const { id, areaIds } = payload;
        const variable = sortOption.sortField;
        const variableOrder = sortOption.direction;

        if(this.listpack.listings_type === 'static' && this.loadedListings) {
            const babouToBenoitVariables = {
                'YearBuilt': ['year'],
                'ListingDate': ['listing_date'],
                'ListPrice': ['price'],
                'Bedrooms': ['beds'],
                'Bathrooms': ['baths'],
                'LivingArea': ['home_size'],
                'OpenHouseDate': ['open_houses[0].date', 'open_houses[0].date_iso', 'open_houses[0].start_time_iso'],
            };

            const variables = babouToBenoitVariables[variable];

            this.listings = _.orderBy(this.listings, (listing)=> {
                for(let variable of variables) {
                    const value = _.get(listing, variable);
                    if(value) {
                        return value;
                    }
                }

                return String.fromCharCode(variableOrder === 'desc'? 0 : 65535);
            }, variableOrder);

            return this.listings;
        }

        this.loadingListings = true;
        this.loadedListings = false;

        try {
            this.listings = yield this._fetchListings(id, areaIds, `${variable}:${variableOrder}`);

            if(this.listings.length) {
                yield this.listingsStore.getComplianceById(this.listings[0].id, this.agent);
            }

        } catch (error) {
            this.loadingListings = false;
            this.error = true;
            console.error(error);

            return null;
        }

        this.loadingListings = false;
        this.loadedListings = true;

        return this.listings;
    });

    fetchTopPriceListings = flow(function*(listpackId, areaIds) {
        this.topPriceListings = yield this._fetchListings(listpackId, areaIds, 'ListPrice:desc');
    });

    fetchAgent = flow(function*(agentId) {
        this.loadingAgent = true;
        this.loadedAgent = false;

        try {
            this.agent = yield benoitApi.getAgent(agentId);

            if(this.agent?.preferredVendorId) {
                this.agent.preferred_vendor = yield benoitApi.getAgent(this.agent.preferredVendorId);
            }
        } catch (error) {
            this.loadingAgent = false;
            this.error = true;
            console.error(error);

            return null;
        }

        this.loadingAgent = false;
        this.loadedAgent = true;
    });

    fetchListingsCount = flow(function*() {
        this.loadingListingsCount = true;
        this.loadedListingsCount = false;

        try {
            let count;
            if(this.listpack.listings_type === 'static') {
                count = this.listpack.listing_ids.length;
            } else {
                count = yield portalApi.getListpackListingsCount(this.listpack, this.listpackEncodedPayload);
            }
            this.listingsCount = count;
            analytics.setSuperProperties({ listing_count: this.listingsCount });
        } catch (error) {
            this.loadingListingsCount = false;
            this.error = true;
            return null;
        }

        this.loadingListingsCount = false;
        this.loadedListingsCount = true;
    });

    fetchListpackData = flow(function*() {
        const payload = JSON.parse(window.atob(this.listpackEncodedPayload));
        const { id, areaIds, agentId } = payload;
        this.areaIds = areaIds;
        const fairHousingResponse = yield axios.get(process.env.REACT_APP_FAIR_HOUSING_CONFIG_URL);
        this.listingsStore.fairHousing = fairHousingResponse.data;

        yield this.fetchListpack(id);
        yield this.fetchListingsCount(payload);
        yield this.fetchAgent(agentId); 
    });

    fetchMoreListings = flow(function*(limit, skip, sortOption) {
        this.loadingMoreListings = true;

        const sortFied = sortOption?.sortField || 'ListingDate';
        const direction = sortOption?.direction || 'desc';
        try {
            var listings = yield this._fetchListings(
                this.listpack.id,
                this.areaIds,
                `${sortFied}:${direction}`,
                limit,
                skip
            );
        } catch (error) {
            this.loadingMoreListings = false;
            this.error = true;
            console.error(error);

            return null;
        }

        this.listings.push(...listings);
        this.loadingMoreListings = false;

        return this.listings;
    });

    getListing = function(index) {
        if(!this.listpack || !this.listings || this.loadingMoreListings) return null;
        const LIMIT = 30;

        if(this.listings.length > index) {
            return this.listings[index];
        }

        this.fetchMoreListings(LIMIT, this.listings.length);
    };

    getListingByIndex = flow(function*(index) {
        if(index > this.listingsCount) {
            return;
        }

        if(index >= this.listings.length) {
            let listingsToFetch = Math.max(index - this.listings.length + 1, 30);
            yield this.fetchMoreListings(listingsToFetch, this.listings.length);
        }

        if(index >= this.listings.length) {
            return null;
        }
        return this.listings[index];
    });

    getListingById = flow(function*(listingId) {
        if(!this.listpack || !this.listings || this.loadingMoreListings) return null;
        const LIMIT = 30;
        
        this.loadedListingById = false;
        
        do{
            let foundListingIndex = this.listings.findIndex((listing)=> listing.id === listingId);
            if(foundListingIndex >= 0) {
                this.loadedListingById = true;
                let foundListing = this.listings[foundListingIndex];
                return [foundListing, foundListingIndex];
            }
            if(this.listings.length >= this.listingsCount) {
                // This means we already have all listings loaded
                return null;
            }
            let skip = this.listings.length;
            yield this.fetchMoreListings(LIMIT, skip);
            if(skip === this.listings.length) {
                // This means it didn't fetch any more listings
                return null;
            }
        } while(this.listings.length <= this.listingsCount);

        return null;
    });

    getCachedListingById = flow(function*(listingId) {
        const cachedListing = this.listingsRegistry.get(listingId);
        if(cachedListing) {
            return cachedListing;
        } else {
            const response = yield this.getListingById(listingId);
            let foundListing = null;
            if(response && response.length) {
                foundListing = response[0];
            }
            return foundListing;
        }
    });

    _fetchListings = async function(listpackId, areaIds, order, limit = 30, skip = 0) {
        const listingsDetails = await portalApi.getListpackListings(this.listpack, this.listpackEncodedPayload, order, limit, skip);
        if(!listingsDetails) throw new Error('listings not found');

        let listings = listingsDetails.map((listingDetails)=> new this.Listing(listingDetails));

        listings.forEach(function(listing) {
            if(_.isArray(listing.open_houses)) {
                listing.open_houses = _.orderBy(
                    listing.open_houses,
                    ['date', 'date_iso', 'start_time_iso'],
                    ['asc', 'asc', 'asc']
                );
            }
        });

        const orderedListings = order === 'ListPrice:desc' ? _.orderBy(listings, ['price'], ['desc']) : listings;
        this._getHeroImages(orderedListings);
        this._addToListingsRegistry(listings);
        return listings;
    };

    _addToListingsRegistry = function(items=[]) {
        if(items && items.length) {
            items.forEach((item)=> this.listingsRegistry.set(item.id, item));
        }
    };

    _getHeroImages = async function(listings, currentIteration = 0) {
        const listingsToCheck = listings.filter((listing)=> !listing.hero_image);
        if(!listingsToCheck.length) return;

        await Promise.each(listingsToCheck, function(listing) {
            if(!listing.photos || listing.photos.length < currentIteration) {
                listing.hero_image = DEFAULT_LISTING_HERO_IMAGE;
                listing.photos = [];
                return;
            }

            const photo = listing.photos[currentIteration];

            return new Promise(function(resolve) {
                const img = new Image();

                img.addEventListener('load', ()=> {
                    listing.photos.splice(0, currentIteration);
                    listing.hero_image = photo;
                    resolve(true);
                });

                img.addEventListener('error', ()=> {
                    resolve(true);
                });

                img.src = photo;
            });
        });

        currentIteration++;
        return this._getHeroImages(listings, currentIteration);
    };

    get topImages() {
        if(!this.listpack) {
            return [];
        }

        if(!this.loadedListings || !this.topPriceListings) {
            return [this.listpack.hero_image];
        }

        const sortedArray = _.orderBy(this.topPriceListings || [], ['price'], ['desc']);
        return [this.listpack.hero_image].concat(
            sortedArray
                .filter((listing)=> listing.hero_image !== DEFAULT_LISTING_HERO_IMAGE)
                .map((listing)=> listing.hero_image)
                .slice(0, 9)
        );
    }

    get compliance() {
        if(!this.listings?.length) return null;
        return this.listingsStore.complianceDataById[this.listings[0].id];
    }
}

decorate(ListpackStore, {
    // Observables
    previewMode: observable,
    disableClickedMetric: observable,
    listpack: observable,
    loadingListpack: observable,
    loadedListpack: observable,

    listings: observable,
    topPriceListings: observable,
    listingsCount: observable,
    loadingListings: observable,
    loadedListings: observable,
    loadingMoreListings: observable,
    listingsRegistry: observable,
    loadedListingById: observable,

    agentId: observable,
    agent: observable,
    loadingAgent: observable,
    loadedAgent: observable,

    areaIds: observable,

    error: observable,

    listpackEncodedPayload: observable,

    isCustomListpack: observable,

    // Actions
    fetchListpackData: action,
    fetchListings: action,
    addToListingsRegistry: action,

    fetchMoreListings: action,

    getListing: action,

    // Computed
    topImages: computed,
    compliance: computed,
});
