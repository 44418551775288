import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import diacritics from 'diacritics';
import { TextField } from '../TextField';
import Address from 'lana';
import _ from 'lodash';

/*
    @function getAddressComponent
    @param {GooglePlace} place
    @param {String} type - the component type you are looking for
    @param {String} [property='short_name']
*/
function getAddressComponent(place, type, property) {
    var components = place.address_components;

    if(components) {
        var target = components.filter(function(item) {
            return item.types.indexOf(type) !== -1;
        });
        target = target[0] || {};
        property = property || 'short_name';
        return target[property] || '';
    } else {
        return '';
    }
}

function getAddressParts(place) {
    var streetNumber = getAddressComponent(place, 'street_number');
    var street = (streetNumber + ' ' + diacritics.remove(getAddressComponent(place, 'route'))).trim();
    var city = diacritics.remove(getAddressComponent(place, 'locality'));
    if(!city) city = diacritics.remove(getAddressComponent(place, 'administrative_area_level_3', 'long_name'));
    if(!city) city = diacritics.remove(getAddressComponent(place, 'sublocality_level_1'));
    var neighborhood = diacritics.remove(getAddressComponent(place, 'neighborhood', 'long_name'));
    if(!city && neighborhood) city = neighborhood;
    var state = diacritics.remove(getAddressComponent(place, 'administrative_area_level_1'));
    var zip = diacritics.remove(getAddressComponent(place, 'postal_code'));

    return {
        formatted_address: diacritics.remove(place.formatted_address.replace(/, (usa|united states)/gi, '')),
        street: street,
        neighborhood: neighborhood,
        city: city,
        state: state,
        zip: zip,
        place_id: place.place_id,
    };
}

function formatAddress(inputText, place) {
    const parsedInputText = diacritics.remove(inputText.replace(/, (usa|united states)/gi, ''));
    const isInputAddressValid = Address.isValid(parsedInputText);
    if(place) {
        const parts = getAddressParts(place);

        let formattedAddress;
        if(isInputAddressValid) {
            formattedAddress = new Address(parsedInputText);
            if(parts.zip) formattedAddress.zip = parts.zip;
            formattedAddress = formattedAddress.toString();
        } else {
            formattedAddress = diacritics.remove(place.formatted_address.replace(/, (usa|united states)/gi, ''));
        }

        parts.formatted_address = formattedAddress;

        return parts;
    } else {
        if(!isInputAddressValid) {
            return undefined;
        }

        const address = new Address(parsedInputText);
        const parts = address.getParts();
        parts.formatted_address = address.toString();
        parts.street = Object.values(_.pick(parts, ['number', 'prefix', 'street', 'type', 'suffix']))
            .join(' ');
        delete parts.number;
        delete parts.prefix;
        delete parts.type;
        delete parts.suffix;
        return parts;
    }
}

export function GoogleAutocomplete({ onChange = ()=> void 0, inputProps, defaultPlace = null, resetSearch = ()=> {} }) {
    const [inputElement, setInputElement] = useState(null);
    const [editedInput, setEditedInput] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(defaultPlace);

    useEffect(
        function() {
            if(!inputElement) {
                return;
            }

            let autocomplete = null;

            let onPlaceChange = function() {
                let place = autocomplete.getPlace();
                if(!place || !place.formatted_address) {
                    return;
                }

                let parts = formatAddress(inputElement.value, place);

                inputElement.value = parts.formatted_address;
                setSelectedPlace(parts);
                setEditedInput(false);
                if(onChange) {
                    onChange({ place: parts, address: place.formatted_address });
                }
            };

            let setupAutocomplete = function() {
                var defaultOpts = {
                    type: ['address'], // https://developers.google.com/places/web-service/autocomplete#place_types
                    componentRestrictions: { country: 'us' },
                };
                autocomplete = new window.google.maps.places.Autocomplete(inputElement, defaultOpts);

                window.google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChange);
            };
            setupAutocomplete();
        },
        [inputElement, onChange]
    );

    useEffect(
        function() {
            if(!inputElement) {
                return;
            }

            const handleOnChange = function() {
                setEditedInput(true);
            };

            inputElement.addEventListener('change', handleOnChange);
            return function() {
                inputElement.removeEventListener('change', handleOnChange);
            };
        },
        [inputElement, onChange]
    );

    useEffect(
        function() {
            if(!inputElement) {
                return;
            }

            const handleOnBlur = function() {
                if(!editedInput) {
                    return;
                }
                inputElement.value = '';
                setSelectedPlace(null);
            };

            const handleEnter = function(event) {
                if(!editedInput) {
                    setEditedInput(true);
                }
                if(event.keyCode !== 13) {
                    return;
                }
                if(!editedInput) {
                    return;
                }
                inputElement.value = '';
                setSelectedPlace(null);
                onChange({});
            };

            inputElement.addEventListener('blur', handleOnBlur);
            inputElement.addEventListener('keypress', handleEnter);
            return function() {
                inputElement.removeEventListener('blur', handleOnBlur);
                inputElement.removeEventListener('keypress', handleEnter);
            };
        },
        [editedInput, inputElement, onChange, selectedPlace]
    );

    useEffect(
        function() {
            if(inputElement && !inputElement.value && defaultPlace) {
                inputElement.value = defaultPlace.formatted_address;
            }
        },
        [defaultPlace, inputElement]
    );

    useEffect(
        function() {
            if(!inputElement || !resetSearch()) return;

            if(resetSearch()) {
                inputElement.value = '';
            }
            resetSearch(true);
        },
        [inputElement, resetSearch]
    );

    return <TextField {...inputProps} inputRef={setInputElement}></TextField>;
}

GoogleAutocomplete.propTypes = {
    defaultPlace: PropTypes.object,
    inputProps: PropTypes.object,
    resetSearch: PropTypes.func,
    onChange: PropTypes.func,
};
