import React from 'react';
import { Tour3DIcon } from "../../../components/LRIcons";
import { COLOR_BLACK, COLOR_GRADE, COLOR_CREST_WHITE } from '../../../constants';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTour3DIconBadge = styled(Tour3DIcon)`
    width: 10px;
    height: 12px;
    fill: ${COLOR_BLACK};
`;

export const StyledDefaultBadge = styled((props)=>
    <Box {...props} />
)`
    background-color: ${COLOR_CREST_WHITE};
    border-radius: 4px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    color: ${COLOR_GRADE}
    margin-right: 8px;
`;

export const StyledBadge = styled((props)=>
    <Box {...props} />
)`
    background-color: ${COLOR_CREST_WHITE};
    color: ${COLOR_GRADE}
    border-radius: 4px;
    padding: 2px 8px; 
`;
