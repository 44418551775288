import { AppBar, CircularProgress, Collapse, Grid, Hidden, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import LRModal from '../LRModal';
import { useForm } from 'react-hook-form';
import { AddRounded, ArrowForwardRounded, GroupRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@material-ui/icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LRButton from '../LRButton';
import { FormItemText, FormItemWhenSelection, FormItemWithSelection } from './FormFields';
import LRAvatar from '../LRAvatar';
import { useStore } from '../../mobx-store';
import { observer } from 'mobx-react-lite';
import Logger from 'js-logger';
import { MapViewSelectionModal } from './MapViewSelectionModal';
import { useBoardCards } from '../../hooks/useBoardCards';
import { SelectedListings } from './SelectedListings';
import { useBoardCollaborations } from '../../hooks/useBoardCollaborations';
import analytics from '../../services/analytics';
import { find, keyBy } from 'lodash';
import shortid from 'shortid';
import { usePEADStates } from 'hooks/usePEADStates';
import { TitleBar, FormItemSpacer, AddAHomeButton } from './components';
import { CovidPEADModal } from './CovidPEADModal';
import { ModalTitle } from './ModalTitle';
import LRTooltip from 'components/LRTooltip';

const logger = Logger.get('ScheduleATourModal');

const addFormattedDate = (obj)=> {
    if(obj?.date?.length && moment(obj.date).isValid()) {
        obj.date_formatted = moment(obj.date).format('ddd, MMM D');
    }
    return obj;
};

export const ScheduleATourModal = observer(function ScheduleATourModal({
    open = false,
    tour,
    onClose = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const history = useHistory();
    const { UserStore, boardsStore, LanesStore } = useStore();
    const { user } = UserStore;
    const {
        boardCards,
    } = useBoardCards();
    const activeBoard = useMemo(()=> {
        return boardsStore.activeBoard || {};
    }, [boardsStore.activeBoard]);
    const boardCardsById = useMemo(()=> {
        return activeBoard.boardCardsById || {};
    }, [activeBoard.boardCardsById]);
    const historyLocationStateBoardCard = useMemo(()=> {
        if(!history.location.state?.boardCardId) return {};
        return boardCardsById[history.location.state.boardCardId] || {};
    }, [history.location, boardCardsById]);
    const historyLocationSource = useMemo(()=> {
        if(!history.location.state?.source) return 'home_tour';
        return history.location.state.source;
    }, [history.location]);

    const { register, handleSubmit, getValues, setValue, watch, errors, reset } = useForm({
        defaultValues: {
            tour_name: tour?.content?.tour_name || '',
            tour_notes: '',
        }
    });
    const [selectedBoardCards, setSelectedBoardCards] = useState([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [openAddHomeMapView, setOpenAddHomeMapView] = useState(false);
    const [participantsJoining, setParticipantsJoining] = useState();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
    const isiPad = useMediaQuery(theme.breakpoints.only('sm'), { noSsr: true });
    const isiPadDown = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const isiPadPro = useMediaQuery(theme.breakpoints.only('md'), { noSsr: true });
    const isCardHorizontal = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true }) || isiPad;
    const [openMobileDetails, setOpenMobileDetails] = useState(false);
    const [whenSuggestions, setWhenSuggestions] = useState({});
    const [showPEAD, setShowPEAD] = useState(false);
    const [tourCollaboration, setTourCollaboration] = useState(null);
    const tourName = getValues().tour_name;
    const {
        isLoading:peadStatesLoading,
        data:states
    } = usePEADStates();
    const isFormValid = useMemo(function () {
        return Boolean(!peadStatesLoading && !Object(errors).keys?.length && whenSuggestions?.first_choice && participantsJoining != null && selectedBoardCards?.length && tourName);
    }, [errors, whenSuggestions, participantsJoining, selectedBoardCards, tourName, peadStatesLoading]);
    const {
        addBoardCollaboration,
        updateBoardCollaboration,
    } = useBoardCollaborations(activeBoard?.id);
    const alreadyAddedTourBoardCardIds = useRef({});
    const defaultWhenToggleValues = useMemo(()=> {
        if(!tour) return {};

        if(tour.content.suggested_date_time_1.asap) {
            setWhenSuggestions({ first_choice: { asap: true } });
            return {
                asap: true,
            };
        } else {
            return {
                letMePick: true,
            };
        }
    }, [tour]);
    const defaultWhenDateTimeValues = useMemo(()=> {
        if(!tour) return {};

        if(tour.content.suggested_date_time_1.date) {
            const data = {
                first_choice: tour.content.suggested_date_time_1,
                second_choice: tour.content.suggested_date_time_2,
            };
            return data;
        }

        return {};
    }, [tour]);
    const requiresPEADFormSignature = useMemo(()=> {
        if(!selectedBoardCards?.length || states === undefined) return false;
        return Boolean(find(selectedBoardCards, (boardCard)=> (~states.indexOf(boardCard.listing_details.state))));
    }, [states, selectedBoardCards]);

    watch('tour_name');

    // UseEffects

    useEffect(()=> {
        if(defaultWhenDateTimeValues && whenSuggestions?.first_choice == null) {
            setWhenSuggestions(defaultWhenDateTimeValues);
        }
    }, [defaultWhenDateTimeValues]);

    useEffect(function () {
        if(historyLocationStateBoardCard.listing_details && !getValues().tour_name) {
            const alreadyHasCard = selectedBoardCards.find((card)=> card.id === historyLocationStateBoardCard.id);

            let listings = [
                ...selectedBoardCards
            ];
            if(!alreadyHasCard){
                listings.push(historyLocationStateBoardCard);
            }
            setSelectedBoardCards(listings);
            setTimeout(setValue, 50, 'tour_name', `${historyLocationStateBoardCard.listing_details?.address.split(',')[0]} Tour`);
        }

    }, [history.location, activeBoard, boardCardsById, historyLocationStateBoardCard.listing_details]);

    useEffect(()=> {
        if(tour && boardCardsById) {
            const boardCards = tour.content.boardCardIds.reduce((result, id)=> {
                const boardCard = boardCardsById[id];

                if(boardCard && !alreadyAddedTourBoardCardIds.current[id] && !find(selectedBoardCards, { id: boardCard.id })) {
                    result.push(boardCard);
                }

                alreadyAddedTourBoardCardIds.current[id] = true;

                return result;
            }, []);

            if(boardCards.length) {
                setSelectedBoardCards([
                    ...boardCards,
                    ...selectedBoardCards,
                ]);
            }
        }
    }, [tour, boardCardsById]);

    useEffect(()=> {
        if(!(tour && tour.content.participants_joining)) return;

        if(tour.content.participants_joining?.length === 0) {
            setParticipantsJoining(false);
            return;
        }

        const participantsInArray = keyBy(participantsJoining || [], 'id');
        const newParticipants = tour.content.participants_joining.reduce((result, participant)=> {
            if(!participantsInArray[participant.id]) result.push(participant);

            return result;
        }, []);

        if(newParticipants.length) {
            setParticipantsJoining([
                ...newParticipants,
                ...(participantsJoining || []),
            ]);
        }
    }, [tour]);

    useEffect(()=> {
        if(open === false) {
            setSelectedBoardCards([]);
        }
    }, [open]);

    // Functions

    function onRemoveListing(listing, source) {
        analytics.eventTrack('robin_schedule_a_tour_listing_removed_from_tour', {
            board_id: activeBoard?.id,
            Source: source,
        });
        setSelectedBoardCards([
            ...selectedBoardCards.filter((bc)=> (bc.id !== listing.id)),
        ]);
    }

    function onAddListing (listing, options = {}) {
        const opts = {
            skipEventTracking: false,
            ...options,
        };
        let foundListing = false;
        const listings = selectedBoardCards.filter((boardCard)=> {
            const doesMatch = boardCard.id === listing.id;

            if(doesMatch) foundListing = true;

            return !doesMatch;
        });

        if(!foundListing) {
            listings.push(listing);

            if(!opts.skipEventTracking) {
                analytics.eventTrack('robin_schedule_a_tour_listing_added_to_tour', {
                    board_id: activeBoard?.id,
                    card_id: listing.id,
                    Source: 'home_tour_map',
                });
            }
        } else if(!opts.skipEventTracking) {
            analytics.eventTrack('robin_schedule_a_tour_listing_removed_from_tour', {
                board_id: activeBoard?.id,
                card_id: listing.id,
                Source: 'home_tour_map',
            });
        }

        setSelectedBoardCards(listings);
    }

    function onWhenChange(data) {
        setWhenSuggestions(data);
    }

    async function onSubmit(data) {

        analytics.eventTrack('robin_schedule_a_tour_request_submit', {
            board_id: activeBoard?.id,
            address: (selectedBoardCards || [])
                .map((bc)=> bc?.listing_details?.address)
                .join('|'),
            tour_name: data.tour_name,
            Source: historyLocationSource,
        });

        if(!isFormValid) return;
        setIsSubmiting(true);

        let whenFirstChoice;
        let whenSecondChoice;

        if(whenSuggestions.first_choice.asap) {
            whenFirstChoice = {
                asap: true,
                date: null,
                time: null,
            };
            whenSecondChoice = {
                asap: null,
                date: null,
                time: null,
            };
        } else {
            whenFirstChoice = {
                ...whenSuggestions.first_choice,
                asap: false,
            };
            whenSecondChoice = {
                ...whenSuggestions.second_choice,
                asap: null,
            };
        }

        const boardCollaboration = {
            id: shortid.generate(),
            boardId: activeBoard.id,
            content: {
                tour_name: data.tour_name,
                message: data.tour_notes,
                suggested_date_time_1: addFormattedDate(whenFirstChoice),
                suggested_date_time_2: addFormattedDate(whenSecondChoice),
                boardCardIds: selectedBoardCards.map((boardCard)=> (boardCard.id)),
                participants_joining: participantsJoining || [],
            },
            itemId: activeBoard.id,
            model: 'Board',
            type: 'schedule_viewing_request',
            visibleToAffiliate: true,
            meta: {
                pead_required: requiresPEADFormSignature,
            }
        };
        let collaboration;
        try {
            if(tour) {
                await updateBoardCollaboration({ id: tour.id, data: { content: boardCollaboration.content } });
                collaboration = boardCollaboration;
            } else {
                collaboration = await addBoardCollaboration(boardCollaboration);
                await UserStore.warmUpHubspotLeadToHot();
            }
        } catch (err) {
            setIsSubmiting(false);
            return logger.error('onSubmit -> Tour -> Error', err);
        }

        if(data.tour_notes) {
            try {
                await createNote(tour?.id || boardCollaboration.id, data.tour_notes);
            } catch (err) {
                logger.error('onSubmit -> Notes -> Error', err);
            }
        }
        if(requiresPEADFormSignature){
            setTourCollaboration(collaboration);
            setShowPEAD(true);
        }
        else {
            if(tour) onClose();
            else {
                setIsSubmiting(false);
                setParticipantsJoining();
                history.push('/boards/scheduled-tours');
            }
        }
        setIsSubmiting(false);
    }
    function onClosePEAD(){
        setShowPEAD(false);
        if(tour) onClose();
    }

    function handleBackPEAD(){
        setShowPEAD(false);
    }

    function handleOnClose(){
        setParticipantsJoining();
        onClose();
    }

    async function createNote(tourId, message) {
        if(!message) return;

        const msg = {
            boardId: activeBoard?.id,
            itemId: tourId,
            content: {
                message: message,

                // We're including the message in the schedule viewing request
                // email so we don't need a separate notification about this comment
                suppress_notifications: true,
            },
            type: 'schedule_viewing_request_comment',
            model: 'BoardCollaboration',
            visibleToAffiliate: true,
            visibleToLO: false,
        };

        await addBoardCollaboration(msg);
    }

    function isValid(){
        return isFormValid && (participantsJoining === false || participantsJoining?.length > 0);
    }

    return (
        <>
            <LRModal
                open={open}
                className="ScheduleATourModal-Root"
                fullScreen
                maxWidth="100%"
                hideCloseButton
                {...props}
            >
                <div
                    style={{
                        backgroundColor: isMobile && '#fff',
                    }}
                >
                    <ModalTitle title="Schedule a tour" onClose={handleOnClose} />
                    <Toolbar />

                    <Hidden smUp>
                        <div
                            style={{
                                backgroundColor: '#eee',
                                padding: `${theme.spacing(2)}px`,
                            }}
                        >
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                            >
                                <Grid item>
                                    <GroupRounded />
                                </Grid>
                                <Grid item>
                                    <Typography style={{ ...theme.typography.body4, textTransform: 'uppercase', fontWeight: 500 }}>
                                        in person tour
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <LRAvatar user={tour?.user || user} size="xsmall"/>
                                        </Grid>

                                        {participantsJoining && participantsJoining.map((participant)=> (
                                            <Grid key={participant.id} item>
                                                <LRAvatar user={participant} size="xsmall"/>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <div>
                                <Typography variant="h6" style={{wordBreak: 'break-all'}}>
                                    {getValues().tour_name || 'Tour name here'}
                                </Typography>
                                <Typography>
                                    {selectedBoardCards.length} home{selectedBoardCards.length !== 1 && 's'} • Date and time pending
                                </Typography>
                            </div>

                            <div style={{ marginTop: theme.spacing(1.5) }}>
                                <LRButton
                                    endIcon={openMobileDetails ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                                    style={{
                                        marginLeft: '-8px',
                                        textDecoration: 'underline',
                                        textUnderlinePosition: 'under'
                                    }}
                                    onClick={()=> {
                                        setOpenMobileDetails(!openMobileDetails);
                                    }}
                                >
                                    {openMobileDetails ? 'Hide' : 'Show'} tour details
                                </LRButton>
                            </div>

                            <Collapse in={openMobileDetails}>
                                <div
                                    style={{
                                        marginTop: theme.spacing(1.5),
                                    }}
                                >
                                    <SelectedListings
                                        listings={selectedBoardCards}
                                        variant={isCardHorizontal ? 'horizontal' : 'vertical'}
                                        onRemoveListing={onRemoveListing}
                                    />

                                    { selectedBoardCards && selectedBoardCards?.length < 5 ? (
                                        <div
                                            style={{
                                                marginTop: theme.spacing(2),
                                            }}
                                        >
                                            <AddAHomeButton
                                                variant="outlined"
                                                fullWidth
                                                startIcon={!(LanesStore.loadingListings) && <AddRounded />}
                                                onClick={()=> {
                                                    analytics.eventTrack('robin_schedule_a_tour_add_a_home_button_click', {
                                                        board_id: activeBoard?.id,
                                                        Source: 'schedule_a_home_modal',
                                                    });
                                                    setOpenAddHomeMapView(true);
                                                }}
                                                disabled={LanesStore.loadingListings || !boardCards.length}
                                            >
                                                {(LanesStore.loadingListings) ? (
                                                    <CircularProgress size={24} color="default" />
                                                ) : (
                                                    'Add a home'
                                                )}
                                            </AddAHomeButton>
                                        </div>
                                    ) : (
                                        <LRTooltip
                                            arrow
                                            disableFocusListener
                                            disableTouchListener
                                            title={
                                                'You can only add up to 5 homes per tour.'
                                            }
                                        >
                                            <div
                                                style={{
                                                    marginTop: theme.spacing(2),
                                                }}
                                            >
                                                <AddAHomeButton
                                                    variant="outlined"
                                                    fullWidth
                                                    startIcon={!(LanesStore.loadingListings) && <AddRounded />}
                                                    onClick={()=> {
                                                        analytics.eventTrack('robin_schedule_a_tour_add_a_home_button_click', {
                                                            board_id: activeBoard?.id,
                                                            Source: 'schedule_a_home_modal',
                                                        });
                                                        setOpenAddHomeMapView(true);
                                                    }}
                                                    disabled={true}
                                                >
                                                    {(LanesStore.loadingListings) ? (
                                                        <CircularProgress size={24} color="default" />
                                                    ) : (
                                                        'Add a home'
                                                    )}
                                                </AddAHomeButton>
                                            </div>
                                        </LRTooltip>
                                    )}
                                </div>
                            </Collapse>
                        </div>
                    </Hidden>

                    <div
                        className="ScheduleATourModal-Content"
                        style={{
                            padding: `-44px ${theme.spacing(isiPadPro ? 3 : 5)}px ${theme.spacing(5)}px`,
                            position: 'relative',
                            ...(isMobile && {
                                padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`,
                            }) || {},
                        }}
                    >
                        <Grid
                            container
                            spacing={isMobile ? 0 : (isiPad ? 8 : 10)}
                            alignItems="flex-start"
                            style={{
                                maxWidth: '1275px',
                                width: '100%',
                                margin: '0 auto',
                            }}
                        >
                            {/* Left Column */}
                            <Grid item xs style={{ maxWidth: !isiPadDown && '560px', width: '100%', paddingTop: 0 }}>
                                <Hidden xsDown>
                                    <div>
                                        <Typography variant="h3">
                                            Schedule a tour
                                        </Typography>

                                        <TitleBar />
                                    </div>
                                </Hidden>

                                <FormItemText name="tour_name" ref={register({ required: true })} autoFocus required>
                                    Let's give your tour a name
                                </FormItemText>

                                <FormItemSpacer />

                                <FormItemWhenSelection
                                    name="tour_when"
                                    required
                                    defaultToggleValues={defaultWhenToggleValues}
                                    defaultDateTimeValues={defaultWhenDateTimeValues}
                                    onChange={onWhenChange}
                                >
                                    When would you like to see this home?
                                </FormItemWhenSelection>

                                <FormItemSpacer />

                                <FormItemWithSelection
                                    name="tour_joining"
                                    required
                                    value={participantsJoining}
                                    onChange={(participants)=> {
                                        setParticipantsJoining(participants);
                                    }}
                                >
                                    Will anyone be joining you on this tour?
                                </FormItemWithSelection>

                                <FormItemSpacer />

                                <FormItemText
                                    name="tour_notes"
                                    ref={register}
                                    inputProps={{
                                        multiline: true,
                                        rows: 5,
                                        placeholder: 'Drop any notes or questions you might have here.',
                                    }}
                                >
                                    Do you have any notes for {user?.affiliate?.first_name}?
                                </FormItemText>
                            </Grid>

                            {/* Right Column */}
                            {!isMobile && (
                                <Grid
                                    item
                                    xs
                                    style={{
                                        minWidth: isiPadDown ? '100%' : '540px',
                                        paddingTop: 0,
                                    }}
                                >
                                    <div>
                                        <div>
                                            <div
                                                style={{
                                                    background: '#fff',
                                                    border: '1px solid #DDDDDD',
                                                    boxSizing: 'border-box',
                                                    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
                                                    borderRadius: theme.spacing(1),
                                                    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    justify="space-between"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <Grid container alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <GroupRounded />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                style={{
                                                                    textTransform: 'uppercase',
                                                                }}
                                                            >
                                                                <Typography variant="body4" style={{ ...theme.typography.body4 }}>
                                                                    in-person tour
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <LRAvatar user={tour?.user || user} />
                                                            </Grid>

                                                            {participantsJoining && participantsJoining?.map((participant)=> (
                                                                <Grid key={participant.id} item>
                                                                    <LRAvatar user={participant} />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <div
                                                    style={{
                                                        marginTop: theme.spacing(1),
                                                    }}
                                                >
                                                    <Typography variant="h4" style={{wordBreak: 'break-all'}}>
                                                        {getValues().tour_name || 'Tour name here'}
                                                    </Typography>
                                                    <Typography>
                                                        {selectedBoardCards.length} home{selectedBoardCards.length !== 1 && 's'} • Date and time pending
                                                    </Typography>
                                                </div>

                                                <div
                                                    style={{
                                                        marginTop: theme.spacing(3),
                                                        backgroundColor: '#eee',
                                                        borderRadius: '2px',
                                                        padding: theme.spacing(4),
                                                        minHeight: !(isMobile || isiPad) && '500px',
                                                        maxHeight: !isiPadDown && '640px',
                                                        overflow: 'auto',
                                                    }}
                                                >
                                                    <SelectedListings
                                                        listings={selectedBoardCards}
                                                        variant={isCardHorizontal ? 'horizontal' : 'vertical'}
                                                        onRemoveListing={onRemoveListing}
                                                    />

                                                    { selectedBoardCards && selectedBoardCards?.length < 5 ? (
                                                        <div
                                                            style={{
                                                                marginTop: theme.spacing(2),
                                                            }}
                                                        >
                                                            <AddAHomeButton
                                                                variant="outlined"
                                                                fullWidth
                                                                startIcon={!(LanesStore.loadingListings) && <AddRounded />}
                                                                onClick={()=> {
                                                                    analytics.eventTrack('robin_schedule_a_tour_add_a_home_button_click', {
                                                                        board_id: activeBoard?.id,
                                                                        Source: 'schedule_a_home_modal',
                                                                    });
                                                                    setOpenAddHomeMapView(true);
                                                                }}
                                                                disabled={LanesStore.loadingListings || !boardCards.length}
                                                            >
                                                                {(LanesStore.loadingListings) ? (
                                                                    <CircularProgress size={24} color="default" />
                                                                ) : (
                                                                    'Add a home'
                                                                )}
                                                            </AddAHomeButton>
                                                        </div>
                                                    ) : (
                                                        <LRTooltip
                                                            hideArrow={false}
                                                            disableFocusListener
                                                            disableTouchListener
                                                            title={
                                                                'You can only add up to 5 homes per tour.'
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    marginTop: theme.spacing(2),
                                                                }}
                                                            >
                                                                <AddAHomeButton
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    startIcon={!(LanesStore.loadingListings) && <AddRounded />}
                                                                    onClick={()=> {
                                                                        analytics.eventTrack('robin_schedule_a_tour_add_a_home_button_click', {
                                                                            board_id: activeBoard?.id,
                                                                            Source: 'schedule_a_home_modal',
                                                                        });
                                                                        setOpenAddHomeMapView(true);
                                                                    }}
                                                                    disabled={true}
                                                                >
                                                                    {(LanesStore.loadingListings) ? (
                                                                        <CircularProgress size={24} color="default" />
                                                                    ) : (
                                                                        'Add a home'
                                                                    )}
                                                                </AddAHomeButton>
                                                            </div>
                                                        </LRTooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                style={{
                                                    marginTop: theme.spacing(6),
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <LRButton variant="contained" color="primary" disabled={!isValid() || isSubmiting} style={{ width: '176px' }} onClick={handleSubmit(onSubmit)} startIcon={isSubmiting && <CircularProgress size={24} color="default" />}>
                                                    {requiresPEADFormSignature ? (
                                                        <>
                                                            Next <ArrowForwardRounded style={{ width: '16px', marginLeft: theme.spacing(1) }} />
                                                        </>
                                                    ) : (
                                                        'Schedule tour'
                                                    )}
                                                </LRButton>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </div>

                    <Hidden smUp>
                        <Toolbar />
                        <AppBar
                            color="inherit"
                            elevation={0}
                            style={{
                                borderTop: isMobile && '1px solid #ddd',
                                top: 'auto',
                                bottom: 0,
                            }}
                        >
                            <Toolbar>
                                <LRButton variant="contained" color="primary" disabled={!isValid() || isSubmiting} fullWidth onClick={handleSubmit(onSubmit)} startIcon={isSubmiting && <CircularProgress size={24} color="default" />}>
                                    {requiresPEADFormSignature ? (
                                        <>
                                            Next <ArrowForwardRounded style={{ width: '16px', marginLeft: theme.spacing(1) }} />
                                        </>
                                    ) : (
                                        'Schedule tour'
                                    )}
                                </LRButton>
                            </Toolbar>
                        </AppBar>
                    </Hidden>
                </div>
            </LRModal>

            {showPEAD && (
                <CovidPEADModal handleBack={handleBackPEAD} open={showPEAD && open} onClose={onClosePEAD} tourCollaboration={tourCollaboration} setParticipantsJoining={setParticipantsJoining} />
            )}
            <MapViewSelectionModal
                open={openAddHomeMapView}
                selectedListings={selectedBoardCards}
                fullScreen
                hideCloseButton
                maxWidth="100%"
                onClose={()=> { setOpenAddHomeMapView(false); }}
                onListingSelected={onAddListing}
            />
        </>
    );
});
