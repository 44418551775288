import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Button, TextField } from '@material-ui/core';
import LRButton from '../../../../../components/LRButton';
import styled from 'styled-components/macro';
import { COLOR_BLUE } from '../../../../../constants';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../mobx-store';
import DraggableUpload from '../../../../../components/DraggableUpload';
import { Redirect } from 'react-router-dom';

const StyledBox = styled(Box)`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    ${({ src, bgColor })=>
        src
            ? `background: url('${src}');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;`
            : `background-color: ${bgColor};`};

    &:hover {
        cursor: pointer;
    }
`;

const BoardSettings = ({ board })=> {
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;

    const [closing, setClosing] = useState(false);

    const [activeBackground, setActiveBackground] = useState(null);

    const [availableBackgrounds] = useState([
        {
            name: 'lr_bg',
            src:
                'https://trello-attachments.s3.amazonaws.com/58421cce1d00d07200a33d54/5d0a4c94a43dcc7864d87824/a305246f9fc00f9df46fae2afbd8d990/bkgd_pattern.png',
            border: '#EAEAEA',
            color: null
        },
        {
            name: 'Black',
            src: null,
            color: '#1E2B3F'
        },
        {
            name: 'Aqua',
            src: null,
            color: '#0EBDC8'
        },
        {
            name: 'Yellow',
            src: null,
            color: '#F3B204'
        },
        {
            name: 'Grey',
            src: null,
            color: '#E3E3E3'
        },
        {
            name: 'White',
            src: null,
            color: '#FFF',
            border: '#EAEAEA'
        }
    ]);

    const [boardSettings, setBoardSettings] = useState({
        id: board.id,
        name: board.name,
        background_color: board.background_color,
        background_url: board.background_url
    });

    const closeModal = ()=> setClosing(true);

    const handleBgSelection = (bg)=> {
        // set the active background in local state for local management
        setActiveBackground(bg);

        // change the board settings to reflect the changes, this should be the payload of the request
        setBoardSettings({
            ...boardSettings,
            background_url: bg.src,
            background_color: bg.color
        });
    };

    const handleChange = ({ target: { name, value } })=> setBoardSettings({ ...boardSettings, [name]: value });

    async function handleSubmit() {
        let message;

        try {
            await activeBoard.update(boardSettings);
            message = 'Changes successfully saved!';
        } catch (err) {
            message = 'An error occurred trying to save, please try again later';
        }

        setSnackbarStatus({ open: true, message: message, hasUndo: false });
    }

    useEffect(()=> {
        if(board && availableBackgrounds) {
            let active = null;

            if(board.background_url) {
                active = availableBackgrounds.find((bg)=> {
                    return bg.src === board.background_url;
                });
            } else if(board.background_color) {
                active = availableBackgrounds.find((bg)=> {
                    return bg.color === board.background_color;
                });
            }
            setActiveBackground(active);
        }
    }, [availableBackgrounds, board]);

    // snackbar code
    const [snackbarStatus, setSnackbarStatus] = useState({ open: false, message: '', hasUndo: false });

    const { open, message, hasUndo } = snackbarStatus;
    const handleClose = ()=> {
        setSnackbarStatus({ ...snackbarStatus, open: false });
    };
    const snackbarActions = hasUndo
        ? [
            <Button key="undo" color="primary" size="small" onClick={handleClose}>
                  UNDO
            </Button>
        ]
        : [];

    if(closing) {
        return <Redirect to="/boards" />;
    }

    return (
        <Box width="100%">
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={<span id="message-id">{message}</span>}
                autoHideDuration={2000}
                onClose={handleClose}
                action={snackbarActions}
            />
            <Box mt={4}>
                <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                    Board name
                </Box>
                <TextField
                    name="name"
                    type="text"
                    margin="dense"
                    variant="outlined"
                    value={boardSettings.name}
                    onChange={handleChange}
                    fullWidth={true}
                />
            </Box>
            <Box mt={4}>
                <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                    Customize background image
                </Box>
                <p>Select one from our templates or upload your own!</p>
            </Box>
            <Box display={'flex'} flexWrap={'wrap'} pt={4}>
                {availableBackgrounds.map((bg)=> (
                    <StyledBox
                        src={bg.src}
                        position="relative"
                        key={bg.name}
                        width={{ xs: 'calc(50% - 11px)', md: 'calc(25% - 11px)' }}
                        m={'5px'}
                        bgcolor={bg.color}
                        height={120}
                        border={bg.border ? `1px solid ${bg.border}` : ''}
                        borderRadius={5}
                        onClick={()=> handleBgSelection(bg)}
                    >
                        {activeBackground && activeBackground.name === bg.name && (
                            <Box
                                position="absolute"
                                top={0}
                                right={0}
                                bottom={0}
                                left={0}
                                borderRadius="borderRadius"
                                border={`2px solid ${COLOR_BLUE}`}
                            />
                        )}
                    </StyledBox>
                ))}
                <Box width={{ xs: 'calc(50% - 11px)', md: 'calc(25% - 11px)' }} m={'5px'} height={120}>
                    <DraggableUpload
                        onFileSelected={()=> {}}
                        btnText="Upload"
                        border={'1px solid #EAEAEA'}
                        borderRadius={5}
                        height="100%"
                    />
                </Box>
            </Box>

            <Box mx="auto" mt={4}>
                <Box justify="center" alignItems="center" display="flex" flexDirection="column">
                    <LRButton color="primary" variant="contained" onClick={handleSubmit}>
                        Save changes
                    </LRButton>
                    <LRButton color="primary" onClick={closeModal}>
                        Cancel
                    </LRButton>
                </Box>
            </Box>
        </Box>
    );
};

export default observer(BoardSettings);
