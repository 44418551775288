// eslint-disable-next-line
import { AppBar, Box, Grid, IconButton, CircularProgress, Typography } from '@material-ui/core';
import { useTheme, withTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import LRAvatar from '../../../components/LRAvatar';
import LRButton from '../../../components/LRButton';
import LRModal from '../../../components/LRModal';
import { COLOR_OFF_WHITE } from '../../../constants';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
import { benoitApi } from '../../../apis';
import { ListpackForm } from './Form';
import { useOnboarding } from '../../../components/Onboarding';
import { useBoardListpack, useBoardListpacks } from 'hooks/useBoardListpacks';
import Logger from 'js-logger';


const StyledAppBar = withTheme(styled(AppBar)`
    background-color: #fff;
    color: ${({ theme })=> theme.palette.text.primary};
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    position: relative;
`);

const HeaderContent = styled(Grid)`
    padding-left: 16px;
    height: 56px;
`;

const Content = withTheme(styled(Box)`
    overflow: auto;
    max-height: calc(100vh - 64px);
    height: 100%;
    ${({ theme })=> theme.breakpoints.down('xs')} {
        max-height: calc(100vh - 120px);
    }
`);

const StyledLRModal = withTheme(styled(LRModal)`
    & .lane-modal-dialog-content {
        background-color: ${COLOR_OFF_WHITE};
        overflow: hidden;
    }

    ${({ theme })=> theme.breakpoints.down('xs')} {
        & .lane-modal-dialog-content {
          background-color: #fff;
        }
    }
`);

const CloseButton = withTheme(styled(IconButton)`
    position: absolute;
    top: 8px;
    right: -55px;

    ${({ theme })=> theme.breakpoints.up('lg')} {
        color: #fff;
    }

    ${({ theme })=> theme.breakpoints.down('md')} {
        top: 4px;
        right: 4px;
        z-index: 3;
        color: #000;
    }
`);

function ListpackModal({ ...props }) {
    const history = useHistory();
    const match = useRouteMatch();
    const isXs = useResponsiveBreakpoint('xs');
    const formRef = useRef();
    const { UserStore, boardsStore, GlobalSnackbarStore } = useStore();
    const { activeBoard } = boardsStore;
    const { user } = UserStore;
    const areaId = match.params.areaId;
    const listpackId = match.params.listpackId;
    const theme = useTheme();
    const [formIsValid, setFormIsValid] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const onboarding = useOnboarding();
    const editMode = listpackId ? true : false;
    const canDeleteListpack = !onboarding?.isFirstRunOnboarding && !onboarding?.isNurtureFirstRunOnboarding || ((onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && !['edit-optional-step'].includes(onboarding.stepId));
    const initialValues = {
        name: `${areaId} Homes`,
        areaIds: [ areaId ]
    };
    const { createOrUpdateListpack } = useBoardListpacks();
    const { listpack, archiveListpack } = useBoardListpack(listpackId);

    // Functions

    function handleOnClose() {
        if((onboarding.isAsyncFirstRunOnboarding)) {
            return;
        }
        setShowSpinner(false);
        history.goBack();
        const { stepId } = onboarding;
        const shouldAdvanceOnboarding = (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding)
            && ['edit-optional-step'].includes(stepId);

        analytics.eventTrack('robin_onboarding_user_edits_or_views_saved_search_filters', {
            source: 'listpack-edit-modal'
        });
        if(shouldAdvanceOnboarding) {
            analytics.eventTrack('robin_onboarding_edit_saved_search_guide_proceed');
            onboarding.next();
        }

        // if ((onboarding?.isFirstRunOnboarding || onboarding.isNurtureFirstRunOnboarding) && onboarding?.stepId === '') {
        //     analytics.eventTrack('robin_onboarding_edit_saved_search_guide_proceed');
        // }
    }

    async function handleDelete() {
        await archiveListpack(listpack);
        setShowSpinner(false);
        history.replace('/boards');
        GlobalSnackbarStore.show('The listpack was successfully deleted');

        if(onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) {
            setTimeout(()=> {
                onboarding.complete();
            }, 500);
        }
    }

    async function handleFormSubmit(data) {
        const payload = {
            userId: user.id,
            boardId: activeBoard.id,
            ...data
        };

        if(listpackId) { payload.id = listpackId; };

        if((onboarding.isFirstRunOnboarding || onboarding.isAsyncFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding)) {
            const listingsCount = await benoitApi.getListpackFiltersListingsCount(payload.areaIds, payload.fields);
            if(listingsCount === 0) {
                onboarding.needsToExpandSearch = true;
                setShowSpinner(false);
                return;
            }
        }

        const listpack = await createOrUpdateListpack(payload);

        // if(UserStore.isFromNurtureUnlimited) {
        //     await UserStore.completeAsyncFirstRunOnboarding();
        // }

        const event = listpackId ? 'robin_board_listpack_edit_submit' : 'robin_board_listpack_created';
        analytics.eventTrack(event, {
            'affiliate_id': user?.affiliateId,
            'listpack_id': listpack?.id,
            'listpack_name': listpack?.name,
            'listpack_area_ids': (listpack?.areaIds || []).join('|'),
            'keyword_filter_set': listpack.fields.keywords.length > 0,
            'keywords_used': listpack.fields.keywords,
        });

        if(onboarding?.isFirstRunOnboarding) {
            analytics.eventTrack('robin_onboarding_user_edits_or_views_saved_search_filters', {
                source: 'listpack-edit-modal'
            });
        }
        const { stepId } = onboarding;
        const shouldAdvanceOnboarding = (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && ['edit-optional-step', 'rpp-nurture-first'].includes(onboarding?.stepId);
        if(shouldAdvanceOnboarding) {

            if(onboarding?.isNurtureFirstRunOnboarding){
                onboarding.tour.show('highlight-listpack-listing-card' , true);
            } else {
                onboarding.next();
            }
            analytics.eventTrack('robin_onboarding_edit_saved_search_guide_proceed');
        }

        setShowSpinner(false);
        history.replace(`/boards/listpack/${listpack.id}/listings`);
    }

    function handleSaveClick() {
        setShowSpinner(formIsValid);
        formRef.current.submit();
    }

    function handleResetClick() {
        formRef.current.reset();
    }

    return (
        <StyledLRModal
            fullScreen={true}
            fullWidth={true}
            maxWidth="sm"
            onClose={handleOnClose}
            dialogContentClasses={{ root: 'lane-modal-dialog-content' }}
            {...props}
        >
            <>
                <StyledAppBar>
                    <HeaderContent container wrap="nowrap" justify={isXs ? 'flex-start' : 'center'} alignItems="center">
                        <Box width={1} display="flex" alignItems="center" justifyContent="space-between" mx={2}>
                            <Box flex={1}>
                                {
                                    editMode && !isXs && (
                                        <Box
                                            display="flex"
                                            flex="1"
                                            mt={3 / 2}
                                            textOverflow="ellipsis"
                                            overflow="hidden"
                                            whiteSpace="nowrap"
                                        >
                                            <Box height={[32, 40]} width={[32, 40]} minWidth={[32, 40]}>
                                                {listpack?.loadedUser && listpack?.loadedAffiliate && (
                                                    <LRAvatar user={listpack.creator} />
                                                )}
                                            </Box>
                                            <Box ml={3 / 2} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                                                { listpack?.loadedAffiliate && listpack?.loadedUser && (
                                                    <Box
                                                        fontSize="caption.fontSize"
                                                        color="lr_colors.grey_dark"
                                                        lineHeight="15px"
                                                    >
                                        Created by{' '}
                                                        {listpack?.affiliateId ? listpack?.affiliate?.name : listpack?.user?.name}
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box flex={1} textAlign="center">
                                <Typography variant='body1' style={{fontWeight: 450}}>
                                    {editMode ? 'Edit search' : 'New search'}
                                </Typography>
                            </Box>
                            <Box flex={1} display="flex" justifyContent="flex-end">
                                <Box textAlign="center" display="flex">
                                    {!isXs && (
                                        <>
                                            {showSpinner ? <CircularProgress size={20} color="secondary" /> : (
                                                <>
                                                    {editMode && canDeleteListpack && (
                                                        <Box>
                                                            <LRButton
                                                                type="button"
                                                                variant="outlined"
                                                                onClick={handleDelete}
                                                            >
                                                    Delete
                                                            </LRButton>
                                                        </Box>
                                                    )}
                                                    <Box ml={0.5}>
                                                        <LRButton
                                                            type="button"
                                                            variant="outlined"
                                                            onClick={handleResetClick}
                                                            style={{minWidth: '90px'}}
                                                        >
                                                    Reset all
                                                        </LRButton>
                                                    </Box>
                                                    <Box ml={0.5}>
                                                        <LRButton
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            className="tour-target-listpack-save"
                                                            onClick={handleSaveClick}
                                                            // disabled={!formIsValid}
                                                        >
                                                    Save
                                                        </LRButton>
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                    )}


                                    {isXs && (
                                        <Box width="36px">
                                            <CloseButton onClick={handleOnClose} title="Close">
                                                <Close />
                                            </CloseButton>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </HeaderContent>
                </StyledAppBar>

                <Content >
                    <Box width={1} height={1} px={[0,5]} py={[0,3]}>
                        <ListpackForm initialValues={initialValues}
                            areaId={areaId}
                            ref={formRef}
                            listpack={listpack}
                            onSubmit={handleFormSubmit}
                            onStateChanged={(formState, errors)=> {
                                // set form state
                                setFormIsValid(formState.isValid);
                            }}
                            editMode={editMode}
                        />
                    </Box>

                </Content>

                {isXs && (
                    <Box
                        position="fixed"
                        bottom="0"
                        height={64}
                        width="100%"
                        borderTop={`1px solid ${theme.palette.lr_colors.border_grey}`}
                        style={{ backgroundColor: '#FFFFFF' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        px={2}
                        py={1.5}
                    >
                        {showSpinner ? <CircularProgress size={20} color="secondary" /> :
                            (
                                <Grid container spacing={2}>
                                    {
                                        editMode && canDeleteListpack && (
                                            <Grid item xs>
                                                <Box width={1}>
                                                    <LRButton
                                                        fullWidth
                                                        variant="outlined"
                                                        onClick={handleDelete}
                                                    >
                                                        <Box component="span" ml={0.5}>
                                                            Delete
                                                        </Box>
                                                    </LRButton>
                                                </Box>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs>
                                        <Box width={1}>
                                            <LRButton
                                                type="button"
                                                variant="outlined"
                                                fullWidth
                                                onClick={handleResetClick}
                                            >
                                            Reset all
                                            </LRButton>
                                        </Box>
                                    </Grid>

                                    <Grid item xs>
                                        <Box width={1}>
                                            <LRButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className="tour-target-listpack-save"
                                                onClick={handleSaveClick}
                                            >
                                                Save
                                            </LRButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                    </Box>
                )}
            </>
        </StyledLRModal>
    );
}

export default observer(ListpackModal);
