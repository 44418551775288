import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

export function CenteredLoading({
    height = 'auto',
    width = 1,
    alignItems = 'center',
    justifyContent = 'center',
    color = 'secondary',
    size = 40,
}) {
    return (
        <Box display="flex" width={width} height={height} alignItems={alignItems} justifyContent={justifyContent}>
            <CircularProgress color={color} size={size} />
        </Box>
    );
}

CenteredLoading.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
};
