import { benoitApi } from 'apis';
import { useActiveBoard } from 'mobx-store';
import { queryClient } from 'query-client';
import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { CACHE_KEY } from './constants';
import { MyHomeContext } from './MyHomeProvider';

export function useMyHomeServiceProviders() {
    const activeBoard = useActiveBoard();
    const boardId = activeBoard?.id;
    const { myHome, isLoading } = useContext(MyHomeContext);
    const serviceProvidersQuery = useQuery(
        [`${CACHE_KEY}-serviceProviders`, myHome?.boardId, myHome?.id],
        ({ queryKey: [, boardId, myHomeId] })=> (benoitApi.getMyHomeServiceProviders(boardId, myHomeId)),
        {
            enabled: Boolean(myHome?.id && boardId),
        },
    );
    const createServiceProviderMutation = useMutation(
        (data)=> (benoitApi.createMyHomeServiceProvider(boardId, myHome.id, data)),
        {
            onSuccess: ()=> {
                refreshServiceProviderQueries();
            }
        }
    );
    const updateServiceProviderMutation = useMutation(
        ([serviceProvider, data])=> (benoitApi.updateMyHomeServiceProvider(boardId, myHome.id, serviceProvider, data)),
        {
            onSuccess: ()=> {
                refreshServiceProviderQueries();
            }
        }
    );
    const deleteServiceProviderMutation = useMutation(
        (serviceProvider)=> (benoitApi.deleteMyHomeServiceProvider(boardId, myHome.id, serviceProvider)),
        {
            onSuccess: ()=> {
                refreshServiceProviderQueries();
            }
        }
    );

    return {
        ...serviceProvidersQuery,
        isLoading: isLoading || serviceProvidersQuery.isLoading,
        createServiceProvider: createServiceProviderMutation.mutateAsync.bind(createServiceProviderMutation),
        updateServiceProvider: updateServiceProviderMutation.mutateAsync.bind(updateServiceProviderMutation),
        deleteServiceProvider: deleteServiceProviderMutation.mutateAsync.bind(deleteServiceProviderMutation),
    };
}

// Helpers

function refreshServiceProviderQueries() {
    queryClient.invalidateQueries(`${CACHE_KEY}-serviceProviders`);
}
