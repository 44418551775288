import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { LRDialog } from 'components/LRDialog';
import { useAgent } from 'hooks/useAgent';
import { useLoanOfficer } from 'hooks/useLoanOfficer';

export function ComplianceModal({
    open = false,
    onClose= ()=> {},
    PaperProps = {},
    ...props
}) {
    const theme = useTheme();
    const { data:agent } = useAgent({ priority: 'context' });
    const lo = useLoanOfficer(agent);
    const vendor = lo?.vendor;

    return (
        <LRDialog
            open={open}
            PaperProps={{
                style: {
                    width: '496px',
                },
                ...PaperProps,
            }}
            {...props}
        >
            <div style={{
                color: theme.palette.lr_colors.giggity,
                padding: theme.spacing(4),
                paddingTop:  theme.spacing(5),
                paddingBottom:  theme.spacing(3),
                width: '100%',
                maxWidth: '100%',
                position: 'relative'
            }}>
                <div
                    onClick={onClose}
                    style={{
                        top: theme.spacing(2),
                        right: theme.spacing(2),
                        position: 'absolute',
                        cursor: 'pointer'
                    }}
                >
                    <Close />
                </div>

                <div style={{fontWeight: 'bold', marginBottom: theme.spacing(2)}}>
                    {lo?.name} • {lo?.company} • NMLS #{lo?.realtor_license_number}
                </div>
                <div style={{
                    maxHeight: '200px',
                    overflow: 'auto',
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-line',
                    marginBottom: theme.spacing(2)
                }}>
                    {(vendor?.compliance_text) ? vendor?.compliance_text : ''}
                </div>

                <img
                    src="/images/equal-housing-logo.png"
                    alt="equal-housing-logo"
                    style={{ width: 23, height: 24 }}
                />

            </div>
        </LRDialog>
    );
}

ComplianceModal.propTypes = {
    ...Dialog.propTypes,
    onOpen: PropTypes.func.isRequired,
};
