import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, CloseRounded, ExpandMoreRounded, CheckRounded, ChevronLeftRounded } from '@material-ui/icons';
import LRDropdown from '../../LRDropdown';
import { useStore } from '../../../mobx-store';
import { useMenu } from '../../../hooks';
import MenuComponent from './Menu';
import styled from 'styled-components/macro';
import Header from './Header';
import Lockr from 'lockr';
import analytics from '../../../services/analytics';
import { useCompareNowStoreContext } from '../store';
import { observer } from 'mobx-react-lite';
import { sortOptions, fieldsByCategory } from '../constants';
import LRButton from 'components/LRButton';

function RenderDisplayOption({ label }) {
    return <Box style={{ cursor: 'pointer' }}>{label}</Box>;
}

const MobileCompare = observer(function MobileCompare({ handleOnClose, closeLabel, source, allLabel }) {
    const store = useCompareNowStoreContext();
    const [showDesktopBanner, setShowDesktopBanner] = useState(true);
    const [menuProps, openMenu, closeMenu] = useMenu();
    let [firstListingIndex, setFirstListingIndex] = useState(0);
    let [secondListingIndex, setSecondListingIndex] = useState(1);
    const { UserStore, boardsStore, LanesStore } = useStore();
    const {activeBoard } = boardsStore;
    const boardPermissions = activeBoard?.userRoleOnBoard;
    const hideDesktopBanner = Lockr.get('compare-now-mobile-hide-desktop-banner');
    const userPreference = {
        beds: 4
    };
    const StyledIconButton = styled(IconButton)`
        color: black;
    `;
    const filterOptions = [{id: '0', label: allLabel}, ...store.categories.map((category)=> {
        return  {id: category.index, label: `${category.label} (${store.cardsByCategories[category.index].length})`};
    })];

    // Functions

    function handleRotateRightClick() {
        analytics.eventTrack('robin_compare_listing_next', {
            role: boardPermissions?.label
        });
        store.rotateRight();
    }

    function handleRotateLeftClick() {
        analytics.eventTrack('robin_compare_listing_previous', {
            role: boardPermissions?.label
        });
        store.rotateLeft();
    }

    function handleLockClick(card) {
        Lockr.set('compare-now-lock-clicked', true);
        store.toggleCardLock(card);
        if(store.isCardLocked(card)){
            analytics.eventTrack('robin_compare_listing_locked', {
                role: boardPermissions?.label
            });
        } else {
            analytics.eventTrack('robin_compare_listing_unlocked', {
                role: boardPermissions?.label
            });
        }
    }

    function handleLaneChange(value) {
        const category = _.defaultTo(_.find(store.categories, ['index', value]), {label: 'all'});
        const category_label = _.snakeCase(category.label);
        analytics.eventTrack(`robin_${source ? (source + '_') : ''}compare_tool_${category_label}_view_toggle`, {});

        if(!!value){
            analytics.eventTrack('robin_compare_tool_view_volumn_toggled_button_click', {
                filter: _.snakeCase(LanesStore.lanes[value - 1 ].name),
                source: 'compare_tool'
            });
        }
    }

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box>
                <Box my={2} display="flex" position="relative" justifyContent={closeLabel ? 'flex-start' : 'center'} alignItems="center" borderBottom="solid 1px #DDD" pb={2}>
                    {closeLabel && (
                        <LRButton startIcon={<ChevronLeftRounded />} onClick={handleOnClose} style={{ padding: '0 0 0 24px' }}>
                            {closeLabel}
                        </LRButton>
                    )}
                    {!closeLabel && (
                        <>
                            <Box fontSize="body1.fontSize">
                                Compare
                            </Box>
                            <Box right={0} position="absolute">
                                <StyledIconButton  className="compare-close-btn" variant="contained" onClick={handleOnClose}>
                                    <CloseRounded />
                                </StyledIconButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            <Box maxWidth={600} ml="max( calc((100% - 600px) / 2), 0px)">
                <Box display="flex" px={2} pb={2} width="100%">
                    <Box width={250}>
                        <LRDropdown
                            name="filter"
                            selectedOption={filterOptions.find((filter)=> filter.id === store.filter)}
                            options={filterOptions}
                            fullWidth
                            renderDisplayOption={RenderDisplayOption}
                            onOptionSelect={(option)=> {
                                handleLaneChange(parseInt(option.id));
                                store.changeFilter(option.id);
                            }}
                            textFieldIcon={ExpandMoreRounded}
                            renderOption={({label})=> {
                                return (
                                    <Box display="flex">
                                        {store.filter === filterOptions.find((filter)=> filter.label === label).id &&
                                            <Box mx={1} fontSize="body1.fontSize" display="flex" alignItems="center">
                                                <CheckRounded fontSize="inherit" />
                                            </Box>
                                        }
                                        {store.filter !== filterOptions.find((filter)=> filter.label === label).id &&
                                            <Box width={30} />
                                        }
                                        <div>{label}</div>
                                    </Box>
                                );
                            }}
                        />
                    </Box>
                    <Box display="flex" pl={4} fontSize={16} alignSelf="center" onClick={openMenu}>
                        Sort <ArrowDropDown />
                    </Box>
                </Box>
            </Box>

            <Box flexGrow="1" maxWidth={600} minWidth="0px" ml="max( calc((100% - 600px) / 2), 0px)">
                <Header
                    firstIndex={0}
                    secondIndex={1}
                    setFirstIndex={setFirstListingIndex}
                    setSecondIndex={setSecondListingIndex}
                    rotateListingsRight={handleRotateRightClick}
                    rotateListingsLeft={handleRotateLeftClick}
                    onLockClick={handleLockClick}
                />
            </Box>

            <Box
                flexGrow="1"
                overflow="auto"
                minHeight="0"
                maxWidth={600}
                mx="auto"
                width="100%"
            >
                {Object.keys(fieldsByCategory)
                    .filter((category)=> UserStore.isLO ? category !== 'Notes' : true)
                    .map((tabName)=> (
                        <MenuComponent
                            key={tabName}
                            name={tabName}
                            tab={fieldsByCategory[tabName]}
                            selectedListings={store.boardCardsWindow}
                            userPreference={userPreference}
                            initOpened={tabName === 'Basics' ? true : false}
                        />
                    ))}
            </Box>

            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                PaperProps={{
                    style: {
                        maxHeight: 400,
                        width: 250
                    }
                }}
                {...menuProps}
            >
                {sortOptions
                    .map((option)=> (
                        <MenuItem
                            key={option.id}
                            onClick={(e)=> {
                                analytics.eventTrack(`robin_${source ? (source + '_') : ''}compare_tool_sort_by_toggle`, {
                                    sort_by: _.snakeCase(option.label),
                                });
                                store.changeSort(option);
                                closeMenu();
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
            </Menu>

            {!hideDesktopBanner && showDesktopBanner && <Box m={2} position="relative">
                <Box position="absolute" right={8} top={8} variant="contained" onClick={()=> { setShowDesktopBanner(false); Lockr.set('compare-now-mobile-hide-desktop-banner', true); }}>
                    <CloseRounded />
                </Box>
                <Box>
                    <Box fontSize={16} boxShadow="0px 0px 8px rgba(219, 219, 219, 1)" borderRadius={6} p={2} pr={4}>
                        <Box component="span" fontWeight="bold">✨This feature is even better on desktop.</Box> Compare more homes at a time by checking out this feature on your computer!
                    </Box>
                </Box>
            </Box>}
        </Box>
    );
});

MobileCompare.propTypes = {
    listings: PropTypes.array,
    removeListing: PropTypes.func,
    handleOnClose: PropTypes.func,
    fieldsByCategory: PropTypes.object
};

export default MobileCompare;
