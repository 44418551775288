import React from 'react';
import { Box, Grid } from '@material-ui/core';
import LRAvatar from 'components/LRAvatar';
import moment from 'moment';
import { DEFAULT_LISTING_HERO_IMAGE } from '../../../../constants';
import { useNotificationColumnName } from '../../../../hooks';
import notificationConfig from './../config';
import lodashGet from 'lodash/get';
import { useStore } from 'mobx-store';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import analytics from 'services/analytics';

const MAX_THUMBNAILS = 3;

const StyledAvatarContainer = styled.div`
    position: relative;
`;

const StyledIconContainer = styled.div`
    position: absolute;
    right: -9px;
    bottom: -9px;
    border-radius: 50%;
    background-color: #1a1a1a;
    width: 22px;
    height: 22px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    > * {
        margin: 7px auto;
        font-size: 10px;
    }
`;

const StyledThumbnail = styled.img`
    border-radius: 2px;
    object-fit: cover;
    width: 70px;
    display: block;
    height: 48px;
`;

export function Notification({ notification }) {
    const history = useHistory();
    const { boardsStore, UserStore } = useStore();
    const { activeBoard } = boardsStore;
    const { user } = UserStore;

    notification = useNotificationColumnName(notification);
    const conf = notificationConfig[notification.type] || {};
    const text = conf?.text(activeBoard, notification, user.affiliateId, true) || '';
    const hero = conf?.hero ? conf.hero(notification) : null;
    const icon = conf?.icon ? conf.icon(notification) : null;
    const showAvatar = conf?.hasOwnProperty('avatar') ? conf.avatar : true;
    const imageUrls = conf?.imageUrls ? conf.imageUrls(notification) || [] : [];
    const plusCount = Math.max(imageUrls.length - MAX_THUMBNAILS, 0);

    function handleNotificationClick(notification) {
        const conf = notificationConfig[notification.type] || {};
        let toUrl = notification?.content?.path ? notification.content.path : conf.cta ? conf.cta(notification, activeBoard) : null;
        if(toUrl) {
            analytics.eventTrack('robin_overview_board_activity_clicked', {
                activity_id: notification.id,
            });
            // 🔨 timeee
            const isMatch = toUrl.match('(/boards|/board)/.+');
            if(isMatch) {
                toUrl = toUrl.replace(/(\/boards|\/board)/, '/overview');
            }
            history.push(`${toUrl}`);
        }
    }

    return (
        <Box
            key={notification?.id}
            style={{ cursor: 'pointer' }}
            onClick={()=> {
                handleNotificationClick(notification);
            }}
        >
            <Box p={2}>
                <Grid container spacing={2}>
                    {showAvatar && (
                        <Grid item>
                            <StyledAvatarContainer>
                                <LRAvatar size="small" user={notification.user} />

                                {icon && <StyledIconContainer>{icon}</StyledIconContainer>}
                            </StyledAvatarContainer>
                        </Grid>
                    )}
                    <Grid item xs>
                        <Box fontSize="body2.fontSize">{text}</Box>
                        <Box fontSize="body2.fontSize" color="text.secondary">
                            { moment(notification.createdAt).format('MMM D, YYYY') }
                        </Box>
                        {imageUrls.length > 1 && (
                            <Box mt={1}>
                                <Grid container spacing={1} alignItems="center">
                                    {imageUrls.slice(0, MAX_THUMBNAILS)
                                        .map((imageUrl, i)=> (
                                            <Grid item key={`notification-list-thumbnails-${notification.id}-${i}`}>
                                                <StyledThumbnail alt="thumbnail" src={imageUrl} />
                                            </Grid>
                                        ))}

                                    {plusCount > 0 && (
                                        <Grid item>
                                            <p>+{plusCount}</p>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        )}
                    </Grid>

                    {(imageUrls.length === 1 || (!imageUrls.length && hero)) && (
                        <Grid item>
                            <StyledThumbnail
                                alt="thumbnail"
                                src={lodashGet(imageUrls, '[0]') || hero}
                                onError={(e)=> {
                                    e.target.src = DEFAULT_LISTING_HERO_IMAGE;
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
}
