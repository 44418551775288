export const trulia = (path)=> {
    const pathRegex = /.*\/.*\/.*\/(.*)--.*/;
    const matchedObject = path?.match(pathRegex);
    let address;

    if(matchedObject) {
        const [, addressPart] = matchedObject;
        address = addressPart?.replace(/-/g, ' ');
    }

    return address;
};
