import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useParams } from 'react-router-dom';

import analytics from '../../../../services/analytics';
import { LRDropPopover } from '../../../../components/LRDropPopover';
import { useOnboarding } from '../../../../components/Onboarding';
import { RobinLogo } from '../../../../components/RobinLogo';
import { Price } from './Price';
import { Beds } from './Beds';
import { Baths } from './Baths';
import { HomeType } from './HomeType';
import { More } from './More';
import { SaveFilter } from './SaveFilter';
import { Area } from './Area';
import { useRFHInput } from '../../../../hooks';
import { useMemo } from 'react';
import abbr from 'number-abbreviate';
import { formatBedsTitle } from './Beds';
import { formatBathTitle } from './Baths';

export function Filters({
    listingsCount,
    openSaveModal,
    setSaveOpenModal,
    showLoading,
    trackFilterClick,
    trackFilterSet,
    saveTrackEvent,
    setSaveTrackEvent,
    fieldArray,
    areas,
    nameState,
    hideSaveButton,
}) {
    const { affiliateCode } = useParams();
    const formMethods = useFormContext();
    const { getValues } = formMethods;
    const { value: priceMin, handleChange: setPriceMin } = useRFHInput('fields.price_min', formMethods);
    const { value: priceMax, handleChange: setPriceMax } = useRFHInput('fields.price_max', formMethods);
    const { value: bathrooms, handleChange: handleBathrooms } = useRFHInput('fields.bathrooms', formMethods);
    const { value: beds, handleChange: handleBeds } = useRFHInput('fields.bedrooms', formMethods);
    const { isIPadDown } = useDeviceBreakpoints();

    const formatPriceTitle = (priceMin, priceMax)=> {
        if(!(priceMin || priceMax)) {
            return 'Price';
        }

        if(priceMin && priceMax) {
            return `$${parseFloat(abbr(priceMin, 2))}-$${abbr(priceMax, 2)}`;
        } else if(priceMin) {
            return `$${abbr(priceMin)}+`;
        } else {
            return `$0-$${abbr(priceMax)}`;
        }
    };

    const priceTitle = useMemo(()=> {
        return formatPriceTitle(priceMin, priceMax);
    }, [priceMin, priceMax]);

    const onboarding = useOnboarding();
    let onClick = undefined;

    const handlePriceMixpanel = ()=> {
        const values = getValues();
        const minValue = values['fields.price_min'];
        const maxValue = values['fields.price_max'];
        const priceTitle = formatPriceTitle(minValue, maxValue);

        analytics.eventTrack('robin_searchpage_filter_added', {
            type: 'price',
            value: priceTitle !== 'Price' ? priceTitle : null,
            ...(affiliateCode && { distinct_id: affiliateCode }),
        });
    };

    const handleHomeTypeMixpanel = ()=> {
        const values = getValues();
        const condo_townhome = values['fields.hometype.condo_townhome'];
        const land = values['fields.hometype.land'];
        const mobile_manufactured = values['fields.hometype.mobile_manufactured'];
        const multi_family = values['fields.hometype.multi_family'];
        const single_family = values['fields.hometype.single_family'];

        const filterItems = [
            condo_townhome && 'condo_townhome',
            land && 'land',
            mobile_manufactured && 'mobile_manufactured',
            multi_family && 'mobile_manufactured',
            single_family && 'single_family'
        ].filter((el)=> el);

        analytics.eventTrack('robin_searchpage_filter_added', {
            type: 'home_type',
            value: filterItems.length > 0 ? filterItems.join(',') : null,
            ...(affiliateCode && { distinct_id: affiliateCode }),
        });
    };

    const handleSingleValueMixpanel = (type, field)=> {
        const values = getValues();
        const formValue = values[field];

        if(type === 'beds') {
            const value = formatBedsTitle(formValue);
            return analytics.eventTrack('robin_searchpage_filter_added', {
                type,
                value: value !== 'Beds' ? value : null,
                ...(affiliateCode && { distinct_id: affiliateCode }),
            });
        }
        if(type === 'bath') {
            const value = formatBathTitle(formValue);
            return analytics.eventTrack('robin_searchpage_filter_added', {
                type,
                value: value !== 'Baths' ? value : null,
                ...(affiliateCode && { distinct_id: affiliateCode }),
            });
        }
    };

    if(onboarding && onboarding.isNurtureFirstRunOnboarding){
        onClick = ()=> {
            if(['rpp-nurture-first'].includes(onboarding.stepId)){
                analytics.eventTrack('robin_onboarding_search_area_dialog_dismiss');
                onboarding.next();
            } else {
                return;
            }
        };
    }
    return (
        <div style={{display: 'flex', alignItems: 'center', overflow:'hidden'}}>
            {!isIPadDown && (
                <Box display="flex" pr={3} alignItems="center">
                    <span
                        style={{
                            fontSize: '10px',
                            color: '#a1a1a1',
                            marginTop: '-4px',
                        }}
                    >
                        powered by
                    </span>
                    <Link to="/" style={{ marginLeft: '4px'}}>
                        <RobinLogo/>
                    </Link>
                </Box>
            )}
            <Box display="flex" className="tour-listpack-areas-filter">
                <Area fieldArray={fieldArray}/>
            </Box>
            <Box ml={1} display="flex">
                <LRDropPopover
                    title={priceTitle}
                    onOpen={()=> trackFilterClick('price')}
                    onClick={onClick}
                    toggled={Boolean(priceMin || priceMax)}
                >
                    <Price
                        priceMin={priceMin}
                        priceMax={priceMax}
                        setPriceMin={setPriceMin}
                        setPriceMax={setPriceMax}
                        trackFilterSet={trackFilterSet}
                        handleMixpanelEvent={handlePriceMixpanel}
                    />
                </LRDropPopover>
            </Box>
            <Box ml={1} display="flex">
                <HomeType
                    trackFilterSet={trackFilterSet}
                    trackFilterClick={trackFilterClick}
                    onClick={onClick}
                    handleMixpanelEvent={handleHomeTypeMixpanel}
                />
            </Box>
            <Hidden mdDown>
                <Box ml={1} display="flex">
                    <Beds
                        beds={beds}
                        handleBeds={handleBeds}
                        trackFilterSet={trackFilterSet}
                        trackFilterClick={trackFilterClick}
                        onClick={onClick}
                        handleMixpanelEvent={handleSingleValueMixpanel}
                    />
                </Box>
            </Hidden>

            <Hidden mdDown>
                <Box ml={1} display="flex">
                    <Baths
                        bathrooms={bathrooms}
                        handleBathrooms={handleBathrooms}
                        trackFilterSet={trackFilterSet}
                        trackFilterClick={trackFilterClick}
                        onClick={onClick}
                        handleMixpanelEvent={handleSingleValueMixpanel}
                    />
                </Box>
            </Hidden>
            <Box ml={1} display="flex">
                <More
                    listingsCount={listingsCount}
                    setSaveOpenModal={setSaveOpenModal}
                    showLoading={showLoading}
                    beds={beds}
                    handleBeds={handleBeds}
                    bathrooms={bathrooms}
                    handleBathrooms={handleBathrooms}
                    trackFilterSet={trackFilterSet}
                    trackFilterClick={trackFilterClick}
                    setSaveTrackEvent={setSaveTrackEvent}
                    onClick={onClick}
                    areas={areas}
                    agentId={affiliateCode}
                />
            </Box>
            {!hideSaveButton && (<Box ml={1} display="flex">
                <SaveFilter
                    openSaveModal={openSaveModal}
                    setSaveOpenModal={setSaveOpenModal}
                    saveTrackEvent={saveTrackEvent}
                    setSaveTrackEvent={setSaveTrackEvent}
                    isLoadingListings={showLoading}
                    areas={areas}
                    nameState={nameState}
                />
            </Box>)}
        </div>
    );
}
