import React, { useState, useMemo } from 'react';
import lodashGet from 'lodash/get';
import lodashSome from 'lodash/some';
import styled from 'styled-components';
import { Grid, Box, Typography, ButtonBase, CircularProgress } from '@material-ui/core';
import DisplayAddress from '../DisplayAddress';
import addCommas from '../../../services/add-commas';
import formatPhoneNumber from 'services/format-phone-number';
import { useStore } from '../../../mobx-store';
import { Place } from '@material-ui/icons';
import { BedIcon } from '../../LRIcons';
import { useListingMLS, useListingMLSByZipcode } from 'hooks/data/listing/useListingMLS';
import GoogleMapReact from 'google-map-react';
import LRMapModal from '../../LRMapModal';
import BathtubIcon from '@material-ui/icons/Bathtub';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import { observer } from 'mobx-react-lite';
import analytics from '../../../services/analytics';
import { COLOR_GREY_DARK } from '../../../constants';

const StyledBedIcon = styled(BedIcon)`
    width: 16px;
    height: 16px;
`;

const StyledBathtubIcon = styled(BathtubIcon)`
    width: 16px;
    height: 16px;
`;

const StyledPhotoSizeSelectSmallIcon = styled(PhotoSizeSelectSmallIcon)`
    width: 16px;
    height: 16px;
`;

const Marker = styled.div``;
const MarkerIconWrapper = styled(Box)`
    width: 0;
    height: 0;
`;

const StyledImg = styled.img`
    width: 13px;
    margin-right: 5px;
`;

const StyledListingStatus = styled(({ ...props })=> (
    <Box {...props} />
))`
    font-weight: 900;
    font-size: 10px;
    margin-top: 4px;
`;

const StyledGoogleMapReact = styled(GoogleMapReact)``;

const getListingStatusLabel = (listingStatus)=> {
    const isPending = 
        ['pending', 'activeundercontract', 'escrow', 'contract', 'contingent', 'contingency']
            .includes(listingStatus.toLowerCase());

    const isOffMarket = 
        ['canceled', 'cancelled', 'closed', 'off-market', 'sold']
            .includes(listingStatus.toLowerCase());
     
    const isActive = listingStatus.toLowerCase() === 'active'; 

    if(isPending) {
        return 'Pending';
    } else {
        if(isOffMarket) {
            return 'Off-Market';
        } else {
            if(isActive) {
                return 'Active';
            } 
            else {
                return null;
            }
        }
    } 
};

const PropertyInfo = ({ 
    listingDetails, 
    onEstMortageClick, 
    requestSource, 
    listing, 
    listingsAreNotCards,
    reportId,
    ...props 
})=> {
    const { MortgageCalculatorStore, ListpackStore, UserStore } = useStore();
    const [showMap, setShowMap] = useState(false);
    const { agent, listpack } = ListpackStore;
    const user = UserStore.user;
    const { data: listingMLS, isFetched: isFetchedListingMLS } = useListingMLS(listingDetails);
    const { data: mlses, isFetched } = useListingMLSByZipcode(listingDetails);

    const showMlsName = useMemo(()=> {
        return lodashGet(listingMLS, '[0].add_mls_name_to_listing_agent_info', false);
    }, [listingMLS]);

    const hideAgent = useMemo(()=> {
        return lodashSome(mlses, 'hide_listing_agent_from_detail_page');
    }, [mlses]);

    function handleShowMapClick() {
        analytics.eventTrack('listpacks_consumer_listingpage_map_click', {
            address: listingDetails.address,
            'listing id': listingDetails.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            source: requestSource,
            report_id: reportId,
        });
        setShowMap(true);
    }

    function handleMapOnClose() {
        setShowMap(false);
    }

    const listingStatus = listingDetails.listing_status;
    const listingStatusTextMapping = {
        'Active': 'For Sale',
        'Pending': 'Pending',
        'Off-Market': 'Off-market',
    };

    const listingStatusColors = {
        'Active': '#54D0AA',
        'Pending': 'red',
        'Off-Market': 'lightGrey',
    };

    const listingStatusText = listingStatusTextMapping[getListingStatusLabel(listingStatus)];
    const listingStatusColor = listingStatusColors[getListingStatusLabel(listingStatus)];

    return (
        <>
            <Box>
                {showMlsName && isFetched && isFetchedListingMLS && (
                    <Box color={COLOR_GREY_DARK} fontSize={12} mb={1.5} textAlign="left">
                        <Grid container direction="column">
                            <Box component="span">
                                <Box fontSize={12}>
                                    {'Listing agent: '}
                                    {!hideAgent && (
                                        <>
                                            {listingDetails.realtor.first_name} {listingDetails.realtor.last_name}
                                            {(listingDetails.realtor.first_name || listingDetails.realtor.last_name) && (
                                                <Box px={0.5} component="span" color="#ababab">
                                                    |
                                                </Box>
                                            )}
                                        </>
                                    )}
                                    {listingDetails.realtor.company && (
                                        <>
                                            {listingDetails.realtor.company}{' and '}
                                        </>
                                    )}
                                    {lodashGet(listingMLS, '[0].market_name')}{' '}
                                    {(listingDetails.realtor.phone || listingDetails.realtor.email) && (
                                        <span>{`| ${listingDetails.realtor.phone ? formatPhoneNumber(listingDetails.realtor.phone) : listingDetails.realtor.email} `}</span>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                )}
                <Grid container>
                    {listingsAreNotCards && (
                        <Grid container item xs={12}>
                            <Box
                                fontSize="0.75rem"
                                color="lr_colors.grey_dark"
                                style={{ letterSpacing: '1.5px' }}
                                display="flex"
                                alignItems="center"
                            >
                                <StyledImg src="/images/logo-simple.png" />
                                <Box component="span" letterSpacing={0.25}>
                                    ListReport:
                                </Box>
                            </Box>
                        </Grid>
                    )}
                    <Grid container item xs={12} sm={6} direction="column" justify="space-between">
                        <Box pr={1}>
                            <DisplayAddress 
                                address={listingDetails.address} 
                                listing={listing}
                                listingsAreNotCards={listingsAreNotCards}
                            /> 
                        </Box>
                        <Box
                            display="flex"
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            fontSize="body2.fontSize"
                            pt={[1, 0]}
                        >
                            <Box display="flex" alignItems="center">
                                <StyledBedIcon fontSize={'large'} />
                                <Box component="span" ml={1} fontSize="body1.fontSize">
                                    {listingDetails.beds} bed
                                </Box>
                            </Box>

                            <Box px={3} display="flex" alignItems="center">
                                <StyledBathtubIcon fontSize="small" />
                                <Box component="span" ml={1} fontSize="body1.fontSize">
                                    {listingDetails.baths} baths
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center">
                                <StyledPhotoSizeSelectSmallIcon fontSize="small" />
                                <Box component="span" ml={1} fontSize="body1.fontSize">
                                    {addCommas(listingDetails.home_size)} sqft
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid container item xs={6} sm={3}>
                        <Box mt={[1, 0]}>
                            <Grid item xs={true}>
                                {listingsAreNotCards ? (
                                    <Box>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="h5">${addCommas(listingDetails.price)}</Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box>
                                        <StyledListingStatus color={listingStatusColor}>
                                            {listingStatusText}
                                        </StyledListingStatus>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="h5">${addCommas(listingDetails.price)}</Typography>
                                        </Box>
                                    </Box>
                                )}
                                
                                <Box mt={2}>
                                    <Box lineHeight={1} fontSize="0.75rem">
                                        Est. Mortgage:
                                    </Box>

                                    {/* {isLoading && (
                                        <Box>
                                            <CircularProgress color="secondary" size={20} />
                                        </Box>
                                    )} */}
                                    <Box lineHeight={1} onClick={onEstMortageClick} style={{ cursor: 'pointer' }}>
                                        <Typography variant="body1" color="secondary">
                                            ${MortgageCalculatorStore.formattedMonthlyPayment}/mo
                                        </Typography>
                                    </Box>
                                    {/* {!isLoading && (
                                       
                                    )} */}
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container item xs={6} sm={3} justify="flex-end">
                        {/* Map */}
                        <Box
                            position="relative"
                            width="128px"
                            height="84px"
                            borderRadius="4px"
                            overflow="hidden"
                            style={{ objectFit: 'cover' }}
                            mt={[1, 0]}
                        >
                            <Box width="128px" height="84px" mt={[1, 0]}>
                                <StyledGoogleMapReact
                                    bootstrapURLKeys={{
                                        key: process.env.REACT_APP_GOOGLE_API_KEY,
                                        region: 'us',
                                    }}
                                    defaultZoom={13}
                                    defaultCenter={[33.78724, -117.85496]}
                                    center={[listingDetails.lat, listingDetails.lon]}
                                    options={{
                                        draggable: false,
                                        zoomControl: false,
                                        fullscreenControl: false,
                                        disableDefaultUI: true,
                                    }}
                                >
                                    <Marker lat={listingDetails.lat} lng={listingDetails.lon}>
                                        <MarkerIconWrapper>
                                            <Place
                                                style={{
                                                    width: '26px',
                                                    height: '56px',
                                                    marginLeft: '-13px',
                                                    marginTop: '-28px',
                                                }}
                                            />
                                        </MarkerIconWrapper>
                                    </Marker>
                                </StyledGoogleMapReact>
                            </Box>

                            <Box position="absolute" top="0" bottom="0" left="0" right="0">
                                <ButtonBase
                                    style={{ width: '100%', height: '100%' }}
                                    onClick={handleShowMapClick}
                                ></ButtonBase>
                            </Box>
                        </Box>
                        {/* End Map */}
                    </Grid>
                </Grid>
            </Box>

            {showMap && (
                <LRMapModal
                    listing={listingDetails}
                    address={listingDetails.address}
                    lat={listingDetails.lat}
                    lng={listingDetails.lon}
                    onClose={handleMapOnClose}
                    requestSource={requestSource}
                    reportId={reportId}
                />
            )}
        </>
    );
};

export default observer(PropertyInfo);
