// eslint-disable-next-line
import Logger from 'js-logger';
import { decorate, observable, computed } from 'mobx';

export class ListingsMapStore {
    selectedLaneId = '0';
    selectedListing = null;
    selectedAreas = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    get filteredListings() {
        const { activeBoard } = this.rootStore.boardsStore;
        var filteredListings;

        if(this.selectedLaneId !== '0') {
            filteredListings = activeBoard.getBoardCardsForColumn(this.selectedLaneId);
        } else {
            filteredListings = activeBoard.notArchivedBoardCards;
        }

        return filteredListings.filter((listing)=> {
            return listing.listing_details.lat && listing.listing_details.lon;
        });
    }

    setSelectedAreas(areas) {
        this.selectedAreas = areas;
    }
}

decorate(ListingsMapStore, {
    // Variables
    selectedLaneId: observable,
    selectedListing: observable,
    selectedAreas: observable,

    // computed
    filteredListings: computed
});

export default new ListingsMapStore();
