import _ from 'lodash';

export function groupByKey(collection, keyPath = 'id') {
    return collection.reduce((accum, elem)=> {
        const key = _.get(elem, keyPath);
        return {
            ...accum,
            [key]: elem
        };
    }, {});
}
