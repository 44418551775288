export class PlaceService {
    constructor() {
        this.autocompleteService = new window.google.maps.places.AutocompleteService();
        this.placesService = new window.google.maps.places.PlacesService(document.querySelector('#map'));
    }

    placesAutocomplete(searchTerm) {
        return new Promise((resolve)=> {
            if(!searchTerm) return resolve([]);
            let autocompleteRequest = {
                input: searchTerm,
                types: ['geocode'],
                componentRestrictions: { country: 'us' },
            };
            this.autocompleteService.getPlacePredictions(autocompleteRequest, (results)=> {
                resolve(results);
            });
        });
    }

    predictionDetails(prediction) {
        return this.placeDetails(prediction.place_id);
    }

    placeDetails(placeId) {
        return new Promise((resolve)=> {
            this.placesService.getDetails({ placeId }, (place)=> {
                resolve(place);
            });
        });
    }

    async searchNearbyPlacesGroupedByCategory(lat, lng) {
        const categories = [
            'cafe',
            'supermarket',
            'atm',
            'gas_station',
            'pharmacy',
            'hospital',
            'laundry',
            'gym',
            'movie_theater',
        ];
        const promises = categories.map((category)=> this.searchNearbyPlaces(lat, lng, { category }));
        let [coffee, groceries, atm, gas, pharmacy, hospital, cleaners, gym, movie] = await Promise.all(promises);
        return {
            coffee,
            groceries,
            atm,
            gas,
            pharmacy,
            hospital,
            cleaners,
            gym,
            movie,
        };
    }

    searchNearbyPlaces(lat, lng, { category }) {
        return new Promise((resolve, reject)=> {
            this.placesService.nearbySearch(
                {
                    location: new window.google.maps.LatLng(lat, lng),
                    // radius: 50000,
                    rankBy: window.google.maps.places.RankBy.DISTANCE,
                    type: [category],
                },
                (results, status)=> {
                    if(status === window.google.maps.places.PlacesServiceStatus.OK) {
                        resolve(results.slice(0, 5));
                    } else {
                        console.error(status);
                        resolve([]);
                    }
                }
            );
        });
    }
}
