// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState, Fragment, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography, Grid, withStyles, Collapse, Box, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment-timezone';
import LRButton from '../../LRButton';
import analytics from '../../../services/analytics';
import { COLOR_BLUE_LINK, COLOR_GREY_LIGHT, CALENDAR_IMAGE } from '../../../constants';
import _ from 'lodash';
import { useOpenHouseTimes, useResponsiveConfig } from '../../../hooks';
import { useBoardCollaborations } from '../../../hooks/useBoardCollaborations';
import { useStore } from '../../../mobx-store';
import simpleCypher from '../../../services/simple-cypher';
import LRTooltip from 'components/LRTooltip';
import { useOnboarding } from 'components/Onboarding';

const logger = Logger.get('VirtualAndOpenHouses');

const StyledButton = styled(LRButton)`
    color: ${COLOR_BLUE_LINK};
`;

const OpenHouseContainer = withStyles(
    {},
    { withTheme: true }
)(styled.div`
    flex: 0 0 33%;
    margin-bottom: 42px;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        flex: 0 0 50%;
    }
`);
const OpenHouseTitle = styled(Typography)`
    font-size: 18px;
`;
const OpenHouseCTAs = styled.div`
    margin-top: 10px;
`;
const ViewButtonWrapper = styled.div`
    margin-top: 26px;
    text-align: center;
`;

function filterOpenHousePredicate(openHouse) {
    const possibleDates = [openHouse.date, openHouse.date_iso, openHouse.start_time_iso, openHouse.end_time_iso];

    const result = possibleDates.find((date)=> moment(date)
        .isValid() && moment(date)
        .diff(moment()) > 0);
    return !!result;
}

const noop = ()=> {};
const emptyArray = [];
const VirtualAndOpenHouses = observer(function VirtualAndOpenHouses(props) {
    const theme = useTheme();
    let {
        listing,
        card,
        listpack,
        onContactAgentClick = noop,
        onSchedulePrivateTour = noop,
        trackEventData,
        readOnly= false,
    } = props;

    const openHouses = useMemo(()=> {
        if(!listing || !listing.open_houses) { return []; }
        const sortedOpenHouses = _.orderBy(listing.open_houses.filter(filterOpenHousePredicate), 'start_time_iso', 'asc');
        return sortedOpenHouses;
    }, [listing, listing?.enriched]);

    const openHousesTimes = useOpenHouseTimes(openHouses, listing?.listing_source);

    const virtualTours = useMemo(()=> {
        return listing && listing.virtual_tours ? listing.virtual_tours.slice(0, 2) : emptyArray;
    }, [listing]);


    const { boardsStore, UserStore } = useStore();
    const { isAgent, isLO } = UserStore;
    const cardId = card?.id;
    const { listpackId } = useParams();
    const activeBoard = boardsStore.activeBoard;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const { addBoardCollaboration } = useBoardCollaborations(cardId);
    const [viewAll, setViewAll] = useState(false);
    const limit = useResponsiveConfig({ xs: 2, sm: 3 });
    const showScheduleTourPortal = !listpack && cardId;
    const showScheduleTourRobin = listpack && listing?.listing_source !== 'listhub';
    const onboarding = useOnboarding();
    const showScheduleTour = (showScheduleTourPortal || showScheduleTourRobin ) && !card?.archived;
    const handleOnViewClick = ()=> {
        setViewAll((viewAll)=> !viewAll);
    };

    function handleVirtualTourClick() {
        analytics.eventTrack('listpacks_consumer_listingpage_virtual_tour_button_click', {
            ...trackEventData,
            'listing id': listing.id,
            listpack_id: listpack?.id,
        });
        analytics.eventTrack('robin_gallery_viewed', {
            card_id: card?.id,
            source: 'rpp_virtual_tour_cta',
        });
    }

    // function handlePrivateTourClick(source) {
    //     analytics.eventTrack('listpacks_consumer_listingpage_private_tour_click', {
    //         card_id: card.id,
    //         board_id: card.boardId,
    //         listingId: listing.id,
    //         source,
    //     });
    //     onSchedulePrivateTour(source);
    // }

    const addScheduleOpenHouseCollaboration = (openHouseData)=> {
        const startIso = openHouseData?.start_time_iso || openHouseData?.date_iso;
        let itemId, model, cta;
        if(cardId) {
            itemId = cardId;
            model = 'BoardCard';
        } else if(listing?.id && activeBoard && listpackId) {
            const { id: listing_id, source: listing_source } = simpleCypher.decode(listing.id) || {};
            if(listing_id && listing_source) {
                itemId = `${listing_source}/${listing_id}`;
                model = 'Listing';
                cta = `/boards/listpack/${listpackId}/listings/listing/${listing.id}`;
            }
        }
        if(startIso && model && itemId) {
            const date_time_formatted = moment(startIso)
                .tz(moment.tz.guess())
                .format('MMMM D, YYYY [at] [start_time] z')
                .replace('start_time', openHouseData?.start_time);
            const data = {
                model,
                itemId,
                boardId: activeBoardId,
                content: {
                    ...openHouseData,
                    date_time_formatted,
                    cta,
                },
                type: 'schedule_open_house',
            };
            addBoardCollaboration(data);
        }
    };

    const renderOpenHouse = (openHouseData, i)=> {
        const possibleDates = openHouseData ? [
            openHouseData.date,
            openHouseData.date_iso,
            openHouseData.start_time_iso,
            openHouseData.end_time_iso,
        ] : [];
        const { start_time: openHouseStartTime, end_time: openHouseEndTime } = !_.isEmpty(openHousesTimes) && openHousesTimes[i];
        const validDate = possibleDates.find((date)=> moment(date).isValid());
        const openHouseDate = validDate ? moment(validDate) : null;

        async function onAddToCalendarClick(openHouseData) {
            if(activeBoardId) {
                try {
                    addScheduleOpenHouseCollaboration(openHouseData);
                } catch (err) {
                    logger.error('addScheduleOpenHouseCollaboration', err);
                }
            }
            analytics.eventTrack('listpacks_consumer_listingpage_open_house_add_calendar_click', {
                trackEventData,
                address: listing.address,
                'listing id': listing.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
            });
        }

        return (
            <>
                {openHouseDate && (
                    <OpenHouseContainer key={`${openHouseData.id}-${i}`}>
                        <OpenHouseTitle component="h3" variant="h6">
                            {openHouseDate.format('dddd, MMM D')}
                        </OpenHouseTitle>
                        <Box fontSize={[13, 16]} lineHeight="25px">
                            {((openHouseStartTime === 'Invalid date' || !openHouseStartTime) && 'N/A') || openHouseStartTime} to {((openHouseEndTime === 'Invalid date' || !openHouseEndTime) && 'N/A') || openHouseEndTime}
                        </Box>
                        <OpenHouseCTAs>
                            <LRButton
                                variant="outlined"
                                target="_blank"
                                onClick={()=> onAddToCalendarClick(openHouseData)}
                                href={`${process.env.REACT_APP_BENOIT_URL}/v1/listings/${
                                    listing?.id
                                }/robin-open-houses/${openHouseData.id}/${encodeURIComponent(window.location.href)}`}
                            >
                                Add to calendar
                            </LRButton>
                        </OpenHouseCTAs>
                    </OpenHouseContainer>
                )}
                {!openHouseData && (
                    <OpenHouseContainer>
                        <OpenHouseTitle component="h3" variant="h6">
                            <Skeleton variant="text" animation="wave" height="100%" width="150px" />
                        </OpenHouseTitle>
                        <Box fontSize={[13, 16]} lineHeight="25px">
                            <Skeleton variant="text" animation="wave" height="100%" width="200px" />
                        </Box>
                        <OpenHouseCTAs>
                            <Skeleton variant="rect" animation="wave" height="36px" width="136px" />
                        </OpenHouseCTAs>
                    </OpenHouseContainer>
                )}
            </>
        );
    };

    const renderTourInPerson = ()=> {
        return (
            <>
                <Grid item justify="space-between" alignContent="space-between">
                    <OpenHouseContainer>
                        <OpenHouseTitle component="h3" variant="h6" style={{ lineHeight: '26px' }}>
                            Tour in person
                            <br />
                            with your agent
                        </OpenHouseTitle>
                        <OpenHouseCTAs>
                            {
                                !isAgent && !isLO ? (
                                    <LRButton
                                        variant="contained"
                                        color="primary"
                                        disabled={(onboarding?.isActive && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding ))|| readOnly}
                                        onClick={
                                            listpack
                                                ? _.partial(onContactAgentClick, 'open_house')
                                                : _.partial(onSchedulePrivateTour, 'schedule_a_tour_btn')
                                        }
                                    >
                                        Schedule a tour
                                    </LRButton>
                                ) : (
                                    <LRTooltip
                                        hideArrow={false}
                                        disableFocusListener
                                        disableTouchListener
                                        title={
                                            'You will receive notifications if a client wishes to schedule a tour.'
                                        }
                                    >
                                        <div>
                                            <LRButton
                                                variant="contained"
                                                color="primary"
                                                disabled={true}
                                            >
                                                Schedule a tour
                                            </LRButton>
                                        </div>
                                    </LRTooltip>
                                )
                            }
                        </OpenHouseCTAs>
                    </OpenHouseContainer>
                </Grid>
            </>
        );
    };

    const renderViewButton = ()=> {
        if(openHouses && openHouses.length > limit) {
            return (
                <ViewButtonWrapper>
                    <StyledButton variant="text" inline color="primary" onClick={handleOnViewClick}>
                        View{viewAll ? ' less' : ' all'}
                    </StyledButton>
                </ViewButtonWrapper>
            );
        } else {
            return undefined;
        }
    };

    if(!listing) {
        return (
            <Box>
                <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>Open House</Typography>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    {[...Array(limit)].map(renderOpenHouse)}
                </Grid>
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>Open House</Typography>

            {openHouses && openHouses.length ? (
                <Fragment>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                        {openHouses.slice(0, limit)
                            .map(renderOpenHouse)}
                        {/* {openHouses.length < limit && this.renderCantWaitCard()} */}
                        {openHouses.length < limit && showScheduleTour && renderTourInPerson()}
                    </Grid>
                    {openHouses && openHouses.length >= limit && (
                        <Collapse in={viewAll}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                {openHouses.slice(limit)
                                    .map(renderOpenHouse)}
                                {/* {this.renderCantWaitCard()} */}
                                {showScheduleTour && renderTourInPerson()}
                            </Grid>
                        </Collapse>
                    )}
                </Fragment>
            ) : (
                <Box
                    bgcolor={COLOR_GREY_LIGHT}
                    position={['relative', 'inherit']}
                    height={['210px', 'auto']}
                    mt={[5, 'auto']}
                >
                    <Box
                        display="flex"
                        flexDirection={['column-reverse', 'row']}
                        alignItems="center"
                        alignContent="center"
                        justifyContent="space-evenly"
                        textAlign={['center', 'left']}
                    >
                        <Box position={['absolute', 'inherit']} top={[75, 'auto']} fontSize={16}>
                            <Box>There are no open houses scheduled at this time.</Box>
                            {showScheduleTour && (
                                <>
                                    <Box fontWeight="bold" mb={2}>
                                        Why not tour in person with your agent?
                                    </Box>
                                    {
                                        !isAgent && !isLO ? (
                                            <LRButton
                                                disabled={(onboarding?.isActive && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding ))|| readOnly}
                                                variant="contained"
                                                color="primary"
                                                onClick={
                                                    listpack
                                                        ? _.partial(onContactAgentClick, 'open_house')
                                                        : _.partial(onSchedulePrivateTour, 'schedule_a_tour_btn')
                                                }
                                            >
                                                Schedule a tour
                                            </LRButton>
                                        ) : (
                                            <LRTooltip
                                                hideArrow={false}
                                                disableFocusListener
                                                disableTouchListener
                                                title={
                                                    'You will receive notifications if a client wishes to schedule a tour.'
                                                }
                                            >
                                                <div style={{width: '140px'}}>
                                                    <LRButton
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={true}
                                                    >
                                                        Schedule a tour
                                                    </LRButton>
                                                </div>
                                            </LRTooltip>
                                        )
                                    }
                                </>
                            )}
                        </Box>
                        <Box position={['absolute', 'inherit']} mt={3}>
                            <img height="85px" src={CALENDAR_IMAGE} alt="calendar" />
                        </Box>
                    </Box>
                    <br />
                </Box>
            )}

            {(openHouses?.length > 0 || virtualTours?.length > 0) && (
                <Box textAlign="center" mt="20px">
                    {showScheduleTour && (
                        <>
                            <Box fontSize="18px" fontWeight="bold">
                                Tour when it suits you best
                            </Box>
                            <Box fontSize="16px" mt="5px">
                                Schedule a{' '}
                                <LRButton
                                    inline
                                    disabled={onboarding?.isActive && onboarding?.isFirstRunOnboarding}
                                    onClick={
                                        listpack
                                            ? _.partial(onContactAgentClick, 'open_house')
                                            : _.partial(onSchedulePrivateTour, 'private_tour_link')
                                    }
                                >
                                    <Typography component="span" fontSize="16px">
                                        <u>private tour</u>
                                    </Typography>
                                </LRButton>{' '}
                                or virtually tour the home right now!
                            </Box>
                        </>
                    )}
                    {virtualTours?.length > 0 && (
                        <Box
                            display="flex"
                            flexDirection={['column', 'row']}
                            mt="10px"
                            alignItems="center"
                            justifyContent="center"
                            flexWrap="wrap"
                        >
                            {virtualTours.map((virtualTour, i)=> (
                                <Box
                                    component="a"
                                    target="_blank"
                                    onClick={handleVirtualTourClick}
                                    href={virtualTour.url}
                                    key={i}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <LRButton
                                        style={{
                                            width: '140px',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            margin: '4px',
                                            backgroundColor: 'white',
                                            border: '1px solid #BBBBBB',
                                        }}
                                        variant="contained"
                                    >
                                        {i ? `Virtual tour #${i + 1}` : 'Take a virtual tour'}
                                    </LRButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            )}
            {listing.listing_source !== 'listhub' && renderViewButton()}
        </Box>
    );
});

VirtualAndOpenHouses.propTypes = {
    listing: PropTypes.object,
    card: PropTypes.object,
    listpack: PropTypes.object,
    onSchedulePrivateTour: PropTypes.func,
};

export default VirtualAndOpenHouses;
