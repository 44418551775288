import React from 'react';
import { SvgIcon } from '@material-ui/core';

function BedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25.67 17.5" {...props}>
            <path d="M7,9.33a3.5,3.5,0,1,0-3.5-3.5A3.49,3.49,0,0,0,7,9.33Zm14-7H11.67V10.5H2.33V0H0V17.5H2.33V14h21v3.5h2.34V7A4.67,4.67,0,0,0,21,2.33Z" />
        </SvgIcon>
    );
}

export default BedIcon;
