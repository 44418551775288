import React from 'react';
import lodashGet from 'lodash/get';
import addressSlugger from 'services/address-slugger';
import { ChatBubble, CalendarToday, LocalAtm } from '@material-ui/icons';
import styled from 'styled-components';
import simpleCypher from 'services/simple-cypher';
import { TourRounded } from 'components/LRIcons';

const StyledSpan = styled.span`
    text-decoration: underline;
`;

const getScheduledListingCard = (notification, board)=> {
    return { name: notification?.content?.listing_address };
};

const getCardName = (notification, board)=> {
    let cardName = lodashGet(notification, 'content.card_name') || lodashGet(notification, 'content.address');
    if(!cardName) {
        let card;
        if(notification.type == 'schedule_open_house' && notification.model === 'Listing') {
            card = getScheduledListingCard(notification, board);
        } else {
            card = board.boardCardsById[notification.itemId] || {};
        }

        cardName = lodashGet(card, 'name') || lodashGet(card, 'listing_details.address') || ' a listing';
    }

    return ` ${cardName}`;
};

const getCardUrl = (notification, board)=> {
    const cardId = lodashGet(notification, 'itemId');
    const address = lodashGet(notification, 'content.address');
    return `/board/cards/${cardId}/${addressSlugger(address)}`;
};

const getHero = (notification)=> lodashGet(notification, 'content.image_url');
const getImageUrls = (notification)=> {
    const activities = lodashGet(notification, 'activities') || [];
    return activities.map((a)=> lodashGet(a, 'content.image_url'))
        .filter((v)=> v);
};

const notificationConfig = {
    user_joined: {
        text: (board, notification, currentUserId)=>
            lodashGet(notification, 'user.id') === currentUserId ? (
                <p>Welcome to Robin, {lodashGet(notification, 'user.first_name')}!</p>
            ) : (
                <p>{lodashGet(notification, 'user.first_name')} has joined the board. Welcome!</p>
            ),
        cta: ()=> '/boards/account/co-buyers',
        trackEventName: (notification)=>
            lodashGet(notification, `content.user_types.${lodashGet(notification, 'user.id')}`) === 'buyer'
                ? 'agent_to_buyer_robin_invite_accepted'
                : 'buyer_to_cobuyer_robin_invite_accepted',
    },
    sent_invite: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} invited{' '}
                <StyledSpan>
                    {lodashGet(notification, 'content.first_name') ||
                        lodashGet(notification, 'content.invitee_first_name')}
                </StyledSpan>{' '}
                to the board.
            </p>
        ),
        cta: ()=> '/boards/account/co-buyers',
        trackEventName: 'buyer_to_cobuyer_robin_invite',
    },
    comment: {
        text: (board, notification)=> {
            return (
                <>
                    {!notification.visibleToAffiliate ? (
                        <p>
                            {lodashGet(notification, 'user.first_name')} added a private note to
                            <StyledSpan>{getCardName(notification, board)}</StyledSpan>.
                        </p>
                    ) : (
                        <p>
                            {lodashGet(notification, 'user.first_name')} added a note to
                            <StyledSpan>{getCardName(notification, board)}</StyledSpan>.
                            {' '}
                            <i>{`"${notification.content.message}"`}</i>
                        </p>
                    )}
                </>
            );
        },
        icon: ()=> <ChatBubble />,
        cta: getCardUrl,
        trackEventName: 'rpp_note_added',
    },
    reaction: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} liked a note on{' '}
                <StyledSpan>{getCardName(notification, board)}</StyledSpan>.
            </p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'rpp_note_liked',
    },
    preferences_change: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} updated preferences:{' '}
                <StyledSpan>{notification?.content?.updated_preferences}</StyledSpan>
            </p>
        ),
    },
    moved_card: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} moved{' '}
                <StyledSpan>{getCardName(notification, board)}</StyledSpan> to{' '}
                <StyledSpan>{lodashGet(notification, 'content.toColumn')}</StyledSpan>.
            </p>
        ),
        cta: getCardUrl,
        trackEventName: 'robin_card_moved',
    },
    schedule_viewing_request: {
        text: (board, notification)=> {
            return (
                <p>
                    <b>{lodashGet(notification, 'user.name')}</b> submitted a new home tour request: {' '}
                    <b>&ldquo;{lodashGet(notification, 'content.tour_name')}&rdquo;</b>.
                </p>
            );
        },
        hero: getHero,
        cta: (notification)=> `/boards/scheduled-tours/${lodashGet(notification, 'id')}`,
        icon: ()=> <TourRounded />,
        trackEventName: 'robin_privateviewing_requested',
    },
    schedule_open_house: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} scheduled an open house viewing for{' '}
                <StyledSpan>{getCardName(notification, board)}</StyledSpan>.
            </p>
        ),
        hero: getHero,
        cta: (notification, board)=> {
            let boardCard;
            if(notification.model === 'Listing') {
                const [source, id] = notification.itemId.split('/');
                const encodedId = simpleCypher.encode(source, id);
                boardCard = board.listingsStore.listingsById[encodedId]?.linkedBoardCard;
            } else {
                boardCard = board.boardCardsById[notification.itemId] || null;
            }
            return boardCard ? `/board/cards/${boardCard.id}/${addressSlugger(boardCard.listing_details.address)}` : notification.content.cta;
        },
        icon: ()=> <CalendarToday />,
        trackEventName: 'robin_openhouse_scheduled',
    },
    offer_submitted: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} would like to prepare an offer for{' '}
                <StyledSpan>{getCardName(notification, board)}</StyledSpan>
                {' '}
                {notification?.content?.message && (`"${notification.content.message}"`)}
            </p>
        ),
        icon: ()=> <LocalAtm />,
        trackEventName: 'robin_submit_offer',
        cta: getCardUrl,
    },
    pinspiration_image: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} saved
                {lodashGet(notification, 'activity_count', 0) > 1
                    ? ` ${notification.activity_count} images `
                    : ' an image '}
                from <span style={{ textDecoration: 'underline' }}>{getCardName(notification, board)}</span>.
            </p>
        ),
        hero: getHero,
        imageUrls: getImageUrls,
        cta: ()=> '/boards/idea-board',
        trackEventName: 'pinspiration_pinned_photo',
    },
    created_saved_search: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} added a saved search{' '}
                <StyledSpan>{lodashGet(notification, 'content.listpack_name')}</StyledSpan> to the board.
            </p>
        ),
        cta: (notification)=> `/boards/listpack/${lodashGet(notification, 'itemId')}/listings`,
        trackEventName: 'robin_savedsearch_added',
    },
    updated_saved_search: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} updated filters on the{' '}
                <StyledSpan>{lodashGet(notification, 'content.listpack_name')}</StyledSpan> saved search.
            </p>
        ),
        cta: (notification)=> `/boards/listpack/${lodashGet(notification, 'itemId')}/listings`,
        trackEventName: 'robin_savedsearch_prefs_updated',
    },
    archived_saved_search: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} removed the saved search{' '}
                <StyledSpan>{lodashGet(notification, 'content.listpack_name')}</StyledSpan> from the board.
            </p>
        ),
        trackEventName: 'robin_savedsearch_removed',
    },
    created_card: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} added
                {notification?.activity_count > 1 ? ` ${notification.activity_count} new homes to ` : ' a new home to '}
                <StyledSpan>{lodashGet(notification, 'content.column_name')}</StyledSpan>.
            </p>
        ),
        cta: getCardUrl,
        trackEventName: 'added_new_home_manually',
    },
    deleted_card: {
        text: (board, notification, userId, trim)=> {
            return (
                <p>
                    {lodashGet(notification, 'user.first_name')} removed{' '}
                    <StyledSpan>{getCardName(notification, board)}</StyledSpan>
                    {` from the board. `}
                    {notification.content.message && (
                        <StyledSpan>{`"${
                            notification.content.message.length > 20 && trim
                                ? notification.content.message.slice(0, 20) + '...'
                                : notification.content.message
                        }"`}</StyledSpan>
                    )}
                </p>
            );
        },
        cta: (notification)=> {
            return `/boards/archivedListings/${lodashGet(notification, 'itemId')}`;
        },
        trackEventName: 'deleted_home',
        hero: getHero,
    },
    deleted_all_homes_in_lane: {
        text: (notification)=> {
            return (
                <p>
                    <b>{lodashGet(notification, 'user.name')}</b> deleted all the homes in the <b>{lodashGet(notification, 'content.laneName')}</b> column.  You can find them in your “Deleted homes” under your “Saved searches”.”
                </p>
            );
        },
        cta: ()=> {
            return `/boards/archivedListings`;
        },
        trackEventName: 'robin_deleted_all_homes_in_lane',
    },
    new_agent_recommended_homes: {
        text: (board, notification)=> (
            <p>{lodashGet(notification, 'user.first_name')} recommended new homes. View homes.</p>
        ),
        cta: (notification)=> `/boards/listpack/${lodashGet(notification, 'itemId')}/listings`,
        imageUrls: getImageUrls,
        trackEventName: 'agent_recommended_homes',
    },
    user_removed: {
        text: (board, notification)=> (
            <p>{lodashGet(notification, 'content.first_name')} was removed from the board.</p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'robin_cobuyer_removed',
    },
    user_left: {
        text: (board, notification)=> <p>{lodashGet(notification, 'content.first_name')} left the board.</p>,
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'robin_cobuyer_left_board',
    },
    listpack_new_listings: {
        text: (board, notification)=> (
            <p>
                Check out the new listings in your{' '}
                <StyledSpan>{lodashGet(notification, 'content.listpack_name')}</StyledSpan> saved search.
            </p>
        ),
        cta: (notification)=> lodashGet(notification, 'content.cta'),
        trackEventName: 'robin_new_listings_in_saved_search',
        hero: getHero,
        avatar: false,
    },
    updated_buy_timeframe: {
        text: (board, notification)=> (
            <p>
                {lodashGet(notification, 'user.first_name')} updated their buying timeframe{' '}
                {notification?.content?.old_timeframe && (
                    <span>
                        from <StyledSpan>{notification?.content?.old_timeframe}</StyledSpan>{' '}
                    </span>
                )}
                to <StyledSpan>{notification?.content?.new_timeframe}</StyledSpan>.
            </p>
        ),
    },
};

export default notificationConfig;
