// eslint-disable-next-line
import React, { useState, useRef } from 'react';
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import LRModal from '../../components/LRModal';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import analytics from '../../services/analytics';
import { useOnboarding } from '../../components/Onboarding';
import {
    AddAnyHomeContent,
    AddAnyHomeAddressLinkContainer,
    AddAnyHomeAddressLinkMainText,
    AddAnyHomeAddressRequired,
    AddAnyHomeAddressLinkSecondaryText,
} from './style';
import { useResponsiveBreakpoint } from '../../hooks';
import { COLOR_CREST_WHITE, COLOR_OFF_WHITE, COLOR_GRADE } from '../../constants';
import AddAnyHomeHeader from './AddAnyHomeHeader';
import AddAnyHomeListingPreview from './AddAnyHomeListingPreview';
import BoardListingModal from '../BoardListingModal';
import { SearchPlaceInput } from './SearchPlaceInput';

function AddAnyHomeModal() {
    const history = useHistory();
    const match = useRouteMatch();
    const onboarding = useOnboarding();

    const { AddAnyHomeStore, listingsStore } = useStore();
    const isXs = useResponsiveBreakpoint('xs');

    const formRegister = useRef();
    const [isLoading, setLoading] = useState(false);

    const { listingNotFound, matchedListings } = AddAnyHomeStore;

    const matchedListingsLength = matchedListings?.length;

    const matchedListingsLabel =
        matchedListingsLength > 1
            ? matchedListingsLength + ' listings'
            : matchedListingsLength
                ? matchedListingsLength + ' listing'
                : null;
    function onRemoveClick(e, id) {
        AddAnyHomeStore.setMatchedListings(matchedListings.filter((listing)=> listing.id !== id));
    }

    async function openListingPreview(listing) {
        await listingsStore.fetchListingById(listing.id);
        history.push(`${match.url}/listings/${listing.id}`);
    }

    function handleOnFoundListings(listings, searchTerm) {
        listings = listings.filter((listing)=> listing.active);
        if(listings && listings.length) {
            const result = listings[0];
            const listingExistsAlready = matchedListings.filter((listing)=> listing.id === result?.id);

            if(listingExistsAlready?.length > 0) {
                AddAnyHomeStore.setListingNotFound(false);
                analytics.eventTrack('robin_add_any_home_failed', {
                    user_input: searchTerm,
                    source: 'add_any_home',
                });
                return null;
            }

            AddAnyHomeStore.setMatchedListings([...matchedListings, result]);
            AddAnyHomeStore.setListingNotFound(false);
        } else {
            AddAnyHomeStore.setListingNotFound(true);
            analytics.eventTrack('robin_add_any_home_failed', {
                user_input: searchTerm,
                source: 'add_any_home',
            });
        }
    }

    function handleClose() {
        AddAnyHomeStore.setMatchedListings([]);
        AddAnyHomeStore.setMatchedListing(null);
        AddAnyHomeStore.setListingNotFound(false);
        history.push("boards");
    }

    return (
        <>
            <LRModal
                onClose={handleClose}
                paperProps={
                    isXs
                        ? {}
                        : {
                            style: {
                                backgroundColor: COLOR_OFF_WHITE,
                            },
                        }
                }
            >
                <Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                        pt={['18px', '22px']}
                        bgcolor={COLOR_CREST_WHITE}
                    >
                        <AddAnyHomeHeader
                            ref={formRegister}
                            handleClose={handleClose}
                            setAddingListings={setLoading}
                            addingListings={isLoading}
                        />

                        <AddAnyHomeContent bgcolor={isXs ? null : COLOR_OFF_WHITE} pt={[0, '24px']}>
                            <AddAnyHomeAddressLinkContainer
                                flexWrap="wrap"
                                pl={['20px', '40px']}
                                pr={['16px', '40px']}
                                mx={[0, '40px']}
                                flexDirection="row"
                                borderRadius={4}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width={'100%'}
                                    pt={['24px', '32px']}
                                    alignItems="baseline"
                                >
                                    <AddAnyHomeAddressLinkMainText>
                                        Listing address or link
                                    </AddAnyHomeAddressLinkMainText>
                                    <AddAnyHomeAddressRequired>
                                        <Typography variant="body2">Required</Typography>
                                    </AddAnyHomeAddressRequired>
                                </Box>
                                <Box display="flex" pt="16px" pb="24px">
                                    <AddAnyHomeAddressLinkSecondaryText>
                                        Enter the address of a home you're interested in, or, if you found it online,
                                        paste the home's link so we can pull the address for you.
                                    </AddAnyHomeAddressLinkSecondaryText>
                                </Box>

                                <Box
                                    width="100%"
                                    pb={listingNotFound === null ? '40px' : listingNotFound === false ? '24px' : '40px'}
                                    display="flex"
                                >
                                    <Box width="100%">
                                        <SearchPlaceInput
                                            onChange={()=> AddAnyHomeStore.setListingNotFound(false)}
                                            onFoundListings={handleOnFoundListings}
                                        />
                                    </Box>
                                </Box>

                                <Box width={isXs ? '100%' : null}>
                                    {!isLoading && listingNotFound && (
                                        <Box
                                            textAlign="center"
                                            display="flex"
                                            fontSize={14}
                                            color="error.main"
                                            alignItems="center"
                                            width="100%"
                                            pb={1}
                                            mt={['-24px', '-30px']}
                                        >
                                            The address or link was not recognized, or the house is no longer on the market.
                                        </Box>
                                    )}

                                    <Box
                                        display={matchedListingsLabel ? 'flex' : 'none'}
                                        color={COLOR_GRADE}
                                        fontWeight="500"
                                        fontSize="24px"
                                        mb={[3, '26px']}
                                    >
                                        {matchedListingsLabel}
                                    </Box>

                                    <Box>
                                        {matchedListings?.map((listing_details, i)=> {
                                            return (
                                                <AddAnyHomeListingPreview
                                                    key={`listing-card-${listing_details?.id}-${i}`}
                                                    listing_details={listing_details}
                                                    openListingPreview={openListingPreview}
                                                    onRemoveClick={onRemoveClick}
                                                    ref={formRegister}
                                                />
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </AddAnyHomeAddressLinkContainer>
                        </AddAnyHomeContent>
                    </Box>
                </Box>
            </LRModal>
            <Route
                path={`${match.path}/listings/:listingIds`}
                render={()=> <BoardListingModal onClose={()=> history.push(match.url)} />}
            />
        </>
    );
}

export default observer(AddAnyHomeModal);
