import { decorate, observable, action, computed } from 'mobx';


export class AccountStore {
    settings = {

        activityNotifications: {
            text: false,
            email: false
        },

        justListedNotifications: {
            text: false,
            email: false
        },

        recentlySoldNotifications: {
            text: false,
            email: false
        }
    };

    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    
    get notificationSettings(){
        return this.settings;
    }

    setNotification(key,value) {
        this.settings[key] = value;
    }
}

decorate(AccountStore, {
    settings: observable,
    notificationSettings: computed,
    setNotification: action
});

export default new AccountStore();