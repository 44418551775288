import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Dialog, Box, Grid, CircularProgress, useTheme, Slide } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import LRModal from '../../../components/LRModal';
import LRButton from '../../../components/LRButton';
import CompareNow from '../../../components/CompareNow';
import _ from 'lodash';
import Logger from 'js-logger';
import { useListingsDetails } from 'hooks/useListingsDetails';
import { useMyHomeAVM } from 'hooks/useMyHome';
import { ModalRoute } from 'components/ModalRoute';
import { LRListingDetailsModal } from 'components/LRListingDetailsModal';

const PROPERTYLABEL_TO_NUMBER = {
    all: '0', //this is All label, needs to be a string 0
    Active: 1,
    Pending: 2,
    Sold: 3,
};

const StyledDialog = styled(({ color, ...other })=> (
    <Dialog classes={{ paperWidthSm: 'paperWidthSm-compare-now' }} {...other} />
))`
    & .paperWidthSm-compare-now {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        overflow: visible;
    }
`;

const StyledLRModal = withTheme(styled(LRModal)`
    max-width: 450px;
    max-height: 245px;
    margin: auto auto;
`);

const CompareErrorModal = ({ onClose })=> {
    return (
        <StyledLRModal disableBackdropClick={true} onClose={onClose}>
            <Box px={4} pt={4}>
                <Box align="center">
                    <Box fontSize="h5.fontSize">Compare homes</Box>
                </Box>

                <Box my={2} mx="auto" borderTop={3} borderColor="grey.300" maxWidth={24} />

                <Box align="center">
                    <Box>
                        You'll need to have at least 2 homes on your board to use the comparison tool. Move some homes
                        from your saved searches to get started.
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center" mt={2}>
                    <Box width="50%" pl={1}>
                        <LRButton fullWidth color="primary" component="span" variant="contained" onClick={onClose}>
                            Ok
                        </LRButton>
                    </Box>
                </Box>
            </Box>
        </StyledLRModal>
    );
};

const CompareNearbyPropertiesModal = observer(function CompareNearbyPropertiesModal({
    open,
    myHome,
    listings: nearbyLististings,
    lockedListings = [],
    onClose = ()=> {},
    propertyLabel = 'all',
}) {
    const theme = useTheme();
    const match = useRouteMatch();
    const history = useHistory();
    const avm = useMyHomeAVM();
    const CATEGORIES = [
        {
            index: 1,
            label: 'Active',
            value: 'Active',
        },
        {
            index: 2,
            label: 'Pending',
            value: 'Pending',
        },
        {
            index: 3,
            label: 'Sold',
            value: 'Sold',
        },
    ];
    const { data:listings, isLoading, } = useListingsDetails(nearbyLististings);
    const loading = isLoading;
    const cardsByCategories = useMemo(()=> {
        if(loading) return;

        const categoriesByListingStatus = _.keyBy(CATEGORIES, 'value');
        const cards = listings?.filter((listing)=> {return (listing.listing_status in categoriesByListingStatus);})
            .map((listing)=> ({
                listing_details: listing,
                hideLock: false,
                canOpen: true,
                cardActions: false,
            }));
        const filteredCards = _.groupBy(cards, (card)=> {
            return categoriesByListingStatus[card.listing_details.listing_status].index;
        });

        _.each(CATEGORIES, (category)=> {
            if(!(category.index in filteredCards)) {
                filteredCards[category.index] = [];
            }
        });

        return filteredCards;
    }, [CATEGORIES, listings, loading]);
    const alwaysLockedCards = useMemo(()=> {
        if(!myHome) return [];

        const myHomeCard = toCard(toListing(myHome, avm));
        return [myHomeCard];
    }, [myHome, avm]);
    const lockCards = useMemo(()=> {
        return lockedListings.map((listing)=> {
            const nl = {...listing};
            return {listing_details: nl};
        });
    }, [lockedListings]);
    const canCompare = useMemo(()=> {
        return listings ? listings.length >= 2 : loading;
    }, [listings, loading]);


    // Functions

    function toCard(l) {
        return {
            listing_details: l,
            icon: 'My Home',
            hideLock: true,
            canOpen: false,
            cardActions: false,
        };
    }

    function toListing(h, avm) {
        return {
            address: h.address,
            baths: h.bathrooms,
            beds: h.bedrooms,
            home_size: h.living_area,
            lot_size: h.lot_size,
            hero_image_url: h.hero_image,
            id: h.id,
            lat: h.latitude,
            lon: h.longitude,
            price: avm?.estimatedValue,
        };
    }

    if(!canCompare) {
        return open
            ? <CompareErrorModal onClose={onClose} />
            : <Redirect to="/home-report" />;
    }

    return (
        <>
            <StyledDialog
                open={open}
                fullScreen
                maxWidth="100%"
                onClose={onClose}
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'left',
                }}
            >
                {!loading && (
                    <CompareNow
                        categories={CATEGORIES}
                        cardsByCategories={cardsByCategories}
                        onClose={onClose}
                        closeLabel={'Home Report'}
                        alwaysLockedCards={alwaysLockedCards}
                        lockCards={lockCards}
                        source={'home_report'}
                        allLabel="All"
                        filter={PROPERTYLABEL_TO_NUMBER[propertyLabel] || '0'}
                        disableListingRoutes
                    />
                )}

                {loading && (
                    <Grid container justify="center" style={{ padding: theme.spacing(3) }}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
            </StyledDialog>

            {match && (
                <ModalRoute
                    exact
                    path={`${match?.url}/listings/:listingId`}
                    Component={LRListingDetailsModal}
                    ComponentProps={{
                        TransitionComponent: Slide,
                        TransitionProps: {
                            direction: 'up',
                        },
                        listings: listings,
                        hideMainHeader: true,
                        onClose: ()=> history.push(match.url),
                    }}
                    useSwitch
                />
            )}
        </>
    );
});

export default CompareNearbyPropertiesModal;
