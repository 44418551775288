import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AppBar, Toolbar, IconButton, Box, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { GalleryIcon, ListIcon } from '../../LRIcons';
import addCommas from '../../../services/add-commas';

const StyledToggleButton = styled(ToggleButton)`
    text-transform: none;
    color: #000;
    padding-left: 14px !important;
    padding-right: 14px !important;

    @media screen and (max-width: 700px) {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    &.Mui-selected {
        color: #000;
        background-color: #eeeeee;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;
const StyledGridItem = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const StyledHeaderControls = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 250px;
`;
const StyledTitleTypography = styled(Typography)`
    color: #000;
    font-weight: normal;
    font-size: 16px;
`;

function HeaderDetails({ listing, isMobile }) {
    return (
        <Box
            fontSize={16}
            borderTop={`${isMobile ? '1px solid #dddddd' : 'none'}`}
            component="h3"
            variant="h6"
            color="#000"
            p={2}
            width={1}
            textAlign="center"
        >
            {listing.price && listing.price > 0 ? `$${addCommas(listing.price)} ` : ''}
            {listing.price && listing.price > 0 && (
                <Box display="inline" fontFamily="Arial">
                    •
                </Box>
            )}
            {listing.beds && listing.beds > 0 ? ` ${listing.beds} ${isMobile ? 'BD' : 'bed'},` : ''}
            {listing.baths && listing.baths > 0 ? ` ${listing.baths} ${isMobile ? 'BA' : 'bath'},` : ''}
            {listing.home_size && listing.home_size > 0 ? ` ${addCommas(listing.home_size)} sqft` : ''}
        </Box>
    );
}

function Header({ defaultToggleValue, onClose, onViewToggleChange, listing, requestSource, ...otherProps }) {
    const [selectedButton, setSelectedButton] = useState(defaultToggleValue);
    const theme = useTheme();
    const gtXs = useMediaQuery(theme.breakpoints.up('sm'));

    /**
     * @function onToggleChange
     * @param {Event} e
     * @param {String} value
     */
    function onToggleChange(e, value) {
        // We dont ever want the value to be undefined so
        // default to the default value if no value
        value = value || defaultToggleValue;

        setSelectedButton(value);
        onViewToggleChange(e, value);
    }

    return (
        <AppBar style={{ backgroundColor: '#fff' }} position="static" {...otherProps}>
            <Toolbar style={{ padding: '0px' }}>
                <Grid container>
                    <Grid container justify="flex-end">
                        <StyledGridItem item sm={8} xs={10}>
                            {/* Header Details */}
                            {gtXs && <HeaderDetails listing={listing} isMobile={false} />}
                            {/* Mobile Title */}
                            {!gtXs && (
                                <Box margin="0 auto">
                                    <StyledTitleTypography component="h3" variant="h6">
                                        Photos
                                    </StyledTitleTypography>
                                </Box>
                            )}
                        </StyledGridItem>
                        <StyledHeaderControls item
                            sm={4}
                            xs={2}
                        >
                            {/* Toggle Buttons */}
                            {gtXs && (
                                <Box marginLeft="auto">
                                    <ToggleButtonGroup
                                        size="small"
                                        value={selectedButton}
                                        exclusive
                                        onChange={onToggleChange}
                                    >
                                        <StyledToggleButton value="list">
                                            <Box display="flex">
                                                <ListIcon />
                                            </Box>
                                            <Box ml={0.5}>List</Box>
                                        </StyledToggleButton>

                                        <StyledToggleButton value="carousel">
                                            <Box display="flex">
                                                <GalleryIcon />
                                            </Box>
                                            <Box ml={0.5}>Gallery</Box>
                                        </StyledToggleButton>

                                    </ToggleButtonGroup>
                                </Box>
                            )}
                            {/* Close Button */}
                            <Box ml={['auto', 0]}
                                mr={1}
                                maxWidth={50}
                            >
                                <IconButton variant="contained" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </StyledHeaderControls>
                    </Grid>
                    {/* Mobile Details */}
                    {!gtXs && (
                        <Grid container>
                            <StyledGridItem item xs={12}>
                                <HeaderDetails listing={listing} isMobile={true} />
                            </StyledGridItem>
                        </Grid>
                    )}
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
Header.propTypes = {
    defaultToggleValue: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onViewToggleChange: PropTypes.func.isRequired,
    listing: PropTypes.object.isRequired,
};

export default React.memo(Header);
