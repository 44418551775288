import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function TourRounded(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.634 3.57967C13.8073 3.13967 13.4873 2.66634 13.014 2.66634H8.66732H8.00065H4.66732V1.99967C4.66732 1.63301 4.36732 1.33301 4.00065 1.33301C3.63398 1.33301 3.33398 1.63301 3.33398 1.99967V2.66634V9.33301V14.6663H4.66732V9.33301H7.33398H8.00065H13.014C13.4873 9.33301 13.8073 8.85967 13.634 8.41967L12.6673 5.99967L13.634 3.57967ZM10.0007 5.99967C10.0007 6.73301 9.40065 7.33301 8.66732 7.33301C7.93398 7.33301 7.33398 6.73301 7.33398 5.99967C7.33398 5.26634 7.93398 4.66634 8.66732 4.66634C9.40065 4.66634 10.0007 5.26634 10.0007 5.99967Z" />
        </SvgIcon>
    );
}
