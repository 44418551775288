import React, { useState, useEffect, useMemo } from 'react';
import { Box, Divider, CircularProgress, CardMedia } from '@material-ui/core';
import LRButton from '../../LRButton';
import LRPoweredByLabel from '../../LRPoweredByLabel';
import styled from 'styled-components';
import { ArrowForwardIos } from '@material-ui/icons';
import { COLOR_GREY_LIGHT, COLOR_GREY, COLOR_GREY_DARK, COLOR_SALMON, DEFAULT_HEADSHOT } from '../../../constants';
import { useResponsiveBreakpoint, useResponsiveConfig } from '../../../hooks';
import { useParams, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import ListpackHeader from '../ListpackHeader';
import queryString from 'query-string';
import analytics from '../../../services/analytics';
import { Swiper } from '../../Swiper';
import formatPhoneNumber from '../../../services/format-phone-number';
import formatAreas from '../../../services/format-areas';
import launchdarkly from '../../../services/launchdarkly';
import theme from '../../../theme';

const AgentHeadshot = styled(CardMedia)`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`;

const MobileDivider = styled(Divider)`
    height: 2px;
    background-color: #eeecee;
    width: 24px;
    margin: 15px auto;
`;

const PhoneNumber = styled.a`
    color: inherit;
    text-decoration: unset;
`;

const launchdarklyViewButtonFlag = 'list-packs-listview-a-b-test';

function ViewButton({ listingsCount, loadedListingsCount, handleViewButtonClick, hideListingsCount, ...props }) {
    return (
        <Box display="flex" alignItems="center">
            <LRButton
                style={{
                    margin: '20px auto',
                    height: 50,
                    maxWidth: 330,
                    width: '100%',
                    color: 'white',
                    backgroundColor: COLOR_SALMON,
                    fontSize: theme.typography.body1.fontSize,
                    fontWeight: 'normal',
                    letterSpacing: '0.5px',
                    ...props,
                }}
                onClick={handleViewButtonClick}
            >
                View homes {(loadedListingsCount && !hideListingsCount) ? `(${listingsCount})` : ''}{' '}
                <Box marginLeft="10px" display="flex" alignItems="center" fontSize="14px">
                    <ArrowForwardIos fontSize="inherit" />
                </Box>
            </LRButton>
        </Box>
    );
}

function ListpackCover() {
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;

    const location = useLocation();
    const { order, preview, source, disable_clicked_metric } = queryString.parse(location.search);

    const { ListpackStore } = useStore();
    const {
        listings,
        listpack,
        listingsCount,
        areaIds,
        agent,
        loadedListpack,
        loadedAgent,
        loadedListingsCount,
        error,
    } = ListpackStore;
    const history = useHistory();

    // const [areas, setAreas] = useState([]);

    const [redirectToView, setRedirectToView] = useState(false);
    const [redirectToEnd, setRedirectToEnd] = useState(false);
    const [redirectFlag, setRedirectFlag] = useState(launchdarkly.variation(launchdarklyViewButtonFlag));
    const hideListingsCount = useMemo(()=> (/^Top\s\d+/).test(listpack?.name), [listpack]);

    const sliderHeight = useResponsiveConfig({ xs: '220px', sm: '450px' });

    useEffect(()=> {
        if(preview) {
            ListpackStore.previewMode = true;
        }

        if(disable_clicked_metric) {
            ListpackStore.disableClickedMetric = true;
        }
    }, [preview, disable_clicked_metric]);

    useEffect(
        function() {
            if(listpack && areaIds) {
                ListpackStore.fetchTopPriceListings(listpack.id, areaIds);
            }
        },
        [ListpackStore, listpack, areaIds]
    );

    useEffect(()=> {
        if(!listpack || !agent) {
            return;
        }
        analytics.eventTrack('listpacks_consumer_frontcover_page_viewed', {
            listpack_id: listpack.id,
        });
    }, [listpack, agent]);

    // useEffect(()=> {
    //     if(areaIds && areaIds.length) {
    //         var areas;
    //         try {
    //             areas = JSON.parse(areaIds);
    //         } catch (err) {
    //             areas = JSON.parse(atob(areaIds));
    //         }

    //         setAreas(areas);
    //     }
    // }, [areaIds]);

    const handleViewButtonClick = ()=> {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_frontcover_view_homes_click', {
            listpack_id: listpack.id,
        });

        setRedirectToView(true);
    };

    const onContactClick = ()=> {
        if(!loadedListpack || !loadedAgent) return;

        analytics.eventTrack('listpacks_consumer_frontcover_contact_agent_click', {
            listpack_id: listpack.id,
        });
        setRedirectToEnd(true);
    };

    function handleLaunchdarklyChange() {
        setRedirectFlag(launchdarkly.variation(launchdarklyViewButtonFlag));
    }

    function handleLaunchdarklyError() {
        console.error('Launchdarkly error: Launchdarkly returned an error, redirecting to list-view.');
        setRedirectFlag('list-view');
    }

    launchdarkly.on('ready', handleLaunchdarklyChange);
    launchdarkly.on('change', handleLaunchdarklyChange);
    launchdarkly.on('error', handleLaunchdarklyError);

    if(redirectToView && redirectFlag) {
        if(redirectFlag === 'detail-view') {
            history.push(`/listpack/${ListpackStore.listpackEncodedPayload}/list?order=${order}${source ? `&source=${source}` : ''}`);
            return (
                <Redirect
                    push
                    to={`/listpack/${ListpackStore.listpackEncodedPayload}/listings/${listings[0].id}?order=${order}${source ? `&source=${source}` : ''}`}
                />
            );
        } else {
            return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/list?order=${order}${source ? `&source=${source}` : ''}`} />;
        }
    }

    if(redirectToEnd) {
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/end?order=${order}${source ? `&source=${source}` : ''}&focus=true`} />;
    }

    return (
        <>
            <ListpackHeader />
            {(!loadedListpack || !loadedAgent || error) && (
                <Box display="flex" flex={1} alignItems="center" justifyContent="center" width="100%" height="100%">
                    {!error && <CircularProgress color="secondary" />}
                    {error && <>There was an error while loading the listpack. Please try again later.</>}
                </Box>
            )}
            {loadedListpack && loadedAgent && !error && (
                <>
                    <Box color="#1A1A1A" margin={`0 ${isMobile ? '20px' : '50px'}`}>
                        <Box position="relative" overflow="hidden" textAlign="center" borderRadius={4}>
                            <LRPoweredByLabel />
                            <Swiper
                                data={ListpackStore.topImages}
                                autoplay={true}
                                renderSlide={(image)=> {
                                    return (
                                        <Box
                                            height={sliderHeight}
                                            width={1}
                                            style={{
                                                backgroundImage: `url('${image}')`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        ></Box>
                                    );
                                }}
                            />
                        </Box>
                        <Box
                            position="relative"
                            zIndex={1}
                            left="50%"
                            style={{ transform: 'translate(-50%, -20%)' }}
                            height={isMobile ? '50px' : '64px'}
                            width={isMobile ? '50px' : '64px'}
                        >
                            <AgentHeadshot image={agent.headshot || DEFAULT_HEADSHOT} />
                        </Box>
                        <Box fontSize={[34, 48]} fontWeight={500} align="center">
                            {listpack.name}
                        </Box>
                        <Box fontSize={[20, 24]} align="center">
                            Presented by {agent.name}
                        </Box>
                        {isMobile ? (
                            <MobileDivider />
                        ) : (
                            <ViewButton
                                handleViewButtonClick={handleViewButtonClick}
                                listingsCount={listingsCount}
                                loadedListingsCount={loadedListingsCount}
                                marginBottom={30}
                                hideListingsCount={hideListingsCount}
                            />
                        )}
                        {ListpackStore && ListpackStore.areaIds && (
                            <Box
                                align={`${isMobile ? 'center' : 'left'}`}
                                fontSize={16}
                                letterSpacing={0.5}
                                lineHeight={1.5}
                            >
                                {ListpackStore.isCustomListpack && (
                                    <>Featuring listings located in {formatAreas(ListpackStore.areaIds)}</>
                                )}
                                {!ListpackStore.isCustomListpack && (
                                    <>
                                        {listpack.name} located in {formatAreas(ListpackStore.areaIds)}
                                    </>
                                )}
                            </Box>
                        )}

                        <Box
                            marginTop={[11 / 4, 13 / 4]}
                            marginBottom={5 / 2}
                            marginX={0}
                            padding="20px"
                            align={`${isMobile ? 'center' : 'left'}`}
                            bgcolor={COLOR_GREY_LIGHT}
                            fontWeight={500}
                            borderRadius={4}
                        >
                            <Box fontSize={[16, 20]}>{agent.name}</Box>
                            <Box position="relative" fontSize="14px">
                                <Box>{agent.company}</Box>
                                <Box>
                                    <PhoneNumber href={isMobile ? `tel:${agent.phone}` : undefined}>
                                        {formatPhoneNumber(agent.phone)}
                                    </PhoneNumber>{' '}
                                    • License #{agent.normalized_license_number}
                                </Box>
                                <Box>{agent.email}</Box>
                                <LRButton
                                    color="inherit"
                                    style={{
                                        fontFamily: 'inherit',
                                        fontWeight: 'normal',
                                        letterSpacing: '0.75px',
                                        backgroundColor: 'unset',
                                        border: `1px solid ${COLOR_GREY}`,
                                        ...(!isMobile && { position: 'absolute' }),
                                        marginTop: '10px',
                                        width: '100%',
                                        maxWidth: '90px',
                                        right: 0,
                                        bottom: 0,
                                    }}
                                    component="span"
                                    onClick={onContactClick}
                                >
                                    Contact
                                </LRButton>
                            </Box>
                        </Box>
                        {agent.preferred_vendor && agent.preferred_vendor.vendor?.company_type === 'Mortgage' && (
                            <Box
                                paddingBottom="20px"
                                marginTop="20px"
                                fontSize="12px"
                                color={COLOR_GREY_DARK}
                                textAlign="center"
                            >
                                <Box>Financing available through {agent.preferred_vendor.name}</Box>
                                <Box>{agent.preferred_vendor.company}</Box>
                                <Box>
                                    <PhoneNumber href={isMobile ? `tel:${agent.preferred_vendor.phone}` : undefined}>
                                        {formatPhoneNumber(agent.preferred_vendor.phone)}
                                    </PhoneNumber>{' '}
                                    • NMLS #{agent.preferred_vendor.normalized_license_number}
                                </Box>
                                <Box>{agent.preferred_vendor.email}</Box>
                            </Box>
                        )}
                    </Box>
                    {isMobile && (
                        <Box
                            width="100%"
                            bgcolor={COLOR_GREY_LIGHT}
                            position="fixed"
                            bottom="0"
                            borderTop={1}
                            borderColor="#DDDDDD"
                        >
                            <ViewButton
                                handleViewButtonClick={handleViewButtonClick}
                                listingsCount={listingsCount}
                                loadedListingsCount={loadedListingsCount}
                                width="100%"
                                margin={12}
                                maxWidth="unset"
                                hideListingsCount={hideListingsCount}
                            />
                        </Box>
                    )}
                </>
            )}
        </>
    );
}

export default observer(ListpackCover);
