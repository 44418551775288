import React, { useState, useEffect } from 'react';
import { Typography, useTheme, CardMedia, Box } from '@material-ui/core';
import { LogoRIcon } from 'components/LRIcons/logoR';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { EmailInput } from '../EmailInput';
import { useParams } from 'react-router-dom';
import { useAgent } from 'hooks/useAgent';
import { useHomeReportSlides } from 'hooks/useHomeReportSlides';
import { portalApi, benoitApi } from 'apis';
import { DEFAULT_HEADSHOT } from '../../../constants';
import styled from 'styled-components';
import analytics from 'services/analytics';
import brazeClient from 'services/analytics/providers/braze';
import { Swiper } from 'components/Swiper';
import { Disclaimer, DisclaimerAgentBranding } from '../Disclaimers';
import { LRDialog } from 'components/LRDialog';
import LRLink from 'components/LRLink';
import { useStore } from 'mobx-store';
import formatPhoneNumber from 'services/format-phone-number';

const AgentHeadshot = styled(CardMedia)`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`;

export const Page2 = function({ variation, uniqueId, email, setSignupStep }) {
    const { isIPadDown } = useDeviceBreakpoints();
    const { GlobalSnackbarStore } = useStore();
    const theme = useTheme();
    const [inputValue, setInputValue] = useState(email || '');
    const [loading, setLoading] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const { affiliateCode } = useParams();
    const { data: agent } = useAgent(affiliateCode, { skipUsersAffiliate: true});
    const {selectedPhotoIndex, setSelectedPhotoIndex, currentSlide, slideContents} = useHomeReportSlides();

    useEffect(function() {
        if(agent?.id) {
            analytics.eventTrack('robin_home_report_opt_in_page_viewed', {
                distinct_id: agent.id,
                variation,
                anonymous_unique_id: uniqueId,
            });
        }
    }, [variation, agent, uniqueId]);

    const onSubscribe = function() {
        subscribeNewsletter(inputValue);
    };

    const subscribeNewsletter = async function(inputEmail) {
        const leadData = {
            applicant: {
                name: 'Prospective Lead',
                email: inputEmail,
                phone: null
            },
            type: 'realtor',
            ownerId: agent.id,
            source: 'Home Report',
        };

        setLoading(true);

        const userExists = await benoitApi.userExists(inputEmail);
        analytics.eventTrack('robin_home_report_opt_in_page_email_submitted', {
            distinct_id: agent.id,
            variation,
            email_submitted: inputEmail,
            anonymous_unique_id: uniqueId,
        });

        if(!userExists) {
            const createLeadResponse = await portalApi.createLead(agent, leadData);

            if(createLeadResponse) {
                const { createdNewLead, data } = createLeadResponse;
                if(createdNewLead && data) {
                    const customAttrs = { type: 'home_report_subscriber' };
                    brazeClient.vendorLeadEmailSubscribe(data, customAttrs);

                    const agentActivityData = {
                        type: 'lead',
                        userId: agent.id,
                        meta: {
                            lead: data
                        },
                        name: 'affiliates_home_report_subscribed',
                    };

                    const loActivityData = {
                        type: 'lead',
                        userId: agent.preferredVendorId,
                        meta: {
                            lead: data
                        },
                        name: 'affiliates_home_report_subscribed',
                    };

                    await Promise.all([
                        portalApi.createActivity(agentActivityData),
                        portalApi.createActivity(loActivityData),
                        portalApi.sendHomeReportOptinAffiliateNotify(
                            data.applicant.email,
                            agent.id,
                            agent.preferredVendorId
                        )
                    ]);
                }
            }

            setSignupStep(inputEmail);
            GlobalSnackbarStore.show('You’re successfully subscribed to the Home Report!', {autoHideDuration: 3000});
        } else {
            setShowSignInModal(true);
        }
    };

    return (
        <div>
            {showSignInModal && (
                <LRDialog
                    open={true}
                >
                    <div style={{margin: theme.spacing(2.5)}}>
                        <Typography variant="body1" display="inline">
                            This email is already associated with a Robin account.
                            Log into your Robin account by clicking
                        </Typography>
                        <LRLink to={'/login'}>
                            <Typography variant="body1" display="inline">
                                {' '}here.
                            </Typography>
                        </LRLink>
                    </div>
                </LRDialog>
            )}
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <div style={{
                    maxWidth: isIPadDown ? '360px' : '488px',
                    margin: isIPadDown ? '0 auto' : 0,
                    padding: isIPadDown ? `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(4.5)}px `:
                        `${theme.spacing(8)}px ${theme.spacing(8)}px`
                }}>
                    <div style={{width: '107px', height: '32px', marginBottom: theme.spacing(isIPadDown ? 1.5 : 10 )}}>
                        <LogoRIcon style={{width: '100%', height: '100%'}} />
                    </div>
                    <div style={{marginBottom: theme.spacing(isIPadDown ? 1.5 : 2)}}>
                        <Typography variant="h4">
                            See what your home is worth in today's market.
                        </Typography>
                    </div>
                    <div style={{marginBottom: theme.spacing(3)}}>
                        <Typography variant="h6">
                            I'll share your updated home value, recently bought and sold properties in your neighborhood, and top real estate news each month. You'll also have instant access to a dashboard to manage your home's value over time!
                        </Typography>
                    </div>
                    <div style={{width: '100%'}}>
                        <EmailInput
                            setInputValue={setInputValue}
                            adornmentProps={{width: isIPadDown ? '80px' : '127px'}}
                            onSubmit={onSubscribe}
                            loading={loading}
                            defaultValue={inputValue}
                        />
                    </div>
                    {variation === 'simplified_with_agent_branding' && (
                        <div>
                            <div style={{display: 'flex', alignItems: 'flex-end', paddingTop: theme.spacing(isIPadDown ? 3 : 4)}}>
                                <div style={{
                                    height: '56px',
                                    width: '56px',
                                    marginRight: theme.spacing(1.5)
                                }}>
                                    <AgentHeadshot image={agent?.headshot || DEFAULT_HEADSHOT} />
                                </div>
                                <div style={{
                                    color: '#fff',
                                    backgroundColor: theme.palette.lr_colors.pool,
                                    borderRadius: '12px',
                                    width: isIPadDown ? 'calc(100% - 68px)' : '291px',
                                    height: '76px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Typography variant="body2" color="inherit" style={{
                                        marginLeft: theme.spacing(1.5),
                                        marginRight: theme.spacing(0.5)
                                    }}>
                                        Let’s keep in touch! You can reach out directly with any questions
                                        about the email.
                                    </Typography>
                                </div>
                            </div>
                            <div style={{
                                marginTop: theme.spacing(1.5),
                                marginLeft: theme.spacing(8.5),
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}>
                                {agent.name && (
                                    <div>
                                        <Typography variant="caption" display="inline" style={{fontWeight: 'bold'}}>{agent.name}</Typography>
                                        <span style={{margin: `0 ${theme.spacing(0.5)}px`}}>•</span>
                                    </div>
                                )}
                                {agent.company && (
                                    <div>
                                        <Typography variant="caption" display="inline">{agent.company}</Typography>
                                        <span style={{margin: `0 ${theme.spacing(0.5)}px`}}>•</span>
                                    </div>
                                )}
                                {agent.normalized_license_number && (
                                    <div>
                                        <Typography variant="caption" display="inline">License#{agent.normalized_license_number}</Typography>
                                        <span style={{margin: `0 ${theme.spacing(0.5)}px`}}>•</span>
                                    </div>
                                )}
                                {agent.phone_formatted && (
                                    <div>
                                        <a
                                            href={`tel:+1${agent.phone}`}
                                            onClick={()=> {
                                                analytics.eventTrack('robin_home_report_signup_page_agent_phone_link_click', {
                                                    distinct_id: agent.id,
                                                    lead_email: email,
                                                });
                                            }}
                                        >
                                            <Typography variant="caption" display="inline">{formatPhoneNumber(agent.phone)}</Typography>
                                        </a>
                                        <span style={{margin: `0 ${theme.spacing(0.5)}px`}}>•</span>
                                    </div>
                                )}
                                {agent.email && (
                                    <div>
                                        <a
                                            href={`mailto: ${agent.email}`}
                                            onClick={()=> {
                                                analytics.eventTrack('robin_home_report_signup_page_agent_email_link_click', {
                                                    distinct_id: agent.id,
                                                    lead_email: email,
                                                });
                                            }}
                                        >
                                            <Typography variant="caption" display="inline" style={{wordBreak: 'break-word'}}>{agent.email}</Typography>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div style={{display: 'flex', paddingTop: theme.spacing(variation === 'simplified_with_agent_branding' ? 3 : 2)}}>
                        {variation === 'simplified_with_agent_branding' ? <DisclaimerAgentBranding fullName={`${agent.first_name} ${agent.last_name}`}/> : <Disclaimer fullName={`${agent.first_name} ${agent.last_name}`}/> }
                    </div>
                </div>
                <div style={{
                    width: isIPadDown ? '100%' : 'min( 100%, calc(100% - 488px) )',
                    height: isIPadDown ? 'auto' : '100vh',
                    background: currentSlide.background,
                    paddingBottom: isIPadDown ? '36px' : 0,
                }}>
                    <Swiper
                        data={slideContents}
                        slidesPerView={1}
                        pagination={true}
                        variant={currentSlide.variant}
                        autoplay={{delay: 9000}}
                        initialSlide={0}
                        activeIndex={selectedPhotoIndex}
                        dynamicBullets={false}
                        loop
                        clickable
                        onSlideChange={setSelectedPhotoIndex}
                        renderSlide={(slide)=> {

                            return (
                                <Box height={1}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        height={1}
                                        maxWidth="100%"
                                        paddingX={[3, 0]}
                                    >
                                        <div>
                                            <img src={slide.photo} alt="" style={{ width: '100%' }}/>
                                        </div>
                                        {
                                            slide.title && slide.text && (
                                                <div
                                                    style={{
                                                        maxWidth: isIPadDown ? '100%' : '400px',
                                                        ...(slide.textColor ? { color: slide.textColor } : {}),
                                                    }}
                                                >
                                                    <Typography variant="h4">{ slide.title }</Typography>
                                                    <Typography variant="h6"
                                                        style={{
                                                            marginBottom: theme.spacing(2),
                                                        }}
                                                    >
                                                        { slide.text }
                                                    </Typography>
                                                </div>
                                            )
                                        }

                                    </Box>
                                </Box>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
