import React, { useEffect, useState } from 'react';
import { Box, Dialog, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Swiper } from '../../../Swiper';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { withTheme } from '@material-ui/styles';
import addCommas from '../../../../services/add-commas';
import { CircleArrowIcon } from '../../../LRIcons';
import PinspirationOverlay from '../../../Pinspiration/PinspirationOverlay';
import { useResponsiveBreakpoint } from 'hooks';

const PhotoSwiper = observer(function({
    photos,
    startIndex,
    selectedPhotoIndex,
    setSelectedPhotoIndex,
    listing_details,
    onNextClick = ()=> {},
    onPreviousClick = ()=> {},
}) {
    const isXs = useResponsiveBreakpoint('xs');
    return (
        <Swiper
            data={photos}
            initialSlide={startIndex}
            slidesPerView={1}
            hasNavigation={!isXs}
            onSlideChange={(index)=> {
                if(index === selectedPhotoIndex +1) {
                    onNextClick();
                }
                else {
                    onPreviousClick();
                }

                setSelectedPhotoIndex(index);
            }}
            activeIndex={selectedPhotoIndex}
            navigationLeft={<CircleArrowIcon />}
            navigationRight={<CircleArrowIcon right />}
            renderSlide={(image)=> (
                <Box px={[0, 16]}>
                    <Box position="relative" height={1} display="flex" alignItems="center" justifyContent="center">
                        <Box position="relative" height="100%" maxWidth="900px">
                            <Box
                                component="img"
                                width='100%'
                                height='100%'
                                src={image}
                                alt={image}
                            />
                            <PinspirationOverlay src={image} listing={listing_details} listpackId={null} />
                        </Box>
                    </Box>
                </Box>
            )}
        ></Swiper>
    );
});

const StyledPhotoSwiper = withTheme(styled(PhotoSwiper)``);

const PhotoDetailModal = observer(function({ open, onClose, photos, startIndex, listing_details, onNextClick, onPreviousClick }) {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const listingAddress = listing_details.address;
    const listingPrice = listing_details.price ? `$${addCommas(listing_details.price)}` : null;
    const listingBeds = listing_details.beds ? `${listing_details.beds} bd` : null;
    const listingBaths = listing_details.baths ? `${listing_details.baths} ba` : null;
    const listingHomeSize = listing_details.home_size ? `${addCommas(listing_details.home_size)} sqft` : null;
    const listingInfo = [listingPrice, listingBeds, listingBaths, listingHomeSize].filter(Boolean);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(startIndex);
    useEffect(()=> {
        setSelectedPhotoIndex(startIndex);
    }, [startIndex]);
    const paperProps = {
        style: {
            padding: `0 ${isXs ? 0 : theme.spacing(6)}px`,
            backgroundColor: '#000',
        },
    };
    return (
        <Dialog PaperProps={paperProps} fullScreen open={open}>
            <IconButton onClick={onClose} style={{ position: 'absolute', right: '30px', top: '20px', color: 'white' }}>
                <CloseIcon />
            </IconButton>
            <Box display="flex" flexDirection="column" height={1}>
                <Box
                    height={50}
                    my={4}
                    style={{ color: 'white' }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography align="center">
                        {selectedPhotoIndex + 1}/{photos.length}
                    </Typography>
                </Box>
                <Box flex={1} overflow="hidden">
                    <StyledPhotoSwiper
                        photos={photos}
                        selectedPhotoIndex={selectedPhotoIndex}
                        setSelectedPhotoIndex={setSelectedPhotoIndex}
                        startIndex={startIndex}
                        listing_details={listing_details}
                        onNextClick={onNextClick}
                        onPreviousClick={onPreviousClick}
                    />
                </Box>
                <Box
                    height={50}
                    my={4}
                    style={{ color: 'white' }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography align="center">{listingAddress}</Typography>
                    <Typography align="center">{listingInfo.join(' | ')}</Typography>
                </Box>
            </Box>
        </Dialog>
    );
});

export default PhotoDetailModal;
