import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, withTheme, CircularProgress } from '@material-ui/core';
import LRButton from '../LRButton';

const paperProps = {
    style: {
        width: '560px',
        borderRadius: '12px',
    },
};


const Modal = styled(Dialog)`

`;

const Title = styled(DialogTitle)`
    padding-top: 28px;
    padding-bottom: 0px;
`;

const Content = withTheme(styled(DialogContent)`
    padding-top: 14px;
    ${({theme})=> theme.breakpoints.up('sm')}{
        padding-top: 26px;
    }
`);

const Actions = withTheme(styled(DialogActions)`
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-top: 16px;
    ${({theme})=> theme.breakpoints.up('sm')}{
        padding-top: 36px;
    }
`);

export function ListpackScheduleTourConfirmationModal({ open, title, subtitle, onCancelClick, onConfirmClick, loading, ...props}){
    return (
        <Modal {...props} open={open} PaperProps={paperProps}>
            <Title>
                <Typography variant="h6">
                    {title}
                </Typography>
            </Title>
            <Content>
                <Typography>
                    {subtitle}
                </Typography>
            </Content>
            <Actions>
                <LRButton
                    color="inherit"
                    variant="text"
                    underline
                    onClick={onCancelClick}
                >
                        Cancel
                </LRButton>
                <LRButton color="primary" variant="contained" onClick={onConfirmClick}
                    disabled={loading}
                >
                    {!loading && 'Schedule a tour'}
                    {loading && <CircularProgress color="secondary" size={24} />}
                </LRButton>
            </Actions>
        </Modal>
    );
}