import React from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { TimeframeSettings } from './TimeframeSettings';
import { useStore } from '../../../../mobx-store';
import analytics from '../../../../services/analytics';

export const TimeframeForm = observer(function TimeframeForm(props){
    const { UserStore } = useStore();
    const { user } = UserStore;
    const defaultTimeframeOption = UserStore.howSoonTimeframe;
    const onSubmit = (newTimeframe)=> {
        analytics.eventTrack('robin_board_drawer_notification_card_clicked', {
            source: 'cta_button',
            type: 'timeframe'
        });
        if(newTimeframe !== defaultTimeframeOption){
            analytics.eventTrack('robin_user_timeframe_updated', {
                role: UserStore.usersBoardRole,
                board_id: user.defaultBoardId,
                source: 'agent_drawer',
                new_timeframe: UserStore.timeframeMapper[newTimeframe],
                old_timeframe: UserStore.timeframeMapper[defaultTimeframeOption],
                name: user.name,
            });
        }
        return UserStore.updateUserTimeframe(newTimeframe);
    };
    return (
        <Box border="1px solid #DDDDDD" borderRadius="4px" style={{boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 8px'}}>
            <Box py="26px" px="24px">
                <TimeframeSettings onSubmit={onSubmit} defaultOption={defaultTimeframeOption} />
            </Box>
        </Box>
    );
});