import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Room } from '@material-ui/icons';
import { useListingExplorerStoreContext } from '../store';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import PinspirationOverlay from '../../Pinspiration/PinspirationOverlay';
import { Tour3DIcon } from '../../LRIcons';
import { observer } from 'mobx-react-lite';
import PhotoDetailModal from './PhotoDetailModal';
import analytics from '../../../services/analytics';
import querystring from 'query-string';
import GoogleMapReact from 'google-map-react';

const StyledPhotoWrapper = withTheme(styled.div`
    max-height: 354px;
    max-width: 530px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;

    ${({ theme })=> theme.breakpoints.down('xs')} {
        max-height: none;
        max-width: none;
        height: 240px;
        width: 100%;
        margin-bottom: 4px;
    }
`);

const StyledImageContainer = withTheme(styled.div`
    display: inline-block;
    position: relative;

    > img {
        width: 100%;
    }
`);

const scrollAdjust = 95;

const scrollToRef = (parentRef, myRef)=> {
    // window.scrollTo(0, myRef.current.offsetTop - scrollAdjust);
    parentRef.current.scrollTo({ top: myRef.current.offsetTop - scrollAdjust });
};

export const PhotoGallery = observer(function PhotoGallery({ selectedPhotoIndex, PinspirationOverlayProps = {} }) {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const isXs = useResponsiveBreakpoint('xs');
    const listingExplorerStore = useListingExplorerStoreContext();
    const { UserStore, ListpackStore } = useStore();
    const listing_details = listingExplorerStore.listing_details;
    const photos = listingExplorerStore.photos;
    const evenPhotos = photos.filter((v, i)=> !(i % 2));
    const oddPhotos = photos.filter((v, i)=> i % 2);
    const [selectedPhotoDetailIndex, setSelectedPhotoDetailIndex] = useState(null);
    const [openPhotoDetail, setOpenSelectedPhotoDetail] = useState(false);

    const myRef = useRef(null);
    const parentRef = useRef(null);

    useEffect(()=> {
        if(selectedPhotoIndex) {
            scrollToRef(parentRef, myRef);
            history.replace(match.url.replace(`index=${selectedPhotoIndex}`, ''));
        }
    }, [selectedPhotoIndex, parentRef, history, match]);

    function onNextClick() {
        analytics.eventTrack('robin_gallery_photo_detail_next_click', {
            card_id: listingExplorerStore.card.id,
        });
    }

    function onPreviousClick() {
        analytics.eventTrack('robin_gallery_photo_detail_previous_click', {
            card_id: listingExplorerStore.card.id,
        });
    }

    function handleSectionNavigation(tab) {
        const search = querystring.parse(location.search);
        history.push({
            search: querystring.stringify({
                ...search,
                tab
            })
        });
    }

    const hasCoords = useMemo(()=> listing_details?.lat || listing_details?.lon, [listing_details]);

    return (
        <Box
            className="tour-target-listing-image-gallery-modal"
            height={1}
            overflow="auto"
            ref={parentRef}
        >
            <PhotoDetailModal
                open={openPhotoDetail}
                onClose={()=> setOpenSelectedPhotoDetail(false)}
                photos={photos}
                startIndex={selectedPhotoDetailIndex}
                listing_details={listing_details}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            />
            {!isXs && (
                <Box width={1} py={2}>
                    <Box maxWidth={1080} mx="auto" display="flex">
                        <Box mr={2.5} width="calc(50% - 10px)">
                            {evenPhotos.map((photo)=> {
                                return (
                                    <StyledPhotoWrapper
                                        onClick={()=> {
                                            setSelectedPhotoDetailIndex(photos.indexOf(photo));
                                            analytics.eventTrack('robin_gallery_photo_click', {
                                                card_id: listingExplorerStore.card.id,
                                                source: 'photo',
                                            });
                                            setOpenSelectedPhotoDetail(true);
                                        }}
                                        key={`photo_detail_modal_photo_wrapper_${photo}`}
                                        ref={photos.indexOf(photo) === selectedPhotoIndex ? myRef : undefined}
                                    >
                                        {/* <img src={photo} height={354} width={530}/> */}
                                        <StyledImageContainer>
                                            <img
                                                src={photo}
                                                alt={photo}
                                                style={{
                                                    width: '100%',
                                                    height: '354px',
                                                    objectFit: 'cover',
                                                    objectPosition: 'bottom'
                                                }}
                                            />
                                            {listing_details && (
                                                <PinspirationOverlay
                                                    src={photo}
                                                    listing={listing_details}
                                                    listpackId={null}
                                                    {...PinspirationOverlayProps}
                                                />
                                            )}
                                        </StyledImageContainer>
                                    </StyledPhotoWrapper>
                                );
                            })}
                        </Box>
                        <Box width="calc(50% - 10px)">
                            {listingExplorerStore.has3dTour && (
                                <Box
                                    maxWidth={530}
                                    maxHeight={253}
                                    mb={2.5}
                                    position="relative"
                                    style={{ cursor: 'pointer' }}
                                    onClick={()=> {
                                        analytics.eventTrack('robin_gallery_photo_click', {
                                            card_id: listingExplorerStore.card.id,
                                            source: '3d_tour',
                                        });
                                        handleSectionNavigation('3dTour');
                                    }}
                                >
                                    <Box
                                        width={1}
                                        height={1}
                                        position="absolute"
                                        zIndex={1}
                                        bgcolor="lr_colors.grade"
                                        style={{ opacity: 0.5 }}
                                    />
                                    <Box
                                        width={1}
                                        height={1}
                                        position="absolute"
                                        zIndex={2}
                                        bgcolor="transparent"
                                        color="white"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box fontSize={31} height={31} mb={1}>
                                            <Tour3DIcon fontSize="inherit" />
                                        </Box>
                                        <Typography color="inherit" variant="h6">
                                            3D Tour
                                        </Typography>
                                    </Box>
                                    <Box maxWidth={530} maxHeight={253} overflow="hidden">
                                        <img
                                            style={{
                                                maxWidth: '530px',
                                                maxHeight: '253px',
                                                objectFit: 'fill',
                                                filter: 'blur(5px)',
                                            }}
                                            src={photos[photos.length - 1]}
                                            alt="Explore Nearby"
                                        />
                                    </Box>
                                </Box>
                            )}
                            {/* // Hiding the explore nearby block to reduce Places API usage

                            <Box
                                maxWidth={530}
                                height={253}
                                mb={2.5}
                                position="relative"
                                style={{ cursor: 'pointer' }}
                                onClick={()=> {
                                    analytics.eventTrack('robin_gallery_photo_click', {
                                        card_id: listingExplorerStore.card.id,
                                        source: 'explore_nearby',
                                    });
                                    handleSectionNavigation('explore');
                                }}
                            >
                                <Box
                                    width={1}
                                    height={1}
                                    position="absolute"
                                    bgcolor="lr_colors.grade"
                                    style={{ opacity: 0.7 }}
                                    zIndex="1"
                                />
                                <Box
                                    width={1}
                                    height={1}
                                    position="absolute"
                                    bgcolor="transparent"
                                    color="white"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    zIndex="1"
                                >
                                    <Box fontSize={31} height={31} mb={1}>
                                        <Room fontSize="inherit" />
                                    </Box>
                                    <Typography color="inherit" variant="h6">
                                        Explore Nearby
                                    </Typography>
                                </Box>
                                {hasCoords && (
                                    <Box width={1} height={1}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{
                                                key: process.env.REACT_APP_GOOGLE_API_KEY,
                                                region: 'us',
                                            }}
                                            defaultZoom={13}
                                            defaultCenter={[33.78724, -117.85496]}
                                            center={[listing_details.lat, listing_details.lon]}
                                            options={{
                                                draggable: false,
                                                zoomControl: false,
                                                fullscreenControl: false,
                                                disableDefaultUI: true,
                                                draggableCursor: 'pointer',
                                            }}
                                        />
                                    </Box>
                                )}
                                {!hasCoords && (
                                    <Box flexShrink="1">
                                        <img
                                            src="/images/explore_map.png"
                                            alt="Explore Nearby"
                                            style={{ objectFit: 'fill' }}
                                        />
                                    </Box>
                                )}
                            </Box> */}
                            {oddPhotos.map((photo)=> (
                                <StyledPhotoWrapper
                                    onClick={()=> {
                                        setSelectedPhotoDetailIndex(photos.indexOf(photo));
                                        analytics.eventTrack('robin_gallery_photo_click', {
                                            card_id: listingExplorerStore.card.id,
                                            source: 'photo',
                                        });
                                        setOpenSelectedPhotoDetail(true);
                                    }}
                                    ref={photos.indexOf(photo) === selectedPhotoIndex ? myRef : undefined}
                                >
                                    <StyledImageContainer>
                                        <img
                                            src={photo}
                                            alt={photo}
                                            style={{
                                                width: '530px',
                                                height: '354px',
                                                objectFit: 'cover',
                                                objectPosition: 'bottom'
                                            }}
                                        />
                                        {listing_details && (
                                            <PinspirationOverlay
                                                src={photo}
                                                listing={listing_details}
                                                listpackId={null}
                                                {...PinspirationOverlayProps}
                                            />
                                        )}
                                    </StyledImageContainer>
                                </StyledPhotoWrapper>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
            {isXs && (
                <Box width="100%">
                    {photos.map((photo)=> (
                        <StyledPhotoWrapper
                            key={`photo_detail_modal_photo_wrapper_${photo}`}
                            ref={photos.indexOf(photo) === selectedPhotoIndex ? myRef : undefined}
                            onClick={()=> {
                                setSelectedPhotoDetailIndex(photos.indexOf(photo));
                                analytics.eventTrack('robin_gallery_photo_click', {
                                    card_id: listingExplorerStore.card.id,
                                    source: 'photo',
                                });
                                setOpenSelectedPhotoDetail(true);
                            }}
                        >
                            <img width="100%" height={240} src={photo} alt={photo} style={{ objectFit: 'cover' }} />
                            {listing_details && (
                                <PinspirationOverlay
                                    src={photo}
                                    listing={listing_details}
                                    listpackId={null}
                                    {...PinspirationOverlayProps}
                                />
                            )}
                        </StyledPhotoWrapper>
                    ))}
                </Box>
            )}
        </Box>
    );
});
