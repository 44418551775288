import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import Logger from 'js-logger';
import {
    Button,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { PushPinIcon } from '../../LRIcons';
import PinnedByHeadshots from '../PinnedByHeadshots';
import { useActiveBoard, useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
import { observer } from 'mobx-react-lite';
import { Can } from '../../Ability';
import { useOnboarding } from 'components/Onboarding';
import Lockr from 'lockr';
import { SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION, SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_PINSPIRATION_PIN_UNPIN } from '../../../constants';

const logger = Logger.get('PinspirationOverlay');
const SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE = SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_PINSPIRATION_PIN_UNPIN;

const StyledOverlayContainer = styled.div`
    display: block;
    min-width: 32px;
    height: 28px;
    position: absolute;
    top: 16px;
    right: 16px;
    text-align: right;
`;

const StyledPinButton = styled(IconButton)`
    padding: 6px;
    font-size: 1rem;
    margin-left: 8px;

    &.pin {
        background-color: rgba(255, 255, 255, 0.8);
        &:hover {
            background-color: rgba(255, 255, 255, 1);
        }
    }

    &.unpin {
        background-color: rgba(0, 0, 0, 0.8);
        &:hover {
            background-color: rgba(0, 0, 0, 1);
        }
    }
`;

const StyledHudRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const snackbarMessages = {
    pinned: 'Saved to your Idea Board!',
    unpinned: 'Removed from your Idea Board',
    error: 'Sorry, something went wrong! Please try again.',
};

function PinButton({ handleClick, pinned = false }) {
    const onButtonClick = (event)=> {
        if(event) event.stopPropagation();
        handleClick(!pinned);
    };
    return (
        <Tooltip title={`${ pinned ? 'Unpin' : 'Pin' }`}>
            <StyledPinButton
                className={`${pinned ? 'unpin': 'pin'} tour-target-pin-listing-image`}
                size="small"
                onClick={onButtonClick}
            >
                <PushPinIcon fillColor={`${pinned ? 'white' : 'black'}`} fontSize="inherit"/>
            </StyledPinButton>
        </Tooltip>
    );
}

function SnackbarAction({ history }) {
    const onButtonClick = (event)=> {
        history.push('/boards/idea-board');
    };
    return (
        <Button onClick={onButtonClick}
            size="small"
            color="secondary"
        >
            View
        </Button>
    );
}
function PinspirationOverlay({ listing={}, listpackId=null, src=null, readOnly=false, canIPassThrough = false }) {
    const { id: listingId, address } = listing;
    const { PinspirationStore, boardsStore, GlobalSnackbarStore, UserStore, LoginSignupModalStore } = useStore();
    const { user, isLoggedIn, hasAttemptedToFetchUser } = UserStore;
    const activeBoard = useActiveBoard();
    const { cardId = null } = useParams();
    const history = useHistory();
    const { id: boardId = null } = boardsStore.activeBoard || {};
    const collaborationId = PinspirationStore.getBoardCollaborationId({boardId, src});
    const isPinnedByUserAlready = PinspirationStore.pinnedByCurrentUser[collaborationId] || false;
    const pinnedByCollaborators = PinspirationStore.pinnedByCollaborators[src] || [];
    const userId = PinspirationStore.currentUserId;
    const [pinnedByUser, setPinnedByUser] = useState(isPinnedByUserAlready);
    const onboarding = useOnboarding();
    const trackingData = useMemo(()=> {
        return {
            board_id: boardId,
            listing_id: listingId,
            image_url: src,
            source: 'rpp',
            role: UserStore.usersBoardRole,
        };
    }, [userId, boardId, src, listingId]);
    const [handlePinClickLoginCallback, setHandlePinClickLoginCallback] = useState();
    const [canFetchCollaborations, setCanFetchCollaborations] = useState(true);
    const cancelOnSuccessEventListenerRef = useRef();
    const cancelOnBeforeOAuthRedirectRef = useRef();
    const oauthRedirectSuccessAction = Lockr.get(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION);

    // UseEffects

    /**
     * Clears out the LoginSignupModalStore event listeners
     */
    useEffect(()=> {
        if(LoginSignupModalStore.isOpen) return;

        if(cancelOnSuccessEventListenerRef.current) {
            cancelOnSuccessEventListenerRef.current();
        }

        if(cancelOnBeforeOAuthRedirectRef.current) {
            cancelOnBeforeOAuthRedirectRef.current();
        }
    }, [LoginSignupModalStore.isOpen]);


    /**
     * Handles handlePinClickLoginCallback once the board data has loaded. this
     * is called when a user has to login.
     */
    useEffect(()=> {
        if(handlePinClickLoginCallback && activeBoard?.loadedBoardData) {
            if(!isPinnedByUserAlready) {
                handlePinClick.apply(null, handlePinClickLoginCallback);
            } else {
                showSnackbar('pinned');
            }

            setHandlePinClickLoginCallback(null);
        }
    }, [activeBoard?.loadedBoardData, handlePinClickLoginCallback, isPinnedByUserAlready]);

    /**
     * Handles the pinnedByUser property
     */
    useEffect(()=> {
        if(isPinnedByUserAlready !== pinnedByUser) {
            setPinnedByUser(isPinnedByUserAlready);
        }
    }, [isPinnedByUserAlready]);

    /**
     * Fetches the collaborations by listing id
     */
    useEffect(()=> {
        if(activeBoard?.id && canFetchCollaborations) {
            setCanFetchCollaborations(false);
            PinspirationStore.getCollaborationsByListingId(activeBoard.id, listingId);
        }
    }, [activeBoard?.id, canFetchCollaborations]);

    /**
     * Handles when the login / signup uses a redirect OAuth system. this
     * manages running the desired action after a successful login
     */
    useEffect(()=> {
        if(!oauthRedirectSuccessAction || !hasAttemptedToFetchUser || !isLoggedIn) return;

        const {
            type,
            data,
        } = oauthRedirectSuccessAction;

        if(type !== SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE) return;

        if(data.listingId === listingId && data.src === src) {
            pinClickLoginCallback(data.value);
            Lockr.rm(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION);
        }
    }, [hasAttemptedToFetchUser, isLoggedIn, listingId, oauthRedirectSuccessAction, src]);

    // Functions

    function showSnackbar(messageKey) {
        const message = snackbarMessages[messageKey];
        PinspirationStore.setBoardUserFilter(user?.id);

        const actionOptions = {
            action: (<SnackbarAction history={history} />)
        };
        const snackOptions = (onboarding?.isActive && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding )) || readOnly ? {} : actionOptions;
        const options = {
            autoHideDuration: 2000,
            ...snackOptions
        };

        GlobalSnackbarStore.show(message, options);
    }

    function handlePinClickFail() {
        // This handler will be invoked if the back end call
        // failed, so we need to revert to the original state
        setPinnedByUser(pinnedByUser);
        showSnackbar('error');
    }

    async function handlePinClick(val) {
        if(!UserStore.isLoggedIn) {
            LoginSignupModalStore.openSignupModal('rpp_pin_photo_icon');
            cancelOnBeforeOAuthRedirectRef.current = LoginSignupModalStore.onBeforeOAuthRedirect(()=> {
                Lockr.set(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION, {
                    type: SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE,
                    data: {
                        value: val,
                        listingId,
                        src,
                    },
                });
            });
            cancelOnSuccessEventListenerRef.current = LoginSignupModalStore.onSuccess(()=> pinClickLoginCallback(val));
            return;
        }

        // Optimistically update state for a snappier UX
        // If the back end fails, we will revert
        setPinnedByUser(val);

        // Handle the actual update - fail gracefully
        try {
            const actionType = val ? 'pin' : 'unpin';
            await PinspirationStore[`${actionType}Item`]({
                src,
                cardId,
                listingId,
                listpackId,
                boardId,
                address,
            });

            // Handle user feedback snackbar if pinned
            const eventType = `${actionType}ned`;
            if(val) showSnackbar(eventType);
            analytics.eventTrack(`pinspiration_image_${eventType}`, trackingData);

        } catch (err) {
            logger.error(`PinspirationOverlay -> pinItem: ${val}`, err);
            handlePinClickFail();
        }
    }

    async function pinClickLoginCallback(...args) {
        setHandlePinClickLoginCallback(args);
    }

    return (
        <StyledOverlayContainer>
            <StyledHudRow>
                {(pinnedByCollaborators.length > 0) &&
                    <PinnedByHeadshots users={pinnedByCollaborators} />
                }
                <Can I="edit" a="Pinspiration" passThrough={canIPassThrough}>
                    <PinButton
                        handleClick={handlePinClick}
                        pinned={pinnedByUser}
                    />
                </Can>
            </StyledHudRow>
        </StyledOverlayContainer>
    );
}

PinspirationOverlay.propTypes = {
    src: PropTypes.string.isRequired,
};

export default observer(PinspirationOverlay);
