import React, { useState } from 'react';
import { Box, RadioGroup, CircularProgress } from '@material-ui/core';
import { LRRadio } from '../../../../LRRadio';
import LRButton from '../../../../LRButton';


const howSoonOptions = [
    { value: 'ASAP', label: 'ASAP' },
    { value: 'within 3 months', label: '1-3 months' },
    { value: '3-6 months', label: '3-6 months' },
    { value: '6-12 months', label: '6-12 months' },
    { value: '12+ months', label: '12+ months' },
    { value:'i_already_bought_a_home_using_robin', label: 'I already bought a home using Robin'},
    { value: 'im_not_sure', label: "I'm not sure" },
];

export function TimeframeSettings({ onSubmit, defaultOption = 'im_not_sure', ...props }){
    const [option, setOption] = useState(defaultOption);
    const [submitting, setSubmitting] = useState(false);

    const submit = async (e)=> {
        e.preventDefault();
        setSubmitting(true);
        await onSubmit(option);
        setSubmitting(false);
        return false;
    };
    return (
        <>
            <Box textAlign="left" whiteSpace="normal" fontSize={20} fontWeight="500">
                Just checking in on your timeframe!
            </Box>
            <Box mt="18px">
                <Box textAlign="left" whiteSpace="normal" component="p" fontWeight="450">
                    How soon do you want to buy a home? This helps improve your Robin experience.
                </Box>
            </Box>
            <Box mt={3} whiteSpace="normal">
                <form onSubmit={submit}>
                    <RadioGroup name="option" value={option} onChange={(e)=> setOption(e.target.value)} >
                        {howSoonOptions.map((option)=> {
                            return (
                                <Box key={option.value} mt="12px" textAlign="left">
                                    <LRRadio {...option} />
                                </Box>
                            );
                        })}
                    </RadioGroup>
                    <Box mt="27px" textAlign="right">
                        <LRButton type="submit" variant="contained" color="primary" disabled={submitting}>
                            {!submitting && 'Submit'}
                            {submitting && (<CircularProgress size={24}/>)}
                        </LRButton>
                    </Box>
                </form>
            </Box>
        </>
    );
}