import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { MoreHoriz, KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { COLOR_GREY_MEDIUM } from '../../../../constants';
import { useStore } from '../../../../mobx-store';
import { listingImage } from '../../../../services/listing-helpers';
import { useMenu } from '../../../../hooks';
import ListingImage from '../../../ListingImage';
import { useHistory, useRouteMatch } from 'react-router-dom';
import analytics from '../../../../services/analytics';
import _ from 'lodash';
import { useCompareNowStoreContext } from '../../store';
import { OverlayMoveMenu } from '../../../../scenes/Board/Card';
import splitAddress from '../../../../services/split-address';
import { observer } from 'mobx-react-lite';
import { SOLD_STATUSES } from '../../../../constants';
import { StaticGoogleMaps } from 'components/StaticGoogleMaps';
import OverlayImageActions from './OverlayImageActions';

const ListingDisplay = observer(function ListingDisplay({ listing, showLock, onLockClick }) {
    const store = useCompareNowStoreContext();
    const match = useRouteMatch();
    const [menuProps, openMenu, closeMenu] = useMenu();
    const { LanesStore, UserStore, boardsStore } = useStore();
    const history = useHistory();
    const { activeBoard } = boardsStore;
    const boardPermissions = activeBoard?.userRoleOnBoard;
    const [addressLine1, addressLine2] = splitAddress(listing.listing_details.address);
    const canOpen = useMemo(()=> _.defaultTo(listing.canOpen, true), [listing.canOpen]);
    const cardActions = useMemo(()=> _.defaultTo(listing.cardActions, true), [listing.cardActions]);

    const isSold = SOLD_STATUSES.includes(
        listing?.listing_details?.listing_status?.toLowerCase()
    ) ?? false;

    const moveToLane = async (e, lane)=> {
        await activeBoard.moveCard(listing.id, lane.id, 0);
    };

    function onTileClick() {
        if(!canOpen) return;
        analytics.eventTrack('robin_compare_listing_image_click', {
            role: boardPermissions?.label,
            board_card_id: activeBoard?.id,
        });
        const encodedId = listing.listing_details?.encodedId;
        if(listing.id) {
            history.push(`${match.url}/cards/${listing.id}/${_.kebabCase(listing.listing_details.address)}?source=compare`);
        } else if(encodedId) {
            history.push(`${match.url}/listings/${encodedId}`);
        }
    };

    const isLocked = store.isCardLocked(listing);

    const image = listingImage(listing);
    return (
        <Box width="100%" position="relative" textAlign="center">
            <Box onClick={onTileClick}>
                {image && <ListingImage width="100%" height="128px" image={image}>
                    {<OverlayImageActions
                        listing={listing}
                        isLocked={isLocked}
                        showLock={showLock}
                        onLockClick={onLockClick}
                    />}
                </ListingImage>}
                {!image && <div style={{height: 128}} onClick={onTileClick}>
                    <StaticGoogleMaps
                        latitude={listing.listing_details?.lat || listing.listing_details?.latitude}
                        longitude={listing.listing_details?.lon || listing.listing_details?.longitude}
                        height={128}
                    >
                        {<OverlayImageActions
                            listing={listing}
                            isLocked={isLocked}
                            showLock={showLock}
                            onLockClick={onLockClick}
                        />}
                    </StaticGoogleMaps>
                </div>}
                {cardActions && (
                    <Box onClick={openMenu} position="absolute" color="white" bottom="0px" right="10px">
                        <MoreHoriz filter="drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5))"/>
                    </Box>
                )}
            </Box>
            <Box>
                {menuProps.open && (
                    <OverlayMoveMenu
                        menuOptions={LanesStore?.lanes.filter((lane)=> lane.id !== listing.laneId)}
                        handleMove={moveToLane}
                        menuProps={menuProps}
                        handleCloseMenu={closeMenu}
                        cardAddress={addressLine1}
                        cardName={listing.name}
                        archived={listing.archived}
                    />
                )}
            </Box>
        </Box>
    );
});

const Header = observer(function Header({ firstIndex, secondIndex, setFirstIndex, setSecondIndex, onLockClick, rotateListingsRight, rotateListingsLeft }) {
    const store = useCompareNowStoreContext();
    const listing1 = store.boardCardsWindow[firstIndex];
    const listing2 = store.boardCardsWindow[secondIndex];

    return (
        <Box style={{ boxShadow: `0px 7px 5px -2px ${COLOR_GREY_MEDIUM}` }}>
            <Box display="flex" position="relative">
                <Box zIndex={1} position="absolute" color="#FFF" pr="30px" flex="1" textAlign="right" alignSelf="center">
                    <KeyboardArrowLeft onClick={rotateListingsLeft} />
                </Box>
                <Box display="flex" width="50%" position="relative">
                    <ListingDisplay
                        listing={listing1}
                        onListingSelect={(_, index)=> setFirstIndex(index)}
                        listings={store.boardCardsWindow}
                        showLock={true}
                        onLockClick={onLockClick}
                    >
                    </ListingDisplay>
                </Box>
                <Box borderLeft={1} borderColor="#E7E7E7" />
                {listing2 && <>
                    <Box width="50%">
                        <ListingDisplay
                            listing={listing2}
                            onListingSelect={(_, index)=> setSecondIndex(index)}
                            listings={store.boardCardsWindow}
                            showLock={true}
                            onLockClick={onLockClick}
                        ></ListingDisplay>
                    </Box>
                    <Box zIndex={1} position="absolute" color="#FFF" right={0} pl="30px" flex="1" alignSelf="center">
                        <KeyboardArrowRight onClick={rotateListingsRight} />
                    </Box>
                </>}
            </Box>
            <Box display="flex" textAlign="center" fontSize={16} fontWeight="bold" height={44}>
                <Box width="50%" position="relative" display="flex" alignItems="center">
                    <Box px={1} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                        {listing1.name || listing1.listing_details.address}
                    </Box>
                </Box>
                <Box borderLeft={1} borderColor="#E7E7E7" />
                <Box>

                </Box>
                {listing2 && <Box width="50%" position="relative" display="flex" alignItems="center">
                    <Box px={1} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                        {listing2.name || listing2.listing_details.address}
                    </Box>
                </Box>}
            </Box>
        </Box>
    );
});

Header.propTypes = {
    listings: PropTypes.array,
    removeListing: PropTypes.func,
    firstIndex: PropTypes.number,
    secondIndex: PropTypes.number,
    setFirstIndex: PropTypes.func,
    setSecondIndex: PropTypes.func
};

export default Header;
