import React from 'react';
import { Box, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LRAvatar from '../../../LRAvatar';
import { COLOR_GREY_DARK } from '../../../../constants';
import styled from 'styled-components';
import formatPhoneNumber from '../../../../services/format-phone-number';
import LRButton from '../../../LRButton';
import _ from 'lodash';

const StyledPhoneIcon = styled(PhoneIcon)`
    width: 16px;
    height: 16px;
`;

const StyledEmailIcon = styled(EmailIcon)`
    width: 16px;
    height: 16px;
`;

const buildAddress = ({ street, city, state })=> {
    return (
        <>
            <Box lineHeight={1}>{street}</Box>
            <Box lineHeight={1}>{`${city}, ${state}`}</Box>
        </>
    );
};

const UserInfo = ({
    user,
    showContactAgentButton = false,
    onContactAgentClick = ()=> {},
})=> {
    const isAgent = user.type === 'agent';
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container item xs={12}>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <Box display="flex" alignItems="center">
                                <LRAvatar user={user} size="medium" />
                                <Box ml={2}>
                                    <Box fontSize={24} fontWeight={500}>
                                        {user.name}
                                    </Box>
                                    <Box fontSize={14} color={COLOR_GREY_DARK}>
                                        {isAgent ? 'License ' : 'NMLS '}
                                        #: {user.normalized_license_number}
                                    </Box>
                                </Box>
                            </Box>

                            {showContactAgentButton && (
                                <Box>
                                    <LRButton variant="contained" color="primary" onClick={_.partial(onContactAgentClick, 'contact_block')}>
                                        Contact agent
                                    </LRButton>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item></Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box fontSize="body1.fontSize" lineHeight={1}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <StyledPhoneIcon />
                            <Box fontSize={14} ml={2}>
                                {formatPhoneNumber(user.phone)}
                            </Box>
                        </Box>

                        <Box mt="2px" display="flex" alignItems="center">
                            <StyledEmailIcon />
                            <Box fontSize={14} ml={2}>
                                {user.email}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box fontSize="body1.fontSize" mt={[2, 0]}>
                        <Box width="24px" mb={13 / 8} borderTop={2} borderColor="#DDDDDD"></Box>
                        <Box component="p" fontSize={14}>
                            {user.company}

                            {user.vendor && user.vendor.meta && (
                                <>
                                    {user.company && ', '} {user.vendor.meta.license_type} #:{' '}
                                    {user.vendor.meta.license_number}
                                </>
                            )}
                        </Box>

                        {user.type === 'agent' && user.licensed_address && (
                            <>
                                <Box fontSize={12} color="#A1A1A1">
                                    {user.licensed_address.street}, {user.licensed_address.city},{' '}
                                    {user.licensed_address.state} {user.licensed_address.zip}
                                </Box>
                            </>
                        )}

                        {user.type !== 'agent' && user.vendor && user.vendor.meta && user.vendor.meta.street && (
                            <>
                                <Box fontSize={12} color="#A1A1A1">
                                    {user.vendor.meta.street}, {user.vendor.meta.city}, {user.vendor.meta.state}{' '}
                                    {user.vendor.meta.zip}
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default UserInfo;
