export class GeocoderService {
    constructor() {
        this.geocoderService = new window.google.maps.Geocoder();
    }

    geocode(address) {
        return new Promise((resolve)=> {
            this.geocoderService.geocode({ address }, (results)=> {
                resolve(results);
            });
        });
    }

    reverseGeocode(lat, lon) {
        return new Promise((resolve, reject)=> {
            const latLng = new window.google.maps.LatLng(lat, lon);
            this.geocoderService.geocode({ latLng }, (results, status)=> {
                if(status === window.google.maps.GeocoderStatus.OK) {
                    resolve(results);
                } else {
                    reject(status);
                }
            });
        });
    }
}
