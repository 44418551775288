import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';

const PhantomBox = styled(Box)`
    display: block;
    width: '100%';
`;

const FixedBox = styled(Box)`
    position: fixed;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
`;

function LRStickyFooter(props) {
    const { children, height=72, ...restProps } = props;

    return (
        <Box>
            <PhantomBox height={height}
                {...restProps}
            />
            <FixedBox
                bgcolor="white"
                height={height}
                {...restProps}
            >
                {children}
            </FixedBox>
        </Box>
    );
}

LRStickyFooter.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default LRStickyFooter;
