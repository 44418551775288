import React, { useState, useCallback, useRef } from 'react';
import { Box, Paper, List, ListItem, OutlinedInput, ClickAwayListener, Popper, InputAdornment, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import CircularProgress from '@material-ui/core/CircularProgress';
import Error from '@material-ui/icons/Error';
import { useDimensions } from '../../hooks';
import { composeRefs } from '../../services/compose-refs';

const StyledSearchOutlinedInput = styled(OutlinedInput)`
    width: 100%;
    padding-right: 20px;
`;

const StyledSuggestionsList = styled(List)`
    padding: 16px;
    width: 100%;
`;

const StyledSuggestionsListItem = styled(ListItem)`
    padding: 4px 0;

    &:hover {
        background-color: ${(props)=> (props.menuItemHoverColor ? `${props.menuItemHoverColor}` : 'inherit')}
    }
`;

const StyledSuggestionsPaper = styled(Paper)`
    z-index: 1000;

    overflow: auto;
    max-height: ${(props)=> (props.maxHeight ? `${props.maxHeight}px` : 'none')}
`;

const IndicatorIconContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
`;

export const AutocompleteInput = ({
    value,
    suggestions,
    isLoading,
    renderSuggestion,
    onSuggestionSelect,
    onChange,
    onClickOutside,
    renderInput,
    floatingSuggestions,
    rootContainerProps = {},
    error,
    clearSearch,
    menuItemHoverColor,
    setAnchorElOnFocus = false,
    popperProps = {},
    onFocus,
    onBlur,
    ...props
})=> {
    const suggestionsRef = useRef();
    const inputContainerRef1 = useRef();
    const [inputContainerRef2, dimensions] = useDimensions();
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const popperRef = useRef();

    const inputContainerRef = composeRefs(inputContainerRef1, inputContainerRef2);

    const handleOnChange = useCallback(
        (searchTerm)=> {
            if(onChange) {
                onChange(searchTerm);
                setShowSuggestions(true);
            }
        },
        [onChange]
    );

    const handleOnSelect = useCallback(
        (suggestion)=> {
            onSuggestionSelect(suggestion);
            setShowSuggestions(false);
        },
        [onSuggestionSelect]
    );

    const handleOnFocus = useCallback((e)=> {
        if(!anchorEl && setAnchorElOnFocus){
            setAnchorEl(e.currentTarget);
        }

        setShowSuggestions(true);
        if(onFocus) {
            onFocus(e);
        }
    }, []);

    const handleOnBlur = useCallback((e)=> {
        if(onBlur) {
            onBlur(e);
        }
    }, [onBlur]);

    const handleOnClickOutside = useCallback((e)=> {
        if(popperRef.current && popperRef.current.contains(e.target)) {
            return;
        }
        setShowSuggestions(false);
        if(onClickOutside) {
            onClickOutside();
        }
    }, [onClickOutside]);

    const shouldShowSuggestions = showSuggestions && suggestions && suggestions.length > 0;
    
    return (
        <ClickAwayListener onClickAway={handleOnClickOutside} >
            <Box position="relative" {...rootContainerProps}>
                <Box ref={inputContainerRef} onClick={(e)=> setAnchorEl(e.currentTarget) }>
                    {
                        renderInput ? ( renderInput({
                            onFocus: handleOnFocus ,
                            onBlur: handleOnBlur,
                            value:value,
                            onChange: (e)=> handleOnChange(e.target.value),
                            isLoading: isLoading,
                            error: error,
                            clearSearch,
                        }) )
                            : <StyledSearchOutlinedInput
                                {...props}
                                onFocus={handleOnFocus}
                                onBlur={handleOnBlur}
                                value={value}
                                margin="none"
                                variant="outlined"
                                onChange={(e)=> handleOnChange(e.target.value)}
                                inputProps={{ 'data-testid': 'input' }}
                                endAdornment={props?.endAdornment}
                            />
                    }
                </Box>
                {isLoading && (
                    <IndicatorIconContainer data-testid="loading">
                        <CircularProgress size={20} />
                    </IndicatorIconContainer>
                )}
                {props.error && (
                    <IndicatorIconContainer data-testid="error">
                        <Error size={20} color="error" />
                    </IndicatorIconContainer>
                )}
                <Popper
                    ref={popperRef}
                    open={shouldShowSuggestions}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    className="popper-autocomplete"
                    style={{ width: dimensions.width }}
                    {...popperProps}
                >
                    <Box position="relative" mt={floatingSuggestions ? 0.5 : 0}>
                        <StyledSuggestionsPaper
                            data-testid="suggestions"
                            ref={suggestionsRef}
                            style={{
                                maxHeight: 300,
                                overflow: 'auto',
                            }}
                        >
                            <StyledSuggestionsList component="nav">
                                {suggestions?.map((suggestion, index)=> (
                                    <StyledSuggestionsListItem
                                        key={index}
                                        button
                                        onClick={()=> {
                                            handleOnSelect(suggestion);
                                        }}
                                        data-testid="suggestion-item"
                                        menuItemHoverColor={menuItemHoverColor}
                                    >
                                        {renderSuggestion(suggestion)}
                                    </StyledSuggestionsListItem>
                                ))}
                            </StyledSuggestionsList>
                        </StyledSuggestionsPaper>
                    </Box>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

AutocompleteInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    suggestions: PropTypes.array,
    isLoading: PropTypes.bool,
    renderSuggestion: PropTypes.func,
    onSuggestionSelect: PropTypes.func,
    error: PropTypes.bool
};
