import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import Card from '../../../scenes/Board/Card';
import _ from 'lodash';
import { useOnboarding } from 'components/Onboarding';
import { isListingNew } from '.';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import ReactPlaceholder from 'react-placeholder/lib';
import { ListingCardPlaceholder } from '../ListingCardPlaceholder';
import { useStore } from 'mobx-store';

export function ListItem({
    cardOrListing,
    listpack,
    selectedListing,
    hoveredListing,
    currentLaneId,
    lastSeenDate,
    handleOnListingClick,
    renderCard,
}) {
    const onboarding = useOnboarding();
    const isBoardCard = cardOrListing.id;
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const { data: cardListing, isLoading } = useBoardCardListing(isBoardCard ? cardOrListing : null, {onError});
    const listing = useMemo(()=> {
        if(!isBoardCard) {
            return cardOrListing;
        }
        return cardListing ? { ...cardOrListing, listing_details: cardListing } : null;
    }, [isBoardCard, cardOrListing, cardListing]);
    const selected = selectedListing?.listing_details?.id === listing?.listing_details?.id;
    const hovered = hoveredListing?.listing_details?.id === listing?.listing_details?.id;

    function onError(err) {
        if(err?.response?.data?.errors[0]?.detail === 'Listing not found.') {
            delete activeBoard.boardCardsById[cardOrListing.id];
        }
    }

    return (
        <>
            {!isLoading && listing && (
                <Box
                    key={`list-item-${isBoardCard ? listing.id : listing.listing_details.id}`}
                    width={350}
                    position="relative"
                    m={1}
                    style={{ cursor: 'pointer' }}
                    onClick={()=> {
                        handleOnListingClick(listing);
                    }}
                    display="flex"
                    flexDirection={{ xs: 'row', md: 'column' }}
                    className="tour-target-mobile-lane-card-link"
                >
                    <Box
                        className="tour-target-listpack-listing-card tour-target-lane-card-link"
                        width={1}
                        borderRadius={6}
                        id={listing?.listing_details?.id}
                        display="flex"
                        flexDirection="column"
                        height={1}
                        border={
                            selectedListing?.listing_details?.id === listing?.listing_details?.id ? '2px solid #FF6259' : 'none'
                        }
                    >
                        {
                            !renderCard ? (
                                <Card
                                    variant={listing.laneId === '3' ? 'makeAnOffer' : 'expanded'}
                                    listing={listing}
                                    boardCard={listing}
                                    isNew={isListingNew(listpack, listing, lastSeenDate)}
                                    laneIdBadge={currentLaneId ? null : listing.listing_details.linkedBoardCard?.laneId}
                                    listpack={!!listpack}
                                    selected={ selected || hovered }
                                    showUnspecifiedListingsWarning
                                />
                            ) : (
                                renderCard({
                                    listing: listing,
                                    boardCard: listing,
                                    isNew: isListingNew(listpack, listing, lastSeenDate),
                                    laneIdBadge: currentLaneId ? null : listing.listing_details.linkedBoardCard?.laneId,
                                    selected: Boolean(selected || hovered),
                                })
                            )
                        }

                    </Box>
                </Box>
            )}
            {isLoading && (
                <Box width={350} m={1}>
                    <ReactPlaceholder ready={false} customPlaceholder={<ListingCardPlaceholder />} />
                </Box>
            )}
        </>
    );
}
