import React from 'react';
import { IconButton, withTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styled, { css } from 'styled-components';
import LRButton from 'components/LRButton';

const ForwardedCloseButton = React.forwardRef((props, ref)=> (
    <IconButton {...props} ref={ref}>
        <Close />
    </IconButton>
));

export const CloseButton = styled(ForwardedCloseButton)`
    // position: fixed;
    // top: 13px;
    // right: 13px;
`;


export const AddAHomeButton = withTheme(styled(LRButton)`
    height: 48px;
    color: ${({ theme })=> (theme.palette.lr_colors.grade)};
    text-transform: none;
    border-color: #bbb;
    padding-left: ${({ theme })=> theme.spacing(2)}px;
    padding-right: ${({ theme })=> theme.spacing(2)}px;

    ${({ variant })=> variant === 'contained' && (
        css`
            background-color: ${({ theme })=> (theme.palette.lr_colors.grade)};
            color: #fff;

            &:hover {
                background-color: ${({ theme })=> (theme.palette.lr_colors.grey_darkest)};
            }
        `
    )}

    &.Mui-disabled {
        color: rgba(0, 0, 0, 0.12);
        border-color: #bbb;
    }
`);

export const TitleBar = styled.div`
    width: 56px;
    height: 4px;
    background: #1A1A1A;
    margin: 20px 0 40px;
`;
export const FormItemSpacer = withTheme(styled.div`
    height: 47px;

    ${({ theme })=> theme.breakpoints.down('xs')} {
        height: 36px;
    }
`);