import React, { useEffect, useState } from 'react';
import { Box, Hidden } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
// import { withTheme } from '@material-ui/core/styles';
// import styled from 'styled-components';
import { useParams, Redirect } from 'react-router-dom';
import _ from 'lodash';
// page components
import { Navbar } from './Navbar';
import Map from './Map';
import Sidebar from './Sidebar';
// import { LeadContactForm } from '../LeadContactForm';
import LRButton from '../../LRButton';
import LRLink from '../../LRButton';
import { LeadFormModal } from './Modals/LeadForm';
import LegalDisclaimer from './Modals/LegalDisclaimer';
import { useResponsiveBreakpoint } from '../../../hooks';

function RateFarmModal() {
    const { RateFarmStore } = useStore();

    const params = useParams();
    const addressSlug = decodeURIComponent(params.addressSlug);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showLegalDisclaimer, setShowLegalDisclaimer] = useState(false);
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;

    useEffect(()=> {
        if(!addressSlug) return;
        RateFarmStore.fetchRates(addressSlug);
    }, [RateFarmStore, addressSlug]);

    useEffect(()=> {
        if(!RateFarmStore.user) return;

        analytics.eventTrack('rate_farm_consumer_page_viewed', {
            distinct_id: RateFarmStore.user.id,
            Source: 'map_page',
            404: false,
        });
    }, [RateFarmStore.user]);

    function handleOnModalClose() {
        return <Redirect to="/rate-farm" />;
    }

    return (
        <Box>
            <Box display="flex" flexDirection="column" height="100vh">
                {/* Header */}
                <Box zIndex={1} boxShadow={'0px 3px 12px -2px rgba(0, 0, 0, 0.2)'}>
                    <Navbar isMobile={isMobile} onModalClose={handleOnModalClose}></Navbar>
                </Box>

                {/* Main content */}

                <Box width={1} display="flex" flex="1">
                    {/* Map component */}
                    <Box display="flex" flexDirection="column" flex="1">
                        <Map />
                        <Hidden smDown>
                            <Box display="flex" alignItems="center" padding="16px" p={2} bgcolor="#F9F9F9">
                                <Box fontSize="12px" color="#A1A1A1">
                                    Results are based on public information where available or otherwise reflect current
                                    interest rates from Freddie Mac. By using this tool, you acknowledge the following:
                                    ListReports strives to keep its information accurate and up to date but cannot
                                    guarantee the accuracy or completeness of this information. Results from this tool
                                    are designed for comparative and illustrative purposes only. ListReports is not a
                                    lender; this is not an advertisement to extend consumer credit. Qualification for
                                    loan programs will depend largely on your credit score, income information, cash
                                    reserves, and other factors. Equal Housing Opportunity.
                                </Box>
                                <Box
                                    component="span"
                                    padding={isMobile ? '16px 0 0' : 0}
                                    mx={isMobile ? 'auto' : '15px'}
                                    style={isMobile ? {} : { float: 'right' }}
                                >
                                    <img src="/images/equal-housing-logo.png" alt="equal-housing-logo" />
                                </Box>
                            </Box>
                        </Hidden>
                    </Box>

                    {/* Lead form component */}
                    <Hidden smDown>
                        <Box width={360} px={4} pt={3} maxHeight={'calc(100vh - 90px)'} overflow="auto">
                            <Sidebar
                                user={RateFarmStore.user}
                                loadingUser={RateFarmStore.loadingUser}
                                loadedUser={RateFarmStore.loadedUser}
                            />
                        </Box>
                    </Hidden>
                </Box>

                <Hidden mdUp>
                    <Box display="flex" alignItems="center" justifyContent="space-between" bgcolor="#FFF" py={1} px={2}>
                        <Box height={14}>
                            <img src="/images/logo.png" height="14" />
                        </Box>
                        <Box>
                            <LRLink
                                onClick={()=> {
                                    setShowLegalDisclaimer(true);
                                }}
                                style={{ textDecoration: 'underline' }}
                            >
                                Here’s how we get, store, and share data.
                            </LRLink>
                        </Box>
                    </Box>
                    <Box py={1.5} px={2} bgcolor="#F9F9F9" borderTop="1px solid #DDDDDD">
                        <LRButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={()=> {
                                setShowContactModal(true);
                            }}
                        >
                            Contact me to learn more
                        </LRButton>
                    </Box>
                </Hidden>
            </Box>
            <LeadFormModal
                user={{}}
                open={showContactModal}
                toggleRateModal={()=> {
                    setShowContactModal(false);
                }}
                headerTitle='Contact me to learn more'
                agentText='Curious about whether you can score a lower mortgage rate? Shoot me a message using the
                form below—I’m here to help!'
                source='map_page'
            />
            <LegalDisclaimer
                open={showLegalDisclaimer}
                toggleRateModal={()=> {
                    setShowLegalDisclaimer(false);
                }}
            />
        </Box>
    );
}

export default observer(RateFarmModal);
