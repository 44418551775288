import React, { useEffect } from 'react';

import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import { useStore } from '../../mobx-store';
import RateFarmHome from './RateFarmHome';
import RateFarmModal from './RateFarmModal';
import { observer } from 'mobx-react-lite';
import { Box, CircularProgress } from '@material-ui/core';
import analytics from '../../services/analytics';

function RateFarm() {
    const params = useParams();

    const { RateFarmStore } = useStore();
    const userId = params.userId;

    useEffect(
        function() {
            RateFarmStore.getUser(userId);
        },
        [RateFarmStore, userId]
    );

    if(!RateFarmStore.loadedUser) {
        return (
            <Box display="flex" width={1} height="100vh" alignItems="center" justifyContent="center">
                <CircularProgress color="secondary" />
            </Box>
        );
    }

    if(RateFarmStore && RateFarmStore.loadedUser && !RateFarmStore.activeSubscription) {
        analytics.eventTrack('rate_farm_consumer_page_viewed', {
            distinct_id: RateFarmStore.user.id,
            Source: 'map_page',
            404: true,
        });

        return <Redirect to="/404" />;
    }

    return (
        <Switch>
            <Route exact path={'/rate-farm/:userId'} component={RateFarmHome} />
            <Route exact path={'/rate-farm/:userId/:addressSlug'} component={RateFarmModal} />
        </Switch>
    );
}

export default observer(RateFarm);
