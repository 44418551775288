import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import { useStore } from '../../mobx-store';
import ListingDetails from '../../components/ListingDetails';
import { benoitApi, portalApi } from '../../apis';
import { CenteredLoading } from '../../components/CenteredLoading';
import Logger from 'js-logger';
import analytics from '../../services/analytics';
const logger = Logger.get('PublicListingModal');

const PublicListingModal = ()=> {
    const params = useParams();
    const location = useLocation();
    const queryStrings = queryString.parse(location.search);

    const { listingsStore, PublicListing } = useStore();
    const [listingsDetails, setListingsDetails] = useState(null);
    const [buyerAgent, setBuyerAgent] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    let listingDetailsIdsParam = params.listingIds;
    let listingDetailsIdsRef = useRef(listingDetailsIdsParam.split(','));
    const [embeded] = useState(queryStrings.embeded && queryStrings.embeded !== 'false' ? true : false);
    const [requestSource] = useState(queryStrings.source ? queryStrings.source : 'consumer_view');
    const [buyerAgentId] = useState(queryStrings['agentId']);
    const [reportId] = useState(queryStrings['reportId']);
    const redirectToSPW = useCallback(async ()=> {
        if(reportId) {
            try {
                const {
                    links: {
                        property: spwLink = null
                    } = {}
                } = await portalApi.getReport(reportId);
                if(spwLink) {
                    analytics.eventTrack('rpp_to_spw_redirect', {
                        report_id: reportId,
                        source: requestSource,
                    });
                    window.location = spwLink;
                }
            } catch (err) {
                logger.debug('Error fetching report', reportId);
            }
        }
    }, [reportId, requestSource]);

    useEffect(()=> {
        const fetchListingDetails = async ()=> {
            const listingsDetails = await listingsStore.fetchListings(listingDetailsIdsRef.current);
            if(listingsDetails?.length) {
                setListingsDetails(listingsDetails);
                // fetch the buyer agent information
                const buyerAgent = await benoitApi.getAgent(buyerAgentId);
                if(buyerAgent) {
                    PublicListing.agent = buyerAgent;
                    setBuyerAgent(buyerAgent);
                }

                // done
                setIsLoaded(true);
            } else {
                // Fallback to SPW if reportId is valid but could not get listing details
                redirectToSPW();
            }
        };

        fetchListingDetails();
    }, [buyerAgentId, redirectToSPW, listingsStore]);

    if(!isLoaded) {
        return (
            <>
                <CenteredLoading height="100vh" />
            </>
        );
    }

    const wrappedListings = listingsDetails.map((listing_details)=> {
        return { listing_details };
    });

    return (
        <Box display="flex" justifyContent="center">
            <ListingDetails
                listings={wrappedListings}
                buyerAgent={buyerAgent}
                startIndex={0}
                listingsAreNotCards={true}
                embeded={embeded}
                requestSource={requestSource}
                fromRobin={false}
                reportId={reportId}
                headerProps={{
                    //showLogo: wrappedListings?.length === 1,
                    hideMainHeader: true,
                }}
            />
        </Box>
    );
};

export default observer(PublicListingModal);
