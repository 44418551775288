import Axios from 'axios';
import Logger from 'js-logger';

const THUMBOR_BASE = 'https://d1u2xbew1ldorh.cloudfront.net';
const thumborAxios = Axios.create({
    baseURL: THUMBOR_BASE,
});

export const thumborize = function (url, thumborActionPath='unsafe') {
    if(!(url && url.length)) {
        // invalid url string
        return url;
    }

    if(!(thumborActionPath && thumborActionPath.length)) {
        // invalid thumborActionPath string
        return url;
    }

    if(!(url.substr(0, 7) === 'http://' || url.substr(0, 8) === 'https://')) {
        // relative url - do NOT thumborize
        return url;
    }

    if(url && url.indexOf(THUMBOR_BASE.replace(/^https/i, '')) > -1){
        // thumborized url - do NOT re thumborize
        return url;
    }

    // Thumbor expects URL encoded image urls to fetch the resource correctly
    // We first decodeURI to make sure it's not encoded already
    // Then we do encodeURI so that if there are spaces in image file path those will get encoded
    // Then we do encodeURIComponent, so that the entire url string is encoded
    // This DOES mean that spaces will get encoded to %2520 (percentage sign from %20 gets encoded to %25)
    // But that is what thumbor expects

    url = encodeURIComponent( encodeURI( decodeURIComponent( String(url) ) ) );
    const urlComponents = [THUMBOR_BASE, thumborActionPath, url];
    return urlComponents.join('/');
};

export const dethumborize = function (url) {
    if(!(url && url.length)) {
        // invalid url string
        return url;
    }

    if(url && url.indexOf(THUMBOR_BASE.replace(/^https/i, '')) > -1){
        // possibly a thumborized url (could also be a user uploaded image)
        // we will try to extract the source url and validate if it begins with http

        const sourceUrl = url.split('/')
            .pop();

        if((/^http/i).test(sourceUrl)) {
            // make sure we return a fully decoded url
            return decodeURI(decodeURIComponent(sourceUrl));
        }
    }

    // not a thumborized url, return as is
    return url;
};

export async function uploadImageToThumbor(imgFIle) {
    const headers = {
        'Content-Type': undefined,
    };
    const formData = new FormData();
    formData.append('media', imgFIle);

    const response = await thumborAxios.post('/image', formData, { headers: headers, });
    const uploadedUrl = [THUMBOR_BASE, response.headers.location].join('').replace(/\s/g, '%20');

    return uploadedUrl;
}
