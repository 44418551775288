import moment from 'moment';
import addCommas from '../../services/add-commas';
import _ from 'lodash';

export const sortOptions = [
    {
        sortField: 'ListingDate',
        direction: 'desc',
        snackbarLabel: 'days on market',
        text: 'Days on market (default)',
    },
    {
        sortField: 'YearBuilt',
        direction: 'desc',
        label: '',
        prefix: 'built in',
        valuefier: (listing)=> (listing.year || '').toString(),
        snackbarLabel: 'year built',
        text: 'Year built',
    },
    {
        sortField: 'ListPrice',
        direction: 'desc',
        snackbarLabel: 'price (high to low)',
        text: 'Price (high to low)',
    },
    {
        sortField: 'ListPrice',
        direction: 'asc',
        snackbarLabel: 'price (low to high)',
        text: 'Price (low to high)',
    },
    {
        sortField: 'Bedrooms',
        direction: 'desc',
        label: 'bed',
        prefix: '',
        valuefier: (listing)=> (listing.beds || '').toString(),
        snackbarLabel: 'most bedrooms',
        text: 'Most bedrooms',
    },
    {
        sortField: 'Bathrooms',
        direction: 'desc',
        label: 'bath',
        prefix: '',
        valuefier: (listing)=> (listing.baths || '').toString(),
        snackbarLabel: 'most bathrooms',
        text: 'Most bathrooms',
    },
    {
        sortField: 'LivingArea',
        direction: 'desc',
        label: 'sqft',
        prefix: '',
        valuefier: (listing)=> (addCommas(listing.home_size) || '').toString(),
        snackbarLabel: 'square feet',
        text: 'Most square feet',
    },
    {
        sortField: 'LotSize',
        direction: 'desc',
        label: 'sqft lot',
        prefix: '',
        valuefier: (listing)=> (addCommas(listing.lot_size) || '').toString(),
        snackbarLabel: 'lot size',
        text: 'Biggest lot size',
    },
    {
        sortField: 'OpenHouseDate',
        direction: 'asc',
        prefix: 'Open House',
        label: '',
        valuefier: (listing)=> {
            if(!_.isArray(listing.open_houses) || !listing.open_houses.length) return;

            const openHouseDates = listing.open_houses;

            for(let i = 0; i < openHouseDates.length; i++) {
                const openHouseDateData = openHouseDates[i];
                const openHouseDate = moment(
                    openHouseDateData.date || openHouseDateData.date_iso || openHouseDateData.start_time_iso
                );

                if(openHouseDate.isValid()) return openHouseDate.format('MM-DD-YYYY');
            }

            return;
        },
        snackbarLabel: 'open house date',
        text: 'Open house',
    },
];

export function matchSortOptions(option1, option2) {
    return option1.sortField === option2.sortField && option1.direction === option2.direction;
}

export function serializeSortOption(sortOption) {
    return `${sortOption.sortField}-${sortOption.direction}`;
}

export function deserializeSortOption(serializedSortOption) {
    const [decodedSortField, decodedDirection] = serializedSortOption.split('-');
    return sortOptions.find(({ sortField, direction })=> {
        return decodedSortField === sortField && decodedDirection === direction;
    });
}

export const defaultSortQuery = 'ListingDate-desc';
