import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Grid, Divider, ClickAwayListener } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useStore } from '../../../../mobx-store';
import notificationConfig from '../../../../mobx-store/notifications/config';
import { useResponsiveBreakpoint } from '../../../../hooks';
import { NotificationsList } from '../../../NotificationsList';
import LRButton from '../../../LRButton';
import styled from 'styled-components';
import analytics from '../../../../services/analytics';

const DISPLAY_LIMIT = 20;
const StyledMenuContainer = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    width: 360px;
    z-index: 1301;

    > div {
        border: 1px solid #ddd;
        border-radius: 4px;
    }
`;

export const NotificationsMenu = withRouter(
    observer(({ history })=> {
        const isXs = useResponsiveBreakpoint('xs');
        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const { NotificationsStore, UserStore, boardsStore } = useStore();
        const { user } = UserStore;
        const { activeBoard } = boardsStore;
        const notifications = NotificationsStore.notifications;
        const hasMore = notifications?.length >= DISPLAY_LIMIT;

        const closeMenu = useCallback(()=> {
            setIsMenuOpen(false);
        }, []);

        const openMenu = useCallback(()=> {
            setIsMenuOpen(true);
        }, []);

        const handleOnViewAllClick = ()=> {
            closeMenu();
            history.push('/boards/notifications');
        };

        const handleOnNotificationsIconClick = useCallback(()=> {
            NotificationsStore.markAllSeen();
            if(isXs) {
                history.push('/boards/notifications');
            } else {
                openMenu();
            }
        }, [openMenu, isXs, history, NotificationsStore]);

        const handleNotificationClick = useCallback(
            (notification)=> {
                NotificationsStore.markAsRead(notification);
                const conf = notificationConfig[notification.type] || {};
                const toUrl = (notification?.content?.path)
                    ? notification.content.path
                    : conf.cta
                        ? conf.cta(notification)
                        : null;
                if(toUrl) {
                    const [ pathname, search ] = toUrl.split('?');
                    history.push({
                        pathname,
                        search,
                        state: { trackSource: 'robin_notification_center' }
                    });
                    closeMenu();
                }
                analytics.eventTrack('robin_notification_center_activity_click', {
                    event: (typeof conf.trackEventName === 'function')
                        ? conf.trackEventName(notification)
                        : (conf.trackEventName || notification.type),
                    parent_component: 'menu',
                    role: UserStore.usersBoardRole,
                    board_id: activeBoard?.id,
                });
            },
            [NotificationsStore, history, closeMenu, UserStore.usersBoardRole, activeBoard]
        );

        const openSettings = ()=> {
            closeMenu();
            analytics.eventTrack('robin_board_menu_notifications_click', {
                role: UserStore.usersBoardRole,
                board_id: activeBoard?.id,
            });
            history.push('/boards/account/notifications');
        };

        return (
            <Box position="relative">
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Box
                        className="cursor-pointer"
                        position="relative"
                        display="flex"
                        alignItems="center"
                        onClick={handleOnNotificationsIconClick}
                    >
                        <NotificationsNoneIcon />
                        {NotificationsStore.unseen > 0 && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                position="absolute"
                                right="-30%"
                                bottom="50%"
                                width="18px"
                                height="18px"
                                borderRadius="50%"
                                bgcolor="secondary.main"
                                style={{ color: 'white', fontSize: '10px' }}
                            >
                                {NotificationsStore.unseen}
                            </Box>
                        )}
                    </Box>
                </Grid>
                {isMenuOpen && (
                    <ClickAwayListener onClickAway={closeMenu}>
                        <StyledMenuContainer>
                            <Paper>
                                <Box p={2} fontSize="h6.fontSize">
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item xs>
                                            Notifications
                                        </Grid>
                                        <Grid item xs container justify="flex-end" alignItems="center">
                                            <Box className="flex" onClick={openSettings}>
                                                <SettingsIcon />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider></Divider>
                                <Box maxHeight={500} overflow="auto" position="relative" zIndex="tooltip">
                                    <NotificationsList
                                        notifications={notifications.slice(0, DISPLAY_LIMIT)}
                                        onNotificationClick={handleNotificationClick}
                                    ></NotificationsList>
                                    {hasMore && (<Box textAlign="center" p={2}>
                                        <LRButton
                                            variant="text"
                                            inline
                                            color="secondary"
                                            onClick={handleOnViewAllClick}
                                        >
                                            View All
                                        </LRButton>
                                    </Box>)}
                                </Box>
                            </Paper>
                        </StyledMenuContainer>
                    </ClickAwayListener>
                )}
            </Box>
        );
    })
);
