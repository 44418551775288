import React from 'react';
import lodashGet from 'lodash/get';
import { Box } from '@material-ui/core';
import {
    COLOR_COMPLIANCE_GREY
} from '../../../constants';

function VendorCompliance({ vendorUser, isMobile }) {
    if(!lodashGet(vendorUser, 'compliance_text') &&
        !lodashGet(vendorUser, 'vendor.compliance_text')
    ) return null;

    const equalHousingType = lodashGet(vendorUser, 'vendor.equal_housing_type', 'equal_housing_lender');
    const equalHousingSrc = `https://s3-us-west-2.amazonaws.com/edge.listreports.com/compliance/${equalHousingType}/logo_black.png`;
    return (
        <Box maxWidth="100%"
            color={COLOR_COMPLIANCE_GREY}
            px={isMobile ? 2 : 0}
            py={isMobile ? 2 : 3}
            fontSize="11px"
            lineHeight={1.2}
            display="flex"
            flexDirection="column"
        >
            <Box textAlign="justify"
                pt={isMobile ? 2 : 0}
            >
                {vendorUser.compliance_text || lodashGet(vendorUser, 'vendor.compliance_text')}
                <br/><br/>
                This is an advertisement and not an offer to enter into an agreement.
                Not all customers will qualify. All products are subject to credit and property approval.
                Other restrictions and limitations apply. Equal Housing Lender.
            </Box>

            <Box height={32}
                mt={2}
                textAlign="center"
            >
                <img src={equalHousingSrc}
                    height={32}
                    alt="Equal Housing"
                />
            </Box>
        </Box>
    );
}

export default VendorCompliance;
