// eslint-disable-next-line
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import MortgageCalculator from 'components/MortgageCalculator';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

/* 
buyerAgent={buyerAgent}
requestSource={requestSource} */

const PaymentCalculator = React.memo(function PaymentCalculator({ listing }) {
    const [expand, setExpand] = useState(false);

    const hoaDuesAmount = _.get(listing, 'hoa_fees.value');
    const hoaDuesPeriod = _.get(listing, 'hoa_fees.currencyPeriod', '')
        .toLowerCase() === 'monthly' ? 'month' : 'year';
    const listingTaxes = useMemo(()=> {
        if(!listing || !listing.id) {
            return null;
        }
        const { taxes } = listing;
        if(taxes && typeof taxes === 'object') {
            const ammount = parseInt(taxes.amount) || 0;
            if(_.includes(_.keys(taxes), 'year')) {
                return _.round(ammount / 12);
            } else {
                return ammount;
            }
        } else if(typeof taxes === 'string') {
            return parseInt(taxes) || 0;
        }
    }, [listing]);

    if(!listing) {
        return null;
    }

    return (
        <div id={`listing-${listing?.id}-calculator`}>
            <Typography variant="h5">Monthly Payment Calculator</Typography>
            <MortgageCalculator
                expand={expand}
                onExpandClick={()=> setExpand(!expand)}
                listing={listing}
                state={listing.state}
                price={listing.price}
                taxes={listingTaxes}
                hoaDuesAmount={hoaDuesAmount}
                hoaDuesPeriod={hoaDuesPeriod}
                requestSource={''}
            />
        </div>
    );
});

PaymentCalculator.propTypes = {
    listing: PropTypes.object,
};

export default PaymentCalculator;
