import styled from "styled-components";
import { Box } from "@material-ui/core";
import { COLOR_CREST_WHITE, COLOR_GRADE } from "../../../constants";
import CreateIcon from '@material-ui/icons/Create';

export const NoResultsContainer = styled(Box)`
    //width: ${({ mobileView })=> (mobileView ? '312px' : '720px')};
    height: ${({ mobileView })=> (mobileView ? '168px' : '153px')};
    background-color: ${COLOR_CREST_WHITE};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const NoResultsMainText = styled(Box)`
    color: ${COLOR_GRADE};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
    display: flex;
`;

export const NoResultsSecondaryText = styled(Box)`
    color: ${COLOR_GRADE};
    font-size: 16px;
    letter-spacing: 0.5px;
    display: flex;
    text-align: center;
`;

export const EditSearchContainer = styled(Box)`
    display: flex; 
    margin-top: ${(props)=> props?.isXs ? '24px' : '20px'};
`;


export const EditSearchButtonContainer = styled(Box)`
    display: flex; 
    justify-content: center;
    align-items: center;
    max-width: 75px;
    max-height: 36px;
`;

export const StyledEditIcon = styled(CreateIcon)`
    width: 16px; 
    height: 16px;
`;

export const StyledEditText = styled(Box)`
    display: flex; 
    font-size: 14px;
`;

export const StyledEditTextContainer = styled(Box)`
    display: flex; 
    margin-right: 10px;
`;
