import React from 'react';
import { LRToggleButtonGroupOptionStyle } from './style';
import { LRToggleButtonGroup } from '../../../../components/LRToggleButtonGroup';
import { LRDropPopover } from '../../../../components/LRDropPopover';
import { useMemo } from 'react';
import { find } from 'lodash';

const BEDS_OPTIONS = [
    { value: '0', label: 'Studio', style: LRToggleButtonGroupOptionStyle },
    { value: '1', label: '1+', style: LRToggleButtonGroupOptionStyle },
    { value: '2', label: '2+', style: LRToggleButtonGroupOptionStyle },
    { value: '3', label: '3+', style: LRToggleButtonGroupOptionStyle },
    { value: '4', label: '4+', style: LRToggleButtonGroupOptionStyle },
];

export function formatBedsTitle(beds) {
    if(beds) {
        const opt = find(BEDS_OPTIONS, { value: beds });
        return `${opt.label} Beds`;
    } else {
        return 'Beds';
    }
}

export function BedsContent({value, handleChange, onClose, trackFilterSet, agentId, handleMixpanelEvent, style={}}) {
    return (
        <div style={style}>
            <LRToggleButtonGroup
                fullWidth
                value={value}
                onChange={(value)=> {
                    handleChange(value);
                    trackFilterSet('beds');
                    handleMixpanelEvent(agentId, 'beds','fields.bedrooms');
                    if(onClose){
                        onClose();
                    }
                }}
                options={BEDS_OPTIONS}
            />
        </div>
    );
}

export function Beds({beds, handleBeds, trackFilterSet, trackFilterClick, onClick, agentId, handleMixpanelEvent}) {
    const bedsTitle = useMemo(()=> {
        return formatBedsTitle(beds);
    }, [beds]);

    return (
        <LRDropPopover
            title={bedsTitle}
            onClick={onClick}
            onOpen={()=> trackFilterClick('beds')}
            toggled={Boolean(beds)}
        >
            <BedsContent
                value={beds}
                handleChange={handleBeds}
                style={{padding: '16px'}}
                trackFilterSet={trackFilterSet}
                agentId={agentId}
                handleMixpanelEvent={handleMixpanelEvent}
            />
        </LRDropPopover>
    );
}
