import React from 'react';
import { SvgIcon } from '@material-ui/core';

function PushPinIcon({ fillColor = 'black', ...props }) {

    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 3C7.49497 3 7.0692 3.37657 7.00749 3.87782C6.94579 4.37907 7.26751 4.84765 7.75746 4.97014C7.98407 5.02679 8.34712 5.17362 8.6301 5.39718C8.90681 5.61578 9 5.81411 9 6L9 9C9 9.13232 8.93626 9.41833 8.7625 9.65C8.62061 9.83919 8.40603 10 8 10C6.54246 10 5.53039 10.9143 4.94126 11.7243C4.63866 12.1404 4.41383 12.5715 4.26233 12.9408C4.12904 13.2657 4 13.6684 4 14C4 14.2652 4.10536 14.5196 4.29289 14.7071C4.48043 14.8946 4.73478 15 5 15L19 15C19.5523 15 20 14.5523 20 14C20 13.6684 19.871 13.2657 19.7377 12.9408C19.5862 12.5715 19.3613 12.1404 19.0587 11.7243C18.4696 10.9143 17.4575 10 16 10C15.594 10 15.3794 9.83918 15.2375 9.65C15.0637 9.41832 15 9.13232 15 9L15 6C15 5.81411 15.0932 5.61578 15.3699 5.39718C15.6529 5.17362 16.0159 5.02679 16.2425 4.97014C16.7325 4.84765 17.0542 4.37907 16.9925 3.87782C16.9308 3.37657 16.505 3 16 3H8ZM11 21L12 22L13 21V16H12H11V21Z"
                fill={fillColor}
            />
        </SvgIcon>
    );
}

export default PushPinIcon;
