import Lockr from 'lockr';
/**
 * Fixes errors when accessing localstorage in incognito mode wihthin an iframe
 */

const originalLockrGet = Lockr.get.bind(Lockr);
Lockr.get = function(...args) {
    try {
        return originalLockrGet(...args);
    } catch (err) {
        console.error('Lockr -> failed to get');
        return null;
    }
};

const originalLockrRm = Lockr.rm.bind(Lockr);
Lockr.rm = function(...args) {
    try {
        return originalLockrRm(...args);
    } catch (err) {
        console.error('Lockr -> failed to rm');
        return null;
    }
};
