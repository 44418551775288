import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { css, keyframes } from 'styled-components';
import { Lock, LockOpen } from '@material-ui/icons';
import Lockr from 'lockr';

function LockIconButton() {
    return <Box
        bgcolor="#000"
        height={24}
        borderRadius="50%"
        style={{ cursor: 'pointer' }}
    >
        <Box pt='3px' color="#FFF">
            <Lock style={{ height: '16px', width: '16px' }}/>
        </Box>
    </Box>;
}

function UnlockIconButton() {
    return <Box
        bgcolor="#FFF"
        height={24}
        borderRadius="50%"
        boxShadow="1px 1px 1px"
        style={{ cursor: 'pointer' }}
    >
        <Box pt='3px' style={{ opacity: 0.54 }}>
            <LockOpen style={{ height: '16px', width: '16px' }}/>
        </Box>
    </Box>;
}

const pulseKeyFrames = keyframes`
  0% {
    -webkit-box-shadow: 0 0 0 0 #1EC8E1D9;
    box-shadow: 0 0 0 0 #1EC8E1D9;
  }

  70% {
    -webkit-box-shadow: 0 0 0 1em #1EC8E100;
    box-shadow: 0 0 0 1em #1EC8E100;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 #1EC8E100;
    box-shadow: 0 0 0 0 #1EC8E100;
`;

const PulseBox = styled(Box)`
    ${({ showAnimation })=>
        showAnimation === true
            ? css`
                    animation: ${pulseKeyFrames} 2s infinite;
                `
            : ''};
`;

const OverlayImageActions = observer(function OverlayImageActions(
    { listing, isLocked, showLock, onLockClick }) {
    return <>
        {listing.icon && (
            <Box
                position="absolute"
                top={10}
                left={16}
                borderRadius="4px"
                py="2px"
                px={1}
                bgcolor="white"
                color="lr_colors.grade"
                fontWeight={500}
            >
                {listing.icon}
            </Box>
        )}
        {!listing.hideLock && showLock && isLocked && <PulseBox
            mt={1}
            mr={1}
            width={24}
            height={24}
            borderRadius="50%"
            position="absolute"
            right={0}
            onClick={(e)=> {e.stopPropagation(); onLockClick(listing);}}
            showAnimation={!listing.locked && !Lockr.get('compare-now-lock-clicked')}
        >
            {!isLocked && <UnlockIconButton />}
            {isLocked && <LockIconButton />}
        </PulseBox>}
    </>;

});

export default OverlayImageActions;
