import React from 'react';
import styled from 'styled-components';
import LRModal from '../../components/LRModal';
import { LRCheckbox } from '../../components/LRCheckbox';
import NameHomeHeader from './NameHomeHeader';
import NameHomeFooter from './NameHomeFooter';
import NameHomeForm from './NameHomeForm';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Box, Hidden, withTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { NameHomeStoreProvider, useNameHomeStore, useNameHomeStoreContext } from './store';

const StyledLRModal = withTheme(styled(LRModal)`
    .name-home-modal-dialog-content {
        margin-bottom: 100px;
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
    }
`);
const Content = withTheme(styled(Box)`
    overflow-x: hidden;
    max-height: calc(100vh - 64px);
    background-color: white;
    padding-top: ${({ theme })=> `${theme.spacing(4)}px`};
    ${({ theme })=> theme.breakpoints.down('xs')} {
        max-height: calc(100vh - 120px);
        padding-left: ${({ theme })=> `${theme.spacing(2)}px`};
        padding-right: ${({ theme })=> `${theme.spacing(2)}px`};
    }
    ${({ theme })=> theme.breakpoints.up('sm')} {
        border-radius: 4px;
        margin: ${({ theme })=> `${theme.spacing(3)}px ${theme.spacing(5)}px`};
        padding-bottom: 32px;
    }
`);

const DontShowAgainCheckbox = observer(function DontShowAgainCheckbox({ source }) {
    const nameHomeStore = useNameHomeStoreContext();
    return (
        <>
            {source === 'autopop' && (
                <Box display="flex" justifyContent="center">
                    <LRCheckbox
                        checked={nameHomeStore.hidePersonalizedModal}
                        onChange={(e)=> (nameHomeStore.hidePersonalizedModal = e.target.checked)}
                        label="Don't show me this again!"
                    />
                </Box>
            )}
        </>
    );
});

function NameHomeModal() {
    const location = useLocation();
    const queryStrings = queryString.parse(location.search);
    const { source } = queryStrings;
    const history = useHistory();

    const match = useRouteMatch();
    const { listingId } = match.params;
    const nameHomeStore = useNameHomeStore(listingId, source);
    const { listing } = nameHomeStore;

    const rppEvent = 'rpp';

    if(nameHomeStore.close || !listing) {
        if(source === rppEvent) {
            history.push(`/boards/cards/${listing.id}/${_.kebabCase(listing.listing_details.address)}`);
        } else {
            return <Redirect to="/boards" />;
        }
    }

    return (
        <NameHomeStoreProvider nameHomeStore={nameHomeStore}>
            <StyledLRModal
                onClose={()=> (nameHomeStore.close = true)}
                dialogContentClasses={{ root: 'name-home-modal-dialog-content' }}
            >
                <NameHomeHeader />
                <Content mx="auto" pt={[0, 4]}>
                    <NameHomeForm listing={listing} />
                    <Hidden smUp>
                        <DontShowAgainCheckbox source={source} />
                    </Hidden>
                </Content>
                <Hidden xsDown>
                    <DontShowAgainCheckbox source={source} />
                </Hidden>
                <NameHomeFooter />
            </StyledLRModal>
        </NameHomeStoreProvider>
    );
}

export default observer(NameHomeModal);
