import { action, decorate, observable } from 'mobx';

const LOGIN_SIGNUP_MODAL_LOGIN_OPEN = 'login';
const LOGIN_SIGNUP_MODAL_SIGNUP_OPEN = 'signup';
const DEFAULT_SIGNUPFORM_OPTIONS = {
    homeReportTextMessageOptInCheckboxEnabled: false,
};

export class LoginSignupModalStore {
    // CONSTANTS

    LOGIN_SIGNUP_MODAL_LOGIN_OPEN = LOGIN_SIGNUP_MODAL_LOGIN_OPEN;
    LOGIN_SIGNUP_MODAL_SIGNUP_OPEN = LOGIN_SIGNUP_MODAL_SIGNUP_OPEN;

    // PRIVATE VARS

    _onSuccessCbs = [];
    _onLoginSuccessCbs = [];
    _onSignupSuccessCbs = [];
    _onBeforeOAuthRedirectCbs = [];

    // PUBLIC VARS

    isOpen = false;
    openLoginSource = undefined;
    openSignupSource = undefined;
    affiliateCode = undefined;
    userInfo = undefined;
    signupFormOptions = DEFAULT_SIGNUPFORM_OPTIONS;

    // PRIVATE FUNCTIONS

    loginSignupSuccess() {
        if(this._onSuccessCbs.length) {
            this._onSuccessCbs.forEach((func)=> func());
        }
    }

    // PUBLIC FUNCTIONS

    setUserInfoFromVendorLead(lead) {
        if(!lead) return;

        const userInfo = {
            ...lead.applicant,
        };

        if(!userInfo?.first_name && userInfo?.name) {
            const splitName = userInfo.name.split(' ');
            userInfo.first_name = splitName.shift();
            userInfo.last_name = splitName.join(' ');
        }

        this.userInfo = userInfo;
    }

    openLoginModal(source) {
        this.openLoginSource = source;
        this.isOpen = LOGIN_SIGNUP_MODAL_LOGIN_OPEN;
    }

    openSignupModal(source, options = {}) {
        this.signupFormOptions = { ...DEFAULT_SIGNUPFORM_OPTIONS, ...options, };
        this.openSignupSource = source;
        this.isOpen = LOGIN_SIGNUP_MODAL_SIGNUP_OPEN;
    }

    closeLoginSignupModal() {
        this.isOpen = false;
        this.openLoginSource = undefined;
        this.openSignupSource = undefined;
    }

    loginSuccess() {
        if(this._onLoginSuccessCbs.length) {
            this._onLoginSuccessCbs.forEach((func)=> func());
        }

        this.loginSignupSuccess();
    }

    signupSuccess() {
        if(this._onSignupSuccessCbs.length) {
            this._onSignupSuccessCbs.forEach((func)=> func());
        }

        this.loginSignupSuccess();
    }

    beforeOAuthRedirect() {
        if(this._onBeforeOAuthRedirectCbs.length) {
            this._onBeforeOAuthRedirectCbs.forEach((func)=> func());
        }
    }

    onSuccess(cb) {
        this._onSuccessCbs.push(cb);

        return ()=> {
            const index = this._onSuccessCbs.findIndex((func)=> func === cb);

            if(~index) {
                this._onSuccessCbs = [
                    ...this._onSuccessCbs.slice(0, index),
                    ...this._onSuccessCbs.slice(index + 1),
                ];
            }
        };
    }

    onLoginSuccess(cb) {
        this._onLoginSuccessCbs.push(cb);

        return ()=> {
            const index = this._onLoginSuccessCbs.findIndex((func)=> func === cb);

            if(~index) {
                this._onLoginSuccessCbs = [
                    ...this._onLoginSuccessCbs.slice(0, index),
                    ...this._onLoginSuccessCbs.slice(index + 1),
                ];
            }
        };
    }

    onSignupSuccess(cb) {
        this._onSignupSuccessCbs.push(cb);

        return ()=> {
            const index = this._onSignupSuccessCbs.findIndex((func)=> func === cb);

            if(~index) {
                this._onSignupSuccessCbs = [
                    ...this._onSignupSuccessCbs.slice(0, index),
                    ...this._onSignupSuccessCbs.slice(index + 1),
                ];
            }
        };
    }

    onBeforeOAuthRedirect(cb) {
        this._onBeforeOAuthRedirectCbs.push(cb);

        return ()=> {
            const index = this._onBeforeOAuthRedirectCbs.findIndex((func)=> func === cb);

            if(~index) {
                this._onBeforeOAuthRedirectCbs = [
                    ...this._onBeforeOAuthRedirectCbs.slice(0, index),
                    ...this._onBeforeOAuthRedirectCbs.slice(index + 1),
                ];
            }
        };
    }
}

decorate(LoginSignupModalStore, {
    isOpen: observable,
    openLoginSource: observable,
    openSignupSource: observable,
    affiliateCode: observable,
    userInfo: observable,
    _onSuccessCbs: observable,

    openLoginModal: action,
    openSignupModal: action,
    closeLoginSignupModal: action,
    loginSignupSuccess: action,
    signupSuccess: action,
    beforeOAuthRedirect: action,
    onSuccess: action,
    onLoginSuccess: action,
    onSignupSuccess: action,
    onBeforeOAuthRedirect: action,
});
