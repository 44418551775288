import React from 'react';
import { Card, Grid, Box } from '@material-ui/core';
import ListingImage from '../ListingImage';
import addCommas from '../../services/add-commas';
import splitAddress from '../../services/split-address';
import { useResponsiveConfig } from '../../hooks';

export default React.memo(({ listing })=> {
    const { listing_details } = listing;
    const heroImage = listing_details.photos && listing_details.photos.length ? listing_details.photos[0] : undefined;
    const [address1, address2] = splitAddress(listing_details.address);
    const cardHeight = useResponsiveConfig({ xs: 88, sm: 120 });
    const imgWidth = useResponsiveConfig({ xs: 112, sm: 180 });

    return (
        <Box key={listing.id} component={Card} position="relative" mb={2} height={cardHeight}>
            <Grid container alignItems="center">
                <Grid item>
                    <ListingImage image={heroImage} width={imgWidth} height={cardHeight} />
                </Grid>
                <Grid xs item>
                    <Box px={2}>
                        <Box fontWeight="bold" fontSize="text.fontSize">
                            ${addCommas(listing_details.price)}
                        </Box>
                        <Box
                            fontSize="subtitle2.fontSize"
                            color="text.secondary"
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {address1}
                            <Box display={{ xs: 'none', sm: 'inline' }}>
                                <br />
                                {address2}
                            </Box>
                        </Box>
                        <Box fontSize="subtitle2.fontSize" display={{ xs: 'none', sm: 'block' }}>
                            <Box mr={1} display="inline">
                                <b>{listing_details.beds}</b> bd
                            </Box>
                            <Box mr={1} display="inline">
                                <b>{listing_details.baths}</b> ba
                            </Box>
                            <Box mr={1} display="inline">
                                <b>{listing_details.home_size}</b> sqft{' '}
                            </Box>
                            <Box mr={1} display="inline">
                                <b>{listing_details.lot_size}</b> sqft lot
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
});
