import _ from 'lodash';
import moment from 'moment';

export const useOpenHouseTimes = (openHouseData = [], source)=> {
    return _.map(openHouseData, (house)=> {
        if(!_.isEmpty(house) && source === 'wolfnet') {
            return {
                start_time: moment(house.start_time_iso).utc().format('hh:mm A'),
                end_time: moment(house.end_time_iso).utc().format('hh:mm A')
            };
        } else {
            return {
                start_time: house?.start_time,
                end_time: house?.end_time
            };
        }
    });
};