import React, { useMemo } from 'react';
import lodashGet from 'lodash/get';
import lodashSome from 'lodash/some';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { useListingMLS, useListingMLSByZipcode } from 'hooks/data/listing/useListingMLS';
import formatPhoneNumber from 'services/format-phone-number';
import { COLOR_GREY_DARK } from '../../../constants';

const ListingAgent = ({ listing })=> {
    const { data: listingMLS, isFetched: isFetchedListingMLS } = useListingMLS(listing);
    const { data: mlses, isFetched } = useListingMLSByZipcode(listing);

    const showMlsName = useMemo(()=> {
        return lodashGet(listingMLS, '[0].add_mls_name_to_listing_agent_info', false);
    }, [listingMLS]);

    const hideAgent = useMemo(()=> {
        return lodashSome(mlses, 'hide_listing_agent_from_detail_page');
    }, [mlses]);

    if(showMlsName) return null;

    return (
        isFetched && isFetchedListingMLS && (
            <>
                <Box>
                    <Grid container direction="column" alignContent="flex-end">
                        <Box
                            textAlign="right"
                            component="span"
                            fontSize={10}
                            color={COLOR_GREY_DARK}
                            style={{ letterSpacing: '1.5px' }}
                        >
                            LISTING AGENT:
                        </Box>
                        <Box component="span" textAlign="right">
                            <Box fontSize={12}>
                                {!hideAgent && (
                                    <>
                                        {listing.realtor.first_name} {listing.realtor.last_name}
                                        {(listing.realtor.first_name || listing.realtor.last_name) && listing.realtor.company && (
                                            <Box px={0.5} component="span" color="#ababab">
                                                |
                                            </Box>
                                        )}
                                    </>
                                )}
                                {listing.realtor.company}{' '}
                                {(listing.realtor.phone || listing.realtor.email) && (
                                    <span>{`| ${listing.realtor.phone ? formatPhoneNumber(listing.realtor.phone) : listing.realtor.email} `}</span>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </>
        )
    );
};

ListingAgent.propTypes = {
    listing: PropTypes.object.isRequired,
};

export default React.memo(ListingAgent);
