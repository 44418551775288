import React, { useMemo, useState } from 'react';
import { CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import LRButton from 'components/LRButton';
import { Section, SectionContainer, SectionHeader, SectionTitle } from '../Section';
import Logger from 'js-logger';
import { AddAddressSearchInput } from '../AddAddressSearchInput';
import { LockOutlined } from '@material-ui/icons';
import addCommas from 'services/add-commas';
import { Line, defaults } from 'react-chartjs-2';
import moment from 'moment';
import { merge, partial } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import analytics from 'services/analytics';
import LRTooltip from 'components/LRTooltip';
import { useMyHome, useMyHomeAVM } from '../../../hooks/useMyHome';
import { useAgent } from '../../../hooks/useAgent';
import { useQuery } from 'hooks';

// Override chart defaults
merge(defaults, {
    global: {
        defaultFontFamily: '"LL Circular Pro", Arial, Helvetica, sans-serif',
    },
});

const NoHomeData = function NoHomeData({
    agent,
    onContactMeClick = (e)=> {},
}) {
    const { isMobile } = useDeviceBreakpoints();

    return <div style={{
        marginTop: 18,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        columnGap: 48,
        rowGap: 18,
        justifyContent: 'center',
        ...(isMobile && {flexWrap: 'wrap'}),
    }}>
        <div style={{}}>
            <img src="/images/estimate-home-value-empty.png" alt=''/>
        </div>
        <Typography variant='h6' style={{
            maxWidth: isMobile ? 328 : 335
        }}>
            This home is so new that we can’t estimate its home value right now.
            <LRButton
                variant="text"
                underline
                inline
                color="inherit"
                onClick={analytics.eventTrackOnEvent('robin_home_report_contact_agent_for_a_professional_estimate_link_click', null, onContactMeClick)}
            >
                <Typography component="span" variant="h6">
                    Reach out to Alison
                </Typography>
            </LRButton>
            &nbsp;for more info.
        </Typography>
    </div>;
};

function AddHomeOverlay({
    disableEnterAddressCta = false,
    style = {},
    onAddMyHome = async (address)=> {},
    ...props
}) {
    const theme = useTheme();
    const [address, setAddress] = useState('');

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            style={{
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), rgba(255, 255, 255, 0.5)',
                padding: theme.spacing(2),
                ...style,
            }}
            {...props}
        >
            <Grid
                xs
                item
                style={{
                    maxWidth: 370,
                }}
            >
                <Grid container alignItems="center">
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        item
                        style={{
                            width: 48,
                            height: 48,
                            backgroundColor: '#000',
                            color: '#fff',
                            borderRadius: '50%',
                            marginRight: theme.spacing(2),
                        }}
                    >
                        <Grid item>
                            <LockOutlined />
                        </Grid>
                    </Grid>

                    <Grid item xs style={{ maxWidth: 290, }}>
                        <Typography variant="h6" style={{ fontWeight: 500, }}>
                            Enter your address to get a home value
                        </Typography>
                    </Grid>
                </Grid>

                <div
                    style={{
                        marginTop: theme.spacing(3),
                    }}
                >
                    <LRTooltip title={disableEnterAddressCta && 'Only a buyer or co-buyer can add their home information.'} placement="top" aria-label="Only a buyer or co-buyer can add their home information.">
                        <div>
                            <AddAddressSearchInput
                                address={address}
                                onChange={(value)=> {
                                    setAddress(value);
                                }}
                                onSubmit={onAddMyHome}
                                style={{
                                    marginBottom: 0,
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
                                    borderRadius: 4,
                                }}
                                disabled={disableEnterAddressCta}
                            />
                        </div>
                    </LRTooltip>
                </div>
            </Grid>
        </Grid>
    );
}

function HomeData({
    agent,
    onContactMeClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const avm = useMyHomeAVM();
    const { isMobile } = useDeviceBreakpoints();
    return (
        <div style={{ minHeight: 200, }}>
            <Grid container justify="space-between" alignItems="center" style={{ display: isMobile && 'block', }}>
                <Grid item xs style={{ maxWidth: !isMobile && 284, }}>
                    <div style={{ display: 'inline-Flex', alignItems: 'center' }}>
                        <Typography variant="h3" style={{ fontWeight: 500, }}>
                            ${addCommas(avm?.estimatedValue)}
                        </Typography>
                        {avm?.includesAgentAdjustments && <Typography style={{ fontSize: '34px', color: '#A1A1A1' }}>*</Typography>}
                    </div>
                    <div style={{ display: 'inline-Flex', alignItems: 'flex-start' }}>
                        {avm?.includesAgentAdjustments && <Typography variant="caption" style={{ color: '#A1A1A1' }}>*</Typography>}
                        &nbsp;
                        <LRButton
                            style={{marginBottom: theme.spacing(2)}}
                            variant="text"
                            href="https://help.listreports.com/knowledge-base/robin-faq/#how-are-the-estimated-home-values-calculated"
                            target="_blank"
                            inline
                            underline
                            onClick={analytics.eventTrackOnEvent('robin_home_report_see_how_we_calculate_link_click')}
                        >
                            <Typography variant="caption" color="inherit">{avm?.includesAgentAdjustments ? `Value adjusted by ${agent?.name}` : 'See how we calculate this'}</Typography>
                        </LRButton>
                    </div>
                    <Typography variant="body1">
                        Last {avm.daysSinceLastChangeInValue} day change
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 500, marginBottom: theme.spacing(2) }}>
                        {avm.lastNDayChangeInValue > 0 && '+'}${addCommas(avm.lastNDayChangeInValue)} <span
                            style={{
                                color: theme.palette.lr_colors[avm.lastNDayChangeInValuePercent > 0 ? 'minty_fresh' : 'brick'] ,
                            }}
                        >({avm.lastNDayChangeInValuePercent > 0 && '+'}{avm.lastNDayChangeInValuePercent}%)</span>
                    </Typography>
                    {!isMobile && <Typography component="p" variant="body3" align={'left'} style={{ ...theme.typography.body3, color: theme.palette.lr_colors.steak_sauce }}>
                        Last updated: {moment(avm.lastUpdated).format('M/D/YY')}
                    </Typography>}
                </Grid>

                <Grid item xs style={{ maxWidth: !isMobile && 373, marginTop: isMobile && theme.spacing(4), }}>
                    <Chart />
                </Grid>

                {isMobile && <Typography component="p" variant="body3" align={'right'} style={{ ...theme.typography.body3, color: theme.palette.lr_colors.steak_sauce }}>
                    Last updated: {moment(avm.lastUpdated).format('M/D/YY')}
                </Typography>}
            </Grid>
        </div>
    );
}

function Chart() {
    const avm = useMyHomeAVM();
    const data = useMemo(()=> {
        if(!avm) return;

        const data = {
            labels: avm.data.map((dataItem)=> moment(dataItem.createdate).format('MMM YYYY')),
            datasets: [
                {
                    label: 'AVM',
                    data: avm.data.map((dataItem)=> dataItem.estimatedvalue),
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderWidth: 1,
                    borderColor: '#000',
                    borderJoinStyle: 'miter',
                    borderCapStyle: 'butt',
                    pointBorderWidth: 1,
                    pointRadius: 0,
                    pointHitRadius: 5,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 1,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(75,192,192,1)',
                }
            ],
        };

        return data;
    }, [avm]);
    const options = useMemo(() => {
        const yearsUsed = {};

        return {
            tooltips: {
                enabled: true,
                intersect: false,
                callbacks: {
                    label: function(tooltipItem) {
                        return `$${addCommas(tooltipItem.yLabel)}`;
                    },
                },
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            autoSkip: false,
                            maxRotation: 0,
                            minRotation: 0,
                            callback: function(value, i, values) {
                                const year = moment(value).format('YYYY');

                                if (yearsUsed[year]) {
                                    return null;
                                } else {
                                    yearsUsed[year] = true;
                                    return year;
                                }
                            },
                        },
                    },
                ],
                yAxes: [
                    {
                        position: 'right',
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 5,
                            callback: function(value) {
                                return `$${addCommas(value)}`;
                            },
                        },
                    },
                ],
            },
            legend: {
                display: false,
            },
        };
    }, []);

    return (
        <div>
            {data && (
                <Line
                    data={data}
                    options={options}
                />
            )}
        </div>
    );
}

function EstimatedHomeValueZeroState({
    disableEnterAddressCta = false,
    onAddMyHome = async (address)=> {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();
    const [address, setAddress] = useState('');

    return (
        <Section {...props}>
            <SectionContainer style={{ padding: isMobile ? 0 : theme.spacing(2), }}>
                <Grid container direction={isMobile ? 'column' : 'row'}>
                    <Grid
                        item
                        style={{
                            position: 'relative',
                            padding: isMobile ? theme.spacing(2) : 4,
                            paddingRight: !isMobile && theme.spacing(7),
                            paddingBottom: isMobile && theme.spacing(3),
                        }}
                    >
                        <SectionTitle style={{ lineHeight: '1.1rem', marginBottom: theme.spacing(2) }}>
                            Estimated home value
                        </SectionTitle>
                        <Typography variant="h3" style={{ fontWeight: 500, }}>
                            $ <span
                                style={{
                                    filter: 'blur(15px)',
                                    webkitFilter: 'blur(15px)',
                                }}
                            >XXX,XXX</span>
                        </Typography>
                        <Typography>
                            Last 30 day change
                        </Typography>
                        <Typography>
                            $ <span
                                style={{
                                    filter: 'blur(6px)',
                                    webkitFilter: 'blur(6px)',
                                }}
                            >X,XXX</span>
                        </Typography>

                        <div
                            style={{
                                display: !isMobile && 'none',
                                position: 'absolute',
                                bottom: -20,
                                left: 200,
                            }}
                        >
                            <img src="/images/my-home/curly.svg" alt="curly" />
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs
                        style={{
                            backgroundColor: '#eee',
                            borderRadius: 4,
                            padding: theme.spacing(isMobile ? 2 : 3),
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(isMobile ? 4 : 3),
                        }}
                    >
                        <Typography variant="h5" style={{ fontWeight: 500, maxWidth: '80%', }}>
                            Unlock by entering your home address
                        </Typography>

                        <div
                            style={{
                                marginTop: theme.spacing(2),
                            }}
                        >
                            <LRTooltip title={disableEnterAddressCta && 'Only a buyer or co-buyer can add their home information.'} placement="top" aria-label="Only a buyer or co-buyer can add their home information.">
                                <div>
                                    <AddAddressSearchInput
                                        buttonLabel="Unlock"
                                        buttonPosition={isMobile ? 'bottom' : 'right'}
                                        address={address}
                                        onChange={(value)=> {
                                            setAddress(value);
                                        }}
                                        onSubmit={onAddMyHome}
                                        style={{
                                            marginBottom: 0,
                                        }}
                                        autoSelect={false}
                                        disabled={disableEnterAddressCta}
                                    />
                                </div>
                            </LRTooltip>
                        </div>
                    </Grid>
                </Grid>
            </SectionContainer>
        </Section>
    );
};

export const EstimatedHomeValue = observer(function EstimatedHomeValue({
    disableEnterAddressCta = false,
    onAddMyHome = async (address)=> {},
    onCompareClick = ()=> {},
    onContactMeClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile, } = useDeviceBreakpoints();
    const { myHome: home } = useMyHome();
    const avm = useMyHomeAVM();
    const { data: agent } = useAgent({ priority: 'context' });
    const contactMeDefaultMessage = useMemo(()=> (
        `Hi ${agent?.first_name}, please help me estimate my home value!`
    ), [agent]);
    const mode = useQuery().get('mode');

    if(!home?.address) return (
        <EstimatedHomeValueZeroState
            {...props}
            disableEnterAddressCta={disableEnterAddressCta}
            onAddMyHome={onAddMyHome}
        />
    );

    return (
        <Section {...props}>
            <SectionHeader style={!isMobile && { borderBottom: '1px solid', borderBottomColor: theme.palette.lr_colors.border_grey, paddingBottom: theme.spacing(2), }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs>
                        <SectionTitle style={{ lineHeight: '1.1rem', }}>
                            Estimated home value
                        </SectionTitle>
                    </Grid>
                    {mode !== 'ro' && Boolean(avm?.data?.length) && <Grid item>
                        <LRButton variant="outlined" onClick={analytics.eventTrackOnEvent('robin_home_report_compare_my_home_button_click', null, onCompareClick)}>
                            Compare
                        </LRButton>
                    </Grid>}
                </Grid>
            </SectionHeader>

            <SectionContainer style={{ position: 'relative' }}>
                {home && avm.isLoading && (
                    <Grid container justify="center">
                        <Grid item style={{ padding: theme.spacing(2) }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}

                {(!avm.isLoading && home && Boolean(avm?.data?.length)) && (
                    <HomeData onContactMeClick={partial(onContactMeClick, partial.placeholder, contactMeDefaultMessage)} agent={agent} />
                )}

                {(!avm.isLoading && (!home || !Boolean(avm?.data?.length))) && (
                    <div
                        style={{
                            filter: !home && 'blur(3px)',
                            webkitFilter: !home && 'blur(3px)',
                        }}
                    >
                        <NoHomeData onContactMeClick={partial(onContactMeClick, partial.placeholder, contactMeDefaultMessage)} agent={agent} />
                        {!isMobile && <Typography component="p" variant="body3" align={'left'} style={{ ...theme.typography.body3, color: theme.palette.lr_colors.steak_sauce }}>
                            Last updated: {moment(home.updatedAt).format('M/D/YY')}
                        </Typography>}
                    </div>
                )}

                {!home && (
                    <AddHomeOverlay
                        disableEnterAddressCta={disableEnterAddressCta}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                        }}
                        onAddMyHome={onAddMyHome}
                    />
                )}
            </SectionContainer>
        </Section>
    );
});
