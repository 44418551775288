import { Hidden, AppBar, Box, ButtonBase, CircularProgress, Grid, Tab as MuiTab, Tabs, ThemeProvider, Toolbar, Typography, Tooltip } from '@material-ui/core';
import { DescriptionRounded, GroupRounded, OpenInNewRounded,  Room } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useTheme, withTheme } from '@material-ui/styles';
import LRAvatar from 'components/LRAvatar';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { benoitApi } from '../../../../apis';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import theme, { MuiPrimaryBlackTheme } from 'theme';
import styled from 'styled-components';
import ListingImage from 'components/ListingImage';
import addCommas from 'services/add-commas';
import { find, partial } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'mobx-store';
import shortid from 'shortid';
import LRButton from 'components/LRButton';
import { LRNoteCard } from 'components/LRNoteCard';
import Logger from 'js-logger';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { LRDateSelect } from 'components/LRDateSelect';
import { LRTimeSelect } from 'components/LRTimeSelect';
import analytics from 'services/analytics';
import LRLink from 'components/LRLink';
import { Can, useAbility } from 'components/Ability';
import { subject } from '@casl/ability';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import { RectShape } from 'react-placeholder/lib/placeholders';

export const TAB_HOMES = 'Homes';
export const TAB_DATE_AND_TIME = 'Date & Time';
export const TAB_DOCUMENTS = 'Documents';
export const TAB_NOTES = 'Notes';

const Tab = withTheme(styled(MuiTab)`
    min-width: 0;
    margin-right: ${({ theme })=> (theme.spacing(3))}px;
    opacity: 1;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        margin-right: 0;
    }
`);
function TabLabel({
    selected = false,
    children,
}) {
    return (
        <Typography variant="body2" style={{ textTransform: 'none', fontWeight: (selected && 'bold') }}>{children}</Typography>
    );
}

export const TourDetailsAppBarHeader = observer(function TourDetailsAppBarHeader({
    tour,
    onMapToggle,
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
        isIPadDown,
    } = useDeviceBreakpoints();
    const isScheduled = useMemo(()=> {
        if(!tour) return false;

        return Boolean(tour.content.scheduled_date_time);
    }, [tour?.content?.scheduled_date_time]);
    const isCancelled = useMemo(()=> {
        if(!tour) return false;

        return tour.content.cancelled;
    }, [tour?.content?.cancelled]);
    const  tourCreator = tour?.user;

    return (
        <AppBar
            position="static"
            color="inherit"
            elevation={0}
            style={{
                borderBottom: '1px solid #ddd',
            }}
        >
            <Toolbar>
                <div
                    style={{
                        width: '100%',
                        paddingLeft: theme.spacing(isIPadDown ? 0 : 3),
                        paddingRight: theme.spacing(isIPadDown ? 0 : 3),
                        paddingTop: theme.spacing(isIPadDown ? 2 : 3),
                        paddingBottom: theme.spacing(isIPadDown ? 2 : 3),
                    }}
                >
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item style={{ height: '28px' }}>
                            <GroupRounded style={{ width: '20px', height: '20px' }} />
                        </Grid>

                        <Grid item>
                            <Typography variant="body4" style={{ ...theme.typography.body4, textTransform: 'uppercase' }}>
                                in-person tour
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            style={{
                                color: isScheduled ? theme.palette.lr_colors.minty_fresh : theme.palette.lr_colors.brick,
                                marginLeft: theme.spacing(.5)
                            }}
                        >
                            {tour && (
                                isCancelled ? (
                                    <Typography variant="body4" style={{ ...theme.typography.body4, textTransform: 'uppercase', fontWeight: 'bold', color: theme.palette.lr_colors.steak_sauce }}>
                                        CANCELLED
                                    </Typography>
                                ) : (
                                    <Typography variant="body4" color="inherit" style={{ ...theme.typography.body4, textTransform: 'uppercase', fontWeight: 'bold' }}>
                                        {isScheduled ? 'SCHEDULED' : 'UNSCHEDULED'}
                                    </Typography>
                                )
                            )}

                            {!tour && (
                                <Typography variant="body4" className="show-loading-animation">
                                    <RectShape 
                                        style={{
                                            height: 15,
                                            width: 95,
                                            backgroundColor: 'rgb(205, 205, 205)',
                                            borderRadius: 4,
                                            overflow: 'hidden',
                                        }}
                                    />
                                </Typography>
                            )}

                        </Grid>

                        <Hidden smUp>
                            <div style={{ flex: 1, textAlign: 'right', position: 'absolute', right: '4px' }}>
                                <LRButton
                                    color="inherit"
                                    variant="text"
                                    onClick={onMapToggle}
                                    style={{
                                        textDecoration: 'underline',
                                        textUnderlinePosition: 'under'
                                    }}
                                >
                                    <Room fontSize="inherit"/> Map
                                </LRButton>
                            </div>
                        </Hidden>
                    </Grid>

                    <Typography variant="h4" style={{ fontWeight: 'normal', wordBreak: 'break-word' }}>
                        {tour?.content?.tour_name}
                    </Typography>

                    {!tour?.content?.tour_name && (
                        <Typography variant="h4" className="show-loading-animation" style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }}>
                            <RectShape 
                                style={{
                                    height: 32,
                                    width: '70%',
                                    backgroundColor: 'rgb(205, 205, 205)',
                                    borderRadius: 4,
                                    overflow: 'hidden',
                                }}
                            />
                        </Typography>
                    )}

                    <Grid container spacing={1} alignItems="center">
                        {tour?.content?.scheduled_date_time && (
                            <Grid item>
                                <Typography variant={!isMobile && 'h6'}>
                                    {moment(tour.content.scheduled_date_time).format('ddd, MMM D [at] h:mma')}
                                </Typography>
                            </Grid>
                        )}

                        <Grid item>
                            {tour ? (
                                <LRAvatar size="xsmall" user={tourCreator} />
                            ) : (
                                <div className="show-loading-animation">
                                    <RectShape 
                                        style={{
                                            height: 24,
                                            width: 24,
                                            backgroundColor: 'rgb(205, 205, 205)',
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                        }}
                                    />
                                </div>
                            )}
                        </Grid>

                        {!!tour?.content?.participants_joining?.length && tour.content.participants_joining.map((participant)=> (
                            <Grid item key={participant.id}>
                                {participant?.userId !== tourCreator?.id && <LRAvatar size="xsmall" user={participant} />}
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Toolbar>
        </AppBar>
    );
});

export const TourDetailsTabs = observer(function TourDetailsTabs({
    tour,
    notes = [],
    selectedTab = TAB_HOMES,
    onChange = (e, value)=> {},
    ...props
}) {
    const theme = useTheme();
    const { UserStore } = useStore();
    const { isAgent, user, profileId } = UserStore;
    const { isMobile } = useDeviceBreakpoints();
    const ability = useAbility();
    const isScheduled = useMemo(()=> {
        if(!tour) return false;

        return Boolean(tour.content.scheduled_date_time);
    }, [tour?.content?.scheduled_date_time]);
    const isCancelled = useMemo(()=> {
        if(!tour) return false;

        return tour.content.cancelled;
    }, [tour?.content?.cancelled]);
    const isUpcoming = useMemo(()=> {
        if(!tour) return false;

        return Boolean(!tour.content.scheduled_date_time || moment().isBefore(tour.content.scheduled_date_time));
    }, [tour]);
    const isPast = useMemo(()=> {
        if(!tour) return false;

        return Boolean(moment().isAfter(tour.content.scheduled_date_time));
    }, [tour]);
    const isCreator = useMemo(()=> {
        if(!tour) return false;
        return profileId === tour.userId;
    }, [profileId, tour]);
    const isParticipant = useMemo(()=> {
        if(!tour?.content?.participants_joining.length) return isCreator || false;
        return isCreator || _.some(tour.content.participants_joining, {userId: profileId});
    }, [tour, profileId, isCreator]);
    const hasSigned = useMemo(()=> {
        if(!tour?.content?.pead_signatures) return false;
        return tour.content.pead_signatures[(isAgent ? user.affiliate.email : user.email)];
    }, [user, tour, isAgent]);

    const myNewNotes = useMemo(()=> {
        return notes.filter((note)=> {
            return ability.can('read', subject('BoardCollaboration', note)) && (!note.last_seen_at || !(profileId in note.last_seen_at));
        });
    }, [ability, profileId, notes]);

    const tourTabs = useMemo(()=> {
        if(tour?.meta.pead_required && !isPast && !isCancelled) {
            return [TAB_HOMES, TAB_DATE_AND_TIME, TAB_DOCUMENTS, TAB_NOTES];
        }
        return [TAB_HOMES, TAB_DATE_AND_TIME, TAB_NOTES];
    },[tour, isPast, isCancelled]);

    return (
        <AppBar
            position="static"
            color="inherit"
            elevation={0}
            style={{
                borderBottom: '1px solid #ddd',
                paddingLeft: theme.spacing(isMobile ? 2 : 6),
                paddingRight: theme.spacing(isMobile ? 2 : 6),
            }}
            {...props}
        >
            <ThemeProvider theme={MuiPrimaryBlackTheme}>
                <Tabs
                    value={selectedTab}
                    onChange={onChange}
                    indicatorColor="primary"
                    style={{
                        padding: `0 ${theme.spacing(2)}px`,
                    }}
                >
                    {tourTabs.map((tab)=> (
                        <Tab
                            key={`tourdetails-tab-${tab}`}
                            style={{
                                minWidth: 'auto',
                                overflow: 'visible',
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: `${theme.spacing(isMobile ? 2 : 3)}px`
                            }}
                            label={(
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <TabLabel selected={selectedTab === tab}>
                                        {tab}
                                    </TabLabel>

                                    {((tab === TAB_NOTES && myNewNotes.length !==0) ||
                                      (tab === TAB_DATE_AND_TIME && isUpcoming && !isScheduled && isAgent) ||
                                      (tab === TAB_DOCUMENTS && isUpcoming && (isAgent || isParticipant) && !hasSigned)) && (
                                        <div
                                            style={{
                                                width: 6,
                                                height: 6,
                                                background: theme.palette.lr_colors.brick,
                                                borderRadius: '50%',
                                                position: 'absolute',
                                                top: 0,
                                                right: -6,
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            value={tab}
                        />
                    ))}
                </Tabs>
            </ThemeProvider>
        </AppBar>
    );
});

function TourDetailsHomesCard({
    boardCard,
    highlightBoardCard,
    onCardHover = (e, boardCard)=> {},
    onCardClick = (e, boardCard)=> {},
    ...props
}) {
    const theme = useTheme();
    const {
        isIPadProUp,
    } = useDeviceBreakpoints();
    const isCardHorizontal = isIPadProUp;
    const { data: cardListing } = useBoardCardListing(boardCard);
    const listing = useMemo(()=> {
        return cardListing ? { ...boardCard, listing_details: cardListing } : null;
    }, [boardCard, cardListing]);

    if(!listing) {
        return null;
    }

    return  (
        <ButtonBase
            key={listing.id}
            style={{
                display: 'block',
                width: '100%',
                textAlign: 'left',
                marginBottom: theme.spacing(2),
                borderRadius: '4px',
            }}
            onClick={partial(onCardClick, partial.placeholder, listing)}
            onMouseEnter={partial(onCardHover, partial.placeholder, listing)}
            onMouseLeave={partial(onCardHover, partial.placeholder, null)}
        >
            <Grid
                container
                alignItems="center"
                style={{
                    border: `2px solid ${highlightBoardCard && highlightBoardCard.id === listing.id ? theme.palette.lr_colors.brick : '#fff'}`,
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
                    display: !isCardHorizontal && 'block',
                }}
            >
                <Grid item>
                    <div
                        style={{
                            padding: isCardHorizontal && theme.spacing(1),
                            height: isCardHorizontal && '100%',
                        }}
                    >
                        <ListingImage
                            style={{
                                width: isCardHorizontal ? '120px' : '100%',
                                height: isCardHorizontal && '100%',
                                minHeight: '80px',
                                maxHeight: !isCardHorizontal && '170px',
                                paddingBottom: '51.52%',
                                position: 'relative',
                                borderTopLeftRadius: !isCardHorizontal && '4px',
                                borderTopRightRadius: !isCardHorizontal && '4px',
                            }}
                            image={
                                listing.hero_image_url ||
                                (listing.listing_details?.photos.length ? listing.listing_details.photos[0] : undefined)
                            }
                        >
                        </ListingImage>
                    </div>
                </Grid>

                <Grid item xs>
                    <div
                        style={{
                            padding: theme.spacing(1),
                        }}
                    >
                        <Typography variant="h6">
                            {listing?.name || listing.listing_details?.address?.split(',')[0]}
                        </Typography>
                        <Typography variant="body2">
                            {!!listing.listing_details?.price && (<span style={{ display: 'inline-block', marginRight: theme.spacing(1) }}>${addCommas(boardCard.listing_details?.price)}</span>)}   {!!boardCard.listing_details?.beds && (<span style={{ display: 'inline-block', marginRight: theme.spacing(1) }}>{boardCard.listing_details?.beds} bed</span>)}   {!!boardCard.listing_details?.baths && (<span style={{ display: 'inline-block', marginRight: theme.spacing(1) }}>{boardCard.listing_details?.baths} bath</span>)}   {!!boardCard.listing_details?.home_size && (<span style={{ display: 'inline-block', marginRight: theme.spacing(1) }}>{addCommas(boardCard.listing_details?.home_size)} sqft</span>)}
                        </Typography>
                        <Typography variant="body4" style={{ ...theme.typography.body4, color: theme.palette.lr_colors.steak_sauce }}>
                            {listing.listing_details?.address}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </ButtonBase>
    );
}


// const { data: listing_details } = useBoardCardListing(boardCard);
export function TourDetailsHomes({
    boardCards = [],
    highlightBoardCard,
    onCardHover = (e, boardCard)=> {},
    onCardClick = (e, boardCard)=> {},
    ...props
}) {
    return (
        <div
            {...props}
        >
            {boardCards.map((boardCard)=> (
                <TourDetailsHomesCard
                    boardCard={boardCard}
                    highlightBoardCard={highlightBoardCard}
                    onCardHover={onCardHover}
                    onCardClick={onCardClick}
                />
            ))}
        </div>
    );
}

export const TourDetailsDateAndTime = observer(function TourDetailsDateAndTime({
    tour,
    onEditClick = (e)=> {},
    onTourTimeAndDateSelected,
    onScheduleReadyToggle = (e)=> {},
    schedulingDateAndTime = false,
    setSchedulingDateAndTime = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();
    const { UserStore, GlobalSnackbarStore } = useStore();
    const { user, isAgent } = UserStore;
    const agent = user?.affiliate;
    const isScheduled = useMemo(()=> {
        if(!tour) return false;

        return Boolean(tour.content.scheduled_date_time);
    }, [tour?.content?.scheduled_date_time]);
    const isCancelled = useMemo(()=> {
        if(!tour) return false;

        return tour.content.cancelled;
    }, [tour?.content?.cancelled]);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedTime, setSelectedTime] = useState();
    const [savingDateAndTime, setSavingDateAndTime] = useState(false);
    const [isReadyToSchedule, setIsReadyToSchedule] = useState(false);

    useEffect(function() {
        const result = selectedDate && selectedTime && !savingDateAndTime;
        if(result !== isReadyToSchedule) {
            onScheduleReadyToggle(result);
        }

        setIsReadyToSchedule(result);
    }, [isReadyToSchedule, onScheduleReadyToggle, savingDateAndTime, selectedDate, selectedTime]);

    const scheduleDateAndTime = useCallback(async function(e) {
        const [hour, minute, amPm] = selectedTime.replace(/^(\d\d?):(\d\d)([ap]m)/i, '$1|$2|$3').split('|');
        const dateAndTime = moment(selectedDate)
            .hour(parseInt(hour) + (amPm.toLowerCase() === 'pm' ? 12 : 0))
            .minutes(minute)
            .toISOString()
        ;

        setSavingDateAndTime(true);

        try {
            if(onTourTimeAndDateSelected){
                await onTourTimeAndDateSelected(dateAndTime);
                GlobalSnackbarStore.show('Tour has been scheduled!');
            }
        } catch (err) {
            Logger.error('scheduleDateAndTime -> Error', err);
        } finally {
            setSavingDateAndTime(false);
        }
    }, [GlobalSnackbarStore, onTourTimeAndDateSelected, selectedDate, selectedTime]);

    useEffect(function() {
        const scheduleFn = async function() {
            setSchedulingDateAndTime(false);
            await scheduleDateAndTime();
        };

        if(schedulingDateAndTime) {
            scheduleFn();
        }
    }, [scheduleDateAndTime, schedulingDateAndTime, setSchedulingDateAndTime]);

    // Functions

    return (
        <div {...props}>
            {isScheduled ? (
                // Scheduled
                <div
                    style={{
                        ...(isMobile ? {
                            marginTop: theme.spacing(2),
                        } : {
                            borderRadius: '4px',
                            backgroundColor: '#fff',
                            padding: theme.spacing(5),
                        })
                    }}
                >
                    <Typography
                        variant="body4"
                        color="inherit"
                        style={{
                            ...theme.typography.body4,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: theme.palette.lr_colors.minty_fresh,
                        }}
                    >
                        SCHEDULED
                    </Typography>
                    <Typography
                        variant="h5"
                        color="inherit"
                        style={{
                            fontWeight: '700',
                        }}
                    >
                        {moment(tour.content.scheduled_date_time).format('dddd, MMMM D')}
                    </Typography>
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {moment(tour.content.scheduled_date_time).format('h:mma')}
                    </Typography>

                    {/* <Typography
                        color="inherit"
                        style={{
                            marginTop: theme.spacing(3),
                        }}
                    >
                        Need to change the date or time?
                        <br />
                        <LRButton color="inherit" inline underline onClick={onEditClick}>Edit your tour.</LRButton>
                    </Typography> */}
                </div>
            ) : isAgent ? (

                // NOT Scheduled AND isAgent
                <div>
                    <div
                        style={{
                            ...(isMobile ? {
                                marginTop: theme.spacing(2),
                            } : {
                                borderRadius: '4px',
                                backgroundColor: '#fff',
                                padding: theme.spacing(5),
                            })
                        }}
                    >
                        {tour?.content?.suggested_date_time_1?.asap ? (
                            <Typography variant="h6">
                                ASAP
                            </Typography>
                        ) : (
                            <Grid container spacing={2}>
                                {tour?.content?.suggested_date_time_1?.date && (
                                    <Grid item xs>
                                        <Typography variant="body2" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                            First choice:
                                        </Typography>
                                        <Typography variant="h6">
                                            {moment(tour.content.suggested_date_time_1.date).format('dddd, MMM D')}
                                        </Typography>
                                        <Typography>
                                            {tour.content.suggested_date_time_1.time}
                                        </Typography>
                                    </Grid>
                                )}

                                {tour?.content?.suggested_date_time_2?.date && (
                                    <Grid item xs>
                                        <Typography variant="body2" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                            Second choice:
                                        </Typography>
                                        <Typography variant="h6">
                                            {moment(tour.content.suggested_date_time_2.date).format('dddd, MMM D')}
                                        </Typography>
                                        <Typography>
                                            {tour.content.suggested_date_time_2.time}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        <div
                            style={{
                                marginTop: theme.spacing(4),
                                marginBottom: theme.spacing(3),
                                borderTop: `2px solid ${theme.palette.lr_colors.border_grey}`,
                            }}
                        />

                        <Typography>
                            Keeping the buyer’s first and second choices in mind, please select a date and time for this tour:
                        </Typography>

                        <div
                            style={{
                                marginTop: theme.spacing(4),
                            }}
                        >
                            <Typography style={{ fontWeight: 'bold', marginBottom: theme.spacing(1) }}>
                                Date:
                            </Typography>

                            <LRDateSelect
                                value={selectedDate}
                                onChange={setSelectedDate}
                                disabled={isCancelled}
                                fullWidth={isMobile}
                            />
                        </div>

                        <div
                            style={{
                                marginTop: theme.spacing(4),
                            }}
                        >
                            <Typography style={{ fontWeight: 'bold', marginBottom: theme.spacing(1) }}>
                                Time:
                            </Typography>

                            <LRTimeSelect
                                value={selectedTime}
                                startTimeHour={9}
                                endTimeHour={22}
                                onChange={setSelectedTime}
                                disabled={isCancelled}
                                fullWidth={isMobile}
                            />
                        </div>
                    </div>

                    {!isMobile && (
                        <div
                            style={{
                                marginTop: theme.spacing(4),
                                textAlign: 'right',
                            }}
                        >
                            <LRButton
                                color="primary"
                                variant="contained"
                                disabled={!isReadyToSchedule}
                                onClick={scheduleDateAndTime}
                                style={{
                                    transition: savingDateAndTime && 'width 200ms ease-out',
                                    width: savingDateAndTime ? 64 : 125,
                                }}
                            >
                                {savingDateAndTime  ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Schedule Tour'
                                )}
                            </LRButton>
                        </div>
                    )}
                </div>
            ) : (

                // NOT Scheduled AND NOT isAgent
                <div
                    style={{
                        ...(isMobile ? {
                            marginTop: theme.spacing(2),
                        } : {
                            borderRadius: '4px',
                            backgroundColor: '#fff',
                            padding: theme.spacing(5),
                        })
                    }}
                >
                    <Typography
                        variant="body4"
                        color="inherit"
                        style={{
                            ...theme.typography.body4,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: theme.palette.lr_colors.brick,
                        }}
                    >
                        UNSCHEDULED
                    </Typography>
                    <Typography
                        variant="h5"
                        color="inherit"
                        style={{
                            fontWeight: '700',
                        }}
                    >
                        {agent?.first_name || 'Your Agent'} hasn't scheduled a date and time for this tour yet.
                    </Typography>

                    <div
                        style={{
                            marginTop: theme.spacing(4),
                            marginBottom: theme.spacing(3),
                            borderTop: `2px solid ${theme.palette.lr_colors.border_grey}`,
                        }}
                    />

                    {UserStore.isConsumer && (
                        <Typography>
                        Your preferred dates/times for this tour:
                        </Typography>
                    )}
                    {!UserStore.isConsumer && (
                        <Typography>
                            {tour?.user.first_name}'s preferred dates/time for this tour:
                        </Typography>
                    )}
                    {tour?.content?.suggested_date_time_1?.asap ? (
                        <Typography variant="h6" style={{ marginTop: theme.spacing(2.5) }}>
                            ASAP
                        </Typography>
                    ) : (
                        <Grid container style={{ marginTop: theme.spacing(2.5) }}>
                            {tour?.content?.suggested_date_time_1?.date && (
                                <Grid item xs>
                                    <Typography variant="body2" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                        First choice:
                                    </Typography>
                                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                                        {moment(tour.content.suggested_date_time_1.date).format('dddd, MMM D')}
                                    </Typography>
                                    <Typography variant="body2">
                                        {tour.content.suggested_date_time_1.time}
                                    </Typography>
                                </Grid>
                            )}

                            {tour?.content?.suggested_date_time_2?.date && (
                                <Grid item xs style={{ marginLeft: theme.spacing(4) }}>
                                    <Typography variant="body2" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                                        Second choice:
                                    </Typography>
                                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                                        {moment(tour.content.suggested_date_time_2.date).format('dddd, MMM D')}
                                    </Typography>
                                    <Typography variant="body2">
                                        {tour.content.suggested_date_time_2.time}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </div>
            )}
        </div>
    );
});

export const TourDetailsNotes = observer(function TourDetailsNotes({
    tour,
    notes = [],
    onAddNote = (e, data)=> {},
    onReactionToNote = (e, data)=> {},
    ...props
}) {
    const theme = useTheme();
    const { UserStore, boardsStore } = useStore();
    const { user, isAgent, isLO, profileId } = UserStore;
    const activeBoard = useMemo(()=> {
        return boardsStore.activeBoard || {};
    }, [boardsStore.activeBoard]);
    const {
        addBoardCollaboration,
        deleteBoardCollaboration,
        updateBoardCollaboration,
    } = useBoardCollaborations(tour?.id);

    // Functions

    function toggleReaction(reaction = 'thumbsup', boardCollaboration) {
        const findByKey = isLO
            ? { userId: user?.affiliate?.preferredVendorId }
            : isAgent
                ? { affiliateId : user.affiliateId }
                : { userId : user.id };
        const usersReaction = find(boardCollaboration.reactions, findByKey);

        if(usersReaction) {
            deleteReaction(usersReaction);
        } else {
            createReaction(reaction, boardCollaboration);
        }
    }

    async function createReaction(reactionIcon, boardCollaboration) {
        const reactorId = isLO
            ? user?.affiliate?.preferredVendorId
            : isAgent
                ? user.affiliateId
                : user.id;
        const msg = {
            id: `reaction_${reactorId}_${boardCollaboration.id}_${activeBoard?.id}`,
            model: 'boardCollaboration',
            boardId: activeBoard?.id,
            itemId: boardCollaboration.id,
            content: {
                icon: reactionIcon,
            },
            type: 'reaction',
            visibleToAffiliate: boardCollaboration.visibleToAffiliate,
            visibleToLO: boardCollaboration.visibleToLO,
        };

        try {
            await addBoardCollaboration(msg);
        } catch (err) {
            Logger.error('createReaction -> Error', err);
        }
    }

    async function deleteReaction(usersReaction) {
        try {
            await deleteBoardCollaboration({ boardCollaboration: usersReaction, options: { soft_delete: false } });
        } catch (err) {
            Logger.error('deleteReaction -> Error', err);
        }
    }

    useEffect(()=> {
        notes.forEach((note)=> {
            if(!note.last_seen_at || !(profileId in note.last_seen_at)) {
                updateBoardCollaboration({ id: note.id, data: { last_seen_at: { [profileId]: new Date().toISOString() } } });
            }
        });
    }, []);

    return (
        <div {...props}>
            {notes && notes.map((note)=> (
                <Can key={note.id} I="read" this={subject('BoardCollaboration', note)}>
                    <div style={{ marginBottom: theme.spacing(2) }}>
                        <LRNoteCard
                            boardCollaboration={note}
                            hideEdit
                            hideVisibleToAgentMessaging
                            onReactionClick={(e, reaction)=> {
                                toggleReaction(reaction, note);
                            }}
                        />
                    </div>
                </Can>
            ))}
        </div>
    );
});

export const TourDetailsDocuments = observer(function TourDetailsDocuments({
    tour,
    onReviewAndSignClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
        isIPadUp,
    } = useDeviceBreakpoints();
    const { UserStore, boardsStore } = useStore();
    const activeBoard = useMemo(()=> {
        return boardsStore.activeBoard || {};
    }, [boardsStore.activeBoard]);

    const { user, isAgent, isLO } = UserStore;
    const creatorHasSigned = useMemo(()=> {
        if(!(tour?.user && tour?.content?.pead_signatures)) return false;
        return tour.content.pead_signatures[tour.user.email];
    }, [tour]);
    const agentHasSigned = useMemo(()=> {
        if(!(user && tour)) return false;

        return Boolean((tour?.content?.pead_signatures || {})[user.affiliate?.email]);
    }, [tour, user]);

    function getEmailForPead(user, isAgent, isLO) {
        if(isAgent){
            return user.affiliate?.email;
        } else if(isLO) {
            return user?.affiliate?.preferred_vendor?.email;
        } else {
            return user.email;
        }
    }

    const userHasSignedPEADForm = useMemo(()=> {
        if(!(user && tour)) return false;
        let email = getEmailForPead(user, isAgent, isLO);
        return Boolean((tour?.content?.pead_signatures || {})[email]);
    }, [tour, user, getEmailForPead]);

    const peadEmailSent = useMemo(()=> {
        if(!(user && tour)) return false;
        const email = getEmailForPead(user, isAgent, isLO);
        return Boolean((tour?.meta?.pead_signatures_sent || {})[email]);
    }, [tour, user, getEmailForPead]);



    let disableToolTipProps = {
        title: `Please contact ${user.affiliate?.name} if you'd like to see this document.`,
        placement: 'top',
    };
    if(!isLO){
        disableToolTipProps = {
            disableHoverListener: true,
            disableFocusListener: true,
            disableTouchListener: true,
        };
    }

    const [state, setState] = useState({isLoading:false, emailPeadSent: null});


    async function handlerPeadEmail(resend = false) {
        const eventTrack = (resend) ? 'robin_home_tour_detail_resend_pead_click' : 'robin_home_tour_detail_sign_via_email_button_click';

        setState({isLoading:true, emailPeadSent: null});

        if(tour.id){
            try {
                const email = getEmailForPead(user, isAgent, isLO);
                await benoitApi.sendPeadSignatureReminder(tour.id, {email});
                setState({isLoading:false, emailPeadSent: {error: false}});

            } catch (err){
                Logger.error('fail to send the Pead Signature email', err.message);
                const emailPeadSent = ( err.message === 'Request failed with status code 500') ? {error: false} : {error: true};
                setState({isLoading:false, emailPeadSent});
            }
        }


        analytics.eventTrack(eventTrack, {
            source: 'home_tours_detail_documents_tab',
        });
    }

    function sendPeadEmail() {
        handlerPeadEmail();
    }

    function reSendPeadEmail() {
        handlerPeadEmail(true);
    }
    const emailToPead = getEmailForPead(user, isAgent, isLO);

    return (
        <div
            style={{
                backgroundColor: '#fff',
                borderRadius: '4px',
                paddingTop: isIPadUp && theme.spacing(4),
                paddingBottom: isIPadUp && theme.spacing(4),
                paddingLeft: isIPadUp && theme.spacing(5),
                paddingRight: isIPadUp && theme.spacing(5),
            }}
            {...props}
        >
            <Typography variant="h5" align="center">
                COVID-19 Entrant{isMobile ? <br /> : ' '}Release & Waiver
            </Typography>
            <Typography variant="body2" align="center" style={{ color: theme.palette.lr_colors.steak_sauce }}>
                Required for all in-person tours.
            </Typography>

            <div
                style={{
                    padding: theme.spacing(3.5),
                    backgroundColor: theme.palette.lr_colors.costa_mesa,
                    maxWidth: isIPadUp && '328px',
                    margin: isIPadUp && '0 auto',
                    marginTop: theme.spacing(3),
                    borderRadius: '8px',
                }}
            >
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <DescriptionRounded style={{ width: '43px', height: '54px' }} />
                    </Grid>
                    <Grid item>
                        {(!state.isLoading && !state.emailPeadSent && !peadEmailSent &&  !userHasSignedPEADForm) && (
                            <Tooltip title={`Please contact ${user.affiliate.name} if you'd like to see this document.`} placement={'top'} disableHoverListener={!isLO}>
                                <span>
                                    <LRButton
                                        variant="contained"
                                        color="primary"
                                        disabled={isLO}
                                        onClick={sendPeadEmail}
                                    >
                                    Sign via email
                                    </LRButton>
                                </span>
                            </Tooltip>
                        )}

                        {state.isLoading && <CircularProgress color="primary" size={24} />}

                        {(!state.isLoading && (state.emailPeadSent || peadEmailSent || userHasSignedPEADForm)) && (
                            <>
                                <div style={{textAlign: 'center', width: '100%'}}>
                                    {state.emailPeadSent?.error? <ErrorIcon color="primary"  size={20}/> : <CheckCircleIcon color="secondary"  size={20}/>}
                                </div>
                                <Typography variant="h6" align="center">
                                    {state.emailPeadSent?.error ? 'Email Sent Failed' : (userHasSignedPEADForm) ? 'Signed' : 'Email Sent'}
                                </Typography>

                                {!userHasSignedPEADForm && (
                                    <div align="center" width="100%" style={{textAlign: 'center', width: '100%'}}>
                                        <LRLink to="#" onClick={reSendPeadEmail} color="primary" style={{fontSize: '10px', textDecoration: 'underline'}}>Resend</LRLink>
                                    </div>
                                )}
                            </>
                        )}

                    </Grid>
                </Grid>


                {userHasSignedPEADForm
                    ? (
                        <p style={{marginTop: theme.spacing(2), fontSize: theme.typography.body4.fontSize, textAlign: 'center'}}> A copy of your signed form is available in your <a href="https://app.hellosign.com" target="_blank" color="primary" style={{textDecoration: 'underline'}}>HelloSign</a>  account.</p>
                    )
                    : (
                        <p style={{marginTop: theme.spacing(2), fontSize: theme.typography.body4.fontSize}}> An email from <b> Robin | HelloSign </b> will be sent to your email address: <b>{emailToPead}</b>. You can review and sign this form directly from this email.</p>
                    )
                }
            </div>

            <div
                style={{
                    maxWidth: isIPadUp && '328px',
                    margin: isIPadUp && '0 auto',
                    marginTop: theme.spacing(3),
                }}
            >
                <Grid container spacing={2}>
                    <Grid item container alignItems="center" spacing={1} xs={6} wrap="nowrap">
                        <Grid item>
                            <LRAvatar user={user.affiliate} size="small" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '-2px' }}>
                                {isAgent && 'You'}
                                {!isAgent && `${user?.affiliate.first_name} ${user?.affiliate?.last_name ? user.affiliate.last_name.split('')[0].toUpperCase() + '.' : ''}`.trim()}
                            </Typography>
                            <Typography variant="body4" style={{ ...theme.typography.body4, fontWeight: 'bold', color: agentHasSigned ? theme.palette.lr_colors.minty_fresh : theme.palette.lr_colors.brick }}>
                                {agentHasSigned ? 'Signed' : `Need${isAgent ? '' : 's'} to sign`}
                            </Typography>
                        </Grid>
                    </Grid>

                    {(<Grid item container alignItems="center" spacing={1} xs={6} wrap="nowrap">
                        <Grid item>
                            <LRAvatar user={tour?.user} size="small" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '-2px' }}>
                                {!isAgent && !isLO && user.id === tour?.userId ? (
                                    'You'
                                ) : (
                                    `${tour?.user.first_name} ${tour?.user?.last_name ? tour.user.last_name.split('')[0].toUpperCase() + '.' : ''}`.trim()
                                )}
                            </Typography>
                            <Typography variant="body4" style={{ ...theme.typography.body4, fontWeight: 'bold', color: creatorHasSigned ? theme.palette.lr_colors.minty_fresh : theme.palette.lr_colors.brick }}>
                                {creatorHasSigned ? 'Signed' : `Need${!isAgent && !isLO && user.id === tour?.userId ? '' : 's'} to sign`}
                            </Typography>
                        </Grid>
                    </Grid>)}

                    {!!tour?.content?.participants_joining?.length && tour?.content?.participants_joining.map((participant)=> {
                        const hasSigned = (tour?.content?.pead_signatures || {})[participant.email];

                        return (
                            <Grid item container alignItems="center" spacing={1} xs={6} wrap="nowrap">
                                <Grid item>
                                    <LRAvatar user={participant} size="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '-2px' }}>
                                        {!isAgent && !isLO && user.id === participant.userId ? (
                                            'You'
                                        ) : (
                                            `${participant.first_name} ${participant?.last_name ? participant.last_name.split('')[0] + '.' : ''}`.trim()
                                        )}
                                    </Typography>
                                    <Typography variant="body4" style={{ ...theme.typography.body4, fontWeight: 'bold', color: hasSigned ? theme.palette.lr_colors.minty_fresh : theme.palette.lr_colors.brick }}>
                                        {hasSigned ? 'Signed' : `Need${!isAgent && !isLO && user.id === participant.userId ? '' : 's'} to sign`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </div>
    );
});
