import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import MobileCompare from './MobileCompare';
import DesktopCompare from './DesktopCompare';
import analytics from '../../services/analytics';
import { useCompareNowStore, CompareNowStoreProvider } from './store';
import { BoardCardModal } from '../../scenes/BoardCardModal';
import { Route, useRouteMatch } from 'react-router-dom';
import BoardListingModal from 'scenes/BoardListingModal';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import Logger from 'js-logger';

const CompareNow = observer(({
    categories = [],
    cardsByCategories = {},
    closeLabel,
    alwaysLockedCards,
    lockCards,
    source,
    allLabel= 'View All',
    filter = '0',
    disableListingRoutes = false,
    onClose = (e)=> {},
})=> {
    const history = useHistory();
    const { UserStore, boardsStore } = useStore();
    const { user } = UserStore;
    const { activeBoard } = boardsStore;
    const boardPermissions = activeBoard?.userRoleOnBoard;
    const match = useRouteMatch();
    const { isIPadDown } = useDeviceBreakpoints();
    const store = useCompareNowStore(
        isIPadDown ? 2 : 4,
        categories,
        cardsByCategories,
        alwaysLockedCards,
        lockCards,
        filter,
    );

    // UseEffects
    useEffect(()=> {
        analytics.eventTrack('robin_compare_page_view', { role: boardPermissions?.label });
    }, [user, boardPermissions]);

    // Functions
    function onBoardCardModalClose() {
        history.push(match.path);
    }

    return (
        <>
            {!disableListingRoutes && match && (
                <>
                    <Route
                        path={`${match.path}/cards/:cardId/:address`}
                        children={({ match: routeMatch })=> (
                            <BoardCardModal open={Boolean(routeMatch)} onClose={onBoardCardModalClose} />
                        )}
                    />
                    <Route
                        path={`${match.path}/listings/:listingIds`}
                        render={()=> (
                            <BoardListingModal onClose={onBoardCardModalClose} />
                        )}
                    />
                </>
            )}

            <CompareNowStoreProvider compareNowStore={store}>
                {isIPadDown ? (
                    <MobileCompare handleOnClose={onClose} closeLabel={closeLabel} source={source} allLabel={allLabel}/>
                ) : (
                    <DesktopCompare handleOnClose={onClose} closeLabel={closeLabel} source={source} allLabel={allLabel}/>
                )}
            </CompareNowStoreProvider>
        </>
    );
});

export default withRouter(CompareNow);
