import React, { useEffect, useState }  from 'react';
import { useOnboarding } from '../../store';
import { observer } from 'mobx-react-lite';
import { delay, simulateClick, clearShepherdOverlay, restoreShepherdOverlay } from '../../utils';
import Confetti from '../../../Confetti';
import { useStore } from '../../../../mobx-store';
import { useHistory } from 'react-router-dom';
import { useResponsiveBreakpoint } from '../../../../hooks';

function createSteps(tour, { user, haveEnoughCards, isXs, onboarding }) {
    return !haveEnoughCards ? [
        {
            id: 'compare',
            title: 'Compare homes from here',
            text: 'Select Compare to see how homes on your board stack up against one another in detail.',
            attachTo: {
                element: '.compare-btn',
                on: 'bottom',
            },
            beforeShowPromise() {
                return delay(200); // Wait for left drawer to close
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_compare_onboarding_compare_click',
            }
        },
        {
            id: 'no-homes-dismissal',
            title: 'Compare multiple homes at once',
            text: 'It looks like you have fewer than two homes on your board (in Interested, Want to see, and Want to buy). Add more from a saved search or through the add home button.',
            beforeShowPromise() {
                return delay(200); // Wait for left drawer to close
            },
            buttons: [
                {
                    text: 'I\'ll do it later',
                    action: function() {
                        onboarding.stop();
                    },
                }
            ],
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_compare_onboarding_no_home_dismiss',
            },
            classes: 'shepherd-wide-tooltip'
        }
    ]
        : [
            {
                id: 'compare',
                title: 'Compare homes from here',
                text: 'Select Compare to see how homes on your board stack up against one another in detail.',
                attachTo: {
                    element: '.compare-btn',
                    on: 'bottom',
                },
                beforeShowPromise() {
                    return delay(200); // Wait for left drawer to close
                },
                advanceOn: {
                    selector: '.compare-btn',
                    event: 'click'
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                },
                modalOverlayOpeningRadius: 4,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_compare_onboarding_compare_click',
                },
            },
            ...(isXs ? [
                {
                    id: 'waitforit',
                    classes: 'hidden',
                    beforeShowPromise() {
                        tour.options.useModalOverlay = false;
                        return Promise.resolve();
                    },
                    analytics: {
                        nextEvent: 'robin_compare_onboarding_complete',
                    },
                }
            ] : [
                {
                    id: 'close-page',
                    title: 'You\'re all set!',
                    text: 'You\'re all set! Let\'s head back to your board 🎉"',
                    attachTo: {
                        element: '.board-btn',
                        on: 'bottom',
                    },
                    advanceOn: {
                        selector: '.board-btn',
                        event: 'click'
                    },
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 12]
                                }
                            }
                        ]
                    },
                    cancelIcon: {
                        enabled: false,
                    },
                    beforeShowPromise() {
                        tour.options.useModalOverlay = true;
                        return delay(400);
                    },
                    when: {
                        show: ()=> {
                            tour.options.useModalOverlay = true;
                            clearShepherdOverlay();
                        },
                        hide: ()=> {
                            restoreShepherdOverlay();
                        },
                    }
                    // analytics: {
                    //     nextEvent: 'robin_compare_onboarding_complete',
                    // },
                }
            ])
        ];
}

export const CompareFlow = observer(function FirstRunFlowDesktop() {
    const { UserStore, boardsStore } = useStore();
    const onboarding = useOnboarding();
    const [showConfetti, setShowConfetti] = useState(false);
    const history = useHistory();
    const isXs = useResponsiveBreakpoint('xs');
    const { activeBoard } = boardsStore;
    let boardCards = activeBoard.notArchivedBoardCards.filter((boardCard)=> !boardCard.inHandselectedSearch);
    const haveEnoughCards = boardCards?.length > 1;
    useEffect(()=> {
        history.replace('/boards');
    }, [history]);

    useEffect(()=> {
        const steps = createSteps(onboarding.tour, { user: UserStore.user, haveEnoughCards, isXs, onboarding });
        onboarding.addSteps(steps);
        onboarding.start();
        return function() {
            onboarding.reset();
        };
    }, [onboarding, UserStore]);

    useEffect(()=> {
        let timeout = null;
        function handleComplete() {
            setShowConfetti(true);
            UserStore.completeCompareOnboarding();



            simulateClick(isXs ? '.tour-target-mobile-footer-agent-toggle' : '.tour-target-leftsidebar-toggle');
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        }

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding, UserStore]);

    if(!showConfetti) {
        return null;
    }

    return <Confetti iterationCount={2}/>;
});
