import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Tour3DIcon } from '../../LRIcons';
import { COLOR_CREST_WHITE } from '../../../constants';

export const Styled3DTourLabel = styled(Typography)`
    color: ${COLOR_CREST_WHITE};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const StyledTour3DIcon = styled((props)=>
    <Tour3DIcon {...props} />
)`
    position: absolute;
    width: 11.69%;
    height: 19.8%;
    left: 48.5%;
    top: 22.86%;
    transform: translate(-50%, -50%);
`;

export const Container = styled(Box)`
    ${({ background })=> css`
    background: ${background};
    background-size: cover;
    background-position: center;
    `}

    display: flex;
    position: relative;
`;
