import React, { useMemo } from 'react';
import lodashSome from 'lodash/some';
import { Box, Typography, withTheme } from '@material-ui/core';
import moment from 'moment';
import LRLink from '../../LRLink';
import styled from 'styled-components';
import formatPhoneNumber from 'services/format-phone-number';
import he from 'he';
import Address from 'lana';
import { useFairHousingData } from 'hooks/data/useFairHousingData';
import { useListingRealtor } from 'hooks/data/listing/useListingRealtor';
import { useMLSComplianceByListingId } from 'hooks/useBoardListpacks';
import { useListingsSourceDetails } from 'hooks/useListingsDetails';
import { useListingMLSByZipcode } from 'hooks/data/listing/useListingMLS';
import Logger from 'js-logger';
import theme from 'theme';
import { COLOR_DISCLAIMER_GREY } from '../../../constants';

const StyledLink = withTheme(styled(LRLink)`
    color: ${COLOR_DISCLAIMER_GREY};
    text-decoration: underline;
`);

const TermsAndConditions = React.memo(function TermsAndConditions({ listing, buyerAgent }) {
    const { data: fairHousing } = useFairHousingData();
    const { data: realtor, isLoading } = useListingRealtor(listing);
    const { complianceText } = useMLSComplianceByListingId(listing?.id, buyerAgent?.id);
    const listingDate = listing ? moment(listing.listing_date || listing.last_updated_at).format('MM/DD/YY \\at hh:mmA') : null;
    const address = listing ? new Address(listing.address || listing.listing_details?.address) : null;
    const state = address?.state?.toLowerCase();
    const { data: listingSourceDetails } = useListingsSourceDetails(listing?.listing_source, listing?.mls_id);
    const { data: mlses, isLoading: isLoadingMLSes } = useListingMLSByZipcode(listing);
    const hideAgent = useMemo(()=> {
        return lodashSome(mlses, 'hide_listing_agent_from_detail_page');
    }, [mlses]);
    const lastUpdatedAtDate = useMemo(()=> {
        return listingSourceDetails?.updatedAt ? moment(listingSourceDetails.updatedAt)
            .format('MM/DD/YY \\at h:mmA') : null;
    }, [listingSourceDetails]);

    if (!listing) {
        return null;
    }

    return (
        <>
            <Box fontSize="caption.fontSize" color={COLOR_DISCLAIMER_GREY} pb={1} textAlign="center">
                Last Updated: {lastUpdatedAtDate || listingDate}
            </Box>
            {!isLoading && !isLoadingMLSes && (
                <Box fontSize="caption.fontSize" color={COLOR_DISCLAIMER_GREY} py={1} textAlign="center">
                    Listing provided by {hideAgent
                        ? realtor?.company
                        : <>
                            {realtor?.first_name} {realtor?.last_name}{' '}
                            {realtor?.license_number && `| License #${realtor?.license_number} `}
                            {realtor?.company && `| ${realtor?.company} `}
                            {realtor?.phone && `| ${formatPhoneNumber(realtor?.phone)} `}
                            {realtor?.email && `| ${realtor?.email}`}
                        </>
                    }
                </Box>
            )}
            {listing.listing_source === 'listhub' && (
                <Box fontSize="caption.fontSize" color={COLOR_DISCLAIMER_GREY} py={1} textAlign="center">
                    This listing information and property photos displayed on this
                    page were derived from third-party data sources and were not
                    licensed directly from the local MLS serving this area.
                </Box>
            )}
            {complianceText && listing.listing_source !== 'listhub' && (
                <>
                    {complianceText?.logo &&
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <img src={complianceText.logo} alt="logo" />
                        </Box>
                    }

                    {complianceText?.listingDetailsCompliance ? (
                        <Box
                            fontSize="caption.fontSize"
                            color={COLOR_DISCLAIMER_GREY}
                            py={1}
                            dangerouslySetInnerHTML={{ __html: complianceText.listingDetailsCompliance }}
                        ></Box>
                    ) : (
                        <Box fontSize="caption.fontSize" color={COLOR_DISCLAIMER_GREY} py={1} textAlign="center">
                            {listing.disclaimer && he.decode(listing.disclaimer)}
                            {!listing.disclaimer && (
                                <Box>
                                    All data, including all measurements and calculations of area, is obtained from
                                    various sources and has not been, and will not be, verified by broker or MLS. All
                                    information should be independently reviewed and verified for accuracy. Properties
                                    may or may not be listed by the office/agent presenting the information.
                                </Box>
                            )}
                        </Box>
                    )}
                </>
            )}

            <Box fontSize="caption.fontSize" color={COLOR_DISCLAIMER_GREY} py={1} textAlign="center">
                {`©2015-${moment().year()} ListReports, Inc. Visit our `}
                <StyledLink native target="_blank" href="https://help.listreports.com/knowledge-base/robin-faq">
                    FAQs
                </StyledLink>{' '}
                to learn more. Access to this site is governed by the ListReports{' '}
                <StyledLink native target="_blank" href="https://welcome.listreports.com/terms-conditions">
                    Terms of Service
                </StyledLink>{' '}
                and{' '}
                <StyledLink native target="_blank" href="https://welcome.listreports.com/privacy-policy">
                    Privacy Policy
                </StyledLink>{' '}
                . If you are using a screen reader or having trouble reading this website, please email ListReports
                Customer Support at support@listreports.com.
                {fairHousing && fairHousing.hasOwnProperty(state) && (
                    <Box ml={1 / 2} display="inline">
                        &
                        <Box ml={1 / 2} display="inline">
                            <StyledLink native target="_blank" href={fairHousing[state].compliance_url}>
                                Fair Housing
                            </StyledLink>
                        </Box>
                    </Box>
                )}
            </Box>

            <Typography
                component="p"
                variant="caption"
                align="center"
                style={{
                    marginTop: theme.spacing(1),
                    color: {COLOR_DISCLAIMER_GREY},
                }}
            >
                Robin is powered by ListReports.
            </Typography>

            <Box pt={2} width="100%" display="flex" justifyContent="center">
                <Box mr={6}>
                    <img src="/images/logo.png" alt="lr_logo" height={24} style={{ objectFit: 'contain' }} />
                </Box>
                <Box>
                    <img
                        src="/images/equal-housing-logo.png"
                        alt="equal-housing-logo"
                        height={24}
                        style={{ objectFit: 'contain' }}
                    />
                </Box>
            </Box>
        </>
    );
});

export default TermsAndConditions;
