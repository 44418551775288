import React, { useState, useEffect } from 'react';
import lodashGet from 'lodash/get';
import styled from 'styled-components';
import Logger from 'js-logger';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Box, CircularProgress, InputLabel, InputBase, Typography, } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { COLOR_GREY_DARK, COLOR_GREY_NERO } from '../../../constants';
import { useValidationForm2 } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import { withTheme } from '@material-ui/core/styles';
import LRConfirmationDialog from '../../../components/LRConfirmationDialog';
import LRButton from '../../LRButton';
import analytics from '../../../services/analytics';

const logger = Logger.get('LeadForm');

const Label = styled(InputLabel)`
    font-size: 20px;
    color: #1a1a1a;
    letter-spacing: 0.15px;
    line-height: 24px;
`;

const Input = styled(InputBase)`
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    padding: 7px 15px;
    width: 100%;
    input {
        position: relative;
        color: #000;
    }

    &.text-right {
        input {
            text-align: right;
        }
    }
`;

const PhoneInput = styled(Input)`
    input {
        color: ${(props)=> (props.placeholder === 'true' ? COLOR_GREY_DARK : 'black')};
    }
`;

const DisclaimerLink = styled.a`
    color: ${COLOR_GREY_DARK};
    text-decoration: none;
    font-weight: 700;
`;

const CustomLRButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme, color })=> theme.palette[color].main};
        color: ${({ theme, color })=> theme.palette[color].contrastText};
        opacity: 0.5;
    }
`);

function validateLead(lead, report) {
    if(!lead || !report) return null;
    if(
        !lead.archived &&
        lead.agentId === lodashGet(report, 'agent.agent_user_id')
    ) {
        return lead;
    }
    return null;
}

function LeadForm({
    formTitle,
    handleSubmit,
    trackData={},
}) {
    const { LrLiveStore } = useStore();
    const {
        lead,
        lrLive,
        formattedStartTime,
        expiredOpenHouse,
        processingRegistration,
        startTime: startTimeISO,
    } = LrLiveStore;
    const { report } = lrLive || {};

    const {
        start_date: startDate,
        start_time: startTime,
        end_time: endTime,
        start_time_24h: startTime24H,
        end_time_24h: endTime24H,
    } = formattedStartTime;

    const validatedLead = validateLead(lead, report);
    const { focus } = queryString.parse(useLocation().search);
    const { address, agent, comarketer, id: reportId } = report;
    const [formStatus, formData] = useValidationForm2(
        {
            name: lodashGet(validatedLead, 'applicant.name') || '',
            email: lodashGet(validatedLead, 'applicant.email') || '',
            phone: lodashGet(validatedLead, 'applicant.phone') || '',
        },
        {
            name: ['required'],
            email: ['required', 'email'],
            phone: [ /^((\([0-9]{3}\) |[0-9]{3}-?)[0-9]{3}-?[0-9]{4})?$/],
        }
    );
    const { name, email, phone } = formData;

    const [filledForm, setFilledForm] = useState(false);
    const [/* showPlaceholder */, setShowPlaceholder] = useState(!phone.inputProps.value.replace(/[^0-9]+/g, ''));
    const [submitted, setSubmitted] = useState(false);
    const [registeredSuccessfully, setRegisteredSuccessfully] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    useEffect(
        function() {
            const filledInputs =
                name.inputProps.value && email.inputProps.value;

            if(filledInputs !== filledForm) {
                setFilledForm(filledInputs);
            }

            setShowPlaceholder(!phone.inputProps.value.replace(/[^0-9]+/g, ''));
        },
        [name.inputProps.value, email.inputProps.value, phone.inputProps.value, filledForm]
    );

    useEffect(
        function() {
            if(!focus) {
                window.scrollTo({ top: 0 });
            }
        },
        [focus]
    );

    const onSubmit = function(event) {
        try {
            event.preventDefault();
            setSubmitted(true);

            const agentUserId = lodashGet(lrLive, 'userId') || lodashGet(agent, 'agent_user_id', null);
            if(!agentUserId) throw new Error('Report missing agent user id');

            const vendorId = (report.owner && report.owner.vendorId)
                ? report.owner.vendorId
                : report.owner && report.owner.preferred_vendor && report.owner.preferred_vendor.vendorId
                    ? report.owner.preferred_vendor.vendorId
                    : undefined;

            const [ first_name='', last_name='' ] = (name.inputProps.value || '').split(' ');

            if(formStatus.isValid) {
                const validatedLeadMeta = lodashGet(validatedLead, 'meta', {});
                let data = {
                    applicant: {
                        name: name.inputProps.value,
                        email: email.inputProps.value,
                        // Remove InputMask added characters
                        phone: phone.inputProps.value.replace(/[^0-9]+/g, ''),
                        first_name,
                        last_name,
                    },
                    meta: {
                        ...validatedLeadMeta,
                        address: report.address,
                        digital_open_house_start_time_iso: startTimeISO,
                        digital_open_house_start_time: startTime,
                        digital_open_house_start_time_24h: startTime24H,
                        digital_open_house_end_time: endTime,
                        digital_open_house_end_time_24h: endTime24H,
                        digital_open_house_start_date: startDate,
                        digital_open_house_expired: expiredOpenHouse,
                        lrLiveId: lodashGet(lrLive, 'id'),
                    },
                    type: 'realtor',
                    source: lodashGet(validatedLead, 'source') || 'Digital Open House',
                    agentId: agentUserId,
                    ownerId: agentUserId,
                    vendorRepId: comarketer ? comarketer.vendor_user_id : null,
                    vendorId,
                    reportId,
                };

                if(validatedLead && validatedLead.id) {
                    // the existence of the id property will
                    // determine if we create or update the lead
                    data.id = validatedLead.id;
                }

                analytics.eventTrack('lrlive_event_registration_submit', {
                    'current url': window.location ? window.location.href : null,
                    ...trackData,
                });
                return handleSubmit(event, data)
                    .then(function (response) {
                        if(response) {
                            setRegisteredSuccessfully(true);
                        } else {
                            setOpenErrorModal(true);
                        }
                    });
            }
        } catch (err) {
            setSubmitted(false);
            logger.error(err.message);
            setOpenErrorModal(true);
            return null;
        }
    };

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                pl={[3, 4]}
                pr={[3, 4]}
                py={3}
            >
                {!registeredSuccessfully && (
                    <form onSubmit={onSubmit}>
                        <Box mt={[1, 0]}
                            mb={3}
                        >
                            <Box fontSize={24} color={COLOR_GREY_NERO} letterSpacing={0.15}>
                                {formTitle || 'Registration'}
                            </Box>
                        </Box>
                        <Box width={1} mb={3}>
                            <Box mb="16px">
                                <Label>Name*</Label>
                            </Box>
                            <Box>
                                <Input
                                    autoFocus={focus ? focus.toLowerCase() === 'true' : false}
                                    name="name"
                                    {...name.inputProps}
                                    error={submitted && !name.isValid}
                                />
                                {submitted && !name.isValid && (
                                    <Box fontSize={14} color="error.main" my="4px">
                                        Please enter a name
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box width={1} mb={3}>
                            <Box mb={2}>
                                <Label>Email*</Label>
                            </Box>
                            <Box width={1}>
                                <Input
                                    name="email"
                                    error={submitted && !email.isValid}
                                    {...email.inputProps}
                                    type="email"
                                />
                            </Box>
                            {submitted && !email.isValid && (
                                <Box fontSize={14} color="error.main" my="4px">
                                    Please enter a valid email number
                                </Box>
                            )}
                        </Box>

                        <Box mb={3}>
                            <Box mb={2}>
                                <Label>Phone</Label>
                            </Box>
                            <Box>
                                <InputMask
                                    mask="999-999-9999"
                                    maskChar=""
                                    alwaysShowMask={false}
                                    {...phone.inputProps}
                                >
                                    {(inputProps)=> (
                                        <PhoneInput
                                            type="tel"
                                            error={submitted && !phone.isValid}
                                            {...inputProps}
                                        />
                                    )}
                                </InputMask>
                            </Box>
                            {submitted && !phone.isValid && (
                                <Box fontSize={14} color="error.main" my="4px">
                                    Please enter a valid phone number
                                </Box>
                            )}
                        </Box>

                        <Box>
                            <CustomLRButton
                                disabled={!formStatus.isValid || registeredSuccessfully || processingRegistration}
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{
                                    width: '100%',
                                    height: '48px',
                                    fontSize: '16px',
                                    'minWidth': '114px',
                                }}
                            >
                                {expiredOpenHouse ? 'Contact agent' : 'Register now'}
                                {processingRegistration && (
                                    <Box display="inline"
                                        mt={1/2}
                                        ml={1}
                                    >
                                        <CircularProgress color="inherit" size={16} />
                                    </Box>
                                )}
                            </CustomLRButton>
                        </Box>

                        <Box color={COLOR_GREY_DARK}
                            py={1}
                        >
                            <Typography variant="caption">
                                Your use of ListReports Live is subject to the ListReports&nbsp;
                                <DisclaimerLink href="https://welcome.listreports.com/acceptable-use"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Acceptable Use Policy</DisclaimerLink>,&nbsp;
                                <DisclaimerLink href="https://welcome.listreports.com/terms-conditions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Terms of Service</DisclaimerLink>, and&nbsp;
                                <DisclaimerLink href="https://welcome.listreports.com/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Privacy Policy</DisclaimerLink>.
                            </Typography>
                        </Box>
                    </form>
                )}

                {registeredSuccessfully && (
                    <Box pt={2}>
                        <Box fontSize={24}
                            color={COLOR_GREY_NERO}
                            letterSpacing={0.15}
                            mb={2}
                        >
                            {expiredOpenHouse ? 'Thanks!' : 'Thank you for registering!'}
                        </Box>

                        {!expiredOpenHouse && (
                            <Box>
                                <Box mb={2}>
                                    We'll send you an email with details on how to join
                                    the video experience and a reminder
                                    before the event. See you soon!
                                </Box>

                                <Box mb={1}>
                                    <b>Digital open house: {address}</b>
                                </Box>
                                <Box  mb={1}>
                                    Date: <b>{startDate}</b>
                                </Box>
                                <Box  mb={1}>
                                    Time: <b>{startTime}</b>
                                </Box>
                            </Box>
                        )}

                        {expiredOpenHouse && (
                            <Box>
                                We've passed your information on to {agent.name}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>


            {openErrorModal && (
                <LRConfirmationDialog
                    title="Sorry, something went wrong! Please try again."
                    okButton={{ label: 'OK' }}
                    open={openErrorModal}
                    onClose={()=> {
                        setSubmitted(false);
                        setOpenErrorModal(false);
                    }}
                ></LRConfirmationDialog>
            )}
        </Box>
    );
}

export default LeadForm;
