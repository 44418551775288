import React from 'react';
import LRModal from '../LRModal';
import { CardBoardCollaborations } from '../CardBoardCollaborations';
import { IconButton, withTheme } from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import styled from 'styled-components';

const StyledModal = withTheme(styled(LRModal)`
    & .BoardCollaborationsModal-DialogContent-root {
        ${({ theme })=> theme.breakpoints.only('xs')} {
            height: 100%;
        }
    }
`);

const BackBtn = styled(IconButton)`
    padding: 8px;
`;

function BackButton({ onClick }){
    return (
        <BackBtn onClick={onClick}>
            <ChevronLeft />
        </BackBtn>
    );
}

export function CardBoardCollaborationsModal({ open, boardId, cardId, onClose, ...props}){
    return (
        <StyledModal open={open} fullWidth onClose={onClose} dialogContentClasses={{ root: 'BoardCollaborationsModal-DialogContent-root' }}>
            <CardBoardCollaborations HeaderLeftContent={<BackButton onClick={onClose}/>} cardId={cardId} onBack={onClose} />
        </StyledModal>
    );
}