import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LRButton from '../../../components/LRButton';
import FullscreenImageGalleryModal from '../../../components/FullscreenImageGalleryModal';
import { observer } from 'mobx-react-lite';
import { ImagesIcon } from '../../../components/LRIcons';

function ViewImagesButton({
    listing,
    photos = [],
    variant = 'contained',
    show,
    selectedPhotoIndex,
    onGalleryOpen,
    onGalleryClose,
    requestSource,
    reportId,
    ...otherProps
}) {
    const [showImageGallery, setShowImageGallery] = useState(false);

    if(typeof show !== 'undefined' && !showImageGallery && showImageGallery !== show) setShowImageGallery(show);

    function handleShowGalleryClick(e) {
        if(photos.length) {
            setShowImageGallery(true);
            if(onGalleryOpen) onGalleryOpen();
        }
    }

    function handleOnGalleryClose(e) {
        setShowImageGallery(false);
        if(onGalleryClose) onGalleryClose();
    }

    return (
        <>
            <LRButton variant={variant} onClick={handleShowGalleryClick} {...otherProps}>
                {`${photos.length > 0 ? (selectedPhotoIndex + 1) : 0} of ${photos.length}`}
            </LRButton>

            {showImageGallery && (
                <FullscreenImageGalleryModal
                    listing={listing}
                    photos={photos}
                    onClose={handleOnGalleryClose}
                    requestSource={requestSource}
                    selectedPhotoIndex={selectedPhotoIndex}
                    reportId={reportId}
                />
            )}
        </>
    );
}
ViewImagesButton.propTypes = {
    listing: PropTypes.object,
    photos: PropTypes.array.isRequired,
    open: PropTypes.bool,
    onGalleryOpen: PropTypes.func,
    onGalleryClose: PropTypes.func,
};

export default React.memo(observer(ViewImagesButton));
