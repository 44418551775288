import React, { useEffect } from 'react';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useStore } from '../../mobx-store';
import { COLOR_GREY_LIGHT } from '../../constants';
import queryString from 'query-string';
import DigitalOpenHouseRegistration from './DigitalOpenHouseRegistration';
import SponsorPreRoll from './SponsorPreRoll';
import PostRoll from './PostRoll';

function DigitalOpenHouse() {
    const { LrLiveStore } = useStore();
    const params = useParams();
    const { id } = params;
    const location = useLocation();
    const { startTime, leadId } = queryString.parse(location.search);

    useEffect(
        function() {
            if(LrLiveStore && id && id.length) {
                LrLiveStore.fetchLrLive(id);
                LrLiveStore.fetchSponsor(id);
            }
        },
        [LrLiveStore, id]
    );

    useEffect(
        function() {
            if(LrLiveStore && leadId) {
                LrLiveStore.fetchLead(leadId);
            }
        },
        [LrLiveStore, leadId]
    );

    useEffect(
        function() {
            if(LrLiveStore && startTime) {
                LrLiveStore.setStartTime(startTime);
            }
        },
        [LrLiveStore, startTime]
    );

    return (
        <Box height="100%" width="100%" bgcolor={COLOR_GREY_LIGHT}>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
                height="100%"
                m="0 auto"
            >
                <Switch>
                    <Route path={'/lrlive/:id/sponsor-pre-roll'} component={SponsorPreRoll} />
                    <Route path={'/lrlive/:id/thank-you'} component={PostRoll} />
                    <Route path={'/lrlive/:id/:addressSlug'} component={DigitalOpenHouseRegistration} />
                </Switch>
            </Box>
        </Box>
    );
}

export default DigitalOpenHouse;
