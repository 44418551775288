import { InputBase, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import shortid from 'shortid';
import { LRFormControl } from './LRFormControl';
import PropTypes from 'prop-types';
import { refType } from '@material-ui/utils';

export const LRInputOutlined = React.forwardRef(function LRInputOutlined(
    {
        name,
        value,
        label,
        placeholder,
        error,
        autoComplete,
        autoFocus,
        helperText,
        startAdornment,
        endAdornment,
        required,
        inputProps = {},
        InputProps = {},
        fullWidth = true,
        onChange = (value)=> {},
        ...props
    },
    ref
) {
    const theme = useTheme();
    const INPUTBASE_STYLE = useMemo(()=> {
        const styles = {
            border: '1px solid',
            borderColor: theme.palette.lr_colors.border_grey,
            backgroundColor: '#fff',
            borderRadius: 4,
            position: 'relative',
            height: !InputProps.multiline && 48,
            ...(InputProps.style || {}),
        };

        if(error) {
            styles.borderColor = theme.palette.lr_colors.brick;
        }

        return styles;
    }, [InputProps.style, error]);

    const uniqueId = useMemo(()=> shortid.generate(), []);
    return (
        <LRFormControl
            uniqueId={uniqueId}
            label={label}
            error={error}
            fullWidth={fullWidth}
            required={required}
            helperText={helperText}
            {...props}
        >
            <InputBase
                value={value}
                name={name}
                required={required}
                error={error}
                id={uniqueId}
                inputRef={ref}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                inputProps={{
                    placeholder,
                    ...inputProps,
                    style: {
                        ...theme.typography.body1,
                        padding: 14,
                        height: 20,
                        ...(inputProps.style || {}),
                    },
                }}
                fullWidth={fullWidth}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                {...InputProps}
                style={INPUTBASE_STYLE}
                onChange={onChange}
            />
        </LRFormControl>
    );
});

LRInputOutlined.propTypes = {
    /**
     * The label content.
     */
    label: PropTypes.string,


    // Material UI Autocomplete Props Below

    /**
    * @ignore
    */
    'aria-describedby': PropTypes.string,
    /**
    * This prop helps users to fill forms faster, especially on mobile devices.
    * The name can be confusing, as it's more like an autofill.
    * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
    */
    autoComplete: PropTypes.string,
    /**
    * If `true`, the `input` element will be focused during the first mount.
    */
    autoFocus: PropTypes.bool,
    /**
    * Override or extend the styles applied to the component.
    * See [CSS API](#css) below for more details.
    */
    classes: PropTypes.object,
    /**
    * @ignore
    */
    className: PropTypes.string,
    /**
    * The color of the component. It supports those theme colors that make sense for this component.
    */
    color: PropTypes.oneOf(['primary', 'secondary']),
    /**
    * The default `input` element value. Use when the component is not controlled.
    */
    defaultValue: PropTypes.any,
    /**
    * If `true`, the `input` element will be disabled.
    */
    disabled: PropTypes.bool,
    /**
    * End `InputAdornment` for this component.
    */
    endAdornment: PropTypes.node,
    /**
    * If `true`, the input will indicate an error. This is normally obtained via context from
    * FormControl.
    */
    error: PropTypes.bool,
    /**
    * If `true`, the input will take up the full width of its container.
    */
    fullWidth: PropTypes.bool,
    /**
    * The id of the `input` element.
    */
    id: PropTypes.string,
    /**
    * The component used for the `input` element.
    * Either a string to use a HTML element or a component.
    */
    inputComponent: PropTypes.elementType,
    /**
    * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
    */
    inputProps: PropTypes.object,
    /**
    * Pass a ref to the `input` element.
    */
    inputRef: refType,
    /**
    * If `dense`, will adjust vertical spacing. This is normally obtained via context from
    * FormControl.
    */
    margin: PropTypes.oneOf(['dense', 'none']),
    /**
    * Maximum number of rows to display when multiline option is set to true.
    */
    maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    * Minimum number of rows to display when multiline option is set to true.
    */
    minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    * If `true`, a textarea element will be rendered.
    */
    multiline: PropTypes.bool,
    /**
    * Name attribute of the `input` element.
    */
    name: PropTypes.string,
    /**
    * Callback fired when the input is blurred.
    *
    * Notice that the first argument (event) might be undefined.
    */
    onBlur: PropTypes.func,
    /**
    * Callback fired when the value is changed.
    *
    * @param {object} event The event source of the callback.
    * You can pull out the new value by accessing `event.target.value` (string).
    */
    onChange: PropTypes.func,
    /**
    * @ignore
    */
    onClick: PropTypes.func,
    /**
    * @ignore
    */
    onFocus: PropTypes.func,
    /**
    * @ignore
    */
    onKeyDown: PropTypes.func,
    /**
    * @ignore
    */
    onKeyUp: PropTypes.func,
    /**
    * The short hint displayed in the input before the user enters a value.
    */
    placeholder: PropTypes.string,
    /**
    * It prevents the user from changing the value of the field
    * (not from interacting with the field).
    */
    readOnly: PropTypes.bool,
    /**
    * @ignore
    */
    renderSuffix: PropTypes.func,
    /**
    * If `true`, the `input` element will be required.
    */
    required: PropTypes.bool,
    /**
    * Number of rows to display when multiline option is set to true.
    */
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    * Maximum number of rows to display.
    * @deprecated Use `maxRows` instead.
    */
    rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    * Minimum number of rows to display.
    * @deprecated Use `minRows` instead.
    */
    rowsMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    * Start `InputAdornment` for this component.
    */
    startAdornment: PropTypes.node,
    /**
    * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
    */
    type: PropTypes.string,
    /**
    * The value of the `input` element, required for a controlled component.
    */
    value: PropTypes.any,
};
