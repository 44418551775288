import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Lockr from 'lockr';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import { useGoogleAuth } from 'components/GoogleLogin';
import { Box, CircularProgress } from '@material-ui/core';
import { fromPairs } from 'lodash';
import Axios from 'axios';

export const OAuth = observer(function OAuth() {
    const history = useHistory();
    const { provider } = useParams();
    const { isSignedIn, googleUser, isInitialized } = useGoogleAuth();
    const { id_token:googleIdToken } = useMemo(()=> {
        return provider === 'google' && (
            fromPairs(window.location.hash
                .substr(1)
                .split('&')
                .map((a)=> a.split('=')))
        );
    }, [provider]);

    // UseEffects

    useEffect(()=> {
        if(provider !== 'google') return;

        if(isSignedIn) {
            const authResponse = googleUser.getAuthResponse();
            const basicProfile = googleUser.getBasicProfile();
            const userInfo = {
                email: basicProfile.getEmail() || undefined,
                first_name: basicProfile.getGivenName() || undefined,
                last_name: basicProfile.getFamilyName() || undefined,
                headshot: basicProfile.getImageUrl() || undefined,
                meta: {
                    auth_providers: {
                        google_user_id: basicProfile.getId(),
                    },
                },
            };
            const auth = { token: authResponse.id_token, method: 'google' };

            onLoginSuccess(userInfo, auth);
        } else if(isInitialized && googleIdToken) {
            /**
             * This is a fallback for safari because of the new
             * cookie policies which prevent the redirect flow
             * from working.
             */
            getGoogleUserInfoFromIdTokenAndRedirect(googleIdToken);
        }
    }, [googleUser, isSignedIn, provider]);

    // Functions

    /**
     * Handles the login redirect
     *
     * @param {Object} userInfo
     * @param {Object} auth
     */
    async function onLoginSuccess(userInfo, auth) {
        const redirectTo = '/' + (Lockr.get('thirdparty_auth_redirect_to') || '/boards')
            .replace('//', '')
            .split('/')
            .slice(1)
            .join('/');
        const authMethod = Lockr.get('thirdparty_auth_method');

        Lockr.rm('thirdparty_auth_redirect_to');
        Lockr.rm('thirdparty_auth_method');
        history.replace(redirectTo, {
            userInfo,
            auth,
            authMethod: authMethod,
        });
    }

    /**
     * This is used as a fallback method and most likely will only be used
     * on an In-App Browser (Facebook app, IG App). This will only allow email
     * to be fetched because thats all the info we can get in this fallback method.
     *
     * @param {String} idToken - The google id token
     */
    async function getGoogleUserInfoFromIdTokenAndRedirect(idToken) {
        const response = await Axios.get('https://www.googleapis.com/oauth2/v3/tokeninfo', { params: { id_token: idToken } });
        const userInfo = {
            email: response.data.email,
        };
        const auth = { token: googleIdToken, method: 'google' };

        onLoginSuccess(userInfo, auth);
    }

    return (
        <Box display="flex" width={1} height="100vh" alignItems="center" justifyContent="center">
            <CircularProgress size={80} color="secondary" />
        </Box>
    );
});
