import React from 'react';
import PropTypes from 'prop-types';
import { Box, GridList, GridListTile, GridListTileBar, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import PinnedByHeadshots from '../PinnedByHeadshots';
import GridTileActionMenu from '../GridTileActionMenu';
import { Can } from '../../Ability';

const useStyles = makeStyles((theme)=> ({
    tile: {
        borderRadius: '4px',
        cursor: 'pointer',
    },
    titleBar: {
        textAlign: 'left',
        background:
              'linear-gradient(180deg, rgba(56, 56, 56, 0.0001) 0%, #1A1A1A 100%)',
    },
    gridList: {
        maxWidth: '680px',
    },
}));

const StyledHeadshotsContainer = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
`;

function PinspirationList({ pinCollection = [],  showPinnedByHeadshots, onUnpinClick, onTileClick, className = '' }) {
    const theme = useTheme();
    const classes = useStyles();
    const gtXs = useMediaQuery(theme.breakpoints.up('sm'));
    const handleTileClick = (e, pinspiration)=> {
        if(e) e.stopPropagation();
        onTileClick(e, pinspiration);
    };

    return (
        <Box align="center" mt={[1,3]} px={[1, 3]}>
            <GridList className={classes.gridList}
                cellHeight={gtXs ? 460 : 280}
                spacing={16}
                cols={1}
            >
                {pinCollection.map((pinspiration, pinIndex)=> (
                    <GridListTile key={`pinspiration_list_tile_${pinIndex}`}
                        classes={{tile: classes.tile}}
                        onClick={(e)=> handleTileClick(e, pinspiration)}
                    >
                        <img src={pinspiration.photo} alt={pinspiration.title}/>
                        <GridListTileBar
                            title={pinspiration.title}
                            subtitle={pinspiration.subtitle}
                            classes={{root: classes.titleBar}}
                            actionIcon={
                                <Can I="edit" a="Pinspiration">
                                    <GridTileActionMenu
                                        pinspirationItem={pinspiration}
                                        onUnpinClick={onUnpinClick}
                                    />
                                </Can>
                            }
                        />

                        {showPinnedByHeadshots && (<StyledHeadshotsContainer>
                            <PinnedByHeadshots users={pinspiration.users} />
                        </StyledHeadshotsContainer>)}
                    </GridListTile>
                ))}
            </GridList>
        </Box>
    );
}

PinspirationList.propTypes = {
    pinCollection: PropTypes.array.isRequired,
    className: PropTypes.string,
};

export default React.memo(PinspirationList);
