import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { AutocompleteInput } from '../AutocompleteInput';
import { useDebounce, useResponsiveConfig } from '../../hooks';
import { portalApi } from '../../apis';
import axios from 'axios';

const CancelToken = axios.CancelToken;

// DEPRECATED - This should only be used in very specific situations. You most likely want to use LRAreaAutocompleteV2
export const LRAreaAutocomplete = React.forwardRef(function LRAreaAutocomplete({
    onSuggestionSelect,
    type,
    suggestionRenderOption,
    renderInput,
    clickOutsideExtension,
    errorTooltipContent,
    rootContainerProps = {},
    maxHeight,
    filterSuggestions,
    menuItemHoverColor,
    setAnchorElOnFocus= false,
    popperProps= {},
    ...props
},  ref) {
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchError, setSearchError] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const searchRef = useRef();
    const requestCancelSourceImage = useRef(null);
    const numberOfSuggestions = useResponsiveConfig({ xs: 5, md: 500 });

    useImperativeHandle(ref, ()=> {
        return {
            clearSearch: function() {
                setSearchTerm('');
            },
        };
    });

    useEffect(()=> {
        searchRef.current = async function search(searchTerm, requestOptions) {
            if(!searchTerm || searchTerm.length < 3) {
                return;
            }

            setIsLoading(true);
            setSearchError(false);
            let suggestions = await portalApi.queryAreas(searchTerm, undefined, 'city,zip,neighborhood,school', requestOptions);

            if(type) {
                suggestions = suggestions.filter((suggestion)=> {

                    return suggestion.type === type;
                });
            }

            if(!suggestions.length) {
                setSearchError(true);
            }

            if(filterSuggestions){
                suggestions = suggestions.filter(filterSuggestions);
            }

            suggestions = suggestions.slice(0, numberOfSuggestions);

            setSuggestions(suggestions);
            setIsLoading(false);
        };
    });

    function handleOnChange(searchTerm) {
        setSearchTerm(searchTerm);
    }

    useEffect(
        function() {
            if(requestCancelSourceImage.current) {
                requestCancelSourceImage.current.cancel();
            }

            if(debouncedSearchTerm) {
                requestCancelSourceImage.current = CancelToken.source();
                searchRef.current(debouncedSearchTerm, { cancelToken: requestCancelSourceImage.current.token });
            } else {
                setSuggestions([]);
                setIsLoading(false);
            }
        },
        [debouncedSearchTerm]
    );

    function clearSearch() {
        setSearchTerm('');
    };

    return (
        <AutocompleteInput
            setAnchorElOnFocus={setAnchorElOnFocus}
            value={searchTerm}
            isLoading={isLoading}
            suggestions={suggestions}
            onChange={handleOnChange}
            clearSearch={clearSearch}
            onSuggestionSelect={(suggestion)=> {
                setSearchTerm(suggestion.id);
                onSuggestionSelect(suggestion);
            }}
            error={searchError}
            errorTooltipContent={errorTooltipContent}
            // input={renderInput ? input : LRAreaAutocompleteInput}
            renderInput={renderInput}
            suggestionsMaxHeight={240}
            floatingSuggestions={true}
            clickOutsideExtension={clickOutsideExtension}
            rootContainerProps={rootContainerProps}
            renderSuggestion={
                suggestionRenderOption?
                    suggestionRenderOption :
                    null
            }
            menuItemHoverColor={menuItemHoverColor}
            popperProps={popperProps}
            {...props}
        />
    );
});
