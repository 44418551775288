import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import LRTabs from '../../components/LRTabs';
import LRTab from '../../components/LRTabs/LRTab';
import { COLOR_GREY, COLOR_GRADE } from '../../constants';
import LRModal from '../../components/LRModal';
import CloseIcon from '@material-ui/icons/Close';

import Settings from './Settings';
import Profile from './Profile';
import { Cobuyers } from './Cobuyers';
import LRButton from '../../components/LRButton';
import { observer } from 'mobx-react-lite';
import { useAccountSettingsStore, AccountSettingsStoreProvider, useAccountSettingsStoreContext } from './store';
import { useQuery, useResponsiveBreakpoint } from '../../hooks';
import { Can } from '../../components/Ability';
import { useStore } from '../../mobx-store';
import { subject } from '@casl/ability';
import analytics from '../../services/analytics';
import { useOnboarding } from '../../components/Onboarding/store';
import { withStyles } from '@material-ui/styles';
import { MobileFooterActions } from '../../components/MobileFooterActions';

const SettingsTabs = withStyles({
    root: {
        borderBottom: '1px solid #ddd',
    },

    indicator: {
        backgroundColor: COLOR_GRADE,
    },
})((props)=> <LRTabs {...props} />);

function AccountSettingsSubmit({
    handleClick,
    isSubmiting,
    canSubmit,
    submitLabel,
    className
}) {
    return (
        <MobileFooterActions forceInplaceSpacing>
            <LRButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleClick}
                className={className}
                type={'submit'}
            >
                {!isSubmiting && submitLabel}
                {isSubmiting && (<CircularProgress color="secondary" size={20} />)}
            </LRButton>
        </MobileFooterActions>
    );
}

function AccountSettingsModal({ onClose }) {
    const match = useRouteMatch();
    const history = useHistory();
    const [closing, setClosing] = useState(false);
    const settingsStore = useAccountSettingsStore();
    const {cobuyerStore} = settingsStore;
    const cobuyerState = useQuery()
        .get('cobuyer_state') || 'list';
    const onboarding = useOnboarding();

    useEffect(()=> {
        settingsStore.setTab(match.params.tab || 'profile');
        cobuyerStore.setCobuyerState(cobuyerState);

        if(cobuyerState === 'invite'){
            cobuyerStore.setInviteSource('board_menu_add_button');
        }
    }, [settingsStore,match.params.tab, cobuyerStore, cobuyerState]);

    function handleChange(_, value) {
        if(onboarding.isCobuyerInviteOnboarding) {
            onboarding.stop();
        }
        settingsStore.setTab(value);

        history.replace(`/boards/account/${value}`);
    }

    function handleOnClose() {
        if(onboarding.isCobuyerInviteOnboarding) {
            if(onboarding.stepId === 'cobuyers-list') {
                onboarding.next();
            } else {
                onboarding.stop();
            }
        }
        if(onClose) {
            onClose();
        } else {
            setClosing(true);
        }
    }

    if(closing) {
        return <Redirect to="/boards" />;
    }

    return (
        <LRModal onClose={handleOnClose}>
            <AccountSettingsStoreProvider accountSettingsStore={settingsStore}>
                <AccountSettings handleTabChange={handleChange} onClose={handleOnClose}/>
            </AccountSettingsStoreProvider>
        </LRModal>
    );
}

const AccountSettings = observer(function AccountSettings({handleTabChange, onClose}){
    const history = useHistory();
    const settingsStore = useAccountSettingsStoreContext();
    const { cobuyerStore } = settingsStore;
    const { profileStore } = settingsStore;
    const inviteFormRef = useRef();
    const isMobile = useResponsiveBreakpoint('xs');
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const onboarding = useOnboarding();
    const saveProfileFormRef = useRef();

    profileStore.formRef = saveProfileFormRef;

    async function handleInviteClick(){
        if(cobuyerStore.isInviteState){
            inviteFormRef.current.submit();
        } else {
            analytics.eventTrack('robin_account_modal_invite_button_click', {
                board_id: activeBoard.id,
                role: activeBoard.userRoleOnBoard.label
            });
            settingsStore.onInvite();
        }
    }

    function handleProfileSaveChanges() {
        if(profileStore.formRef?.current) {
            profileStore.formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }

    return (
        <Box height="100vh" display="flex" flexDirection="column">

            <Box position="relative">
                {cobuyerStore.isInviteState && (
                    <Box position="absolute"  top="50%" left="12px" style={{transform: 'translate(0,-50%)'}}>
                        <IconButton onClick={()=> {
                            if(onboarding.isCobuyerInviteOnboarding) {
                                return;
                            }
                            cobuyerStore.resetState();
                            history.push('co-buyers');
                        }}>
                            <ChevronLeft />
                        </IconButton>
                    </Box>
                )}
                <Box textAlign="center" my={['18px','22px']} fontSize={16}>
                    Account
                </Box>
                {settingsStore.isCobuyersOpened && !isMobile && (
                    <Can I="update" this={subject('Board', activeBoard)}>
                        <Box position="absolute" top="14px" right="24px">
                            <LRButton
                                disabled={!cobuyerStore.canSubmit || cobuyerStore.submitting}
                                color="primary" variant="contained"
                                onClick={handleInviteClick}
                                className="tour-target-invite-cobuyer"
                            >
                                {!cobuyerStore.submitting && 'Invite'}
                                {cobuyerStore.submitting && (<CircularProgress color="secondary" size={20}/>)}
                            </LRButton>
                        </Box>
                    </Can>
                )}
                {isMobile && (
                    <Box position="absolute" top="50%" right="14px" style={{transform: 'translate(0,-50%)'}}>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
            </Box>

            <Box borderBottom={`solid 1px ${COLOR_GREY}`} />
            <Box>
                <SettingsTabs
                    variant="fullWidth"
                    centered
                    value={settingsStore.currentTab}
                    onChange={handleTabChange}
                >
                    <LRTab value="profile" label="Profile" />
                    <LRTab value="co-buyers" label="Co-buyers" />
                    <LRTab value="notifications" label="Notifications" />
                </SettingsTabs>
            </Box>
            {settingsStore.isProfileOpened && <Profile />}
            {settingsStore.isCobuyersOpened && <Cobuyers ref={inviteFormRef} />}
            {settingsStore.isSettingsOpened && <Settings />}
            {isMobile && (
                <>
                    {(settingsStore.isProfileOpened) && (
                        <Can I="edit" a="User">
                            <AccountSettingsSubmit
                                canSubmit={profileStore.canSubmit}
                                isSubmiting={profileStore.submitting}
                                handleClick={handleProfileSaveChanges}
                                submitLabel={'Save changes'}
                            />
                        </Can>
                    )}
                    {settingsStore.isCobuyersOpened && (
                        <Can I="update" this={subject('Board', activeBoard)}>
                            <AccountSettingsSubmit
                                canSubmit={cobuyerStore.canSubmit}
                                isSubmiting={cobuyerStore.submitting}
                                handleClick={handleInviteClick}
                                className={'tour-target-invite-cobuyer'}
                                submitLabel={'Invite'}
                            />
                        </Can>
                    )}
                </>
            )}
        </Box>
    );
});

export default AccountSettingsModal;
