import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import ListingImage from '../../../../ListingImage';
import { useCompareNowStoreContext } from '../../../store';
import { useBoardCollaborations } from '../../../../../hooks/useBoardCollaborations';
import { useStore } from '../../../../../mobx-store';
import LRAvatar from '../../../../LRAvatar';
import { ChatBubble } from '@material-ui/icons';
import { PushPinIcon } from '../../../../LRIcons';
import simpleCypher from '../../../../../services/simple-cypher';
import analytics from '../../../../../services/analytics';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

function CardNoteSummary({card}) {

    const { boardsStore, PinspirationStore, UserStore } = useStore();
    const history = useHistory();
    const activeBoard = boardsStore.activeBoard;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const {
        cardMessageBoardCollaborations,
    } = useBoardCollaborations(card.id);
    let cardComments = cardMessageBoardCollaborations;
    if(!UserStore.isConsumer) {
        cardComments = cardComments.filter((comment)=> comment.visibleToAffiliate === true);
    }
    const lastComment = _.last(cardComments);

    const { id: listingId, source: listingSource } = (card?.listing_details?.id) ? simpleCypher.decode(card.listing_details?.id) : {};
    const itemId = `${listingSource}/${listingId}`;
    const pinnedImagesCount = [...PinspirationStore.collaborationsCache]
        .filter(([k, pin])=> pin.itemId === itemId).length;
    const displayDuration = lastComment ?
        moment(lastComment.updatedAt)
            .fromNow() :
        null;

    useEffect(()=> {
        async function fetchCollaborations() {
            await PinspirationStore.getCollaborationsByListingId(activeBoard.id, card.listing_details.id);
        }
        if(card?.listing_details?.id && activeBoard?.id) {
            fetchCollaborations();
        }
    }, [card?.listing_details?.id, activeBoard?.id, PinspirationStore]);

    function summaryClick() {
        history.push(`/boards/compare-now/cards/${card.id}/${_.kebabCase(card.listing_details.address)}?source=compare`);
    }

    return (
        <>
            {lastComment && lastComment?.user &&
                <Box>
                    <Box display="flex">
                        <Box ml={1}>
                            <LRAvatar size="xsmall" user={lastComment.user} />
                        </Box>
                        <Box ml={1} display="flex" alignItems="baseline">
                            <Typography variant="body1">
                                {lastComment.user.first_name}
                            </Typography>
                            <Box ml={1}/>
                            <Box color="lr_colors.grey_dark">
                                <Typography variant="caption" color="inherit">
                                    {displayDuration}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box p={1.5} mt={1} bgcolor="lr_colors.grey_light" borderRadius={4}>
                        <Typography variant="body1">
                            {lastComment.content.message}
                        </Typography>
                    </Box>
                    {cardComments.length > 1 &&
                        <Box mt={2} display="flex" >
                            <Box fontSize={16} display="flex" alignItems="center">
                                <ChatBubble fontSize="inherit" />
                            </Box>
                            <Box
                                ml={1}
                                color="lr_colors.blue_link"
                                style={{cursor: 'pointer'}}
                                onClick={function() {
                                    analytics.eventTrack('robin_compare_notes_comments_click', {
                                        role: UserStore.usersBoardRole,
                                        board_card_id: card.id
                                    });
                                    summaryClick();
                                }}
                            >
                                <Typography variant="body1" color="inherit">
                                    {`${cardComments.length} comment${cardComments.length > 1 ? 's' : ''}`}
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            }
            { !!pinnedImagesCount &&
                <Box mt={1} display="flex" >
                    <Box fontSize={16} display="flex" alignItems="center">
                        <PushPinIcon fontSize="inherit" />
                    </Box>
                    <Box
                        ml={1}
                        color="lr_colors.blue_link"
                        style={{cursor: 'pointer'}}
                        onClick={function() {
                            analytics.eventTrack('robin_compare_notes_pinned_photos_click', {
                                role: UserStore.usersBoardRole,
                                board_card_id: card.id
                            });
                            summaryClick();
                        }}
                    >
                        <Typography variant="body1" color="inherit">
                            {`${pinnedImagesCount} pinned photo${pinnedImagesCount > 1 ? 's' : ''}`}
                        </Typography>
                    </Box>
                </Box>
            }
        </>
    );
}

function NotesTable({ listings }) {
    const store = useCompareNowStoreContext();

    return (
        <Box ml="46px" mr="46px" display="flex" flexDirection="row" justifyContent="center">
            <Box flex="1" />
            {listings.map((listing, index)=> (
                <Box key={listing.id} width="240px" position="relative">
                    {store.isCardLocked(listing) && <Box position="absolute" right={-10} width={10} height={1} zIndex="1" />}
                    <Box height="100%" px={1.5} py={3} borderLeft={index === 0 ? '' : '1px solid #E3E3E3'}>
                        <CardNoteSummary card={listing} />
                    </Box>
                </Box>
            ))}
            <Box flex="1" />
        </Box>
    );
}

NotesTable.propTypes = {
    lisitngs: PropTypes.array
};

export default NotesTable;
