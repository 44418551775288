import React from 'react';
import { Box, Typography } from '@material-ui/core';
import UserInfo from './UserInfo';
import { COLOR_GREY_LIGHT } from '../../../constants';

const YourTeam = ({ buyerAgent, loanOfficer, onContactAgentClick })=> {
    return (
        <>
            <Box mb={2}>
                <Typography variant="h5">Your agent</Typography>
            </Box>
            <Box>
                <UserInfo
                    user={buyerAgent}
                    showContactAgentButton={!!onContactAgentClick}
                    onContactAgentClick={onContactAgentClick}
                />
            </Box>

            {loanOfficer && loanOfficer.vendor?.company_type === 'Mortgage' && (
                <>
                    <Box mt={4} pl={4} py={3} pr={6} bgcolor={COLOR_GREY_LIGHT} borderRadius="4px" fontSize={16}>
                        {buyerAgent.first_name} works closely with {loanOfficer.name} who can help you get
                        pre-qualified, lock in an interest rate, and answer any financing questions you might have.
                    </Box>
                    <Box mt={40 / 8}>
                        <UserInfo user={loanOfficer} />
                    </Box>{' '}
                </>
            )}
        </>
    );
};

export default YourTeam;
