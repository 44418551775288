import React from 'react';
import { Divider, Hidden, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { useBoardCards } from '../../../hooks/useBoardCards';
import { LANES_NAMES } from '../../../constants';

const BoardStats = observer(function BoardStats({ board, loading }) {
    const { getUnarchivedBoardCardsByLaneId } = useBoardCards();
    const maybeCards = getUnarchivedBoardCardsByLaneId('1');
    const favCards = getUnarchivedBoardCardsByLaneId('2');
    const makeAnOfferCards = getUnarchivedBoardCardsByLaneId('3');
    const savedSearches = loading ? [] : board.listpacks.filter((l)=> !l.handSelected);

    return (
        <div className="space-y-4">
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{ width: '50%' }}>
                    <Typography variant="body1">Properties viewed</Typography>
                    <Typography variant="h6">
                        {loading ?
                            <Skeleton animation="wave" variant="text" width={50} height="100%" /> :
                            board?.metrics?.listings_viewed
                        }
                    </Typography>
                </div>
                <div style={{ width: '50%' }}>
                    <Typography variant="body1">{LANES_NAMES['see']}</Typography>
                    <Typography variant="h6">
                        {loading ?
                            <Skeleton animation="wave" variant="text" width={50} height="100%" /> :
                            favCards.length
                        }
                    </Typography>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{ width: '50%' }}>
                    <Typography variant="body1">Saved searches</Typography>
                    <Typography variant="h6">
                        {loading ?
                            <Skeleton animation="wave" variant="text" width={50} height="100%" /> :
                            savedSearches.length
                        }
                    </Typography>
                </div>
                <div style={{ width: '50%' }}>
                    <Typography variant="body1">{LANES_NAMES['buy']}</Typography>
                    <Typography variant="h6">
                        {loading ?
                            <Skeleton animation="wave" variant="text" width={50} height="100%" /> :
                            makeAnOfferCards.length
                        }
                    </Typography>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{ width: '50%' }}>
                    <Typography variant="body1">{LANES_NAMES['interested']}</Typography>
                    <Typography variant="h6">
                        {loading ?
                            <Skeleton animation="wave" variant="text" width={50} height="100%" /> :
                            maybeCards.length
                        }
                    </Typography>
                </div>
            </div>
        </div>
    );

    // With HomeTours version. Use this one when home tours is available.
    /*     return (
        <div className="space-y-4">
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{width: '50%'}}>
                    <Typography variant="body1">Properties viewed</Typography>
                    <Typography variant="h6">{board?.metrics?.listings_viewed}</Typography>
                </div>
                <div style={{width: '50%'}}>
                    <Typography variant="body1">Maybes</Typography>
                    <Typography variant="h6">{maybeCards.length}</Typography>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{width: '50%'}}>
                    <Typography variant="body1">Saved searches</Typography>
                    <Typography variant="h6">{board.listpacks.length}</Typography>
                </div>
                <div style={{width: '50%'}}>
                    <Typography variant="body1">Favorites</Typography>
                    <Typography variant="h6">{favCards.length}</Typography>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div style={{ width: '50%' }}>
                    <Typography variant="body1">Tours on calendar</Typography>
                    <Typography variant="h6">0</Typography>
                </div>
                <div style={{width: '50%'}}>
                    <Typography variant="body1">Make an Offer</Typography>
                    <Typography variant="h6">{makeAnOfferCards.length}</Typography>
                </div>
            </div>
        </div>
    ); */
});

const BoardSummary = observer(function() {
    const theme = useTheme();
    const { boardsStore } = useStore();
    const board = boardsStore.activeBoard;

    return (
        <div
            style={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <Hidden smDown>
                <div
                    style={{
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                    }}
                >
                    <Typography variant="h6">Board summary</Typography>
                </div>
                <Divider style={{ marginLeft: theme.spacing(-2), marginRight: theme.spacing(-2) }}></Divider>
            </Hidden>
            <div
                style={{
                    paddingTop: theme.spacing(2),
                    paddingBottom: theme.spacing(2),
                }}
            >
                <BoardStats board={board} loading={boardsStore.loading || !boardsStore.loaded || boardsStore.activeBoard?.loadingBoardData} />
            </div>
        </div>
    );
});

export default BoardSummary;
