import React from 'react';
import LRButton from '../LRButton';
import analytics from '../../services/analytics';

export function ContactSupport({ source }) {
    const handleClick = (event)=> {
        analytics.eventTrack('robin_login_signup_support_email_click', {
            Source: source === 'signup' ? 'signup_page' : 'login_page',
        });
    };
    return (
        <div style={{ textAlign: 'center' }}>
            Have trouble logging in? Contact
            <div>
                <LRButton
                    onClick={handleClick}
                    color="secondary"
                    type="text"
                    href="mailto:support@listreports.com"
                    style={{ padding: 0 }}
                >
                    support@listreports.com
                </LRButton>
            </div>
        </div>
    );
}
