import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Dialog, IconButton, withTheme, useTheme, Hidden } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import styled from 'styled-components';
import ListingDetails from '../../components/ListingDetails';
import { useStore } from '../../mobx-store';
import queryString from 'query-string';
import _ from 'lodash';
import splitAddress from '../../services/split-address';
import { debug } from 'js-logger';
import { useResponsiveBreakpoint } from '../../hooks';
import { LoggedOutHeader } from 'scenes/LoggedOutListingView/loggedOutHeader';
import CopyToClipboard from 'components/CopyToClipboard';
import { portalApi } from 'apis';
import { ActionShareIcon } from 'components/LRIcons';
import analytics from 'services/analytics';

const StyledDialog = withTheme(styled(({ color, ...other })=> (
    <Dialog classes={{ paperWidthSm: 'paperWidthSm-listing-details' }} {...other} />
))`
    & .paperWidthSm-listing-details {
        max-width: 768px;
        max-height: 100%;
        margin: 0;
        border-radius: 0;
        overflow: visible;

        ${({ theme })=> theme.breakpoints.down('md')} {
            max-width: 768px;
        }
    }

    & .MuiBackdrop-root {
        ${({ solidblackbg })=> (solidblackbg === 'true' ? 'background-color: #000;' : '')}
    }
`);

const CloseButton = withTheme(styled(IconButton)`
    position: absolute;
    top: 8px;
    right: -55px;

    ${({ theme })=> theme.breakpoints.up('lg')} {
        color: #fff;
    }

    ${({ theme })=> theme.breakpoints.down('md')} {
        top: 4px;
        right: 4px;
        z-index: 3;
        color: #000;
    }
`);

const BoardListingModal = function({ onClose }) {
    const history = useHistory();
    const params = useParams();
    const { listingsStore, UserStore } = useStore();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const theme = useTheme();
    const [listings, setListings] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [shortShareUrl, setShortShareUrl] = useState(null);
    const location = useLocation();
    const { state: locationState = {} } = location || {};
    const { trackSource } = locationState;
    const queryStrings = queryString.parse(location.search);
    const requestSource = queryStrings?.source || trackSource;

    let listingDetailsIdsParam = params.listingIds;
    let listingDetailsIdsRef = useRef(listingDetailsIdsParam.split(','));
    const agent = UserStore.user.affiliate;

    useEffect(()=> {
        const fetchListingDetails = async ()=> {
            const listingsDetails = await listingsStore.fetchListings(listingDetailsIdsRef.current);
            setListings(listingsDetails);
            setIsLoaded(true);
        };
        fetchListingDetails();
    }, [listingsStore]);

    useEffect(()=> {
        async function shortenShareUrl(shareUrl) {
            const shortenedUrl = await portalApi.shortenURL(shareUrl);
            setShortShareUrl(shortenedUrl.data);
        }

        shortenShareUrl(window.location.href);
    }, []);

    const handleOnCloseClick = ()=> {
        if(onClose) {
            onClose();
        } else {
            history.goBack();
        }
    };

    if(!isLoaded) {
        return null;
    }

    if(!listings || listings.length === 0) {
        return (
            <StyledDialog
                fullScreen={true}
                fullWidth={true}
                maxWidth="sm"
                open={true}
                onExited={handleOnCloseClick}
                // TransitionComponent={isXs ? MobileTransition : Grow}
            >
                <h1>NOT FOUND</h1>
            </StyledDialog>
        );
    }

    const wrappedListings = listings.map((listing)=> {
        return { listing_details: listing };
    });

    return (
        <StyledDialog
            fullScreen={true}
            fullWidth={true}
            maxWidth="sm"
            open={true}
            onExited={handleOnCloseClick}
            // TransitionComponent={isXs ? MobileTransition : Grow}
        >
            <CloseButton onClick={handleOnCloseClick} title="Close">
                <Close />
            </CloseButton>

            <div style={{overflow: 'scroll'}}>
                <div style={{
                    height: isMobile ? '54px' : '64px',
                    borderBottom: `1px solid ${theme.palette.lr_colors.border_grey}`,
                    display: 'flex',
                    flexDirection: isMobile ? 'row' : 'row-reverse',
                    alignItems: 'center',
                    padding: `0 ${theme.spacing(isMobile ? 1 : 3)}px`,
                    position: 'sticky',
                    top: 0,
                    zIndex: 31,
                    backgroundColor: 'white',
                }}>
                    <Hidden smDown>
                        {shortShareUrl && <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: theme.spacing(2),
                                    cursor: 'pointer'
                                }}
                                onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                    listingId: params.listingIds,
                                    Source: 'logged_in',
                                })}
                            >
                                <IconButton
                                    disabled={false}
                                    style={{padding: theme.spacing(1)}}
                                >
                                    <ActionShareIcon style={{fontSize: theme.typography.body1.fontSize}} />
                                </IconButton>
                                <span style={{textDecoration: 'underline'}}>Share</span>
                            </div>
                        </CopyToClipboard>}
                    </Hidden>


                    <Hidden mdUp>
                        {shortShareUrl && <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                            <div onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                listingId: params.listingIds,
                                Source: 'logged_in',
                            })}>
                                <IconButton
                                    disabled={false}
                                    style={{padding: theme.spacing(1)}}
                                >
                                    <ActionShareIcon/>
                                </IconButton>
                            </div>
                        </CopyToClipboard>}
                    </Hidden>
                </div>
                <div>
                    <ListingDetails
                        buyerAgent={agent}
                        listingsAreNotCards={true}
                        listings={wrappedListings}
                        startIndex={0}
                        onClose={handleOnCloseClick}
                        requestSource={requestSource}
                        fromRobin={true}
                        overridingProps={{
                            hideMainHeader: !isXs && wrappedListings.length === 1
                        }}
                    />
                </div>
            </div>
        </StyledDialog>
    );
};

BoardListingModal.propTypes = {};

export default observer(BoardListingModal);