import React from 'react';
import { Divider, IconButton, makeStyles, Typography, useTheme, withTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styled from 'styled-components';
import LRModal from 'components/LRModal';
import ResponsiveHOC from 'components/ResponsiveHoc';
import LRAvatar from 'components/LRAvatar';

const Modal = withTheme(styled((props)=> <LRModal {...props} />)`
    & .paperWidthSm-listing-details {
        height: auto;
        max-width: 560px;
        padding: 0;

        ${({ theme })=> theme.breakpoints.only('xs')} {
            height: 100%;
        }
    }

    & .AddNoteModal-DialogContent-root {
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }

    & .AddNoteModal-DialogContent-root:first-child {
        padding-top: 0;
    }
`);

const useAvatarStyles = makeStyles({
    root: {
        backgroundColor: '#1A1A1A !important',
        color: 'white !important',
        width: '60px !important',
        height: '60px !important',
        fontSize: '24px !important',
        textTransform: 'uppercase',
    },
});

const useDialogContentStyles = makeStyles({
    root: {
        '&:first-child': {
            paddingTop: '0',
        },
    },
});

function CobuyerInfoMobile({ onClose, cobuyer }) {
    const theme = useTheme();
    const avatarClasses = useAvatarStyles();

    return (
        <div
            style={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    padding: theme.spacing(3),
                    position: 'relative',
                }}
            >
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                    Co-buyer
                </Typography>
                <div
                    style={{
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton onClick={onClose} title="Close">
                        <Close />
                    </IconButton>
                </div>
            </div>
            <Divider></Divider>
            <div
                style={{
                    padding: theme.spacing(2),
                }}
            >
                <div
                    className="space-x-4"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <LRAvatar classes={avatarClasses} user={cobuyer} />
                    <Typography variant="h5">{cobuyer.name}</Typography>
                </div>
                <div
                    className="space-y-4"
                    style={{
                        color: theme.palette.lr_colors.grade,
                    }}
                >
                    {cobuyer.phone_formatted && (
                        <div>
                            <Typography>Phone</Typography>
                            <a
                                href={`tel:${cobuyer.phone_formatted}`}
                                style={{
                                    color: theme.palette.lr_colors.grade,
                                    textDecoration: 'underline',
                                    fontWeight: '500',
                                    fontSize: '1rem',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {cobuyer.phone_formatted}
                            </a>
                        </div>
                    )}
                    {cobuyer.email && (
                        <div>
                            <Typography>Email</Typography>
                            <a
                                href={`mailto:${cobuyer.email}`}
                                style={{
                                    color: theme.palette.lr_colors.grade,
                                    textDecoration: 'underline',
                                    fontWeight: '500',
                                    fontSize: '1rem',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {cobuyer.email}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function CobuyerInfoDesktop({ onClose, cobuyer }) {
    const theme = useTheme();
    const avatarClasses = useAvatarStyles();
    return (
        <div
            style={{
                padding: theme.spacing(3),
                position: 'relative',
                height: '100%',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    right: 0,
                }}
            >
                <IconButton onClick={onClose} title="Close">
                    <Close />
                </IconButton>
            </div>
            <div
                className="space-x-4"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: theme.spacing(2),
                }}
            >
                <LRAvatar classes={avatarClasses} user={cobuyer} />
                <Typography variant="h5">{cobuyer.name}</Typography>
            </div>
            {cobuyer.phone_formatted && (
                <div
                    className="space-x-4"
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            width: '48px',
                        }}
                    >
                        <Typography>Phone</Typography>
                    </div>
                    <div
                        style={{
                            flex: 1,
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: '500',
                            }}
                        >
                            {cobuyer.phone_formatted}
                        </Typography>
                    </div>
                </div>
            )}
            {cobuyer.email && (
                <div
                    className="space-x-4"
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            width: '48px',
                        }}
                    >
                        <Typography>Email</Typography>
                    </div>
                    <div
                        style={{
                            flex: 1,
                        }}
                    >
                        <a
                            href={`mailto:${cobuyer.email}`}
                            style={{
                                color: theme.palette.lr_colors.grade,
                                textDecoration: 'underline',
                                fontWeight: '500',
                                fontSize: '1rem',
                                wordBreak: 'break-word',
                            }}
                        >
                            {cobuyer.email}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

const CobuyerInfo = ResponsiveHOC({ xs: CobuyerInfoMobile, sm: CobuyerInfoDesktop });

function CobuyerInfoModal({ cobuyer, open, onClose }) {
    const contentClasses = useDialogContentStyles();

    if(!cobuyer) {
        return null;
    }

    return (
        <Modal open={open} onClose={onClose} hideCloseButton={true} dialogContentClasses={contentClasses}>
            <CobuyerInfo cobuyer={cobuyer} onClose={onClose} />
        </Modal>
    );
}

export default CobuyerInfoModal;
