import { Box, Grid, IconButton, CircularProgress } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import styled from 'styled-components';
import { TrashIcon } from '../../../../components/LRIcons';
import { Can } from '../../../Ability';
import LRButton from '../../../LRButton';
import MoveToButton from '../../MoveToButton';
import { RobinLogo } from '../../../RobinLogo';

const ListingInfoContainer = styled.div`
    flex: 1;
    padding: 0;
`;

const StyledListingInfoContainerGrid = styled(Grid)`
    padding: 0;
`;
const StyledIconButton = styled(IconButton)`
    color: #000;
    padding: 4px;
`;

export function DesktopHeader(
    {
        cardId,
        laneId,
        onMoveToChange,
        onArchiveClick,
        totalListings,
        onPreviousClick,
        onNextClick,
        currentIndex,
        isNextBackDisabled,
        isNextDisabled,
        isBackDisabled,
        onShareClick,
        cardArchived,
        showLogo,
    }){

    return (
        <Grid container direction="row" alignItems="center">
            <ListingInfoContainer>
                <StyledListingInfoContainerGrid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                >


                    <Can I="edit" a="BoardCard">
                        <MoveToButton
                            laneId={laneId}
                            onMoveToChange={onMoveToChange}
                            archived={cardArchived}
                        />
                        {!cardArchived && (
                            <>
                                <Box display="inline" mr={3} />
                                {cardId && <StyledIconButton onClick={onArchiveClick}>
                                    <TrashIcon />
                                </StyledIconButton>}
                            </>
                        )}
                        <Box display="inline" mr={3} />
                        {/* <StyledIconButton onClick={onShareClick}>
                            <ShareIcon />
                        </StyledIconButton> */}
                    </Can>

                    {showLogo && (
                        <Box mx="auto">
                            <RobinLogo />
                        </Box>
                    )}

                    {/* {ShareBtn} */}
                </StyledListingInfoContainerGrid>
            </ListingInfoContainer>

            {totalListings > 1 && (
                <>
                    {!!onPreviousClick && (
                        <LRButton disabled={isBackDisabled || isNextBackDisabled || currentIndex === 0} onClick={onPreviousClick} variant="outlined">
                            <ChevronLeftIcon fontSize="small" />
                            <span>
                                Previous
                            </span>
                        </LRButton>
                    )
                    }

                    <Box mx={1}>
                        {currentIndex + 1} of {totalListings}
                    </Box>

                    {!!onNextClick && (
                        <LRButton
                            disabled={isNextDisabled || isNextBackDisabled || currentIndex === totalListings - 1}
                            onClick={onNextClick} variant="outlined"
                            style={{
                                minWidth: '82px'
                            }}
                        >
                            {
                                isNextDisabled && currentIndex < totalListings - 1 ?
                                    (
                                        <CircularProgress color="secondary" size={24} />
                                    )
                                    : (
                                        <>
                                            <span>
                                                Next
                                            </span>
                                            <ChevronRightIcon fontSize="small" />
                                        </>
                                    )
                            }
                        </LRButton>
                    )
                    }
                </>
            )}
        </Grid>
    );
}
