import React, { useCallback, useEffect, useMemo, useState } from 'react';
import diacritics from 'diacritics';
import parseUnit from './parse-unit';
import Address from 'lana';
import { LRInputOutlined } from 'components/LRInput';
import { Typography } from '@material-ui/core';
import { capitalize, debounce } from 'lodash';
import { LRAutocomplete } from 'components/LRAutocomplete';
import Logger from 'js-logger';

const logger = Logger.get('LRAddressAutocomplete');

function fixGoogleAddress(address) {
    if (!address) return address;
    return diacritics.remove(address.replace(/, (usa|united states)/gi, ''));
}

function insertUnit(address, unit) {
    if (unit?.num) {
        address = new Address(address);
        address.sec_unit_type = capitalize(unit.type);
        address.sec_unit_num = unit.num;
        address = address.toString();
    }

    return address;
}

async function geocodeByAddress(address) {
    if (!window.google?.maps?.Geocoder) return;
    const geocoder = new window.google.maps.Geocoder();
    const request = {
        address,
    };
    const { results } = await geocoder.geocode(request);
    return results;
}

function useGoogleAddressAutocomplete(text, unit, debounceDelay = 400) {
    const [suggestions, setSuggestions] = useState([]);
    const autocompleteService = useMemo(() => {
        if (!window.google?.maps?.places?.AutocompleteService) return;
        return new window.google.maps.places.AutocompleteService();
    }, []);

    //
    // Functions

    const getAddressFromSuggestion = useCallback(
        function getAddressFromSuggestion(suggestion) {
            if (!suggestion) return '';

            let suggestedAddress = fixGoogleAddress(suggestion.description);

            if (unit.num) {
                suggestedAddress = insertUnit(suggestedAddress, unit);
            }

            return suggestedAddress;
        },
        [unit]
    );

    const getSuggestions = useCallback(
        async function getSuggestions(text) {
            if (!autocompleteService || !text) return;

            const request = {
                input: text,
                types: ['address'],
                componentRestrictions: { country: 'us' },
            };
            const { predictions } = await autocompleteService.getPlacePredictions(request);

            setSuggestions(predictions.map((p) => getAddressFromSuggestion(p)));
        },
        [autocompleteService, getAddressFromSuggestion]
    );

    //
    // UseEffects

    useEffect(() => {
        const debounced = debounce(() => getSuggestions(text), debounceDelay);
        debounced();
        return debounced.cancel;
    }, [debounceDelay, getSuggestions, text]);

    //
    // Return

    return {
        suggestions,
    };
}

export function LRAddressAutocompleteInput({
    value = '',
    name = '',
    label,
    placeholder = 'Enter your address',
    disabled = false,
    debounceDelay = 500,
    helperText,
    error,
    autoFocus = false,
    autoSelect = true,
    autoHighlight = true,
    clearOnBlur = false,
    onChange: _onChange = (value) => {},
    onSuggestSelect = (address, placeId) => {},
    ...props
}) {
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [unit, setUnit] = useState({});
    const {
        suggestions
    } = useGoogleAddressAutocomplete(inputValue, unit, debounceDelay);

    // Functions

    function onInputChange(e, value) {
        onAddressChange(value);
    }

    async function onChange(e, value) {
        const [place] = await geocodeByAddress(value);
        let address = place.formatted_address;
        address = insertUnit(fixGoogleAddress(address), unit);

        _onChange(address);
    }

    function onAddressChange(address) {
        if(!address) {
            _onChange(address || '');
            setInputValue(address || '');
            return;
        }

        const unitParse = parseUnit(address);

        if (unitParse.sec_unit_num) {
            setUnit({
                type: unitParse.sec_unit_type,
                num: unitParse.sec_unit_num,
            });
        } else {
            setUnit({});
        }

        setInputValue(address);
    }

    return (
        <LRAutocomplete
            {...props}
            placeholder={placeholder}
            value={value}
            inputValue={inputValue}
            open={suggestionsOpen && suggestions.length > 0}
            options={suggestions}
            autoSelect={autoSelect}
            autoHighlight={autoHighlight}
            clearOnBlur={clearOnBlur}
            autoFocus={autoFocus}
            error={error}
            helperText={helperText}
            label={label}
            filterOptions={(x) => x}
            renderOption={(option) => <Typography variant="body2">{option}</Typography>}
            onChange={onChange}
            onInputChange={onInputChange}
            onOpen={() => setSuggestionsOpen(true)}
            onClose={() => setSuggestionsOpen(false)}
        />
    );
}

LRAddressAutocompleteInput.propTypes = {
    ...LRInputOutlined.propTypes,
};
