import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

export function useDeviceBreakpoints() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
    const isIPad = useMediaQuery(theme.breakpoints.only('sm'), { noSsr: true });
    const isIPadDown = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const isIPadUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const isIPadPro = useMediaQuery(theme.breakpoints.only('md'), { noSsr: true });
    const isIPadProDown = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const isIPadProUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    
    return {
        isMobile,
        isIPad,
        isIPadDown,
        isIPadUp,
        isIPadPro,
        isIPadProDown,
        isIPadProUp,
        isDesktop,
    };
}
