import { useMemo } from 'react';
import { useListingNearbyPlacesByCategory } from './useListingNearbyPlacesByCategory';
import _ from 'lodash';

export function useListingPlaceCategoriesWithClosestPlace(listing) {
    const { data: nearbyPlaces, ...queryResult } = useListingNearbyPlacesByCategory(listing);
    let closestByCategory = useMemo(()=> {
        if(!nearbyPlaces) {
            return null;
        }
        let _closestByCategory = [];
        _.forOwn(nearbyPlaces, (value, key)=> {
            if(key !== 'schools') {
                if(value.length) {
                    _closestByCategory.push({
                        category: key,
                        place: _.minBy(value, 'distance'),
                    });
                } else {
                    _closestByCategory.push({
                        category: key,
                        place: null,
                    });
                }
            }
        });

        _closestByCategory = _.sortBy(_closestByCategory, (c)=> {
            if(c.place && c.place.distance) {
                return c.place.distance;
            }
        });
        return _closestByCategory;
    }, [nearbyPlaces]);

    return { data: closestByCategory, nearbyPlaces, ...queryResult };
}
