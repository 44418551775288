import { Typography, useTheme } from '@material-ui/core';
import { useStore } from 'mobx-store';
import React from 'react';
import { Link } from 'react-router-dom';
import analytics from 'services/analytics';
import LRButton from '../../../components/LRButton';
import { useResponsive } from '../../../hooks/use-responsive';

function BoardHomes() {
    const { UserStore } = useStore();
    const user = UserStore.user;
    const r = useResponsive();
    const theme = useTheme();
    return (
        <div
            className={r({ xs: 'space-y-2', md: 'space-x-4' })}
            style={{
                display: r({ xs: 'block', md: 'flex' }),
                alignItems: 'center',
                padding: r({ xs: theme.spacing(2), md: theme.spacing(3) }),
            }}
        >
            <div
                style={{
                    position: 'relative',
                    left: '-8px' // Offset the image shadow
                }}
            >
                <img
                    src="/images/overview/board-example.png"
                    alt="board"
                    height="191"
                    width="280"
                    style={{
                        height: '191px',
                        width: 'auto',
                        display: 'block',
                    }}
                />
            </div>
            <div className="space-y-4">
                <Typography variant="h5">
                    Check out {user?.first_name}’s board to <br /> view homes on their board
                </Typography>
                <LRButton
                    component={Link}
                    to="/boards"
                    variant="outlined"
                    onClick={()=> {
                        analytics.eventTrack('robin_overview_properties_homes_on_board_click');
                    }}
                >
                    View board
                </LRButton>
            </div>
        </div>
    );
}

export default BoardHomes;
