import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { Box, CircularProgress, CardMedia, Divider, Grid, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import {
    COLOR_BORDER_GREY,
    COLOR_GREY_LIGHT,
    COLOR_GREY_DARK,
    DEFAULT_HEADSHOT
} from '../../../constants';
import styled from 'styled-components';
import { useResponsiveBreakpoint, useResponsiveConfig } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';

import analytics from '../../../services/analytics';
import formatPhoneNumber from '../../../services/format-phone-number';
import Header from '../../../components/Header';
import VideoPlayer from '../VideoPlayer';
import SponsorCompliance from '../SponsorCompliance';

const PRE_ROLL_TIMEOUT = 20; // in seconds

const AgentHeadshot = withTheme(styled(CardMedia)`
    margin-top: 16px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 40px;
    height: 40px;
    border-radius: 50%;

    ${({ theme })=> theme.breakpoints.up('xs')} {
        width: 64px;
        height: 64px;
    }
`);

const PhoneNumber = styled.a`
    color: inherit;
    text-decoration: unset;
`;

const PageDivider = withTheme(styled(Divider)`
    height: 2px;
    background-color: #eeecee;
    width: 24px;
    margin: 15px auto;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        margin: 12px 0;
    }
`);

const BoldTypography = styled(Typography)`
    font-weight: 500;
`;



function Preloader({ error }) {
    return (
        <Box display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            mt={5}
        >
            {!error && <CircularProgress color="secondary" />}
            {error && (
                <span>There was an error while loading the open house details. Please try again later.</span>
            )}
        </Box>
    );
}


function SponsorInfoCard({ sponsor, isMobile }) {
    if(!sponsor) return null;
    return (
        <Box>
            <Grid container
                spacing={isMobile? 0 : 3}
                justify={isMobile ? 'flex-start':'center'}
            >
                <Grid item>
                    <Box minWidth={320} maxWidth={480}>
                        <Box letterSpacing={1.8}
                            color={COLOR_GREY_DARK}
                        >
                            <BoldTypography variant="caption">
                                THIS EVENT BROUGHT TO YOU BY
                            </BoldTypography>
                        </Box>
                        <Box mt={2} display="inline-block">
                            <AgentHeadshot image={sponsor.headshot || DEFAULT_HEADSHOT} />
                        </Box>
                        <Box fontSize="20px">{sponsor.name}</Box>
                        <Box position="relative" fontSize="14px">
                            <Box color={COLOR_GREY_DARK}>
                                <Typography variant="caption">
                                    {sponsor.title ? `${sponsor.title} at ` : ''}{sponsor.company}
                                </Typography><br/>
                                <Typography variant="caption">
                                    NMLS# {sponsor.realtor_license_number}
                                </Typography>
                                {sponsor.mlo_license_number && (
                                    <Typography variant="caption">
                                        &nbsp;• {sponsor.mlo_license_state} License: MLO.{sponsor.mlo_license_number}
                                    </Typography>
                                )}
                            </Box>
                            <Box display="inline-block">
                                <PageDivider></PageDivider>
                            </Box>
                            <Box>
                                <PhoneNumber href={isMobile ? `tel:${sponsor.phone}` : undefined}>
                                    {formatPhoneNumber(sponsor.phone)}
                                </PhoneNumber>{' '}
                            </Box>
                            <Box>{sponsor.email}</Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box minWidth={320} maxWidth={480}>
                        {sponsor.branding &&
                            <Box maxWidth={isMobile? '100%' : '75%'}
                                pt={2}
                                mb={1}
                            >
                                <img src={sponsor.branding}
                                    alt={sponsor.company}
                                    height={70}
                                />
                            </Box>
                        }
                        <Box color={COLOR_GREY_DARK}>
                            {sponsor.branch_address && (
                                <Typography variant="caption">
                                    {sponsor.branch_address}<br/>
                                </Typography>
                            )}
                            {sponsor.branch_license_number && (
                                <Typography variant="caption">
                                    Branch NMLS# {sponsor.branch_license_number}&nbsp;•&nbsp;
                                </Typography>
                            )}
                            {sponsor.phone_secondary && (
                                <Typography variant="caption">
                                    Phone: {formatPhoneNumber(sponsor.phone_secondary)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}


function SponsorPreRoll() {
    const isMobile = useResponsiveBreakpoint('xs');
    const isStacked = useResponsiveBreakpoint('sm');
    const headingVariant = useResponsiveConfig({ xs: 'h5', sm: 'h5', md: 'h4', lg: 'h3'});
    const cloudBackgroundPosY = useResponsiveConfig({ xs: '25%', sm: '20%', md: '10%', lg: '-12%' });
    const [secondsToGo, setSecondsToGo] = useState(PRE_ROLL_TIMEOUT);
    const { LrLiveStore } = useStore();
    const { lrLive,
        sponsor,
        startTime,
        error,
        expiredOpenHouse,
    } = LrLiveStore;
    const { report, user } = lrLive || {};
    const agent = user
        ? user
        : report
            ? report.agent
            : null;

    useEffect(()=> {
        if(lrLive && sponsor) {
            analytics.eventTrack('lrlive_sponsored_event_preroll_page_viewed', {
                distinct_id: lodashGet(lrLive, 'userId'),
                event_type: lodashGet(lrLive, 'type'),
                lrlive_id: lodashGet(lrLive, 'id'),
                sponsor_user_id: lodashGet(lrLive, 'sponsorUserId'),
                recorded_event: expiredOpenHouse,
                'Address': report.address,
                'Start Time': startTime,
                report_id: lodashGet(report, 'id'),
                'current url': window.location.href,
            });
        }
    }, [lrLive, sponsor, report, agent, startTime, expiredOpenHouse]);

    useEffect(()=> {
        if(sponsor && secondsToGo > 0) {
            window.setTimeout(()=> {
                setSecondsToGo(secondsToGo - 1);
            }, 1000);
        }
    }, [sponsor, secondsToGo]);


    return (
        <Box display="flex"
            flexDirection="column"
            minHeight="100vh"
            height="100%"
        >
            {sponsor && !error && (

                <Box maxWidth="100%"
                    flex={1}
                    style={{
                        backgroundImage: 'url(\'/images/lr-live/cityscape_grayscale.png\')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: `0 ${cloudBackgroundPosY}`,
                        backgroundSize: '100% auto',
                    }}
                >
                    <Header justifyContent={isMobile ? 'left' : 'center'}
                        isMobile={isMobile}
                    />

                    <Box px={[3, 5]} pt={[1,3]} pb={[3, 5]}>
                        <BoldTypography variant={headingVariant}
                            align={isMobile ? 'left' : 'center'}
                        >
                            Welcome to the digital open house!{isMobile ? ' ' : <br/>}We’ll begin&nbsp;
                            {secondsToGo ? <span>in: {secondsToGo}</span> : 'shortly'}
                        </BoldTypography>
                    </Box>

                    <Box>
                        <VideoPlayer videoUrl={sponsor.pre_roll_video_url}
                            autoplay={true}
                        />
                    </Box>

                    <Box bgcolor={COLOR_GREY_LIGHT}
                        py={2}
                        px={[3, 4]}
                        mb={3}
                    >
                        <SponsorInfoCard sponsor={sponsor} isMobile={isMobile}/>
                    </Box>
                </Box>
            )}

            {!isMobile && (
                /*  Desktop separator line above compliance block */
                <Box px={2}>
                    <Box height={1}
                        borderBottom={`1px solid ${COLOR_BORDER_GREY}`}
                    />
                </Box>
            )}

            {sponsor && <SponsorCompliance sponsor={sponsor} isMobile={isMobile}/>}

            {(!sponsor || error) && <Preloader error={error} />}
        </Box>
    );
}

export default observer(SponsorPreRoll);
