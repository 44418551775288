import React from 'react';
import { AppBar, useTheme, useMediaQuery, Toolbar, Grid, Typography } from '@material-ui/core';
import { CloseButton } from './components';

export function ModalTitle({title, onClose, ...props}){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
    return (
        <AppBar 
            color={isMobile ? 'inherit' : 'transparent'} 
            elevation={0}
            style={{
                borderBottom: isMobile && '1px solid #ddd',
            }}
        >
            <Toolbar>
                <Grid container justify="space-evenly" alignItems="center">
                    <Grid item xs></Grid>

                    <Grid item xs style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                display: 'inline-block',
                            }}
                        >
                            {isMobile && (
                                <Typography variant="body2">
                                    Schedule a tour
                                </Typography>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs style={{ textAlign: 'right' }}>
                        <CloseButton onClick={onClose} edge="end" />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}