import { useOnboarding } from 'components/Onboarding';
import { MOBILE_VIEW_MODES } from '../../constants';
import { useBoardListpack, useBoardListpackListing, useBoardListpackListings } from 'hooks/useBoardListpacks';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import React from 'react';
import { useState } from 'react';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import analytics from 'services/analytics';
import { ListingsMapAndListModal } from 'components/ListingsMapAndListModal';
import { Box, Slide } from '@material-ui/core';
import { OrganizerNavbar } from './Navbar';
import { MobileFooter } from './Mobile/Footer';
import ListpackModal from 'scenes/Board/ListpackModal';
import { useMemo } from 'react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { ListpackListingView } from 'components/ListpackListingView';
import { ModalRoute } from 'components/ModalRoute';
import { useBoardCards } from 'hooks/useBoardCards';
import { useBoardListpacks } from 'hooks/useBoardListpacks';

function ViewListpackListing({
    listpack,
    sortOrder,
    open = false,
    onExited = (e)=> {},
    ...props
}) {
    const history = useHistory();
    const location = useLocation();
    const { listingId:listingIdParam } = useParams();
    const [listingId, setListingId] = useState(listingIdParam);
    const { listings, hasNextPage, isFetchingNextPage, fetchNextPage } = useBoardListpackListings(listpack, sortOrder);
    const { listing } = useBoardListpackListing(listpack, listingId, sortOrder);
    const listingIndex = useMemo(()=> {
        return listings.findIndex((listpackListing)=> listing && listpackListing.id === listing.id);
    }, [listing, listings]);
    const qs = queryString.parse(location.search);

    // UseEffects

    /**
     set the initial listing id by the query param  on open
     */
    useEffect(()=> {
        if(open) {
            setListingId(listingIdParam);
        }
    }, [open]);

    /**
     Fetch the next page as needed when the user gets close to the end of the
     currently loaded listings and we have more listings.
     */
    useEffect(()=> {
        if(open && listings && hasNextPage && listingIndex > (listings.length - 10) && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, listingIndex, listings, open]);

    /**
     Update the URL with the latest listing id when the listing changes
     */
    useEffect(()=> {
        let to;

        if(listing && listing.id !== listingIdParam) {
            to = setTimeout(()=> {
                history.push({
                    pathname: location.pathname.replace(listingIdParam, listing.id),
                    search: location.search,
                });
            }, 100);
        }

        return ()=> {
            if(to) clearTimeout(to);
        };
    }, [history, listing, listingIdParam, location.pathname, location.search]);

    // Functions

    function onNextListingClick() {
        const nextListing = listings[listingIndex + 1];
        setListingId(nextListing.id);
    }

    function onPreviousListingClick() {
        const prevListing = listings[listingIndex - 1];
        setListingId(prevListing.id);
    }

    function onModalExited(e) {
        setListingId(null);
        onExited(e);
    }

    return (
        <ListpackListingView
            open={open}
            listpack={listpack}
            listing={listing}
            listings={listings}
            totalOfListings={listpack?.activeListingsCount}
            isloadingMore={isFetchingNextPage}
            canLoadMore={hasNextPage}
            onNextListingClick={onNextListingClick}
            onPreviousListingClick={onPreviousListingClick}
            sourceRequest={qs.source_request}
            onExited={onModalExited}
            {...props}
        />
    );
}

export function ListpackListingsMapAndListModal({
    open = false,
    onCloseClick = ()=> {},
    ...props
}) {
    const onboarding = useOnboarding();
    const { isIPadDown } = useDeviceBreakpoints();
    const history = useHistory();
    const match = useRouteMatch();
    const listpackId = match?.params?.listpackId;
    const { listpack } = useBoardListpack(listpackId);
    const [sortOrder, setSortOrder] = useState();
    const [view, setView] = useState(MOBILE_VIEW_MODES.LIST);
    const {
        sortedListpacks
    } = useBoardListpacks();
    const {
        listingCards,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        isLoading
    } = useBoardListpackListings(listpack?.handSelected ? sortedListpacks.handSelected : listpack, sortOrder);

    // Functions

    function handleOnSortOrderChange(sortOrder) {
        setSortOrder(sortOrder);
    }

    function handleViewOnChange(view) {
        setView(view);
    }

    function handleOnListingSelect(listing) {
        const { stepId } = onboarding;
        const shouldAdvanceOnboarding = ['highlight-listpack-listing-card'].includes(stepId);

        if((onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && shouldAdvanceOnboarding) {
            onboarding.next();
        }

        if(onboarding?.isFirstRunOnboarding) {
            analytics.eventTrack('robin_onboarding_view_RPP_from_search', {
                source: 'listings-map-rpp',
            });
        }

        const destination = `${match.url}/listing/${listing.listing_details.id}?source=lane-expanded&source_request=saved_search`;
        history.push(destination);
    }

    return (
        <>
            <ListingsMapAndListModal
                open={open}
                view={view}
                listpack={listpack}
                listings={listingCards}
                hasMoreListings={hasNextPage}
                loadingMoreListings={isFetchingNextPage}
                fetchMoreListings={fetchNextPage}
                isLoading={isLoading}
                header={(
                    <Box borderBottom="1px solid rgb(216, 216, 216)">
                        <OrganizerNavbar
                            listpack={listpack}
                            onSortChange={handleOnSortOrderChange}
                            sort={sortOrder}
                            onClose={onCloseClick}
                        />
                    </Box>
                )}
                footer={isIPadDown && (
                    <MobileFooter
                        view={view}
                        onViewChange={handleViewOnChange}
                        listpack={listpack}
                        onSortChange={handleOnSortOrderChange}
                        sort={sortOrder}
                    />
                )}
                onListingSelect={handleOnListingSelect}
                {...props}
            />

            {match && (
                <>
                    {/* EDIT MODAL */}
                    <Route path={`${match.path}/edit`} component={ListpackModal}></Route>

                    {/* VIEW LISTING MODAL */}
                    <ModalRoute
                        path={`${match.path}/listing/:listingId`}
                        Component={ViewListpackListing}
                        ComponentProps={{
                            TransitionComponent: Slide,
                            TransitionProps: {
                                direction: 'up',
                            },
                            listpack: listpack,
                            sortOrder: sortOrder,
                            onCloseClick: ()=> {
                                if(onboarding && (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding) && onboarding.stepId !== 'free-step') {
                                    onboarding.bypassRPPView = true;
                                    onboarding.back();
                                }
                                history.push(match.url);
                            },
                        }}
                    />
                </>
            )}
        </>
    );
}
