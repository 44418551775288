import React, { useState, useEffect } from 'react';
import LRButton from '../../components/LRButton';
import CopyToClipboard from '../../components/CopyToClipboard';
import { COLOR_GREY_DARK, COLOR_BLUE_LINK, SEND_IMAGE } from '../../constants';
import { useResponsiveBreakpoint, useValidationForm2 } from '../../hooks';
import { Box, Dialog, InputBase, InputLabel } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import { portalApi } from '../../apis';
import analytics from '../../services/analytics';
import styled from 'styled-components';
import Logger from 'js-logger';
import CircularProgress from '@material-ui/core/CircularProgress';

const logger = Logger.get('ShareModal');

const Input = styled(InputBase)`
    border: 1px solid #ddd;

    border-top-left-radius: '4px';
    border-bottom-left-radius: '4px';

    background: #fff;
    padding: 8px 15px;
    width: 100%;

    input {
        position: relative;
        color: #000;
        line-height: 1;
    }

    &::placeholder {
        color: #d8d8d8;
    }

    &.text-right {
        input {
            text-align: right;
        }
    }
`;

const Label = styled(InputLabel)`
    font-size: 20px;
    color: #1a1a1a;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-bottom: 16px;
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    resize: none;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100px;
    margin-bottom: 24px;
    font-family: inherit !important;

    &::placeholder {
        color: ${COLOR_GREY_DARK} !important;
    }
    &:focus {
        outline: none;
    }
`;

const ShareModal = observer(({ open = false, onClose, agentId, listpackId, listing, shortenedUrl })=> {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const [submitted, setSubmitted] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const [formStatus, formData, clearForm] = useValidationForm2(
        {
            recipientEmail: '',
            fromEmail: '',
            customMessage: '',
        },
        {
            recipientEmail: ['required', 'email'],
            fromEmail: ['required', 'email'],
        }
    );
    const { recipientEmail, fromEmail, customMessage } = formData;

    const [filledForm, setFilledForm] = useState(false);

    useEffect(
        function() {
            const filledInputs = recipientEmail.inputProps.value && fromEmail.inputProps.value;

            if(filledInputs !== filledForm) {
                setFilledForm(filledInputs);
            }
        },
        [filledForm, recipientEmail.inputProps.value, fromEmail.inputProps.value]
    );

    function close() {
        onClose(false);
    }

    function trackCopy() {
        analytics.eventTrack('listpacks_consumer_individual_listing_share_copy_link', {
            distinct_id: agentId,
            address: listing.address,
            'listing id': listing.id,
            listpack_id: listpackId,
            'current url': window.location.href,
        });
    }

    async function share(e) {
        try {
            e.preventDefault();
            setSubmitted(true);
            if(formStatus.isValid) {
                setIsSharing(true);
                let data = {
                    recipient: recipientEmail.inputProps.value,
                    sender: fromEmail.inputProps.value,
                    customMessage: customMessage.inputProps.value,
                    listingUrl: shortenedUrl,
                    listingHeroImage: listing.hero_image,
                };

                const response = await portalApi.shareListing(agentId, listpackId, listing.id, data);
                const success = response.status === 'SUCCESS';

                setIsSharing(false);

                analytics.eventTrack('listpacks_consumer_individual_listing_share_submit', {
                    distinct_id: agentId,
                    address: listing.address,
                    'listing id': listing.id,
                    listpack_id: listpackId,
                    'current url': window.location.href,
                });

                onClose(success);
            }
        } catch (err) {
            setSubmitted(false);
            logger.error(err.message);
            throw err.message;
        }
    }

    return (
        <Dialog
            fullScreen={isMobile}
            open={open}
            PaperProps={{
                style: {
                    width: isMobile ? '' : 480,
                },
            }}
        >
            {isSharing && (
                <Box
                    minHeight={200}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    p={4}
                >
                    <Box height={42} width={41} mx="auto">
                        <img src={SEND_IMAGE} width="100%" height="100%" alt="Sending" />
                    </Box>
                    <Box pt={2} fontSize="h5.fontSize">
                        Sharing
                    </Box>
                    <Box pt={2} zIndex="10" bgcolor="transparent !important">
                        <CircularProgress color="secondary" />
                    </Box>
                </Box>
            )}
            {!isSharing && (
                <Box bgColor="#FFF !important">
                    <form onSubmit={share}>
                        <Box display="flex" p={3} flexDirection="column">
                            <Box display="flex" flexDirection="row" mb={3} right={24}>
                                <Box fontSize="h5.fontSize" mr="auto">
                                    Share this home
                                </Box>
                                <Box
                                    position="absolute"
                                    right={16}
                                    top={16}
                                    onClick={close}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Close />
                                </Box>
                            </Box>
                            <Label>Recipient's email*</Label>
                            <Box width={1} mb={2}>
                                <Input
                                    autoFocus={true}
                                    name="recipientEmail"
                                    {...recipientEmail.inputProps}
                                    error={submitted && !recipientEmail.isValid}
                                />
                                {submitted && !recipientEmail.isValid && (
                                    <Box fontSize={14} color="error.main" my="4px">
                                        Please enter a valid recipient's email
                                    </Box>
                                )}
                            </Box>
                            <Label>Your email*</Label>
                            <Box width={1} mb={2}>
                                <Input
                                    name="fromEmail"
                                    {...fromEmail.inputProps}
                                    error={submitted && !fromEmail.isValid}
                                />
                                {submitted && !fromEmail.isValid && (
                                    <Box fontSize={14} color="error.main" my="4px">
                                        Please enter a valid email
                                    </Box>
                                )}
                            </Box>
                            <Label>Include a message (optional)</Label>
                            <StyledTextArea
                                name="customMessage"
                                {...customMessage.inputProps}
                                placeholder="Check out this home I found on ListReports."
                            />
                            <Box
                                display="flex"
                                flexDirection={['column', 'row']}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <CopyToClipboard text={shortenedUrl}>
                                    <Box style={{ cursor: 'pointer' }} color={COLOR_BLUE_LINK} component="span" onClick={trackCopy}>
                                        Copy shareable Link
                                    </Box>
                                </CopyToClipboard>
                                <Box pt={[3, 0]} width={[1, 'auto']}>
                                    <LRButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            width: `${isMobile ? '100%' : '114px'}`,
                                            height: '48px',
                                            fontSize: '16px',
                                            'min-width': '114px',
                                        }}
                                    >
                                        Submit
                                    </LRButton>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Box>
            )}
        </Dialog>
    );
});

export default ShareModal;
