// eslint-disable-next-line
import Logger from 'js-logger';
import * as LDClient from 'launchdarkly-js-client-sdk';

const logger = Logger.get('LaunchDarkly');
const ANON_USER = {
    anonymous: true
};

class LaunchDarkly {
    constructor() {
        this.client = LDClient.initialize(process.env.REACT_APP_ROBIN_LAUNCHDARKLY_CLIENT_ID, ANON_USER, {
            sendEventsOnlyForVariation: true,
        });

        // Add Listeners
        this.client.on('ready', this._onReady.bind(this));
        this.client.on('change', this._onChange.bind(this));
        this.client.on('error', this._onError.bind(this));
    }

    // Private Functions
    _debug() {
        logger.debug.apply(logger, arguments);
    }

    _onReady() {
        this._debug('onReady');
    }

    _onError() {
        this._debug('onError');
    }

    _onChange(flags) {
        this._debug('onChange', flags);
    }

    // Public Functions

    /**
     * Shortcut for this.client.on
     *
     * @function on
     */
    on() {
        this.client.on.apply(this.client, arguments);
    }

    /**
     * Shortcut for this.client.off
     *
     * @function off
     */
    off() {
        this.client.off.apply(this.client, arguments);
    }

    /**
     * Identifies the user in launch darkly
     * See their docs for details.
     * https://docs.launchdarkly.com/docs/js-sdk-reference#section-changing-the-user-context
     *
     * @function identify
     */
    identify(user, hash, callback) {
        this._debug('changeUser', user);

        if(user) {
            this.client.identify(user, hash, callback);
        } else {
            this.client.identify(ANON_USER, hash, callback);
        }
    }

    /**
     * Fetches the value for the flag being passed in.
     * See their docs for details.
     * https://docs.launchdarkly.com/docs/js-sdk-reference#section-variation
     *
     * @function variation
     * @param {String} flag
     * @param {Mixed} [defaultValue]
     */
    variation() {
        return this.client.variation.apply(this.client, arguments);
    }

    /**
     * Used to track custom events and goals in
     * launch darkly.
     * See their docs for details.
     * https://docs.launchdarkly.com/docs/js-sdk-reference#section-analytics-events
     *
     * @function track
     * @param {String} eventName
     * @param {Object} [additionalData]
     */
    track() {
        return this.client.track.apply(this.client, arguments);
    }

    allFlags() {
        return this.client.allFlags.apply(this.client, arguments);
    }
}

export default new LaunchDarkly();
