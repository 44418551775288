import { FormControl, FormHelperText, Grid, Typography, useTheme } from '@material-ui/core';
import React from 'react';

export function LRFormControl({
    children,
    label,
    error,
    fullWidth,
    required,
    uniqueId,
    helperText,
    style = {},
    ...props
}) {
    const theme = useTheme();

    return (
        <FormControl fullWidth={fullWidth} style={{ marginBottom: theme.spacing(2), ...style, }} {...props}>
            {Boolean(label) && (
                <label
                    htmlFor={uniqueId}
                    style={{
                        marginBottom: theme.spacing(1),
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        alignItems="flex-end"
                    >
                        <Grid item xs>
                            <Typography variant="body1" style={{ fontWeight: 500, }}>
                                {label}
                            </Typography>
                        </Grid>

                        {required && (
                            <Grid item>
                                <Typography style={{ ...theme.typography.body4, color: theme.palette.lr_colors.steak_sauce }}>
                                    Required
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </label>
            )}

            {children}

            {Boolean(helperText) && (
                <FormHelperText id={`${uniqueId}-helper-text`} error={error}>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
}
