import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import LRButton from '../../../../components/LRButton';
import LRAvatar from '../../../../components/LRAvatar';
import { Redirect } from 'react-router-dom';
import { useStore, useActiveBoard } from '../../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { useForm, FormContext } from 'react-hook-form';
import { TextField } from '../../../../components/TextField';
import { Preferences } from '../PerferencesForm';
import { LRPhoneInput } from '../../../../components/LRPhoneInput';
import { useAccountSettingsStoreContext } from '../../store';
import { Can, useAbility } from '../../../../components/Ability';
import { useResponsiveBreakpoint } from '../../../../hooks';
import LRDropdown from '../../../../components/LRDropdown';
import analytics from '../../../../services/analytics';
import moment from 'moment';
import Logger from 'js-logger';

const howSoonOptions = [
    { value: 'ASAP', label: 'ASAP' },
    { value: 'within 3 months', label: '1-3 months' },
    { value: '3-6 months', label: '3-6 months' },
    { value: '6-12 months', label: '6-12 months' },
    { value: '12+ months', label: '12+ months' },
    { value:'i_already_bought_a_home_using_robin', label: 'I already bought a home using Robin'},
    { value: 'im_not_sure', label: "I'm not sure" },
];

function ProfileForm({ match }) {
    const { UserStore, GlobalSnackbarStore } = useStore();
    const { user, userPreferences, hasUserPreferences } = UserStore;
    const activeBoard = useActiveBoard();
    const defaultTimeframeValue = howSoonOptions.find((option)=> {
        return option.value === (UserStore.howSoonTimeframe || 'im_not_sure');
    });
    const [selectedTimeframe, setSelectedTimeframe] = useState(defaultTimeframeValue);
    const [timeframeChanged, setTimeframeChanged] = useState(false);

    const { profileStore } = useAccountSettingsStoreContext();
    const duplicateEmail = profileStore.updateUserRequest.hasDuplicateEmailError;
    const duplicatePhone = profileStore.updateUserRequest.hasDuplicatePhoneError;
    const invalidPhone = profileStore.updateUserRequest.hasInvalidPhoneError;
    const phoneError = useMemo(()=> {
        return (duplicatePhone || invalidPhone)
            ? profileStore.updateUserRequest.firstError.detail
            : '';
    }, [duplicatePhone, invalidPhone]);
    const isMobile = useResponsiveBreakpoint('xs');
    const ability = useAbility();
    const canEditUser = ability.can('edit', 'User');
    function handleHeadshotChange(event) {
        UserStore.updateHeadshot(event.target.files[0]);
    }

    const rfhMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            email: user.email,
            areaIds: userPreferences?.areaIds || [],
            preferences: {
                price_min: userPreferences?.price_min || null,
                price_max: userPreferences?.price_max || null,
                bathrooms: userPreferences?.bathrooms || null,
                bedrooms: userPreferences?.bedrooms || null,
            }
        },
    });
    const [areas, setAreas] = useState(userPreferences?.areaIds || []);
    const {register, handleSubmit, errors, formState, triggerValidation} = rfhMethods;

    useEffect(()=> {
        triggerValidation();
    }, [triggerValidation]);

    useEffect(()=> {
        const isValid =!(!formState.isValid || !formState.dirty);
        profileStore.setIsFormValid(isValid);
    }, [formState.isValid, formState.dirty, areas, profileStore]);

    function showSuccessSnackbar(){
        GlobalSnackbarStore.show('User profile has been changed!', {
            autoHideDuration: 1000,
            action:  (
                <>
                    <LRButton color="secondary" onClick={()=> {
                        GlobalSnackbarStore.hide();
                    }}>Close</LRButton>
                </>
            )
        });
    }

    async function handleOnSubmit(formdata) {
        const {preferences, areaIds, ...data} = formdata;
        let preferencesData = null;

        if(preferences.price_min || preferences.price_max  || preferences.bathrooms  || preferences.bedrooms  || preferences.price_min  || areaIds){
            preferencesData = {
                ...preferences,
                areaIds: areaIds || null,
                boardId: activeBoard?.id,
                userId: user.id,
            };
        }

        const dataToSend = Object.keys(data)
            .reduce((accum, key)=> {
                if(user[key] !== data[key]){
                    accum[key] = data[key];
                }
                return accum;
            },{ id: user.id });

        if(defaultTimeframeValue.value !== selectedTimeframe.value){
            dataToSend.meta = {
                how_soon: selectedTimeframe.value,
                howSoonLastSeenAt: moment()
                    .toISOString(),
            };

            analytics.eventTrack('robin_user_timeframe_updated', {
                name: user.name,
                role: UserStore.usersBoardRole,
                board_id: user.defaultBoardId,
                source: 'profile_settings',
                old_timeframe: UserStore.timeframeMapper[defaultTimeframeValue.value],
                new_timeframe: UserStore.timeframeMapper[selectedTimeframe.value],
            });
        }

        if(preferencesData){
            analytics.eventTrack((hasUserPreferences) ? 'robin_default_preferences_updated' : 'robin_default_preferences_added', {source: 'profile'});
        }

        await profileStore.saveChanges(dataToSend, preferencesData, (hasUserPreferences) ? userPreferences.id : null);

        if(profileStore.userPreferencesRequest.hasLoaded && preferencesData){
            UserStore.mergeUserPreferencesData(preferencesData);
        }

        if(profileStore.updateUserRequest.hasLoaded){
            UserStore.mergeUserData(dataToSend);
            showSuccessSnackbar();
        }
    }

    return (
        <FormContext {...rfhMethods}>
            <Box pb={[8, 0]}>
                <form ref={profileStore.formRef} onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <LRAvatar size="large" user={user} />
                            <Box
                                name="headshot"
                                component={'input'}
                                display="none"
                                id="headshot-upload"
                                accept="image/*"
                                type="file"
                                onChange={handleHeadshotChange}
                            />
                            <Can I="edit" a="User">
                                <Box mt={2}>
                                    <label htmlFor="headshot-upload">
                                        <LRButton color="primary" component="span">
                                            Change photo
                                        </LRButton>
                                    </label>
                                </Box>
                            </Can>
                        </Box>
                        <Box mt={3} mx="auto" width={{ xs: '100%', md: '70%' }}>
                            <Grid container>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Box pr={{ xs: 0, md: 1 }}>
                                            <Box my={1}>
                                                <TextField
                                                    label="First name"
                                                    name="first_name"
                                                    inputRef={register({required: true, validate: (value)=> value.trim().length})}
                                                    type="text"
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    inputProps={{
                                                        fontSize: 16,
                                                        disabled: !canEditUser
                                                    }}
                                                    labelProps={{
                                                        fontWeight: 'normal',
                                                        fontSize: 16,
                                                    }}
                                                    tone={errors.first_name && 'error'}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pl={{ xs: 0, md: 1 }}>
                                            <Box my={1}>
                                                <TextField
                                                    label="Last name"
                                                    name="last_name"
                                                    inputRef={register({required: true, validate: (value)=> value.trim().length})}
                                                    type="text"
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    inputProps={{
                                                        fontSize: 16,
                                                        disabled: !canEditUser
                                                    }}
                                                    labelProps={{
                                                        fontWeight: 'normal',
                                                        fontSize: 16,
                                                    }}
                                                    tone={errors.last_name && 'error'}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box my={1}>
                                        <LRPhoneInput rules={{ required: true }} rfhMethods={rfhMethods} label="Mobile number" name="phone"
                                            inputProps={{
                                                fontSize: 16,
                                                height: '48px',
                                                width: '100%',
                                                message: ()=> phoneError,
                                                tone: (errors.phone || phoneError) && 'error',
                                                disabled: !canEditUser
                                            }}
                                            labelProps={{
                                                fontWeight: 'normal',
                                                fontSize: 16
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box my={1}>
                                        <TextField
                                            label="Email address"
                                            name="email"
                                            labelProps={{
                                                fontSize: 16,
                                                fontWeight: 'normal'
                                            }}
                                            inputProps={{
                                                fontSize: 16,
                                                disabled: !canEditUser
                                            }}
                                            inputRef={register({
                                                required: true,
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: 'Invalid email address',
                                                },
                                            })}
                                            message={duplicateEmail && 'An account with this email already exists.'}
                                            tone={(errors.email || duplicateEmail) && 'error'}
                                        />
                                    </Box>
                                </Grid>


                                <Grid item xs={12}>
                                    <Box my={2}>
                                        <Box fontSize={16} mb={1}>How soon are you looking to buy?</Box>
                                        <LRDropdown fullWidth
                                            disabled={!canEditUser}
                                            selectedOption={selectedTimeframe}
                                            onOptionSelect={(option)=> {
                                                setSelectedTimeframe(option);
                                                setTimeframeChanged(true);
                                            }}
                                            options={howSoonOptions}
                                            renderDisplayOption={({ label })=> <Box component="span" fontSize={16}>{label}</Box>}
                                            renderOption={({ label })=> label}
                                            DropdownDisplayProps={{ borderRadius: 0, pl: '12px' }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Preferences setAreas={setAreas} canEditUser={canEditUser} hasUserPreferences={hasUserPreferences} />

                            {!isMobile && (
                                <Can I="edit" a="User">
                                    <Box mt={2} mb={4}>
                                        <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                                            <Grid item >
                                                <LRButton
                                                    fullWidth
                                                    color="primary"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    {!profileStore.submitting && 'Save changes'}
                                                    {profileStore.submitting && <CircularProgress size={20} />}
                                                </LRButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Can>
                            )}
                        </Box>
                    </Grid>
                </form>
            </Box>
        </FormContext>
    );
}

ProfileForm.propTypes = {
    match: PropTypes.object.isRequired
};

export default observer(ProfileForm);
