// eslint-disable-next-line
import { AppBar, Box, Hidden, Slide } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LRMapModal from '../../../components/LRMapModal';
import LRModal from '../../../components/LRModal';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import { CardBoardCollaborationsModal } from '../../CardBoardCollaborationsModal';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
import { SlidyAgentInfo } from './SlidyAgentInfo';
const Container = styled(AppBar)`
    background: #fff;
    position: relative;
    z-index: 30;
    padding: 0 24px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border-bottom: 1px solid #DDDDDD;
`;

const MessengerModal = styled(LRModal)`
    & .MuiDialogContent-root {
        padding: 0;
    }

    & .paperWidthSm-listing-details {
        width: 100%;
    }
`;



const MessengerModalTransition = React.forwardRef(function Transition(props, ref) {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');

    return <Slide direction={isXs || isSm ? 'left' : 'up'} ref={ref} {...props} />;
});

function Header(props) {
    const { boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const {
        currentIndex,
        totalListings,
        laneId,
        cardId,
        listingDetails,
        buyerAgent,
        isNextBackDisabled,
        isNextDisabled,
        isPreviousDisabled,
        onPreviousClick,
        onNextClick,
        onArchiveClick,
        onMoveToChange,
        requestSource,
        contentRef,
        onClose,
        onShareClick,
        cardArchived,
        onSignupClick,
        onVerifyClick,
        hideMainHeader,
        showLogo = false,
        reportId,
    } = props;
    const [showMap, setShowMap] = useState(false);
    const { show_collab_modal } = queryString.parse(useLocation().search);
    const [showCollaborations, setShowCollaborations] = useState(show_collab_modal === 'true');
    const [messengerCloseButtonRef, setMessengerCloseButtonRef] = useState();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    // hide header if we are viewing from one of these sources
    const hideHeader = !!~['property_search'].indexOf(requestSource); 

    const showHeader = !hideHeader && !hideMainHeader;

    function handleMapOnClose() {
        setShowMap(false);
    }

    function handleShowMessengerClick() {
        setShowCollaborations(true);
    }
    

    return (
        <Box className="tour-listing-details-header">
            {
                showHeader && (
                    <Container color="default" position="relative">
                        {/* Desktop */}
                        <Hidden smDown>
                            <DesktopHeader 
                                onShareClick={onShareClick} 
                                cardId={cardId} 
                                laneId={laneId} 
                                onMoveToChange={onMoveToChange} 
                                onArchiveClick={onArchiveClick} 
                                totalListings={totalListings}
                                onPreviousClick={onPreviousClick} 
                                onNextClick={onNextClick} 
                                currentIndex={currentIndex} 
                                isNextBackDisabled={isNextBackDisabled} 
                                isNextDisabled={isNextDisabled} 
                                isPreviousDisabled={isPreviousDisabled} 
                                cardArchived={cardArchived}
                                showLogo={showLogo}
                            />
                        </Hidden>
                        {/* End Desktop */}

                        {/* Tablet and Mobile */}
                        <Hidden mdUp>
                            <MobileHeader 
                                onShareClick={onShareClick} 
                                cardId={cardId} 
                                totalListings={totalListings}
                                currentIndex={currentIndex} 
                                handleShowMessengerClick={handleShowMessengerClick} 
                                onClose={onClose} 
                                showLogo={showLogo}
                            />
                        </Hidden>
                    </Container>
                )
            }
            {/* End Tablet and Mobile */}
            <SlidyAgentInfo agent={buyerAgent} sliderRef={contentRef} onSignupClick={onSignupClick} onVerifyClick={onVerifyClick} requestSource={requestSource} />

            {/* Map Modal */}
            {showMap && (
                <LRMapModal
                    address={listingDetails.address}
                    lat={listingDetails.lat}
                    lng={listingDetails.lon}
                    onClose={handleMapOnClose}
                    requestSource={requestSource}
                    reportId={reportId}
                />
            )}
            {/* End Map Modal */}
            { showCollaborations && <CardBoardCollaborationsModal boardId={activeBoard.id} cardId={cardId} open={true}  onClose={()=> setShowCollaborations(false)} />}
        </Box>
    );
}

Header.propTypes = {
    currentIndex: PropTypes.number.isRequired,
    totalListings: PropTypes.number.isRequired,
    laneId: PropTypes.string,
    cardId: PropTypes.string,
    listingDetails: PropTypes.object.isRequired,
    isNextBackDisabled: PropTypes.bool,
    isNextDisabled: PropTypes.bool,
    isBackDisabled: PropTypes.bool,
    onNextClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
    onArchiveClick: PropTypes.func,
    onMoveToChange: PropTypes.func,
};

export default observer(Header);
