import React, { useState } from 'react';
import Logger from 'js-logger';
import { AppBar, CircularProgress, Collapse, Dialog, DialogContent, Grid, Hidden, IconButton, Slide, Toolbar, Typography, useTheme } from '@material-ui/core';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import LRButton from 'components/LRButton';
import { CallRounded, ChatBubbleRounded, ChevronLeftRounded, EmailRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@material-ui/icons';
import { ServiceProvidersIcon } from '../ServiceProvidersIcon';
import { orderBy, partial } from 'lodash';
import BusinessIcon from 'components/LRIcons/business';
import { MY_HOME_SERVICE_PROVIDER_HANDYPERSON } from '../../../constants';
import analytics from 'services/analytics';
import LRTooltip from 'components/LRTooltip';
import { useMyHomeServiceProviders } from 'hooks/useMyHome';
import { hyphenateSync } from 'hyphen/en';


const logger = Logger.get('ServiceProvidersModal');

function ServiceProvider({
    data:serviceProvider,
    style = {},
    onEditClick = (e, serviceProvider)=> {},
    ...props
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();
    const [showAllData, setShowAllData] = useState(false);

    // functions

    function toggleShowAll() {
        setShowAllData(!showAllData);
    }

    const isShowArrow = serviceProvider.email || serviceProvider.notes;

    return (
        <div
            style={{
                position: 'relative',
                padding: theme.spacing(3),
                backgroundColor: '#fff',
                borderRadius: 4,
                ...style,
            }}
            {...props}
        >
            <Grid container spacing={2} style={{ display: isMobile && 'block', }}>
                <Grid 
                    item
                    style={{
                        width: 192,
                    }}
                >
                    <div
                        style={{
                            width: 28,
                            height: 28,
                            backgroundColor: '#000',
                            color: '#fff',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ServiceProvidersIcon variant={serviceProvider.type} style={{ width: 16, height: 16, }} />
                    </div>

                    <Typography variant="h5" style={{ fontWeight: 500, marginTop: theme.spacing(1), }}>
                        {serviceProvider.type}
                    </Typography>
                </Grid>

                <Grid item xs>
                    {serviceProvider.name && (
                        <Typography
                            variant="body1"
                            style={{
                                fontWeight: 500,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            {serviceProvider.name && hyphenateSync(serviceProvider.name)}
                        </Typography>
                    )}


                    {serviceProvider.company && (
                        <Grid container justify="flex-start">
                            <Grid item style={{ marginRight: theme.spacing(2), }}>
                                <BusinessIcon style={{ color: theme.palette.lr_colors.steak_sauce, width: 16, height: 16, position: 'relative', top: 4, }} />
                            </Grid>
                            
                            <Grid item xs>
                                {/* Here we are using German language, because we want hyphens to occur when the space is small for the text, does not work that well with english language.*/}
                                <Typography variant="body1">
                                    {serviceProvider.company && hyphenateSync(serviceProvider.company)}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {serviceProvider.phone_formatted && (
                        <Grid container justify="flex-start">
                            <Grid item style={{ marginRight: theme.spacing(2), }}>
                                <CallRounded style={{ color: theme.palette.lr_colors.steak_sauce, width: 16, height: 16, position: 'relative', top: 4, }} />
                            </Grid>
                            
                            <Grid item xs>
                                <Typography variant="body1">
                                    {serviceProvider.phone_formatted}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    
                    <Collapse in={showAllData}>
                        {serviceProvider.email && (
                            <Grid container justify="flex-start">
                                <Grid item style={{ marginRight: theme.spacing(2), }}>
                                    <EmailRounded style={{ color: theme.palette.lr_colors.steak_sauce, width: 16, height: 16, position: 'relative', top: 5, }} />
                                </Grid>
                                
                                <Grid item xs>
                                    <Typography variant="body1">
                                        {serviceProvider.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {serviceProvider.notes && (
                            <Grid container justify="flex-start">
                                <Grid item style={{ marginRight: theme.spacing(2), }}>
                                    <ChatBubbleRounded style={{ color: theme.palette.lr_colors.steak_sauce, width: 16, height: 16, position: 'relative', top: 4, }} />
                                </Grid>
                                
                                <Grid item xs>
                                    <Typography variant="body1">
                                        "{serviceProvider.notes}"
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Collapse>
                </Grid>

                <Grid item style={{ width: 96, }}></Grid>
            </Grid>

            <Grid 
                container
                spacing={2}
                justify="flex-end"
                style={{
                    position: 'absolute',
                    top: theme.spacing(3),
                    right: theme.spacing(3),
                }}
            >
                <Grid item>
                    <LRButton 
                        variant="text" 
                        inline 
                        underline 
                        onClick={analytics.eventTrackOnEvent('robin_home_report_service_provider_details_edit_link_click', null, partial(onEditClick, partial.placeholder, serviceProvider))}
                    >
                        Edit
                    </LRButton>
                </Grid>
                <Grid item>
                    {isShowArrow &&
                        <LRButton variant="text" inline onClick={toggleShowAll}>
                            {showAllData ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                        </LRButton>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export function ServiceProvidersModal({
    open = false,
    isAgentOrLO = false,
    closeCTAText,
    TransitionComponent = Slide,
    TransitionProps = {},
    style = {},
    PaperProps = {},
    onAddServiceProviderClick = (e)=> {},
    onCloseClick = (e)=> {},
    onEditClick = (e, serviceProvider)=> {},
    ...props
}) {
    const theme = useTheme();
    const serviceProviders = useMyHomeServiceProviders();
    const {
        isMobile,
    } = useDeviceBreakpoints();

    return (
        <Dialog 
            disableEnforceFocus
            fullScreen
            open={open} 
            style={{
                ...style,
            }}
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.lr_colors.grey_light,
                    ...(PaperProps?.style || {}),
                },
                ...PaperProps,
            }}
            TransitionComponent={TransitionComponent}
            TransitionProps={{
                direction: 'left',
                ...TransitionProps,
            }}
            {...props}
        >
            <AppBar color="inherit" elevation={0} style={{ border: '1px solid', borderColor: theme.palette.lr_colors.border_grey, backgroundColor: '#fff', }}>
                <Toolbar>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={1} sm={3}>
                            {!isMobile ? (
                                <LRButton startIcon={<ChevronLeftRounded />} onClick={onCloseClick}>
                                    {closeCTAText ? closeCTAText : 'Home Report'}
                                </LRButton>
                            ) : (
                                <IconButton edge="start" onClick={onCloseClick}>
                                    <ChevronLeftRounded />
                                </IconButton>
                            )}
                        </Grid>

                        <Grid item>
                            <Typography>
                                Service providers
                            </Typography>
                        </Grid>

                        <Grid item xs={1} sm={3} style={{ textAlign: 'right', }}>
                            <Hidden xsDown>
                                <LRTooltip title={isAgentOrLO && 'Only a buyer or co-buyer can add service providers to their Home Report.'} placement="bottom" hideArrow={false}>
                                    <span>
                                        <LRButton
                                            variant="contained"
                                            color="primary"
                                            onClick={analytics.eventTrackOnEvent('robin_home_report_service_provider_details_add_new_button_click', null, onAddServiceProviderClick)}
                                            disabled={isAgentOrLO}
                                        >Add new</LRButton>
                                    </span>
                                </LRTooltip>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Toolbar />

            <DialogContent style={{ paddingRight: isMobile && 0, paddingLeft: isMobile && 0, }}>
                {serviceProviders.isLoading && (
                    <Grid container justify="center" style={{ padding: theme.spacing(3), }}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}

                <div
                    style={{
                        width: '100%',
                        maxWidth: 688,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    {orderBy(serviceProviders?.data, ['type', 'createdAt'])?.map((serviceProvider)=> (
                        <ServiceProvider 
                            key={serviceProvider.id} 
                            data={serviceProvider} 
                            style={{ marginTop: theme.spacing(isMobile ? 2 : 3), }} 
                            onEditClick={onEditClick}
                        />
                    ))}

                    {!Boolean(serviceProviders?.data?.length) && !serviceProviders.isLoading && (
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs style={{ padding: theme.spacing(4), }}>
                                <div
                                    style={{
                                        width: 48,
                                        height: 48,
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '0 auto 16px',
                                    }}
                                >
                                    <ServiceProvidersIcon variant={MY_HOME_SERVICE_PROVIDER_HANDYPERSON} style={{ width: 27, height: 27, }} />
                                </div>

                                <Typography variant="h6" style={{ fontWeight: 'normal', textAlign: 'center', }}>
                                    Add your service providers here to keep them all in one place.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </div>

                <br/>
                <br/>
                <br/>
            </DialogContent>

            <Hidden smUp>
                <Toolbar />
                <AppBar 
                    color="inherit" 
                    className="HeaderNavBar-root" 
                    elevation={0}  
                    style={{
                        borderTop: '1px solid #ddd',
                        top: 'auto',
                        bottom: 0,
                        paddingTop: 6,
                        paddingBottom: 6,
                    }}
                    {...props}
                >
                    <Toolbar>
                        <Grid container justify="space-between">
                            <Grid item xs>
                                <LRTooltip title={isAgentOrLO && 'Only a buyer or co-buyer can add service providers to their Home Report.'} placement="top" hideArrow={false}>
                                    <span>
                                        <LRButton
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={analytics.eventTrackOnEvent('robin_home_report_service_provider_details_add_new_button_click', null, onAddServiceProviderClick)}
                                            disabled={isAgentOrLO}
                                        >Add new</LRButton>
                                    </span>
                                </LRTooltip>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Hidden>
        </Dialog>
    );
}
