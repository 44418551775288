import React, { useMemo, useState } from 'react';
import _ from 'lodash';

export function TabPanel({ value, index, children, ...props }) {
    return (
        <div role="tabpanel" hidden={value !== index} {...props}>
            {children}
        </div>
    );
}

export function usePaginatedCollection(collection, { initialPageSize, newPageSize }) {
    const [limit, setLimit] = useState(initialPageSize);
    const paginatedCollection = useMemo(()=> _.take(collection, limit), [collection, limit]);
    const hasMore = paginatedCollection.length < collection.length;

    function loadMore() {
        const increment = newPageSize || initialPageSize;
        setLimit(Math.min(limit + increment, collection.length));
    }

    return {
        loadMore,
        hasMore,
        paginatedCollection,
    };
}
