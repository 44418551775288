import React from 'react';
import { observer } from 'mobx-react-lite';
import { LRAreaAutocompleteV2 } from 'components/LRAreaAutocomplete';
import { Divider, Typography, useTheme } from '@material-ui/core';
import { Business, Home, Room, School } from '@material-ui/icons';
import _ from 'lodash';

export const AreaSearchInput = observer(({
    addArea = ()=> {},
    disabled = false,
})=> {
    const theme = useTheme();
    function onAreaChange(area) {
        area?.id && addArea(area?.id);
    }

    const suggestionPlural = {
        'city': 'CITIES',
        'neighborhood': 'NEIGHBORHOODS',
        'school': 'SCHOOLS',
        'zip': 'ZIPS'
    };

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
            case 'zip':
                iconElement = <Business fontSize="small" style={{ color: '#DADADA' }} />;
                break;
            case 'neighborhood':
                iconElement = <Home fontSize="small" style={{ color: '#DADADA' }}/>;
                break;
            case 'school':
                iconElement = <School fontSize="small" style={{ color: '#DADADA' }}/>;
                break;
            default:
                iconElement = <Room fontSize="small" style={{ color: '#DADADA' }}/>;
                break;
        }
        return iconElement;
    }

    return (
        <LRAreaAutocompleteV2
            disabled={disabled}
            name="area"
            noOptionsText="No Area found"
            onChange={onAreaChange}
            groupBy={(option)=> option.type}
            clearOnSelect={true}
            placeholder='City, ZIP, school, neighborhood'
            autoSelect={false}
            freeSolo={true}
            InputProps={{
                ...(disabled && {
                    style: {
                        backgroundColor: theme.palette.lr_colors.grey_medium_second}
                }),
            }}
            renderGroup={({key, children})=> {
                return ( 
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignContent: 'center',
                            alignItems: 'center',
                            paddingLeft: theme.spacing(1),
                            paddingTop: theme.spacing(1),
                            marginLeft: theme.spacing(1) + 6,
                        }}>
                            {renderSuggestionIcon(key)}
                            <Typography style={{padding: theme.spacing(1), color: '#DADADA'}}>{suggestionPlural[key]}</Typography>
                        </div>
                        {_.map(children, (option)=> <Typography
                            variant={'body1'}
                            style={{padding: 4}}
                        >
                            {option}
                        </Typography>)}
                        <Divider/>
                    </div>
                );
            }}
        />
    );
});
