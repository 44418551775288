// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, useMediaQuery, withTheme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Carousel from './Carousel';
import List from './List';
import Header from './Header';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import analytics from '../../services/analytics';

const StyledDialog = withTheme(styled(Dialog)`
    & .MuiDialog-paperWidthSm {
        background: #000;

        ${({ theme })=> theme.breakpoints.down('sm')} {
            background: #fff;
        }
    }
`);
const StyledDialogContent = styled(DialogContent)`
    padding: 0;
    width: 100%;
    margin: 0 auto;
`;

function FullscreenImageGalleryModal({
    listing,
    photos,
    onClose,
    requestSource,
    className = '',
    selectedPhotoIndex,
    reportId,
}) {
    const [isOpen, setIsOpen] = useState(true);
    const [toggleView, setToggleView] = useState('list');
    const theme = useTheme();
    const gtXs = useMediaQuery(theme.breakpoints.up('sm'));
    const { ListpackStore, PinspirationStore, boardsStore } = useStore();
    const { listpack } = ListpackStore;
    const ref = React.createRef();
    const [selectedPhoto, setSelectedPhoto] = useState(selectedPhotoIndex);
    const defaultSelectedPhotoIndex = 0;
    const { id: listingId } = listing || {};
    const { id: listpackId } = listpack || {};
    const { id: boardId = null } = boardsStore.activeBoard || {};

    useEffect(()=> {
        async function fetchCollaborations() {
            await PinspirationStore.getCollaborationsByListingId(boardId, listingId);
        }
        if(listingId && boardId) {
            fetchCollaborations();
        }
    }, [listingId, boardId, PinspirationStore]);

    /**
     * Closes dialog
     *
     * @function handleOnCloseClick
     */
    function handleOnCloseClick() {
        setIsOpen(false);
    }

    /**
     * Set the toggle view
     *
     * @function handleViewToggleChange
     */
    function handleViewToggleChange(e, value) {
        let eventName;
        if(value === 'carousel') {
            setSelectedPhoto(defaultSelectedPhotoIndex);
            eventName = 'listpacks_consumer_listingpage_gallery_scroll_click';
        } else if(value === 'list') {
            eventName = 'listpacks_consumer_listingpage_gallery_list_button_click';
        }
        analytics.eventTrack(eventName, {
            address: listing.address,
            'listing id': listing.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            source: requestSource,
            report_id: reportId,
        });
        setToggleView(value);
    }

    return (
        <StyledDialog
            fullScreen={true}
            open={isOpen}
            onExited={onClose}
            className={`${className} tour-target-listing-image-gallery-modal`}
        >
            <Header
                defaultToggleValue={toggleView}
                onClose={handleOnCloseClick}
                onViewToggleChange={handleViewToggleChange}
                listing={listing}
                requestSource={requestSource}
            />

            <StyledDialogContent ref={ref}>
                {/* Carousel View */}
                {toggleView === 'carousel' && gtXs &&
                  <Carousel
                      listing={listing}
                      photos={photos}
                      requestSource={requestSource}
                      listingId={listingId}
                      listpackId={listpackId}
                      reportId={reportId}
                  />
                }

                {/* List View */}
                {(toggleView === 'list' || !gtXs) &&
                  <List
                      listing={listing}
                      photos={photos}
                      selectedPhotoIndex={selectedPhoto}
                      parentRef={ref}
                      listingId={listingId}
                      listpackId={listpackId}
                  />
                }
            </StyledDialogContent>
        </StyledDialog>
    );
}
FullscreenImageGalleryModal.propTypes = {
    photos: PropTypes.array.isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    selectedPhotoIndex: PropTypes.number.isRequired,
};

export default observer(FullscreenImageGalleryModal);
