import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Info } from '@material-ui/icons';
import addCommas from '../../../../../services/add-commas';
import _ from 'lodash';
import moment from 'moment';
import { useCompareNowStoreContext } from '../../../store';

const renderFieldsByType = {
    money: (value)=> {
        return '$' + addCommas(value);
    },
    string: (value)=> {
        return value;
    },
    date: (value)=> {
        return moment()
            .diff(moment(value), 'days');
    },
    number: function(value) {
        return addCommas(value);
    },
    boolean: function(value) {
        return value ? 'Yes' : 'No';
    },
    array: function(value) {
        return value.join(', ');
    },
    associationFee: function(value) {
        var feeObj = _.cloneDeep(value);
        if(feeObj.value != null) {
            feeObj = feeObj.value;
            feeObj = addCommas(feeObj);
        }
        if(value.currencyPeriod) {
            feeObj = feeObj + ' ' + value.currencyPeriod;
        }
        return '$' + feeObj;
    }
};

const LightTooltip = withStyles((theme)=> ({
    tooltip: {
        backgroundColor: 'white',
        color: '#3A3A3A',
        boxShadow: theme.shadows[3],
        fontSize: 11
    }
}))(Tooltip);

function FieldsTable({ listings, fields }) {
    const store = useCompareNowStoreContext();
    const getFormatedField = (field, listing)=> {
        var value =  (listing && listing?.listing_details && field?.keyName && listing?.listing_details[field.keyName] ) ? listing?.listing_details[field.keyName] : null;
        return value == null ? '--' : renderFieldsByType[field.type](value);
    };

    const hasDiffInfo = (myPrefer, listingField)=> {
        return !(myPrefer === listingField);
    };

    return (
        <Box display="flex" justifyContent="center">
            <Box width="100%">
                {fields.map((field)=> (
                    <Box
                        key={field.name}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="stretch"
                        borderBottom="1px solid #E3E3E3"
                        minHeight="40px"
                        color="lr_colors.grade"
                    >
                        <Box display="flex" flex="1" alignItems="center" minWidth="85px">
                            <Box fontSize="14px" pl="16px">
                                {field.name}
                            </Box>
                        </Box>
                        {listings.map((listing, index)=> (
                            <Box
                                key={listing.id}
                                width="240px"
                                borderLeft={index === 0 ? '' : '1px solid #E3E3E3'}
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                position="relative"
                            >
                                <Typography component="div">
                                    <Box
                                        pl="16px"
                                        pr="16px"
                                        pt="10px"
                                        pb="10px"
                                        fontSize="14px"
                                        fontWeight="fontWeightMedium"
                                    >
                                        {getFormatedField(field, listing)}
                                    </Box>
                                </Typography>
                            </Box>
                        ))}
                        <Box flex="1" />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

FieldsTable.propTypes = {
    listings: PropTypes.array,
    fields: PropTypes.array
};

export default FieldsTable;
