import React, { useEffect, useState } from 'react';
import { Divider, Slide, useTheme } from '@material-ui/core';
import { useResponsive, useIsScrollPositionWithinOffset } from 'hooks';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStore } from 'mobx-store';
import { benoitApi } from 'apis';
import { VerificationBanner } from 'components/VerificationBanner';
import { SignUpModal } from 'components/VerificationBanner/SignUpModal';
import { VerifyModal } from 'components/VerificationBanner/VerifyModal';
import GalleryPreview from './GalleryPreview';
import BasicInfo from './BasicInfo';
import ListingAgent from './ListingAgent';
import PropertyDescription from './PropertyDescription';
import VirtualAndOpenHouses from './VirtualAndOpenHouses';
import Details from './Details';
import Schools from './Schools';
import AreaReport from './AreaReport';
import PaymentCalculator from './PaymentCalculator';
import YourTeam from './YourTeam';
import TermsAndConditions from './TermsAndConditions';
import AgentBrandingHeader from './AgentBrandingHeader';
import ListingBadges from './ListingBadges';
import analytics from '../../services/analytics';
import Logger from 'js-logger';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useQuery } from 'hooks';
import { useIsElementVisible } from 'hooks/useIsElementVisible';

const emptyObj = {};
export const Listing = observer(function PropertyDetail({
    listing,
    listingName,
    card,
    listpack,
    requestSource = 'newlisting_savedsearch_email',
    buyerAgent,
    loanOfficer,
    onEditListingName,
    onSchedulePrivateTour,
    trackEventData = emptyObj,
    readOnly= false,
    GalleryPreviewProps = {},
    onContactAgentClick,
}) {
    const theme = useTheme();
    const r = useResponsive();
    const { isMobile } =  useDeviceBreakpoints();
    const { RootStore, UserStore } = useStore();
    const prodMode = RootStore.inProductionMode;
    const usersBoardRole = UserStore.usersBoardRole;
    const [enabledAreaReport, setEnabledAreaReport] = useState(false);
    const [areaReportElement, setAreaReportElement] = useState(null);
    const [scrollableElement, setScrollableElement] = useState(null);
    const isAreaElementVisible = useIsElementVisible(areaReportElement, scrollableElement);
    const visibleAgentBranding = useIsScrollPositionWithinOffset(54, scrollableElement);
    const [pageViewTracked, setPageViewTracked] = useState(false);
    const { user, isBannerVisible } = UserStore;
    const match = useRouteMatch();
    const history = useHistory();
    const showBanner = isBannerVisible && !['property_search', 'consumer_view'].includes(requestSource);
    const queryParamListpackId = useQuery().get('listpack_id');
    const queryParamSlug = useQuery().get('slug');
    const userId = user?.id;
    const buyerAgentId = buyerAgent?.id;

    // Functions

    async function handleOnVerifyClick() {
        const newToken = await benoitApi.requestLogin('phone', user?.phone);
        history.push(`${match.url}/verify?login_token=${newToken.token}`);
    }

    function handleOnSignupClick() {
        history.push(`${match.url}/signup?invite_id=${user.meta.boardUserInviteId}`);
    }

    function redirectToTheEnd(source) {
        if(onContactAgentClick) {onContactAgentClick(source);}
    }

    // UseEffects

    useEffect(()=> {
        if(!listing) return;

        if(!pageViewTracked && UserStore.usersBoardRole !=='') {
            analytics.eventTrack('listpacks_consumer_listing_page_viewed', {
                address: listing?.address,
                'listing id': listing?.id,
                listing_id: card?.content?.listing_id,
                listpack_id: listpack?.id,
                type: queryParamListpackId,
                slug: queryParamSlug,
                source: requestSource,
                role: UserStore.usersBoardRole,
                distinct_id: userId || buyerAgentId,
            });
            setPageViewTracked(true);
        }
    }, [listpack, listing, requestSource, usersBoardRole, pageViewTracked, card, queryParamListpackId, queryParamSlug]);

    useEffect(()=> {
        if(!scrollableElement) return;
        scrollableElement.scrollTo({ top: 0 });
    }, [listing?.id, scrollableElement]);

    useEffect(()=> {
        if(!listing) {
            setEnabledAreaReport(prodMode);
        }
    }, [listing, prodMode]);

    useEffect(()=> {
        setEnabledAreaReport((currentEnabledAreaReport)=> {
            if(!currentEnabledAreaReport) {
                return isAreaElementVisible;
            }
        });
    }, [isAreaElementVisible]);

    return (
        <div
            style={{
                height: '100%',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                <Slide in={visibleAgentBranding} direction="down" timeout={700}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            backgroundColor: 'white',
                            boxShadow:
                                '0px 2px 4px -4px rgba(0,0,0,0.2), 0px 4px 5px -5px rgba(0,0,0,0.14), 0px 1px 10px -10px rgba(0,0,0,0.12)',
                        }}
                    >
                        {buyerAgent && <AgentBrandingHeader
                            agent={buyerAgent}
                            hideLicenseNumber={isMobile}
                        />}
                    </div>
                </Slide>
            </div>
            <div
                ref={setScrollableElement}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingTop: buyerAgent ? theme.spacing(8) : 0,
                }}
            >
                <div>
                    {showBanner && <VerificationBanner source="rpp" onVerifyClick={handleOnVerifyClick} onSignupClick={handleOnSignupClick}/>}
                </div>
                <div style={{ position: 'relative' }}>
                    <GalleryPreview listing={listing} card={card} listpack={listpack} readOnly={readOnly} {...GalleryPreviewProps} />
                    <div style={{ position: 'absolute', left: 0, top: 0, zIndex: 5 }}>
                        <ListingBadges listing={listing} card={card} listpack={listpack} />
                    </div>
                </div>
                <div
                    className="space-y-6"
                    style={{
                        padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
                        marginTop: r({ xs: 0, sm: -theme.spacing(3) }),
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <div className="space-y-6">
                        <BasicInfo listingName={listingName} onEditListingName={onEditListingName} listing={listing} />
                        <div
                            style={{
                                padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                            }}
                        >
                            <ListingAgent listing={listing}/>
                            <div style={{height: theme.spacing(1.5)}}/>
                            <PropertyDescription listing={listing}/>
                        </div>
                    </div>
                    <Divider />
                    <div
                        style={{
                            padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                        }}
                    >
                        <VirtualAndOpenHouses
                            listing={listing}
                            card={card}
                            onSchedulePrivateTour={onSchedulePrivateTour}
                            trackEventData={trackEventData}
                            listpack={listpack}
                            onContactAgentClick={redirectToTheEnd}
                            readOnly={readOnly}
                        />
                    </div>
                    <Divider />
                    <div
                        style={{
                            padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                        }}
                    >
                        <Details listing={listing} onContactAgentClick={redirectToTheEnd}/>
                    </div>
                    {listing && <Divider />}
                    <div
                        style={{
                            padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                        }}
                    >
                        <Schools listing={listing} />
                    </div>
                    {listing && <Divider />}
                    {listing && (
                        <div
                            ref={setAreaReportElement}
                            style={{
                                padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                            }}
                        >
                            <AreaReport
                                listing={listing}
                                enabled={enabledAreaReport}
                                onContactAgentClick={onContactAgentClick}
                            />
                        </div>
                    )}
                    {listing && <Divider />}
                    <div
                        style={{
                            padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                        }}
                    >
                        <PaymentCalculator listing={listing} />
                    </div>
                    {buyerAgent && (
                        <>
                            <Divider />
                            <div
                                style={{
                                    padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                                }}
                            >
                                <YourTeam buyerAgent={buyerAgent} loanOfficer={loanOfficer} />
                            </div>
                        </>
                    )}
                    {listing && <Divider />}
                    <div
                        style={{
                            padding: r({ xs: 0, sm: `0 ${theme.spacing(2)}px` }),
                        }}
                    >
                        <TermsAndConditions listing={listing} buyerAgent={buyerAgent} />
                    </div>
                </div>
            </div>
            <Route
                path={`${match?.path}/signup`}
                render={()=> (
                    <SignUpModal onClose={()=> history.push(match.url)}/>
                )}
            />
            <Route
                path={`${match?.path}/verify`}
                render={()=> (
                    <VerifyModal onClose={()=> history.push(match.url) }/>
                )}
            />
        </div>
    );
});

Listing.propTypes = {
    listing: PropTypes.object,
    card: PropTypes.object,
    listingName: PropTypes.string,
    onEditListingName: PropTypes.func,
    buyerAgent: PropTypes.object,
    loanOfficer: PropTypes.object,
    onSchedulePrivateTour: PropTypes.func,
};
