import React, { useCallback, useState, createContext, useContext, useRef } from 'react';
import { Box, Icon, useTheme, ClickAwayListener, Popper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useToggleState, useDimensions } from '../../hooks';

const DropdownContext = React.createContext();

const useDropdownToggleState = function() {
    return useContext(DropdownContext);
};

const DropdownMenu = React.forwardRef((props, ref)=> {
    const theme = useTheme();
    const { options = [], onOptionSelect, renderOption, maxOptionListHeight, selectedOption,  dropdownOptionProps= {}, dropdownMenuProps= {}  } = props;
    const { close: closeMenu } = useDropdownToggleState();

    const handleOnSelect = useCallback(function(option) {
        if(onOptionSelect) {
            onOptionSelect(option);
        }
        closeMenu();
    }, [onOptionSelect, closeMenu]);

    return (
        <Box
            zIndex={10} top="100%"
            border={`1px solid ${theme.palette.lr_colors.border_grey}`}
            width={1}
            mt={0.5}
            bgcolor="#FFF"
            maxHeight={maxOptionListHeight}
            style={{ borderRadius: '4px', cursor: 'pointer', overflowY: maxOptionListHeight ? 'scroll' : 'hidden' }}
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
            data-testid="options"
            ref={ref}
            {...dropdownMenuProps}
        >
            {
                options.map((option, index)=> (
                    <DropdownOption key={index} option={option} onSelect={handleOnSelect} renderOption={renderOption} dropdownOptionProps={dropdownOptionProps}/>
                ))
            }
        </Box>
    );
});

function LRDropdown({
    value,
    selectedOption, // Value variables
    options,
    onOptionSelect,
    onChange, // onChange functions
    name,
    renderDisplayOption,
    renderOption,
    maxOptionListHeight,
    fullWidth,
    textFieldIcon,
    textFieldIconProps,
    height,
    DropdownDisplayProps,
    disabled,
    placement = 'bottom',
    placeholder,
    dropdownOptionProps={},
    dropdownMenuProps={},
    minWidth,
}){
    const toggleMenuState = useToggleState();
    const { isOpen: isMenuOpen, close: closeMenu } = toggleMenuState;
    const [anchorEl, setAnchorEl] = useState(null);
    const [containerRef, containerDimensions] = useDimensions();

    const currentValue = value || selectedOption;

    function onChangeHandler(props) {
        if(onOptionSelect) return onOptionSelect(props);
        else if(onChange) onChange({ target: { value: props, name } });
    }

    return (
        <DropdownContext.Provider value={toggleMenuState}>
            <Box
                minWidth={minWidth || 120}
                width={fullWidth ? 1 : undefined}
                position="relative"
                onClick={(e)=> setAnchorEl(e.currentTarget)}
                ref={containerRef}
            >
                <DropdownDisplay
                    selectedOption={currentValue}
                    renderDisplayOption={renderDisplayOption}
                    textFieldIcon={textFieldIcon}
                    textFieldIconProps={textFieldIconProps}
                    height={height}
                    DropdownDisplayProps={DropdownDisplayProps}
                    placeholder={placeholder}
                />
                {!disabled && (
                    <Popper
                        open={isMenuOpen}
                        anchorEl={anchorEl}
                        placement={placement}
                        style={{ width: containerDimensions.width }}
                        className="popper-dropdown"
                    >
                        <ClickAwayListener onClickAway={()=> closeMenu()} >
                            <DropdownMenu
                                options={options}
                                onOptionSelect={onChangeHandler}
                                renderOption={renderOption}
                                maxOptionListHeight={maxOptionListHeight}
                                dropdownOptionProps={dropdownOptionProps}
                                dropdownMenuProps={dropdownMenuProps}
                            />
                        </ClickAwayListener>
                    </Popper>
                )}
            </Box>
        </DropdownContext.Provider>
    );
}

function DropdownOption({ option, onSelect, renderOption, dropdownOptionProps={} }){
    const theme = useTheme();
    return (
        <Box
            py={1} px={1}
            onClick={()=> {
                if(option.disabled) {
                    return;
                }
                onSelect(option);
            }}
            color={option?.disabled ? theme.palette.lr_colors.grey_dark : 'inherit'}
            data-testid="option-item"
            {...dropdownOptionProps}
        >
            {renderOption ? renderOption(option): option}
        </Box>
    );
}

function DropdownDisplay({
    selectedOption,
    renderDisplayOption,
    textFieldIcon = ExpandMoreIcon,
    textFieldIconProps = {},
    height=null,
    DropdownDisplayProps = {},
    placeholder
}){
    const { toggle: toggleMenu } = useDropdownToggleState();

    return (
        <Box
            style={{ cursor: 'pointer' }}
            onClick={()=> toggleMenu()}
            display="flex"
            alignItems="center"
            borderColor='lr_colors.border_grey'
            bgcolor="#ffffff"
            border={1}
            borderRadius={4}
            height={height || 42}
            pl={'14px'}
            pr={4}
            position="relative"
            data-testid="display"
            {...DropdownDisplayProps}
        >

            { selectedOption && (renderDisplayOption ? renderDisplayOption(selectedOption) : selectedOption) }
            { !selectedOption && placeholder && (
                <Box fontSize="body1.fontSize" color="lr_colors.steak_sauce">{placeholder}</Box>
            ) }
            <Box position="absolute" display="flex" alignItems="center" right={10} top={0} bottom={0}>
                <Icon component={textFieldIcon} {...textFieldIconProps} fontSize="small"/>
            </Box>
        </Box>
    );
}


export default LRDropdown;
