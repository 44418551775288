import React from 'react';
import ReactDOM from 'react-dom';

import { Box, Hidden } from '@material-ui/core';

const mobileFooterContainer = document.querySelector('.mobile-footer-actions-container');

function MobileFooterActionsContent({ children }) {
    return (
        <Box height="1px" marginTop="66px">
            <Box
                position="fixed"
                bottom={0}
                width={1}
                boxShadow="0px 2px 20px rgba(0, 0, 0, 0.2);"
                bgcolor="white"
                zIndex={9999}
            >
                <Box p={1.5}>{children}</Box>
            </Box>
        </Box>
    );
}

export function MobileFooterActions({ children, forceInplaceSpacing = false }) {

    return (
        <Hidden smUp>
            <Box mt={forceInplaceSpacing ? '66px' : 0}>
                {ReactDOM.createPortal(
                    <MobileFooterActionsContent>{children}</MobileFooterActionsContent>,
                    mobileFooterContainer
                )}
            </Box>
        </Hidden>
    );
}
