import { AppBar, Box, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import LRButton from '../../../components/LRButton';
import { useStore } from '../../../mobx-store';
import { LoggedIn } from './LoggedIn';
const Logo = styled.img`
    max-height: 20px;
`;

function Footer({
    laneId,
    cardId,
    onMoveToChange,
    onArchiveClick,
    isNextBackDisabled,
    isNextDisabled,
    isBackDisabled,
    onNextClick,
    onPreviousClick,
    currentIndex,
    totalListings,
    cardArchived,
}) {
    const { UserStore } = useStore();

    return (
        <Box clone boxShadow="none" borderTop="1px solid #ddd">
            <AppBar position="static" component="footer" style={{ height: '64px', boxShadow: 'none', backgroundColor: '#fff', padding: '8px 0' }}>
                {/* Logged In */}
                {UserStore.isLoggedIn && (
                    <LoggedIn 
                        currentIndex={currentIndex} 
                        totalListings={totalListings}
                        laneId={laneId} 
                        cardId={cardId} 
                        onMoveToChange={onMoveToChange} 
                        onArchiveClick={onArchiveClick}
                        isNextBackDisabled={isNextBackDisabled} 
                        isNextDisabled={isNextDisabled} 
                        isBackDisabled={isBackDisabled} 
                        onNextClick={onNextClick} 
                        onPreviousClick={onPreviousClick} 
                        cardArchived={cardArchived}
                    />
                )}

                {/* Logged Out */}
                {!UserStore.isLoggedIn && (
                    <Box p={2}>
                        <Box clone height="100%">
                            <Grid container alignItems="center" justify="space-between">
                                <Box clone pr={2}>
                                    <Grid item>
                                        <Logo src="/images/logo.png" />
                                    </Grid>
                                </Box>

                                <Grid item>
                                    <LRButton variant="contained" color="primary">
                                        Sign up or login
                                    </LRButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </AppBar>
        </Box>
    );
}

export default React.memo(Footer);
