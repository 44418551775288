import React, { useContext } from 'react';
import { decorate, observable, action } from 'mobx';

export class AddAnyHomeStore {
    newHomeDetails = {
        price: '',
        beds: '',
        baths: '',
        home_size: '',
        photos: ''
    };
    closing = false;
    searchingListing = false;
    listingNotFound = null;
    nameHomeForm = false;
    newFavorite = null;
    matchedListings = [];
    listingPreviewIds = [];
    matchedListing = null;
    addingListing = false;
    notes = {};

    setNewHomeDetails(homeDetailsValue) {
        this.newHomeDetails = homeDetailsValue;
    }
    
    setClosing(closingValue) {
        this.closing = closingValue;
    }

    setSearchingListing(searchingListingResult) {
        this.searchingListing = searchingListingResult;
    }

    setListingNotFound(listingNotFoundResult) {
        this.listingNotFound = listingNotFoundResult;
    }

    setNameHomeForm(nameHomeFormValue) {
        this.nameHomeForm = nameHomeFormValue;
    }

    setNewFavorite(newFavoriteValue) {
        this.newFavorite = newFavoriteValue;
    }

    setAddingListings(newAddListingsValue) {
        this.addingListings = newAddListingsValue;
    }

    setMatchedListings(matchedListingsValue) {
        this.matchedListings = matchedListingsValue;
    }

    setListingPreviewIds(listingPreviewIds) {
        this.listingPreviewIds = listingPreviewIds;
    }

    setMatchedListing(matchedListing) {
        this.matchedListing = matchedListing;
    }

    setAddingListing(addingListing) {
        this.addingListing = addingListing;
    }

    setNotes(notes) {
        this.notes = notes;
    }
}

decorate(AddAnyHomeStore, {
    closing: observable,
    searchingListing: observable,
    listingNotFound: observable,
    nameHomeForm: observable,
    newFavorite: observable,
    newHomeDetails: observable,
    addingListings: observable,
    listingPreviewIds: observable,
    matchedListings: observable,
    matchedListing: observable,
    addingListing: observable,
    notes: observable,

    setNewHomeDetails: action,
    setClosing: action,
    setSearchingListing: action,
    setListingNotFound: action,
    setNameHomeForm: action,
    setSearchTerm: action,
    setNewFavorite: action,
    searchResult: action,
    setListingPreviewIds: action,
    setMatchedListings: action,
    setMatchedListing: action,
    setAddingListings: action,
    setAddingListing: action,
    setNotes: action,
});
