import React from 'react';

export default function Warning({color= null, filledVersion = true}) {
    return filledVersion
        ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.98024 13.6691H13.0202C14.0469 13.6691 14.6869 12.5558 14.1736 11.6691L9.15358 2.99581C8.64024 2.10914 7.36024 2.10914 6.84691 2.99581L1.82691 11.6691C1.31358 12.5558 1.95358 13.6691 2.98024 13.6691ZM8.00024 9.00248C7.63358 9.00248 7.33358 8.70248 7.33358 8.33581V7.00248C7.33358 6.63581 7.63358 6.33581 8.00024 6.33581C8.36691 6.33581 8.66691 6.63581 8.66691 7.00248V8.33581C8.66691 8.70248 8.36691 9.00248 8.00024 9.00248ZM7.33358 11.6691H8.66691V10.3358H7.33358V11.6691Z" fill={color || '#FFC85A' }/>
        </svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.72506 17.086C2.44173 17.086 1.64173 15.6943 2.28339 14.586L8.55839 3.74434C9.20006 2.636 10.8001 2.636 11.4417 3.74434L17.7167 14.586C18.3584 15.6943 17.5584 17.086 16.2751 17.086H3.72506ZM16.2751 15.4193L10.0001 4.57767L3.72506 15.4193H16.2751ZM9.16673 8.75267V10.4193C9.16673 10.8777 9.54173 11.2527 10.0001 11.2527C10.4584 11.2527 10.8334 10.8777 10.8334 10.4193V8.75267C10.8334 8.29434 10.4584 7.91934 10.0001 7.91934C9.54173 7.91934 9.16673 8.29434 9.16673 8.75267ZM10.8334 14.586V12.9193H9.16673V14.586H10.8334Z" fill={color || '#FF6259'}/>
        </svg>;

}