import React, { useState, useEffect } from 'react';
import { Box, Dialog, InputBase, InputAdornment } from '@material-ui/core';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../mobx-store';
import { COLOR_GREY_DARK } from '../../../../../constants';
import { Close } from '@material-ui/icons';
import analytics from '../../../../../services/analytics';

import LRButton from '../../../../LRButton';

import { useResponsiveBreakpoint } from '../../../../../hooks';

const Input = styled(InputBase)`
    background: #fff;

    input {
        position: relative;
        color: #000;
        line-height: 1;
        font-size: 28px;
        padding: 0;
        text-align: center;
    }

    &::placeholder {
        color: #d8d8d8;
    }

    &.text-right {
        input {
            text-align: right;
        }
    }
`;

const StyledInputAdornment = styled(InputAdornment)`
    height: 16px;
    & p {
        color: #dddddd;
        font-size: 28px;
        line-height: 1;
    }
`;

const EditRateModal = observer(({ open = false, toggleRateModal, rate })=> {
    const { RateFarmStore } = useStore();

    const [customRate, setCustomRate] = useState();

    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;

    function handleInputChange(event) {
        function formatInterestRate(value) {
            const dot = ~String(value || '')
                .indexOf('.') ? '.' : '';
            let [front, back] = String(value || '')
                .split('.');
            front = (front || '0').replace(/^0+(\d)/, '$1');
            back = (back || '').substr(0, 2);

            return `${front}${dot}${back}`;
        }

        const value = formatInterestRate(event.target.value.replace(/[^[0-9|.]]*/g, ''));
        setCustomRate(value);
    }

    function handleSubmitRate() {
        RateFarmStore.compareRate = parseFloat(customRate)
            .toFixed(2);

        analytics.eventTrack('rate_farm_consumer_add_your_rate_submit', {
            distinct_id: RateFarmStore.user.id,
            rate: RateFarmStore.compareRate,
        });

        toggleRateModal();
    }

    useEffect(()=> {
        setCustomRate(RateFarmStore.compareRate);
    }, [RateFarmStore.compareRate]);

    if(isMobile) {
        return <EditRateModalMobile open={open} toggleRateModal={toggleRateModal} />;
    }

    return (
        <Dialog fullWidth open={open} maxWidth={'sm'}>
            <Box position="absolute" top={24} right={24} onClick={toggleRateModal} style={{ cursor: 'pointer' }}>
                <Close />
            </Box>
            <Box width={1} pt={7} px={8} textAlign="center" mb={5}>
                <Box fontSize="h4.fontSize">Add your current monthly mortgage interest rate:</Box>
            </Box>
            <Box display="flex" mb={5} justifyContent="center">
                <Box display="flex" width={182}>
                    <Box
                        borderTop="1px solid #ddd"
                        borderBottom="1px solid #ddd"
                        borderLeft="1px solid #ddd"
                        borderRight="0px"
                        style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                        padding="7px 15px"
                    >
                        <Input
                            value={customRate}
                            align="center"
                            name="formattedInterestRate"
                            placeholder="2.57"
                            endAdornment={<StyledInputAdornment position="end">%</StyledInputAdornment>}
                            onChange={handleInputChange}
                            borderRight={0}
                        />
                    </Box>
                    <LRButton
                        style={{
                            width: '57px',
                            height: '50px',
                            borderRadius: 0,
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitRate}
                    >
                        Add
                    </LRButton>
                </Box>
            </Box>
            <Box textAlign="center" mb={5} color={COLOR_GREY_DARK} fontSize="body1.fontSize">
                Forgot your mortgage rate? Fill out the contact form.
                {/* <LRLink style={{ color: COLOR_GREY_DARK, textDecoration: 'underline', fontSize: '16px' }}>
                    How do I find my interest rate?
                </LRLink> */}
            </Box>
        </Dialog>
    );
});

const EditRateModalMobile = observer(({ open = false, toggleRateModal })=> {
    const { RateFarmStore } = useStore();

    const [customRate, setCustomRate] = useState(RateFarmStore.compareRate);

    function handleInputChange(event) {
        function formatInterestRate(value) {
            const dot = ~String(value || '')
                .indexOf('.') ? '.' : '';
            let [front, back] = String(value || '')
                .split('.');
            front = (front || '0').replace(/^0+(\d)/, '$1');
            back = (back || '').substr(0, 2);

            return `${front}${dot}${back}`;
        }

        const value = formatInterestRate(event.target.value.replace(/[^[0-9|.]]*/g, ''));
        setCustomRate(value);
    }

    function handleSubmitRate() {
        RateFarmStore.compareRate = parseFloat(customRate)
            .toFixed(2);

        analytics.eventTrack('rate_farm_consumer_add_your_rate_submit', {
            distinct_id: RateFarmStore.user.id,
            rate: RateFarmStore.compareRate,
        });

        toggleRateModal();
    }

    return (
        <Dialog fullScreen open={open} maxWidth={'sm'}>
            <Box zIndex="1" boxShadow={'0px 3px 12px -2px rgba(0, 0, 0, 0.2)'} bgcolor="white">
                <Box position="relative" px={4} py={2} display="flex" justifyContent="space-between">
                    <Box></Box>
                    <Box>Mortgage interest rate</Box>
                    <Box onClick={toggleRateModal} style={{ cursor: 'pointer' }}>
                        <Close />
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" flex="1">
                <Box width={1} pt={7} px={8} textAlign="center" mb={5}>
                    <Box fontSize="h6.fontSize">Add your current monthly mortgage interest rate:</Box>
                </Box>
                <Box mb={5} display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" justifyContent="center" alignItems="center" mb={5} width={160}>
                        <Box border="1px solid #ddd" borderRadius={4} padding="7px 15px">
                            <Input
                                value={customRate}
                                align="center"
                                placeholder="2.57"
                                name="formattedInterestRate"
                                // endAdornment={<StyledInputAdornment position="end">%</StyledInputAdornment>}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box
                            component="span"
                            fontSize="h3.fontSize"
                            ml={1}
                            display="flex"
                            fontWeight="500"
                            alignItems="center"
                        >
                            %
                        </Box>
                    </Box>
                    <Box textAlign="center" color={COLOR_GREY_DARK} fontSize="body1.fontSize">
                        Forgot your mortgage rate? Fill out the contact form.
                        {/* <LRLink style={{ color: COLOR_GREY_DARK, textDecoration: 'underline', fontSize: '16px' }}>
                            How do I find my interest rate?
                        </LRLink> */}
                    </Box>
                </Box>
            </Box>

            <Box py={1.5} px={2} bgcolor="#F9F9F9" borderTop="1px solid #DDDDDD">
                <LRButton variant="contained" color="primary" fullWidth onClick={handleSubmitRate}>
                    Add
                </LRButton>
            </Box>
        </Dialog>
    );
});

export { EditRateModal, EditRateModalMobile };
