import axios from 'axios';
import { useQuery } from 'react-query';

export function useFairHousingData() {
    return useQuery(
        'fair-housing-data',
        async function() {
            const response = await axios.get(process.env.REACT_APP_FAIR_HOUSING_CONFIG_URL);
            return response.data;
        }
    );
}
