import React, { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import LRButton from '../../../components/LRButton';
import { AddHomeIcon } from '../../../components/LRIcons';
import { useHistory } from 'react-router-dom';
import { ListpackComplianceTerms } from '../../../components/ListpackComplianceTerms';
import { CardPlaceholderList } from '../CardPlaceholderList';
import { ListItem } from '../Common/ListItem';
import { useBoardListpacks } from 'hooks/useBoardListpacks';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import _ from 'lodash';

const ZeroState = ()=> {
    const history = useHistory();
    const theme = useTheme();
    const handler = ()=>  history.push('/boards/listpack/create/');

    return (
        <Box display="flex" p={5} flexDirection="column" alignItems="center" bgcolor={theme.palette.lr_colors.grey_medium_second} width="100%" height="100%">
            <div style={{
                textAlign: 'center',
                border: `1px dashed ${theme.palette.lr_colors.border_grey_lighter}`,
                lineHeight:'20.24px',
                color: theme.palette.lr_colors.grey_dark,
                width: '264px',
                borderRadius: '4px',
                fontSize: '16px',
                padding: `${theme.spacing(4)}px`,
            }}>
                <AddHomeIcon style={{ width: '32px', height: '32px'}}/>
                <p style={{margin: `${theme.spacing(1)}px 0 ${theme.spacing(3)}px`}}>You don’t have any homes in this column yet.</p>
                <p style={{margin: `0 0 ${theme.spacing(3)}px`}}> Move a few over from a saved search, or start a new search!</p>
                <LRButton variant="contained" color="primary" onClick={handler}> New search</LRButton>
            </div>
        </Box>
    );
};

const ListView = observer(({
    listings,
    selectedListing,
    onListingSelect,
    listpack,
    lastSeenDate,
    currentLaneId,
    hasMoreListings,
    loadingMoreListings,
    fetchMoreListings,
    parentRef,
    showLoading,
})=> {
    const { isIPadDown } = useDeviceBreakpoints();
    const [showLoader, setShowLoader] = useState(true);
    const { isLoading:isLoadingListpacks } = useBoardListpacks();
    const mlsSources = useMemo(()=> _.uniqBy(listings, 'listing_details.mls_id'),[listings]);

    useEffect(()=> {
        const newValue = (!listpack && isLoadingListpacks) || showLoading;

        if(newValue !== showLoader && !newValue) {
            parentRef.current.scrollTo({ top: 0 });
        }

        setShowLoader(newValue);
    }, [showLoader, listpack, parentRef, isLoadingListpacks, showLoading]);

    function handleOnListingClick(listing) {
        onListingSelect(listing);
    }

    if(!showLoader && !listings.length && isIPadDown){
        return <ZeroState  />;
    }

    return (
        <Box px={1} py={1 / 2} display="flex" flexDirection="column" alignItems="center" width={1}>

            {showLoader && <CardPlaceholderList ready={!showLoader} />}

            {!showLoader && listings.map((listing, index)=> {
                return (
                    <ListItem
                        key={listing.id ? listing.id : listing.listing_details.id}
                        cardOrListing={listing}
                        listpack={listpack}
                        selectedListing={selectedListing}
                        currentLaneId={currentLaneId}
                        lastSeenDate={lastSeenDate}
                        handleOnListingClick={handleOnListingClick}
                    />
                );
            })}

            {!showLoader && listpack && hasMoreListings && (
                <Box mt={2} width="100%" display="flex" justifyContent="center" mb={3}>
                    <Box width={102}>
                        <LRButton
                            fullWidth
                            variant="outlined"
                            disabled={loadingMoreListings}
                            onClick={fetchMoreListings}
                        >
                            {!loadingMoreListings && 'Load more'}
                            {loadingMoreListings && (
                                <CircularProgress size={24} color="secondary" />
                            )}
                        </LRButton>
                    </Box>
                </Box>
            )}
            {
                !showLoader && listpack && listings.length > 0 && (
                    <Box px={2}>
                        <ListpackComplianceTerms mlsSources={mlsSources} />
                    </Box>
                )
            }
        </Box>
    );
});

export default ListView;
