import { NAV_BOARD, NAV_COMPARE_NOW, NAV_IDEAS_BOARD, NAV_MORTGAGE_CENTER, NAV_OVERVIEW, NAV_SCHEDULED_TOURS, NAV_MY_HOME } from '../../constants';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useCurrentPage() {
    const location = useLocation();
    const currentPage = useMemo(()=> {
        const path = location.pathname;

        if(path === NAV_BOARD) {
            return NAV_BOARD;
        } else if(path === NAV_SCHEDULED_TOURS) {
            return NAV_SCHEDULED_TOURS;
        } else if(path === NAV_COMPARE_NOW) {
            return NAV_COMPARE_NOW;
        } else if(path === NAV_IDEAS_BOARD) {
            return NAV_IDEAS_BOARD;
        } else if(path === NAV_MORTGAGE_CENTER) {
            return NAV_MORTGAGE_CENTER;
        } else if(path === NAV_OVERVIEW) {
            return NAV_OVERVIEW;
        } else if(path === NAV_MY_HOME) {
            return NAV_MY_HOME;
        }
    }, [location]);

    return {
        current_page: currentPage,
        NAV_BOARD,
        NAV_SCHEDULED_TOURS,
        NAV_COMPARE_NOW,
        NAV_IDEAS_BOARD,
        NAV_MORTGAGE_CENTER,
        NAV_OVERVIEW,
        NAV_MY_HOME,
    };
}
