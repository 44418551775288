import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useActiveBoard } from '../../../../mobx-store';
import TaskLayout from './task-layout';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import { useResponsive } from '../../../../hooks';

const NurtureStatusHot = observer(function NurtureStatusHot(props) {
    const r = useResponsive();
    const { task } = props;
    const { actor, cta } = task.content;
    const checked = !!task?.completedAt;

    return (
        <TaskLayout {...props}>
            <div>
                <Typography 
                    variant="body2"
                    // variant={r({ xs: checked ? 'body2' : 'body1', md: checked ? 'body2' : 'h6' })}
                >
                    Successfully nurtured!  Connect to set an appointment for introductions.
                </Typography>
                
            </div>
        </TaskLayout>
    );
});

export default NurtureStatusHot;