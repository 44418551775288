import { Popover, ThemeProvider } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import LRButton from 'components/LRButton';
import moment from 'moment';
import React, { useState } from 'react';
import { MuiCalendarBlackTheme } from 'theme';
import PropTypes from 'prop-types';

export function LRDateSelect({
    value,
    theme = MuiCalendarBlackTheme,
    fullWidth = false,
    minWidth = 184,
    disabled = false,
    onChange = (date)=> {},
    ...props
}) {
    const [openCalendar, setOpenCalendar] = useState(false);
    const [calendarRef, setCalendarRef] = useState();

    return (
        <div {...props}>
            <ThemeProvider theme={theme}>
                <LRButton 
                    disabled={disabled}
                    fullWidth={fullWidth}
                    variant={value ? 'contained' : 'outlined'} 
                    color={value ? 'primary' : 'default'}
                    ref={setCalendarRef} 
                    endIcon={<ExpandMoreRounded />}
                    onClick={()=> (setOpenCalendar(true))}
                    style={{
                        justifyContent: 'space-between',
                        backgroundColor: !value && '#fff',
                        paddingTop: 7,
                        paddingBottom: 7,
                    }}
                >
                    <div 
                        style={{
                            minWidth: Math.max(minWidth - 56, 0),
                            textAlign: 'left',
                        }}
                    >
                        {value ? moment(value).format('ddd, MMM D') : 'Select date'}
                    </div>
                </LRButton>
            </ThemeProvider>

            <Popover
                open={openCalendar}
                anchorEl={calendarRef}
                onClose={()=> {
                    setOpenCalendar(false);
                }}
            >
                <ThemeProvider theme={theme}>
                    <DatePicker
                        variant="static" 
                        minDate={new Date()}
                        value={value}
                        onChange={function (date) {
                            onChange(date);
                            setOpenCalendar(false);
                        }}
                    />
                </ThemeProvider>
            </Popover>
        </div>
    );
}

LRDateSelect.propTypes = {
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    minWidth: PropTypes.number,
    onChange: PropTypes.func,
    theme: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    value: PropTypes.any,
};
