import React from 'react';
import { DialogContent, withTheme } from '@material-ui/core';
import Welcome from './Welcome';
// import PreQualification from './PreQualification';
import EducationalResources from './EducationalResources';
import styled from 'styled-components';

const StyledDialogContent = withTheme(styled(DialogContent)`
    display: grid;
    grid-gap: 4rem;
    padding-top: 24px;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        padding: 60px;
    }
    ${({ theme })=> theme.breakpoints.up('md')} {
        grid-template-columns: 400px repeat(auto-fit, 350px);
        justify-content: center;
        padding: 60px 145px;
    }
`);

function MortgageCenterBoard() {
    return (
        <StyledDialogContent>
            <Welcome />
            {/* <PreQualification /> */}
            <EducationalResources />
        </StyledDialogContent>
    );
}

export default MortgageCenterBoard;
