import { useQuery } from 'react-query';
import { getPreferredAgent } from '../../../services/babou';

function listingHasRealtorInfo(listing) {
    const realtor = listing.realtor;
    return realtor && realtor.first_name &&
        realtor.last_name &&
        realtor.license_number &&
        realtor.company &&
        realtor.phone &&
        realtor.email;
}

function listingHasNoRealtorEmail(listing) {
    return listing && listing.realtor && !listing.realtor.email;
}

function makeRealtorRequest(listing) {
    return async function() {
        if(listingHasRealtorInfo(listing) || listingHasNoRealtorEmail(listing)) {
            return listing.realtor;
        } else {
            const realtorEmail = listing?.realtor?.email;
            const realtor = await getPreferredAgent({ email: realtorEmail });
            if(!realtor) {
                return listing?.realtor;
            }
            const preferredProfile = realtor.preferred_profile;
            const agentData = realtor.agent_data;
            return {
                company: listing.realtor?.company || preferredProfile.company,
                first_name: listing.realtor?.first_name || preferredProfile.first_name,
                last_name: listing.realtor?.last_name || preferredProfile.last_name,
                email: listing.realtor?.email || preferredProfile.email || agentData?.emails[0] || null,
                phone: listing.realtor?.phone || preferredProfile.phone || agentData?.phone_numbers[0] || null,
                license_number: listing.realtor?.license_number || preferredProfile.licenseNumber || agentData?.license_numbers[0] || null,
                office_phone: listing.realtor?.office_phone || preferredProfile.officePhone,
                website: listing.realtor.website,
            };
        }
    };
}

export function useListingRealtor(listing) {
    return useQuery(['listing-realtor', listing?.id], makeRealtorRequest(listing), {
        staleTime: Infinity,
        enabled: Boolean(listing)
    });
}
