import React, { useEffect, useRef, useState } from 'react';
import { Box, Dialog, Grid, Hidden, useTheme, SwipeableDrawer } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Map from './Map';
import Sidebar from './Sidebar';
import analytics from 'services/analytics';
import Logger from 'js-logger';
import { DrawerToggleButton } from 'components/DrawerToggleButton';
import { MOBILE_VIEW_MODES } from '../../constants';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useSwipeable } from 'react-swipeable';
import ListView from './List';
import { useStore } from 'mobx-store';
import _ from 'lodash';

const logger = Logger.get('ListingsMapAndListModal');

export function ListingsMapAndList({
    view,
    listpack,
    listings,
    hasMoreListings,
    loadingMoreListings,
    fetchMoreListings,
    isLoading,
    header,
    renderCard,
    onListingSelect = (listing)=> {},
    onSidebarColumnToggle = (isSingleColumn)=> {},
    ...props
}) {
    const theme = useTheme();
    const {
        isIPadDown,
    } = useDeviceBreakpoints();
    const [selectedListing, setSelectedListing] = useState(null);
    const [hoveredListing, setHoveredListing] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showTwoColumns, setShowTwoColumns] = useState(true);
    const [swiperInitialized ,setSwiperInitialized] = useState(false);
    const [lastSeenUpdated ,setLastSeenUpdated] = useState(false);
    const { UserStore } = useStore();
    const { user = {} } = UserStore;

    // UseRef
    const outerSidebarRef = useRef();
    const outerSidebarMobileRef = useRef();
    const listpackLastSeenAt = useRef();

    // Functions

    useEffect(()=> {
        setSwiperInitialized(false);
        setSelectedListing(null);
        setShowDrawer(false);
    }, [view, listpack, listings]);


    useEffect(()=> {
        setShowTwoColumns(_.size(listings) > 5);
    }, [listings]);

    useEffect(()=> {
        if(!listpack || !listpack?.id || !UserStore.isConsumer || lastSeenUpdated) return;

        updateLastSeen();

        async function updateLastSeen() {
            listpackLastSeenAt.current = listpack.lastSeenDate;
            await listpack.updateLastSeenAt(user.id);
            setLastSeenUpdated(true);
        }

    }, [UserStore.isConsumer, user.id, listpack, lastSeenUpdated]);

    const handlers = useSwipeable({ onSwipedDown: (eventData)=> {
        setShowDrawer(false);
    }});

    function handleOnHover(listing) {
        setHoveredListing(listing);
    }

    function handleOnListingSelect(listing) {
        setSelectedListing(listing);
        const elem = outerSidebarRef.current?.querySelector(`#${listing?.listing_details?.id}`);

        if(elem){
            elem.scrollIntoView();
        }

        setShowDrawer(true);
    }

    return (
        <Grid container {...props}>
            {/* MAP */}
            {(!isIPadDown || (isIPadDown && view === MOBILE_VIEW_MODES.MAP)) && (
                <Grid
                    item
                    xs
                    style={{
                        position: 'relative',
                        height: (showDrawer && isIPadDown) ? 'calc(100% - 222px)' : '100%',
                    }}
                >
                    {isIPadDown && view === MOBILE_VIEW_MODES.MAP && header}

                    <Map
                        listpackId={listpack?.id}
                        listings={listings}
                        selectedListing={selectedListing}
                        hoveredListing={hoveredListing}
                        onHover={handleOnHover}
                        onListingSelect={handleOnListingSelect}
                    />
                </Grid>
            )}

            {/* LIST VIEW */}
            {isIPadDown && view === MOBILE_VIEW_MODES.LIST && (
                <Grid
                    item
                    xs
                    style={{
                        position: 'relative',
                        maxHeight: '100%',
                        height: '100%',
                    }}
                    ref={outerSidebarMobileRef}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            overflow: 'auto',
                            backgroundColor: theme.palette.lr_colors.off_white,
                        }}
                    >
                        {header}

                        <ListView
                            lastSeenDate={listpackLastSeenAt.current}
                            listings={listings}
                            listpack={listpack}
                            selectedListing={selectedListing}
                            onListingSelect={onListingSelect}
                            hasMoreListings={hasMoreListings}
                            loadingMoreListings={loadingMoreListings}
                            fetchMoreListings={fetchMoreListings}
                            parentRef={outerSidebarMobileRef}
                            showLoading={isLoading}
                        />
                    </div>
                </Grid>
            )}

            {isIPadDown && view === MOBILE_VIEW_MODES.MAP && (
                <SwipeableDrawer
                    anchor="bottom"
                    open={true}
                    variant="persistent"
                    style={{
                        zIndex: 1,
                        visibility: showDrawer ? 'visible' : 'hidden'
                    }}
                >
                    <Box height={286} display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center" justifyContent="center"  {...handlers} onClick={()=> {
                            setShowDrawer(false);
                        }}>
                            <Box width={25} borderRadius="1">
                                <ExpandMore />
                            </Box>
                        </Box>
                        <Box visibility={showDrawer && swiperInitialized ? 'visible' : 'hidden'}>
                            <Sidebar
                                onSelectListing={(listing)=> {setSelectedListing(listing);} }
                                hoveredListing={hoveredListing}
                                onHover={handleOnHover}
                                lastSeenDate={listpackLastSeenAt.current}
                                listings={listings}
                                onSwiperInit={()=> { setSwiperInitialized(true); }}
                                listpack={listpack}
                                selectedListing={selectedListing}
                                onListingSelect={onListingSelect}
                            />
                        </Box>
                    </Box>
                </SwipeableDrawer>
            )}

            {/* SIDEBAR */}
            {(!isIPadDown) && (
                <Grid
                    item
                    style={{
                        position: 'relative',
                        width: showTwoColumns ? 768 : 400,
                        maxHeight: '100%',
                        height: '100%',
                    }}
                    ref={outerSidebarRef}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            overflow: 'auto',
                        }}
                    >
                        {header}

                        <Sidebar
                            handleOnHover={handleOnHover}
                            parentRef={outerSidebarRef}
                            hoveredListing={hoveredListing}
                            onHover={handleOnHover}
                            lastSeenDate={listpackLastSeenAt.current}
                            listings={listings}
                            listpack={listpack}
                            selectedListing={selectedListing}
                            onListingSelect={onListingSelect}
                            hasMoreListings={hasMoreListings}
                            loadingMoreListings={loadingMoreListings}
                            fetchMoreListings={fetchMoreListings}
                            showLoading={isLoading}
                            renderCard={renderCard}
                        />
                    </div>
                    {listings && _.size(listings) >= 5 && (
                        <Hidden smDown>
                            <Box
                                position='absolute'
                                top={72}
                                left={-10}
                            >
                                <DrawerToggleButton open={!showTwoColumns} handleToggle={()=> {
                                    analytics.eventTrack('robin_expanded_view_map_expand_icon_click',  {
                                        source: showTwoColumns ? 'collapse' : 'expand'
                                    });

                                    const newValue = !showTwoColumns;

                                    setShowTwoColumns(newValue);
                                    onSidebarColumnToggle(newValue === false);
                                }} />
                            </Box>
                        </Hidden>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export function ListingsMapAndListModal({
    open = false,
    view,
    header,
    bodyHeader,
    footer,
    listpack,
    listings,
    hasMoreListings,
    loadingMoreListings,
    fetchMoreListings,
    isLoading,
    renderCard,
    onListingSelect = (listing)=> {},
    onSidebarColumnToggle = (isSingleColumn)=> {},
    ...props
}) {
    return (
        <Dialog
            fullScreen
            open={open}
            {...props}
        >
            <Grid
                className="leland"
                container
                direction="column"
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                }}
            >
                {header && (
                    <Grid item>
                        <Box borderBottom="1px solid rgb(216, 216, 216)">
                            {header}
                        </Box>
                    </Grid>
                )}

                <Grid
                    item
                    xs
                >
                    <ListingsMapAndList
                        view={view}
                        listpack={listpack}
                        listings={listings}
                        hasMoreListings={hasMoreListings}
                        loadingMoreListings={loadingMoreListings}
                        fetchMoreListings={fetchMoreListings}
                        isLoading={isLoading}
                        onListingSelect={onListingSelect}
                        onSidebarColumnToggle={onSidebarColumnToggle}
                        style={{
                            height: '100%',
                        }}
                        header={bodyHeader}
                        renderCard={renderCard}
                    />
                </Grid>

                {footer && (
                    <Grid item>
                        {footer}
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
}
