import { portalApi } from 'apis';
import Logger from 'js-logger';
import { useQuery } from 'react-query';

export function useLoanOfficer(agent, allowNoRobinAccess) {
    const loId = agent?.benoitPartnerId || agent?.preferredVendorId;
    const lo = agent?.benoitPartner || agent?.preferred_vendor;
    const { data } = useQuery(
        ['lo-robin-access', loId],
        ()=> portalApi.getLORobinAccessStatus(loId),
        {
            enabled: Boolean(loId),
        }
    );

    return lo?.vendor?.company_type === 'Mortgage' && (data?.active || allowNoRobinAccess)
        ? lo
        : null;
}
