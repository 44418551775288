// eslint-disable-next-line
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { COLOR_GREY_DARK } from '../../../constants';
import styled from 'styled-components';

const DisclaimerLink = styled.a`
    color: ${COLOR_GREY_DARK};
    text-decoration: none;
`;

function FooterMobile() {
    const currentYear = moment().format('YYYY');

    return (
        <Box
            color={COLOR_GREY_DARK}
            fontSize="10px"
            ml={2}
            mr={2}
            mb={2}
            textAlign="center"
        >
            <Typography variant="caption" display="flex" justifyContent="center">
                &copy; Copyright {currentYear} ListReports, Inc. All rights reserved |&nbsp;Patent Pending |&nbsp;

                <DisclaimerLink 
                    href="https://welcome.listreports.com/terms-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms
                </DisclaimerLink> |&nbsp;

                <DisclaimerLink 
                    href="https://welcome.listreports.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy
                </DisclaimerLink> |&nbsp;
    
                <DisclaimerLink 
                    href="https://welcome.listreports.com/premium-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Premium Membership Terms
                </DisclaimerLink> |&nbsp;

                Available in USA only
            </Typography>
        </Box>
    );
}

export default FooterMobile;
