import React from 'react';
import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { UNCHECKED, CHECKED } from './Checkbox-SVGs';
import PropTypes from 'prop-types';

//Need to make disabled style
const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: (props)=> ({
        width: 24,
        height: 24,
        border: props.error ? '1px solid red' : 'none',
        borderRadius: 2,
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(196,196,196,0.6)',
        },
        backgroundImage: `url(data:image/svg+xml;utf8,${UNCHECKED})`,
    }),
    checkedIcon: {
        '&:before': {
            display: 'block',
            width: 24,
            height: 24,
            content: '""',
            backgroundImage: `url(data:image/svg+xml;utf8,${CHECKED})`,
        },
    },
});

export function LRCheckbox({ label, labelProps, ...props }) {
    const classes = useStyles({ error: props.error });
    return (
        <FormControlLabel
            label={label}
            {...labelProps}
            control={
                <Checkbox
                    className={classes.root}
                    disableRipple
                    color="default"
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    {...props}
                />
            }
        />
    );
}

LRCheckbox.propTypes = {
    checked: PropTypes.bool,
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    inputRef: PropTypes.object,
    label: PropTypes.string,
    labelProps: PropTypes.object,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.any,
};
