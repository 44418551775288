import React, { useState, useRef } from 'react';

import { Box, Dialog, Snackbar, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Close } from '@material-ui/icons';
import { useTheme, withTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Lockr from 'lockr';

import { useStore } from '../../../../../mobx-store';
import LRButton from '../../../../LRButton';
import { DEFAULT_HEADSHOT } from '../../../../../constants';
import { TextField } from '../../../../TextField';
import { portalApi } from '../../../../../apis';
import { LRPhoneInput } from '../../../../LRPhoneInput';
import analytics from '../../../../../services/analytics';
import html2canvas from 'html2canvas';
import Logger from 'js-logger';
import LRLink from '../../../../LRLink';
import { useResponsiveBreakpoint } from '../../../../../hooks';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const logger = Logger.get('LeadForm');

// ${({ theme })=> theme.breakpoints.up('sm')} {
//     height: calc(100vh - 64px);
// }

const StyledDialog = withTheme(styled(Dialog)`
 
& .MuiDialog-paperScrollPaper {
    min-height: calc(100vh - 64px);
}


    
`);

const CustomLRButton = withTheme(styled(LRButton)`
    width: 100%;
    height: 48px;

    &.Mui-disabled {
        background-color: ${({ theme, color })=> theme.palette[color].main};
        color: ${({ theme, color })=> theme.palette[color].contrastText};
        opacity: 0.5;
    }
`);

const AgentAvatar = styled(Box)`
    background-image: url("${(props)=> (props.headshot ? props.headshot : DEFAULT_HEADSHOT)}");
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    width: 64px;
    height: 64px;
`;

const LOCompliance = observer(({ open = false, toggleModal = ()=> void 0, headerTitle })=> {
    const { RateFarmStore } = useStore();
    const user = RateFarmStore.user || {};
    const loadingUser = RateFarmStore.loadingUser;
    const loadedUser = RateFarmStore.loadedUser;
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const maxWidth = isMobile ? false : 'xs';

    return (
        <StyledDialog open={open} fullScreen={isMobile} maxWidth={'xs'}>
            <Box zIndex="1" boxShadow={'0px 3px 12px -2px rgba(0, 0, 0, 0.2)'} bgcolor="white">
                <Box
                    display="flex"
                    position="relative"
                    justifyContent="flex-end"
                    px={3} py={2}
                >
                    <Box component={Close} onClick={toggleModal} style={{ cursor: 'pointer' }}>
                        {/* <Box
                        component={Close}
                        top="50%"
                        style={{
                            transform: 'translate(0, -50%)',
                            cursor: 'pointer',
                        }}
                        right={0}
                        mx={4}
                        position="absolute"
                        onClick={toggleRateModal}
                    /> */}
                    </Box>
                    
                </Box>
            </Box>
            <Box flex={1} overflow="auto">
                <Box p={4} position="relative">
                    <span>
                        {`${user?.vendor?.compliance_text || ''} `}
                        This is an advertisement and not an offer to enter into an agreement. Not all customers will qualify. All products are subject to credit and property approval. Other restrictions and limitations apply. Equal 
                        Housing Lender. 
                    </span>

                    <Box position="absolute" right={32} zIndex={1}>
                        <img src="/images/equal-housing-logo.png" alt="equal-housing-logo" />
                    </Box>
                </Box>
                
            </Box>
            
        </StyledDialog>
    );
});

export { LOCompliance };
