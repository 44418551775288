import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, IconButton, Typography, withTheme } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

const StyledAppBar = withTheme(styled.div`
    background-color: #fff;
    color: #000;
    position: relative;
    border-bottom: 1px solid #E0E0E0;
    ${({ theme })=> theme.breakpoints.down('sm')} {
        border-bottom: 1px solid ${({ theme })=> theme.palette.lr_colors.border_grey};
    }
`);

const StyledToolbar = withTheme(styled.div`
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        display: flex;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;
    }
`);

const Title = withTheme(styled(Typography)`
    grid-column: 2 / span 1;
    text-align: center;
`);

const StyledIconButton = styled(IconButton)`
    grid-column: 3 / span 1;
`;

function DialogHeader({ onClose }) {
    return (
        <StyledAppBar>
            <StyledToolbar style={{}}>
                <Title variant={['body1', 'h6']}>Mortgage Center</Title>
                <Box display="flex" justifyContent="center">
                    <StyledIconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                        <CloseRounded />
                    </StyledIconButton>
                </Box>
            </StyledToolbar>
        </StyledAppBar>
    );
}

DialogHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default DialogHeader;
