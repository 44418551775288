import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import LRButton from 'components/LRButton';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import { LRInputOutlined } from '../../../components/LRInput';
import Logger from 'js-logger';
import { useForm } from 'react-hook-form';
import { LRDialog } from 'components/LRDialog';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { LRPhoneInput } from 'components/LRPhoneInput';
import { VALIDATION_EMAIL_REGEX } from '../../../constants';
import LRTooltip from 'components/LRTooltip';

const logger = Logger.get('InviteModal');

export function InviteModal({
    home,
    disabled = false,
    open = false,
    PaperProps = {},
    onSend = async (formData)=> {},
    onCloseClick = (e)=> {},
    ...props
}) {
    const theme = useTheme();
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const form = useForm({
        defaultValues: {
        }
    });
    const { register, handleSubmit, errors, reset } = form;
    const {
        isMobile,
    } = useDeviceBreakpoints();

    // UseEffects

    useEffect(()=> {
        if(open) {
            reset({});
        }
    }, [open, home, reset]);

    // Functions

    async function onSubmit(formData) {
        setDisableSaveBtn(true);
        await onSend(formData);
        setDisableSaveBtn(false);
    }

    return (
        <LRDialog
            open={open}
            fullWidth={isMobile}
            PaperProps={{
                style: {
                    margin: isMobile && theme.spacing(2),
                    width: isMobile && `calc(100% - ${theme.spacing(2)}px)`,
                },
                ...PaperProps,
            }}
            {...props}
        >
            <form name="editMyHomeForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle disableTypography style={{ paddingTop: theme.spacing(3) }}>
                    <Typography variant="h6" style={{ fontWeight: 500 }}>
                        Invite
                    </Typography>
                </DialogTitle>

                <DialogContent
                    style={{
                        width: !isMobile && 590,
                    }}
                >
                    <Grid container spacing={2} style={{ display: isMobile ? 'block' : 'flex', }}>
                        <Grid item xs>
                            <LRInputOutlined
                                autoFocus
                                label="First name"
                                name="first_name"
                                error={Boolean(errors.first_name)}
                                autoComplete="off"
                                ref={register({ required: true })}
                                helperText={Boolean(errors.first_name) && 'Please enter a First name.'}
                                required
                            />
                        </Grid>
                        <Grid item xs>
                            <LRInputOutlined
                                label="Last name"
                                name="last_name"
                                error={Boolean(errors.last_name)}
                                autoComplete="off"
                                ref={register({ required: true })}
                                helperText={Boolean(errors.last_name) && 'Please enter a Last name.'}
                                required
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <LRInputOutlined 
                            label="Email"
                            name="email" 
                            error={Boolean(errors.email)}
                            autoComplete="off"
                            ref={register({ required: true, validate: (value)=> (Boolean((value || '').match(VALIDATION_EMAIL_REGEX))) })}
                            helperText={Boolean(errors.email) && 'Please enter an Email address.'}
                            required
                            InputProps={{
                                type: 'email',
                            }}
                        />
                    </div>

                    <div>
                        <div
                            style={{
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <label>
                                <Typography variant="body1" style={{ fontWeight: 500, }}>
                                    Phone number
                                </Typography>
                            </label>
                        </div>

                        <LRPhoneInput 
                            rfhMethods={form}
                            rules={{ required: false }}
                            name="phone"
                            inputProps={{
                                height: '48px',
                                width: isMobile ? '100%' : 211,
                                message: ()=> Boolean(errors.phone) && 'Please enter a Phone number.',
                                fontSize: theme.typography.body1.fontSize,
                                tone: (errors.phone) && 'error',
                            }}
                        />
                    </div>
                </DialogContent>

                <DialogActions style={{ paddingBottom: theme.spacing(3), paddingRight: theme.spacing(3), marginTop: isMobile && theme.spacing(2) }}>
                    <LRButton variant="text" inline underline color="inherit" onClick={onCloseClick} style={{ marginRight: theme.spacing(4) }}>
                        Cancel
                    </LRButton>

                    <LRTooltip hideArrow={false} title={disabled && 'If a buyer or co-buyer invites a new user, you will get a new lead notification when they sign up.'} placement="top" aria-label={disabled && 'If a buyer or co-buyer invites a new user, you will get a new lead notification when they sign up.'}>
                        <span>
                            <LRButton type="submit" variant="contained" color="primary" disabled={disabled || disableSaveBtn}>
                                Send
                            </LRButton>
                        </span>
                    </LRTooltip>
                </DialogActions>
            </form>
        </LRDialog>
    );
}

InviteModal.propTypes = {
    ...Dialog.propTypes,
    home: PropTypes.object.isRequired,
    onSend: PropTypes.func.isRequired,
};
