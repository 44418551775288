import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import ProfileForm from './ProfileForm';
import PasswordForm from './PasswordForm';

function Profile({ match }) {
    return (
        <Box maxWidth={650} mt={4} alignSelf="center" width="100%" px={[2, 0]}>
            <Route exact component={ProfileForm} path={`${match.path}`} />
            <Route component={PasswordForm} path={`${match.path}/password`} />
        </Box>
    );
}

Profile.propTypes = {
    match: PropTypes.object.isRequired
};

export default withRouter(Profile);
