import React, { useState, useCallback } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Divider, Hidden } from '@material-ui/core';

import { useStore } from '../../mobx-store';
import notificationConfig from '../../mobx-store/notifications/config';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import LRModal from '../../components/LRModal';
import LRButton from '../../components/LRButton';
import { NotificationsList } from '../../components/NotificationsList';
import { useResponsiveBreakpoint } from '../../hooks';
import analytics from '../../services/analytics';

export default observer(()=> {
    const { NotificationsStore, UserStore, boardsStore } = useStore();
    const { activeBoard } = boardsStore;
    const notifications = NotificationsStore.notifications;
    const lastId = NotificationsStore.lastId;
    const isXs = useResponsiveBreakpoint('xs');
    const history = useHistory();

    const handleNotificationClick = useCallback(
        (notification)=> {
            NotificationsStore.markAsRead(notification);
            const conf = notificationConfig[notification.type] || {};
            const toUrl = (notification?.content?.path)
                ? notification.content.path
                : conf.cta
                    ? conf.cta(notification)
                    : null;
            if(toUrl) {
                const [ pathname, search ] = toUrl.split('?');
                history.push({
                    pathname,
                    search,
                    state: { trackSource: 'robin_notification_center' }
                });
            }
            analytics.eventTrack('robin_notification_center_activity_click', {
                event: (typeof conf.trackEventName === 'function')
                    ? conf.trackEventName(notification)
                    : (conf.trackEventName || notification.type),
                parent_component: 'modal',
                role: UserStore.usersBoardRole,
                board_id: activeBoard?.id,
            });
        },
        [NotificationsStore, history, UserStore.usersBoardRole, activeBoard]
    );

    const onLoadMoreClick = (event)=> {
        NotificationsStore.fetchMoreStreamNotifications();
    };

    const [close, setClose] = useState(false);
    if(close) {
        return <Redirect to={'/boards'} />;
    }
    return (
        <LRModal onClose={()=> setClose(true)}>
            <Box p={2} fontSize="h6.fontSize">
                <Grid container justify="space-between">
                    <Grid item xs>
                        Notifications
                    </Grid>
                    <Grid item xs container alignItems="center" justify="flex-end" spacing={1}>
                        <Grid item>
                            <Box height={24}>
                                <Link to="/boards/account/notifications">
                                    <SettingsIcon />
                                </Link>
                            </Box>
                        </Grid>
                        <Hidden smUp>
                            <Grid item>
                                <Box height={24} display={{ xs: 'block', sm: 'none' }}>
                                    <CloseIcon onClick={()=> setClose(true)} />
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Box>
            <Divider></Divider>
            <NotificationsList
                notifications={notifications}
                onNotificationClick={handleNotificationClick}
                fullView={true}
            ></NotificationsList>
            <div style={{textAlign: 'center'}}>
                {lastId && (<LRButton color="secondary"
                    fullWidth={isXs}
                    onClick={onLoadMoreClick}
                >
                    Load More
                </LRButton>)}
            </div>
        </LRModal>
    );
});
