import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useOnboarding } from '../../store';
import { useStore } from '../../../../mobx-store';
import { simulateClick, waitForElement } from '../../utils';
import { useHistory } from 'react-router-dom';
import Confetti from '../../../Confetti';
import { delay } from 'bluebird';

function createSteps(tour, { user }) {
    return [
        {
            id: 'continue-modal',
            title: 'Buying a home together?',
            text: 'Robin is built for collaboration, and not just between you and your agent. Invite friends, family, or business partners to join your board as “co-buyers” and keep everyone on the same page (literally). Co-buyers have similar board privileges to you, but you can remove them from your board at any time.',
            buttons: [
                {
                    text: 'No Thanks',
                    classes: 'shepherd-text-button',
                    action: tour.complete
                },
                {
                    text: 'Invite',
                    action: tour.next,
                }
            ],
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_search_submit',
            },
            classes: 'shepherd-wide-tooltip',
        },
        {
            id: 'select-cobuyer-item',
            title: 'Co-buyer settings are here 👉',
            text: 'This is where you manage invitations and board membership.<br/><span class="font-medium">Select Co-buyers.</span>',
            attachTo: {
                element: '.tour-target-cobuyer-setting-item',
                on: 'bottom',
            },
            advanceOn: {
                selector: '.tour-target-cobuyer-setting-item',
                event: 'click',
            },
            beforeShowPromise() {
                tour.options.useModalOverlay = false;
                return Promise.resolve()
                    .then(()=> simulateClick('.tour-target-user-avatar'))
                    .then(()=> delay(300))
                    .then(()=> waitForElement('.tour-target-add-cobuyer'));
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [10, 30]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_search_submit',
            },
        },
        {
            id: 'invite-button',
            title: 'Who else is involved? ',
            text: 'Click here to invite your first co-buyer or simply exit the menu if you’re the only one making decisions in your home search.',
            attachTo: {
                element: '.tour-target-invite-cobuyer',
                on: 'bottom',
            },
            advanceOn: {
                selector: '.tour-target-invite-cobuyer',
                event: 'click',
            },
            beforeShowPromise() {
                tour.options.useModalOverlay = false;
                return Promise.resolve()
                    .then(()=> waitForElement('.tour-target-invite-cobuyer'));
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 30]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_search_submit',
            },
        },
        {
            id: 'invite-form',
            beforeShowPromise() {
                tour.options.useModalOverlay = false;
                return Promise.resolve();
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            classes: 'hidden',
        },
        {
            id: 'cobuyers-list',
            beforeShowPromise() {
                tour.options.useModalOverlay = false;
                return Promise.resolve();
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            classes: 'hidden',
            when: {
                hide: ()=> tour.options.useModalOverlay = true
            }
        },
    ];
};

export const InviteCobuyerFlowMobile = observer(function InviteCobuyerFlowMobile() {
    const { UserStore } = useStore();
    const onboarding = useOnboarding();
    const history = useHistory();
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(()=> {
        if(!onboarding) return;

        window.onpopstate = function(event) {
            if(onboarding.stepId !== 'final') {
                onboarding.stop();
            };
        };
        return ()=> { window.onpopstate = ()=> {}; };

    }, [onboarding]);

    useEffect(()=> {
        history.push('/boards');
    }, [history]);

    useEffect(()=> {
        let timeout = null;
        function handleComplete() {
            setShowConfetti(true);
            UserStore.completeInviteBuyerOnboarding();
            simulateClick('.tour-target-mobile-footer-agent-toggle');
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        }

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding, UserStore]);

    useEffect(()=> {
        const steps = createSteps(onboarding.tour, { user: UserStore.user });
        onboarding.addSteps(steps);
        setTimeout(()=> {
            onboarding.start();
        }, 300);

        return function() {
            onboarding.reset();
        };
    }, [onboarding, UserStore]);

    useEffect(()=> {
        return function() {
            onboarding.reset();
        };
    }, [onboarding]);

    if(!showConfetti) {
        return null;
    }

    return <Confetti iterationCount={2}/>;
});
