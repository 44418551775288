import { Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { SendRounded } from '@material-ui/icons';
import LRAvatar from 'components/LRAvatar';
import LRButton from 'components/LRButton';
import { useAgent } from 'hooks/useAgent';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const AskAgentAQuestion = observer(function AskAgentAQuestion({
    disabled,
    onContactMeClick = (e)=> {},
    style = {},
    ...props
}) {
    const theme = useTheme();
    const { data:agent } = useAgent({ priority: 'context' });
    const {
        isMobile,
    } = useDeviceBreakpoints();

    return (
        <Grid 
            container 
            alignItems="center" 
            justify="center"
            spacing={2} 
            style={{ 
                paddingRight: theme.spacing(isMobile ? 1 : 2),
                paddingLeft: theme.spacing(2),
                width: '100%',
                ...style,
            }} 
            {...props}
        >
            <Grid container item alignItems="center" style={{ maxWidth: 420, }} lg>
                <Grid item style={{ paddingRight: theme.spacing(2), }}>
                    <LRAvatar user={agent} size="size64" />
                </Grid>

                <Grid item xs>
                    <Typography variant="body1">
                        Let me know if you have any questions!
                    </Typography>
                </Grid>
            </Grid>

            <Grid item style={{ width: isMobile && '100%' }}>
                <Paper elevation={0} style={{ cursor: !disabled && 'pointer' }} onClick={!disabled && onContactMeClick}>
                    <Grid container >
                        <Grid
                            item
                            xs
                            style={{
                                color: '#bbb',
                                padding: theme.spacing(1.5),
                                border: '1px solid',
                                borderColor: theme.palette.lr_colors.border_grey,
                                borderRight: 0,
                                borderTopLeftRadius: 4,
                                borderBottomLeftRadius: 4,
                                maxWidth: 'calc(100% - 64px)',
                            }}
                        >
                            <Typography
                                variant="body1"
                                noWrap
                            >
                                How much would I make if I sold my home?
                            </Typography>
                        </Grid>

                        <Grid item>
                            <LRButton variant="contained" color="primary" disabled={disabled} style={{ height: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                                <SendRounded />
                            </LRButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
});
