import { useState, useLayoutEffect, useRef } from 'react';

function getDimensions(node) {
    const rect = node.getBoundingClientRect();
    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
        node
    };
}

/**
 * 
 Inspired by https://github.com/Swizec/useDimensions
 */
export function useDimensions({ liveMeasure = true } = {}) {
    const [node, setNode] = useState(null);
    const optionsRef = useRef({ liveMeasure });
    const dimensionsRef = useRef({});

    const ref = setNode;

    useLayoutEffect(()=> {
        if(!node) return;
        function measure() {
            window.requestAnimationFrame(function() {
                const calculatedDimensions = getDimensions(node);
                dimensionsRef.current = calculatedDimensions;
            });
        }

        measure();
        const options = optionsRef.current;
        if(options.liveMeasure) {
            window.addEventListener('resize', measure);
            window.addEventListener('scroll', measure);
            return function() {
                window.removeEventListener('resize', measure);
                window.removeEventListener('scroll', measure);
            };
        }
    });

    return  [ref, dimensionsRef.current, node];
}
