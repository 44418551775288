import React, { useEffect, useRef, useState } from 'react';
import { LRAreaAutocomplete } from '../LRAreaAutocomplete';
import { Box, TextField, InputAdornment, makeStyles, CircularProgress, Typography, Hidden, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import analytics from '../../services/analytics';
import { useQuery } from '../../hooks';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from '../..//mobx-store';
import { Room, Search, NearMeRounded} from '@material-ui/icons';
import { useOnboarding } from '../Onboarding';
import CloseIcon from '@material-ui/icons/Close';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import { isLocationSupported, getLocation } from 'services/location';
import LRConfirmationDialog from 'components/LRConfirmationDialog';
import { geocoder } from 'services/gmaps';

const useTextFieldsStyles = makeStyles({
    root: {
        'background': '#FFF',
        'font-size': '14px',
        
        '& input': {
            'font-size': '14px',
            'color': '#BBBBBB',
        },

        '&:focus-within input': {
            'color': '#1A1A1A',
        },  

        '& fieldset': {
            'border': '1px solid #DDDDDD !important',
        },

        '&:hover fieldset': {
            'border': '1px solid #DDDDDD !important'
        },

        '&:focus-within fieldset': {
            'border': '1px solid #BBBBBB !important',
        },

        '& ::-webkit-input-placeholder': {
            'font-size': '14px'
        }, 
        '& ::-moz-placeholder': {
            'font-size': '14px'
        }
    }
});
export const ListpackAreaSearchInput = observer(({
    inputHeight = 56,
    buttonColor = '#FF6259',
    buttonTextColor = '#FFF',
    location = false,
})=> {
    const theme = useTheme();
    const history = useHistory();
    const { UserStore } = useStore();
    const { user } = UserStore;
    const areaAutocompleteRef = useRef();
    const searchFieldRef = useRef();
    const query = useQuery();
    const onboarding = useOnboarding();
    const textFieldStyles = useTextFieldsStyles();

    const [openLocationErrorModal, setOpenLocationErrorModal] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(()=> {
        if(query.has('focus')){
            searchFieldRef.current.focus();
        }
    },[query, searchFieldRef]);

    function handleSuggestionSelect(suggestion, currentLocation) {
        
        analytics.eventTrack('robin_board_search_submitted', {
            'affiliate_id': user?.affiliateId,
            'search_query id': currentLocation ? 'current_location' : suggestion?.id,
            source: 'board',
        });
        if(onboarding.isActive) {
            onboarding.next();
        }
        areaAutocompleteRef.current.clearSearch();
        history.push(`/boards/listpack/create/${suggestion.id}`);
    }

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
                iconElement = <BusinessIcon fontSize="small" />;
                break;
            case 'zip':
                iconElement = <Room fontSize="small" />;
                break;
            case 'neighborhood':
                iconElement = <HomeIcon fontSize="small" />;
                break;
            case 'school':
                iconElement = <SchoolIcon fontSize="small" />;
                break;
            default:
                iconElement = <Room fontSize="small"/>;
                break;
        }
        return iconElement;
    }

    async function onLocationFetch(location) {
        const geocodeResults = await geocoder.reverseGeocode(location.coords.latitude, location.coords.longitude);
        const firstZip = geocodeResults.find((location)=> location.types.includes('postal_code'))?.
            address_components.find((component)=> component.types.includes('postal_code'))?.
            long_name;
        handleSuggestionSelect({id: firstZip}, true);
    }

    return (
        <>
            {openLocationErrorModal && <LRConfirmationDialog
                okButton={{ label: 'OK' }}
                onClose={()=> setOpenLocationErrorModal(false)}
            >
                There is no location support on this device or it is disabled.  Please check your settings.
            </LRConfirmationDialog>}
            <Box height={inputHeight} mb={[0, 0.2]}>
                <LRAreaAutocomplete
                    ref={areaAutocompleteRef}
                    onSuggestionSelect={handleSuggestionSelect}
                    rootContainerProps={{ className: 'tour-target-listpack-search' }}
                    menuItemHoverColor={'#F9F9F9'}
                    onFocus={(e)=> {
                        setIsInputFocused(true);
                    }}
                    onBlur={(e)=> {
                        setTimeout(()=> setIsInputFocused(false), 200);
                    }}
                    renderInput={
                        ({error, isLoading, value, clearSearch, ...props})=> {
                            return (
                                <>
                                    <TextField
                                        classes={textFieldStyles}
                                        inputRef={searchFieldRef}
                                        variant="outlined"
                                        color="primary"
                                        placeholder="City, Zip, Neighborhood, School"
                                        value={value}
                                        InputProps={{
                                            endAdornment:
                                                <>
                                                    <InputAdornment position="end">
                                                        <Box
                                                            color="#A1A1A1"
                                                            onClick={clearSearch}
                                                            style={{cursor: 'pointer'}}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <CloseIcon style={{
                                                                width: '14px',
                                                                height: '14px',
                                                                display: !!value?.length ? 'flex' : 'none'
                                                            }} />
                                                        </Box>
                                                        <Box
                                                            color={buttonTextColor}
                                                            border={`1px solid ${buttonColor}`}
                                                            bgcolor={buttonColor}
                                                            position="relative"
                                                            left="14px"
                                                            zIndex="2"
                                                            borderRadius="0px 4px 4px 0px"
                                                            display="flex"
                                                            width={inputHeight}
                                                            height={inputHeight}
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            {
                                                                isLoading ? (
                                                                    <Hidden xsDown>
                                                                        <CircularProgress color="white" size={20} />
                                                                    </Hidden>
                                                                ) : (
                                                                    <Search fontSize="small" />
                                                                )
                                                            }
                                                        </Box>
                                                    </InputAdornment>
                                                </>,
                                            style: {
                                                height: inputHeight,
                                            },
                                        }}
                                        size="small"
                                        fullWidth
                                        {...props}
                                    />
                                    <Box
                                        display={error ? 'flex' : 'none'}
                                        color='red'
                                        fontSize={['10px', 'small']}
                                        mt={[1.6, 0.2]}
                                        position={['absolute', 'relative']}
                                        whiteSpace={['nowrap', 'normal']}
                                        zIndex={[1000, 0]}
                                    >
                                        Sorry, we couldn’t find any results for your search.
                                    </Box>
                                    {
                                        location &&
                                        isLocationSupported &&
                                        isInputFocused && 
                                        searchFieldRef.current.value === '' &&
                                    (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: `${inputHeight + 8}px`,
                                                width: '100%',
                                                height: '56px',
                                                backgroundColor: '#fff',
                                                border: `1px solid ${theme.palette.lr_colors.grey_2}`,
                                                borderRadius: '4px',
                                                boxShadow: '0px 4px 8px 0px #00000040',
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                zIndex: 2,
                                            }}
                                            onClick={async ()=> {
                                                try {
                                                    onLocationFetch(await getLocation());
                                                } catch (err) {
                                                    setOpenLocationErrorModal(true);
                                                }
                                            }}
                                        >
                                            <div style={{
                                                marginLeft: theme.spacing(2),
                                                display: 'flex',
                                            }}>
                                                <NearMeRounded />
                                                <Typography variant="body1" style={{marginLeft: theme.spacing(1)}}>
                                                    Current location
                                                </Typography>
                                            </div>
                                        </div>
                                    )}
                                </>    
                            );
                        }
                    }
                    suggestionRenderOption={(suggestion)=> {
                        return (
                            <Box display="flex" style={{ cursor: 'pointer' }} className="tour-target-listpack-search-option">
                                <div>
                                    {
                                        renderSuggestionIcon(suggestion.type)
                                    }
                                </div>

                                <Box ml={1} fontSize="body1.fontSize" fontWeight="450">
                                    {suggestion.id}
                                </Box>
                            </Box>
                        );
                    }}
                    clickOutsideExtension={()=> {}}
                />
            </Box>
        </>
    );
});
