import { AppBar, CircularProgress, Fade, Grid, Hidden, IconButton, Slide, Toolbar, Typography, useScrollTrigger, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import LRButton from 'components/LRButton';
import LRModal from 'components/LRModal';
import ResponsiveHOC from 'components/ResponsiveHoc';
import { useQuery } from 'hooks';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { MyHomeProvider, useMyHome, useMyHomeServiceProviders } from 'hooks/useMyHome';
import Logger from 'js-logger';
import _, { partial } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useActiveBoard, useStore } from 'mobx-store';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';
import analytics from 'services/analytics';
import AnimatedScroll from 'services/animated-scroll';
import { AddServiceProviderModal } from './AddServiceProviderModal';
import { AskAgentAQuestion } from './AskAgentAQuestion';
import { ContactModal } from './ContactModal';
import { EditMyHomeModal } from './EditMyHomeModal';
import { ComplianceModal } from './ComplianceModal';
import { EstimatedHomeValue } from './EstimatedHomeValue';
import { FeaturedPropertyPacks } from './FeaturedPropertyPacks';
import { Header } from './Header';
import { InviteModal } from './InviteModal';
import { InfoBox } from './InfoBox';
import { MyServiceProviders } from './MyServiceProviders';
import { NavBar, TAB_ESTIMATED_HOME_VALUE, TAB_FEATURED_PROPERTY_PACKS, TAB_MY_AGENT, TAB_MY_SERVICE_PROVIDERS, TAB_NEARBY_PROPERTIES, TAB_NEIGHBORHOOD_DATA } from './NavBar';
import { NearbyProperties } from './NearbyProperties';
import { ServiceProvidersModal } from './ServiceProvidersModal';
import CompareNearbyPropertiesModal from './CompareNearbyPropertiesModal';
import { AgentProvider, useAgent } from 'hooks/useAgent';
import { useLoanOfficer } from 'hooks/useLoanOfficer';
import LRAvatar from 'components/LRAvatar';
import { RobinLogo } from 'components/RobinLogo';
import { OptIntoHomeReportTextMessage, useCanViewOptinTextMessage } from './OptIntoHomeReportTextMessage';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useLoginAndContinue } from 'hooks/useLoginAndContinue';
import { NAV_MY_HOME } from '../../constants';
import { benoitApi, portalApi } from 'apis';
import { EditAreaModal } from './EditAreaModal';
import { NeighborhoodDataMultipleAreas } from './NeighborhoodDataMultipleAreas';
import moment from 'moment';
import { ManageModal as NeighborhoodManageModal } from './NeighborhoodDataMultipleAreas/ManageModal';
import Address from 'lana';
import { useNeighborhoodMarketActivity } from 'hooks/useNeighborhoodMarketActivity';
import AgentBrandingHeader from 'components/Listing/AgentBrandingHeader';

const logger = Logger.get('MyHome');
const EditServiceProviderModal = AddServiceProviderModal;

function isEventNotifiable() {
    //should notify if lastDate does not exist and if already passed one day from the last update.
    const tag = 'home-report-notify-date';
    const lastDate = localStorage.getItem(tag) || null;
    if(!lastDate) {
        localStorage.setItem(tag, moment().toISOString());
        return true;
    }

    const diff = moment().diff(lastDate, 'days');
    if(diff >= 1) {
        localStorage.setItem(tag, moment().toISOString());
        return true;
    }
    return false;
};

const MyHomeMobile = observer(function MyHomeMobile({
    isLoading = true,
    showNavBar = false,
    selectedTab,
    scrollRef,
    selectedNeighborhood,
    setSelectedNeighborhood,
    marketActivity,
    estimatedHomeValueRef,
    neighborhoodDataRef,
    nearbyPropertiesRef,
    myServiceProvidersRef,
    featuredPropertiesRef,
    myAgentRef,
    onTabChange = (e)=> {},
    onEditMyHomeClick = (e)=> {},
    onAddMyHome = (address, source)=> {},
    onCompareClick = (e, source)=> {},
    setPropertyLabel,
    onOpenContactAgentModal = (e)=> {},
    onOpenContactLOModal = (e)=> {},
    onEnterYourAddressClick = (e)=> {},
    onInviteClick = (e)=> {},
    onViewAllServiceProvidersClick = (e)=> {},
    onAddServiceProviderClick = (e)=> {},
    onOpenComplianceModal = (e)=> {},
    onOpenEditAreaModal = (e)=> {},
    setOpenManageNeighborhoodModal = (e)=> {},
    openSignUpModal,
}) {
    const theme = useTheme();
    const { isLoggedIn } = useIsLoggedIn();
    const { isMobile } = useDeviceBreakpoints();
    const { myHome: home } = useMyHome();
    const { UserStore } = useStore();
    const { isLO, isAgent } = UserStore;
    const isAgentOrLO = Boolean(isLO || isAgent);
    const scrollTrigger = useScrollTrigger({
        target: scrollRef ? scrollRef : undefined,
        threshold: 159,
        disableHysteresis: true,
    });
    const appBarPosition = useMemo(()=> {
        return scrollTrigger ? 'fixed' : 'static';
    }, [scrollTrigger]);

    // UseEffects

    return (
        <>
            {isLoading && (
                <Grid container justify="center">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}

            <Fade in={!isLoading}>
                <div>
                    <div
                        style={{
                            marginRight: theme.spacing(2),
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        <Header disableEditing={isAgentOrLO} onEditClick={onEditMyHomeClick} />
                    </div>

                    <NavBar
                        show
                        selectedTab={selectedTab}
                        onTabChange={onTabChange}
                        position={appBarPosition}
                        style={{
                            marginTop: scrollTrigger && 56,
                        }}
                    />

                    {scrollTrigger && (
                        <div style={{ height: 48, }} />
                    )}

                    <div ref={estimatedHomeValueRef}>
                        <EstimatedHomeValue
                            disableEnterAddressCta={isAgentOrLO}
                            onAddMyHome={partial(onAddMyHome, partial.placeholder, 'est_home_widget')}
                            onCompareClick={partial(onCompareClick, partial.placeholder, 'home_value_email_modal')}
                            onContactMeClick={partial(onOpenContactAgentModal, partial.placeholder, partial.placeholder, 'estimated_home_value_link')}
                        />
                    </div>

                    <div ref={neighborhoodDataRef}>
                        <NeighborhoodDataMultipleAreas
                            setOpenManageNeighborhoodModal={setOpenManageNeighborhoodModal}
                            openSignUpModal={openSignUpModal}
                        />
                    </div>

                    {isLoggedIn && (
                        <AskAgentAQuestion
                            style={{
                                backgroundColor: theme.palette.lr_colors.grey_light,
                                margin: 0,
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(2)
                            }}
                            onContactMeClick={partial(onOpenContactAgentModal, partial.placeholder, partial.placeholder, 'question_widget')}
                        />
                    )}

                    <div ref={nearbyPropertiesRef}>
                        <NearbyProperties
                            home={home}
                            hideEnterAddressCta={isAgentOrLO}
                            onEnterYourAddressClick={partial(onEnterYourAddressClick, partial.placeholder, 'Enter your address to compare your home with others in the area', 'nearby_properties')}
                            onCompareClick={partial(onCompareClick, partial.placeholder, 'nearby_homes_modal')}
                            setPropertyLabel={setPropertyLabel}
                            setOpenManageNeighborhoodModal={setOpenManageNeighborhoodModal}
                            style={{
                                borderTop: isMobile && '1px solid',
                                borderTopColor: isMobile && theme.palette.lr_colors.border_grey,
                            }}
                            marketActivity={marketActivity}
                            selectedNeighborhood={selectedNeighborhood}
                            setSelectedNeighborhood={setSelectedNeighborhood}
                            openSignUpModal={openSignUpModal}
                        />
                    </div>

                    <div ref={featuredPropertiesRef}>
                        <FeaturedPropertyPacks />
                    </div>

                    <div ref={myServiceProvidersRef}>
                        {isLoggedIn && <MyServiceProviders
                            isAgentOrLO={isAgentOrLO}
                            onViewAllClick={onViewAllServiceProvidersClick}
                            onAddClick={onAddServiceProviderClick}
                        />}
                    </div>

                    <div ref={myAgentRef}>
                        <InfoBox
                            variant="agent"
                            onContactMeClick={
                                analytics.eventTrackOnEvent(
                                    'robin_home_report_agent_widget_contact_me_button_click',
                                    null,
                                    partial(onOpenContactAgentModal, partial.placeholder, partial.placeholder, 'agent_widget')
                                )
                            }
                        />
                    </div>

                    <div>
                        <InfoBox
                            variant="lo"
                            onContactMeClick={
                                analytics.eventTrackOnEvent(
                                    'robin_home_report_lo_widget_contact_me_button_click',
                                    null,
                                    partial(onOpenContactLOModal, partial.placeholder, partial.placeholder, 'agent_widget')
                                )
                            }
                            hasComplianceModal
                            onComplianceModal={onOpenComplianceModal}
                            style={{ marginTop: !isMobile && theme.spacing(2) }}
                            hideIfNoProfile
                        />
                    </div>
                </div>
            </Fade>
        </>
    );
});

const MyHomeDesktop = observer(function MyHomeDesktop({
    isLoading = true,
    showNavBar = false,
    selectedTab,
    marketActivity,
    selectedNeighborhood,
    setSelectedNeighborhood,
    estimatedHomeValueRef,
    neighborhoodDataRef,
    nearbyPropertiesRef,
    myServiceProvidersRef,
    featuredPropertiesRef,
    myAgentRef,
    onTabChange = (e)=> {},
    onEditMyHomeClick = (e)=> {},
    onAddMyHome = (address, source)=> {},
    onCompareClick = (e, source)=> {},
    setPropertyLabel,
    onOpenContactAgentModal = (e)=> {},
    onOpenContactLOModal = (e)=> {},
    onEnterYourAddressClick = (e)=> {},
    onInviteClick = (e)=> {},
    onViewAllServiceProvidersClick = (e)=> {},
    onAddServiceProviderClick = (e)=> {},
    onOpenComplianceModal = (e)=> {},
    onOpenEditAreaModal = (e)=> {},
    setOpenManageNeighborhoodModal = (e)=> {},
    openSignUpModal,
}) {
    const theme = useTheme();
    const { isLoggedIn } = useIsLoggedIn();
    const { myHome:home } = useMyHome();
    const { UserStore } = useStore();
    const { isLO, isAgent } = UserStore;
    const isAgentOrLO = Boolean(isLO || isAgent);
    const {
        isIPadProDown,
    } = useDeviceBreakpoints();

    return (
        <>
            {isLoading && (
                <Grid container justify="center">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}

            <NavBar show={showNavBar} selectedTab={selectedTab} onTabChange={onTabChange} />

            <Fade in={!isLoading}>
                <div>
                    <Header disableEditing={isAgentOrLO} onEditClick={onEditMyHomeClick} />

                    <Grid
                        container
                        direction={isIPadProDown ? 'column' : 'row'}
                        spacing={!isIPadProDown && 2}
                        style={{
                            maxWidth: 1160,
                            margin: '0 auto',
                        }}
                    >
                        {/* Left Column */}
                        <Grid
                            item
                            xs
                            style={{
                                maxWidth: 800,
                            }}
                        >
                            <div ref={estimatedHomeValueRef}>
                                <EstimatedHomeValue
                                    disableEnterAddressCta={isAgentOrLO}
                                    style={{ marginTop: theme.spacing(3) }}
                                    onAddMyHome={partial(onAddMyHome, partial.placeholder, 'est_home_widget')}
                                    onCompareClick={partial(onCompareClick, partial.placeholder, 'home_value_email_modal')}
                                    onContactMeClick={partial(onOpenContactAgentModal, partial.placeholder, partial.placeholder, 'estimated_home_value_link')}
                                />
                            </div>

                            <div ref={neighborhoodDataRef} style={{ marginTop: theme.spacing(2) }}>
                                <NeighborhoodDataMultipleAreas
                                    setOpenManageNeighborhoodModal={setOpenManageNeighborhoodModal}
                                    openSignUpModal={openSignUpModal}
                                />
                            </div>

                            {isLoggedIn && (
                                <AskAgentAQuestion
                                    style={{ marginTop: theme.spacing(4) }}
                                    onContactMeClick={partial(onOpenContactAgentModal, partial.placeholder, partial.placeholder, 'question_widget')}
                                />
                            )}

                            <div ref={nearbyPropertiesRef}>
                                <NearbyProperties
                                    home={home}
                                    hideEnterAddressCta={isAgentOrLO}
                                    style={{ marginTop: theme.spacing(isLoggedIn ? 4 : 2) }}
                                    onEnterYourAddressClick={partial(onEnterYourAddressClick, partial.placeholder, 'Enter your address to compare your home with others in the area', 'nearby_properties')}
                                    onCompareClick={partial(onCompareClick, partial.placeholder, 'nearby_homes_modal')}
                                    setPropertyLabel={setPropertyLabel}
                                    openAddNeighborhoodModal={()=> setOpenManageNeighborhoodModal(true)}
                                    marketActivity={marketActivity}
                                    selectedNeighborhood={selectedNeighborhood}
                                    setSelectedNeighborhood={setSelectedNeighborhood}
                                    openSignUpModal={openSignUpModal}
                                />
                            </div>
                        </Grid>

                        {/* Right Column */}
                        <Grid
                            item
                            xs
                            style={{
                                maxWidth: isIPadProDown ? '100%' : 376,
                                marginTop: !isIPadProDown && -theme.spacing(9),
                                zIndex: 1, // Needed ao it shows above the header
                            }}
                        >
                            <Grid container direction={isIPadProDown ? 'row' : 'column'}>
                                <Grid item xs>
                                    <div ref={myAgentRef}>
                                        <InfoBox
                                            variant="agent"
                                            onContactMeClick={
                                                analytics.eventTrackOnEvent(
                                                    'robin_home_report_agent_widget_contact_me_button_click',
                                                    null,
                                                    partial(onOpenContactAgentModal, partial.placeholder, partial.placeholder, 'agent_widget')
                                                )
                                            }
                                            style={{ marginTop: isIPadProDown && theme.spacing(2) }}
                                        />
                                    </div>
                                    <div>
                                        <InfoBox
                                            variant="lo"
                                            onContactMeClick={
                                                analytics.eventTrackOnEvent(
                                                    'robin_home_report_lo_widget_contact_me_button_click',
                                                    null,
                                                    partial(onOpenContactLOModal, partial.placeholder, partial.placeholder, 'agent_widget')
                                                )
                                            }
                                            hasComplianceModal
                                            onComplianceModal={onOpenComplianceModal}
                                            style={{ marginTop: theme.spacing(2) }}
                                            hideIfNoProfile
                                        />
                                    </div>
                                    <div ref={myServiceProvidersRef}>
                                        {isLoggedIn && <MyServiceProviders
                                            isAgentOrLO={isAgentOrLO}
                                            onViewAllClick={onViewAllServiceProvidersClick}
                                            onAddClick={onAddServiceProviderClick}
                                            style={{ marginTop: theme.spacing(2) }}
                                        />}
                                    </div>
                                </Grid>

                                <Grid item style={{ width: theme.spacing(2) }}></Grid>

                                <Grid item xs>
                                    <div ref={featuredPropertiesRef}>
                                        <FeaturedPropertyPacks
                                            style={{ marginTop: theme.spacing(2) }}
                                        />
                                    </div>
                                    {/* <InviteMessage
                                        onInviteClick={analytics.eventTrackOnEvent('robin_home_report_invite_widget_invite_link_click', null, onInviteClick)}
                                        style={{ marginTop: theme.spacing(2) }}
                                    /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </>
    );
});

const MyHomeResponsive = ResponsiveHOC({xs: MyHomeMobile, sm: MyHomeDesktop});

export const MyHome = observer(function MyHome({
    agentAffiliateCode,
    scrollRef,
}) {
    const theme = useTheme();
    const activeBoard = useActiveBoard();
    const { LoginSignupModalStore, } = useStore();
    const { isMobile, isIPadDown } = useDeviceBreakpoints();
    const { GlobalSnackbarStore, UserStore } = useStore();
    const { isAgent, isLO, user } = UserStore ?? {};
    const isAgentOrLO = Boolean(isLO || isAgent);
    const { data: agent } = useAgent({ priority: 'context' });
    const lo = useLoanOfficer(agent);
    const [openAddHomeModal, setOpenAddHomeModal] = useState(false);
    const [openEditMyHomeModal, setOpenEditMyHomeModal] = useState(false);
    const [openContactAgentModal, setOpenContactAgentModal] = useState(false);
    const [openContactLOModal, setOpenContactLOModal] = useState(false);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [openComplianceModal, setOpenComplianceModal] = useState(false);
    const [openAddServiceProviderModal, setOpenAddServiceProviderModal] = useState(false);
    const [showNavBar, setShowNavBar] = useState(false);
    const [openContactAgentModalDefaultMessage, setOpenContactAgentModalDefaultMessage] = useState('');
    const [addHomeModalLabel, setAddHomeModalLabel] = useState();
    const [addHomeModalDefaultAddress, setAddHomeModalDefaultAddress] = useState();
    const [addHomeModalCB, setAddHomeModalCB] = useState();
    const [openEditAreaModal, setOpenEditAreaModal] = useState(false);
    const [openManageNeighborhoodModal, setOpenManageNeighborhoodModal] = useState(false);

    const queryParams = useQuery();
    const {
        isDataLocal,
        isLoading,
        myHome,
        lead,
        createMyHome,
        updateMyHome,
        inviteUserToRobinMyHome,
        contactAgentAboutMyHome,
        contactLOAboutMyHome,
    } = useMyHome();
    const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
    const marketActivity = useNeighborhoodMarketActivity(selectedNeighborhood);
    const {
        createServiceProvider,
        updateServiceProvider,
        deleteServiceProvider,
    } = useMyHomeServiceProviders();
    const [selectedTab, setSelectedTab] = useState(TAB_ESTIMATED_HOME_VALUE);
    const estimatedHomeValueRef = useRef();
    const neighborhoodDataRef = useRef();
    const nearbyPropertiesRef = useRef();
    const myServiceProvidersRef = useRef();
    const featuredPropertiesRef = useRef();
    const myAgentRef = useRef();
    const sectionQueryParamRef = useRef();
    const match = useRouteMatch();
    const history = useHistory();
    const [editServiceProvider, setEditServiceProvider] = useState();
    const [addHomeModalEventSource, setAddHomeModalEventSource] = useState();
    const [contactAgentModalSource, setContactAgentModalSource] = useState();
    const [isCompareModalOpen, setCompareModalOpen] = useState(false);
    const [listingToCompare, setListingToCompare] = useState(null);
    const lockedListings = useMemo(()=> {
        return listingToCompare ? [listingToCompare] : [];
    }, [listingToCompare]);
    const canViewOptinTextMessage = useCanViewOptinTextMessage();
    const loginAndContinue = useLoginAndContinue({
        singupFormOptions: {
            homeReportTextMessageOptInCheckboxEnabled: true,
        }
    });
    const [postLoginDataCheck, setPostLoginDataCheck] = useState();
    const [propertyLabel, setPropertyLabel] = useState('all');
    const { hasAttemptedToFetchUser } = useIsLoggedIn();
    const mode = useQuery().get('mode');
    const [leadAVMData, setLeadAVMData] = useState();
    const { areaIds, myHome: home } = useMyHome();

    useEffect(()=> {
        async function notifyAgentAndLo(){
            const notificationData = (id)=> {
                const buyer = user || lead;
                return {
                    type: (user && 'robin') || (lead && 'lead'),
                    userId: id,
                    meta: {
                        buyer: {
                            name: buyer?.name || buyer?.applicant?.name
                        },
                        cta: `/home-report/l/${buyer.id}/${buyer?.affiliate?.id || buyer?.ownerId}?mode=ro`,
                        leadId: buyer.id,
                        name: buyer?.name || buyer?.applicant?.name,
                        benoitUserId: user?.id,
                    },
                    avatar: buyer?.headshot || null,
                    name: 'robin_home_report_view',
                };
            };

            if(isEventNotifiable()) {
                const loId = agent?.benoitPartnerId || agent?.preferredVendorId;
                Promise.all([
                    portalApi.createActivity(notificationData(agent.id)),
                    portalApi.createActivity(notificationData(loId))
                ]);
            }
        }

        if(agent?.id && (user?.id || lead?.id)){
            notifyAgentAndLo();
        }
    }, [agent, user, lead]);

    useEffect(()=> {
        if(agent?.affiliate_code) {
            LoginSignupModalStore.affiliateCode = agent.affiliate_code;
        }
    }, [agent]);
    
    useEffect(()=> {
        if(lead) {
            LoginSignupModalStore.setUserInfoFromVendorLead(lead);
            setLeadAVMData(lead?.meta?.robin?.home_report?.avm);
        }
    }, [lead]);

    useEffect(()=> {
        if(queryParams.get('utm_source') === 'email') {
            openAddHomeModalFunc('Enter your address for more personalized results');
        }
    }, [queryParams.get('utm_source')]);

    useEffect(()=> {
        const myHomeAddress = queryParams.get('my_home_address');

        if(myHomeAddress != null) {
            openAddHomeModalFunc('Enter your address for more personalized results', myHomeAddress);
        }
    }, [queryParams.get('my_home_address')]);

    useEffect(()=> {
        const section = queryParams.get('section');

        if(sectionQueryParamRef.current || (!myHome && ~['market_activity'].indexOf(section))) return;

        switch(section) {
            case 'market_activity':
                if(!marketActivity.isLoading) {
                    setTimeout(onTabChange, 500, null, TAB_NEARBY_PROPERTIES, 0);
                    sectionQueryParamRef.current = section;
                }
                break;

            case 'refer_friend':
                sectionQueryParamRef.current = section;
                openReferAFriendModalFunc();
                break;

            case 'agent_contact_form':
                sectionQueryParamRef.current = section;
                setOpenContactAgentModal(true);
                break;

            default:
                sectionQueryParamRef.current = section;
                break;
        }
    }, [myHome, queryParams]);

    useEffect(()=> {
        if(!scrollRef) return;

        scrollRef.addEventListener('scroll', handleWindowScrollEvent);
        handleWindowScrollEvent();

        return function () {
            scrollRef.removeEventListener('scroll', handleWindowScrollEvent);
        };
    }, [scrollRef, isMobile]);

    useEffect(()=> {
        const myHome = postLoginDataCheck;
        if(myHome && activeBoard?.id && !isDataLocal) {
            createMyHomeAndRedirect(myHome);
        }
    }, [activeBoard, isDataLocal, postLoginDataCheck, lead, createMyHomeAndRedirect]);

    // Functions
    async function createMyHomeAndRedirect(myHomeData) {
        const response = await benoitApi.getMyHomes(activeBoard.id);

        if(!response?.length) {
            const newHomeData = await createMyHome(myHomeData);
            await updateMyHome({ ...myHomeData, id: newHomeData.id }, activeBoard.id);
        }

        history.replace(NAV_MY_HOME);
    }

    function openAddHomeModalFunc(label = null, defaultAddress = null, cb = null) {
        setAddHomeModalCB(cb && (()=> (cb)));
        setAddHomeModalLabel(label);
        setAddHomeModalDefaultAddress(defaultAddress);
        setOpenAddHomeModal(true);
    }

    async function onAddMyHome(address, source) {
        await (myHome ? updateMyHome : createMyHome)({address});
        
        analytics.eventTrack('robin_home_report_address_added', { Source: source, listing_address: address, });
        setAddHomeModalEventSource(null);
        if(addHomeModalCB) addHomeModalCB();
        showSnackbarMessage('Your home has been added!', 'success');
    }

    async function onCompareClick(e, source, listing) {
        if(LoginSignupModalStore.isOpen) {
            setPostLoginDataCheck({
                ...myHome,
                avm: leadAVMData,
                areaIds
            });
            return;
        }

        if(source === 'home_value_email_modal') {
            setSelectedNeighborhood(home.zipcode);
        }

        if(listing) setListingToCompare(listing);
        openCompareModal();
    }

    function onEnterYourAddressClick(e, label, source) {
        setAddHomeModalLabel(label);
        setAddHomeModalEventSource(source);
        setOpenAddHomeModal(true);
    }

    function openCompareModal() {
        setCompareModalOpen(true);
    }

    function onAddHomeModalClose() {
        setOpenAddHomeModal(false);
        setAddHomeModalCB(null);
        setAddHomeModalDefaultAddress(null);
        setAddHomeModalLabel(null);
    }

    function onEditMyHomeModalClose() {
        setOpenAddHomeModal(false);
        setOpenEditMyHomeModal(false);
    }

    async function onUpdateMyHome(data) {
        try {
            if(!myHome?.id) {
                const newHomeData = await createMyHome(data.address);
                await updateMyHome({ ...data, id: newHomeData?.id });
            } else {
                if(data?.address && myHome?.address && !(new Address(data.address).isSame(myHome.address))) {
                    data.avm = [];
                }

                await updateMyHome(data);
            }
        } catch (err) {
            Logger.error('onUpdateMyHome -> Error', err);
            return;
        }

        analytics.eventTrack('robin_home_report_facts_updated');
        onEditMyHomeModalClose();
        showSnackbarMessage('Your home has been updated!', 'success');
    }

    function showSnackbarMessage(message, severity) {
        GlobalSnackbarStore.show(message, {
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            action:  (
                <LRButton
                    color="inherit"
                    onClick={()=> {
                        GlobalSnackbarStore.hide();
                    }}
                    underline
                >
                    Close
                </LRButton>
            ),
            TransitionComponent: Slide,
            TransitionProps: {
                direction: 'left',
            },
            ContentProps:{
                style: {
                    backgroundColor: severity === 'error' && theme.palette.lr_colors.brick,
                },
            },
        });
    }

    function onHeroImageUploadError(err) {
        showSnackbarMessage('There was an error uploading your new hero image.', 'error');
    }

    function onOpenContactAgentModal(e, defaultMessage, source) {
        setContactAgentModalSource(source);
        setOpenContactAgentModalDefaultMessage(defaultMessage);
        setOpenContactAgentModal(true);
    }

    function onCloseContactAgentModal() {
        setOpenContactAgentModal(false);
        setOpenContactAgentModalDefaultMessage('');
    }

    function onOpenContactLOModal() {
        setOpenContactLOModal(true);
    }

    function onCloseContactLOModal() {
        setOpenContactLOModal(false);
    }

    function handleWindowScrollEvent(e) {
        const BAR_OFFSET = isMobile ? 105 : 64;
        const value = scrollRef.scrollTop >= 1;
        setShowNavBar(value);

        if(isMobile) {
            if((myServiceProvidersRef.current.offsetTop - BAR_OFFSET) <= scrollRef.scrollTop) {
                if(selectedTab !== TAB_MY_SERVICE_PROVIDERS) setSelectedTab(TAB_MY_SERVICE_PROVIDERS);
                return;
            } else if((featuredPropertiesRef.current.offsetTop - BAR_OFFSET) <= scrollRef.scrollTop) {
                if(selectedTab !== TAB_FEATURED_PROPERTY_PACKS) setSelectedTab(TAB_FEATURED_PROPERTY_PACKS);
                return;
            }
        }

        if((nearbyPropertiesRef.current.offsetTop - BAR_OFFSET) <= scrollRef.scrollTop) {
            if(selectedTab !== TAB_NEARBY_PROPERTIES) setSelectedTab(TAB_NEARBY_PROPERTIES);
        } else if((neighborhoodDataRef.current.offsetTop - BAR_OFFSET) <= scrollRef.scrollTop) {
            if(selectedTab !== TAB_NEIGHBORHOOD_DATA) setSelectedTab(TAB_NEIGHBORHOOD_DATA);
        } else {
            setSelectedTab(TAB_ESTIMATED_HOME_VALUE);
        }
    }

    function onTabChange(e, selectedTab, speed = 400) {
        handleScrollFromSelectedTab(selectedTab, speed);
    }

    async function handleScrollFromSelectedTab(selectedTab, speed = 400) {
        const BAR_OFFSET = isMobile ? 105 : 64;;
        const scroll = new AnimatedScroll(scrollRef);
        let scrollPosition;

        if(selectedTab === TAB_ESTIMATED_HOME_VALUE) {
            scrollPosition = estimatedHomeValueRef.current.offsetTop;
        } else if(selectedTab === TAB_NEIGHBORHOOD_DATA) {
            scrollPosition = neighborhoodDataRef.current.offsetTop;
        } else if(selectedTab === TAB_NEARBY_PROPERTIES) {
            scrollPosition = nearbyPropertiesRef.current.offsetTop;
        } else if(selectedTab === TAB_MY_AGENT) {
            scrollPosition = myAgentRef.current.offsetTop;
        } else if(selectedTab === TAB_MY_SERVICE_PROVIDERS) {
            scrollPosition = myServiceProvidersRef.current.offsetTop;
        } else if(selectedTab === TAB_FEATURED_PROPERTY_PACKS) {
            scrollPosition = featuredPropertiesRef.current.offsetTop;
        }

        if(scrollPosition) {
            await scroll.top(scrollPosition - BAR_OFFSET, speed);
            // Need to do a setTimeout in order to make sure the final tab selected
            // is the one you clicked and not necessarly based on scroll position.
            setTimeout(setSelectedTab, 10, selectedTab);
        }
    }

    function openReferAFriendModalFunc() {
        setOpenInviteModal(true);
    }

    function onInviteClick() {
        setOpenInviteModal(true);
    }

    async function sendMyHomeInvite(contactInfo) {
        try {
            await inviteUserToRobinMyHome(contactInfo);
        } catch (err) {
            logger.error('sendMyHomeInvite -> Error', err);
            return showSnackbarMessage('There was an error sending the invite.', 'error');
        }

        setOpenInviteModal(false);
        showSnackbarMessage(`Invite sent to ${contactInfo.first_name}.`, 'success');
    }

    async function sendContactAgentEmail(message) {
        try {
            await contactAgentAboutMyHome(message);
        } catch (err) {
            logger.error('sendContactAgentEmail -> Error', err);
            return showSnackbarMessage('There was an error contacting your agent.', 'error');
        }

        analytics.eventTrack('robin_home_report_agent_contacted', {
            Source: contactAgentModalSource,
        });
        analytics.eventTrack('robin_home_report_contact_agent_email_sent', {
            Source: contactAgentModalSource,
        });
        setContactAgentModalSource(null);
        setOpenContactAgentModal(false);
        showSnackbarMessage(`Message sent to ${agent.first_name}.`, 'success');
    }

    async function sendContactLOEmail(message) {
        try {
            await contactLOAboutMyHome(message);
        } catch (err) {
            logger.error('sendContactLOEmail -> Error', err);
            return showSnackbarMessage('There was an error contacting your lender.', 'error');
        }

        analytics.eventTrack('robin_home_report_lo_contacted', {
            Source: 'lo_widget',
        });

        setOpenContactLOModal(false);
        showSnackbarMessage(`Message sent to ${lo?.name}.`, 'success');
    }

    function onAddServiceProviderClick(e, source) {
        if(LoginSignupModalStore.isOpen) {
            setPostLoginDataCheck({
                ...myHome,
                avm: leadAVMData,
                areaIds
            });
            return;
        }

        setOpenAddServiceProviderModal(source);
    }

    async function onCreateServiceProvider(data) {
        try {
            await createServiceProvider(data);
        } catch (err) {
            logger.error('onCreateServiceProvider -> Error', err);
            return showSnackbarMessage('There was an error creating your service provider.', 'error');
        }

        analytics.eventTrack('robin_home_report_service_provider_saved');
        setOpenAddServiceProviderModal(false);
        showSnackbarMessage(`Your service provider ${data.type} has been added.`, 'success');
    }

    function onViewAllServiceProvidersClick() {
        history.push(`${match.url}/service-providers`);
    }

    async function onSaveServiceProvider(serviceProvider, data) {
        try {
            await updateServiceProvider([serviceProvider, data]);
        } catch (err) {
            logger.error('onSaveServiceProvider -> Error', err);
            return showSnackbarMessage('There was an error updating your service provider.', 'error');
        }

        analytics.eventTrack('robin_home_report_service_provider_edited');
        setEditServiceProvider(null);
        showSnackbarMessage(`Your service provider ${data.type} has been updated.`, 'success');
    }

    async function onDeleteServiceProvider(e, serviceProvider) {
        try {
            await deleteServiceProvider(serviceProvider);
        } catch (err) {
            logger.error('onDeleteServiceProvider -> Error', err);
            return showSnackbarMessage('There was an error deleting your service provider.', 'error');
        }

        analytics.eventTrack('robin_home_report_service_provider_deleted');
        setEditServiceProvider(null);
        showSnackbarMessage(`Your service provider ${serviceProvider.company} has been deleted.`, 'success');
    }

    function onOpenComplianceModal(){
        setOpenComplianceModal(true);
    }

    function handleCloseComplianceModal(){
        setOpenComplianceModal(false);
    }

    function onOpenEditAreaModal(area) {
        setOpenEditAreaModal(area);
    }

    function onEditAreaModalClose(){
        setOpenEditAreaModal(false);
    }

    return (
        <>
            {mode !== 'ro' && <OptIntoHomeReportTextMessage />}
            <div
                style={{
                    paddingBottom: canViewOptinTextMessage && (isIPadDown ? 135 : 100),
                }}
            >
                <MyHomeResponsive
                    scrollRef={scrollRef}
                    isLoading={Boolean(postLoginDataCheck || !hasAttemptedToFetchUser || isLoading)}
                    showNavBar={showNavBar}
                    selectedTab={selectedTab}
                    selectedNeighborhood={selectedNeighborhood}
                    marketActivity={marketActivity}
                    setSelectedNeighborhood={setSelectedNeighborhood}
                    estimatedHomeValueRef={estimatedHomeValueRef}
                    neighborhoodDataRef={neighborhoodDataRef}
                    nearbyPropertiesRef={nearbyPropertiesRef}
                    myServiceProvidersRef={myServiceProvidersRef}
                    featuredPropertiesRef={featuredPropertiesRef}
                    myAgentRef={myAgentRef}
                    onTabChange={onTabChange}
                    onEditMyHomeClick={()=> {
                        setOpenEditMyHomeModal(true);
                        analytics.eventTrack('robin_home_report_edit_my_home_link_click');
                    }}
                    onAddMyHome={onAddMyHome}
                    onCompareClick={loginAndContinue(onCompareClick)}
                    setPropertyLabel={setPropertyLabel}
                    onOpenContactAgentModal={onOpenContactAgentModal}
                    onOpenContactLOModal={onOpenContactLOModal}
                    onEnterYourAddressClick={onEnterYourAddressClick}
                    onInviteClick={onInviteClick}
                    onAddServiceProviderClick={loginAndContinue(partial(onAddServiceProviderClick, partial.placeholder, 'my-home'))}
                    onViewAllServiceProvidersClick={onViewAllServiceProvidersClick}
                    onOpenComplianceModal={onOpenComplianceModal}
                    onOpenEditAreaModal={onOpenEditAreaModal}
                    setOpenManageNeighborhoodModal={setOpenManageNeighborhoodModal}
                    openSignUpModal={loginAndContinue(
                        partial(setPostLoginDataCheck, {
                            ...myHome,
                            avm: leadAVMData,
                            areaIds
                        }))}
                />
            </div>

            {/* {!isAgentOrLO && (
                <AddHomeModal
                    agent={agent}
                    open={openAddHomeModal && !myHome && !isLoading}
                    defaultAddress={addHomeModalDefaultAddress}
                    callToActionLabel={addHomeModalLabel}
                    onAddMyHome={partial(onAddMyHome, partial.placeholder, addHomeModalEventSource)}
                    onClose={onAddHomeModalClose}
                />
            )} */}

            {/* Handles Add and Edit */}
            {!isAgentOrLO && (
                <EditMyHomeModal
                    open={openEditMyHomeModal || openAddHomeModal}
                    onSave={onUpdateMyHome}
                    onClose={onEditMyHomeModalClose}
                    onHeroImageUploadError={onHeroImageUploadError}
                />
            )}

            <NeighborhoodManageModal
                open={openManageNeighborhoodModal}
                closeModal={()=> setOpenManageNeighborhoodModal(false)}
                areas={areaIds}
                saveAreas={(areas)=> {
                    //if myHome does not exist createMyHome object with areaIds
                    const saveAreas = myHome ? updateMyHome : createMyHome;
                    return saveAreas({areaIds: areas});
                }}
            />

            <ContactModal
                variant="agent"
                open={openContactAgentModal}
                defaultMessage={openContactAgentModalDefaultMessage}
                onClose={onCloseContactAgentModal}
                onSend={sendContactAgentEmail}
                disabled={isAgentOrLO}
            />

            <ContactModal
                variant="lo"
                open={openContactLOModal}
                onClose={onCloseContactLOModal}
                onSend={sendContactLOEmail}
                disabled={isAgentOrLO}
            />

            <ComplianceModal
                open={openComplianceModal}
                onClose={handleCloseComplianceModal}
            />

            <InviteModal
                open={openInviteModal}
                onCloseClick={()=> setOpenInviteModal(false)}
                onSend={sendMyHomeInvite}
                disabled={isAgentOrLO}
            />

            {!isAgentOrLO && (
                <AddServiceProviderModal
                    closeCTAText={openAddServiceProviderModal === 'service-providers' && 'Service providers'}
                    open={openAddServiceProviderModal}
                    onCloseClick={()=> setOpenAddServiceProviderModal(false)}
                    onSave={onCreateServiceProvider}
                />
            )}

            {!isAgentOrLO && (
                <EditServiceProviderModal
                    closeCTAText="Service providers"
                    serviceProvider={editServiceProvider}
                    open={Boolean(editServiceProvider)}
                    onCloseClick={()=> setEditServiceProvider(null)}
                    onSave={partial(onSaveServiceProvider, editServiceProvider, partial.placeholder)}
                    onDeleteClick={onDeleteServiceProvider}
                />
            )}

            {isCompareModalOpen && (
                <CompareNearbyPropertiesModal
                    open
                    onClose={()=> setCompareModalOpen(false)}
                    listings={marketActivity?.data}
                    lockedListings={lockedListings}
                    myHome={myHome}
                    propertyLabel={propertyLabel}
                />
            )}

            <EditAreaModal
                open={openEditAreaModal}
                onClose={onEditAreaModalClose}
            />

            <Route
                path={`${match?.url}/service-providers`}
                children={({ match:routeMatch })=> {
                    return (
                        <ServiceProvidersModal
                            isAgentOrLO={isAgentOrLO}
                            open={Boolean(routeMatch)}
                            onAddServiceProviderClick={partial(onAddServiceProviderClick, partial.placeholder, 'service-providers')}
                            onCloseClick={()=> {
                                history.push(match.url);
                            }}
                            onEditClick={(e, serviceProvider)=> setEditServiceProvider(serviceProvider)}
                        />
                    );
                }}
            />
        </>
    );
});

export function MyHomeModal({
    open = false,
    onCloseClick = (e)=> {},
    style = {},
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
    } = useDeviceBreakpoints();
    const [containerRef, setContainerRef] = useState();

    // UseEffects

    useEffect(()=> {
        if(open) {
            analytics.eventTrack('robin_home_report_page_viewed');
        }
    }, [open]);

    return (
        <LRModal
            open={open}
            hideBackdrop
            PaperProps={{
                elevation: 0,
                style: {
                    backgroundColor: theme.palette.lr_colors.costa_mesa,
                },
            }}
            fullScreen
            maxWidth="100%"
            hideCloseButton
            style={{
                marginTop: !isMobile && 64,
                ...style,
            }}
            dialogContentProps={{
                id: 'my-home-modal',
                ref: setContainerRef,
            }}
            {...props}
        >
            <Hidden smUp>
                <AppBar
                    color="inherit"
                    position="fixed"
                    className="HeaderNavBar-root"
                    elevation={0}
                >
                    <Toolbar
                        style={{
                            borderBottom: '1px solid #ddd',
                        }}
                    >
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            <Grid item xs={2} sm={1}></Grid>

                            <Grid item>
                                <Typography variant={isMobile ? 'body2' : 'h6'}>
                                    Home Report
                                </Typography>
                            </Grid>

                            <Grid item xs={2} sm={1} style={{ textAlign: 'right' }}>
                                <IconButton edge="end" onClick={onCloseClick}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Hidden>

            <Hidden smUp>
                <Toolbar />
            </Hidden>

            <div
                style={{
                    paddingRight: !isMobile && theme.spacing(2),
                    paddingLeft: !isMobile && theme.spacing(2),
                    paddingBottom: !isMobile && theme.spacing(2),
                    paddingTop: theme.spacing(4),
                }}
            >
                <MyHomeProvider>
                    <MyHome scrollRef={containerRef} />
                </MyHomeProvider>
            </div>
        </LRModal>
    );
}

export const MyHomeModalLoggedOut = observer(function MyHomeModalLoggedOut({
    onCloseClick = (e)=> {},
    style = {},
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
    } = useDeviceBreakpoints();
    const [containerRef, setContainerRef] = useState();
    const { affiliateCode, leadId } = useParams();
    const { data:agent } = useAgent(affiliateCode);
    const { hasAttemptedToFetchUser, isLoggedIn } = useIsLoggedIn();
    const history = useHistory();
    const queryParams = useQuery();
    const mode = queryParams.get('mode');
    const bearerToken = queryParams.get('bearerToken');
    const forceLoggedOut = mode === 'ro' && !bearerToken;

    // UseEffects

    useEffect(()=> {
        if(hasAttemptedToFetchUser && isLoggedIn && !forceLoggedOut) {
            history.replace(NAV_MY_HOME);
        }
    }, [hasAttemptedToFetchUser]);

    // When we have the lead id set it as a super property once
    useEffect(()=> {
        if(leadId) {
            analytics.setSuperPropertiesOnce({
                lead_id: leadId,
            });
        }
    }, [leadId]);

    return (
        <LRModal
            open
            hideBackdrop
            PaperProps={{
                elevation: 0,
                style: {
                    backgroundColor: theme.palette.lr_colors.costa_mesa,
                },
            }}
            fullScreen
            maxWidth="100%"
            hideCloseButton
            style={{
                ...style,
            }}
            dialogContentProps={{
                id: 'my-home-modal',
                ref: setContainerRef,
            }}
            {...props}
        >
            <AppBar
                color="inherit"
                className="HeaderNavBar-root"
                elevation={0}
                style={{
                    zIndex: 1101,
                }}
            >
                <Toolbar
                    style={{
                        borderBottom: '1px solid #ddd',
                    }}
                >
                    <Grid container alignItems="center">
                        <Grid item>
                            <Fade in={Boolean(agent)}>
                                <div>
                                    {agent && <AgentBrandingHeader
                                        agent={agent}
                                        hideLicenseNumber={isMobile}
                                        hideCompanyLogo={true}
                                        style={{
                                            padding: 0
                                        }}
                                    />}
                                </div>
                            </Fade>
                        </Grid>

                        <Hidden mdDown>
                            <Grid item xs={6}>
                                <Typography align="center">
                                    Take control of your home journey with the report that simplifies local real estate.
                                </Typography>
                            </Grid>
                        </Hidden>

                        <Grid item xs align="right" style={{ height: 28, }}>
                            <RobinLogo variant="full" />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Toolbar />

            <div
                style={{
                    paddingRight: !isMobile && theme.spacing(2),
                    paddingLeft: !isMobile && theme.spacing(2),
                    paddingBottom: !isMobile && theme.spacing(2),
                    paddingTop: theme.spacing(4),
                }}
            >
                <AgentProvider agent={agent}>
                    <MyHomeProvider leadId={leadId}>
                        <MyHome scrollRef={containerRef} />
                    </MyHomeProvider>
                </AgentProvider>
            </div>
        </LRModal>
    );
});
