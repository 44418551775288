import React, { useState, useRef, useEffect } from 'react';
import { Box, Popover, Grid, InputAdornment, TextField, useTheme, makeStyles, CircularProgress, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Room, Search, NearMeRounded } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import BusinessIcon from '@material-ui/icons/Business';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { BlinkCursor } from './style';
import { LRChip } from '../../../../components/LRChip';
import analytics from '../../../../services/analytics';
import { COLOR_BLACK, COLOR_CREST_WHITE, COLOR_BRICK } from '../../../../constants';
import { useOnboarding } from 'components/Onboarding';
import { LRAreaAutocomplete } from '../../../../components/LRAreaAutocomplete';
import { isLocationSupported, getLocation } from 'services/location';
import { geocoder } from 'services/gmaps';
import LRConfirmationDialog from 'components/LRConfirmationDialog';

const StyledChip = styled.div`
    height: 26px;
    border-radius: 30px;
    background-color: ${COLOR_BLACK};
    color: ${COLOR_CREST_WHITE};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    font-size: 12px;
    line-height: 18px;
    margin-left: 4px;
`;

const StyledContainerChip = styled.span`
    span {
        white-space: nowrap !important;
    }
`;

const StyledCloseButton = styled.div`
    height: 23px;
    position: absolute;
    top: 0;
    right: 0;
    width: 23px;
`;


export const containerStyles = makeStyles((theme)=> ({
    root: {
        padding: 0,
        border: 'none',
        height: '36px',

        '& > div' : {
            padding: 0,
        },

        '& input': {
            height: '36px',
            border: `1px solid ${theme.palette.lr_colors.border_grey}`,
            padding: '0 8px',
            borderRight: 'none',
            borderRadius: '4px 0 0 4px',
            boxSizing: 'border-box',
        },

        '& fieldset': {
            border: 'none',
        },

        '& ::-webkit-input-placeholder': {
            fontSize: '14px'
        },

        '& ::-moz-placeholder': {
            fontSize: '14px'
        },
    },
}));

export function AreaPopover({
    append,
    formMethods,
    fields,
    handleRemoveArea,
    handleClose,
    isMobile= false,
    enteredPopover,
    setIsInvalid,
}) {
    const theme = useTheme();
    const classes = containerStyles();
    const areaAutocompleteRef = useRef();
    const searchFieldRef = useRef();
    const onboarding = useOnboarding();

    const [openLocationErrorModal, setOpenLocationErrorModal] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(()=> {
        if(enteredPopover) {
            searchFieldRef.current.focus();
        }
    }, [enteredPopover]);

    function handleAreaSelect(area, currentLocation) {
        formMethods.clearError('areaIds');
        formMethods.unregister('areaIds');
        analytics.eventTrack('robin_board_search_submitted', {
            'search_query id':  currentLocation ? 'current_location' : area.id,
            source: 'results_header',
        });
        analytics.eventTrack('robin_searchpage_area_search_submit', {
            'area_id': area.id,
            source: 'results_header',
        });

        if(!fields.find((field)=> field.value === area.id)) {
            formMethods.setValue('areaIds', fields);
            append(area.id);
            formMethods.unregister('areaIds');
            setIsInvalid(false);
            if(onboarding?.isFirstRunOnboarding && onboarding?.stepId === 'optional-expand-search') {
                onboarding.next();
                handleClose();
            }
        }
        areaAutocompleteRef.current.clearSearch();
    }

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
            case 'zip':
                iconElement = <BusinessIcon fontSize="small" />;
                break;
            case 'neighborhood':
                iconElement = <HomeIcon fontSize="small" />;
                break;
            case 'school':
                iconElement = <SchoolIcon fontSize="small" />;
                break;
            default:
                iconElement = <Room fontSize="small" />;
                break;
        }
        return iconElement;
    }

    function filterSuggestions(suggestion) {
        return !fields.find((area)=> {
            return area.value === suggestion.id;
        });
    }

    async function onLocationFetch(location) {
        const geocodeResults = await geocoder.reverseGeocode(location.coords.latitude, location.coords.longitude);
        const firstZip = geocodeResults.find((location)=> location.types.includes('postal_code'))?.
            address_components.find((component)=> component.types.includes('postal_code'))?.
            long_name;
        handleAreaSelect({id: firstZip}, true);
    }

    return (
        <>
            {openLocationErrorModal && <LRConfirmationDialog
                okButton={{ label: 'OK' }}
                onClose={()=> setOpenLocationErrorModal(false)}
            >
                There is no location support on this device or it is disabled.  Please check your settings.
            </LRConfirmationDialog>}
            <div style={{ padding: '18px 16px 16px', width: (isMobile) ? 'calc(100vw - 32px)' : '268px', position: 'relative'}}>
                <div style={{ paddingRight: '30px', minHeight: '20px', position: 'relative', marginBottom: '8px'}}>
                    <Grid container spacing={1}>
                        {fields.map((area, index)=> (
                            <Grid item key={area.id} xs={12} sm={6} >
                                <StyledContainerChip>
                                    <LRChip
                                        style={{borderRadius: '30px', height: '26px', maxWidth: '140px', width: '100%'}}
                                        iconStyle={{height: '16px'}}
                                        label={area.value}
                                        onDelete={()=> handleRemoveArea(index)}
                                    />
                                </StyledContainerChip>
                            </Grid>
                        ))}
                    </Grid>
                    <StyledCloseButton>
                        <CloseIcon style={{width: '23px', heigth:'23px', cursor: 'pointer'}} onClick={handleClose}/>
                    </StyledCloseButton>
                </div>


                <Grid container justify="space-between" spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <LRAreaAutocomplete
                            ref={areaAutocompleteRef}
                            setAnchorElOnFocus={true}
                            onSuggestionSelect={handleAreaSelect}
                            popperProps={{modifiers: {preventOverflow: {
                                enabled: false,
                            }}}}
                            onFocus={(e)=> {
                                setIsInputFocused(true);
                            }}
                            onBlur={(e)=> {
                                setTimeout(()=> setIsInputFocused(false), 200);
                            }}
                            renderInput={(props)=> {
                                return (
                                    <>
                                        <TextField
                                            inputRef={searchFieldRef}
                                            variant="outlined"
                                            color="primary"
                                            placeholder="Enter another area"
                                            height="36px"
                                            className={classes.root}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        style={{margin: 0}}
                                                    >
                                                        <Box
                                                            height="36px"
                                                            width="36px"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            style={{backgroundColor: '#FF6259'}}
                                                            color="#ffffff"
                                                            display="flex"
                                                            borderRadius="0px 4px 4px 0px"
                                                        >
                                                            {
                                                                props.isLoading ? (
                                                                    <CircularProgress color="white" size={20}  />
                                                                ) : (
                                                                    <AddIcon fontSize="small" color="white" />
                                                                )
                                                            }

                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            autoFocus={false}
                                            fullWidth
                                            error={formMethods.errors['areaIds']}
                                            {...props}
                                        />
                                        {
                                            isLocationSupported &&
                                            isInputFocused &&
                                            searchFieldRef.current.value === '' &&
                                        (
                                            <div 
                                                style={{
                                                    position: 'fixed',
                                                    top: `${searchFieldRef.current.getBoundingClientRect().top + 44}px`,
                                                    left: isMobile ? '32px' : '48px',
                                                    height: '56px',
                                                    width: isMobile ? `${searchFieldRef.current.getBoundingClientRect().width + 36}px` : '236px',
                                                    backgroundColor: '#fff',
                                                    border: `1px solid ${theme.palette.lr_colors.grey_2}`,
                                                    borderRadius: '4px',
                                                    boxShadow: '0px 4px 8px 0px #00000040',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    zIndex: 1,
                                                }}
                                                onClick={async ()=> {
                                                    try {
                                                        onLocationFetch(await getLocation());
                                                    } catch (err) {
                                                        setOpenLocationErrorModal(true);
                                                    }
                                                }}
                                            >
                                                <div style={{
                                                    marginLeft: theme.spacing(2),
                                                    display: 'flex',
                                                }}>
                                                    <NearMeRounded />
                                                    <Typography variant="body1" style={{marginLeft: theme.spacing(1)}}>
                                                        Current location
                                                    </Typography>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                );
                            }}
                            suggestionRenderOption={(suggestion)=> {
                                return (
                                    <Box display="flex" style={{ cursor: 'pointer' }}>
                                        <div>{renderSuggestionIcon(suggestion.type)}</div>
                                        <Box ml={1}>{suggestion.id}</Box>
                                    </Box>
                                );
                            }}
                            errorTooltipContent={'Sorry, it looks like we can’t find this area.'}
                            rootContainerProps={{ className: 'tour-target-listpack-area' }}
                            filterSuggestions={filterSuggestions}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}



export function Area({isMobile= false, fieldArray}) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(false);

    const [enteredPopover, setEnteredPopover] = useState(false);
    const formMethods = useFormContext();
    const { fields, append, remove } = fieldArray;
    const onboarding = useOnboarding();
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(()=> {
        if(fields.length === 0 && !isInvalid) {
            formMethods.register('areaIds', { validate: (value)=> value && value.length > 0});
            formMethods.setValue('areaIds', []);
            setIsInvalid(true);
        }
    }, [fields, formMethods.register, formMethods.unregister, formMethods.setValue, formMethods, isInvalid]);

    function handleOpen(event) {
        const { isFirstRunOnboarding, stepId } = onboarding || {};

        const shouldBlockAction = onboarding
            && isFirstRunOnboarding
            && ['listpack-area-filters', 'tour-listpack-area-filters'].includes(stepId);

        if(shouldBlockAction) {
            onboarding.next();
            return;
        }

        if(onboarding && onboarding.isNurtureFirstRunOnboarding ){
            if(['rpp-nurture-first'].includes(onboarding.stepId)){
                analytics.eventTrack('robin_onboarding_search_area_dialog_dismiss');
                onboarding.next();
                return;
            } else {
                return;
            }
        }

        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        if(onboarding?.isFirstRunOnboarding && ['optional-expand-search'].includes(onboarding.stepId)) {
            return;
        }

        setAnchorEl(false);
        setEnteredPopover(false);
    }

    function handleRemoveArea(index) {
        const { isFirstRunOnboarding, stepId } = onboarding || {};
        const shouldBlockAction = onboarding
            && (isFirstRunOnboarding)
            && ['listpack-area-filters', 'tour-listpack-area-filters'].includes(stepId);

        if(shouldBlockAction) {
            onboarding.next();
            return;
        }

        if(onboarding && onboarding.isNurtureFirstRunOnboarding ){
            if(['rpp-nurture-first'].includes(onboarding.stepId)){
                analytics.eventTrack('robin_onboarding_search_area_dialog_dismiss');
                onboarding.next();
                return;
            } else {
                return;
            }
        }

        remove(index);
    }

    return (
        <>
            {fields.map((area, index)=>  <input key={index} type="hidden" defaultValue={area.value} name={`areaIds[${index}]`} ref={formMethods.register()} />)}
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    cursor: 'pointer',
                    width: (isMobile) ? '100%' : '258px',
                }}
                onClick={handleOpen}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    borderColor={theme.palette.lr_colors.border_grey}
                    bgcolor={theme.palette.lr_colors.crest_white}
                    border={1}
                    padding="5px 8px"
                    borderRadius={4}
                    borderRight="none"
                    height={36}
                    borderRadius="4px 0px 0 4px"
                    width={(isMobile) ? '100%' : '268px'}
                >
                    {fields.length > 0 && (
                        <StyledContainerChip>
                            <LRChip
                                style={{borderRadius: '30px', height: '26px', minHeight: '26px', maxWidth: '140px'}}
                                iconStyle={{height: '16px'}}
                                label={fields[fields.length - 1].value}
                                onDelete={()=> handleRemoveArea(fields.length - 1)}
                            />
                        </StyledContainerChip>
                    )}
                    {fields.length > 1 && (
                        <StyledChip>
                            {fields.length - 1} more
                        </StyledChip>
                    )}
                    <BlinkCursor />
                </Box>
                <Box
                    color="#DADADA"
                    border="1px solid rgb(255, 98, 89)"
                    bgcolor={COLOR_BRICK}
                    borderRadius="0px 4px 4px 0px"
                    display="flex"
                    width="36px"
                    height="36px"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Search fontSize="small" style={{color: theme.palette.lr_colors.crest_white}} />
                </Box>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onEntered={()=> setEnteredPopover(true)}
            >
                <div className="tour-expand-search-step">
                    <AreaPopover
                        append={append}
                        formMethods={formMethods}
                        fields={fields}
                        handleRemoveArea={handleRemoveArea}
                        handleClose={handleClose}
                        isMobile={isMobile}
                        enteredPopover={enteredPopover}
                        setIsInvalid={setIsInvalid}
                    />
                </div>
            </Popover>
        </>
    );
}
