// eslint-disable-next-line
import Logger from 'js-logger';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Slider, Slide, StyledBoxArrow } from '../../Slider';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Grid, Box, IconButton, AppBar, GridList } from '@material-ui/core';
import AnimatedScroll from '../../../services/animated-scroll';
import { COLOR_SALMON } from '../../../constants';
import BgImage from '../../BgImage';
import { useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
import PinspirationOverlay from '../../Pinspiration/PinspirationOverlay';

const StyledBoxContainer = styled(Box)`
    position: relative;
    padding-left: 80px;
    padding-right: 80px;
    max-width: 1080px;
    overflow: hidden;
`;
const StyledContainerGrid = styled(Grid)`
    height: calc(100% - 104px);
`;
const StyledSlider = styled(Slider)`
    position: initial !important;

    & .glide__slides {
        padding: 0 !important;
    }
`;
const StyledSlide = styled(Slide)`
    margin-top: auto;
    margin-bottom: auto;
`;
const StyledImg = styled.img`
    width: 100%;
    max-width: 1080px;
    max-height: calc(100vh - 128px - 64px - 104px);
    opacity: 0;
`;
const StyledImageBgImage = styled(BgImage)`
    background-size: contain;
    position: relative;
`;
const StyledGalleryButton = styled(IconButton)`
    border-radius: 0;
    color: #fff;
`;
const StyledPhotoCountGrid = styled(Grid)`
    min-height: 64px;
    color: #fff;
`;
const StyledThumbnailsAppBar = styled(AppBar)`
    padding: 0 12px;
    background-color: #fff;
`;
const StyledCarouselGridItem = styled(Grid)`
    max-width: 100%;
`;
const StyledThumbnailsGridList = styled(GridList)`
    flex-wrap: nowrap;
    transform: translateZ(0);
    margin: 0 auto !important;
    padding: 16px 0 24px;
`;
const ThumbnailImage = styled.img`
    max-width: 88px;
    max-height: 64px;
    border: ${({ selected })=> (selected ? `4px solid ${COLOR_SALMON}` : '0')};
    box-sizing: border-box;
    margin: 0 4px;
    cursor: pointer;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;

/**
 * Base Component for the Next and Previous buttons
 *
 * @function NextPreviousButton
 */
function NextPreviousButton({ children, ...otherProps }) {
    return (
        <StyledGalleryButton variant="text" {...otherProps}>
            {children}
        </StyledGalleryButton>
    );
}

/**
 * Previuos Button
 */
function PreviousButton(props) {
    return (
        <StyledBoxArrow {...props} left="0">
            <NextPreviousButton>
                <KeyboardArrowLeftIcon style={{ fontSize: '3.5rem' }} />
            </NextPreviousButton>
        </StyledBoxArrow>
    );
}

/**
 * Next Button
 */
function NextButton(props) {
    return (
        <StyledBoxArrow {...props} right="0">
            <NextPreviousButton>
                <KeyboardArrowRightIcon style={{ fontSize: '3.5rem' }} />
            </NextPreviousButton>
        </StyledBoxArrow>
    );
}

function Carousel({ listing, listingId, listpackId, photos, requestSource, reportId }) {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [glide, setGlide] = useState();
    const thumbnailGridListRef = useRef(null);
    const thumbnailsAppBarRef = useRef(null);
    const { ListpackStore } = useStore();
    const { listpack } = ListpackStore;
    /**
     * @function handleOnSlideFocus
     * @param {Number} index
     */
    function handleOnSlideFocus(index) {
        setCurrentSlideIndex(index);
        syncThumbnailGridListPosition(index);
    }

    /**
     * Stores the glidejs instance so we can us it
     *
     * @function handleOnInit
     * @param {Object} glideInstance
     */
    function handleOnInit(glideInstance) {
        setGlide(glideInstance);
    }

    /**
     * @function handleThumbnailClick
     * @param {Event} e
     * @param {Number} index
     */
    function handleThumbnailClick(e, index) {
        analytics.eventTrack('listpacks_consumer_listingpage_gallery_thumbnail_click', {
            'address': listing.address,
            'listing id': listing.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            source: requestSource,
            report_id: reportId,
        });
        glide.go(`=${index}`);
    }

    /**
     * Syncs the thumbnail scroll bar so the clicked thumbnail
     * will show in the middle. This will only work if there
     * is enough space to scroll.
     *
     * @function syncThumbnailGridListPosition
     * @param {Number} index
     */
    function syncThumbnailGridListPosition(index) {
        const elem = thumbnailGridListRef.current;
        const scroll = new AnimatedScroll(elem);
        const maxScroll = Array.from(elem.children)
            .reduce((result, el)=> el.offsetWidth + result, 0);
        const targetChild = elem.children[index];
        const centerOffset = elem.offsetWidth / 2;
        let scrollAmount = Array.from(elem.children)
            .slice(0, index)
            .reduce((result, el)=> result + el.offsetWidth + 8, 0);
        scrollAmount -= centerOffset - targetChild.offsetWidth / 2;

        if(scrollAmount > maxScroll) scrollAmount = maxScroll;
        if(scrollAmount < 0) scrollAmount = 0;

        scroll.left(scrollAmount, 300);
    }

    return (
        <>
            <StyledContainerGrid container direction="column" alignItems="center" justify="space-around" wrap="nowrap">
                {/* Current Image Index / Total Images */}
                <Box display="flex" flexGrow={1} alignContent="center">
                    <StyledPhotoCountGrid container alignContent="center" justify="center">
                        <Box>
                            <span>{currentSlideIndex + 1}</span> of <span>{photos.length}</span>
                        </Box>
                    </StyledPhotoCountGrid>
                </Box>

                {/* Main Carousel */}
                <StyledCarouselGridItem item>
                    <StyledBoxContainer>
                        <StyledSlider
                            type="carousel"
                            collection={photos}
                            gap={0}
                            previousButtonComponent={PreviousButton}
                            nextButtonComponent={NextButton}
                            onSlideFocus={handleOnSlideFocus}
                            onInit={handleOnInit}
                            index={currentSlideIndex}
                        >
                            {photos.map((photo, i)=> (
                                <StyledSlide key={i}>
                                    <StyledImageBgImage src={photo}>
                                        {/*
                                                I know this seems weird to do but having the original image
                                                helps to make sure everything displays correctly and doesnt
                                                cause everything to shrink to 0.
                                             */}
                                        <StyledImg src={photo} alt={`Item ${i}`} />
                                        {listing && <PinspirationOverlay
                                            src={photo}
                                            listing={listing}
                                            listpackId={listpackId}
                                        />}
                                    </StyledImageBgImage>
                                </StyledSlide>
                            ))}
                        </StyledSlider>
                    </StyledBoxContainer>
                </StyledCarouselGridItem>

                {/* Filler to make the carousel display centered */}
                <Box flexGrow={1}>
                    <StyledPhotoCountGrid>&nbsp;</StyledPhotoCountGrid>
                </Box>
            </StyledContainerGrid>

            {/* Thumbnails */}
            <StyledThumbnailsAppBar ref={thumbnailsAppBarRef} position="static">
                <StyledThumbnailsGridList ref={thumbnailGridListRef} spacing={0}>
                    {photos.map((photo, i)=> (
                        <ThumbnailImage
                            key={i}
                            src={photo}
                            onClick={_.partial(handleThumbnailClick, _, i)}
                            selected={i === currentSlideIndex}
                        />
                    ))}
                </StyledThumbnailsGridList>
            </StyledThumbnailsAppBar>
        </>
    );
}
Carousel.propTypes = {
    photos: PropTypes.array.isRequired
};

export default React.memo(Carousel);
