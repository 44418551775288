import React, { useEffect, useState, useRef } from 'react';
import { Box, Divider, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { LeadContactForm } from '../LeadContactForm';
import LRButton from '../../LRButton';
import { useResponsiveBreakpoint } from '../../../hooks';
import { COLOR_GREY_DARK, COLOR_GREY_LIGHT, COLOR_SALMON } from '../../../constants';
import { GoogleAutocomplete } from '../../GoogleAutocomplete';
import { useForm } from 'react-hook-form';
import { useRFHInput } from '../../../hooks/useRFHInput';
import { Redirect } from 'react-router-dom';
import Lockr from 'lockr';
import { useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
import { portalApi } from '../../../apis';
import launchdarkly from '../../../services/launchdarkly';

const DesktopBackground = styled(Box)`
    background-image: url('${(props)=> props.image}');
    background-position: center;
    background-size: cover;
`;

const imageSteps = [1, 2, 3, 4];

function loadImage(imageUrl) {
    const image = new Image();
    image.src = imageUrl;
    return new Promise((resolve, reject)=> {
        image.onload = function() {
            resolve();
        };
        image.onerror = function() {
            reject();
        };
    });
}

const useLoadedBackgroundImages = function() {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(()=> {
        async function loadImages() {
            setLoading(true);
            const loadingImagesPromise = imageSteps.map((step)=> {
                return Promise.all([
                    loadImage(`/images/rate-farm/rate-farm-desktop-2x-background-${step}.png`),
                    loadImage(`/images/rate-farm/rate-farm-mobile-background-${step}.png`),
                ]);
            });
            await Promise.all(loadingImagesPromise);
            setLoading(false);
            setLoaded(true);
        }
        loadImages();
    }, []);
    return [loaded, loading];
};

const rateFarmAddressHeaderFlag = 'rate-farm-consumer-address-header';

function RateFarmHome() {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const [imagesLoaded] = useLoadedBackgroundImages();
    const isMobile = isXs || isSm;
    const [currentImageStep, setCurrentImageStep] = useState(1);
    const [redirectToMap, setRedirectToMap] = useState(false);
    const [noRatesFoundAddress, setNoRatesFoundAddress] = useState('');
    const [headerVersion, setheaderVersion] = useState(launchdarkly.variation(rateFarmAddressHeaderFlag));
    const [resetSearch, setResetSearch] = useState(false);
    const headerCopy = (()=> {
        if(!headerVersion) return ' ';
        switch(headerVersion) {
            case 'a':
                return 'Compare your mortgage interest rate to other peeps nearby.';
            case 'b':
                return 'See how your interest rate compares to those around you.';
            case 'c':
                return 'Is your mortgage rate higher than those next door? Find out now.';
            case 'd':
                return 'Stop overpaying for your mortgage. See what others are paying.';
            default:
                return 'Compare your mortgage interest rate to other peeps nearby.';
        }
    })();
    const goRef = useRef(null);

    function handleLaunchdarklyChange() {
        setheaderVersion(launchdarkly.variation(rateFarmAddressHeaderFlag));
    }

    function handleLaunchdarklyError() {
        console.error('Launchdarkly error: Launchdarkly returned an error, redirecting to list-view.');
        setheaderVersion('list-view');
    }

    launchdarkly.on('ready', handleLaunchdarklyChange);
    launchdarkly.on('change', handleLaunchdarklyChange);
    launchdarkly.on('error', handleLaunchdarklyError);

    const { RateFarmStore } = useStore();
    const user = RateFarmStore.user || {};

    const rfhMethods = useForm();

    const { register, handleSubmit, errors } = rfhMethods;

    const { handleChange: setAddress } = useRFHInput('address', {
        ...rfhMethods,
        register: register({ required: true }),
    });

    useEffect(()=> {
        if(!imagesLoaded) {
            return;
        }
        const value = currentImageStep + 1;
        if(!imageSteps.includes(value)) return;

        const timer = setTimeout(()=> {
            setCurrentImageStep(value);
        }, 1500);
        return ()=> clearTimeout(timer);
    }, [currentImageStep, imagesLoaded]);

    useEffect(()=> {
        if(!user) return;

        analytics.eventTrack('rate_farm_consumer_page_viewed', {
            distinct_id: user.id,
            Source: 'address_page',
            404: false,
        });
    }, [user]);

    function wrapUpAddress(address) {
        let foundRate = false;
        if(RateFarmStore.addressInfo?.properties?.length) {
            setRedirectToMap(address);
        } else {
            setNoRatesFoundAddress(address);
            setResetSearch(true);
        }

        if(RateFarmStore.addressInfo?.targetProperty?.loanData?.interestrate) {
            foundRate = true;
        }

        analytics.eventTrack('rate_farm_consumer_address_submit', {
            distinct_id: user.id,
            'Rate Found': foundRate,
        });
    }

    const onAddressSubmit = (data)=> {
        Lockr.set('rate-farm-latest-searched-address', data.address.formatted_address);
        setNoRatesFoundAddress('');

        RateFarmStore.fetchRates(data.address.formatted_address)
            .then(()=> {
                wrapUpAddress(data.address.formatted_address);
            });

        const rateFarmSubmittedLead = Lockr.get('rate-farm-submitted-lead');
        const rateFarmCreatedUnknownActivity = Lockr.get('rate-farm-created-unknown-activity');
        const rateFarmLeadSubmitActivity = Lockr.get('rate-farm-submitted-lead-activity');
        if(!rateFarmSubmittedLead && !rateFarmCreatedUnknownActivity) {
            portalApi.createActivity({
                name: 'lead_rate_farm_unknown_address_search',
                userId: user.id,
                type: 'lead',
            });

            Lockr.set('rate-farm-created-unknown-activity', true);
        } else if(rateFarmSubmittedLead && rateFarmLeadSubmitActivity && !rateFarmLeadSubmitActivity.meta?.address) {
            portalApi
                .createActivity({
                    name: 'lead_rate_farm_lead_search_address_after_creation',
                    userId: user.id,
                    type: 'lead',
                    meta: {
                        leadName: rateFarmSubmittedLead.applicant.name,
                        address: data.address.formatted_address,
                    },
                })
                .then((activity)=> Lockr.set('rate-farm-submitted-lead-activity', activity));
        }
    };

    function shouldResetSearch(changeBack) {
        if(changeBack) {
            setResetSearch(false);
        }
        return resetSearch;
    }

    if(redirectToMap) {
        const url = user ? `/rate-farm/${user.id}/${encodeURIComponent(redirectToMap)}` : '';

        return <Redirect push to={url} />;
    }

    return (
        <Box height={{ md: '100vh' }}>
            <Box
                display="flex"
                height="100%"
                width={1}
                maxWidth="1600px"
                mx="auto"
                flexDirection={{ xs: 'column', md: 'row' }}
            >
                <Box display="flex" flexDirection="column">
                    <Box pt={{ xs: '64px', md: '30px', xl: '64px' }} px={{ xs: '32px', md: '85px' }}>
                        <img src="/images/logo_v2.png" alt="lr_logo" />

                        <Box
                            mt={{ xs: '15px', md: '20px', xl: '35px' }}
                            maxWidth={{ xs: 300, md: 735 }}
                            fontSize={{ xs: '24px', md: '48px', xl: '48px' }}
                            lineHeight={['32px', '56px']}
                            fontWeight="500"
                        >
                            {headerCopy}
                        </Box>
                        <form onSubmit={handleSubmit((data)=> onAddressSubmit(data))} noValidate>
                            <Box position="relative" mb={isMobile ? '68px' : 0}>
                                <Box
                                    display="flex"
                                    mt={{ xs: '35px', md: '15px', xl: '50px' }}
                                    border={`1px solid ${errors.address ? COLOR_SALMON : '#E0E0E0'}`}
                                    maxWidth="675px"
                                    borderRadius={{ xs: '2px', md: '4px' }}
                                    overflow="hidden"
                                    boxShadow="0px 0px 16px rgba(0, 0, 0, 0.1)"
                                >
                                    <GoogleAutocomplete
                                        // inputPropCeption.
                                        // We have to go deeper Leo.
                                        // *Inception sound*
                                        inputProps={{
                                            width: '100%',
                                            inputProps: {
                                                placeholder: 'Your home address',
                                                placeholderFontSize: isMobile ? 16 : 24,
                                                width: '100%',
                                                border: '0px',
                                                height: isMobile ? '48px' : '72px',
                                                paddingLeft: '24px',
                                                paddingRight: '24px',
                                                fontSize: isMobile ? '16px' : '24px',
                                            },
                                        }}
                                        onChange={({ place })=> {
                                            setAddress(place);
                                            goRef.current.click();
                                        }}
                                        resetSearch={shouldResetSearch}
                                    />
                                    <LRButton
                                        type="submit"
                                        ref={goRef}
                                        style={{
                                            width: isMobile ? '57px' : '98px',
                                            height: isMobile ? '48px' : '72px',
                                            borderRadius: 0,
                                        }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        <Box
                                            fontSize={isMobile ? '16px' : '24px'}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {!RateFarmStore.loading ? (
                                                'Go'
                                            ) : (
                                                <CircularProgress color="white" size={isMobile ? '24px' : '36px'} />
                                            )}
                                        </Box>
                                    </LRButton>
                                </Box>
                                {errors.address ? (
                                    <Box
                                        position="absolute"
                                        top={{ xs: '66px', md: '96px' }}
                                        color={COLOR_SALMON}
                                        fontSize="16px"
                                        display="flex"
                                    >
                                        <Box mr={1}>⚠️</Box>
                                        <Box>Please enter an address and select an option from the dropdown menu.</Box>
                                    </Box>
                                ) : null}
                            </Box>
                        </form>
                        {noRatesFoundAddress.length ? (
                            <Box
                                display={isMobile ? '' : 'flex'}
                                width="100%"
                                mt={isMobile ? 0 : '40px'}
                                justifyContent="space-between"
                            >
                                <Box fontSize="24px">
                                    <Box fontWeight="bold">
                                        Hrmm... we weren't able to locate rates near {noRatesFoundAddress}
                                    </Box>
                                    <Box mt="22px">
                                        Don’t fret! You can either enter a different property address or contact{' '}
                                        {user.name} directly using the handy form on this page. Easy peasy.
                                    </Box>
                                </Box>
                                <Box width="fit-content" mx="auto" my={isMobile ? '38px' : 0}>
                                    <img
                                        src={'/images/rate-farm/rate-farm-rates-not-found.png'}
                                        alt="address-rates-not-found"
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Box marginBottom={isMobile ? 0 : '-82px'} />
                        )}
                    </Box>
                    <Box display="flex" flexDirection="column" zIndex={-1} flex={1}>
                        {!noRatesFoundAddress.length && (
                            <DesktopBackground
                                image={`/images/rate-farm/rate-farm-${
                                    isMobile ? 'mobile' : 'desktop-2x'
                                }-background-${currentImageStep}.png`}
                                flex={1}
                                minHeight={isMobile ? '516px' : 0}
                            />
                        )}
                    </Box>
                    {!isMobile ? (
                        <>
                            <Divider />
                            <Box display="flex" alignItems="center" padding="16px">
                                <Box fontSize="13px" color={COLOR_GREY_DARK}>
                                    Feel free to plug in your address, it won’t be shared with anyone else until you
                                    tell us to. Results illustrate interest rates in your neighborhood with a 30 year
                                    fixed loan term, and are based on public information where available or otherwise
                                    reflect current interest rates from Freddie Mac. By using this tool, you acknowledge
                                    the following: ListReports strives to keep its information accurate and up to date
                                    but cannot guarantee the accuracy or completeness of this information. Results from
                                    this tool are designed for comparative and illustrative purposes only. ListReports
                                    is not a lender; this is not an advertisement to extend consumer credit.
                                    Qualification for loan programs will depend largely on your credit score, income
                                    information, cash reserves, and other factors. Equal Housing Opportunity.
                                </Box>
                                <Box padding={isMobile ? '16px 0 0' : 0} mx={isMobile ? 'auto' : '15px'}>
                                    <img src="/images/equal-housing-logo.png" alt="equal-housing-logo" />
                                </Box>
                            </Box>
                        </>
                    ) : null}
                </Box>
                <Box
                    p="32px"
                    bgcolor={COLOR_GREY_LIGHT}
                    width="100%"
                    height={{ md: '100%' }}
                    overflow={{ md: 'auto' }}
                    maxWidth={isMobile ? 1 : '360px'}
                    minWidth={isMobile ? 0 : '360px'}
                >
                    <LeadContactForm
                        user={user}
                        loadingUser={RateFarmStore.loadingUser}
                        loadedUser={RateFarmStore.loadedUser}
                        source={'address_page'}
                        isMobile={isMobile}
                    />
                </Box>
                {isMobile ? (
                    <Box p={2}>
                        <Box fontSize="13px" color={COLOR_GREY_DARK} mb={1}>
                            Feel free to plug in your address, it won’t be shared with anyone else until you tell us to.
                            Results illustrate interest rates in your neighborhood with a 30 year fixed loan term, and
                            are based on public information where available or otherwise reflect current interest rates
                            from Freddie Mac. By using this tool, you acknowledge the following: ListReports strives to
                            keep its information accurate and up to date but cannot guarantee the accuracy or
                            completeness of this information. Results from this tool are designed for comparative and
                            illustrative purposes only. ListReports is not a lender; this is not an advertisement to
                            extend consumer credit. Qualification for loan programs will depend largely on your credit
                            score, income information, cash reserves, and other factors. Equal Housing Opportunity.
                        </Box>
                        <Box textAlign="center">
                            <img src="/images/equal-housing-logo.png" alt="equal-housing-logo" />
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}

export default observer(RateFarmHome);
