import React from 'react';
import { AgentNotificationNewNote } from './AgentNotificationNewNote';
import { useTheme } from '@material-ui/core';
import { AgentNotificationNewSearchCreated } from './AgentNotificationNewSearchCreated';
import { AgentNotificationNewListing } from './AgentNotificationNewListing';
import { AgentNotificationMessage } from './AgentNotificationMessage';
import { useStore } from '../../../../mobx-store';
import { observer } from 'mobx-react-lite';

export const AgentNotifications = observer(function AgentNotifications({
    onPrimaryNotificationButtonClick = ()=> {},
}) {
    const theme = useTheme();
    const {
        NotificationsStore,
    } = useStore();

    // Functions

    function onMarkAsReadClick(e, notification) {
        NotificationsStore.markAsRead(notification, 'agent_actions_notifications');
    }

    return (
        <div className="AgentNotifications" style={{ textAlign: 'left' }}>
            {NotificationsStore.unreadAgentActionsNotifications.map((notification)=> (
                <div
                    key={`agent_actions_notification_container_${notification?.id}`}
                    style={{
                        marginTop: theme.spacing(1)
                    }}
                >
                    {notification.type === 'comment' && <AgentNotificationNewNote notification={notification} onMarkAsReadClick={onMarkAsReadClick} onPrimaryCTAClick={onPrimaryNotificationButtonClick} />}
                    {notification.type === 'created_saved_search' && <AgentNotificationNewSearchCreated notification={notification} onMarkAsReadClick={onMarkAsReadClick} onPrimaryCTAClick={onPrimaryNotificationButtonClick} />}
                    {notification.type === 'new_agent_recommended_homes' && <AgentNotificationNewListing notification={notification} onMarkAsReadClick={onMarkAsReadClick} onPrimaryCTAClick={onPrimaryNotificationButtonClick} />}
                    {notification.type === 'agent_message' && <AgentNotificationMessage notification={notification} onMarkAsReadClick={onMarkAsReadClick} onPrimaryCTAClick={onPrimaryNotificationButtonClick} />}
                </div>
            ))}
        </div>
    );
});
