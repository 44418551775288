import _ from 'lodash';
import { decorate, action, extendObservable, computed } from 'mobx';
import { benoitApi } from '../../apis';

const filterFields = ['zipcode', 'match_score'];

const preferenceFields = [
    'stories',
    'minPrice',
    'maxPrice',
    'minBeds',
    'maxBeds',
    'minBaths',
    'maxBaths',
    'minSqft',
    'maxSqft',
    'propertyType',
    'lotSize',
    'yearBuilt',
    'daysOnListreports',
    'garage',
    'poolOrSpa',
    'schools',
    'walkingDistanceToSchools',
    'walkingDistanceToShopping',
    'walkingDistanceToParks',
    'walkingDistanceToRestaurants'
];

export class BoardPreferences {
    constructor(boardPreferences, board) {
        extendObservable(this, { boardPreferences });
        this.board = board;
        this.mapPreferences();
    }

    get isPreferenceSetup() {
        var result = undefined;
        if(this.boardPreferences) {
            result = this.boardPreferences.setup;
        }
        return result;
    }

    get preferenceByKey() {
        let boardPreferencesHash = {};

        if(this.boardPreferences) {
            boardPreferencesHash = this.boardPreferences.filters.reduce((accum, filter)=> {
                return {
                    ...accum,
                    [filter.name]: filter
                };
            }, {});

            boardPreferencesHash = this.boardPreferences.preferences.reduce((accum, preference)=> {
                return {
                    ...accum,
                    [preference.name]: preference
                };
            }, boardPreferencesHash);
        }

        let allBoardPreferences = _.concat(this.filterFields, this.preferenceFields);
        return allBoardPreferences.reduce((accum, fieldName)=> {
            const field = boardPreferencesHash[fieldName];
            if(field) {
                return accum;
            }
            return {
                ...accum,
                [fieldName]: { name: fieldName, value: null }
            };
        }, boardPreferencesHash);
    }

    mapPreferences() {
        // check if already exists

        const filters = filterFields.map((value)=> {
            return this.getPreference(value);
        });

        const preferences = preferenceFields.map((value)=> {
            return this.getPreference(value);
        });
        this.boardPreferences.preferences = preferences;
        this.boardPreferences.filters = filters;
    }

    getPreference = (key)=> {
        const isFilter = filterFields.includes(key);

        let response;

        if(!this.boardPreferences) {
            return { name: key, value: null };
        }

        if(isFilter) {
            response = this.boardPreferences.filters.find((filter)=> {
                return filter.name === key;
            });
        } else {
            response = this.boardPreferences.preferences.find((preference)=> {
                return preference.name === key;
            });
        }

        return response ? response : { name: key, value: null };
    };

    setPreference = (key, value)=> {
        const isFilter = filterFields.includes(key);

        var obj;

        if(isFilter) {
            obj = this.boardPreferences.filters.find((filter)=> {
                return filter.name === key;
            });
        } else {
            obj = this.boardPreferences.preferences.find((filter)=> {
                return filter.name === key;
            });
        }
        obj.value = value;
    };

    upsertPreferences = ()=> {
        const { user } = this.UserStore;
        this.boardPreferences.updatedById = user.id;
        var preferencesObj = { preferences: this.boardPreferences.preferences, filters: this.boardPreferences.filters };
        return benoitApi.upsertBoardPreferences(this.board.id, preferencesObj);
    };

    resetActiveBoardPreferences = ()=> {
        this.boardPreferences.setup = false;
        benoitApi
            .resetBoardPreferences(this.board.id)
            .then((res)=> {
                const filters = filterFields.map((value)=> {
                    return { name: value, value: null };
                });
                const preferences = preferenceFields.map((value)=> {
                    return { name: value, value: null };
                });
                this.boardPreferences = { setup: false, preferences: preferences, filters: filters };
            })
            .catch((err)=> {
                this.boardPreferences.setup = true;
            });
    };

    createBoardPreferences = ()=> {
        const { user } = this.UserStore;
        this.boardPreferences.setup = true;
        this.boardPreferences.createdById = user.id;
        this.boardPreferences.updatedById = user.id;
        return benoitApi.createBoardPreferences(this.board.id, this.boardPreferences);
    };

    get UserStore() {
        return this.board.boardsStore.rootStore.UserStore;
    }
}

decorate(BoardPreferences, {
    isPreferenceSetup: computed,
    preferenceByKey: computed,

    setPreference: action,
    upsertPreferences: action,
    resetActiveBoardPreferences: action,
    createBoardPreferences: action
});
