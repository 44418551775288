import { decorate, flow, observable, action, computed } from 'mobx';
import { portalApi } from '../../apis';
import { DEFAULT_HEADSHOT } from '../../constants';
import Logger from 'js-logger';
import moment from 'moment-timezone';
import lodashGet from 'lodash/get';

const logger = Logger.get('LrLiveStore');
const EVENT_DURATION_IN_MINS = 60;

export class LrLiveStore {
    loadingLrLive = false;
    loadingLead = false;
    loadingSponsor = false;
    processingRegistration = false;
    lrLive = null;
    error = false;
    startTime = null;
    lead = null;
    sponsor = null;

    fetchReport = flow(function*(id) {
        try {
            this.loadingLrLive = true;
            const report = yield portalApi.getReport(id);
            if(!report) throw new Error(`Report ${id} not found`);
            this.lrLive = { report };
        } catch (error) {
            this.loadingLrLive = false;
            this.error = true;
            logger.error(error);
            return null;
        }

    });

    fetchLrLive = flow(function*(id) {
        try {
            this.loadingLrLive = true;
            this.lrLive = yield portalApi.getLrLive(id, {
                includeReport: true,
                includeUser: true,
            });
            if(!this.lrLive) throw new Error('LrLive not found');
            this.setStartTime(this.lrLive.scheduledAt);
        } catch (error) {
            return this.fetchReport(id);
        }

        this.loadingLrLive = false;
    });

    fetchSponsor = flow(function*(id) {
        try {
            this.loadingSponsor = true;
            // Replaced sponsor fetch call with a Promise.resolve(null) temporarily
            // until we determine the business rules for the sponsorship feature
            // - amish@listreports.com 2020-05-20

            // this.sponsor = yield portalApi.getLrLiveSponsor(id);
            this.sponsor = yield Promise.resolve(null);
            if(!this.sponsor) throw new Error('LrLive sponsor not found');
        } catch (error) {
            this.loadingSponsor = false;
            logger.error(error);
            return null;
        }

        this.loadingSponsor = false;
    });

    fetchLead = flow(function*(leadId) {
        try {
            this.loadingLead = true;
            this.lead = yield portalApi.getLead(leadId);
            if(!this.lead) throw new Error('Lead not found');
        } catch (error) {
            this.loadingLead = false;
            logger.error(error);
            return null;
        }
    });

    registerLead = flow(function*(meetingId, data) {
        const self = this;
        try {
            self.processingRegistration = true;
            if(!self.lead) {
                // creates a new lead if there isn't one fetched already
                const id = lodashGet(data, 'ownerId') || lodashGet(data, 'agentUserId');
                const { data: createLeadResponse } = yield portalApi.createLead({ id }, data);
                self.lead = createLeadResponse;
            }
            if(!self.lead) throw new Error('Error creating lead');

            const { id: leadId } = self.lead;
            const participant = self.formatLeadForLrLiveLink(data);

            if(self.expiredOpenHouse) {
                // we don't need to get them a link at this point
                // since the event has expired
                self.processingRegistration = false;
                return self.lead;
            }

            const meetingUrls = yield self.getLrLiveLink(meetingId, participant);
            const { short_url } = meetingUrls || {};

            const updateData = {
                ...data,
                applicant: {
                    ...(self.lead.applicant || {}),
                    ...(data.applicant || {}),
                },
                meta: {
                    ...(self.lead.meta || {}),
                    ...(data.meta || {}),
                    digital_open_house_url: short_url,
                },
            };

            delete updateData.id;

            const updatedLead = yield self.updateLead(leadId, updateData);
            self.processingRegistration = false;
            return updatedLead;

        } catch (error) {
            self.processingRegistration = false;
            logger.error('LrLiveStore.createLead ->', error);
            throw new Error(error);
        }
    });

    formatLeadForLrLiveLink = function(data) {
        const lead = {
            ...(this.lead || {}),
            ...(data || {}),
        };
        return {
            headshot: lodashGet(lead, 'applicant.headshot', DEFAULT_HEADSHOT),
            name: lodashGet(lead, 'applicant.name'),
            email: lodashGet(lead, 'applicant.email'),
            phone: lodashGet(lead, 'applicant.phone'),
            id: lodashGet(lead, 'id'),
        };
    };

    getLrLiveLink = flow(function*(meetingId, participant) {
        try {
            const meetingUrls = yield portalApi.getLrLiveLink(meetingId, participant);
            if(!lodashGet(meetingUrls, 'short_url')) throw new Error('Error getting lr live link');
            return meetingUrls;
        } catch (error) {
            logger.error('LrLiveStore.getLrLiveLink ->', error);
            throw new Error(error);
        }

    });

    updateLead = flow(function*(leadId, data) {
        try {
            this.lead = yield portalApi.updateLead(leadId, data);
            if(!this.lead) throw new Error('Error updating lead');
            return this.lead;
        } catch (error) {
            logger.error('LrLiveStore.updateLead ->', error);
            throw new Error(error);
        }
    });

    setStartTime = function (startTime) {
        if(startTime &&
            moment(startTime)
                .isValid()){
            this.startTime = startTime;
        } else {
            logger.error('Invalid startTime');
            this.error = true;
        }
    };

    get formattedStartTime() {
        if(!this.startTime) return null;

        // Here, we need to use moment.tz.guess() to get the EST, CST, PST etc.
        // regular momentjs has deprecated the 'z' formatter
        // since Date().toString() behaves inconsistently
        // More info: https://github.com/moment/moment/issues/162#issuecomment-182539707

        const long = moment(this.startTime)
            .tz(moment.tz.guess())
            .format('MMM D, YYYY [at] h:mm A z');

        const start_date = moment(this.startTime)
            .format('dddd, MMMM Do YYYY');

        const start_time = moment(this.startTime)
            .format('h:mm A');

        const start_time_24h = moment(this.startTime)
            .format('HH:mm');

        const end_moment = moment(this.startTime)
            .add(EVENT_DURATION_IN_MINS, 'minutes');

        const end_time = end_moment.format('h:mm A');
        const end_time_24h = end_moment.format('HH:mm');

        return {
            long,
            start_date,
            start_time,
            end_time,
            start_time_24h,
            end_time_24h,
        };
    }

    get isLiveNow() {
        const end_moment = moment(this.startTime)
            .add(EVENT_DURATION_IN_MINS, 'minutes');

        const live_now = (
            moment()
                .isAfter(this.startTime) &&
            moment()
                .isBefore(end_moment)
        );

        return live_now;
    }

    get expiredOpenHouse() {
        const now = moment();
        const expired = this.startTime
            ? moment(this.startTime)
                .add(EVENT_DURATION_IN_MINS, 'minutes')
                .isBefore(now)
            : false;

        return expired;
    }
}

decorate(LrLiveStore, {
    // Observables
    error: observable,
    loadingLead: observable,
    loadingLrLive: observable,
    loadingSponsor: observable,
    lead: observable,
    lrLive: observable,
    sponsor: observable,
    startTime: observable,
    processingRegistration: observable,

    // Actions
    registerLead: action,
    fetchLead: action,
    fetchLrLive: action,
    fetchSponsor: action,
    getLrLiveLink: action,
    setStartTime: action,
    updateLead: action,

    // Computed
    formattedStartTime: computed,
    expiredOpenHouse: computed,
});
