import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { Box, CircularProgress, CardMedia, Divider, Grid, Typography, Dialog, IconButton } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import {
    COLOR_GREY_DARK,
    COLOR_BLUE_LINK,
    COLOR_SALMON,
    DEFAULT_HEADSHOT
} from '../../../constants';
import styled from 'styled-components';
import { useResponsiveBreakpoint, useResponsiveConfig } from '../../../hooks';
import { useStore } from '../../../mobx-store';
import Logger from 'js-logger';
import { observer } from 'mobx-react-lite';
import { Swiper } from '../../Swiper';

import analytics from '../../../services/analytics';
import formatPhoneNumber from '../../../services/format-phone-number';
import addCommas from '../../../services/add-commas';
import Header from '../../../components/Header';
import CountdownTimer from '../CountdownTimer';
import LeadForm from '../LeadForm';
import SponsorCompliance from '../SponsorCompliance';
import LRButton from '../../LRButton';
import LRStickyHeader from '../../LRStickyHeader';
import LRStickyFooter from '../../LRStickyFooter';

const logger = Logger.get('DigitalOpenHouseRegistration');

const AgentHeadshot = withTheme(styled(CardMedia)`
    margin-top: 16px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 40px;
    height: 40px;
    border-radius: 50%;

    ${({ theme })=> theme.breakpoints.up('xs')} {
        width: 64px;
        height: 64px;
    }
`);

const PhoneNumber = styled.a`
    color: inherit;
    text-decoration: unset;
`;

const BlueLink = styled.a`
    cursor: pointer;
    color: ${COLOR_BLUE_LINK};
    text-decoration: unset;
`;

const PageDivider = withTheme(styled(Divider)`
    height: 2px;
    background-color: #eeecee;
    width: 24px;
    margin: 15px auto;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        margin: 12px 0;
    }
`);

const BoldTypography = styled(Typography)`
    font-weight: 500;
`;

const Address = withTheme(styled(BoldTypography)`
    whiteSpace: 'nowrap';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
    line-height: 32px;
    font-size: 24px;
    letter-spacing: 0.15;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 20px;
        line-height: 26px;
    }
`);

const SectionTitle = withTheme(styled(Box)`
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 0.15;
    vertical-align: middle;
    margin-bottom: 6px;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 18px;
    }
`);

const ListingPrice = withTheme(styled(Box)`
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 0.15;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 18px;
        line-height: 32px;
    }
`);

const ListingStats = withTheme(styled(Box)`
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 0.15;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        font-size: 16px;
    }
`);

const SemiBold = styled.b`
    font-weight: 500;
`;

const LiveBadge = styled(Box)`
    position: relative;
    top: -4px;
    display: inline-block;
    color: white;
    font-size: 12px;
    padding: 3px 6px;
    background-color: ${COLOR_SALMON};
    border-radius: 2px;
    margin-left: 2px;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
`;


function splitAddress(address) {
    return (address || '').replace(/^([\w\s\d#.:]*)(,)/i, '$1|||')
        .split('|||');
}


function Preloader({ error }) {
    return (
        <Box display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            mt={5}
        >
            {!error && <CircularProgress color="secondary" />}
            {error && (
                <span>There was an error while loading the open house details. Please try again later.</span>
            )}
        </Box>
    );
}

function ListingInfoCard({ report, isMobile }) {
    const imageList = lodashGet(report, 'images.enhanced_gallery', []).length
        ? lodashGet(report, 'images.enhanced_gallery')
        : lodashGet(report, 'image_pool') || [];

    const cardHeight = useResponsiveConfig({ xs: 200, sm: 220, md: 300, lg: 340 });
    const imgWidth = useResponsiveConfig({ xs: '100%', sm: 300, md: '60%', lg: 500 });
    const address = report.display_address || report.address;
    const [address1, address2] = splitAddress(address);
    return (
        <Grid container
            alignItems="flex-start"
            direction={isMobile ? 'column' : 'row'}
        >
            {(imageList.length > 0) && (
                <Grid item style={{width: imgWidth}}>
                    <Box position="relative"
                        overflow="hidden"
                        textAlign="center"
                        borderRadius={4}
                        pr={[0, 3]}
                    >
                        <Swiper
                            data={imageList}
                            autoplay={true}
                            renderSlide={(image)=> {
                                return (
                                    <Box
                                        height={cardHeight}
                                        width={1}
                                        style={{
                                            backgroundImage: `url('${image}')`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    ></Box>
                                );
                            }}
                        />
                    </Box>
                </Grid>
            )}

            <Grid xs item>
                <Box pt={[3, 0]}
                    textAlign="left"
                >
                    <Address>
                        {address1}<br/>
                        {address2}
                    </Address>
                    {report.price_amount &&
                        <ListingPrice
                            py={[0, 1]}
                        >
                            ${addCommas(report.price_amount)}
                        </ListingPrice>
                    }

                    <ListingStats>
                        {report.bedrooms &&
                            <Box mr={2} display="inline">
                                <SemiBold>{report.bedrooms}</SemiBold> BD
                            </Box>
                        }
                        {report.bathrooms &&
                            <Box mr={2} display="inline">
                                <SemiBold>{report.bathrooms}</SemiBold> BA
                            </Box>
                        }
                        {report.home_size &&
                            <Box display="inline">
                                <SemiBold>{addCommas(report.home_size)}</SemiBold> SQ FT
                            </Box>
                        }
                    </ListingStats>

                    {report.links && report.links.property &&
                        <Box mt={2}>
                            <BlueLink
                                href={report.links.property}
                                target="_blank"
                                title="View property">
                                View property
                            </BlueLink>
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>
    );
}

function AgentInfoCard({ agent, isMobile }) {
    return (
        <Box px={[3, 5]}
            mb={[3, 0]}
        >
            <Box mt={[1, 4]}
                display={isMobile ? 'inline-block' : 'block'}
            >
                <AgentHeadshot image={agent.headshot || DEFAULT_HEADSHOT} />
            </Box>

            <Grid container
                direction={isMobile ? 'row' : 'column'}
                justify="space-between"
                alignItems={isMobile ? 'flex-start' : 'center'}
            >
                <Grid item>
                    <Box align={isMobile ? 'left' : 'center'}
                    >
                        <Box fontSize="20px">{agent.name}</Box>
                        <Box position="relative" fontSize="14px">
                            <Box>{agent.company}</Box>
                            <Box color={COLOR_GREY_DARK}>
                                <Typography variant="caption">
                                    License #{agent.realtor_license_number}
                                </Typography>
                            </Box>
                            <Box textAlign={isMobile ? 'left' : 'center'} mb={2}>
                                <PageDivider></PageDivider>
                            </Box>
                            <Box>
                                <PhoneNumber href={isMobile ? `tel:${agent.phone}` : undefined}>
                                    {formatPhoneNumber(agent.phone)}
                                </PhoneNumber>{' '}
                            </Box>
                            <Box>{agent.email}</Box>
                            {agent.branding &&
                                <Box maxWidth={isMobile? '100%' : '75%'}
                                    pt={2}
                                >
                                    <img src={agent.branding}
                                        alt={agent.company}
                                        height={70}
                                    />
                                </Box>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

function SponsorInfoCard({ sponsor, isMobile }) {
    if(!sponsor) return null;
    return (
        <Box px={[3, 4]}
            mt={[3, 2, 0]}
            mb={3}
        >
            <Box letterSpacing={1.8}
                color={COLOR_GREY_DARK}
            >
                <BoldTypography variant="caption">
                    THIS EVENT BROUGHT TO YOU BY
                </BoldTypography>
            </Box>
            <Box mt={2} display="inline-block">
                <AgentHeadshot image={sponsor.headshot || DEFAULT_HEADSHOT} />
            </Box>

            <Grid container
                direction="column"
            >
                <Grid item>
                    <Box fontSize="20px">{sponsor.name}</Box>
                    <Box position="relative" fontSize="14px">
                        <Box color={COLOR_GREY_DARK}>
                            <Typography variant="caption">
                                {sponsor.title ? `${sponsor.title} at ` : ''}{sponsor.company}
                            </Typography><br/>
                            <Typography variant="caption">
                                NMLS# {sponsor.realtor_license_number}
                            </Typography>
                            {sponsor.mlo_license_number && (
                                <Typography variant="caption">
                                    &nbsp;• {sponsor.mlo_license_state} License: MLO.{sponsor.mlo_license_number}
                                </Typography>
                            )}
                        </Box>
                        <Box display="inline-block">
                            <PageDivider></PageDivider>
                        </Box>
                        <Box>
                            <PhoneNumber href={isMobile ? `tel:${sponsor.phone}` : undefined}>
                                {formatPhoneNumber(sponsor.phone)}
                            </PhoneNumber>{' '}
                        </Box>
                        <Box>{sponsor.email}</Box>
                        {sponsor.branding &&
                            <Box maxWidth={isMobile? '100%' : '75%'}
                                pt={2}
                                mb={1}
                            >
                                <img src={sponsor.branding}
                                    alt={sponsor.company}
                                    height={70}
                                />
                            </Box>
                        }
                        <Box color={COLOR_GREY_DARK}>
                            {sponsor.branch_address && (
                                <Typography variant="caption">
                                    {sponsor.branch_address}<br/>
                                </Typography>
                            )}
                            {sponsor.branch_license_number && (
                                <Typography variant="caption">
                                    Branch NMLS# {sponsor.branch_license_number}&nbsp;•&nbsp;
                                </Typography>
                            )}
                            {sponsor.phone_secondary && (
                                <Typography variant="caption">
                                    Phone: {formatPhoneNumber(sponsor.phone_secondary)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}


function OpenHouseSchedule({formattedStartTime, expiredOpenHouse, archived, isLiveNow, isMobile}) {
    const headingVariant = isMobile ? 'h5' : 'h4';
    const startTime = isMobile
        ? (formattedStartTime)
            ? (formattedStartTime.long || '').split(' at ')
            : ''
        : (formattedStartTime)
            ? formattedStartTime.long || ''
            : '';

    const isUpcoming = !(expiredOpenHouse || archived);
    return (
        <Box>
            <SectionTitle>
                Digital open house {isLiveNow && <LiveBadge>LIVE NOW</LiveBadge>}
            </SectionTitle>

            {(isUpcoming && isMobile && startTime.length) &&
                <Box>
                    <BoldTypography variant={headingVariant}
                        align="left"
                    >
                        {startTime[0]}
                    </BoldTypography>

                    <BoldTypography variant={headingVariant}
                        align="left"
                    >
                        at {startTime[1]}
                    </BoldTypography>
                </Box>
            }

            {(isUpcoming && !isMobile) &&
                <BoldTypography variant={headingVariant}
                    align="left"
                >
                    {startTime}
                </BoldTypography>
            }

            {!isUpcoming && (
                <BoldTypography variant={headingVariant}
                    align="left"
                >
                    This event has {archived ? 'been cancelled' : 'ended'}
                </BoldTypography>
            )}
        </Box>
    );
}

function RegistrationFormBlock({ archived, agent, sponsor, formTitle, handleSubmit, isMobile, isStacked, trackData }) {
    if(!agent) return null;
    return (
        <Box bgcolor="white"
            minHeight={isMobile ? 0 : '100vh'}
            pt={[0, 3]}
        >
            {!(isMobile || isStacked) && <AgentInfoCard agent={agent}/>}

            {!archived && (
                <LeadForm
                    formTitle={formTitle}
                    handleSubmit={handleSubmit}
                    trackData={trackData}
                />
            )}

            {(isMobile || isStacked) && <AgentInfoCard agent={agent} isMobile={true}/>}

            {sponsor && <SponsorInfoCard sponsor={sponsor} isMobile={isMobile}/>}

            {(isMobile || isStacked) && <SponsorCompliance sponsor={sponsor} isMobile={true}/>}
        </Box>
    );
}

function DigitalOpenHouseRegistration() {
    const isMobile = useResponsiveBreakpoint('xs');
    const isStacked = useResponsiveBreakpoint('sm');
    const [formModalOpen, setFormModalOpen] = useState(false);
    const { LrLiveStore } = useStore();
    const { lrLive,
        sponsor,
        startTime,
        formattedStartTime,
        expiredOpenHouse,
        isLiveNow,
        error,
    } = LrLiveStore;
    const {
        report,
        user,
        archived,
    } = lrLive || {};
    const agent = user
        ? user
        : report
            ? report.agent
            : null;
    const meetingId = (lrLive && lrLive.id)
        ? lrLive.id
        : report
            ? report.id
            : null;

    const [ agentFirstName ] = (lodashGet(agent, 'name') || '')
        .split(' ');
    const formTitlePostfix = agentFirstName.length
        ? `${agentFirstName}'s digital open house!`
        : 'the digital open house!';
    const formTitle = expiredOpenHouse
        ? 'Interested in this property? Get in touch.'
        : `Register to join ${formTitlePostfix}`;
    const primaryButtonCta = expiredOpenHouse ? 'Contact agent' : 'Register now';
    const trackData = {
        distinct_id: lodashGet(agent, 'id') || lodashGet(agent, 'agent_user_id'),
        lrlive_id: lodashGet(lrLive, 'id'),
        event_type: lodashGet(lrLive, 'type'),
        sponsor_user_id: lodashGet(lrLive, 'sponsorUserId'),
        'Address': lodashGet(report, 'address'),
        'Start Time': startTime,
        report_id: lodashGet(report, 'id'),
    };

    const handleSubmit = async function (event, data) {
        try {
            const createLeadResponse = await LrLiveStore.registerLead(meetingId, data);
            return createLeadResponse;
        } catch (error) {
            logger.error('Error creating lead', error);
            return null;
        }
    };

    const toggleFormModal = (value)=> (event)=> {
        if(value) analytics.eventTrack('lrlive_consumer_landing_page_mobile_reg_click', trackData);
        setFormModalOpen(value);
    };

    useEffect(()=> {
        if(report && agent) {
            analytics.eventTrack('lrlive_consumer_landing_paged_viewed', {
                'current url': window.location.href,
                ...trackData,
            });
        }
    }, [report, agent, trackData]);


    return (
        <Box display="flex"
            flexDirection="column"
            minHeight="100vh"
            height="100%"
        >
            {!error && formattedStartTime && (
                <Grid container
                    direction={isMobile ? 'column' : 'row'}
                    justify="space-between"
                    item
                >
                    <Grid item
                        sm={12}
                        md={8}
                        xl={9}
                        container
                        direction="column"
                    >
                        <Box maxWidth="100%"
                            minHeight={isMobile ? 'calc(100vh - 72px)': 'auto'}
                            flex={1}
                            style={{
                                backgroundImage: `${report ? "url('/images/lr-live/cityscape_grayscale.png')" : 'none'}`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'bottom',
                                backgroundSize: '100% auto',
                            }}
                        >
                            <Header justifyContent="left"
                                isMobile={isMobile}
                            />
                            <Box px={[3, 5]} pb={[3, 5, 12]}>
                                <OpenHouseSchedule
                                    formattedStartTime={formattedStartTime}
                                    expiredOpenHouse={expiredOpenHouse}
                                    isLiveNow={isLiveNow}
                                    isMobile={isMobile}
                                    archived={archived}
                                />

                                {report && (
                                    <Box py={3}>
                                        <ListingInfoCard
                                            report={report}
                                            isMobile={isMobile}
                                        />
                                    </Box>
                                )}
                            </Box>

                            <Box px={[3, 5]} pb={[3, 5]}>
                                <CountdownTimer startTime={startTime}
                                    expiredOpenHouse={expiredOpenHouse}
                                />
                            </Box>
                        </Box>

                        {!(isMobile || isStacked) && (
                            <Box bgcolor="white">
                                <SponsorCompliance sponsor={sponsor} />
                            </Box>
                        )}

                        {isMobile && (
                            <LRStickyFooter height={72} px={3/2} py={3/2}>
                                <LRButton
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        width: '100%',
                                        height: '48px',
                                        fontSize: '16px',
                                        'minWidth': '114px',
                                    }}
                                    onClick={toggleFormModal(true)}
                                >
                                    {primaryButtonCta}
                                </LRButton>
                            </LRStickyFooter>
                        )}
                    </Grid>

                    {!isMobile && (
                        <Grid item
                            sm={12}
                            md={4}
                            xl={3}
                            container
                            direction="column"
                        >
                            <RegistrationFormBlock
                                archived={archived}
                                agent={agent}
                                sponsor={sponsor}
                                formTitle={formTitle}
                                handleSubmit={handleSubmit}
                                isMobile={isMobile}
                                isStacked={isStacked}
                                trackData={trackData}
                            />
                        </Grid>
                    )}
                </Grid>
            )}

            {isMobile && (
                <Dialog
                    open={formModalOpen}
                    fullScreen
                    onClose={toggleFormModal(false)}
                >
                    <Box height="100vh"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <LRStickyHeader height={52} px={3/2} py={3/2}>
                            <Box textAlign="center">
                                <Typography variant="subtitle1">
                                    {primaryButtonCta}
                                </Typography>
                            </Box>
                            <CloseButton onClick={toggleFormModal(false)} title="Close">
                                <Close />
                            </CloseButton>
                        </LRStickyHeader>

                        <RegistrationFormBlock
                            archived={archived}
                            agent={agent}
                            sponsor={sponsor}
                            formTitle={formTitle}
                            handleSubmit={handleSubmit}
                            isMobile={isMobile}
                            isStacked={isStacked}
                            trackData={trackData}
                        />

                    </Box>
                </Dialog>
            )}

            {(!report || error) && <Preloader error={error} />}
        </Box>
    );
}

export default observer(DigitalOpenHouseRegistration);
