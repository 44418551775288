import React, { useState } from 'react';
import { SwipeableDrawer, Box } from '@material-ui/core';
import { useSwipeable } from 'react-swipeable';
import { ExpandMore } from '@material-ui/icons';
import Sidebar from '../../../../scenes/ListingsMap/Sidebar';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const MobileDrawer = function({
    showDrawer,
    closeDrawer,
    listings,
    selectedListing,
    onSelectListing,
    hoveredListing,
    ...props
}) {
    const history = useHistory();
    const match = useRouteMatch();

    const [swiperInitialized ,setSwiperInitialized] = useState(false);

    const handlers = useSwipeable({ onSwipedDown: (eventData)=> {
        closeDrawer();
    }});

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={true}
            variant="persistent"
            style={{
                zIndex: 1,
                visibility: showDrawer ? 'visible' : 'hidden'
            }}
        >
            <Box height={222} display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" justifyContent="center"  {...handlers} onClick={()=> {
                    closeDrawer();
                }}>
                    <Box width={25} borderRadius="1">
                        <ExpandMore />
                    </Box>
                </Box>
                <Box visibility={showDrawer && swiperInitialized ? 'visible' : 'hidden'}>
                    <Sidebar
                        onSelectListing={onSelectListing}
                        hoveredListing={hoveredListing}
                        listings={listings}
                        onSwiperInit={()=> { setSwiperInitialized(true); }}
                        selectedListing={selectedListing}
                        onListingSelect={(boardCard)=> history.push(`${match.url}/cards/${boardCard.id}`)}
                    />
                </Box>
            </Box>
        </SwipeableDrawer>
    );

};
