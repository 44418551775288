import _ from 'lodash';

export const PRICE_RANGE = [
    { label: 'Any', value: null },
    { label: '$50k', value: '50000' },
    { label: '$75k', value: '75000' },
    { label: '$100k', value: '100000' },
    { label: '$125k', value: '125000' },
    { label: '$150k', value: '150000' },
    { label: '$175k', value:'175000' },
    { label: '$200k', value: '200000' },
    { label: '$225k', value: '225000' },
    { label: '$250k', value: '250000' },
    { label: '$275k', value: '275000' },
    { label: '$300k', value: '300000' },
    { label: '$325k', value: '325000' },
    { label: '$350k', value: '350000' },
    { label: '$375k', value: '375000' },
    { label: '$400k', value: '400000' },
    { label: '$425k', value: '425000' },
    { label: '$450k', value: '450000' },
    { label: '$475k', value: '475000' },
    { label: '$500k', value: '500000' },
    { label: '$550k', value: '550000' },
    { label: '$600k', value: '600000' },
    { label: '$650k', value: '650000' },
    { label: '$700k', value: '700000' },
    { label: '$750k', value: '750000' },
    { label: '$800k', value: '800000' },
    { label: '$850k', value: '850000' },
    { label: '$900k', value: '900000' },
    { label: '$950k', value: '950000' },
    { label: '$1M', value: '1000000' },
    { label: '$1.25M', value: '1250000' },
    { label: '$1.5M', value: '1500000' },
    { label: '$1.75M', value: '1750000' },
    { label: '$2M', value: '2000000' },
    { label: '$2.25M', value: '2250000' },
    { label: '$2.5M', value: '2500000' },
    { label: '$2.75M', value: '2750000' },
    { label: '$3M', value: '3000000' },
    { label: '$3.25M', value: '3250000' },
    { label: '$3.5M', value: '3500000' },
    { label: '$3.75M', value: '3750000' },
    { label: '$4M', value: '4000000' },
    { label: '$4.25M', value: '4250000' },
    { label: '$4.5M', value: '4500000' },
    { label: '$4.75M', value: '4750000' },
    { label: '$5M', value: '5000000' },
    { label: '$6M', value: '6000000' },
    { label: '$7M', value: '7000000' },
    { label: '$8M', value: '8000000' },
    { label: '$9M', value: '9000000' },
    { label: '$10M', value: '10000000' },
];

export const SQUARE_FT_VALUES = [
    { label: 'any', value: null },
    { label: '500', value: '500' },
    { label: '750', value: '750' },
    { label: '1,000', value: '1000' },
    { label: '1,250', value: '1250' },
    { label: '1,500', value: '1500' },
    { label: '1,750', value: '1750' },
    { label: '2,000', value: '2000' },
    { label: '2,250', value: '2250' },
    { label: '2,500', value: '2500' },
    { label: '2,750', value: '2750' },
    { label: '3,000', value: '3000' },
    { label: '3,500', value: '3500' },
    { label: '4,000', value: '4000' },
    { label: '5,000', value: '5000' },
    { label: '7,500', value: '7500' },
];

export const LOT_SIZE_VALUES = [
    { label: 'any', value: null },
    { label: '2,000 sq ft', value: '2000' },
    { label: '4,500 sq ft', value: '4500' },
    { label: '6,500 sq ft', value: '6500' },
    { label: '8,000 sq ft', value: '8000' },
    { label: '.25 acres', value: '10890' },
    { label: '.5 acres', value: '21780' },
    { label: '.75 acres', value: '32670' },
    { label: '1 acres', value: '43560' },
    { label: '2 acres', value: '87120' },
    { label: '3 acres', value: '130680' },
    { label: '4 acres', value: '174240' },
    { label: '5 acres', value: '217800' },
    { label: '10 acres', value: '435600' },
    { label: '40 acres', value: '1742400' },
    { label: '100 acres', value: '4356000' },
];

let currentYear = new Date()
    .getFullYear();
let dates = [{ label: 'any', value: null }];

const firstRange = _.range(currentYear, 2014, -1);
const secondRange = _.range(2010, 2000, -5);
const thirdRange = _.range(2000, 1890, -10);

const yearRange = firstRange.concat(secondRange)
    .concat(thirdRange);

export const YEAR_RANGE = dates.concat(yearRange.map((year)=> ({ label: year.toString(), value: year.toString() })));
