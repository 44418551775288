import React from 'react';
import { Box, Grid, Divider, useTheme, Hidden } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

import { DAYS_ON_MARKET } from '../../../../constants';
import { SQUARE_FT_VALUES, LOT_SIZE_VALUES, YEAR_RANGE, STORIES_VALUES } from './constants';
import { LRToggleButtonGroupOptionStyle, FilterMoreActions } from './style';
import { LRToggleButtonGroup } from '../../../../components/LRToggleButtonGroup';
import LRDropdown from '../../../../components/LRDropdown';
import { LRSwitch } from '../../../../components/LRSwitch';
import { LRRangeDropdown } from '../../../../components/LRRangeDropdown';
import LRButton from '../../../../components/LRButton';
import { LRChip } from '../../../../components/LRChip';
import { TextField } from '../../../../components/TextField';
import { useRFHInput } from '../../../../hooks';
import { LRDropPopover } from '../../../../components/LRDropPopover';
import { BedsContent } from './Beds';
import { BathsContent } from './Baths';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { useMemo } from 'react';
import analytics from 'services/analytics';
import UnspecifiedListingsStoriesCheckbox from 'components/UnspecifiedListingsStoriesCheckbox';
import { useStore } from '../../../../mobx-store';

function formatRangeTitle(min, max) {
    if(!(min || max)) {
        return null;
    }
    if(min && max) {
        return `${min}-${max}`;
    } else if(min) {
        return `${min}+`;
    } else {
        return `0-${max}`;
    }
};

function handleRangeMixPanelEvents(agentId, type, min, max) {
    analytics.eventTrack('robin_searchpage_filter_added', {
        type,
        value: formatRangeTitle(min, max),
        ...(agentId && { distinct_id: agentId }),
    });
}

function handleMixPanelEvents(agentId, type, value, getValues) {
    if(type === 'stories') {
        return analytics.eventTrack('robin_searchpage_filter_added', {
            type,
            value: value !== 'Any' ? value : null,
            ...(agentId && { distinct_id: agentId }),
        });
    }
    if(type === 'keywords') {
        const values = getValues();
        const keywords = values['fields.keywords'];

        // Make sure that mixpanel event is not fired when onChange and onBlur
        // if there is no change just return
        const isEqual =  _.isEqual(value, keywords);
        if(isEqual) return;
        return analytics.eventTrack('robin_searchpage_filter_added', {
            type,
            value: keywords.length > 0 ? keywords.join(',') : null,
            ...(agentId && { distinct_id: agentId }),
        });
    }
    if(type === 'mls_numbers') {
        const values = getValues();
        const mlsNumbers = values['fields.mls_numbers'];

        // Make sure that mixpanel event is not fired when onChange and onBlur
        // if there is no change just return
        const isEqual =  _.isEqual(value, mlsNumbers);
        if(isEqual) return;

        return analytics.eventTrack('robin_searchpage_filter_added', {
            type,
            value: mlsNumbers.length > 0 ? mlsNumbers.join(',') : null,
            ...(agentId && { distinct_id: agentId }),
        });
    }
    analytics.eventTrack('robin_searchpage_filter_added', {
        type,
        value,
        ...(agentId && { distinct_id: agentId }),
    });
}

export function MoreBasicsContent({
    fields,
    trackFilterSet,
    agentId,
}) {
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    Square Feet
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container justify="flex-end">
                <Grid item xs={12}>
                    <LRRangeDropdown
                        minValue={fields.homeSizeMin}
                        maxValue={fields.homeSizeMax}
                        options={SQUARE_FT_VALUES}
                        dropdownMenuProps={{maxWidth: '144px'}}
                        onMinValueChange={(option)=> {
                            fields.handleHomeSizeMinChange(option);
                            trackFilterSet('sqft');
                            handleRangeMixPanelEvents(agentId, 'sqft', option, fields.homeSizeMax);
                        }}
                        onMaxValueChange={(option)=> {
                            fields.handleHomeSizeMaxChange(option);
                            trackFilterSet('sqft');
                            handleRangeMixPanelEvents(agentId, 'sqft', fields.homeSizeMin, option);
                        }}
                        compareAsNumbers={true}
                    ></LRRangeDropdown>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    Lot size
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container justify="flex-end">
                <Grid item xs={12}>
                    <LRRangeDropdown
                        options={LOT_SIZE_VALUES}
                        minValue={fields.lotSizeMin}
                        maxValue={fields.lotSizeMax}
                        dropdownMenuProps={{maxWidth: '144px'}}
                        onMinValueChange={(option)=> {
                            fields.handleLotSizeMinChange(option);
                            trackFilterSet('lot');
                            handleRangeMixPanelEvents(agentId, 'lot', option, fields.lotSizeMax);
                        }}
                        onMaxValueChange={(option)=> {
                            fields.handleLotSizeMaxChange(option);
                            trackFilterSet('lot');
                            handleRangeMixPanelEvents(agentId, 'lot', fields.lotSizeMin, option);
                        }}
                        compareAsNumbers={true}
                    ></LRRangeDropdown>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    Stories
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container justify="flex-end">
                <Grid item xs={12}>
                    <LRToggleButtonGroup
                        value={fields.stories}
                        onChange={(option)=> {
                            fields.handleStories(option);
                            if(option === null) { //if label Any
                                //reset fields.includeListingsWithUnspecifiedData
                                fields.handleIncludeListingsWithUnspecifiedData(false);
                            }
                            trackFilterSet('stories');
                            handleMixPanelEvents(agentId, 'stories', STORIES_VALUES.find((el)=> el.value === option)?.label);
                        }}
                        options={STORIES_VALUES.map((el)=> ({ ...el, ...{ style: LRToggleButtonGroupOptionStyle} }))}
                        fullWidth
                    ></LRToggleButtonGroup>
                </Grid>
            </Grid>
            {fields.stories !== null &&
                <Grid item xs={12}>
                    <UnspecifiedListingsStoriesCheckbox
                        value={fields.includeListingsWithUnspecifiedData}
                        onChange={(checked)=> {
                            handleMixPanelEvents(agentId, 'include_listings_with_unspecified_data', checked);
                            fields.handleIncludeListingsWithUnspecifiedData(checked);
                        }}
                    />
                </Grid>
            }
        </Grid>
    );
}

export function EverythingElseSection({fields, trackFilterSet, getValues, agentId}) {

    const schoolOptions = [
        { value: null, label: 'No preference' },
        { value: 10, label: '1+' },
        { value: 20, label: '2+' },
        { value: 30, label: '3+' },
        { value: 40, label: '4+' },
        { value: 50, label: '5+' },
        { value: 60, label: '6+' },
        { value: 70, label: '7+' },
        { value: 80, label: '8+' },
        { value: 90, label: '9+' },
        { value: 100, label: '10+' },
    ];

    function onKeywordsChange(event) {
        const value = _.replace(_.trim(event.target.value), ',', '');
        const { keywords, handleKeywordsChange } = fields;
        const hasKeyword = keywords && keywords.filter((k)=> k.toLowerCase() === value.toLowerCase()).length > 0;
        if(!value || hasKeyword) {
            event.target.value = value;
            return;
        }
        handleKeywordsChange([...(keywords || []), value]);
        event.target.value = '';
        analytics.eventTrack('robin_keyword_filter_input', {keyword_input: value});
        trackFilterSet('keywords');
    }

    function handleDeleteKeyword(id) {
        const { keywords, handleKeywordsChange } = fields;
        const _keywords = keywords.filter((k)=> k !== id);
        handleKeywordsChange(_keywords);
        trackFilterSet('keywords');
    }

    function onMlsNumbersChange(event) {
        const value = _.replace(_.trim(event.target.value), ',', '');
        const { mlsNumbers, handleMlsNumbersChange } = fields;
        const hasMls = mlsNumbers && mlsNumbers.filter((k)=> k.toLowerCase() === value.toLowerCase()).length > 0;
        if(!value || hasMls) {
            event.target.value = value;
            return;
        }
        handleMlsNumbersChange([...(mlsNumbers || []), value]);
        event.target.value = '';
        analytics.eventTrack('robin_mls_number_filter_added', {
            mls_number: value
        });
        trackFilterSet('mls_numbers');
    }

    function handleDeleteMlsNumber(id) {
        const { mlsNumbers, handleMlsNumbersChange } = fields;
        const _mls = mlsNumbers.filter((k)=> k !== id);
        handleMlsNumbersChange(_mls);
        trackFilterSet('mls_numbers');
    }

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    Year built
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container justify="flex-end">
                <Grid item xs={12}>
                    <LRRangeDropdown
                        options={YEAR_RANGE}
                        minValue={fields.yearBuiltMin}
                        maxValue={fields.yearBuiltMax}
                        dropdownMenuProps={{maxWidth: '144px'}}
                        onMinValueChange={(option)=> {
                            fields.handleYearBuiltMinChange(option);
                            trackFilterSet('year');
                            handleRangeMixPanelEvents(agentId, 'year', option, fields.yearBuiltMax);
                        }}
                        onMaxValueChange={(option)=> {
                            fields.handleYearBuiltMaxChange(option);
                            trackFilterSet('year');
                            handleRangeMixPanelEvents(agentId, 'year', fields.yearBuiltMin, option);
                        }}
                        compareAsNumbers={true}
                    ></LRRangeDropdown>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    Days on market
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container justify="flex-end">
                <Grid item xs={12}>
                    <LRDropdown
                        name="fields.days_on_market"
                        selectedOption={_.find(DAYS_ON_MARKET, ['value', fields.daysOnMarket]) || DAYS_ON_MARKET[0]}
                        onOptionSelect={(option)=> {
                            fields.handleDaysOnMarket(option.value);
                            trackFilterSet('days_on_market');
                            handleMixPanelEvents(agentId, 'days_on_market', option.value);
                        }}
                        renderDisplayOption={({ label })=> label}
                        renderOption={({ label })=> label}
                        maxOptionListHeight="200px"
                        options={DAYS_ON_MARKET}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Box fontSize="20px" height={['auto', '210px']}>
                    Must-haves
                </Box>
            </Grid>

            <Grid item xs={12} sm={8} container justify="space-between">
                <Grid item container xs={12} sm={12} alignItems="center">
                    <Box width={1}>
                        <Divider />
                    </Box>
                    <Grid item xs={10} sm={10}>
                        <Box py="18px">Pool</Box>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Box>
                            <LRSwitch
                                name="fields.tags.with_pool"
                                label="Pool"
                                checked={!!fields.withPool}
                                onChange={(event)=> {
                                    fields.handleWithPool(event.target.checked);
                                    trackFilterSet('pool');
                                    handleMixPanelEvents(agentId, 'pool', event.target.checked);
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm={12} alignItems="center">
                    <Box width={1}>
                        <Divider />
                    </Box>
                    <Grid item xs={10} sm={10}>
                        <Box py="18px">View</Box>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Box>
                            <LRSwitch
                                name="fields.tags.with_views"
                                label="View"
                                checked={!!fields.withViews}
                                onChange={(event)=> {
                                    fields.handleWithViews(event.target.checked);
                                    trackFilterSet('view');
                                    handleMixPanelEvents(agentId, 'view', event.target.checked);
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} alignItems="center">
                    <Box width={1}>
                        <Divider />
                    </Box>
                    <Grid item xs={10} sm={10}>
                        <Box py="18px">Virtual Tour</Box>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Box>
                            <LRSwitch
                                name="fields.tags.with_virtual_tours"
                                label="Virtual Tour"
                                checked={!!fields.withVirtualTours}
                                onChange={(event)=> {
                                    fields.handleWithVirtualTours(event.target.checked);
                                    trackFilterSet('virtual_tour');
                                    handleMixPanelEvents(agentId, 'virtual_tour', event.target.checked);
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>


                <Grid item container xs={12} sm={12} alignItems="center">
                    <Box width={1}>
                        <Divider />
                    </Box>
                    <Grid item xs={10} sm={10}>
                        <Box py="18px">Open House</Box>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Box>
                            <LRSwitch
                                name="fields.tags.with_open_houses"
                                label="Open House"
                                checked={!!fields.withOpenHouses}
                                onChange={(event)=> {
                                    fields.handleWithOpenHouses(event.target.checked);
                                    trackFilterSet('open_house');
                                    handleMixPanelEvents(agentId, 'open_house', event.target.checked);
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    Great schools rating
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container justify="flex-end">
                <Grid item xs={12}>
                    <LRDropdown
                        name="fields.school_score_min"
                        selectedOption={_.find(schoolOptions, ['value', fields.schoolScoreMin])}
                        onOptionSelect={(option)=> {
                            fields.setSchoolScoreMin(option.value);
                            trackFilterSet('schools');
                            handleMixPanelEvents(agentId, 'schools', option.label !== 'No preference' ? option.label : null);
                        }}
                        renderDisplayOption={({ label })=> label}
                        renderOption={({ label })=> label}
                        maxOptionListHeight="200px"
                        placement="top"
                        options={schoolOptions}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    Keywords
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container>
                <Grid item xs={12}>
                    <TextField
                        name="fields.keywords"
                        type="text"
                        variant="outlined"
                        placeholder="E.g. Office, fireplace, pool"
                        fullWidth={true}
                        inputProps={{
                            fontSize: 16
                        }}
                        labelProps={{
                            fontWeight: 'normal',
                            fontSize: 16,
                        }}
                        onKeyPress={(event)=> {
                            if(event.key === 'Enter' || event.charCode === 13) {
                                onKeywordsChange(event);
                                handleMixPanelEvents(agentId, 'keywords', fields.keywords, getValues);
                            }
                        }}
                        onChange={(event)=> {
                            if(_.includes([',', ' '], _.last(event.target.value))) {
                                onKeywordsChange(event);
                                handleMixPanelEvents(agentId, 'keywords', fields.keywords, getValues);
                            }
                        }}
                        onBlur={(event)=> {
                            onKeywordsChange(event);
                            handleMixPanelEvents(agentId, 'keywords', fields.keywords, getValues);
                        }}
                    />
                </Grid>
                <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {fields.keywords &&
                        fields.keywords.map((keyword)=> (
                            <Box key={keyword} m="12px 12px 0 0">
                                <LRChip
                                    label={keyword}
                                    onDelete={async (event)=> {
                                        handleDeleteKeyword(event);
                                        handleMixPanelEvents(agentId, 'keywords', fields.keywords, getValues);
                                    }}
                                />
                            </Box>
                        ))
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Box fontSize="20px">
                    MLS #
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} container>
                <Grid item xs={12}>
                    <TextField
                        name="fields.mls_numbers"
                        type="text"
                        variant="outlined"
                        placeholder=""
                        fullWidth={true}
                        inputProps={{
                            fontSize: 16
                        }}
                        labelProps={{
                            fontWeight: 'normal',
                            fontSize: 16,
                        }}
                        onKeyPress={(event)=> {
                            if(event.key === 'Enter' || event.charCode === 13) {
                                onMlsNumbersChange(event);
                                handleMixPanelEvents(agentId, 'mls_numbers', fields.mls_numbers, getValues);
                            }
                        }}
                        onChange={(event)=> {
                            if(_.includes([',', ' '], _.last(event.target.value))) {
                                onMlsNumbersChange(event);
                                handleMixPanelEvents(agentId, 'mls_numbers', fields.mls_numbers, getValues);
                            }
                        }}
                        onBlur={(event)=> {
                            onMlsNumbersChange(event);
                            handleMixPanelEvents(agentId, 'mls_numbers', fields.mls_numbers, getValues);
                        }}
                    />
                </Grid>
                <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {fields.mlsNumbers &&
                        fields.mlsNumbers.map((mlsNumber)=> (
                            <Box key={mlsNumber} m="12px 12px 0 0">
                                <LRChip
                                    label={mlsNumber}
                                    onDelete={(events)=> {
                                        handleDeleteMlsNumber(events);
                                        handleMixPanelEvents(agentId, 'mls_numbers', null, getValues);
                                    }}
                                />
                            </Box>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export function MoreContent({
    handleResetFilters,
    onClose,
    fields,
    trackFilterSet,
    getValues,
    agentId,
}) {
    const theme = useTheme();

    return (
        <div style={{maxWidth: '564px'}}>
            <div style={{maxHeight: 'calc(90vh - 120px)', overflow:'hidden', overflowY: 'auto', padding: '24px'}}>
                <Hidden only={['lg', 'xl']}>
                    <Box fontSize="20px" mb={2}>Beds</Box>
                    <Box mb={3}>
                        <BedsContent
                            value={fields.beds}
                            handleChange={fields.handleBeds}
                            trackFilterSet={trackFilterSet}
                            agentId={agentId}
                            handleMixpanelEvent={handleMixPanelEvents}
                        />
                    </Box>
                    <Box fontSize="20px" mb={2}>Baths</Box>
                    <Box mb={3}>
                        <BathsContent
                            bathrooms={fields.bathrooms}
                            handleBathrooms={fields.handleBathrooms}
                            trackFilterSet={trackFilterSet}
                            agentId={agentId}
                            handleMixpanelEvent={handleMixPanelEvents}
                        />
                    </Box>
                </Hidden>
                <MoreBasicsContent fields={fields} trackFilterSet={trackFilterSet} />
                <div style={{margin: `${theme.spacing(5)}px 0`}}>
                    <Divider/>
                </div>
                <Box pb={4} >
                    <EverythingElseSection
                        fields={fields}
                        trackFilterSet={trackFilterSet}
                        getValues={getValues}
                        agentId={agentId}
                    />
                </Box>
            </div>
            <FilterMoreActions justifyContent={'flex-end'}>
                <LRButton
                    type="button"
                    variant="outlined"
                    height={36}
                    style={{ marginRight: theme.spacing(1)}}
                    onClick={()=> handleResetFilters()}
                >
                    Reset
                </LRButton>
                <LRButton type="button" variant="contained" color="primary" height={36} onClick={()=> onClose()}> Done</LRButton>
            </FilterMoreActions>
        </div>
    );
}

function MoreTitle({
    totalSelectedOptions = 0,
}) {
    const theme = useTheme();

    return (
        <Grid container>
            {Boolean(totalSelectedOptions) && (
                <Grid item style={{ marginRight: theme.spacing(.5), }}>
                    <Grid container justify="center" alignItems="center">
                        <Grid
                            item
                            style={{
                                color: '#fff',
                                backgroundColor: '#000',
                                borderRadius: '50%',
                                width: 21,
                                height: 20,
                                textAlign: 'center',
                                fontSize: 12,
                            }}
                        >
                            <div style={{ position: 'relative', top: 1, }}>
                                {totalSelectedOptions}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item>More filters</Grid>
        </Grid>
    );
}

export function More({
    listingsCount,
    beds,
    handleBeds,
    bathrooms,
    handleBathrooms,
    trackFilterSet,
    trackFilterClick,
    onClick,
    areas,
    agentId,
}) {
    const { isIPadProDown } = useDeviceBreakpoints();
    const { ListpackStore } = useStore();
    const formMethods = useFormContext();
    const { getValues } = formMethods;
    const { value: withSingleLevelHomes, handleChange: handleWithSingleLevelHomes } = useRFHInput('fields.tags.single_level_homes', formMethods);
    const { value: withAttachedHomes, handleChange: handleWithAttachedHomes } = useRFHInput('fields.tags.attached_homes', formMethods);
    const { value: withDetachedHomes, handleChange: handleWithDetachedHomes } = useRFHInput('fields.tags.detached_homes', formMethods);
    const { value: withGreatSchools, handleChange: handleWithGreatSchools } = useRFHInput('fields.tags.with_great_schools', formMethods);
    const { value: withHeating, handleChange: handleWithHeating } = useRFHInput('fields.tags.with_heating', formMethods);
    const { value: withCooling, handleChange: handleWithCooling } = useRFHInput('fields.tags.with_cooling', formMethods);
    const { value: newListings, handleChange: handleNewListings } = useRFHInput('fields.tags.new_listings', formMethods);
    const { value: staleListings, handleChange: setStaleListings } = useRFHInput('fields.tags.stale_listings', formMethods);
    const { value: withAttachedGarage, handleChange: handleWithAttachedGarage } = useRFHInput('fields.tags.with_attached_garage', formMethods);
    const { value: withUpgradedKitchens, handleChange: handleWithUpgradedKitchens } = useRFHInput('fields.tags.with_upgraded_kitchens', formMethods);
    const { value: youngerHomes, handleChange: handleYoungerHomes } = useRFHInput('fields.tags.younger_homes', formMethods);
    const { value: withOpenHouses, handleChange: handleWithOpenHouses } = useRFHInput('fields.tags.with_open_houses', formMethods);
    const { value: withVirtualTours, handleChange: handleWithVirtualTours } = useRFHInput('fields.tags.with_virtual_tours', formMethods);
    const { value: stories, handleChange: handleStories } = useRFHInput('fields.stories', formMethods);
    const { value: lotSizeMin, handleChange: handleLotSizeMinChange } = useRFHInput('fields.lotsize_min', formMethods);
    const { value: lotSizeMax, handleChange: handleLotSizeMaxChange } = useRFHInput('fields.lotsize_max', formMethods);
    const { value: homeSizeMin, handleChange: handleHomeSizeMinChange } = useRFHInput('fields.homesize_min', formMethods);
    const { value: homeSizeMax, handleChange: handleHomeSizeMaxChange } = useRFHInput('fields.homesize_max', formMethods);
    const { value: yearBuiltMin, handleChange: handleYearBuiltMinChange } = useRFHInput('fields.year_built_min', formMethods);
    const { value: yearBuiltMax, handleChange: handleYearBuiltMaxChange } = useRFHInput('fields.year_built_max', formMethods);
    const { value: daysOnMarket, handleChange: handleDaysOnMarket } = useRFHInput('fields.days_on_market', formMethods);
    const { value: schoolScoreMin, handleChange: setSchoolScoreMin } = useRFHInput( 'fields.school_score_min', formMethods);
    const { value: withPool, handleChange: handleWithPool } = useRFHInput( 'fields.tags.with_pool', formMethods);
    const { value: withViews, handleChange: handleWithViews } = useRFHInput( 'fields.tags.with_views', formMethods);
    const { value: keywords, handleChange: handleKeywordsChange } = useRFHInput( 'fields.keywords', formMethods);
    const { value: mlsNumbers, handleChange: handleMlsNumbersChange } = useRFHInput( 'fields.mls_numbers', formMethods);
    const { value: includeListingsWithUnspecifiedData, handleChange: handleIncludeListingsWithUnspecifiedData } = useRFHInput('fields.include_listings_with_unspecified_data', formMethods);

    const totalSelectedOptions = useMemo(()=> {
        let totalSelected = 0;

        if(stories) totalSelected++;
        if(lotSizeMin) totalSelected++;
        if(lotSizeMax) totalSelected++;
        if(homeSizeMin) totalSelected++;
        if(homeSizeMax) totalSelected++;
        if(yearBuiltMin) totalSelected++;
        if(yearBuiltMax) totalSelected++;
        if(daysOnMarket) totalSelected++;
        if(schoolScoreMin) totalSelected++;
        if(withPool) totalSelected++;
        if(withViews) totalSelected++;
        if(withOpenHouses) totalSelected++;
        if(withVirtualTours) totalSelected++;
        if(keywords?.length) totalSelected += keywords.length;
        if(mlsNumbers?.length) totalSelected += mlsNumbers.length;
        if(beds && isIPadProDown) totalSelected++;
        if(bathrooms && isIPadProDown) totalSelected++;

        return totalSelected;
    }, [bathrooms, beds, homeSizeMax, homeSizeMin, isIPadProDown, keywords, mlsNumbers, lotSizeMax, lotSizeMin, schoolScoreMin, stories, withPool, withViews, withOpenHouses, withVirtualTours, yearBuiltMax, daysOnMarket, yearBuiltMin]);
    const fields = {
        stories,
        handleStories,
        lotSizeMin,
        handleLotSizeMinChange,
        lotSizeMax,
        handleLotSizeMaxChange,
        homeSizeMin,
        handleHomeSizeMinChange,
        homeSizeMax,
        handleHomeSizeMaxChange,
        yearBuiltMin,
        handleYearBuiltMinChange,
        yearBuiltMax,
        handleYearBuiltMaxChange,
        daysOnMarket,
        handleDaysOnMarket,
        schoolScoreMin,
        setSchoolScoreMin,
        withPool,
        handleWithPool,
        withViews,
        handleWithViews,


        withVirtualTours,
        handleWithVirtualTours,
        withOpenHouses,
        handleWithOpenHouses,


        keywords,
        handleKeywordsChange,

        mlsNumbers,
        handleMlsNumbersChange,

        beds,
        handleBeds,
        bathrooms,
        handleBathrooms,

        includeListingsWithUnspecifiedData,
        handleIncludeListingsWithUnspecifiedData
    };


    function handleResetFilters(areas) {
        formMethods.reset({
            areaIds: areas,
            fields: {
                new_listings: false,
                stale_listings: false,
                price_min: null,
                price_max: null,
                bedrooms: null,
                bathrooms: null,
                homesize_min: null,
                homesize_max: null,
                stories: null,
                lotsize_min: null,
                lotsize_max: null,
                year_built_min: null,
                year_built_max: null,
                days_on_market: null,
                school_score_min: null,
                hometype: {
                    single_family: false,
                    multi_family: false,
                    condo_townhome: false,
                    mobile_manufactured: false,
                    land: false,
                },
                tags: {
                    with_great_schools: false,
                    with_open_houses: false,
                    with_virtual_tours: false,
                    with_views: false,
                    with_upgraded_kitchens: false,
                    with_pool: false,
                },
                keywords: [],
                mls_numbers: [],
                include_listings_with_unspecified_data: null
            },
            meta: {
                agent_note: null,
            },
        });
    }

    return (
        <LRDropPopover
            title={<MoreTitle totalSelectedOptions={totalSelectedOptions} />}
            onOpen={()=> {
                trackFilterClick('more_filters');
            }}
            onClick={onClick}
        >
            <MoreContent
                handleResetFilters={()=> handleResetFilters(areas)}
                listingsCount={listingsCount}
                trackFilterSet={trackFilterSet}
                fields={fields}
                getValues={getValues}
                agentId={agentId}
            />
        </LRDropPopover>
    );
}
