// eslint-disable-next-line
import Logger from 'js-logger';
import { benoitApi } from '../../apis';
import { observable, decorate, action, computed, flow } from 'mobx';
import simpleCypher from '../../services/simple-cypher';
import { thumborize, dethumborize } from '../../services/thumborizer';
import addressSlugger from '../../services/address-slugger';
import crypto from 'crypto';
import lodashGet from 'lodash/get';
import lodashPick from 'lodash/pick';
import lodashFind from 'lodash/find';
import Address from 'lana';

const logger = Logger.get('PinspirationStore');

function getAddressParts(addr) {
    try {
        const address = new Address(addr);
        const parts = address.getParts();
        parts.formatted_address = address.toString();
        parts.street = Object.values(lodashPick(parts, ['number', 'prefix', 'street', 'type', 'suffix']))
            .join(' ');

        return parts;
    } catch (err) {
        Logger.warn('Error getting address component', err);
        return addr;
    }
}

class PinspirationStore {
    /**
     * collaborationsCache: {Map} An observable map with keys as BoardCollaboration doc ids
     *  and values as the BoardCollaboration doc. Used to cache pin/unpin status for the session
     */
    collaborationsCache = new Map();

    /**
     * boardPinRegistry: {Map} An observable map with keys as thumborized `image_url`
     *  and values as an array of corresponding BoardCollaboration docs.
     *  Used to store an ordered list for display on the Pinspiration board.
     */
    boardPinRegistry = new Map();
    filterUserId = '';
    boardLastId = null;
    boardLastCreatedAt = null;
    boardHasMore = false;
    boardLoaded = false;
    boardLoading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    // Computed

    /**
     * Computed: {String} Returns the current userId
     */
    get currentUserId() {
        return this.rootStore.UserStore && this.rootStore.UserStore.user
            ? this.rootStore.UserStore.user.id
            : null;
    }

    /**
     * Computed: {String} Returns the current boardId
     */
    get currentBoardId() {
        const { id: boardId = null } = this.rootStore.boardsStore.activeBoard || {};
        return boardId;
    }

    /**
     * Computed: {Object} Returns an object with keys as the collaborator id/userId
     * and values as the user object of the collaborator
     */
    get currentBoardCollaborators() {
        const { collaborators = [] } = this.rootStore.boardsStore.activeBoard || {};
        return collaborators.reduce((obj, c)=> {
            obj[c.id] = c;
            obj[c.userId] = c;
            return obj;
        }, {});
    }

    /**
     * Computed: {Object} Returns an object with keys as the BoardCollaboration ids
     * and values as true if the current user has pinned it, else false
     */
    get pinnedByCurrentUser() {
        return [...this.collaborationsCache.values()]
            .filter((collaboration)=> collaboration.userId === this.currentUserId)
            .reduce((obj, collaboration)=> {
                const { id } = collaboration;
                obj[id] = true;
                return obj;
            }, {});
    }

    /**
     * Computed: {Object} Returns an object with keys as the image_urls
     * and values as arrays of users (collaborators) that have pinned them
     */
    get pinnedByCollaborators() {
        const boardCollaborators = this.currentBoardCollaborators;
        return [...this.collaborationsCache.values()]
            //.filter((collaboration)=> collaboration.userId !== this.currentUserId)
            .reduce((obj, collaboration)=> {
                const { content: { image_url = null } = {} } = collaboration || {};
                if(image_url) {
                    const user = collaboration.user || boardCollaborators[collaboration.userId];
                    obj[image_url] = [
                        ...(obj[image_url] || []).filter((u)=> u.id !== user?.id),
                        user,
                    ];
                }
                return obj;
            }, {});
    }

    /**
     * Computed: {Array} Returns a sorted collection of formatted objects
     * to be displayed on the current Pinspiration board.
     * Each object corresponds to a single tile on the board.
     * We're re-sorting the list here to account for
     * any updates to the list in the current session
     */
    get boardPinCollection() {
        return [...this.boardPinRegistry.values()]
            .filter((docs)=> docs && docs.length > 0)
            .map((docs)=> this.formatBoardPinItem(docs))
            .sort((a, b)=> (
                // sort items in descending order
                // by lastCreatedAt (since the same
                // image could be pinned by multiple users)
                (a.lastCreatedAt < b.lastCreatedAt)
                    ? 1
                    : (a.lastCreatedAt > b.lastCreatedAt)
                        ? -1
                        : 0
            ));
    }

    // Functions

    /**
     * Action: Handles adding a new collaboration to the
     * boardPinRegistry observable map, eliminating any dupes
     *
     * @function addToPinRegistry
     * @param {String} key - The thumborized image url from boardCollaboration.content.image_url
     * @param {Object} item - The BoardCollaboration doc to add to the registry
     */
    addToPinRegistry(key, item) {
        if(key) {
            const currentVal = this.boardPinRegistry.get(key);
            const newVal = (currentVal && currentVal.length)
                ? [...currentVal.filter((p)=> p.id !== item.id), item]
                : [item];
            this.boardPinRegistry.set(key, newVal);
        }
    }

    /**
     * Action: Sets the observable filterUserId value
     *
     * @function setBoardUserFilter
     * @param {String} filterId - The user id to filter for, or '' for View All
     */
    setBoardUserFilter = flow(function*(filterId) {
        const self = this;
        self.clearBoard();
        self.filterUserId = filterId || '';
        yield self.getCollaborations({ boardId: self.currentBoardId });
    });


    /**
     * Action: Fetches a paginated list of type === 'pinspiration_image'
     * BoardCollaboration records, filtered by this.filterUserId
     * to be displayed on the Pinspiration board. Sorted by desc createdAt.
     * Populates the boardPinRegistry on success.
     *
     * @function getCollaborations
     * @param {String} boardId
     * @param {Object} cancelToken - An axios cancel token
     */
    getCollaborations = flow(function*({ boardId, cancelToken }={}) {
        const self = this;
        const limit = 20;
        if(!boardId && self.currentBoardId) boardId = self.currentBoardId;
        let response;
        const params = {
            userId: self.filterUserId || '',
            limit,
        };
        if(self.boardLastId && self.boardLastCreatedAt) {
            params.lastId = self.boardLastId;
            params.lastCreatedAt = self.boardLastCreatedAt;
        }
        const options = { params, cancelToken };
        try {
            self.boardLoading = true;
            response = yield benoitApi.getPinspirationBoardCollaborations(boardId, options);
            if(response && response.length) {
                const lastItem = response[response.length - 1];
                self.boardLastId = lastItem.id;
                self.boardLastCreatedAt = lastItem.createdAt;
                self.boardHasMore = (response.length === limit);
            }
        } catch (err) {
            logger.error(err);
            self.boardLoading = false;
            throw err;
        }

        response.forEach((item)=> {
            const imageUrl = lodashGet(item, 'content.image_url');
            self.addToPinRegistry(imageUrl, item);
        });

        self.boardLoading = false;
        self.boardLoaded = true;
    });

    /**
     * Get all the pinspiration_image type
     * collaborations for a specific listingId
     *
     * @function getCollaborationsByListingId
     * @param {String} boardId - The board Id you are currently on
     * @param {String} [encodedListingId] - The encodedListingId
     * @param {Object} [options]
     * @param {Object} [options.cancelToken] - An axios cancel token https://github.com/axios/axios#cancellation
     */
    getCollaborationsByListingId = flow(function*(boardId, encodedListingId, options = {}) {
        let response;
        //this.collaborationsCache.clear();
        const { id: listingId, source: listingSource } = simpleCypher.decode(encodedListingId) || {};
        const itemId = `${listingSource}/${listingId}`;
        options = {
            params: {
                include_user: true,
            },
            ...options,
        };

        try {
            response = yield benoitApi.getBoardCollaborations(boardId, itemId, options);
        } catch (err) {
            logger.error(err);
            return;
        }

        response.forEach((item)=> {
            return this.collaborationsCache.set(item.id, item);
        });
    });

    /**
     * Adds a new pinspiration_image type collaboration to a board
     *
     * @function pinItem
     * @param {Object} data
     * @param {String} data.boardId
     * @param {String} data.listingId - The simple cypher encoded listingId
     * @param {String} data.src - The src url of the item being pinned
     * @param {String} data.cardId - The BoardCard id if this came from a card
     */
    pinItem = flow(function*(data={}) {
        const self = this;
        const user = this.rootStore.UserStore.user;
        if(!user) throw new Error('User is not logged in');
        const { id: userId } = user;
        const {
            listingId: encodedListingId,
            cardId,
            boardId,
            src,
            address,
        } = data;

        if(!encodedListingId) throw new Error('listingId missing');
        if(!boardId) throw new Error('boardId missing');
        if(!src) throw new Error('src missing');

        const { id: listingId, source: listingSource } = simpleCypher.decode(encodedListingId) || {};
        const thumborized = thumborize(src);
        const content = {
            image_url: thumborized,
            boardCardId: cardId,
            address,
        };
        const payload = {
            id: self.getBoardCollaborationId({ src, boardId, userId }),
            type: 'pinspiration_image',
            itemId: `${listingSource}/${listingId}`,
            model: 'Listing',
            createdById: userId,
            updatedById: userId,
            userId,
            boardId,
            content,
        };
        this.rootStore.UserStore.completePinPhotoOnboarding();
        const response = yield benoitApi.addBoardCollaboration(payload);
        self.collaborationsCache.set(response.id, response);
        self.addToPinRegistry(thumborized, { user, ...response });

        return response;
    });

    /**
     * Removes the pinspiration_image collaboration from a board
     *
     * @function unpinItem
     * @param {Object} data
     * @param {String} data.boardId
     * @param {String} data.src - The src url of the item being unpinned
     */
    unpinItem = flow(function*(data={}) {
        const self = this;
        const userId = this.currentUserId;
        const {
            boardId,
            src,
        } = data;

        if(!userId) throw new Error('User is not logged in');
        if(!boardId) throw new Error('boardId missing');
        if(!src) throw new Error('src missing');

        const collaborationId = self.getBoardCollaborationId({ src, boardId, userId });
        const options = { soft_delete: false };
        const response = yield benoitApi.deleteBoardCollaboration(collaborationId, options);
        const thumborized = thumborize(src);
        const oldPinRegistryValue = this.boardPinRegistry.get(thumborized);
        const newPinRegistryValue = (oldPinRegistryValue || [])
            .filter((item)=> (item.id !== collaborationId));

        self.collaborationsCache.delete(collaborationId);
        if(oldPinRegistryValue) {
            if(newPinRegistryValue.length) {
                self.boardPinRegistry.set(thumborized, newPinRegistryValue);
            } else {
                self.boardPinRegistry.delete(thumborized);
            }
        }

        return response;
    });

    /**
    * Expects an array of BoardCollaboration docs
    * for the same image_url - one per user.
    * Grabs common values from the first item and
    * returns an object formatted for displaying
    * a single tile on the Pinspiration board
    *
    * @function formatBoardPinItem
    * @param {Array} docs - a collection of BoardCollaboration docs for the same image_url
    **/
    formatBoardPinItem(docs) {
        const [firstDoc] = docs;
        const userId = this.currentUserId;
        const [ listingSource, listingId ] = (lodashGet(firstDoc, 'itemId') || '').split('/');
        const address = lodashGet(firstDoc, 'content.address');
        const addressParts = getAddressParts(address);
        const addressSlug = addressSlugger(address);
        return {
            photo: lodashGet(firstDoc, 'content.image_url'),
            title: lodashGet(firstDoc, 'board_card.name') || lodashGet(addressParts, 'street'),
            subtitle: lodashGet(addressParts, 'city'),
            addressSlug: addressSlug,
            cardId: lodashGet(firstDoc, 'content.boardCardId'),
            encodedListingId: listingId ? simpleCypher.encode(listingSource, listingId) : null,
            users: docs.map((doc)=> doc.user),
            currentUserPin: lodashFind(docs, (doc)=> doc.userId === userId) || null,
            lastCreatedAt: lodashGet(firstDoc, 'createdAt'),
        };
    }

    /**
    * Returns an MD5 hash of <boardId>_<userId>_<imageUrl>
    * to be used as the id for the BoardCollaboration doc
    *
    * @function getBoardCollaborationId
    * @param {String} src - The image_url associated with the pin action
    * @param {String} boardId
    * @param {String} userId
    **/
    getBoardCollaborationId({ src, boardId, userId }) {
        if(!userId) {
            userId = this.currentUserId;
        }
        const idString = `${boardId}_${userId}_${dethumborize(src)}`;
        return crypto.createHash('md5')
            .update(idString)
            .digest('hex');
    }

    /**
    * Resets all the board observables to clear
    * the current Pinspiration board session
    *
    * @function clearBoard
    **/
    clearBoard() {
        this.boardPinRegistry.clear();
        this.boardLastId = null;
        this.boardLastCreatedAt = null;
        this.boardHasMore = false;
        this.boardLoaded = false;
    }
}

decorate(PinspirationStore, {
    // Variables
    collaborationsCache: observable,
    boardPinRegistry: observable,
    filterUserId: observable,
    boardHasMore: observable,
    boardLoading: observable,

    // Computed
    pinnedByCurrentUser: computed,
    currentUserId: computed,

    // Public Functions
    getCollaborations: action,
    getCollaborationsByListingId: action,
    setBoardUserFilter: action,
    addToPinRegistry: action,
});

export default PinspirationStore;
