import _ from 'lodash';
import * as CONSTANTS from '../../constants';

const LRColors = Object.keys(CONSTANTS)
    .reduce(function(result, key) {
        if(~key.indexOf('COLOR_')) {
            const name = _.snakeCase(key.split('COLOR_')[1]);
            result[name] = CONSTANTS[key];
        }

        return result;
    }, {});

export default LRColors;
