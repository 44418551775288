import { useEffect, useState } from 'react';

export function useIsElementVisible(element, container) {
    const [observer, setObserver] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if(container) {
            let options = {
                root: container,
                rootMargin: '0px',
                threshold: 0,
            };

            let observer = new IntersectionObserver(function intersectionHandler(entries) {
                entries.forEach((entry) => {
                    setIsVisible(entry.isIntersecting);
                });
            }, options);
            setObserver(observer);
        }
        return () => {
            setObserver((currentObserver) => {
                if(currentObserver) {
                    currentObserver.disconnect();
                }
                return null;
            });
            setIsVisible(false);
        };
    }, [container]);

    useEffect(() => {
        if(!observer || !element) {
            return;
        }
        observer.observe(element);
    }, [observer, element]);

    return isVisible;
}
