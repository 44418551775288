import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import TaskLayout from './task-layout';

const SimpleTask = observer(function SimpleTask(props) {
    const { task } = props;

    return (
        <TaskLayout {...props}>
            <div>
                <Typography variant="body2">
                    {task.content.text}
                </Typography>
            </div>
        </TaskLayout>
    );
});

export default SimpleTask;
