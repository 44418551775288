import React from 'react';
import { FormControlLabel, Checkbox, Typography, useTheme, Hidden, makeStyles } from '@material-ui/core';
import moment from 'moment';
import LRButton from '../../../../components/LRButton';
import { useUpdateTask, useMarkCompleted } from '../hooks';
import { useTaskManagementStateContext } from '../context';
import { Link } from 'react-router-dom';
import analytics from 'services/analytics';
import { useActiveBoard } from 'mobx-store';

const useFormControlLabelClasses = makeStyles({
    root: {
        alignItems: 'flex-start',
    },
    label: {
        flex: 1,
    },
});

export default function TaskLayout({ task, important, viewTo, children }) {
    const { filter } = useTaskManagementStateContext();
    const { mutate: updateTask } = useUpdateTask(filter.toLowerCase());
    const activeBoard = useActiveBoard();
    const { mutate: markCompleted } = useMarkCompleted(filter);

    const theme = useTheme();
    const checked = !!task?.completedAt;
    const classes = useFormControlLabelClasses();

    function handleOnCheck(checked) {
        if(checked) {
            analytics.eventTrack('robin_overview_todo_task_marked_as_complete', {
                task_id: task.id,
            });
        }
    
        updateTask({
            task,
            payload: {
                completedAt: checked ? new Date().toISOString() : null,
            }
        });

    }

    function hadleViewClick() {
        analytics.eventTrack('robin_overview_todo_task_viewed', {
            task_id: task.id,
        });
    }

    return (
        <div
            style={{
                position: 'relative',
                overflow: 'hidden',
                padding: theme.spacing(2),
                paddingRight: theme.spacing(1),
                borderRadius: '4px',
                border: `1px solid ${theme.palette.lr_colors.border_grey}`,
            }}
        >
            {important && (
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        height: '100%',
                        width: '4px',
                        backgroundColor: theme.palette.lr_colors.brick,
                    }}
                />
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <FormControlLabel
                    classes={classes}
                    control={
                        <Checkbox
                            color="default"
                            style={{
                                paddingTop: '4px',
                                paddingRight: '8px',
                                paddingBottom: 0,
                            }}
                            checked={checked}
                            onChange={(event)=> handleOnCheck(event.target.checked)}
                        />
                    }
                    label={
                        <div>
                            <div
                                style={{
                                    textDecoration: checked ? 'line-through' : '',
                                }}
                            >
                                {children}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: theme.palette.lr_colors.steak_sauce,
                                    }}
                                >
                                    {moment(task.createdAt)
                                        .fromNow()} 
                                </Typography>
                                {viewTo && (
                                    <Hidden mdUp>
                                        <LRButton
                                            component={Link}
                                            to={viewTo}
                                            variant="text"
                                            style={{
                                                textDecoration: 'underline',
                                            }}
                                            onClick={hadleViewClick}
                                        >
                                            View
                                        </LRButton>
                                    </Hidden>
                                )}
                            </div>
                        </div>
                    }
                    style={{
                        flex: 1,
                    }}
                />
                {viewTo && (
                    <Hidden smDown>
                        <LRButton
                            component={Link}
                            to={viewTo}
                            variant="text"
                            style={{
                                textDecoration: 'underline',
                            }}
                            onClick={hadleViewClick}
                        >
                            View
                        </LRButton>
                    </Hidden>
                )}
            </div>
        </div>
    );
}
