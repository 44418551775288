import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useStore, useActiveBoard } from '../../../../mobx-store';
import { useResponsiveConfig } from '../../../../hooks';
import LRLink from '../../../../components/LRLink';
import ListingImage from '../../../../components/ListingImage';
import { COLOR_MINTY_FRESH } from '../../../../constants';
import { useScheduleOpenHouse, useRemoveScheduledOpenHouse } from 'hooks/data/boards/open-house/useGetBoardOpenHouses';
import analytics from 'services/analytics';

export const OpenHouseDay = observer(({ date, openHouses })=> {
    const { OpenHouseCalendarStore, GlobalSnackbarStore } = useStore();
    const momentDate = moment(date);
    const activeBoard = useActiveBoard();
    const theme = useTheme();
    const currentFilter = OpenHouseCalendarStore.openHouseFilter;
    const laneFilter = OpenHouseCalendarStore.listingsLaneFilter;

    const displayDate = useResponsiveConfig({
        xs: momentDate.format('ddd, MMM D'),
        md: momentDate.format('dddd, MMMM D'),
    });

    const handleOnMouseEnter = (listingId, date)=> {    
        OpenHouseCalendarStore.highlightedOpenHouse = { listingId, date };
    };

    const handleOnMouseLeave = ()=> {
        OpenHouseCalendarStore.highlightedOpenHouse = null;
    };

    // const [ scheduleOpenHouse ] = useScheduleOpenHouse();
    const { mutateAsync: scheduleOpenHouse } = useScheduleOpenHouse();
    const { mutateAsync: removeScheduledOpenHouse } = useRemoveScheduledOpenHouse();
    // const [ removeScheduledOpenHouse ] = useRemoveScheduledOpenHouse();

    function handleAddOpenHouse(openHouse) {
        
        const { openHouseInfo, cardId, card, listing } = openHouse;
        const startIso = openHouseInfo?.start_time_iso || openHouseInfo?.date_iso;
        if(startIso) {
            const date_time_formatted = moment(startIso)
                .tz(moment.tz.guess())
                .format('MMMM D, YYYY [at] [start_time] z')
                .replace('start_time', openHouseInfo?.start_time);
            const data = {
                model: 'BoardCard',
                itemId: cardId,
                boardId: activeBoard.id,
                content: {
                    ...openHouseInfo,
                    date_time_formatted,
                    name: card?.name || listing?.address,
                    // cta,
                    listing_address: listing?.address,
                },
                type: 'schedule_open_house',
            };
            scheduleOpenHouse({ collaboration: data, filter: currentFilter, laneId: laneFilter });
            GlobalSnackbarStore.show(`Listing added to calendar`);
            
            analytics.eventTrack('robin_home_tours_open_house_scheduled', {
                Source: 'home_tour_map',
                card_id: cardId
            });
        }
    }

    function handleRemoveOpenHouse(openHouse) {
        let { openHouseInfo, cardId, listing, boardCollaboration } = openHouse; 
        removeScheduledOpenHouse({ collaboration: boardCollaboration, filter: currentFilter, laneId: laneFilter });
        GlobalSnackbarStore.show(`Listing removed to calendar`);

        analytics.eventTrack('robin_home_tours_open_house_unscheduled', {
            Source: 'home_tour_map',
            card_id: cardId
        });
    } 
    
    return (
        <>
            <div>
                <div 
                    style={{
                        fontWeight: 'bold',
                        marginBottom: `${theme.spacing(2)}px`
                    }}
                >
                    <Typography variant=""h6>{displayDate}</Typography>
                </div>
                <Grid container spacing={2}>
                    {openHouses.map((openHouse, i)=> {
                        const { openHouseInfo, listing, isScheduled, isHistory, cardId } = openHouse;
 
                        const momentStartDate = moment(openHouseInfo.start_time_iso);
                        const momentEndDate = moment(openHouseInfo.end_time_iso);
                        const headshot =
                            listing &&
                            listing.photos &&
                            listing.photos.length
                                ? listing.photos[0]
                                : null;
                        return (
                            <Grid
                                key={`listing.id - ${i}`}
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                onMouseEnter={()=> handleOnMouseEnter(listing.id, openHouseInfo.date)}
                                onMouseLeave={()=> handleOnMouseLeave()}
                            >
                                <Paper component={Box} overflow="hidden" position="relative">
                                    <Box
                                        component={LRLink}
                                        unstyled
                                        to={`calendar/cards/${cardId}`}
                                        color={isScheduled ? 'white' : null}
                                        bgcolor={isScheduled ? COLOR_MINTY_FRESH : null}
                                        display="flex"
                                        height="88px"
                                        alignItems="center"
                                    >
                                        <div style={{
                                            height: '100%'
                                        }}>
                                            <ListingImage width="110px" height="100%" image={headshot} />
                                        </div>
                                        <div 
                                            style={{ 
                                                paddingLeft: `${theme.spacing(2)}px`,
                                                paddingRight: `${theme.spacing(2)}px`
                                            }}
                                        >
                                            <div>
                                                <Typography variant="h6">
                                                    { moment(openHouseInfo.start_time,'h:mm a').isValid() ? moment(openHouseInfo.start_time,'h:mm a').format('ha') : 'N/A' }
                                                    {' '}-{' '}
                                                    { moment(openHouseInfo.end_time,'h:mm a').isValid() ? moment(openHouseInfo.end_time,'h:mm a').format('ha') : 'N/A' }
                                                </Typography>
                                                
                                            </div>
                                            <div
                                                style={{
                                                    color: isScheduled ? 'white' : theme.palette.text.secondary
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    {listing.address}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Box>
                                    {
                                        !isHistory && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: 10,
                                                    top: 10,
                                                    fontWeight: 'bold',
                                                    fontSize: '10px'
                                                }}
                                            >
                                                {isScheduled ? (
                                                    <Tooltip title="Remove from calendar">
                                                        <LRLink
                                                            color="white"
                                                            onClick={()=> {
                                                                // archive the boardCollaboration
                                                                handleRemoveOpenHouse(openHouse);
                                                            }}
                                                        >
                                                            - REMOVE
                                                        </LRLink>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Add to calendar">
                                                        <LRLink
                                                            onClick={()=> {
                                                                // archive the boardCollaboration
                                                                handleAddOpenHouse(openHouse);
                                                            }}
                                                        >
                                                            + ADD
                                                        </LRLink>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        )
                                    }
                                    
                                    {isScheduled  && (
                                        <div
                                            style={{
                                                borderRadius: "2px",
                                                fontWeight: "bold",
                                                position: "absolute",
                                                bottom: 5,
                                                left: 5,
                                                backgroundColor: "#FFF",
                                                color: COLOR_MINTY_FRESH,
                                                padding: `${theme.spacing(1)}px`,
                                                fontSize: '10px'
                                            }}
                                        >
                                            SCHEDULED
                                        </div>
                                    )}
                                    {OpenHouseCalendarStore.highlightedOpenHouse &&
                                        listing.id === OpenHouseCalendarStore.highlightedOpenHouse.listingId &&
                                        openHouseInfo.date === OpenHouseCalendarStore.highlightedOpenHouse.date && (
                                        <>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    border: '1px',
                                                    borderColor: COLOR_MINTY_FRESH,
                                                    borderRadius: '4px',
                                                    pointerEvents: 'none'
                                                }}
                                            ></div>
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </>
    );
});
