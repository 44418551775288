import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';

function FetchNotifications(props) {
    const { NotificationsStore } = useStore();

    useEffect(()=> {
        NotificationsStore.fetchStreamNotifications();
        NotificationsStore.subscribe();
    }, [NotificationsStore]);

    return props.children;
}

export default observer(FetchNotifications);
