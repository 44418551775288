import React from 'react';
import { DialogContent, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import FormCard from './FormCard';

const StyledDialogContent = withTheme(styled(DialogContent)`
    display: flex;
    justify-content: center;
    padding: 0;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        padding: 0 ${({ theme })=> theme.spacing(3)}px;
    }
`);

const GreyContainer = withTheme(styled.div`
    background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
    width: 768px;
    ${({ theme })=> theme.breakpoints.up('sm')} {
        padding: ${({ theme })=> theme.spacing(5)}px;
    }
`);

function Content({ card, agent, formRef, onSchedule, isSubmiting, isScheduled }) {
    return (
        <StyledDialogContent>
            <GreyContainer>
                <FormCard
                    card={card}
                    agent={agent}
                    formRef={formRef}
                    onSchedule={onSchedule}
                    isSubmiting={isSubmiting}
                    isScheduled={isScheduled}
                />
            </GreyContainer>
        </StyledDialogContent>
    );
}

export default Content;
