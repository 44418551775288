import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function ElectricityIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12 2C8 2 4.75 5.25 4.75 9.25C4.75 11.77 6.04 14 8 15.29V18.85C8 19.95 8.9 20.85 10 20.85H10.01C10.42 21.54 11.15 22 12 22C12.85 22 13.58 21.54 13.99 20.86H14C15.1 20.86 16 19.96 16 18.86V15.3C17.96 14 19.25 11.78 19.25 9.26C19.25 5.25 16 2 12 2ZM13.54 18.86H10.47C10.21 18.86 10.01 18.65 10.01 18.4C10.01 18.14 10.22 17.94 10.47 17.94H13.54C13.8 17.94 14 18.15 14 18.4C14 18.65 13.79 18.86 13.54 18.86ZM13.54 16.93H10.47C10.21 16.93 10.01 16.72 10.01 16.47C10.01 16.21 10.22 16.01 10.47 16.01H13.54C13.8 16.01 14 16.22 14 16.47C14 16.72 13.79 16.93 13.54 16.93ZM12.75 11.31V13.37C12.75 13.78 12.41 14.12 12 14.12C11.59 14.12 11.25 13.78 11.25 13.37V11.31L9.58 9.64C9.29 9.35 9.29 8.87 9.58 8.58C9.87 8.29 10.35 8.29 10.64 8.58L12 9.94L13.36 8.58C13.65 8.29 14.13 8.29 14.42 8.58C14.71 8.87 14.71 9.35 14.42 9.64L12.75 11.31Z" />
        </SvgIcon>
    );
}
