import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { withTheme } from '@material-ui/styles';

const pulseKeyFrames = keyframes`
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 98, 89, .85);
    box-shadow: 0 0 0 0 rgba(255, 98, 89, .85);
  }
  
  70% {
    -webkit-box-shadow: 0 0 0 1em rgba(255, 98, 89, 0);
    box-shadow: 0 0 0 1em rgba(255, 98, 89, 0);
  }
  
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 98, 89, 0);
    box-shadow: 0 0 0 0 rgba(255, 98, 89, 0);
`;

export const NotificationRing = withTheme(styled.div`
    border: 3px solid;
    border-color: ${({ hasNotifications, theme })=> (hasNotifications ? theme.palette.lr_colors.brick : 'transparent')};
    border-radius: 50%;

    ${({ showPulse })=>
        showPulse === true
            ? css`
                animation: ${pulseKeyFrames} 2s 3;
            `
            : ''};
`);
