export const timeframeOptions = [
    { value: 'ASAP', label: 'ASAP' },
    { value: 'within 3 months', label: '1-3 months' },
    { value: '3-6 months', label: '3-6 months' },
    { value: '6-12 months', label: '6-12 months' },
    { value: '12+ months', label: '12+ months' },
    { value: 'i_already_bought_a_home_using_robin', label: 'I already bought a home using Robin' },
    { value: 'im_not_sure', label: "I'm not sure" },
];

export const timeframeOptionsByValue = timeframeOptions.reduce((accum, option)=> {
    return {
        ...accum,
        [option.value]: option,
    };
}, {});
