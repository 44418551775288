import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { LRInputOutlined } from 'components/LRInput';
import Logger from 'js-logger';
import PropTypes from 'prop-types';

export function LRAutocomplete({
    autoFocus,
    error,
    helperText,
    label,
    placeholder,
    name,
    inputProps = {},
    InputProps = {},
    ...props
}) {
    return (
        <Autocomplete
            {...props}
            forcePopupIcon={false}
            renderInput={(params)=> (
                <LRInputOutlined
                    {...params}
                    {...inputProps}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    error={error}
                    helperText={helperText}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        ...InputProps,
                        name,
                        endAdornment: (
                            <>
                                {InputProps.endAdornment}
                                <div
                                    style={{
                                        position: 'relative',
                                        right: -22,
                                    }}
                                >
                                    {params.InputProps.endAdornment}
                                </div>
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

LRAutocomplete.propTypes = {
    // LRInputOutlined Props Below

    /**
     * If `true`, the input will be focused during the first mount.
     */
    autoFocus: PropTypes.bool,

    /**
     * If `true`, the input will be display a red border and red helper text.
     */
    error: PropTypes.bool,

    /**
     * The helper text content.
     */
    helperText: PropTypes.string,

    /**
     * The label content.
     */
    label: PropTypes.string,

    /**
     * The name of the input.
     */
    name: PropTypes.string,

    /**
     * The props for the input.
    */
    InputProps: PropTypes.object,


    // Material UI Autocomplete Props Below

    /**
    * If `true`, the portion of the selected suggestion that has not been typed by the user,
    * known as the completion string, appears inline after the input cursor in the textbox.
    * The inline completion string is visually highlighted and has a selected state.
    */
    autoComplete: PropTypes.bool,
    /**
    * If `true`, the first option is automatically highlighted.
    */
    autoHighlight: PropTypes.bool,
    /**
    * If `true`, the selected option becomes the value of the input
    * when the Autocomplete loses focus unless the user chooses
    * a different option or changes the character string in the input.
    */
    autoSelect: PropTypes.bool,
    /**
    * Control if the input should be blurred when an option is selected:
    *
    * - `false` the input is not blurred.
    * - `true` the input is always blurred.
    * - `touch` the input is blurred after a touch event.
    * - `mouse` the input is blurred after a mouse event.
    */
    blurOnSelect: PropTypes.oneOfType([PropTypes.oneOf(['mouse', 'touch']), PropTypes.bool]),
    /**
    * Props applied to the [`Chip`](/api/chip/) element.
    */
    ChipProps: PropTypes.object,
    /**
    * Override or extend the styles applied to the component.
    * See [CSS API](#css) below for more details.
    */
    classes: PropTypes.object,
    /**
    * @ignore
    */
    className: PropTypes.string,
    /**
    * If `true`, the input's text will be cleared on blur if no value is selected.
    *
    * Set to `true` if you want to help the user enter a new value.
    * Set to `false` if you want to help the user resume his search.
    */
    clearOnBlur: PropTypes.bool,
    /**
    * If `true`, clear all values when the user presses escape and the popup is closed.
    */
    clearOnEscape: PropTypes.bool,
    /**
    * Override the default text for the *clear* icon button.
    *
    * For localization purposes, you can use the provided [translations](/guides/localization/).
    */
    clearText: PropTypes.string,
    /**
    * The icon to display in place of the default close icon.
    */
    closeIcon: PropTypes.node,
    /**
    * Override the default text for the *close popup* icon button.
    *
    * For localization purposes, you can use the provided [translations](/guides/localization/).
    */
    closeText: PropTypes.string,
    /**
    * If `true`, the popup will ignore the blur event if the input is filled.
    * You can inspect the popup markup with your browser tools.
    * Consider this option when you need to customize the component.
    */
    debug: PropTypes.bool,
    /**
    * The default input value. Use when the component is not controlled.
    */
    defaultValue: PropTypes.any,
    /**
    * If `true`, the input can't be cleared.
    */
    disableClearable: PropTypes /* @typescript-to-proptypes-ignore */.bool,
    /**
    * If `true`, the popup won't close when a value is selected.
    */
    disableCloseOnSelect: PropTypes.bool,
    /**
    * If `true`, the input will be disabled.
    */
    disabled: PropTypes.bool,
    /**
    * If `true`, will allow focus on disabled items.
    */
    disabledItemsFocusable: PropTypes.bool,
    /**
    * If `true`, the list box in the popup will not wrap focus.
    */
    disableListWrap: PropTypes.bool,
    /**
    * Disable the portal behavior.
    * The children stay within it's parent DOM hierarchy.
    */
    disablePortal: PropTypes.bool,
    /**
    * A filter function that determines the options that are eligible.
    *
    * @param {T[]} options The options to render.
    * @param {object} state The state of the component.
    * @returns {T[]}
    */
    filterOptions: PropTypes.func,
    /**
    * If `true`, hide the selected options from the list box.
    */
    filterSelectedOptions: PropTypes.bool,
    /**
    * Force the visibility display of the popup icon.
    */
    forcePopupIcon: PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.bool]),
    /**
    * If `true`, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
    */
    freeSolo: PropTypes /* @typescript-to-proptypes-ignore */.bool,
    /**
    * If `true`, the input will take up the full width of its container.
    */
    fullWidth: PropTypes.bool,
    /**
    * The label to display when the tags are truncated (`limitTags`).
    *
    * @param {number} more The number of truncated tags.
    * @returns {ReactNode}
    */
    getLimitTagsText: PropTypes.func,
    /**
    * Used to determine the disabled state for a given option.
    *
    * @param {T} option The option to test.
    * @returns {boolean}
    */
    getOptionDisabled: PropTypes.func,
    /**
    * Used to determine if an option is selected, considering the current value.
    * Uses strict equality by default.
    *
    * @param {T} option The option to test.
    * @param {T} value The value to test against.
    * @returns {boolean}
    */
    getOptionSelected: PropTypes.func,
    /**
    * If `true`, the component handles the "Home" and "End" keys when the popup is open.
    * It should move focus to the first option and last option, respectively.
    */
    handleHomeEndKeys: PropTypes.bool,
    /**
    * This prop is used to help implement the accessibility logic.
    * If you don't provide this prop. It falls back to a randomly generated id.
    */
    id: PropTypes.string,
    /**
    * If `true`, the highlight can move to the input.
    */
    includeInputInList: PropTypes.bool,
    /**
    * The maximum number of tags that will be visible when not focused.
    * Set `-1` to disable the limit.
    */
    limitTags: PropTypes.number,
    /**
    * The component used to render the listbox.
    */
    ListboxComponent: PropTypes.elementType,
    /**
    * Props applied to the Listbox element.
    */
    ListboxProps: PropTypes.object,
    /**
    * Text to display when in a loading state.
    *
    * For localization purposes, you can use the provided [translations](/guides/localization/).
    */
    loadingText: PropTypes.node,
    /**
    * If `true`, `value` must be an array and the menu will support multiple selections.
    */
    multiple: PropTypes /* @typescript-to-proptypes-ignore */.bool,
    /**
    * Callback fired when the value changes.
    *
    * @param {T|T[]} value The new value of the component.
    */
    onChange: PropTypes.func,
    /**
    * Callback fired when the highlight option changes.
    *
    * @param {object} event The event source of the callback.
    * @param {T} option The highlighted option.
    * @param {string} reason Can be: `"keyboard"`, `"auto"`, `"mouse"`.
    */
    onHighlightChange: PropTypes.func,
    /**
    * If `true`, the popup will open on input focus.
    */
    openOnFocus: PropTypes.bool,
    /**
    * Override the default text for the *open popup* icon button.
    *
    * For localization purposes, you can use the provided [translations](/guides/localization/).
    */
    openText: PropTypes.string,
    /**
    * The component used to render the body of the popup.
    */
    PaperComponent: PropTypes.elementType,
    /**
    * The component used to position the popup.
    */
    PopperComponent: PropTypes.elementType,
    /**
    * The icon to display in place of the default popup icon.
    */
    popupIcon: PropTypes.node,
    /**
    * If `true`, the input's text will be selected on focus.
    * It helps the user clear the selected value.
    */
    selectOnFocus: PropTypes.bool,
    /**
    * The size of the autocomplete.
    */
    size: PropTypes.oneOf(['medium', 'small']),
    /**
    * The value of the autocomplete.
    *
    * The value must have reference equality with the option in order to be selected.
    * You can customize the equality behavior with the `getOptionSelected` prop.
    */
    value: PropTypes.any,
};
