import { babouApi, portalApi } from 'apis';
import Logger from 'js-logger';
import { filter, omit, set } from 'lodash';
import { useQueries, useQuery } from 'react-query';


export const FEATURED_PROPERTY_PACKS_CACHE_KEY = 'featured-property-packs';
export const FEATURED_PROPERTY_PACKS_LISTINGS_CACHE_KEY = `${FEATURED_PROPERTY_PACKS_CACHE_KEY}-listings`;

export function useFeaturedPropertyPacks(/* areas */) {
    const featuredPropertyPacks = useQuery(
        [FEATURED_PROPERTY_PACKS_CACHE_KEY], 
        ()=> (portalApi.myHomeFeaturedPropertyPacks()),
        {
            select: (data)=> {
                const propertyPacks = data.filter((rawFilterData)=> Boolean(rawFilterData.home_base_page_active)).map((rawFilterData)=> {
                    const filterKeys = Object.keys(rawFilterData).filter((k)=> (Boolean(k.match(/^filter/))));
                    let propertyPack = omit(rawFilterData, filterKeys);

                    propertyPack = filterKeys.reduce((obj, key)=> {
                        return set(obj, key, rawFilterData[key]);
                    }, propertyPack);

                    propertyPack.fields = propertyPack.filters;
                    delete propertyPack.filters;

                    return propertyPack;
                });

                return propertyPacks;
            },
        }
    );

    return featuredPropertyPacks;

    // Commented out in case in the future we want to check if the property packs have listings
    // const propertyPacks = featuredPropertyPacks.data || [];
    // const featuredPropertyPacksListings = useQueries(propertyPacks.map((propertyPack)=> ({
    //     queryKey: [FEATURED_PROPERTY_PACKS_LISTINGS_CACHE_KEY, propertyPack.fields, areas],
    //     queryFn: ({ queryKey: [, fields] })=> (babouApi.searchListings(areas, fields || {}, { includeListings: true, }, { skipSearchAreaTransform: true, })),
    //     enabled: Boolean(areas?.length),
    //     select: (data)=> (data?.page?.listings),
    // })));
    // featuredPropertyPacksListings.forEach((propertyPackListingsQuery, i)=> {
    //     propertyPacks[i].listings = propertyPackListingsQuery;
    // });
    // const isLoadingListings = Boolean(filter(featuredPropertyPacksListings, { isLoading: true }).length);

    // return {
    //     ...featuredPropertyPacks,
    //     isLoading: featuredPropertyPacks.isLoading || isLoadingListings,
    // };
}
