import React, { useState } from 'react';
import { CircularProgress, Divider, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import styled from 'styled-components';
import LRModal from '../../../../components/LRModal';
import { Close } from '@material-ui/icons';
import LRButton from '../../../../components/LRButton';
import ResponsiveHOC from '../../../../components/ResponsiveHoc';
import { useCreateTask } from '../hooks';
import { useActiveBoard , useStore} from '../../../../mobx-store';
import analytics from 'services/analytics';

const Modal = withTheme(styled((props)=> <LRModal {...props} />)`
    & .paperWidthSm-listing-details {
        height: auto;
        max-width: 560px;
        padding: 0;

        ${({ theme })=> theme.breakpoints.only('xs')} {
            height: 100%;
        }
    }

    & .AddNoteModal-DialogContent-root {
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }

    & .AddNoteModal-DialogContent-root:first-child {
        padding-top: 0;
    }
`);

const useDialogContentStyles = makeStyles({
    root: {
        '&:first-child': {
            paddingTop: '0',
        },
    },
});

function AddTaskMobile({ onClose, onChangeText, text, onAddTask, isAddingTask }) {
    const theme = useTheme();

    return (
        <div
            style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
        >
            <div
                style={{
                    padding: theme.spacing(3),
                    position: 'relative',
                }}
            >
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                    Add Task
                </Typography>
                <div
                    style={{
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton onClick={onClose} title="Close">
                        <Close />
                    </IconButton>
                </div>
            </div>
            <Divider></Divider>
            <div
                style={{
                    flex: 1,
                }}
            >
                <textarea
                    value={text}
                    onChange={(event)=> onChangeText(event.target.value)}
                    placeholder="Write your task here!"
                    style={{
                        fontFamily: 'LL Circular Pro',
                        border: 0,
                        width: '100%',
                        height: '100%',
                        padding: theme.spacing(2),
                        fontSize: '1rem',
                    }}
                />
            </div>
            <Divider></Divider>
            <div
                style={{
                    padding: theme.spacing(2),
                }}
            >
                <LRButton
                    onClick={onAddTask}
                    fullWidth
                    variant="contained"
                    disabled={!text}
                    style={{
                        height: '48px',
                    }}
                >
                    {isAddingTask ? <CircularProgress size="20px" color="default" /> : 'Add'}
                </LRButton>
            </div>
        </div>
    );
}

function AddTaskDesktop({ onClose, onChangeText, text, onAddTask, isAddingTask }) {
    const theme = useTheme();
    return (
        <div
            style={{
                padding: `${theme.spacing(4)}px ${theme.spacing(7)}px`,
                position: 'relative',
                height: '100%',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    right: 0,
                }}
            >
                <IconButton onClick={onClose} title="Close">
                    <Close />
                </IconButton>
            </div>
            <div
                style={{
                    marginBottom: theme.spacing(3),
                }}
            >
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                    Add Task
                </Typography>
            </div>
            <div
                style={{
                    marginBottom: theme.spacing(2),
                }}
            >
                <textarea
                    value={text}
                    onChange={(event)=> onChangeText(event.target.value)}
                    placeholder="Write your task here!"
                    style={{
                        fontFamily: 'LL Circular Pro',
                        border: `1px solid ${theme.palette.lr_colors.border_grey}`,
                        width: '100%',
                        height: '134px',
                        padding: theme.spacing(2),
                        fontSize: '1rem',
                        resize: 'none',
                        outline: 'none',
                    }}
                />
            </div>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <LRButton
                    onClick={onAddTask}
                    variant={text ? 'contained' : ''}
                    color={text ? 'primary' : ''}
                    disabled={!text}
                >
                    {isAddingTask ? <CircularProgress size="20px" color="default" /> : 'Add'}
                </LRButton>
            </div>
        </div>
    );
}

const AddTask = ResponsiveHOC({ xs: AddTaskMobile, sm: AddTaskDesktop });

function AddTaskModal({ open, onClose }) {
    const { UserStore } = useStore();
    const { profileId, user } = UserStore;
    const { mutateAsync: createTask, isLoading } = useCreateTask();
    const activeBoard = useActiveBoard();
    const [text, setText] = useState('');
    const contentClasses = useDialogContentStyles();

    async function handleAddTask() {
        if(text) {
            analytics.eventTrack('robin_overview_todo_add_task_submit', {
                task_text: text,
            });
            await createTask({ text, boardId: activeBoard.id, userId: profileId, targetBenoitUserId: user?.id });
            setText('');
            onClose();
        }
    }

    return (
        <Modal open={open} onClose={onClose} hideCloseButton={true} dialogContentClasses={contentClasses}>
            <AddTask
                onClose={onClose}
                text={text}
                onChangeText={(newText)=> setText(newText)}
                onAddTask={handleAddTask}
                isAddingTask={isLoading}
            />
        </Modal>
    );
}

export default AddTaskModal;
