import _ from 'lodash';
import moment from 'moment';

// Colors
export const COLOR_RED = '#F80404';
export const COLOR_TREND_DOWN_RED = '#D51D4C';
export const COLOR_SALMON = '#ff6259';
export const COLOR_SALMON_DARK = '#9E332D';
export const COLOR_SALMON_LIGHT = '#ff9586';
export const COLOR_BLUE = '#01AAB5';
export const COLOR_BLUE_DARK = '#007a85';
export const COLOR_BLUE_LIGHT = '#5bdce7';
export const COLOR_BLUE_LINK = '#1EC8E1';
export const COLOR_BLUE_LINK_DARK = '#00A1C5';
export const COLOR_GREY = '#D8D8D8';
export const COLOR_GREEN = '#8CB63F';
export const COLOR_TREND_UP_GREEN = '#1E923E';
export const COLOR_GREY_DARKEST = '#3A3A3A';
export const COLOR_GREY_NERO = '#1A1A1A';
export const COLOR_GREY_MEDIUM = '#E3E3E3';
export const COLOR_GREY_DARK = '#A1A1A1';
export const COLOR_OFF_WHITE = '#f4f4f4';
export const COLOR_GREY_LIGHT = '#F9F9F9';
export const COLOR_DISCLAIMER_GREY= '#909090';
export const COLOR_ICE_ICE_BABY = '#ECF0F1';
export const GIGGITY = '#696969';
export const COLOR_COMPLIANCE_GREY = '#cccccc';
export const COLOR_BORDER_GREY = '#dddddd';
export const COLOR_BORDER_GREY_LIGHTER = '#BBBBBB';
export const COLOR_BLACK = '#000000';
export const COLOR_CREST_WHITE = '#FFFFFF';

// Robin Colors
export const COLOR_GRADE = '#1a1a1a';
export const COLOR_STEAK_SAUCE = '#a1a1a1';
export const COLOR_BRICK = '#ff6259';
export const COLOR_POOL = '#1ec8e1';
export const COLOR_CHARLIE = '#ffc85a';
export const COLOR_MINTY_FRESH = '#54d0aa';
export const COLOR_MINTY_FRESH_DARK = '#00B288';
export const COLOR_COSTA_MESA = '#f9f9f9';
export const COLOR_SWIM_LANE = '#f4f4f4';
export const COLOR_GREY2 = '#bbbbbb';
export const COLOR_GREY_MEDIUM_SECOND = '#EEEEEE';
export const COLOR_ERROR = '#d51d4c';

// Images
/* Old headshot was http://du1ux2871uqvu.cloudfront.net/sites/default/files/personplaceholder.jpg */
export const DEFAULT_HEADSHOT = 'https://cdn1.listreports.com/images/headshot.png';
export const DEFAULT_LISTING_HERO_IMAGE = '/images/hero_placeholder.png';
export const CALENDAR_IMAGE = '/images/calendar.png';
export const SEND_IMAGE = '/images/send.png';

//Images
export const IMG_HOME_REPORT_FEATURES = '/images/home-report-newsletter/features.svg';
export const IMG_HOME_REPORT_NEARBY_PROPERTIES = '/images/home-report-newsletter/nearby-properties.svg';
export const IMG_HOME_REPORT_NEWS = '/images/home-report-newsletter/news.svg';
export const IMG_HOME_REPORT_WORTH = '/images/home-report-newsletter/worth.svg';
export const HOME_REPORT_SIGNUP_IMAGE_URLS = [
    IMG_HOME_REPORT_FEATURES,
    IMG_HOME_REPORT_NEARBY_PROPERTIES,
    IMG_HOME_REPORT_NEWS,
    IMG_HOME_REPORT_WORTH,
];
export const IMG_HOME_REPORT_NEARBY_PROPERTIES_2 = '/images/home-report-newsletter/nearby-properties_slide.svg';
export const IMG_HOME_REPORT_WORTH_2 = '/images/home-report-newsletter/worth_2.svg';

// REALTIME
/**
 * This can be anything because its not validate on
 * the backend but it must be consistant everywhere in
 * the app.
 */
export const REALTIME_AUTH_HEADER = 'ListReports Auth';

// ScheduleATour
export const SCHEDULE_A_TOUR_WHEN_ASAP = 'asap';
export const SCHEDULE_A_TOUR_WHEN_LET_ME_PICK = 'letMePick';

// Nav
export const NAV_BOARD = '/boards';
export const NAV_MY_HOME = '/boards/home-report';
export const NAV_SCHEDULED_TOURS = '/boards/scheduled-tours';
export const NAV_COMPARE_NOW = '/boards/compare-now';
export const NAV_IDEAS_BOARD = '/boards/idea-board';
export const NAV_MORTGAGE_CENTER = '/boards/mortgage-center';
export const NAV_OVERVIEW = '/overview';

export const PRICE_RANGE = [
    { label: 'Any', value: null },
    { label: '$50k', value: '50000' },
    { label: '$75k', value: '75000' },
    { label: '$100k', value: '100000' },
    { label: '$125k', value: '125000' },
    { label: '$150k', value: '150000' },
    { label: '$175k', value:'175000' },
    { label: '$200k', value: '200000' },
    { label: '$225k', value: '225000' },
    { label: '$250k', value: '250000' },
    { label: '$275k', value: '275000' },
    { label: '$300k', value: '300000' },
    { label: '$325k', value: '325000' },
    { label: '$350k', value: '350000' },
    { label: '$375k', value: '375000' },
    { label: '$400k', value: '400000' },
    { label: '$425k', value: '425000' },
    { label: '$450k', value: '450000' },
    { label: '$475k', value: '475000' },
    { label: '$500k', value: '500000' },
    { label: '$550k', value: '550000' },
    { label: '$600k', value: '600000' },
    { label: '$650k', value: '650000' },
    { label: '$700k', value: '700000' },
    { label: '$750k', value: '750000' },
    { label: '$800k', value: '800000' },
    { label: '$850k', value: '850000' },
    { label: '$900k', value: '900000' },
    { label: '$950k', value: '950000' },
    { label: '$1M', value: '1000000' },
    { label: '$1.25M', value: '1250000' },
    { label: '$1.5M', value: '1500000' },
    { label: '$1.75M', value: '1750000' },
    { label: '$2M', value: '2000000' },
    { label: '$2.25M', value: '2250000' },
    { label: '$2.5M', value: '2500000' },
    { label: '$2.75M', value: '2750000' },
    { label: '$3M', value: '3000000' },
    { label: '$3.25M', value: '3250000' },
    { label: '$3.5M', value: '3500000' },
    { label: '$3.75M', value: '3750000' },
    { label: '$4M', value: '4000000' },
    { label: '$4.25M', value: '4250000' },
    { label: '$4.5M', value: '4500000' },
    { label: '$4.75M', value: '4750000' },
    { label: '$5M', value: '5000000' },
    { label: '$6M', value: '6000000' },
    { label: '$7M', value: '7000000' },
    { label: '$8M', value: '8000000' },
    { label: '$9M', value: '9000000' },
    { label: '$10M', value: '10000000' },
];


//LANES

function filterAndSort(arr, archived, orderByProps, orderByOrders) {
    return _(arr)
        .filter({ archived })
        .orderBy(orderByProps, orderByOrders)
        .value();
}

export const LANE_SORT_FUNCTIONS = [
    {
        label: 'Newest',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['listing_details.listing_date', 'match_score'], ['desc', 'desc'])
    },
    {
        label: "Agent's pick",
        divider: false,
        func: _.partial(
            filterAndSort,
            _,
            false,
            ['picked_by_agent', 'listing_details.listing_date', 'match_score'],
            ['desc', 'desc', 'desc']
        )
    },
    {
        label: 'Match Score',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['match_score'], ['desc'])
    },
    {
        label: 'Price low to high',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['listing_details.price', 'match_score'], ['asc', 'desc'])
    },
    {
        label: 'Price high to low',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['listing_details.price', 'match_score'], ['desc', 'desc'])
    },
    {
        label: 'Days on ListReports (newest first)',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['listing_details.listing_date', 'match_score'], ['desc', 'desc'])
    },
    {
        label: 'Days on ListReports (oldest first)',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['listing_details.listing_date', 'match_score'], ['asc', 'desc'])
    },
    {
        label: 'Year built',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['listing_details.year', 'match_score'], ['desc', 'desc'])
    },
    {
        label: 'City',
        divider: false,
        func: _.partial(filterAndSort, _, false, ['listing_details.city', 'match_score'], ['asc', 'desc'])
    },
    {
        label: 'View Hidden Properties',
        divider: true,
        func: _.partial(filterAndSort, _, true, ['match_score'], ['desc'])
    }
];

export const LANES_NAMES = {
    interested: 'Interested',
    see: 'Want to see',
    buy: 'Want to buy',
};

export const LANES = [
    // {
    //     id: '1',
    //     name: 'Your searches',
    //     doubleWide: true,
    //     sortingFunctions: LANE_SORT_FUNCTIONS,
    //     selectedSortFunction: LANE_SORT_FUNCTIONS[0].func,
    //     numOfPlaceholders: 3,
    //     superPower: {
    //         buttonLabel: 'Edit search',
    //         doNotDisableButton: true,
    //         path: '/boards/account/preferences'
    //     }
    // },
    {
        id: '1',
        columnId: 1,
        name: LANES_NAMES['interested'],
        emptyStateLabel: 'Drag homes here that you’re interested in.',
        doubleWide: false,
        numOfPlaceholders: 1,
        superPower: false,
        isExpandable: true,
        description: 'Homes you like and want to discuss or keep track of.'
    },
    {
        id: '2',
        columnId: 2,
        name: LANES_NAMES['see'],
        emptyStateLabel: 'Drag homes here that you want to see.',
        doubleWide: false,
        numOfPlaceholders: 2,
        superPower: false,
        isExpandable: true,
        description: 'Homes you love and want to see or learn more about!'
    },
    {
        id: '3',
        columnId: 3,
        name: LANES_NAMES['buy'],
        emptyStateLabel: 'Drag homes here that you want to prepare an offer for.',
        doubleWide: false,
        numOfPlaceholders: 1,
        isExpandable: true,
        superPower: {
            buttonLabel: 'Submit Offer',
            doNotDisableButton: true,
            path: '/boards/submit-offer'
        },
        description: "Homes you're seriously considering buying.",
    }
];

export const LEGACY_LANES_NAMES = {
    interested: 'Maybes',
    want_to_see: 'Favorites',
    want_to_buy: 'Make an offer',
};

// Validation RegEx
export const VALIDATION_EMAIL_REGEX = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i;

// My Home Service Providers
export const MY_HOME_SERVICE_PROVIDER_CONTRACTOR = 'Contractor';
export const MY_HOME_SERVICE_PROVIDER_ELECTRICIAN = 'Electrician';
export const MY_HOME_SERVICE_PROVIDER_HANDYPERSON = 'Handyperson';
export const MY_HOME_SERVICE_PROVIDER_HOUSEKEEPER = 'Housekeeper';
export const MY_HOME_SERVICE_PROVIDER_HVAC = 'HVAC';
export const MY_HOME_SERVICE_PROVIDER_LANDSCAPER = 'Landscaper';
export const MY_HOME_SERVICE_PROVIDER_OTHER = 'Other';
export const MY_HOME_SERVICE_PROVIDER_PLUMBER = 'Plumber';
export const MY_HOME_SERVICE_PROVIDER_POOL_SPA = 'Pool/spa';
export const MY_HOME_SERVICE_PROVIDER_ROOFING = 'Roofing';
export const MY_HOME_SERVICE_PROVIDER_WINDOWS_DOORS = 'Windows/doors';

// Listpaccks
export const LISTPACK_DEFAULT_SORT_ORDER = 'Days on market (default)';
export const LISTPACK_SORT_MAPPING = {
    'Days on market (default)': 'ListingDate:desc',
    'Year built': 'YearBuilt:desc',
    'Price (high to low)': 'ListPrice:desc',
    'Price (low to high)': 'ListPrice:asc',
    'Most bedrooms': 'Bedrooms:desc',
    'Most bathrooms': 'Bathrooms:desc',
    'Most square feet': 'LivingArea:desc',
    'Open house': 'OpenHouseDate:asc',
    'Biggest lot size': 'LotSize:desc',
};
export const LISTPACK_STATIC_SORT_MAPPING = [
    {id: 'listing_date', label: 'Days on market (default)', order: 'desc'},
    {id: 'year', label: 'Year built', order: 'desc'},
    {id: 'price', label: 'Price (high to low)', order: 'desc'},
    {id: 'price', label: 'Price (low to high)', order: 'asc'},
    {id: 'beds', label: 'Most bedrooms', order: 'desc'},
    {id: 'baths', label: 'Most bathrooms', order: 'desc'},
    {id: 'home_size', label: 'Most square feet', order: 'desc'},
    {id: (l)=> l.open_houses[0]?.date_iso || '1970-01-01' , label: 'Open house', order: 'desc'},
];
export const LISTPACK_DEFAULT_NOTIFY_FREQUENCY = 'daily';
export const BOARD_LISTPACK_CACHE_KEY = 'board-listpacks';
export const LISTPACK_CONTACT_AGENT_CARD_DISMISS_LOCKR_KEY = 'listpack-contact-agent-card-dismissed';
export const LISTPACK_CONTACT_AGENT_CARD_DISPLAY_FOR_SOURCES = [
    'shareables_daily',
];

export const SOLD_STATUSES = [
    'sold',
    'closed sale',
    'temp off market',
    'delete',
    'expired',
    'cancelled',
    'canceled',
    'closed',
    'off-market',
];

export const PENDING_STATUSES = [
    'act und contract',
    'active contingent',
    'active kick out',
    'active option contract',
    'active under contract',
    'active w contingency',
    'active with contract',
    'active/pending-call',
    'active/pending-house',
    'active/pending-loan',
    'active/pending-short sale',
    'backup contract-call la',
    'cntg/ko',
    'cntg/no ko',
    'contingent',
    'contingent no kickout',
    'contingent offer',
    'contingent short sale',
    'contingent w/kickout',
    'contract',
    'contingent take backup',
    'escrow',
    'contract contingent on buyer sale',
    'hold',
    'pending',
    'pending - over 4 months',
    'pending (do not show)',
    'pending bring backup',
    'pending courtconfirmation',
    'pending please show',
    'pending selling bnk ap',
    'pending continue to show',
    'pending short lender approval',
    'pending show backup-short',
    'pending show backups-reo',
    'pending show for backups',
    'pending subj lenderapprov',
    'pending take backups',
    'pending w/ contingency',
    'pending w/option',
    'pending-reo',
    'pending/take back up',
    'uc-contingency-take bkups',
    'under agreement',
    'under contract',
    'under contract-backups',
    'activeundercontract',
    'accepting backup offers',
    'backup offer',
    'active with accepted offer',
    'offer accepted',
    'contingency',
];

export const SORT_OPTIONS = [
    {id: 'columnIndex', label: 'Column Order', order: 'asc', key: 'columnIndex'},
    {id: 'listing_details.listing_date', label: 'Days on market', order: 'desc', key: 'listing_details.listing_date'},
    {id: 'listing_details.year', label: 'Year built', order: 'desc', key: 'listing_details.year'},
    {id: 'listing_details.price', label: 'Price (high to low)', order: 'desc', key: 'listing_details.price'},
    {id: 'listing_details.price', label: 'Price (low to high)', order: 'asc', key: 'listing_details.price'},
    {id: 'listing_details.beds', label: 'Most bedrooms', order: 'desc', key: 'listing_details.beds'},
    {id: 'listing_details.baths', label: 'Most bathrooms', order: 'desc', key: 'listing_details.baths'},
    {id: 'listing_details.home_size', label: 'Most square feet', order: 'desc', key: 'listing_details.home_size'},
    {id: (l)=> l.listing_details?.open_houses[0]?.date_iso || '1970-01-01' , label: 'Open house', order: 'desc', key: 'open_houses'},
];

export const SORT_MAP_MIXPANEL = {
    'columnIndex_asc': 'column_order',
    'listing_details.listing_date_desc': 'days_on_market',
    'listing_details.year_desc': 'year_built',
    'listing_details.price_desc': 'price_high_to_low',
    'listing_details.price_asc': 'price_low_to_high',
    'listing_details.beds_desc': 'most_bedrooms',
    'listing_details.baths_desc': 'most_bathrooms',
    'listing_details.home_size_desc': 'most_square_feet',
    'open_houses_desc': 'open_house',
};

export const DAYS_ON_MARKET = [
    {value: null, label: 'No max'},
    {value: 'less_than_0_days', label: 'New listings'},
    {value: '2_days_or_less', label: 'Less than 3 days'},
    {value: '6_days_or_less', label: 'Less than 7 days'},
    {value: '13_days_or_less', label: 'Less than 14 days'},
    {value: '29_days_or_less', label: 'Less than 30 days'},
    {value: '8_days_or_more', label: 'More than 7 days'},
    {value: '15_days_or_more', label: 'More than 14 days'},
    {value: '31_days_or_more', label: 'More than 30 days'},
    {value: '61_days_or_more', label: 'More than 60 days'},
    {value: '91_days_or_more', label: 'More than 90 days'},
    {value: '181_days_or_more', label: 'More than 180 days'},
];

export const daysOnMarketToFilterParam = (key)=> ({
    name: `effective_listingdate_${key.includes('more') ? 'max' : 'min'}`,
    value: moment().subtract(parseInt(key.match(/\d+/)[0]), 'd')
        .format('YYYY-MM-DD')
});

export const  MOBILE_VIEW_MODES = {
    MAP: 'map',
    LIST: 'list'
};

// View Listings Modal
export const LISTING_MODAL_NAV_VARIANT_STANDARD = 'standard';
export const LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL = 'loggedout-listpack-search-modal';
export const LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS = 'loggedout-listpack-search-modal-no-robin-access';

// Login
export const SIGNUP_LOGIN_VARIANT_PAGE = 'page';
export const SIGNUP_LOGIN_VARIANT_WIDGET = 'widget';
export const SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION = 'run_oauth_redirect_success_action';
export const SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_MOVE_CARD_OR_LISTING = 'oauth_redirect_move_card_or_listing';
export const SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_PINSPIRATION_PIN_UNPIN = 'oauth_redirect_pinspiration_pin_unpin';

// Subscriptions
export const SUBSCRIPTION_HOME_REPORT_LEAD_GEN = 'Home Report Lead Gen';
export const SUBSCRIPTION_ROBIN_ACCESS = 'Robin Access';
