import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Box, Grid, Hidden } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withTheme } from '@material-ui/styles';
import { useListingSchoolData } from 'hooks/data/listing/useListingSchoolData';

const StyledScoreGrid = styled(Grid)`
    width: 64px;
    height: 64px;
    background: #1ec8e1;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin-right: 18px;
`;
const StyledScoreTypography = styled(Typography)`
    font-size: 1.75rem;
    color: #fff;
    margin-right: 1px;
`;
const StyledOutOfTypography = styled(Typography)`
    font-size: 0.625rem;
    color: #fff;
`;
const StyledAssignedLabelTypography = styled(Typography)`
    font-size: 0.625rem;
    letter-spacing: 0.0938rem;
    color: #a1a1a1;
    text-transform: uppercase;
`;
const StyledSchoolDetailsTypography = styled(Typography)`
    & .space-it {
        margin-right: 18px;
    }
`;
const SchoolInfoContainer = styled.div`
    flex: 1;
    max-width: 100%;
    margin-right: 18px;
`;
const StyledNoGridMobileGrid = withTheme(styled(Grid)`
    ${({ theme })=> theme.breakpoints.only('xs')} {
        display: inline-block;
    }
`);
const GTXSFlexDiv = styled.div`
    flex: 1;
`;

function School({ width, schoolData, ...props }) {
    return (
        <Grid container direction="row" alignItems={width === 'xs' ? 'flex-start' : 'center'}>
            <StyledScoreGrid container direction="row" justify="center" alignItems="center">
                <div>
                    <StyledScoreTypography display="inline">{schoolData.rating}</StyledScoreTypography>
                    <StyledOutOfTypography display="inline">/10</StyledOutOfTypography>
                </div>
            </StyledScoreGrid>

            <GTXSFlexDiv>
                <StyledNoGridMobileGrid container direction="row" alignItems="center">
                    <SchoolInfoContainer>
                        {schoolData.assigned && <StyledAssignedLabelTypography>Assigned</StyledAssignedLabelTypography>}
                        <Box fontSize={20} fontWeight={500} lineHeight="24px" component="h3">
                            {schoolData.name}
                        </Box>
                        <Hidden smDown>
                            <StyledSchoolDetailsTypography component="div">
                                <span className="space-it">
                                    <b>Grades:</b> {schoolData.grades}
                                </span>{' '}
                                <b>Distance:</b> {schoolData.distance} miles
                            </StyledSchoolDetailsTypography>
                        </Hidden>
                        <Hidden mdUp>
                            <StyledSchoolDetailsTypography component="div">
                                <Box>
                                    <span className="space-it">
                                        <b>Grades:</b> {schoolData.grades}
                                    </span>
                                </Box>
                                <Box>
                                    <b>Distance:</b> {schoolData.distance} miles
                                </Box>
                            </StyledSchoolDetailsTypography>
                        </Hidden>
                    </SchoolInfoContainer>
                </StyledNoGridMobileGrid>
            </GTXSFlexDiv>
        </Grid>
    );
}

const StyledExpansionPanel = styled(({ className, ...props })=> (
    <ExpansionPanel classes={{ root: className }} {...props} />
))`
    box-shadow: none;
    border-radius: 0 !important;
`;
const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
    padding: 0;
    min-height: 0 !important;

    & .MuiExpansionPanelSummary-content {
        margin: 0 !important;
    }
    & .MuiIconButton-root {
        padding-top: 0;
        padding-bottom: 0;
    }
`;
const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 24px;
`;

const StyleExpandMoreIcon = styled(ExpandMoreIcon)`
    color: #000;
`;
const StyledSubheadingTypography = styled(Typography)`
    margin-bottom: 42px;
`;
const StyledLegalTypography = styled(Typography)`
    font-size: 10px;
    color: #a1a1a1;
`;
const SchoolsContainer = styled.div`
    margin-bottom: 36px;
`;
const SchoolSpacer = styled.div`
    height: 32px;
`;

const Schools = React.memo(function Schools({ listing }) {
    const { data: schoolData } = useListingSchoolData(listing);
    const schoolSumaryRating = useMemo(()=> {
        if(!schoolData) {
            return '';
        }
        var avgRating = 0;
        schoolData.schools.forEach((school)=> {
            avgRating += school.rating;
        });
        avgRating = avgRating / schoolData.schools.length;
        return avgRating >= 7 ? 'above average' : 'average';
    }, [schoolData]);

    if(!schoolData) {
        return null;
    }

    return (
        <Box>
            <StyledExpansionPanel defaultExpanded={true}>
                <StyledExpansionPanelSummary
                    style={{ marginTop: 0 }}
                    expandIcon={<StyleExpandMoreIcon />}
                >
                    <Typography variant="h5">Schools</Typography>
                </StyledExpansionPanelSummary>

                <StyledExpansionPanelDetails>
                    <StyledSubheadingTypography>
                        The assigned public schools for this home are {schoolSumaryRating} for this area. There are
                        additionally {schoolData.total_private_schools || 0} private school
                        {schoolData.total_private_schools !== 1 && 's'} and {schoolData.total_charter_schools || 0}{' '}
                        charter school{schoolData.total_charter_schools !== 1 && 's'} within 5 miles of this home.
                    </StyledSubheadingTypography>

                    <SchoolsContainer>
                        {schoolData.schools.map((school, i)=> (
                            <Box key={i}>
                                {i > 0 && <SchoolSpacer />}
                                <School schoolData={school} />
                            </Box>
                        ))}
                    </SchoolsContainer>

                    <StyledLegalTypography>
                        School data provided by ATTOM Data Solutions. School service boundaries are intended to be used as 
                        reference only. To verify enrollment eligibility for a property, contact the school directly.
                    </StyledLegalTypography>
                </StyledExpansionPanelDetails>
            </StyledExpansionPanel>
        </Box>
    );
});

Schools.propTypes = {
    listing: PropTypes.object.isRequired,
};

export default Schools;
