import React from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import LRTab from './LRTab';
import { withTheme } from '@material-ui/core/styles';

export { LRTab };

export default withTheme(styled(({ ...props })=> <Tabs {...props} />)`
    color: ${({ theme })=> theme.palette.text.primary};

    & .MuiTabs-indicator {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_darkest};
    }

    & .MuiTab-textColorPrimary.Mui-selected {
        color: ${({ theme })=> theme.palette.lr_colors.grey_darkest};
        font-weight: 700;
    }

    & .MuiTab-textColorPrimary {
        border-bottom: 1px solid ${({ theme })=> theme.palette.lr_colors.grey};
    }
`);
