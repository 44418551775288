import React, { useContext } from 'react';
import {
    Box,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    Typography,
    useTheme,
    withStyles,
    makeStyles,
} from '@material-ui/core';
import { useResponsiveBreakpoint } from '../../../hooks';
import { TalkingPointsContext } from '..';
import LRAvatar from 'components/LRAvatar';
import { UserProfileInfo } from '../UserProfileInfo';
import { COLOR_ICE_ICE_BABY, COLOR_BLACK, COLOR_GREY_LIGHT, GIGGITY, COLOR_BLUE_LIGHT } from '../../../constants';
import addCommas from '../../../services/add-commas';
import { COMMENTARY_PLACEHOLDER } from '../constants';
import { useQuery } from '../../../hooks';
import { HistoricalTrend, HistoricalTrendLegend } from './HistoricalTrend';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: COLOR_ICE_ICE_BABY,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme)=> ({
    head: {
        borderColor: COLOR_BLACK,
        fontWeight: 700,
        padding: '6px 4px 0 !important',
    },
    body: {
        border: 'none',
        padding: '6px 4px !important',
    },
}))(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    trendContainer: {
        display: 'flex',
        alignItems: 'center'
    },
});

const isValid = (str)=> {
    return !['', '0', 'NaN', 'null', 'undefined'].includes(String(str));
};

export const LocalMarketTrends = function () {
    const isMobile = useResponsiveBreakpoint('xs');
    const theme = useTheme();
    const classes = useStyles();
    const {
        user,
        talkingPoints: {
            commentary = '',
            areas = [],
        } = {},
        formattedDate,
        media,
    } = useContext(TalkingPointsContext);
    const previewMode = media === 'preview';
    const printMode = media === 'print';
    const userCommentary = previewMode
        ? commentary || COMMENTARY_PLACEHOLDER
        : commentary;
    const highlightCommentary = useQuery()
        .get('highlight') === 'local_market_commentary';
    const fromMonth = areas[0]?.from_month;
    const toMonth = areas[0]?.to_month;
    return (
        <div style={{
            marginTop: theme.spacing(4)
        }}>
            <Typography variant={isMobile ? 'h5' : 'h4'}
                style={{fontWeight: 500}}
            >
                Local Market Trends
            </Typography>
            <Typography variant={isMobile ? 'body1' : 'h6'} style={{fontWeight: 'normal'}}>
                As of <strong>{formattedDate}</strong>
            </Typography>

            {userCommentary && (<div
                style={{
                    display: 'flex',
                    alignItems: isMobile ? 'flex-start' : 'center',
                    marginTop: isMobile ? theme.spacing(1.5) : theme.spacing(4),
                    flexDirection: isMobile ? 'column-reverse' : 'row',
                }}
            >
                <div
                    className="space-x-4"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '124px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        {user.headshot && <LRAvatar user={user} size="size64"></LRAvatar>}
                        {user.name && !isMobile && !printMode && (
                            <Typography variant="body2" style={{marginTop: theme.spacing(1), fontWeight: 500}}>{user.name}</Typography>
                        )}
                    </div>

                    {isMobile && (<UserProfileInfo user={user} />)}
                </div>

                <div style={{
                    padding: theme.spacing(3),
                    backgroundColor: media === 'print' ? COLOR_GREY_LIGHT : 'white',
                    borderRadius: 8,
                    marginBottom: isMobile ? theme.spacing(3) : 0,
                    marginLeft: isMobile ? 0 : theme.spacing(3),
                }}>
                    <Typography variant="body1"
                        style={{
                            backgroundColor: highlightCommentary ? `${COLOR_BLUE_LIGHT}50` : 'transparent',
                        }}
                    >{userCommentary}</Typography>
                </div>
            </div>)}

            <TableContainer component={Box} marginTop={4} borderBottom={`0.5px solid ${GIGGITY}`}>
                <Table className={classes.table} size="small" aria-label="Local market trends data table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Area</StyledTableCell>
                            <StyledTableCell>Median Price</StyledTableCell>
                            <StyledTableCell>Active Listings</StyledTableCell>
                            <StyledTableCell>New Listings - 5 days</StyledTableCell>
                            <StyledTableCell>Median Days on Market</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {areas.map((area)=> {
                            const {
                                id,
                                median_price,
                                median_price_mm,
                                active_listings_count,
                                active_listings_count_mm,
                                new_listings_count,
                                median_dom,
                                median_dom_mm,
                            } = area;
                            return (<StyledTableRow key={id}>
                                <StyledTableCell>{id}</StyledTableCell>
                                <StyledTableCell>
                                    <div className={classes.trendContainer}>
                                        {isValid(median_price) ? `$${addCommas(median_price)}` : '-'}
                                        {isValid(median_price) && (<HistoricalTrend
                                            value={median_price_mm}
                                            size="small"
                                        />)}
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <div className={classes.trendContainer}>
                                        {isValid(active_listings_count) ? active_listings_count : '-'}
                                        {isValid(active_listings_count) && (<HistoricalTrend
                                            value={active_listings_count_mm}
                                            size="small"
                                        />)}
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isValid(new_listings_count) ? new_listings_count : '-'}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <div className={classes.trendContainer}>
                                        {isValid(median_dom) ? median_dom : '-'}
                                        {isValid(median_dom) && (<HistoricalTrend
                                            value={median_dom_mm}
                                            size="small"
                                        />)}
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>);
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {fromMonth && toMonth && (
                <Box my={1}>
                    <HistoricalTrendLegend fromMonth={fromMonth} toMonth={toMonth} />
                </Box>
            )}
        </div>
    );
};
