
import { portalApi } from 'apis';
import { useQuery } from 'react-query';

export function usePEADStates() {
    return useQuery(
        ['pead-states'],
        portalApi.getPEADStates.bind(portalApi),
        {
            staleTime: Infinity,
            refetchInterval: 60000,
            forceFetchOnMount: true,
        }
    );
}
