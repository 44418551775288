import Logger from 'js-logger';
import React, { createContext } from 'react';


export const AgentContext = createContext();

export function AgentProvider({ agent, ...props }) {
    return (
        <AgentContext.Provider
            {...props}
            value={agent}
        />
    );
}
