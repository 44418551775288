import React from 'react';
import { AppBar, Tab, Tabs, ThemeProvider, useTheme } from '@material-ui/core';
import { MuiPrimaryBlackTheme } from 'theme';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import analytics from 'services/analytics';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';

export const TAB_ESTIMATED_HOME_VALUE = 'estimated_home_value';
export const TAB_NEIGHBORHOOD_DATA = 'neighborhood_data';
export const TAB_NEARBY_PROPERTIES = 'nearby_properties';
export const TAB_MY_AGENT = 'my_agent';
export const TAB_MY_SERVICE_PROVIDERS = 'my_service_providers';
export const TAB_FEATURED_PROPERTY_PACKS = 'featured_property_packs';

export function NavBar({
    selectedTab = TAB_ESTIMATED_HOME_VALUE,
    show = false,
    style = {},
    onTabChange = (e, selectedTab)=> {},
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
        isIPadProUp,
    } = useDeviceBreakpoints();
    const { isLoggedIn } = useIsLoggedIn();

    return (
        <AppBar
            color="transparent"
            elevation={0}
            style={{
                marginTop: isMobile ? 0 : (theme.spacing(8) - 1),
                overflow: 'hidden',
                ...style,
            }}
            {...props}
        >
            <div
                style={{
                    position: 'relative',
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.lr_colors.border_grey,
                    top: show ? 0 : -130,
                    transition: 'top 0.3s ease-out',
                    background: '#fff',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        maxWidth: 1144,
                        margin: '0 auto',
                    }}
                >
                    <ThemeProvider theme={MuiPrimaryBlackTheme}>
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={onTabChange}
                        >
                            <Tab
                                label={`${isIPadProUp ? 'Est. h' : 'H'}ome value`} 
                                value={TAB_ESTIMATED_HOME_VALUE} 
                                style={{ textTransform: 'none', color: selectedTab !== TAB_ESTIMATED_HOME_VALUE && theme.palette.lr_colors.steak_sauce, }}
                                onClick={analytics.eventTrackOnEvent('robin_home_report_est_home_value_tab_click')}
                            />
                            <Tab
                                label={`Neighborhood${isIPadProUp ? ' data' : ''}`} 
                                value={TAB_NEIGHBORHOOD_DATA} 
                                style={{ textTransform: 'none', color: selectedTab !== TAB_NEIGHBORHOOD_DATA && theme.palette.lr_colors.steak_sauce, }}
                                onClick={analytics.eventTrackOnEvent('robin_home_report_neighborhood_data_tab_click')}
                            />
                            <Tab
                                label={`Nearby ${isIPadProUp ? 'properties' : 'homes'}`} 
                                value={TAB_NEARBY_PROPERTIES} 
                                style={{ textTransform: 'none', color: selectedTab !== TAB_NEARBY_PROPERTIES && theme.palette.lr_colors.steak_sauce, }}
                                onClick={analytics.eventTrackOnEvent('robin_home_report_nearby_properties_tab_click')}
                            />
                            {isMobile && (
                                <Tab
                                    label="Featured properties" 
                                    value={TAB_FEATURED_PROPERTY_PACKS} 
                                    style={{ textTransform: 'none', color: selectedTab !== TAB_FEATURED_PROPERTY_PACKS && theme.palette.lr_colors.steak_sauce, }}
                                    onClick={analytics.eventTrackOnEvent('robin_home_report_featured_proeprty_packs_tab_click')}
                                />
                            )}
                            {isLoggedIn && isMobile && (
                                <Tab
                                    label="Service providers" 
                                    value={TAB_MY_SERVICE_PROVIDERS} 
                                    style={{ textTransform: 'none', color: selectedTab !== TAB_MY_SERVICE_PROVIDERS && theme.palette.lr_colors.steak_sauce, }}
                                    onClick={analytics.eventTrackOnEvent('robin_home_report_service_providers_tab_click')}
                                />
                            )}
                            {/* {isMobile && (
                                <Tab
                                    label="My agent"
                                    value={TAB_MY_AGENT}
                                    style={{ textTransform: 'none', }}
                                    onClick={analytics.eventTrackOnEvent('robin_home_report_my_agent_tab_click')}
                                />
                            )} */}
                        </Tabs>
                    </ThemeProvider>
                </div>
            </div>
        </AppBar>
    );
}
