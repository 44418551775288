import React from 'react';
import { Typography, Box } from '@material-ui/core';
import addCommas from '../../../services/add-commas';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../mobx-store';
import { COLOR_GREY_DARK } from '../../../constants';

function Overview(props) {
    const { MortgageCalculatorStore } = useStore();

    return (
        <div {...props}>
            <Box fontSize={48} component="h2" lineHeight={1} align="center">
                ${addCommas(MortgageCalculatorStore.formattedMonthlyPayment)}
                <Box display="inline" color={COLOR_GREY_DARK} ml={3 / 8} fontSize={16}>
                    /mo
                </Box>
            </Box>
            <Typography align="center">
                {MortgageCalculatorStore.formattedLoanType}, {parseFloat(MortgageCalculatorStore.formattedInterestRate)}
                % interest
            </Typography>
        </div>
    );
}

export default React.memo(observer(Overview));
