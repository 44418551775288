import { AssignmentIndRounded, BuildRounded, LocalFloristRounded, PersonRounded, PoolRounded } from '@material-ui/icons';
import ConstructionIcon from 'components/LRIcons/construction';
import { MY_HOME_SERVICE_PROVIDER_CONTRACTOR, MY_HOME_SERVICE_PROVIDER_ELECTRICIAN, MY_HOME_SERVICE_PROVIDER_HANDYPERSON, MY_HOME_SERVICE_PROVIDER_HOUSEKEEPER, MY_HOME_SERVICE_PROVIDER_HVAC, MY_HOME_SERVICE_PROVIDER_LANDSCAPER, MY_HOME_SERVICE_PROVIDER_OTHER, MY_HOME_SERVICE_PROVIDER_PLUMBER, MY_HOME_SERVICE_PROVIDER_POOL_SPA, MY_HOME_SERVICE_PROVIDER_ROOFING, MY_HOME_SERVICE_PROVIDER_WINDOWS_DOORS } from '../../../constants';
import React from 'react';
import PlumberIcon from '../../../components/LRIcons/plumber';
import ElectricityIcon from 'components/LRIcons/electricity';
import HvacIcon from 'components/LRIcons/hvac';
import RoofingIcon from 'components/LRIcons/roofing';
import WindowIcon from 'components/LRIcons/window';
import CleaningServicesIcon from 'components/LRIcons/cleaning-services';

export function ServiceProvidersIcon({
    variant = 'default',
    ...props
}) {
    switch(variant) {
        case MY_HOME_SERVICE_PROVIDER_CONTRACTOR:
            return <ConstructionIcon {...props} />;
        case MY_HOME_SERVICE_PROVIDER_ELECTRICIAN:
            return <ElectricityIcon {...props} />;
        case MY_HOME_SERVICE_PROVIDER_HANDYPERSON:
            return <BuildRounded {...props} />;
        case MY_HOME_SERVICE_PROVIDER_HOUSEKEEPER:
            return <CleaningServicesIcon {...props} />;
        case MY_HOME_SERVICE_PROVIDER_HVAC:
            return <HvacIcon {...props} />;
        case MY_HOME_SERVICE_PROVIDER_LANDSCAPER:
            return <LocalFloristRounded {...props} />;
        case MY_HOME_SERVICE_PROVIDER_OTHER:
            return <PersonRounded {...props} />;
        case MY_HOME_SERVICE_PROVIDER_WINDOWS_DOORS:
            return <WindowIcon {...props} />;
        case MY_HOME_SERVICE_PROVIDER_ROOFING:
            return <RoofingIcon {...props} />;
        case MY_HOME_SERVICE_PROVIDER_POOL_SPA:
            return <PoolRounded {...props} />;
        case MY_HOME_SERVICE_PROVIDER_PLUMBER:
            return <PlumberIcon {...props} />;
        default:
            return <AssignmentIndRounded {...props} />;
    };
}
