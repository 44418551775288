import { babouApi, portalApi } from 'apis';
import { transformAreasForSearch } from 'apis/babou';
import Logger from 'js-logger';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import { CACHE_KEY } from './constants';

export function useMyHomeStats(areaId) {

    const areaQuery = useQuery(
        [`${CACHE_KEY}-area-id`, areaId], 
        async ({ queryKey: [, areaId] })=> await portalApi.getArea(areaId),
    );

    const buysideStatsQuery = useQuery(
        [`${CACHE_KEY}-buyer-stats`, areaId, areaQuery?.data?.type],
        async ()=> babouApi.getSoldStats(
            transformAreasForSearch([areaQuery?.data])
        ),
        {
            enabled: Boolean(areaId) && areaQuery?.isSuccess,
            select: (data)=> {
                return {
                    total_sales_last_30_days: get(data, 'thirty_days.sales', 0),
                    median_price_last_30_days: get(data, 'thirty_days.median_price', 0),
                    total_sales_last_12_months: get(data, 'one_year.sales', 0),
                    median_price_last_12_months: get(data, 'one_year.median_price', 0),
                };
            },
        },
    );

    const listingStatsQuery = useQuery(
        [`${CACHE_KEY}-listing-stats`, areaId, areaQuery?.data?.type],
        ()=> babouApi.getListingStats(
            transformAreasForSearch([areaQuery?.data])
        ),
        {
            enabled: Boolean(areaId) && areaQuery?.isSuccess,
            select: (data)=> {
                return {
                    average_neighborhood_value: get(data, 'median_price', 0),
                    average_days_on_market: get(data, 'median_dom', 0),
                    active_listings: get(data, 'counts.active_listings', 0),
                };
            },
        },
    );

    return {
        buysideStatsQuery: buysideStatsQuery,
        listingStatsQuery: listingStatsQuery,
        isLoading: buysideStatsQuery.isLoading || listingStatsQuery.isLoading,
        isSuccess: buysideStatsQuery.isSuccess && listingStatsQuery.isSuccess,
        isError: buysideStatsQuery.isError && listingStatsQuery.isError,
        buyside: buysideStatsQuery.data,
        listing: listingStatsQuery.data,
    };
}
