import React, { useRef, useContext } from 'react';
import { useStore } from '../../mobx-store';
import { decorate, observable, computed, action, flow, toJS } from 'mobx';
import _ from 'lodash';

class CompareNowStore {
    sort = { id: 'listing_details.price', label: 'Price', order: 'desc' };
    lockedCardsCollection = observable(new Set());
    windowSize = 4;
    offset = 0;
    filter = '0';
    loadedListings = false;
    loadingListings = false;
    categories = [];
    cardsByCategories = {};

    constructor(windowSize, rootStore, categories, cardsByCategories, alwaysLockedCards = [], lockCards = [], filter = '0') {
        this.windowSize = windowSize;
        this.rootStore = rootStore;
        this.categories = categories;
        this.cardsByCategories = cardsByCategories;
        this.alwaysLockedCards = alwaysLockedCards;
        this.filter = filter;
        _.each(lockCards, this.lockCard.bind(this));
    }

    get activeBoard() {
        return this.rootStore.boardsStore.activeBoard;
    }

    get cards() {
        return _.flatten(_.values(this.cardsByCategories));
    }

    get compareabledBoardCards() {
        const orderedListings =
            this.filter === '0'
                ? _.orderBy(this.cards, this.sort.id, this.sort.order)
                : _.orderBy(this.cardsByCategories[this.filter], this.sort.id, this.sort.order);
        return _.orderBy(
            orderedListings,
            //if _.get(listing, this.sort.id, '') returns null, the final value is going to be null and not the defaultValue,
            //the defaultValue is only returned when the result is undefined.
            (listing)=> _.get(listing, this.sort.id, '') || '',
            ['desc']
        );
    }

    get allComparableBoardCards() {
        const orderedListings = _.orderBy(this.cards, this.sort.id, this.sort.order);
        return _.orderBy(
            orderedListings,
            (listing)=> _.get(listing, this.sort.id, '') || '',
            ['desc']
        );
    }

    get unlockedCards() {
        return this.compareabledBoardCards.filter((card)=> !this.lockedCardsCollection.has(card.listing_details.id));
    }

    get lockedCards() {
        return this.allComparableBoardCards.filter((card)=> this.lockedCardsCollection.has(card.listing_details.id));
    }

    get lockedWindowSize() {
        return this.lockedCardsCollection.size + this.alwaysLockedCards.length;
    }

    get unlockedWindowSize() {
        return Math.min(this.windowSize - this.lockedWindowSize, this.unlockedCards.length);
    }

    get maxLocksAllowed() {
        return this.windowSize <= 2 ? this.windowSize : this.windowSize - 1;
    }

    get unlockedBoardCardsWindow() {
        const totalUnlockedCards = this.unlockedCards.length;
        const limit = this.unlockedWindowSize;
        const endIndex = Math.min(totalUnlockedCards, this.offset + limit);
        const firstSlice = this.unlockedCards.slice(this.offset, endIndex);

        if(firstSlice.length < limit && firstSlice.length < totalUnlockedCards) {
            let secondEndIndex = limit - firstSlice.length;
            const secondSlice = this.unlockedCards.slice(0, secondEndIndex);
            return firstSlice.concat(secondSlice);
        }
        return firstSlice;
    }

    get boardCardsWindow() {
        return this.alwaysLockedCards.concat(this.lockedCards, this.unlockedBoardCardsWindow);
    }

    isCardInAlwaysLocked(card) {
        return _.some(this.alwaysLockedCards, ['listing_details.id', card.listing_details.id]);
    }

    isCardLocked(card) {
        return this.lockedCardsCollection.has(card.listing_details.id) || this.isCardInAlwaysLocked(card);
    }

    reset() {
        this.offset = 0;
    }

    changeWindowSize(size) {
        if(size < this.windowSize && this.lockedCardsCollection.size > size - 1) {
            const lastCardId = Array.from(this.lockedCardsCollection)[this.lockedCardsCollection.size-1];
            this.lockedCardsCollection.delete(lastCardId);
            const currentCardIndex = this.unlockedCards.findIndex((c)=> c.listing_details.id === lastCardId);
            this.offset = currentCardIndex;
        }
        this.windowSize = size;
    }

    // User click right arrow
    rotateRight() {
        const totalUnlockedCards = this.unlockedCards.length;
        if(this.offset === totalUnlockedCards - 1) {
            this.offset = 0;
        } else {
            this.offset = this.offset + 1;
        }
    }

    // User click left arrow
    rotateLeft() {
        if(this.offset === 0) {
            this.offset = this.unlockedCards.length - 1;
        } else {
            this.offset = this.offset - 1;
        }
    }

    toggleCardLock(card) {
        if(this.isCardInAlwaysLocked(card)) return;
        if(this.lockedCardsCollection.has(card.listing_details.id)) {
            this.unlockCard(card);
        } else {
            this.lockCard(card);
        }
    }

    lockCard(card) {
        if(this.lockedCardsCollection.has(card.listing_details.id)) {
            return;
        }
        if(this.lockedWindowSize >= this.maxLocksAllowed) {
            return;
        }

        const nextOffsetCard = this.unlockedBoardCardsWindow.find((c)=> c.listing_details.id !== card.listing_details.id);
        this.lockedCardsCollection.add(card.listing_details.id);
        const newOffset = this.unlockedCards.findIndex((c)=> c.listing_details.id === nextOffsetCard.listing_details.id);
        this.offset = newOffset;
    }

    unlockCard(card) {
        if(!this.lockedCardsCollection.has(card.listing_details.id)) {
            return;
        }
        this.lockedCardsCollection.delete(card.listing_details.id);

        const currentCardIndex = this.unlockedCards.findIndex((c)=> c.listing_details.id === card.listing_details.id);
        this.offset = currentCardIndex;
    }

    changeSort(newSort) {
        this.reset();
        if(this.sort.id === newSort.id) {
            const _selectedSort = {id: this.sort.id, label: this.sort.label, order: this.sort.order === 'desc' ? 'asc' : 'desc' };
            this.sort = _selectedSort;
        } else {
            const _selectedSort = {id: newSort.id, label: newSort.label, order: 'desc' };
            this.sort = _selectedSort;
        }
    }

    changeFilter(newFilter) {
        if(this.windowSize > 2 || newFilter === '0' || this.cardsByCategories[newFilter].length + this.alwaysLockedCards.length >= 1) {
            this.reset();
            this.filter = newFilter;
        }
    }

    getLanecardsCount(laneId) {
        return this.activeBoard.boardCards.filter((card)=> card.laneId === laneId).length;
    }
}

decorate(CompareNowStore, {
    // Observables
    sort: observable,
    filter: observable,
    windowSize: observable,
    offset: observable,
    loadedListings: observable,
    loadingListings: observable,
    categories: observable,
    cardsByCategories: observable,
    alwaysLockedCards: observable,

    // Computed
    activeBoard: computed,
    cards: computed,
    compareabledBoardCards: computed,
    allComparableBoardCards: computed,
    lockedCards: computed,
    unlockedCards: computed,
    unlockedWindowSize: computed,
    boardCardsWindow: computed,
    maxLocksAllowed: computed,

    // Actions
    reset: action,
    changeWindowSize: action,
    rotateRight: action,
    rotateLeft: action,
    toggleCardLock: action,
    lockCard: action,
    unlockCard: action,
    changeFilter: action,
    changeSort: action,
    getLanecardsCount: action,
});

const CompareNowContext = React.createContext();

export function useCompareNowStore(windowSize = 4, categories, cardsByCategories, alwaysLockedCards, lockCards, filter) {
    const rootStore = useStore();
    const storeRef = useRef(new CompareNowStore(windowSize, rootStore, categories, cardsByCategories, alwaysLockedCards, lockCards, filter));
    return storeRef.current;
}

export function CompareNowStoreProvider({ compareNowStore, children }) {
    return <CompareNowContext.Provider value={compareNowStore}>{children}</CompareNowContext.Provider>;
}

export function useCompareNowStoreContext() {
    return useContext(CompareNowContext);
}
