import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog, DialogContent, Hidden, IconButton } from '@material-ui/core';
import { CardBoardCollaborations } from 'components/CardBoardCollaborations';
import { Listing } from 'components/Listing';
import { useResponsive } from 'hooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useActiveBoard, useStore } from 'mobx-store';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import PropertyDetailLayout from './PropertyDetailLayout';
import SubmitOfferModal from 'scenes/SubmitOfferModal';
import SchedulePrivateTourModal from 'components/SchedulePrivateTourModal';
import queryString from 'query-string';
import analytics from 'services/analytics';
import { useBoardCard } from 'hooks/data/board-card/useBoardCard';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import { useListingMLS } from 'hooks/data/listing/useListingMLS';
import { Close } from '@material-ui/icons';
import { useOnboarding } from 'components/Onboarding';
import { ListpackListingView } from 'components/ListpackListingView';
import { useAgent } from 'hooks/useAgent';
import { useLoanOfficer } from 'hooks/useLoanOfficer';
import Logger from 'js-logger';
// import { ListpackListingView } from 'scenes/Board/ListpackListingView';

const ModalLayout = observer(function ModalLayout({ card, open, onClose, children, listings }) {
    const history = useHistory();
    const match = useRouteMatch();
    const r = useResponsive();
    const activeBoard = useActiveBoard();
    const onboarding = useOnboarding();

    return (
        <>
            <Dialog
                fullWidth
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        maxWidth: r({ xs: '768px', lg: '1128px' }),
                        maxHeight: '100%',
                        height: '100%',
                        width: '100%',
                        margin: 0,
                        overflowY: 'unset'
                    },
                }}
            >
                <DialogContent
                    style={{
                        padding: 0,
                        overflow: 'hidden',
                        flex: '1 1 0'
                    }}
                >
                    {children}
                </DialogContent>
                <Hidden smDown>
                    <IconButton
                        onClick={()=> {
                            onClose();
                            if(onboarding?.isAddNoteOnboarding) {
                                onboarding.next();
                            }
                        }}
                        style={{
                            position: 'absolute',
                            left: 'calc(100% + 8px)',
                            top: '8px',
                            color: 'white'
                        }}
                    >
                        <Close></Close>
                    </IconButton>
                </Hidden>
            </Dialog>
            {/* Necessary condition to be able to render the SubmitOfferModal */}
            {activeBoard && activeBoard.loadedBoardData && (
                <Route
                    path={`${match?.url}/submit-offer/:boardCardId`}
                    render={()=> <SubmitOfferModal onClose={()=> history.push(match.url)} />}
                />
            )}
        </>
    );
});

function usePageSource() {
    const location = useLocation();
    const queryStrings = queryString.parse(location.search);
    const { state: locationState = {} } = location || {};
    const { trackSource } = locationState;
    return queryStrings.source ? queryStrings.source : trackSource || 'consumer_view';
}

function useTrackPageView(eventData, ready) {
    const trackedRef = useRef(false);
    useEffect(()=> {
        if(ready && !trackedRef.current) {
            analytics.eventTrack('robin_board_listpack_page_viewed');
            trackedRef.current = true;
        }
    }, [ready, eventData]);
}

function useTrackEventData({ card, listing }) {
    const source = usePageSource();

    return useMemo(()=> {
        return {
            address: listing?.address,
            'listing id': listing?.id,
            source: source,
            card_id: card?.id,
        };
    }, [listing, card, source]);
}

export const openedFrom = {
    pinspiration: 'pinspiration',
};

export const BoardCardModal = observer(function BoardCardModal({ open, onClose, from, requestSource="column", listings }) {
    const activeBoard = useActiveBoard();
    const { UserStore } = useStore();
    const { affiliatedMLS } = UserStore;
    const history = useHistory();
    const { cardId } = useParams();
    const [showPrivateTourModal, setShowPrivateTourModal] = useState(false);
    const [sharedTracked, setSharedTracked] = useState(false);
    const { data: card } = useBoardCard(cardId);
    const { data: listing } = useBoardCardListing(card);
    const { data: listingMls } = useListingMLS(listing);
    const showAgentBranding = useMemo(()=> {
        if(!listing || !listingMls) return false;

        const mls = _.get(listingMls, '[0]', null);
        if(listing.listing_source === 'listhub' || (mls && !mls.idx_rules_enforced)) return true;

        if(!mls) return false;

        return affiliatedMLS.includes(mls.source_code_name);
    }, [listing, listingMls, affiliatedMLS]);
    const augmentedCard =
        card && activeBoard && activeBoard.loadedBoardData ? activeBoard.boardCardsById[card.id] : null;
    const { data:buyerAgent } = useAgent();
    const loanOfficer = useLoanOfficer(buyerAgent);
    const hideNavigationFrom = [openedFrom.pinspiration];
    const showNavigation = !hideNavigationFrom.includes(from);
    const agent =  useMemo(()=> {
        if(showAgentBranding && buyerAgent) {
            return buyerAgent;
        }

        return null;
    }, [showAgentBranding, buyerAgent]);
    const trackEventData = useTrackEventData({ card, listing });
    const handleEditListingName = useCallback(
        function handleEditListingName() {
            if(!card) {
                return null;
            }
            history.push(`/boards/name-home/${card.id}?source=rpp`);
        },
        [history, card]
    );

    function onPrivateTourClick(source) {
        history.push({
            pathname: '/boards/schedule-a-tour',
            state: {
                previousPath: history.location.pathname,
                boardCardId: card.id,
                listingId: listing.id,
                source: 'rpp'
            },
        });

        analytics.eventTrack('listpacks_consumer_listingpage_private_tour_click', {
            card_id: card.id,
            listingId: listing.id,
            Source: source,
        });
    }


    useTrackPageView(trackEventData, card && listing);
    useEffect(()=> {
        if(activeBoard && card) {
            activeBoard.incrementBoardMetric('listings_viewed');
        }
    }, [activeBoard, card]);

    useEffect(()=> {
        document.referrer.includes(window.location.host);
        if(
            !card ||
            !UserStore.user ||
            (document.referrer !== '' && document.referrer.includes(window.location.host)) ||
            sharedTracked
        ) { return; }

        analytics.eventTrack('robin_shared_listing_viewed', {
            card_id: cardId,
            listingId: card.listingId || card.content.encoded_id,
            logged_out: 'no'
        });
        setSharedTracked(true);
    }, [cardId, card, sharedTracked, UserStore, UserStore.user]);

    if(card && card.listingId) {
        return (
            <ListpackListingView
                open={true}
                onClose={onClose}
                listpack={{}}
                listingIdParam={card.listingId}
            />
        );
    }

    return (
        <ModalLayout card={card} open={open} onClose={onClose}>
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <PropertyDetailLayout card={card} listing={listing} onClose={onClose} trackEventData={trackEventData} showNavigation={showNavigation} requestSource={requestSource} listings={listings}>
                        <Listing
                            listingName={card?.name}
                            onEditListingName={handleEditListingName}
                            listing={listing || null}
                            card={card}
                            buyerAgent={agent}
                            loanOfficer={loanOfficer}
                            onSchedulePrivateTour={onPrivateTourClick}
                            trackEventData={trackEventData}
                            requestSource={requestSource}
                        />
                    </PropertyDetailLayout>
                </div>
                <Hidden mdDown>
                    <div
                        style={{
                            width: '360px',
                            height: '100%',
                        }}
                    >
                        {card && <CardBoardCollaborations cardId={card.id} />}
                    </div>
                </Hidden>
            </div>
            {augmentedCard && (
                <SchedulePrivateTourModal
                    open={showPrivateTourModal}
                    onClose={()=> setShowPrivateTourModal(false)}
                    card={augmentedCard}
                    agent={buyerAgent}
                />
            )}
        </ModalLayout>
    );
});
