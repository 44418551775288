import { MenuItem, Popover, ThemeProvider } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import LRButton from 'components/LRButton';
import { partial } from 'lodash';
import React, { useState } from 'react';
import { MuiCalendarBlackTheme } from 'theme';

export function LRTimeOfDaySelect({
    value,
    timeOfDayOptions = ['Morning', 'Afternoon', 'Evening'],
    theme = MuiCalendarBlackTheme,
    fullWidth = false,
    minWidth = 184,
    onChange = (timeOfDay)=> {},
    ...props
}) {
    const [buttonRef, setButtonRef] = useState();
    const [openPopover, setOpenPopover] = useState(false);

    // Functions
    
    function onTimeOfDaySelect(e, time) {
        onChange(time);
        setOpenPopover(false);
    }

    return (
        <div {...props}>
            <ThemeProvider theme={theme}>
                <LRButton 
                    fullWidth={fullWidth}
                    variant={value ? 'contained' : 'outlined'} 
                    color={value ? 'primary' : 'default'}
                    ref={setButtonRef} 
                    endIcon={<ExpandMoreRounded />}
                    onClick={()=> (setOpenPopover(true))}
                    style={{
                        justifyContent: 'space-between',
                        backgroundColor: !value && '#fff',
                        paddingTop: 7,
                        paddingBottom: 7,
                    }}
                >
                    <div 
                        style={{
                            minWidth: Math.max(minWidth - 56, 0),
                            textAlign: 'left',
                        }}
                    >
                        {value || 'Select time'}
                    </div>
                </LRButton>
            </ThemeProvider>

            <Popover
                open={openPopover}
                anchorEl={buttonRef}
                onClose={()=> {
                    setOpenPopover(false);
                }}
                style={{
                    maxHeight: 211,
                }}
            >
                <ul>
                    {timeOfDayOptions.map((timeOfDay)=> (
                        <MenuItem key={timeOfDay} onClick={partial(onTimeOfDaySelect, partial.placeholder, timeOfDay)} style={{ minWidth: 184 }}>
                            {timeOfDay}
                        </MenuItem>
                    ))}
                </ul>
            </Popover>
        </div>
    );
}
