import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { StyledDefaultBadge, StyledTour3DIconBadge, StyledBadge } from './style';
import { COLOR_BLACK, LANES_NAMES, PENDING_STATUSES, SOLD_STATUSES } from '../../../constants';
import { Box, Typography} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@material-ui/styles';

export const StatusText = ({ listing })=> {
    const theme = useTheme();
    const status = listing?.listing_status?.toLowerCase();
    const isSold = SOLD_STATUSES.includes(status);
    const isPending = PENDING_STATUSES.includes(status);
    return (
        <>
            {isSold && <Typography variant='body2' style={{color: theme.palette.lr_colors.red}}>Sold</Typography>}
            {isPending && <Typography variant='body2' style={{color: theme.palette.lr_colors.red}}>Pending</Typography>}
        </>
    );
};

export const Badge = ({ status, isPending })=> {
    const isSold = ['canceled', 'cancelled', 'closed', 'off-market', 'sold'].includes(status.toLowerCase());
    return (
        <>
            {(isPending || isSold) && (
                <Box pl={3} pt={3} width="fit-content">
                    <StyledBadge>
                        <Box>{isPending ? 'Pending' : isSold ? 'Sold' : ''}</Box>
                    </StyledBadge>
                </Box>
            )}
        </>
    );
};

const DefaultStatusBadge = ({shouldDisplay, children})=> {
    return (
        <StyledDefaultBadge display={shouldDisplay ? "inline-flex" : 'none'}>
            {children}
        </StyledDefaultBadge>
    );
};

function getBadgesToDisplay(badgesToDisplay, maxNumberBadges) {
    let badges = {};
    Object.keys(badgesToDisplay).forEach((badge)=> {
        if(badgesToDisplay[badge] && Object.keys(badges).length < maxNumberBadges) {
            badges[badge] = true;
        }
    });

    return badges;
}

export const StatusBadge = observer(({
    isRpp,
    listingDetails,
    isNew,
    laneIdBadge,
    isListpackCard
})=> {
    const fontWeight = "500";
    const maxNumberBadges = 2;

    // Pending or sold badge
    const isPending = PENDING_STATUSES.includes(
        listingDetails?.listing_status?.toLowerCase()
    );

    const isSold = SOLD_STATUSES.includes(
        listingDetails?.listing_status?.toLowerCase()
    );

    // Lane badge
    const laneBadgesTexts = [LANES_NAMES['interested'], LANES_NAMES['see'], LANES_NAMES['buy']];
    const laneBadge = isListpackCard ? laneBadgesTexts[laneIdBadge - 1] : false;

    // 3DTour badge
    const has3DTour = listingDetails?.virtual_tours?.length > 0;
    const has3DTourText = "3D Tour";

    if(!isPending && !isSold && !isNew && !laneBadge && !has3DTour){
        return <></>;
    }

    // All the badges that are possible to be displayed
    let badgesToDisplay = {
        newBadge: isNew,
        pendingBadge: isPending,
        soldBadge: isSold,
        laneBadge: laneBadge,
        tour3dBadge: has3DTour,
    };

    // The badges we are actually going to display in consideration with the badge number limit
    badgesToDisplay = getBadgesToDisplay(badgesToDisplay, maxNumberBadges);
    const theme = useTheme();
    return (
        <Box display="flex" justifyContent="center" alignItems="center" mt={isRpp ? '18px' : []} ml={isRpp ? [3, 4] : []}>
            <DefaultStatusBadge shouldDisplay={badgesToDisplay.newBadge}>
                <Box component="span" fontWeight={fontWeight}>
                    <span role="img" aria-label="party">🎉</span> New
                </Box>
            </DefaultStatusBadge>

            <DefaultStatusBadge shouldDisplay={badgesToDisplay.pendingBadge}>
                <Box component="span" fontWeight={fontWeight}>Pending</Box>
            </DefaultStatusBadge>

            <DefaultStatusBadge shouldDisplay={badgesToDisplay.soldBadge}>
                <Typography
                    variant="body2"
                    style={{
                        fontWeight: fontWeight,
                        color: theme.palette.lr_colors.red,
                        letterSpacing: '0.55px',
                        fontStyle: 'normal',
                    }}
                >
                    Sold
                </Typography>
            </DefaultStatusBadge>

            <DefaultStatusBadge shouldDisplay={badgesToDisplay.laneBadge}>
                <Box display="flex">
                    <CheckIcon fontSize="small"/>
                </Box>
                <Box fontWeight={fontWeight}>
                    {laneBadge}
                </Box>
            </DefaultStatusBadge>

            <DefaultStatusBadge shouldDisplay={badgesToDisplay.tour3dBadge}>
                <Box display="flex" mr={1}>
                    <StyledTour3DIconBadge fill={COLOR_BLACK}/>
                </Box>
                <Box fontWeight={fontWeight}>
                    {has3DTourText}
                </Box>
            </DefaultStatusBadge>
        </Box>
    );
});
