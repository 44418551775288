import React from 'react';
import { CollaboratorsList } from './CollaboratorsList';
import { InviteCollaborator } from './InviteCollaborator';
import {useAccountSettingsStoreContext} from '../store';
import { observer } from 'mobx-react-lite';

export const Cobuyers = observer(function Cobuyers(props, ref){
    const { cobuyerStore } = useAccountSettingsStoreContext();

    return (
        <>
            {cobuyerStore.isListState && <CollaboratorsList  />}
            {cobuyerStore.isInviteState && <InviteCollaborator ref={ref}/>}
        </>
    );
}, {forwardRef: true});
