import React from 'react';
import { Box, Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const LegalDisclaimer = ({ open = false, toggleRateModal, rate })=> {
    return (
        <Dialog fullScreen open={open} maxWidth={'sm'}>
            <Box zIndex="1" boxShadow={'0px 3px 12px -2px rgba(0, 0, 0, 0.2)'} bgcolor="white">
                <Box position="relative" px={4} py={2} display="flex" justifyContent="space-between">
                    <Box></Box>
                    <Box>Legal disclaimer</Box>
                    <Box onClick={toggleRateModal} style={{ cursor: 'pointer' }}>
                        <Close />
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                flex="1"
                p={4}
                color="#A1A1A1"
                borderTop="1px solid #DDDDDD"
                fontSize="12px"
            >
                <Box>
                    Results are based on public information where available or otherwise reflect current interest rates
                    from Freddie Mac. By using this tool, you acknowledge the following: ListReports strives to keep its
                    information accurate and up to date but cannot guarantee the accuracy or completeness of this
                    information. Results from this tool are designed for comparative and illustrative purposes only.
                    ListReports is not a lender; this is not an advertisement to extend consumer credit. Qualification
                    for loan programs will depend largely on your credit score, income information, cash reserves, and
                    other factors. Equal Housing Opportunity.
                </Box>

                <Box mt={3} textAlign="center">
                    <img src="/images/equal-housing-logo.png" />
                </Box>
            </Box>
        </Dialog>
    );
};

export default LegalDisclaimer;
