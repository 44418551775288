import { portalApi } from 'apis';
import { useQuery } from 'react-query';
const CACHE_KEY = 'talking-points';

export const useTalkingPoints = ({ userType, slug, date })=> {
    const talkingPointsQuery = useQuery(
        [CACHE_KEY, slug, date],
        async ({ queryKey: [, slug, date] })=> await portalApi.getTalkingPoints(userType, { slug, date }),
        {
            enabled: Boolean(userType),
        }
    );
    return talkingPointsQuery;
};