import React, { useState, useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { IMG_HOME_REPORT_FEATURES, IMG_HOME_REPORT_WORTH_2, IMG_HOME_REPORT_NEARBY_PROPERTIES_2 } from '../../constants';

export function useHomeReportSlides() {
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const theme = useTheme();
    const slideContents = [
        {
            photo:  IMG_HOME_REPORT_FEATURES,
            type:   'home-report-bullets-black',
            background: `${theme.palette.lr_colors.grey_light}`,
            variant: 'home-report'
        },
        {
            photo:  IMG_HOME_REPORT_WORTH_2,
            type:   'home-report-bullets-white',
            infoText: `Hello from the ${selectedPhotoIndex + 1} slide`,
            title: 'Know your worth',
            text: 'Watch your investment grow, understand changes in your home’s value and know exactly when to buy or sell.',
            background: `linear-gradient(${theme.palette.lr_colors.minty_fresh},${theme.palette.lr_colors.minty_fresh_dark})`,
            textColor: '#FFF',
            variant: 'home-report-white',
        },
        {
            photo:  IMG_HOME_REPORT_NEARBY_PROPERTIES_2,
            type:   'home-report-bullets-white',
            infoText: `Hello from the ${selectedPhotoIndex + 1} slide`,
            title: 'Compare your home',
            text: 'Get a snapshot of exactly what’s happening in your neighborhood delivered each month. You’ll see recently listed homes in your area, which properties are flying off the market, and how your home stacks up. ',
            background: `linear-gradient(${theme.palette.lr_colors.blue_link},${theme.palette.lr_colors.blue_link_dark})`,
            textColor: '#FFF',
            variant: 'home-report-white',
        },
    ];

    const currentSlide = useMemo(()=> {
        return slideContents[selectedPhotoIndex];
    }, [selectedPhotoIndex, slideContents]);

    return {selectedPhotoIndex, setSelectedPhotoIndex, currentSlide, slideContents};
}
