import { Button, Dialog, DialogContent, Hidden, IconButton, Typography, useTheme, CircularProgress, AppBar, Toolbar, Grid } from '@material-ui/core';
import { ArrowBack, ArrowForward, ChevronLeftRounded, ChevronRightRounded, Close } from '@material-ui/icons';
import { Listing } from 'components/Listing';
import LRButton from 'components/LRButton';
import { LRSplitButton } from 'components/LRSplitButton';
import { useListingMLS } from 'hooks/data/listing/useListingMLS';
import { useResponsiveBreakpoint } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useActiveBoard, useStore } from 'mobx-store';
import React, { useState, useEffect, useMemo } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import SubmitOfferModal from 'scenes/SubmitOfferModal';
import { ContactAgentLeadCaptureButton } from 'components/LeadCaptureFormModal';
import { ListpackScheduleTourConfirmationModal } from 'components/ListpackScheduleTourConfirmationModal';
import { Can } from 'components/Ability';
import analytics from 'services/analytics';
import CopyToClipboard from 'components/CopyToClipboard';
import { ActionShareIcon } from 'components/LRIcons';
import { portalApi } from 'apis';
import _ from 'lodash';
import {
    LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL,
    LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS,
    LISTING_MODAL_NAV_VARIANT_STANDARD,
} from '../../constants';
import Logger from 'js-logger';
import { useLoanOfficer } from 'hooks/useLoanOfficer';
import { useMoveCardOrListing } from 'hooks/useMoveCardOrListing';
import { useAgent } from 'hooks/useAgent';
import { useQuery } from 'hooks';
import { MoveCardOrListingButton } from 'components/MoveCardOrListingButton';

const ModalLayout = observer(function ModalLayout({ open, onClose, children, onOpen, ...props }) {
    return (
        <>
            <Dialog
                fullWidth
                open={open}
                onClose={onClose}
                onEnter={onOpen}
                PaperProps={{
                    style: {
                        maxWidth: '768px',
                        maxHeight: '100%',
                        height: '100%',
                        width: '100%',
                        margin: 0,
                        overflowY: 'unset',
                    },
                }}
                {...props}
            >
                <DialogContent
                    className="tour-listpack-listing-detail"
                    style={{
                        padding: 0,
                        overflow: 'hidden',
                        flex: '1 1 0',
                    }}
                >
                    {children}
                </DialogContent>
                <Hidden smDown>
                    <IconButton
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            left: 'calc(100% + 8px)',
                            top: '8px',
                            color: 'white',
                        }}
                    >
                        <Close></Close>
                    </IconButton>
                </Hidden>
            </Dialog>
        </>
    );
});

function Header({
    variant = LISTING_MODAL_NAV_VARIANT_STANDARD, // standard or loggedout-listpack-search-modal
    listing,
    card,
    index,
    total,
    onNext,
    disableNext,
    onPrevious,
    disabledPrevious,
    onClose,
    showLoading = false,
    hidePagination=false,
    agent,
}) {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const [shortShareUrl, setShortShareUrl] = useState(null);
    const showContactAgentCTA = variant === LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS;

    useEffect(()=> {
        async function shortenShareUrl(shareUrl) {
            const shortenedUrl = await portalApi.shortenURL(shareUrl);
            setShortShareUrl(shortenedUrl.data);
        }

        shortenShareUrl(window.location.href);
    }, []);

    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={0}
            style={{
                borderBottom: '1px solid #DDDDDD',
                height: isMobile ? '56px' : '64px',
            }}
        >
            <Toolbar>
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                >
                    {/* iPadProUp */}
                    <Hidden smDown>
                        <Grid item>
                            <Can I="edit" a="BoardCard" passThrough={variant === LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL}>
                                <MoveCardOrListingButton
                                    boardCard={card}
                                    listing={listing}
                                />
                            </Can>

                            {showContactAgentCTA && (
                                <ContactAgentLeadCaptureButton
                                    agent={agent}
                                    trackSource="rpp"
                                    address={listing?.address}
                                />
                            )}
                        </Grid>

                        {/* Pagination */}
                        <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            spacing={3}
                            wrap="nowrap"
                            style={{
                                width: '100%',
                            }}
                        >
                            {total > 1 && index >= 0 && !showLoading && !hidePagination && (
                                <>
                                    <Grid item>
                                        <LRButton
                                            variant="outlined"
                                            color="default"
                                            disabled={disabledPrevious}
                                            onClick={onPrevious}
                                            style={{
                                                fontWeight: 'normal',
                                                paddingLeft: '12px',
                                                paddingRight: '16px',
                                                minWidth: 'unset',
                                                height: '36px',
                                            }}
                                        >
                                            <ChevronLeftRounded style={{fontSize: theme.typography.body1.fontSize}} />
                                            <Typography variant="body2" style={{marginLeft: theme.spacing(1)}}>Previous</Typography>
                                        </LRButton>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="caption">
                                            {index >= 0 ? index + 1 : '-'} of {total ? total : '-'}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <LRButton
                                            variant="outlined"
                                            color="default"
                                            disabled={disableNext}
                                            onClick={onNext}
                                            style={{
                                                fontWeight: 'normal',
                                                paddingLeft: '16px',
                                                paddingRight: '12px',
                                                minWidth: 'unset',
                                                height: '36px',
                                            }}
                                        >
                                            <Typography variant="body2" style={{marginRight: theme.spacing(1)}}>Next</Typography>
                                            <ChevronRightRounded style={{fontSize: theme.typography.body1.fontSize}} />
                                        </LRButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        {/* Share */}
                        {shortShareUrl ? (
                            <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                                <Grid
                                    item
                                    container
                                    justify="flex-end"
                                    alignItems="center"
                                    wrap="nowrap"
                                    style={{
                                        cursor: 'pointer',
                                        width: 'auto',
                                    }}
                                    onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                        listingId: listing.id,
                                        Source: 'logged_out',
                                    })}
                                >
                                    <Grid item>
                                        <IconButton
                                            disabled={false}
                                            style={{padding: theme.spacing(1)}}
                                        >
                                            <ActionShareIcon style={{fontSize: theme.typography.body1.fontSize}} />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <span style={{textDecoration: 'underline'}}>Share</span>
                                    </Grid>
                                </Grid>
                            </CopyToClipboard>
                        ) : (
                            <Grid item xs></Grid>
                        )}

                        {/* Loading */}
                        {showLoading && (
                            <Grid item style={{ height: 24, marginLeft: theme.spacing(2), }}>
                                <CircularProgress size={24} color="primary" />
                            </Grid>
                        )}
                    </Hidden>


                    {/* iPadDown */}
                    <Hidden mdUp>
                        {/* Share */}
                        {shortShareUrl ? (
                            <CopyToClipboard text={shortShareUrl} snackbarText="Link copied!">
                                <Grid
                                    item
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={()=> analytics.eventTrack('robin_header_share_button_click', {
                                        listingId: listing.id,
                                        Source: 'logged_out',
                                    })}
                                >
                                    <IconButton
                                        disabled={false}
                                        edge="start"
                                    >
                                        <ActionShareIcon/>
                                    </IconButton>
                                </Grid>
                            </CopyToClipboard>
                        ) : (
                            <Grid item xs></Grid>
                        )}

                        {/* Counts */}
                        <Grid item xs>
                            <div style={{ fontSize: theme.typography.caption.fontSize, textAlign: 'center', }}>
                                {index >= 0 && total ? `${index + 1} of ${total}` : ''}
                            </div>
                        </Grid>

                        {/* Close */}
                        <Grid item style={{ textAlign: 'right', }}>
                            <IconButton
                                edge="end"
                                onClick={onClose}
                            >
                                <Close></Close>
                            </IconButton>
                        </Grid>
                    </Hidden>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

function Footer({
    variant = LISTING_MODAL_NAV_VARIANT_STANDARD, // standard or loggedout-listpack-search-modal
    listing,
    card,
    onNext,
    disableNext,
    onPrevious,
    disabledPrevious,
    hidePagination,
    agent,
}) {
    const theme = useTheme();
    const showContactAgentCTA = variant === LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS;

    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={0}
            style={{
                borderTop: `1px solid ${theme.palette.lr_colors.border_grey}`,
                height: 64,
            }}
        >
            <Toolbar disableGutters>
                <Grid
                    container
                    alignItems="center"
                    style={{
                        height: 64,
                    }}
                >
                    {/* Previous Button */}
                    <Grid
                        item
                    >
                        {!hidePagination && (
                            <Button onClick={onPrevious} style={{ textTransform: 'none' }} disabled={disabledPrevious}>
                                <span style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <ArrowBack />
                                    <Typography variant="caption">Prev</Typography>
                                </span>
                            </Button>
                        )}
                    </Grid>

                    {/* Move card / listing button */}
                    <Grid
                        item
                        container
                        justify="center"
                        xs
                    >
                        <Grid item>
                            <Can I="edit" a="BoardCard" passThrough={variant === LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL}>
                                <MoveCardOrListingButton
                                    boardCard={card}
                                    listing={listing}
                                />
                            </Can>

                            {showContactAgentCTA && (
                                <ContactAgentLeadCaptureButton
                                    agent={agent}
                                    trackSource="rpp"
                                    address={listing?.address}
                                />
                            )}
                        </Grid>
                    </Grid>

                    {/* Next Button */}
                    <Grid
                        item
                    >
                        {!hidePagination && (
                            <Button onClick={onNext} style={{ textTransform: 'none' }} disabled={disableNext}>
                                <span style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <ArrowForward />
                                    <Typography variant="caption">Next</Typography>
                                </span>
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

const emptyArray = [];

const ListpackListingLayout = observer(function ListpackListingLayout({
    HeaderProps = {},
    FooterProps = {},
    listing,
    listings,
    card,
    listpack,
    onClose,
    children,
    totalOfListings,
    requestSource,
    isloadingMore= false,
    defaultListings= [],
    shouldLoadMore= false,
    hidePagination= false,
    onNextListingClick = ()=> {},
    onPreviousListingClick = ()=> {},
    buyerAgent,
}) {
    const listpackListings = listings || defaultListings;
    const totalListings = (listpack && listpack.activeListingsCount) ? listpack.activeListingsCount : totalOfListings || defaultListings.length;
    const availableListings = (listpack && listpackListings) ? listpackListings.length : totalOfListings || defaultListings.length;
    const listingIndex = useMemo(()=> {
        return listpackListings.findIndex((listpackListing)=> listing && listpackListing.id === listing.id);
    }, [listing, listpackListings]);
    const canGetNext = listingIndex < availableListings - 1;
    const disableNext = !canGetNext || listingIndex < 0 || !totalListings || listingIndex === totalListings - 1;
    const disabledPrevious = listingIndex < 0 || listingIndex === 0 || !totalListings;
    const queryParamListpackId = useQuery().get('listpack_id');
    const queryParamSlug = useQuery().get('slug');
    const distinctIdFallback = [
        LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL,
        LISTING_MODAL_NAV_VARIANT_LOGGEDOUT_LISTPACK_SEARCH_MODAL_NO_ROBIN_ACCESS,
    ].includes(HeaderProps.variant) ? buyerAgent?.id : undefined;

    // Functions

    function handlePreviousClick() {
        const previousListing = listpackListings[listingIndex - 1];
        analytics.eventTrack('rpp_previous_button_click', {
            card_id: previousListing.id,
        });
        analytics.eventTrack('listpacks_consumer_listing_page_viewed', {
            address: previousListing?.address,
            'listing id': previousListing?.id,
            listing_id: card?.content?.listing_id,
            listpack_id: listpack?.id,
            source: requestSource,
            type: queryParamListpackId,
            slug: queryParamSlug,
            distinct_id: distinctIdFallback,
        });

        onPreviousListingClick();
    }

    async function handleNextClick() {
        const nextListing = listpackListings[listingIndex + 1];
        analytics.eventTrack('rpp_next_button_click', {
            card_id: nextListing.id,
        });
        analytics.eventTrack('listpacks_consumer_listing_page_viewed', {
            address: nextListing?.address,
            'listing id': nextListing?.id,
            listing_id: card?.content?.listing_id,
            listpack_id: listpack?.id,
            source: requestSource,
            type: queryParamListpackId,
            slug: queryParamSlug,
            distinct_id: distinctIdFallback,
        });

        if(nextListing){
            onNextListingClick();
        }

    }

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header
                {...HeaderProps}
                listing={listing}
                card={card}
                index={listingIndex}
                total={totalListings}
                available={availableListings}
                onNext={handleNextClick}
                disableNext={disableNext}
                onPrevious={handlePreviousClick}
                disabledPrevious={disabledPrevious}
                onClose={onClose}
                hidePagination={hidePagination}
                showLoading={(listingIndex < 0 && isloadingMore && shouldLoadMore) || (listingIndex  > listpackListings.length - 2 && isloadingMore && shouldLoadMore)}
            />
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                {children}
            </div>
            <Hidden mdUp>
                <Footer
                    {...FooterProps}
                    listing={listing}
                    card={card}
                    index={listingIndex}
                    total={totalListings}
                    onNext={handleNextClick}
                    disableNext={disableNext}
                    onPrevious={handlePreviousClick}
                    disabledPrevious={disabledPrevious}
                    hidePagination={hidePagination}
                />
            </Hidden>
        </div>
    );
});

export const ListpackListingView = observer(function ListpackListingView({
    open,
    listpack,
    listing,
    listings,
    totalOfListings,
    isloadingMore = false,
    canLoadMore = false,
    hidePagination = false,
    readOnly= false,
    sourceRequest = 'newlisting_savedsearch_email',
    HeaderProps = {},
    FooterProps = {},
    ListingProps = {},
    onNextListingClick = ()=> {},
    onPreviousListingClick = ()=> {},
    onCloseClick = (e)=> {},
    ...props
}) {
    const match = useRouteMatch();
    const history = useHistory();
    const { LanesStore, listingsStore } = useStore();
    const activeBoard = useActiveBoard();
    const { data:listingMls } = useListingMLS(listing);
    const boardCards = activeBoard ? activeBoard.notArchivedBoardCards : emptyArray;
    const listingBoardCard = useMemo(()=> {
        return boardCards.find((bc)=> listing && bc.content.encoded_id === listing.id);
    }, [boardCards, listing]);
    const { affiliateCode } = useParams();
    const { data: buyerAgent } = useAgent(affiliateCode, { priority: 'affiliate' });
    const affiliatedMLS = useMemo(()=> {
        if(!buyerAgent?.mls_affiliations) return [];

        const result = buyerAgent.mls_affiliations.reduce((accum, value)=> {
            if(value.status === 'active') {
                accum.push(value.mls.source_code_name);
            }
            return accum;
        }, []);

        return result;
    }, [buyerAgent]);

    const showAgentBranding = useMemo(()=> {
        if(!listing || !listingMls) return false;

        const mls = _.get(listingMls, '[0]', null);

        if(listing.listing_source === 'listhub' || (mls && !mls.idx_rules_enforced)) return true;

        if(!mls) return true;

        return affiliatedMLS.includes(mls.source_code_name);
    }, [listing, listingMls, affiliatedMLS]);

    const agent =  useMemo(()=> {
        if(showAgentBranding && buyerAgent) {
            return buyerAgent;
        }
        return null;
    }, [showAgentBranding, buyerAgent]);

    const allowNoRobinAccessLO = true;
    const loanOfficer = useLoanOfficer(agent, allowNoRobinAccessLO);
    const [openScheduleConfirmationModal, setOpenScheduleConfirmationModal] = useState(false);
    const [loadingCardCreation, setLoadingCardCreation] = useState(false);

    // Functions
    function onContactAgentClick(source) {
        analytics.eventTrack('listpacks_consumer_contact_agent_cta_click', {
            listpack_id: listpack?.id,
            listingId: listing?.id,
            address: listing?.address,
            Source: source,
        });

        if(!listingBoardCard){
            setOpenScheduleConfirmationModal(true);
        } else {
            history.push({
                pathname: '/boards/schedule-a-tour',
                state: {
                    previousPath: history.location.pathname,
                    boardCardId: listingBoardCard.id,
                    listingId: listing.id,
                    source: 'rpp'
                },
            });
        }
    }

    function onPrivateTourClick() {
        //if (onboarding?.isFirstRunOnboarding) return;

        if(!listingBoardCard){
            setOpenScheduleConfirmationModal(true);
        } else {
            history.push({
                pathname: '/boards/schedule-a-tour',
                state: {
                    previousPath: history.location.pathname,
                    boardCardId: listingBoardCard.id,
                    listingId: listing.id,
                    source: 'rpp'
                },
            });
        }
    }

    function onCancelScheduleClick(){
        setOpenScheduleConfirmationModal(false);
    }

    async function onConfirmScheduleClick(){
        const processingList = listingsStore.listingsById[listing.id];
        if(!processingList?.linkedBoardCard?.id) {
            const destinationLane = LanesStore.lanesById[2];
            await activeBoard.moveListpackCard(listing, destinationLane.id);
            setLoadingCardCreation(true);
        }
        setOpenScheduleConfirmationModal(false);
        history.push({
            pathname: '/boards/schedule-a-tour',
            state: {
                previousPath: history.location.pathname,
                boardCardId: processingList?.linkedBoardCard?.id,
                source: 'rpp'
            },
        });
    }

    return (
        <>
            <ModalLayout
                open={open}
                onClose={onCloseClick}
                {...props}
            >
                <ListpackListingLayout
                    HeaderProps={HeaderProps}
                    FooterProps={FooterProps}
                    listing={listing}
                    listings={listings}
                    card={listingBoardCard}
                    listpack={listpack}
                    onClose={onCloseClick}
                    requestSource={sourceRequest}
                    totalOfListings={totalOfListings}
                    isloadingMore={isloadingMore}
                    shouldLoadMore={canLoadMore}
                    hidePagination={hidePagination}
                    onNextListingClick={onNextListingClick}
                    onPreviousListingClick={onPreviousListingClick}
                    buyerAgent={buyerAgent}
                >
                    <Listing
                        {...ListingProps}
                        listing={listing}
                        readOnly={readOnly}
                        card={listingBoardCard}
                        listpack={listpack}
                        buyerAgent={buyerAgent}
                        loanOfficer={loanOfficer}
                        onSchedulePrivateTour={onPrivateTourClick}
                        onContactAgentClick={onContactAgentClick}
                        requestSource={sourceRequest}
                    />
                </ListpackListingLayout>
            </ModalLayout>

            {openScheduleConfirmationModal && (
                <ListpackScheduleTourConfirmationModal
                    open={openScheduleConfirmationModal}
                    title="Continuing will add the listing to your “Want to See” column."
                    subtitle="Would you like to continue?"
                    onCancelClick={onCancelScheduleClick}
                    onConfirmClick={onConfirmScheduleClick}
                    loading={loadingCardCreation}
                />
            )}

            {/* Necessary condition to be able to render the SubmitOfferModal */}
            {activeBoard && activeBoard.loadedBoardData && (
                <Route
                    path={`${match?.url}/submit-offer/:boardCardId`}
                    render={()=> <SubmitOfferModal onClose={()=> history.push(match.url)} />}
                />
            )}
        </>
    );
});
