import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import { LRRangeDropdown } from '../../../../components/LRRangeDropdown';
import { PRICE_RANGE } from './constants';

export function Price({
    priceMin,
    priceMax,
    setPriceMin,
    setPriceMax,
    trackFilterSet,
    isMobile= false,
    onClose = (e)=> {},
    handleMixpanelEvent,
    ...props
}) {
    const [pricesSelected , setPricesSelected] = useState([]);

    return (
        <Box maxWidth={[1, 350]} p={2} {...props}>
            <LRRangeDropdown
                minValue={priceMin}
                maxValue={priceMax}
                options={PRICE_RANGE}
                dropdownMenuProps={isMobile ? {maxWidth: '144px'}: {}}
                onMinValueChange={(option)=> {
                    setPriceMin(option);
                    trackFilterSet('price');
                    handleMixpanelEvent();
                    if(pricesSelected.includes('price_max')){
                        onClose();
                    } else if(!pricesSelected.includes('price_min')){
                        setPricesSelected(pricesSelected.concat('price_min'));
                    }
                }}
                onMaxValueChange={(option)=> {
                    setPriceMax(option);
                    trackFilterSet('price');
                    handleMixpanelEvent();
                    if(pricesSelected.includes('price_min')){
                        onClose();
                    } else if(!pricesSelected.includes('price_max')){
                        setPricesSelected(pricesSelected.concat('price_max'));
                    }
                }}
                compareAsNumbers={true}
            ></LRRangeDropdown>
        </Box>
    );
}
