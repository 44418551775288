import React, { useState, useContext, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { waitForElement, delay, simulateClick } from '../../utils';
import { useEffect } from 'react';
import { useOnboarding } from '../../store';
import { useStore, useActiveBoard } from '../../../../mobx-store';
import { useHistory } from 'react-router-dom';
import Confetti from '../../../Confetti';
import { useMixpanelEvents } from './utils';
import { LoggedInWrapperContext } from 'components/LoggedInWrapper';
import analytics from 'services/analytics';
import { PreferencesModal } from 'components/PreferencesModal';

function commonSteps(tour, { setShowOverlay }) {

    function trackSkipEvent() {
        analytics.eventTrack('robin_onboarding_add_home_from_saved_search_skipped');
    }
    function dismissEvent() {
        analytics.eventTrack('robin_onboarding_edit_saved_search_guide_proceed', {
            source: 'first_run_onboarding'
        });
        tour.show('highlight-listpack-listing-card', true);
    }

    return [
        {
            id: 'edit-option-highlight',
            title: 'You can edit your search',
            text: 'With Robin, you can create multiple saved searches with different preferences, and edit those preferences anytime.',
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.tour-fre-edit-highlight', { timeout: 40000 }).then(()=> setShowOverlay(false));
            },
            attachTo: {
                element:'.tour-fre-edit-highlight',
                on: 'bottom'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 24]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    if(overlay){
                        overlay.forEach((elem)=> {
                            elem.addEventListener('click', dismissEvent);
                        });
                    }
                }
            },
        },
        {
            id: 'edit-optional-step',
            attachTo: {
                element:'.lane-modal-dialog-content',
                on: 'bottom'
            },
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.lane-modal-dialog-content', { timeout: 1000 })
                    .then(()=> tour.options.useModalOverlay = false)
                    .catch((err)=> {
                        tour.options.useModalOverlay = true;
                        tour.next();
                    });
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                },
                hide: ()=> {
                    setShowOverlay(false);
                    tour.options.useModalOverlay = true;
                }
            },
            classes: 'hidden'
        },
        {
            id: 'highlight-listpack-listing-card',
            title: 'Try adding this home to your board',
            text: 'Hover your mouse over the home and add it to any section of your board (you can remove it later). ',
            attachTo: {
                element: '.tour-target-expanded-board-card',
                on: 'bottom',
            },
            beforeShowPromise() {
                setShowOverlay(true);
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-target-expanded-board-card', { timeout: 400000 });
            },
            highlightClass: 'pulse-move-card-button',
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            },
            cancelIcon: {
                enabled: false,
            },
            advanceOn: {
                event: 'click',
                selector: '.shepherd-modal-overlay-container',
            },
            when: {
                show: function() {
                    setShowOverlay(false);
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    if(overlay){
                        overlay.forEach((elem)=> {
                            elem.removeEventListener('click', dismissEvent);
                            elem.addEventListener('click', ()=> {
                                tour.show('free-step', true);
                                trackSkipEvent();
                            });
                        });
                    }
                },
            }
        },
        {
            id: 'optional-rpp-view',
            attachTo: {
                element:'.tour-listpack-listing-detail',
                on: 'right'
            },
            beforeShowPromise() {
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-listpack-listing-detail', { timeout: 2000 })
                    .then(()=> tour.options.useModalOverlay = false);
            },
            classes: 'hidden'
        },
        {
            id: 'free-step',
            beforeShowPromise() {
                tour.options.useModalOverlay = false;
                return delay(400);
            },
            advanceOn: {
                selector: '.target-modal-overlay-container',
                event: 'click',
            },
            classes: 'hidden'
        },
    ];

}

function createLastWhinerStep(tour, { history, setShowOverlay }) {
    function handleDismiss() {
        analytics.eventTrack('robin_onboarding_search_area_dialog_dismiss');
    }

    return [
        {
            id: 'tour-listpack-area-filters',
            title: '🔍 Find homes you like',
            text: 'Based on that listing, here’s a quick search for similar homes. Broaden or narrow your filters to see all active listings that suit your needs.',
            attachTo: {
                element: '.tour-listpack-area-filters',
                on: 'bottom-start',
            },
            beforeShowPromise() {
                setShowOverlay(true);
                return delay(1000);
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 24]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            classes: 'shepherd-left-pinned',
            advanceOn: {
                event: 'click',
                selector: '.shepherd-modal-overlay-container',
            },
            when: {
                show: ()=> {
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> elem.addEventListener('click', handleDismiss));
                    setShowOverlay(false);
                }
            }
        },
        {
            id: 'search-cta',
            title: 'You can save a search ☝️',
            text: 'Saved searches in Robin are smart, showing you the latest homes matching your preferences as soon as they hit the market. <strong>Go ahead and save this one to see.</strong>',
            attachTo: {
                element: '.tour-save-listpack-search',
                on: 'auto-end',
            },
            beforeShowPromise() {
                setShowOverlay(true);
                return delay(500);
                // return waitForElement('.tour-save-listpack-search');
            },
            highlightClass: 'pulsing-button',
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 24]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            advanceOn: {
                event: 'click',
                selector: '.shepherd-modal-overlay-container',
            },
            when: {
                show: ()=> {
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> {
                        elem.removeEventListener('click', handleDismiss);
                    });
                    setShowOverlay(false);
                }
            },
        },

        {
            id: 'rpp-nurture-skip',
            title: 'Want to see what else you can do?',
            text: 'You can always search for homes with Robin and even create multiple saved searches. But there’s much more to this app than an awesome search tool - you can collaborate with co-buyers, take notes on homes, save ideas for later, make appointments to see homes you love... Want to take it for a spin?',
            beforeShowPromise() {
                setShowOverlay(true);
            },
            buttons: [
                {
                    text: 'Maybe later',
                    classes:'shepherd-text-button shepherd-text-button--left',
                    action: ()=> {
                        tour.show('free-step');
                    },
                },
                {
                    text: 'Show me!',
                    action: ()=> {
                        tour.complete();
                        history.replace('/boards/lane/0/your-searches');
                    },
                }
            ],
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: ()=> {
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> {
                        elem.removeEventListener('click', handleDismiss);
                    });
                    setShowOverlay(false);
                },
                hide: function() {
                    tour.options.useModalOverlay = true;
                },
            },
            classes: 'shepherd-wide-tooltip',
        },
        {
            id: 'save-search-modal',
            attachTo: {
                element: '.tour-save-search-modal',
                on: 'right',
            },
            beforeShowPromise() {
                setShowOverlay(true);
                return waitForElement('.tour-save-search-modal');
            },
            modalOverlayOpeningRadius: 4,
            analytics: {
                nextEvent: 'robin_onboarding_new_search_saved',
            },
            when: {
                show: ()=> {
                    setShowOverlay(false);
                    const overlay = document.querySelectorAll('.shepherd-modal-overlay-container');
                    overlay.forEach((elem)=> {
                        elem.removeEventListener('click', handleDismiss);
                    });
                },
                hide: function() {
                    tour.options.useModalOverlay = true;
                },
            },
            classes: 'hidden',
        },
        ...commonSteps(tour, {setShowOverlay})
    ];
}

function createInitalWhinersSteps(tour, { history, user, userRole, setShowPreferencesModal, setShowOverlay, goToListpack}) {
    return [
        {
            id: 'set-preferences',
            beforeShowPromise() {
                setShowPreferencesModal(true);
                tour.options.useModalOverlay = false;
                return waitForElement('.tour-set-preferences-modal', { timeout: 20000 });
            },
            attachTo: {
                element:'.tour-set-preferences-modal',
                on: 'bottom'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            // analytics: {
            //     nextEvent: 'robin_onboarding_browsing_preferences_submit',
            // },
            classes: 'hidden'

        },
        {
            id: 'highlight-saved-search',
            title: 'Check out your first saved search!',
            text: 'You can always search for homes from the top of this column, and save as many searches as you like based on your preferences. Saved searches are smart, so you’ll see them update when new listings hit the market, and can edit them anytime!',
            beforeShowPromise() {
                setShowPreferencesModal(true);
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-target-my-listpack-card', { timeout: 40000 })
                    .then(()=> setShowPreferencesModal(false))
                    .catch(()=> setShowPreferencesModal(true));
            },
            buttons: [
                {
                    text: 'View homes',
                    action: ()=> {
                        analytics.eventTrack('robin_onboarding_view_first_saved_search_submit', {
                            source: 'saved_search_card'
                        });
                        tour.next();
                        goToListpack();
                    }
                }
            ],
            attachTo: {
                element:'.tour-target-my-listpack-card',
                on: 'bottom'
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    }
                ]
            },
            modalOverlayOpeningRadius: 4,
            cancelIcon: {
                enabled: false,
            },
            when: {
                show: ()=> {
                    tour.options.useModalOverlay = true;
                    setShowPreferencesModal(false);
                },
            }
        },
        ...commonSteps(tour, { history, user, userRole, setShowPreferencesModal, setShowOverlay })
    ];
}

function createSteps(tour, options) {
    const { triggerOrigin } = options;

    const steps = {
        sidenav: createInitalWhinersSteps,
        rpp: createLastWhinerStep
    };

    return steps[triggerOrigin](tour, options);
}

export const NurtureFirstRunFlowMobile = observer(function FirstRunFlowMobile() {
    const { UserStore } = useStore();
    const { user, userPreferences } = UserStore;
    const onboarding = useOnboarding();
    const history = useHistory();
    const [showConfetti, setShowConfetti] = useState(false);
    const activeBoard = useActiveBoard();
    const { onOpenLeftSidebar } = useContext(LoggedInWrapperContext);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState();
    const currentListpack = useRef(null);

    useMixpanelEvents();

    useEffect(()=> {
        if( onboarding?.options?.triggerOrigin === 'sidenav'){
            history.replace('/boards/lane/0/your-searches');
        }
        if( onboarding?.options?.triggerOrigin === 'rpp'){
            onOpenLeftSidebar(false);
        }

    }, [history, onboarding?.options?.triggerOrigin]);

    const goToListpack = useCallback(
        ()=> {
            if(currentListpack.current?.id){
                history.replace(`/boards/listpack/${currentListpack.current?.id}/listings`);
            }
        },
        [currentListpack]
    );

    useEffect(()=> {
        if(!onboarding.hasTour) {
            return;
        }
        const steps = createSteps(onboarding.tour, {
            triggerOrigin: onboarding?.options?.triggerOrigin,
            history,
            user: UserStore.user,
            userRole: UserStore.usersBoardRole,
            setShowPreferencesModal,
            setShowOverlay,
            goToListpack
        });
        onboarding.addSteps(steps);
        onboarding.start();
        UserStore.unverifiedOnboardingInProgress = true;
        return function() {
            onboarding.reset();
        };
    }, [onboarding, UserStore, history]);

    useEffect(()=> {
        let timeout = null;
        const handleComplete = function() {
            onOpenLeftSidebar(true);
            const onboardingCompletedInfo = { movedCard: onboarding?.userMovedCard };
            UserStore.completeAsyncFirstRunOnboarding(onboardingCompletedInfo);
            UserStore.unverifiedOnboardingInProgress = false;
            setShowConfetti(true);
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        };

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding]);


    async function handleOnSubmit() {

        analytics.eventTrack('robin_onboarding_browsing_preferences_submit', {
            source: 'preferences_modal'
        });

        const { areaIds, bathrooms, bedrooms, price_min, price_max } = userPreferences;

        const payload = {
            userId: user.id,
            boardId: activeBoard.id,
            name: `${user?.first_name}’s first saved search 🎉`,
            areaIds: areaIds,
            notification_settings: {
                alert_frequency: 'daily'
            },
            fields: {
                bathrooms: bathrooms,
                bedrooms: bedrooms,
                price_min: price_min,
                price_max: price_max,
                homesize_min: null,
                homesize_max: null,
                stories: null,
                lotsize_min: null,
                lotsize_max: null,
                year_built_min: null,
                year_built_max: null,
                school_score_min: null,
                hometype: {
                    single_family: false,
                    multi_family: false,
                    condo_townhome: false,
                    mobile_manufactured: false,
                    land: false,
                },
                tags: {
                    with_great_schools: false,
                    with_virtual_tours: false,
                    with_open_houses: false,
                    with_views: false,
                    with_upgraded_kitchens: false,
                    with_pool: false,
                },
            }
        };

        currentListpack.current = await activeBoard.createOrUpdateListpack(payload);
        setShowPreferencesModal(false);
        onboarding.next();
    }

    async function handleOnSkip() {
        setShowPreferencesModal(false);
        onboarding.complete();
    }

    return <>
        {
            showOverlay && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 9999,
                        background: 'rgba(0,0,0, 0.5)'
                    }}
                ></div>
            )
        }
        <PreferencesModal
            open={showPreferencesModal}
            handleOnSubmit={handleOnSubmit}
            handleOnSkip={handleOnSkip}
        />
        { showConfetti && (
            <Confetti iterationCount={2}/>
        )}
    </>;

});
