import { CircularProgress, Divider, Grid, Hidden, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import LRButton from 'components/LRButton';
import LRDropdown from 'components/LRDropdown';
import lodashGet from 'lodash/get';
import { useStore } from 'mobx-store';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import notificationConfig from './config';
import { useResponsive } from 'hooks';
import { useBoardCardCollaborations } from './hooks';
import { Notification } from './Notification';
import { SkeletonNotification } from './Notification/skeleton-notification';
import analytics from 'services/analytics';
import LRAvatar from '../../../components/LRAvatar';

const RenderOption = ({ label, value })=> {
    return <div>{label}</div>;
};

const DisplayRenderOption = ({ label, value })=> {
    return <div>{label}</div>;
};

const BoardActivity = observer(function() {
    const theme = useTheme();
    const { boardsStore } = useStore();
    const board = boardsStore.activeBoard;
    const r = useResponsive();
    const options = [
        { label: 'Recent', value: null },
        { label: 'Notes', value: 'comment' },
        { label: 'Saved images', value: 'pinspiration_image' },
    ];
    const [selectedOption, setSelectedOption] = useState(options[1]);
    const selectedOptionValue = selectedOption?.value;

    const unselectedOptions = options.filter((o)=> o.value !== selectedOptionValue);

    const {
        isLoading,
        data,
        fetchNextPage: fetchMore,
        hasNextPage: canFetchMore,
        isFetchingNextPage: isFetchingMore,
    } = useBoardCardCollaborations(board?.id, selectedOptionValue);

    const notifications = data ? data.pages.flat() : [];
    const isLoadingData = boardsStore.loading || isLoading;
    
    // handlers
    function handleLaneChange(option) {
        analytics.eventTrack('robin_overview_board_activity_filter_updated', {
            filter_selected: option.label
        });
        setSelectedOption(option);
    }

    return (
        <div
            style={{
                borderRadius: '4px',
                background: '#FFF',
            }}
        >
            <Hidden smDown>
                <div
                    style={{
                        padding: theme.spacing(2),
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <Typography variant="h6">Board activity</Typography>
                    </div>
                    <div>
                        <LRDropdown
                            name="lane-switcher"
                            selectedOption={selectedOption}
                            options={unselectedOptions}
                            onOptionSelect={handleLaneChange}
                            renderDisplayOption={DisplayRenderOption}
                            renderOption={RenderOption}
                        />
                    </div>
                </div>
                <Divider />
            </Hidden>
            <div
                style={{
                    padding: theme.spacing(2),
                    maxHeight: r({ xs: 'auto', md: '640px' }),
                    overflowY: 'auto',
                }}
            >
                <Hidden mdUp>
                    <div
                        style={{
                            width: '142px',
                            marginBottom: '8px',
                        }}
                    >
                        <LRDropdown
                            name="lane-switcher"
                            selectedOption={selectedOption}
                            options={unselectedOptions}
                            onOptionSelect={handleLaneChange}
                            renderDisplayOption={DisplayRenderOption}
                            renderOption={RenderOption}
                        />
                    </div>
                </Hidden>

                <div
                    style={{
                        height: '100%',
                    }}
                >
                    {isLoadingData && (
                        <>
                            <SkeletonNotification />
                            <SkeletonNotification />
                            <SkeletonNotification />
                        </>
                    )}

                    {!isLoadingData &&
                        (notifications?.length ? (
                            notifications
                                .filter((notification)=> {
                                    return lodashGet(notificationConfig, notification?.type) && !notification.content?.suppress_notifications;
                                })
                                .map((notification, index)=> <Notification notification={notification} />)
                        ) : (
                            <Typography
                                style={{
                                    paddingLeft: theme.spacing(2),
                                    paddingRight: theme.spacing(2),
                                    paddingTop: r({ xs: 0, md: theme.spacing(2) }),
                                    paddingBottom: r({ xs: 0, md: theme.spacing(2) }),
                                }}
                            >
                                <span role="img" aria-label="emoji">
                                    📝
                                </span>{' '}
                                Once your buyers start using Robin, you’ll see all their activity here.
                            </Typography>
                        ))}
                </div>
            </div>
            <Divider />
            {canFetchMore && (
                <div
                    style={{
                        width: '100%',
                        minHeight: '52px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LRButton
                        disabled={isFetchingMore || isLoading}
                        variant="text"
                        fullWidth
                        style={{
                            padding: theme.spacing(1.5),
                        }}
                        onClick={()=> {
                            fetchMore();
                        }}
                    >
                        {isLoading || isFetchingMore ? <CircularProgress color="secondary" size={20} /> : 'Show more'}
                    </LRButton>
                </div>
            )}
        </div>
    );
});

export default BoardActivity;
