import React from 'react';
import { SvgIcon } from '@material-ui/core';

function BriefcaseIcon({ fillColor = 'black', ...props }) {

    return (
        <SvgIcon width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill={fillColor}
                d="M7.66666 8.66667H6.33333C5.96666 8.66667 5.66666 8.36667 5.66666 8H1.00666V10.6667C1.00666 11.4 1.60666 12 2.34 12H11.6667C12.4 12 13 11.4 13 10.6667V8H8.33333C8.33333 8.36667 8.03333 8.66667 7.66666 8.66667ZM12.3333 2.66667H9.66666C9.66666 1.19333 8.47333 0 7 0C5.52666 0 4.33333 1.19333 4.33333 2.66667H1.66666C0.933328 2.66667 0.333328 3.26667 0.333328 4V6C0.333328 6.74 0.926662 7.33333 1.66666 7.33333H5.66666V6.66667C5.66666 6.3 5.96666 6 6.33333 6H7.66666C8.03333 6 8.33333 6.3 8.33333 6.66667V7.33333H12.3333C13.0667 7.33333 13.6667 6.73333 13.6667 6V4C13.6667 3.26667 13.0667 2.66667 12.3333 2.66667ZM5.66666 2.66667C5.66666 1.93333 6.26666 1.33333 7 1.33333C7.73333 1.33333 8.33333 1.93333 8.33333 2.66667H5.66H5.66666Z"
            />
        </SvgIcon>
    );
}

export default BriefcaseIcon;
