import React, { useState } from 'react';
import { Button, IconButton, Typography, useTheme } from '@material-ui/core';
import { TrashIcon } from 'components/LRIcons';
import { CardDeleteModal } from 'components/CardDeleteModal';
import { useBoardCollaborations } from 'hooks/useBoardCollaborations';
import { useActiveBoard, useStore } from 'mobx-store';
import splitAddress from 'services/split-address';
import { observer } from 'mobx-react-lite';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import analytics from 'services/analytics';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';
import { useArchiveBoardCard } from 'hooks/data/board-card/useArchiveBoardCard';
import addressSlugger from 'services/address-slugger';
import { useResponsiveBreakpoint } from 'hooks';
import { SOLD_STATUSES, PENDING_STATUSES } from '../../../constants';

const emptyArray = [];
export const ArchiveCardButton = observer(function ArchiveCardButton({ card, trackEventData, ...props }) {
    const history = useHistory();
    const match = useRouteMatch();
    const { GlobalSnackbarStore } = useStore();
    const activeBoard = useActiveBoard();
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const sameLaneCards = activeBoard
        ? activeBoard.notArchivedBoardCards.filter((boardCard)=> boardCard.columnId === card?.columnId)
        : emptyArray;
    const { data: listing } = useBoardCardListing(card);
    const { mutateAsync: archiveCard, isLoading: isDeleting } = useArchiveBoardCard();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { addBoardCollaboration } = useBoardCollaborations();

    async function handleDeleteConfirmation(message) {
        const cardIndex = sameLaneCards.findIndex((boardCard)=> boardCard.id === card?.id);
        const nextCard = sameLaneCards[cardIndex + 1];
        setShowConfirmModal(false);

        await archiveCard(card);

        if(message) {
            addBoardCollaboration({
                model: 'BoardCard',
                itemId: card.id,
                boardId: card.boardId,
                content: {
                    message,
                    suppress_notifications: true,
                },
                type: 'comment',
                visibleToAffiliate: true,
                visibleToLO: false,
            });
        }

        // create the board collaboration
        addBoardCollaboration({
            model: 'BoardCard',
            itemId: card.id,
            boardId: card.boardId,
            content: {
                message: message,
                cta: `/boards/archivedListings/${card.id}`,
            },
            type: 'deleted_card',
        });

        const [address1] = listing ? splitAddress(listing.address) : [];
        GlobalSnackbarStore.show(`${card.name || address1 || 'This home '} has been moved to your deleted homes`, {
            autoHideDuration: 2000,
        });

        analytics.eventTrack('robin_card_archived', {
            source: 'rpp_header',
            card_id: card.id,
            board_id: card.boardId,
            listing_id: listing.id,
            address: listing.address,
        });

        if(nextCard) {
            // try to infere the destination url based on the current path
            const { path, params } = match;
            const url = generatePath(path, {
                ...params,
                cardId: nextCard.id,
                address: addressSlugger(nextCard?.listing_details.address)
            });

            history.push(url);
        } else {
            history.push('/boards');
        }
    }

    function handleArchiveClick() {
        analytics.eventTrack('rpp_trash_icon_click', {
            card_id: card.id
        });
        if(![...SOLD_STATUSES, ...PENDING_STATUSES].includes(listing.listing_status.toLowerCase()))  {
            setShowConfirmModal(true);
        } else {
            handleDeleteConfirmation();
        }
    }

    if(!card) {
        return null;
    }

    return (
        <>
            <Button
                onClick={handleArchiveClick}
                style={{
                    textTransform: 'none',
                    backgroundColor: !isMobile ? 'transparent' : null,
                }}
                disableRipple={!isMobile}
                disabled={isDeleting}
            >
                <span
                    style={{
                        display: 'inline-flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                    }}
                >
                    {isMobile ? (
                        <TrashIcon />
                    ) : (
                        <IconButton style={{ padding: theme.spacing(1), }}>
                            <TrashIcon
                                style={{
                                    width: 16,
                                    height: 16,
                                }}
                            />
                        </IconButton>
                    )}

                    <Typography
                        variant="caption"
                        style={{
                            color: isDeleting ? 'rgba(0, 0, 0, 0.26)' : null,
                            textDecoration: !isMobile ? 'underline' : null,
                        }}
                    >
                        Delete
                    </Typography>
                </span>
            </Button>

            {showConfirmModal && <div
                onClick={(e)=> {
                    e.preventDefault();
                }}
            >
                <CardDeleteModal
                    open={showConfirmModal}
                    onClose={()=> {
                        setShowConfirmModal(false);
                    }}
                    onConfirm={handleDeleteConfirmation}
                />
            </div>}
        </>
    );
});
