import React from 'react';
import { SvgIcon } from '@material-ui/core';

function ListIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M0,0 L24,0 L24,24 L0,24 L0,0 Z M12,21 L12,13 L3,13 L3,21 L12,21 Z M21,3 L3,3 L3,11 L21,11 L21,3 Z M21,21 L21,13 L12,13 L12,21 L21,21 Z"></path>
        </SvgIcon>
    );
}

export default ListIcon;
