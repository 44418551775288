import React, { useEffect } from 'react';
import { LeadForm } from '../../../components/LeadForm';
import { Box } from '@material-ui/core';
import { COLOR_GREY_LIGHT } from '../../../constants';
import analytics from '../../../services/analytics';
import { useStore } from '../../../mobx-store';
import { useQuery } from '../../../hooks';
import { useResponsiveBreakpoint } from '../../../hooks';
import { useTalkingPoints } from '../../../hooks/useTalkingPoints';
import { CenteredLoading } from '../../../components/CenteredLoading';
import { TalkingPoints } from '../../../components/TalkingPoints';
import { Footer } from '../../../components/TalkingPoints/Footer';

function FormHeader({ userType, ...props }) {
    return (
        <Box fontSize="20px" fontWeight="500" letterSpacing={0} fontStyle="normal">
            {userType === 'agent' ?
                'Questions about these articles or the state of your local market? Get in touch! 👇' :
                'Need help with mortgage financing?'
            }
        </Box>
    );
}

export function LandingPageBody({ user, loadingUser, loadedUser, media = 'screen', ...props }) {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const { ShareablesStore } = useStore();
    const slugParam = useQuery()
        .get('slug');
    const date = useQuery()
        .get('date');
    const b64data = useQuery()
        .get('data');
    let queryData;
    try {
        queryData = JSON.parse(atob(b64data));
    } catch (e) {
        queryData = {};
    }
    const {
        isLoading: isLoadingTalkingPoints,
        isSuccess,
        isError,
        data: talkingPointsData = [],
    } = useTalkingPoints({ userType: user?.type, date, slugParam });
    const talkingPoints = { ...talkingPointsData[0], ...queryData };
    const { slug } = talkingPoints;


    const onLeadCreated = (lead, message)=> {
        analytics.eventTrack('shareable_consumer_lead_form_submit', {
            distinct_id: user.id,
            ...(slug && { 'Shareable Slug': slug }),
            'Shareable Type': 'talking_points',
        });

        ShareablesStore.onLeadCreated(lead, message, slug, 'talking_points');
    };

    useEffect(()=> {
        if(!window.isLoaded && media === 'print') {
            if(loadedUser && (isSuccess || isError)) {
                window.isLoaded = true;
            }
        }
    }, [loadedUser, isSuccess, isError, media]);

    return (
        <>
            <Box maxWidth={1}
                position="relative"
                bgcolor={media === 'print' ? 'transparent': COLOR_GREY_LIGHT}
                overflow="auto"
                flexGrow="1"
            >
                {isLoadingTalkingPoints && <CenteredLoading height="100vh" />}
                {!isLoadingTalkingPoints && (<TalkingPoints user={user} talkingPoints={talkingPoints} date={date} media={media} />)}
            </Box>
            {media === 'screen' && <Box
                width="100%"
                height={{ xs: '100%', md: 'auto' }}
                maxWidth={{ xs: 1, md: '360px' }}
                minWidth={{ xs: 0, md: '360px' }}
                overflow="auto"
            >
                <Box
                    px={4}
                    pb="28px"
                    pt="26px"
                >
                    <LeadForm
                        user={user}
                        loadingUser={loadingUser}
                        loadedUser={loadedUser}
                        formHeader={<FormHeader userType={user.type} />}
                        variant={isMobile ? 'mobile' : 'desktop'}
                        leadSource="Weekly Talking Points"
                        onLeadCreated={onLeadCreated}
                        submitText="Submit"
                    />
                </Box>

                {isMobile && <Footer user={user} />}
            </Box>}
        </>
    );
}
