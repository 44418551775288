import React from 'react';
import SectionTitle from '../SectionTitle';
import { Typography, Box } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withWidth } from '@material-ui/core';
import LRButton from '../../../components/LRButton';
import { useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
import { COLOR_BLUE_LINK } from '../../../constants';

const P = styled(Typography)`
    white-space: pre-line;
`;

const StyledButton = styled(LRButton)`
    color: ${COLOR_BLUE_LINK};
`;

function Description(props) {
    const DEFAULT_DESCRIPTION_CHARACTER_LIMIT = 280;
    const { listing, description, expand, onExpandClick, requestSource, reportId } = props;
    const { ListpackStore, UserStore } = useStore();
    const { agent, listpack } = ListpackStore;
    const user = UserStore.user;

    function renderDescription() {
        if(description.length <= DEFAULT_DESCRIPTION_CHARACTER_LIMIT || expand) {
            return description;
        }

        return (
            description.substr(0, DEFAULT_DESCRIPTION_CHARACTER_LIMIT)
                .substring(0, description.lastIndexOf(' ')) +
            '...'
        );
    }

    function handleReadClick() {
        if(!expand) {
            analytics.eventTrack('listpacks_consumer_listingpage_description_read_more_click', {
                address: listing.address,
                'listing id': listing.id,
                listpack_id: listpack?.id,
                'current url': window.location.href,
                source: requestSource,
                report_id: reportId,
            });
        }
        onExpandClick();
    }

    return (
        <Box mt={3}>
            <SectionTitle>Description</SectionTitle>
            <P component="p">{renderDescription()} </P>
            <Box textAlign="center" mt={12 / 8}>
                {!expand && description.length >= DEFAULT_DESCRIPTION_CHARACTER_LIMIT && (
                    <StyledButton variant="text" inline onClick={handleReadClick}>
                        Read more
                    </StyledButton>
                )}

                {expand && description.length >= DEFAULT_DESCRIPTION_CHARACTER_LIMIT && (
                    <StyledButton variant="text" inline onClick={handleReadClick}>
                        Read less
                    </StyledButton>
                )}
            </Box>
        </Box>
    );
}

Description.propTypes = {
    listing: PropTypes.object,
    expand: PropTypes.bool,
    setExpand: PropTypes.func,
    description: PropTypes.string.isRequired,
};

export default withWidth()(Description);
