import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { QueryClientProvider  } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import rootStore, { StoreProvider, useStore } from '../../mobx-store';
// Pages
import Four0FourPage from '../../scenes/404Page';
import Board from '../../scenes/Board';
import MobileLaneView from '../../scenes/Board/MobileLaneView';
import CreateBoard from '../../scenes/CreateBoard';
import ListingsMap from '../../scenes/ListingsMap';
import { Login } from '../../scenes/Login';
import LRLive from '../../scenes/LRLive';
import PublicListingModal from '../../scenes/PublicListingModal';
import RateFarmModal from '../../scenes/RateFarmModal';
import ShareableLandingPage from '../../scenes/ShareableLandingPage';
import OverviewPage from '../../scenes/OverviewPage';
import ErrorPage from '../../scenes/ErrorPage';
import { SignUp } from '../../scenes/SignUp';
import { HomeReportSignUp } from '../../scenes/HomeReportSignUp';
import analytics from '../../services/analytics';
import launchdarkly from '../../services/launchdarkly';
import { RobinTheme } from '../../theme';
import GlobalSnackbar from '../GlobalSnackbar';
import GlobalConfetti from '../GlobalConfetti';
import LoggedInWrapper from '../LoggedInWrapper';
import PrivateRoute from '../PrivateRoute';
import { defineAbilityFor } from '../../abilities/defineAbility';
import { AbilityContext } from '../Ability';
import { AppOnboardingProvider } from '../Onboarding';
import { RealtimeStoreManager } from '../RealtimeStoreManager';
import { useResponsiveBreakpoint } from '../../hooks';
import { queryClient } from '../../query-client';
import { observer } from 'mobx-react-lite';
import { LoggedInWrapperProvider } from 'components/LoggedInWrapper';
import ListpackModal from 'scenes/ListpackModal';
import { ListpackSearchModalLoggedOut } from '../../scenes/Board/ListpackSearchModal';
import { LoginModal, SignUpModal } from 'components/SignUpLoginModal';
import Logger from 'js-logger';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { Logout } from 'scenes/Logout';
import { OptInLandingPage } from '../../scenes/OptInLandingPage';
import { OAuth } from 'scenes/OAuth';
import { SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION, SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_MOVE_CARD_OR_LISTING, SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_PINSPIRATION_PIN_UNPIN } from '../../constants';
import Lockr from 'lockr';
import { useMoveCardOrListing } from 'hooks/useMoveCardOrListing';
import { GoogleAuthProvider } from 'components/GoogleLogin';
import { useQuery } from 'hooks';
import { MyHomeModalLoggedOut } from 'scenes/MyHome';
import { BoardCardsProvider } from 'hooks/useBoardCards/BoardCardsProvider';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import TalkingPointsLandingPage from '../../scenes/TalkingPointsLandingPage';

function handleLaunchdarklyChange() {
    let flags = launchdarkly.allFlags();
    flags = Object.keys(flags)
        .reduce(function(result, flag) {
            var name = `LaunchDarkly ${flag}`;
            result[name] = flags[flag];

            return result;
        }, {});
    analytics.setUserProperties(flags);
}

launchdarkly.on('ready', handleLaunchdarklyChange);
launchdarkly.on('change', handleLaunchdarklyChange);
launchdarkly.on('error', ()=> void 0);

const RedirectPage = observer(function() {
    const location = useLocation();
    const { UserStore } = useStore();
    return <Redirect to={{ ...location, pathname: UserStore.isConsumer ? '/boards' : '/overview' }} />;
});

const Routes = observer(function Routes() {
    const isXs = useResponsiveBreakpoint('xs');
    const location = useLocation();
    const history = useHistory();
    const { isLoggedIn } = useIsLoggedIn();

    useEffect(()=> {
        let searchParams = new URLSearchParams(location.search);
        if(searchParams.has('bearerToken')) {
            searchParams.delete('bearerToken');
            history.push({ ...location, search: searchParams.toString() });
        } else {
            if(location?.pathname) analytics.pageTrack(location.pathname);
        }
    }, [location, history]);

    useEffect(()=> {
        analytics.setSuperProperties({
            logged_in: isLoggedIn,
        });
    }, [isLoggedIn]);

    return (
        <Switch>
            <Route
                path={'/oauth/:provider'}
            >
                <OAuth />
            </Route>
            <Route
                path={'/listpacks/create/:areaId/:affiliateCode'}
            >
                <ListpackSearchModalLoggedOut />
            </Route>
            <Route
                path={'/home-report/l/:leadId/:affiliateCode'}
            >
                <MyHomeModalLoggedOut />
            </Route>
            <Route path="/listings/:listingIds" component={PublicListingModal} />
            <Route path="/listpack/:listpackId" component={ListpackModal} />
            <Route path="/lrlive/:id/:addressSlug" component={LRLive} />
            <Route path="/rate-farm/:userId" component={RateFarmModal} />
            <Route path="/shareables/:userId" component={ShareableLandingPage} />
            <Route path="/talking-points/:userId" component={TalkingPointsLandingPage} />
            <Route path="/signup/homereport/:affiliateCode" component={HomeReportSignUp} />
            <Route path="/signup/:affiliateCode" component={SignUp} />
            <Route path="/signup"  component={SignUp} />
            <Route path="/login"  component={Login} />
            <Route path="/logout"  component={Logout} />
            <Route path="/error" exact component={ErrorPage} />
            <Route path="/404" component={Four0FourPage} />
            <Route path="/subscribe/home-report/:affiliateCode" component={OptInLandingPage} />
            <>
                <PrivateRoute
                    path="/"
                    render={()=> (
                        <LoggedInWrapperProvider>
                            <AppOnboardingProvider>
                                <LoggedInWrapper>
                                    <BoardCardsProvider>
                                        <Switch>
                                            <PrivateRoute path="/overview" component={OverviewPage} />
                                            <PrivateRoute  path="/r/your-searches" component={(props)=> {
                                                const redirectionRoute = isXs ? '/boards/lane/0/your-searches' : '/boards';
                                                return <Redirect to={redirectionRoute} />;
                                            }}/>
                                            <PrivateRoute path="/new-board" exact component={CreateBoard} />
                                            <PrivateRoute
                                                path="/boards/lane/:laneId/:laneSlug"
                                                component={MobileLaneView}
                                            />
                                            <PrivateRoute path="/boards" component={Board} />
                                            <PrivateRoute path="/map" component={ListingsMap} />
                                            <Route
                                                path="/"
                                                component={RedirectPage}
                                            />
                                            <Route component={Four0FourPage} />
                                        </Switch>
                                    </BoardCardsProvider>
                                </LoggedInWrapper>
                            </AppOnboardingProvider>
                        </LoggedInWrapperProvider>
                    )}
                ></PrivateRoute>
            </>

            <Route component={Four0FourPage} />
        </Switch>
    );
});

const GlobalLoginSignupModals = observer(function GlobalLoginSignupModals() {
    const { LoginSignupModalStore } = useStore();
    const { isMobile } = useDeviceBreakpoints();
    const location = useLocation();
    const moveCardOrListing = useMoveCardOrListing();

    // UseEffects

    /**
     * handles the reopening of the login / signup modal if the
     * user went through the redirect process when doing an
     * OAuth login flow.
     */
    useEffect(()=> {
        if(LoginSignupModalStore.isOpen) return;

        switch(location.state?.authMethod) {
            case LoginSignupModalStore.LOGIN_SIGNUP_MODAL_LOGIN_OPEN:
                LoginSignupModalStore.openLoginModal();
                break;
            case LoginSignupModalStore.LOGIN_SIGNUP_MODAL_SIGNUP_OPEN:
                LoginSignupModalStore.openSignupModal();
                break;
            default:
                break;
        }
        window.history.replaceState({}, document.title);
    }, [location.state]);

    /**
     * Handles rerunning of task that was attempted before loggin in using
     * the OAuth redirect flow.
     * This should only run once thus the empty dependencies Array.
     */
    useEffect(()=> {
        const cancel = LoginSignupModalStore.onSuccess(()=> {
            const oauthRedirectAction = Lockr.get(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION);

            if(!oauthRedirectAction) return;

            const {
                type,
                data,
            } = oauthRedirectAction;

            switch(type) {
                case SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_MOVE_CARD_OR_LISTING:
                    moveCardOrListing(data.boardCard, data.destinationLane, data.fromLaneName);
                    break;
                case SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION_TYPE_PINSPIRATION_PIN_UNPIN:
                    // Nothing goes here. This is handled in the PinspirationOverlay component
                    break;
                default:
                    break;
            }

            Lockr.rm(SIGNUP_LOGIN_RUN_OAUTH_REDIRECT_SUCCESS_ACTION);
        });
        return cancel;
    }, []);

    // Functions

    function onCloseSignUpLoginModalClick() {
        LoginSignupModalStore.closeLoginSignupModal();
    }

    function onSwitchToLoginClick() {
        LoginSignupModalStore.openLoginModal();
    }

    function onLoginSuccess() {
        LoginSignupModalStore.loginSuccess();
        onCloseSignUpLoginModalClick();
    }

    function onSignUpSuccess() {
        LoginSignupModalStore.signupSuccess();
        onCloseSignUpLoginModalClick();
    }

    return (
        <>
            <SignUpModal
                open={LoginSignupModalStore.isOpen === LoginSignupModalStore.LOGIN_SIGNUP_MODAL_SIGNUP_OPEN}
                onCloseClick={onCloseSignUpLoginModalClick}
                onSignUpSuccess={onSignUpSuccess}
                onSwitchToLoginClick={onSwitchToLoginClick}
                fullScreen={isMobile}
            />
            <LoginModal
                open={LoginSignupModalStore.isOpen === LoginSignupModalStore.LOGIN_SIGNUP_MODAL_LOGIN_OPEN}
                onCloseClick={onCloseSignUpLoginModalClick}
                onLoginSuccess={onLoginSuccess}
                fullScreen={isMobile}
            />
        </>
    );
});

function App() {
    const searchParamsRef = useRef(document.location.search);
    const guestAbility = defineAbilityFor(null);

    useEffect(()=> {
        let searchParams = new URLSearchParams(searchParamsRef.current);
        rootStore.fetchInitialData({
            bearerToken: searchParams.get('bearerToken'),
            testOnboarding: searchParams.has('onboarding')
        });
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <AbilityContext.Provider value={guestAbility}>
                <StoreProvider>
                    <GoogleAuthProvider>
                        <RealtimeStoreManager />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <MuiThemeProvider theme={RobinTheme}>
                                <Router>
                                    <Routes key="routes" />
                                    <GlobalLoginSignupModals />
                                </Router>
                                <GlobalSnackbar />
                                <GlobalConfetti />
                            </MuiThemeProvider>
                        </MuiPickersUtilsProvider>
                    </GoogleAuthProvider>
                </StoreProvider>
            </AbilityContext.Provider>
            <ReactQueryDevtools position="bottom-left" toggleButtonProps={{  style: { bottom: 64, }}} />
        </QueryClientProvider>
    );
}

export default App;
