import { portalApi } from 'apis';
import { useQuery } from 'react-query';

export function useListingMLS(listing) {
    return useQuery(
        ['listing-mls', listing?.mls_source_code],
        function() {
            return portalApi.getMLSBySourceCode(listing?.mls_source_code);
        }, {
            enabled: Boolean(listing),
        }
    );
}

export function useListingMLSByZipcode(listing) {
    return useQuery(
        ['listing-mls-by-zipcode', listing?.postal_code],
        function() {
            return portalApi.getMLSByZipcode(listing?.postal_code);
        }, {
            enabled: Boolean(listing),
        }
    );
}
