import Logger from 'js-logger';
import React, { useEffect, useMemo, useState } from 'react';
import LRModal from '../LRModal';
import { HeaderNavBar, MobileFooterNavBar } from './NavBars';
import styled from 'styled-components';
import { AppBar, ButtonBase, CircularProgress, Grid, Slide, Hidden, Tab, Tabs, ThemeProvider, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { MuiPrimaryBlackTheme } from '../../theme';
import { withTheme } from '@material-ui/styles';
import { useStore } from '../../mobx-store';
import { useBoardCollaborations } from '../../hooks/useBoardCollaborations';
import { Card } from './Card';
import { observer } from 'mobx-react-lite';
import analytics from '../../services/analytics';
import { TourDetailsModal } from './TourDetailsModal';
import { find, orderBy, partial } from 'lodash';
import { Link, Route, useHistory, useParams, useRouteMatch, Switch } from 'react-router-dom';
import LRConfirmationDialog from '../LRConfirmationDialog';
import LRButton from '../LRButton';
import moment from 'moment';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import OpenHouseCalendar from 'components/OpenHouseCalendar';
import { useGetBoardOpenHouses } from 'hooks/data/boards/open-house/useGetBoardOpenHouses';
import _ from 'lodash';
import { Can } from 'components/Ability';
import { subject } from '@casl/ability';

const logger = Logger.get('ScheduledToursModal');

const Modal = styled((props)=> <LRModal {...props} />)`
    .ScheduledToursModal-container {
        padding-top: 0;
    }
`;

const TabContainer = withTheme(styled(AppBar)`
    background-color: transparent;
    border-bottom: 1px solid #ddd;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        // margin-left: -${({ theme })=> (theme.spacing(2))}px;
        // width: calc(100% + ${({ theme })=> (theme.spacing(4))}px);
        background-color: #fff;
        top: 57px;
    }
`);

function TabLabel({
    selected = false,
    children,
}) {
    return (
        <Typography style={{ textTransform: 'none', fontWeight: (selected && 'bold') }}>{children}</Typography>
    );
}

const StyledTab = withTheme(styled(Tab)`
    min-width: 0;
    margin-right: ${({ theme })=> (theme.spacing(3))}px;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        margin-right: 0;
    }
`);

export const ScheduledToursModal = observer(function ScheduledToursModal({
    open = false,
    onCloseClick = (e)=> {},
    onNewClick = (e)=> {},
    style = {},
    ...props
}) {
    const DEFAULT_TOUR = {
        upcoming: [],
        past: [],
        cancelled: [],
    };
    const theme = useTheme();
    const match = useRouteMatch();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState('upcoming');
    const [loadingTours, setLoadingTours] = useState(true);
    const openHouseFilter = selectedTab === 'upcoming' ? 'scheduled' : 'past';
    const { boardsStore, UserStore } = useStore();
    const { user, isAgent, isLO } = UserStore;
    const activeBoard = useMemo(()=> {
        return boardsStore.activeBoard || {};
    }, [boardsStore.activeBoard]);
    const {
        addBoardCollaboration,
        isLoading:boardCollaborationsLoading,
        boardCollaborations,
        updateBoardCollaboration,
    } = useBoardCollaborations(activeBoard?.id);
    const { data, isLoading: isLoadingOpenHouses } = useGetBoardOpenHouses(activeBoard?.id, openHouseFilter, 'all');
    const [tours, setTours] = useState(DEFAULT_TOUR);
    const isCardHorizontal = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const {
        isMobile,
    } = useDeviceBreakpoints();
    const [modalError, setModalError] = useState(false);

    // UseEffects
    useEffect(function () {
        if(isLoadingOpenHouses || boardCollaborationsLoading ) {
            setLoadingTours(true);
            return;
        }
        const newTours = (boardCollaborations || []).reduce((result, tour)=> {
            if(tour.type !== 'schedule_viewing_request') return result;
            if(
                !(tour.userId === user.id) &&
                (!tour.content.participants_joining ||
                    !tour.content.participants_joining.find((participant)=> participant.userId === user.id )
                )
            ) {
                return result;
            }

            if(tour.content.cancelled) {
                result.cancelled.push(tour);
            } else if(!tour.content.scheduled_date_time || moment().isBefore(tour.content.scheduled_date_time)) {
                result.upcoming.push(tour);
            } else {
                result.past.push(tour);
            }

            return result;
        }, {...DEFAULT_TOUR});

        let openHouses = [];

        if(data && data.length) {
            data.reduce((accum, openHouse)=> {
                const startTime = openHouse.boardCollaboration.content.start_time_iso;
                openHouse.boardCollaboration.content.scheduled_date_time = startTime;
                openHouse.boardCollaboration.content.name = openHouse.card?.name || openHouse.listing?.address;

                if(openHouse.isHistory) {
                    accum.past.push(openHouse.boardCollaboration);
                } else {
                    accum.upcoming.push(openHouse.boardCollaboration);
                }
                return accum;
            }, {...DEFAULT_TOUR});
        }

        const allTours = _.merge(newTours, openHouses);

        if(allTours.upcoming.length) {
            const unscheduledASAP = allTours.upcoming.filter((tour)=> (!Boolean(tour.content.scheduled_date_time) && tour.content.suggested_date_time_1?.asap));
            const scheduled = allTours.upcoming.filter((tour)=> (Boolean(tour.content.scheduled_date_time)));
            const unscheduledSuggestedDateTime = allTours.upcoming.filter((tour)=> (!Boolean(tour.content.scheduled_date_time) && !tour.content.suggested_date_time_1?.asap));

            allTours.upcoming = [
                ...orderBy(unscheduledASAP, ['createdAt'], ['asc']),
                ...orderBy([...scheduled, ...unscheduledSuggestedDateTime],
                    (tour)=> (tour.content.scheduled_date_time || moment(tour.content.suggested_date_time_1.date).toISOString()), ['asc']),
            ];
        }

        if(allTours.past.length) {
            allTours.past = orderBy(allTours.past, ['content.scheduled_date_time'], ['asc']);
        }

        setTours(newTours);
        setLoadingTours(false);
    }, [boardCollaborations, boardCollaborationsLoading, data, isLoadingOpenHouses]);

    // Functions

    async function cancelTour(e, tour) {
        const content = {
            cancelled: {
                cancelledAt: new Date().toISOString(),
                userId: user.id,
            }
        };

        try {
            await updateBoardCollaboration({
                id: tour.id,
                data: {
                    content: content
                }
            });
        } catch (err) {
            logger.error('cancelTour -> Error', err);
            setModalError({
                message: 'We encountered an error while trying to cancel your tour.'
            });
            return;
        }

        try {
            await addBoardCollaboration({
                boardId: activeBoard.id,
                itemId: tour.id,
                content: {
                    ...tour.content,
                    ...content,
                },
                model: 'BoardCollaboration',
                type: 'schedule_viewing_cancelled',
                visibleToAffiliate: true,
                visibleToLO: false,
            });
        } catch (err) {
            logger.warn('cancelTour -> Create cancelled board collaboration -> Error', err);
        }

        history.push(match.url);
    }

    function setSelectedTabFromTourId(id) {
        const currentSelectedTab = selectedTab;
        const tour = find(boardCollaborations, { id });

        if(!tour) return;

        let newSelectedTab;

        if(~tours.cancelled.indexOf(tour)) {
            newSelectedTab = 'cancelled';
        } else if(~tours.past.indexOf(tour)) {
            newSelectedTab = 'past';
        } else {
            newSelectedTab = 'upcoming';
        }

        if(currentSelectedTab !== newSelectedTab) setSelectedTab(newSelectedTab);
    }

    return (
        <>
            <Modal
                open={open}
                className="ScheduledToursModal-Root"
                hideBackdrop
                PaperProps={{
                    elevation: 0,
                }}
                fullScreen
                maxWidth="100%"
                hideCloseButton
                dialogContentClasses={{
                    root: 'ScheduledToursModal-container'
                }}
                style={{
                    marginTop: !isMobile && 64,
                    ...style,
                }}
                {...props}
            >
                <div
                    className="ScheduledToursModal-content"
                    style={{
                        backgroundColor: '#f9f9f9',
                        minHeight: '100%',
                    }}
                >
                    <HeaderNavBar
                        boardId={activeBoard?.id}
                        hideNewButton={isAgent || isLO}
                        hideBrowseOpenHousesButton={isAgent || isLO}
                        selectedTab={selectedTab}
                        onClose={onCloseClick}
                        onNewClick={onNewClick}
                        onSelectedTabChange={(e, value)=> {
                            if(!value) return;
                            setSelectedTab(value);

                            let eventName;

                            switch(value) {
                                case 'upcoming':
                                    eventName = 'robin_home_tours_upcoming_filter_click';
                                    break;

                                case 'past':
                                    eventName = 'robin_home_tours_past_filter_click';
                                    break;

                                case 'cancelled':
                                    eventName = 'robin_home_tours_cancelled_filter_click';
                                    break;

                                default:
                                    break;
                            }

                            if(eventName) analytics.eventTrack(eventName, {
                                board_id: activeBoard?.id,
                                Source: 'home_tour_modal',
                            });
                        }}
                    />

                    <div
                        style={{
                            padding: theme.spacing(isMobile ? 2 : 3),
                            paddingTop: isMobile && 0,
                            maxWidth: '816px',
                            width: '100%',
                            margin: '0 auto',
                        }}
                    >

                        <div
                            style={{
                                marginTop: theme.spacing(3),
                            }}
                        >
                            <div>
                                {loadingTours && (
                                    <Grid container justify="center">
                                        <Grid item>
                                            <CircularProgress />
                                        </Grid>
                                    </Grid>
                                )}

                                {!loadingTours && !!tours[selectedTab].length && (
                                    tours[selectedTab].map((tour)=> (
                                        <div key={tour.id} style={{ marginBottom: theme.spacing(3) }}>
                                            <Card
                                                tour={tour}
                                                variant={selectedTab}
                                                orientation={isCardHorizontal ? 'horizontal' : 'vertical'}
                                                onViewClick={()=> {
                                                    analytics.eventTrack('robin_home_tours_in_person_tour_card_click', {
                                                        Source: 'home_tours_modal',
                                                        board_id: activeBoard?.id
                                                    });
                                                    history.push(`${match.url}/${tour.id}`);
                                                }}
                                            />
                                        </div>
                                    ))
                                )}

                                {!loadingTours && !tours[selectedTab].length && (
                                    <div style={{ color: theme.palette.lr_colors.steak_sauce, marginTop: theme.spacing(5) }}>
                                        <Typography variant="h4" align="center" color="inherit">
                                            You don't have any {selectedTab} tours.
                                        </Typography>

                                        <Can I="create" a="HomeTour">
                                            {selectedTab === 'upcoming' && (
                                                <Typography variant="h6" align="center" color="inherit" style={{ marginTop: theme.spacing(3), fontWeight: 'normal' }}>
                                                    Don't worry! You can <LRButton onClick={onNewClick} color="inherit" inline underline style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>schedule</LRButton> one right now. 😊
                                                </Typography>
                                            )}
                                        </Can>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Hidden smUp>
                        <MobileFooterNavBar boardId={activeBoard?.id} hideNewButton={isAgent || isLO} hideBrowseOpenHousesButton={isAgent || isLO} onNewClick={onNewClick} />
                    </Hidden>
                </div>
            </Modal>

            <Route
                path={match?.url && `${match.url}/calendar`}
                children={({ match: routeMatch })=> {
                    return (
                        <OpenHouseCalendar
                            open={Boolean(routeMatch)}
                            onClose={()=> { history.push(match.url); }}
                            closeLabel="All Home Tours"
                            TransitionComponent={Slide}
                            TransitionProps={{
                                direction: 'left',
                            }}
                        />
                    );
                }}
            />

            <Route
                path={match?.url && `${match.url}/:tourId`}
                children={({ match:routeMatch })=> {
                    if(routeMatch && routeMatch?.params?.tourId !== 'calendar') {
                        setSelectedTabFromTourId(routeMatch.params.tourId);
                    }

                    return (
                        <TourDetailsModal
                            open={routeMatch && routeMatch?.params?.tourId !== 'calendar'}
                            tours={tours[selectedTab]}
                            onClose={()=> {
                                if(match) history.push(match.url);
                            }}
                            onTourChange={(e, tour)=> {
                                if(match) history.push(`${match.url}/${tour.id}`);
                            }}
                            onCancelTour={cancelTour}
                        />
                    );
                }}
            />


            {/* Error Modal */}
            {modalError && (
                <LRConfirmationDialog
                    title="Error"
                >
                    <Typography>
                        {modalError.message}
                    </Typography>
                </LRConfirmationDialog>
            )}
        </>
    );
});
