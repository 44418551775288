import React from 'react';
import { SvgIcon } from '@material-ui/core';

function Tour3DIcon(props) {
    const { fillColor, ...restProps } = props;
    return (
        <SvgIcon viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path d="M10.9996 0.244141L0.883301 5.19792L10.9996 10.1517L21.1159 5.19792L10.9996 0.244141Z" fill={fillColor}/>
            <path d="M0 20.5186L10.2972 25.5698H10.3041V11.3625L0 6.31128V20.5186Z" fill={fillColor}/>
            <path d="M11.6957 11.3625L11.6887 25.5698H11.6957L21.9929 20.5186L21.9998 6.31128L11.6957 11.3625Z" fill={fillColor}/>
        </SvgIcon>
    );
}

export default Tour3DIcon;

