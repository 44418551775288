import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import { withTheme } from '@material-ui/core/styles';

export default withTheme(styled(Tab)`
    text-transform: none;
    font-size: ${({ theme })=> theme.typography.body1.fontSize};

    & .MuiTab-textColorPrimary {
        color: #000000;
        opacity: 1;
    }
`);
