
const composeRefCache = new WeakMap();

function updateRef(ref, instance) {
    if(typeof ref === 'function') {
        ref(instance);
    } else {
        ref.current = instance;
    }
}

export function composeRefs(ref1, ref2) {
    if(ref1 && ref2) {
        if(!composeRefCache.has(ref1)) {
            composeRefCache.set(ref1, new WeakMap());
        }
        const ref1Cache = composeRefCache.get(ref1);

        if(ref1Cache.has(ref2)) {
            return ref1Cache.get(ref2);
        }

        function composeRef(instance) {
            updateRef(ref1, instance);
            updateRef(ref2, instance);
        }

        ref1Cache.set(ref2, composeRef);
        return composeRef;
    }

    return ref1 || ref2 || null;
};
