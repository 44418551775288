import { useQuery } from 'react-query';
import { places } from 'services/gmaps';
import { mapquestAPI } from '../../../apis';

const calculateDistance = function(lat1, lon1, lat2, lon2, unit) {
    if(lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if(dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if(unit === 'K') {
            dist = dist * 1.609344;
        }
        if(unit === 'N') {
            dist = dist * 0.8684;
        }
        return dist;
    }
};

export function useListingNearbyPlacesByCategory(listing) {
    return useQuery(
        ['listing-nearby-places-by-category', listing?.id],
        async function() {
            let nearbyPlaces = await places.searchNearbyPlacesGroupedByCategory(listing.lat, listing.lon);

            const startLocation = `${listing.lat},${listing.lon}`;
            const searchCoordinates = Object.values(nearbyPlaces)
                .flat()
                .map((place)=> {
                    return `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
                });

            try {
                const requestPayload = {
                    locations: [].concat([startLocation], searchCoordinates),
                };

                const response = await mapquestAPI.fetchMatrixData(requestPayload);

                Object.values(nearbyPlaces)
                    .flat()
                    .forEach((place, index)=> {
                        // the result object contains the target address on index 0
                        // in order to fetch the correct place index we offset by 1
                        const placeIndex = index + 1;
                        const location = response.locations[placeIndex];
                        place.distance = response.distance[placeIndex].toFixed(1);
                        place.formatted_address = `${location.street}, ${location.adminArea5}, ${location.adminArea3} ${location.postalCode}, ${location.adminArea1}`;
                    });
            } catch (err) {
                // if the request fails for some reason we could estimate the distance with a math function
                Object.values(nearbyPlaces)
                    .flat()
                    .forEach((place, index)=> {
                        const placeLocation = place.geometry.location;

                        const placeDistance = calculateDistance(
                            listing.lat,
                            listing.lon,
                            placeLocation.lat(),
                            placeLocation.lng()
                        );
                        place.distance = placeDistance.toFixed(1);
                        place.formatted_address = '';
                    });
                // nearbyPlaces.schools = schoolData.allSchools;
            }
            return nearbyPlaces;
        },
        {
            enabled: Boolean(listing),
        }
    );
}
