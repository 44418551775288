import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Typography, withTheme, withWidth, Box, Hidden } from '@material-ui/core';

const StyledScoreGrid = styled(Grid)`
    width: 64px;
    height: 64px;
    background: #1ec8e1;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin-right: 18px;
`;
const StyledScoreTypography = styled(Typography)`
    font-size: 1.75rem;
    color: #fff;
    margin-right: 1px;
`;
const StyledOutOfTypography = styled(Typography)`
    font-size: 0.625rem;
    color: #fff;
`;
const StyledAssignedLabelTypography = styled(Typography)`
    font-size: 0.625rem;
    letter-spacing: 0.0938rem;
    color: #a1a1a1;
    text-transform: uppercase;
`;
const StyledSchoolDetailsTypography = styled(Typography)`
    & .space-it {
        margin-right: 18px;
    }
`;
const SchoolInfoContainer = styled.div`
    flex: 1;
    max-width: 100%;
    margin-right: 18px;
`;
const StyledNoGridMobileGrid = withTheme(styled(Grid)`
    ${({ theme })=> theme.breakpoints.only('xs')} {
        display: inline-block;
    }
`);
const GTXSFlexDiv = styled.div`
    flex: 1;
`;

function School({ width, schoolData, ...props }) {
    return (
        <Grid container direction="row" alignItems={width === 'xs' ? 'flex-start' : 'center'}>
            <StyledScoreGrid container direction="row" justify="center" alignItems="center">
                <div>
                    <StyledScoreTypography display="inline">{schoolData.rating}</StyledScoreTypography>
                    <StyledOutOfTypography display="inline">/10</StyledOutOfTypography>
                </div>
            </StyledScoreGrid>

            <GTXSFlexDiv>
                <StyledNoGridMobileGrid container direction="row" alignItems="center">
                    <SchoolInfoContainer>
                        {schoolData.assigned && <StyledAssignedLabelTypography>Assigned</StyledAssignedLabelTypography>}
                        <Box fontSize={20} fontWeight={500} lineHeight="24px" component="h3">
                            {schoolData.name}
                        </Box>
                        <Hidden smDown>
                            <StyledSchoolDetailsTypography component="div">
                                <span className="space-it">
                                    <b>Grades:</b> {schoolData.grades}
                                </span>{' '}
                                <b>Distance:</b> {schoolData.distance} miles
                            </StyledSchoolDetailsTypography>
                        </Hidden>
                        <Hidden mdUp>
                            <StyledSchoolDetailsTypography component="div">
                                <Box>
                                    <span className="space-it">
                                        <b>Grades:</b> {schoolData.grades}
                                    </span>
                                </Box>
                                <Box>
                                    <b>Distance:</b> {schoolData.distance} miles
                                </Box>
                            </StyledSchoolDetailsTypography>
                        </Hidden>
                    </SchoolInfoContainer>
                </StyledNoGridMobileGrid>
            </GTXSFlexDiv>
        </Grid>
    );
}

School.propTypes = {
    schoolData: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export default withWidth()(School);
