import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { LRMap } from '../../LRMapModal';
import { useListingExplorerStoreContext } from '../store';

export const StreetView = observer(function StreetView() {
    const store = useListingExplorerStoreContext();
    const listing_details = store.listing_details;

    return (
        <Box height={1} width={1}>
            <LRMap
                address={listing_details.address}
                lat={listing_details.lat}
                lng={listing_details.lon}
                mode='street'
            />
        </Box>
    );
});