import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
import Checked from 'components/LRIcons/checkbox-more-filters-icons/checked';
import Unchecked from 'components/LRIcons/checkbox-more-filters-icons/unchecked';
import analytics from 'services/analytics';
import { LRCheckbox } from 'components/LRCheckbox';
import { useTheme } from '@material-ui/styles';
import { useResponsiveBreakpoint } from 'hooks';

function UnspecifiedListingsStoriesCheckbox(
    { value, onChange }) {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    return (
        <>
            <LRCheckbox
                checked={value}
                onChange={(e)=> {
                    onChange(e.target.checked);

                    //track includeListingsWithUnspecifiedData change
                    const event = `robin_unspecified_stories_checkbox_filter_${e.target.checked ? 'checked' : 'unchecked'}`;
                    analytics.eventTrack(event);
                }}
                label={<Typography variant="body1">Include listings with unspecified story data</Typography>}
                checkedIcon={<Checked/>}
                icon={<Unchecked/>}
                style={{display: 'flex', alignSelf: 'start', ...(isXs && {paddingTop: 0})}}
            />
            <Typography variant="body2"
                style={{
                    color: theme.palette.lr_colors.grey_dark,
                    marginLeft: '30px',
                }}>
                        On occasion, Robin will find homes where data pertaining to stories, floors, or levels was not included in the original listing. By checking this box, we’ll include these listings in your filtered search.
            </Typography>
        </>
    );
}

export default observer(UnspecifiedListingsStoriesCheckbox);
