// eslint-disable-next-line
import Logger from 'js-logger';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid, Typography, Divider, withWidth, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const Container = styled.div`
    margin-top: 36px;
`;
const StyledTypography = styled(Typography)`
    width: 40%;
    display: inline-block;
    vertical-align: top;
`;
const StyledValue = styled(StyledTypography)`
    width: 60%;
    vertical-align: top;
    font-weight: 500;
`;
const ItemContainer = withTheme(styled.div`
    width: ${({ singleColumn })=> (singleColumn ? '100%' : '50%')};
    padding-right: ${({ paddingRight })=> (paddingRight ? '20px' : '0px')};

    ${({ theme })=> theme.breakpoints.only('xs')} {
        width: 100%;
    }
`);
const StyledDivider = styled(Divider)`
    margin: 12px 0;
    background-color: #f7f7f7;
`;

function Details(props) {
    const { width, singleColumn } = props;
    let { data } = props;

    if(width === 'xs' || singleColumn) {
        if(!singleColumn) {
            const data1 = data.map((row)=> row[0]);
            const data2 = data.reduce((acc, row)=> {
                if(row[1]) return [...acc, row[1]];
                return acc;
            }, []);
            data = [].concat([], data1, data2);
        }
        return (
            <Container>
                {data.map((item, i)=> (
                    <Fragment key={i}>
                        {i !== 0 && <StyledDivider />}

                        <ItemContainer singleColumn={singleColumn}>
                            <StyledTypography variant="body2">{item.label}:</StyledTypography>
                            <StyledValue variant="body2">{item.value}</StyledValue>
                        </ItemContainer>
                    </Fragment>
                ))}
            </Container>
        );
    } else {
        return (
            <Container>
                {data.map(([item1, item2], i)=> (
                    <Fragment key={i}>
                        {i !== 0 && <StyledDivider />}

                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <ItemContainer paddingRight={true}>
                                <StyledTypography variant="body2">{item1.label}:</StyledTypography>
                                <StyledValue variant="body2">{item1.value}</StyledValue>
                            </ItemContainer>

                            {/** Put a check here just in case there is a row with no data for the second item */}
                            {!!item2 && (
                                <ItemContainer>
                                    <StyledTypography variant="body2">{item2.label}:</StyledTypography>
                                    <StyledValue variant="body2">{item2.value}</StyledValue>
                                </ItemContainer>
                            )}
                        </Grid>
                    </Fragment>
                ))}
            </Container>
        );
    }
}
Details.propTypes = {
    data: PropTypes.array.isRequired,
    singleColumn: PropTypes.bool,
};

export default withWidth()(Details);
