export default {
    data: [
        {
            'category': 'groceries',
            'place': {
                'name': 'El Metate Market #2',
                'distance': '0.5'
            }
        },
        {
            'category': 'atm',
            'place': {
                'name': 'RockItCoin Bitcoin ATM',
                'distance': '0.5'
            }
        },
        {
            'category': 'gas',
            'place': {
                'name': '76',
                'distance': '0.5'
            }
        },
        {
            'category': 'gym',
            'place': {
                'name': 'Enderby Field',
                'distance': '0.5'
            }
        },
        {
            'category': 'cleaners',
            'place': {
                'name': 'Villa Park Cleaners',
                'distance': '0.7'
            }
        },
        {
            'category': 'pharmacy',
            'place': {
                'name': 'Pharmacy Alternative Ca',
                'distance': '0.9'
            }
        },
        {
            'category': 'coffee',
            'place': {
                'name': 'Starbucks',
                'distance': '1.0'
            }
        },
        {
            'category': 'hospital',
            'place': {
                'name': 'NewportCare Medical Group',
                'distance': '2.2'
            }
        },
        {
            'category': 'movie'
        }
    ],
    nearbyPlaces: null,
    isLoading: false,
};
