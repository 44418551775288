export const zillow = (path)=> {
    // homedetails/401-S-Cooper-St-California-MO-65018/114959559_zpid/
    const matchedObject = path?.split('/');
    let address;

    if(matchedObject) {
        const [, addressPart] = matchedObject;
        address = addressPart?.replace(/-/g, ' ');
    }
    
    return address;
};
