import React from 'react';
import { useTheme } from '@material-ui/core';

export const Disclaimer = function({ fullName }) {
    const theme = useTheme();

    return (
        <div style={{width: '100%', color: theme.palette.lr_colors.steak_sauce, fontSize: theme.typography.caption.fontSize}}>
            Real Estate Professionals like {fullName} provide important
            insights to your monthly report. By clicking "subscribe," you agree to ListReports {' '}
            <a
                style={{color: theme.palette.lr_colors.steak_sauce}}
                href="https://welcome.listreports.com/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
            >
               terms of service
            </a> and {' '}
            <a
                style={{color: theme.palette.lr_colors.steak_sauce}}
                href="https://welcome.listreports.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
            >
                privacy policy
            </a>. Your email address will be shared with {fullName}.
        </div>
    );
};

export const DisclaimerAgentBranding = function({ fullName }) {
    const theme = useTheme();

    return (
        <div style={{width: '100%', color: theme.palette.lr_colors.steak_sauce, fontSize: theme.typography.caption.fontSize}}>
            By clicking "subscribe," you agree to ListReports {' '}
            <a
                style={{color: theme.palette.lr_colors.steak_sauce}}
                href="https://welcome.listreports.com/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
            >
                terms of service
            </a> and {' '}
            <a
                style={{color: theme.palette.lr_colors.steak_sauce}}
                href="https://welcome.listreports.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
            >
                privacy policy
            </a>.
            Your email address will be shared with {fullName}.
        </div>
    );
};
