import { useInfiniteQuery } from 'react-query';
import { benoitApi } from 'apis';

export function useBoardCardCollaborations(boardId, type, limit = 20) {
    return useInfiniteQuery(
        ['board-collaborations', boardId, type],
        async ({ pageParam = 0 })=> {
            const data = await benoitApi.getAgentActivityFeed(boardId, null, {
                params: {
                    type: type,
                    include_user: 'true',
                    offset: pageParam,
                    limit: limit,
                },
            });
            return data;
        },
        {
            getNextPageParam: (lastPage, allPages)=> {
                return lastPage.length === limit ? allPages.flat().length : false;
            },
        }
    );
}
