import React, { useRef, useContext } from 'react';
import { observable, decorate, action, computed, flow } from 'mobx';
import { useStore } from '../../mobx-store';
import { asyncRequest, AsyncRequest } from '../../mobx-store/utils/async-request';
import {benoitApi} from '../../apis';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

const DEFAULT_COBUYER_STATE = 'list';

class CobuyerStore {
    isFormValid = false;
    state = DEFAULT_COBUYER_STATE;
    submitting = false;
    inviteSource = 'board_menu_cobuyer';
    inviteError = null;
    constructor(rootStore){
        this.rootStore = rootStore;
    }

    resetState(){
        this.state = DEFAULT_COBUYER_STATE;
        this.isFormValid = false;
        this.inviteSource = 'board_menu_cobuyer';
        this.inviteError = null;
    }

    setInviteSource(inviteSource){
        this.inviteSource = inviteSource;
    }

    setIsFormValid(isValid){
        this.isFormValid = isValid;
    }

    get isInviteState(){
        return this.state === 'invite';
    }

    get isListState(){
        return this.state === 'list';
    }

    get canSubmit() {
        return !this.isInviteState || this.isFormValid;
    }

    onInviteSubmit = (data)=> this.submitInvite(data);

    submitInvite = flow(function*(data){
        this.submitting = true;
        const { boardsStore, UserStore } = this.rootStore;
        const { activeBoard } = boardsStore;
        let userInvite;
        try {
            userInvite = yield activeBoard.createInvite({...data, meta: {label: 'co-buyer'}});
            this.resetState();
            UserStore.completeInviteBuyerOnboarding();
        } catch (err) {
            this.inviteError = err.response.data.errors[0].detail;
        }
        this.submitting = false;
        return userInvite;
    });

    emailAvailable = asyncRequest(AwesomeDebouncePromise(async (email)=> {
        const exists = await benoitApi.userExists(email);
        return !exists;
    }, 300));

    onInviteCobuyer(){
        if(this.isListState){
            this.state = 'invite';
            return;
        }
    }

    setCobuyerState(state){
        this.state = state;
    }
}

decorate(CobuyerStore, {
    isFormValid: observable,
    state: observable,
    submitting: observable,
    inviteSource: observable,
    inviteError: observable,
    resetState: action,
    setIsFormValid: action,
    isInviteState: computed,
    isListState: computed,
    canSubmit: computed,
    setInviteSource: action,
    onInviteSubmit: action,
    onInviteCobuyer: action,
    setCobuyerState: action,
});



class UpdateUserRequest extends AsyncRequest {

    asyncRequest(userData) {
        return benoitApi.updateUser(userData);
    }

    get firstError() {
        return this.errorData && this.errorData.errors?.length && this.errorData.errors[0];
    }

    get hasDuplicateEmailError() {
        return this.firstError && this.firstError.detail.includes('already exists') && this.firstError.detail.includes('email');
    }

    get hasDuplicatePhoneError() {
        return this.firstError && this.firstError.detail.includes('already exists') && this.firstError.detail.includes('phone');
    }

    get hasInvalidPhoneError() {
        return this.firstError && this.firstError.detail.includes('is not a valid phone number') && this.firstError.detail.includes('phone');
    }
}

decorate(UpdateUserRequest, {
    firstError: computed,
    hasDuplicateEmailError: computed,
    hasDuplicatePhoneError: computed,
    hasInvalidPhoneError: computed,
});



class UserPreferencesRequest extends AsyncRequest {
    asyncRequest({data, preferenceId}) {
        if(preferenceId && data){
            return benoitApi.updateUserPreferences(data, preferenceId);
        } else if(data ){
            return benoitApi.createUserPreferences(data);
        } else {
            return;
        }
    }
}

class ProfileStore {
    isFormValid = false;
    submitting = false;
    formRef = null;
    updateUserRequest = new UpdateUserRequest();
    userPreferencesRequest = new UserPreferencesRequest();
    constructor(rootStore){
        this.rootStore = rootStore;
    }

    updateUser = flow(function*(data){
        this.updateUserRequest.reset();
        yield this.updateUserRequest.fetch(data);
        if(data?.meta?.how_soon === 'within 3 months') {
            yield this.rootStore.UserStore.warmUpHubspotLeadToHot();
        }
        return;
    });

    updateUserPreferences = flow(function*(data, preferenceId){
        this.userPreferencesRequest.reset();
        yield this.userPreferencesRequest.fetch({data, preferenceId});
        return;
    });

    setIsFormValid(isValid){
        this.isFormValid = isValid;
    }

    get canSubmit() {
        return this.isFormValid;
    }

    saveChanges = flow(function*(data, preferences, preferenceId) {
        this.submitting = true;
        yield this.updateUser(data);
        yield this.updateUserPreferences(preferences, preferenceId);
        this.submitting = false;
    })
}

decorate(ProfileStore, {
    formRef: observable,
    isFormValid: observable,
    submitting: observable,
    setIsFormValid: action,
    canSubmit: computed,
});
class AccountSettingsStore {
    currentTab = 'profile';

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.cobuyerStore = new CobuyerStore(rootStore);
        this.profileStore = new ProfileStore(rootStore);
    }

    get isProfileOpened(){
        return this.currentTab === 'profile';
    }

    get isCobuyersOpened(){
        return this.currentTab === 'co-buyers';
    }

    get isSettingsOpened(){
        return this.currentTab === 'notifications';
    }

    setTab(tab){
        this.currentTab = tab;
        this.cobuyerStore.resetState();
    }

    async onInvite(data){
        await this.cobuyerStore.onInviteCobuyer(data);
    }
}

decorate(AccountSettingsStore, {
    currentTab: observable,
    isProfileOpened: computed,
    isCobuyersOpened: computed,
    isSettingsOpened: computed,
    setTab: action,
    onInvite: action,
});


const AccountSettingsContext = React.createContext();

export function useAccountSettingsStore() {
    const rootStore = useStore();
    const storeRef = useRef(new AccountSettingsStore(rootStore));
    return storeRef.current;
}

export function AccountSettingsStoreProvider({ accountSettingsStore, children }) {
    return <AccountSettingsContext.Provider value={accountSettingsStore}>{children}</AccountSettingsContext.Provider>;
}

export function useAccountSettingsStoreContext() {
    return useContext(AccountSettingsContext);
}
