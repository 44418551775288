import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, useTheme } from '@material-ui/core';
import {  useParams } from 'react-router-dom';
import { useAgent } from 'hooks/useAgent';
import moment from 'moment';
import { useMLSComplianceByListingIdArray } from 'hooks/useBoardListpacks';
import { COLOR_DISCLAIMER_GREY } from '../../constants';
import formatPhoneNumber from 'services/format-phone-number';

export const ListpackComplianceTerms = observer(({ listings, mlsSources, ...props})=> {
    const theme = useTheme();
    const { affiliateCode } = useParams();
    const { data: agent } = useAgent(affiliateCode, { priority: 'affiliate' });
    const { company, office_phone, phone_formatted, licensed_address: { street, state, zip, city } = {} } = agent || {};
    const listingIdsArr = useMemo(()=> mlsSources.map((source)=> source.listing_details?.id), [mlsSources]);
    const { data, isLoading } = useMLSComplianceByListingIdArray(listingIdsArr, affiliateCode);
    const complianceTermsList = useMemo(()=> data?.filter((e)=> e.searchDetailsCompliance),[data]);
    const len = complianceTermsList?.length;

    const lastUpdatedAtMlsSource= (mlsSources || [])
        .filter((el)=> el?.listing_details?.updatedAt)
        .map((el)=> el.listing_details.updatedAt)
        .sort()
        .pop() ?? '';
    const formattedUpdatedAt = moment(new Date(lastUpdatedAtMlsSource)).format('M/D/YY');

    return (
        <>
            <Box textAlign='center'>
                <Typography
                    variant='caption'
                    style={{ color: COLOR_DISCLAIMER_GREY }}
                >
                    Last Updated: {formattedUpdatedAt}
                </Typography>
            </Box>
            <Box textAlign='center'>
                <Typography
                    variant='caption'
                    style={{ color: COLOR_DISCLAIMER_GREY }}
                >
                    Local real estate services provided by
                </Typography>
            </Box>

            <Box
                fontSize="caption.fontSize"
                color={COLOR_DISCLAIMER_GREY}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Box>{company}</Box>
                {(office_phone || phone_formatted) && (<Box ml={1} mr={1}>|</Box>)}
                {(office_phone || phone_formatted) && (<Box>{formatPhoneNumber(office_phone, { withParentheses: true }) || phone_formatted}</Box>)}
                {street && city && (<Box ml={1} mr={1}>|</Box>)}
                {street && city && (<Box> {street}, {city}, {state} {zip}</Box>)}
            </Box>

            {len === 0 && !isLoading &&
                <Box py={1}>
                    <Typography
                        variant='caption'
                        style={{ color: COLOR_DISCLAIMER_GREY }}
                    >
                        All data, including all measurements and calculations of area, is obtained from various sources
                        and has not been, and will not be, verified by broker or MLS. All information should be
                        independently reviewed and verified for accuracy. Properties may or may not be listed by the
                        office/agent presenting the information.
                    </Typography>
                </Box>
            }

            {len === 1 && !isLoading &&
                <Box py={1}>
                    {complianceTermsList[0]?.logo && 
                        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                            <img src={complianceTermsList[0].logo} alt="logo" style={{ maxWidth: '250px' }} />
                        </Box>
                    }
                    <Box py={1}>
                        <Typography
                            variant='caption'
                            style={{ color: COLOR_DISCLAIMER_GREY }}
                            dangerouslySetInnerHTML={{__html: complianceTermsList[0]?.searchDetailsCompliance}}
                        />
                    </Box>
                </Box>
            }

            {len > 1 && !isLoading &&
                <Box py={1}>
                    {complianceTermsList.map((term)=> {
                        if(term.logo) {
                            return (
                                <Box key={term.listingKey} py={1}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                        <img
                                            src={term.logo} alt="logo"
                                            style={{ maxWidth: '250px', marginRight: theme.spacing(1) }}
                                        />
                                    </Box>
                                    <Typography
                                        variant='caption'
                                        style={{ color: COLOR_DISCLAIMER_GREY }}
                                        dangerouslySetInnerHTML={{__html: term.searchDetailsCompliance}}
                                    />
                                </Box>
                            );
                        } else {
                            return (
                                <Box key={term.listingKey} py={1}>
                                    <Typography
                                        variant='caption'
                                        style={{ color: COLOR_DISCLAIMER_GREY }}
                                        dangerouslySetInnerHTML={{__html: term.searchDetailsCompliance}}
                                    />
                                </Box>
                            );
                        }
                    })}
                </Box>
            }

        </>
    );
});