import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';

/**
 * https://developers.google.com/maps/documentation/staticmaps/intro#api_key
*/

export const IMAGE_FORMATS = {
    // png8 or png (default) specifies the 8-bit PNG format.
    PNG: 'png',
    // png32 specifies the 32-bit PNG format.
    PNG32: 'png32',
    // gif specifies the GIF format.
    GIF: 'gif',
    // jpg specifies the JPEG compression format.
    JPG: 'jpg',
    // jpg-baseline specifies a non-progressive JPEG compression format.
    JPG_BASELINE: 'jpg-baseline',
};

export const MAP_TYPES = {
    // roadmap (default) specifies a standard roadmap image, as is normally shown on the Google Maps website.
    ROADMAP: 'roadmap',
    // satellite specifies a satellite image.
    SATELLITE: 'satellite',
    // terrain specifies a physical relief map image, showing terrain and vegetation.
    TERRAIN: 'terrain',
    // hybrid specifies a hybrid of the satellite and roadmap image,
    // showing a transparent layer of major streets and place names on the satellite image.
    HYBRID: 'hybrid',
};

const Container = styled(Box)`
    ${({ background, backgroundColor })=> css`
        background: ${background};
        background-color: ${backgroundColor || '#ccc'};
        background-size: cover;
        background-position: center;
    `}
`;

const ROOT_URL = 'http://maps.googleapis.com/maps/api/staticmap';

export const StaticGoogleMaps = observer(function StaticGoogleMaps({
    latitude= 33.78724,
    longitude= -117.85496,
    width=500,
    height=400,
    zoom=19,
    format=IMAGE_FORMATS.PNG,
    mapType=MAP_TYPES.SATELLITE,
    styles,
    ...props
}) {
    const apiKey = `key=${process.env.REACT_APP_GOOGLE_API_KEY}` || '';
    const staticMapUrl = `${ROOT_URL}?center=${latitude || 33.78724},${longitude || -117.85496}&zoom=${zoom}&size=${width || 500}x${height || 500}&maptype=${mapType}&format=${format}&&${apiKey}`;

    return (
        <Container position="relative" background={`url('${staticMapUrl}')`}
            style={{width: '100%', height: '100%', ...styles}}>
            {props.children}
        </Container>
    );
});