import React from 'react';
import { SvgIcon } from '@material-ui/core';

function SortIcon(props) {
    return (
        <SvgIcon {...props}>
            <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g transform="translate(-882.000000, -25.000000)" stroke="#1A1A1A">
                    <g transform="translate(299.000000, 0.000000)">
                        <g transform="translate(543.000000, 24.000000)">
                            <g transform="translate(41.000000, 2.000000)">
                                <g>
                                    <polyline points="8 4 4 0 0 4"></polyline>
                                    <path d="M4,1 L4,13"></path>
                                </g>
                                <g transform="translate(12.000000, 6.500000) scale(1, -1) translate(-12.000000, -6.500000) translate(8.000000, 0.000000)">
                                    <polyline points="8 4 4 0 0 4"></polyline>
                                    <path d="M4,1 L4,13"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default SortIcon;
