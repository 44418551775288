import React from 'react';
import lodashGet from 'lodash/get';
import { Box } from '@material-ui/core';
import {
    COLOR_COMPLIANCE_GREY,
    COLOR_BORDER_GREY
} from '../../../constants';

function SponsorCompliance({ sponsor, isMobile }) {
    if(!lodashGet(sponsor, 'compliance_text')) return null;
    return (
        <Box maxWidth="100%"
            color={COLOR_COMPLIANCE_GREY}
            px={isMobile ? 3 : 2}
            py={isMobile ? 2 : 3/2}
            fontSize="11px"
            lineHeight={1.2}
            display="flex"
            flexDirection={isMobile ? 'column': 'row'}
            alignItems="center"
            justifyContent={isMobile ? 'flex-start' : 'space-between'}
        >
            <Box flex={1}
                borderTop={isMobile ? `1px solid ${COLOR_BORDER_GREY}`: 'none'}
                minWidth={isMobile ? '100%' : 'auto'}
                textAlign="justify"
                pt={isMobile ? 2 : 0}
                mr={1}
            >
                {sponsor.compliance_text}
            </Box>

            <Box width={32} height={32}
                mt={isMobile ? 2 : 0}
            >
                <img src={`http://edge.listreports.com.s3.amazonaws.com/compliance/${sponsor.equal_housing_type}/logo_black.png`}
                    height={32}
                    alt="Equal Housing"
                />
            </Box>
        </Box>
    );
}

export default SponsorCompliance;
