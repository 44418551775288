import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import { withTheme } from '@material-ui/styles';
import Logger from 'js-logger';

export * from './LRInputOutlined';
export * from './LRInputSelect';
export * from './LRFormControl';


const ForwardedTextField = React.forwardRef(function ForwardedTextField({ FormHelperTextProps, ...props }, ref) {
    return <TextField ref={ref} {...props} classes={{ root: 'LRInput-root' }} FormHelperTextProps={{ classes: { root: 'LRInput-HelperText-root' } }} />;
});

// DEPRECATED FOR NEW CODE: use LRInputOutlined instead
const LRInput = withTheme(styled(ForwardedTextField)`
    & input {
        -webkit-appearance: none;
        padding-top: 14.5px;
        padding-bottom: 14.5px
    }

    & .MuiOutlinedInput-notchedOutline {
        border-radius: 2px;
        border-color: #dddddd;
    }

`);
export default LRInput;
