const BRAND_ICONS = {
    atm: {
        BankOfAmerica: '/assets/brand_icons/BankOfAmerica.png',
        BankOfCalifornia: '/assets/brand_icons/BankOfCalifornia.png',
        Chase: '/assets/brand_icons/Chase.png',
        WellsFargo: '/assets/brand_icons/WellsFargo.png',
    },
    coffee: {
        CoffeeBean: '/assets/brand_icons/CoffeeBean.png',
        DunkinDonuts: '/assets/brand_icons/DunkinDonuts.png',
        PeetsCoffeeandTea: '/assets/brand_icons/PeetsCoffeeandTea.png',
        Starbucks: '/assets/brand_icons/Starbucks.png',
    },
    gym: {
        '24HourFitness': '/assets/brand_icons/24HourFitness.png',
        GoldsGym: '/assets/brand_icons/GoldsGym.png',
        LAFitness: '/assets/brand_icons/LAFitness.png',
    },
    'gas': {
        '76': '/assets/brand_icons/76.png',
        Arco: '/assets/brand_icons/Arco.png',
        Chevron: '/assets/brand_icons/Chevron.png',
        Exxon: '/assets/brand_icons/Exxon.png',
        Mobil: '/assets/brand_icons/Mobil.png',
        Shell: '/assets/brand_icons/Shell.png',
        Texaco: '/assets/brand_icons/Texaco.png',
        Valero: '/assets/brand_icons/Valero.png',
    },
    groceries: {
        Albertsons: '/assets/brand_icons/Albertsons.png',
        Costco: '/assets/brand_icons/Costco.png',
        Krogers: '/assets/brand_icons/Krogers.png',
        Ralphs: '/assets/brand_icons/Ralphs.png',
        Safeway: '/assets/brand_icons/Safeway.png',
        Target: '/assets/brand_icons/Target.png',
        Walmart: '/assets/brand_icons/Walmart.png',
        WholeFoods: '/assets/brand_icons/WholeFoods.png',
        Winco: '/assets/brand_icons/Winco.png',
    },
    pharmacy: {
        Albertsons: '/assets/brand_icons/Albertsons.png',
        Target: '/assets/brand_icons/Target.png',
        CVS: '/assets/brand_icons/CVS.png',
        DuaneReade: '/assets/brand_icons/DuaneReade.png',
        SavOn: '/assets/brand_icons/SavOn.png',
        Walgreens: '/assets/brand_icons/Walgreens.png',
    },
    movie: {
        AMC: '/assets/brand_icons/AMC.png',
        Cinemark: '/assets/brand_icons/Cinemark.png',
        Regal: '/assets/brand_icons/Regal.png',
    },
};

export default BRAND_ICONS;
