import React from 'react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../../../mobx-store';
import Confetti from '../../../Confetti';
import { useOnboarding } from '../../store';
import { clearShepherdOverlay, delay, restoreShepherdOverlay, simulateClick, waitForElement } from '../../utils';
import { useResponsiveBreakpoint } from '../../../../hooks';
import { useDimissibleOverlay } from '../../hooks';

function createSteps(tour, { user, LanesStore, activeBoard, history, onboarding, isXs }) {
    let steps = [];
    let boardCards = activeBoard.notArchivedBoardCards;
    boardCards = boardCards.filter((boardCard)=> !boardCard.inHandselectedSearch);
    const listpacks = activeBoard.notArchivedListpacks;

    if(boardCards.length === 0 && listpacks.length === 0) {
        return [
            {
                id: 'needs-homes',
                title: 'Get inspired by homes on your board',
                text: 'It looks like you don’t have any homes on your board. Create a saved search or add a listing you found elsewhere with the add home button',
                buttons: [
                    {
                        text: 'I’ll do this later',
                        action: function() {
                            onboarding.stop();
                        },
                    },
                ],
                cancelIcon: {
                    enabled: false,
                },
                classes: 'shepherd-wide-tooltip'
            },
            {
                id: 'dream-board-modal',
                beforeShowPromise() {
                    tour.options.useModalOverlay = false;
                },
                analytics: {
                    nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
                },
                classes: 'hidden',
            },
            {
                id: 'stop',
                beforeShowPromise() {
                    onboarding.stop();
                },
                classes: 'hidden',
            },
        ];
    } else if(boardCards.length === 0) {
        steps = steps.concat([
            {
                id: 'highlight-listpack-card',
                title: 'Get inspired by any home',
                text: `
                Whether it’s in a saved search or on your board already, you can view the photo gallery for any home and save photos to your Idea Board for inspiration.
                <br/><br/>
                <strong>Click on this home to see how it works.</strong>
                `,
                advanceOn: {
                    event: 'click',
                    selector: '.tour-target-my-listpack-card',
                },
                attachTo: {
                    element: '.tour-target-my-listpack-card',
                    on: isXs ? 'bottom' : 'right',
                },
                beforeShowPromise() {
                    if(isXs) {
                        history.push('/boards/lane/0/your-searches');
                        return delay(400);
                    }
                    return delay(400);
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ],
                },
                scrollTo: true,
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_fre_onboarding_saved_search_next_click',
                },
            },
            {
                id: 'highlight-listpack-listing-card',
                title: `View this home <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM8 8C8 6.34 9.34 5 11 5C12.66 5 14 6.34 14 8C14 9.66 12.66 11 11 11C9.34 11 8 9.66 8 8Z" fill="#1A1A1A"/>
                </svg>
                `,
                text: 'Hover your mouse over the home and select the view option to see its details.',
                attachTo: {
                    element: '.tour-target-listpack-listing-card',
                    on: 'bottom',
                },
                advanceOn: {
                    selector: '.tour-target-listpack-listing-card',
                    event: 'click',
                },
                beforeShowPromise() {
                    return waitForElement('.tour-target-listpack-listing-card');
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ],
                },
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
                },
            },
        ]);
    } else if(boardCards.length > 0) {
        steps = steps.concat([
            {
                id: 'highlight-board-card',
                title: 'Get inspired by any home',
                text: `
                Whether it’s in a saved search or on your board already, you can view the photo gallery for any home and save photos to your Idea Board for inspiration.
                <br/><br/>
                <strong>Click on this home to see how it works.</strong>
                `,
                advanceOn: {
                    selector: '.tour-target-lane-card-link',
                    event: 'click',
                },
                attachTo: {
                    element: '.tour-target-lane-card-link',
                    on: isXs ? 'bottom' : 'left'
                },
                beforeShowPromise() {
                    if(isXs) {
                        const boardCard = boardCards[0];
                        const lane = LanesStore.lanesById[boardCard.laneId];
                        history.push(`/boards/lane/${lane.id}/${lane.name}`);
                        return delay(400);
                    }
                    return delay(400);
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ],
                },
                cancelIcon: {
                    enabled: false,
                },
                analytics: {
                    nextEvent: 'robin_fre_onboarding_saved_search_next_click',
                },
            },
        ]);
    }
    steps = steps.concat([
        {
            id: 'highlight-listing-image',
            title: 'Open this home’s gallery',
            text: 'Select the cover image to view all the photos.',
            attachTo: {
                element: '.tour-target-listing-image',
                on: 'bottom'
            },
            advanceOn: {
                selector: '.tour-target-listing-image',
                event: 'click'
            }, 
            beforeShowPromise() {
                tour.options.useModalOverlay = true;
                return waitForElement('.tour-target-listing-image');
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -50],
                        },
                    },
                ],
            },
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
            },
            when: {
                show: ()=> {
                    clearShepherdOverlay();
                },
                hide: ()=> {
                    restoreShepherdOverlay();
                },
            },
        },
        {
            id: 'highlight-listing-image-pin',
            title: `Try saving this photo <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3C7.49497 3 7.0692 3.37657 7.00749 3.87782C6.94579 4.37907 7.26751 4.84765 7.75746 4.97014C7.98407 5.02679 8.34712 5.17362 8.6301 5.39718C8.90681 5.61578 9 5.81411 9 6L9 9C9 9.13232 8.93626 9.41833 8.7625 9.65C8.62061 9.83919 8.40603 10 8 10C6.54246 10 5.53039 10.9143 4.94126 11.7243C4.63866 12.1404 4.41383 12.5715 4.26233 12.9408C4.12904 13.2657 4 13.6684 4 14C4 14.2652 4.10536 14.5196 4.29289 14.7071C4.48043 14.8946 4.73478 15 5 15L19 15C19.5523 15 20 14.5523 20 14C20 13.6684 19.871 13.2657 19.7377 12.9408C19.5862 12.5715 19.3613 12.1404 19.0587 11.7243C18.4696 10.9143 17.4575 10 16 10C15.594 10 15.3794 9.83918 15.2375 9.65C15.0637 9.41832 15 9.13232 15 9L15 6C15 5.81411 15.0932 5.61578 15.3699 5.39718C15.6529 5.17362 16.0159 5.02679 16.2425 4.97014C16.7325 4.84765 17.0542 4.37907 16.9925 3.87782C16.9308 3.37657 16.505 3 16 3H8ZM11 21L12 22L13 21V16H12H11V21Z" fill="black"/>
            </svg>
            `,
            text: 'This will add a photo to your Idea Board.',
            attachTo: {
                element: '.tour-target-listing-image-gallery-modal .tour-target-pin-listing-image.pin',
                on: isXs ? 'bottom' : 'left'
            },
            advanceOn: {
                selector: '.tour-target-listing-image-gallery-modal .tour-target-pin-listing-image.pin',
                event: 'click',
            },
            beforeShowPromise() {
                return Promise.resolve()
                    .then(()=> waitForElement('.tour-target-listing-image-gallery-modal .tour-target-pin-listing-image.pin'))
                    .then(()=> delay(100));
                ;
            },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 30],
                        },
                    },
                ],
            },
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
            },
            when: {
                show: ()=> {
                    clearShepherdOverlay();
                },
                hide: ()=> {
                    restoreShepherdOverlay();
                },
            },
        },
        {
            id: 'pinned-modal',
            title: 'Saved! Now see the Idea Board.',
            text: 'You can always unpin photos later. For now, check out where pinned photos live.',
            beforeShowPromise() {
                return waitForElement('.tour-target-listing-image-gallery-modal .tour-target-pin-listing-image');
            },
            buttons: [
                {
                    text: 'View Idea Board',
                    action: function() {
                        history.push('/boards/idea-board');
                        tour.next();
                    },
                },
            ],
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
            },
            classes: 'shepherd-wide-tooltip',
        },
        ...(isXs ? [
            {
                id: 'dream-board-modal',
                beforeShowPromise() {
                    tour.options.useModalOverlay = false;
                },
                analytics: {
                    nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
                },
                classes: 'hidden',
            }
        ] : [
            {
                id: 'close-page',
                title: 'You\'re all set!',
                text: 'You\'re all set! Let\'s head back to your board 🎉"',
                attachTo: {
                    element: '.board-btn',
                    on: 'bottom',
                },
                advanceOn: {
                    selector: '.board-btn',
                    event: 'click'
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                },
                cancelIcon: {
                    enabled: false,
                },
                beforeShowPromise() {
                    tour.options.useModalOverlay = true;
                    return delay(400);
                },
                when: {
                    show: ()=> {
                        document.querySelector('.shepherd-modal-overlay-container path')
                            .addEventListener('click', (event)=> {
                                event.preventDefault();
                                event.stopPropagation();
                            });
                        clearShepherdOverlay();
                    },
                    hide: ()=> {
                        restoreShepherdOverlay();
                    },
                }
            }
        ]),
        {
            id: 'highlight-idea-board-button',
            title: 'Visit the Idea Board any time',
            text:
                'You (and anyone invited to your board) can use the Idea Board to keep track of preferred design, architecture, landscaping... anything that sparks inspiration can be saved and shared here.',
            attachTo: {
                element: '.tour-target-navbar-idea-button',
                on: 'bottom',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Finish Tour',
                    action: tour.next,
                },
            ],
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 30],
                        },
                    },
                ],
            },
            beforeShowPromise() {
                tour.options.useModalOverlay = true;
            },
            cancelIcon: {
                enabled: false,
            },
            analytics: {
                nextEvent: 'robin_fre_onboarding_move_card_maybes_selected',
            },
        },
    ]);

    return steps;
}

export const PinPhotoFlow = observer(function() {
    const { LanesStore, UserStore, boardsStore } = useStore();
    const activeBoard = boardsStore.activeBoard;
    const history = useHistory();
    const [showConfetti, setShowConfetti] = useState(false);
    const isXs = useResponsiveBreakpoint('xs');

    const onboarding = useOnboarding();

    useDimissibleOverlay();

    useEffect(()=> {
        if(!onboarding || !isXs) return;

        window.onpopstate = function(event) {
            if(onboarding.stepId !== 'final') {
                onboarding.stop();
            };
        };
        return ()=> { window.onpopstate = ()=> {}; };

    }, [onboarding, isXs]);

    useEffect(()=> {
        const steps = createSteps(onboarding.tour, { user: UserStore.user, LanesStore, activeBoard, history, onboarding, isXs });
        onboarding.addSteps(steps);
        onboarding.start();
        return function() {
            onboarding.reset();
        };
    }, [onboarding, UserStore]);

    useEffect(()=> {
        let timeout = null;
        function handleComplete() {
            setShowConfetti(true);
            // UserStore.completeInviteBuyerOnboarding();
            simulateClick(isXs ? '.tour-target-mobile-footer-agent-toggle' : '.tour-target-leftsidebar-toggle');
            timeout = setTimeout(()=> {
                onboarding.stop();
            }, 20000);
        }

        onboarding.registerTourEvent('complete', handleComplete);
        return function() {
            onboarding.unregisterTourEvent('complete', handleComplete);
            if(timeout) {
                clearTimeout(timeout);
            }
        };
    }, [onboarding, UserStore]);

    if(showConfetti) {
        return <Confetti iterationCount={2} />;
    }

    return null;
});
