import React, { useMemo, useState } from 'react';
import { Box, useTheme, Dialog, Typography, CircularProgress, Popover, Grid, Hidden, Divider } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { Close } from '@material-ui/icons';
import { Price } from './Price';
import { BedsContent } from '../Filters/Beds';
import { BathsContent } from '../Filters/Baths';
import { HomeTypeContent } from '../Filters/HomeType';
import { EverythingElseSection, MoreBasicsContent } from '../Filters/More';
import { withTheme } from '@material-ui/core/styles';
import { SaveFilter } from './SaveFilter';
import { Area } from './Area';
import styled from 'styled-components';
import { useRFHInput } from '../../../../hooks';
import LRLink from '../../../../components/LRLink';
import LRButton from '../../../../components/LRButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FilterMoreActions } from './style';
import { COLOR_BLACK, COLOR_STEAK_SAUCE } from '../../../../constants';
import analytics from '../../../../services/analytics';
import { useOnboarding } from 'components/Onboarding';
import { observer } from 'mobx-react-lite';
import Logger from 'js-logger';
import { isArray, omit, omitBy } from 'lodash';
import flat from 'flat';
import abbr from 'number-abbreviate';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

const StyledTitleSpan = withTheme(styled.span`
    font-size: 24px;
    font-weight: bold;
    ${({ theme })=> theme.breakpoints.down('xs')} {
        font-size: 20px;
    }
`);

const HeaderContent = styled.div`
    padding: 0 16px;
    height: 56px;
    border-bottom: 1px solid rgb(216, 216, 216);
`;

export function MobileMoreFilters({
    open,
    onClose,
    showLoading,
    listingsCount,
    setSaveOpenModal,
    handleResetFilters,
    trackFilterSet,
}) {
    const formMethods = useFormContext();
    const theme = useTheme();
    const { value: withSingleLevelHomes, handleChange: handleWithSingleLevelHomes } = useRFHInput('fields.tags.single_level_homes', formMethods);
    const { value: withAttachedHomes, handleChange: handleWithAttachedHomes } = useRFHInput('fields.tags.attached_homes', formMethods);
    const { value: withDetachedHomes, handleChange: handleWithDetachedHomes } = useRFHInput('fields.tags.detached_homes', formMethods);
    const { value: withGreatSchools, handleChange: handleWithGreatSchools } = useRFHInput('fields.tags.with_great_schools', formMethods);
    const { value: withHeating, handleChange: handleWithHeating } = useRFHInput('fields.tags.with_heating', formMethods);
    const { value: withCooling, handleChange: handleWithCooling } = useRFHInput('fields.tags.with_cooling', formMethods);
    const { value: newListings, handleChange: handleNewListings } = useRFHInput('fields.tags.new_listings', formMethods);
    const { value: staleListings, handleChange: setStaleListings } = useRFHInput('fields.tags.stale_listings', formMethods);
    const { value: withAttachedGarage, handleChange: handleWithAttachedGarage } = useRFHInput('fields.tags.with_attached_garage', formMethods);
    const { value: withOpenHouses, handleChange: handleWithOpenHouses } = useRFHInput('fields.tags.with_open_houses', formMethods);
    const { value: withUpgradedKitchens, handleChange: handleWithUpgradedKitchens } = useRFHInput('fields.tags.with_upgraded_kitchens', formMethods);
    const { value: withVirtualTours, handleChange: handleWithVirtualTours } = useRFHInput('fields.tags.with_virtual_tours', formMethods);
    const { value: youngerHomes, handleChange: handleYoungerHomes } = useRFHInput('fields.tags.younger_homes', formMethods);
    const { value: priceMin, handleChange: setPriceMin } = useRFHInput('fields.price_min', formMethods);
    const { value: priceMax, handleChange: setPriceMax } = useRFHInput('fields.price_max', formMethods);
    const { value: singleFamily, handleChange: handleSingleFamily } = useRFHInput('fields.hometype.single_family', formMethods);
    const { value: condoTownhome, handleChange: handleCondoTownhome } = useRFHInput('fields.hometype.condo_townhome', formMethods);
    const { value: multiFamily, handleChange: handleMultiFamily } = useRFHInput('fields.hometype.multi_family', formMethods);
    const { value: mobileManufactured, handleChange: handleMobileManufactured } = useRFHInput('fields.hometype.mobile_manufactured', formMethods);
    const { value: land, handleChange: handleLand } = useRFHInput('fields.hometype.land', formMethods);
    const { value: stories, handleChange: handleStories } = useRFHInput('fields.stories', formMethods);
    const { value: lotSizeMin, handleChange: handleLotSizeMinChange } = useRFHInput('fields.lotsize_min', formMethods);
    const { value: lotSizeMax, handleChange: handleLotSizeMaxChange } = useRFHInput('fields.lotsize_max', formMethods);
    const { value: homeSizeMin, handleChange: handleHomeSizeMinChange } = useRFHInput('fields.homesize_min', formMethods);
    const { value: homeSizeMax, handleChange: handleHomeSizeMaxChange } = useRFHInput('fields.homesize_max', formMethods);
    const { value: yearBuiltMin, handleChange: handleYearBuiltMinChange } = useRFHInput('fields.year_built_min', formMethods);
    const { value: yearBuiltMax, handleChange: handleYearBuiltMaxChange } = useRFHInput('fields.year_built_max', formMethods);
    const { value: daysOnMarket, handleChange: handleDaysOnMarket } = useRFHInput('fields.days_on_market', formMethods);
    const { value: schoolScoreMin, handleChange: setSchoolScoreMin } = useRFHInput( 'fields.school_score_min', formMethods);
    const { value: withPool, handleChange: handleWithPool } = useRFHInput( 'fields.tags.with_pool', formMethods);
    const { value: withViews, handleChange: handleWithViews } = useRFHInput( 'fields.tags.with_views', formMethods);
    const { value: valueBeds, handleChange: handleBedsChange } = useRFHInput('fields.bedrooms', formMethods);
    const { value: bathrooms, handleChange: handleBathrooms } = useRFHInput('fields.bathrooms', formMethods);
    const { value: keywords, handleChange: handleKeywordsChange } = useRFHInput('fields.keywords', formMethods);
    const { value: includeListingsWithUnspecifiedData, handleChange: handleIncludeListingsWithUnspecifiedData } = useRFHInput('fields.include_listings_with_unspecified_data', formMethods);

    const fieldsMoreBasics  = {
        stories,
        handleStories,
        lotSizeMin,
        lotSizeMax,
        handleLotSizeMinChange,
        handleLotSizeMaxChange,
        homeSizeMin,
        homeSizeMax,
        handleHomeSizeMinChange,
        handleHomeSizeMaxChange,
        includeListingsWithUnspecifiedData,
        handleIncludeListingsWithUnspecifiedData};
    const fieldsHomeType = {
        singleFamily,
        handleSingleFamily,
        condoTownhome,
        handleCondoTownhome,
        multiFamily,
        handleMultiFamily,
        mobileManufactured,
        handleMobileManufactured,
        land,
        handleLand };
    const fieldsEverythingElse = {
        yearBuiltMin,
        handleYearBuiltMinChange,
        yearBuiltMax,
        handleYearBuiltMaxChange,
        daysOnMarket,
        handleDaysOnMarket,
        schoolScoreMin,
        setSchoolScoreMin,
        withPool,
        handleWithPool,
        withViews,
        handleWithViews,
        withVirtualTours,
        handleWithVirtualTours,
        withOpenHouses,
        handleWithOpenHouses,
        keywords,
        handleKeywordsChange };

    return (
        <Dialog fullScreen open={open}>
            <HeaderContent justifyContent="center" alignItems="center" position="relative">
                <Typography
                    variant="body1"
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    Filters
                </Typography>
                <LRLink
                    position="absolute"
                    top={16}
                    right={16}
                    color={COLOR_BLACK}
                    onClick={()=> {
                        onClose();
                    }}
                >
                    <Close />
                </LRLink>
            </HeaderContent>
            <div style={{maxHeight: 'calc(100vh - 122px)', overflow:'hidden', overflowY: 'auto',height: '100%'}}>
                <div style={{padding: '16px'}}>
                    <Hidden smUp>
                        <Box fontSize="20px" mb={2}>Price</Box>
                        <Box mb={3}>
                            <Price
                                priceMin={priceMin}
                                priceMax={priceMax}
                                setPriceMin={setPriceMin}
                                setPriceMax={setPriceMax}
                                trackFilterSet={trackFilterSet}
                                isMobile
                                p={0}
                            />
                        </Box>
                    </Hidden>

                    <Box fontSize="20px" mb={2}>Beds</Box>
                    <Box mb={3}>
                        <BedsContent
                            value={valueBeds}
                            handleChange={handleBedsChange}
                            trackFilterSet={trackFilterSet}
                        />
                    </Box>
                    <Box fontSize="20px" mb={2}>Baths</Box>
                    <Box mb={3}>
                        <BathsContent
                            bathrooms={bathrooms}
                            handleBathrooms={handleBathrooms}
                            trackFilterSet={trackFilterSet}
                        />
                    </Box>

                    <Box fontSize="20px" mb={2}>
                        Home Type

                        <span style={{marginLeft: '8px', fontSize: '14px', color: COLOR_STEAK_SAUCE }}>Select all that apply</span>
                    </Box>

                    <Box mb={3}>
                        <HomeTypeContent
                            fields={fieldsHomeType}
                            trackFilterSet={trackFilterSet}
                        />
                    </Box>
                    <div style={{margin: `${theme.spacing(5)}px 0 `}}>
                        <Divider/>
                    </div>
                    <Box mb={3}>
                        <MoreBasicsContent
                            fields={fieldsMoreBasics}
                            trackFilterSet={trackFilterSet}
                        />
                    </Box>
                </div>
                <div style={{padding: `0px ${theme.spacing(2)}px ${theme.spacing(3)}px`}}>
                    <Box>
                        <div style={{margin: `${theme.spacing(1)}px 0px ${theme.spacing(4)}px 0px`}}>
                            <Divider/>
                        </div>
                        <EverythingElseSection
                            fields={fieldsEverythingElse}
                            trackFilterSet={trackFilterSet}
                        />
                    </Box>
                </div>
            </div>
            <FilterMoreActions justifyContent={'space-between'}>
                <div>
                    {(showLoading)
                        ?  <CircularProgress size={24} color="primary"  style={{marginRight: theme.spacing(1) }}/>
                        : <Typography variant="body1" style={{color: theme.palette.lr_colors.steak_sauce, marginRight: theme.spacing(1) }}> {(listingsCount === 1) ? `${listingsCount} home`: `${listingsCount} homes`}</Typography>
                    }
                </div>
                <div>
                    <LRButton
                        type="button"
                        variant="outlined"
                        height={36}
                        style={{ marginRight: theme.spacing(1)}}
                        onClick={handleResetFilters}
                    >
                    Reset
                    </LRButton>
                    <LRButton type="button" variant="contained" color="primary" height={36} onClick={()=> onClose()}> Done</LRButton>
                </div>
            </FilterMoreActions>
        </Dialog>
    );
}

function FiltersTitle({
    totalSelectedOptions = 0,
}) {
    const theme = useTheme();
    const { isMobile } = useDeviceBreakpoints();

    return (
        <Grid container>
            {Boolean(totalSelectedOptions) && (
                <Grid item style={{ marginRight: theme.spacing(.5), }}>
                    <Grid container justify="center" alignItems="center">
                        <Grid
                            item
                            style={{
                                color: '#fff',
                                backgroundColor: '#000',
                                borderRadius: '50%',
                                width: 20,
                                height: 20,
                                textAlign: 'center',
                                fontSize: 12,
                            }}
                        >
                            <div style={{ position: 'relative', top: 1, }}>
                                {totalSelectedOptions}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item>{isMobile ? 'Filters' : 'More filters'}</Grid>
        </Grid>
    );
}

export const MobileFilters = observer(function({
    listingsCount,
    openSaveModal,
    setSaveOpenModal,
    showLoading,
    trackFilterSet,
    saveTrackEvent,
    setSaveTrackEvent,
    isLoadingListings,
    fieldArray,
    areas,
    nameState,
    hideSaveButton,
}) {
    const formMethods = useFormContext();
    const { isMobile } = useDeviceBreakpoints();
    const { getValues } = formMethods;
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { value: priceMin, handleChange: setPriceMin } = useRFHInput('fields.price_min', formMethods);
    const { value: priceMax, handleChange: setPriceMax } = useRFHInput('fields.price_max', formMethods);
    const fields = getValues();
    const totalSelectedOptions = useMemo(()=> {
        const filterFields = omitBy(fields, (v, k)=> (
            !k.match('fields.') ||
            (!isMobile && k.match('.price_min')) ||
            (!isMobile && k.match('.price_max'))
        ));
        const flattenedFields = flat(filterFields);
        const totalSelected = Object.values(flattenedFields).reduce((result, value)=> {
            const hasValue = isArray(value) ? !!value.length : !!value;

            if(hasValue) result++;

            return result;
        }, 0);

        return totalSelected;
    }, [fields]);
    const isPriceToggled = Boolean(priceMin || priceMax);
    const onboarding = useOnboarding();
    const [blockActions, setBlockActions] = useState(true);
    const priceTitle = useMemo(()=> {
        if(!(priceMin || priceMax)) {
            return 'Price';
        }

        if(priceMin && priceMax) {
            return `$${parseFloat(abbr(priceMin, 2))}-$${abbr(priceMax, 2)}`;
        } else if(priceMin) {
            return `$${abbr(priceMin)}+`;
        } else {
            return `$0-$${abbr(priceMax)}`;
        }
    }, [priceMin, priceMax]);

    function handleOnboardingNext() {
        setBlockActions(false);
        onboarding.next();
        if(onboarding?.isNurtureFirstRunOnboarding ){
            analytics.eventTrack('robin_onboarding_search_area_dialog_dismiss');
        }
    }

    function handlePriceOpen(event) {
        setAnchorEl(event.currentTarget);
        setOpen(false);
    }

    function handlePriceClose() {
        setAnchorEl(null);
    }

    function handleFilterOpen() {
        setOpen(true);
        setAnchorEl(null);
    }

    function onClose() {
        setOpen(false);
    }

    function handleResetFilters(areas) {
        formMethods.reset({
            areaIds: areas,
            fields: {
                price_min: null,
                price_max: null,
                bedrooms: null,
                bathrooms: null,
                homesize_min: null,
                homesize_max: null,
                stories: null,
                lotsize_min: null,
                lotsize_max: null,
                year_built_min: null,
                year_built_max: null,
                days_on_market: null,
                school_score_min: null,
                hometype: {
                    single_family: false,
                    multi_family: false,
                    condo_townhome: false,
                    mobile_manufactured: false,
                    land: false,
                },
                tags: {
                    with_great_schools: false,
                    with_open_houses: false,
                    with_virtual_tours: false,
                    with_views: false,
                    with_upgraded_kitchens: false,
                    with_pool: false,
                },
                keywords: [],
                include_listings_with_unspecified_data: null
            },
            meta: {
                agent_note: null,
            },
        });
    }

    return (
        <div
            className="tour-listpack-area-filters"
            style={{
                position: 'relative',
                marginLeft: isMobile ? 'auto' : theme.spacing(3),
                marginRight: isMobile ? 'auto' : theme.spacing(3),
                maxWidth: isMobile && 350,
            }}
        >
            {
                (onboarding?.isFirstRunOnboarding || onboarding?.isNurtureFirstRunOnboarding )&& ['tour-listpack-area-filters'].includes(onboarding.stepId) && blockActions && (
                    <Box
                        className="tour-click-hack"
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bgcolor="transparent"
                        style={{
                            zIndex: 1000
                        }}
                        onClick={handleOnboardingNext}
                    ></Box>
                )
            }

            <Box display="flex" py={2} pb={0} className="tour-listpack-areas-filter">
                <Area isMobile={true} fieldArray={fieldArray} />
            </Box>
            <Box display="flex" position="sticky" py={2} top={0} zIndex={9} pb={2}>
                <Hidden xsDown>
                    <Box position="relative">
                        <Box
                            className="test"
                            style={{ cursor: 'pointer' }}
                            display="flex"
                            alignItems="center"
                            borderColor={theme.palette.lr_colors.border_grey}
                            bgcolor={isPriceToggled ? '#000' : theme.palette.lr_colors.crest_white}
                            color={isPriceToggled && '#fff'}
                            border={1}
                            borderRadius={4}
                            height={36}
                            padding="8px 29px 8px 12px"
                            position="relative"
                            data-testid="display"
                            onClick={handlePriceOpen}
                        >
                            {priceTitle}
                            <Box position="absolute" display="flex" alignItems="center" right={8} top={0} bottom={0}>
                                <KeyboardArrowDownIcon style={{ fontSize: 16 }}/>
                            </Box>
                        </Box>

                        <Popover
                            style={{ marginTop: '8px'}}
                            anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                            transformOrigin={{vertical: 'top', horizontal: 'left'}}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handlePriceClose}
                        >
                            <Box
                                borderRadius={4}
                                bgcolor={theme.palette.lr_colors.crest_white}
                                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
                            >
                                <Price
                                    priceMin={priceMin}
                                    priceMax={priceMax}
                                    setPriceMin={setPriceMin}
                                    setPriceMax={setPriceMax}
                                    isMobile={true}
                                    trackFilterSet={trackFilterSet}
                                    onClose={handlePriceClose}
                                />
                            </Box>
                        </Popover>
                    </Box>
                </Hidden>

                <Box
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    borderColor={theme.palette.lr_colors.border_grey}
                    bgcolor={theme.palette.lr_colors.crest_white}
                    border={1}
                    borderRadius={4}
                    height={36}
                    padding="8px 35px 8px 12px"
                    position="relative"
                    data-testid="display"
                    ml={!isMobile && 1}
                    onClick={handleFilterOpen}
                >
                    <div>
                        <FiltersTitle totalSelectedOptions={totalSelectedOptions} />
                        <Box position="absolute" display="flex" alignItems="center" right={8} top={0} bottom={0}>
                            <KeyboardArrowDownIcon style={{ fontSize: '16px' }}/>
                        </Box>
                    </div>
                </Box>

                {!hideSaveButton && (<Box ml={1} display="flex">
                    <SaveFilter
                        openSaveModal={openSaveModal}
                        setSaveOpenModal={setSaveOpenModal}
                        saveTrackEvent={saveTrackEvent}
                        setSaveTrackEvent={setSaveTrackEvent}
                        isLoadingListings={isLoadingListings}
                        areas={areas}
                        nameState={nameState}
                    />
                </Box>)}
            </Box>
            <MobileMoreFilters
                open={open}
                onClose={onClose}
                showLoading={showLoading}
                setSaveOpenModal={setSaveOpenModal}
                listingsCount={listingsCount}
                handleResetFilters={()=> handleResetFilters(areas)}
                trackFilterSet={trackFilterSet}
            />
        </div>
    );
});
