import React, { useState, useEffect } from 'react';
import { Typography, useTheme, Slide, useScrollTrigger } from '@material-ui/core';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { EmailInput } from '../EmailInput';
import { LogoRIcon } from 'components/LRIcons/logoR';
import { useParams } from 'react-router-dom';
import { useAgent } from 'hooks/useAgent';
import { portalApi, benoitApi } from 'apis';
import analytics from 'services/analytics';
import brazeClient from 'services/analytics/providers/braze';
import { Disclaimer } from '../Disclaimers';
import { LRDialog } from 'components/LRDialog';
import LRLink from 'components/LRLink';
import { useStore } from 'mobx-store';

export const Page1 = function({uniqueId, email, setSignupStep}) {
    const { isIPadDown } = useDeviceBreakpoints();
    const { GlobalSnackbarStore } = useStore();
    const theme = useTheme();
    const [inputValue1, setInputValue1] = useState(email || '');
    const [inputValue2, setInputValue2] = useState(email || '');
    const [inputValue3, setInputValue3] = useState(email || '');
    const [loading, setLoading] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const trigger = useScrollTrigger({
        threshold: 360,
        disableHysteresis: true,
    });
    const { affiliateCode } = useParams();
    const { data: agent } = useAgent(affiliateCode, { skipUsersAffiliate: true });

    useEffect(function() {
        if(agent?.id) {
            analytics.eventTrack('robin_home_report_opt_in_page_viewed', {
                distinct_id: agent.id,
                variation: 'full',
                anonymous_unique_id: uniqueId,
            });
        }
    }, [agent, uniqueId]);

    const subscribeNewsletter = async function(inputEmail) {
        const leadData = {
            applicant: {
                name: 'Prospective Lead',
                email: inputEmail,
                phone: null
            },
            type: 'realtor',
            ownerId: agent.id,
            source: 'Home Report',
        };

        setLoading(true);

        const userExists = await benoitApi.userExists(inputEmail);
        analytics.eventTrack('robin_home_report_opt_in_page_email_submitted', {
            distinct_id: agent.id,
            variation: 'full',
            email_submitted: inputEmail,
            anonymous_unique_id: uniqueId,
        });

        if(!userExists) {
            const createLeadResponse = await portalApi.createLead(agent, leadData);

            if(createLeadResponse) {
                const { createdNewLead, data } = createLeadResponse;
                if(createdNewLead && data) {
                    const customAttrs = { type: 'home_report_subscriber' };
                    brazeClient.vendorLeadEmailSubscribe(data, customAttrs);

                    const agentActivityData = {
                        type: 'lead',
                        userId: agent.id,
                        meta: {
                            lead: data
                        },
                        name: 'affiliates_home_report_subscribed',
                    };

                    const loActivityData = {
                        type: 'lead',
                        userId: agent.preferredVendorId,
                        meta: {
                            lead: data
                        },
                        name: 'affiliates_home_report_subscribed',
                    };

                    await Promise.all([
                        portalApi.createActivity(agentActivityData),
                        portalApi.createActivity(loActivityData),
                        portalApi.sendHomeReportOptinAffiliateNotify(
                            data.applicant.email,
                            agent.id,
                            agent.preferredVendorId
                        )
                    ]);
                }
            }
        
            setSignupStep(inputEmail);
            GlobalSnackbarStore.show('You’re successfully subscribed to the Home Report!', {autoHideDuration: 3000});
        } else {
            setShowSignInModal(true);
        }
        
    };

    const onSubscribe1 = function() {
        subscribeNewsletter(inputValue1);
    };
    const onSubscribe2 = function() {
        subscribeNewsletter(inputValue2);
    };
    const onSubscribe3 = function() {
        subscribeNewsletter(inputValue3);
    };

    return (

        <div>
            {showSignInModal && (
                <LRDialog
                    open={true}
                >
                    <div style={{margin: theme.spacing(2.5)}}>
                        <Typography variant="body1" display="inline">
                            This email is already associated with a Robin account. 
                            Log into your Robin account by clicking
                        </Typography>
                        <LRLink to={'/login'}>
                            <Typography variant="body1" display="inline">
                                {' '}here.
                            </Typography>
                        </LRLink>
                    </div>
                </LRDialog>
            )}
            <div>
                {!isIPadDown && (
                    <Slide in={trigger} direction="down" timeout={700}>
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                backgroundColor: 'white',
                                zIndex: 1,
                                visibility: 'visible'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '64px',
                                borderBottom: `1px solid ${theme.palette.lr_colors.border_grey}`
                            }}>
                                <div style={{width: '66px', height: '20px', marginLeft: theme.spacing(8)}}>
                                    <LogoRIcon style={{width: '100%', height: '100%'}} />
                                </div>
                                <div style={{width: 'min(440px, 100%)', display: 'flex', marginRight: theme.spacing(8)}}>
                                    <EmailInput
                                        setInputValue={setInputValue3}
                                        adornmentProps={{width: '88px', height: '42px'}}
                                        textFieldProps={{height: '42px'}}
                                        onSubmit={onSubscribe3}
                                        loading={loading}
                                        defaultValue={inputValue3}
                                    />
                                </div>
                            </div>
                        </div>
                    </Slide>
                )}
                <div style={{
                    display: 'flex',
                    alignItems: isIPadDown ? 'flex-start' : 'center',
                    backgroundColor: theme.palette.lr_colors.grey_light,
                    flexDirection: 'column',
                }}>
                    <div style={{margin: `0 ${theme.spacing(isIPadDown ? 3 : 0)}px`}}>
                        <div style={{
                            marginTop: theme.spacing(isIPadDown ? 3 : 6.5),
                            marginBottom: theme.spacing(isIPadDown ? 3 : 4)
                        }}>
                            <div style={{width: isIPadDown ? '66px' : '107px', margin: isIPadDown ? '0' : '0 auto'}}>
                                <LogoRIcon style={{width: '100%', height: isIPadDown ? '20px' : '32px'}} />
                            </div>
                        </div>
                        <Typography variant="h4" align={isIPadDown ? 'left' : 'center'} style={{marginBottom: theme.spacing(isIPadDown ? 1.5 : 2)}}>
                            Real estate made simple
                        </Typography>
                        <Typography variant="h6" align={isIPadDown ? 'left' : 'center'} style={{marginBottom: theme.spacing(3), maxWidth: '560px'}}>
                            Get the free monthly email that simplifies local real estate.
                            Say goodbye to confusing graphics and terms—this is real estate for everyone.
                        </Typography>
                        <div style={{width: 'min(560px, 100%)', display: 'flex', marginBottom: theme.spacing(2)}}>
                            <EmailInput
                                setInputValue={setInputValue1}
                                adornmentProps={{width: isIPadDown ? '80px' : '127px'}}
                                onSubmit={onSubscribe1}
                                loading={loading}
                                defaultValue={inputValue1}
                            />
                        </div>
                        <div style={{maxWidth:'560px', margin: '0 auto', marginBottom: theme.spacing(isIPadDown ? 3 : 6.5)}}>
                            <Disclaimer fullName={`${agent.first_name} ${agent.last_name}`}/>
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    margin: `0 ${theme.spacing(isIPadDown ? 3 : 0)}px`
                }}>
                    <div style={{height: theme.spacing(isIPadDown ? 4 : 6.5)}}/>
                    <div style={{marginBottom: theme.spacing(isIPadDown ? 5.5 : 6.5)}}>
                        <img
                            alt="features"
                            src="/images/home-report-newsletter/features.svg"
                            style={{
                                objectFit: 'contain',
                                width: isIPadDown ? '312px' : '620px'
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: theme.spacing(isIPadDown ? 5 : 10.5)}}>
                        <div style={{
                            maxWidth: '420px',
                            marginTop: isIPadDown ? 0 : '60px',
                            marginBottom: theme.spacing(isIPadDown ? 3 : 0),
                            marginRight: theme.spacing(isIPadDown ? 0 : 10)
                        }}>
                            <Typography variant="h4">Know your worth</Typography>
                            <Typography variant="h6">
                                Watch your investment grow,
                                understand changes in your home’s value and know exactly when to buy or sell.
                                Armed with the right information, you’re one step closer to your home goals.
                            </Typography>
                        </div>
                        <div>
                            <img
                                alt="features"
                                src="/images/home-report-newsletter/worth.svg"
                                style={{
                                    objectFit: 'contain',
                                    width: isIPadDown ? '312px' : '390px'
                                }}
                            />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap-reverse', marginBottom: theme.spacing(isIPadDown ? 8 : 3)}}>
                        <div>
                            <img
                                alt="features"
                                src="/images/home-report-newsletter/nearby-properties.svg"
                                style={{
                                    objectFit: 'contain',
                                    width: isIPadDown ? '308px' : '395px'
                                }}
                            />
                        </div>
                        <div style={{
                            maxWidth: '420px',
                            marginTop: isIPadDown ? 0 : '20px',
                            marginBottom: theme.spacing(isIPadDown ? 3 : 0),
                            marginLeft: theme.spacing(isIPadDown ? 0 : 13.5)
                        }}>
                            <Typography variant="h4">Keep tabs on your neighbors</Typography>
                            <Typography variant="h6">
                                No binoculars needed. 
                                Get a snapshot of exactly what’s happening in 
                                your neighborhood delivered each month. 
                                You’ll see recently listed homes in your area, 
                                which properties are flying off the market, 
                                and how your home stacks up. 
                            </Typography>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: theme.spacing(isIPadDown ? 6.5 : 18)}}>
                        <div style={{
                            maxWidth: '420px',
                            marginTop: isIPadDown ? 0 : '64px',
                            marginBottom: theme.spacing(isIPadDown ? 3 : 0),
                            marginRight: theme.spacing(isIPadDown ? 0 : 10)
                        }}>
                            <Typography variant="h4">
                                Stay informed
                                <br />(the fun way)
                            </Typography>
                            <Typography variant="h6">
                                Real estate’s biggest stories, trending moments and exciting updates - 
                                all delivered directly to your inbox. 
                                Stay up to date with curated content that you’ll actually want to read. 
                            </Typography>
                        </div>
                        <div>
                            <img
                                alt="features"
                                src="/images/home-report-newsletter/news.svg"
                                style={{
                                    objectFit: 'contain',
                                    width: isIPadDown ? '312px' : '390px'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: theme.palette.lr_colors.grey_light,
                    flexDirection: 'column'
                }}>
                    <div style={{margin: `0 ${theme.spacing(isIPadDown ? 3 : 0)}px`}}>
                        <div style={{height: theme.spacing(isIPadDown ? 3 : 8)}} />
                        <Typography variant="h4" align="center" style={{marginBottom: theme.spacing(2)}}>What are you waiting for?</Typography>
                        <Typography variant="h6" align="center" style={{marginBottom: theme.spacing(isIPadDown ? 2 : 3), maxWidth: '575px'}}>
                            Get the free monthly real estate email that demystifies the market 
                            and helps you become a smarter homeowner.
                        </Typography>
                        <div style={{width: 'min(460px, 100%)', display: 'flex', margin: '0 auto'}}>
                            <EmailInput
                                setInputValue={setInputValue2}
                                adornmentProps={{width: isIPadDown ? '80px' : '117px'}}
                                onSubmit={onSubscribe2}
                                loading={loading}
                                defaultValue={inputValue2}
                            />
                        </div>
                        <div style={{paddingTop: theme.spacing(2), maxWidth:'460px', margin: '0 auto'}}>
                            <Disclaimer fullName={`${agent.first_name} ${agent.last_name}`}/>
                        </div>
                        <div style={{height: theme.spacing(isIPadDown ? 3 : 8)}} />
                    </div>
                </div>
            </div>

        </div>
    );
};
