import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import { Box, TextField } from '@material-ui/core';
import LRButton from '../../components/LRButton';
import LRConfirmationDialog from '../../components/LRConfirmationDialog';
import { useFormField } from '../../hooks/use-form-field';

const CreateBoard = ()=> {
    const { boardsStore } = useStore();
    const [showNewBoardModal, setShowNewBoardModal] = useState(false);
    const [boardNameProps, boardName] = useFormField('');
    const [redirect, setRedirect] = useState(false);

    if(redirect || boardsStore.activeBoard) {
        return <Redirect to="/boards"></Redirect>;
    }

    return (
        <Box mt="50px" display="flex" justifyContent="center">
            <LRButton
                variant="outlined"
                color="primary"
                onClick={()=> {
                    setShowNewBoardModal(true);
                }}
            >
                Create new board
            </LRButton>
            {showNewBoardModal && (
                <LRConfirmationDialog
                    title="Name your board"
                    okButton={{ label: 'Create' }}
                    cancelButton={{ visible: true }}
                    onClose={async (e, value)=> {
                        await boardsStore.createUserBoard({ name: boardName });
                        setRedirect(true);
                    }}
                >
                    <TextField
                        {...boardNameProps}
                        fullWidth
                        placeholder="board name"
                        inputProps={{ style: { textAlign: 'center' } }}
                    />
                </LRConfirmationDialog>
            )}
        </Box>
    );
};

export default observer(CreateBoard);
