import { benoitApi, portalApi } from 'apis';
import Logger from 'js-logger';
import { keyBy, find as lodashFind } from 'lodash';
import { Listing } from 'mobx-store/domain/listings-store';
import { useQuery } from 'react-query';

const logger = Logger.get('useListingsDetails');

/**
 * Enhances the listings provided with additional data
 * @param {Array} listings - minimum info should iclude an id ex [{ id: 'ENCODED_LISTING_ID' }, ...]
 */

export function useListingsDetails(listings = []) {
    const query = useQuery(
        ['listing-details', listings?.map((l)=> l.id)],
        benoitApi.getListingsDetailsByIds.bind(benoitApi),
        {
            enabled: Boolean(listings?.length),
            select: (data)=> {
                const dataObj = keyBy(data, 'id');
                const updatedListings = listings.map((listingDetails)=> {
                    if(dataObj[listingDetails.id]) {
                        listingDetails = {
                            ...listingDetails,
                            ...dataObj[listingDetails.id],
                        };
                    }

                    if(listingDetails.listing_status === 'Hold') listingDetails.listing_status = 'Pending';

                    return new Listing(listingDetails);
                });

                return updatedListings;
            },
        }
    );

    return query;
}

export function useListingsSourceDetails(source, mlsId) {
    const query = useQuery(
        ['listings-source-details', source, mlsId],
        ({ queryKey: [, source] })=> (portalApi.getListingsSourceDetails(source)),
        {
            enabled: Boolean(source && mlsId),
            select: (data)=> {
                const downloads = data?.downloads || [];
                return lodashFind(downloads, (item)=> {
                    return item?._id && (String(item._id)).toLowerCase() === (String(mlsId)).toLowerCase();
                }) || null;
            }
        }
    );

    return query;
}
