import React, { useState } from 'react';
import { Box, Dialog, TextareaAutosize, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../mobx-store';
import styled from 'styled-components';
import { useResponsiveBreakpoint } from '../../hooks';
import { Close } from '@material-ui/icons';
import LRButton from '../LRButton';
import LRLink from '../LRLink';
import { useFormField } from '../../hooks/use-form-field';

export const CardDeleteModal = observer(({ open, onClose = ()=> {}, onConfirm= ()=> {} })=> {
    const { UserStore, boardsStore } = useStore();
    const [messageProps, message, setMessage] = useFormField('');
    const { activeBoard } = boardsStore;
    const { user } = UserStore;
    const isXs = useResponsiveBreakpoint('xs');
    const theme = useTheme();

    return (
        <Dialog fullWidth open={open} maxWidth={'sm'}>
            <Box width={1} height={1} px={[3,7]} py={4}>
                {/* <Box position="absolute" top={24} right={24} onClick={onClose} style={{ cursor: 'pointer' }}>
                    <Close />
                </Box> */}
                <Box width={1} textAlign="center">
                    <Box fontSize="h5.fontSize">Delete Home</Box>
                </Box>
                <Box width={1} textAlign="center" mt={2} fontSize="h6.fontSize">
                    Do you want to give { user?.affiliate?.first_name } feedback on why this is a “no”?
                </Box>
                <Box mt={3}>
                    <div
                        style={{
                            flexGrow: '1',
                            marginBottom: isXs ? 0 : theme.spacing(1.5),
                            border: `1px solid ${theme.palette.lr_colors.border_grey}`,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TextareaAutosize
                            {...messageProps}
                            rowsMin={5}
                            style={{
                                width: '100%',
                                height: isXs && '100%',
                                padding: theme.spacing(2),
                                fontSize: '1rem',
                                border: '0',
                                borderRadius: isXs ? 0 : '2px',
                                resize: 'none',
                                outline: 'none',
                                fontFamily: 'inherit',
                                flexGrow: '1',
                                overflow: 'auto'
                            }}
                            autoFocus
                        ></TextareaAutosize>
                    </div>
                </Box>
                {/* <Box width={1} textAlign="center" mt={2} fontSize="body1.fontSize">
                    Are you sure you want to permanently erase these homes from your board? You can’t undo this action.
                </Box> */}
                <Box width={1} mt={3} display="flex" alignItems="center" justifyContent="flex-end">
                    <LRLink
                        onClick={onClose}
                        style={{ textDecoration: 'underline', color: '#1A1A1A' }}
                    >
                        Cancel
                    </LRLink>
                    <Box ml={4}>
                        <LRButton color="primary" variant="contained" onClick={()=> {
                            onConfirm(message);
                            setMessage('');
                        }}>
                            Delete
                        </LRButton>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );

});