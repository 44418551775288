import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../mobx-store';
import { observer } from 'mobx-react-lite';
import { CenteredLoading } from '../../components/CenteredLoading';
import { LandingPageBody } from './LandingPageBody';
import analytics from '../../services/analytics';
import { Box } from '@material-ui/core';
import { useQuery } from '../../hooks';

export default observer(function ShareableLandingPage() {
    const { userId } = useParams();
    const { ShareablesStore } = useStore();
    const {
        loadedUser,
        loadingUser,
        user,
        hasPremiumShareablesSubscription,
        checkedPremiumShareablesSubscription,
    } = ShareablesStore;
    const shareableSlug = useQuery()
        .get('slug');
    const shareableType = useQuery()
        .get('type');
    const landingPageType = useQuery()
        .get('landingPageType');
    const customImage = useQuery()
        .get('customImage');

    useEffect(()=> {
        if(!loadedUser) {
            ShareablesStore.getUser(userId);
        }
    }, [ShareablesStore, loadedUser, userId]);

    useEffect(()=> {
        if(!checkedPremiumShareablesSubscription) {
            ShareablesStore.checkPremiumShareablesSubscription(userId);
        }
    }, [ShareablesStore, checkedPremiumShareablesSubscription, userId]);

    useEffect(()=> {
        if(loadedUser && checkedPremiumShareablesSubscription) {
            analytics.eventTrack('shareable_consumer_page_viewed', {
                distinct_id: userId,
                'User Type': hasPremiumShareablesSubscription? 'Pro' : 'Free',
                'Type': landingPageType === 'normal'? 'normal' : 'premium',
                ...shareableSlug && { 'Shareable Slug': shareableSlug },
                ...shareableType && { 'Shareable Type': shareableType },
                ...customImage && { 'uploaded_image': customImage },
            });
        }
    }, [
        checkedPremiumShareablesSubscription,
        hasPremiumShareablesSubscription,
        landingPageType,
        loadedUser,
        shareableSlug,
        shareableType,
        userId,
        customImage
    ]);

    if(!loadedUser || !checkedPremiumShareablesSubscription) {
        return <CenteredLoading height="100vh" />;
    }

    return (
        <Box height={{ md: '100vh' }}>
            <Box
                display="flex"
                height="100%"
                width={1}
                mx="auto"
                flexDirection={{ xs: 'column', md: 'row' }}
                overflow={{ xs: 'auto', md: 'hidden' }}
            >
                <LandingPageBody user={user} loadedUser={loadedUser} loadingUser={loadingUser} />
            </Box>
        </Box>
    );
});
