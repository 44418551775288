import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import TaskLayout from './task-layout';

const MovedCardTask = observer(function MovedCardTask(props) {
    const match = useRouteMatch();
    const { task } = props;
    const { actor, address, cta } = task.content;
    const strippedCta = cta.replace('/boards/', '');
    const checked = !!task?.completedAt;

    return (
        <TaskLayout {...props} viewTo={`${match.url}/${strippedCta}`}>
            <div>

                <Typography
                    variant="body2"
                    // variant={r({ xs: checked ? 'body2' : 'body1', md: checked ? 'body2' : 'h6' })}
                >
                    {actor?.first_name} just moved {' '}
                    <span style={{
                        textDecoration: checked ? 'none' : 'underline',
                        fontWeight: 'bold'
                    }}>
                        {address}
                    </span>
                    {' '} to Want to see.  Add a note to see if you can help with anything.
                </Typography>
            </div>
        </TaskLayout>
    );
});

export default MovedCardTask;
