// eslint-disable-next-line
import Logger from 'js-logger';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Typography, Grid, Box } from '@material-ui/core';
import Card from '../Card';
import _ from 'lodash';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DragHomeHereCard from '../DragHomeHereCard';
import { withRouter, Link } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import CardPlaceholder from '../CardPlaceholder';
import { observer } from 'mobx-react-lite';
import { withTheme } from '@material-ui/core/styles';
import { Room } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import analytics from '../../../services/analytics';
import SortMenu from './SortMenu';
import { useBoardCardListing } from 'hooks/data/board-card/useBoardCardListing';

const Container = styled.div`
    height: 100%;
    width: 312px;
    min-width: 312px;
    margin: 12px;
    margin-top: 0;
    margin-bottom: 0px;
    border-radius: 4px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;
const StyledTitleTypography = styled(Typography)`
    font-weight: normal;
`;

const LaneCardHolder = withTheme(styled.div`
    margin-top: 16px;
    border-radius: 2px;
    position: relative;
    padding: 24px;
    background: #eee;
    height: calc(100% - 66px);
    overflow: auto;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        height: auto;
        margin: 0;
    }
`);
const CardWrapper = styled.div`
    margin-bottom: 16px;
`;
const DragHomeHereCardWrapper = styled.div`
    padding-bottom: 10px;
`;
const StyledEmptyContainer = styled.div`
    width: 264px;
`;
const SuperPower = withTheme(styled.div`
    border-radius: 6px;
    width: 250px;
    margin: 0 auto 14px;

    ${({ theme })=> theme.breakpoints.only('xs')} {
        width: 100%;
    }
`);
const StyledTitleWrapperGrid = styled(Grid)`
    overflow: hidden;
    color: #1A1A1A;
`;

const StyledRoomIcon = styled(Room)`
    font-size: 14px;
`;

const LaneBoardCard = observer(function LaneBoardCard({ index, lane, isDropDisabled, boardCard }) {
    const { data: listing_details } = useBoardCardListing(boardCard);
    let variant = 'board';

    if(lane.id === '2'){
        variant = 'scheduleTour';
    } else if(lane.id === '3'){
        variant = 'makeAnOffer';
    }

    return (
        <div key={boardCard.id} className="tour-target-board-card-draggable">
            <ReactPlaceholder ready={listing_details} customPlaceholder={<CardPlaceholder />}>
                <Draggable draggableId={boardCard.id} index={index}>
                    {(provided)=> (
                        <StyledLink
                            to={`/boards/cards/${boardCard.id}/${_.kebabCase(listing_details.address)}`}
                            className="tour-target-lane-card-link"
                            style={{ display: 'block' }}
                            /*                                                             onClick={()=> {
                            if(onboarding.isIdeaBoardOnboarding) {
                                onboarding.next();
                            }
                        }} */
                        >
                            <CardWrapper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <Card
                                    variant={variant}
                                    boardCard={boardCard}
                                    listing_details={listing_details}
                                    canMove={!isDropDisabled}
                                />
                            </CardWrapper>
                        </StyledLink>
                    )}
                </Draggable>
            </ReactPlaceholder>
        </div>
    );
});


function Lane({
    lane,
    loading,
    isDropDisabled,
    listings,
}) {
    const theme = useTheme();
    const { numOfPlaceholders = 0 } = lane;

    const DragHomeHereCardComponent =
        listings.length || (loading && numOfPlaceholders) ? (
            undefined
        ) : (
            <DragHomeHereCardWrapper>
                <DragHomeHereCard label={lane.emptyStateLabel ||  ''} />
            </DragHomeHereCardWrapper>
        );
    const CPlaceholder = numOfPlaceholders && loading ? ReactPlaceholder : StyledEmptyContainer;

    const shouldOffsetMargins = lane.sortingFunctions && lane.sortingFunctions.length;

    return (
        <>
            <Container>
                <StyledTitleWrapperGrid
                    container
                    alignItems="center"
                    justify="space-between"
                    shouldoffsetmargins={shouldOffsetMargins}
                >
                    <StyledTitleTypography variant="h6" style={{ marginLeft: theme.spacing(3) }}>
                        {lane.name}
                        <Box component="span" ml={2} color={'#acacac' }>
                            {listings.length}
                        </Box>
                    </StyledTitleTypography>

                    {
                        lane.isExpandable && (
                            <Box style={{ display: 'flex', marginRight: theme.spacing(3), cursor: 'pointer' }}>

                                <Box ml={0.5}  alignItems="center" display="flex">
                                    <Link to={`/boards/lane-expanded/${lane.id}/${lane.name}`}
                                        onClick={(e)=> {
                                            if(listings.length === 0) {
                                                e.preventDefault();
                                            } else {
                                                analytics.eventTrack('robin_board_expand_column_click', {
                                                    column: lane?.name.toLowerCase(),
                                                    source: lane?.name.toLowerCase(),
                                                });
                                            }
                                        }}
                                        disabled={listings.length === 0}
                                        style={{
                                            cursor: listings.length > 0 ? 'pointer' : 'default',
                                            color: listings.length > 0 ? 'black' : '#acacac'
                                        }}
                                    >
                                        <StyledRoomIcon style={{ marginTop: '2px', color: listings.length > 0 ? 'black' : '#acacac'}}/>
                                    </Link>
                                    <SortMenu lane={lane} />
                                </Box>
                            </Box>
                        )
                    }
                </StyledTitleWrapperGrid>

                {/* {lane.superPower && (
                <SuperPower>
                    <LRButton
                        onClick={()=> lane.superPower.path && history.push(lane.superPower.path)}
                        color="primary"
                        fullWidth={true}
                        variant="contained"
                        disabled={!listings.length && !lane.superPower.doNotDisableButton}
                    >
                        {lane.superPower.buttonLabel}
                    </LRButton>
                </SuperPower>
            )} */}

                {/* <Box bgcolor="#f4f4f4"> */}
                <Droppable droppableId={lane.id} ignoreContainerClipping={true} isDropDisabled={!!isDropDisabled}>
                    {(provided)=> (
                        <LaneCardHolder
                            id={`lane-container-${lane.id}`}
                            hasSuperPower={lane.superPower}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {DragHomeHereCardComponent}

                            <CPlaceholder
                                type="rect"
                                ready={Boolean(listings.length)}
                                customPlaceholder={<CardPlaceholder />}
                            >
                                {listings.map((listing, i)=> {
                                    return (
                                        <LaneBoardCard
                                            key={listing.id}
                                            lane={lane}
                                            index={i}
                                            boardCard={listing}
                                            isDropDisabled={isDropDisabled}
                                        />
                                    );
                                })}

                                {provided.placeholder}
                            </CPlaceholder>
                        </LaneCardHolder>
                    )}
                </Droppable>
                {/* </Box> */}
            </Container>
            {/* <StyledDialog
                fullScreen={true}
                fullWidth={true}
                maxWidth="sm"
                open={showExpandedView}
                onExited={()=> {}}
            // TransitionComponent={isXs ? MobileTransition : Grow}
            >
                <CloseButton onClick={()=> {}} title="Close">
                    <Close />
                </CloseButton>

                <Box width={1} height={1} p={3} overflow="auto">
                    <Grid container spacing={2}>
                        {listings.map((listing, i)=> {
                            return (
                                <Grid item xs={6}>
                                    <Card listing={listing} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </StyledDialog> */}
        </>
    );
}
Lane.propTypes = {
    history: PropTypes.object.isRequired,
    lane: PropTypes.object.isRequired,
    listings: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    isDropDisabled: PropTypes.bool,
};

const ObservedLane = observer(Lane);
const HistoryLane = withRouter(ObservedLane);

export default HistoryLane;
export { CardWrapper, LaneCardHolder, SuperPower };
