import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { COLOR_GREEN } from '../../constants';

const StyledSwitch = styled(Switch)`
    & .Mui-checked + span[class^='MuiSwitch-track'] {
        background-color: ${COLOR_GREEN};
    }
    & .Mui-checked {
        color: ${COLOR_GREEN};
    }
`;


export function LRSwitch(props) {
    return <FormControlLabel control={<StyledSwitch {...props} />} />;
}

export function LRSimpleSwitch(props) {
    return <StyledSwitch {...props} />;
}
