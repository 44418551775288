// eslint-disable-next-line
import { Box, Fab, Slide } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Link, Route, Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import { Can } from '../../components/Ability';
import { AddHomeIcon } from '../../components/LRIcons';
import ResponsiveHoc from '../../components/ResponsiveHoc';
import { useStore } from '../../mobx-store';
import AccountSettingsModal from '../AccountSettingsModal';
import AddAnyHomeModal from '../AddAnyHomeModal';
import { BoardCardModal } from '../BoardCardModal';
import BoardListingModal from '../BoardListingModal';
import CompareNowModal from '../CompareNowModal';
import InviteACoBuyerModal from '../InviteACoBuyerModal';
import MortgageCenterBoardModal from '../MortgageCenterBoardModal';
import NameHomeModal from '../NameHomeModal';
import NotificationsModal from '../NotificationsModal';
import PinspirationBoardModal from '../PinspirationBoardModal';
import SubmitOfferModal from '../SubmitOfferModal';
import WantToSeeModal from '../WantToSeeModal';
import ArchivedHomesModal from './ArchivedHomes/ArchivedHomesModal';
import BoardDesktop from './BoardDesktop';
import BoardManageModal from './BoardManage/BoardManageModal';
import BoardMobile from './BoardMobile';
import ListpackModal from './ListpackModal';
import ListpackSearchModal from '../Board/ListpackSearchModal';
import { useOnboarding } from '../../components/Onboarding';
import ListingsMap from './../ListingsMap';
import { VerifyModal } from '../../components/VerificationBanner/VerifyModal';
import { SignUpModal } from '../../components/VerificationBanner/SignUpModal';
import { OnboardingVideoModal } from '../../components/OnboardingVideoModal';
import Logger from 'js-logger';
import { ScheduleATourModal } from '../../components/ScheduleATourModal';
import { ScheduledToursModal } from '../../components/ScheduledToursModal';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import { TermsAndConditionsModal } from 'components/TermsAndConditionsModal';
import { MyHomeModal } from 'scenes/MyHome';
import { ListpackListingsMapAndListModal } from 'components/ListpackListingsMapAndListModal';
import { ModalRoute } from 'components/ModalRoute';

const logger = Logger.get('Board');

const pulseAnimation = keyframes`
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 1
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
`;

const AddAnyHomeButton = withTheme(styled(Fab)`
    position: fixed;
    bottom: 74px;
    background: #1A1A1A;
    right: 25px;

    ${({ theme })=> theme.breakpoints.up('sm')} {
        bottom: 40px;
    }

    &:hover {
        background: #000;
    }

    &.pulse {
        z-index: 9998;
    }

    &.pulse:after, &.pulse:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        border: 1px solid rgba(30, 200, 225, 0.5);
        top: -20px;
        right: -20px;
        bottom: -20px;
        left: -20px;
        transform: scale(0.5);
        opacity: 0;
        animation: ${pulseAnimation} 1.5s linear infinite;
    }
    &.pulse:after {
        animation-delay: 0.5s;
        border-width: 2px;
    }
`);

const BoardView = ResponsiveHoc({
    xs: BoardMobile,
    sm: BoardDesktop,
});

function Board(props) {
    const match = useRouteMatch();
    const history = useHistory();
    const { UserStore, boardsStore } = useStore();
    const { user, isAgent, isLO } = UserStore;
    const activeBoard = boardsStore.activeBoard;
    const onboarding = useOnboarding();
    const { isMobile } = useDeviceBreakpoints();

    // determine if we should show the service terms modal to the user
    // if the user does not have the terms_and_conditions_accepted_v1 we will display the modal
    const showTermsAndConditionsModal = user?.terms_and_conditions_accepted !== process.env.REACT_APP_TERMS_AND_CONDITIONS_VERSION && UserStore.isConsumer;
    const termsAndConditionModalVariant = useMemo(()=> {
        return UserStore?.firstRunOnboardingCompleted ? 'updated_terms' : 'new_user';
    }, [UserStore]);

    // UseEffects
    const handleModalClose = useCallback(()=> {
        history.push(match.url);
        // if(activeBoard.listpacks.length === 0 && !UserStore.asyncFirstRunOnboardingCompleted && UserStore.isFromNurtureUnlimited) {
        //     onboarding.startTour('asyncFirstRun');
        // }
    }, [history, match, activeBoard, UserStore, onboarding]);

    return (
        <>
            <Box height={1}>
                <BoardView />
            </Box>

            <Can I="add" a="Listing">
                <AddAnyHomeButton
                    color="primary"
                    title="Add any home"
                    component={Link}
                    to={`${match.url}/add-any-home`}
                    className="tour-target-add-home-button"
                    onClick={()=> {
                        // if(onboarding.isFirstRunOnboarding) {
                        //     onboarding.next();
                        // }
                    }}
                >
                    <AddHomeIcon />
                </AddAnyHomeButton>
            </Can>

            <TermsAndConditionsModal 
                open={showTermsAndConditionsModal}
                variant={termsAndConditionModalVariant}
            />
            
            <Switch>
                <Route
                    path={`${match.url}/cards/:cardId`}
                    render={()=> <BoardCardModal open={true} onClose={handleModalClose} />}
                />
                <Route
                    path={`${match.url}/cards/:cardId/:addressSlug`}
                    render={()=> <BoardCardModal open={true} onClose={handleModalClose} />}
                />
                {/* <Route path={`${match.url}/listpack/:listpackId/listings`} component={ListingsMap} /> */}
                <Route path={`${match.url}/archivedListings`} render={ArchivedHomesModal} />
                <Route path={`${match.url}/map/:laneId`} component={ListingsMap} />

                <Route
                    path={`${match.url}/compare-now`}
                    children={({ match:routeMatch })=> (
                        <CompareNowModal
                            open={Boolean(routeMatch)}
                            onClose={()=> history.push(match.url)}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/mortgage-center`}
                    children={({ match:routeMatch })=> {
                        return (
                            <MortgageCenterBoardModal
                                open={Boolean(routeMatch)}
                                PaperProps={{
                                    elevation: 0,
                                }}
                                TransitionComponent={Slide}
                                TransitionProps={{
                                    direction: 'up',
                                }}
                                onClose={()=> {
                                    history.push(match.url);
                                }}
                                style={{
                                    zIndex: isMobile ? 1300 : 1299,
                                }}
                            />
                        );
                    }}
                />

                {boardsStore.loaded && (
                    <>
                        <Route path={`${match.url}/listings/:listingIds`} component={BoardListingModal} />
                        <Route
                            path={`${match.url}/lane/:laneId/:laneSlug/cards/:cardId/:addressSlug`}
                            component={BoardCardModal}
                        />
                        <Route path={`${match.url}/add-any-home`} component={AddAnyHomeModal} />
                        <Route path={`${match.url}/submit-offer/:boardCardId`} render={()=> (<SubmitOfferModal onClose={()=> history.push(match.url)} />)} />
                        <Route path={`${match.url}/schedule`} component={WantToSeeModal} />
                        <Route path={`${match.url}/notifications`} component={NotificationsModal} />
                        <Route
                            path={`${match.url}/account/:tab?`}
                            render={()=> (
                                <AccountSettingsModal onClose={handleModalClose} />
                            )}
                        />
                        <Route path={`${match.url}/invite-cobuyer`} component={InviteACoBuyerModal} />
                        <Route path={`${match.url}/board-manage`} component={BoardManageModal} />
                        <Route path={`${match.url}/name-home/:listingId`} component={NameHomeModal} />
                        <Route path={`${match.url}/listpack/:listpackId/edit`} component={ListpackModal} />
                        <Route path={`${match.url}/pinspiration`}><Redirect to={`${match.url}/idea-board`} /></Route>
                        <Route path={`${match.url}/archivedListings`} render={ArchivedHomesModal} />
                        <Route path={`${match.url}/map/:laneId`} component={ListingsMap} />
                        <Route
                            path={`${match.path}/signup`}
                            render={()=> (
                                <SignUpModal onClose={()=> history.push(match.url)}/>
                            )}
                        />
                        <Route
                            path={`${match.path}/verify`}
                            render={()=> (
                                <VerifyModal onClose={()=> history.push(match.url) }/>
                            )}
                        />
                        <Route
                            path={`${match.url}/onboarding-video`}
                            render={()=> (
                                <OnboardingVideoModal onClose={()=> history.push(match.url)} />
                            )}
                        />
                        {/* <Route exact path={`/boards/listpack/:listpackId/listings/edit`} render={(props)=> {
                            return <Redirect to={`/boards/listpack/${props.match.params.listpackId}/listings/listpack/edit?source=lane-expanded`} />;
                        }}></Route> */}
                        {/* <Route exact path={`/boards/listpack/:listpackId/listings/:listingId`} render={(props)=> {
                            return <Redirect to={`/boards/listpack/${props.match.params.listpackId}/listings/listing/${props.match.params.listingId}?source=lane-expanded`} />;
                        }}></Route> */}
                    </>
                )}
            </Switch>

            <Route
                path={`${match.url}/idea-board`}
                render={(props)=> (
                    <PinspirationBoardModal
                        {...props}
                        PaperProps={{
                            elevation: 0,
                        }}
                        TransitionComponent={Slide}
                        TransitionProps={{
                            direction: 'up',
                        }}
                        style={{
                            zIndex: isMobile ? 1300 : 1299,
                        }}
                    />
                )}
            />
            {!isAgent && !isLO && (
                <ModalRoute
                    path={`${match.url}/schedule-a-tour`}
                    Component={ScheduleATourModal}
                    ComponentProps={{
                        TransitionComponent: Slide,
                        TransitionProps: {
                            direction: 'up',
                        },
                        onClose: ()=> {
                            if(history.location.state?.previousPath){
                                history.push(history.location.state?.previousPath);
                            } else {
                                history.push('/boards');
                            }
                        },
                    }}
                />
            )}

            <ModalRoute
                path={`${match.url}/scheduled-tours`}
                Component={ScheduledToursModal}
                ComponentProps={{
                    TransitionComponent: Slide,
                    TransitionProps: {
                        direction: 'up',
                    },
                    onCloseClick: ()=> {
                        history.push('/boards');
                    },
                    onNewClick: ()=> {
                        history.push('/boards/schedule-a-tour');
                    },
                    style: {
                        zIndex: isMobile ? 1300 : 1299,
                    },
                }}
            />

            <ModalRoute
                path={`${match.url}/home-report`}
                Component={MyHomeModal}
                ComponentProps={{
                    TransitionComponent: Slide,
                    TransitionProps: {
                        direction: 'up',
                    },
                    onCloseClick: ()=> {
                        history.push('/boards');
                    },
                    style: {
                        zIndex: isMobile ? 1300 : 1299,
                    },
                }}
            />

            <ModalRoute
                path={`${match.url}/listpack/create/:areaId`}
                Component={ListpackSearchModal}
                renderOnlyRouteMatch={true}
                ComponentProps={{
                    TransitionComponent: Slide,
                    TransitionProps: {
                        direction: 'up',
                    },
                    onCloseClick: ()=> {
                        history.push('/boards');
                    },
                }}
            />

            <ModalRoute
                path={`${match.url}/listpack/:listpackId/listings`}
                Component={ListpackListingsMapAndListModal}
                ComponentProps={{
                    TransitionComponent: Slide,
                    TransitionProps: {
                        direction: 'up',
                    },
                    onCloseClick: ()=> {
                        history.push('/boards');
                    },
                }}
            />

            <ModalRoute
                path={`${match.url}/lane-expanded/:laneId/:laneSlug`}
                Component={ListingsMap}
                ComponentProps={{
                    TransitionComponent: Slide,
                    TransitionProps: {
                        direction: 'up',
                    },
                }}
            />
        </>
    );
}
Board.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default observer(Board);
