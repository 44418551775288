import React, { useEffect, useMemo } from 'react';
import { Box, withTheme, Hidden, Typography, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { useStore, useActiveBoard } from '../../../mobx-store';
import { OpenHouseDay } from './OpenHouseDay';
import { LaneFilter } from './../LaneFilter';
import { useGetBoardOpenHouses } from 'hooks/data/boards/open-house/useGetBoardOpenHouses';
import moment from 'moment';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useResponsive } from 'hooks';
import { useResponsiveBreakpoint } from 'hooks/use-responsive-breakpoint';

const FilterButton = withTheme(styled(ToggleButton)`
    width: 150px;
    height: 40px;

    ${({ theme })=> css`
        color: ${theme.palette.text.primary};
        &.Mui-selected {
            color: ${theme.palette.text.primary};
            background-color: ${theme.palette.grey[300]};
        }
    `}
`);

const OpenHouseDayContainer = withTheme(styled.div`
    ${({ isFirst, isLast, theme })=> css`
        padding-top: ${theme.spacing(3)}px;
        padding-bottom: ${theme.spacing(3)}px;
        border-bottom: 1px solid;
        border-color: ${theme.palette.grey[200]};
        ${isFirst &&
            css`
                padding-top: ${theme.spacing(1)}px;
            `}
        ${isLast &&
            css`
                padding-bottom: 0;
                border-bottom: 0;
            `};
    `}
`);

export const SchedulePane = observer(()=> {
    const { OpenHouseCalendarStore } = useStore();
    const r  = useResponsive();
    const activeBoard = useActiveBoard();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const currentFilter = OpenHouseCalendarStore.openHouseFilter;
    const laneFilter = OpenHouseCalendarStore.listingsLaneFilter;

    const { data, isLoading } = useGetBoardOpenHouses(activeBoard?.id, currentFilter, laneFilter);
    
    const sortedOpenHouses = useMemo(()=> {
        if(!data) return null;

        return data.reduce((accum, value)=> {
            if(!accum[value.openHouseInfo.date]) {
                accum[value.openHouseInfo.date] = [];
            }
            accum[value.openHouseInfo.date].push(value);
            return accum;
        }, {});

    }, [data, isLoading]);

    const structuredOpenHouses = useMemo(()=> {

        if(!sortedOpenHouses) return [];

        return Object.keys(sortedOpenHouses || {})
            .map((date)=> {
                const openHouses = sortedOpenHouses[date];
                openHouses.sort((oh1, oh2)=> {
                    const momentStartTime1 = moment(oh1.openHouseInfo.start_time_iso);
                    const momentStartTime2 = moment(oh2.openHouseInfo.start_time_iso);
                    return momentStartTime1.diff(momentStartTime2);
                });
                return { date, openHouses };
            })
            .sort(({ date: date1 }, { date: date2 })=> {
                return moment(date1).diff(moment(date2));
            });
    }, [sortedOpenHouses]);

    const handleFilterChange = (e)=> {
        OpenHouseCalendarStore.setOpenHouseFilter(e.currentTarget.value);
    };

    const openHouseCountCopy = useMemo(()=> {
        const openHousesCount = data?.length || 0;

        switch(currentFilter) {
            case 'scheduled':
                return `You have ${openHousesCount} open houses scheduled.`;
                break;
            case 'past':
                return `You have ${openHousesCount} past open houses.`;
                break;
            default:
                return `There are ${openHousesCount} open houses on your board coming up.`;
        }

    }, [currentFilter, data]);

    return (
        <Box display="flex" height="100%" flexDirection="column" overflow="auto">
            <Box flexGrow="1" flexBasis="auto" height="0px">
                <Box maxWidth="1000px" p={{ xs: 0, md: 4 }}>
                    <Hidden smDown>
                        <Box mb={3}>
                            <ToggleButtonGroup value={currentFilter} onChange={handleFilterChange}>
                                <FilterButton value="all">All</FilterButton>
                                <FilterButton value="available">Available</FilterButton>
                                <FilterButton value="scheduled">Scheduled</FilterButton>
                                <FilterButton value="past">Past</FilterButton>
                            </ToggleButtonGroup>
                        </Box> 
                    </Hidden>
                    <Box mb={4} display={{ xs: 'none', sm: 'flex' }} justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" style={{ fontWeight: 500 }}>{openHouseCountCopy}</Typography>
                        <Hidden smDown>
                            <Box>
                                <LaneFilter></LaneFilter>
                            </Box>
                        </Hidden>
                    </Box>
                    <Box position="relative">
                        {
                            isMobile && !isLoading && !data?.length && (
                                <div
                                    style={{
                                        marginTop: '24px',
                                        textAlign: 'center'    
                                    }}
                                >
                                    <Typography variant="h6">{openHouseCountCopy}</Typography>
                                </div>
                            )
                        }
                        <Box
                            bgcolor="grey.100"
                            style={{
                                display: isLoading || !isLoading && data && data.length ? 'block' : 'none',
                                padding: r({ xs: '24px 16px', md: '24px 48px' }),
                            }}
                        >
                            {isLoading ? (
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                >
                                    <CircularProgress color="secondary" />
                                </div>
                            ) : structuredOpenHouses.map(({ date, openHouses }, index)=> {
                                const isFirst = index === 0;
                                const isLast = structuredOpenHouses.length - 1 === index;
                                return (
                                    <OpenHouseDayContainer
                                        isFirst={isFirst}
                                        isLast={isLast}
                                        key={date}
                                        id={`date-container-${date}`}
                                    >
                                        <OpenHouseDay date={date} openHouses={openHouses}></OpenHouseDay>
                                    </OpenHouseDayContainer>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});
