import React, { useState, useEffect, useMemo, useCallback } from 'react';
import LRModal from '../LRModal';
import styled from 'styled-components';
import { withTheme } from '@material-ui/styles';
import { Typography, useTheme, TextareaAutosize, IconButton, RadioGroup } from '@material-ui/core';
import LRButton from '../LRButton';
import { LRRadio } from '../LRRadio';
import { Close } from '@material-ui/icons';
import { useResponsiveBreakpoint } from '../../hooks';
import { useStore } from '../../mobx-store';
import Logger from 'js-logger';
import { useFormField } from '../../hooks/use-form-field';
import analytics from '../../services/analytics';
import { useOnboarding } from '../Onboarding';
import { observer } from 'mobx-react-lite';

const logger = Logger.get('LRAddNoteModal');

const Modal = withTheme(styled((props)=> (
    <LRModal {...props} />
))`
    & .paperWidthSm-listing-details {
        height: auto;
        max-width: 560px;
        border-radius: 4px;
        padding: 0;

        ${({ theme })=> theme.breakpoints.only('xs')} {
            height: 100%;
        }
    }

    & .AddNoteModal-DialogContent-root {
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }
`);

const allVisibilityOptions = {
    'consumer': [
        { value: 'consumer', label: 'Only co-buyers' },
        { value:'consumer_agent', label: 'Only co-buyers and agent' },
        { value: 'consumer_agent_lo', label: 'Co-buyers, agent and loan officer' },
    ],
    'agent': [
        { value:'consumer_agent', label: 'Only buyers' },
        { value: 'consumer_agent_lo', label: 'Buyers and loan officer' },
    ],
};

export const LRAddNoteModal = observer(function LRAddNoteModal({
    cobuyersToExclude = [],
    itemId,
    boardCollaboration,
    open = false,
    hideVisibilityOptions = false,
    onClose = ()=> {},
    addBoardCollaboration = ()=> {},
    updateBoardCollaboration = ()=> {},
    deleteBoardCollaboration = ()=> {},
    eventSlug = '',
}) {
    const theme = useTheme();
    const { UserStore, boardsStore } = useStore();
    const { user, usersBoardRole, isAgent, isLO, profileId } = UserStore;
    const initVisibility = (isLO) ? 'consumer_agent_lo' : (isAgent) ? 'consumer_agent' : 'consumer';
    const [visibility, setVisibility] = useState(initVisibility);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const visibleToAffiliateChecked = useMemo(()=> (/agent/ig).test(visibility), [visibility]);
    const visibleToLOChecked = useMemo(()=> (/lo/ig).test(visibility), [visibility]);
    const getVisibility = useCallback((collab)=> {
        const visibleToList = ['consumer'];
        if(collab.visibleToAffiliate) visibleToList.push('agent');
        if(collab.visibleToLO) visibleToList.push('lo');
        return visibleToList.join('_');
    }, []);
    const [closeButtonRef, setCloseButttonRef] = useState();
    const isXs = useResponsiveBreakpoint('xs');
    const boardUsers = boardsStore.activeBoard ? boardsStore.activeBoard.collaborators : [];
    const [messageProps, message, setMessage] = useFormField();
    const activeBoard = boardsStore.activeBoard;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const onboarding = useOnboarding();
    const getVisibleToNames = useCallback((visibilityVal)=> {
        let bUsers = boardUsers.filter((bUser)=> (isAgent || isLO || bUser.email !== user.email));
        bUsers = bUsers.filter((bUser)=> {
            const isInList = cobuyersToExclude.find((co)=> co.userId === bUser.userId);
            return !isInList;
        });
        if((/agent/ig).test(visibilityVal) && !isAgent) {
            bUsers.unshift(user.affiliate);
        }
        if((/lo/ig).test(visibilityVal) && !isLO) {
            bUsers.unshift(user.affiliate?.preferred_vendor);
        }
        if(!bUsers?.length) {
            return '';
        }
        let vtText = '';
        if(bUsers.length < 6) {
            vtText = bUsers.map((bUser, i)=> (`${
                (i && i === bUsers.length - 1)
                    ? ' and '
                    : i
                        ? ', '
                        : ''
            }${bUser?.first_name}`))
                .join('');
        } else {
            vtText = bUsers.slice(0, 5)
                .map((bUser)=> bUser.first_name)
                .join(', ');
            vtText = `${vtText} +${bUsers.length - 5} other${(bUsers.length - 5 > 1) ? 's' : ''}`;
        }

        return vtText;
    }, [boardUsers, user, cobuyersToExclude, isAgent, isLO]);
    const visibilityOptionsKey = (isAgent) ? 'agent' : 'consumer';
    const visibilityOptions = allVisibilityOptions[visibilityOptionsKey]
        .filter((option)=> {
            // only show the LO visibility option
            // if the agent is paired with them and if they have robin access
            return option?.value &&
                ( !(/lo/).test(option.value) ||
                (user.affiliate?.preferred_vendor && UserStore.loRobinAccess)
                );
        })
        .map((option)=> {
            option.visibleTo = getVisibleToNames(option.value);
            option.disabled = (boardCollaboration?.visibleToAffiliate)
                ? (boardCollaboration.visibleToLO) ? true : option.value === 'consumer'
                : false;
            return option;
        });

    useEffect(()=> {
        if(boardCollaboration) {
            setMessage(boardCollaboration.content.message);
            setVisibility(getVisibility(boardCollaboration));
        }
    }, [boardCollaboration, setMessage, getVisibility]);

    async function submitForm(e) {
        setIsSubmiting(true);
        if(e) e.preventDefault();

        if(message) {
            const trimmedMessage = message.trim();

            try {
                if(boardCollaboration) {
                    const data = {
                        content: {
                            message: trimmedMessage,
                        },
                        last_seen_at: {
                            [profileId]: new Date().toISOString(),
                        },
                        visibleToAffiliate: boardCollaboration.visibleToAffiliate || visibleToAffiliateChecked,
                        visibleToLO: boardCollaboration.visibleToLO || visibleToLOChecked,
                    };

                    await updateBoardCollaboration({ id: boardCollaboration.id, data });
                    analytics.eventTrack(`${eventSlug}_note_edited`, {
                        board_card_id: itemId,
                        private: !visibleToAffiliateChecked,
                        role: usersBoardRole,
                    });
                } else {
                    const msg = {
                        boardId: activeBoardId,
                        itemId: itemId,
                        content: {
                            message: trimmedMessage,
                        },
                        type: 'comment',
                        last_seen_at: {
                            [profileId]: new Date().toISOString(),
                        },
                        visibleToAffiliate: visibleToAffiliateChecked ?? false,
                        visibleToLO: visibleToLOChecked ?? false,
                    };
                    if(boardsStore?.activeBoard?.boardCardsById[itemId]) {
                        boardsStore.activeBoard.boardCardsById[itemId].total_notes = (boardsStore.activeBoard.boardCardsById[itemId].total_notes || 0) + 1;
                    }

                    await addBoardCollaboration(msg);
                    
                    if(msg.visibleToAffiliate || msg.visibleToLO) {
                        await UserStore.warmUpHubspotLeadToHot();
                    }
                    
                    analytics.eventTrack(`${eventSlug}_note_added`, {
                        board_card_id: itemId,
                        private: !visibleToAffiliateChecked,
                        role: usersBoardRole,
                    });
                }

                onClose(e, !boardCollaboration);
                resetForm();

                if(onboarding.isAddNoteOnboarding && onboarding.stepId === 'await-note-submit') {
                    onboarding.next();
                }

                if(!onboarding.hasTour && !UserStore.addNoteOnboardingCompleted)  {
                    UserStore.completeAddNoteOnboarding();
                }

            } catch (err) {
                logger.error('submitForm -> Error', err);
            }
        }
        setIsSubmiting(false);
    }

    async function deleteCollaboration(e) {
        if(!boardCollaboration) return;

        try {
            await deleteBoardCollaboration({ boardCollaboration });
            analytics.eventTrack(`${eventSlug}_note_deleted`, {
                board_card_id: itemId,
                private: boardCollaboration.visibleToAffiliate,
                role: usersBoardRole,
            });
            if(boardsStore?.activeBoard?.boardCardsById[itemId]) {
                boardsStore.activeBoard.boardCardsById[itemId].total_notes = Math.max((boardsStore.activeBoard.boardCardsById[itemId].total_notes || 1) - 1, 0);
            }
            onClose(e);
            resetForm();
        } catch (err) {
            logger.error('deleteCollaboration -> Error', err);
        }
    }

    function resetForm() {
        setMessage('');
        setVisibility(initVisibility);
    }

    return (
        <Modal
            className="AddNoteModal"
            open={open}
            dialogContentClasses={{ root: 'AddNoteModal-DialogContent-root' }}
            closeButtonRef={closeButtonRef}
        >
            <div
                style={{
                    height: isXs ? '100%' : 'auto',
                    display: isXs ? 'flex' : 'block',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    flexGrow: '1',
                }}
            >
                <Typography
                    className="AddNoteModal-Title"
                    variant="h5"
                    component="h1"
                    align="center"
                    style={{
                        fontWeight: 'normal',
                        paddingBottom: isXs ? theme.spacing(3) : 0,
                        marginTop: isXs ? theme.spacing(3) : theme.spacing(4),
                        marginBottom: isXs ? 0 : theme.spacing(3),
                        marginLeft: `-${theme.spacing(3)}`,
                        marginRight: `-${theme.spacing(3)}`,
                    }}
                >
                    {boardCollaboration ? 'Edit' : 'Add a'} note
                </Typography>

                <div
                    className="AddNoteModal-Textarea-Container"
                    style={{
                        flexGrow: '1',
                        marginBottom: isXs ? 0 : theme.spacing(1.5),
                        marginLeft: isXs ? 0 : theme.spacing(7),
                        marginRight: isXs ? 0 : theme.spacing(7),
                        border: `1px solid ${theme.palette.lr_colors.border_grey}`,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TextareaAutosize
                        {...messageProps}
                        className="AddNoteModal-Textarea-Container-Textarea"
                        rowsMin={5}
                        style={{
                            width: '100%',
                            height: isXs && '100%',
                            padding: theme.spacing(2),
                            fontSize: '1rem',
                            border: '0',
                            borderRadius: isXs ? 0 : '2px',
                            resize: 'none',
                            outline: 'none',
                            fontFamily: 'inherit',
                            flexGrow: '1',
                        }}
                        placeholder="Write your note here!"
                        autoFocus
                    ></TextareaAutosize>
                </div>


                <div
                    className="AddNoteModal-CTAS-Container"
                    style={{
                        display: isXs ? 'auto' : 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: isXs ? theme.spacing(2) : 0,
                        paddingLeft: isXs ? theme.spacing(3) : theme.spacing(7),
                        paddingRight: isXs ? theme.spacing(3) : theme.spacing(7),
                        marginBottom: isXs ? theme.spacing(2) : theme.spacing(4),
                    }}
                >
                    {!hideVisibilityOptions && (
                        <div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    Who can see your note?
                                </Typography>
                            </div>
                            <div style={{ marginBottom: isXs ? theme.spacing(2) : 0 }}>
                                {!isLO && (
                                    <RadioGroup name="option" value={visibility} onChange={(e)=> setVisibility(e.target.value)} >
                                        {visibilityOptions.map((option)=> {
                                            return (
                                                <div key={option.value} style={{
                                                    marginTop: theme.spacing(1),
                                                    textAlign: 'left',
                                                }}>
                                                    <LRRadio longLabel={true} {...option} />
                                                    <div
                                                        style={{
                                                            color: theme.palette.lr_colors.grey_dark,
                                                            fontSize: '10px',
                                                            lineHeight: '1.35rem',
                                                            marginLeft: '-10px',
                                                            verticalAlign: 'bottom',
                                                            display: 'inline',
                                                        }}
                                                    >
                                                        {option.visibleTo}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </RadioGroup>
                                )}

                                {isLO && (
                                    <div style={{ marginTop: theme.spacing(1) }}>
                                        <Typography variant="body1">
                                            Buyer(s) and agent
                                        </Typography>
                                        <div
                                            style={{
                                                color: theme.palette.lr_colors.grey_dark,
                                                fontSize: '10px',
                                            }}
                                        >
                                            { getVisibleToNames('consumer_agent_lo') }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginLeft: 'auto',
                        }}
                    >
                        {boardCollaboration && (
                            <>
                                <LRButton variant="outlined" fullWidth={isXs} onClick={deleteCollaboration}>
                                    Delete
                                </LRButton>

                                <div style={{ width: theme.spacing(2) }} />
                            </>
                        )}

                        <LRButton color="secondary" disabled={isSubmiting} variant="contained" fullWidth={isXs} onClick={submitForm}>
                            {boardCollaboration ? 'Save' : 'Add'}
                        </LRButton>
                    </div>
                </div>

                {/* Close Buttons */}
                <div
                    className="AddNoteModal-CloseButton"
                    style={{
                        position: 'absolute',
                        top: isXs ? '16px' : '0px',
                        right: isXs ? '8px' : '-55px',
                    }}
                >
                    <IconButton
                        ref={setCloseButttonRef}
                        style={{
                            color: isXs ? 'inherit' : '#fff'
                        }}
                        onClick={(e)=> {
                            onClose(e);
                            resetForm();
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            </div>
        </Modal>
    );
});
