import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LeadForm } from '../../components/LeadForm';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import analytics from '../../services/analytics';
import styled from 'styled-components';
import { useQuery } from '../../hooks';
import { useResponsiveBreakpoint } from '../../hooks';
import LRModal from '../../components/LRModal';
import LRButton from 'components/LRButton';
import { withTheme } from '@material-ui/styles';
import { portalApi } from '../../apis';
import Logger from 'js-logger';
const logger = Logger.get('LeadCaptureFormModal');

const Modal = withTheme(styled((props)=> (
    <LRModal {...props} />
))`
    & .paperWidthSm-listing-details {
        height: auto;
        max-width: 560px;
        border-radius: 4px;
        padding: 0;

        ${({ theme })=> theme.breakpoints.only('xs')} {
            height: 100%;
        }
    }

    & .LeadCaptureFormModal-DialogContent-root {
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }
`);

function FormHeader({ userType, ...props }) {
    return (
        <Box fontSize="20px" fontWeight="500">
            {userType === 'agent' ?
                'Interested in buying or selling a home? Get in touch!' :
                'Need help with mortgage financing?'
            }
        </Box>
    );
}

export function LeadCaptureFormModal({
    user,
    address,
    leadSource = 'Shareables',
    isOpen,
    onClose = ()=> {},
    trackSource,
    ...props
}) {
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;
    const [closeButtonRef, setCloseButttonRef] = useState();
    const shareableSlug = useQuery()
        .get('slug');
    const shareableType = useQuery()
        .get('type');
    const customImage = useQuery()
        .get('customImage');
    const onLeadCreated = async (lead, message)=> {
        if(lead?.id) {
            analytics.eventTrack('robin_logged_out_contact_agent_form_submit', {
                distinct_id: user?.id,
                ...(shareableSlug && { 'Shareable Slug': shareableSlug }),
                ...(shareableType && { 'Shareable Type': shareableType }),
                ...(customImage && { 'uploaded_image': customImage }),
                source: trackSource,
            });

            let activityData = {
                name: 'myleads_from_robin_shareables',
                type: 'lead',
                userId: user.id,
                meta: {
                    lead_name: lead.applicant.name,
                    lead_email: lead.applicant.email,
                    lead_phone: lead.applicant.phone,
                    leadId: lead.id,
                    message,
                    shareable_slug: shareableSlug,
                    shareable_type: shareableType,
                    address,
                },
            };

            try {
                await portalApi.createActivity(activityData);
                await portalApi.sendPremiumShareablesEmail(user, lead, message);
            } catch (err) {
                logger.debug('onLeadCreated error', err);
            }
        }

        // Delayed modal close to allow for the user
        // to see the Snackbar feedback on form submit
        setTimeout(()=> onClose(), 1500);
    };

    if(!user?.id) return null;

    return (
        <Modal
            open={isOpen}
            fullWidth={false}
            onClose={onClose}
            className="LeadCaptureFormModal"
            dialogContentClasses={{ root: 'LeadCaptureFormModal-DialogContent-root' }}
            closeButtonRef={closeButtonRef}
        >
            <Box width="100%">
                <Box
                    px={4}
                    pb="28px"
                    pt="26px"
                >
                    <LeadForm
                        user={user}
                        loadingUser={!user}
                        loadedUser={!!user}
                        formHeader={<FormHeader userType={user?.type} />}
                        variant={isMobile ? 'mobile' : 'desktop'}
                        leadSource={leadSource}
                        onLeadCreated={onLeadCreated}
                        submitText="Get in touch"
                        address={address}
                    />
                </Box>

                {/* Close Buttons */}
                <div
                    className="LeadCaptureFormModal-CloseButton"
                    style={{
                        position: 'absolute',
                        top: isXs ? '16px' : '0px',
                        right: isXs ? '8px' : '-55px',
                    }}
                >
                    <IconButton
                        ref={setCloseButttonRef}
                        style={{
                            color: isXs ? 'inherit' : '#fff'
                        }}
                        onClick={(e)=> onClose(e)}
                    >
                        <Close />
                    </IconButton>
                </div>
            </Box>
        </Modal>
    );
}

export function ContactAgentLeadCaptureButton({ agent, address, trackSource, variant, buttonProps }) {
    const [isOpen, setIsOpen] = useState(false);
    const { affiliateCode } = useParams();
    const openLeadCaptureModal = ()=> {
        analytics.eventTrack('robin_logged_out_contact_agent_button_click', {
            agent_user_id: affiliateCode,
            source: trackSource,
            distinct_id: agent?.id || affiliateCode,
        });
        setIsOpen(true);
    };
    const onClose = ()=> {
        setIsOpen(false);
    };

    return (
        <>
            <div style={variant === 'text' ? { display: 'inline-block' } : { minWidth: 125 }}>
                <LRButton variant={variant || 'contained'}
                    color="primary"
                    onClick={openLeadCaptureModal}
                    {...buttonProps}
                >
                    Contact me
                </LRButton>
            </div>

            <LeadCaptureFormModal
                isOpen={isOpen}
                onClose={onClose}
                user={agent}
                address={address}
                trackSource={trackSource}
            />
        </>
    );
}

