import React, { useEffect, useRef, useMemo } from 'react';
import { Drawer, makeStyles, IconButton, Typography, Collapse, Toolbar, AppBar, Grid, Box, Icon } from '@material-ui/core';
import { ChevronLeft, ExpandMore, Close, ArrowForward } from '@material-ui/icons';
import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import { useState } from 'react';
import analytics from '../../../services/analytics';
import { useStore } from '../../../mobx-store';
import LRAvatar, { MultipleAvatars } from '../../LRAvatar';
import LRButton from '../../LRButton';
import formatPhoneNumber from '../../../services/format-phone-number';
import Logger from 'js-logger';
import { ResponsiveImg } from '../../ResponsiveImg';
import { useResponsiveBreakpoint } from '../../../hooks';
import { observer } from 'mobx-react-lite';
import { AgentMessage } from './AgentMessage';
import { useAbility } from '../../Ability';
import { useOnboarding } from '../../Onboarding';
import { OnboardingChecklist } from './OnboardingChecklist';
import { AgentNotifications } from './AgentNotifications';
import { NotificationRing } from '../../NotificationRing';
import { TimeframeForm } from './TimeframeForm';
import { useLocation } from 'react-router-dom';

const logger = Logger.get('LeftSidebar');

const useStyles = makeStyles((theme)=> ({
    drawer: {
        whiteSpace: 'nowrap',
        zIndex: 20,
        [theme.breakpoints.only('xs')]: {
            zIndex: 1301,
        },
    },
    drawerOpen: {
        marginLeft: 0,
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClosed: {
        marginLeft: '-287px',
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerPaper: {
        position: 'relative',
        overflow: 'visible',
    },
    drawerPaperXs: {
        width: '100%',
    },
    drawerIconButtonRoot: {
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: '#ccc',
        }
    },
    avatarVisible: {
        right: '-21px',
        opacity: 1,
        transition: theme.transitions.create(['right'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
            delay: '10ms',
        }),
    },
    avatarHidden: {
        right: '312px',
        opacity: 0,
        transition: theme.transitions.create(['right', 'opacity'], {
            easing: theme.transitions.easing.easeOut,
            duration: '200ms',
        }),
    },
}));

// TODO: Has notifications
export const LeftSidebar = observer(function LeftSidebar({
    open = false,
    onOpen = ()=> {},
    onClose = ()=> {},
    ...props
}) {
    const theme = useTheme();
    const { UserStore, boardsStore, NotificationsStore } = useStore();
    const { user, isAgent, isLO } = UserStore;
    const classes = useStyles();
    const [lastOpenValue, setLastOpenValue] = useState(open);
    const { activeBoard } = boardsStore;
    const [openAgentInfo, setOpenAgentInfo] = useState(false);
    const isXs = useResponsiveBreakpoint('xs');
    const ability = useAbility();
    const onboarding = useOnboarding();
    const canDoOnboarding = ability.can('read', 'Onboarding');
    const location = useLocation();
    const shouldTriggerFirstRunExperience = useMemo(()=> {
        const ALLOWED_FIRST_RUN_ONBOARDING_ROUTES = [
            '/boards',
        ];

        return ~ALLOWED_FIRST_RUN_ONBOARDING_ROUTES.indexOf(location.pathname) && !UserStore.firstRunOnboardingCompleted;
    }, [UserStore.firstRunOnboardingCompleted, location.pathname]);
    const shouldTriggerAsyncOnboarding = UserStore.firstRunOnboardingCompleted && !UserStore.asyncOnboardingCompleted;
    const [previousNotificationCount, setPreviousNotificationCount] = useState();
    const [showNotificationPulse, setShowNotificationPulse] = useState(false);
    const notificationPulseTimeout = useRef();
    const [showOnBoardingChecklist, setShowOnBoardingChecklist] = useState(!UserStore.asyncOnboardingCompleted);
    const [animateOnBoardingChecklist, setAnimateOnBoardingChecklist] = useState(false);
    
    useEffect(()=> {
        if(!shouldTriggerFirstRunExperience) {
            return;
        }
        if(canDoOnboarding) {
            onOpen();
        } else {
            onClose();
        }
    }, [canDoOnboarding]);

    // useEffects
    useEffect(()=> {

        if(UserStore.asyncOnboardingCompleted) {
            const timeout = UserStore.asyncOnboardingCompleted ? 2000 : 0;
            setTimeout(()=> {
                setShowOnBoardingChecklist(!UserStore.asyncOnboardingCompleted);
            }, timeout);
        }
    }, [UserStore.asyncOnboardingCompleted, showOnBoardingChecklist]);


    useEffect(()=> {
        if(lastOpenValue === open) return;

        setLastOpenValue(open);
        analytics.eventTrack(`robin_board_drawer_${open ? 'opened' : 'closed'}`, {
            board_id: activeBoard?.id,
        });
    }, [open]);

    useEffect(()=> {
        if(previousNotificationCount == null) {
            setPreviousNotificationCount(NotificationsStore.unreadAgentActionsNotifications.length);
            return;
        }

        if(NotificationsStore.unreadAgentActionsNotifications.length <= previousNotificationCount) return;

        if(notificationPulseTimeout.current) {
            clearTimeout(notificationPulseTimeout.current);
        }

        setShowNotificationPulse(true);

        notificationPulseTimeout.current = setTimeout(()=> {
            setShowNotificationPulse(false);
        }, 11000);

        return ()=> {
            setShowNotificationPulse(false);

            if(notificationPulseTimeout.current) {
                clearTimeout(notificationPulseTimeout.current);
            }
        };
    }, [NotificationsStore.unreadAgentActionsNotifications.length]);

    // Functions

    const ONBOARDING_MESSAGES = {
        blank: {
            ctaText: 'Got it!',
            title: 'See what you can do with Robin!',
            message: 'The setup checklist below is a great way to get acquainted with your new home base in this home buying journey.',
            cta: ()=> { UserStore.dismissFirstRunOnboardingMessage(); } 
        },
        moved_card: {
            ctaText: 'Got it!',
            title: 'See what you can do with Robin!',
            message: 'The setup checklist below is a great way to get acquainted with your new home base in this home buying journey.',
            cta: ()=> { UserStore.dismissFirstRunOnboardingMessage(); } 
        },
        saved_search: {
            ctaText: 'Will do!',
            title: 'Nice! Now try putting a home on the board',
            message: 'Saved searches are live filters, so if you see something you want to take notes on, move it to your Interested column for safekeeping.',
            cta: ()=> { UserStore.dismissFirstRunOnboardingMessage(); }
        }, 
        all_done: {
            ctaText: 'Okay!',
            title: 'You’re great at this already! 💪',
            message: 'Complete your setup checklist to see what else you can do with Robin.',
            cta: ()=> { UserStore.dismissFirstRunOnboardingMessage(); }
        },
    };

    const onboardingMessageOptions = useMemo(()=> {
        const hasSavedSearches = user?.meta?.async_onboarding?.created_saved_searches; 
        const hasMovedCards = user?.meta?.async_onboarding?.moved_board_cards;
 
        let mapperKey = 'blank';
        if(hasSavedSearches && hasMovedCards) {
            mapperKey = 'all_done';
        } else if(hasSavedSearches && !hasMovedCards) {
            mapperKey = 'saved_search';
        } else if(!hasSavedSearches && hasMovedCards) {
            mapperKey = 'moved_card';
        }
        return ONBOARDING_MESSAGES[mapperKey];
    }, [UserStore?.user?.meta?.async_onboarding?.created_saved_searches, UserStore?.user?.meta?.async_onboarding?.moved_board_cards]);

    function toggleOpenAgentInfo() {
        const newValue = !openAgentInfo;

        analytics.eventTrack(`robin_board_drawer_agent_details_${newValue ? 'expanded' : 'collapsed'}`, {
            board_id: activeBoard?.id,
        });
        setOpenAgentInfo(newValue);
    }

    function handleToggle(changeType) {
        if(changeType === 'video') return;

        if(canDoOnboarding && !UserStore.firstRunOnboardingCompleted) {
            onOpen();
        } else {
            open ? onClose() : onOpen();
        }
    }

    async function handleStartFirstRunOnboarding() {
        const onbordingFlow = UserStore?.isFromNurtureUnlimited ? 'nurtureFirstRun' : 'firstRun';
        let onboardingOptions = {};
        
        if(UserStore?.isFromNurtureUnlimited) {
            onboardingOptions = {
                triggerOrigin: 'sidenav'
            };
        }

        await UserStore.completeAsyncFirstRunOnboarding({ dismissed: true });
        await UserStore.initiateFirstRunOnboarding();
        onboarding.startTour(onbordingFlow, onboardingOptions);
        analytics.eventTrack('robin_onboarding_welcome_cta_clicked', {
            board_id: activeBoard?.id,
        });
        onClose();
    }

    function trackPhoneNumberClick(e) {
        analytics.eventTrack('robin_board_drawer_agent_phone_clicked');
    }

    function trackEmailClick(e) {
        analytics.eventTrack('robin_board_drawer_agent_email_clicked');
    }

    function onPrimaryNotificationButtonClick(e) {
        if(isXs) {
            onClose(e);
        }
    }

    const isCaliforniaAgent = user.affiliate?.licensed_address?.state === 'CA' || user.affiliate?.licensed_address?.state === 'California';

    return (
        <>
            {Boolean(!isXs && open && canDoOnboarding && shouldTriggerFirstRunExperience && !onboarding.isFirstRunOnboarding) && (
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        top: 0,
                        zIndex: 10,
                        backgroundColor: 'black',
                        opacity: '0.5'
                    }}
                />
            )}

            <Drawer 
                open={open} 
                variant={isXs ? 'persistent' : 'permanent'} 
                anchor="left"
                className={clsx(classes.drawer, { [classes.drawerClosed]: !open, [classes.drawerOpen]: open, })}
                classes={{
                    paper: isXs ? classes.drawerPaperXs : classes.drawerPaper,
                }}
                SlideProps={{
                    direction: isXs ? 'up' : 'left',
                }}
                onClose={onClose}
                {...props}
            >
                <div
                    style={{
                        maxHeight: isXs ? '100%' : 'unset',
                        overflow: 'auto',
                    }}
                >
                    {/* Mobile Header */}
                    {isXs && (
                        <AppBar
                            elevation={0}
                            position="sticky"
                            style={{
                                backgroundColor: '#fff',
                                borderBottom: `1px solid ${theme.palette.lr_colors.grey_2}`,
                            }}
                        >
                            <Toolbar>
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="space-between"
                                    style={{
                                        height: '100%',
                                        color: theme.palette.text.primary,
                                    }}
                                >
                                    <Grid item xs={2}></Grid>

                                    <Grid item>
                                        <Typography>
                                            Agent
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        {(!shouldTriggerFirstRunExperience || !canDoOnboarding) && (
                                            <IconButton
                                                size="small"
                                                edge="end"
                                                onClick={handleToggle}
                                                className="tour-target-leftdrawer-modal-close-button"
                                            >
                                                <Close />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    )}

                    <div
                        style={{
                            width: isXs ? '100%' : '312px',
                            position: 'relative',
                        }}
                    >
                        {/* Content */}
                        <div
                            className="AgentNotifications-Content"
                            style={{
                                padding: theme.spacing(3),
                            }}
                        >
                            {/* Agent */}
                            <div
                                className="tour-target-leftsidebar-agent"
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        marginBottom: theme.spacing(1),
                                    }}
                                >
                                    <LRAvatar
                                        user={user?.affiliate}
                                        size={'size64'}
                                        style={{
                                            display: 'inline-flex',
                                        }}
                                        onClick={toggleOpenAgentInfo}
                                    />
                                </div>

                                <div>
                                    {/* Name */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <Typography>
                                            <b>
                                                {user?.affiliate?.name}
                                            </b>
                                        </Typography>

                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                position: 'relative',
                                                top: openAgentInfo ? '-4px' : '0',
                                                transform: `rotate(${openAgentInfo ? 180 : 0}deg)`,
                                                transition: theme.transitions.create('all', {
                                                    easing: theme.transitions.easing.sharp,
                                                    duration: openAgentInfo ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
                                                }),
                                            }}
                                            onClick={toggleOpenAgentInfo}
                                        >
                                            <ExpandMore />
                                        </div>
                                    </div>

                                    {/* Company */}
                                    <div>
                                        <Typography style={{ ...theme.typography.body4 }}>
                                            {user?.affiliate?.company}
                                        </Typography>
                                    </div>

                                    {/* Phone */}
                                    <div>
                                        {isXs ? (
                                            <LRButton href={`tel:${user?.affiliate?.phone}`} variant="text" inline color="secondary" onClick={trackPhoneNumberClick}>
                                                <Typography style={{ ...theme.typography.body4 }}>
                                                    {formatPhoneNumber(user?.affiliate?.phone)}
                                                </Typography>
                                            </LRButton>
                                        ) : (
                                            <Typography style={{ ...theme.typography.body4 }}>
                                                {formatPhoneNumber(user?.affiliate?.phone)}
                                            </Typography>
                                        )}
                                    </div>

                                    {/* License */}
                                    {user?.affiliate?.realtor_license_type && <div>
                                        <Typography style={{ ...theme.typography.body4, color: theme.palette.lr_colors.steak_sauce }}>
                                            {isCaliforniaAgent ? 'CA DRE #: ' :  'Lic #: '}
                                            {user?.affiliate?.realtor_license_number}
                                        </Typography>
                                    </div>}

                                    <Collapse in={openAgentInfo}>
                                        {/* Email */}
                                        <div>
                                            <LRButton href={`mailto:${user?.affiliate?.email}`} variant="text" inline color="secondary" onClick={trackEmailClick}>
                                                <Typography style={{ ...theme.typography.body4, textAlign: 'left', whiteSpace: 'break-spaces', overflowWrap: 'anywhere'}}>
                                                    {user?.affiliate?.email}
                                                </Typography>
                                            </LRButton>
                                        </div>

                                        {/* Branding */}
                                        <div
                                            style={{
                                                marginTop: theme.spacing(2),
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    maxWidth: '150px',
                                                    maxHeight: '300px',
                                                }}
                                            >
                                                <ResponsiveImg src={user?.affiliate?.branding} />
                                            </div>
                                        </div>

                                        {/* Address */}
                                        {user?.affiliate?.licensed_address && <div>
                                            <Typography style={{ ...theme.typography.body4 }}>
                                                {user?.affiliate?.licensed_address?.street} {user?.affiliate?.licensed_address?.street2}
                                            </Typography>

                                            <Typography style={{ ...theme.typography.body4 }}>
                                                {user?.affiliate?.licensed_address?.city}, {user?.affiliate?.licensed_address?.state} {user?.affiliate?.licensed_address?.zip}
                                            </Typography>
                                        </div>}
                                    </Collapse>

                                    {/* Agent Notifications */}
                                    {user?.meta?.first_run_onboarding_flow_completed && (
                                        <div>
                                            <AgentNotifications onPrimaryNotificationButtonClick={onPrimaryNotificationButtonClick} />
                                        </div>
                                    )}
                                    {canDoOnboarding && (
                                        <>
                                            <div style={{
                                                marginTop: theme.spacing(2),
                                                marginBottom: theme.spacing(2),
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                {
                                                    shouldTriggerFirstRunExperience ? (
                                                        <AgentMessage
                                                            title={`Welcome to Robin, ${user?.first_name} 👋`}
                                                            message="This is your board to search, organize, and collaborate around buying your home. It’s pretty straight-forward, but you should take a quick tour before getting started."
                                                            onCtaClick={handleStartFirstRunOnboarding}
                                                            animateCta={true}
                                                            ctaProps={{
                                                                disabled: !activeBoard?.listpacksLoaded
                                                            }}
                                                            ctaText={
                                                                (
                                                                    <>
                                                                        <span style={{ marginRight: theme.spacing(1)}}>Let's go</span>{' '}
                                                                        <ArrowForward style={{ fontSize: theme.typography.body1.fontSize}}></ArrowForward>
                                                                    </>
                                                                )
                                                            }
                                                        />
                                                    ) : !UserStore.dismissedFirstRunOnboardingMessage && onboardingMessageOptions && (
                                                        <AgentMessage
                                                            ctaText={
                                                                (
                                                                    <>
                                                                        <span style={{ marginRight: theme.spacing(1)}}>{ onboardingMessageOptions.ctaText }</span>
                                                                    </>
                                                                )
                                                            }
                                                            title={(
                                                                <span>
                                                                    {/* You’re great at this already! <span role="img" aria-label="img">💪</span> */}
                                                                    { onboardingMessageOptions.title }
                                                                </span>
                                                            )}
                                                            message={onboardingMessageOptions.message}
                                                            onCtaClick={onboardingMessageOptions.cta}
                                                        />
                                                    )
                                                }
                                            </div>
                                            { UserStore.usersBoardRole && UserStore.usersBoardRole !== '' &&UserStore.firstRunOnboardingCompleted && showOnBoardingChecklist &&
                                                    <OnboardingChecklist
                                                        onChange={handleToggle}
                                                        style={{
                                                            opacity: `${animateOnBoardingChecklist ? 0 : 1}`,
                                                            transition: 'opacity .5s linear'
                                                        }}
                                                    />
                                            }
                                        </>
                                    )}

                                    {/* Timeframe question */}
                                    { UserStore.showTimeframeForm && (
                                        <Box mt={3}>
                                            <TimeframeForm />
                                        </Box>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <PreferencesModal open={showPreferencesModal}/> */}
                    </div>
                </div>

                {!isXs && (
                    <>
                        {/* Agent Headshot Avatar */}
                        <NotificationRing
                            style={{
                                position: 'absolute',
                                top: '20px',
                                zIndex: 2,
                            }}
                            className={clsx({ [classes.avatarVisible]: !open, [classes.avatarHidden]: open, })}
                            showPulse={showNotificationPulse && !isAgent && !isLO && user?.meta?.first_run_onboarding_flow_completed}
                            hasNotifications={!!NotificationsStore.unreadAgentActionsNotifications.length && user?.meta?.first_run_onboarding_flow_completed}
                        >
                            <MultipleAvatars users={[user?.affiliate]} avatarSize="small" />
                        </NotificationRing>


                        {/* Toggle Drawer Button */}
                        <div
                            style={{
                                position: 'absolute',
                                top: '57px',
                                right: '-11px',
                                zIndex: 3,
                            }}
                        >
                            <IconButton
                                size="small"
                                classes={{
                                    root: classes.drawerIconButtonRoot,
                                }}
                                className="tour-target-leftsidebar-toggle"
                                onClick={handleToggle}
                            >
                                <ChevronLeft
                                    style={{
                                        fontSize: '16px',
                                        transform: `rotate(${open ? 0 : 180}deg)`,
                                        transition: theme.transitions.create('all', {
                                            easing: theme.transitions.easing.sharp,
                                            duration: open ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
                                        }),
                                    }}
                                />
                            </IconButton>
                        </div>
                    </>
                )}
            </Drawer>
        </>
    );
});
