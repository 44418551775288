import { Dialog, Hidden } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import SchedulePrivateTourContent from './SchedulePrivateTourContent';
import DialogHeader from './DialogHeader';
import DialogFooter from './DialogFooter';
import { FormContext, useForm } from 'react-hook-form';
import { useBoardCollaborations } from '../../hooks/useBoardCollaborations';
import moment from 'moment';
import _ from 'lodash';
import { useStore } from '../../mobx-store';
import { observer } from 'mobx-react-lite';
import analytics from '../../services/analytics';

const SchedulePrivateTourModal = observer(function SchedulePrivateTourModal({ open, onClose, card, agent }) {
    const formMethods = useForm({ mode: 'onChange' });
    const formRef = useRef();
    const [isSubmiting, setSubmiting] = useState(false);
    const [isScheduled, setScheduled] = useState(false);

    const { boardsStore } = useStore();
    const activeBoard = boardsStore.activeBoard;
    const activeBoardId = activeBoard ? activeBoard.id : null;
    const cardId = card.id;
    const { boardCollaborations, addBoardCollaboration, isLoading:boardCollaborationsLoading } = useBoardCollaborations(cardId);

    useEffect(()=> {
        setScheduled(_.some(boardCollaborations, ['type', 'schedule_viewing_request']));
    }, [boardCollaborations]);

    useEffect(()=> {
        setSubmiting(boardCollaborationsLoading);
    }, [boardCollaborationsLoading]);

    function submitForm() {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    }
    async function onSchedule(data) {
        setSubmiting(true);
        analytics.eventTrack('robin_schedule_private_tour_scheduled', {
            card_id: card.id,
            address: _.get(card, 'meta.address'),
            comment: data.message,
        });
        await addBoardCollaboration({
            model: 'BoardCard',
            boardId: activeBoardId,
            itemId: cardId,
            content: {
                requested: moment()
                    .toISOString(),
                message: data.message,
            },
            type: 'schedule_viewing_request',
        });
        setSubmiting(false);
    }
    return (
        <Dialog fullScreen fullWidth open={open}>
            <FormContext {...formMethods}>
                <DialogHeader
                    onClose={onClose}
                    submitForm={submitForm}
                    isSubmiting={isSubmiting}
                    isScheduled={isScheduled}
                />
                <SchedulePrivateTourContent
                    card={card}
                    agent={agent}
                    formRef={formRef}
                    onSchedule={onSchedule}
                    isSubmiting={isSubmiting}
                    isScheduled={isScheduled}
                />
                <Hidden smUp>
                    <DialogFooter
                        onClose={onClose}
                        submitForm={submitForm}
                        isSubmiting={isSubmiting}
                        isScheduled={isScheduled}
                    />
                </Hidden>
            </FormContext>
        </Dialog>
    );
});

export default SchedulePrivateTourModal;
