import React, { useEffect, useState } from 'react';
import { Dialog, Divider, Slide, Typography, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { AreaSearchInput } from './AreaSearchInput';
import { LRChip } from 'components/LRChip';
import LRButton from 'components/LRButton';
import { ChevronLeftRounded } from '@material-ui/icons';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import styled from 'styled-components';
import { useMyHome } from 'hooks/useMyHome';
import { AddHomeIcon } from 'components/LRIcons';
import Warning from 'components/LRIcons/warning/warning';
import HomeIcon from '@material-ui/icons/Home';

const StyledDialog = styled(({ color, ...other })=> (
    <Dialog classes={{ paperWidthSm: 'paperWidthSm-compare-now' }} {...other} />
))`
    & .paperWidthSm-compare-now {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        overflow: visible;
    }
`;

export const ManageModal = observer(function({open, closeModal, closeLabel= 'Home Report', saveAreas}) {
    const { myHome, areaIds } = useMyHome();
    const MAX_AREAS = 10;
    const theme = useTheme();
    const [localAreas, setLocalAreas] = useState([]);
    const { isMobile } = useDeviceBreakpoints();
    const hasChanges = !_.isEqual(localAreas, areaIds);
    const localAreasNum = _.size(localAreas) + (myHome?.zipcode ? 1 : 0);
    const isAreasEmpty = localAreasNum === 0;
    const maxAreasReached = localAreasNum >= MAX_AREAS;

    const deleteArea = (deleteArea)=> {
        const newAreas = _.filter(localAreas, (area)=> area !== deleteArea);
        setLocalAreas(newAreas);
    };

    const addArea = (newArea)=> {
        if(newArea === myHome?.zipcode) return;
        const newAreas = _.uniq([...(localAreas || []), newArea]);
        !maxAreasReached && setLocalAreas(newAreas);
    };

    useEffect(()=> {
        setLocalAreas(_.slice(areaIds, 0, localAreasNum > 10 ? 9 : 10));
    }, [open, areaIds]);

    const clearAndSaveButton = ()=> 
        <div>
            <LRButton
                color="inherit"
                variant="outlined"
                onClick={()=> setLocalAreas([])}
                style={{marginRight: theme.spacing(2), borderColor: theme.palette.lr_colors.steak_sauce}}
                disabled={isAreasEmpty}
            >
                Clear added areas
            </LRButton>
            <LRButton 
                color="primary" 
                variant="contained"
                style={{marginRight: theme.spacing(2)}}
                disabled={!hasChanges}
                onClick={()=> {
                    saveAreas(localAreas)
                        .then(()=> {
                            closeModal();
                        });
                }}
            >
                Save
            </LRButton>
        </div>;

    const showNumOfAreas = ()=> 
        <Typography
            variant='body2'
            style={{
                color: localAreasNum === 9
                    ? theme.palette.lr_colors.brick
                    : maxAreasReached
                        ? theme.palette.lr_colors.red
                        : theme.palette.lr_colors.steak_sauce
            }}
        >
            {`${localAreasNum} of ${MAX_AREAS}`}
        </Typography>; 

    return (
        <StyledDialog
            open={open}
            fullScreen
            maxWidth="100%"
            onClose={closeModal}
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'left',
            }}
        >
            <div style={{
                backgroundColor: theme.palette.lr_colors.grey_light,
                height: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'white'
                }}>
                    <LRButton
                        startIcon={<ChevronLeftRounded />}
                        onClick={closeModal}
                        style={{ paddingLeft: theme.spacing(3) }}
                    >
                        {!isMobile && closeLabel}
                    </LRButton>
                    <Typography
                        style={{margin: theme.spacing(2) + 2}}
                        variant="body2"
                    >
                        Manage Neighborhoods
                    </Typography>
                    {isMobile && <div style={{width: '64px'}}></div>}
                    {!isMobile && clearAndSaveButton()}
                </div>
                <div style={{
                    backgroundImage: `url('/images/manage-modal/manage-modal-image-${isMobile ? 'mobile' : 'web'}.png')`,
                    backgroundRepeat: 'round',
                    backgroundSize: '100% auto',
                    height: isMobile ? 168 : 248
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        ...(isMobile && {
                            background: 'linear-gradient(rgb(0 0 0 / 71%) 0%, rgba(26, 26, 26, 0) 100%)',
                            mixBlendMode: 'normal'
                        }),
                        ...(!isMobile && {
                            background: 'linear-gradient(rgb(0 0 0 / 30%) 0%, rgb(0 0 0 / 30%) 0%)',
                            mixBlendMode: 'normal'
                        }),
                    }}>
                        <Typography 
                            variant="h6"
                            style={{
                                margin: theme.spacing(2) + 2,
                                color: 'white',
                                textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)'
                            }}
                        >
                            Search for a neighborhood or city
                        </Typography>
                        <div style={{
                            marginBottom: theme.spacing(5),
                            width: '328px'
                        }}>
                            <AreaSearchInput
                                disabled={maxAreasReached}
                                addArea={addArea}
                            />
                        </div>
                    </div>
                </div>
                <div style={{            
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: isMobile ? 'flex-start' : 'center',
                    marginTop: isMobile ? 0 : 56
                }}>
                    <div style={{width: isMobile ? '100%' : 600}}>
                        <div style={{            
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            ...(isMobile && {
                                padding: theme.spacing(2),
                                backgroundColor: 'white'
                            }),
                            ...(!isMobile && {
                                paddingBottom: theme.spacing(2),
                                paddingTop: theme.spacing(2),
                            })
                        }}>
                            <Typography variant='h6'>
                                Your neighborhoods:
                            </Typography>
                            {!isMobile && showNumOfAreas()}
                        </div>
                        <Divider style={{width: '100%'}}/>
                        {isAreasEmpty && <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingBottom: theme.spacing(2)
                        }}>
                            <div style={{padding: isMobile ? theme.spacing(1) : theme.spacing(4) - 2, paddingTop: theme.spacing(isMobile ? 5 : 8) - 4}}>
                                <AddHomeIcon style={{ width: '32px', height: '32px'}}/>
                            </div>
                            <Typography variant='h6'
                                style={{
                                    maxWidth: isMobile ? 235 : '100%',
                                    textAlign: 'center',
                                    padding: theme.spacing(2)
                                }}
                            >
                                No neighborhoods to keep track of yet
                            </Typography>
                            <Typography variant='body1'
                                style={{
                                    maxWidth: 346,
                                    textAlign: 'center',
                                    padding: theme.spacing(2)
                                }}>
                                        Find them using the search bar above. You can add up to 10 areas.
                            </Typography>
                        </div>}
                        <div style={{ height: '100%' }}>
                            <div style={{paddingTop: theme.spacing(2), ...(isMobile && {overflow: 'scroll', maxHeight: 'calc(100vh - 360px)'})}}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                }}>
                                    {myHome?.zipcode && <div style={{padding: theme.spacing(1) + 2}}>
                                        <LRChip
                                            label={myHome?.zipcode}
                                            iconStyle={{display: 'none'}}
                                            avatar={<div style={{
                                                color: 'white',
                                                marginLeft: theme.spacing(1)
                                            }}>
                                                <HomeIcon/>
                                            </div>}
                                        />
                                    </div>}
                                    {_.map(localAreas || [], (area)=> 
                                        <div style={{padding: theme.spacing(1) + 2}}>
                                            <LRChip
                                                label={area}
                                                onDelete={()=> deleteArea(area)}
                                            />
                                        </div>
                                    )}
                                    {(maxAreasReached || localAreasNum === 9) &&
                                    <Typography variant='body1' style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: theme.spacing(2),
                                        width: '100%',
                                    }}>
                                        {(localAreasNum === 9 && 'You can add 1 more area.')
                                        || (maxAreasReached && 'To add a new area, remove a current one.')}
                                    </Typography>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {isMobile && <div style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '64px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'white'
                    }}> 
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: theme.spacing(1),
                        }}> 
                            <div style={{
                                padding: theme.spacing(1), 
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                {(localAreasNum === 9 || maxAreasReached)
                                    ? <Warning
                                        filledVersion={false}
                                        color={localAreasNum === 9
                                            ? theme.palette.lr_colors.brick
                                            : maxAreasReached
                                                ? theme.palette.lr_colors.red
                                                : theme.palette.lr_colors.steak_sauce
                                        }/>
                                    : <div style={{width: 25}}/>
                                }
                            </div>
                            {showNumOfAreas()} 
                        </div>           
                        {clearAndSaveButton()}
                    </div>}
                </div>
            </div>
        </StyledDialog>
    );
});