import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import NotificationSetting from './NotificationSetting';
import { observer } from 'mobx-react-lite';
import { Box, Divider, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useStore } from '../../../mobx-store';
import analytics from '../../../services/analytics';
import { MobileAppAd } from 'components/MobileAppAd';
import { useResponsiveBreakpoint } from 'hooks';
import { VerifyPhoneModal } from './VerifyPhone';

const NOTIFY_FREQUENCY_OPTIONS = [
    { id: 'asap', label: 'ASAP' },
    { id: 'daily', label: 'Daily' },
    { id: 'never', label: 'Never' },
];

const NEW_LISTINGS_FREQUENCY_OPTIONS = {
    email: NOTIFY_FREQUENCY_OPTIONS,
    text: NOTIFY_FREQUENCY_OPTIONS,
    push: NOTIFY_FREQUENCY_OPTIONS,
};

const NOTIFICATION_GROUP_LABELS = {
    robin_listing_off_market: 'off market listing emails',
    robin_hand_selected: 'hand selected listing emails from your agent',
    robin_notes: 'new note emails',
    robin_openhouses_viewings: 'home tour emails',
    robin_moved_card: 'board activity emails',
    robin_myhome: 'the Robin Home Report',
    marketing: 'marketing and promotional emails'
};

function Settings({ history }) {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const { UserStore, GlobalSnackbarStore, boardsStore } = useStore();
    const { notificationSettings, isPhoneVerified, hasMobileApp } = UserStore;
    const { activeBoard } = boardsStore;
    const {
        robin_listing_off_market={},
        robin_hand_selected={},
        robin_notes={},
        robin_openhouses_viewings={},
        robin_moved_card={},
        robin_myhome={},
        robin_account_info_and_activity={ email: true },
        marketing={}
    } = notificationSettings;

    const [ openVerifyModal, setOpenVerifyModal ] = useState(false);
    const [ autoUnsubCheckComplete, setAutoUnsubCheckComplete ] = useState(false);
    const [ currentSetting, setCurrentSetting ] = useState({key: null, value: null, channel: null, listpack: null});
    const disabled = !UserStore.isConsumer;
    const pushDisabled = !hasMobileApp || disabled;
    const savedSearches = activeBoard.unarchivedSavedSearches;
    const location = useLocation();
    const { unsubscribe_on_click = null } = queryString.parse(location.search);
    const autoUnsubSnackbarOptions = {
        successMessage: `You have been unsubscribed from ${NOTIFICATION_GROUP_LABELS[unsubscribe_on_click]}`,
        autoHideDuration: 6000,
    };

    const defaultSettings = {
        email: true,
        text: false,
        push: false,
    };

    const onChange = useCallback(function onChange(key, snackbarOptions) {
        const { successMessage = 'Changes saved', ...snackbarOpts } = snackbarOptions || {};
        return function(channel, value) {
            if(!isPhoneVerified && channel === 'text') {
                setOpenVerifyModal(true);
            } else {
                UserStore.setNotification(key, channel, value);
                if(Object.keys(snackbarOpts).length) {
                    GlobalSnackbarStore.show(successMessage, snackbarOpts);
                } else {
                    GlobalSnackbarStore.show(successMessage);
                }
                trackUserSettingsUpdate(key, channel, value);
            }
        };
    }, [UserStore, GlobalSnackbarStore, isPhoneVerified]);

    useEffect(()=> {
        if(!autoUnsubCheckComplete &&
            unsubscribe_on_click &&
            unsubscribe_on_click !== 'robin_listings' &&
            notificationSettings &&
            notificationSettings[unsubscribe_on_click]?.email
        ) {
            onChange(unsubscribe_on_click, autoUnsubSnackbarOptions)('email', false);
        }

        // make sure this check only runs ONCE after the user's settings have loaded
        if(unsubscribe_on_click &&
            notificationSettings &&
            notificationSettings[unsubscribe_on_click]) {
            setAutoUnsubCheckComplete(true);
        }
    }, [notificationSettings, unsubscribe_on_click, onChange, autoUnsubSnackbarOptions, autoUnsubCheckComplete]);

    function trackUserSettingsUpdate(key, channel, value) {
        const sourceMap = {
            'robin_listing_off_market': 'just_sold',
            'robin_hand_selected': 'hand_selected',
            'robin_notes': 'notes',
            'robin_openhouses_viewings': 'home_tours',
            'robin_moved_card': 'board_activity',
            'robin_home_base': 'home_report',
        };
        analytics.eventTrack('robin_notification_settings_toggle_flip', {
            Source: sourceMap[key] || key,
            Type: channel,
            State: value ? 'on' : 'off',
        });
    }

    function trackSavedSearchSettingsUpdate(listpack, channel, value) {
        analytics.eventTrack('robin_notification_center_new_listing_saved_search_updated', {
            Source: listpack?.name,
            Type: channel,
            Frequency: value,
        });
    }

    function onSavedSearchSettingsChange(key) {
        return function(listpack, channel, value) {
            if(!isPhoneVerified && channel === 'text') {
                setOpenVerifyModal(true);
            } else {
                listpack.setNotificationSetting(key, channel, value);
                GlobalSnackbarStore.show('Changes saved');
                trackSavedSearchSettingsUpdate(listpack, channel, value);
            }
        };
    }

    function disableIfNotVerified(defaultValue) {
        return isPhoneVerified === false ? false : defaultValue;
    }

    function disableIfNoMobileApp(defaultValue) {
        return hasMobileApp ? defaultValue: false;
    }

    function handleOnClose() {
        setOpenVerifyModal(false);
    }

    async function onVerificationSuccessful() {
        await UserStore.fetchLocalUser();
        handleOnClose();
    }

    const notificationsSettings = [
        {
            title: 'Off-market alerts',
            description: 'Receive notifications whenever homes you’ve added to your board go off the market (pending or sold).',
            onChangeNotification: onChange('robin_listing_off_market'),
            value: {
                email: _.defaultTo(robin_listing_off_market.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(robin_listing_off_market.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(robin_listing_off_market.push, defaultSettings.push)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
        },
        {
            title: 'Hand selected listings',
            description: 'Receive notifications whenever your agent sends you a hand-selected listing just for you.',
            onChangeNotification: onChange('robin_hand_selected'),
            value: {
                email: _.defaultTo(robin_hand_selected.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(robin_hand_selected.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(robin_hand_selected.push, true)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
        },
        {
            title: 'Notes',
            description: 'Receive notifications whenever new notes are added to listings by your co-buyers, agent, or loan officer.',
            onChangeNotification: onChange('robin_notes'),
            value: {
                email: _.defaultTo(robin_notes.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(robin_notes.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(robin_notes.push, true)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
        },
        {
            title: 'Home tours',
            description: 'Receive notifications whenever any home tours are requested, confirmed, or canceled.',
            onChangeNotification: onChange('robin_openhouses_viewings'),
            value: {
                email: _.defaultTo(robin_openhouses_viewings.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(robin_openhouses_viewings.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(robin_openhouses_viewings.push, true)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
        },
        {
            title: 'Board activity',
            description: 'Receive notifications whenever your co-buyers move or add homes to new columns.',
            onChangeNotification: onChange('robin_moved_card'),
            value: {
                email: _.defaultTo(robin_moved_card.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(robin_moved_card.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(robin_moved_card.push, defaultSettings.push)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
        },
        /*{
            //disabled on ticket CO-135
            title: 'Home Report',
            description: 'Receive notifications regarding the value of your home.',
            onChangeNotification: onChange('robin_myhome'),
            value: {
                email: _.defaultTo(robin_myhome.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(robin_myhome.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(robin_myhome.push, defaultSettings.push)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
            channelOptions: { email: true, text: false, push: false },
        },*/
        {
            title: 'Account info and activity',
            description: 'Receive notifications regarding your account, activity, notifications, reminders, and customer support requests.',
            onChangeNotification: onChange('robin_account_info_and_activity'),
            value: {
                email: _.defaultTo(robin_account_info_and_activity.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(robin_account_info_and_activity.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(robin_account_info_and_activity.push, defaultSettings.push)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
            channelOptions: { email: true, text: true, push: true },
        },
        {
            title: 'Promotional and marketing',
            description: 'Stay up to date on new products, announcements, and more from the Robin Team.',
            onChangeNotification: onChange('marketing'),
            value: {
                email: _.defaultTo(marketing.email, defaultSettings.email),
                text: disableIfNotVerified(_.defaultTo(marketing.text, defaultSettings.text)),
                push: disableIfNoMobileApp(_.defaultTo(marketing.push, defaultSettings.push)),
            },
            disabled: { email: disabled, text: disabled, push: pushDisabled },
            channelOptions: { email: true, text: true, push: false },
        },
    ];

    return (
        <Box maxWidth={650} mx="auto" mt={4} px={[2, 0]} width={1}>
            <VerifyPhoneModal
                open={openVerifyModal}
                onClose={handleOnClose}
                onVerificationSuccessful={onVerificationSuccessful}
            />
            <Box mx="auto" mb={1} textAlign="left" fontWeight="bold" fontSize="h6.fontSize">
                Notifications
            </Box>

            <Box mx="auto">
                {
                    savedSearches?.length > 0 && (
                        <>
                            <Box py={2} mb={1}>
                                <NotificationSetting
                                    title="New listings in saved searches"
                                    description="Receive notifications whenever there is a new listing in your saved searches."
                                    onChangeSavedSearchSettings={onSavedSearchSettingsChange('new_listings')}
                                    settingsKey="new_listings"
                                    savedSearches={savedSearches}
                                    channelOptions={NEW_LISTINGS_FREQUENCY_OPTIONS}
                                    disabled={{ email: disabled, text: disabled, push: pushDisabled }}
                                    isListpackNotification={true}
                                />
                            </Box>
                            <Divider />
                        </>
                    )
                }
                {notificationsSettings.map((notificationSettings)=> (
                    <React.Fragment key={notificationSettings && notificationSettings.title}>
                        <Box py={2}>
                            <NotificationSetting {...notificationSettings} />
                        </Box>
                        <Divider />
                    </React.Fragment>
                ))}
                <Box py={4}>
                    <Typography style={{ color: theme.palette.lr_colors.steak_sauce }}>
                        Text Messages. When the “text” toggle is selected, you agree to receive autodialed texts from
                        ListReports and its partners at the Mobile Number you have provided. Consent to receive marketing
                        texts is not a condition for using ListReport’s services. To cancel text notifications, toggle your
                        settings or reply “Stop” to the applicable text. Standard message and data rates apply.
                    </Typography>
                </Box>
                {/*!hasMobileApp && (
                    <MobileAppAd
                        source="notifications"
                        text="Want to receive push notifications on your phone? Check out the Robin™ app."
                        direction={isXs ? 'column' : 'row'}
                    />
                )*/}
            </Box>
        </Box>
    );
}

export default withRouter(observer(Settings));
