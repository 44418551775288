import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledSectionTitle = styled(Typography)`
    margin: 16px 0;
`;

function SectionTitle(props) {
    return (
        <StyledSectionTitle component="h1" variant="h5" {...props}>
            {props.children}
        </StyledSectionTitle>
    );
}

export default SectionTitle;
