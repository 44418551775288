import React, { useEffect, useState } from 'react';
import { COLOR_GREY_DARK } from '../../constants';
import { Box, Dialog, Hidden } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { RectShape } from 'react-placeholder/lib/placeholders';
import { useStore } from '../../mobx-store';
import Map from './Map';
import Sidebar from './Sidebar';
import { Navbar } from './Navbar';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import BRAND_ICONS from '../../services/brand-icons';
import { listingImage } from '../../services/listing-helpers';
import _ from 'lodash';
import { Home } from '@material-ui/icons';
import ListingImage from '../../components/ListingImage';
import analytics from '../../services/analytics';
import { useResponsiveBreakpoint } from '../../hooks';
import { InfographicsCategoryIcon } from 'components/InfographicsCategoryIcon';

const StyledLogoImage = withTheme(styled.img`
    height: 40px;
    filter: grayscale(100%);

    ${({ theme })=> theme.breakpoints.down('xs')} {
        max-height: 32px;
    }
`);

const AreaIcon = withTheme(styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: #fff;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    cursor: pointer;

    & .area-icon-svg {
        font-size: 2.5rem;
    }

    ${({ theme, active })=> (active === 'true' ? `border-color: ${theme.palette.primary.main}` : '')};
    }

    &:hover {
        ${({ theme })=> `border-color: ${theme.palette.primary.main}`}
    }

    ${({ theme })=> theme.breakpoints.down('xs')} {
        width: 72px;
        height: 72px;
        padding: 4px;

        & .area-icon-svg {
            font-size: 2rem;
        }
    }
`);

const AreaIconLoading = withTheme(styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: #fff;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    cursor: pointer;

    & .area-icon-svg {
        fill: ${COLOR_GREY_DARK};
        font-size: 2.5rem;
    }

    ${({ theme })=> theme.breakpoints.down('xs')} {
        width: 72px;
        height: 72px;
        padding: 4px;

        & .area-icon-svg {
            font-size: 2rem;
        }
    }
`);

const MedalHeroImage = withTheme(styled.div`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;

    ${({ theme })=> theme.breakpoints.down('xs')} {
        width: 42px;
        height: 42px;
    }
`);

const MapContainer = withTheme(styled(Box)`
    flex: 1;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    height: 100%;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        height: 350px;
    }

    ${({ theme })=> theme.breakpoints.down('xs')} {
        height: 300px;
    }

    @media screen and (max-height: 700px) {
        height: 185px;
    }
`);


const SidebarContainer = withTheme(styled(Box)`
    height: 100%;

    ${({ theme })=> theme.breakpoints.down('sm')} {
        height: calc(100% - 350px);
    }

    ${({ theme })=> theme.breakpoints.down('xs')} {
        height: calc(100% - 300px);
    }

    @media screen and (max-height: 700px) {
        height: calc(100% - 185px);
    }
`);

const styles = {
    loadingMiles: {
        marginTop: '2px',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '18px',
        backgroundColor: 'rgb(205, 205, 205)',
        width: '58px',
    },
    loadingIcon: {
        width: '1.5rem',
        height: '1.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '16%',
        backgroundColor: 'rgb(205, 205, 205)',
    }

};

export const InfographicsMap = observer(function InfographicsMap({ listingDetails, requestSource, reportId }) {
    const { InfographicsMapStore, ListpackStore, UserStore } = useStore();
    const [heroImage, setHeroImage] = useState(null);
    const isXs = useResponsiveBreakpoint('xs');
    const [railCategories, setRailCategories] = useState([]);
    const placesContainerRef = React.createRef();
    const user = UserStore.user;

    const { agent, listpack } = ListpackStore;
    const categoriesLocalization = {
        cleaners: 'Dry cleaners',
        groceries: 'Grocery store',
        atm: 'ATM',
        pharmacy: 'Pharmacy',
        coffee: 'Coffee',
        gas: 'Gas',
        gym: 'Gym',
        schools: 'Schools',
        hospital: 'Medical',
        movie: 'Theater',
    };

    useEffect(()=> {
        return function() {
            InfographicsMapStore.setSelectedCategory(null);
        };
    }, []);

    useEffect(()=> {
        const heroImg = listingImage({ listing_details: listingDetails });
        setHeroImage(heroImg);
    }, [listingDetails]);

    useEffect(()=> {
        async function fetchData() {
            const data = await InfographicsMapStore.fetchData(listingDetails);

            let closestByCategory = [];

            _.forOwn(data, (value, key)=> {
                if(value.length) {
                    closestByCategory.push({
                        key,
                        value: value[0],
                    });
                } else {
                    closestByCategory.push({
                        key,
                        value: null,
                    });
                }
            });

            closestByCategory = _.sortBy(closestByCategory, (c)=> {
                if(c.value && c.value.distance) {
                    return c.value.distance;
                }
            });
            setRailCategories(closestByCategory); 
        }

        if(InfographicsMapStore.map && listingDetails) {
            fetchData();
        }
        
    }, [InfographicsMapStore, InfographicsMapStore.maps, listingDetails]);

    useEffect(()=> {
        let response;
        const sortedCategories = railCategories.map((item)=> {
            return item.key;
        });

        const currentIndex = sortedCategories.indexOf(InfographicsMapStore.selectedCategory);
        const firstIndex = 0;
        const lastIndex = sortedCategories.length - 1;

        switch(currentIndex) {
            case -1:
                response = {
                    previous: null,
                    next: 0,
                };
                break;
            case firstIndex:
                response = {
                    previous: null,
                    next: 1,
                };
                break;
            case lastIndex:
                response = {
                    previous: currentIndex - 1,
                    next: currentIndex,
                    final: true,
                };
                break;
            default:
                response = {
                    previous: currentIndex - 1,
                    next: currentIndex + 1,
                    final: currentIndex === lastIndex - 1,
                };
                break;
        }
    }, [InfographicsMapStore, InfographicsMapStore.selectedCategory, railCategories]);

    function getArea(category, closestPlace) {
        let isActive = false;
        if(InfographicsMapStore.selectedCategory) {
            isActive = category === InfographicsMapStore.selectedCategory;
        }

        if(category && closestPlace) {
            const name = closestPlace.name;
            const categoryLookupObj = BRAND_ICONS[category];

            if(categoryLookupObj) {
                const logo = categoryLookupObj[name];
                if(logo) {
                    return (
                        <AreaIcon
                            border="8px solid #F0F0F0"
                            onClick={()=> handleCategoryClick(category)}
                            active={isActive ? 'true' : 'false'}
                        >
                            <StyledLogoImage src={`https://s3-us-west-2.amazonaws.com/listreports-templates${logo}`} />
                        </AreaIcon>
                    );
                }
            }
        }

        return (
            <AreaIcon
                border="8px solid #F0F0F0"
                onClick={()=> handleCategoryClick(category)}
                active={isActive ? 'true' : 'false'}
            >
                <InfographicsCategoryIcon
                    category={category}
                    classes={{ root: !isXs ? 'area-icon-svg' : '' }}
                />
            </AreaIcon>
        );
    }

    function getAreaLoading(category) {
        return (
            <AreaIconLoading
                border="8px solid #F0F0F0"
            >
                {!isXs
                    ? <InfographicsCategoryIcon
                        category={category}
                        classes={{ root: !isXs ? 'area-icon-svg' : '' }}
                    />
                    : <Box className="show-loading-animation"><RectShape style={styles.loadingIcon} /></Box>}
            </AreaIconLoading>
        );
    }

    function handleCategoryClick(category) {
        analytics.eventTrack('listpacks_consumer_listingpage_infographic_category_detail_click', {
            address: listingDetails.address,
            'listing id': listingDetails.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            Category: category,
            source: requestSource,
            report_id: reportId,
        });
        InfographicsMapStore.localizatedCategory = categoriesLocalization[category];
        InfographicsMapStore.setSelectedCategory(category);
    }

    function handleHomeClick() {
        InfographicsMapStore.localizatedCategory = 'This Home';
        InfographicsMapStore.setSelectedCategory(null);
    }

    useEffect(()=> {
        if(placesContainerRef.current) {
            placesContainerRef.current.scrollTo({ top: 0 });
        }
    }, [InfographicsMapStore.selectedCategory, placesContainerRef]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            height={1}
        >
            <Box
                flex="1"
                display="flex"
                width="100%"
                flexDirection={{ xs: 'column', md: 'row' }}
                overflow="hidden"
            >
                <MapContainer>
                    <Map listingDetails={listingDetails} />
                </MapContainer>

                <SidebarContainer>
                    <Box height={1} width={{ xs: '100%', md: '360px' }} overflow="auto" ref={placesContainerRef}>
                        <Box width={1} bgcolor="#FFF">
                            <Sidebar listingDetails={listingDetails} />
                        </Box>
                    </Box>
                </SidebarContainer>
            </Box>

            <Box
                width={1}
                height={[120, 200]}
                bgcolor="white"
                display="flex"
                zIndex={1}
                boxShadow="0px 1px 10px 5px rgba(0,0,0,0.21)"
            >
                {!isXs && (
                    <Box
                        width={174}
                        display="flex"
                        zIndex={1}
                        overflow="auto"
                        alignItems="center"
                        justifyContent="center"
                        boxShadow="3px 1px 15px 5px rgba(0,0,0,0.21)"
                    >
                        <Box
                            mt={2}
                            width={140}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="start"
                        >
                            <Box width={1} display="flex" alignItems="center" justifyContent="center">
                                <Box>
                                    {/* border="8px solid #F0F0F0" */}
                                    <AreaIcon
                                        border={8}
                                        borderColor={!InfographicsMapStore.selectedCategory ? '#000' : '#F0F0F0'}
                                        onClick={handleHomeClick}
                                    >
                                        {heroImage && (
                                            <MedalHeroImage>
                                                <ListingImage image={heroImage} height="100%" />
                                            </MedalHeroImage>
                                        )}
                                        {!heroImage && <Home classes={{ root: 'area-icon-svg' }} />}
                                    </AreaIcon>
                                </Box>
                            </Box>
                            <Box fontSize="subtitle2.fontSize" color={`${COLOR_GREY_DARK}`}>
                                0 miles
                            </Box>
                            <Box fontSize="body1.fontSize"></Box>
                        </Box>
                    </Box>
                )}

                <Box width={1} display="flex" overflow="auto" alignItems="center" mt={2} mr={[2, 0]}>
                    {isXs && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="start"
                            flex="1"
                            minWidth="80x"
                            flexShrink={0}
                            pl={2}
                            mb={2}
                        >
                            <Box width={1} display="flex" alignItems="center" justifyContent="center">
                                <AreaIcon
                                    border={8}
                                    borderColor="#000 !important"
                                    onClick={handleHomeClick}
                                    bgcolor="#000 !important"
                                >
                                    <Box
                                        color="#FFF"
                                        height={48}
                                        width={48}
                                        bgcolor="#000"
                                        borderRadius="50%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Home />
                                    </Box>
                                </AreaIcon>
                            </Box>
                        </Box>
                    )}

                    {InfographicsMapStore.isLoading &&
                        Object.keys(categoriesLocalization).map((cat, index)=> (
                            <Box
                                key={cat.key}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="start"
                                flex="1"
                                minWidth={['96px', '140px']}
                                mb={[2, categoriesLocalization[cat] ? 0 : '20px']}
                                flexShrink={0}
                            >
                                <Box width={1} display="flex" alignItems="center" justifyContent="center">
                                    <Box height={8} flex={2} bgcolor="#F0F0F0" />
                                    {getAreaLoading(cat)}
                                    <Hidden xsDown>
                                        <Box
                                            height={8}
                                            flex={2}
                                            bgcolor={'#F0F0F0'}
                                        />
                                    </Hidden>
                                </Box>
                                <Hidden xsDown>
                                    <Box fontSize="subtitle2.fontSize" color={`${COLOR_GREY_DARK}`}>
                                        <Box className="show-loading-animation">
                                            <RectShape style={styles.loadingMiles} />
                                        </Box> 
                                    </Box>
                                    <Box
                                        width={1}
                                        fontSize="body1.fontSize"
                                        textAlign="center"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                        color={`${COLOR_GREY_DARK}`}
                                    >
                                        {categoriesLocalization[cat]}
                                    </Box>
                                </Hidden>
                            </Box>
                        ))}

                    {railCategories.map((cat, index)=> (
                        <Box
                            key={cat.key}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="start"
                            flex="1"
                            minWidth={['96px', '140px']}
                            mb={[2, cat.value ? 0 : '20px']}
                            flexShrink={0}
                        >
                            <Box width={1} display="flex" alignItems="center" justifyContent="center">
                                <Box height={8} flex={2} bgcolor="#F0F0F0" />
                                {getArea(cat.key, cat.value)}
                                <Hidden xsDown>
                                    <Box
                                        height={8}
                                        flex={2}
                                        bgcolor={railCategories.length - 1 === index ? '#FFF' : '#F0F0F0'}
                                    />
                                </Hidden>
                            </Box>
                            <Hidden xsDown>
                                <Box fontSize="subtitle2.fontSize" color={`${COLOR_GREY_DARK}`}>
                                    {cat.value && <Box>{cat.value.distance} miles</Box>}
                                </Box>
                                <Box
                                    width={1}
                                    fontSize="body1.fontSize"
                                    textAlign="center"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                >
                                    {categoriesLocalization[cat.key]}
                                </Box>
                            </Hidden>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
});

const InfographicsMapModal = observer(function InfographicsMapModal({ listingDetails, onModalClose, requestSource }) {
    const { InfographicsMapStore } = useStore();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');

    return (
        <Dialog fullScreen open={_}>
            <Box
                display="flex"
                flexDirection="column"
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                bgcolor="#FFF"
            >
                <Box zIndex="1" boxShadow={isSm || isXs ? '' : '0px 3px 12px -2px rgba(0, 0, 0, 0.2)'}>
                    <Navbar onModalClose={onModalClose} selectedCategory={InfographicsMapStore.localizatedCategory}></Navbar>
                </Box>
                <Box flex={1} overflow="hidden">
                    <InfographicsMap listingDetails={listingDetails} requestSource={requestSource} />
                </Box>
            </Box>
        </Dialog>
    );
});

export default InfographicsMapModal;
