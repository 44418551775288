import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from '../../mobx-store';
import { Box, TextField, Grid } from '@material-ui/core';
import { Route, Switch, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTheme } from '@material-ui/core/styles';
import { AppleBtn, FacebookBtn, GoogleBtn } from '../../components/LRSocialLoginButtons';
import { useFormField } from '../../hooks/use-form-field';
import LRButton from '../../components/LRButton';
import { benoitApi } from '../../apis';
import { AutoInfo } from '../../components/AuthInfo';
import { OnboardingVideoModal } from '../../components/OnboardingVideoModal';
import { Verification } from './Verification';
import analytics from '../../services/analytics';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useQuery } from '../../hooks';
import Logger from 'js-logger';
import { AUTH_ONBOARDING_VIDEO } from '../../constants/routes';
import { AgentLoginModal } from '../../components/AgentLoginModal';
import { MobileAppAd } from '../../components/MobileAppAd';
import { ContactSupport } from '../../components/ContactSupport';
import LRLink from '../../components/LRLink';
import { LREmailPhoneField } from '../../components/LREmailPhoneField';
import { useMyHome } from 'hooks/useMyHome';
import { SIGNUP_LOGIN_VARIANT_PAGE, SIGNUP_LOGIN_VARIANT_WIDGET } from '../../constants';

const StyledButton = withTheme(styled(LRButton)`
    &.Mui-disabled {
        background-color: ${({ theme })=> theme.palette.lr_colors.grey_light};
        color: ${({ theme })=> theme.palette.lr_colors.grey_dark};
    }
`);

function WarningMessage({children}) {
    return (
        <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  style={{ verticalAlign: 'bottom' }}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.98024 13.6694H13.0202C14.0469 13.6694 14.6869 12.5561 14.1736 11.6694L9.15358 2.99605C8.64024 2.10939 7.36024 2.10939 6.84691 2.99605L1.82691 11.6694C1.31358 12.5561 1.95358 13.6694 2.98024 13.6694ZM8.00024 9.00272C7.63358 9.00272 7.33358 8.70272 7.33358 8.33605V7.00272C7.33358 6.63605 7.63358 6.33605 8.00024 6.33605C8.36691 6.33605 8.66691 6.63605 8.66691 7.00272V8.33605C8.66691 8.70272 8.36691 9.00272 8.00024 9.00272ZM7.33358 11.6694H8.66691V10.3361H7.33358V11.6694Z" fill="#FF6259"/>
            </svg>
            {children}
        </span>
    );
}

export function LoginHeader({ showSignupInLogin = true }) {

    return (
        <>
            <Box mt={5} fontSize={32} fontWeight={500} textAlign="center">
                Sign In
            </Box>
            {showSignupInLogin && (
                <Box mt={5 / 4} textAlign="center">
                    <Box>Robin is currently available by invite only.</Box>
                    {/* <Box>
                        Don't have an account? <LRLink to={'/signup'}>Sign up.</LRLink>
                    </Box> */}
                </Box>
            )}
        </>
    );
}

const LoginMenu = function({
    variant = SIGNUP_LOGIN_VARIANT_PAGE, // page or widget
    onLoginRequest,
    onLoginSuccess = ()=> {},
}) {
    const history = useHistory();
    const { RootStore, UserStore, boardsStore } = useStore();
    const query = useQuery();
    const showDevLogin = query.has('dev-mode') && !RootStore.inProductionMode;
    const theme = useTheme();
    const rfhMethods = useForm({ mode: 'onChange' });
    const { handleSubmit, errors, formState, setError } = rfhMethods;

    // old login, to deprecate soon
    const [userIdInputProps] = useFormField('N-VJAl8JaeZ');

    useEffect(()=> {
        const devModeUserId = query.get('dev-mode');
        if(showDevLogin && devModeUserId) {
            userIdInputProps.value = devModeUserId;
            handleLoginClick();
        }
    }, []);

    const [nonExistentPhone, setNonExistentPhone] = useState(false);
    const [deactivatedUser, setDeactivatedUser] = useState(false);
    const [nonExistentFacebookAuth, setNonExistentFacebookAuth] = useState(false);
    const [nonExistentGoogleAuth, setNonExistentGoogleAuth] = useState(false);
    const [nonExistentAppleAuth, setNonExistentAppleAuth] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [fbLoading, setFbLoading] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [appleLoading, setAppleLoading] = useState(false);
    const [openedAgentLoginModal, setOpenedAgentLoginModal] = useState(false);

    async function handleLoginSuccess({ userInfo, auth }) {
        // With the new refactor this is probably to the best way to indicate loading. Leaving it in for now to avoid more changes.
        if(auth.method === 'google') {
            setGoogleLoading(true);
        } else if(auth.method === 'facebook') {
            setFbLoading(true);
        } else {
            setAppleLoading(true);
        }

        const loginPayload = {
            auth,
            user: {
                email: userInfo.email,
            }
        };

        try {
            const loggedInUser = await UserStore.login(loginPayload);

            if(auth.method === 'google') {
                setGoogleLoading(false);
            } else if(auth.method === 'facebook') {
                setFbLoading(false);
            } else {
                setAppleLoading(false);
            }

            if(loggedInUser) {
                registerLoginEvent(loggedInUser, auth.method);
                if(variant === SIGNUP_LOGIN_VARIANT_PAGE) history.push('/boards');
                else onLoginSuccess();
            } else {

                if(auth.method === 'google') {
                    setNonExistentGoogleAuth(true);
                } else if(auth.method === 'facebook') {
                    setNonExistentFacebookAuth(true);
                } else {
                    setNonExistentAppleAuth(true);
                }

            }
        } catch (err) {
            Logger.error('Login -> handleLoginSuccess -> loginPayload', err);
        }
    }

    async function handleLoginClick() {
        await UserStore.deprecatedLogin(userIdInputProps.value);
        await boardsStore.fetchUserBoards();
    }

    async function submitForm(data) {
        setSubmitting(true);
        const { input, input_type } = data;
        const inputTypeToUserInfo = {
            email: 'email',
            tel: 'phone',
        };
        const type = inputTypeToUserInfo[input_type];
        try {
            const token = await benoitApi.requestLogin(type, input, { forRobin: true });
            onLoginRequest({ [type]: input }, token);
        } catch (err) {
            switch(err.response.data.errors[0].status){
                case '404':
                    setNonExistentPhone(true);
                    const messageMap = { email: 'email', phone: 'phone number'};
                    setError(
                        'input',
                        'manual',
                        <WarningMessage>This {messageMap[type]} isn’t associated with a Robin account.</WarningMessage>
                    );
                    break;
                case '401':
                    switch(err.response.data.errors[0].detail) {
                        case 'invited_robin':
                            setDeactivatedUser(true);
                            setError(
                                'input',
                                'manual',
                                <WarningMessage>Check your inbox for an invite email - you’ll need to sign up first!</WarningMessage>
                            );
                            break;
                        case 'deactivated_robin':
                            setDeactivatedUser(true);
                            setError(
                                'input',
                                'manual',
                                <WarningMessage>Access denied.</WarningMessage>
                            );
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    throw err;
            }
            
            setSubmitting(false);
        }
    }

    function registerLoginEvent(user, method) {
        analytics.eventTrack(`robin_${method}_login_click`, {
            consumer_name: `${user.first_name} ${user.last_name}`,
            consumer_email: user.email,
            consumer_phone: user.phone,
            Source: method,
        });
    }

    function cleanErrors() {
        if(!nonExistentFacebookAuth && !nonExistentGoogleAuth && !nonExistentAppleAuth && !nonExistentPhone && !deactivatedUser) {
            return;
        }
        setNonExistentFacebookAuth(false);
        setNonExistentGoogleAuth(false);
        setNonExistentAppleAuth(false);
        setNonExistentPhone(false);
        setDeactivatedUser(false);
    }

    return (
        <>
            <LoginHeader />
            <Box width="100%" px={2} pt={5 / 2}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <LREmailPhoneField
                        rfhMethods={rfhMethods}
                        required
                        name="input"
                        placeholder="Enter email or mobile phone number"
                        message={'Please ensure you entered a 10 digit mobile number or a valid email address.'} />
                    <Box mt="19px">
                        <LRButton
                            fullWidth
                            variant="contained"
                            style={{
                                height: '48px'
                            }}
                            color="primary"
                            type="submit"
                        >
                            Continue
                        </LRButton>
                    </Box>
                </form>
            </Box>
            <Box mt={2} align="center">or</Box>
            <Box width={1} px={2}>
                {/* facebook button commented while app is in developer mode */}
                {/* <Box onClick={cleanErrors} mt={2}>
                    <FacebookBtn onLoginSuccess={handleLoginSuccess} loading={fbLoading} />
                    {nonExistentFacebookAuth && (
                        <Box color={theme.palette.error.main} fontSize={12}>
                            This Facebook account is not registered.
                        </Box>
                    )}
                </Box> */}

                <Box onClick={cleanErrors} mt={2}>
                    <GoogleBtn onLoginSuccess={handleLoginSuccess} loading={googleLoading} />
                    {nonExistentGoogleAuth && (
                        <Box color={theme.palette.error.main} fontSize={12}>
                            This Google account is not registered.
                        </Box>
                    )}
                </Box>

                {/* Hide Apple social login until it's working */}
                <Box onClick={cleanErrors} mt={2}>
                    <AppleBtn onLoginSuccess={handleLoginSuccess} loading={appleLoading} />
                    {nonExistentAppleAuth && (
                        <Box color={theme.palette.error.main} fontSize={12}>
                            This apple account is not registered.
                        </Box>
                    )}
                </Box>
            </Box>

            <Box
                width="calc(100% - 32px)"
                mt={3}
                mx="auto"
                borderBottom={`1px solid ${theme.palette.lr_colors.border_grey}`}
            />

            <Box width="calc(100% - 32px)" mx={2} mt={3}>
                <LRButton
                    fullWidth
                    variant="outlined"
                    style={{
                        height: '48px',
                        fontWeight: 'normal',
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={()=> {
                        analytics.eventTrack('robin_login_im_an_agent_button_click');
                        setOpenedAgentLoginModal(true);
                    }}
                >
                    I’m an agent
                </LRButton>
            </Box>
            <Box width={variant === SIGNUP_LOGIN_VARIANT_PAGE ? 'calc(100% - 32px)' : '100%'}>
                {/* <Box height={24} />
                <MobileAppAd source='login' /> */}
                <Box mt={3}>
                    <ContactSupport source='login' />
                </Box>
            </Box>

            <AgentLoginModal
                open={openedAgentLoginModal}
                onClose={()=> setOpenedAgentLoginModal(false)}
            />
            {showDevLogin && <Box mt={5} align="center">
                DevMode login, only available in testing environments
                <Grid container direction="row" justify="center" alignItems="center">
                    <Box align="center">
                        <TextField label="User Id" {...userIdInputProps} />
                        <br />
                        <br />
                        <LRButton variant="contained" color="primary" onClick={handleLoginClick}>
                            Log Me In
                        </LRButton>
                    </Box>
                </Grid>
            </Box>}
        </>
    );
};

export const Login = observer(function({
    variant = SIGNUP_LOGIN_VARIANT_PAGE, // page or widget
    onLoginSuccess = ()=> {},
}) {
    const { UserStore, boardsStore } = useStore();
    const match = useRouteMatch();
    const { user } = UserStore;
    const { fetchingUserBoards, activeBoard } = boardsStore;
    const history = useHistory();
    const [loginState, setLoginState] = useState('login');
    const [token, setToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [loginMethod, setLoginMethod] = useState(null);
    const location = useLocation();
    const { isLoading:isLoadingMyHome, myHome } = useMyHome(activeBoard?.id);
    const { from } = useMemo(()=> {
        if(location.state) return location.state;
        return myHome ? { from: { pathname: '/boards/home-report' } } : { from: { pathname: '/boards' } };
    }, [location.state, myHome]);
    const titleMessageByMethod = {
        phone: 'Verify your mobile phone number',
        email: 'Verify your email address',
    };
    const subtitleMessageByMethod = {
        phone: `We just sent you a 4-digit verification code via text message to ${userInfo?.phone}. Please enter it below.`,
        email: `We just sent you a 4-digit verification code via email to ${userInfo?.email}. Please enter it below.`
    };
    const variantProps = {
        [SIGNUP_LOGIN_VARIANT_PAGE]: {
            height: {xs: 'calc(100% - 211px)', md:'100%'},
            width: ['100%', '100%', '25%'],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [SIGNUP_LOGIN_VARIANT_WIDGET]: {
            width: '100%',
        }
    };

    function onLoginRequest(user, token) {
        setLoginMethod(user.email ? 'email' : 'phone');
        setUserInfo(user);
        setToken(token);
        setLoginState('verification');
    }

    async function onVerificationSuccessful(user) {
        UserStore.storeToken(user.token);
        await UserStore.fetchLocalUser();
        await UserStore.fetchUserData();
        onLoginSuccess();
    }

    if(user && UserStore.isVerified  && !fetchingUserBoards && !isLoadingMyHome && variant === SIGNUP_LOGIN_VARIANT_PAGE) {
        return history.replace(from);
    }

    return (
        <>
            <Box height={variant === SIGNUP_LOGIN_VARIANT_PAGE && '100vh'} display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                {variant === SIGNUP_LOGIN_VARIANT_PAGE && <AutoInfo trackSource="login" />}

                <Box
                    {...variantProps[variant]}
                >
                    {loginState === 'login' && <LoginMenu variant={variant} onLoginRequest={onLoginRequest} onLoginSuccess={onLoginSuccess} />}
                    {loginState === 'verification' && (
                        <Verification
                            setLoginState={setLoginState}
                            userInfo={userInfo}
                            token={token}
                            onVerificationSuccessful={onVerificationSuccessful}
                            headerText="Almost done!"
                            subTitleText={subtitleMessageByMethod[loginMethod]}
                            mode="login"
                            method={loginMethod}
                        />
                    )}
                </Box>
            </Box>
            <Switch>
                <Route
                    path={`${match.url}/${AUTH_ONBOARDING_VIDEO}`}
                    render={()=> (
                        <OnboardingVideoModal onClose={()=> history.push(match.url)} />
                    )}
                />
            </Switch>
        </>
    );
});
