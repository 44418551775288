import React from 'react';
import { useResponsiveBreakpoint } from '../../../hooks';
import LRModal from '../../LRModal';

export const ImageLightbox = ({
    open,
    imageUrl,
    onClose = ()=> {},
})=> {
    const isXs = useResponsiveBreakpoint('xs');
    if(!imageUrl) return null;
    return (
        <LRModal
            fullWidth={true}
            open={open}
            onClose={onClose}
            maxWidth="lg"
            paperProps={{
                elevation: 0,
                style: {
                    backgroundColor: 'transparent',
                }
            }}
            closeButtonStyles={{
                top: 0,
                right: 0,
            }}
        >
            <div style={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img src={imageUrl} style={{maxWidth: '100%'}} alt="Enlarged talking points"/>
            </div>
        </LRModal>
    );
};
