import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { TextField } from 'components/TextField';
import { Room, Search } from '@material-ui/icons';
import { LRChip } from 'components/LRChip';
import { LRAreaAutocomplete } from 'components/LRAreaAutocomplete';

const StyledTextField = withTheme(styled(TextField)`
    background: #fff;

    input {
        font-size: 16px;
    }

    & .MuiOutlinedInput-notchedOutline {
        border-radius: 2px;
        border-color: #dddddd;
    }
    & ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 16px;
    }
    & ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 16px;
    }

    & .MuiOutlinedInput-input {
        padding: 14px;
    }
`);

export function AreaSection({ onChange }) {
    const areaAutocompleteRef = useRef();
    const formMethods = useFormContext();
    const { register, control } = formMethods;
    const [isInvalid, setIsInvalid] = useState(true);
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'areaIds',
    });

    function handleAreaSelect(area) {
        append({ value: area.id });
        areaAutocompleteRef.current.clearSearch();
        onChange(false);
    }

    function handleRemoveArea(index) {
        remove(index);
        onChange(false);
    }

    useEffect(()=> {
        if(fields.length === 0) {
            formMethods.register('areaIds', { validate: (value)=> value && value.length > 0});
            formMethods.setValue('areaIds', []);
            setIsInvalid(true);
        } else {
            formMethods.unregister('areaIds');
            setIsInvalid(false);
        }
    }, [
        fields,
        // formMethods.register,
        // formMethods.unregister,
        // formMethods.setValue,
        // formMethods,
    ]);

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
            case 'zip':
                iconElement = <BusinessIcon fontSize="small" />;
                break;
            case 'neighborhood':
                iconElement = <HomeIcon fontSize="small" />;
                break;
            case 'school':
                iconElement = <SchoolIcon fontSize="small" />;
                break;
            default:
                iconElement = <Room fontSize="small" />;
                break;
        }
        return iconElement;
    }

    function filterSuggestions(suggestion) {
        return !fields.find((area)=> {
            return area.value === suggestion.id;
        });
    }

    return (
        <Box mb={3}>
            <Grid container justify="space-between" spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Box>
                        <LRAreaAutocomplete
                            ref={areaAutocompleteRef}
                            onSuggestionSelect={handleAreaSelect}
                            renderInput={(props)=> {
                                return (
                                    <StyledTextField
                                        variant="outlined"
                                        color="primary"
                                        placeholder="Search By City, Zip, Neighborhood or School"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Box color="#DADADA" display="flex">
                                                        <Search fontSize="small" />
                                                    </Box>
                                                </InputAdornment>
                                            ),
                                        }}
                                        size="small"
                                        fullWidth
                                        error={formMethods.errors['areaIds']}
                                        {...props}
                                    />
                                );
                            }}
                            suggestionRenderOption={(suggestion)=> {
                                return (
                                    <Box display="flex" style={{ cursor: 'pointer' }}>
                                        <div>{renderSuggestionIcon(suggestion.type)}</div>
                                        <Box ml={1}>{suggestion.id}</Box>
                                    </Box>
                                );
                            }}
                            errorTooltipContent={'Sorry, it looks like we can’t find this area.'}
                            rootContainerProps={{ className: 'tour-target-listpack-area' }}
                            filterSuggestions={filterSuggestions}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} container spacing={1}>
                    {fields.map((area, index)=> (
                        <Grid item key={area.id}>
                            <LRChip label={area.value} onDelete={()=> handleRemoveArea(index)} />
                            <input
                                type="hidden"
                                defaultValue={area.value}
                                name={`areaIds[${index}]`}
                                ref={register()}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}
