import React, { useState, useEffect, useCallback } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams, useHistory, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { COLOR_GREY_LIGHT, COLOR_GREY, COLOR_SALMON } from '../../../constants';
import LRButton from '../../LRButton';
import { useResponsiveBreakpoint } from '../../../hooks';
import { Listing } from 'components/Listing';
import ListpackListingHeader from './ListpackListingHeader';
import analytics from '../../../services/analytics';
import Logger from 'js-logger';

const logger = Logger.get('ListpackListing');
const navigationButtonStyle = {
    border: `1px solid ${COLOR_GREY}`,
    width: '104px',
    height: '40px',
};

const navigationButtonDisabledStyle = {
    color: COLOR_GREY,
    cursor: 'not-allowed',
};

function ListpackListing({ onGoBack, onGoToEnd }) {
    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isMobile = isXs;

    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const listingIdParam = params.listingId;
    const [listingId, setListingId] = useState(listingIdParam);
    const { index: queryIndex, source } = queryString.parse(location.search);
    const [index, setIndex] = useState(queryIndex);
    const [redirectEnd, setRedirectEnd] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [redirectNotFound, setRedirectNotFound] = useState(false);
    const [listing_details, setListing_details] = useState(null);
    const [requestSource] = useState(source ? source : 'consumer_view');
    const { ListpackStore } = useStore();

    const {
        listpack,
        loadedListpack,
        agent,
        listingsCount,
        loadedListingById,
        loadingMoreListings,
        loadedListings,
        loadedListingsCount,
        error,
    } = ListpackStore;

    const redirectToIndex = useCallback(
        async function(newIndex) {
            setRedirecting(true);
            let listing = await ListpackStore.getListingByIndex(Number(newIndex));
            if(listing) {
                history.push({
                    pathname: `/listpack/${ListpackStore.listpackEncodedPayload}/listings/${listing.id}`,
                    search: location.search,
                });
                setListingId(listing.id);
            } else {
                setRedirectNotFound(true);
            }
            setRedirecting(false);
        },
        [ListpackStore, history, source]
    );

    useEffect(()=> {
        if(!queryIndex || redirecting || listingId) {
            return;
        }

        if(loadingMoreListings || !loadedListingsCount || !loadedListings) {
            return;
        }

        redirectToIndex(queryIndex);
    }, [listingId, loadedListings, loadedListingsCount, loadingMoreListings, queryIndex, redirectToIndex, redirecting]);

    useEffect(()=> {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(()=> {
        if(!listingId || !loadedListpack  || !loadedListings || !loadedListingsCount) {
            return;
        }

        async function getListing() {
            let response = await ListpackStore.getListingById(listingId);
            if(response) {
                const [listingDetails, listingDetailsIndex] = response;
                setListing_details(listingDetails);
                setIndex(listingDetailsIndex);
                ListpackStore.incrementListingViewedMetric();
            } else {
                setRedirectNotFound(true);
            }
        }
        getListing();
    }, [ListpackStore, listingId, loadedListings, loadedListingsCount, loadedListpack]);

    function trackEvent(eventName) {
        if(!listing_details) return;

        analytics.eventTrack(eventName, {
            address: listing_details.address,
            'listing id': listing_details.id,
            listpack_id: listpack?.id,
            'current url': window.location.href,
            source: requestSource,
        });
    }

    function onPreviousClick() {
        trackEvent('listpacks_consumer_listingpage_previous_click');
        redirectToIndex(Number(index) - 1);
    }

    function onNextClick() {
        trackEvent('listpacks_consumer_listingpage_next_click');
        redirectToIndex(Number(index) + 1);
    }

    function handleFinishClick() {
        setRedirectEnd(true);
    }

    function onContactAgentClick(source) {
        analytics.eventTrack('listpacks_consumer_contact_agent_cta_click', {
            listpack_id: listpack?.id,
            listingId: listing_details?.id,
            address: listing_details?.address,
            Source: source,
        });

        onGoToEnd(listing_details, { source });
    }

    if(redirectNotFound) {
        return <Redirect to="/404" />;
    }

    if(redirectEnd) {
        return <Redirect push to={`/listpack/${ListpackStore.listpackEncodedPayload}/end?${source ? `&source=${source}` : ''}`} />;
    }

    return (
        <>
            {error && (
                <Box textAlign="center">There was an error while loading the listpack. Please try again later.</Box>
            )}
            {!error && (
                <Box display="flex" flexDirection="column" height="calc(100vh - 131)" overflow="hidden">
                    <Box
                        position="fixed"
                        bgcolor="#FFF"
                        zIndex="11"
                        width="100%"
                        maxWidth="768px"
                        boxShadow={'0px 16px 32px -13px rgba(0,0,0,0.21)'}
                        border={1}
                        borderLeft={0}
                        borderRight={0}
                        borderTop={0}
                        borderColor={COLOR_GREY}
                    >
                        <ListpackListingHeader
                            listing_details={listing_details}
                            index={index}
                            toIndex={redirectToIndex}
                            requestSource={requestSource}
                            onGoBack={onGoBack}
                        />
                    </Box>
                    {isMobile && loadedListingsCount && (
                        <Box
                            width="100%"
                            bgcolor={COLOR_GREY_LIGHT}
                            position="fixed"
                            bottom={0}
                            display="flex"
                            height="75px"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={`${theme.spacing(2)}px`}
                            border="1px solid #BBBBBB"
                            zIndex={40}
                        >
                            <LRButton
                                onClick={onPreviousClick}
                                style={{
                                    ...navigationButtonStyle,
                                    ...(Number(index) === 0 && navigationButtonDisabledStyle),
                                }}
                            >
                                Previous
                            </LRButton>
                            <Box fontSize="caption.fontSize">{`${Number(index) + 1} of ${listingsCount}`}</Box>

                            {Number(index) + 1 < listingsCount && (
                                <LRButton
                                    onClick={onNextClick}
                                    style={{
                                        ...navigationButtonStyle,
                                        color: 'white',
                                        border: 'none',
                                        backgroundColor: COLOR_SALMON,
                                    }}
                                >
                                    Next
                                </LRButton>
                            )}
                            {Number(index) + 1 >= listingsCount && (
                                <LRButton
                                    style={{
                                        ...navigationButtonStyle,
                                        color: 'white',
                                        border: 'none',
                                        backgroundColor: COLOR_SALMON,
                                    }}
                                    onClick={handleFinishClick}
                                >
                                    {'Finish 🎉'}
                                </LRButton>
                            )}
                        </Box>
                    )}
                    <Box flex={1} pt="65px">
                        <Listing
                            listing={listing_details || null}
                            buyerAgent={agent}
                            listpack={listpack}
                            onContactAgentClick={onContactAgentClick}
                            requestSource="saved_search"
                            loanOfficer={agent?.preferred_vendor}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
}

export default observer(ListpackListing);
