import {useState, useEffect} from 'react';

export const useIsScrollPositionTop = function(container) {
    const [visible, setVisible] = useState(false);

    useEffect(
        function() {
            let scrollContainer = container || document.documentElement;

            const updateHeaderTop = function() {
                setVisible(scrollContainer.scrollTop > 0);
            };

            const onScrollHandler = function() {
                requestAnimationFrame(updateHeaderTop);
            };
            if(container){
                scrollContainer.addEventListener('scroll', onScrollHandler);
            } else {
                document.addEventListener('scroll', onScrollHandler);
            }
            return function() {
                scrollContainer.removeEventListener('scroll', onScrollHandler);
            };
        },
        [container]
    );

    return visible;
};