import React from 'react';
import styled from 'styled-components';
import { Select, InputBase } from '@material-ui/core';

const StyledSelect = styled(Select)`
    & .MuiSelect-icon {
        color: #000;
    }
`;
const StyledInputBase = styled(InputBase)`
    background: transparent;
    border: 0;
    font-size: 0.875rem;

    & select {
        position: relative;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        padding: 10px 30px 10px 12px;
        width: ${({ fullWidth })=> (fullWidth ? '100%' : 'auto')};

        &:focus {
            border-radius: 4px;
            border: 1px solid #ddd;
            background-color: #fff;
        }
    }

    & .MuiSelect-icon {
        right: 6px;
    }
`;

function LRSelect({ children, inputValue, ...otherProps }) {
    return (
        <StyledSelect input={<StyledInputBase value={inputValue} />} {...otherProps}>
            {children}
        </StyledSelect>
    );
}

export default LRSelect;
