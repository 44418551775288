import React, { useState, useEffect, useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Hidden, Dialog } from '@material-ui/core';
import supercluster from 'points-cluster';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../mobx-store';
import { useResponsiveBreakpoint } from '../../../../hooks';
import LRLink from '../../../LRLink';
import { COLOR_GREY_DARK } from '../../../../constants';
import { ArrowDownward, ArrowUpward, DragHandle, Home, Remove, Add, Close } from '@material-ui/icons';
import { EditRateModal } from '../Modals/AddRate';
import { LeadFormModal } from '../Modals/LeadForm';
import analytics from '../../../../services/analytics';
import { useTheme } from '@material-ui/core/styles';
import { LeadContactForm } from '../../LeadContactForm';
import moment from 'moment';
import styled, { keyframes, css } from 'styled-components';
import Logger from 'js-logger';

const logger = Logger.get('Map');

const pulseKeyFrames = function(color) {
    return (
        keyframes`
            0% {
                -webkit-box-shadow: 0 0 0 0 ${color}D9;
                box-shadow: 0 0 0 0 ${color}D9;
            }
            
            70% {
                -webkit-box-shadow: 0 0 0 1em ${color}00;
                box-shadow: 0 0 0 1em ${color}00;
            }
            
            100% {
                -webkit-box-shadow: 0 0 0 0 ${color}00;
                box-shadow: 0 0 0 0 ${color}00;
        `
    );
};

const PulseBox = styled(Box)`
    ${({ pulseColor, showAnimation })=>
        showAnimation === true
            ? css`
                    animation: ${pulseKeyFrames(pulseColor)} 2s infinite;
                `
            : ''};
`;

const PlaceWrapper = ({ children })=> {
    return children;
};

const MapLegendColor = ({ color, ...props })=> {
    const border = props.border || '2px solid #FFF';
    return (
        <Box
            boxSizing="content-box"
            width={12}
            height={12}
            borderRadius="50%"
            bgcolor={color}
            border={border}
            {...props}
        ></Box>
    );
};

const MIN_SHOWING_PINS = 10;

const MapLegend = (props)=> {
    const isXs = useResponsiveBreakpoint('xs');

    const [isExpanded, setIsExpanded] = useState(false);

    function handleClick() {
        setIsExpanded(!isExpanded);
    }

    return (
        <Box
            position="absolute"
            bgcolor="#FFF"
            bottom={32}
            right={isXs ? 24 : 65}
            zIndex={10}
            p={1}
            borderRadius={8}
            width={94}
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            {!isExpanded && (
                <Box display="flex">
                    <Box fontSize="12px">Legend</Box>
                    <Box display="flex" ml={0.5}>
                        <MapLegendColor color={'#54D0AA'} />
                        <MapLegendColor color={'#FFC85A'} ml={-1} />
                        <MapLegendColor color={'#FF6259'} ml={-1} />
                    </Box>
                </Box>
            )}

            {isExpanded && (
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box fontSize="12px">Legend</Box>
                        <Box>
                            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5 4L3 6L1 4"
                                    stroke="#A1A1A1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M5 1L3 3L1 1"
                                    stroke="#A1A1A1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </Box>
                    </Box>
                    <Box mt={1} fontSize="8px" fontWeight={500}>
                        <Box display="flex">
                            <MapLegendColor color={'#54D0AA'} border="none" />
                            <Box ml={0.5} display="flex" justifyContent="center">
                                Lower rate
                            </Box>
                        </Box>
                        <Box display="flex" mt={0.5}>
                            <MapLegendColor color={'#FFC85A'} border="none" />
                            <Box ml={0.5} display="flex" justifyContent="center">
                                Same rate
                            </Box>
                        </Box>
                        <Box display="flex" mt={0.5}>
                            <MapLegendColor color={'#FF6259'} border="none" />
                            <Box ml={0.5} display="flex" justifyContent="center">
                                Higher rate
                            </Box>
                        </Box>
                        <Box mt={0.5} fontSize="10px">
                            Compared to your address
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

const Map = (props)=> {
    const { RateFarmStore } = useStore();
    const user = RateFarmStore.user;

    // map props
    const [center, setCenter] = useState([33.78724, -117.85496]);
    const [zoom, setZoom] = useState(16);
    const [bounds, setBounds] = useState({ nw: { lat: 85, lng: -180 }, se: { lat: -85, lng: 180 } });

    const [ratePoints, setRatePoints] = useState([]);
    const [leadAllowedIds, setLeadAllowedIds] = useState([]);

    const [homeSelected, setHomeSelected] = useState(true);

    const [showRateModal, setShowRateModal] = useState(false);
    const [showLeadFormModal, setShowLeadFormModal] = useState(false);
    const [leadCaptureModalSource, setLeadCaptureModalSource] = useState('');

    const [openedPins, setOpenedPins] = useState([]);
    const [firstRun, setFirstRun] = useState(true);
    const [showLegend, setShowLegend] = useState(false);

    const [homeLat, setHomeLat] = useState(null);
    const [homeLng, setHomeLng] = useState(null);

    const [leadSubmittedUpdatedPins, setLeadSubmittedUpdatedPins] = useState(null);

    const theme = useTheme();
    const isXs = useResponsiveBreakpoint('xs');
    const isSm = useResponsiveBreakpoint('sm');
    const isMobile = isXs || isSm;

    useEffect(()=> {
        if(RateFarmStore.leadSubmitted) return;

        setShowLeadFormModal(true);
    }, [RateFarmStore.leadSubmitted]);

    useEffect(()=> {
        if(!RateFarmStore || !RateFarmStore.addressInfo) return;

        const ratePoints = RateFarmStore.addressInfo.properties.map((property)=> {
            let color;
            let icon;
            if(!RateFarmStore.compareRate) {
                color = '#1EC8E1';
            } else if(property.loanData.interestrate < RateFarmStore.compareRate) {
                color = '#54D0AA';
                icon = <ArrowDownward style={{ fontSize: '1rem' }} />;
            } else if(property.loanData.interestrate > RateFarmStore.compareRate) {
                color = '#FF6259';
                icon = <ArrowUpward style={{ fontSize: '1rem' }} />;
            } else {
                color = '#FFC85A';
                icon = <DragHandle style={{ fontSize: '1rem' }} />;
            }

            if(RateFarmStore.addressInfo.properties.length > 0 && RateFarmStore.compareRate) {
                setShowLegend(true);
            } else {
                setShowLegend(false);
            }

            return {
                id: property.addressHash,
                lat: property.location.lat,
                lng: property.location.lon,
                rate: property.loanData.interestrate,
                date: property.loanData.recordingdate,
                color: color,
                icon: icon,
                opened: openedPins.includes(property.addressHash),
            };
        });

        setRatePoints(ratePoints);
    }, [RateFarmStore, RateFarmStore.addressInfo, RateFarmStore.compareRate, openedPins]);

    useEffect(()=> {
        if(!ratePoints.length || !firstRun) return;

        let initialOpenedIds = ratePoints
            .map((point)=> {
                return point.id;
            });
    
        if(!RateFarmStore.leadSubmitted && initialOpenedIds.length > MIN_SHOWING_PINS) {
            initialOpenedIds = initialOpenedIds.slice(0, Math.max(MIN_SHOWING_PINS, initialOpenedIds.length / 2 ));
            setLeadAllowedIds(initialOpenedIds);
        }

        setOpenedPins(initialOpenedIds);
        setFirstRun(false);
    }, [firstRun, ratePoints, RateFarmStore.leadSubmitted]);

    useEffect(()=> {  
        if(!RateFarmStore.leadSubmitted || leadSubmittedUpdatedPins) return;
        
        setOpenedPins(
            ratePoints.map((point)=> {
                return point.id;
            })
        ); 

        setLeadSubmittedUpdatedPins(true);
        
    }, [RateFarmStore.leadSubmitted, firstRun, leadSubmittedUpdatedPins, ratePoints]);

    const clusters = useMemo(()=> {
        const cl = supercluster(ratePoints, {
            minZoom: 0,
            maxZoom: 16,
            radius: 60,
        });

        window.clusters = cl({
            bounds: bounds,
            zoom: zoom,
        });

        return cl({
            bounds: bounds,
            zoom: zoom,
        });
    }, [bounds, ratePoints, zoom]);

    function handleMarkerClick(item) {
        if(item.numPoints === 1) {
            setCenter([item.points[0].lat, item.points[0].lng]);
        } else {
            setCenter([item.y, item.x]);
            setZoom(zoom + 2);
        }
    }

    function handleMapChange({ center, zoom, bounds }) {
        setZoom(zoom);
        setBounds(bounds);
    }

    function handlePlaceClick(event, point) {
        event.preventDefault();
        event.stopPropagation();
        let opened = [...openedPins];

        if(openedPins.includes(point.id)) {
            opened = openedPins.filter((item)=> {
                return item !== point.id;
            });
        } else {
            if(!RateFarmStore.leadSubmitted && !leadAllowedIds.includes(point.id)) {
                setLeadCaptureModalSource('pin_exploration_click');
                setShowLeadFormModal(true);
            } else {
                opened.push(point.id);
            }
        }

        analytics.eventTrack('rate_farm_consumer_rate_dot_click', {
            distinct_id: RateFarmStore.user.id,
            rate: point.rate,
        });

        setOpenedPins(opened);
    }

    function handleHomeClick(event) {
        event.preventDefault();
        event.stopPropagation();
        setHomeSelected(!homeSelected);
    }

    function handleHomeRateClick(event) {
        event.preventDefault();
        event.stopPropagation();

        analytics.eventTrack('rate_farm_consumer_add_your_rate_click', {
            distinct_id: user.id,
        });

        setShowRateModal(true);
        setHomeSelected(false);
    }

    function handleSeeRateClick(event) {
        event.preventDefault();
        event.stopPropagation();

        analytics.eventTrack('rate_farm_consumer_see_date_click', {
            distinct_id: user.id,
        });
        setLeadCaptureModalSource('pin_learn_more');
        setShowLeadFormModal(true);
    }

    function handleApiLoaded(map, maps) {
        const geocoder = new maps.Geocoder();
        geocoder.geocode({ address: RateFarmStore.address }, function(results, status) {
            if(status === 'OK') {
                logger.debug(results[0]);
                const location = results[0].geometry.location;

                const lat = location.lat();
                const lng = location.lng();

                setHomeLat(lat);
                setHomeLng(lng);
                setCenter([lat, lng]);
            } else {
                logger.warn('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    function zoomIn() {
        setZoom(zoom + 1);
    }
    function zoomOut() {
        setZoom(zoom - 1);
    }

    return (
        <Box position="relative" width={1} height={1}>
            {showLegend && <MapLegend />}

            <Hidden xsDown>
                <Box
                    position="absolute"
                    bgcolor="#FFF"
                    bottom={32}
                    right={24}
                    zIndex={10}
                    borderRadius={8}
                    width={28}
                    height={56}
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
                    display="flex"
                    flexDirection="column"
                >
                    <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{ cursor: 'pointer' }}
                        onClick={zoomIn}
                    >
                        <Add style={{ fontSize: '17px' }} />
                    </Box>
                    <Box border="1px solid #E0E0E0" mx={0.5}></Box>
                    <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{ cursor: 'pointer' }}
                        onClick={zoomOut}
                    >
                        <Remove style={{ fontSize: '17px' }} />
                    </Box>
                </Box>
            </Hidden>

            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: 'us' }}
                zoom={zoom}
                center={center}
                onChange={(e)=> {
                    handleMapChange(e);
                }}
                options={{
                    fullscreenControl: false,
                    zoomControl: false,
                }}
                onGoogleApiLoaded={({ map, maps })=> handleApiLoaded(map, maps)}
            >
                {clusters.map((item)=> (
                    <PlaceWrapper lat={item.points[0].lat} lng={item.points[0].lng} key={item.id}>
                        {item.numPoints === 1 && (
                            <PulseBox
                                pulseColor={item.points[0].color}
                                position="absolute"
                                left={-8}
                                top={-8}
                                height={16}
                                width={16}
                                bgcolor="#fff"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                style={{ cursor: 'pointer' }}
                                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
                                onClick={(event)=> {
                                    handlePlaceClick(event, item.points[0]);
                                }}
                                showAnimation={RateFarmStore.leadSubmitted && moment()
                                    .diff(moment(item.points[0].date), 'months') <= 6}
                            >
                                <Box
                                    bgcolor={item.points[0].color}
                                    width={12}
                                    height={12}
                                    borderRadius="50%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color="#FFF"
                                >
                                    {item.points[0].opened && (
                                        <Box
                                            position="absolute"
                                            bgcolor="#FFF"
                                            top={-52}
                                            p={1}
                                            borderRadius={4}
                                            boxShadow="rgba(0, 0, 0, 0.20) 0px 3px 7px"
                                            color="#000"
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                        >
                                            <Box display="flex" alignItems="center" fontSize="h6.fontSize">
                                                {RateFarmStore.compareRate && (
                                                    <Box
                                                        width={20}
                                                        height={20}
                                                        borderRadius="50%"
                                                        bgcolor={item.points[0].color}
                                                        color="#FFF"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        {item.points[0].icon}
                                                    </Box>
                                                )}
                                                <Box
                                                    ml={0.5}
                                                    lineHeight={1}
                                                    fontWeight={500}
                                                    letterSpacing="0.01em"
                                                    fontFamily={theme.typography.fontFamily.join(', ')}
                                                >
                                                    {item.points[0].rate}%
                                                </Box>
                                            </Box>
                                            {!RateFarmStore.leadSubmitted && (
                                                <LRLink
                                                    onClick={handleSeeRateClick}
                                                    style={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
                                                >
                                                    <Box
                                                        fontFamily={theme.typography.fontFamily.join(', ')}
                                                        fontSize={theme.typography.body1.fontSize}
                                                        fontWeight={theme.typography.body1.fontWeight}
                                                        lineHeight="10px"
                                                        mt={1 / 2}
                                                        color={theme.palette.lr_colors.blue_link}
                                                    >
                                                        See date
                                                    </Box>
                                                </LRLink>
                                            )}
                                            {RateFarmStore.leadSubmitted && (
                                                <Box
                                                    fontSize={11}
                                                    color={COLOR_GREY_DARK}
                                                    whiteSpace="nowrap"
                                                    lineHeight="10px"
                                                    mt={1 / 2}
                                                >
                                                    {`Set on ${moment(item.points[0].date)
                                                        .format('M/D/YYYY')}`}
                                                </Box>
                                            )}
                                            <Box
                                                display="block"
                                                height={10}
                                                width={10}
                                                bgcolor="#FFF"
                                                position="absolute"
                                                left="calc(50% - 5px)"
                                                bottom={-5}
                                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                                                borderRadius={4}
                                                style={{
                                                    clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
                                                    transform: 'rotate(-45deg)',
                                                }}
                                            ></Box>
                                        </Box>
                                    )}
                                </Box>
                            </PulseBox>
                        )}

                        {item.numPoints !== 1 && (
                            <Box
                                height={72}
                                width={72}
                                bgcolor="rgba(30, 200, 255, 0.25)"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={(cluster)=> {
                                    handleMarkerClick(item);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <Box
                                    height={56}
                                    width={56}
                                    bgcolor="#fff"
                                    borderRadius="50%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={(cluster)=> {
                                        handleMarkerClick(item);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Box
                                        bgcolor="#1EC8E1"
                                        width={48}
                                        height={48}
                                        borderRadius="50%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        color="#FFF"
                                        fontSize="body1.fontSize"
                                        fontWeight="800"
                                    >
                                        {item.numPoints}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </PlaceWrapper>
                ))}

                {/* Your home pin */}
                {!RateFarmStore.loading && homeLat && homeLng && (
                    <PlaceWrapper lat={homeLat} lng={homeLng} key="home">
                        <Box
                            height={16}
                            width={16}
                            bgcolor="#fff"
                            borderRadius="50%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{ cursor: 'pointer' }}
                            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
                            onClick={handleHomeClick}
                        >
                            <Box
                                bgcolor="#000"
                                width={12}
                                height={12}
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                color="#FFF"
                            >
                                {homeSelected && (
                                    <Box
                                        position="absolute"
                                        bgcolor="#FFF"
                                        top={RateFarmStore.compareRate ? -50 : -55}
                                        p={1}
                                        borderRadius={4}
                                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                                        color="#000"
                                        display="flex"
                                        fontSize="h6.fontSize"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="column"
                                        minHeight={RateFarmStore.compareRate ? 50 : 60}
                                    >
                                        {RateFarmStore.compareRate && (
                                            <Box
                                                width={1}
                                                mb={0.5}
                                                letterSpacing="1.5px"
                                                lineHeight="10px"
                                                fontSize="11px"
                                                color={COLOR_GREY_DARK}
                                                fontFamily={theme.typography.fontFamily.join(', ')}
                                                fontWeight={500}
                                                whiteSpace="nowrap"
                                            >
                                                YOUR HOME
                                            </Box>
                                        )}

                                        <Box
                                            display="flex"
                                            flexDirection={RateFarmStore.compareRate ? 'row' : 'column'}
                                        >
                                            <Box display="flex" justifyContent="center">
                                                <Box
                                                    width={20}
                                                    height={20}
                                                    borderRadius="50%"
                                                    bgcolor="#000"
                                                    color="#FFF"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Home style={{ fontSize: '15px' }} />
                                                    {/* <Box position="relative" left={3} bottom={1}>
                                                        <Home style={{ fontSize: '15px' }} />
                                                    </Box> */}
                                                </Box>
                                            </Box>
                                            <Box ml={0.5} lineHeight={1} fontWeight={500}>
                                                {RateFarmStore && (
                                                    <LRLink
                                                        onClick={handleHomeRateClick}
                                                        style={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
                                                    >
                                                        <Box
                                                            fontFamily={theme.typography.fontFamily.join(', ')}
                                                            fontSize={
                                                                RateFarmStore.compareRate
                                                                    ? theme.typography.h6.fontSize
                                                                    : theme.typography.body1.fontSize
                                                            }
                                                            fontWeight={
                                                                RateFarmStore.compareRate
                                                                    ? theme.typography.h6.fontWeight
                                                                    : theme.typography.body1.fontWeight
                                                            }
                                                            lineHeight={RateFarmStore.compareRate ? '20px' : '10px'}
                                                            mt={RateFarmStore.compareRate ? 0 : 1 / 2}
                                                            color={theme.palette.lr_colors.blue_link}
                                                        >
                                                            {RateFarmStore.compareRate
                                                                ? `${RateFarmStore.compareRate}%`
                                                                : 'Add your rate'}
                                                        </Box>
                                                    </LRLink>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box
                                            display="block"
                                            height={10}
                                            width={10}
                                            bgcolor="#FFF"
                                            position="absolute"
                                            left="calc(50% - 5px)"
                                            bottom={-5}
                                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                                            borderRadius={4}
                                            style={{
                                                clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
                                                transform: 'rotate(-45deg)',
                                            }}
                                        ></Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </PlaceWrapper>
                )}
            </GoogleMapReact>
            <EditRateModal
                rate={RateFarmStore.compareRate}
                open={showRateModal}
                toggleRateModal={()=> {
                    setShowRateModal(false);
                    setHomeSelected(true);
                }}
            />
            {/* {isMobile? ( */}
            <LeadFormModal
                user={{}}
                open={showLeadFormModal}
                toggleRateModal={()=> {
                    setShowLeadFormModal(false);
                }}
                headerTitle='Contact me to learn more'
                hideAgentText={false}
                agentText={
                    <Box fontSize={theme.typography.h6.fontSize}>
                        Enter your name and email below to access rates for your neighborhood
                    </Box>
                }
                source={'map_page'}
            />
            {/* ) : (
                <Dialog open={showLeadFormModal} fullScreen={false}>
                    <Box width={360} px={4} py={4}>
                        <Box position="absolute" onClick={()=> setShowLeadFormModal(false)} style={{ cursor: 'pointer', right: '10px', top: '10px' }}>
                            <Close />
                        </Box>
                        <LeadContactForm
                            isMobile={false}
                            source={leadCaptureModalSource}
                            user={user}
                            loadingUser={false}
                            loadedUser={true}
                            agentText={
                                <Box fontSize={theme.typography.h6.fontSize}>
                                    Contact me to find out more, like the date of mortgage!
                                </Box>
                            }
                            onLeadSubmitted={ ()=> setTimeout(()=> setShowLeadFormModal(false), 2000) }
                        />
                    </Box>
                </Dialog>
            )} */}
        </Box>
    );
};

export default observer(Map);
