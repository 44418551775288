export default {
    al: 0.82,
    ak: 0,
    az: 0.32,
    ar: 0.84,
    ca: 0.22,
    co: 0.33,
    ct: 0,
    de: 0,
    fl: 1.17,
    ga: 0.58,
    hi: 0.18,
    id: 0,
    il: 0.49,
    in: 0.86,
    ia: 0,
    ks: 0,
    ky: 0.68,
    la: 0.95,
    me: 0.57,
    md: 0.31,
    ma: 0.4,
    mi: 0.63,
    mn: 0.54,
    ms: 0,
    mo: 0.71,
    mt: 0,
    ne: 0.68,
    nv: 0.31,
    nh: 0.35,
    nj: 0.32,
    nm: 0.56,
    ny: 0.32,
    nc: 0.52,
    nd: 0,
    oh: 0.55,
    ok: 1.2,
    or: 0.2,
    pa: 0.48,
    ri: 0.56,
    sc: 0.61,
    sd: 0,
    tn: 0.65,
    tx: 1.09,
    ut: 0.23,
    vt: 0.35,
    va: 0.3,
    wa: 0.22,
    wv: 0,
    wi: 0.41,
    wy: 0
};
