import Logger from 'js-logger';
import { benoitAxios } from '../benoit-axios';

export * from './service-providers';

const logger = Logger.get('Benoit API - My Home');

/**
    GET my homes
 *
 * @param {String} boardId
 */
export async function getMyHomes(boardId) {
    let myHomes;

    try {
        const response = await benoitAxios.get(`/v1/boards/${boardId}/my-homes`);
        myHomes = response.data.data;
    } catch (err) {
        logger.error('getMyHomes', err);
        throw err;
    }

    return myHomes;
}

/**
    GET my home data by lead id (VendorLead)
 *
 * @param {String} leadId
 */
export async function getMyHomeDetailsByLeadId(leadId) {
    let myHomes;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/leads/${leadId}`);
        myHomes = response.data.data;
    } catch (err) {
        logger.error('getMyHomeDetailsByLeadId', err);
        throw err;
    }

    return myHomes;
}

/**
    GET my home data by address
 *
 * @param {String} address
 */
export async function getMyHomeDetailsByAddress(address) {
    let myHomes;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/addresses/${address}`);
        myHomes = response.data.data;
    } catch (err) {
        logger.error('getMyHomeDetailsByAddress', err);
        throw err;
    }

    return myHomes;
}

/**
    POST create my home
 *
 * @param {String} address 
 * @param {String} boardId 
 */
export async function createMyHome(boardId, data) {
    let myHomes;

    try {
        const response = await benoitAxios.post(`/v1/boards/${boardId}/my-homes`, data);
        myHomes = response.data.data;
    } catch (err) {
        logger.error('createMyHome', err);

        throw err;
    }

    return myHomes;
}

/**
    PATCH update my home
 *
 * @param {String} boardId 
 * @param {String} myHomeId 
 * @param {Object} data 
 */
export async function updateMyHome(boardId, myHomeId, data) {
    let myHomes;

    try {
        const response = await benoitAxios.patch(`/v1/boards/${boardId}/my-homes/${myHomeId}`, data);
        myHomes = response.data.data;
    } catch (err) {
        logger.error('updateMyHome', err);

        throw err;
    }

    return myHomes;
}

/**
    GET my home avm data
 *
 * @param {String} leadId
*/

export async function getMyHomeAVMDataByLeadId(leadId) {
    let avmData;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/leads/${leadId}/avm`);
        avmData = response.data.data;
    } catch (err) {
        logger.error('getMyHomeAVMDataByLeadId', err);
        throw err;
    }

    return avmData;
}

/**
    GET my home avm data
 *
 * @param {String} address
*/

export async function getMyHomeAVMDataByAddress(address) {
    let avmData;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/addresses/${address}/avm`);
        avmData = response.data.data;
    } catch (err) {
        logger.error('getMyHomeAVMDataByAddress', err);
        throw err;
    }

    return avmData;
}

/**
 * GET my homes market activity
 *
 * @param {String} leadId
 * @returns {Array}
 */
export async function getMyHomeMarketActivityByLeadId(leadId, params) {
    let data;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/leads/${leadId}/market-activity`, { params });
        data = response.data.data;
    } catch (err) {
        logger.error('getMyHomeMarketActivityByLeadId', err);
        throw err;
    }

    return data;
}

/**
 * GET my homes market activity
 *
 * @param {String} address
 * @returns {Array}
 */
export async function getMyHomeMarketActivityByAddress(address) {
    let data;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/addresses/${address}/market-activity`);
        data = response.data.data;
    } catch (err) {
        logger.error('getMyHomeMarketActivityByAddress', err);
        throw err;
    }

    return data;
}


/**
 * GET my homes market activity by areaId
 *
 * @param {String} areaId
 * @returns {Array}
 */
export async function getMyHomeMarketActivityByAreaId(areaId) {
    let data;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/area-ids/${areaId}/market-activity`);
        data = response.data.data;
    } catch (err) {
        logger.error('getMyHomeMarketActivityByAreaId', err);
        throw err;
    }

    return data;
}

/**
 * Invite someone to join robin my home
 *
 * @param {Object} contactInfo 
 */
export async function inviteUserToUseMyHome(boardId, contactInfo) {
    try {
        await benoitAxios.post(`/v1/boards/${boardId}/my-homes/invites`, contactInfo);
    } catch (err) {
        logger.error('getMyHomeMarketActivity', err);
        throw err;
    }
}

/**
 * Contact agent about the users home
 *
 * @param {String} boardId 
 * @param {MyHome} myHome 
 * @param {String} message 
 */
export async function contactAgentAboutMyHome(boardId, myHome, message) {
    try {
        await benoitAxios.post(`/v1/boards/${boardId}/my-homes/contact-my-agent`, { message }, { params: { myHomeId: myHome?.id } });
    } catch (err) {
        logger.error('getMyHomeMarketActivity', err);
        throw err;
    }
}

/**
 * Contact LO about the users home
 *
 * @param {String} boardId
 * @param {String} message
 */
export async function contactLOAboutMyHome(boardId, message) {
    try {
        await benoitAxios.post(`/v1/boards/${boardId}/my-homes/contact-my-lender`, { message });
    } catch (err) {
        logger.error('getMyHomeMarketActivity', err);
        throw err;
    }
}

export async function getMyHomeAddressListingDetails(boardId, address) {
    let data;

    try {
        const response = await benoitAxios.get(`/v1/my-homes/addresses/${address}/details-preview`);
        data = response.data.data;
    } catch (err) {
        logger.error('getMyHomeAddressListingDetails', err);
        throw err;
    }

    return data;
}

export async function deleteMyHome(boardId, myHomeId) {
    try {
        await benoitAxios.delete(`/v1/boards/${boardId}/my-homes/${myHomeId}`);
    } catch (err) {
        logger.error('deleteMyHome', err);

        throw err;
    }
}
