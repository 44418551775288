// eslint-disable-next-line
import { benoitApi } from 'apis';
import axios from 'axios';
import Logger from 'js-logger';
import _ from 'lodash';
import { benoitAxios } from './benoit-axios';

export * from './my-home';

const logger = Logger.get('Benoit API');

// PUBLIC FUNCTIONS

/**
 * Get an affiliates info from portal
 *
 * @function getAffiliate
 * @param {String} agentId
 */
export async function getAffiliate(affiliateId) {
    if(!affiliateId) return null;

    let agentInfo;

    try {
        const response = await benoitAxios.get(`/v1/affiliates/${affiliateId}`);
        agentInfo = response.data.data.affiliate;
    } catch (err) {
        logger.error('getAffiliate', err);
    }

    return agentInfo;
}

export function updateBenoitAuthToken(token) {
    // only affects the global instance and instances created afterwards
    benoitAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // immediately affects this instance
    benoitAxios.defaults.headers['Authorization'] = `Bearer ${token}`;
}

/**
 * Get the agent info that shared the listing
 *
 * @function getUser
 * @param {String} userId
 */
export async function getAgent(agentId) {
    return await getAffiliate(agentId);
}

/**
 * Get the users info that is currently logged in
 *
 * @function getUser
 * @param {String} userId
 */
export async function getUser(userId, options = {}) {
    options = {
        params: undefined,
        ...options
    };
    let userInfo;

    try {
        const response = await benoitAxios.get(`/v1/users/${userId}`, { params: options.params });
        userInfo = response.data.data;
    } catch (err) {
        logger.error('getUser', err);

        throw err;
    }

    if(userInfo.affiliateId) {
        userInfo.affiliate = await getAffiliate(userInfo.affiliateId);
    }

    return userInfo;
}


export async function getListpackAgent(listpackId) {
    if(!listpackId) return null;

    let agentInfo;
    try {
        const response = await benoitAxios.get(`v1/users/listpackAgent/${listpackId}`);
        agentInfo = response.data.data;
    } catch (err) {
        logger.error('getListpackAgent', err);
    }

    return agentInfo;
}

export async function userExists(emailOrPhone){
    try {
        const response = await benoitAxios.get(`/v1/users/exist/${emailOrPhone}`);
        return response.data.data;
    } catch (err) {
        logger.error('userExists', err);
        throw err;
    }
}

export async function isPhoneValid(phone){
    try {
        const response = await benoitAxios.get(`/v1/text-messages/isPhoneValid/${phone}`);
        return response.data.data;
    } catch (err) {
        logger.error('isPhoneValid', err);
        throw err;
    }
}

export async function getUserByToken(bearerToken) {
    try {
        const response = await benoitAxios.get(`/auth/${bearerToken}/user`);
        return response.data.data;
    } catch (err) {
        logger.error('getUserByToken', err);
        throw err;
    }
}

export async function loginUser(payload) {
    let responseData;

    try {
        const response = await benoitAxios.post('/v2/auth/', payload);
        responseData = response.data.data;
    } catch (err) {
        logger.error('loginUser', err);

        throw err;
    }

    return responseData;
}

export async function signupUser(payload) {
    let responseData;

    try {
        const response = await benoitAxios.post('/v2/signup', payload);
        responseData = response.data.data;
    } catch (err) {
        logger.error('signupUser', err);

        throw err;
    }

    return responseData;
}

export async function updateUser(user) {
    let responseData;

    try {
        const response = await benoitAxios.put(`/v1/users/${user.id}`, user);
        responseData = response.data.data;
    } catch (err) {
        logger.error('updateUser', err);

        throw err;
    }

    return responseData;
}

export async function updateUserPreferences(payload, preferenceId) {
    let responseData;

    try {
        const response = await benoitAxios.put(`/v1/user/preferences/${preferenceId}`, payload);
        responseData = response.data.data;
    } catch (err) {
        logger.error('updateUserPreferences', err);

        throw err;
    }

    return responseData;
}

export async function createUserPreferences(payload) {
    let responseData;

    try {
        const response = await benoitAxios.post('/v1/user/preferences', payload);
        responseData = response.data.data;
    } catch (err) {
        logger.error('createUserPreferences', err);

        throw err;
    }

    return responseData;
}

export async function getUserPreference(userId, boardId) {
    try {
        const response = await benoitAxios.get(`v1/user/preferences/${userId}/${boardId}`);
        var preferences = response.data.data;
    } catch (err) {
        logger.error('getUserNotifications', err);
        throw err;
    }

    return preferences;
}

export async function updateUserHeadshot(file, user) {
    try {
        let formData = new FormData();
        formData.append('headshot', file);

        const response = await benoitAxios.put(`/v1/users/${user.id}/headshot`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data.data;
    } catch (err) {
        logger.error('updateUserHeadshot', err);

        throw err;
    }
}

export async function getUserNotifications(userId) {
    try {
        const response = await benoitAxios.get(`v1/user/${userId}/notifications`);
        var notifications = response.data.data;
    } catch (err) {
        logger.error('getUserNotifications', err);

        throw err;
    }

    return notifications;
}

export async function getUserBoards(userId) {
    let boards;

    try {
        const response = await benoitAxios.get(`v1/users/${userId}/boards`, {
            params: {
                archived: 0
            }
        });
        boards = response.data.data;
    } catch (err) {
        logger.error('getUserBoards', err);

        throw err;
    }

    return boards;
}

export async function getBoardCollaborators(boardId) {
    let collaborators;

    try {
        const response = await benoitAxios.get(`v1/boards/${boardId}/user-permissions`);
        collaborators = response.data.data;
    } catch (err) {
        logger.error('getBoardCollaborators', err);

        throw err;
    }

    return collaborators;
}

export async function getBoardInvites(boardId) {
    let invites;

    try {
        const response = await benoitAxios.get(`v1/boards/${boardId}/user-invites`);
        invites = response.data.data;
    } catch (err) {
        logger.error('getBoardInvites', err);

        throw err;
    }

    return invites;
}

export async function removeBoardUserPermission(collaboratorId) {
    try {
        const response = await benoitAxios.delete(`/v1/board-user-permissions/${collaboratorId}`);
        return response.data.data.success;
    } catch (err) {
        logger.error('removeBoardUserPermission', err);

        throw err;
    }
}

export async function removeBoardUserInvite(collaboratorId) {
    try {
        const response = await benoitAxios.delete(`/v1/board-user-invites/${collaboratorId}`);
        return response.data.data.success;
    } catch (err) {
        logger.error('removeBoardUserInvite', err);

        throw err;
    }
}

export async function updateBoardUserPermission(collaborator) {
    let updatedCollaborator;
    try {
        const response = await benoitAxios.put(`/v1/board-user-permissions/${collaborator.id}`, collaborator);
        updatedCollaborator = response.data.data;
    } catch (err) {
        logger.error('updateBoardUserPermission', err);

        throw err;
    }
    return updatedCollaborator;
}

export async function updateBoardUserInvite(collaborator) {
    let updatedCollaborator;
    try {
        const response = await benoitAxios.put(`/v1/board-user-invites/${collaborator.id}`, collaborator);
        updatedCollaborator = response.data.data;
    } catch (err) {
        logger.error('updateBoardUserInvite', err);

        throw err;
    }

    return updatedCollaborator;
}

export async function updateBoard(payload) {
    let board;

    try {
        const boardId = payload.id;
        const response = await benoitAxios.put(`/v1/boards/${boardId}`, payload);
        board = response.data.data;
    } catch (err) {
        logger.error('updateBoard', err);

        throw err;
    }

    return board;
}

export async function createBoardInvite(payload) {
    let invite;
    let userPermission;

    try {
        const response = await benoitAxios.post('/v1/board-user-invites', payload);
        invite = response.data.data.invite;
        userPermission = response.data.data.userPermission;
    } catch (err) {
        logger.error('createBoardInvite', err);

        throw err;
    }

    return {invite, userPermission};
}

export async function resendBoardInviteEmail(collaboratorId){

    try {
        const response = await benoitAxios.post(`/v1/board-user-invites/${collaboratorId}/invite-email`);
        return response.data.data;
    } catch (err){
        logger.error('resendBoardInviteEmail', err);

        throw err;
    }
}

export async function getBoardUserInvite(id) {
    try {
        const response = await benoitAxios.get(`/v1/board-user-invites/${id}`);
        return response.data.data;
    } catch (err) {
        logger.error('getBoardUserInvite', err);

        throw err;
    }
}

/**
 * @function getInfographicsData
 * @param {Object} options
 * @param {Object} [options.type = 'all']
 * @param {Object} [options.address]
 * @param {Object} [options.lat]
 * @param {Object} [options.lng]
 */
export async function getInfographicsData({ type = 'all', address, lat, lng }) {
    if(!(address || (lat && lng))) {
        throw new Error('You must pass either an address or lat lng.');
    }

    let data;

    try {
        const response = await benoitAxios.get(`/v1/infographics/${type}/data`, {
            params: {
                address,
                lat,
                lng,
            },
        });
        data = response.data.data;

        if(type !== 'all') {
            data = data[type];
        }
    } catch (err) {
        logger.error('getInfographicsData', err);

        throw err;
    }

    return data;
}

/**
 * Get collaborations for a board
 *
 * @param {String} boardId
 * @param {String} [itemId]
 * @param {Object} [options]
 * @param {Object} [options.cancelToken] - An axios cancel token https://github.com/axios/axios#cancellation
 * @param {Boolean} [options.include_user = false] - If you want to return the created_by user object
 * @param {Boolean} [options.include_reactions = false] - If you want to return the reactions to the item
 * @param {Boolean} [options.archived] - filter by archived
 */
export async function getBoardCollaborations(boardId, itemId, options = {}) {
    let collaborations;

    try {
        const response = await benoitAxios.get(`/v1/boards/${boardId}/collaborations`, {
            params: {
                itemId,
                archived: options.archived,
                include_user: options.include_user ?? false,
                include_reactions: options.include_reactions ?? false,
                ...options.params,
            },
            cancelToken: options.cancelToken,
        });

        collaborations = response.data.data;
    } catch (err) {
        logger.error('getBoardCollaborations', err);

        throw err;
    }

    return collaborations;
}


export async function getAgentActivityFeed(boardId, itemId, options = {}) {

    let collaborations;

    try {
        const response = await benoitAxios.get(`/v1/boards/${boardId}/agent-activity-feed`, {
            params: {
                itemId,
                archived: options.archived,
                include_user: options.include_user ?? false,
                include_reactions: options.include_reactions ?? false,
                ...options.params,
            },
            cancelToken: options.cancelToken,
        });

        collaborations = response.data.data;
    } catch (err) {
        logger.error('getBoardCollaborations', err);

        throw err;
    }

    return collaborations;
}

export async function addBoardCollaboration(data) {
    let response;

    try {
        response = await benoitAxios.post('/v1/board-collaborations', data);
        response = response.data.data;

    } catch (err) {
        logger.error('addBoardCollaboration', err);
        throw err;
    }

    return response;
}

export async function sendPeadSignatureReminder(id, data) {
    let response;

    try {
        response = await benoitAxios.post(`/v1/board-collaborations/${id}/send-pead-signature-reminder`, data);
        response = response.data.data;

    } catch (err) {
        logger.error('sendPeadSignatureReminder', err);
        throw err;
    }

    return response;
}

export async function updateBoardCollaboration(id, data) {
    let response;

    try {
        response = await benoitAxios.put(`/v1/board-collaborations/${id}`, data);
        response = response.data.data;
    } catch (err) {
        logger.error('updateBoardCollaboration', err);

        throw err;
    }

    return response;
}

/**
 * deleteBoardCollaboration
 *
 * @param {String} id
 * @param {Object} [options]
 * @param {String} [options.soft_delete = true] - If false it will delete it from the DB rather then just archive it
 */
export async function deleteBoardCollaboration(id, options = {}) {
    const params = {};

    if(options.soft_delete != null) {
        params.soft_delete = options.soft_delete;
    }

    try {
        await benoitAxios.delete(`/v1/board-collaborations/${id}`, {
            params: params,
        });
    } catch (err) {
        logger.error('deleteBoardCollaboration', err);

        throw err;
    }
}

/**
 * Get pinspiration_image collaborations for a board
 *
 * @param {String} boardId
 * @param {String} [itemId]
 * @param {Object} [options]
 * @param {Object} [options.cancelToken] - An axios cancel token https://github.com/axios/axios#cancellation
 */
export async function getPinspirationBoardCollaborations(boardId, options = {}) {
    let collaborations;

    try {
        const response = await benoitAxios.get(`/v1/boards/${boardId}/pinspiration`, options);
        collaborations = response.data.data;
    } catch (err) {
        logger.error('getPinspirationBoardCollaborations', err);

        throw err;
    }

    return collaborations;
}

export async function updateUserTodo(boardId, userTodoId, payload) {
    let userTodo;

    try {
        const response = await benoitAxios.put(`/v2/boards/${boardId}/user-todos/${userTodoId}`, payload);
        userTodo = response.data.data;
    } catch (err) {
        logger.error('updateUserTodo', err);
        throw err;
    }

    return userTodo;
}

export async function createUserTodo(boardId, userTodo) {

    let task;

    try {
        const response = await benoitAxios.post(`/v2/boards/${boardId}/user-todos`, userTodo);
        task = response.data.data;
    } catch (err) {
        logger.error('createUserTodo', err);
        throw err;
    }

    return task;
}

export async function markTaskComplete(boardId, userTodoId) {

    let response;
    try {
        response = await benoitAxios.put(`/v2/boards/${boardId}/user-todos/${userTodoId}/completed`);
    } catch (err) {
        logger.error('markTaskComplete', err);
        throw err;
    }

    return response;
}

export async function getBoardTasks(boardId, { filter = 'all', limit = 5, skip = 0 }) {

    let tasks;

    try {
        const response = await benoitAxios.get(`/v2/boards/${boardId}/user-todos`, {
            params: {
                filter,
                limit,
                skip
            }
        });
        tasks = response.data.data;
    } catch (err) {
        logger.error('getBoardTasks', err);

        throw err;
    }

    return tasks;
}

/**
 * Fetch a listing by its listing id.
 * NOTE: This id should be the encoded version.
 *
 * @param {String} listingId
 * @param {Object} [options]
 * @param {Object} [options.cancelToken] - An axios cancel token https://github.com/axios/axios#cancellation
 */
export async function getListingDetailsById(listingId, options = {}) {
    let response;

    try {
        response = await benoitAxios.get(`/v1/listings/${listingId}`, {
            params: { forRobin: true },
            cancelToken: options.cancelToken,
        });
        response = response.data.data;
    } catch (err) {
        if(err && err.message !== 'Component unmounting') {
            logger.error('getListingDetailsById', err);
        }

        throw err;
    }

    return response;
}

/**
 * Fetch board preferences
 *
 * @param {String} boardId
 */
export async function getBoardListpacks(boardId) {
    let response;

    try {
        response = await benoitAxios.get(`/v1/boards/${boardId}/listpacks`);
        response = response.data.data;
    } catch (err) {
        logger.error('getBoardListpacks', err);

        throw err;
    }

    return response;
}

/**
 * Fetch board preferences
 *
 * @param {String} boardId
 */
export async function getBoardPreferences(boardId) {
    let response;

    try {
        response = await benoitAxios.get(`/v1/boards/${boardId}/preferences`);
        response = response.data.data;
    } catch (err) {
        logger.error('getBoardPreferences', err);

        throw err;
    }

    return response;
}

/**
 * Fetch multiple listings by listing ids.
 * NOTE: This ids param should be the encoded version.
 *
 * @param {Tring} listingId
 * @param {Object} [options]
 * @param {Object} [options.cancelToken] - An axios cancel token https://github.com/axios/axios#cancellation
 */
export async function getListingsDetailsByIds(listingsIds, options = {}) {
    let response;
    const CHUNK_SIZE = 200;
    listingsIds = _.filter(listingsIds, (listingId)=>  listingId);
    const listingsIdsChunks = _.chunk(listingsIds, CHUNK_SIZE);
    const requests = listingsIdsChunks.map((listingsIdsChunk)=> {
        return benoitAxios.get('/v1/listings', {
            cancelToken: options.cancelToken,
            params: { forRobin: true, ids: listingsIdsChunk.join(',') },
        });
    });
    try {
        response = await axios.all(requests);
        response = _.concat(..._.map(response, 'data.listings'));
    } catch (err) {
        if(err && err.message !== 'Component unmounting') {
            logger.error('getListingsDetailsByIds', err);
        }

        response = [];
    }

    return response;
}

/**
 * insert/update board preferences
 *
 * @param {String} boardId
 */
export async function upsertBoardPreferences(boardId, preferences) {
    let response;

    try {
        response = await benoitAxios.patch(`/v1/boards/${boardId}/preferences`, preferences);
        response = response.data.data;
    } catch (err) {
        logger.error('upsertBoardPreferences', err);

        throw err;
    }

    return response;
}

/**
 * reset board preferences
 *
 * @param {String} boardId
 */
export async function resetBoardPreferences(boardId) {
    let response;

    try {
        response = await benoitAxios.put(`/v1/boards/${boardId}/preferences/reset`);
        response = response.data.data;
    } catch (err) {
        logger.error('resetBoardPreferences', err);

        throw err;
    }

    return response;
}

export async function createBoardPreferences(boardId, data) {
    let response;

    try {
        data.boardId = boardId;

        response = await benoitAxios.post('/v1/board-preferences', data);
        response = response.data.data;
    } catch (err) {
        logger.error('createBoardPreferences', err);

        throw err;
    }

    return response;
}

export async function createBoard(data) {
    let response;

    try {
        response = await benoitAxios.post('/v1/boards', data);
    } catch (err) {
        logger.error('createBoard', err);

        throw err;
    }

    return response.data;
}

/**
 *
 * @param {String} boardId
 * @param {Object} options
 * @param {Boolean} [options.include_total_notes = false]
 * @param {Boolean} [options.include_last_moved_by_id = false]
 */
export async function getBoardCards(boardId, options = {}) {
    try {
        const response = await benoitAxios.get(`/v1/boards/${boardId}/cards`, {
            params: {
                include_total_notes: options.include_total_notes,
                include_last_moved_by_id: options.include_last_moved_by_id,
            },
        });
        return response.data.cards;
    } catch (err) {
        logger.error('getBoardCards', err);

        throw err;
    }
}

/**
 *
 * @param {String} boardId
 * @param {Object} options
 */
export async function getBoardCard(cardId) {
    try {
        const response = await benoitAxios.get(`/v1/board-cards/${cardId}`);
        return response.data.data;
    } catch (err) {
        logger.error('getBoardCards', err);

        throw err;
    }
}

export async function updateBoardCards(boardId, boardCardsData, options = {}) {
    try {
        const response = await benoitAxios.put(`/v1/boards/${boardId}/cards`, boardCardsData);
        return response.data;
    } catch (err) {
        logger.error('updateBoardCards', err);

        throw err;
    }
}

export async function addBoardCard(data) {
    try {
        const response = await benoitAxios.post('/v1/board-cards', data);
        return response.data.data;
    } catch (err) {
        logger.error('addBoardCard', err);

        throw err;
    }
}

export async function updateBoardCard(boardCardId, boardData, options = {}) {
    try {
        const response = await benoitAxios.put(`/v1/board-cards/${boardCardId}`, boardData);
        return response.data.data;
    } catch (err) {
        logger.error('updateBoardCard', err);

        throw err;
    }
}

export async function archiveBoardCard(boardCardId) {
    try {
        const response = await benoitAxios.delete(`/v1/board-cards/${boardCardId}`);
        return response.data.data;
    } catch (err) {
        logger.error('deleteBoardCard', err);

        throw err;
    }
}

export async function archiveLaneCards(boardCardId, laneId) {
    try {
        const response = await benoitAxios.delete(`/v1/board-cards/${boardCardId}/${laneId}/delete-cards`);
        return response.data.data;
    } catch (err) {
        logger.error('deleteLaneCards', err);

        throw err;
    }
}

export async function getPreferredListings(boardId) {
    try {
        const response = await benoitAxios.put(`/v1/boards/${boardId}/preferred-listings`);
        return response.data;
    } catch (err) {
        logger.error('getPreferredListings', err);

        throw err;
    }
}

export async function createListpack(listpackData) {
    try {
        const response = await benoitAxios.post('/v2/listpacks', listpackData);
        return response.data.data;
    } catch (err) {
        logger.error('createListpack', err);
    }
}

export async function getListpackFiltersListingsCount(areaIds, fields) {
    try {
        const response = await benoitAxios.post('/v2/listpacks/params-listings-count', { areaIds, fields });
        return response.data.data;
    } catch (err) {
        logger.error('getListpackFiltersListingsCount', err);

        throw err;
    }
}

export async function createBenoitUser(payload) {
    let user;

    try {
        const response = await benoitAxios.post('/v1/users', payload);
        user = response.data.data;
    } catch (err) {
        logger.error('createBenoitUser', err);

        throw err;
    }

    return user;
}

export async function sendVerificationCode(userId, type) {
    try {
        await benoitAxios.post(`/v1/users/${userId}/account-verification-code/${type}`);
    } catch (err) {
        logger.error('sendVerificationCode', err);

        throw err;
    }
}

export async function updateListpack(listpackData) {
    try {
        const response = await benoitAxios.put(`/v2/listpacks/${listpackData.id}`, listpackData);
        return response.data.data;
    } catch (err) {
        logger.error('updateListpack', err);

        throw err;
    }
}

export async function requestLogin(type, value, options = {}) {
    let token = null;
    try {
        const response = await benoitAxios.post('/v1/users/login', { type, value }, {
            params: options
        });
        token = response.data.data;
    } catch (err) {
        logger.error('requestLogin', err);
        throw err;
    }

    return token;
}

export async function activateDevice(code, token) {
    let loggedinUser = null;
    try {
        const response = await benoitAxios.post('/v1/accounts/devices/activate?forRobin=true', { code, token });
        loggedinUser = response.data.data;
    } catch (err) {
        logger.error('activateDevice', err);
        throw err;
    }

    return loggedinUser;
}

export async function incrementBoardMetric(boardId, metric){
    try {
        const response = await benoitAxios.post(`/v1/boards/${boardId}/metrics/${metric}/increment`);
        return response.data.data;
    } catch (err) {
        console.group('Benoit API - incrementBoardMetric');
        Logger.error(err);
        console.groupEnd();

        throw err;
    }
}

export async function getMLSCompliance(listingId, user){
    try {
        logger.debug('getMLSCompliance -> listingId -> ', listingId);
        const response = await benoitAxios.post(`/v1/listings/${listingId}/compliance`, {
            agent: user
        });
        logger.debug('getMLSCompliance -> ', response.data.mls);
        return response.data;
    } catch (err) {
        console.group('Benoit API - getMLSCompliance');
        Logger.error(err);
        console.groupEnd();
        return {};
    }
}

/**
 * Hubspot contacts
 */
export async function updateHubspotContact(contactId, patchData) {
    try {
        const response = await benoitAxios.patch(`/v1/hubspot-contacts/${contactId}`, patchData);
        return response.data.data;
    } catch (err) {
        console.group('Benoit API - updateHubspotContact');
        Logger.error(err);
        console.groupEnd();
        throw err;
    }
}

export async function getHubspotContact(contactId) {
    try {
        const response = await benoitAxios.get(`/v1/hubspot-contacts/${contactId}`);
        return response.data.data;
    } catch (err) {
        console.group('Benoit API - getHubspotContact');
        Logger.error(err);
        console.groupEnd();
        throw err;
    }
}

export async  function getBoardOpenHouses(boardId, filter, laneId) {
    try {
        const response = await benoitAxios.get(`/v2/boards/${boardId}/open-houses`, {
            params: {
                filter,
                laneId
            }
        });
        return response.data.data;
    } catch (err) {
        console.group('Benoit API - getOpenHouses');
        Logger.error(err);
        console.groupEnd();
        throw err;
    }
}

export async function getBoardScheduledOpenHouses(boardId, filter, laneId) {
    try {
        const response = await benoitAxios.get(`/v2/boards/${boardId}/scheduled-open-houses`, {
            params: {
                filter,
                laneId
            }
        });
        return response.data.data;
    } catch (err) {
        console.group('Benoit API - getOpenHouses');
        Logger.error(err);
        console.groupEnd();
        throw err;
    }
}

export async function getListingOpenHouses(listingId) {
    try {
        const response = await benoitAxios.get(`/v2/listings/${listingId}/open-house-virtual-tours`);
        return response.data.data;
    } catch (err) {
        console.group('Benoit API - getListingOpenHouses');
        Logger.error(err);
        console.groupEnd();
        throw err;
    }
}
