import * as braze from '@braze/web-sdk';
import Logger from 'js-logger';
import { getBrazeEvent } from './custom-events';
const logger = Logger.get('BrazeClient');
const {
    REACT_APP_BRAZE_SDK_ENDPOINT,
    REACT_APP_BRAZE_SDK_API_KEY,
    REACT_APP_MODE,
} = process.env;

class BrazeClient {
    userId = null;
    constructor({ apiKey, baseUrl }) {
        // set debug logger
        braze.setLogger((msg)=> logger.debug(msg));

        // initialize the SDK
        braze.initialize(apiKey, {
            baseUrl,
            enableLogging: (REACT_APP_MODE !== 'production'),
            inAppMessageZIndex: 9999,
        });

        // optionally show all In-App Messages without custom handling
        braze.automaticallyShowInAppMessages();

        this.braze = braze;
    }

    /* Private Fns */

    _trackEvent(eventName, eventProperties) {
        const success = this.braze.logCustomEvent(eventName, eventProperties);
        logger.debug(`${eventName} track - success: ${success}`, eventProperties);
        return success;
    }

    _getBrazeEvent(action, properties) {
        return getBrazeEvent(action, properties);
    }

    /* Public Fns - SDK Action Helpers */

    vendorLeadEmailSubscribe(lead, customAttrs={}) {
        const self = this;
        const _addUserAliasAndAttributes = ()=> {
            let result = null;
            if(lead && lead.id && lead.applicant && lead.applicant.email) {
                const addAliasResponse = self.braze.getUser().addAlias(lead.id, 'vendor_lead_id');
                const setEmailResponse = self.braze.getUser().setEmail(lead.applicant.email);
                const setEmailSubscriptionSettingResponse = self.braze.getUser().setEmailNotificationSubscriptionType('opted_in');
                if(customAttrs) customAttrs.vendor_lead_id = lead.id;
                let customAttrsResponse = {};
                if(Object.keys(customAttrs).length) {
                    Object.keys(customAttrs).forEach((key)=> {
                        customAttrsResponse[key] = self.braze.getUser().setCustomUserAttribute(key, customAttrs[key]);
                    });
                }
                result = {
                    addAliasResponse,
                    setEmailResponse,
                    setEmailSubscriptionSettingResponse,
                    customAttrsResponse,
                };
            }
            logger.debug(`vendorLeadEmailSubscribe -> [${lead?.id}]`, result);
            return result;
        };

        return self.braze.getUser()
            .getUserId((userId)=> {
                // only set aliases and attrs if this
                // is not an existing identified user
                if(!userId) _addUserAliasAndAttributes();
            });
    }

    /* Public Fns - Analytics Hooks */

    setUsername(id, isPublicUser) {
        if(!isPublicUser && id && id !== this.userId) {
            this.userId = id;
            return this.braze.changeUser(id);
        } else if(isPublicUser) {
            // Skipping for non-Robin public listpack, shareables etc lead capture landing pages
            // we don't want braze to track this as the affiliate Agent/LO's sessions
            logger.debug(`setUsername - skipped isPublicUser: ${isPublicUser} | userId: ${id}`);
        }
    }

    initSession() {
        return this.braze.openSession();
    }

    eventTrack(action, properties) {
        const self = this;
        if(typeof action === 'string') {
            const { eventName, eventProperties: eventProps } = self._getBrazeEvent(action, properties);
            const eventProperties = (typeof eventProps === 'function')
                ? eventProps(action, properties)
                : eventProps;
            if(eventName && eventProperties) {
                self._trackEvent(eventName, eventProperties);
            }
        }
    }

    pageTrack(path) {
        const eventName = ((/\/listing\//).test(path))
            ? 'listing_page_view'
            : ((/\/boards\/listpack\//).test(path))
                ? 'saved_search_page_view'
                : ((/\/listpack\//).test(path))
                    ? 'listpack_page_view'
                    : 'page_view';
        const eventProperties = {
            // full url including the protocol, hostname and port
            url: `${
                window &&
                window.location &&
                window.location.origin
                    ? window.location.origin
                    : ''
            }${path}`,
        };
        this._trackEvent(eventName, eventProperties);
    }
}

const brazeClient =  new BrazeClient({
    apiKey: REACT_APP_BRAZE_SDK_API_KEY,
    baseUrl: REACT_APP_BRAZE_SDK_ENDPOINT,
});

export default brazeClient;
