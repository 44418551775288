import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import  {useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../mobx-store';
import { Grid, Box, Typography, InputAdornment, TextField } from '@material-ui/core';
import { withTheme, useTheme } from '@material-ui/core/styles';
import { LRAreaAutocomplete } from '../../../../components/LRAreaAutocomplete';
import LRInput from '../../../../components/LRInput';
import SearchIcon from '@material-ui/icons/Search';
import { Room} from '@material-ui/icons';
import { ListpackCard, CardPlaceholder, MinimizedCardPlaceholder } from '../../ListpackCard';
import ReactPlaceholder from 'react-placeholder';
import { Can } from '../../../../components/Ability';
import analytics from '../../../../services/analytics';
import { ArchivedHomes } from '../../ArchivedHomes';
import clsx from 'clsx';
import { ListpackAreaSearchInput } from '../../../../components/ListpackAreaSearchInput';
import { useOnboarding } from '../../../../components/Onboarding';
import LanesDescription from '../../LanesDescription';
import { useBoardListpacks } from 'hooks/useBoardListpacks';

const LaneContainer = styled.div`
    height: 100%;
    width: 384px;
    min-width: 384px;
    margin: 12px;
    margin-top: 0;  
    margin-bottom: 0px;
    border-radius: 4px;
`;

const StyledTextField = withTheme(styled(TextField)`
    background: #FFF;

    input { 
        font-size: 16px
    }

    & .MuiOutlinedInput-notchedOutline {
        border-radius: 2px;
        border-color: #dddddd;
        
    }
    & ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 14px;
      }
    & ::-moz-placeholder { /* Firefox 19+ */
        font-size: 14px;
    }
`);

const StyledTitleWrapperGrid = styled(Grid)`
    overflow: hidden;
    color: #1A1A1A;
`;

const StyledTitleTypography = styled(Typography)`
    font-weight: normal;
`;

export const SearchLane = observer((props)=> {
    const onboarding = useOnboarding();
    const theme = useTheme();
    const areaAutocompleteRef = useRef();
    const { boardsStore, UserStore } = useStore();
    const { activeBoard } = boardsStore;
    const history = useHistory();
    const [showOverflowShadow, setShowOverflowShadow] = useState(false);
    const { user } = UserStore;
    const {
        sortedListpacks
    } = useBoardListpacks();

    function renderSuggestionIcon(type) {
        let iconElement;
        switch(type) {
            case 'city':
                iconElement = <Room fontSize="small"/>;
                break;
            default:
                iconElement = <Room fontSize="small"/>;
                break;
        }
        return iconElement;
    }

    function handleSuggestionSelect({id, type, ...props}) { 
        analytics.eventTrack('robin_board_search_submitted', {
            'affiliate_id': user?.affiliateId,
            'search_query id': id,
        });
        onboarding.next();
        areaAutocompleteRef.current.clearSearch();
        history.push(`/boards/listpack/create/${id}`);
    }

    function handleScroll(event) {
        const scrollTop = event.target.scrollTop;
        setShowOverflowShadow(scrollTop > 0);
    }

    return (<LaneContainer>
        <StyledTitleWrapperGrid
            container
            alignItems="center"
            justify="space-between"
        >
            <StyledTitleTypography variant="h6" style={{ marginLeft: theme.spacing(3) }}>
                Your searches

                <Typography variant="body2" component="span" style={{ marginLeft: theme.spacing(2), }}>
                    {activeBoard && activeBoard.newListings > 0 && (
                        <span
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                            }}
                            role="img" 
                            aria-label="party"
                        >
                            {activeBoard.newListings} new 🎉
                        </span>
                    )}
                </Typography>
            </StyledTitleTypography>
        </StyledTitleWrapperGrid>

        {/* <LanesDescription isSavedSearchLane={true} /> */}

        <Box
            width={1}
            mt={2}
            height="calc(100% - 66px)"
            bgcolor="#eee"
            display="flex"
            flexDirection="column"
            position="relative"
        >
            <Can I="create" a="Listpack">
                <Box bgcolor="##eee;" 
                    boxShadow={showOverflowShadow ? 'rgba(0, 0, 0, 0.1) 0px 10px 5px 0px' : ''}
                    p={3}
                    position="sticky"
                    top="0" 
                    zIndex="2"
                    style={{
                        transition: 'box-shadow 0.2s ease-in-out'
                    }}
                >
                    <ListpackAreaSearchInput location />
                    {/* <LRAreaAutocomplete
                        ref={areaAutocompleteRef}
                        onSuggestionSelect={handleSuggestionSelect}
                        rootContainerProps={{ className: 'tour-target-listpack-search' }}
                        renderInput={
                            (props)=> {
                                return (
                                    <StyledTextField
                                        variant="outlined"
                                        color="primary"
                                        placeholder="Search by city, zip, neighborhood or school!"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <Box color="#DADADA" display="flex">
                                                    <SearchIcon fontSize="small" />
                                                </Box>
                                            </InputAdornment>,
                                        }}
                                        size="small"
                                        fullWidth
                                        {...props}
                                    />
                                );
                            }
                        }
                        suggestionRenderOption={(suggestion)=> {
                            return (
                                <Box
                                    display="flex"
                                    style={{ cursor: 'pointer' }}
                                    className="tour-target-listpack-search-option"
                                >
                                    <div>
                                        {
                                            renderSuggestionIcon(suggestion.type)
                                        }
                                    </div>
                                    <Box ml={1}>{suggestion.id}</Box>
                                </Box>
                            );
                        }}
                        errorTooltipContent={'Sorry, it looks like we can’t find this area.'}
                    /> */}
                </Box>
            </Can>
            <Box 
                onScroll={handleScroll} 
                width={1} 
                maxHeight="calc(100vh - 200px)"
                overflow="auto" 
                p={3} display="flex"
                pt={0}
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
            >   
                <Box>
                    {activeBoard && sortedListpacks.opened.map((listpack, index)=> (
                        <ReactPlaceholder key={listpack.id} ready={listpack.listpackDataLoaded} customPlaceholder={<Box mb={2}><CardPlaceholder /></Box>}>
                            <Box
                                mt={index > 0 ? 2 : 0}
                                key={listpack.id}
                                className={
                                    clsx(
                                        'tour-target-my-listpack-card',
                                        `tour-target-opened-listpack-card-created-by-${listpack.isCreatedByAffiliate ? listpack.affiliateId : listpack.userId}`,
                                        {
                                            'tour-target-my-opened-listpack-card': !listpack.isCreatedByAffiliate,
                                            'tour-target-affiliate-opened-listpack-card': listpack.isCreatedByAffiliate,
                                        }
                                    )
                                }>
                                <ListpackCard listpack={listpack} index={index} />
                            </Box>
                        </ReactPlaceholder>
                    ))}
                  
                    {activeBoard && sortedListpacks.closed.map((listpack, index)=> (
                        <ReactPlaceholder key={listpack.id} ready={listpack.listpackDataLoaded} customPlaceholder={<Box mb={1}><CardPlaceholder /></Box>}>
                            <Box
                                mt={index > 0 || activeBoard.sortedListpacks.opened.length > 0 ? 2 : 0}
                                key={listpack.id}
                                className={
                                    clsx(
                                        'tour-target-my-listpack-card',
                                        {
                                            'tour-target-my-closed-listpack-card': !listpack.isCreatedByAffiliate,
                                        }
                                    )
                                }
                            >
                                <ListpackCard listpack={listpack} index={index} />
                            </Box>
                        </ReactPlaceholder>
                    ))}

                    {activeBoard && !sortedListpacks.handSelected && (
                        <ReactPlaceholder ready={true} customPlaceholder={<Box mt={2}><MinimizedCardPlaceholder /></Box>}>
                            <Box mt={2}>
                                <ListpackCard listpack={{
                                    handSelected: true,
                                    listpackDataLoaded: true,
                                    activeListingsCount: 0,
                                }} />
                            </Box>
                        </ReactPlaceholder >
                    )}

                    <ArchivedHomes />
                </Box>
                <Box>
                    
                </Box>
            </Box>
        </Box>

    </LaneContainer>);
});
