import React, { useState } from 'react';

import { Box, Divider, CircularProgress, Snackbar } from '@material-ui/core';
import styled from 'styled-components';
import { DEFAULT_HEADSHOT } from '../../../constants';
import { useResponsiveBreakpoint } from '../../../hooks';
import { COLOR_GREY_DARK } from '../../../constants';
import { TextField } from '../../TextField';
import LRButton from '../../LRButton';
import { useForm } from 'react-hook-form';
import { portalApi } from '../../../apis';
import Lockr from 'lockr';
import { observer } from 'mobx-react-lite';
import analytics from '../../../services/analytics';
import { LRPhoneInput } from '../../LRPhoneInput';
import { withTheme } from '@material-ui/core/styles';
import { useStore } from '../../../mobx-store';
import html2canvas from 'html2canvas';
import Logger from 'js-logger';
import formatPhoneNumber from '../../../services/format-phone-number';
import LRLink from '../../LRLink';
import { LOCompliance } from './../RateFarmModal/Modals/LOCompliance';

const logger = Logger.get('LeadContactForm');

const UserAvatar = styled(Box)`
    background-image: url("${(props)=> (props.headshot ? props.headshot : DEFAULT_HEADSHOT)}");
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    width: ${(props)=> (props.isMobile || props.source === 'map_page' ? '64px' : '80px')};
    height: ${(props)=> (props.isMobile || props.source === 'map_page' ? '64px' : '80px')};
`;

const CustomLRButton = withTheme(styled(LRButton)`
    width: 100%;
    height: 48px;

    &.Mui-disabled {
        background-color: ${({ theme, color })=> theme.palette[color].main};
        color: ${({ theme, color })=> theme.palette[color].contrastText};
        opacity: 0.5;
    }
`);

const AffiliateInfo = function({ isMobile, user, source, agentText }) {
    const isAgent = user.type === 'agent';

    function isMapDesign() {
        return source === 'map_page' || source === 'pin_learn_more' || source === 'pin_exploration_click';
    }

    return (
        <Box
            display="flex"
            flexDirection={isMobile && !isMapDesign() ? 'row-reverse' : 'column'}
            alignItems={isMobile ? '' : 'center'}
            justifyContent={isMobile ? 'space-between' : ''}
            textAlign={isMobile ? '' : 'center'}
        >
            <Box
                display="flex"
                alignItems={isMapDesign() ? 'center' : 'flex-start'}
                justifyContent={isMapDesign() ? 'flex-start' : 'center'}
                mb={isMobile ? 0 : '22px'}
            >
                <UserAvatar headshot={user.headshot} isMobile={isMobile} source={source} />
                {isMapDesign() && (
                    <Box ml="9px" textAlign="left" style={{ wordBreak: 'break-all' }}>
                        <Box fontWeight="500" fontSize="18px">
                            {user.name}
                        </Box>
                        <Box fontSize="16px">{user.phone}</Box>
                    </Box>
                )}
            </Box>
            {isMapDesign() ? (
                <Box fontSize="18px" mt={isMobile ? '20px' : 0} textAlign="left">
                    {agentText}
                </Box>
            ) : (
                <Box style={{ wordBreak: 'break-all' }}>
                    <Box fontWeight="500" fontSize="18px">
                        {user.name}
                    </Box>
                    <Box fontSize="16px">{user.company}</Box>
                    <Box fontSize="12px" color={COLOR_GREY_DARK}>
                        {isAgent ? 'License ' : 'NMLS '} #{user.realtor_license_number}
                    </Box>
                    <Box my="11px" maxWidth="24px" mx={isMobile ? '' : 'auto'}>
                        <Divider style={{ height: '2px' }} />
                    </Box>
                    <Box fontSize="16px">{formatPhoneNumber(user.phone)}</Box>
                    <Box fontSize="16px">{user.email}</Box>
                </Box>
            )}
        </Box>
    );
};

export const LeadContactForm = observer(function({ user, loadingUser, loadedUser, isMobile = null, source, agentText, onLeadSubmitted }) {
    const { RateFarmStore } = useStore();

    const isXs = useResponsiveBreakpoint('xs');
    isMobile = isMobile !== null ? isMobile : isXs;

    const rfhMethods = useForm();
    const { register, handleSubmit, errors, formState } = rfhMethods;
    const [submittingLead, setSubmittingLead] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showLOCompliance, setShowLOCompliance] = useState(false);


    function isMapDesign() {
        return source === 'map_page' || source === 'pin_learn_more' || source === 'pin_exploration_click';
    }

    async function takeSnapshot() {
        const el = document.body;
        const canvas = await html2canvas(el, { useCORS: true });
        const snapshotImg = canvas.toDataURL();
        const snapshotHtml = document.documentElement.outerHTML;
        return [snapshotImg, snapshotHtml];
    }

    const submitLead = async (data)=> {
        const leadData = {
            applicant: {
                name: data.name,
                email: data.email,
                ...(data.phone && { phone: data.phone }),
            },
            ownerId: user.id,
            source: 'Rate Farm',
        };

        const leadResponse = await portalApi.createLead(user, leadData);
        const addedLead = leadResponse.createdNewLead;
        const lead = leadResponse.data;
        const latestSearchedAddress = Lockr.get('rate-farm-latest-searched-address');
        const variation = addedLead ? 'new_lead' : 'new_message';

        let snapshotImg, snapshotHtml;
        try {
            [snapshotImg, snapshotHtml] = await takeSnapshot();
            logger.debug(snapshotImg);
        } catch (err) {
            logger.error(err);
        }

        const response = await portalApi.sendUserLeadSubmit(user, lead, variation, data.message, latestSearchedAddress, snapshotImg, snapshotHtml);

        const activity = response.activity;

        analytics.eventTrack('rate_farm_consumer_lead_form_submit', {
            distinct_id: user.id,
            Source: source,
            Lead_Name: lead.applicant.name,
            Lead_Email: lead.applicant.email,
            ...(lead.applicant.phone && { Lead_Phone: lead.applicant.phone }),
            Lead_Message: data.message,
        });

        Lockr.set('rate-farm-submitted-lead-activity', activity);
        Lockr.set('rate-farm-submitted-lead', lead);
        RateFarmStore.leadSubmitted = true;

        return lead;
    };

    async function onLeadSubmit(data) {
        if(!loadedUser || loadingUser) return;
        if(data.phone) {
            data.phone = data.phone.replace(/-/g, '');
        }
        setSubmittingLead(true);

        try {
            await submitLead(data);
            setShowSuccessSnackbar(true);
            if(onLeadSubmitted) {
                onLeadSubmitted();
            }
        } catch (error) {
            setShowErrorSnackbar(true);
        } finally {
            setSubmittingLead(false);
        }
    }

    if(loadingUser || !loadedUser) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                width={isMobile ? '100%' : '298px'}
            >
                <CircularProgress color="secondary" />
            </Box>
        );
    }

    return (
        <Box width="100%">
            <Snackbar
                open={showSuccessSnackbar || showErrorSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={
                    <Box width="330px" textAlign="center">
                        {showSuccessSnackbar && 'Message sent!'}
                        {showErrorSnackbar && 'An error occurred. Please try again later.'}
                    </Box>
                }
                autoHideDuration={2000}
                onClose={()=> {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
            />
            <form onSubmit={handleSubmit((data)=> onLeadSubmit(data))} noValidate>
                <Box display="flex" flexDirection={isMobile && !isMapDesign() ? 'column-reverse' : 'column'}>
                    <Box
                        mb={isMobile || isMapDesign() ? 0 : '31px'}
                        mt={isMobile && !isMapDesign() ? '31px' : 0}
                        width="100%"
                    >
                        <AffiliateInfo
                            agentText={agentText}
                            user={user}
                            isMobile={isMobile}
                            source={source}
                        />
                    </Box>
                    <Box>
                        {!isMapDesign() ? (
                            <Box fontSize="20px" fontWeight="500">
                                <Box>Got questions?</Box>
                                <Box>
                                    {user.first_name} can answer them.{' '}
                                    <span role="img" aria-label="point_down">
                                        &#128071;
                                    </span>
                                </Box>
                            </Box>
                        ) : null}
                        <Box mt="27px">
                            <TextField
                                name="name"
                                label="Name"
                                labelProps={{ fontSize: 18, fontWeight: 'normal' }}
                                inputProps={{ height: '48px', width: '100%' }}
                                inputRef={register({
                                    required: true,
                                    validate: (value)=> value.split(' ').length >= 2,
                                })}
                                message={errors.name && 'Please provide a first and last name.'}
                                tone={errors.name && 'error'}
                                required
                            />
                            <Box mt="22px">
                                <TextField
                                    name="email"
                                    label="Email"
                                    labelProps={{ fontSize: 18, fontWeight: 'normal' }}
                                    type="email"
                                    inputProps={{ height: '48px', width: '100%' }}
                                    inputRef={register({
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'invalid email address',
                                        },
                                    })}
                                    message={errors.email && 'Please provide a valid email.'}
                                    tone={errors.email && 'error'}
                                    required
                                />
                            </Box>
                            <Box mt="22px">
                                <LRPhoneInput
                                    rfhMethods={rfhMethods}
                                    label="Phone" name="phone"
                                    labelProps={{ fontSize: 18, fontWeight: 'normal' }}
                                    inputProps={{ height: '48px', width: '100%' }}
                                />
                            </Box>
                            <Box mt="22px">
                                <TextField
                                    name="message"
                                    label="Message"
                                    labelProps={{ fontSize: 18, fontWeight: 'normal' }}
                                    inputProps={{
                                        height: '96px',
                                        width: '100%',
                                        component: 'textarea',
                                        padding: '12px',
                                        maxLength: '1000',
                                        style: { resize: 'none', outline: 'none' },
                                    }}
                                    inputRef={register}
                                />
                            </Box>
                        </Box>
                        <Box mt="32px">
                            <CustomLRButton
                                type="submit"
                                disabled={
                                    submittingLead ||
                                    !formState.dirtyFields.has('name') ||
                                    !formState.dirtyFields.has('email')
                                }
                                variant="contained"
                                color="primary"
                            >
                                {!submittingLead && <Box fontSize={16}>Submit</Box>}
                                {submittingLead && <CircularProgress size={24} color="secondary" />}
                            </CustomLRButton>
                        </Box>
                    </Box>

                </Box>
            </form>

            <Box mt={3} color="#A1A1A1" fontSize="12px" pb={3}>
                {`By clicking Submit, you consent to receive emails, calls, and texts from ${user.name} and ListReports about your inquiry.`}
                <span>You agree to ListReports <LRLink native target="_blank" href="https://welcome.listreports.com/terms-conditions">Terms of Service</LRLink> and <LRLink native target="_blank" href="https://welcome.listreports.com/privacy-policy">Privacy Policy</LRLink>. Msg/data rates may apply.</span>
                <br /><br />
                {`${user.first_name}'s NMLS #${user.realtor_license_number} `}<LRLink onClick={()=> {
                    setShowLOCompliance(true);
                }}>learn more</LRLink>.
            </Box>

            <LOCompliance open={showLOCompliance} toggleModal={()=> {
                setShowLOCompliance(!showLOCompliance);
            }}/>
        </Box>
    );
});
