import * as parsers from './parsers';
import { geocoder } from '../gmaps';
import Address from 'lana';

const SourceMatchers = [
    { regex: /https:\/\/www\.zillow\.com\/(.*)/, source: 'zillow', parser: parsers.zillow },
    { regex: /https:\/\/www\.realtor\.com\/(.*)/, source: 'realtor', parser: parsers.realtor },
    { regex: /https:\/\/www\.trulia\.com\/(.*)/, source: 'trulia', parser: parsers.trulia },
    { regex: /https:\/\/www\.redfin\.com\/(.*)/, source: 'redfin', parser: parsers.redfin }
];

export const extractListingUrlAddress = async (url)=> {
    let matcher = SourceMatchers.find((matcher)=> matcher.regex.test(url));

    if(!matcher) return null;
    const [, path] = url.match(matcher.regex);
    const addressString = matcher.parser(path);
    const lanaAddress = new Address(addressString);

    if(lanaAddress._isValid) {
        return {
            source: matcher.source,
            extractedAddress: addressString,
            formattedAddress: lanaAddress.toString()
        };
    }
    const geocodeResults = await geocoder.geocode(addressString);

    if(geocodeResults && geocodeResults.length === 1) {
        const [geocodedAddress] = geocodeResults;
        return {
            source: matcher.source,
            extractedAddress: addressString,
            formattedAddress: geocodedAddress.formatted_address
        };
    }

    return {
        source: matcher.source,
        extractedAddress: addressString,
        formattedAddress: null
    };
};
