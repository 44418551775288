import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { Box, BottomNavigation, BottomNavigationAction, Menu, MenuItem, Typography, withTheme, SvgIcon, Hidden } from '@material-ui/core';
import { CompareArrows, CalendarToday, MoreHoriz, TrendingUp, TableChart, DashboardRounded, BusinessCenterRounded, TrendingUpRounded, HomeRounded, AssignmentIndRounded } from '@material-ui/icons';
import ResponsiveHoc from '../../../components/ResponsiveHoc';
import LRButton from '../../../components/LRButton';
import RoomIcon from '@material-ui/icons/Room';
import styled, { css } from 'styled-components';
import { PushPinIcon, BriefcaseIcon, TourRounded, HomeSparkleIcon } from '../../../components/LRIcons';
import analytics from '../../../services/analytics';
import { useActiveBoard, useStore } from '../../../mobx-store';
import { observer } from 'mobx-react-lite';
import LRAvatar, { MultipleAvatars } from '../../../components/LRAvatar';
import { useMenu } from '../../../hooks';
import { useRef } from 'react';
import _, { partial } from 'lodash';
import { NotificationRing } from '../../../components/NotificationRing';
import Logger from 'js-logger';
import { NAV_SCHEDULED_TOURS, NAV_BOARD, NAV_COMPARE_NOW, NAV_IDEAS_BOARD, NAV_MORTGAGE_CENTER, NAV_OVERVIEW, NAV_MY_HOME } from '../../../constants';
import { useCurrentPage } from 'hooks/useCurrentPage';
import { useOnboarding } from 'components/Onboarding';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

const routeEventNameMapper = {
    '/boards/my-home': 'robin_board_my_home_button_click',
    '/boards': 'robin_board_board_button_click',
    '/overview': 'robin_overview_button_click',
    '/map': 'robin_board_map_button_click',
    '/calendar': 'robin_board_open_house_calendar_button_click',
    '/boards/compare-now': 'robin_board_compare_button_click',
    '/boards/idea-board': 'robin_board_pinspiration_click',
    '/boards/mortgage-center': 'robin_board_mortgage_center_click',
    '/boards/scheduled-tours': 'robin_board_home_tour_button_click',
};

const StyledRoomIcon = styled(RoomIcon)`
    font-size: 16px;
`;

const StyledEventOutlined = styled(CalendarToday)`
    font-size: 16px;
`;

const StyledTourRounded = styled(TourRounded)`
    font-size: 16px;
`;

const StyledCompareArrows = styled(CompareArrows)`
    font-size: 16px;
`;

const StyledPushPinIcon = styled(PushPinIcon)`
    font-size: 16px;
`;

const StyledBriefcaseIcon = styled(BriefcaseIcon)`
    font-size: 16px;
`;

const ButtonSpacer = styled.div`
    margin: 0 4px;
`;

const StyledBottomNavigationAction = withTheme(styled(BottomNavigationAction)`
    color: ${({ theme })=> theme.palette.lr_colors.grade};
    ${({ isActive, theme })=> isActive && `
        color: ${theme.palette.lr_colors.brick};
    `}
    min-width: 40px;
    padding: 0;
    flex: 0 1 auto;
    font-size: 10px;
    letter-spacing: 0.4px;

    & span {
        // force label font-size to 10px
        font-size: 10px !important;
    }
`);

const StyledLRButton = withTheme(styled(LRButton)`
    border-color: ${({ theme })=> theme.palette.lr_colors.grey_2};
    padding-right: 12px;
    padding-left: 12px;

    ${({ selected, theme })=> selected && css`
        color: #fff !important;
        background-color: ${theme.palette.lr_colors.grade} !important;
        border-color: ${theme.palette.lr_colors.grade} !important;
        cursor: default;
    `}
`);

const MoreMenu = observer(function MoreMenu({
    onMenuItemClick = ()=> {},
    ...props
}) {
    const { UserStore  } = useStore();
    const { user } = UserStore;
    return (
        <Menu {...props}>
            <MenuItem 
                onClick={partial(onMenuItemClick, _, '/boards/idea-board')}
            >
                <Typography variant="body2">
                    Idea Board
                </Typography>
            </MenuItem>
            <MenuItem onClick={partial(onMenuItemClick, _, 'openLeftSideDrawer')}
                className="onMenuItemClick"
            >
                <Typography variant="body2">
                    Agent
                </Typography>
            </MenuItem>
            {user.affiliate?.benoit_partner && (
                <MenuItem onClick={partial(onMenuItemClick, _, '/boards/mortgage-center')}>
                    <Typography variant="body2">
                        Mortgage Center
                    </Typography>
                </MenuItem>
            )}
            {/* <MenuItem onClick={analytics.eventTrackOnEvent('robin_board_home_report_button_click', null, partial(onMenuItemClick, _, '/boards/home-report'))}>
                <Typography variant="body2">
                    Home Report
                </Typography>
            </MenuItem> */}
        </Menu>
    );
});

const ConsumerNavbarMobile = observer(function ConsumerNavbarMobile({
    onChange,
    showNotificationPulse,
    menu
}) {
    const location = useLocation();
    const [menuProps, handleOpenMenuClick] = menu;
    const moreButtonRef = useRef();
    const { UserStore, NotificationsStore  } = useStore();
    const user = UserStore.user;
    const {
        current_page:currentlyOnPage,
    } = useCurrentPage();

    return (
        <BottomNavigation
            onChange={onChange}
            showLabels
            style={{
                height: '64px',
                justifyContent: 'space-around'
            }}
        >
            {/* <StyledBottomNavigationAction
                value="openLeftSideDrawer"
                label="Agent"
                icon={
                    <NotificationRing
                        showPulse={showNotificationPulse}
                        hasNotifications={!!NotificationsStore.unreadAgentActionsNotifications.length}
                    >
                        {!!NotificationsStore.unreadAgentActionsNotifications.length ? (
                            <MultipleAvatars users={[user?.affiliate]} avatarSize="xxsmall" />
                        ) : (
                            <LRAvatar user={user?.affiliate} size="size20" />
                        )}
                    </NotificationRing>
                }
                className="tour-target-mobile-footer-agent-toggle"
            /> */}

            <StyledBottomNavigationAction
                value="/boards/home-report"
                isActive={location.pathname === '/boards/home-report'}
                label="Report"
                icon={<HomeSparkleIcon fillColor="" />}
                className="tour-target-navbar-home-base-button"
            ></StyledBottomNavigationAction>

            <StyledBottomNavigationAction
                value="/boards"
                isActive={location.pathname === '/boards'}
                label="Board"
                icon={<DashboardRounded />}
            ></StyledBottomNavigationAction>

            <StyledBottomNavigationAction
                className="tours-btn"
                value="/boards/scheduled-tours"
                isActive={location.pathname === '/scheduled-tours'}
                label="Tours"
                icon={<TourRounded />}
            ></StyledBottomNavigationAction>

            <StyledBottomNavigationAction
                className="compare-btn"
                value="/boards/compare-now"
                isActive={location.pathname === '/compare-now'}
                label="Compare"
                icon={<CompareArrows />}
            ></StyledBottomNavigationAction>

            {/* <StyledBottomNavigationAction
                value="/boards/idea-board"
                isActive={location.pathname === '/boards/idea-board'}
                label="Ideas"
                icon={<PushPinIcon fillColor="" />}
                className="tour-target-navbar-idea-button"
            ></StyledBottomNavigationAction> */}

            

            <StyledBottomNavigationAction
                ref={moreButtonRef}
                value="more"
                label="More"
                icon={<MoreHoriz />}
                onClick={handleOpenMenuClick}
            ></StyledBottomNavigationAction>

            <MoreMenu {...menuProps} anchorEl={moreButtonRef.current} onMenuItemClick={onChange} />
        </BottomNavigation>
    );
});

const NonConsumerMoreMenu = observer(function NonConsumerMoreMenu({
    onMenuItemClick = ()=> {},
    ...props
}) {
    const { UserStore  } = useStore();
    const { user } = UserStore;
    return (
        <Menu {...props}>
            <MenuItem onClick={partial(onMenuItemClick, _, 'openLeftSideDrawer')}
                className="onMenuItemClick"
            >
                <Typography variant="body2">
                    Agent
                </Typography>
            </MenuItem>
            <MenuItem onClick={partial(onMenuItemClick, _, '/boards/idea-board')}>
                <Typography variant="body2">
                    Idea Board
                </Typography>
            </MenuItem>
            {user.affiliate?.benoit_partner && UserStore.loRobinAccess && (
                <MenuItem onClick={partial(onMenuItemClick, _, '/boards/mortgage-center')}>
                    <Typography variant="body2">
                        Mortgage Center
                    </Typography>
                </MenuItem>
            )}
            <MenuItem onClick={partial(onMenuItemClick, _, '/boards/compare-now')}>
                <Typography variant="body2">
                    Compare
                </Typography>
            </MenuItem>
        </Menu>
    );
});

const NonConsumerNavbarMobile = observer(function NonConsumerNavbarMobile({
    onChange,
    menu
}) {
    const location = useLocation();
    const [menuProps, handleOpenMenuClick] = menu;
    const moreButtonRef = useRef();
    return (
        <BottomNavigation
            onChange={onChange}
            showLabels
            style={{
                height: '64px',
                justifyContent: 'space-around'
            }}
        >
            <StyledBottomNavigationAction
                className="overview-btn"
                value="/overview"
                isActive={location.pathname === '/overview'}
                label="Overview"
                icon={<AssignmentIndRounded />}
            ></StyledBottomNavigationAction>

            <StyledBottomNavigationAction
                className="home-base"
                value="/boards/home-report"
                isActive={location.pathname === '/home-report'}
                label="Report"
                icon={<HomeSparkleIcon />}
            ></StyledBottomNavigationAction>

            <StyledBottomNavigationAction
                className="board-btn"
                value="/boards"
                isActive={location.pathname === '/boards'}
                label="Board"
                icon={<TableChart />}
            ></StyledBottomNavigationAction>

            <StyledBottomNavigationAction
                className="tours-btn"
                value="/boards/scheduled-tours"
                isActive={location.pathname === '/scheduled-tours'}
                label="Tours"
                icon={<TourRounded />}
            ></StyledBottomNavigationAction>

            <StyledBottomNavigationAction
                ref={moreButtonRef}
                value="more"
                label="More"
                isActive={false}
                icon={<MoreHoriz />}
                onClick={handleOpenMenuClick}
            ></StyledBottomNavigationAction>

            <NonConsumerMoreMenu {...menuProps} anchorEl={moreButtonRef.current} onMenuItemClick={onChange} />
        </BottomNavigation>
    );
});

const UtilitiesNavbarMobile = observer(({
    onOpenLeftSidebar = ()=> {},
})=> {
    const history = useHistory();
    const { UserStore, NotificationsStore  } = useStore();
    const menu = useMenu();
    const [, , handleCloseMenu] = menu;
    const [previousNotificationCount, setPreviousNotificationCount] = useState();
    const [showNotificationPulse, setShowNotificationPulse] = useState(false);
    const notificationPulseTimeout = useRef();

    // useEffects
    useEffect(()=> {
        if(previousNotificationCount == null) {
            setPreviousNotificationCount(NotificationsStore.unreadAgentActionsNotifications.length);
            return;
        }

        if(NotificationsStore.unreadAgentActionsNotifications.length <= previousNotificationCount) return;

        if(notificationPulseTimeout.current) {
            clearTimeout(notificationPulseTimeout.current);
        }

        setShowNotificationPulse(true);

        notificationPulseTimeout.current = setTimeout(()=> {
            setShowNotificationPulse(false);
        }, 11000);

        return ()=> {
            setShowNotificationPulse(false);

            if(notificationPulseTimeout.current) {
                clearTimeout(notificationPulseTimeout.current);
            }
        };
    }, [NotificationsStore.unreadAgentActionsNotifications.length]);

    // Functions
    const onChange = (event, newValue)=> {
        handleCloseMenu();

        if(newValue === 'openLeftSideDrawer') {
            return onOpenLeftSidebar(event);
        }

        if(newValue === 'more') {
            return;
        }

        analytics.eventTrack(routeEventNameMapper[newValue]);

        if(newValue) {
            history.push(newValue);
        }
    };

    return (
        <Box position="fixed" bottom={0} left={0} right={0} zIndex={100} color="#000" borderTop='1px solid #DDDDDD'>
            {UserStore.isConsumer ? (
                <ConsumerNavbarMobile
                    onChange={onChange}
                    showNotificationPulse={showNotificationPulse}
                    menu={menu}
                />
            ) : (
                <NonConsumerNavbarMobile
                    onChange={onChange}
                    menu={menu}
                />
            )}
        </Box>
    );
});

const UtilitiesNavbarDesktop = observer(()=> {
    const history = useHistory();
    const { UserStore } = useStore();
    const { user } = UserStore;
    const {
        current_page:currentlyOnPage,
    } = useCurrentPage();
    const { isIPadProUp, } = useDeviceBreakpoints();
    

    // Functions

    function handleClick(route) {
        analytics.eventTrack(routeEventNameMapper[route]);
        history.push(route);
    }

    return (
        <Box display="flex" alignItems="center">
            {!UserStore.isConsumer && (
                <>
                    <ButtonSpacer>
                        <StyledLRButton
                            variant="outlined"
                            onClick={()=> handleClick('/overview')}
                            selected={currentlyOnPage === NAV_OVERVIEW}
                        >
                            <Box display="flex" alignItems="center">
                                <Hidden smDown>
                                    <AssignmentIndRounded style={{ fontSize: 16 }} />
                                </Hidden>
                                <Typography component="div" variant="body2">
                                    <Box component="span" ml={isIPadProUp && 1}>Overview</Box>
                                </Typography>
                            </Box>
                        </StyledLRButton>
                    </ButtonSpacer>
                </>
            )}
            <ButtonSpacer>
                <StyledLRButton
                    variant="outlined"
                    onClick={analytics.eventTrackOnEvent('robin_board_home_report_button_click', null, ()=> handleClick(NAV_MY_HOME))}
                    selected={currentlyOnPage === NAV_MY_HOME}
                >
                    <Box display="flex" alignItems="center">
                        <Hidden smDown>
                            <HomeSparkleIcon style={{ fontSize: 16 }} />
                        </Hidden>
                        <Typography component="div" variant="body2">
                            <Box component="span" ml={isIPadProUp && 1}>Home Report</Box>
                        </Typography>
                    </Box>
                </StyledLRButton>
            </ButtonSpacer>
            <ButtonSpacer>
                <StyledLRButton
                    variant="outlined"
                    className="board-btn"
                    onClick={()=> handleClick(NAV_BOARD)}
                    selected={currentlyOnPage === NAV_BOARD}
                >
                    <Box display="flex" alignItems="center">
                        <Hidden smDown>
                            <DashboardRounded style={{ fontSize: 16 }} />
                        </Hidden>
                        <Typography component="div" variant="body2">
                            <Box component="span" ml={isIPadProUp && 1}>Board</Box>
                        </Typography>
                    </Box>
                </StyledLRButton>
            </ButtonSpacer>
            <ButtonSpacer>
                <StyledLRButton
                    variant="outlined"
                    onClick={()=> handleClick('/boards/scheduled-tours')}
                    selected={currentlyOnPage === NAV_SCHEDULED_TOURS}
                >
                    <Box display="flex" alignItems="center">
                        <Hidden smDown>
                            <StyledTourRounded />
                        </Hidden>
                        <Typography component="div" variant="body2">
                            <Box component="span" ml={isIPadProUp && 1}><Hidden smDown>Home</Hidden> Tours</Box>
                        </Typography>
                    </Box>
                </StyledLRButton>
            </ButtonSpacer>
            <ButtonSpacer>
                <StyledLRButton
                    variant="outlined"
                    onClick={()=> handleClick('/boards/compare-now')}
                    className="compare-btn"
                    selected={currentlyOnPage === NAV_COMPARE_NOW}
                >
                    <Box display="flex" alignItems="center">
                        <Hidden smDown>
                            <StyledCompareArrows/>
                        </Hidden>
                        <Typography component="div" variant="body2">
                            <Box component="span"ml={isIPadProUp && 1}>Compare</Box>
                        </Typography>
                    </Box>
                </StyledLRButton>
            </ButtonSpacer>
            <ButtonSpacer>
                <StyledLRButton
                    variant="outlined"
                    onClick={()=> handleClick('/boards/idea-board')}
                    className="tour-target-navbar-idea-button"
                    selected={currentlyOnPage === NAV_IDEAS_BOARD}
                >
                    <Box display="flex" alignItems="center">
                        <Hidden smDown>
                            <StyledPushPinIcon fillColor="inherit" />
                        </Hidden>
                        <Typography component="div" variant="body2">
                            <Box component="span" ml={isIPadProUp && 1}>Idea<Hidden mdUp>s</Hidden> <Hidden smDown>Board</Hidden></Box>
                        </Typography>
                    </Box>
                </StyledLRButton>
            </ButtonSpacer>
            {user.affiliate?.benoit_partner && UserStore.loRobinAccess && (
                <ButtonSpacer>
                    <StyledLRButton
                        variant="outlined"
                        onClick={()=> handleClick('/boards/mortgage-center')}
                        selected={currentlyOnPage === NAV_MORTGAGE_CENTER}
                    >
                        <Box display="flex" alignItems="center">
                            <Hidden smDown>
                                <BusinessCenterRounded style={{ fontSize: 16 }} />
                            </Hidden>
                            <Typography component="div" variant="body2">
                                <Box component="span" ml={isIPadProUp && 1}>Mortgage <Hidden smDown>Center</Hidden></Box>
                            </Typography>
                        </Box>
                    </StyledLRButton>
                </ButtonSpacer>
            )}
        </Box>
    );
});

const UtilitiesNavbar = ResponsiveHoc({ xs: UtilitiesNavbarMobile, sm: UtilitiesNavbarDesktop });

export default UtilitiesNavbar;
