import { AppBar, ClickAwayListener, Grid, IconButton, MenuItem, Popover, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ChevronLeftRounded, CreateRounded, ExpandMoreRounded } from '@material-ui/icons';
import LRButton from 'components/LRButton';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';
import _, { partial } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Can } from 'components/Ability';
import { subject } from '@casl/ability';

export function AppBarHeader({
    tour,
    tours = [],
    onEditClick = (e)=> {},
    onCancelTourClick = (e)=> {},
    onCloseClick = (e)=> {},
    onTourChange = (e)=> {},
    closeCTAText,
    ...props
}) {
    const theme = useTheme();
    const {
        isMobile,
        isIPadDown,
    } = useDeviceBreakpoints();
    const [openSelectTourPopover, setOpenSelectTourPopover] = useState(false);
    const [openSelectTourPopoverAnchorEl, setOpenSelectTourPopoverAnchorEl] = useState(null);

    // Functions

    function handleCloseSelectTourPopover() {
        setOpenSelectTourPopover(false);
    }

    function handleOpenSelectTourPopover() {
        setOpenSelectTourPopover(true);
    }

    return (
        <>
            <AppBar
                color="inherit"
                elevation={0}
                style={{
                    borderBottom: '1px solid #ddd',
                }}
                {...props}
            >
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs={1} sm={3}>
                            {!isMobile ? (
                                <LRButton startIcon={<ChevronLeftRounded />} onClick={onCloseClick}>
                                    {closeCTAText ? closeCTAText : 'All Home Tours'}
                                </LRButton>
                            ) : (
                                <IconButton edge="start" onClick={onCloseClick}>
                                    <ChevronLeftRounded />
                                </IconButton>
                            )}
                        </Grid>

                        <Grid item xs container justify="center" alignItems="center">
                            {Boolean(tours?.length) && (
                                <ClickAwayListener onClickAway={handleCloseSelectTourPopover}>
                                    <Grid container justify="center" alignItems="center" style={{ cursor: 'pointer', width: 'auto' }} ref={setOpenSelectTourPopoverAnchorEl} onClick={handleOpenSelectTourPopover}>
                                        <Typography variant={isMobile ? 'body2' : 'h6'} style={{ fontWeight: 'normal' }}>
                                            {tour?.content?.tour_name}
                                        </Typography>
                                        <ExpandMoreRounded
                                            style={{ marginLeft: '4px' }}
                                            type="button"
                                        />


                                        <Popover
                                            open={openSelectTourPopover}
                                            anchorEl={openSelectTourPopoverAnchorEl}
                                            style={{ zIndex: 1300, borderColor: '#bbb', borderRadius: 4, marginTop: 8 }}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        >
                                            <ul
                                                style={{
                                                    paddingTop: theme.spacing(1),
                                                    paddingBottom: theme.spacing(1),
                                                }}
                                            >
                                                {tours.map((tour)=> (
                                                    <MenuItem key={tour.id} onClick={partial(onTourChange, _, tour)}>
                                                        {tour.content.tour_name}
                                                    </MenuItem>
                                                ))}
                                            </ul>
                                        </Popover>
                                    </Grid>
                                </ClickAwayListener>
                            )}
                        </Grid>

                        <Grid item xs={1} sm={3} style={{ textAlign: 'right' }}>
                            {!isIPadDown && !tour?.content.cancelled && (!tour?.content?.scheduled_date_time || moment().isBefore(tour.content.scheduled_date_time)) && (
                                <Grid container spacing={2} justify="flex-end">
                                    <Can I="edit" this={subject('HomeTour', tour)}>
                                        <Grid item>
                                            <LRButton variant="outlined" startIcon={<CreateRounded />} onClick={onEditClick}>
                                                Edit
                                            </LRButton>
                                        </Grid>
                                    </Can>
                                    <Can I="delete" this={subject('HomeTour', tour)}>
                                        <Grid item>
                                            <LRButton variant="outlined" onClick={onCancelTourClick}>
                                                Cancel Tour
                                            </LRButton>
                                        </Grid>
                                    </Can>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    );
}
